import { clsx } from 'clsx'

import { Space } from '@tribeplatform/gql-client/types'
import {
  BannerPlaceholder,
  EntityImage,
} from '@tribeplatform/react-components/common/components'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { useRouter } from '@tribeplatform/react-sdk'

import { getGlyph, getMedia } from '../utils/index.js'

interface Props {
  field: StaticField
  space: Space
  highlighted: boolean
  aspectRatio: StaticField['settings']['aspectRatio']
  preview: boolean
}
export const CardBanner = ({
  field,
  space,
  highlighted,
  aspectRatio,
  preview,
}: Props) => {
  const { Link } = useRouter()
  const bannerSource = field.settings?.bannerSource
  const mediaUrl = getMedia({ source: bannerSource, space })
  const glyph = getGlyph({
    source: bannerSource,
    space,
  })
  const fieldMediaUrl = mediaUrl

  if (!mediaUrl && !preview) {
    return null
  }

  if (mediaUrl || bannerSource !== 'image') {
    return (
      <Link href={space?.relativeUrl ?? ''} variant="inherit" tabIndex={-1}>
        <div
          className={clsx(
            'w-full relative',
            aspectRatio || 'aspect-video',
            highlighted && 'border-2 border-focused',
          )}
        >
          {!fieldMediaUrl ? (
            <div className="absolute inset-0">
              <BannerPlaceholder name={space?.relativeUrl} />
            </div>
          ) : (
            <div
              className="w-full h-full bg-cover bg-center absolute top-0 start-0"
              style={{ backgroundImage: `url(${fieldMediaUrl})` }}
            ></div>
          )}
        </div>
      </Link>
    )
  }

  return (
    <Link href={space?.relativeUrl ?? ''} variant="inherit" tabIndex={-1}>
      <div
        className={clsx(
          'w-full relative',
          aspectRatio || 'aspect-video',
          highlighted && 'border-2 border-focused',
        )}
      >
        <div className="w-full h-full flex justify-center items-center text-content-on-background">
          <EntityImage entity={{ image: glyph }} size="6x" />
        </div>
      </div>
    </Link>
  )
}
