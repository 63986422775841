import { useMemo, useState } from 'react'

import { PostType } from '@tribeplatform/gql-client/types'
import { usePostTypes } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Multiselect } from '@tribeplatform/react-ui-kit/Multiselect'
import { SearchableSelect } from '@tribeplatform/react-ui-kit/SearchableSelect'

import { useDebounce } from '../common/hooks/useDebounce.js'
import { T } from '../i18n/components/T.js'
import { pickIfExists } from './arrays.utils.js'

type PostTypePickerSingleProps = {
  value?: PostType
  onChange: (values: PostType) => void
}
type PostTypePickerMultipleProps = {
  value?: PostType[]
  onChange: (values: PostType[]) => void
}

export type PostTypePickerProps = (
  | PostTypePickerSingleProps
  | PostTypePickerMultipleProps
) & {
  placeholder?: string
  options?: PostType[]
  disabled?: boolean
  /**
   * Allows to pick multiple values at once
   * @default false
   */
  multiple?: boolean
  noWrapper?: boolean
  invalid?: boolean
  className?: string
}

const isMultiple = (
  props: PostTypePickerProps,
): props is PostTypePickerMultipleProps => props.multiple === true

export const PostTypePicker = (props: PostTypePickerProps) => {
  const { placeholder, options, disabled, noWrapper, invalid, className } =
    props

  const [search, setSearch] = useState('')
  const debouncedQuery = useDebounce(search, 300)

  const { data, isInitialLoading } = usePostTypes({
    fields: 'basic',
    variables: {
      query: debouncedQuery,
      limit: 20,
    },
    useInfiniteQueryOptions: {
      enabled: !options,
    },
  })

  const postTypes = useMemo(() => {
    const postTypeOptions =
      options || simplifyPaginatedResult<PostType>(data)?.nodes || []
    return postTypeOptions
  }, [data, options])

  if (isMultiple(props)) {
    const { value, onChange } = props
    const suggestedPostTypes: PostType[] = pickIfExists(postTypes, value, 'id')

    return (
      <Multiselect
        value={value || []}
        options={suggestedPostTypes}
        onChange={onChange}
        searchable
        onInputChange={setSearch}
        disabled={disabled}
        className={className}
        invalid={invalid}
      >
        <Multiselect.Button
          placeholder={placeholder}
          hideInput={value?.length > 0}
        >
          {value?.map((postType, index) => (
            <Multiselect.SelectedItem
              className="px-1 pe-2"
              key={postType.id}
              value={postType}
              index={index}
            >
              <PostTypeSelectItem postType={postType} />
            </Multiselect.SelectedItem>
          ))}
        </Multiselect.Button>
        <Multiselect.Items noWrapper={noWrapper}>
          {suggestedPostTypes.map((postType, index) => (
            <Multiselect.Item key={postType.id} value={postType} index={index}>
              <PostTypeSelectItem postType={postType} />
            </Multiselect.Item>
          ))}
          {isInitialLoading && (
            <Multiselect.ItemsEmpty>
              <T id="Generics.LoadingDotDotDot" defaultMessage="Loading..." />
            </Multiselect.ItemsEmpty>
          )}
          {!isInitialLoading && search && suggestedPostTypes.length === 0 && (
            <Multiselect.ItemsEmpty>
              <T id="Generics.NoResults" defaultMessage="No results" />
            </Multiselect.ItemsEmpty>
          )}
        </Multiselect.Items>
      </Multiselect>
    )
  }

  const { value, onChange } = props
  const suggestedPostTypes: PostType[] = value
    ? pickIfExists(postTypes, [value], 'id')
    : postTypes

  return (
    <SearchableSelect
      value={value}
      options={suggestedPostTypes}
      onChange={onChange}
      onInputChange={setSearch}
    >
      <SearchableSelect.Button placeholder={placeholder}>
        {value && <PostTypeSelectItem postType={value} />}
      </SearchableSelect.Button>
      <SearchableSelect.Items>
        {suggestedPostTypes.map(postType => (
          <SearchableSelect.Item key={postType.id} value={postType.id}>
            <PostTypeSelectItem postType={postType} />
          </SearchableSelect.Item>
        ))}
        {isInitialLoading && (
          <SearchableSelect.ItemsEmpty>
            <T id="Generics.LoadingDotDotDot" defaultMessage="Loading..." />
          </SearchableSelect.ItemsEmpty>
        )}
        {!isInitialLoading && search && suggestedPostTypes.length === 0 && (
          <SearchableSelect.ItemsEmpty>
            <T id="Generics.NoResults" defaultMessage="No results" />
          </SearchableSelect.ItemsEmpty>
        )}
      </SearchableSelect.Items>
    </SearchableSelect>
  )
}

const PostTypeSelectItem = (props: { postType: PostType }) => {
  const { postType } = props
  return (
    <div className="flex items-center space-s-2">
      <span className="truncate">{postType?.name}</span>
    </div>
  )
}
