import { createContext, useContext, useMemo } from 'react'

import type { Image } from '@tribeplatform/gql-client/types'

interface PreviewLogoContextProps {
  logo: Image
  squareLogo: Image
}
const PreviewLogoContext = createContext<PreviewLogoContextProps>(null)

export const usePreviewLogo = () => {
  return useContext(PreviewLogoContext)
}

interface PreviewLogoProviderProps extends PreviewLogoContextProps {
  children: React.ReactNode
}
export const PreviewLogoProvider = ({
  logo,
  squareLogo,
  children,
}: PreviewLogoProviderProps) => {
  const value = useMemo(() => {
    return { logo, squareLogo }
  }, [logo, squareLogo])

  return (
    <PreviewLogoContext.Provider value={value}>
      {children}
    </PreviewLogoContext.Provider>
  )
}
