import { theme as defaultTheme } from '@tribeplatform/design-system/themes/default'
import { Theme } from '@tribeplatform/design-system/types'
import { logger } from '@tribeplatform/react-components/common'

export const colorThemeNames = [
  'default',

  'primary-blueish',
  'peachy-rose',
  'bright-lilac',
  'classic-eggplant',
  'classic-orange',
  'summer-atmosphere',
  'essential-violet',

  'vigilate',
  'semita',
  'ornamentes',
  'zuck',
] as const

export type TThemeModules = (typeof colorThemeNames)[number]
export type TThemeModule = { theme: Theme }

export const isSupportedTheme = (
  themeName: string,
): themeName is TThemeModules =>
  colorThemeNames.includes(themeName as TThemeModules)

/**
 * dynamic import does not support import from module
 * https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#imports-must-start-with--or-
 */
const resolveThemeJsModule = (name: TThemeModules): Promise<TThemeModule> => {
  switch (name) {
    case 'default':
      return import('@tribeplatform/design-system/themes/default')
    // legacy themes
    case 'vigilate':
      return import('@tribeplatform/design-system/themes/vigilate')
    case 'semita':
      return import('@tribeplatform/design-system/themes/semita')
    case 'ornamentes':
      return import('@tribeplatform/design-system/themes/ornamentes')
    case 'zuck':
      return import('@tribeplatform/design-system/themes/zuck')
    // new themes
    case 'primary-blueish':
      return import('@tribeplatform/design-system/themes/primary-blueish')
    case 'peachy-rose':
      return import('@tribeplatform/design-system/themes/peachy-rose')
    case 'bright-lilac':
      return import('@tribeplatform/design-system/themes/bright-lilac')
    case 'classic-eggplant':
      return import('@tribeplatform/design-system/themes/classic-eggplant')
    case 'classic-orange':
      return import('@tribeplatform/design-system/themes/classic-orange')
    case 'summer-atmosphere':
      return import('@tribeplatform/design-system/themes/summer-atmosphere')
    case 'essential-violet':
      return import('@tribeplatform/design-system/themes/essential-violet')
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = name
      return exhaustiveCheck
    }
  }
}

export const importThemeModule = async (themeName: string): Promise<Theme> => {
  try {
    if (!themeName || !isSupportedTheme(themeName)) {
      return
    }

    const module = await resolveThemeJsModule(themeName)
    return module.theme
  } catch (e) {
    logger.warn('error loading theme module', themeName)
  }
}

const colorThemes = {} as Record<TThemeModules, Theme>

Promise.all(
  colorThemeNames.map(async themeName => {
    const theme = await importThemeModule(themeName)
    if (theme) {
      colorThemes[theme.id] = theme
    }
  }),
)

// TODO import only currently used theme
export const resolveColorTheme = (name: string): Theme => {
  return colorThemes[name] ?? defaultTheme
}
