import { clsx } from 'clsx'

import { analyticsNumberFormatter } from '@tribeplatform/react-components/Analytics/utils'
import { T } from '@tribeplatform/react-components/i18n'
import { useSpace } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { PostsCountSettings } from '../../Fields/types.js'

interface Props {
  className?: string
  settings: PostsCountSettings
  spaceId: string
}

export const PostsCountField = ({ className, settings, spaceId }: Props) => {
  const { data: space } = useSpace({
    variables: {
      id: spaceId,
    },
    useQueryOptions: {
      enabled: !!spaceId,
    },
  })
  const postsCount = space?.postsCount ?? 0
  const { showText } = settings

  return (
    <div className={clsx(className, 'flex items-center gap-1')}>
      <SvgIcon name="post" size="lg" />
      <span>{analyticsNumberFormatter(postsCount)}</span>
      {showText && (
        <T
          id="SpaceHeader.PostsCountText"
          defaultMessage="{posts_count, plural, =1 {post} =0 {posts} other {posts}}"
          values={{ posts_count: postsCount }}
        />
      )}
    </div>
  )
}
