import React from 'react'

import { ClientFileError } from '@tribeplatform/gql-client/lib'

import {
  FormImageConfig,
  FormImageVariant,
  UploadableImage,
  UploadableMedia,
} from './types.js'
import {
  useHandleUploadableMedia,
  type UseHandleUploadableMediaProps,
} from './useHandleUploadableMedia.js'
import { VariantAvatar } from './VariantAvatar.js'
import { VariantCompact } from './VariantCompact.js'
import { VariantDefault } from './VariantDefault.js'

type FormImageUploadSingleProps = {
  value?: UploadableMedia
  /** Callback triggered on image upload finish */
  onChange?: (media: UploadableMedia | undefined) => void
  variant?: FormImageVariant
}

type FormImageUploadMultipleProps = {
  value?: UploadableImage[]
  /** Callback triggered on image upload finish */
  onChange?: (medias: UploadableImage[]) => void
  variant: 'default'
}

export type FormImageUploadProps = (
  | FormImageUploadSingleProps
  | FormImageUploadMultipleProps
) & {
  /**
   * Allows multiple images to be uploaded at once
   * @default false
   */
  multiple?: boolean
  /** Callback triggered on image upload start */
  onUploadStart?: UseHandleUploadableMediaProps['onUploadStart']
  onError?: (error: ClientFileError) => void
  config?: FormImageConfig
  cropable?: boolean
  shape?: 'round' | 'rect'
}

const isMultiple = (
  props: FormImageUploadProps,
): props is FormImageUploadMultipleProps => props.multiple === true

const defaultConfig: FormImageConfig = {
  emoji: false,
  link: true,
  unsplash: true,
}

export const FormImageUpload = (props: FormImageUploadProps) => {
  const {
    variant = 'default',
    // eslint-disable-next-line unused-imports/no-unused-vars
    onChange,
    // eslint-disable-next-line unused-imports/no-unused-vars
    value,
    onUploadStart,
    onError,
    config = defaultConfig,
    ...rest
  } = props
  const multiple = isMultiple(props)
  let defaultValue
  if (multiple) {
    defaultValue = props.value || []
  } else {
    defaultValue = props.value ? [props.value] : []
  }

  const onSuccess = newValue => {
    if (multiple) {
      props.onChange?.(newValue)
    } else {
      props.onChange?.(newValue?.[0])
    }
  }

  const { medias, onImageUpload, onEmojiChange, onIconChange, onRemove } =
    useHandleUploadableMedia({
      defaultValue,
      multiple,
      onChange: onSuccess,
      onUploadStart,
      onError,
    })
  const images = medias?.filter(it => it.name !== 'Emoji') as UploadableImage[]

  switch (variant) {
    case 'compact':
      return (
        <VariantCompact
          image={medias?.[0]}
          onChange={onImageUpload}
          onEmojiChange={config.emoji && onEmojiChange}
          onIconChange={config.icon && onIconChange}
          onRemove={onRemove}
          config={config}
          {...rest}
        />
      )
    case 'avatar':
      return (
        <VariantAvatar
          image={images?.[0]}
          onChange={file => onImageUpload([file])}
          {...rest}
        />
      )
    case 'default':
    default:
      return (
        <VariantDefault
          images={images}
          multiple={multiple}
          onChange={onImageUpload}
          onRemove={onRemove}
          config={config}
          {...rest}
        />
      )
  }
}
