import { gql } from 'graphql-tag'

import { NetworkFields, networkGQLFields } from './network.fields.js'

export const addMemberSchemaFieldGQLMutation = (fields: NetworkFields) => gql`
  mutation AddMemberSchemaField($input: CustomFieldSchemaInput!) {
    addMemberSchemaField(input: $input) {
      ${networkGQLFields(fields)}
    }
  }
`

export const updateMemberSchemaFieldGQLMutation = (
  fields: NetworkFields,
) => gql`
  mutation UpdateMemberSchemaField($input: UpdateCustomFieldSchemaInput!) {
    updateMemberSchemaField(input: $input) {
      ${networkGQLFields(fields)}
    }
  }
`

export const archiveMemberSchemaFieldGQLMutation = (
  fields: NetworkFields,
) => gql`
  mutation ArchiveMemberSchemaField($key: String!) {
    archiveMemberSchemaField(key: $key) {
      ${networkGQLFields(fields)}
    }
  }
`

export const unArchiveMemberSchemaFieldGQLMutation = (
  fields: NetworkFields,
) => gql`
  mutation UnArchiveMemberSchemaField($key: String!) {
    unArchiveMemberSchemaField(key: $key) {
      ${networkGQLFields(fields)}
    }
  }
`

export const deleteMemberSchemaFieldGQLMutation = (
  fields: NetworkFields,
) => gql`
  mutation DeleteMemberSchemaField($key: String!) {
    deleteMemberSchemaField(key: $key) {
      ${networkGQLFields(fields)}
    }
  }
`

export const reorderMemberSchemaFieldsGQLMutation = (
  fields: NetworkFields,
) => gql`
  mutation ReorderMemberSchemaFields($input: [ReOrderSchemaFieldInput!]!) {
    reorderMemberSchemaFields(input: $input) {
      ${networkGQLFields(fields)}
    }
  }
`
