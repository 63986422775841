import { ComponentProps, forwardRef, ReactNode, useMemo } from 'react'

import { clsx } from 'clsx'

import { Spacing } from '../../types.js'
import {
  cleanupClassName,
  getHideOnBreakpointClass,
  getSpacingClasses,
} from '../../utils/utils.js'

interface Props extends ComponentProps<'div'> {
  gap: Spacing
  horizontalMargin: Spacing
  verticalMargin: Spacing
  horizontalPadding: Spacing
  verticalPadding: Spacing
  hideOnMobile?: boolean
  hideOnTablet?: boolean
  hideOnDesktop?: boolean
  className?: string
  children: ReactNode
  dataBlockId?: string
  dataBlockName?: string
}

export const ColumnRenderer = forwardRef<HTMLDivElement, Props>(
  (props: Props, ref) => {
    const {
      gap,
      horizontalMargin,
      verticalMargin,
      horizontalPadding,
      verticalPadding,
      hideOnMobile,
      hideOnTablet,
      hideOnDesktop,
      className,
      children,
      dataBlockId,
      dataBlockName,
    } = props

    const hiddenClass = useMemo(
      () =>
        getHideOnBreakpointClass(
          hideOnMobile,
          hideOnTablet,
          hideOnDesktop,
          'flex',
        ),
      [hideOnDesktop, hideOnMobile, hideOnTablet],
    )
    const spacingClasses = useMemo(
      () =>
        getSpacingClasses({
          gap,
          horizontalMargin,
          verticalMargin,
          horizontalPadding,
          verticalPadding,
        }),
      [
        gap,
        horizontalMargin,
        horizontalPadding,
        verticalMargin,
        verticalPadding,
      ],
    )

    const allClassNames = useMemo(
      () => cleanupClassName(clsx(className, spacingClasses, hiddenClass)),
      [className, hiddenClass, spacingClasses],
    )

    return (
      <div
        data-block-id={dataBlockId}
        data-block-name={dataBlockName}
        ref={ref}
        className={clsx(allClassNames, 'flex flex-col')}
      >
        {children}
      </div>
    )
  },
)
