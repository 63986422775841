import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { AnnouncementBlock as Component } from './Block.js'
import { AnnouncementProps } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.AnnouncementBlockSettings })),
)

export const RegisteredAnnouncementBlock = (
  intl: I18nShape,
): RegisteredBlock<AnnouncementProps> => {
  const { $t } = intl

  return {
    recommendedName: 'Announcement',
    Component,
    config: {
      type: BlockType.Block,
      usable: false,
      deprecated: true,

      displayName: $t({
        id: 'Blocks.Announcement.DisplayName',
        defaultMessage: 'Announcement',
      }),
      Settings,
      defaultProps: {
        align: 'center',
        viewStyle: 'simple',
        hidden: false,
      },
    },
  }
}
