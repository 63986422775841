import type { IntlCache, IntlConfig } from 'react-intl'
import { createIntl } from 'react-intl'

import { DefaultElements, ResourceAliases } from '../placeholders/index.js'
import type { I18nObject } from '../types.js'

export const createI18n: (
  config: IntlConfig,
  cache?: IntlCache,
) => I18nObject = (config, cache) => {
  const intl = createIntl(
    {
      ...config,
      defaultRichTextElements: {
        ...DefaultElements,
        ...config.defaultRichTextElements,
      },
    },
    cache,
  )

  const aliases = ResourceAliases(intl)
  const originalFormatMessage = intl.formatMessage
  intl.formatMessage = (descriptor, values, opts) => {
    return originalFormatMessage(descriptor, { ...aliases, ...values }, opts)
  }

  return intl
}
