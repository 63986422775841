import {
  SpaceJoinRequestStatus,
  SpaceMembershipStatus,
} from '@tribeplatform/gql-client/types'
import type { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

export interface Permissions {
  canJoinSpace: boolean
  canRequestSpaceMembership: boolean
  isLoggedIn: boolean
}

export const getButtonProps = (
  { $t }: I18nShape,
  state: SpaceMembershipStatus | SpaceJoinRequestStatus,
  permission: Permissions,
) => {
  switch (state) {
    case SpaceMembershipStatus.notJoined:
      if (permission.canJoinSpace || !permission.isLoggedIn) {
        return {
          label: $t({
            defaultMessage: 'Join',
            id: 'Generics.Join',
          }),
        }
      }
      if (permission.canRequestSpaceMembership) {
        return {
          label: $t({
            defaultMessage: 'Request to join',
            id: 'Space.MembershipActions.RequestToJoin',
          }),
        }
      }
      return undefined
    case SpaceJoinRequestStatus.PENDING:
    case SpaceMembershipStatus.requested:
      return {
        label: $t({
          defaultMessage: 'Pending',
          id: 'Generics.Pending',
        }),
        icon: <SvgIcon name="clock" />,
        disabled: true,
      }
    default:
      return undefined
  }
}
