import type { Post, PostType } from '@tribeplatform/gql-client/types'
import type {
  RawInlineFilter,
  LegacyFilter,
  FilterAppearances,
  InlineFilter,
} from '@tribeplatform/react-components/Filters'
import type { StaticField, View } from '@tribeplatform/react-components/Views'

export interface PostsBlockView extends View {
  /** @deprecated postTypeId is deprecated in favor of the postTypeSlugs field */
  postTypeId?: string
  /** @deprecated postTypeSlug is deprecated in favor of the postTypeSlugs field */
  postTypeSlug?: string
  postTypeSource: PostTypeSource
  postTypeSlugs?: string[]
  /** @deprecated spaceId is deprecated in favor of the spaceIds field */
  spaceId?: string
  source: PostListSource
  spaceIds?: string[]
  filterBy: LegacyFilter[]
  inlineFilters: RawInlineFilter[]
  allowInlineFilters: boolean
  filterAppearance: FilterAppearances
  sort: string
  sortDirection: SortDirections
  /** @deprecated reverseSort is deprecated in favor of the sortDirection field */
  reverseSort: boolean
  linkTo: string
  showMore: boolean
  limit: number
}

export type PostsBlockProps = {
  views: PostsBlockView[]
  title: string
  description: string
  linkText: string
  linkUrl: string
}

export type FieldProps = {
  field: StaticField
  post: Post
  originalPost?: Post
  postTypes: PostType[]
  viewStyle: View['style']
  linkTo?: string
  activeFieldId: string
  preview: boolean
  isSinglePost?: boolean
  isPostPage?: boolean
  singlePostType?: boolean
  setTranslatedPost?: (post: Post) => void
}

export enum SortDirections {
  ASC = 'asc',
  DESC = 'desc',
}

export enum PostListSource {
  allSpaces = 'allSpaces',
  currentSpace = 'currentSpace',
  joinedSpaces = 'joinedSpaces',
  specificSpaces = 'specificSpaces',
}

export enum PostTypeSource {
  allPostTypes = 'allPostTypes',
  specificPostTypes = 'specificPostTypes',
}

export type SetInlineFilterProperty = <T extends keyof InlineFilter>(
  key: T,
  value: InlineFilter[T],
) => void

export enum ActionStates {
  default = 'default',
  active = 'active',
}
