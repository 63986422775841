import { ReactNode, useEffect, useState } from 'react'

import type { Placement } from '@floating-ui/react'
import { clsx } from 'clsx'
import { useCombobox, useMultipleSelection } from 'downshift'

import { FloatingContextProvider } from '../utils/floating/index.js'
import { MultiselectSelectedItem } from './MultiselectButton.js'
import { MultiselectContext } from './MultiselectContext.js'
import { MultiselectCreatableButton } from './MultiselectCreatableButton.js'
import { MultiselectCreatableItems } from './MultiselectCreatableItems.js'
import { MultiselectItem } from './MultiselectItem.js'
import { MultiselectItemsEmpty } from './MultiselectItems.js'

export type MultiselectCreatableProps = {
  value: string[]
  options: string[]
  onChange?: (newValue: string[]) => void
  className?: string
  children: ReactNode
  disabled?: boolean
  searchable?: boolean
  onInputChange?: (newValue: string) => void
  onCreateItem?: (newItem: string) => void
  /**
   * Sets the position of the createOption element in your options list.
   * @default 'last'
   */
  createItemPosition?: 'first' | 'last'
  /**
   * see https://floating-ui.com/docs/computePosition#placement
   */
  placement?: Placement
}

/**
 * Component that displays a list of options and allows for multiple selections from this list and the creation of new items.
 */
export const MultiselectCreatable = ({
  children,
  value = [],
  options,
  onChange,
  className,
  disabled = false,
  searchable = false,
  onInputChange,
  onCreateItem,
  createItemPosition = 'last',
  placement = 'bottom-start',
  ...rest
}: MultiselectCreatableProps) => {
  const [creating, setCreating] = useState(false)
  const [inputItems, setInputItems] = useState(options)

  const dsMultiple = useMultipleSelection<string>({
    selectedItems: value,
    onSelectedItemsChange: changes => {
      if (typeof onChange === 'function') {
        onChange(changes.selectedItems)
      }
    },
    stateReducer: (_, actionAndChanges) => {
      const { type, changes } = actionAndChanges
      switch (type) {
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          return {
            ...changes,
            activeIndex: null,
          }
        default:
          return changes
      }
    },
  })

  const dsCombobox = useCombobox<string>({
    items: inputItems,
    selectedItem: null,
    onSelectedItemChange: changes => {
      if (
        !changes.selectedItem ||
        changes.type === useCombobox.stateChangeTypes.InputBlur
      ) {
        return
      }

      const index = dsMultiple.selectedItems.indexOf(changes.selectedItem)
      if (index > 0) {
        dsMultiple.setSelectedItems([
          ...dsMultiple.selectedItems.slice(0, index),
          ...dsMultiple.selectedItems.slice(index + 1),
        ])
      } else if (index === 0) {
        dsMultiple.setSelectedItems([...dsMultiple.selectedItems.slice(1)])
      } else {
        dsMultiple.setSelectedItems([
          ...dsMultiple.selectedItems,
          changes.selectedItem,
        ])
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep menu open after selection.
            highlightedIndex: state.highlightedIndex,
            inputValue: '', // don't add the item string as input value at selection.
          }
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: '', // don't add the item string as input value at selection.
          }
        default:
          return changes
      }
    },
    onInputValueChange: changes => {
      setCreating(false)
      setInputItems([])

      if (searchable) {
        onInputChange?.(changes.inputValue)
      }
    },
    onStateChange: changes => {
      const { type, selectedItem } = changes
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (onCreateItem && creating && inputValue === selectedItem) {
            onCreateItem(inputValue)
            setCreating(false)
          }
          break
        default:
          break
      }
    },
  })

  const { inputValue, setHighlightedIndex } = dsCombobox

  useEffect(() => {
    if (inputValue.length > 0) {
      if (!options.includes(inputValue)) {
        setCreating(true)
        if (createItemPosition === 'last') {
          setInputItems([...options, inputValue])
        } else {
          setInputItems([inputValue, ...options])
        }
        setHighlightedIndex(0)
      } else {
        setInputItems(options)
        setCreating(false)
      }
    } else {
      setInputItems(options)
    }
  }, [options, inputValue, createItemPosition, setHighlightedIndex])

  return (
    <div className={clsx('relative isolate', className)} {...rest}>
      <MultiselectContext.Provider
        value={{
          ...dsMultiple,
          ...dsCombobox,
          searchable,
          disabled,
          creating,
          onCreateItem,
        }}
      >
        <FloatingContextProvider placement={placement}>
          {children}
        </FloatingContextProvider>
      </MultiselectContext.Provider>
    </div>
  )
}

MultiselectCreatable.Button = MultiselectCreatableButton
MultiselectCreatable.SelectedItem = MultiselectSelectedItem
MultiselectCreatable.Items = MultiselectCreatableItems
MultiselectCreatable.ItemsEmpty = MultiselectItemsEmpty
MultiselectCreatable.Item = MultiselectItem
