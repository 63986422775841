import { LimitedIntlShape } from '../types.js'

const TagPluralRule =
  ({ $t }: LimitedIntlShape) =>
  chunks =>
    $t(
      {
        defaultMessage: '{tag_count, plural, =1 {tag} other {tags}}',
        id: 'RESOURCE.Tag.Pluralization',
      },
      {
        tag_count: chunks[0],
      },
    )

const TagCapitalPluralRule =
  ({ $t }: LimitedIntlShape) =>
  chunks =>
    $t(
      {
        defaultMessage: '{tag_count, plural, =1 {Tag} other {Tags}}',
        id: 'RESOURCE.Tag.Pluralization.Capital',
      },
      {
        tag_count: chunks[0],
      },
    )

export const TagResourceRules = (intl: LimitedIntlShape) => {
  const tagPlural = TagPluralRule(intl)
  const tagCapitalPlural = TagCapitalPluralRule(intl)
  return {
    'TAG.PLURAL': tagPlural,
    TAG: tagPlural([1]),
    TAGS: tagPlural([5]),
    'TAG_CC.PLURAL': tagCapitalPlural,
    TAG_CC: tagCapitalPlural([1]),
    TAGS_CC: tagCapitalPlural([5]),
  }
}
