import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { CollectionHeaderBlock as Component } from './Block.js'
import { CollectionHeaderProps } from './types.js'

export const RegisteredCollectionHeaderBlock = (
  _intl: I18nShape,
): RegisteredBlock<CollectionHeaderProps> => ({
  recommendedName: 'CollectionHeader',
  Component,
  config: {
    type: BlockType.Block,
    deprecated: true,

    defaultProps: {
      collectionId: '{{ collectionId }}',
    },
  },
})
