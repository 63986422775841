export const emailLogGQLFields = () => {
  return `
    createdAt
    id
    reason
    recipient
    status
    type
    updatedAt
  `
}
