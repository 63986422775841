import { CustomFieldSubtype } from '../../CustomField/utils.js'
import type { ValuePickerComponent } from '../types.js'
import { RelationMemberPicker } from './RelationMemberPicker.js'
import { RelationSpacePicker } from './RelationSpacePicker.js'
import { RelationTagPicker } from './RelationTagPicker.js'

export const RelationValuePicker: ValuePickerComponent = ({
  filter,
  spread,
  inline,
  className,
  onChange,
}) => {
  const { value = [], subType, extraProps } = filter
  const memberGroup = extraProps?.memberGroup
  const inlineSelectOptions = extraProps?.inlineSelectOptions
  const narrowedDownOptions = extraProps?.narrowedDownOptions
  const options =
    narrowedDownOptions?.length > 0 ? narrowedDownOptions : inlineSelectOptions

  if (!Array.isArray(value)) {
    return null
  }

  switch (subType) {
    case CustomFieldSubtype.MEMBER:
      return (
        <RelationMemberPicker
          value={value}
          options={options}
          memberGroup={memberGroup}
          spread={spread}
          inline={inline}
          className={className}
          onChange={onChange}
        />
      )
    case CustomFieldSubtype.SPACE:
      return (
        <RelationSpacePicker
          value={value}
          options={options}
          inline={inline}
          spread={spread}
          onChange={onChange}
          className={className}
        />
      )
    case CustomFieldSubtype.TAG:
      return (
        <RelationTagPicker
          value={value}
          options={options}
          inline={inline}
          spread={spread}
          onChange={onChange}
          className={className}
        />
      )
    default:
      return null
  }
}
