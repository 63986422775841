import { themeColorsGQLFields } from './themeColors.fields.js'

export type NewThemeFields = 'basic' | 'all'

// eslint-disable-next-line unused-imports/no-unused-vars
export const newThemesGQLFields = (fields: NewThemeFields) => `
  id
  name
  typography {
    key
    value
  }
  colors {
    ${themeColorsGQLFields('all')}
  }
`
