import { useState } from 'react'

import { Avatar, legacySizeMapper } from '@tribeplatform/react-ui-kit/Avatar'
import { FormControl } from '@tribeplatform/react-ui-kit/FormControl'
import type { FormControlSelectProps } from '@tribeplatform/react-ui-kit/FormControl'
import { Select } from '@tribeplatform/react-ui-kit/Select'

import { useSlate } from '../../../hooks/slate.hook.js'
import { CallbackExtraContext } from '../../../types/callback.types.js'
import { MarkdownBlock } from '../../Markdown/Block.js'
import { SelectBlockProps } from '../types.js'

type StandAloneSelectProps = SelectBlockProps & {
  name: string
  items: FormControlSelectProps['items']
}
export const StandAloneSelect = ({
  value,
  items,
  helperText: markdownHelperText,
  name,
  callbackId,
  disabled,
  avatarRounded = 'md',
  avatarSize = 'xs',
  ...rest
}: StandAloneSelectProps) => {
  const {
    extraContext: {
      slate: { callback },
    },
  } = useSlate<CallbackExtraContext>()

  const [loading, setLoading] = useState(false)

  const selectedItem = items.find(item => item.value === value)
  return (
    <FormControl.Select
      {...rest}
      name={name}
      helperText={
        typeof markdownHelperText === 'string' ? (
          <MarkdownBlock text={markdownHelperText} />
        ) : (
          markdownHelperText
        )
      }
      value={value}
      onChange={async value => {
        if (disabled !== true && callbackId) {
          setLoading(true)
          try {
            await callback(callbackId, { data: { value } })
          } catch {
            // We are catching the error here because we don't want to crash the app
            // We are already logging the error in the callback function
          }
          setLoading(false)
        }
      }}
      disabled={loading || disabled}
    >
      <Select.Button>
        <span className="flex items-center">
          {selectedItem?.avatar ? (
            <Avatar
              src={selectedItem.avatar}
              size={legacySizeMapper(avatarSize)}
              rounded={avatarRounded}
              name={selectedItem.text as string}
            />
          ) : null}

          <span className="ms-3 block truncate">{selectedItem?.text}</span>
        </span>
      </Select.Button>
      <Select.Items>
        {items.map(item => (
          <Select.Item key={item.value} value={item.value}>
            <div className="flex items-center">
              <Avatar
                src={item.avatar}
                size={legacySizeMapper(avatarSize)}
                rounded={avatarRounded}
                name={item.text as string}
              />
              <span className="ms-3 block truncate">{item?.text}</span>
            </div>
          </Select.Item>
        ))}
      </Select.Items>
    </FormControl.Select>
  )
}
