import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { FieldViewBlock as Component } from './Block.js'
import { FieldViewProps } from './types.js'

export const RegisteredFieldViewBlock = (
  _intl: I18nShape,
): RegisteredBlock<FieldViewProps> => ({
  recommendedName: 'FieldView',
  Component,
  config: {
    type: BlockType.Block,
    usable: false,

    displayName: 'Field View',
    defaultProps: {
      collapsible: false,
      highlighted: false,
      preview: false,
      showLabel: false,
    },
  },
})
