import { ModalProps } from './types.js'

export const labelledById = (id: string) => `modal-${id}-title`

export const animateTransition = (
  transition: ModalProps['transition'],
  open: boolean,
) => {
  switch (transition) {
    case 'scale':
      return open ? ['scale(0.95)', 'scale(1)'] : ['scale(1)', 'scale(0.95)']
    case 'fade':
      return open ? ['opacity(0)', 'opacity(1)'] : ['opacity(1)', 'opacity(0)']
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = transition
      return exhaustiveCheck
    }
  }
}
