import { createContext, ReactNode, useContext } from 'react'

export interface BleedContext {
  bleedClassName: string
}

const BleedContext = createContext<BleedContext>({ bleedClassName: '' })

export const BleedProvider = ({
  bleedClassName,
  children,
}: {
  bleedClassName?: string
  children: ReactNode
}) => {
  return (
    <BleedContext.Provider
      value={{
        bleedClassName,
      }}
    >
      {children}
    </BleedContext.Provider>
  )
}

export const useBleedContext = () => {
  return useContext(BleedContext)
}
