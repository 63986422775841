import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react'

import type { ColorSchemeMode } from '@tribeplatform/design-system/types'

export type ColorSchemeProps = {
  colorSchemeMode: ColorSchemeMode | null
  setColorSchemeMode: (colorSchemeMode: ColorSchemeMode) => void
}

export const ColorSchemeContext = createContext<ColorSchemeProps>({
  colorSchemeMode: null,
  setColorSchemeMode: () => undefined,
})

export const useColorSchemeContext = (): ColorSchemeProps => {
  return useContext(ColorSchemeContext)
}

export type ColorSchemeProviderProps = {
  colorSchemeMode?: ColorSchemeMode
  children: ReactNode
}

export const ColorSchemeProvider = ({
  children,
  colorSchemeMode: defaultColorSchemeMode,
}: ColorSchemeProviderProps) => {
  const [colorMode, setColorMode] = useState<ColorSchemeMode | null>(
    defaultColorSchemeMode,
  )

  useEffect(() => {
    setColorMode(defaultColorSchemeMode)
  }, [defaultColorSchemeMode])

  const context = useMemo(
    () => ({
      colorSchemeMode: colorMode,
      setColorSchemeMode: setColorMode,
    }),
    [colorMode],
  )

  return (
    <ColorSchemeContext.Provider value={context}>
      {children}
    </ColorSchemeContext.Provider>
  )
}
