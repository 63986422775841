import {
  AuthTokenFields,
  emailLogsGQLQuery,
  extraPropertiesGQLQuery,
  getLimitedTokenGQLQuery,
  getTokensGQLQuery,
  LimitedTokenFields,
  SearchEntityFields,
  searchGQLQuery,
  upsertExtraPropertyGQLMutation,
} from '../graphql/index.js'
import { redirectGQLQuery } from '../graphql/redirect/redirect.gql.js'
import { MutationFunctions, QueryFunctions } from '../types/fields.generated.js'
import {
  Action,
  AppToken,
  AuthToken,
  ExtraProperty,
  Mutation,
  MutationName,
  MutationOption,
  MutationUpsertExtraPropertyArgs,
  PaginatedEmailLog,
  PermissionContext,
  Query,
  QueryEmailLogsArgs,
  QueryExtraPropertiesArgs,
  QueryLimitedTokenArgs,
  QueryName,
  QueryOption,
  QueryRedirectArgs,
  QuerySearchArgs,
  QueryTokensArgs,
  Redirect,
  SearchResult,
} from '../types/index.js'
import { AppClient } from './clients/app.client.js'
import { AuthClient } from './clients/auth.client.js'
import { BadgeClient } from './clients/badge.client.js'
import { CollectionsClient } from './clients/collections.client.js'
import { DevClient } from './clients/dev.client.js'
import { InvitationsClient } from './clients/invitations.client.js'
import { MediaClient } from './clients/media.client.js'
import { MembersClient } from './clients/members.client.js'
import { MessagingClient } from './clients/messaging.client.js'
import { ModerationClient } from './clients/moderation.client.js'
import { NetworkClient } from './clients/network.client.js'
import { NotificationsClient } from './clients/notifications.client.js'
import { OAuthClient } from './clients/oAuth.client.js'
import { PostsClient } from './clients/posts.client.js'
import { PostTypeClient } from './clients/postType.client.js'
import { ReportClient } from './clients/report.client.js'
import { RolesClient } from './clients/roles.client.js'
import { SpacePostTypeClient } from './clients/space-post-type.client.js'
import { SpaceMembersClient } from './clients/spaceMembers.client.js'
import { SpaceMembershipClient } from './clients/spaceMembership.client.js'
import { SpaceRolesClient } from './clients/spaceRoles.client.js'
import { SpacesClient } from './clients/spaces.client.js'
import { TagsClient } from './clients/tags.client.js'
import { TemplatesClient } from './clients/templates.client.js'
import { ThemeClient } from './clients/theme.client.js'
import { GraphqlClient, TribeClientOptions } from './graphql.client.js'

export class TribeClient {
  app: AppClient

  auth: AuthClient

  badge: BadgeClient

  collections: CollectionsClient

  dev: DevClient

  invitations: InvitationsClient

  media: MediaClient

  members: MembersClient

  messaging: MessagingClient

  moderation: ModerationClient

  network: NetworkClient

  notifications: NotificationsClient

  oAuth: OAuthClient

  posts: PostsClient

  postType: PostTypeClient

  report: ReportClient

  roles: RolesClient

  spaceMembers: SpaceMembersClient

  spaceMembership: SpaceMembershipClient

  spaceRoles: SpaceRolesClient

  spaces: SpacesClient

  spacePostType: SpacePostTypeClient

  tags: TagsClient

  templates: TemplatesClient

  theme: ThemeClient

  private client: GraphqlClient

  constructor(options: TribeClientOptions) {
    this.client = new GraphqlClient(options)

    this.app = new AppClient(this.client)
    this.auth = new AuthClient(this.client)
    this.badge = new BadgeClient(this.client)
    this.collections = new CollectionsClient(this.client)
    this.dev = new DevClient(this.client)
    this.invitations = new InvitationsClient(this.client)
    this.media = new MediaClient(this.client)
    this.members = new MembersClient(this.client)
    this.messaging = new MessagingClient(this.client)
    this.moderation = new ModerationClient(this.client)
    this.network = new NetworkClient(this.client)
    this.notifications = new NotificationsClient(this.client)
    this.oAuth = new OAuthClient(this.client)
    this.posts = new PostsClient(this.client)
    this.postType = new PostTypeClient(this.client)
    this.report = new ReportClient(this.client)
    this.roles = new RolesClient(this.client)
    this.spaceMembers = new SpaceMembersClient(this.client)
    this.spaceMembership = new SpaceMembershipClient(this.client)
    this.spaceRoles = new SpaceRolesClient(this.client)
    this.spaces = new SpacesClient(this.client)
    this.tags = new TagsClient(this.client)
    this.templates = new TemplatesClient(this.client)
    this.theme = new ThemeClient(this.client)
    this.spacePostType = new SpacePostTypeClient(this.client)
  }

  setToken(accessToken: string): void {
    this.client.setToken(accessToken)
  }

  /**
   * Get the guest access token for a community.
   * @query tokens(): AuthToken!
   * @example
   * ```typescript
   *  t.client.getTokens({networkDomain: 'community.tribe.so'})
   * ```
   * @returns The AuthToken.
   */
  async getTokens(
    variables: QueryTokensArgs,
    fields: AuthTokenFields = 'default',
  ): Promise<AuthToken> {
    type QueryResult = { tokens: AuthToken }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getTokensGQLQuery(fields),
      variables,
    })
    return result.tokens
  }

  async getLimitedToken(
    variables: QueryLimitedTokenArgs,
    fields: LimitedTokenFields = 'basic',
    useBasicToken?: boolean,
  ): Promise<AppToken> {
    type QueryResult = { limitedToken: AppToken }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getLimitedTokenGQLQuery(fields),
      variables,
      useBasicToken,
    })
    return result.limitedToken
  }

  async search(
    variables: QuerySearchArgs,
    fields: SearchEntityFields = 'basic',
    accessToken?: string,
  ): Promise<SearchResult> {
    type QueryResult = { search: SearchResult }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: searchGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.search
  }

  async generateToken(options: {
    networkId: string
    memberId?: string
  }): Promise<string> {
    const { networkId, memberId } = options
    return this.getLimitedToken(
      {
        context: PermissionContext.NETWORK,
        networkId,
        entityId: networkId,
        impersonateMemberId: memberId,
      },
      'basic',
      true,
    ).then(x => x.accessToken)
  }

  async extraProperties(
    variables: QueryExtraPropertiesArgs,
    accessToken?: string,
  ): Promise<ExtraProperty[]> {
    type QueryResult = { extraProperties: ExtraProperty[] }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: extraPropertiesGQLQuery(),
      variables,
      customToken: accessToken,
    })
    return result.extraProperties
  }

  async upsertExtraProperties(
    variables: MutationUpsertExtraPropertyArgs,
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { upsertExtraProperty: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: upsertExtraPropertyGQLMutation(),
      variables,
      customToken: accessToken,
    })
    return result.upsertExtraProperty
  }

  async redirect(variables: QueryRedirectArgs): Promise<Redirect> {
    type QueryResult = {
      redirect: Redirect
    }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: redirectGQLQuery(),
      variables,
    })
    return result.redirect
  }

  async emailLogs(
    variables: QueryEmailLogsArgs,
    accessToken?: string,
  ): Promise<PaginatedEmailLog> {
    type QueryResult = { emailLogs: PaginatedEmailLog }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: emailLogsGQLQuery(),
      variables,
      customToken: accessToken,
    })
    return result.emailLogs
  }

  public get accessToken() {
    return this.client.accessToken
  }

  public get graphqlUrl() {
    return this.client.graphqlUrl
  }

  async query<Name extends QueryName>(
    options: QueryOption<Name>,
  ): Promise<Query[Name]> {
    const { name, args, accessToken } = options
    const variables = (args as any)?.variables
    const fields =
      typeof args === 'string' ? args : (args as any)?.fields ?? args
    const result = await this.client.authorizedRequest({
      query: (QueryFunctions[name] as any)(fields),
      variables,
      customToken: accessToken,
    })
    return result[name] as Query[Name]
  }

  async mutation<Name extends MutationName>(
    options: MutationOption<Name>,
  ): Promise<Mutation[Name]> {
    const { name, args, accessToken } = options
    const variables = (args as any)?.variables
    const fields =
      typeof args === 'string' ? args : (args as any)?.fields ?? args
    const result = await this.client.authorizedRequest({
      query: (MutationFunctions[name] as any)(fields),
      variables,
      customToken: accessToken,
    })
    return result[name] as Mutation[Name]
  }
}
