/* eslint-disable prefer-object-spread */
import { useCallback, useState } from 'react'

import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { IconButton } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { dayjs } from '../../common/lib/dayjs.js'
import { useI18n } from '../../i18n/index.js'
import { TimeFrameSelect } from '../components/TimeFrameSelect.js'
import { TimeFrame } from '../types/index.js'
import { getTimeFrames } from '../utils/timeFrame.js'

interface Props {
  createdAt: string
  parentTimeFrame?: TimeFrame
}

export const useTimeFrame = ({ createdAt, parentTimeFrame }: Props) => {
  const i18n = useI18n()
  const timeframes = getTimeFrames(i18n)
  const startOfTime = dayjs(createdAt).unix() * 1000
  const [timeFrame, setTimeFrame] = useState(() => {
    if (parentTimeFrame) {
      return parentTimeFrame
    }

    return timeframes[2]
  })

  const { data: network } = useNetwork({
    fields: {
      limits: 'all',
    },
  })
  const daysLimitation =
    network?.limits?.usageLimits?.analyticsDaysLimit ?? Infinity

  const TimeFrameSelectComponent = useCallback(() => {
    return (
      <div className="flex gap-2">
        <TimeFrameSelect
          startOfTime={startOfTime}
          timeFrame={timeFrame}
          setTimeFrame={setTimeFrame}
          daysLimitation={daysLimitation}
        />
        <IconButton
          size="xl"
          variant="secondaryNeutral"
          icon={<SvgIcon name="refresh" />}
          tooltip
          accessibilityLabel={i18n.$t({
            id: 'analytics.refresh',
            defaultMessage: 'Refresh',
          })}
          onClick={() => {
            const epsilon = Math.random() > 0.5 ? 1 : -1
            setTimeFrame({
              ...timeFrame,
              from: timeFrame.from + epsilon,
              to: timeFrame.to + epsilon,
            })
          }}
        />
      </div>
    )
  }, [daysLimitation, i18n, startOfTime, timeFrame])

  return {
    timeFrame,
    TimeFrameSelect: TimeFrameSelectComponent,
  }
}
