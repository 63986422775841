import { cva } from 'class-variance-authority'

export const dropdownItemsStyles = cva(
  [
    'p-2 rounded-popover shadow-popover bg-surface',
    'border border-line-subdued',
    'focus-visible:ring focus:outline-none ring-offset-0',
    'overflow-y-auto',
  ],
  {
    variants: {
      maxWidth: {
        md: 'min-w-[224px] sm:max-w-xs',
        full: 'w-full',
      },
      maxHeight: {
        sm: 'max-h-[236px]',
        full: '',
      },
    },
    defaultVariants: {
      maxWidth: 'md',
      maxHeight: 'full',
    },
  },
)

export const dropdownItemStyles = cva(
  [
    'group flex items-center px-2 py-2.5 w-full rounded-base',
    'text-label-md text-left',
    'bg-surface hover:bg-surface-hovered data-[headlessui-state=active]:bg-surface-pressed',
    'disabled:text-content-disabled disabled:cursor-default disabled:pointer-events-none',
    'cursor-pointer',
  ],
  {
    variants: {
      destructive: {
        true: 'text-content-on-negative',
        false: 'text-content',
      },
      truncate: {
        true: 'truncate',
      },
    },
    defaultVariants: {
      destructive: false,
    },
  },
)
