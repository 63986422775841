import { useMemo } from 'react'

import { PostFields } from '@tribeplatform/gql-client/graphql'
import { type UsePostProps, usePost } from '@tribeplatform/react-sdk/hooks'

import { useQuery } from '../../common/hooks/useQuery.js'
import {
  REACTIONS_OF_REPLY_LIMIT,
  REPLIES_OF_REPLY_LIMIT,
} from '../constants.js'

const replyFields: PostFields = {
  owner: {
    member: { profilePicture: 'basic', badges: 'all' },
  },
  embeds: 'basic',
  mentions: 'basic',
  attachments: 'basic',
  authMemberProps: 'all',
  reactions: { fields: 'all', variables: { limit: REACTIONS_OF_REPLY_LIMIT } },
}

/**
 * Fetches the parent reply of the highlighted reply.
 * If the highlighted reply is directly a reply to the post,
 * the highlighted reply itself is returned.
 * Case 1:
 * POST
 *  Reply1
 *  Reply2
 *     Reply3 - (Highlighted)
 *
 * Case 2:
 * POST
 *  Reply1
 *  Reply2 - (Highlighted)
 *   Reply3
 *
 * In both cases, the returned reply will be Reply2. So that
 * it can be moved to the top of the list.
 *
 * @param post - The post that contains the highlighted reply.
 */
export const useHighlightedReply = (postId: string) => {
  const { highlight: highlightedReplyId } = useQuery<{
    highlight?: string
  }>()

  const queryOptions: UsePostProps = useMemo(
    () => ({
      variables: { id: highlightedReplyId },
      fields: {
        ...replyFields,
        repliedTo: {
          ...replyFields,
        },
        replies: {
          variables: { limit: REPLIES_OF_REPLY_LIMIT },
          fields: {
            ...replyFields,
          },
        },
      },
      useQueryOptions: {
        enabled: !!highlightedReplyId,
      },
    }),
    [highlightedReplyId],
  )

  const {
    data: reply,
    isFetching,
    isRefetching,
    isFetched,
  } = usePost(queryOptions)

  const highlightedRootReply =
    reply?.repliedToId === postId ? reply : reply?.repliedTo

  // We want to know the first time query is loading only
  // otherwise it can cause showing loading indicator when editing.
  const isLoading = isFetching && !isRefetching && isFetched

  return {
    highlightedRootReply,
    isLoading,
  }
}
