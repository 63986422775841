import { useMemo, useState } from 'react'

import { Tag } from '@tribeplatform/gql-client/types'
import { useCreateTag, useSpaceTags } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { MultiselectCreatable } from '@tribeplatform/react-ui-kit/Multiselect'

import { useDebounce } from '../common/hooks/useDebounce.js'
import { T } from '../i18n/components/T.js'

export type TagPickerCreatableProps = {
  value: Tag[]
  onChange: (newTags: Tag[]) => void
  placeholder?: string
  disabled?: boolean
}

const tagTitleMap: Record<string, Tag> = {}

export const TagPickerCreatable = (props: TagPickerCreatableProps) => {
  const {
    value: valueOriginal,
    onChange: onChangeOriginal,
    placeholder,
    disabled,
  } = props

  const [search, setSearch] = useState('')
  const debouncedQuery = useDebounce(search, 300)

  const { data, isFetching, refetch } = useSpaceTags({
    variables: {
      query: debouncedQuery,
      limit: 20,
    },
  })

  const tags = useMemo(
    () => simplifyPaginatedResult<Tag>(data)?.nodes || [],
    [data],
  )

  const { mutateAsync: createTag, isLoading: isCreating } = useCreateTag({
    fields: 'basic',
  })

  const options = useMemo(() => tags?.map(t => t.title) || [], [tags])
  const value = useMemo(
    () => valueOriginal.map(tag => tag?.title),
    [valueOriginal],
  )

  useMemo(() => {
    tags.forEach(t => {
      tagTitleMap[t.title] = t
    })
    valueOriginal?.forEach(t => {
      tagTitleMap[t.title] = t
    })
  }, [tags, valueOriginal])

  const onChange = newTags => {
    onChangeOriginal(
      newTags.map(title => tagTitleMap[title]).filter(it => !!it),
    )
  }

  const onCreateItem = async inputValue => {
    createTag({
      input: {
        title: inputValue,
      },
    }).then(result => {
      onChangeOriginal([...valueOriginal, result])
      refetch()
    })
  }

  return (
    <MultiselectCreatable
      options={options}
      value={value}
      onChange={onChange}
      searchable
      onInputChange={setSearch}
      onCreateItem={onCreateItem}
      createItemPosition="first"
      disabled={disabled}
    >
      <MultiselectCreatable.Button
        placeholder={placeholder}
        leadingIcon={
          isFetching ||
          (isCreating && (
            <SvgIcon
              className="self-center animate-spin"
              size="lg"
              name="spinner"
            />
          ))
        }
      />
      <MultiselectCreatable.Items>
        {({ creating, inputValue }) => {
          return (
            <>
              {creating && search?.length > 0 && !isFetching && (
                <MultiselectCreatable.Item value={search} index={0}>
                  <span>
                    <T id="Generics.Create" defaultMessage="Create" />
                  </span>{' '}
                  <span className="font-bold">{search}</span>
                </MultiselectCreatable.Item>
              )}
              {options.length > 0 &&
                options.map((tag, index) => (
                  <MultiselectCreatable.Item
                    key={tag}
                    value={tag}
                    index={index + (creating ? 1 : 0)}
                  >
                    <span>{tag}</span>
                  </MultiselectCreatable.Item>
                ))}
              {isFetching ? (
                <MultiselectCreatable.Item
                  disabled
                  key=":_search"
                  value=":_search"
                  index={creating ? 1 : 0}
                >
                  <span>
                    <T
                      id="EntityPicker.TagPickerCreatable.SearchingForText"
                      defaultMessage="Searching for {search_term}..."
                      values={{ search_term: inputValue }}
                    />
                  </span>
                </MultiselectCreatable.Item>
              ) : (
                options.length === 0 && (
                  <MultiselectCreatable.Item
                    disabled
                    key=":_none"
                    value=":_none"
                    index={creating ? 1 : 0}
                  >
                    <span>
                      <T
                        id="EntityPicker.TagPickerCreatable.NoResultsForTerm"
                        defaultMessage="No matching results for {search_term}..."
                        values={{ search_term: inputValue }}
                      />
                    </span>
                  </MultiselectCreatable.Item>
                )
              )}
            </>
          )
        }}
      </MultiselectCreatable.Items>
    </MultiselectCreatable>
  )
}
