import { createPortal } from 'react-dom'

import { useDocument } from '../hooks/useDocument.js'
import { useUniqueId } from '../utils/unique-id.js'
import { getPortalRootElement } from './utils.js'

export interface PortalProps {
  children?: React.ReactNode
  idPrefix?: string
}

/**
 * Portal is used to transport any component or element to the end of document.body and renders a React tree into it.
 *
 * Useful for rendering a natural React element hierarchy with a different DOM hierarchy
 * to prevent parent styles from clipping or hiding content (for popovers, dropdowns, and modals).
 *
 */
export const Portal = ({ children, idPrefix = '' }: PortalProps) => {
  const { document } = useDocument()

  const uniqueId = useUniqueId(idPrefix || 'portal')

  if (!document?.body) {
    return null
  }

  return createPortal(
    <div data-portal-id={uniqueId} className="isolate">
      {children}
    </div>,
    getPortalRootElement(document) ?? document.body,
  )
}
