import {
  PostTypeFields,
  postTypeGQLFields,
} from '../post-type/postType.fields.js'
import {
  SpaceMemberFields,
  spaceMemberGQLFields,
} from '../space/spaceMember.fields.js'
import {
  ActionPermissionsFields,
  actionPermissionGQLFields,
} from './actionPermissions.fields.js'

export type SpaceAuthMemberPropsFields =
  | 'basic'
  | 'all'
  | CustomSpaceAuthMemberPropsFields

export interface CustomSpaceAuthMemberPropsFields {
  permissions?: ActionPermissionsFields
  availablePostTypes?: PostTypeFields
  spaceMember?: SpaceMemberFields
}

const BASIC_SPACE_AUTH_MEMBER_PROPS_FIELDS: CustomSpaceAuthMemberPropsFields =
  {}
const ALL_SPACE_AUTH_MEMBER_PROPS_FIELDS: CustomSpaceAuthMemberPropsFields = {
  permissions: 'basic',
  availablePostTypes: 'basic',
}

export function spaceAuthMemberPropsGQLFields(
  fields: SpaceAuthMemberPropsFields,
): string {
  if (fields === 'basic') {
    fields = BASIC_SPACE_AUTH_MEMBER_PROPS_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_SPACE_AUTH_MEMBER_PROPS_FIELDS
  }
  return `
    context
    lastReadAt
    membershipStatus
    scopes
    unreadPostsCount
    subscribed
    ${
      fields.permissions
        ? `
      permissions {
        ${actionPermissionGQLFields(fields.permissions)}
      }
    `
        : ``
    }
    ${
      fields.availablePostTypes
        ? `
      availablePostTypes {
        ${postTypeGQLFields(fields.availablePostTypes)}
      }`
        : ''
    }
    ${
      fields.spaceMember
        ? `
      spaceMember {
        ${spaceMemberGQLFields(fields.spaceMember)}
      }`
        : ''
    }
  `
}
