import { lazy } from 'react'

import { SpaceType } from '@tribeplatform/gql-client/types'
import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SpaceAboutProps } from '@tribeplatform/react-components/Space'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { SpaceAboutBlock as Component } from './Block.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.SpaceAboutBlockSettings })),
)

export const RegisteredSpaceAboutBlock = (
  intl: I18nShape,
): RegisteredBlock<SpaceAboutProps> => {
  const { $t } = intl

  return {
    recommendedName: 'SpaceAbout',
    Component,
    config: {
      type: BlockType.Block,
      usable: true,
      availabilityConditions: {
        spaceType: SpaceType.Group,
      },

      displayName: $t({
        id: 'Generics.About',
        defaultMessage: 'About',
      }),
      Settings,
      defaultProps: {
        title: $t({
          id: 'Generics.About',
          defaultMessage: 'About',
        }),
        showMembersCount: true,
        showPostsCount: true,
        showCreationDate: true,
      },
      Icon: <SvgIcon name="status-info" />,
    },
  }
}
