import { useEffect } from 'react'

import { useRouter } from '@tribeplatform/react-sdk'
import { useSlate } from '@tribeplatform/react-slate-kit/hooks'
import { Scrollable } from '@tribeplatform/react-ui-kit/Scrollable'
import { SectionHeader } from '@tribeplatform/react-ui-kit/SectionHeader'

import { useMultiViewBlockQueryParam } from '../hooks/useMultiViewBlockQueryParam.js'
import { View } from '../types.js'
import { MultiViewBlockTab } from './MultiViewBlockTab.js'

interface RenderProps<T> {
  view: T
  preview: boolean
}
interface MultiViewBlockProps<T> {
  activeViewIndex: number
  title: string
  description: string
  views: T[]
  linkUrl: string
  linkText: string
  children: (props: RenderProps<T>) => JSX.Element
}
export function MultiViewBlock<T extends View>({
  activeViewIndex,
  title,
  description,
  views,
  linkUrl,
  linkText,
  children,
  ...rest
}: MultiViewBlockProps<T>) {
  const { Link } = useRouter()
  const { mode } = useSlate()
  const preview = mode !== 'live'

  const [selectedIndex, setSelectedIndex] = useMultiViewBlockQueryParam({
    views,
  })

  useEffect(() => {
    if (preview && activeViewIndex !== undefined) {
      setSelectedIndex(activeViewIndex)
    }
  }, [preview, activeViewIndex, setSelectedIndex])

  return (
    <div {...rest}>
      {(title || description) && (
        <div className="flex justify-between items-end">
          <SectionHeader
            className="flex-1 min-w-0 break-words pb-5"
            title={title ?? ''}
            description={description}
          />
          {views.length <= 1 && !!linkText && (
            <Link
              href={linkUrl ?? ''}
              variant="accent"
              className="pb-5 mx-2 sm:mx-0 truncate"
            >
              {linkText}
            </Link>
          )}
        </div>
      )}
      <>
        {views.length > 1 && (
          <>
            <div className="flex justify-between items-center gap-x-2">
              <Scrollable className="min-w-0 mx-2 sm:mx-0">
                <div className="flex space-x-2 p-1">
                  {views.map((view, index) => (
                    <MultiViewBlockTab
                      key={view.id}
                      pressed={selectedIndex === index}
                      onClick={() => setSelectedIndex(index)}
                    >
                      {view.name}
                    </MultiViewBlockTab>
                  ))}
                </div>
              </Scrollable>
              {!!linkText && (
                <Link
                  href={linkUrl ?? ''}
                  variant="accent"
                  className="mx-2 sm:mx-0 shrink-0 truncate max-w-[10rem]"
                  title={linkText}
                >
                  {linkText}
                </Link>
              )}
            </div>
            <div className="mt-3">
              {children({
                view: views?.[selectedIndex],
                preview,
              })}
            </div>
          </>
        )}
        {views.length === 1 && (
          <>
            {!title && !description && !!linkText && (
              <div className="flex justify-end pb-5">
                <Link
                  href={linkUrl ?? ''}
                  variant="accent"
                  className="mx-2 sm:mx-0 truncate"
                >
                  {linkText}
                </Link>
              </div>
            )}
            {children({
              view: views[0],
              preview,
            })}
          </>
        )}
      </>
    </div>
  )
}
