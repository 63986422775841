import { useEffect, useState } from 'react'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import {
  PinnedInto,
  Post,
  PostListOrderByEnum,
  QueryPostsArgs,
  QueryTagPostsArgs,
} from '@tribeplatform/gql-client/types'
import {
  usePinnedPosts,
  usePosts,
  useSpace,
} from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { EmptyState } from '@tribeplatform/react-ui-kit/EmptyState'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { useI18n } from '../i18n/providers/I18nProvider.js'
import { getUserSettings, setUserSetting } from '../utils/userSettings/index.js'
import { EmptyState as PostsEmptyState } from './EmptyState.js'
import { GenericPostList, GenericPostListProps } from './GenericPostList.js'
import { PostListFilter, PostListFilterLoading } from './PostListFilter.js'

export type SpacePostListProps = {
  spaceId: string
  tagId?: string
  tagSlug?: string
  orderBy?: PostListOrderByEnum
  view?: GenericPostListProps['view']
}

export const SpacePostList = ({
  spaceId,
  tagId,
  tagSlug,
  view,
  orderBy: defaultOrderBy = PostListOrderByEnum.publishedAt,
}: SpacePostListProps) => {
  const { $t } = useI18n()
  const [orderBy, setOrderBy] = useState<PostListOrderByEnum>()

  useEffect(() => {
    if (!orderBy) {
      const userSettingOrderBy = getUserSettings().postListOrderBy?.[spaceId]
      setOrderBy(userSettingOrderBy || defaultOrderBy)
    }
  }, [defaultOrderBy, orderBy, spaceId])

  useEffect(() => {
    if (orderBy) {
      setUserSetting('postListOrderBy', {
        [spaceId]: orderBy,
      })
    }
  }, [spaceId, orderBy])

  const { data: space } = useSpace({
    variables: { id: spaceId },
  })
  const [canGetPosts] = hasScopesPermission(space, ['posts'])

  const shouldFilterByTag =
    (tagId && tagId.length > 0) || (tagSlug && tagSlug.length > 0)

  let variables: QueryPostsArgs | QueryTagPostsArgs

  if (shouldFilterByTag) {
    // TODO: This is a temporary solution until tags become filterable by posts query
    variables = {
      spaceId,
      tagId,
      tagSlug,
      limit: 5,
      orderBy,
    } as QueryTagPostsArgs
  } else {
    variables = {
      limit: 5,
      spaceIds: [spaceId],
      excludePins: true,
      orderBy,
    } as QueryPostsArgs
  }

  const {
    data,
    fetchNextPage = false,
    hasNextPage,
    isLoading,
    isFetched,
    isFetchingNextPage,
  } = usePosts({
    variables,
    fields: {
      tags: 'basic',
      owner: {
        member: { profilePicture: 'basic', badges: 'all' },
      },
      embeds: 'basic',
      mentions: 'basic',
      space: {
        authMemberProps: 'all',
        image: 'basic',
      },
      attachments: 'basic',
      authMemberProps: 'all',
      reactions: { fields: 'all', variables: { limit: 10 } },
      replies: {
        fields: {
          authMemberProps: 'all',
          embeds: 'basic',
          mentions: 'basic',
          attachments: 'basic',
          owner: {
            member: { profilePicture: 'basic', badges: 'all' },
          },
          reactions: { fields: 'all', variables: { limit: 10 } },
        },
        variables: {
          limit: 2,
          reverse: true,
        },
      },
    },
    useInfiniteQueryOptions: {
      refetchOnMount: 'always',
      enabled: canGetPosts && !!orderBy,
    },
  })

  const { data: pinnedPostsData, isInitialLoading: isPinnedPostLoading } =
    usePinnedPosts({
      variables: { spaceId },
      useQueryOptions: {
        enabled: canGetPosts,
      },
    })
  let pinnedPosts = pinnedPostsData

  if (!canGetPosts && space?.authMemberProps?.permissions) {
    return (
      <Card>
        <Card.Content>
          <div className="my-16">
            <EmptyState
              title={$t({
                id: 'Alerts.PrivateSpace',
                defaultMessage: 'This {SPACE} is private',
              })}
              icon={<SvgIcon name="lock" />}
              description={$t({
                id: 'Alerts.PrivateSpace.Description',
                defaultMessage:
                  'You need to be invited to this {SPACE} to access its posts',
              })}
            />
          </div>
        </Card.Content>
      </Card>
    )
  }

  let { nodes: posts } = simplifyPaginatedResult(data) as { nodes: Post[] }
  if (shouldFilterByTag) {
    pinnedPosts = pinnedPosts?.filter(post =>
      post.tags?.some(tag => tag.id === tagId || tag.slug === tagSlug),
    )
    posts = posts.filter(post => !post.pinnedInto?.includes(PinnedInto.space))
  }

  if (
    space?.authMemberProps?.permissions &&
    !isLoading &&
    !isPinnedPostLoading &&
    !posts?.length &&
    !pinnedPosts?.length
  ) {
    return (
      <>
        <PostsEmptyState />
      </>
    )
  }

  return (
    <div>
      {orderBy ? (
        <PostListFilter orderBy={orderBy} setOrderBy={setOrderBy} />
      ) : (
        <PostListFilterLoading />
      )}
      <GenericPostList
        pinnedPosts={pinnedPosts}
        posts={posts}
        context="space"
        activeTagId={tagId}
        activeTagSlug={tagSlug}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isLoading={
          isLoading ||
          isPinnedPostLoading ||
          !space?.authMemberProps?.permissions
        }
        view={view}
        isFetchingNextPage={isFetchingNextPage}
        isFetched={isFetched}
      />
    </div>
  )
}
