import { useMemo, useState } from 'react'

import { clsx } from 'clsx'

import {
  Post,
  PostListFilterByEnum,
  PostListFilterByOperator,
} from '@tribeplatform/gql-client/types'
import { usePosts } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Media } from '@tribeplatform/react-ui-kit/Media'
import { Multiselect } from '@tribeplatform/react-ui-kit/Multiselect'
import { SearchableSelect } from '@tribeplatform/react-ui-kit/SearchableSelect'

import { useDebounce } from '../common/hooks/useDebounce.js'
import { dayjs } from '../common/lib/dayjs.js'
import { T } from '../i18n/components/T.js'
import { SpaceImage } from '../Space/SpaceImage.js'
import { pickIfExists } from './arrays.utils.js'

type PostPickerSingleProps = {
  value?: Post
  onChange?: (values: Post) => void
}
type PostPickerMultipleProps = {
  value?: Post[]
  onChange?: (values: Post[]) => void
}

export type PostPickerProps = (
  | PostPickerSingleProps
  | PostPickerMultipleProps
) & {
  placeholder?: string
  spaceIds?: string[]
  postTypeIds?: string[]
  options?: Post[]
  /**
   * Allows to pick multiple values at once
   * @default false
   */
  multiple?: boolean
}

const isMultiple = (props: PostPickerProps): props is PostPickerMultipleProps =>
  props.multiple === true

export const PostPicker = (props: PostPickerProps) => {
  const { placeholder, spaceIds, postTypeIds, options } = props

  const [search, setSearch] = useState('')
  const debouncedQuery = useDebounce(search, 300)

  let filterBy
  if (debouncedQuery) {
    filterBy = [
      {
        keyString: 'fields.title',
        // key is not used when keyString is present
        key: PostListFilterByEnum.boolean1,
        operator: PostListFilterByOperator.contains,
        value: JSON.stringify(debouncedQuery),
      },
    ]
  }
  const { data, isInitialLoading } = usePosts({
    fields: {
      createdBy: {
        member: 'basic',
      },
      space: {
        image: 'basic',
      },
    },
    variables: {
      filterBy,
      limit: 20,
      spaceIds,
      postTypeIds,
    },
    useInfiniteQueryOptions: {
      enabled: !options,
    },
  })

  const posts = useMemo(
    () => options || simplifyPaginatedResult<Post>(data)?.nodes || [],
    [data, options],
  )

  if (isMultiple(props)) {
    const { value, onChange } = props
    const suggestedPosts: Post[] = pickIfExists(posts, value, 'id')

    return (
      <Multiselect
        value={value || []}
        options={suggestedPosts}
        onChange={onChange}
        searchable
        onInputChange={setSearch}
      >
        <Multiselect.Button placeholder={placeholder}>
          {value?.map((post, index) => (
            <Multiselect.SelectedItem
              className="px-1 pe-2"
              key={post.id}
              value={post}
              index={index}
            >
              <div className="flex items-center post-x-2">
                <span className="truncate">{post?.title}</span>
              </div>
            </Multiselect.SelectedItem>
          ))}
        </Multiselect.Button>
        <Multiselect.Items>
          {suggestedPosts.map((post, index) => (
            <Multiselect.Item key={post.id} value={post} index={index}>
              {({ active }) => <Item post={post} active={active} />}
            </Multiselect.Item>
          ))}
          {isInitialLoading && (
            <Multiselect.ItemsEmpty>
              <T defaultMessage="Loading..." id="Generics.LoadingDotDotDot" />
            </Multiselect.ItemsEmpty>
          )}
          {!isInitialLoading && search && suggestedPosts.length === 0 && (
            <Multiselect.ItemsEmpty>
              <T defaultMessage="No results" id="Generics.NoResults" />
            </Multiselect.ItemsEmpty>
          )}
        </Multiselect.Items>
      </Multiselect>
    )
  }

  const { value, onChange } = props
  const suggestedPosts: Post[] = value
    ? pickIfExists(posts, [value], 'id')
    : posts

  return (
    <SearchableSelect
      value={value}
      options={suggestedPosts}
      onChange={onChange}
      onInputChange={setSearch}
    >
      <SearchableSelect.Button placeholder={placeholder}>
        {value && (
          <div className="flex items-center post-x-2">
            <span className="truncate">{value?.title}</span>
          </div>
        )}
      </SearchableSelect.Button>
      <SearchableSelect.Items>
        {suggestedPosts.map(post => (
          <SearchableSelect.Item key={post.id} value={post.id}>
            {({ active }) => <Item post={post} active={active} />}
          </SearchableSelect.Item>
        ))}
        {isInitialLoading && (
          <SearchableSelect.ItemsEmpty>
            <T defaultMessage="Loading..." id="Generics.LoadingDotDotDot" />
          </SearchableSelect.ItemsEmpty>
        )}
        {!isInitialLoading && search && suggestedPosts.length === 0 && (
          <SearchableSelect.ItemsEmpty>
            <T defaultMessage="No results" id="Generics.NoResults" />
          </SearchableSelect.ItemsEmpty>
        )}
      </SearchableSelect.Items>
    </SearchableSelect>
  )
}

const Item = ({ post, active }: { post: Post; active?: boolean }) => {
  const authorName = post.createdBy?.member?.name || 'Unknown'
  const time = dayjs(post.createdAt).fromNow()
  const spaceName = post.space?.name || 'Untitled'

  return (
    <Media icon={<SpaceImage size="2x" space={post.space} />} align="center">
      <div
        className={clsx(
          'truncate',
          active ? 'text-content-on-primary' : 'text-content',
        )}
      >
        {post.title}
      </div>
      <div
        className={clsx(
          'truncate',
          active ? 'text-content-on-primary' : 'text-content-subdued',
        )}
      >
        <T
          defaultMessage="Posted on {space} {time} by {author}"
          description="The subtitle that appears under each search result"
          id="SearchResult.Subtitle"
          values={{
            space: (
              <span
                className={clsx(
                  active ? 'text-content-on-primary' : 'text-content',
                )}
              >
                {spaceName}
              </span>
            ),
            author: (
              <span
                className={clsx(
                  active ? 'text-content-on-primary' : 'text-content',
                )}
              >
                {authorName}
              </span>
            ),
            time,
          }}
        />
      </div>
    </Media>
  )
}
