import { Link, useNavigate } from 'react-router-dom'

import { SystemSpaceSlug } from '@bettermode/common/space'
import { Member } from '@tribeplatform/gql-client/types'
import { MemberAvatar } from '@tribeplatform/react-components/common/components/member'
import {
  useAuthToken,
  useMembers,
  useSpaceUtil,
} from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Button } from '@tribeplatform/react-ui-kit/Button'

import { EmptyState } from './EmptyState.js'
import { LoadingState } from './LoadingState.js'
import { MembersBlockProps } from './types.js'

export const NetworkMemberList = ({
  limit,
  sort,
  showTagline,
  showViewAll,
  filterBy,
}: Partial<MembersBlockProps>) => {
  const {
    data: { network },
  } = useAuthToken()

  let orderBy: string
  let reverse = false

  switch (sort) {
    case 'oldest-first':
      orderBy = 'createdAt'
      break
    case 'alphabetical':
      orderBy = 'name'
      break
    // case 'number-of-posts':
    // TODO implement with TQL?
    case 'newest-first':
    default:
      orderBy = 'createdAt'
      reverse = true
      break
  }

  let roleId = ''
  const roleFilter = filterBy?.find(filter => filter?.key === 'role.name')
  if (roleFilter) {
    try {
      const roleName = JSON.parse(roleFilter.value)
      const role = network?.roles?.find(role => role.name === roleName)
      if (role?.id) {
        roleId = role?.id
      }
    } catch (err) {
      logger.error('Could not parse role of the member filter')
    }
    filterBy = filterBy?.filter(filter => filter?.key !== 'role.name')
  }

  const { data, isInitialLoading, isFetched } = useMembers({
    fields: {
      profilePicture: 'basic',
      role: 'basic',
    },
    variables: {
      limit,
      orderBy,
      reverse,
      roleIds: roleId ? [roleId] : undefined,
      filterBy: filterBy || [],
    },
  })
  const { nodes: members } = simplifyPaginatedResult<Member>(data)

  const navigate = useNavigate()
  const { generatePath } = useSpaceUtil()

  if (!isFetched || isInitialLoading) {
    return <LoadingState count={limit} />
  }

  if (!members?.length) {
    return <EmptyState />
  }

  return (
    <div className="space-y-5">
      <div className="grid grid-cols-1 gap-1 -ms-2 -me-2">
        {members.map(member => {
          if (!member?.id) {
            return null
          }
          return (
            <Link
              key={member.id}
              to={member?.relativeUrl}
              className="group flex space-s-3 items-center p-2 rounded-base hover:bg-surface-hovered"
            >
              <MemberAvatar size="2.5x" member={member} />
              <div className="flex-grow max-w-full overflow-hidden">
                <div className="truncate text-content flex items-center">
                  {member?.name}
                </div>
                {showTagline && member?.tagline && (
                  <div className="text-md text-content-subdued truncate">
                    {member?.tagline}
                  </div>
                )}
              </div>
            </Link>
          )
        })}
      </div>
      {false && showViewAll && (
        <Button
          fullWidth
          variant="secondaryNeutral"
          onClick={() => {
            navigate(generatePath(SystemSpaceSlug.MEMBERS))
          }}
        >
          View all
        </Button>
      )}
    </div>
  )
}
