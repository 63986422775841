import { ReactNode } from 'react'

import {
  Tooltip as UITooltip,
  TooltipDelay,
} from '@tribeplatform/react-ui-kit/Tooltip'

const TOOLTIP_HOVER_DELAY_MS: TooltipDelay = {
  open: 500,
  close: 0,
}

interface Props {
  children?: ReactNode
  tooltip?: ReactNode
}

export const Tooltip = ({ children, tooltip }: Props) => {
  return (
    <UITooltip delay={TOOLTIP_HOVER_DELAY_MS}>
      <UITooltip.Trigger>{children}</UITooltip.Trigger>
      <UITooltip.Panel>{tooltip}</UITooltip.Panel>
    </UITooltip>
  )
}
