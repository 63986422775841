import { I18nShape } from '@tribeplatform/react-components/i18n'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { staticFieldsFactory as postsListStaticFieldsFactory } from '../PostsBlock/config.js'
import { SinglePostBlockProps } from './types.js'
import { getStaticFieldById } from './utils/utils.js'

export const staticFieldsFactory: (intl: I18nShape) => StaticField[] = intl => {
  const { $t } = intl

  const staticFields: StaticField[] = [
    ...postsListStaticFieldsFactory(intl),
    {
      id: 'pinnedReply',
      label: $t({
        defaultMessage: 'Pinned reply',
        id: 'Generics.PinnedReply',
      }),
      enabled: false,
      locked: false,
      styles: ['card'],
      settings: {},
      isStatic: true,
      icon: <SvgIcon name="status-success" />,
      default:
        '<p>Commodo <b>magna esse</b> ea in in. Minim <i>occaecat</i> aute <a>veniam pariatur</a> laboris veniam.</p>',
    },
  ]

  const singlePostStaticFields = [
    { ...getStaticFieldById(staticFields, 'postInfoBar'), enabled: true },
    { ...getStaticFieldById(staticFields, 'cardMemberInfo'), enabled: true },
    getStaticFieldById(staticFields, 'cardBanner'),
    { ...getStaticFieldById(staticFields, 'galleryPostTitle'), enabled: true },
    { ...getStaticFieldById(staticFields, 'cardPostContent'), enabled: true },
    getStaticFieldById(staticFields, 'pinnedReply'),
    { ...getStaticFieldById(staticFields, 'customFields'), enabled: true },
    { ...getStaticFieldById(staticFields, 'tags'), enabled: true },
    { ...getStaticFieldById(staticFields, 'cardPostInfoBar'), enabled: true },
    { ...getStaticFieldById(staticFields, 'replyList'), enabled: true },
    { ...getStaticFieldById(staticFields, 'actionBar'), enabled: true },
    getStaticFieldById(staticFields, 'publishDate'),
  ]

  return singlePostStaticFields
}

export const SinglePostConfig: (intl: I18nShape) => {
  defaultProps: SinglePostBlockProps
  displayName: string
  removable: boolean
  Icon: JSX.Element
} = ({ $t }) => ({
  defaultProps: {
    postId: '',
    fields: [],
  },
  displayName: $t({
    defaultMessage: 'Single post',
    id: 'Blocks.SinglePost.DisplayName',
  }),
  removable: true,
  Icon: <SvgIcon name="post" />,
})
