import { ReactElement } from 'react'

import {
  FormControl,
  FormControlProps,
} from '@tribeplatform/react-ui-kit/FormControl'

import { MemberPicker, MemberPickerProps } from './MemberPicker.js'
import { PostTypeIdPicker, PostTypeIdPickerProps } from './PostTypeIdPicker.js'
import { PostTypePicker, PostTypePickerProps } from './PostTypePicker.js'
import { SpaceIdPicker, SpaceIdPickerProps } from './SpaceIdPicker.js'
import { SpacePicker, SpacePickerProps } from './SpacePicker.js'
import { TagPicker, TagPickerProps } from './TagPicker.js'

export const PickerFormControl = ({ children }) => (
  <div className="space-y-1">{children}</div>
)

export type PickerFormControlSpaceProps = FormControlProps & SpacePickerProps
export type PickerFormControlSpaceIdProps = FormControlProps &
  SpaceIdPickerProps
export type PickerFormControlMemberProps = FormControlProps & MemberPickerProps
export type PickerFormControlTagProps = FormControlProps & TagPickerProps
export type PickerFormControlPostTypeProps = FormControlProps &
  PostTypePickerProps
export type PickerFormControlPostTypeIdProps = FormControlProps &
  PostTypeIdPickerProps

export type PickerProps =
  | SpacePickerProps
  | SpaceIdPickerProps
  | MemberPickerProps
  | TagPickerProps
  | PostTypePickerProps
  | PostTypeIdPickerProps

export function PickerFormControlGenerator(
  Picker: (props: PickerProps) => ReactElement,
) {
  return (props: FormControlProps & PickerProps) => {
    const { label, helperText, error, invalid, ...rest } = props
    return (
      <div>
        {label ? <FormControl.Label>{label}</FormControl.Label> : null}
        <div className="mt-1 space-y-2">
          <Picker {...rest} />
          <FormControl.Meta
            {...{
              helperText,
              error,
              invalid,
            }}
          />
        </div>
      </div>
    )
  }
}

PickerFormControl.SpacePicker = PickerFormControlGenerator(SpacePicker)
PickerFormControl.SpaceIdPicker = PickerFormControlGenerator(SpaceIdPicker)
PickerFormControl.MemberPicker = PickerFormControlGenerator(MemberPicker)
PickerFormControl.TagPicker = PickerFormControlGenerator(TagPicker)
PickerFormControl.PostTypePicker = PickerFormControlGenerator(PostTypePicker)
PickerFormControl.PostTypeIdPicker =
  PickerFormControlGenerator(PostTypeIdPicker)
