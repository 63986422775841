import {
  assignBadgeGQLMutation,
  BadgeFields,
  createBadgeGQLMutation,
  deleteBadgeGQLMutation,
  revokeBadgeGQLMutation,
  updateBadgeGQLMutation,
} from '../../graphql/index.js'
import {
  Action,
  Badge,
  MutationAssignBadgeArgs,
  MutationCreateBadgeArgs,
  MutationDeleteBadgeArgs,
  MutationRevokeBadgeArgs,
  MutationUpdateBadgeArgs,
} from '../../types/index.js'
import { GraphqlClient } from '../graphql.client.js'

export class BadgeClient {
  private client: GraphqlClient

  constructor(client: GraphqlClient) {
    this.client = client
  }

  async create(
    variables: MutationCreateBadgeArgs,
    fields: BadgeFields = 'basic',
    accessToken?: string,
  ): Promise<Badge> {
    type QueryResult = { createBadge: Badge }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: createBadgeGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.createBadge
  }

  async update(
    variables: MutationUpdateBadgeArgs,
    fields: BadgeFields = 'basic',
    accessToken?: string,
  ): Promise<Badge> {
    type QueryResult = { updateBadge: Badge }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: updateBadgeGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.updateBadge
  }

  async delete(
    variables: MutationDeleteBadgeArgs,
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { deleteBadge: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: deleteBadgeGQLMutation(),
      variables,
      customToken: accessToken,
    })
    return result.deleteBadge
  }

  async assign(
    variables: MutationAssignBadgeArgs,
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { assignBadge: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: assignBadgeGQLMutation(),
      variables,
      customToken: accessToken,
    })
    return result.assignBadge
  }

  async revoke(
    variables: MutationRevokeBadgeArgs,
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { revokeBadge: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: revokeBadgeGQLMutation(),
      variables,
      customToken: accessToken,
    })
    return result.revokeBadge
  }
}
