import { T } from '@tribeplatform/react-components/i18n'
import { FormControl } from '@tribeplatform/react-ui-kit/FormControl'

interface Props {
  hideOnMobile: boolean
  hideOnTablet: boolean
  hideOnDesktop: boolean
  onHideOnMobileChange: (value: boolean) => unknown
  onHideOnTabletChange: (value: boolean) => unknown
  onHideOnDesktopChange: (value: boolean) => unknown
}

export const HideOnDeviceSettings = ({
  hideOnDesktop,
  hideOnMobile,
  hideOnTablet,
  onHideOnMobileChange,
  onHideOnDesktopChange,
  onHideOnTabletChange,
}: Props) => {
  return (
    <>
      <FormControl.Checkbox
        name="hideOneMobile"
        label={
          <T
            id="Blocks.Settings.Responsive.HideOnMobile"
            defaultMessage="Hide on mobile"
          />
        }
        checked={hideOnMobile}
        onChange={e => onHideOnMobileChange(e.target.checked)}
      />
      <FormControl.Checkbox
        name="hideOnTablet"
        label={
          <T
            id="Blocks.Settings.Responsive.HideOnTablet"
            defaultMessage="Hide on tablet"
          />
        }
        checked={hideOnTablet}
        onChange={e => onHideOnTabletChange(e.target.checked)}
      />
      <FormControl.Checkbox
        name="hideOnDesktop"
        label={
          <T
            id="Blocks.Settings.Responsive.HideOnDesktop"
            defaultMessage="Hide on desktop"
          />
        }
        checked={hideOnDesktop}
        onChange={e => onHideOnDesktopChange(e.target.checked)}
      />
    </>
  )
}
