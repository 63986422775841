import { MemberStatus } from '@tribeplatform/gql-client/types'
import type { ValueRendererComponent } from '@tribeplatform/react-components/Filters'
import { type I18nShape, useI18n } from '@tribeplatform/react-components/i18n'

export const emailStatusFactory = (intl: I18nShape) => {
  const { $t } = intl

  return {
    [MemberStatus.VERIFIED]: $t({
      defaultMessage: 'Verified',
      id: 'Generics.Verified',
    }),
    [MemberStatus.UNVERIFIED]: $t({
      defaultMessage: 'Unverified',
      id: 'Generics.Unverified',
    }),
  }
}

export const CustomEmailStatusRenderer: ValueRendererComponent = ({
  filter,
}) => {
  const intl = useI18n()
  const { value } = filter

  if (!Array.isArray(value)) {
    return null
  }

  const emailStatusMapper = emailStatusFactory(intl)
  const emailStatuses = value.map(
    emailStatus => emailStatusMapper?.[emailStatus] ?? '',
  )

  return <>{emailStatuses.join(', ')}</>
}
