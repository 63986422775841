import { FilterAppearances } from '@tribeplatform/react-components/Filters'
import { I18nShape } from '@tribeplatform/react-components/i18n'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { StaticFieldTypes } from '@tribeplatform/react-components/Views'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { MembersBlockProps, MembersBlockView } from './types.js'

export const genericViewFactory: (intl: I18nShape) => MembersBlockView = ({
  $t,
}) => ({
  id: '',
  name: $t({
    defaultMessage: 'Default',
    id: 'Generics.Default',
  }),
  filterBy: [],
  inlineFilters: [],
  filterAppearance: FilterAppearances.INLINE,
  allowInlineFilters: false,
  sort: 'alphabetical',
  style: 'list',
  gallerySize: 'medium',
  showDivider: true,
  limit: 10,
  fields: [],
  showMore: false,
  spaceId: '{{ spaceId }}',
})

export const staticFieldsFactory: ({ $t }: I18nShape) => StaticField[] = ({
  $t,
}) => [
  {
    id: 'galleryBanner',
    label: $t({
      defaultMessage: '{MEMBER_CC} banner',
      id: 'MemberBlock.FieldLabels.MemberBanner',
    }),
    enabled: true,
    locked: false,
    styles: ['gallery', 'carousel'],
    type: StaticFieldTypes.banner,
    settings: {
      bannerStyle: 'banner',
      aspectRatio: 'aspect-[5/4]',
    },
    isStatic: true,
    icon: <SvgIcon name="image" />,
  },
  {
    id: 'listAvatar',
    label: $t({
      defaultMessage: '{MEMBER_CC} avatar',
      id: 'MemberBlock.FieldLabels.MemberAvatar',
    }),
    enabled: true,
    locked: false,
    styles: ['list'],
    type: StaticFieldTypes.avatar,
    settings: {
      avatarSize: 'md',
    },
    isStatic: true,
    icon: <SvgIcon name="member" />,
  },
  {
    id: 'magazineAvatar',
    label: $t({
      defaultMessage: '{MEMBER_CC} avatar',
      id: 'MemberBlock.FieldLabels.MemberAvatar',
    }),
    enabled: true,
    locked: true,
    styles: ['magazine'],
    type: StaticFieldTypes.avatar,
    settings: {
      avatarSize: 'lg',
    },
    isStatic: true,
    icon: <SvgIcon name="member" />,
  },
  {
    id: 'listMemberInfo',
    label: $t({
      defaultMessage: '{MEMBER_CC} info',
      id: 'MemberBlock.FieldLabels.MemberInfo',
    }),
    enabled: true,
    locked: false,
    styles: ['list'],
    settings: {
      stretchX: true,
    },
    isStatic: true,
    icon: <SvgIcon name="identification" />,
    fields: [
      {
        id: 'listMemberName',
        label: $t({
          defaultMessage: '{MEMBER_CC} name',
          id: 'MemberBlock.FieldLabels.MemberName',
        }),
        enabled: true,
        locked: false,
        type: StaticFieldTypes.title,
        settings: {
          titleSize: 'sm',
        },
        isStatic: true,
        icon: <SvgIcon name="field" />,
      },
      {
        id: 'listTagline',
        label: $t({
          defaultMessage: 'Tagline',
          id: 'Generics.Tagline',
        }),
        enabled: true,
        locked: false,
        settings: {},
        isStatic: true,
        icon: <SvgIcon name="tag" />,
      },
      {
        id: 'listBadges',
        label: $t({
          defaultMessage: 'Badges',
          id: 'Generics.Badges',
        }),
        enabled: true,
        locked: false,
        settings: {},
        isStatic: true,
        icon: <SvgIcon name="badge-check" />,
      },
    ],
  },
  {
    id: 'galleryMemberName',
    label: $t({
      defaultMessage: '{MEMBER_CC} name',
      id: 'MemberBlock.FieldLabels.MemberName',
    }),
    enabled: true,
    locked: false,
    styles: ['gallery', 'magazine', 'carousel'],
    type: StaticFieldTypes.title,
    settings: {
      fullWidth: true,
      titleSize: 'sm',
    },
    isStatic: true,
    icon: <SvgIcon name="field" />,
  },
  {
    id: 'galleryTagline',
    label: $t({
      defaultMessage: 'Tagline',
      id: 'Generics.Tagline',
    }),
    enabled: true,
    locked: false,
    styles: ['gallery', 'magazine', 'carousel'],
    settings: {
      fullWidth: true,
    },
    isStatic: true,
    icon: <SvgIcon name="tag" />,
  },
  {
    id: 'galleryBadges',
    label: $t({
      defaultMessage: 'Badges',
      id: 'Generics.Badges',
    }),
    enabled: true,
    locked: false,
    styles: ['gallery', 'magazine', 'carousel'],
    settings: {
      fullWidth: true,
    },
    isStatic: true,
    icon: <SvgIcon name="badge-check" />,
  },
  {
    id: 'listMessageButton',
    label: $t({
      defaultMessage: 'Message button',
      id: 'MemberBlock.FieldLabels.MessageButton',
    }),
    enabled: true,
    locked: true,
    type: StaticFieldTypes.button,
    styles: ['list', 'gallery', 'carousel'],
    settings: { buttonVariant: 'iconText' },
    isStatic: true,
    icon: <SvgIcon name="message" />,
  },
  {
    id: 'magazineMessageButton',
    label: $t({
      defaultMessage: 'Message button',
      id: 'MemberBlock.FieldLabels.MessageButton',
    }),
    enabled: true,
    locked: false,
    type: StaticFieldTypes.button,
    styles: ['magazine'],
    settings: { buttonVariant: 'iconText' },
    isStatic: true,
    icon: <SvgIcon name="message" />,
  },
]

export const MembersConfig: (intl: I18nShape) => {
  defaultProps: MembersBlockProps
  displayName: string
  removable: boolean
  Icon: JSX.Element
} = ({ $t }) => ({
  defaultProps: {
    views: [],
    title: '',
    description: '',
    linkText: '',
    linkUrl: '',
  },
  displayName: $t({
    defaultMessage: 'Members',
    id: 'Blocks.Members.DisplayName',
  }),
  removable: true,
  Icon: <SvgIcon name="members" />,
})
