import { clsx } from 'clsx'

import { Image } from '@tribeplatform/gql-client/types'
import { SearchBar } from '@tribeplatform/react-components/Search'
import { Card } from '@tribeplatform/react-ui-kit/Card'

import { BC } from '../../types/index.js'
import { LegacyHeroBannerProps } from './types.js'

export const LegacyHeroBannerBlock: BC<LegacyHeroBannerProps> = ({
  title,
  description,
  searchPlaceholder,
  align,
  textColor,
  backgroundType,
  backgroundColor,
  imagePosition,
  style,
  imageTintOpacity,
  image,
  extraPadding,
  className,
  ...rest
}) => {
  let backgroundTint = null
  if (backgroundType === 'image') {
    backgroundTint =
      textColor === 'dark'
        ? `rgba(255,255,255,${imageTintOpacity / 100})`
        : `rgba(0,0,0,${imageTintOpacity / 100})`
  }

  const wrapperStyle: { background?: string; backgroundImage?: string } = {}
  if (backgroundType === 'color' && backgroundColor) {
    wrapperStyle.background = backgroundColor
  } else if (backgroundType === 'image' && (image as Image)?.urls?.full) {
    wrapperStyle.backgroundImage = `url(${(image as Image)?.urls?.full})`
  }

  const WrapperComponent = style === 'card' ? Card : 'div'
  return (
    <WrapperComponent
      className={clsx(
        align === 'center' && 'text-center',
        align === 'trailing' && 'text-end',
        imagePosition === 'cover' && 'bg-cover',
        imagePosition === 'contain' && 'bg-contain bg-no-repeat',
        imagePosition === 'no-repeat' && 'bg-auto bg-no-repeat',
        style === 'rounded' && 'sm:rounded-lg',
        'bg-center overflow-hidden',
        'bg-clip-content',
        className,
      )}
      style={wrapperStyle}
      {...rest}
    >
      <div
        className={clsx(
          textColor === 'dark' ? 'text-black' : 'text-white',
          'p-6',
        )}
        style={{
          backgroundColor: backgroundTint,
        }}
      >
        <div
          className="p-12"
          style={{
            paddingTop: `${extraPadding}em`,
            paddingBottom: `${extraPadding}em`,
          }}
        >
          <div className="mb-10">
            <h2 className="text-heading-xl mb-5">{title}</h2>
            <div>{description}</div>
          </div>
          <div className="flex">
            {align === 'trailing' && <div className="flex-grow"></div>}
            <div
              className={clsx(
                align === 'center' && 'mx-auto',
                'max-w-full w-132',
              )}
            >
              <SearchBar
                entityType="post"
                autoFocus={false}
                placeholder={searchPlaceholder}
              />
            </div>
          </div>
        </div>
      </div>
    </WrapperComponent>
  )
}
