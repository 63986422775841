import type { Post } from '@tribeplatform/gql-client/types'

import { useCMS } from '../../CMS/hooks/useCMS.js'
import { sanitizePostType } from '../../CMS/utils/utils.js'
import {
  ReactionActionTypes,
  ReactAction as TReactAction,
} from '../../Views/types.js'
import { getDefaultReactions } from '../utils/actionBar.js'
import { ReactAction } from './ReactAction.js'

interface Props {
  post: Post
  action: TReactAction
}
export const AdaptiveReactionAction = ({ post, action }: Props) => {
  const {
    defaultLabel,
    glyphId,
    color,
    activeLabel,
    activeGlyphId,
    activeColor,
    variant,
    showCount,
    reactionCountPosition,
  } = action
  const postTypeId = post?.postTypeId
  const { postTypes } = useCMS()
  const maybePostType = postTypes?.find(({ id }) => id === postTypeId)
  const postType = sanitizePostType(maybePostType)
  const customReactions = postType?.customReactions ?? []
  const allowedReactions = postType?.allowedReactions ?? []
  const singleChoiceReactions = postType?.singleChoiceReactions
  const hasAllEmojis = postType?.allowedReactions === null
  const isSingleReaction =
    singleChoiceReactions === null || singleChoiceReactions?.length > 0
  const validReactions = hasAllEmojis
    ? null
    : customReactions.filter(({ key }) => allowedReactions.includes(key))

  const reactionActions = getDefaultReactions(
    validReactions,
    isSingleReaction,
    variant,
    showCount,
    reactionCountPosition,
  )
  const getAction = (action: TReactAction) => {
    const { reactionActionType } = action
    if (reactionActionType === ReactionActionTypes.single) {
      return action
    }

    return {
      ...action,
      defaultLabel: defaultLabel || action.defaultLabel,
      activeLabel: activeLabel || action.activeLabel,
      glyphId: glyphId || action.glyphId,
      activeGlyphId: activeGlyphId || action.activeGlyphId,
      color: color || action.color,
      activeColor: activeColor || action.activeColor,
    }
  }

  return (
    <>
      {reactionActions.map(action => (
        <ReactAction key={action.id} post={post} action={getAction(action)} />
      ))}
    </>
  )
}
