import { lazy, Suspense, useState } from 'react'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import {
  Member,
  MemberEmailStatus,
  MemberStatus,
  ModerationEntityType,
  NetworkVisibility,
  RoleType,
} from '@tribeplatform/gql-client/types'
import {
  useAuthMember,
  useAuthToken,
  useDeleteMember,
  useNetworkRoles,
  useSendResetPasswordEmail,
  useSuspendMember,
  useUnsuspendMember,
  useVerifyMember,
} from '@tribeplatform/react-sdk/hooks'
import { confirm } from '@tribeplatform/react-ui-kit/Dialog'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { useI18n } from '../../i18n/providers/I18nProvider.js'
import type { MemberActionItem, MemberContext } from '../types.js'
import { useFlagMemberActionItem } from './useFlagMemberActionItem.js'

const MemberAnalyticsModal = lazy(() =>
  import('../../Analytics/MemberAnalytics/MemberAnalyticsModal.js').then(m => ({
    default: m.MemberAnalyticsModal,
  })),
)
const AuditLogsModal = lazy(() =>
  import('../../AuditLog/AuditLogsModal.js').then(m => ({
    default: m.AuditLogsModal,
  })),
)
const ManageBadgesModal = lazy(() =>
  import('../../MemberBadge/ManageBadgesModal.js').then(m => ({
    default: m.ManageBadgesModal,
  })),
)
const ProfileSettingsModal = lazy(() =>
  import('../../ProfileSettingsModal/ProfileSettingsModal.js').then(m => ({
    default: m.ProfileSettingsModal,
  })),
)
const ReportModal = lazy(() =>
  import('../../ReportModal/ReportModal.js').then(m => ({
    default: m.ReportModal,
  })),
)
const ShareModal = lazy(() =>
  import('../../ShareModal/ShareModal.js').then(m => ({
    default: m.ShareModal,
  })),
)

export const useMemberActionItems = ({
  member,
  context,
}: {
  member: Member
  context?: MemberContext
}) => {
  const { $t } = useI18n()
  const actionItems: MemberActionItem[] = []

  const [shareModal, setShareModal] = useState<boolean>(false)
  const [editModal, setEditModal] = useState(false)
  const [auditLogsModal, setAuditLogsModal] = useState<boolean>(false)
  const [assignBadgesModal, setAssignBadgesModal] = useState<boolean>(false)
  const [reportModal, setReportModal] = useState<boolean>(false)
  const [memberAnalyticsModal, setMemberAnalyticsModal] =
    useState<boolean>(false)

  const { data: authMember } = useAuthMember()

  const {
    data: { network },
  } = useAuthToken()

  const roles = useNetworkRoles()

  const { mutateAsync: sendResetPasswordEmail } = useSendResetPasswordEmail()
  const { mutateAsync: verifyEmail } = useVerifyMember()
  const { mutateAsync: deleteMember } = useDeleteMember()
  const { mutateAsync: suspendMember } = useSuspendMember()
  const { mutateAsync: unsuspendMember } = useUnsuspendMember()
  // TODO (refactor): this is how we want to handle the action items
  const flagMemberActionItem = useFlagMemberActionItem(member)

  const [
    canUpdateMember,
    canSuspendMember,
    canUnsuspendMember,
    canRemoveMember,
  ] = hasScopesPermission(member, [
    'updateMember',
    'suspendMember',
    'unsuspendMember',
    'removeMember',
  ])

  const [
    canUpdateNetwork,
    canAssignBadge,
    canSendResetPasswordEmail,
    canVerifyMember,
    canReportMember,
  ] = hasScopesPermission(network, [
    'updateNetwork',
    'assignBadge',
    'sendResetPasswordEmail',
    'verifyMember',
    'createModerationReport',
  ])

  if (!member?.id) {
    return { actions: [], children: null }
  }

  const memberPath = member?.relativeUrl

  const isOwnProfile = authMember?.id === member?.id

  const onDelete = async (member: Member) => {
    if (!member?.id) {
      return
    }

    const confirmed = await confirm({
      title: $t(
        {
          defaultMessage:
            'Are you sure you want to remove {member_name} ({member_username})?',
          id: 'MemberAction.Delete.Confirmation',
        },
        { member_name: member.name, member_username: member.username },
      ),
      proceedLabel: $t({
        id: 'Generics.Remove',
        defaultMessage: 'Remove',
      }),
      cancelLabel: $t({
        defaultMessage: 'Cancel',
        id: 'Generics.Cancel',
      }),
      danger: true,
    })
    if (!confirmed) {
      return
    }

    try {
      await deleteMember({ id: member?.id })
      toast({
        title: $t({
          defaultMessage: '{MEMBER_CC} removed',
          id: 'MemberAction.Removed',
        }),
        description: $t({
          defaultMessage: '{MEMBER_CC} is successfully removed.',
          id: 'MemberAction.Removed.Description',
        }),
        status: 'success',
      })
    } catch (err) {
      toast({
        title: $t({
          id: 'Generics.SomethingWentWrong',
          defaultMessage: 'Something went wrong',
        }),
        description: $t({
          defaultMessage: 'Cannot remove this {MEMBER}.',
          id: 'MemberAction.FailedToRemove.Description',
        }),
        status: 'error',
      })
    }
  }

  const onSuspend = async (member: Member) => {
    if (!member?.id) {
      return
    }

    const confirmed = await confirm({
      title: $t({
        defaultMessage: '{MEMBER} suspension',
        id: 'MemberAction.Suspend.Confirmation',
      }),
      description: $t({
        defaultMessage:
          'Suspending a {MEMBER} will take away their ability to login and engage with your {COMMUNITY} as a registered {MEMBER}. Please note that you will be able to revoke the suspension if you will choose to do so later on.',
        id: 'MemberAction.Suspend.Confirmation.Description',
      }),
      proceedLabel: $t({
        defaultMessage: 'Suspend',
        id: 'Generics.Suspend',
      }),
      cancelLabel: $t({
        defaultMessage: 'Cancel',
        id: 'Generics.Cancel',
      }),
    })
    if (!confirmed) {
      return
    }

    try {
      await suspendMember({ memberId: member?.id })
      toast({
        title: $t({
          defaultMessage: '{MEMBER_CC} suspended',
          id: 'MemberAction.Suspended',
        }),
        description: $t({
          defaultMessage: '{MEMBER_CC} is successfully suspended.',
          id: 'MemberAction.Suspended.Description',
        }),
        status: 'success',
      })
    } catch (err) {
      toast({
        title: $t({
          defaultMessage: 'Something went wrong',
          id: 'Generics.SomethingWentWrong',
        }),
        description: $t({
          defaultMessage: 'Cannot suspend this {MEMBER}.',
          id: 'MemberAction.FailedToSuspend.Description',
        }),
        status: 'error',
      })
    }
  }

  const onUnsuspend = async (member: Member) => {
    if (!member?.id) {
      return
    }

    try {
      await unsuspendMember({ memberId: member?.id })
      toast({
        title: $t({
          defaultMessage: '{MEMBER_CC} suspension revoked',
          id: 'MemberAction.RevokedSuspension',
        }),
        description: $t({
          defaultMessage: '{MEMBER_CC} suspension is successfully revoked.',
          id: 'MemberAction.RevokedSuspension.Description',
        }),
        status: 'success',
      })
    } catch (err) {
      toast({
        title: $t({
          defaultMessage: 'Something went wrong',
          id: 'Generics.SomethingWentWrong',
        }),
        description: $t({
          defaultMessage: 'Cannot revoke suspension for this {MEMBER}.',
          id: 'MemberAction.FailedToRevokeSuspension.Description',
        }),
        status: 'error',
      })
    }
  }

  const onSendResetPasswordEmail = async (member: Member) => {
    if (!member?.id) {
      return
    }

    const confirmed = await confirm({
      title: $t({
        defaultMessage: 'Reset password',
        id: 'Generics.ResetPassword',
      }),
      description: $t({
        defaultMessage:
          'An email containing reset password link will be sent to the {MEMBER}. Are you sure you want to continue?',
        id: 'Member.ResetPassword.Confirmation.Description',
      }),
      proceedLabel: $t({
        defaultMessage: 'Email reset password link',
        id: 'Member.ResetPassword.Confirmation.Proceed',
      }),
      cancelLabel: $t({
        defaultMessage: 'Cancel',
        id: 'Generics.Cancel',
      }),
    })
    if (!confirmed) {
      return
    }

    try {
      await sendResetPasswordEmail({ email: member?.email })
      toast({
        title: $t({
          defaultMessage: 'Email sent',
          id: 'MemberAction.ResetPassSent',
        }),
        description: $t({
          defaultMessage: 'Reset password email sent to the {MEMBER}.',
          id: 'MemberAction.ResetPassSent.Description',
        }),
        status: 'success',
      })
    } catch (err) {
      toast({
        title: $t({
          defaultMessage: 'Something went wrong',
          id: 'Generics.SomethingWentWrong',
        }),
        description: $t({
          defaultMessage: 'Cannot send reset password email.',
          id: 'MemberAction.ResetPassFailedToSend.Description',
        }),
        status: 'error',
      })
    }
  }

  const onVerifyEmail = async memberId => {
    const confirmed = await confirm({
      title: $t({
        id: 'MemberAction.VerifyEmail.Confirmation',
        defaultMessage:
          'Are you sure you want to manually confirm {MEMBER}’s email address?',
      }),
      description: $t({
        id: 'MemberAction.VerifyEmail.Confirmation.Description',
        defaultMessage:
          'Confirming incorrect email addresses can hurt your email delivery rate.',
      }),
      proceedLabel: $t({
        id: 'Generics.Yes',
        defaultMessage: 'Yes',
      }),
      cancelLabel: $t({
        id: 'Generics.No',
        defaultMessage: 'No',
      }),
    })
    if (!confirmed) {
      return
    }

    await verifyEmail({
      input: {
        memberId,
      },
    })

    toast({
      title: $t({
        defaultMessage: 'Email address verified',
        id: 'MemberAction.EmailVerified',
      }),
      description: $t({
        defaultMessage: 'The email address is now verified.',
        id: 'MemberAction.EmailVerified.Description',
      }),
      status: 'success',
    })
  }

  const categoryText = $t({
    id: 'Generics.Member',
    defaultMessage: '{MEMBER_CC}',
  })

  if (context === 'admin') {
    actionItems.push({
      url: memberPath,
      icon: 'member',
      name: $t({
        id: 'Generics.ViewProfile',
        defaultMessage: 'View profile',
      }),
      category: categoryText,
    })
  }

  if (canUpdateMember && context !== 'member') {
    actionItems.push({
      action: () => setEditModal(true),
      icon: 'edit',
      name: $t({ defaultMessage: 'Edit profile', id: 'Generics.EditProfile' }),
      category: categoryText,
    })
  }

  actionItems.push({
    action: () => setShareModal(true),
    icon: 'share',
    name: $t({
      defaultMessage: 'Share {MEMBER}',
      id: 'Member.Share',
    }),
    hotkey: 'option+s,alt+s',
    category: categoryText,
  })

  if (canAssignBadge) {
    actionItems.push({
      action: () => setAssignBadgesModal(true),
      icon: 'badge-check',
      name: $t({
        defaultMessage: 'Manage badges',
        id: 'Generics.ManageBadges',
      }),
      category: categoryText,
    })
  }

  if (canUpdateNetwork) {
    actionItems.push({
      action: () => setMemberAnalyticsModal(true),
      icon: 'analytics',
      name: $t({
        defaultMessage: '{MEMBER_CC} analytics',
        id: 'Member.Analytics',
      }),
      hotkey: 'option+a,alt+a',
      category: categoryText,
    })
  }

  if (canUpdateNetwork) {
    actionItems.push({
      action: () => setAuditLogsModal(true),
      icon: 'audit-log',
      name: $t({
        id: 'Generics.AuditLogs',
        defaultMessage: 'Audit logs',
      }),
      hotkey: 'option+l,alt+l',
      category: categoryText,
    })
  }

  if (authMember?.role?.type === RoleType.admin && canSendResetPasswordEmail) {
    actionItems.push({
      action: () => onSendResetPasswordEmail(member),
      icon: 'key',
      name: $t({
        id: 'Generics.ResetPassword',
        defaultMessage: 'Reset password',
      }),
      category: categoryText,
    })
  }

  if (
    !isOwnProfile &&
    authMember?.role?.type === RoleType.admin &&
    member.emailStatus !== MemberEmailStatus.verified &&
    canVerifyMember
  ) {
    actionItems.push({
      action: () => onVerifyEmail(member?.id),
      icon: 'mail',
      name: $t({
        id: 'Member.VerifyEmail',
        defaultMessage: 'Verify email address',
      }),
      category: categoryText,
    })
  }

  if (flagMemberActionItem) {
    actionItems.push(flagMemberActionItem)
  }

  if (
    member?.role?.type !== roles?.admin?.type &&
    member?.role?.type !== roles?.moderator?.type &&
    canSuspendMember &&
    !isOwnProfile &&
    member?.status !== MemberStatus.Suspended
  ) {
    actionItems.push({
      action: () => onSuspend(member),
      icon: 'ban',
      name: $t({
        id: 'Member.Suspend',
        defaultMessage: 'Suspend {MEMBER}',
      }),
      category: categoryText,
    })
  }

  if (
    canUnsuspendMember &&
    member?.status === MemberStatus.Suspended &&
    !isOwnProfile
  ) {
    actionItems.push({
      action: () => onUnsuspend(member),
      icon: 'ban',
      name: $t({
        id: 'Member.RevokeSuspension',
        defaultMessage: 'Revoke suspension',
      }),
      category: categoryText,
    })
  }

  if (canRemoveMember && !isOwnProfile) {
    actionItems.push({
      action: () => onDelete(member),
      icon: 'trash',
      name: $t({
        id: 'Generics.RemoveMember',
        defaultMessage: 'Remove {MEMBER}',
      }),
      hotkey: 'cmd+backspace,ctrl+backspace',
      category: categoryText,
    })
  }

  if (canReportMember) {
    actionItems.push({
      action: () => setReportModal(true),
      icon: 'status-warning',
      name: $t({
        id: 'Member.Report',
        defaultMessage: 'Report {MEMBER}',
      }),
      category: categoryText,
    })
  }

  const children = (
    <>
      <Suspense fallback={<></>}>
        <ShareModal
          isPrivate={network.visibility === NetworkVisibility.private}
          memberId={member?.id}
          open={shareModal}
          onClose={() => setShareModal(false)}
          title={member?.name}
          url={member?.url}
        />
      </Suspense>
      {editModal ? (
        <Suspense fallback={<></>}>
          <ProfileSettingsModal
            member={member}
            open
            onClose={() => setEditModal(false)}
          />
        </Suspense>
      ) : null}
      {auditLogsModal && (
        <Suspense fallback={<></>}>
          <AuditLogsModal
            open
            onClose={() => setAuditLogsModal(false)}
            memberId={member?.id}
          />
        </Suspense>
      )}
      {assignBadgesModal && (
        <Suspense fallback={<></>}>
          <ManageBadgesModal
            open
            onClose={() => setAssignBadgesModal(false)}
            memberId={member?.id}
          />
        </Suspense>
      )}
      {memberAnalyticsModal && (
        <Suspense fallback={<></>}>
          <MemberAnalyticsModal
            open
            onClose={() => setMemberAnalyticsModal(false)}
            member={member}
            linkToContent={false}
          />
        </Suspense>
      )}
      {reportModal && (
        <Suspense fallback={<></>}>
          <ReportModal
            entity={member}
            entityType={ModerationEntityType.MEMBER}
            onClose={() => setReportModal(false)}
            open
          />
        </Suspense>
      )}
    </>
  )

  return { actions: actionItems, children }
}
