import { Children } from 'react'

import {
  Container as UIContainer,
  ContainerSize,
  useContainer,
  minContainerSize,
} from '@tribeplatform/react-ui-kit/Layout'

import { useBlock, useSlate } from '../../hooks/index.js'
import { LayoutBlockProps, LBC } from '../../types/index.js'
import { ContainerDeprecatedProps } from './types.js'
import { isColumnBlock, isContainerBlock, isDeprecatedBlock } from './utils.js'

type ContainerProps = ContainerDeprecatedProps & LayoutBlockProps

export const ContainerBlock: LBC<ContainerProps> = ({
  size: inputSize,
  maxSize: inputMaxSize,
  direction: inputDirection,
  columns,
  children,
  smColumns,
  containerProps,
  HighlightWrapper,
  ...rest
}) => {
  const {
    compiledSlate: { blocks },
  } = useSlate()
  const {
    block: { name, children: blockChildren },
  } = useBlock<ContainerDeprecatedProps>()
  const { size: parentSize } = useContainer()

  const isColumn = isColumnBlock(name) && !!columns
  const isDeprecated = isDeprecatedBlock(name)

  const items = Children.toArray(children)
  if (items.length === 0) {
    return null
  }

  const direction = isColumn ? 'grid' : inputDirection
  const smOrders =
    isColumn && (smColumns || [columns.findIndex(({ main }) => main)])

  const size = minContainerSize(inputSize, parentSize)
  const maxSize = inputMaxSize || inputSize || (isDeprecated ? 'lg' : undefined)
  const childrenSizes: ContainerSize[] = []
  if (isColumn) {
    blockChildren
      .map((child, idx) => ({ block: blocks[child], idx }))
      .filter(({ block }) => block.children?.length > 0)
      .forEach(({ block, idx }) => {
        if (isContainerBlock(block)) {
          childrenSizes.push(block.props?.size || size)
        } else {
          childrenSizes.push(columns[idx].main ? 'md' : 'sm')
        }
      })
  }

  return (
    <UIContainer
      size={isColumn ? 'lg' : size}
      maxSize={maxSize}
      childrenSizes={childrenSizes}
      direction={direction}
      containerProps={{
        ...(containerProps ?? {}),
        className: containerProps?.className,
      }}
      {...rest}
    >
      {isColumn
        ? items.map((item, idx) => {
            const smOrder = smOrders.findIndex(smIdx => smIdx === idx)
            const hide = blocks?.[blockChildren?.[idx]]?.children?.length === 0
            return (
              <UIContainer
                key={blockChildren?.[idx]}
                size={columns[idx].main ? 'md' : 'sm'}
                maxSize={columns[idx].main ? 'full' : 'md'}
                direction="vertical"
                order={{ default: idx + 1, sm: smOrder > -1 ? smOrder + 1 : 0 }}
                hide={{ default: hide, sm: hide || smOrder === -1 }}
              >
                {item}
              </UIContainer>
            )
          })
        : items}

      {HighlightWrapper}
    </UIContainer>
  )
}
