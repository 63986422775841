import { useMemo } from 'react'

import { TableOfContents } from '../Composer/extensions/table-of-content/components/TableOfContent.js'

interface Props {
  content: string
}

const HEADER_REGEX = /<h([1-6])[^>]*>([\s\S]*?)<\/h[1-6]>/g

const decodeHtmlEntities = (text: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(text, 'text/html')
  return doc.documentElement.textContent || ''
}

export function TableOfContentRenderer({ content }: Props) {
  const headings = useMemo(() => {
    const matches = Array.from(content.matchAll(HEADER_REGEX))

    return matches.map((match, index) => {
      const [fullMatch, level, text] = match
      return {
        id: fullMatch.match(/id="([^"]*)"/)?.[1] || `heading-${index}`,
        itemIndex: index + 1,
        textContent: decodeHtmlEntities(text.replace(/<[^>]*>/g, '')),
        level: parseInt(level, 10),
      }
    })
  }, [content])

  if (headings.length < 1) {
    return null
  }

  return <TableOfContents content={headings} />
}
