import { useCallback, useMemo } from 'react'

import qs, { IParseOptions, IStringifyOptions } from 'qs'

import { useRouter } from '@tribeplatform/react-sdk'

export interface UseQueryParamsOptions {
  navigationMode?: 'push' | 'replace'
  parseOptions?: IParseOptions
  stringifyOptions?: IStringifyOptions
}

const defaultParseOptions: IParseOptions = {
  allowDots: true,
  ignoreQueryPrefix: true,
}
const defaultStringifyOptions: IStringifyOptions = {
  allowDots: true,
  format: 'RFC1738', // encode space character as '+'
}

export const useQueryParams = ({
  navigationMode = 'push',
  parseOptions,
  stringifyOptions,
}: UseQueryParamsOptions = {}) => {
  const {
    push,
    replace,
    location: { search },
  } = useRouter()

  const queryParams = useMemo(() => {
    const mergedParseOptions = { ...defaultParseOptions, ...parseOptions }

    return qs.parse(search, mergedParseOptions)
  }, [parseOptions, search])

  const setQueryParams = useCallback(
    (newValue: Record<string, unknown>) => {
      const mergedStringifyOptions = {
        ...defaultStringifyOptions,
        ...stringifyOptions,
      }
      const newQuery = { ...queryParams, ...newValue }

      switch (navigationMode) {
        case 'push':
          push(`?${qs.stringify(newQuery, mergedStringifyOptions)}`)
          return undefined
        case 'replace':
          replace(`?${qs.stringify(newQuery, mergedStringifyOptions)}`)
          return undefined
        default: {
          // Code should never reach the default case
          const exhaustiveCheck: never = navigationMode
          return exhaustiveCheck
        }
      }
    },
    [navigationMode, push, queryParams, replace, stringifyOptions],
  )

  return [queryParams, setQueryParams] as const
}
