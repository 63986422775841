import { ReactNode, useEffect, useMemo } from 'react'

import { theme as defaultTheme } from '@tribeplatform/design-system/themes/default'
import type { Theme, Typography } from '@tribeplatform/design-system/types'
import { AppearanceStyle } from '@tribeplatform/design-system/types'
import { typography as defaultTypography } from '@tribeplatform/design-system/typography/system'

import { useDocument } from '../hooks/useDocument.js'
import { useUniqueId } from '../utils/unique-id.js'
import { useColorSchemeContext } from './ColorSchemeProvider.js'
import {
  DEFAULT_NETWORK_COLOR_SCHEME,
  DEFAULT_STYLE,
  DEFAULT_THEME_ASSETS,
} from './constants.js'
import { ThemeContext } from './ThemeContext.js'
import { NetworkColorScheme, ThemeAssets, UserColorScheme } from './types.js'
import { injectFontLink, injectStyles } from './utils.js'

export type ThemeProviderProps = {
  theme?: Theme
  typography?: Typography
  userColorScheme?: UserColorScheme
  networkColorScheme?: NetworkColorScheme
  style?: AppearanceStyle
  children?: ReactNode
  /** Attach css variables to wrapper instead of document root */
  scoped?: boolean
  /***/
  injectCssStyle?: boolean
  assets?: ThemeAssets
}

export const ThemeProvider = ({
  children,
  theme = defaultTheme,
  typography = defaultTypography,
  style = DEFAULT_STYLE,
  userColorScheme: userColorSchemeProp,
  networkColorScheme = DEFAULT_NETWORK_COLOR_SCHEME,
  scoped = false,
  injectCssStyle = true,
  assets = DEFAULT_THEME_ASSETS,
}: ThemeProviderProps) => {
  const { document } = useDocument()
  const uniqueId = useUniqueId('theme-provider-')
  const id = uniqueId.replace(/:/g, '')

  const { colorSchemeMode } = useColorSchemeContext()
  const userColorScheme = userColorSchemeProp ?? colorSchemeMode

  useEffect(() => {
    if (!injectCssStyle || !document) {
      return
    }

    injectStyles({
      document,
      elementId: scoped ? id : undefined,
      theme,
      userColorScheme,
      networkColorScheme,
      typography,
      style,
    })
    injectFontLink({ document, typography })
  }, [
    scoped,
    document,
    id,
    theme,
    userColorScheme,
    networkColorScheme,
    typography,
    style,
    injectCssStyle,
  ])

  const contextValue = useMemo(
    () => ({
      theme,
      style,
      assets,
    }),
    [theme, style, assets],
  )

  const content = !scoped ? <>{children}</> : <div id={id}>{children}</div>

  return (
    <ThemeContext.Provider value={contextValue}>
      {content}
    </ThemeContext.Provider>
  )
}
