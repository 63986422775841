import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  forwardRef,
} from 'react'

import type { TribeRouterType } from './@types/index.js'

export const defaultRouter: TribeRouterType = {
  Link: forwardRef(({ children, ...props }, ref) => (
    <a ref={ref} {...props}>
      {children}
    </a>
  )),
  Redirect: ({ to }) => {
    useEffect(() => {
      window.history.pushState({}, '', to)
    }, [to])
    return null
  },
  redirect: href => {
    window.location.href = href
  },
  push: path => {
    window.history.pushState({}, '', path)
  },
  replace: path => {
    window.history.replaceState({}, '', path)
  },
  params: {},
  location:
    typeof window === 'object'
      ? window.location
      : { pathname: '/', search: '' },
}

export const RouterContext = createContext<TribeRouterType>(defaultRouter)
const RouterPathContext = createContext<string>('')

export const useRouter = () => {
  return useContext(RouterContext)
}

export const useRouterPath = () => {
  return useContext(RouterPathContext)
}

RouterContext.displayName = 'TribeRouter'

export const TribeRouter = ({
  children,
  ...rest
}: PropsWithChildren<TribeRouterType>) => {
  const contextValue = rest ?? defaultRouter
  return (
    <RouterContext.Provider value={contextValue}>
      <RouterPathContext.Provider
        value={contextValue?.location?.pathname ?? ''}
      >
        {children}
      </RouterPathContext.Provider>
    </RouterContext.Provider>
  )
}
