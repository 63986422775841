import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { FormBlock as Component } from './Block.js'
import { FormProps } from './types.js'

export const RegisteredFormBlock = (
  _intl: I18nShape,
): RegisteredBlock<FormProps> => ({
  recommendedName: 'Form',
  Component,
  config: {
    type: BlockType.BuildingBlock,
  },
})
