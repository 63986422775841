import type {
  PaginatedSpaceJoinRequest,
  QuerySpaceJoinRequestsArgs,
} from '@tribeplatform/gql-client/types'
import { PaginatedSpaceJoinRequestFields } from '@tribeplatform/gql-client/types/generated'

import { useInfiniteQuery, UseInfiniteQueryOptions } from '../../index.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSpaceJoinRequestKey } from '../../utils/keys/spaceMembership.key.js'

export const useSpaceJoinRequests = (options: {
  variables: QuerySpaceJoinRequestsArgs
  fields?: PaginatedSpaceJoinRequestFields
  useQueryOptions?: UseInfiniteQueryOptions<PaginatedSpaceJoinRequest>
}) => {
  const { variables, fields = 'basic', useQueryOptions } = options
  const { client } = useTribeClient()
  const key = getSpaceJoinRequestKey({ variables, fields })

  return useInfiniteQuery<PaginatedSpaceJoinRequest>(
    key,
    ({ pageParam: after }) =>
      client.query({
        name: 'spaceJoinRequests',
        args: {
          variables: {
            after,
            ...variables,
          },
          fields,
        },
      }),
    {
      ...useQueryOptions,
    },
  )
}
