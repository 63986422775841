import { clsx } from 'clsx'
import DumbMarkdown from 'markdown-to-jsx'

import { Link } from '@tribeplatform/react-ui-kit/Link'

import { BC } from '../../types/index.js'
import { MarkdownProps } from './types.js'

export const MarkdownBlock: BC<MarkdownProps> = ({
  text,
  className,
  ...rest
}) => {
  return (
    <article className={clsx('prose', className)} {...rest}>
      <DumbMarkdown
        options={{
          forceBlock: true,
          forceWrapper: true,
          overrides: {
            a: {
              component: Link,
              props: {
                external: true,
              },
            },
            li: {
              component: 'li',
              props: {
                className: 'list-disc list-outside ps-6',
              },
            },
          },
        }}
      >
        {text}
      </DumbMarkdown>
    </article>
  )
}
