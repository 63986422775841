import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { ButtonBlock as Component } from './Block.js'
import { ButtonProps } from './types.js'

export const RegisteredButtonBlock = (
  _intl: I18nShape,
): RegisteredBlock<ButtonProps> => ({
  recommendedName: 'Button',
  Component,
  config: {
    type: BlockType.BuildingBlock,
    defaultProps: {
      autoDisabled: true,
      autoLoading: true,
    },
  },
})
