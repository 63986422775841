import { FC } from 'react'

import { RadioGroup } from '../RadioGroup/index.js'
import { HelperText } from './HelperText.js'
import { Label } from './Label.js'
import { FormControlRadioGroupProps } from './types.js'

export const FormControlRadioGroup: FC<FormControlRadioGroupProps> = props => {
  const {
    children,
    name,
    label,
    helperText,
    error,
    invalid,
    hidden,
    onChange,
    ...rest
  } = props
  return (
    <div className="space-y-1" hidden={hidden}>
      {label ? <Label htmlFor={name}>{label}</Label> : null}
      {!invalid && helperText && (
        <div className="pb-2">
          <HelperText>{helperText}</HelperText>
        </div>
      )}
      <RadioGroup {...rest} onChange={onChange} name={name}>
        {children}
      </RadioGroup>
      {invalid && error && (
        <div className="pt-1">
          <HelperText status="error">{error}</HelperText>
        </div>
      )}
    </div>
  )
}
