import { useCallback, useMemo } from 'react'

import { useQueryParams, UseQueryParamsOptions } from './useQueryParams.js'

const slugify = (name: string) => {
  const slug = name
    // remove not allowed characters
    .replace(/[^\w\s$*_+~()'"!\-:@]/g, '')
    // trim leading/trailing spaces
    .trim()
    // convert spaces
    .replace(/[-\s]+/g, '-')

  return slug.toLowerCase()
}

export type QueryParamName = {
  name: string
  property?: string
}

export const useQueryParam = <T = unknown>(
  { name, property }: QueryParamName,
  {
    navigationMode = 'push',
    parseOptions,
    stringifyOptions,
  }: UseQueryParamsOptions = {},
): readonly [T, (newValue: T) => void] => {
  const [queryParams, setQueryParams] = useQueryParams({
    navigationMode,
    parseOptions,
    stringifyOptions,
  })

  const firstLevel = slugify(name)
  const secondLevel = property ? slugify(property) : undefined

  const value = useMemo<T>(() => {
    const queryParam = queryParams[firstLevel]

    if (secondLevel) {
      return queryParam?.[secondLevel]
    }

    return queryParam
  }, [firstLevel, secondLevel, queryParams])

  const setValue = useCallback(
    (newValue: T) => {
      if (!secondLevel) {
        setQueryParams({
          [firstLevel]: newValue,
        })
        return
      }

      const queryParam = queryParams[firstLevel] ?? {}
      if (typeof queryParam === 'object') {
        setQueryParams({
          [firstLevel]: {
            ...queryParam,
            [secondLevel]: newValue,
          },
        })
      }
    },
    [firstLevel, secondLevel, queryParams, setQueryParams],
  )

  return [value, setValue] as const
}
