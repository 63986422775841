import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getNotificationsCountKey } from '../../utils/keys/notification.key.js'

export const useNotificationsCount = (options: {
  useQueryOptions?: UseQueryOptions<number>
}) => {
  const { useQueryOptions } = options || {}
  const { client } = useTribeClient()
  const notificationsKey = getNotificationsCountKey()

  return useQuery<number>(
    notificationsKey,
    () => client?.notifications.count(),
    useQueryOptions,
  )
}
