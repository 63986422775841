import { createContext, useContext } from 'react'

import { DesktopNotificationProviderClient } from './DesktopNotificationProviderClient.js'
import type { DesktopNotification } from './types.js'

export const DesktopNotificationContext = createContext<DesktopNotification>({
  enabled: false,
  blocked: false,
  enable: () => {
    return Promise.resolve()
  },
  disable: () => {
    return Promise.resolve()
  },
})

export const useDesktopNotification = (): DesktopNotification => {
  const context = useContext(DesktopNotificationContext)
  return context
}

interface Props {
  children: React.ReactNode
}

export const DesktopNotificationProvider = ({ children }: Props) => {
  return (
    <DesktopNotificationProviderClient>
      {children}
    </DesktopNotificationProviderClient>
  )
}
