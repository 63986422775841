import { useRef, useEffect, useCallback } from 'react'

import { useAuthMember, useUpdateMember } from '@tribeplatform/react-sdk/hooks'

export const useUpdateTimeZone = () => {
  const { data: member, isGuest } = useAuthMember()
  const { mutateAsync: updateMember } = useUpdateMember()
  const didUpdateTimeZone = useRef(false)

  const updateTimeZone = useCallback(async () => {
    try {
      const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

      await updateMember({
        id: member.id,
        input: {
          timeZone: currentTimeZone,
        },
      })
    } catch (error) {
      logger.error('Error while updating timezone', error)
    }
  }, [member, updateMember])

  useEffect(() => {
    const isTimeZoneAlreadySet = !!member && !!member.timeZone

    if (!isGuest && !didUpdateTimeZone.current && !isTimeZoneAlreadySet) {
      updateTimeZone()

      didUpdateTimeZone.current = true
    }
  }, [member, updateTimeZone, isGuest])
}
