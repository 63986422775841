import { forwardRef } from 'react'

import { clsx } from 'clsx'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { Post, PostStatus } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { useAuthMember } from '@tribeplatform/react-sdk/hooks'
import { Alert } from '@tribeplatform/react-ui-kit/Alert'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { useQuery } from '../common/hooks/useQuery.js'
import { T } from '../i18n/components/T.js'
import { REPLIES_SECTION_HASH } from '../Reply/constants.js'
import { Reply } from '../Reply/Reply.js'
import { PostActionBar } from './PostActionBar.js'
import { PostCardContent } from './PostCardContent.js'
import { ReplyBar } from './ReplyBar.js'
import type { PostContext } from './types.js'

export type PostCardProps = {
  post: Post
  context: PostContext
  activeTagId?: string
  activeTagSlug?: string
  replyBar?: boolean
  collapsible?: boolean
  readonly?: boolean
  showContent?: boolean
}

export const PostCard = forwardRef<HTMLDivElement, PostCardProps>(
  (
    {
      post,
      context,
      activeTagId = '',
      activeTagSlug = '',
      replyBar = true,
      collapsible = true,
      readonly = false,
      showContent,
    },
    ref,
  ) => {
    const { Link } = useRouter()
    const query = useQuery()
    const { isGuest } = useAuthMember()

    if (!post?.id) {
      return null
    }

    let canReply = true
    const isDeleted = post.status === PostStatus.DELETED

    const [canCreateReply] = hasScopesPermission(post, ['createReply'])
    if (
      (!isGuest && !canCreateReply) ||
      !post.authMemberProps?.availableReplyTypes?.length
    ) {
      canReply = false
    }
    const isPendingReview = post.status === PostStatus.BLOCKED

    if (context === 'moderation') {
      return (
        <PostCardContent
          post={post}
          context={context}
          activeTagId={activeTagId}
          activeTagSlug={activeTagSlug}
          collapsible={collapsible}
          readonly={readonly}
          showContent={showContent}
        />
      )
    }

    return (
      <Card
        className={clsx(
          'post-card',
          post?.id === query?.highlight &&
            'ring ring-line-attention-subdued ring-offset-surface ring-offset-4 rounded-card',
        )}
      >
        {isPendingReview && (
          <Card.Header>
            <Alert
              status="neutral"
              className="rounded-b-none"
              icon={<SvgIcon name="clipboard-check" />}
            >
              <T
                id="Generics.PendingReviewBanner"
                defaultMessage="This post is pending approval. Once it's approved, it'll be available to other members."
              />
            </Alert>
          </Card.Header>
        )}
        <Card.Content>
          <PostCardContent
            post={post}
            context={context}
            activeTagId={activeTagId}
            activeTagSlug={activeTagSlug}
            collapsible={collapsible}
            readonly={readonly}
            showContent={showContent}
            ref={ref}
          />
        </Card.Content>
        {!isDeleted && !readonly && (
          <div className={clsx(context === 'post' && 'pb-2')}>
            <Card.Content className="flex gap-3 py-0 sm:p-1 sm:px-6 min-w-0">
              <PostActionBar post={post} />
            </Card.Content>
          </div>
        )}
        {replyBar && (canReply || post?.replies?.nodes?.length) ? (
          <Card.Content
            className={clsx(
              'flex flex-col space-y-6 px-5',
              !post?.replies?.nodes?.length && 'sm:p-4 sm:px-6 py-4',
            )}
          >
            {post?.repliesCount > 2 ? (
              <Link
                variant="neutral"
                className="text-sm leading-5 font-medium text-content-subdued"
                href={
                  post?.relativeUrl
                    ? `${post?.relativeUrl}${REPLIES_SECTION_HASH}`
                    : ''
                }
              >
                <T
                  id="Post.PostCard.ViewMoreXReplysText"
                  defaultMessage="View {more_replies_count} more replies"
                  values={{ more_replies_count: post?.repliesCount - 2 }}
                />
              </Link>
            ) : null}
            <div className="relative flex flex-col space-y-6">
              {post?.replies?.nodes
                ?.map(reply => (
                  <Reply
                    reply={reply}
                    key={reply.id}
                    canReply={!readonly}
                    readonly={readonly}
                  />
                ))
                .reverse()}
              {!readonly && canReply && replyBar && !isDeleted ? (
                <ReplyBar post={post} />
              ) : null}
            </div>
          </Card.Content>
        ) : (
          <div className="h-4"></div>
        )}
      </Card>
    )
  },
)
