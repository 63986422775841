import { ComponentProps } from 'react'

import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import { useModalContext } from './ModalContext.js'

export type ModalContentProps = ComponentProps<'div'> & {
  padding?: boolean
}
export const ModalContent = ({
  children,
  className,
  padding = true,
  ...rest
}: ModalContentProps) => {
  const { scrollable } = useModalContext()

  return (
    <div
      className={twMerge(
        clsx(
          'flex-1 text-md',
          padding && 'px-4 sm:px-6',
          padding && !scrollable && 'py-5 sm:py-6',
          scrollable && 'overflow-y-auto my-4 py-1',
          className,
        ),
      )}
      {...rest}
    >
      {children}
    </div>
  )
}
