import { ReactNode } from 'react'

import { useI18n } from '../../i18n/index.js'
import { useFirstRenderContext } from '../ssr/FirstRenderProvider.js'
import { getLocalizedDateFormat } from '../utils/date.js'

export interface TimeProps {
  dateTime: string
  className?: string
  children?: ReactNode
}

export const Time = ({ dateTime, className, children }: TimeProps) => {
  /**
   * @tag Vite migration
   * title produces hydration error
   * As a workaround, 'title' prop is initialized on client-side after first render
   */
  const { isFirstRender } = useFirstRenderContext()
  const { localeCode } = useI18n()

  const { dateTime: dateTimeFormatted } = getLocalizedDateFormat(
    dateTime,
    localeCode,
  )

  return (
    <time
      dateTime={dateTimeFormatted}
      title={isFirstRender ? undefined : dateTimeFormatted}
      className={className}
    >
      {children}
    </time>
  )
}
