import { useCallback } from 'react'

import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { getMemberKey, getMembersKey } from '../../utils/keys/member.key.js'

export const useInvalidateMemberQuery = () => {
  const queryClient = useQueryClient()

  const invalidate = useCallback(
    (memberId: string) => {
      const memberKey = getMemberKey({ variables: { id: memberId } })
      queryClient.invalidateQueries(memberKey)

      const membersKey = getMembersKey()

      queryClient.invalidateQueries(membersKey)
    },
    [queryClient],
  )

  return invalidate
}
