import { clsx } from 'clsx'

import { Space } from '@tribeplatform/gql-client/types'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { useRouter } from '@tribeplatform/react-sdk'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Text } from '@tribeplatform/react-ui-kit/Text'

import { getTitleVariant } from '../utils/index.js'

interface Props {
  field: StaticField
  space: Space
  highlighted: boolean
  stretchX: string
  preview: boolean
}
export const SpaceTitle = ({
  field,
  space,
  highlighted,
  stretchX,
  preview,
}: Props) => {
  const { Link } = useRouter()
  const { default: defaultTitle } = field
  const title = space?.name || (preview ? defaultTitle : '')
  const titleSize = field.settings?.titleSize
  const titleVariant = getTitleVariant(titleSize)
  const isPrivate = space?.private

  return (
    <Link
      href={space?.relativeUrl ?? ''}
      variant="inherit"
      className={clsx(
        stretchX,
        'break-words min-w-0 transition-none block',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
      )}
      onClick={e => e.stopPropagation()}
    >
      <Text as="h2" variant={titleVariant} className="line-clamp-3">
        {isPrivate && <SvgIcon className="me-2" name="lock" inline />}
        {title}
      </Text>
    </Link>
  )
}
