import { useMemo } from 'react'

import { Link } from 'react-router-dom'

import { SpacePostType } from '@tribeplatform/gql-client/types'
import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { useSpacePostTypeSettings } from '@tribeplatform/react-components/Space'
import {
  useAuthMember,
  useSpace,
  useSpacePostTypes,
} from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { getTranslatedAddPostText } from '../utils.js'
import { Tooltip } from './Tooltip.js'

const SPACE_POST_TYPES_LIMIT = 30

interface Props {
  className?: string
  spaceId: string
}

export const AddPostButton = ({ className, spaceId }: Props) => {
  const { data: space } = useSpace({
    variables: {
      id: spaceId,
    },
  })
  const intl = useI18n()
  const { isGuest } = useAuthMember()
  const { data: spacePostTypesData } = useSpacePostTypes({
    variables: {
      limit: SPACE_POST_TYPES_LIMIT,
      spaceId: space?.id,
    },
  })
  const { mapToPostTypePermissions } = useSpacePostTypeSettings({
    spaceId: space?.id,
    isPrivate: space?.private,
  })
  const spacePostTypes = useMemo(
    () => simplifyPaginatedResult<SpacePostType>(spacePostTypesData)?.nodes,
    [spacePostTypesData],
  )
  const postsAvailableToUser = space?.authMemberProps?.availablePostTypes || []

  if (isGuest || !space || postsAvailableToUser.length === 0) {
    return null
  }

  const postTypePermissions = mapToPostTypePermissions(
    postsAvailableToUser,
    spacePostTypes,
  )

  if (postsAvailableToUser.length === 1) {
    const { id, name } = postsAvailableToUser[0]

    return (
      <Button
        variant="primary"
        as={Link}
        to={`${space.relativeUrl}/new?post_type=${id}`}
        className={className}
      >
        <div className="flex items-center gap-2">
          {getTranslatedAddPostText(intl, name)}
          {postTypePermissions[id]?.whoCanPost === 'admins' && (
            <Tooltip
              tooltip={
                <T
                  id="Blocks.SpaceHeader.AddPostDropdown.Button.Tooltip"
                  defaultMessage="This button is only visible to the admins and staff members"
                />
              }
            >
              <SvgIcon name="eye-off" size="sm" />
            </Tooltip>
          )}
        </div>
      </Button>
    )
  }

  const onlyAdminsCanPost = postsAvailableToUser.every(
    postType => postTypePermissions[postType.id]?.whoCanPost === 'admins',
  )

  return (
    <Dropdown placement="bottom-end">
      <Dropdown.ButtonMinimal as="div">
        <Button
          variant="primary"
          trailingIcon={<SvgIcon name="chevron-down" size="lg" />}
          className={className}
        >
          <div className="flex items-center gap-2">
            <T defaultMessage="Add post" id="Posts.Add" />
            {onlyAdminsCanPost && (
              <Tooltip
                tooltip={
                  <T
                    id="Blocks.SpaceHeader.AddPostButton.Hidden.Tooltip"
                    defaultMessage="This button is only visible to the admins and staff members. You can change the CMS models in space settings to allow other members to post."
                  />
                }
              >
                <SvgIcon name="eye-off" size="sm" />
              </Tooltip>
            )}
          </div>
        </Button>
      </Dropdown.ButtonMinimal>
      <Dropdown.Items>
        {postsAvailableToUser.map(postType => (
          <Dropdown.Item
            key={postType.id}
            leadingIcon={<SvgIcon name="post" />}
            as={Link}
            to={`${space.relativeUrl}/new?post_type=${postType.id}`}
          >
            <div className="flex items-center gap-2">
              {getTranslatedAddPostText(intl, postType.name)}
              {postTypePermissions[postType.id]?.whoCanPost === 'admins' && (
                <Tooltip
                  tooltip={
                    <T
                      id="Blocks.SpaceHeader.AddPostButton.Option.Hidden.Tooltip"
                      defaultMessage="This option is only visible to the admins and staff members. You can change the CMS models in space settings to allow other members to post."
                    />
                  }
                >
                  <SvgIcon name="eye-off" size="sm" />
                </Tooltip>
              )}
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Items>
    </Dropdown>
  )
}
