import { clsx } from 'clsx'

import type { Image } from '@tribeplatform/gql-client/types'
import { useAuthToken } from '@tribeplatform/react-sdk/hooks'
import { useBlock } from '@tribeplatform/react-slate-kit/hooks'

import { Badges } from '../fields/Badges.js'
import { CustomField } from '../fields/CustomField.js'
import { GalleryBanner } from '../fields/GalleryBanner.js'
import { ListAvatar } from '../fields/ListAvatar.js'
import { ListMemberInfo } from '../fields/ListMemberInfo.js'
import { MemberName } from '../fields/MemberName.js'
import { MessageButton } from '../fields/MessageButton.js'
import { Tagline } from '../fields/Tagline.js'
import { FieldProps, MembersBlockProps } from '../types.js'
import { getTitleVariant } from '../utils/utils.js'

export const Field = ({
  field,
  member,
  activeFieldId,
  viewStyle,
}: FieldProps) => {
  const {
    extraContext: { activated },
  } = useBlock<MembersBlockProps, { activated: boolean }>()

  const {
    data: { member: authMember },
  } = useAuthToken()

  const mediaUrl =
    (member?.profilePicture as Image)?.urls?.medium ||
    (member?.profilePicture as Image)?.url
  const stretchX = clsx(
    viewStyle === 'list' && field.settings?.stretchX && 'flex-1 max-w-full',
    viewStyle === 'gallery' &&
      field.settings?.fullWidth &&
      'basis-full max-w-full',
    viewStyle === 'carousel' &&
      field.settings?.fullWidth &&
      'basis-full max-w-full',
    viewStyle === 'magazine' && 'basis-full max-w-full',
  )
  const titleVariant = getTitleVariant(field.settings?.titleSize)
  const highlighted = activated && activeFieldId === field.id

  switch (field.id) {
    case 'listAvatar':
    case 'magazineAvatar':
      return (
        <ListAvatar
          field={field}
          mediaUrl={mediaUrl}
          member={member}
          highlighted={highlighted}
        />
      )
    case 'galleryBanner':
      return (
        <GalleryBanner
          field={field}
          mediaUrl={mediaUrl}
          member={member}
          highlighted={highlighted}
          aspectRatio={field.settings?.aspectRatio}
        />
      )
    case 'listMemberName':
    case 'galleryMemberName':
      return (
        <MemberName
          member={member}
          highlighted={highlighted}
          stretchX={stretchX}
          titleVariant={titleVariant}
        />
      )
    case 'listTagline':
    case 'galleryTagline':
      return (
        <Tagline
          member={member}
          highlighted={highlighted}
          stretchX={stretchX}
        />
      )
    case 'listBadges':
    case 'galleryBadges':
      return (
        <Badges member={member} highlighted={highlighted} stretchX={stretchX} />
      )
    case 'listMemberInfo':
      return (
        <ListMemberInfo
          field={field}
          highlighted={highlighted}
          stretchX={stretchX}
          fieldProps={{ member, activeFieldId, viewStyle }}
          FieldRenderer={Field}
        />
      )
    case 'listMessageButton':
    case 'magazineMessageButton':
      return (
        <MessageButton
          member={member}
          highlighted={highlighted}
          stretchX={stretchX}
          authMember={authMember}
          fullWidth={viewStyle !== 'magazine'}
          buttonVariant={field.settings?.buttonVariant}
        />
      )
    default:
      return (
        <CustomField
          field={field}
          member={member}
          highlighted={highlighted}
          viewStyle={viewStyle}
          stretchX={stretchX}
        />
      )
  }
}
