import type { MemberFields } from '@tribeplatform/gql-client/graphql'
import type { Member, QueryMemberArgs } from '@tribeplatform/gql-client/types'

import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getMemberKey } from '../../utils/keys/member.key.js'
import { useCachedMember } from '../cache/useCachedMember.js'

export const useMember = (options: {
  variables: QueryMemberArgs
  fields?: MemberFields
  useQueryOptions?: UseQueryOptions<Member>
}) => {
  const { variables, fields = 'default', useQueryOptions } = options
  const { client } = useTribeClient()
  const memberKey = getMemberKey({ variables, fields })
  const memberPlaceHolder = useCachedMember(variables?.id)

  return useQuery<Member>(
    memberKey,
    () => client.members.get(variables, fields),
    {
      placeholderData: () => memberPlaceHolder,
      ...useQueryOptions,
    },
  )
}
