import { lazy, Suspense } from 'react'

import { Navigate, Route, Routes } from 'react-router'
import { Outlet } from 'react-router-dom'

import {
  CMSEmptyState,
  CMSFormPreview,
} from '@tribeplatform/react-components/CMS'
import { SlateLayout } from '@tribeplatform/react-components/LayoutFrame'
import { Container } from '@tribeplatform/react-ui-kit/Layout'

import type { InjectedAppProps } from '../server/types/server.types.js'
import { Templates } from './components/Admin/Templates/index.js'
import { Analytics } from './components/Analytics/Analytics.js'
import { RouterErrorBoundary } from './components/Error/RouterErrorBoundary.js'
import { PageLayout } from './components/Layout/PageLayout.js'
import { MemberSettingsRouteGuard } from './components/Settings/MemberSettingsRouteGuard.js'
import { SettingsLayout } from './components/Settings/SettingsLayout.js'
import { SlateApp } from './SlateApp.js'

const WhatsNew = lazy(() =>
  import('./components/Admin/Dashboard/WhatsNew.js').then(m => ({
    default: m.WhatsNew,
  })),
)
const Preview = lazy(() =>
  import('./components/Admin/Editor/Preview/index.js').then(m => ({
    default: m.Preview,
  })),
)
const EmailLogs = lazy(() =>
  import('./components/Admin/EmailLogs/index.js').then(m => ({
    default: m.EmailLogs,
  })),
)
const AdminRouteGuard = lazy(() =>
  import('./components/Admin/index.js').then(m => ({
    default: m.AdminRouteGuard,
  })),
)
const MemberBadges = lazy(() =>
  import('./components/Admin/MemberBadges/index.js').then(m => ({
    default: m.MemberBadges,
  })),
)
const MemberFields = lazy(() =>
  import('./components/Admin/MemberFields/index.js').then(m => ({
    default: m.MemberFields,
  })),
)
const Staff = lazy(() =>
  import('./components/Admin/Members/Staff.js').then(m => ({
    default: m.Staff,
  })),
)
const Members = lazy(() =>
  import('./components/Admin/Members/index.js').then(m => ({
    default: m.Members,
  })),
)
const Invitations = lazy(() =>
  import('./components/Admin/Members/Invitations.js').then(m => ({
    default: m.Invitations,
  })),
)
const ModerationSettings = lazy(() =>
  import('./components/Admin/ModerationSettings/index.js').then(m => ({
    default: m.ModerationSettings,
  })),
)
const Onboarding = lazy(() =>
  import('./components/Admin/Onboarding/index.js').then(m => ({
    default: m.Onboarding,
  })),
)
const Posts = lazy(() =>
  import('./components/Admin/Posts/index.js').then(m => ({
    default: m.Posts,
  })),
)
const Security = lazy(() =>
  import('./components/Admin/Security/index.js').then(m => ({
    default: m.Security,
  })),
)
const Authentication = lazy(() =>
  import('./components/Admin/Settings/Authentication/index.js').then(m => ({
    default: m.Authentication,
  })),
)
const Settings = lazy(() =>
  import('./components/Admin/Settings/CommunitySettings/index.js').then(m => ({
    default: m.Settings,
  })),
)
const Domain = lazy(() =>
  import('./components/Admin/Settings/Domain/index.js').then(m => ({
    default: m.Domain,
  })),
)
const LocalizationSettings = lazy(() =>
  import('./components/Admin/Settings/Localization/index.js').then(m => ({
    default: m.LocalizationSettings,
  })),
)
const Notifications = lazy(() =>
  import('./components/Admin/Settings/Notifications/index.js').then(m => ({
    default: m.Notifications,
  })),
)
const SeoSettings = lazy(() =>
  import('./components/Admin/Settings/SeoSettings/SeoSettings.js').then(m => ({
    default: m.SeoSettings,
  })),
)
const Spaces = lazy(() =>
  import('./components/Admin/Spaces/index.js').then(m => ({
    default: m.Spaces,
  })),
)
const Tags = lazy(() =>
  import('./components/Admin/Tags/index.js').then(m => ({
    default: m.Tags,
  })),
)

const SpaceRoutes = lazy(() =>
  import('./components/SpaceRoutes.component.js').then(m => ({
    default: m.SpaceRoutes,
  })),
)

const PostEmbed = lazy(() =>
  import('./embed/Post/index.js').then(m => ({
    default: m.PostEmbed,
  })),
)

const SpaceEmbed = lazy(() =>
  import('./embed/Space/index.js').then(m => ({
    default: m.SpaceEmbed,
  })),
)

const Unsubscribe = lazy(() =>
  import('./components/Settings/Unsubscribe.js').then(m => ({
    default: m.Unsubscribe,
  })),
)

const DownloadMembers = lazy(() =>
  import('./components/Admin/Members/DownloadMembers.js').then(m => ({
    default: m.DownloadMembers,
  })),
)

const MessagingSettings = lazy(() =>
  import('./components/Admin/Messaging/index.js').then(m => ({
    default: m.Messaging,
  })),
)

const Messaging = lazy(() =>
  import('./components/Messaging/index.js').then(m => ({
    default: m.Messaging,
  })),
)

const MessagingLayout = lazy(() =>
  import('./components/Messaging/MessagingLayout.js').then(m => ({
    default: m.MessagingLayout,
  })),
)

const Moderation = lazy(() =>
  import('./components/Moderation/index.js').then(m => ({
    default: m.Moderation,
  })),
)

const OAuth = lazy(() =>
  import('./components/OAuth/OAuth.js').then(m => ({ default: m.OAuth })),
)

const BillingPage = lazy(() =>
  import('./components/Admin/Billing/BillingPage/index.js').then(m => ({
    default: m.BillingPage,
  })),
)
const PlansPage = lazy(() =>
  import('./components/Admin/Billing/PlansPage/index.js').then(m => ({
    default: m.PlansPage,
  })),
)
const UsagesPage = lazy(() =>
  import('./components/Admin/Billing/UsagesPage/index.js').then(m => ({
    default: m.UsagesPage,
  })),
)

const StorePage = lazy(() =>
  import('./components/Admin/Store/StorePage.js').then(m => ({
    default: m.StorePage,
  })),
)
const StoreAddonPage = lazy(() =>
  import('./components/Admin/Store/Addon/StoreAddonPage.js').then(m => ({
    default: m.StoreAddonPage,
  })),
)
const StoreAppPage = lazy(() =>
  import('./components/Admin/Store/App/StoreAppPage.js').then(m => ({
    default: m.StoreAppPage,
  })),
)

const Logout = lazy(() =>
  import('./components/Logout.js').then(m => ({
    default: m.Logout,
  })),
)

const MemberAccountSettings = lazy(() =>
  import('./components/Settings/AccountSettings.js').then(m => ({
    default: m.AccountSettings,
  })),
)
const MemberColorSchemeSettings = lazy(() =>
  import('./components/Settings/ColorSchemeSettings.js').then(m => ({
    default: m.ColorSchemeSettings,
  })),
)
const MemberConnectedApps = lazy(() =>
  import('./components/Settings/MemberConnectedApps/index.js').then(m => ({
    default: m.MemberConnectedApps,
  })),
)
const MemberNotificationSettings = lazy(() =>
  import('./components/Settings/Notifications/NotificationSettings.js').then(
    m => ({
      default: m.NotificationSettings,
    }),
  ),
)
const MemberSecuritySettings = lazy(() =>
  import('./components/Settings/SecuritySettings.js').then(m => ({
    default: m.SecuritySettings,
  })),
)
const MemberMessagingSettings = lazy(() =>
  import('./components/Settings/MessagingSettings.js').then(m => ({
    default: m.MessagingSettings,
  })),
)

interface Props {
  injectedAppProps: InjectedAppProps
}

export const AppRoutes = ({ injectedAppProps }: Props) => {
  return (
    <Routes>
      <Route
        path="/admin"
        element={<Navigate to="/manage/people/members" replace />}
      />
      <Route
        path="/manage/people/members/export/:id"
        element={
          <Suspense fallback={<></>}>
            <DownloadMembers />
          </Suspense>
        }
        errorElement={<RouterErrorBoundary />}
      />
      <Route
        path="/manage/*"
        element={
          <Suspense fallback={<></>}>
            <AdminRouteGuard>
              <Outlet />
            </AdminRouteGuard>
          </Suspense>
        }
        errorElement={<RouterErrorBoundary />}
      >
        <Route
          index
          element={<Navigate to="/manage/people/members" replace />}
        />
        <Route path="people/fields" element={<MemberFields />} />
        <Route path="people/badges" element={<MemberBadges />} />

        <Route
          path="customizer/templates"
          element={
            <Container
              size="full"
              padding={{
                vertical: 'md',
                horizontal: { size: 'md', disableOnMobile: true },
              }}
            >
              <Templates />
            </Container>
          }
        />
        {[
          'customizer/create-space/:templateId',
          'customizer/create-space',
          'customizer/templates/new',
          'customizer/templates/edit/:templateId/',
          'customizer/cms/:postTypeId',
          'customizer/cms/:postTypeId/blocks',
          'customizer/spaces/:id',
          'customizer/spaces/:id/blocks',
          'customizer/colors',
          'customizer/navigation',
          'customizer/typography',
          'customizer/cms',
          'customizer/spaces',
          'customizer',
          'appearance/*',
        ].map(path => (
          <Route key="preview" path={path} element={<Preview />} />
        ))}

        {/* With default padding */}
        <Route
          path="*"
          element={
            <Container
              size="full"
              padding={{
                vertical: 'md',
                horizontal: { size: 'md', disableOnMobile: true },
              }}
              containerProps={{
                className: '@container min-h-full relative',
              }}
            >
              <Outlet />
            </Container>
          }
        >
          <Route path="dashboard/whats-new" element={<WhatsNew />} />
          <Route path="reports/email-logs" element={<EmailLogs />} />

          <Route path="people/members" element={<Members />} />
          <Route path="people/invitations" element={<Invitations />} />

          <Route
            path="people/admin"
            element={<Navigate to="/manage/people/staff" replace />}
          />
          <Route path="people/staff" element={<Staff />} />

          <Route path="content" element={<Posts />} />
          <Route path="content/posts" element={<Posts />} />
          <Route path="content/spaces" element={<Spaces />} />
          <Route path="content/tags" element={<Tags />} />
          <Route path="content/cms" element={<CMSEmptyState />} />
          <Route path="content/cms/:slug" element={<CMSFormPreview />} />
          <Route path="settings/network" element={<Settings />} />
          <Route
            path="settings/localization"
            element={<LocalizationSettings />}
          />
          <Route path="settings/authentication" element={<Authentication />} />
          <Route path="settings/domain" element={<Domain />} />
          <Route path="settings/messaging" element={<MessagingSettings />} />
          <Route path="settings/notifications" element={<Notifications />} />
          <Route path="settings/seo" element={<SeoSettings />} />
          <Route path="settings/security" element={<Security />} />

          <Route path="settings/moderation" element={<ModerationSettings />} />

          <Route path="billing" element={<BillingPage />} />
          <Route path="billing/plans" element={<PlansPage />} />
          <Route path="billing/usages" element={<UsagesPage />} />

          <Route path="app-store/:category" element={<StorePage />} />
          <Route
            path="app-store/addons/:addonId"
            element={<StoreAddonPage />}
          />
          <Route path="app-store/apps/:appId" element={<StoreAppPage />} />

          <Route
            path="reports/*"
            element={
              <Analytics
                networkId={injectedAppProps?.network?.id}
                networkCreatedAt={injectedAppProps?.network?.createdAt}
              />
            }
          />

          <Route path="onboarding" element={<Onboarding />} />
        </Route>
      </Route>

      <Route
        path="/auth/logout"
        element={
          <Suspense fallback={<></>}>
            <Logout />
          </Suspense>
        }
        errorElement={<RouterErrorBoundary />}
      />
      <Route
        path="/embed/post/:id"
        element={
          <Suspense fallback={<></>}>
            <PostEmbed />
          </Suspense>
        }
        errorElement={<RouterErrorBoundary />}
      />
      <Route
        path="/embed/space/:slug"
        element={
          <Suspense fallback={<></>}>
            <SpaceEmbed />
          </Suspense>
        }
        errorElement={<RouterErrorBoundary />}
      />

      <Route
        path="/settings/notifications/unsubscribe"
        element={
          <Suspense fallback={<></>}>
            <Unsubscribe />
          </Suspense>
        }
        errorElement={<RouterErrorBoundary />}
      />

      <Route
        path="*"
        element={
          <SlateApp
            networkId={injectedAppProps?.network?.id}
            networkExtraProperties={injectedAppProps?.network?.extraProperties}
            memberId={injectedAppProps?.authMember?.id}
          >
            <Outlet />
          </SlateApp>
        }
        errorElement={<RouterErrorBoundary />}
      >
        <Route
          path="messaging"
          element={
            <Suspense fallback={<></>}>
              <Messaging>
                <Outlet />
              </Messaging>
            </Suspense>
          }
        >
          <Route path="new" element={<MessagingLayout newChat chatOpen />} />
          <Route path=":slug" element={<MessagingLayout chatOpen />} />
          <Route path="*" element={<MessagingLayout />} />
        </Route>

        <Route
          path="oauth/authorize"
          element={
            <Suspense fallback={<></>}>
              <OAuth />
            </Suspense>
          }
        />

        <Route
          path="settings/moderation"
          element={
            <MemberSettingsRouteGuard>
              <PageLayout layout={SlateLayout.headerNoSidebar}>
                <Container
                  size="full"
                  padding={{
                    vertical: 'md',
                    horizontal: { size: 'md', disableOnMobile: true },
                  }}
                >
                  <Suspense fallback={<></>}>
                    <Outlet />
                  </Suspense>
                </Container>
              </PageLayout>
            </MemberSettingsRouteGuard>
          }
        >
          <Route index element={<Moderation />} />
          <Route path=":spaceId/:section?" element={<Moderation />} />
        </Route>

        <Route
          path="settings/*"
          element={
            <MemberSettingsRouteGuard>
              <SettingsLayout>
                <Suspense fallback={<></>}>
                  <Outlet />
                </Suspense>
              </SettingsLayout>
            </MemberSettingsRouteGuard>
          }
        >
          <Route path="account" element={<MemberAccountSettings />} />
          <Route path="security" element={<MemberSecuritySettings />} />
          <Route
            path="notifications"
            element={<MemberNotificationSettings />}
          />
          <Route path="appearance" element={<MemberColorSchemeSettings />} />
          <Route path="messaging" element={<MemberMessagingSettings />} />
          <Route path="connected-apps" element={<MemberConnectedApps />} />
        </Route>

        <Route
          path="*"
          element={
            <Suspense fallback={<></>}>
              <SpaceRoutes
                networkName={injectedAppProps?.network?.name}
                headerDisabled={
                  injectedAppProps?.network?.navigationSlates?.headerDisabled
                }
              />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  )
}
