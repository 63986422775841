import { cva } from 'class-variance-authority'

export const selectItemStyles = cva(
  ['cursor-default select-none relative py-2 px-3'],
  {
    variants: {
      active: {
        true: 'text-content bg-surface-selected pe-9 cursor-pointer',
        false: 'text-content bg-surface',
      },
      selected: { true: 'font-semibold', false: 'font-normal' },
      disabled: { true: 'opacity-50 cursor-not-allowed' },
    },
  },
)

export const selectItemIconStyles = cva('h-5 w-5 shrink-0', {
  variants: {
    active: {
      true: 'text-content',
      false: 'text-content',
    },
  },
})

export const selectItemsStyles = cva([
  'w-full min-w-[160px] text-content bg-surface shadow-popover max-h-60 rounded-base py-1 text-md border border-line-subdued overflow-auto',
  'empty:hidden',
  'overscroll-contain',
  'focus-visible:ring-1 focus:outline-none',
])
