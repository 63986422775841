import { PickerIcon, PickerIconPack } from '../Picker/types.js'

const isCodepoint = (num: string) => Boolean(num.match(/^[0-9a-f\\-]+$/i))

export const nativeEmojiFromCodepoint = (code?: string): string | null => {
  if (!code) {
    return null
  }

  if (!isCodepoint(code)) {
    return null
  }

  try {
    return code
      .split('-')
      .map(codepoint => String.fromCodePoint(parseInt(`0x${codepoint}`, 16)))
      .join('')
  } catch (e) {
    return null
  }
}

export const pickerIconFromGlyphText = (
  glyphText: string,
  style: PickerIconPack,
): PickerIcon => {
  const iconName = glyphText.replace(/^(line-|solid-|duocolor-)/, '')
  return {
    id: `${style}-${iconName}`,
    pack: style,
    name: iconName,
  }
}

export const iconResourceUrl = (
  icon: PickerIcon,
  { subfolder }: { subfolder?: string },
) => {
  return `${subfolder || ''}/icons/${icon.pack}/${icon.name}.svg`
}
