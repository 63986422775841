import {
  PostLastActivityEntityFields,
  postLastActivityEntityGQLFields,
} from './post-last-activity-entity.fields.js'

export type PostLastActivityDetailFields =
  | 'basic'
  | 'all'
  | 'default'
  | CustomPostLastActivityDetailFields

interface CustomPostLastActivityDetailFields {
  entity?: PostLastActivityEntityFields
}

const BASIC_POST_LAST_ACTIVITY_DETAIL_FIELDS: CustomPostLastActivityDetailFields =
  {}
const ALL_POST_LAST_ACTIVITY_DETAIL_FIELDS: CustomPostLastActivityDetailFields =
  {
    entity: 'basic',
  }
const DEFAULT_POST_LAST_ACTIVITY_DETAIL_FIELDS: CustomPostLastActivityDetailFields =
  {
    entity: {
      onPost: 'basic',
      onPostReactionParticipant: {
        participant: 'basic',
        reaction: {
          fields: 'all',
          variables: {
            limit: 25,
          },
        },
      },
    },
  }

export const postLastActivityDetailGQLFields = (
  fields: PostLastActivityDetailFields,
) => {
  if (fields === 'basic') fields = BASIC_POST_LAST_ACTIVITY_DETAIL_FIELDS
  if (fields === 'all') fields = ALL_POST_LAST_ACTIVITY_DETAIL_FIELDS
  if (fields === 'default') fields = DEFAULT_POST_LAST_ACTIVITY_DETAIL_FIELDS

  return `
    entityId
    entityType
    ${
      fields.entity
        ? `
      entity {
        ${postLastActivityEntityGQLFields(fields.entity)}
      }
    `
        : ``
    }
  `
}
