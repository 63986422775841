import { useCallback } from 'react'

import type { SearchEntityFields } from '@tribeplatform/gql-client/graphql'
import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  QuerySearchArgs,
  SearchResult,
} from '@tribeplatform/gql-client/types'

import { useQuery, UseQueryOptions } from '../lib/react-query/useQuery.js'
import { useQueryClient } from '../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../useTribeClient.js'
import { getSearchKey } from '../utils/keys/post.key.js'

export const useSearch = (options?: {
  variables?: QuerySearchArgs
  fields?: SearchEntityFields
  useQueryOptions?: UseQueryOptions<SearchResult, ClientError>
}) => {
  const { variables, fields, useQueryOptions } = options || {}
  const queryClient = useQueryClient()
  const { client } = useTribeClient()

  const asyncSearch = useCallback(
    async (variables: QuerySearchArgs) => {
      const data = await queryClient.fetchQuery<SearchResult, ClientError>(
        getSearchKey({ variables, fields }),
        () => client.search(variables, fields),
        useQueryOptions,
      )
      return data
    },
    [queryClient, client, useQueryOptions, fields],
  )

  return {
    ...useQuery<SearchResult, ClientError>(
      getSearchKey({ variables, fields }),
      () => client.search(variables, fields),
      { ...useQueryOptions, enabled: !!variables?.input?.query },
    ),
    asyncQuery: asyncSearch,
  }
}
