import { blockGQLFields } from './block.js'

export type SlateFields = 'all'

export const slateGQLFields = (fields: SlateFields) => `
  rootBlock
  blocks {
    ${blockGQLFields(fields)}
  }
  restrictions {
    nonEditableBlocks
    lockedChildrenBlocks
    nonRemovableBlocks
  }
`
