import { ReactNode, useMemo } from 'react'

import { Hydrate as HydrateReactQuery } from '@tanstack/react-query'

import type { InjectedAppProps } from '../../../server/types/server.types.js'
import { getDehydratedState } from './utils/hydrate.utils.js'

interface Props {
  injectedAppProps: InjectedAppProps
  children: ReactNode
}

export const Hydrate = ({ injectedAppProps, children }: Props) => {
  const dehydratedState = useMemo(() => {
    return getDehydratedState(injectedAppProps)
  }, [injectedAppProps])

  return (
    <HydrateReactQuery state={dehydratedState}>{children}</HydrateReactQuery>
  )
}
