import { MediaFields, mediaGQLFields } from '../media/index.js'
import {
  MemberAuthMemberPropsFields,
  memberAuthMemberPropsGQLFields,
} from '../permission/index.js'
import { MemberExtraPropertiesFields } from '../properties/index.js'
import { MemberBadgeFields, memberBadgeGQLFields } from './badge.fields.js'
import { roleGQLFields, RoleFields } from './role.fields.js'

export type MemberFields = 'basic' | 'all' | 'default' | CustomMemberFields

export interface CustomMemberFields {
  profilePicture?: MediaFields
  badges?: MemberBadgeFields
  banner?: MediaFields
  authMemberProps?: MemberAuthMemberPropsFields
  role?: RoleFields
  fields?: 'basic' | 'all'
  sessions?: 'basic'
  activeSession?: 'basic'
  score?: 'basic' | 'all'
  extraProperties?: MemberExtraPropertiesFields
  settings?: 'basic' | 'all'
  blockedMembers?: MemberFields
}

const BASIC_MEMBER_FIELDS: CustomMemberFields = {}
const ALL_MEMBER_FIELDS: CustomMemberFields = {
  profilePicture: 'basic',
  badges: 'basic',
  banner: 'basic',
  authMemberProps: 'basic',
  fields: 'basic',
  sessions: 'basic',
  activeSession: 'basic',
  score: 'basic',
  settings: 'basic',
  blockedMembers: 'basic',
}
const DEFAULT_MEMBER_FIELDS: CustomMemberFields = {
  authMemberProps: 'all',
  badges: 'default',
  banner: 'all',
  profilePicture: 'all',
  role: 'basic',
  fields: 'all',
  settings: 'basic',
  extraProperties: {
    variables: {},
  },
}

export function memberGQLFields(fields: MemberFields): string {
  if (fields === 'basic') {
    fields = BASIC_MEMBER_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_MEMBER_FIELDS
  }
  if (fields === 'default') {
    fields = DEFAULT_MEMBER_FIELDS
  }

  return `
    displayName
    name
    id
    locale
    profilePictureId
    bannerId
    status
    username
    email
    emailStatus
    newEmail
    tagline
    lastSeenAt
    createdAt
    updatedAt
    relativeUrl
    url
    externalId
    roleId
    flagged
    teammate
    staffReasons
    timeZone
    ${
      fields.role
        ? `
        role {
          ${roleGQLFields(fields.role)}
        }
      `
        : ''
    }
    ${
      fields.banner
        ? `
      banner {
        ${mediaGQLFields(fields.banner)}
      }
    `
        : ``
    }
    ${
      fields.profilePicture
        ? `
      profilePicture {
        ${mediaGQLFields(fields.profilePicture)}
      }
    `
        : ``
    }
    ${
      fields.authMemberProps
        ? `
      authMemberProps {
        ${memberAuthMemberPropsGQLFields(fields.authMemberProps)}
      }
    `
        : ``
    }
    ${
      fields.badges
        ? `
      badges {
        ${memberBadgeGQLFields(fields.badges)}
      }
    `
        : ``
    }
    ${
      fields.fields
        ? `
      fields {
        key
        value
      }
    `
        : ``
    }
    ${
      fields.sessions
        ? `
      sessions {
        active
        country
        createdAt
        deviceBrand
        id
        ip
        lastActivityAt
        os
        osVersion
      }
    `
        : ``
    }
    ${
      fields.activeSession
        ? `
      activeSession {
        active
        country
        createdAt
        deviceBrand
        id
        ip
        lastActivityAt
        os
        osVersion
      }
    `
        : ``
    }
    ${fields.score ? 'score' : ''}
    ${
      fields?.extraProperties
        ? `
      extraProperties ${
        fields?.extraProperties?.variables?.keys
          ? `(keys: ["${fields.extraProperties.variables.keys.join('","')}"])`
          : ``
      } {
        key
        value
      }
    `
        : ``
    }
    ${
      fields.settings
        ? `
    settings {
      privateMessaging {
        privateMessagingEnabled
      }
    }
    `
        : ``
    }
    ${
      fields.blockedMembers
        ? `
       blockedMemberIds
       blockedMembers {
        ${memberGQLFields(fields.blockedMembers)}
      }
    `
        : ``
    }
  `
}
