import React, { isValidElement } from 'react'

import { useRouter } from '@tribeplatform/react-sdk'
import {
  Dropdown,
  DropdownItemProps,
} from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import type { PostActionItem } from './hooks/usePostActionItems.js'
import type { PostContext } from './types.js'

type ActionItemProps = PostActionItem & {
  isShortcut: boolean
  context: PostContext
  hideHotkeys: boolean
}
export const ActionItem = ({
  action,
  hotkey,
  icon: iconName,
  name,
  url,
  disabled,
  isShortcut,
  context,
  hideHotkeys,
}: ActionItemProps) => {
  const { Link } = useRouter()
  const handleClick: DropdownItemProps['onClick'] = e => {
    if (isShortcut) {
      e.preventDefault()
    }
    action?.()
  }
  const Icon =
    typeof iconName === 'string' ? (
      <SvgIcon name={iconName} className="flex-none" />
    ) : (
      isValidElement(iconName) && iconName
    )

  const hotkeyValue = hideHotkeys || context === 'admin' ? undefined : hotkey
  if (action) {
    return (
      <Dropdown.Item
        onClick={handleClick}
        hotkey={hotkeyValue}
        disabled={disabled}
        leadingIcon={Icon}
      >
        {name}
      </Dropdown.Item>
    )
  }

  return (
    <Dropdown.Item
      as={Link}
      href={url}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      variant="inherit"
      hotkey={hotkeyValue}
      disabled={disabled}
      onClick={handleClick}
      leadingIcon={Icon}
    >
      {name}
    </Dropdown.Item>
  )
}
