import { ReactNode, useState } from 'react'

import * as fuzzysort from 'fuzzysort'

import { Avatar } from '../../Avatar/Avatar.js'
import type { AvatarProps } from '../../Avatar/Avatar.js'
import { SearchableSelect } from '../../SearchableSelect/SearchableSelect.js'
import { FormControlLayout } from '../FormControlLayout.js'
import { FormControlProps } from '../types.js'
import { extractLayoutProps } from '../utils.js'

export type FormControlSearchableSelectProps = FormControlProps & {
  value: string
  options: { value: string; key: string; text: ReactNode; avatar?: string }[]
  onChange: (newValue: string) => void
  disabled?: boolean
  loading?: boolean
  hideIcon?: boolean
  hideClearIcon?: boolean
  avatarProps?: AvatarProps
  onInputChange?: (newValue: string) => void
}

export const FormControlSearchableSelect = ({
  onChange,
  placeholder,
  value,
  avatarProps,
  options,
  onInputChange,
  loading = false,
  hideIcon = false,
  hideClearIcon = false,
  ...rest
}: FormControlSearchableSelectProps) => {
  const { layoutProps, inputProps } = extractLayoutProps(rest)

  const [query, setQuery] = useState<string>()

  // if you don't pass in onInputChange then we use fuzzysort
  const isAsyncSearching = typeof onInputChange === 'function'
  const filteredOptions =
    !isAsyncSearching && query
      ? fuzzysort.go(query, options ?? [], { key: 'key' }).map(res => res.obj)
      : options

  const chosenOption = options?.find(option => option?.value === value)

  return (
    <FormControlLayout {...layoutProps}>
      <SearchableSelect
        {...inputProps}
        options={filteredOptions ?? []}
        value={chosenOption}
        onChange={item => onChange(item?.value ?? '')}
        onInputChange={value => {
          if (isAsyncSearching) {
            onInputChange(value)
          } else {
            setQuery(value)
          }
        }}
      >
        <SearchableSelect.Button
          clearable={!hideClearIcon}
          hideIcon={hideIcon}
          placeholder={placeholder}
        >
          {chosenOption?.avatar ? (
            <div className="flex items-center">
              <Avatar
                src={chosenOption.avatar}
                name={
                  typeof chosenOption?.text === 'string'
                    ? chosenOption.text
                    : ''
                }
                {...avatarProps}
              />
              <span className="ms-3 block truncate">{chosenOption?.text}</span>
            </div>
          ) : (
            <span className="block truncate">{chosenOption?.text}</span>
          )}
        </SearchableSelect.Button>
        <SearchableSelect.Items>
          {filteredOptions?.map(option => (
            <SearchableSelect.Item key={option?.value} value={option?.value}>
              {option?.avatar ? (
                <div className="flex items-center">
                  <Avatar
                    src={option.avatar}
                    name={typeof option?.text === 'string' ? option.text : ''}
                    {...avatarProps}
                  />
                  <span className="ms-3 block truncate">{option?.text}</span>
                </div>
              ) : (
                <span className="ms-3 block truncate">{option?.text}</span>
              )}
            </SearchableSelect.Item>
          ))}
          {filteredOptions?.length === 0 && (
            <SearchableSelect.ItemsEmpty>
              No results
            </SearchableSelect.ItemsEmpty>
          )}
          {loading && (
            <SearchableSelect.ItemsEmpty>
              Loading...
            </SearchableSelect.ItemsEmpty>
          )}
        </SearchableSelect.Items>
      </SearchableSelect>
    </FormControlLayout>
  )
}
