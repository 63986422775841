import type { AuthTokenFields } from '@tribeplatform/gql-client/graphql'
import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  AuthToken,
  MutationVerifyMemberArgs,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  type UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useTribeClient } from '../../useTribeClient.js'

export const useVerifyMember = (options?: {
  fields?: AuthTokenFields
  useMutationOptions?: UseMutationOptions<
    AuthToken,
    ClientError,
    MutationVerifyMemberArgs
  >
}) => {
  const { useMutationOptions, fields = 'default' } = options || {}
  const { client } = useTribeClient()

  return useMutation<AuthToken, ClientError, MutationVerifyMemberArgs>(
    (input: MutationVerifyMemberArgs) =>
      client.auth.verifyMember(input, fields),
    useMutationOptions,
  )
}
