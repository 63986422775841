import type { QueryTranslateMarkupArgs } from '@tribeplatform/gql-client/types'

import { KeyConstructor } from './keyConstructor.js'

const TRANSLATE_MARKUP_KEY = 'translateMarkup'

export const getTranslateMarkupKey = KeyConstructor<
  QueryTranslateMarkupArgs,
  never
>(TRANSLATE_MARKUP_KEY)
