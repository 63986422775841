import ColorContrastChecker from 'color-contrast-checker'

import { CustomFieldType } from '@tribeplatform/gql-client/types'
import { Badge } from '@tribeplatform/react-ui-kit/Badge'

import { getColoredLabels } from '../../CMS/utils/utils.js'
import {
  CustomFieldSubtype,
  CustomFieldViewProps,
  getFieldSetting,
} from '../utils.js'

export const PillView = ({
  value,
  field,
  trueText,
  falseText,
}: CustomFieldViewProps & { trueText: string; falseText: string }) => {
  const contrastChecker = new ColorContrastChecker()

  let text
  let color
  let textColor
  if (field?.type === CustomFieldType.boolean) {
    if (!trueText && !falseText) {
      trueText = 'Checked'
    }

    text = value ? trueText : falseText
    if (!text) {
      return null
    }
  } else {
    text = value
  }

  if (!text) {
    return null
  }

  const subtype = getFieldSetting(field, 'subtype')
  if (subtype === CustomFieldSubtype.SELECT) {
    const coloredLabels = getColoredLabels(field)
    const currentOption = coloredLabels?.find(option => option?.id === value)
    color = currentOption?.colorHex
    text = currentOption?.name
    if (color) {
      textColor =
        contrastChecker.hexToLuminance(color) < 0.5 ? '#ffffff' : '#000000'
    }
  }

  return (
    <div className="flex items-center">
      <Badge
        size="lg"
        style={{ backgroundColor: color, color: textColor }}
        truncate
      >
        {text}
      </Badge>
    </div>
  )
}
