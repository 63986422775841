import { dayjs } from '../lib/dayjs.js'

export const getLocalizedDateFormat = (
  value,
  currentLocale: string,
  timeZone?: string,
): {
  date: string
  dateTime: string
  time: string
} => {
  const localDate = dayjs.utc(value).tz()

  try {
    const targetLocale = navigator.language
    if (currentLocale.startsWith('en') && targetLocale.startsWith('en')) {
      const timeFormattingOptions: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        timeZone,
      }
      const dateFormattingOptions: Intl.DateTimeFormatOptions = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        timeZone,
      }

      return {
        date: localDate
          .toDate()
          .toLocaleDateString(targetLocale, dateFormattingOptions),
        dateTime: localDate.toDate().toLocaleDateString(targetLocale, {
          ...dateFormattingOptions,
          ...timeFormattingOptions,
        }),
        time: localDate
          .toDate()
          .toLocaleTimeString(targetLocale, timeFormattingOptions),
      }
    }

    return {
      date: localDate.format('L'),
      dateTime: localDate.format('L LT'),
      time: localDate.format('LT'),
    }
  } catch (error) {
    // in case document is undefined
    return {
      date: localDate.format('L'),
      dateTime: localDate.format('L LT'),
      time: localDate.format('LT'),
    }
  }
}

export const convertTzToUtc = (
  date: string,
  format = 'YYYY-MM-DDTHH:mm:ss',
) => {
  return dayjs.tz(date).utc().format(format)
}

export const convertUtcToTz = (
  date: string,
  format = 'YYYY-MM-DDTHH:mm:ss',
) => {
  return dayjs.utc(date).tz().format(format)
}

export const formatUtcDateTime = (
  date: string,
  format = 'YYYY-MM-DDTHH:mm:ss',
) => {
  return dayjs.utc(date).format(format)
}

export const formatDateTime = (
  date: string,
  format = 'YYYY-MM-DDTHH:mm:ss',
) => {
  return dayjs(date).format(format)
}
