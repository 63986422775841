import type { PostFields } from '@tribeplatform/gql-client/graphql/post'
import type {
  PaginatedPost,
  QueryPostsArgs,
  QueryTagPostsArgs,
} from '@tribeplatform/gql-client/types'

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '../../lib/react-query/useInfiniteQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPostsKey } from '../../utils/keys/post.key.js'

export const usePosts = (options: {
  variables: QueryPostsArgs | QueryTagPostsArgs
  fields?: PostFields
  useInfiniteQueryOptions?: UseInfiniteQueryOptions<PaginatedPost>
}) => {
  const {
    variables,
    fields = 'default',
    useInfiniteQueryOptions,
  } = options || {}
  const { client } = useTribeClient()
  const postsKey = getPostsKey({ variables, fields })

  return useInfiniteQuery<PaginatedPost>(
    postsKey,
    ({ pageParam: after }) =>
      client?.posts.list({ after, ...variables }, fields),
    useInfiniteQueryOptions,
  )
}
