import { clsx } from 'clsx'

import { useSlate } from '../../../hooks/slate.hook.js'
import { LogoRenderer } from '../Components/LogoRenderer.js'
import { ItemAlignment, type LogoSection as TLogoSection } from '../types.js'

interface Props extends TLogoSection {
  active: boolean
}
export const LogoSection = ({
  active,
  stretch,
  alignment,
  logoSize,
}: Props) => {
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'

  return (
    <div
      className={clsx(
        'flex h-full items-center shrink-0',
        isPreviewMode && 'px-1',
        active &&
          'rounded-base ring ring-customizer-blue ring-inset ring-offset-0',
        stretch && [
          'flex-grow',
          alignment === ItemAlignment.start && 'justify-start',
          alignment === ItemAlignment.center && 'justify-center',
          alignment === ItemAlignment.end && 'justify-end',
        ],
      )}
    >
      <LogoRenderer logoSize={logoSize} />
    </div>
  )
}
