import { CSSProperties, ReactNode, useEffect } from 'react'

import {
  autoUpdate,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'

import { AnimatePortal } from '../../animation/AnimatePortal.js'
import { ScrollLock } from '../../ScrollLock/ScrollLock.js'
import { useFloatingContext } from './FloatingContext.js'
import { useFloatingMiddleware } from './useFloatingMiddleware.js'

export interface FloatingWrapperProps {
  open?: boolean
  sameWidth?: boolean
  children: ReactNode
}

export const FloatingWrapper = ({
  open: openProp,
  sameWidth = false,
  children,
}: FloatingWrapperProps) => {
  const { placement, triggerRef } = useFloatingContext()

  const { middleware, maxWidth, availableHeight } = useFloatingMiddleware()

  const { context } = useFloating<HTMLElement>({
    placement,
    open: openProp,
    middleware,
    whileElementsMounted: autoUpdate,
  })
  const { open, refs, floatingStyles } = context

  const { getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context),
  ])

  useEffect(() => {
    refs.setReference(triggerRef.current)
  }, [refs, triggerRef])

  const style: CSSProperties = {
    ...floatingStyles,
    width: sameWidth ? maxWidth : undefined,
    maxWidth: sameWidth ? maxWidth : undefined,
    maxHeight: availableHeight,
  }

  return (
    <AnimatePortal open={open}>
      {open && <ScrollLock />}
      <div
        ref={refs.setFloating}
        {...getFloatingProps({
          style,
        })}
      >
        {children}
      </div>
    </AnimatePortal>
  )
}
