import type {
  PaginatedAppFields,
  AppFields as GlobalAppFields,
  AppCollaboratorFields,
  DynamicBlockFields as GlobalDynamicBlockFields,
  QueryShortcutsArgs as GlobalQueryShortcutsArgs,
  PaginatedDynamicBlockFields as GlobalPaginatedDynamicBlockFields,
} from '@tribeplatform/gql-client/global-types'
import type {
  AppInstallationFields,
  AppPublicationFields,
  DynamicBlockFields,
  AppFields,
} from '@tribeplatform/gql-client/graphql'
import type {
  QueryGlobalAppPublicationsArgs,
  QueryGlobalAppCollaboratorsArgs,
  QueryAppArgs,
  QueryAppsArgs,
  QueryGetAppNetworkSettingsArgs,
  QueryGetAppSpaceSettingsArgs,
  QueryGetNetworkAppInstallationsArgs,
  QueryDynamicBlockArgs,
  DynamicBlockSlateArgs,
  QueryAuthMemberAppInstallationArgs,
  QueryAuthMemberAppInstallationsArgs,
  QueryAvailableDynamicBlocksArgs,
  QueryDynamicBlockByKeyArgs,
} from '@tribeplatform/gql-client/types'
import type {
  AppInstallationFields as AuthMemberAppInstallationFields,
  PaginatedAppInstallationFields,
  DynamicBlockFields as GenericDynamicBlockFields,
} from '@tribeplatform/gql-client/types/generated'

import { KeyConstructor } from './keyConstructor.js'

const APP_KEY = 'app'
const GLOBAL_APP_KEY = 'global_app'
const APPS_KEY = 'apps'
const GLOBAL_APPS_KEY = 'global_apps'
const APP_NETWORK_SETTINGS = 'appNetworkSettings'
const APP_SPACE_SETTINGS = 'appSpaceSettings'
const APP_INSTALLATIONS_KEY = 'appInstallations'
const APP_COLLABORATORS_KEY = 'appCollaborators'
const APP_PUBLICATIONS_KEY = 'appPublications'
const DYNAMIC_BLOCK_KEY = 'dynamicBlock'
const GLOBAL_SHORTCUTS_KEY = 'global_shortcuts'

export const getAppKey = KeyConstructor<QueryAppArgs, AppFields>(APP_KEY)
export const getGlobalAppKey = KeyConstructor<QueryAppArgs, GlobalAppFields>(
  GLOBAL_APP_KEY,
)
export const getGlobalShortcutsKey = KeyConstructor<
  GlobalQueryShortcutsArgs,
  GlobalPaginatedDynamicBlockFields
>(GLOBAL_SHORTCUTS_KEY)

export const getAppsKey = KeyConstructor<QueryAppsArgs, AppFields>(APPS_KEY)
export const getGlobalAppsKey = KeyConstructor<QueryAppsArgs, GlobalAppFields>(
  GLOBAL_APPS_KEY,
)
export const getPaginatedGlobalAppsKey = KeyConstructor<
  QueryAppsArgs,
  PaginatedAppFields
>(GLOBAL_APPS_KEY)
export const getAppNetworkSettingsKey = KeyConstructor<
  QueryGetAppNetworkSettingsArgs,
  never
>(APP_NETWORK_SETTINGS)
export const getAppSpaceSettingsKey = KeyConstructor<
  QueryGetAppSpaceSettingsArgs,
  never
>(APP_SPACE_SETTINGS)
export const getAppInstallationsKey = KeyConstructor<
  QueryGetNetworkAppInstallationsArgs,
  AppInstallationFields
>(APP_INSTALLATIONS_KEY)
export const getAuthMemberAppInstallationKey = KeyConstructor<
  QueryAuthMemberAppInstallationArgs,
  AuthMemberAppInstallationFields
>(APP_INSTALLATIONS_KEY)
export const getAuthMemberAppInstallationsKey = KeyConstructor<
  QueryAuthMemberAppInstallationsArgs,
  PaginatedAppInstallationFields
>(APP_INSTALLATIONS_KEY)
export const getAppCollaboratorsKey = KeyConstructor<
  QueryGlobalAppCollaboratorsArgs,
  AppCollaboratorFields
>(APP_COLLABORATORS_KEY)
export const getAppPublicationsKey = KeyConstructor<
  QueryGlobalAppPublicationsArgs,
  AppPublicationFields
>(APP_PUBLICATIONS_KEY)
export const getDynamicBlockKey = KeyConstructor<
  QueryDynamicBlockArgs & DynamicBlockSlateArgs,
  DynamicBlockFields
>(DYNAMIC_BLOCK_KEY)
export const getDynamicBlockByKeyKey = KeyConstructor<
  QueryDynamicBlockByKeyArgs,
  GlobalDynamicBlockFields
>(DYNAMIC_BLOCK_KEY)
export const getAvailableDynamicBlocksKey = KeyConstructor<
  QueryAvailableDynamicBlocksArgs,
  GenericDynamicBlockFields
>(DYNAMIC_BLOCK_KEY)
