import { useEffect } from 'react'

import { useTribeClient } from '@tribeplatform/react-sdk'
import { alert, confirm } from '@tribeplatform/react-ui-kit/Dialog'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import {
  TribeEmitter,
  TribeEmitterEvent,
} from '../lib/tribe-events/TribeEmitter.js'

const init = () => {
  window.BM.toast = props => {
    toast({
      ...props,
      status: props.status || 'info',
    })
  }
  window.BM.confirm = confirm
  window.BM.alert = alert

  // legacy
  window.Tribe.toast = window.BM.toast
  window.Tribe.confirm = window.BM.confirm
  window.Tribe.alert = window.BM.alert
  // window.Tribe.prompt = prompt
  TribeEmitter.emit(TribeEmitterEvent.READY, {})
}

export const useWindowTribe = () => {
  const { client } = useTribeClient()
  useEffect(() => {
    if (window.BM) {
      if (!window.BM._loaded) {
        window.BM.on(TribeEmitterEvent.ON_LOAD, init)
      } else {
        init()
      }
      window.BM.client = client
    }
  }, []) // We don't want this to be retriggered on any change
}
