import {
  CustomFieldType,
  CustomFieldValidators,
  PostListFilterByOperator,
} from '@tribeplatform/gql-client/types'
import type { CustomFieldSchema } from '@tribeplatform/gql-client/types'
import { FieldInput } from '@tribeplatform/react-components/CustomField'
import { Form } from '@tribeplatform/react-components/Form'
import { useI18n } from '@tribeplatform/react-components/i18n'

import {
  TableFilterField,
  TableFilterOperator,
  TableFilterValueParsed,
} from './types.js'

export const FieldsFilterValue = ({
  field,
  operator,
  onChange,
  name,
  value,
}: {
  field: TableFilterField
  operator: TableFilterOperator
  onChange: (value) => void
  name: string
  value: TableFilterValueParsed['value']
}) => {
  const { $t } = useI18n()
  if (!operator || field?.key) {
    return null
  }

  if (operator === PostListFilterByOperator.in && Array.isArray(value)) {
    const enumValidator = field?.validators?.find(
      validator => validator.validation === CustomFieldValidators.enum,
    )
    const validValues = enumValidator?.value
      ? JSON.parse(enumValidator?.value)
      : []

    return (
      <Form.Multiselect
        name={name}
        value={value}
        onChange={onChange}
        options={validValues}
        labels={field.labels}
      />
    )
  }

  if (field.type === CustomFieldType.date && operator.endsWith(':relative')) {
    const DAY_IN_MINUTES = 60 * 24
    return (
      <Form.Select
        name={name}
        placeholder={$t({
          defaultMessage: 'Pick a time...',
          id: 'Table.FieldFilter.DateTime',
        })}
        onChange={onChange}
        items={[
          {
            text: $t(
              {
                defaultMessage:
                  '{days_count, plural, =1 {Tomorrow} =0 {Now} =-1 {Yesterday} other {{days_count, number, ::sign-never} days ago}}',
                id: 'Filter.Date.Values.XDaysAgo',
              },
              {
                days_count: -30,
              },
            ),
            value: -30 * DAY_IN_MINUTES,
          },
          {
            text: $t(
              {
                defaultMessage:
                  '{days_count, plural, =1 {Tomorrow} =0 {Now} =-1 {Yesterday} other {{days_count, number, ::sign-never} days ago}}',
                id: 'Filter.Date.Values.XDaysAgo',
              },
              {
                days_count: -7,
              },
            ),
            value: -7 * DAY_IN_MINUTES,
          },
          {
            text: $t(
              {
                defaultMessage:
                  '{days_count, plural, =1 {Tomorrow} =0 {Now} =-1 {Yesterday} other {{days_count, number, ::sign-never} days ago}}',
                id: 'Filter.Date.Values.XDaysAgo',
              },
              {
                days_count: -1,
              },
            ),
            value: -1 * DAY_IN_MINUTES,
          },
          {
            text: $t(
              {
                defaultMessage:
                  '{days_count, plural, =1 {Tomorrow} =0 {Now} =-1 {Yesterday} other {{days_count, number, ::sign-never} days ago}}',
                id: 'Filter.Date.Values.XDaysAgo',
              },
              {
                days_count: 0,
              },
            ),
            value: 0,
          },
          {
            text: $t(
              {
                defaultMessage:
                  '{days_count, plural, =1 {Tomorrow} =0 {Now} =-1 {Yesterday} other {{days_count, number, ::sign-never} days ago}}',
                id: 'Filter.Date.Values.XDaysAgo',
              },
              {
                days_count: 1,
              },
            ),
            value: DAY_IN_MINUTES,
          },
        ]}
      />
    )
  }

  return (
    <FieldInput
      onChange={onChange}
      value={value}
      field={field as CustomFieldSchema}
      name={name}
      size="compact"
    />
  )
}
