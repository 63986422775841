import type { Space } from '@tribeplatform/gql-client/types'
import type { StaticField, View } from '@tribeplatform/react-components/Views'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { List } from '@tribeplatform/react-ui-kit/Layout'

import { SpacesListItem } from './SpacesListItem.js'

interface Props {
  spaces: Space[]
  fields: StaticField[]
  showDivider: View['showDivider']
  activeFieldId?: string
  showMoreButton: JSX.Element
  preview: boolean
}
export const SpacesList = ({
  spaces,
  fields,
  showDivider = true,
  activeFieldId,
  showMoreButton,
  preview,
}: Props) => {
  return (
    <Card padding="md">
      <Card.Content>
        <List direction="vertical" divider={showDivider} spacing="lg">
          {spaces.map(space => (
            <List.Item key={space.id}>
              <SpacesListItem
                space={space}
                fields={fields.filter(
                  field =>
                    field.enabled &&
                    !(field.styles && field.styles.indexOf('list') === -1),
                )}
                activeFieldId={activeFieldId}
                preview={preview}
              />
            </List.Item>
          ))}
          {!!showMoreButton && <List.Item>{showMoreButton}</List.Item>}
        </List>
      </Card.Content>
    </Card>
  )
}
