import { useMemo } from 'react'

import { clsx } from 'clsx'

import { useBlock } from '../../../hooks/block.hook.js'
import { useSlate } from '../../../hooks/slate.hook.js'
import {
  CompiledBlock,
  RegisteredBlockProps,
} from '../../../types/block.types.js'
import { SectionProps } from '../types.js'
import { ColumnRenderer } from './Components/ColumnRenderer.js'
import { EmptyColumnButton } from './Components/EmptyColumnButton.js'
import { ColumnProps, SlateExtraContext } from './types.js'
import { getSpanAndOrderClasses } from './utils.js'

export const ColumnBlock = ({
  'data-block-id': dataBlockId,
  'data-block-name': dataBlockName,
  gap,
  horizontalPadding,
  verticalPadding,
  horizontalMargin,
  verticalMargin,
  hideOnMobile,
  hideOnTablet,
  hideOnDesktop,
  children,
  containerProps,
  HighlightWrapper,
}: RegisteredBlockProps<ColumnProps>) => {
  const { block: columnBlock } = useBlock<ColumnProps>()
  const {
    mode,
    compiledSlate: { blocks },
    extraContext: {
      slate: {
        openAddBlock,
        focusBlock,
        unfocusBlock,
        activeBlock,
        activeBlockMode,
      },
    },
  } = useSlate<SlateExtraContext>()

  const isPreview = mode !== 'live'
  const isAddingBlock =
    activeBlock === columnBlock.id && activeBlockMode === 'add'

  const showColumnPlaceHolder =
    isPreview && columnBlock.children.length === 0 && !isAddingBlock

  const sectionBlock = blocks[
    columnBlock.parentId
  ] as CompiledBlock<SectionProps>
  const responsiveClasses = useMemo(
    () => getSpanAndOrderClasses(sectionBlock, columnBlock),
    [columnBlock, sectionBlock],
  )

  return (
    <ColumnRenderer
      dataBlockId={dataBlockId}
      dataBlockName={dataBlockName}
      ref={containerProps?.ref}
      gap={gap}
      horizontalPadding={horizontalPadding}
      verticalPadding={verticalPadding}
      horizontalMargin={horizontalMargin}
      verticalMargin={verticalMargin}
      hideOnMobile={hideOnMobile}
      hideOnTablet={hideOnTablet}
      hideOnDesktop={hideOnDesktop}
      className={clsx(containerProps?.className, responsiveClasses)}
    >
      {children}
      {showColumnPlaceHolder && (
        <EmptyColumnButton
          onClick={() => openAddBlock(columnBlock.id)}
          onHover={() => focusBlock(columnBlock.id)}
          onMouseLeave={() => unfocusBlock(columnBlock.id)}
        />
      )}
      {!showColumnPlaceHolder && HighlightWrapper}
    </ColumnRenderer>
  )
}
