import { clsx } from 'clsx'

import { useBlock } from '../../../../hooks/block.hook.js'
import { StoredField } from '../../Fields/types.js'
import { SpaceHeaderProps } from '../../types.js'
import { FieldRenderer } from './FieldRenderer.js'

interface Props {
  className?: string
  field: StoredField
  spaceId: string
}

export const StatsField = ({ className, field, spaceId }: Props) => {
  const {
    extraContext: { activeFieldId },
  } = useBlock<SpaceHeaderProps, { activeFieldId?: string }>()
  const enabledFields = field.fields.filter(field => field.enabled)

  if (enabledFields.length === 0) {
    return null
  }

  return (
    <div
      className={clsx(
        'text-sm flex gap-x-5 items-center max-w-full flex-wrap',
        className,
      )}
    >
      {enabledFields.map(field => {
        return (
          <FieldRenderer
            key={field.id}
            field={field}
            highlighted={field.id === activeFieldId}
            spaceId={spaceId}
          />
        )
      })}
    </div>
  )
}
