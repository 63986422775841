import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { InputBlock as Component } from './Block.js'
import { InputProps } from './types.js'

export const RegisteredInputBlock = (
  _intl: I18nShape,
): RegisteredBlock<InputProps> => ({
  recommendedName: 'Input',
  Component,
  config: {
    type: BlockType.BuildingBlock,
    defaultProps: {
      copy: false,
    },
  },
})
