import { cva } from 'class-variance-authority'

export const sliderTrackStyles = cva(
  ['relative w-full flex items-center h-7 px-2.5'],
  {
    variants: {
      disabled: {
        true: 'opacity-60 cursor-default pointer-events-none',
      },
    },
  },
)

export const sliderThumbStyles = cva([
  'relative',
  'w-5 h-5',
  'after:transition-all',
  "after:content-[''] after:block after:rounded-full",
  'after:w-2 after:h-2',
  'after:bg-content-on-interactive',
  'rounded-full',
  'shadow-base',
  'flex items-center justify-center',
  'bg-interactive hover:bg-interactive-hovered',
  'active:after:w-3 active:after:h-3',
  'focus:outline-none focus-visible:ring',
  'focus-visible:ring-offset-2 focus-visible:ring-focused',
])
