import {
  NetworkFields,
  PostFields,
  SpaceFields,
} from '@tribeplatform/gql-client/graphql'
import { SpaceType } from '@tribeplatform/gql-client/types'

import type { ComposerExtensionConfig } from '../Composer/@types/index.js'

export const MARK_MESSAGING_SPACE_AS_VIEWED_AFTER_MS = 100

export const ChatComposerExtensionConfig: ComposerExtensionConfig = {
  heading: false,
  mention: false,
  emoji: true,
  embed: true,
  attachment: true,
  image: true,
  video: false,
  code: true,
  list: false,
  table: true,
  tableOfContents: true,
  formatting: {
    bold: false,
    italic: true,
    underline: true,
    strikethrough: true,
  },
}

// fields have to be the same to sync state between different UI elements
export const activeChatFields: SpaceFields = {
  authMemberProps: {
    availablePostTypes: 'basic',
  },
  members: {
    variables: {
      limit: 10,
    },
    fields: {
      member: 'default',
    },
  },
}
export const activeChatPostFields: PostFields = {
  owner: {
    member: {
      profilePicture: 'basic',
    },
  },
  mentions: 'basic',
  embeds: 'basic',
  attachments: 'basic',
  tags: 'basic',
  postType: 'basic',
  space: 'basic',
}

export const chatsFields: SpaceFields = {
  authMemberProps: 'basic',
  members: {
    variables: {
      limit: 10,
    },
    fields: {
      member: 'default',
    },
  },
  posts: {
    variables: {
      limit: 1,
    },
    fields: {
      owner: {
        member: {
          profilePicture: 'basic',
        },
      },
      embeds: 'basic',
      attachments: 'basic',
    },
  },
}

export const networkFields: NetworkFields = {
  authMemberProps: {
    totalUnreadPostsCount: {
      variables: {
        type: [SpaceType.PrivateMessage],
      },
    },
  },
  settings: 'basic',
}
