import type { MemberFields } from '@tribeplatform/gql-client/graphql'
import type {
  PaginatedPostReactionParticipant,
  QueryPostReactionParticipantsArgs,
} from '@tribeplatform/gql-client/types'

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '../../lib/react-query/useInfiniteQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPostReactionParticipantsKey } from '../../utils/keys/post.key.js'

export const usePostReactionParticipants = (options: {
  variables: QueryPostReactionParticipantsArgs
  fields?: MemberFields
  useInfiniteQueryOptions?: UseInfiniteQueryOptions<PaginatedPostReactionParticipant>
}) => {
  const { variables, fields = 'basic', useInfiniteQueryOptions } = options || {}
  const { client } = useTribeClient()
  const postReactionParticipantsKey = getPostReactionParticipantsKey({
    variables,
    fields,
  })

  return useInfiniteQuery<PaginatedPostReactionParticipant>(
    postReactionParticipantsKey,
    ({ pageParam: after }) =>
      client?.posts.reactionParticipants({ after, ...variables }, fields),
    useInfiniteQueryOptions,
  )
}
