import type { CSSProperties, PropsWithChildren } from 'react'
import { createContext, useContext, useMemo } from 'react'

import type {
  DraggableSyntheticListeners,
  UniqueIdentifier,
} from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { clsx } from 'clsx'

import { SvgIcon } from '../Icon/SvgIcon.js'

export type SortableItemProps = {
  id: UniqueIdentifier
  disabled?: boolean
  className?: string
}

interface Context {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributes: Record<string, any>
  listeners: DraggableSyntheticListeners
  ref(node: HTMLElement | null): void
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ref() {},
})

export const SortableItem = ({
  children,
  id,
  disabled,
  className,
}: PropsWithChildren<SortableItemProps>) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id, disabled })
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  )

  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <SortableItemContext.Provider value={context}>
      <li
        className={clsx(isDragging && 'shadow-raised', className)}
        ref={setNodeRef}
        style={style}
      >
        {children}
      </li>
    </SortableItemContext.Provider>
  )
}

export type DragHandleProps = {
  disabled?: boolean
}
export const DragHandle = ({ disabled }: DragHandleProps) => {
  const { attributes, listeners, ref } = useContext(SortableItemContext)

  return (
    <button
      type="button"
      className={clsx(
        'shrink-0 cursor-grab me-1 text-content-subdued disabled:opacity-60',
      )}
      {...attributes}
      {...listeners}
      disabled={disabled}
      ref={ref}
    >
      <SvgIcon className="h-[1.25em] w-[1.25em]" name="dots-drag-and-drop" />
    </button>
  )
}
