import { I18nShape } from '@tribeplatform/react-components/i18n'

import { getRecommendedRegisteredBlocks } from './blocks/recommended.js'
import {
  SlateKitInterface,
  BlockWrapperComponent,
  RegisteredBlockWithName,
  SlateWrapperComponent,
  SlateKitUserStorage,
  RegisteredBlocks,
  RegisteredBlock,
  UnknownProps,
  RegisteredBlocksWithNames,
  BlockQuery,
  SlateKitOptions,
  BlockAvailabilityConditions,
} from './types/index.js'
import { applyQuery, UserLocalStorage } from './utils/index.js'

export class SlateKit implements SlateKitInterface {
  registeredBlocks: RegisteredBlocksWithNames = {}

  BlockWrapper: BlockWrapperComponent

  SlateWrapper: SlateWrapperComponent

  userStorage: SlateKitUserStorage

  dynamicBlockSettings: boolean

  constructor(intl: I18nShape, options?: SlateKitOptions) {
    const {
      userStorage,
      blocks,
      slateWrapper,
      blockWrapper,
      useRecommendedBlocks,
      dynamicBlockSettings,
    } = options || {}
    this.setUserStorage(userStorage || new UserLocalStorage())
    this.dynamicBlockSettings = dynamicBlockSettings === true

    if (useRecommendedBlocks !== false) {
      this.registerBlocks(getRecommendedRegisteredBlocks(intl))
    }
    if (blocks) {
      this.registerBlocks(blocks)
    }
    if (slateWrapper) {
      this.registerSlateWrapper(slateWrapper)
    }
    if (blockWrapper) {
      this.registerBlockWrapper(blockWrapper)
    }
  }

  isBlockRegistered(name: string): boolean {
    return !!this.registeredBlocks[name]
  }

  registerBlockWrapper(wrapper: BlockWrapperComponent) {
    this.BlockWrapper = wrapper
  }

  loadBlockWrapper(): BlockWrapperComponent {
    if (!this.BlockWrapper) {
      return null
    }
    return this.BlockWrapper
  }

  registerSlateWrapper(wrapper: SlateWrapperComponent) {
    this.SlateWrapper = wrapper
  }

  loadSlateWrapper(): SlateWrapperComponent {
    if (!this.SlateWrapper) {
      return null
    }
    return this.SlateWrapper
  }

  registerBlocks(blocks: RegisteredBlocks): void {
    Object.keys(blocks).forEach(name => this.registerBlock(name, blocks[name]))
  }

  registerBlock<P extends UnknownProps>(
    name: string,
    registeredBlock: RegisteredBlock<P>,
  ): void {
    this.registeredBlocks[name] = {
      name,
      ...registeredBlock,
      config: {
        displayName: name,
        acceptsChildren: false,
        deprecated: false,
        hide: false,
        lockedChildren: false,
        removable: true,
        states: [],
        usable: false,
        ...registeredBlock.config,
        availabilityConditions: {
          header: false,
          ...(registeredBlock.config.availabilityConditions || {}),
        },
        editable:
          registeredBlock.config.editable !== false &&
          !!registeredBlock.config.Settings &&
          (name !== 'DynamicBlock' || this.dynamicBlockSettings),
      },
    }
  }

  getRegisteredBlock<P extends UnknownProps>(
    name: string,
  ): RegisteredBlockWithName<P> {
    return this.registeredBlocks[name] as RegisteredBlockWithName<P>
  }

  getAvailableBlocks(options: {
    context: BlockAvailabilityConditions
    filter?: BlockQuery<UnknownProps>
    orderBy?:
      | keyof BlockQuery<UnknownProps>
      | Array<keyof BlockQuery<UnknownProps>>
  }): RegisteredBlockWithName<UnknownProps>[] {
    const { context, filter, orderBy } = options
    const result = Object.values(this.registeredBlocks)
      .filter(block => {
        const {
          config: { availabilityConditions },
        } = block
        return applyQuery(context, availabilityConditions)
      })
      .filter(block => {
        return applyQuery(block.config, filter)
      })
    if (orderBy) {
      const orderBys = Array.isArray(orderBy) ? orderBy : [orderBy]
      result.sort((a, b) => {
        const order = orderBys
          .map(orderBy => {
            if (a.config[orderBy] < b.config[orderBy]) {
              return -1
            }
            if (a.config[orderBy] > b.config[orderBy]) {
              return 1
            }
            return 0
          })
          .find(result => result !== 0)
        return order || 0
      })
    }
    return result
  }

  getDynamicBlock(): RegisteredBlockWithName<UnknownProps> {
    return this.registeredBlocks.DynamicBlock
  }

  setUserStorage(storage: SlateKitUserStorage): void {
    this.userStorage = storage
  }
}
