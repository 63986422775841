import type {
  QueryMemberSpaceMembershipRequestArgs,
  SpaceJoinRequest,
} from '@tribeplatform/gql-client/types'
import type { SpaceJoinRequestFields } from '@tribeplatform/gql-client/types/generated'

import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getMemberSpaceMembershipRequestKey } from '../../utils/keys/spaceMembership.key.js'

export const useMemberSpaceMembershipRequest = (options: {
  variables?: QueryMemberSpaceMembershipRequestArgs
  fields?: SpaceJoinRequestFields
  useQueryOptions?: UseQueryOptions<Array<SpaceJoinRequest>>
}) => {
  const { fields = 'basic', useQueryOptions, variables } = options
  const { client } = useTribeClient()
  const memberSpaceMembershipRequest = getMemberSpaceMembershipRequestKey({
    variables,
    fields,
  })

  return useQuery<Array<SpaceJoinRequest>>(
    memberSpaceMembershipRequest,
    () =>
      client.query({
        name: 'memberSpaceMembershipRequest',
        args: {
          fields,
          variables,
        },
      }),
    {
      ...useQueryOptions,
    },
  )
}
