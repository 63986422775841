import { CustomFieldSubtype } from '../../CustomField/utils.js'
import type { ValueRendererComponent } from '../types.js'
import { MemberRenderer } from './MemberRenderer.js'
import { MultiSelectRenderer } from './MultiSelectRenderer.js'
import { SpaceRenderer } from './SpaceRenderer.js'
import { TagRenderer } from './TagRenderer.js'

export const ArrayRenderer: ValueRendererComponent = ({ filter }) => {
  const { value, subType } = filter
  if (!Array.isArray(value)) {
    return null
  }

  switch (subType) {
    case CustomFieldSubtype.MULTISELECT:
    case CustomFieldSubtype.SELECT:
      return <MultiSelectRenderer filter={filter} />
    case CustomFieldSubtype.MEMBERS:
      return <MemberRenderer value={value} />
    case CustomFieldSubtype.SPACES:
      return <SpaceRenderer value={value} />
    case CustomFieldSubtype.TAGS:
      return <TagRenderer value={value} />
    default:
      return null
  }
}
