import { type ComponentProps } from 'react'

import { clsx } from 'clsx'

import { iconSizeStyles, iconStyles } from '../Icon/index.js'
import type { IconComponentType, IconSize } from '../Icon/types.js'
import { avatarIconStyles, avatarStyles } from './Avatar.styles.js'
import { AvatarFallback, AvatarFallbackProps } from './AvatarFallback.js'
import { AvatarRounded } from './types.js'

export type AvatarProps = ComponentProps<'div'> & {
  size?: IconSize
  iconSize?: IconSize
  src?: string | null
  name?: string
  icon?: IconComponentType
  rounded?: AvatarRounded
  fallback?: (props: AvatarFallbackProps) => JSX.Element
}

/**
 * The Avatar component is used to represent a user, and displays the profile picture, initials or fallback icon.
 */
export const Avatar = ({
  size = '2.5x',
  iconSize,
  rounded = 'avatar',
  src,
  name,
  icon,
  className,
  fallback,
  ...rest
}: AvatarProps) => {
  if (!src) {
    const Component = fallback || AvatarFallback
    return (
      <div
        className={clsx(
          'relative',
          avatarStyles({
            rounded,
          }),
          iconStyles({ size }),
          className,
        )}
        title={name}
      >
        <Component size={size} name={name} {...rest} />
        {icon && (
          <div className={avatarIconStyles({ size: iconSize ?? size })}>
            {icon}
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      className={clsx(
        'relative',
        avatarStyles({
          rounded,
        }),
        iconStyles({ size }),
        className,
      )}
      title={name}
      {...rest}
    >
      <img
        className={clsx(
          avatarStyles({
            rounded,
          }),
          iconStyles({ size }),
          'object-cover object-center',
        )}
        height={iconSizeStyles({ size }) || '1em'}
        width={iconSizeStyles({ size }) || '1em'}
        src={src}
        alt={name}
      />
      {icon && (
        <div className={avatarIconStyles({ size: iconSize ?? size })}>
          {icon}
        </div>
      )}
    </div>
  )
}
