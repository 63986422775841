import { PostListOrderByEnum } from '@tribeplatform/react-sdk/types'

export enum SortOptionId {
  latest = 'latest',
  oldest = 'oldest',
  recentActivity = 'recentActivity',
  mostReplied = 'mostReplied',
  mostReacted = 'mostReacted',
}

export interface SortOption {
  id: SortOptionId
  name: string
  value: PostListOrderByEnum
  reverse: boolean
}
