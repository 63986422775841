import { ReactNode } from 'react'

import { HelperText, HelperTextProps } from '../HelperText/HelperText.js'

export type FormControlMetaProps = Omit<HelperTextProps, 'status'> & {
  helperText?: string | ReactNode
  error?: ReactNode
  invalid?: boolean
}

export const FormControlMeta = ({
  invalid,
  helperText,
  error,
  size,
}: FormControlMetaProps) => (
  <>
    {!invalid && helperText && (
      <HelperText size={size}>{helperText}</HelperText>
    )}
    {invalid && error && (
      <HelperText status="error" size={size}>
        {error}
      </HelperText>
    )}
  </>
)
