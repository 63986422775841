import { mediaGQLFields } from '../media/media.fields.js'
import { spaceGQLFields, SpaceFields } from './space.fields.js'

export interface CustomPageFields {
  space?: SpaceFields
}
export type PageFields = 'default' | 'basic' | 'all' | CustomPageFields

const BASIC_PAGE_FIELDS: CustomPageFields = {}
const DEFAULT_PAGE_FIELDS: CustomPageFields = {
  space: 'default',
}
const ALL_PAGE_FIELDS: CustomPageFields = { space: 'basic' }

export const pageGqlFields = (fields: PageFields = 'default') => {
  if (fields === 'default') {
    fields = DEFAULT_PAGE_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_PAGE_FIELDS
  }
  if (fields === 'basic') {
    fields = BASIC_PAGE_FIELDS
  }

  return `
    seoDetail {
      additionalMeta {
        type
        properties {
          key
          value
        }
      }
      description
      jsonld
      title
      thumbnail {
        ${mediaGQLFields()}
      }
    }
    ${
      fields.space
        ? `space {
      ${spaceGQLFields(fields.space)}
    }`
        : ''
    }
  `
}
