import { createContext, useContext } from 'react'

export type MobileMenuProviderProps = {
  close: () => void
}

export const MobileMenuProvider = createContext<MobileMenuProviderProps>({
  close: () => {},
})

export const useMobileMenu = (): MobileMenuProviderProps => {
  const context = useContext(MobileMenuProvider)
  return context
}
