import { ReactNode } from 'react'

type CookiesBoxProps = {
  title: string
  description: string
  children: ReactNode
}

export const CookiesBox = ({
  title,
  description,
  children,
}: CookiesBoxProps) => {
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex justify-between">
        <h4 className="text-lg font-medium text-content">{title}</h4>
        {children}
      </div>
      <div className="bg-surface-subdued text-content-subdued p-4">
        {description}
      </div>
    </div>
  )
}
