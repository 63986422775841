import { hasLocalStorage } from '@bettermode/common/local-storage-detect'

import { UserSettings, SetUserSettingType } from './@types/index.js'

const isClient = typeof window !== 'undefined'

export const getUserSettings = (): UserSettings => {
  try {
    if (hasLocalStorage()) {
      return JSON.parse(localStorage.getItem('userSettings') || '{}')
    }
  } catch (e) {
    // NO OP
  }
  return {} as UserSettings
}

export const setUserSetting = (
  type: SetUserSettingType,
  value,
): UserSettings | undefined => {
  if (isClient && hasLocalStorage()) {
    const userSettings: UserSettings = getUserSettings()
    let newUserSettings: UserSettings

    switch (type) {
      case 'spaceCollection':
        newUserSettings = {
          ...userSettings,
          spaceCollections: {
            ...userSettings?.spaceCollections,
            [value?.id]: {
              isExpanded: value?.isExpanded,
              name: value?.name,
            },
          },
        }
        break
      case 'tableSelectedFields':
        newUserSettings = {
          ...userSettings,
          tableSelectedFields: {
            ...userSettings?.tableSelectedFields,
            ...value,
          },
        }
        break
      case 'timeFrames':
        newUserSettings = {
          ...userSettings,
          timeFrames: {
            ...userSettings?.timeFrames,
            ...value,
          },
        }
        break
      case 'postListOrderBy':
        newUserSettings = {
          ...userSettings,
          postListOrderBy: {
            ...userSettings?.postListOrderBy,
            ...value,
          },
        }
        break
      case 'iconPickerFrequently':
        newUserSettings = {
          ...userSettings,
          iconPickerFrequently: value,
        }
        break
      case 'iconPickerLast':
        newUserSettings = {
          ...userSettings,
          iconPickerLast: value,
        }
        break
      default:
        // Code should never reach the default case
        // eslint-disable-next-line no-case-declarations
        const exhaustiveCheck: never = type
        return exhaustiveCheck
    }

    if (hasLocalStorage()) {
      localStorage.setItem('userSettings', JSON.stringify(newUserSettings))
    }
    return newUserSettings
  }
}
