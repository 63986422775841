import { clsx } from 'clsx'

import { useRouter } from '@tribeplatform/react-sdk'
import { Alert } from '@tribeplatform/react-ui-kit/Alert'

import { BC } from '../../types/index.js'
import { AnnouncementBannerProps } from './types.js'

export const AnnouncementBannerBlock: BC<AnnouncementBannerProps> = ({
  title,
  url,
  style = 'primary',
  rounded = true,
  className,
  children,
  ...rest
}) => {
  const { Link } = useRouter()
  if (!title) {
    return null
  }

  if (url) {
    return (
      <div className={clsx('@container', className)} {...rest}>
        <Alert
          title={title}
          status={style}
          as={Link}
          href={url}
          align="center"
          rounded={rounded}
        >
          {children}
        </Alert>
      </div>
    )
  }

  return (
    <div className={clsx('@container', className)} {...rest}>
      <Alert title={title} status={style} align="center" rounded={rounded}>
        {children}
      </Alert>
    </div>
  )
}
