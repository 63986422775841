import { Component, type ReactNode } from 'react'

import { DialogMountPoint } from '../Dialog/index.js'
import { MediaQueryProvider } from '../Frame/MediaQueryProvider.js'
import { DocumentContext } from '../hooks/useDocument.js'
import { ScrollLockManagerContext } from '../ScrollLock/useScrollLockManager.js'
import { ToastContainer } from '../Toast/ToastContainer.js'
import { FocusManager } from '../TrapFocus/FocusManager.js'
import { ScrollLockManager } from '../utils/scroll-lock-manager.js'

export interface AppProviderProps {
  /** Inner content of the application */
  children?: ReactNode
}

export class AppProvider extends Component<AppProviderProps> {
  private scrollLockManager: ScrollLockManager

  constructor(props: AppProviderProps) {
    super(props)
    this.scrollLockManager = new ScrollLockManager()
  }

  render() {
    const { children } = this.props

    const document = typeof window === 'undefined' ? null : window.document

    return (
      <ScrollLockManagerContext.Provider value={this.scrollLockManager}>
        <DocumentContext.Provider value={{ document }}>
          <MediaQueryProvider>
            <FocusManager>
              {children}
              <ToastContainer />
              <DialogMountPoint />
            </FocusManager>
          </MediaQueryProvider>
        </DocumentContext.Provider>
      </ScrollLockManagerContext.Provider>
    )
  }
}
