import { SpaceMembersArgs } from '../../types/index.js'
import {
  SpaceMemberFields,
  spaceMemberGQLFields,
} from './spaceMember.fields.js'

export type SpaceMembersFields = {
  fields: SpaceMemberFields
  variables: SpaceMembersArgs
}

export function spaceMembersGQLFields(fields: SpaceMembersFields): string {
  return `
      members(limit: ${fields.variables.limit || 1}) {
        edges {
          cursor
          node {
            ${spaceMemberGQLFields(fields.fields)}
          }
        }
        nodes {
          ${spaceMemberGQLFields(fields.fields)}
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    `
}
