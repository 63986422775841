import { clsx } from 'clsx'

import { HtmlContent } from '@tribeplatform/react-components/HtmlContent'

import type { QuickLinksSource } from '../types.js'

interface Props {
  source: QuickLinksSource
  highlighted: boolean
  stretchX: string
  lineClamp?: 'none' | 'sm' | 'md' | 'lg'
}

export const GalleryContent = ({
  source,
  highlighted,
  stretchX,
  lineClamp = 'none',
}: Props) => {
  return (
    <div
      className={clsx(
        'empty:hidden min-w-0',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
    >
      <article
        className={clsx(
          'prose break-words',
          lineClamp === 'sm' && 'line-clamp-2',
          lineClamp === 'md' && 'line-clamp-3',
          lineClamp === 'lg' && 'line-clamp-5',
        )}
      >
        <HtmlContent value={source?.description ?? ''} trustedDomains={['*']} />
      </article>
    </div>
  )
}
