import { MemberStatus } from '@tribeplatform/gql-client/types'
import { T } from '@tribeplatform/react-components/i18n'
import { useAuthMember } from '@tribeplatform/react-sdk/hooks'
import { Alert } from '@tribeplatform/react-ui-kit/Alert'
import { Link } from '@tribeplatform/react-ui-kit/Link'

import { useResendVerificationCode } from '../hooks/useResendVerificationCode.js'

export const AccountVerificationAlert = () => {
  const { data: member, isAdmin } = useAuthMember()

  const {
    onResend,
    isResending: resendIsLoading,
    codeResent,
  } = useResendVerificationCode({
    // Don't do anything on success
    onSuccess: () => Promise.resolve(),
  })

  const isUnverifiedAdmin =
    isAdmin && member?.status === MemberStatus.UNVERIFIED

  if (!isUnverifiedAdmin) {
    return null
  }

  return (
    <Alert status={codeResent ? 'info' : 'warning'} align="center">
      {codeResent ? (
        <T
          defaultMessage="We've sent an email to {email_address}. Please follow the instructions in the email to verify your account."
          id="Auth.Alert.UnverifiedAccount.VerificationEmailSent"
          values={{
            email_address: <strong>{member?.email}</strong>,
          }}
        />
      ) : (
        <T
          defaultMessage="To complete your registration, you need to verify your account: <resend>Send verification email</resend>"
          id="Auth.Alert.UnverifiedAccount.CompleteRegistration"
          values={{
            resend: chunks => (
              <Link
                variant="inherit"
                disabled={resendIsLoading}
                onClick={onResend}
                as="button"
              >
                <strong>{chunks}</strong>
              </Link>
            ),
          }}
        />
      )}
    </Alert>
  )
}
