import { inputPathPermissionsGQLFields } from './inputPathPermissions.fields.js'
import { isAuthorizedGQLFields } from './isAuthorized.fields.js'
import { pathPermissionsGQLFields } from './pathPermissions.fields.js'

export type ActionPermissionsFields = 'basic' | 'all'

export function actionPermissionGQLFields(
  // eslint-disable-next-line unused-imports/no-unused-vars
  fields: ActionPermissionsFields,
): string {
  return `
    name
    isAuthorized {
      ${isAuthorizedGQLFields('basic')}
    }
    inputPermissions {
      ${inputPathPermissionsGQLFields('basic')}
    }
    outputPermissions {
      ${pathPermissionsGQLFields('basic')}
    }
  `
}
