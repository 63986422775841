import { ActionButtonSettings, StoredField } from '../../Fields/types.js'
import { ActionButtonVariant, SpaceHeaderFieldKey } from '../../types.js'
import { AddPostButton } from './AddPostButton.js'
import { CustomActionButton } from './CustomActionButton.js'
import { JoinSpaceButton } from './JoinSpaceButton.js'
import { NotificationsButton } from './NotificationsButton.js'
import { OptionsButton } from './OptionsButton.js'
import { SearchButton } from './SearchButton.js'
import { ShareButton } from './ShareButton.js'

interface Props {
  highlighted: boolean
  settings: ActionButtonSettings
  field: StoredField
  spaceId: string
}

export const ActionButton = ({
  settings,
  highlighted,
  field,
  spaceId,
}: Props) => {
  const { variant, icon } = settings
  const showText =
    variant === ActionButtonVariant.Text ||
    variant === ActionButtonVariant.IconText
  const showIcon =
    variant === ActionButtonVariant.Icon ||
    variant === ActionButtonVariant.IconText
  const className = highlighted && 'outline outline-1 outline-customizer-blue'
  const fieldKey = field.key

  switch (fieldKey) {
    case SpaceHeaderFieldKey.AddPostButton:
      return <AddPostButton className={className} spaceId={spaceId} />
    case SpaceHeaderFieldKey.JoinButton:
      return (
        <JoinSpaceButton
          className={className}
          showText={showText}
          showIcon={showIcon}
          spaceId={spaceId}
        />
      )
    case SpaceHeaderFieldKey.SearchButton:
      return (
        <SearchButton
          className={className}
          showText={showText}
          showIcon={showIcon}
          icon={icon}
        />
      )
    case SpaceHeaderFieldKey.NotificationsButton:
      return (
        <NotificationsButton
          className={className}
          showText={showText}
          showIcon={showIcon}
          spaceId={spaceId}
        />
      )
    case SpaceHeaderFieldKey.ShareButton:
      return (
        <ShareButton
          className={className}
          showText={showText}
          showIcon={showIcon}
          icon={icon}
          spaceId={spaceId}
        />
      )
    case SpaceHeaderFieldKey.OptionsButton:
      return <OptionsButton className={className} spaceId={spaceId} />
    case SpaceHeaderFieldKey.CustomButton:
      return (
        <CustomActionButton
          className={className}
          field={field}
          settings={settings}
        />
      )
    case SpaceHeaderFieldKey.Icon:
    case SpaceHeaderFieldKey.Title:
    case SpaceHeaderFieldKey.Stats:
    case SpaceHeaderFieldKey.DateCreated:
    case SpaceHeaderFieldKey.Description:
    case SpaceHeaderFieldKey.TotalPosts:
    case SpaceHeaderFieldKey.Members:
    case SpaceHeaderFieldKey.TotalMembers:
    case SpaceHeaderFieldKey.Buttons:
      return null
    default: {
      const _exhaustiveCheck: never = fieldKey
      return null
    }
  }
}
