import { ReactNode } from 'react'

import { clsx } from 'clsx'

import { Image } from '@tribeplatform/gql-client/types'

export type ImageGalleryItemProps = {
  image: Image
  thumbnailUrl: string
  onClick: () => void
  span: 'third' | 'half' | 'full'
  children?: ReactNode
  overlayText?: string
}

export const ImageGalleryItem = ({
  image,
  thumbnailUrl,
  onClick,
  span,
  overlayText,
}: ImageGalleryItemProps) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx('relative w-full', [
        /**
         * Assumption is that the parent component (ImageGalleryView) has 6 grid columns
         */
        span === 'third' && 'col-span-2',
        span === 'half' && 'col-span-3',
        span === 'full' && 'col-span-6',
      ])}
    >
      <figure className="w-full h-full flex not-prose justify-center bg-surface-subdued">
        <img
          className="bg-surface-subdued object-cover object-center max-h-120"
          src={thumbnailUrl}
          alt={image.name}
        />
      </figure>

      {overlayText && (
        <div className="absolute h-full w-full bg-surface-inverse opacity-60 inset-0 flex items-center justify-center">
          <div className="text-content-on-inverse opacity-80 text-lg font-medium leading-none">
            {overlayText}
          </div>
        </div>
      )}
    </button>
  )
}
