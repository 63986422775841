import {
  GlyphMediaVariant,
  type CustomReaction,
  type PostType,
} from '@tribeplatform/gql-client/types'

import {
  ActionTypes,
  LabelAlignments,
  ActionVariants,
  ReactionActionTypes,
  type ActionBar,
  type ReactAction,
  type ActionBarAction,
  type ReactionCountPositions,
} from '../../Views/types.js'

export const getDefaultActionBar = (variant?: ActionVariants): ActionBar => {
  return {
    label: '',
    labelAlignment: LabelAlignments.start,
    actions: [
      {
        id: 'react',
        enabled: true,
        reactionActionType: ReactionActionTypes.adaptive,
        type: ActionTypes.react,
        defaultLabel: '',
        label: 'React',
        glyphId: '',
        color: '',
        activeLabel: '',
        activeGlyphId: '',
        activeColor: '',
        variant: variant ?? ActionVariants.iconText,
        reactions: [],
        isSingleReaction: true,
      },
      {
        id: 'follow',
        enabled: true,
        type: ActionTypes.follow,
        defaultLabel: 'Follow',
        label: 'Follow',
        glyphId: `${GlyphMediaVariant.icon}/bell-01`,
        color: '',
        activeLabel: 'Following',
        activeGlyphId: '',
        activeColor: '',
        variant: variant ?? ActionVariants.iconText,
      },
      {
        id: 'share',
        enabled: true,
        type: ActionTypes.share,
        defaultLabel: 'Share',
        label: 'Share',
        glyphId: `${GlyphMediaVariant.icon}/share-06`,
        color: '',
        activeLabel: '',
        activeGlyphId: '',
        activeColor: '',
        variant: variant ?? ActionVariants.iconText,
      },
    ],
  }
}

interface GetActionBar {
  customActionBar: ActionBar
  postType: PostType
  variant: ActionVariants
}
export const getActionBar = ({
  customActionBar,
  postType,
  variant,
}: GetActionBar) => {
  const customReactions = postType?.customReactions ?? []
  const allowedReactions = postType?.allowedReactions ?? []
  const singleChoiceReactions = postType?.singleChoiceReactions
  const isSingleReaction =
    singleChoiceReactions === null || singleChoiceReactions?.length > 0
  const hasAllEmojis = postType?.allowedReactions === null
  const validReactions = hasAllEmojis
    ? null
    : customReactions.filter(({ key }) => allowedReactions.includes(key))
  const actionBar = customActionBar ?? getDefaultActionBar(variant)
  const actions = actionBar?.actions ?? []
  const sanitizedActions = sanitizeActions(
    actions,
    validReactions,
    isSingleReaction,
  )

  return { ...actionBar, actions: sanitizedActions }
}

const sanitizeActions = (
  actions: ActionBar['actions'],
  validReactions: CustomReaction[],
  isSingleReaction: boolean,
) => {
  const sanitizedActions: ActionBarAction[] = actions.map(action => {
    if (action.type === ActionTypes.react) {
      const hasAllEmojis = action.reactions === null || validReactions === null

      if (hasAllEmojis) {
        const reactAction: ReactAction = {
          ...action,
          reactions: null,
          isSingleReaction,
        }
        return reactAction
      }

      const reactions = action.reactions ?? []
      const sanitizedReaction = sanitizeReaction(reactions, validReactions)
      const newReactAction: ReactAction = {
        ...action,
        reactions: sanitizedReaction,
        isSingleReaction,
      }
      return newReactAction
    }

    return action
  })

  return sanitizedActions
}

const sanitizeReaction = (
  reactions: CustomReaction[],
  validReactions: CustomReaction[],
): CustomReaction[] => {
  return validReactions.filter(({ key }) => {
    return reactions.some(reaction => reaction.key === key)
  })
}

export const getDefaultReactions = (
  validReactions: CustomReaction[],
  isSingleReaction: boolean,
  variant?: ActionVariants,
  showCount?: boolean,
  reactionCountPosition?: ReactionCountPositions,
): ReactAction[] => {
  if (validReactions === null) {
    const reactAction: ReactAction = {
      reactionActionType: ReactionActionTypes.allEmojis,
      id: 'react',
      enabled: true,
      type: ActionTypes.react,
      defaultLabel: 'Like',
      label: 'React',
      glyphId: `${GlyphMediaVariant.icon}/thumbs-up`,
      color: '',
      activeLabel: 'Liked',
      activeGlyphId: '',
      activeColor: '',
      reactions: null,
      variant,
      isSingleReaction,
    }

    return [reactAction]
  }

  if (validReactions.length === 1) {
    const [validReaction] = validReactions
    const reactAction: ReactAction = {
      id: 'react',
      enabled: true,
      reactionActionType: ReactionActionTypes.single,
      type: ActionTypes.react,
      defaultLabel: validReaction.name,
      label: 'React',
      glyphId: validReaction.glyphId,
      color: validReaction.color,
      activeLabel: validReaction.activeName,
      activeGlyphId: validReaction.activeGlyphId,
      activeColor: validReaction.activeColor,
      reactions: [validReaction],
      variant,
      showCount,
      reactionCountPosition,
      isSingleReaction,
    }

    return [reactAction]
  }

  if (validReactions.length === 2) {
    const [validReaction1, validReaction2] = validReactions
    const reactAction1: ReactAction = {
      id: 'reaction1',
      enabled: true,
      reactionActionType: ReactionActionTypes.single,
      type: ActionTypes.react,
      defaultLabel: validReaction1.name,
      label: 'React',
      glyphId: validReaction1.glyphId,
      color: validReaction1.color,
      activeLabel: validReaction1.activeName,
      activeGlyphId: validReaction1.activeGlyphId,
      activeColor: validReaction1.activeColor,
      reactions: [validReaction1],
      variant,
      showCount,
      reactionCountPosition,
      isSingleReaction,
    }
    const reactAction2: ReactAction = {
      id: 'reaction2',
      enabled: true,
      reactionActionType: ReactionActionTypes.single,
      type: ActionTypes.react,
      defaultLabel: validReaction2.name,
      label: 'React',
      glyphId: validReaction2.glyphId,
      color: validReaction2.color,
      activeLabel: validReaction2.activeName,
      activeGlyphId: validReaction2.activeGlyphId,
      activeColor: validReaction2.activeColor,
      reactions: [validReaction2],
      variant,
      showCount,
      reactionCountPosition,
      isSingleReaction,
    }

    return [reactAction1, reactAction2]
  }

  if (validReactions.length > 2) {
    const reactAction: ReactAction = {
      id: 'react',
      enabled: true,
      reactionActionType: ReactionActionTypes.multiple,
      type: ActionTypes.react,
      defaultLabel: 'Like',
      label: 'React',
      glyphId: `${GlyphMediaVariant.icon}/thumbs-up`,
      color: '',
      activeLabel: '',
      activeGlyphId: '',
      activeColor: '',
      reactions: validReactions,
      variant,
      isSingleReaction,
    }

    return [reactAction]
  }

  return []
}
