import type { ActionFields } from '@tribeplatform/gql-client/graphql'
import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Action,
  MutationDeletePostArgs,
  Post,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getFeedKey } from '../../utils/keys/feed.keys.js'
import { getPostKey, getPostsKey } from '../../utils/keys/post.key.js'
import { getPinnedPostsKey } from '../../utils/keys/space.key.js'
import { getCachedPost } from '../cache/useCachedPost.js'
import { getPostsQueryById } from './filters.js'

export const useDeletePost = (options?: {
  fields?: ActionFields
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationDeletePostArgs
  >
}) => {
  const { useMutationOptions, fields = 'basic' } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()
  const pinnedPostKey = getPinnedPostsKey()

  return useMutation<Action, ClientError, MutationDeletePostArgs>(
    (input: MutationDeletePostArgs) => client.posts.delete(input, fields),
    {
      onMutate: variables => {
        queryClient.cancelQueries(pinnedPostKey)
        queryClient.setQueriesData<Post[]>(pinnedPostKey, old =>
          old.filter(post => variables?.id && post?.id !== variables?.id),
        )
      },
      onSettled: (data, error, input) => {
        const postKey = getPostKey({ variables: { id: input?.id } })
        queryClient.removeQueries(postKey)

        const postsKey = getPostsKey()
        queryClient.invalidateQueries(postsKey)

        const feedKey = getFeedKey()
        queryClient.invalidateQueries(feedKey)

        queryClient.invalidateQueries(pinnedPostKey)

        const cachedPost = getCachedPost(input.id, queryClient)
        // we want to "refetch" only replies query if any
        // (invalidate does not work for disabled queries)
        // (replies withing a reply is a disabled query)
        const parentPostId = cachedPost.repliedToId
        if (parentPostId) {
          queryClient.refetchQueries(getPostsQueryById(parentPostId))
        }
      },
      ...useMutationOptions,
    },
  )
}
