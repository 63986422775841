import { type Role, RoleType } from '@tribeplatform/gql-client/types'
import { type I18nShape } from '@tribeplatform/react-components/i18n'
import { getTranslatedRoleName } from '@tribeplatform/react-components/UserImport'

export const getRoleIds = (roles: Role[]) => {
  if (!roles) {
    return []
  }

  return roles
    .filter(role => role?.type !== RoleType.guest)
    .map(role => role.id)
}

export const getRoleNames = (
  roles: Role[],
  intl: I18nShape,
): Record<string, string> => {
  if (!roles) {
    return {}
  }

  return roles.reduce((output, role) => {
    return { ...output, [role.id]: getTranslatedRoleName(intl, role.name) }
  }, {})
}
