import { Post } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { Card } from '@tribeplatform/react-ui-kit/Card'

import { dayjs } from '../common/lib/dayjs.js'
import { T } from '../i18n/components/T.js'
import type { PostContext } from '../Post/types.js'
import { Reply } from './Reply.js'

export const ReplyWithParent = ({
  reply,
  context,
}: {
  reply: Post
  context: PostContext
}) => {
  const { Link } = useRouter()

  const rootPost = reply?.repliedTos?.[reply?.repliedTos.length - 1]
  return (
    <div>
      <div className="text-content-on-background-subdued mb-2 px-1">
        <T
          id="Reply.ReplyWithParent.RepliedToText"
          defaultMessage="Replied to"
        />{' '}
        <Link
          href={rootPost?.relativeUrl}
          className="font-medium text-content-on-background"
          variant="inherit"
        >
          {rootPost?.title}
        </Link>{' '}
        {dayjs(reply.createdAt).fromNow()}
      </div>

      <Card>
        <Card.Content>
          <Reply reply={reply} canReply={context === 'member'} />
        </Card.Content>
      </Card>
    </div>
  )
}
