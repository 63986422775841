import type { IntlShape } from 'react-intl/src/types.js'

import type { InjectedAppProps } from '../../../server/types/server.types.js'
import { APP_DATA_KEY } from '../../constants/app.constants.js'
import { fetchTranslationData } from '../../utils/translation/fetchTranslationData.js'
import { I18nStore } from '../../utils/translation/I18nStore.js'

export const getTranslationDate = async (
  lang: string,
  subfolder: string,
): Promise<IntlShape> => {
  try {
    const translationData = await fetchTranslationData(lang, subfolder)
    const i18n = I18nStore.getOrCreate(lang, translationData)

    return i18n
  } catch (_error) {
    return null
  }
}

export const getInjectedAppProps = (): InjectedAppProps => {
  try {
    const initialProps = window[APP_DATA_KEY] as InjectedAppProps
    const { network } = initialProps
    // Adding authToken to the window object for backward compatibility
    window[APP_DATA_KEY] = {
      ...initialProps,
      authToken: {
        accessToken: initialProps.accessToken,
        network,
        member: initialProps.authMember,
        networkPublicInfo: {
          id: network?.id,
          membership: network?.membership,
          status: network?.status,
          name: network?.name,
          domain: network?.domain,
          domainSubfolder: network?.domainSubfolder,
          visibility: network?.visibility,
          favicon: network?.favicon,
          images: network?.images,
          logo: network?.logo,
        },
        role: initialProps.authMember?.role,
      },
    }
    const appDataScript = document.getElementById(APP_DATA_KEY)

    if (appDataScript) {
      appDataScript.remove()
    }

    return initialProps
  } catch (_error) {
    return null
  }
}
