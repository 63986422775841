import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { merge } from '@bettermode/common/merge'

import type { ProviderProps } from './@types/index.js'
import { ClientProvider } from './ClientProvider.js'

const defaultQueryConfigs: ProviderProps['queryConfig'] = {
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5min,
      cacheTime: Infinity,
      /**
       * There's an issue with Chrome on macOS where it fails
       * to properly detect internet connectivity and causes
       * the query to start in paused state.
       * See: https://stackoverflow.com/a/75566702
       *
       * Opting into previous behavior as suggested here:
       * https://tanstack.com/query/v4/docs/react/guides/migrating-to-react-query-4#queries-and-mutations-per-default-need-network-connection-to-run
       */
      networkMode: 'offlineFirst',
    },
    mutations: {
      networkMode: 'offlineFirst',
    },
  },
}

export const Provider = ({
  children,
  queryConfig = {},
  config,
  onTokenUpdate,
  ignoreDevTools,
}: ProviderProps) => {
  const queryClient = new QueryClient(merge(defaultQueryConfigs, queryConfig))

  return (
    <ClientProvider config={config} onTokenUpdate={onTokenUpdate}>
      <QueryClientProvider client={queryClient}>
        {children}
        {!ignoreDevTools && <ReactQueryDevtools position="bottom-right" />}
      </QueryClientProvider>
    </ClientProvider>
  )
}
