import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Action,
  MutationSuspendMemberArgs,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getMemberKey, getMembersKey } from '../../utils/keys/member.key.js'

export const useSuspendMember = (options?: {
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationSuspendMemberArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<Action, ClientError, MutationSuspendMemberArgs>(
    variables =>
      client.mutation({
        name: 'suspendMember',
        args: {
          fields: 'basic',
          variables,
        },
      }),
    {
      onSettled: (data, error, variables) => {
        const memberKey = getMemberKey({
          variables: { id: variables?.memberId },
        })
        queryClient.invalidateQueries(memberKey)

        const membersKey = getMembersKey()
        queryClient.invalidateQueries(membersKey)
      },
      ...useMutationOptions,
    },
  )
}
