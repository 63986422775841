import { CustomFieldType } from '@tribeplatform/gql-client/types'
import { CustomFieldSubtype } from '@tribeplatform/react-components/CustomField'
import { type RawFilter } from '@tribeplatform/react-components/Filters'
import { type I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { CustomEmailStatusPicker } from '../components/CustomEmailStatusPicker.js'
import { CustomEmailStatusRenderer } from '../components/CustomEmailStatusRenderer.js'
import { CustomRoleIdPicker } from '../components/CustomRoleIdPicker.js'
import { CustomRoleIdRenderer } from '../components/CustomRoleIdRenderer.js'

export const staticFiltersFactory = (intl: I18nShape): RawFilter[] => {
  const { $t } = intl
  return [
    {
      id: 'role',
      key: 'roleId',
      name: $t({
        defaultMessage: 'Role',
        id: 'Generics.Role',
      }),
      type: CustomFieldType.text,
      subType: CustomFieldSubtype.SELECT,
      customValuePickerComponent: CustomRoleIdPicker,
      customValueRendererComponent: CustomRoleIdRenderer,
      icon: <SvgIcon name="selector" />,
    },
    {
      id: 'name',
      key: 'name',
      name: $t({
        defaultMessage: 'Name',
        id: 'Generics.Name.Noun',
      }),
      type: CustomFieldType.text,
      icon: <SvgIcon name="field" />,
    },
    {
      id: 'username',
      key: 'username',
      name: $t({
        defaultMessage: 'Username',
        id: 'Generics.Username',
      }),
      type: CustomFieldType.text,
      icon: <SvgIcon name="field" />,
    },
    // {
    //   id: 'email',
    //   key: 'email',
    //   name: $t({
    //     defaultMessage: 'Email',
    //     id: 'Generics.Email',
    //   }),
    //   type: CustomFieldType.text,
    //   subType: CustomFieldSubtype.EMAIL,
    //   icon: <AtSymbolIcon />,
    // },
    {
      id: 'createdAt',
      key: 'createdAt',
      name: $t({
        defaultMessage: 'Created at',
        id: 'Generics.CreatedAt',
      }),
      type: CustomFieldType.date,
      icon: <SvgIcon name="calendar" />,
    },
    {
      id: 'updatedAt',
      key: 'updatedAt',
      name: $t({
        defaultMessage: 'Updated at',
        id: 'Generics.UpdatedAt',
      }),
      type: CustomFieldType.date,
      icon: <SvgIcon name="calendar" />,
    },
    {
      id: 'lastSeenAt',
      key: 'lastSeenAt',
      name: $t({
        defaultMessage: 'Last seen',
        id: 'Generics.LastSeen',
      }),
      type: CustomFieldType.date,
      icon: <SvgIcon name="calendar" />,
    },
    {
      id: 'emailStatus',
      key: 'status',
      name: $t({
        defaultMessage: 'Email status',
        id: 'Generics.EmailStatus',
      }),
      type: CustomFieldType.text,
      subType: CustomFieldSubtype.SELECT,
      customValuePickerComponent: CustomEmailStatusPicker,
      customValueRendererComponent: CustomEmailStatusRenderer,
      icon: <SvgIcon name="selector" />,
    },
  ]
}
