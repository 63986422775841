import { T } from '@tribeplatform/react-components/i18n'

import type { TableCounterProps } from './types.js'

export const EmailLogsCounter = ({
  selectedCount,
  totalCount,
}: TableCounterProps) => {
  return (
    <div className="flex items-center space-s-1">
      {totalCount === selectedCount ? (
        <span className="font-bold">
          <T
            defaultMessage="{email_logs_count, plural, zero {No email logs} one {# email log} other {# email logs}}"
            id="Generics.EmailLogs.Plural"
            values={{
              email_logs_count: totalCount,
            }}
          />
        </span>
      ) : (
        <span>
          <T
            defaultMessage="<bold>{matched_count, plural, one {# email log} other {# email logs}} matched</bold> of {total_count, number}"
            id="Admin.EmailLogsList.TableCounter"
            values={{
              matched_count: selectedCount,
              total_count: totalCount,
              bold: chunks => <span className="font-bold">{chunks}</span>,
            }}
          />
        </span>
      )}
    </div>
  )
}
