import { SpaceType } from '@tribeplatform/gql-client/types'
import type { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

export const TagsBlockConfig = ({ $t }: I18nShape) => ({
  defaultProps: {
    title: 'Tags',
    style: 'cloud',
    action: 'filter',
    source: 'most-popular',
    limit: 10,
    tagIds: [],
  },

  states: [],

  contextAwareInfo: ({ context }) => {
    const removable = context.space?.type === SpaceType.Group
    return {
      removable,
    }
  },

  displayName: $t({ defaultMessage: '{TAGS_CC}', id: 'Generics.Tags' }),
  Icon: <SvgIcon name="tag" />,
})
