import React, { ReactNode, useCallback, useState } from 'react'

import { Placement } from '@floating-ui/react'

import type { AppearanceStyle } from '@tribeplatform/design-system/types'
import { Popover } from '@tribeplatform/react-ui-kit/Popover'

import { ImagePickerProps, ImagePickerTabs } from './ImagePicker.js'

type PopoverImagePickerProps = ImagePickerProps & {
  placement?: Placement
  iconStyle?: AppearanceStyle['icons']
  children: ReactNode
}

export const PopoverImagePicker = ({
  placement,
  iconStyle,
  children,
  ...rest
}: PopoverImagePickerProps) => {
  const [active, setActive] = useState(false)
  const toggleActive = useCallback(() => setActive(active => !active), [])
  const onClose = useCallback(() => setActive(false), [])

  const activator = <div onClick={toggleActive}>{children}</div>

  return (
    <Popover
      activator={activator}
      open={active}
      onClose={onClose}
      autofocusTarget="first-node"
      fluidContent
      allowPanelOverflow={false}
      placement={placement}
    >
      <ImagePickerTabs onSelect={onClose} iconStyle={iconStyle} {...rest} />
    </Popover>
  )
}
