import { lazy, ReactNode, Suspense, useCallback } from 'react'

import { clsx } from 'clsx'

import { fileSize } from '@bettermode/common/filesize'
import { IconButton } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Media } from '@tribeplatform/react-ui-kit/Media'

import { useI18n } from '../../i18n/index.js'
import { downloadFile, extensionHasPreview } from '../../MediaModal/utils.js'

const FileTypeIcon = lazy(() =>
  import('./FileTypeIcon/index.js').then(m => ({
    default: m.FileTypeIcon,
  })),
)

export type FileListItemProps = {
  name: string
  extension?: string
  size?: number
  downloadUrl?: string
  onPreview?: (e) => void
  action?: ReactNode
  className?: string
}

export const FileListItem = ({
  name,
  extension,
  size,
  downloadUrl,
  action,
  className,
  onPreview,
  ...rest
}: FileListItemProps) => {
  const { $t } = useI18n()

  const previewSupported = extensionHasPreview(extension)

  const onClick = useCallback(
    e => {
      if (previewSupported && typeof onPreview === 'function') {
        return onPreview(e)
      }
      if (downloadUrl && name) {
        downloadFile(document, downloadUrl, name)
      }
    },
    [downloadUrl, name, onPreview, previewSupported],
  )

  const trailingVisual = action || (
    <div className="flex items-center">
      {previewSupported && onPreview && (
        <IconButton
          onClick={onPreview}
          variant="tertiaryNeutral"
          icon={<SvgIcon name="eye" />}
          accessibilityLabel={$t({
            defaultMessage: 'Preview',
            id: 'Generics.Preview.Verb',
          })}
          tooltip
        />
      )}
      {downloadUrl && (
        <IconButton
          as="a"
          href={downloadUrl}
          onClick={e => {
            e.stopPropagation()
          }}
          variant="tertiaryNeutral"
          icon={<SvgIcon name="download" />}
          accessibilityLabel={$t({
            defaultMessage: 'Download',
            id: 'Generics.Download',
          })}
          tooltip
        />
      )}
    </div>
  )

  return (
    <Media
      className={clsx(
        'flex-1 px-6 py-4 rounded-base border border-line-subdued min-w-0',
        onPreview && 'cursor-pointer',
        className,
      )}
      align="center"
      icon={
        <Suspense fallback={<></>}>
          <FileTypeIcon
            extension={extension}
            className="w-9 h-9 text-content-subdued"
          />
        </Suspense>
      }
      trailingVisual={trailingVisual}
      onClick={onClick}
      {...rest}
    >
      <div className="h-12 flex flex-col justify-center">
        <div className="text-content truncate text-sm" title={name}>
          {name}
        </div>
        {size && (
          <div
            className="text-content-subdued truncate text-xs"
            title={fileSize(size)}
          >
            {fileSize(size)}
          </div>
        )}
      </div>
    </Media>
  )
}
