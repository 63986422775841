import { clsx } from 'clsx'

import { analyticsNumberFormatter } from '@tribeplatform/react-components/Analytics/utils'
import { T } from '@tribeplatform/react-components/i18n'
import { useSpace } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { MembersCountSettings } from '../../Fields/types.js'

interface Props {
  className?: string
  settings: MembersCountSettings
  spaceId: string
}

export const MembersCountField = ({ className, settings, spaceId }: Props) => {
  const { data: space } = useSpace({
    variables: {
      id: spaceId,
    },
    useQueryOptions: {
      enabled: !!spaceId,
    },
  })
  const membersCount = space?.membersCount ?? 0
  const { showText } = settings

  return (
    <div className={clsx(className, 'flex items-center gap-1')}>
      <SvgIcon name="member" size="lg" />
      <span>{analyticsNumberFormatter(membersCount)}</span>
      {showText && (
        <T
          id="SpaceHeader.MembersCountText"
          defaultMessage="{members_count, plural, =1 {{MEMBER}} =0 {{MEMBERS}} other {{MEMBERS}}}"
          values={{ members_count: membersCount }}
        />
      )}
    </div>
  )
}
