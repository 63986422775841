import { createRef, useEffect } from 'react'

import { FloatingArrow } from '@floating-ui/react'
import { clsx } from 'clsx'

import { BackgroundProvider } from '../BackgroundContext/index.js'
import { useDocument } from '../hooks/useDocument.js'
import { ScrollLock } from '../ScrollLock/ScrollLock.js'
import { useFloatingFullContext } from '../utils/floating/index.js'
import { findFirstKeyboardFocusableNode } from '../utils/focus.js'
import { PopoverAutofocusTarget, PopoverCloseSource } from './types.js'

export type PopoverPanelProps = {
  children?: React.ReactNode
  onClose: (source: PopoverCloseSource) => void
  id: string
  className?: string
  autofocusTarget?: PopoverAutofocusTarget
  fluidContent?: boolean
  roundedStyleButton?: boolean
  allowOverflow?: boolean
  arrow?: boolean
}
export const PopoverPanel = ({
  children,
  id,
  className,
  autofocusTarget = 'container',
  onClose,
  fluidContent = true,
  roundedStyleButton,
  arrow,
  allowOverflow,
  ...rest
}: PopoverPanelProps) => {
  const { document } = useDocument()

  const { getFloatingProps, context, arrowRef } = useFloatingFullContext()
  const { open, refs, floatingStyles } = context
  const contentNode = createRef<HTMLDivElement>()

  const focusContent = () => {
    if (autofocusTarget === 'none' || contentNode == null) {
      return
    }

    requestAnimationFrame(() => {
      if (contentNode.current == null) {
        return
      }

      const focusableChild = findFirstKeyboardFocusableNode(
        document,
        contentNode.current,
      )

      if (focusableChild && autofocusTarget === 'first-node') {
        focusableChild.focus()
      } else {
        contentNode.current.focus()
      }
    })
  }

  const FocusTrigger = () => {
    useEffect(() => {
      focusContent()
    }, [])
    return <div />
  }

  const handleFocusFirstItem = () => {
    onClose(PopoverCloseSource.FocusOut)
  }

  const handleFocusLastItem = () => {
    onClose(PopoverCloseSource.FocusOut)
  }

  return (
    <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
      <BackgroundProvider backgroundType="surface">
        <div
          className={clsx([
            !allowOverflow && 'overflow-hidden',
            'relative',
            'max-w-[calc(100vw-2rem)]',
            !fluidContent && 'w-[calc(100vw-2rem)] md:max-w-sm',
            'shadow-popover',
            'border border-line-subdued',
            'bg-surface text-content-subdued',
            roundedStyleButton ? 'rounded-button' : 'rounded-popover',
          ])}
          {...rest}
        >
          <div
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            onFocus={handleFocusFirstItem}
          />
          <div
            id={id}
            ref={contentNode}
            tabIndex={autofocusTarget === 'none' ? undefined : -1}
            className={clsx([
              'relative',
              'flex flex-col',
              roundedStyleButton ? 'rounded-button' : 'rounded-popover',
              !fluidContent ? 'max-h-none max-w-none' : 'md:max-w-sm max-h-120',
              className,
            ])}
          >
            {open && <FocusTrigger />}
            {open && <ScrollLock />}
            {children}
          </div>
          <div
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            onFocus={handleFocusLastItem}
          />
        </div>
        {arrow && (
          <FloatingArrow
            ref={arrowRef}
            context={context}
            className="fill-line-subdued"
          />
        )}
      </BackgroundProvider>
    </div>
  )
}
