import { Helmet } from 'react-helmet-async'

import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { SlateRenderer } from '@tribeplatform/react-slate-kit/components'
import { SlateProps } from '@tribeplatform/react-slate-kit/types'

export const PostPreview = ({
  postTypeId,
  postTypeSlateProps,
}: {
  postTypeId: string
  postTypeSlateProps: SlateProps
}) => {
  const {
    data: { postTypes },
  } = useNetwork()
  const postType = postTypes.find(postType => postType.id === postTypeId)
  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          type="application/rss+xml"
          title={postType.name?.substring(0, 120)}
        />
      </Helmet>
      <SlateRenderer {...postTypeSlateProps} />
    </>
  )
}
