import { useState, useEffect } from 'react'

import { dayjs } from '../../common/lib/dayjs.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import type { TimeFrame } from '../types/index.js'
import {
  DateTimeFormat,
  getDateString,
  getShouldUpgradePlan,
  isDateFuture,
  parseDateString,
} from '../utils/timeFrame.js'
import { DateTimeValue } from './useDateTimePicker.js'

interface Props {
  timeFrame: TimeFrame
  daysLimitation: number
}
export const useCustomTimeFrame = ({ timeFrame, daysLimitation }: Props) => {
  const intl = useI18n()
  const [date, setDate] = useState<DateTimeValue>(() => {
    if (timeFrame.id !== 'custom') {
      return []
    }

    return [
      dayjs(timeFrame.from).startOf('day').toDate(),
      dayjs(timeFrame.to).startOf('day').toDate(),
    ]
  })
  const [startDate, setStartDate] = useState('')
  const [startDateError, setStartDateError] = useState(false)
  const [endDate, setEndDate] = useState('')
  const [endDateError, setEndDateError] = useState(false)
  const [shouldUpgradePlan, setShouldUpgradePlan] = useState(false)

  useEffect(() => {
    if (Array.isArray(date)) {
      const [date0, date1] = date
      setStartDate(getDateString(date0 as Date))
      setEndDate(getDateString(date1 as Date))
    }
  }, [date])

  useEffect(() => {
    if (!startDate) {
      setStartDateError(false)
      return
    }
    setShouldUpgradePlan(false)

    const date = parseDateString(startDate, DateTimeFormat)
    if (!date || isDateFuture(date)) {
      setStartDateError(true)
      return
    }

    const shouldUpgradePlan = getShouldUpgradePlan(date, daysLimitation)
    if (shouldUpgradePlan) {
      setShouldUpgradePlan(true)
      setStartDateError(true)
      return
    }

    setStartDateError(false)
    setDate(prev => {
      if (!Array.isArray(prev)) {
        return prev
      }

      const newDate = [...prev]
      newDate[0] = date

      return newDate
    })
  }, [daysLimitation, intl, startDate])

  useEffect(() => {
    if (!endDate) {
      setEndDateError(false)
      return
    }
    setShouldUpgradePlan(false)

    const date = parseDateString(endDate, DateTimeFormat)
    if (!date || isDateFuture(date)) {
      setEndDateError(true)
      return
    }

    const shouldUpgradePlan = getShouldUpgradePlan(date, daysLimitation)
    if (shouldUpgradePlan) {
      setShouldUpgradePlan(true)
      setEndDateError(true)
      return
    }

    setEndDateError(false)
    setDate(prev => {
      if (!Array.isArray(prev)) {
        return prev
      }

      const newDate = [...prev]
      if (newDate.length === 0) {
        newDate[0] = date
      } else {
        newDate[1] = date
      }

      return newDate
    })
  }, [daysLimitation, endDate, intl])

  return {
    date,
    setDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    startDateError,
    endDateError,
    shouldUpgradePlan,
  }
}
