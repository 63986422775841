import { isValidElement } from 'react'

import { Member } from '@tribeplatform/gql-client/types'
import { useHotkeysSettings } from '@tribeplatform/react-components/common/hooks'
import {
  useMemberActionItems,
  MemberContext,
} from '@tribeplatform/react-components/Member'
import { useRouter } from '@tribeplatform/react-sdk'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

type MemberActionItemsProps = {
  member: Member
  className?: string
  context?: MemberContext
}

export const MemberActionItems = ({
  member,
  className,
  context,
}: MemberActionItemsProps) => {
  const { Link } = useRouter()

  const { actions, children } = useMemberActionItems({ member, context })
  const { disabled: hotkeysDisabled } = useHotkeysSettings()

  return (
    <>
      <Dropdown.Items className={className}>
        {actions.map(actionItem => {
          const Icon =
            typeof actionItem.icon === 'string' ? (
              <SvgIcon name={actionItem.icon} className="flex-none" />
            ) : (
              isValidElement(actionItem.icon) && actionItem.icon
            )

          if (!actionItem) {
            return null
          }

          const hotkeyValue =
            hotkeysDisabled || context === 'admin'
              ? undefined
              : actionItem.hotkey
          if (actionItem.action) {
            return (
              <Dropdown.Item
                key={actionItem.name}
                leadingIcon={Icon}
                onClick={actionItem.action}
                hotkey={hotkeyValue}
              >
                {actionItem.name}
              </Dropdown.Item>
            )
          }

          return (
            <Dropdown.Item
              key={actionItem.name}
              as={Link}
              href={actionItem.url}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              variant="inherit"
              leadingIcon={Icon}
              hotkey={hotkeyValue}
            >
              {actionItem.name}
            </Dropdown.Item>
          )
        })}
      </Dropdown.Items>
      {children}
    </>
  )
}
