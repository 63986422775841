import { useCallback } from 'react'

import type {
  AppInteraction,
  InteractWithAppInput,
  PermissionContext,
} from '@tribeplatform/gql-client/types'
import { AppInteractionType } from '@tribeplatform/gql-client/types'
import { useNavigate } from '@tribeplatform/react-components/Link'
import { useInteractWithApps } from '@tribeplatform/react-sdk/hooks'

import { interactionEmitter } from '../InteractionEmitter.js'
import {
  ExtraInteractionsEvents,
  InteractionEventPayload,
  LoadInteractionsProps,
  UseInteractions,
} from '../types.js'
import { parseProps } from '../utils.js'
import { useReloadByContext } from './useReloadByContext.js'

export const useInteractions: UseInteractions = ({ dynamicBlockKey }) => {
  const { mutateAsync: interactWithApps } = useInteractWithApps({
    fields: {
      slate: 'all',
    },
  })
  const { reloadContext } = useReloadByContext()
  const navigate = useNavigate()
  const handelInteraction = useCallback(
    (
      interaction: AppInteraction,
      originalInteractionId: string,
      appId: string,
      entityId: string,
      permissionContext: PermissionContext,
      shortcutKey: string,
    ) => {
      const {
        type,
        props,
        slate: interactionSlate,
        interactionId: newInteractionId,
      } = interaction

      const eventPayload: InteractionEventPayload = {
        type,
        originalInteractionId,
        interactionId: newInteractionId,
        slate: interactionSlate,
        props: parseProps(props),
        appId,
        entityId,
        permissionContext,
        dynamicBlockKey,
        shortcutKey,
      }
      const payloadType = eventPayload.type
      switch (payloadType) {
        case AppInteractionType.Show:
          interactionEmitter.emit(AppInteractionType.Show, eventPayload)
          return null

        case AppInteractionType.OpenModal:
          interactionEmitter.emit(AppInteractionType.OpenModal, eventPayload)
          return null

        case AppInteractionType.Close:
          interactionEmitter.emit(AppInteractionType.Close, eventPayload)
          return null

        case AppInteractionType.Reload:
          interactionEmitter.emit(AppInteractionType.Reload, eventPayload)
          if (eventPayload.props?.permissionContext) {
            reloadContext(
              eventPayload.props?.permissionContext,
              eventPayload.props?.entityId,
            )
          }
          return null

        case AppInteractionType.OpenToast:
          interactionEmitter.emit(AppInteractionType.OpenToast, eventPayload)
          return null

        case AppInteractionType.Redirect:
          navigate(
            eventPayload.props.url,
            eventPayload.props.external ? '_blank' : '_self',
          )
          return null

        case AppInteractionType.Data:
          interactionEmitter.emit(AppInteractionType.Data, eventPayload)
          return null

        default: {
          // Code should never reach the default case
          const exhaustiveCheck: never = payloadType
          return exhaustiveCheck
        }
      }
    },
    [dynamicBlockKey, navigate, reloadContext],
  )

  const loadInteractions = useCallback(
    async ({
      interactionId,
      originalInteractionId: customOriginalInteractionId,
      appId,
      permissionContext,
      entityId,
      preview,
      shortcutKey,
      dynamicBlockKey,
      callbackId,
      inputs,
      props,
    }: LoadInteractionsProps) => {
      const originalInteractionId = customOriginalInteractionId || interactionId
      const input: InteractWithAppInput = {
        interactionId,
        appId,
        callbackId,
        preview,
        inputs: inputs ? JSON.stringify(inputs) : undefined,
        props: props ? JSON.stringify(props) : undefined,
        ...(dynamicBlockKey ? { dynamicBlockKey } : {}),
        ...(shortcutKey ? { shortcutKey } : {}),
      }

      try {
        const interactions = await interactWithApps({
          input: [input],
          context: permissionContext,
          entityId,
        })
        if (interactions) {
          interactions.forEach(interaction => {
            handelInteraction(
              interaction,
              originalInteractionId,
              appId,
              entityId,
              permissionContext,
              shortcutKey,
            )
          })
        }
      } catch (error) {
        interactionEmitter.emit(ExtraInteractionsEvents.InteractionError, {
          error: error?.message,
          context: inputs,
        })
        // We don't want to catch errors here, so we can handle them in the app
        throw error
      }
    },
    [interactWithApps, handelInteraction],
  )

  return { loadInteractions }
}
