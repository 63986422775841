import type { InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query'

import type { PostFields } from '@tribeplatform/gql-client/graphql'
import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  MutationCreateReplyArgs,
  PaginatedPost,
  Post,
} from '@tribeplatform/gql-client/types'

import type { QueryFilters } from '../../lib/react-query/QueryFilters.js'
import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getFeedKey } from '../../utils/keys/feed.keys.js'
import { getPostKey, getPostsKey } from '../../utils/keys/post.key.js'
import {
  getCachedPost,
  postRepliesUpdater,
  addPostToPaginatedPost,
  infinitePostArrayUpdater,
  infinitePostUpdater,
} from '../cache/useCachedPost.js'
import { pinnedPostsFilter } from './filters.js'

type Snapshot = {
  rootPostSnapshot?: Post
  repliesSnapshot: [QueryKey, InfiniteData<PaginatedPost>][]
  postsSnapshots: [QueryKey, InfiniteData<PaginatedPost>][]
  pinnedPostsSnapshot: [QueryKey, InfiniteData<Post>][]
}

const onAddReply = (
  variables: MutationCreateReplyArgs,
  newPost: Post,
  queryClient: QueryClient,
): Snapshot => {
  const { postId } = variables
  /** Add the reply to the parent post.replies query */
  const rootPostKey = getPostKey({ variables: { id: postId } })
  const rootPostSnapshot = getCachedPost(postId, queryClient)
  if (rootPostSnapshot) {
    queryClient.setQueriesData<Post>(rootPostKey, postRepliesUpdater(newPost))
  }

  /**
   * Add the reply to the replies queries
   * (inactive/disabled queries are the replies to reply ones so we also update them to show the feedback)
   * */
  const repliesKey: QueryFilters = {
    queryKey: getPostsKey(),
    predicate: query => {
      const args = query?.queryKey?.[1]
      return (args as any)?.variables?.postId === postId
    },
  }

  const repliesSnapshot =
    queryClient.getQueriesData<InfiniteData<PaginatedPost>>(repliesKey)

  if (repliesSnapshot) {
    queryClient.setQueriesData<InfiniteData<PaginatedPost>>(
      repliesKey,
      addPostToPaginatedPost(newPost),
    )
  }
  /** Add reply to the pinned posts */
  const pinnedPostsSnapshot =
    queryClient.getQueriesData<InfiniteData<Post>>(pinnedPostsFilter)
  if (pinnedPostsSnapshot) {
    queryClient.setQueriesData<Post[]>(pinnedPostsFilter, oldPosts =>
      oldPosts.map(
        infinitePostArrayUpdater(postId, postRepliesUpdater(newPost), false),
      ),
    )
  }

  /**  Add the reply to Post.replies in any posts/feed queries which the parent post exists */
  // get all active posts query
  const postsQueryFilter: QueryFilters = {
    type: 'active',
    predicate: query => {
      const [main, args] = query?.queryKey || []
      if (
        (args as any)?.postId !== postId &&
        (main === getFeedKey()[0] || main === getPostsKey()[0])
      ) {
        return true
      }
      return false
    },
  }

  // take a snapshot from all matched posts queries
  const postsSnapshots =
    queryClient.getQueriesData<InfiniteData<PaginatedPost>>(postsQueryFilter)

  // update replies within the found post everywhere (posts and the feed queries)
  queryClient.setQueriesData<InfiniteData<PaginatedPost>>(
    postsQueryFilter,
    infinitePostUpdater(postId, postRepliesUpdater(newPost), false),
  )

  return {
    postsSnapshots,
    rootPostSnapshot,
    repliesSnapshot,
    pinnedPostsSnapshot,
  }
}

export const useAddReply = (options?: {
  fields?: PostFields
  useMutationOptions?: UseMutationOptions<
    Post,
    ClientError,
    MutationCreateReplyArgs,
    Snapshot
  >
}) => {
  const { useMutationOptions, fields = 'default' } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<Post, ClientError, MutationCreateReplyArgs, Snapshot>(
    (input: MutationCreateReplyArgs) =>
      client.posts.reply(input.postId, input, fields),

    {
      onSuccess: (data, variables) => {
        onAddReply(variables, data, queryClient)
      },
      ...useMutationOptions,
    },
  )
}
