import { ErrorInfo, FC, ReactElement, Suspense, useMemo } from 'react'

import { paramCase } from '@bettermode/common/paramcase'
import { MediaModalBoundary } from '@tribeplatform/react-components/MediaModal'
import { SlateDto } from '@tribeplatform/slate-kit/dtos'

import { BlockContext, useSlate, useSlateKitContext } from '../hooks/index.js'
import {
  BlockType,
  type BlockWrapperComponent,
  type CompiledSlate,
  type SlateContextProps,
  type SlateKitProps,
  type UnknownCompiledBlock,
} from '../types/index.js'
import { getBlockProps } from '../utils/index.js'
import { ErrorBoundary } from './ErrorBoundary.js'
import { NEW_BLOCK_PLACEHOLDER_ID } from './PlaceholderBlock/constants.js'
import { NewBlockPlaceholder } from './PlaceholderBlock/NewBlockPlaceholder.js'
import type { SlateExtraContext } from './types.js'
import { getChildrenToRender } from './utils/get-block-children.utils.js'

type BlockRendererProps = {
  id: string
}

const renderBlock = (options: {
  slate: SlateDto
  compiledSlate: CompiledSlate
  block: UnknownCompiledBlock
  context: SlateContextProps
  extraContext: SlateExtraContext
  Wrapper?: BlockWrapperComponent
  onError?: SlateKitProps['onError']
  ErrorFallback?: SlateKitProps['ErrorFallback']
}): ReactElement | null | string => {
  const {
    slate,
    compiledSlate,
    block,
    context,
    extraContext,
    Wrapper,
    onError,
    ErrorFallback,
  } = options
  const { id, name, Component } = block

  const onRenderError = (error: Error, errorInfo?: ErrorInfo) =>
    onError &&
    onError({
      error,
      errorInfo,
      block,
      compiledSlate,
      context,
      slate,
    })

  const childrenBlocks = getChildrenToRender({ block, context: extraContext })
    .filter(childId => !compiledSlate.blocks[childId]?.config.hide)
    .map(childId => {
      if (childId === NEW_BLOCK_PLACEHOLDER_ID) {
        return <NewBlockPlaceholder key={NEW_BLOCK_PLACEHOLDER_ID} />
      }

      return <BlockRenderer key={childId} id={childId} />
    })

  const renderedBlock = (
    <Component
      key={id}
      className={`block-${paramCase(name)}`}
      data-block-id={id}
      data-block-name={paramCase(name)}
      {...block.compiledProps}
    >
      {childrenBlocks}
    </Component>
  )

  const wrappedBlock = Wrapper ? (
    <Wrapper key={id} Block={Component} childrenBlocks={childrenBlocks}>
      {renderedBlock}
    </Wrapper>
  ) : (
    renderedBlock
  )

  const shouldUseSuspense = block.config.type !== BlockType.Layout

  return (
    <ErrorBoundary ErrorFallback={ErrorFallback} onError={onRenderError}>
      {shouldUseSuspense ? (
        <Suspense fallback={<></>}>{wrappedBlock}</Suspense>
      ) : (
        wrappedBlock
      )}
    </ErrorBoundary>
  )
}

export const BlockRenderer: FC<BlockRendererProps> = ({ id: blockId }) => {
  const { kit, onError, ErrorFallback } = useSlateKitContext()
  const slateProps = useSlate<SlateExtraContext>()
  const {
    slate,
    compiledSlate,
    context,
    extraContext: { slate: slateExtraContext },
  } = slateProps

  const block = compiledSlate.blocks[blockId]

  const BlockContextValue = useMemo(
    () => getBlockProps(blockId, slateProps),
    [blockId, slateProps],
  )

  if (!block || block.config.hide) {
    return null
  }

  if (!kit.isBlockRegistered(block.name)) {
    return null
  }

  const renderedBlock = renderBlock({
    slate,
    compiledSlate,
    block,
    context,
    extraContext: slateExtraContext,
    Wrapper: kit.loadBlockWrapper(),
    onError,
    ErrorFallback,
  })

  if (!renderedBlock) {
    return null
  }

  return (
    <BlockContext.Provider key={blockId} value={BlockContextValue}>
      <MediaModalBoundary>{renderedBlock}</MediaModalBoundary>
    </BlockContext.Provider>
  )
}
