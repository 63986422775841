import { ButtonVariant } from '@tribeplatform/react-ui-kit/Button'

import { ButtonActionType } from '../../../types.js'

export const getButtonVariant = (type: ButtonActionType): ButtonVariant => {
  switch (type) {
    case ButtonActionType.PrimaryButton:
      return 'primary'
    case ButtonActionType.NeutralButton:
      return 'secondaryNeutral'
    default:
      return 'secondary'
  }
}
