import { ComponentProps } from 'react'

import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export type GridListColumns = 2 | 3 | 4

export type GridListProps = ComponentProps<'div'> & {
  columns?: GridListColumns
}

/**
 * GridList is a 2-dimensional layout system, meaning it can handle both columns and rows.
 * @deprecated use Container instead
 */
export const GridList = ({
  children,
  columns = 3,
  className,
}: GridListProps) => {
  const columnsClsx = {
    'grid-cols-1 sm:grid-cols-2 lg:grid-cols-2': columns === 2,
    'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3': columns === 3,
    'grid-cols-1 lg:grid-cols-3 xl:grid-cols-4': columns === 4,
  }

  return (
    <ul className={twMerge(clsx('grid gap-5', columnsClsx, className))}>
      {children}
    </ul>
  )
}

const GridListItem = ({ children, ...rest }) => {
  return (
    <li className="col-span-1" {...rest}>
      {children}
    </li>
  )
}

GridList.Item = GridListItem
