import { Controller, useFormContext } from 'react-hook-form'

import {
  FormControl,
  FormControlRadioGroupProps,
} from '@tribeplatform/react-ui-kit/FormControl'

import { FormFieldProps, Optional } from './typings.js'

export function RadioGroup({
  children,
  name,
  validation,
  value: fieldValue,
  onChange: fieldOnChange,
  ...rest
}: FormFieldProps<Optional<FormControlRadioGroupProps, 'value' | 'onChange'>>) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      defaultValue=""
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl.RadioGroup
          {...rest}
          name={name}
          invalid={!!error?.message}
          error={error?.message}
          value={fieldValue ?? value}
          onChange={value => {
            onChange(value)
            if (typeof fieldOnChange === 'function') {
              fieldOnChange(value)
            }
          }}
        >
          {children}
        </FormControl.RadioGroup>
      )}
    />
  )
}
