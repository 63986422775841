import { clsx } from 'clsx'

import { SvgIcon } from '../Icon/SvgIcon.js'
import { Input } from '../Input/index.js'
import { useUniqueId } from '../utils/unique-id.js'
import { DateTimePickerProps } from './types.js'
import { useDatePicker } from './useDatePicker.js'
import { cleanDate } from './utils.js'

export const DateTimePicker = ({
  className,
  dateFormat = 'Y-m-d H:i',
  invalid,
  onChange,
  value,
  inline,
  ...rest
}: DateTimePickerProps) => {
  const prefix = useUniqueId('DateTimePicker')

  const handleOnChange = dates => {
    if (!Array.isArray(dates) || dates.length !== 1) {
      onChange?.(undefined)
      return
    }

    onChange?.(cleanDate(dates[0]))
  }

  const { startInputField } = useDatePicker({
    value,
    onChange: handleOnChange,
    mode: 'single',
    prefix,
    dateFormat,
    allowInput: false,
    enableTime: true,
    showMonths: 1,
    inline,
    ...rest,
  })

  if (inline) {
    return <div ref={startInputField}></div>
  }

  return (
    <div className={clsx(className, 'w-[308px]')}>
      <Input
        ref={startInputField}
        invalid={invalid}
        leadingIcon={<SvgIcon size="lg" name="calendar" />}
      />
    </div>
  )
}
