import { I18nShape } from '@tribeplatform/react-components/i18n'

import { mainMenuDefaultItemsFactory } from './constants.js'
import { MainMenuItem } from './types.js'

export const getDeprecatedItemsCleaner = (intl: I18nShape) => {
  const defaultItems = mainMenuDefaultItemsFactory(intl)
  /**
   * `items` could literally be anything
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (items: any[]): MainMenuItem[] => {
    if (!items?.length) {
      return [...defaultItems]
    }
    return items
      .filter(
        item =>
          item.spaceSlug !== `default::explore` &&
          item.spaceSlug !== 'explore' &&
          item.page !== 'explore',
      )
      .map(item => ({
        ...item,
        action: item.action === 'OPEN_PAGE' ? 'OPEN_SPACE' : item.action,
        spaceSlug: item.spaceSlug || `default::${item.page}`,
      }))
  }
}
