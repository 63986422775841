import type { InfiniteData } from '@tanstack/react-query'

import type {
  Post,
  Collection,
  Page,
  PaginatedSpace,
  QuerySpaceArgs,
  Space,
} from '@tribeplatform/gql-client/types'

import type { QueryClient } from '../../lib/react-query/QueryClient.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { getAuthTokenKey } from '../../utils/keys/authToken.keys.js'
import { getCollectionsKey } from '../../utils/keys/collection.key.js'
import {
  getSpaceKey,
  getSpacesByIdsKey,
  getSpacesBySlugsKey,
  getSpacesKey,
  getPageKey,
} from '../../utils/keys/space.key.js'
import { SpaceUtilResult, useSpaceUtil } from '../utils/useSpaceUtil.js'

const findSpaceInSystemSpaces = (
  args: QuerySpaceArgs,
  queryClient,
  spaceUtils: SpaceUtilResult,
): Space => {
  let cachedSpace = null

  const queries = queryClient.getQueriesData({
    queryKey: getAuthTokenKey(),
  })
  queries?.forEach(query => {
    const queryData = query[1] as {
      network: { systemSpaces?: [{ id; slug; address }] }
    }
    queryData?.network?.systemSpaces?.forEach(space => {
      if (
        space?.id === args.id ||
        space?.slug === args.slug ||
        spaceUtils.matchPath(space, args.path)
      ) {
        cachedSpace = { ...space, ...cachedSpace }
      }
    })
  })

  return cachedSpace && Object.keys(cachedSpace).length > 0
    ? cachedSpace
    : undefined
}

const findSpace = (
  args: QuerySpaceArgs,
  queryClient,
  active: boolean,
  spaceUtils: SpaceUtilResult,
): Space | undefined => {
  let cachedSpace = null

  const queries = queryClient.getQueriesData({
    queryKey: getSpaceKey(),
    active,
  })
  queries?.forEach(query => {
    const space = query[1] as { id; slug; address }
    if (
      space?.id === args.id ||
      space?.slug === args.slug ||
      spaceUtils.matchPath(space, args.path)
    ) {
      cachedSpace = { ...space, ...cachedSpace }
    }
  })

  return cachedSpace && Object.keys(cachedSpace).length > 0
    ? cachedSpace
    : undefined
}

const findSpaceInIds = (
  args: QuerySpaceArgs,
  queryClient,
  active: boolean,
  spaceUtils: SpaceUtilResult,
): Space => {
  let cachedSpace = null

  const queries = queryClient.getQueriesData({
    queryKey: getSpacesByIdsKey(),
    active,
  })
  queries?.forEach(query => {
    const queryData = query[1] as [{ id; slug; address }]
    queryData?.forEach(space => {
      if (
        space?.id === args.id ||
        space?.slug === args.slug ||
        spaceUtils.matchPath(space, args.path)
      ) {
        cachedSpace = { ...space, ...cachedSpace }
      }
    })
  })

  return cachedSpace && Object.keys(cachedSpace).length > 0
    ? cachedSpace
    : undefined
}

const findSpaceInSlugs = (
  args: QuerySpaceArgs,
  queryClient,
  active: boolean,
  spaceUtils: SpaceUtilResult,
): Space => {
  let cachedSpace = null

  const queries = queryClient.getQueriesData({
    queryKey: getSpacesBySlugsKey(),
    active,
  })
  queries?.forEach(query => {
    const queryData = query[1] as [{ id; slug; address }]
    queryData?.forEach(space => {
      if (
        space?.id === args.id ||
        space?.slug === args.slug ||
        spaceUtils.matchPath(space, args.path)
      ) {
        cachedSpace = { ...space, ...cachedSpace }
      }
    })
  })

  return cachedSpace && Object.keys(cachedSpace).length > 0
    ? cachedSpace
    : undefined
}

const findSpaceInSpaces = (
  args: QuerySpaceArgs,
  queryClient,
  active: boolean,
  spaceUtils: SpaceUtilResult,
): Space => {
  let cachedSpace = null

  const queries = queryClient.getQueriesData({
    queryKey: getSpacesKey(),
    active,
  })
  queries?.forEach(query => {
    const queryData = query[1] as {
      pages: [{ nodes: [{ id; slug; address }] }]
    }
    queryData?.pages?.forEach(page => {
      page?.nodes?.forEach(space => {
        if (
          space?.id === args.id ||
          space?.slug === args.slug ||
          spaceUtils.matchPath(space, args.path)
        ) {
          cachedSpace = { ...space, ...cachedSpace }
        }
      })
    })
  })

  return cachedSpace && Object.keys(cachedSpace).length > 0
    ? cachedSpace
    : undefined
}

const findSpaceInCollections = (
  args: QuerySpaceArgs,
  queryClient,
  active: boolean,
  spaceUtils: SpaceUtilResult,
): Space => {
  let cachedSpace = null

  const queries = queryClient.getQueriesData({
    queryKey: getCollectionsKey(),
    active,
  })
  queries?.forEach(query => {
    const queryData = query[1] as Collection[]

    queryData?.forEach(collection => {
      collection?.spaces?.nodes?.forEach(space => {
        if (
          space?.id === args.id ||
          space?.slug === args.slug ||
          spaceUtils.matchPath(space, args.path)
        ) {
          cachedSpace = { ...space, ...cachedSpace }
        }
      })
    })
  })

  return cachedSpace && Object.keys(cachedSpace).length > 0
    ? cachedSpace
    : undefined
}

const findSpaceInPages = (
  args: QuerySpaceArgs,
  queryClient,
  active: boolean,
  spaceUtils: SpaceUtilResult,
): Space => {
  let cachedSpace = null

  const queries = queryClient.getQueriesData({
    queryKey: getPageKey(),
    active,
  })
  queries?.forEach(query => {
    const page = query[1] as Page
    if (page?.space) {
      const { space } = page
      if (
        space?.id === args.id ||
        space?.slug === args.slug ||
        spaceUtils.matchPath(space, args.path)
      ) {
        cachedSpace = { ...space, ...cachedSpace }
      }
    }
  })

  return cachedSpace && Object.keys(cachedSpace).length > 0
    ? cachedSpace
    : undefined
}

export const getCachedSpace = (
  uncleanedArgs: QuerySpaceArgs,
  queryClient: QueryClient,
  spaceUtils: SpaceUtilResult,
): Space => {
  const systemCandidate = findSpaceInSystemSpaces(
    uncleanedArgs,
    queryClient,
    spaceUtils,
  )
  let args: QuerySpaceArgs = { ...uncleanedArgs }
  if (systemCandidate && args.path) {
    args = { id: systemCandidate.id }
  }

  const activeCandidates = [
    findSpace(args, queryClient, true, spaceUtils),
    findSpaceInIds(args, queryClient, true, spaceUtils),
    findSpaceInSlugs(args, queryClient, true, spaceUtils),
    findSpaceInSpaces(args, queryClient, true, spaceUtils),
    findSpaceInCollections(args, queryClient, true, spaceUtils),
    findSpaceInPages(args, queryClient, true, spaceUtils),
  ]
  const activeSpace = [systemCandidate, ...activeCandidates].reduce(
    (preValue, candidate) => ({
      ...preValue,
      ...(candidate || {}),
    }),
  )
  if (activeSpace && Object.keys(activeSpace).length) {
    return activeSpace
  }

  const inActiveCandidates = [
    findSpace(args, queryClient, false, spaceUtils),
    findSpaceInIds(args, queryClient, false, spaceUtils),
    findSpaceInSlugs(args, queryClient, false, spaceUtils),
    findSpaceInSpaces(args, queryClient, false, spaceUtils),
    findSpaceInCollections(args, queryClient, false, spaceUtils),
    findSpaceInPages(args, queryClient, false, spaceUtils),
  ]
  const inActiveSpace = inActiveCandidates.reduce((preValue, candidate) => ({
    ...preValue,
    ...(candidate || {}),
  }))
  if (inActiveSpace && Object.keys(inActiveSpace).length) {
    return inActiveSpace
  }
}

export const useCachedSpace = (args: QuerySpaceArgs): Space => {
  const queryClient = useQueryClient()
  const spaceUtils = useSpaceUtil()
  return getCachedSpace(args, queryClient, spaceUtils)
}

export const useCachedSpaces = (args: QuerySpaceArgs[]): Space[] => {
  const queryClient = useQueryClient()
  const spaceUtils = useSpaceUtil()
  return args
    .map(arg => getCachedSpace(arg, queryClient, spaceUtils))
    .filter(space => space)
}

export const spacePostsUpdater = (node: Post) => (sn: Space) => ({
  ...sn,
  postsCount: sn?.postsCount + 1,
  posts: {
    ...sn?.posts,
    totalCount: (sn?.posts?.totalCount || 0) + 1,
    nodes: [node, ...(sn?.posts?.nodes || [])],
    edges: [{ node, cursor: '' }, ...(sn?.posts?.edges || [])],
  },
})

export const infiniteSpaceUpdater =
  (spaceId: string, updater: (oldSpace: Space) => Space) =>
  (paginatedSpaces: InfiniteData<PaginatedSpace>) => ({
    ...paginatedSpaces,
    pages: paginatedSpaces?.pages?.map(
      infinitePaginatedSpaceWithRepliesUpdater(spaceId, updater),
    ),
  })

export const infinitePaginatedSpaceWithRepliesUpdater =
  (spaceId: string, updater: (oldSpace: Space) => Space) =>
  (spaces: PaginatedSpace): PaginatedSpace => {
    return {
      ...spaces,
      nodes: spaces.nodes?.map(infiniteSpaceArrayUpdater(spaceId, updater)),
      edges: spaces.edges?.map(e => {
        let newNode = e.node
        if (e.node.id === spaceId) {
          newNode = updater(e.node)
        }
        return {
          ...e,
          node: {
            ...newNode,
          },
        }
      }),
    }
  }

export const infiniteSpaceArrayUpdater =
  (spaceId: string, updater: (oldSpace: Space) => Space) =>
  (space: Space): Space => {
    let newNode = space
    if (space.id === spaceId) {
      newNode = updater(space)
    }
    return {
      ...newNode,
    }
  }
