import { useState } from 'react'

import { clsx } from 'clsx'

import { Post, PostStatus } from '@tribeplatform/gql-client/types'
import { T } from '@tribeplatform/react-components/i18n'
import { MediaModalBoundary } from '@tribeplatform/react-components/MediaModal'
import { PostMediaModalTitle } from '@tribeplatform/react-components/MediaModal/TitleComponents'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { Alert } from '@tribeplatform/react-ui-kit/Alert'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { Field } from './Field.js'

interface Props {
  post: Post
  fields: StaticField[]
  preview: boolean
  activeFieldId: string
  isSinglePost?: boolean
  singlePostType?: boolean
  isPostPage?: boolean
  className?: string
}
export const PostsCardItem = ({
  post: originalPost,
  fields,
  preview,
  activeFieldId,
  isSinglePost,
  singlePostType,
  isPostPage,
  className,
  ...rest
}: Props) => {
  const {
    data: { postTypes },
  } = useNetwork()
  const [translatedPost, setTranslatedPost] = useState<Post>(null)
  const bannerField = fields.find(field => field.id === 'cardBanner')
  const replyListField = fields.find(field => field.id === 'replyList')
  const isPendingReview = originalPost.status === PostStatus.BLOCKED
  const isBannerOnly = fields.every(
    field => !field.enabled || field.id === 'cardBanner',
  )
  return (
    <MediaModalBoundary
      title={
        <PostMediaModalTitle
          post={originalPost}
          key={`${originalPost.id}-post-card-item`}
        />
      }
    >
      <Card
        padding="md"
        className={clsx(isBannerOnly && 'border-none', className)}
        {...rest}
      >
        {isPendingReview && (
          <Card.Header>
            <Alert
              status="neutral"
              className="rounded-b-none"
              icon={<SvgIcon name="clipboard-check" />}
            >
              <T
                id="Generics.PendingReviewBanner"
                defaultMessage="This post is pending approval. Once it's approved, it'll be available to other members."
              />
            </Alert>
          </Card.Header>
        )}
        {!!bannerField && (
          <Card.Media top={!isPendingReview}>
            <Field
              key={bannerField.id}
              field={bannerField}
              post={translatedPost ?? originalPost}
              originalPost={originalPost}
              activeFieldId={activeFieldId}
              postTypes={postTypes}
              viewStyle="card"
              preview={preview}
              isSinglePost={isSinglePost}
              isPostPage={isPostPage}
              singlePostType={singlePostType}
              setTranslatedPost={setTranslatedPost}
            />
          </Card.Media>
        )}
        {!isBannerOnly && (
          <Card.Content>
            <div className="flex flex-wrap gap-4 items-center">
              {fields
                .filter(
                  field => !['cardBanner', 'replyList'].includes(field.id),
                )
                .map(field => (
                  <Field
                    key={field.id}
                    field={field}
                    post={translatedPost ?? originalPost}
                    originalPost={originalPost}
                    activeFieldId={activeFieldId}
                    postTypes={postTypes}
                    viewStyle="card"
                    preview={preview}
                    isSinglePost={isSinglePost}
                    isPostPage={isPostPage}
                    singlePostType={singlePostType}
                    setTranslatedPost={setTranslatedPost}
                  />
                ))}
            </div>
          </Card.Content>
        )}
        {!!replyListField && !isSinglePost && (
          <Field
            key={replyListField.id}
            field={replyListField}
            post={translatedPost ?? originalPost}
            originalPost={originalPost}
            activeFieldId={activeFieldId}
            postTypes={postTypes}
            viewStyle="card"
            preview={preview}
            isSinglePost={isSinglePost}
            isPostPage={isPostPage}
            singlePostType={singlePostType}
            setTranslatedPost={setTranslatedPost}
          />
        )}
      </Card>
      {!!replyListField && isSinglePost && (
        <Field
          key={replyListField.id}
          field={replyListField}
          post={translatedPost ?? originalPost}
          originalPost={originalPost}
          activeFieldId={activeFieldId}
          postTypes={postTypes}
          viewStyle="card"
          preview={preview}
          isSinglePost={isSinglePost}
          isPostPage={isPostPage}
          singlePostType={singlePostType}
          setTranslatedPost={setTranslatedPost}
        />
      )}
    </MediaModalBoundary>
  )
}
