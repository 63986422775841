import { forwardRef } from 'react'

import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import { useBackgroundContext } from '../BackgroundContext/index.js'
import { HTMLTribeProps } from '../types/index.js'

export const LINK_VARIANTS = ['inherit', 'neutral', 'accent'] as const
export type LinkVariant = (typeof LINK_VARIANTS)[number]

export type LinkProps = HTMLTribeProps<'a'> & {
  /**
   *  If `true`, the link will open in new tab
   */
  /** @deprecated This property is deprecated. To handle external, set target="_blank" */
  external?: boolean
  variant?: LinkVariant
  disabled?: boolean
  truncate?: boolean
  rounded?: 'none' | 'base' | 'avatar'
}

/**
 * Links are accessible elements used primarily for navigation.
 *
 * It integrates well with other routing libraries like
 * React Router, Reach Router and Next.js Link.
 *
 * @example
 *
 * ```jsx
 * <Link as={ReactRouterLink} href="/home">Home</Link>
 * ```
 */
export const Link = forwardRef(
  (
    {
      as,
      variant = 'accent',
      external,
      children,
      className,
      target,
      rel,
      truncate,
      disabled = false,
      rounded = 'base',
      ...rest
    }: LinkProps,
    ref,
  ) => {
    const Component = as || 'a'
    const { backgroundType } = useBackgroundContext()
    // For backward compatibility, we should handle 'external' property
    const linkTarget = external ? '_blank' : target
    const linkRel = external ? 'noopener noreferrer' : rel
    return (
      <Component
        ref={ref}
        className={twMerge(
          clsx(
            'cursor-pointer',
            [
              rounded === 'base' && 'rounded-base',
              rounded === 'avatar' && 'rounded-avatar',
              rounded === 'none' && 'rounded-none',
            ],
            'transition duration-200',
            'focus:outline-none focus-visible:ring',
            variant === 'inherit' && '',
            variant === 'neutral' && [
              backgroundType === 'main' &&
                'text-content-on-background-subdued hover:text-link-hovered',
              backgroundType === 'surface' &&
                'text-content-subdued hover:text-link-hovered',
              backgroundType === 'secondary' &&
                'text-content-on-topbar hover:text-link-hovered',
            ],
            variant === 'accent' &&
              'text-link hover:text-link-hovered ring-link',
            truncate && 'truncate',
            className,
            disabled && 'pointer-events-none opacity-50',
          ),
        )}
        target={linkTarget}
        rel={linkRel}
        {...rest}
      >
        {children}
      </Component>
    )
  },
)
