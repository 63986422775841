import type { Post } from '@tribeplatform/gql-client/types'
import type { StaticField } from '@tribeplatform/react-components/Views'

import { PostsCardItem } from './PostsCardItem.js'

interface Props {
  posts: Post[]
  fields: StaticField[]
  activeFieldId?: string
  showMoreButton: JSX.Element
  singlePostType?: boolean
  preview: boolean
}
export const PostsCard = ({
  posts,
  fields,
  activeFieldId,
  showMoreButton,
  singlePostType,
  preview,
}: Props) => {
  return (
    <div className="space-y-5">
      {posts.map(post => (
        <PostsCardItem
          key={post.id}
          post={post}
          fields={fields.filter(
            field =>
              field.enabled &&
              !(field.styles && field.styles.indexOf('card') === -1),
          )}
          activeFieldId={activeFieldId}
          singlePostType={singlePostType}
          preview={preview}
        />
      ))}
      {showMoreButton}
    </div>
  )
}
