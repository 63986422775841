import { gql } from 'graphql-tag'

import {
  ReportRecordFields,
  reportRecordGQLFields,
} from './reportRecord.fields.js'

export const getActivityLogsGQLQuery = (fields: ReportRecordFields) => gql`
  query ActivityLogs($query: String!) {
    activityLogs(query: $query) {
      ${reportRecordGQLFields(fields)}
    }
  }
`
