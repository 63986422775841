import { BlockType, RegisteredBlock } from '../../types/index.js'
import { DelayedBlock as Component } from './Block.js'
import { DelayedProps } from './types.js'

export const RegisteredDelayedBlock = (): RegisteredBlock<DelayedProps> => ({
  recommendedName: 'Delayed',
  Component,
  config: {
    type: BlockType.Block,
    usable: false,
    hide: true,
    defaultProps: {
      delay: 100,
    },
  },
})
