import { PostType } from '@tribeplatform/gql-client/types'

const findPostTypeByIdOrSlug = ({
  networkId,
  idOrSlug,
  postTypes,
}: {
  networkId: string
  idOrSlug: string
  postTypes: PostType[]
}): PostType => {
  if (!postTypes || !postTypes?.length) {
    return null
  }

  return postTypes.find(
    postType =>
      postType.id === idOrSlug ||
      postType.slug === idOrSlug ||
      // Some post type slugs are prefixed with the network id,
      // during template creation we convert network id and post type id separately
      // which results in [newNetworkId]-[postTypeId] format
      // therefore we need to check for this format as well
      `${networkId}-${postType.id}` === idOrSlug,
  )
}

export const findPostType = ({
  networkId,
  id,
  slug,
  postTypes,
}: {
  networkId: string
  id?: string
  slug?: string
  postTypes: PostType[]
}): PostType => {
  if (id) {
    const postType = findPostTypeByIdOrSlug({
      networkId,
      idOrSlug: id,
      postTypes,
    })
    if (postType) {
      return postType
    }
  }

  if (slug) {
    const postType = findPostTypeByIdOrSlug({
      networkId,
      idOrSlug: slug,
      postTypes,
    })
    if (postType) {
      return postType
    }
  }

  return null
}
