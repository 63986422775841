import { useMemo } from 'react'

import { CONTENT_FIELD_KEY, TITLE_FIELD_KEY } from '@bettermode/common/CMS'
import { CustomFieldType, PostType } from '@tribeplatform/gql-client/types'
import {
  findPostType,
  isFieldPrivate,
} from '@tribeplatform/react-components/CMS'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

interface Props {
  postTypeSlugs: string[]
  postTypeId?: string
  excludeRichText?: boolean
  excludePrivateFields?: boolean
  excludeTitle?: boolean
  excludeContent?: boolean
}

// Do not reuse this since this has a legacy logic
const _getPostType = ({
  networkId,
  id,
  slugs,
  postTypes,
}: {
  networkId: string
  id?: string
  slugs?: string[]
  postTypes: PostType[]
}) => {
  if (id) {
    return findPostType({ networkId, id, postTypes })
  }

  if (!slugs || slugs?.length !== 1) {
    return null
  }

  const [slug] = slugs
  return findPostType({ networkId, slug, postTypes })
}

export const useCustomFields = ({
  postTypeSlugs,
  postTypeId,
  excludeContent = true,
  excludeRichText = true,
  excludePrivateFields = true,
  excludeTitle = true,
}: Props) => {
  const {
    data: { id: networkId, postTypes },
  } = useNetwork()

  const customFields = useMemo(() => {
    if (!postTypes) {
      return []
    }

    const postType = _getPostType({
      networkId,
      id: postTypeId,
      slugs: postTypeSlugs,
      postTypes,
    })

    if (!postType) {
      return []
    }

    return postType.postFields.fields.filter(
      field =>
        !field.archived &&
        !(excludeContent && field.key === CONTENT_FIELD_KEY) &&
        !(excludeTitle && field.key === TITLE_FIELD_KEY) &&
        !(excludeRichText && field.type === CustomFieldType.richText) &&
        !(excludePrivateFields && isFieldPrivate(field?.readPrivacy)),
    )
  }, [
    networkId,
    excludeContent,
    excludePrivateFields,
    excludeRichText,
    excludeTitle,
    postTypeId,
    postTypeSlugs,
    postTypes,
  ])

  return { postTypes, customFields }
}
