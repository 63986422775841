import { forwardRef } from 'react'

import { clsx } from 'clsx'

import { SvgIcon } from '../Icon/SvgIcon.js'
import { InputProps } from '../Input/index.js'

export type TopBarInputProps = Omit<InputProps, 'size'>

export const TopBarInput = forwardRef<HTMLInputElement, TopBarInputProps>(
  ({ className, trailingAddon: _, ...rest }, ref) => {
    return (
      <div
        className={clsx([
          'relative inline-flex items-center gap-2',
          'block w-full rounded-input',
          'px-2 py-[7px] min-h-[40px] text-label-md',
          'border border-line-on-topbar',
          'transition duration-200',

          'focus-within:ring-1 focus-within:ring-offset-0',
          'focus-within:ring-line-on-topbar-pressed focus-within:border-line-on-topbar-pressed',

          'bg-topbar text-content-on-topbar',
          'placeholder:text-content-on-topbar placeholder:opacity-60',
          'disabled:opacity-60 disabled:cursor-default',
          className,
        ])}
      >
        <div className="shrink-0">
          <SvgIcon
            className="w-4 h-4 text-label-sm text-content-on-topbar"
            name="search"
          />
        </div>
        <input
          ref={ref}
          type="search"
          className={clsx([
            'grow appearance-none',
            'focus-visible:outline-none bg-transparent',
          ])}
          {...rest}
        />
      </div>
    )
  },
)
