import { ReactNode } from 'react'

import { Listbox } from '@headlessui/react'

import { SvgIcon } from '../Icon/SvgIcon.js'
import { HTMLTribeProps } from '../types/index.js'
import { runIfFn } from '../utils/index.js'
import {
  optionDescriptorDescriptionStyles,
  optionDescriptorTitleStyles,
} from '../utils/OptionDescriptor.styles.js'
import { selectItemIconStyles, selectItemStyles } from './Select.styles.js'

interface SelectItemRenderProps {
  selected: boolean
  active: boolean
}

export type SelectItemProps<T> = Omit<
  HTMLTribeProps<'li'>,
  'value' | 'children'
> & {
  /** If title is set, children are ignored */
  title?: ReactNode
  description?: ReactNode
  value: T
  disabled?: boolean
  children?: ReactNode | ((props: SelectItemRenderProps) => ReactNode)
  className?: string
}
export const SelectItem = ({
  value,
  children,
  disabled,
  className,
  title,
  description,
  ...rest
}: SelectItemProps<unknown>) => {
  return (
    <Listbox.Option value={value} disabled={disabled} {...rest}>
      {({ selected, active }) => (
        <div
          className={selectItemStyles({
            active,
            selected,
            disabled,
            className,
          })}
        >
          <div className="min-w-0 max-w-full">
            {title ? (
              <div className="space-y-1">
                <div className={optionDescriptorTitleStyles({ disabled })}>
                  {title}
                </div>
                <div
                  className={optionDescriptorDescriptionStyles({ disabled })}
                >
                  {description}
                </div>
              </div>
            ) : (
              runIfFn(children, {
                selected,
                active,
              })
            )}
          </div>

          {selected && (
            <span className="absolute inset-y-0 end-0 flex items-start pe-4 py-2">
              <SvgIcon
                className={selectItemIconStyles({ active })}
                aria-hidden="true"
                name="check"
              />
            </span>
          )}
        </div>
      )}
    </Listbox.Option>
  )
}
