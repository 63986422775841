import { useCallback } from 'react'

import type { ClientError } from '@tribeplatform/gql-client/lib'
import type { Embed, EmbedInput } from '@tribeplatform/gql-client/types'

import {
  useQuery,
  type UseQueryOptions,
} from '../../lib/react-query/useQuery.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getEmbedKey } from '../../utils/keys/media.key.js'

export const useEmbed = (options?: {
  variables?: EmbedInput
  useQueryOptions?: UseQueryOptions<Embed, ClientError>
}) => {
  const { variables, useQueryOptions } = options || {}
  const queryClient = useQueryClient()
  const { client } = useTribeClient()

  const asyncEmbed = useCallback(
    async (variables: EmbedInput) => {
      const data = await queryClient.fetchQuery<Embed, ClientError>(
        getEmbedKey({ variables }),
        () =>
          client.media.embed({
            options: JSON.stringify({
              iframe: '1',
              omit_script: '1',
            }),
            ...variables,
          }),
        useQueryOptions,
      )
      return data
    },
    [queryClient, client, useQueryOptions],
  )

  return {
    ...useQuery<Embed, ClientError>(
      getEmbedKey({ variables }),
      () => client.media.embed(variables),
      { ...useQueryOptions, enabled: !!variables?.url },
    ),
    asyncQuery: asyncEmbed,
  }
}
