import { convertUtcToTz } from '../../common/utils/date.js'
import { useI18n } from '../../i18n/index.js'
import type { ValueRendererComponent } from '../types.js'
import { relativeDatesFactory } from '../valuePickers/DateValuePicker.js'

export const DateTimeRenderer: ValueRendererComponent = ({ filter }) => {
  const intl = useI18n()
  const { value } = filter
  if (typeof value === 'string') {
    return <>{convertUtcToTz(value, 'MMMM D, YYYY h:mma')}</>
  }

  if (typeof value === 'number') {
    const relativeDates = relativeDatesFactory(intl)
    const relativeDate = relativeDates.find(
      relativeDate => relativeDate.value === value,
    )

    if (relativeDate.text) {
      return <>{relativeDate.text}</>
    }
  }

  return null
}
