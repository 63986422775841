import { useMemo } from 'react'

// eslint-disable-next-line no-restricted-imports
import { Options, useHotkeys } from 'react-hotkeys-hook'

import { useHotkeysSettings } from './useHotkeysSettings.js'

/**
 * Custom hook to use hotkeys with the ability to disable them
 * based on user settings.
 */
export const useControlledHotkeys = <T extends Element>(
  keys: string,
  callback: (event: KeyboardEvent) => void,
  options?: Options,
  deps?: unknown[],
) => {
  const { disabled } = useHotkeysSettings()

  const hotkeyOptions = useMemo(() => {
    return {
      ...options,
      enabled: !disabled && options?.enabled,
    }
  }, [disabled, options])

  return useHotkeys<T>(keys, callback, hotkeyOptions, deps)
}
