import { createContext, useContext } from 'react'

import type { TribeTrackerType } from './@types/index.js'

export const defaultTracker: TribeTrackerType = {
  setTarget: () => {
    // do nothing
  },
  trackPageView: () => {
    // do nothing
  },
  track: () => {
    // do nothing
  },
}

export const TrackerContext = createContext<TribeTrackerType>(defaultTracker)

export const useTracker = () => {
  return useContext(TrackerContext)
}

TrackerContext.displayName = 'TribeTracker'

export const TribeTracker = TrackerContext.Provider
