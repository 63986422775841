import { useMembersByIds } from '@tribeplatform/react-sdk/hooks'

import { SpreadFilterItems } from '../../components/SpreadFilterItems.js'

interface Props {
  value: string[]
  options: string[]
  onChange: (memberIds: string[]) => void
}
export const SpreadMemberPicker = ({ value, options, onChange }: Props) => {
  const { data: memberOptions } = useMembersByIds({
    variables: { ids: options },
    fields: {
      profilePicture: { onImage: { urls: 'basic' } },
    },
    useQueryOptions: {
      enabled: options.length > 0,
    },
  })

  return (
    <SpreadFilterItems
      options={memberOptions?.map(({ id }) => id) ?? []}
      value={value}
      onChange={onChange}
      isLoading={!memberOptions}
    >
      {selectedOption => (
        <span
          title={
            memberOptions.find(({ id }) => id === selectedOption)?.name ?? ''
          }
        >
          {memberOptions.find(({ id }) => id === selectedOption)?.name ?? ''}
        </span>
      )}
    </SpreadFilterItems>
  )
}
