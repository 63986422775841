import { MultiViewBlock } from '@tribeplatform/react-components/Views'
import { useBlock } from '@tribeplatform/react-slate-kit/hooks'
import type { BC } from '@tribeplatform/react-slate-kit/types'

import { GenericMembersBlock } from './components/GenericMembersBlock.js'
import type { MembersBlockProps } from './types.js'

export const MembersBlock: BC<MembersBlockProps> = ({
  title,
  description,
  views,
  linkUrl,
  linkText,
  ...rest
}) => {
  const {
    extraContext: { activeViewIndex, activeFieldId },
  } = useBlock<
    MembersBlockProps,
    { activeViewIndex?: number; activeFieldId?: string }
  >()

  return (
    <MultiViewBlock
      activeViewIndex={activeViewIndex}
      title={title}
      description={description}
      views={views}
      linkUrl={linkUrl}
      linkText={linkText}
      {...rest}
    >
      {({ view }) => (
        <GenericMembersBlock view={view} activeFieldId={activeFieldId} />
      )}
    </MultiViewBlock>
  )
}
