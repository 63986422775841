import { noop } from '@tribeplatform/react-ui-kit/hooks'

import { SlateLayout } from '../types.js'

export const getLayoutFromQuery = (layout: string) => {
  switch (layout) {
    case 'no-sidebar':
      return SlateLayout.headerNoSidebar
    case 'default':
      return SlateLayout.headerSidebar
    case 'basic':
      return SlateLayout.noHeaderNoSidebar
    case 'no-header':
      return SlateLayout.sidebarNoHeader
    default:
      return null
  }
}

interface LayoutHasHeaderSidebar {
  pageHasHeader: boolean
  pageHasLeftSidebar: boolean
}
export const getLayoutHasHeaderSidebar = (
  slateLayout: SlateLayout,
): LayoutHasHeaderSidebar => {
  switch (slateLayout) {
    case SlateLayout.headerNoSidebar:
      return { pageHasHeader: true, pageHasLeftSidebar: false }
    case SlateLayout.headerSidebar:
      return { pageHasHeader: true, pageHasLeftSidebar: true }
    case SlateLayout.noHeaderNoSidebar:
      return { pageHasHeader: false, pageHasLeftSidebar: false }
    case SlateLayout.sidebarNoHeader:
      return { pageHasHeader: false, pageHasLeftSidebar: true }
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = slateLayout
      noop(exhaustiveCheck)
      return { pageHasHeader: false, pageHasLeftSidebar: false }
    }
  }
}
