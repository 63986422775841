import { memo, useMemo } from 'react'

import { T } from '../../../../i18n/components/T.js'
import { cn } from '../../../helpers/cn.js'
import { TableOfContentHeading } from '../types.js'

export type TableOfContentsProps = {
  content: TableOfContentHeading[]
  showPlaceholder?: boolean
}

/*
  as we have some elements that render on top of our document, we need to offset the scroll position
  to avoid jumping to the wrong heading
*/
const offsetQueries = [
  '[data-block-name="announcement-banner"]',
  '[data-block-name="navbar"]',
]

export const TableOfContents = memo(
  ({ content, showPlaceholder = true }: TableOfContentsProps) => {
    const startLevel = useMemo(() => {
      return content.reduce((acc, item) => {
        if (acc === null) {
          return item.level
        }

        return item.level < acc ? item.level : acc
      }, null as number | null)
    }, [content])

    if (content.length < 1) {
      if (showPlaceholder) {
        return (
          <div className="text-sm text-content-subdued">
            <T
              id="Generics.StartAddingHeadlines"
              defaultMessage="Start adding headlines to your document …"
            />
          </div>
        )
      }
      return null
    }

    return (
      <>
        <div className="flex flex-col gap-1">
          {content.map(item => (
            <a
              key={item.id}
              href={`#${item.id}`}
              style={{
                marginLeft: `${1 * (item.level - startLevel)}rem`,
              }}
              onClick={e => {
                e.preventDefault()
                const element = document.getElementById(item.id)

                if (!element) {
                  return undefined
                }

                const offset = offsetQueries.reduce((acc, query) => {
                  const element = document.querySelector(query)
                  if (element) {
                    return acc + element.getBoundingClientRect().height
                  }
                  return acc
                }, 0)

                const elementPosition =
                  element.getBoundingClientRect().top + window.scrollY
                const offsetPosition = elementPosition - offset

                window.scrollTo({
                  top: offsetPosition,
                  behavior: 'smooth',
                })

                return undefined
              }}
              className={cn(
                'block font-medium text-content-disabled p-1 rounded text-sm hover:text-content-hovered transition-all truncate w-fit',
              )}
            >
              {item.textContent}
            </a>
          ))}
        </div>
      </>
    )
  },
)

TableOfContents.displayName = 'TableOfContents'
