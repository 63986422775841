import type { ComponentProps, ReactElement } from 'react'
import { cloneElement, isValidElement } from 'react'

import { clsx } from 'clsx'

import { iconStyles } from './Icon.styles.js'
import { IconStyleProps } from './types.js'

export type IconProps = ComponentProps<'svg'> & IconStyleProps

/**
 * @deprecated use SvgIcon instead
 */
export const Icon = (props: IconProps) => {
  const { children, className, size, ...rest } = props

  return isValidElement(children) ? (
    cloneElement(children as ReactElement, {
      'aria-hidden': true,
      focusable: false,
      className: clsx(
        className,
        children?.props?.className,
        iconStyles({ size }),
      ),
      ...rest,
    })
  ) : (
    <>{children}</>
  )
}
