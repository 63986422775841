import React from 'react'

import { Controller, useFormContext } from 'react-hook-form'

import { FormControl } from '@tribeplatform/react-ui-kit/FormControl'

import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { FormFieldProps } from '../typings.js'
import { FormFileUpload, FormFileUploadProps } from './FormFileUpload.js'

export function FormFile({
  name,
  validation,
  label,
  helperText,
  value: fieldValue,
  onChange: fileOnChange,
  ...rest
}: FormFieldProps<FormFileUploadProps>) {
  const { $t } = useI18n()
  const { control, setError } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => {
        return (
          <div>
            {label ? (
              <FormControl.Label htmlFor={name}>{label}</FormControl.Label>
            ) : null}
            <div className="mt-1">
              <FormFileUpload
                {...rest}
                invalid={invalid}
                value={fieldValue ?? value}
                onChange={value => {
                  onChange(value)
                  if (typeof fileOnChange === 'function') {
                    fileOnChange(value)
                  }
                }}
                onError={({ message, fileName }) => {
                  setError(name, {
                    type: 'validate',
                    message: fileName
                      ? $t(
                          {
                            id: 'Generics.UploadFailedWithMessage',
                            defaultMessage:
                              'Could not upload {file_name}: {err_message}',
                          },
                          { file_name: fileName, err_message: message },
                        )
                      : message,
                  })
                }}
              />
              <FormControl.Meta
                {...{
                  helperText,
                  invalid,
                  error: error?.message,
                }}
              />
            </div>
          </div>
        )
      }}
    />
  )
}
