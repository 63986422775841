import { ReactNode } from 'react'

export type IndicatorBadgeWrapperProps = {
  children: ReactNode
  indicatorBadge: ReactNode
}

export const IndicatorBadgeWrapper = ({
  children,
  indicatorBadge,
}: IndicatorBadgeWrapperProps) => (
  <div className="relative inline-block">
    {children}
    <div className="absolute top-0 end-0 transform translate-x-1/4 -translate-y-1/4 flex items-center justify-center">
      {indicatorBadge}
    </div>
  </div>
)
