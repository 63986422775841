import {
  type ElementType,
  FC,
  forwardRef,
  type ReactNode,
  type ReactElement,
} from 'react'

import { VariantProps } from 'class-variance-authority'
import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import { BackgroundProvider } from '../BackgroundContext/index.js'
import { BleedProvider } from '../Bleed/BleedContext.js'
import { useThemeContext } from '../ThemeProvider/index.js'
import {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from '../types/polymorphic.js'
import { cardMediaStyles, cardStyles } from './Card.styles.js'
import { CardPadding, CardProvider, useCard } from './CardContext.js'
import { CardActions, CardFooter } from './CardFooter.js'
import { CardHeader } from './CardHeader.js'

interface Props extends VariantProps<typeof cardStyles> {
  children: ReactNode
  padding?: CardPadding
}

export type CardProps<C extends ElementType> = PolymorphicComponentPropsWithRef<
  C,
  Props
>
type CardComponent = <C extends ElementType = 'div'>(
  props: CardProps<C>,
) => ReactElement | null

/**
 * A “card” is a UI design pattern that groups related information in a flexible-size container visually resembling a playing card.
 */

export type CompoundCardComponent = CardComponent & {
  Header?: typeof CardHeader
  Content?: typeof CardContent
  Footer?: typeof CardFooter
  Media?: typeof CardMedia
  Actions?: typeof CardActions
}

export const Card: CompoundCardComponent = forwardRef(
  <C extends React.ElementType = 'div'>(
    {
      as,
      children,
      className,
      justifyContent = 'between',
      transparent = false,
      padding = 'md',
      attached = 'none',
      ...rest
    }: CardProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const Component = as || 'div'

    return (
      <Component
        ref={ref}
        className={clsx(
          cardStyles({
            justifyContent,
            transparent,
            attached,
          }),
          className,
        )}
        {...rest}
      >
        <CardProvider padding={padding}>
          <BackgroundProvider backgroundType="surface">
            {children}
          </BackgroundProvider>
        </CardProvider>
      </Component>
    )
  },
)

export type CardContentProps = React.ComponentProps<'div'> & {
  withBleed?: boolean
}

const CardContent: FC<CardContentProps> = ({
  children,
  className,
  withBleed = false,
  ...rest
}) => {
  const { padding } = useCard()

  return (
    <div
      className={twMerge(
        clsx(
          'flex-1',
          padding === 'md' && 'px-4 py-5 sm:p-6',
          padding === 'sm' && 'px-4 py-5 sm:px-3',
          className,
        ),
      )}
      {...rest}
    >
      {withBleed ? (
        <BleedProvider
          bleedClassName={clsx(
            padding === 'md' && '-ms-4 -me-4 sm:-mx-6',
            padding === 'sm' && '-ms-4 -me-4 sm:-mx-3',
          )}
        >
          {children}
        </BleedProvider>
      ) : (
        children
      )}
    </div>
  )
}

export type CardMediaProps = React.ComponentProps<'div'> &
  VariantProps<typeof cardMediaStyles>

const CardMedia = ({
  children,
  className,
  top,
  bottom,
  ...rest
}: CardMediaProps) => {
  const { style } = useThemeContext()
  const { cards } = style

  const bordered = cards === 'bordered'

  return (
    <div
      className={cardMediaStyles({
        top,
        bottom,
        bordered,
        className,
      })}
      {...rest}
    >
      {children}
    </div>
  )
}

Card.Header = CardHeader
Card.Content = CardContent
Card.Footer = CardFooter
Card.Media = CardMedia
Card.Actions = CardActions
