import { Component, createRef } from 'react'

import { DropZoneFileType } from './types.js'

export interface FileDropZoneInputProps {
  id: string
  accept?: string
  disabled: boolean
  type: DropZoneFileType
  multiple: boolean
  openFileDialog?: boolean
  onChange(event: DragEvent | React.ChangeEvent<HTMLInputElement>): void
  onFocus(): void
  onBlur(): void
  onFileDialogClose?(): void
}

// Due to security reasons, browsers do not allow file inputs to be opened artificially.
// For example `useEffect(() => { ref.click() })`. Oddly enough react class-based components bi-pass this.
export class FileDropZoneInput extends Component<
  FileDropZoneInputProps,
  never
> {
  private fileInputNode = createRef<HTMLInputElement>()

  componentDidMount() {
    const { openFileDialog } = this.props
    if (openFileDialog) {
      this.triggerFileDialog()
    }
  }

  componentDidUpdate() {
    const { openFileDialog } = this.props
    if (openFileDialog) {
      this.triggerFileDialog()
    }
  }

  render() {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const { openFileDialog, onFileDialogClose, ...inputProps } = this.props

    return <input {...inputProps} ref={this.fileInputNode} autoComplete="off" />
  }

  private triggerFileDialog = () => {
    this.open()
    if (this.props.onFileDialogClose) {
      this.props.onFileDialogClose()
    }
  }

  private open = () => {
    if (!this.fileInputNode.current) {
      return
    }
    this.fileInputNode.current.click()
  }
}
