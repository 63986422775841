import { File, GlyphMediaVariant, Image } from '@tribeplatform/gql-client/types'
import { I18nShape } from '@tribeplatform/react-components/i18n'

import { KnownPostTypes } from '../../../types/post.types.js'
import { IconSettings, StoredField } from '../Fields/types.js'
import {
  Alignment,
  IconWrapperStyle,
  MediaType,
  SpaceHeaderFieldKey,
  SpaceHeaderPreset,
  SpaceHeaderProps,
  TextColor,
  SpaceHeaderIconSize,
  ActionButtonVariant,
} from '../types.js'

type GroupedFields = Array<StoredField | Array<StoredField>>

export const getTranslatedAddPostText = (
  { $t }: I18nShape,
  postTypeName: string,
) => {
  const knownPostTypeName = postTypeName as KnownPostTypes
  switch (knownPostTypeName) {
    case 'Discussion':
      return $t({
        defaultMessage: 'Add discussion',
        id: 'Space.Actions.AddPost.Discussion',
      })
    case 'Question':
      return $t({
        defaultMessage: 'Add question',
        id: 'Space.Actions.AddPost.Question',
      })
    case 'Idea':
      return $t({
        defaultMessage: 'Add idea',
        id: 'Space.Actions.AddPost.Idea',
      })
    case 'Article':
      return $t({
        defaultMessage: 'Add article',
        id: 'Space.Actions.AddPost.Article',
      })
    case 'Event':
      return $t({
        defaultMessage: 'Add event',
        id: 'Space.Actions.AddPost.Event',
      })
    case 'Product update':
      return $t({
        defaultMessage: 'Add product update',
        id: 'Space.Actions.AddPost.ProductUpdate',
      })
    case 'Changelog':
      return $t({
        defaultMessage: 'Add changelog',
        id: 'Space.Actions.AddPost.Changelog',
      })
    case 'Job':
      return $t({
        defaultMessage: 'Add job',
        id: 'Space.Actions.AddPost.Job',
      })
    default: {
      const unknownPostTypeName: never = knownPostTypeName

      return $t(
        {
          defaultMessage: 'Add <lower>{post_type}</lower>',
          id: 'Space.Actions.AddPost',
        },
        {
          post_type: unknownPostTypeName,
        },
      )
    }
  }
}

export const shouldApplyDefaultAlignment = (field: StoredField) => {
  return field.settings?.stretchX && field.settings?.alignment === undefined
}

export const getPaddingTopWhenIconEnabled = (iconSettings: IconSettings) => {
  if (!iconSettings) {
    return ''
  }

  const iconSize = iconSettings.size
  const wrapped = !!iconSettings.wrapInCard
  const stretched = !!iconSettings.stretchX

  if (!stretched) {
    return ''
  }

  switch (iconSize) {
    case SpaceHeaderIconSize.ExtraSmall:
      return wrapped ? 'pt-3' : 'pt-1'
    case SpaceHeaderIconSize.Small:
      return wrapped ? 'pt-4' : 'pt-2'
    case SpaceHeaderIconSize.Medium:
      return wrapped ? 'pt-6' : 'pt-3'
    case SpaceHeaderIconSize.Large:
      return wrapped ? 'pt-8' : 'pt-4'
    default:
      return ''
  }
}

/**
 * Groups the non-stretched fields in groups of two.
 * @param fields - The fields to group.
 */
export const groupNotStretchedItems = (
  fields: StoredField[],
): GroupedFields => {
  return fields
    .reduce<GroupedFields>((acc, item) => {
      const stretched = item.settings?.stretchX

      if (!stretched) {
        const lastGroup = acc[acc.length - 1]

        if (Array.isArray(lastGroup) && lastGroup.length === 1) {
          return [...acc.slice(0, -1), [...lastGroup, item]]
        }

        return [...acc, [item]]
      }

      return [...acc, item]
    }, [])
    .flatMap(group =>
      Array.isArray(group) && group.length === 1 ? group : [group],
    )
}

export const getEmbedProps = (intl: I18nShape): SpaceHeaderProps => {
  const { $t } = intl

  return {
    preset: SpaceHeaderPreset.MediaTopIconTop,
    contentSpacing: 1,
    mediaAspectRatio: 3,
    textColor: TextColor.Default,
    contentAlignment: Alignment.Start,
    mediaType: MediaType.Image,
    useSpaceBanner: true,
    image: {
      url: 'https://tribe-s3-production.imgix.net/DspBwptWGkZ5ShIdKvzjm?w=500&auto=compress,format&dl',
    } as Image,
    video: {
      url: 'https://files-us-east-1.t-cdn.net/files/r7zBnRPvbOSeWlppDkPAs?dl',
      downloadUrl:
        'https://files-us-east-1.t-cdn.net/files/r7zBnRPvbOSeWlppDkPAs?dl',
    } as File,
    mediaTint: 25,
    fields: [
      {
        id: 'Icon',
        enabled: true,
        key: SpaceHeaderFieldKey.Icon,
        settings: {
          stretchX: true,
          size: SpaceHeaderIconSize.Medium,
          iconWrapperStyle: IconWrapperStyle.Circle,
          useSpaceIcon: true,
          customIcon: {
            id: 'emoji/1f508',
            text: '1f508',
            variant: GlyphMediaVariant.emoji,
          },
        },
      },
      {
        id: 'Title',
        key: SpaceHeaderFieldKey.Title,
        enabled: true,
        settings: {
          stretchX: false,
          customTitle: $t(
            {
              id: 'Blocks.SpaceHeader.CustomTitlePlaceholder',
              defaultMessage: 'Welcome to {space_name_liquid_var}',
            },
            {
              space_name_liquid_var: '{{ space.name }}',
            },
          ),
          useSpaceName: true,
          showPrivateIcon: true,
          size: 'headingMd',
        },
      },
      {
        enabled: true,
        id: 'Buttons',
        key: SpaceHeaderFieldKey.Buttons,
        fields: [
          {
            enabled: true,
            id: 'AddPostButton',
            key: SpaceHeaderFieldKey.AddPostButton,
            settings: {
              stretchX: true,
              isSubField: true,
            },
          },
          {
            enabled: true,
            id: 'JoinButton',
            key: SpaceHeaderFieldKey.JoinButton,
            settings: {
              stretchX: false,
              isSubField: true,
              variant: ActionButtonVariant.IconText,
            },
          },
          {
            enabled: true,
            id: 'OptionsButton',
            key: SpaceHeaderFieldKey.OptionsButton,
            settings: {
              stretchX: true,
              isSubField: true,
              variant: ActionButtonVariant.Icon,
            },
          },
        ],
        settings: {
          stretchX: false,
        },
      },
    ],
  }
}
