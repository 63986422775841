import {
  type UseMutationOptions,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query'

import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Action,
  MutationUpdateSpaceHighlightedTagsArgs,
} from '@tribeplatform/gql-client/types'

import { useTribeClient } from '../../useTribeClient.js'
import { getSpaceKey } from '../../utils/keys/space.key.js'

export const useUpdateSpaceHighlightedTags = (options?: {
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationUpdateSpaceHighlightedTagsArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()
  return useMutation<
    Action,
    ClientError,
    MutationUpdateSpaceHighlightedTagsArgs
  >(
    (input: MutationUpdateSpaceHighlightedTagsArgs) =>
      client.spaces.updateHighlightedTags(input),
    {
      ...useMutationOptions,
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(
          getSpaceKey({
            variables: {
              id: variables.spaceId,
            },
          }),
        )
        if (typeof useMutationOptions.onSuccess === 'function') {
          useMutationOptions.onSuccess(data, variables, context)
        }
      },
    },
  )
}
