import {
  CustomReactionFields,
  CustomReactionGQLFields,
} from '../../types/fields.generated.js'
import { MediaFields, mediaGQLFields } from '../media/media.fields.js'
import {
  CustomFieldsSchemaFields,
  customFieldsSchemaGQLFields,
} from '../network/customFieldsSchema.fields.js'
import {
  PostTypeAuthMemberPropsFields,
  postTypeAuthMemberPropsGQLFields,
} from '../permission/postTypeAuthMemberProps.fields.js'
import { SlateFields, slateGQLFields } from '../slate/slate.fields.js'

export type PostTypeFields = 'basic' | 'all' | CustomPostTypeFields
export type AuthMemberPropsFields = 'basic' | 'all'

export interface CustomPostTypeFields {
  slate?: SlateFields
  postFields?: CustomFieldsSchemaFields
  validReplyTypes?: PostTypeFields
  authMemberProps?: PostTypeAuthMemberPropsFields
  icon?: MediaFields
  customReactions?: CustomReactionFields
}

const BASIC_POST_TYPE_FIELDS: CustomPostTypeFields = {}
const ALL_POST_TYPE_FIELDS: CustomPostTypeFields = {
  slate: 'all',
  postFields: 'basic',
  validReplyTypes: 'basic',
  authMemberProps: 'basic',
  icon: 'basic',
}

export function postTypeGQLFields(fields: PostTypeFields) {
  if (fields === 'basic') {
    fields = BASIC_POST_TYPE_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_POST_TYPE_FIELDS
  }

  return `
    __typename
    archived
    allowedEmojis
    context
    createdAt
    forbiddenEmojis
    id
    languageTemplate
    name
    description
    nativeFieldsTemplates {
      description
      thumbnailId
      title
    }
    negativeReactions
    pluralName
    positiveReactions
    primaryReactionType
    shortContentTemplate
    singleChoiceReactions
    allowedReactions
    customReactions {
      ${CustomReactionGQLFields(fields.customReactions)}
    }
    ${
      fields.slate
        ? `
        slate {
        ${slateGQLFields(fields.slate)}
      }
    `
        : ``
    }
    slug
    titleTemplate
    updatedAt   
    mappings {
      key
      field
      type
      title
      description
      required
      isMainContent
      isSearchable
      default
    }
    ${
      fields.postFields
        ? `
      postFields {
        ${customFieldsSchemaGQLFields(fields.postFields)}
      }
    `
        : ``
    }
    ${
      fields.icon
        ? `
      icon {
        ${mediaGQLFields(fields.icon)}
      }
    `
        : ``
    }    
    ${
      fields.validReplyTypes
        ? `
      validReplyTypes {
        ${postTypeGQLFields(fields.validReplyTypes)}
      }
    `
        : ``
    }
    ${
      fields.authMemberProps
        ? `
      authMemberProps {
        ${postTypeAuthMemberPropsGQLFields(fields.authMemberProps)}
      }
    `
        : ``
    }
  `
}
