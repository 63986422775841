import { ReactNode } from 'react'

import { T } from '@tribeplatform/react-components/i18n'
import { Accordion } from '@tribeplatform/react-ui-kit/Accordion'
import { SimpleDivider } from '@tribeplatform/react-ui-kit/Divider'

interface Props {
  children: ReactNode
}

export const SettingSections = ({ children }: Props) => {
  return <div className="space-y-2">{children}</div>
}

interface HocProps {
  children: ReactNode
}

const SectionHOC = (title: JSX.Element, defaultOpen?: boolean) => {
  return ({ children }: HocProps) => {
    return (
      <>
        <Accordion
          defaultOpen={defaultOpen}
          iconAlign="trailing"
          className="-mx-2"
        >
          <Accordion.Button noHover className="hover:text-content-hovered">
            {title}
          </Accordion.Button>
          <Accordion.Panel>
            <div className="space-y-5">{children}</div>
          </Accordion.Panel>
        </Accordion>
        <SimpleDivider variant="subdued" />
      </>
    )
  }
}

SettingSections.Layout = SectionHOC(
  <T defaultMessage="Layout" id="Generics.Layout" />,
  true,
)
SettingSections.Padding = SectionHOC(
  <T defaultMessage="Padding" id="Generics.Padding" />,
)
SettingSections.Margin = SectionHOC(
  <T defaultMessage="Margin" id="Generics.Margin" />,
)

SettingSections.Responsive = SectionHOC(
  <T defaultMessage="Responsive" id="Generics.Responsive" />,
)
