import { T } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Text, TextVariant } from '@tribeplatform/react-ui-kit/Text'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

interface Props {
  size?: TextVariant
}

export const PrivacyIcon = ({ size }: Props) => {
  return (
    <Tooltip>
      <Tooltip.Trigger>
        <Text
          as="h2"
          className="line-clamp-3"
          variant={size}
          color="inherit"
          fontWeight="bold"
        >
          <SvgIcon name="lock" size="md" />
        </Text>
      </Tooltip.Trigger>
      <Tooltip.Panel>
        <T id="Generics.PrivateSpace" defaultMessage="Private {SPACE}" />
      </Tooltip.Panel>
    </Tooltip>
  )
}
