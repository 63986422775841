import { SpacePostsArgs } from '../../types/index.js'
import { PostFields, postGQLFields } from '../post/posts.fields.js'

export type SpacePostsFields = {
  fields: PostFields
  variables: SpacePostsArgs
}

export function spacePostsGQLFields(fields: SpacePostsFields): string {
  return `
      posts(limit: ${fields.variables.limit || 1}) {
        edges {
          cursor
          node {
            ${postGQLFields(fields.fields)}
          }
        }
        nodes {
          ${postGQLFields(fields.fields)}
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    `
}
