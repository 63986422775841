import { clsx } from 'clsx'

import {
  Image,
  SearchEntity,
  SearchEntityType,
  UnknownMedia,
} from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { Avatar } from '@tribeplatform/react-ui-kit/Avatar'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { EntityImage } from '../common/components/EntityImage.js'
import { MemberAvatarPlaceholder } from '../common/components/index.js'
import { T } from '../i18n/components/T.js'
import { SearchSubtitle } from './SearchSubtitle.js'
import { getSearchItemLink } from './utils.js'

export const SearchRowPreview = ({
  hit,
  entityType,
}: {
  hit: SearchEntity
  entityType: SearchEntityType
}) => {
  switch (entityType) {
    case SearchEntityType.member:
      return (
        <Avatar
          size="2.5x"
          src={(hit?.media as Image)?.url}
          fallback={MemberAvatarPlaceholder}
          name={hit?.title}
        />
      )
    case SearchEntityType.External:
      return <SvgIcon className="w-10 h-10 text-content-subdued" name="post" />
    case SearchEntityType.space:
    case SearchEntityType.post:
      {
        const media = (hit?.in?.image as UnknownMedia)?.__typename
          ? hit?.in?.image
          : hit?.media

        if ((media as UnknownMedia)?.__typename === 'Image') {
          return (
            <Avatar rounded="base" size="2.5x" src={(media as Image)?.url} />
          )
        }

        if (media) {
          return (
            <div className="flex items-center justify-center">
              <EntityImage entity={{ image: media }} size="2.5x" />
            </div>
          )
        }
      }
      return null
    default:
      return null
  }
}

export const SearchRow = ({
  hit,
  entityType,
  spacing = 'md',
}: {
  hit: SearchEntity
  entityType: SearchEntityType
  spacing?: string
}) => {
  const { Link } = useRouter()

  // The space for the post is deleted
  if (entityType === SearchEntityType.post && !hit?.in) {
    return null
  }

  return (
    <Link
      href={getSearchItemLink(hit)}
      target={entityType === SearchEntityType.External ? '_blank' : '_self'}
      className={clsx(
        'flex items-center space-s-3',
        spacing === 'md' && 'px-6 py-5',
        spacing === 'sm' && 'px-2 py-2',
      )}
    >
      <div className="flex-shrink-0">
        <SearchRowPreview hit={hit} entityType={entityType} />
      </div>
      <div className="flex-1 min-w-0">
        <p className="text-content">{hit.title}</p>
        <p className="text-content-subdued truncate">
          {!!hit && <SearchSubtitle hit={hit} />}
        </p>
      </div>
    </Link>
  )
}

export const SearchRowPage = ({ search, link, spacing = 'md' }) => {
  const { Link } = useRouter()

  if (!search) {
    return null
  }

  return (
    <Link
      href={link}
      className={clsx(
        'flex items-center space-s-3',
        spacing === 'md' && 'px-6 py-5',
        spacing === 'sm' && 'px-2 py-2',
      )}
    >
      <div className="flex-1 min-w-0">
        <span className="text-content">{search}</span>
        <span className="text-content-subdued truncate">
          {' — '}
          <T
            id="Search.Hints.SearchEverything"
            defaultMessage="Search posts, {MEMBERS} and more"
          />
        </span>
      </div>
    </Link>
  )
}
