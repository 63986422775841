import { useMemo } from 'react'

import { File } from '@tribeplatform/gql-client/types'

import { FileListItem } from '../../common/components/FileListItem.js'
import { useMediaModal } from '../../MediaModal/MediaModalProvider.js'

type FilesViewProps = {
  files: File[]
}

export const FilesView = ({ files }: FilesViewProps) => {
  const { pushSlide, openSlideWithIndex } = useMediaModal()
  const fileListItemProps = useMemo(() => {
    return files.map(file => {
      const slideIndex = pushSlide(file)
      return {
        ...file,
        id: file.id ?? file.name,
        name: file.name,
        onPreview: () => {
          openSlideWithIndex(slideIndex)
        },
      }
    })
  }, [files, pushSlide, openSlideWithIndex])
  return (
    <div className="flex flex-col">
      {fileListItemProps.map(({ name, id, ...props }) => (
        <FileListItem key={id || name} name={name} {...props} />
      ))}
    </div>
  )
}
