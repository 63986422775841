import { cva } from 'class-variance-authority'

export const avatarStyles = cva(['shrink-0'], {
  variants: {
    rounded: {
      none: 'rounded-none',
      sm: 'rounded-avatar',
      md: 'rounded-avatar',
      base: 'rounded-base',
      avatar: 'rounded-avatar',
    },
  },
  defaultVariants: {
    rounded: 'avatar',
  },
})

export const avatarIconStyles = cva(
  [
    'absolute bottom-1 end-1',
    'bg-surface rounded-avatar text-xs text-content-subdued',
    'transform translate-x-1/2 translate-y-1/2',
  ],
  {
    variants: {
      size: {
        '2xs': 'h-3 w-3',
        xs: 'h-3 w-3',
        sm: 'h-3 w-3',
        md: 'h-4 w-4',
        lg: 'h-4 w-4',
        xl: 'h-4 w-4',
        '1.5x': 'h-5 w-5',
        '2x': 'h-5 w-5',
        '2.5x': 'h-5 w-5',
        '3x': 'h-6 w-6',
        '4x': 'h-6 w-6',
        '6x': 'h-6 w-6',
        '8x': 'h-6 w-6',
      },
    },
  },
)

export const avatarPlaceholderStyles = cva('font-medium uppercase', {
  variants: {
    size: {
      '2xs': 'text-xs',
      xs: 'text-xs',
      sm: 'text-xs',
      md: 'text-xs',
      lg: 'text-xs',
      xl: 'text-sm',
      '1.5x': 'text-sm',
      '2x': 'text-sm',
      '2.5x': 'text-sm',
      '3x': 'text-md',
      '4x': 'text-xl',
      '6x': 'text-xl',
      '8x': 'text-xl',
    },
  },
})
