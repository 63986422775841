import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { StoreItemStatus, Template } from '@tribeplatform/gql-client/types'
import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { useRouter } from '@tribeplatform/react-sdk'
import {
  useDeleteTemplate,
  useNetwork,
  usePublishTemplate,
  useUnpublishTemplate,
} from '@tribeplatform/react-sdk/hooks'
import { confirm } from '@tribeplatform/react-ui-kit/Dialog'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

type TemplateActionItemsProps = {
  template: Template
}

export const TemplateActionItems = ({ template }: TemplateActionItemsProps) => {
  const { Link } = useRouter()
  const { $t } = useI18n()
  const { data: network } = useNetwork()
  const { mutateAsync: deleteTemplate } = useDeleteTemplate()
  const { mutateAsync: publishTemplate } = usePublishTemplate()
  const { mutateAsync: unpublishTemplate } = useUnpublishTemplate()

  const [
    canUpdateTemplate,
    canDeleteTemplate,
    canPublishTemplate,
    canUnpublishTemplate,
  ] = hasScopesPermission(network, [
    'updateTemplate',
    'deleteTemplate',
    'publishTemplate',
    'unpublishTemplate',
  ])

  const onDelete = async () => {
    const proceed = await confirm({
      title: $t({
        defaultMessage: 'Delete template',
        id: 'Generics.DeleteTemplate',
      }),
      description: $t({
        defaultMessage: 'Are you sure you want to delete this template?',
        id: 'Admin.Templates.DeleteTemplateDescription',
      }),
      proceedLabel: $t({ defaultMessage: 'Delete', id: 'Generics.Delete' }),
      cancelLabel: $t({ defaultMessage: 'Cancel', id: 'Generics.Cancel' }),
      danger: true,
    })
    if (proceed) {
      try {
        await deleteTemplate({ id: template.id })
        toast({
          title: $t({
            defaultMessage: 'Template deleted',
            id: 'Generics.TemplateDeleted',
          }),
          status: 'success',
        })
      } catch (error) {
        toast({
          title: $t({
            defaultMessage: 'Template not deleted',
            id: 'Generics.TemplateNotDeleted',
          }),
          status: 'error',
        })
      }
    }
  }

  const onPublish = async () => {
    const proceed = await confirm({
      title: $t({
        defaultMessage: 'Publish template',
        id: 'Generics.PublishTemplate',
      }),
      description: $t({
        defaultMessage: 'Are you sure you want to publish this template?',
        id: 'Admin.Template.PublishTemplateDescription',
      }),
      proceedLabel: $t({ defaultMessage: 'Publish', id: 'Generics.Publish' }),
      cancelLabel: $t({ defaultMessage: 'Cancel', id: 'Generics.Cancel' }),
      danger: false,
    })
    if (proceed) {
      try {
        publishTemplate({ id: template.id })
        toast({
          title: $t({
            defaultMessage: 'Template published',
            id: 'Generics.TemplatePublished',
          }),
          status: 'success',
        })
      } catch (error) {
        toast({
          title: $t({
            defaultMessage: 'Template not published',
            id: 'Generics.TemplateNotPublished',
          }),
          status: 'error',
        })
      }
    }
  }

  const onUnpublish = async () => {
    const proceed = await confirm({
      title: $t({
        defaultMessage: 'Unpublish template',
        id: 'Generics.UnpublishTemplate',
      }),
      description: $t({
        defaultMessage: 'Are you sure you want to unpublish this template?',
        id: 'Admin.Template.UnpublishTemplateDescription',
      }),
      proceedLabel: $t({
        defaultMessage: 'Unpublish',
        id: 'Generics.Unpublish',
      }),
      cancelLabel: $t({ defaultMessage: 'Cancel', id: 'Generics.Cancel' }),
      danger: false,
    })
    if (proceed) {
      try {
        unpublishTemplate({ id: template.id })
        toast({
          title: $t({
            defaultMessage: 'Template unpublished',
            id: 'Generics.TemplateUnpublished',
          }),
          status: 'success',
        })
      } catch (error) {
        toast({
          title: $t({
            defaultMessage: 'Template not unpublished',
            id: 'Generics.TemplateNotUnpublished',
          }),
          status: 'error',
        })
      }
    }
  }

  return (
    <Dropdown.Items>
      {canUpdateTemplate && (
        <Dropdown.Item
          as={Link}
          href={`/manage/customizer/templates/edit/${template.id}/?spaceId=${template.entityId}`}
          leadingIcon={<SvgIcon name="edit" />}
        >
          <T id="Generics.Edit" defaultMessage="Edit" />
        </Dropdown.Item>
      )}
      {canPublishTemplate && template.status === StoreItemStatus.PRIVATE && (
        <Dropdown.Item
          leadingIcon={<SvgIcon name="upload" />}
          onClick={onPublish}
        >
          <T id="Generics.Publish" defaultMessage="Publish" />
        </Dropdown.Item>
      )}
      {canUnpublishTemplate && template.status === StoreItemStatus.PUBLIC && (
        <Dropdown.Item
          leadingIcon={<SvgIcon name="reverse-left" />}
          onClick={onUnpublish}
        >
          <T id="Generics.Unpublish" defaultMessage="Unpublish" />
        </Dropdown.Item>
      )}
      {canDeleteTemplate && (
        <Dropdown.Item
          leadingIcon={<SvgIcon name="delete" />}
          onClick={onDelete}
        >
          <T id="Generics.Delete" defaultMessage="Delete" />
        </Dropdown.Item>
      )}
    </Dropdown.Items>
  )
}
