import {
  GlyphMediaVariant,
  type CustomReaction,
} from '@tribeplatform/gql-client/types'

// These legacy reactions are used in the data sanitization process and should not be changed
export const legacyEmojiReactions: CustomReaction[] = [
  {
    key: '+1',
    name: 'Like',
    glyphId: `${GlyphMediaVariant.emoji}/1f44d`,
    activeName: 'Liked',
  },
  {
    key: 'heart',
    name: 'Love',
    glyphId: `${GlyphMediaVariant.emoji}/2764-fe0f`,
    activeName: 'Loved',
  },
  {
    key: 'tada',
    name: 'Celebrate',
    glyphId: `${GlyphMediaVariant.emoji}/1f389`,
    activeName: 'Celebrated',
  },
  {
    key: 'smile',
    name: 'Smile',
    glyphId: `${GlyphMediaVariant.emoji}/1f601`,
    activeName: 'Smiled',
  },
  {
    key: 'open_mouth',
    name: 'Wow',
    glyphId: `${GlyphMediaVariant.emoji}/1f62e`,
    activeName: 'Wow!',
  },
  {
    key: 'cry',
    name: 'Cry',
    glyphId: `${GlyphMediaVariant.emoji}/1f622`,
    activeName: 'Cried',
  },
]

export const legacyVoteReactions: CustomReaction[] = [
  {
    key: '+1',
    name: 'Upvote',
    glyphId: `${GlyphMediaVariant.icon}/chevron-up`,
    activeName: 'Upvoted',
  },
]

export const legacyLikeReactions: CustomReaction[] = [
  {
    key: '+1',
    name: 'Like',
    glyphId: `${GlyphMediaVariant.icon}/thumbs-up`,
    activeName: 'Liked',
  },
]

// The initial reactions are now set to legacyReactions, but we can change these to whatever we want
// The important thing is that we should not change the legacyReactions
export const initialReactions = legacyEmojiReactions
