import {
  PostTypeFields,
  postTypeGQLFields,
} from '../post-type/postType.fields.js'
import {
  actionPermissionGQLFields,
  ActionPermissionsFields,
} from './actionPermissions.fields.js'

export type PostAuthMemberPropsFields =
  | 'basic'
  | 'all'
  | CustomPostAuthMemberPropsFields

export interface CustomPostAuthMemberPropsFields {
  permissions?: ActionPermissionsFields
  availableReplyTypes?: PostTypeFields
  canReact?: boolean
}

const BASIC_POST_AUTH_MEMBER_PROPS_FIELDS: CustomPostAuthMemberPropsFields = {}
const ALL_POST_AUTH_MEMBER_PROPS_FIELDS: CustomPostAuthMemberPropsFields = {
  permissions: 'basic',
  availableReplyTypes: 'basic',
  canReact: true,
}

export function postAuthMemberPropsGQLFields(
  fields: PostAuthMemberPropsFields,
): string {
  if (fields === 'basic') {
    fields = BASIC_POST_AUTH_MEMBER_PROPS_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_POST_AUTH_MEMBER_PROPS_FIELDS
  }
  return `
      context
      scopes
      subscribed
      ${
        fields.permissions
          ? `
        permissions {
          ${actionPermissionGQLFields(fields.permissions)}
        }
      `
          : ``
      }
      ${
        fields.availableReplyTypes
          ? `
          availableReplyTypes {
              ${postTypeGQLFields(fields.availableReplyTypes)}
          }
          `
          : ''
      }
      ${
        fields.canReact
          ? `
      canReact
      `
          : ''
      }
    `
}
