import { useCallback } from 'react'

import { useI18n } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { useKeypressListener } from '@tribeplatform/react-ui-kit/utils'

import { type SearchAction as SearchItem } from '../../HeroBanner/types.js'
import { SearchBarRenderer } from '../Components/SearchBarRenderer.js'
import { useSearchBar } from '../providers/SearchBarProvider.js'

export const SearchOnlyLayout = ({
  searchFor,
  placeholder,
  searchSource,
  spaceIds,
}: SearchItem) => {
  const { $t } = useI18n()
  const { setSearchOpen } = useSearchBar()
  const defaultPlaceholder = $t({
    id: 'Generics.SearchDotDotDot',
    defaultMessage: 'Search...',
  })

  const closeSearchLayout = useCallback(() => {
    setSearchOpen(false)
  }, [setSearchOpen])

  useKeypressListener('Escape', closeSearchLayout, 'keydown')

  return (
    <div className="flex justify-between items-center space-s-2 h-16">
      <SearchBarRenderer
        searchFor={searchFor}
        placeholder={placeholder ?? defaultPlaceholder}
        searchSource={searchSource}
        spaceIds={spaceIds}
        autoFocus
        fullWidth
      />
      <div className="flex-shrink-0">
        <button
          type="button"
          className="p-2 block rounded-base"
          onClick={closeSearchLayout}
        >
          <SvgIcon name="close" className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  )
}
