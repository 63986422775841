import { useMembersByIds } from '@tribeplatform/react-sdk/hooks'

import { EMPTY_VALUE_PLACEHOLDER } from '../utils/values.js'

interface Props {
  value: string[]
}
export const MemberRenderer = ({ value }: Props) => {
  const { data: members, isInitialLoading } = useMembersByIds({
    variables: { ids: value },
    fields: {
      profilePicture: { onImage: { urls: 'basic' } },
    },
    useQueryOptions: {
      enabled: value.length > 0,
    },
  })
  const membersNames = members?.map(member => member.name) ?? []

  if (isInitialLoading) {
    return EMPTY_VALUE_PLACEHOLDER
  }

  return <>{membersNames.join(', ')}</>
}
