import { FC } from 'react'

import { clsx } from 'clsx'

import { useBackgroundContext } from '../BackgroundContext/index.js'

export const DIVIDER_PADDINGS = ['none', 'xs', 'sm', 'md', 'lg'] as const
export const DIVIDER_SPACING = ['tight', 'medium', 'wide'] as const
export type DividerPadding = (typeof DIVIDER_PADDINGS)[number]
export type DividerSpacing = (typeof DIVIDER_SPACING)[number]

export type DividerProps = React.ComponentProps<'div'> & {
  padding?: DividerPadding
  spacing?: DividerSpacing
  position?: 'center' | 'trailing'
}
/**
 * Dividers are used to visually separate content in a list or group.
 */
export const Divider: FC<DividerProps> = props => {
  const {
    children,
    className,
    padding = 'md',
    spacing = 'tight',
    position = 'center',
    ...rest
  } = props

  const { backgroundClsx, text400Clsx } = useBackgroundContext()

  return (
    <div
      className={clsx([
        'relative',
        padding === 'none' && 'py-0',
        padding === 'xs' && 'py-1',
        padding === 'sm' && 'py-2',
        padding === 'md' && 'py-4',
        padding === 'lg' && 'py-6',
        className,
      ])}
      {...rest}
    >
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-line" />
      </div>
      {children && (
        <div
          className={clsx(
            'relative flex',
            position === 'center' && 'justify-center',
            position === 'trailing' && 'justify-end',
          )}
        >
          <span
            className={clsx([
              spacing === 'tight' && 'px-2',
              spacing === 'medium' && 'px-4',
              spacing === 'wide' && 'px-6',
              backgroundClsx,
              text400Clsx,
            ])}
          >
            {children}
          </span>
        </div>
      )}
    </div>
  )
}

interface SimpleDividerProps {
  variant?: 'normal' | 'subdued'
  className?: string
}
export const SimpleDivider = ({ variant, className }: SimpleDividerProps) => {
  return (
    <div
      className={clsx(
        'h-px',
        variant === 'subdued' ? 'bg-line-subdued' : 'bg-line',
        className,
      )}
    ></div>
  )
}
