import { clsx } from 'clsx'

import { Accordion } from '@tribeplatform/react-ui-kit/Accordion'
import { IndicatorDot } from '@tribeplatform/react-ui-kit/IndicatorBadge'
import { Scrollable } from '@tribeplatform/react-ui-kit/Scrollable'

import type { Filter } from '../types.js'
import { isFilterSpreadable } from '../utils/inlineFilters.js'
import { isValueNotEmpty } from '../utils/values.js'
import { FilterItemPopOver } from './FilterItemPopOver.js'
import { InlineFilterItem } from './InlineFilterItem.js'

interface Props {
  filter: Filter
  defaultOpen?: boolean
  setFilter: (newFilter: Filter) => void
  removeFilter: () => void
}
export const ModalFilterItem = ({
  filter,
  defaultOpen = false,
  setFilter,
  removeFilter,
}: Props) => {
  const { name, value } = filter
  const hasValidValue = isValueNotEmpty(value)
  const canSpread = isFilterSpreadable(filter)

  return (
    <Accordion defaultOpen={defaultOpen} iconAlign="trailing" className="-mx-2">
      <Accordion.Button noHover className="hover:text-content-hovered">
        <div className="flex space-x-1 flex-1 truncate justify-between items-center">
          <span
            className={clsx(
              hasValidValue ? 'text-interactive' : 'text-content',
              'font-semibold',
            )}
          >
            {name}
          </span>
          {hasValidValue && (
            <IndicatorDot variant="primary" className="block" />
          )}
        </div>
      </Accordion.Button>
      <Accordion.Panel>
        {canSpread ? (
          <Scrollable
            className="min-w-0 mx-2 sm:mx-0"
            gradientFrom="from-surface"
            gradientVia="via-surface"
          >
            <div className="flex space-x-2 p-1">
              <InlineFilterItem
                key={filter.id}
                filter={filter}
                setFilter={setFilter}
                removeFilter={removeFilter}
                allowOperatorSelection={
                  !!filter?.extraProps?.allowOperatorSelection
                }
                spread
              />
            </div>
          </Scrollable>
        ) : (
          <FilterItemPopOver
            filter={filter}
            removeFilter={removeFilter}
            onChange={setFilter}
            showFilterLabel={!!filter?.extraProps?.allowOperatorSelection}
            allowOperatorSelection={
              !!filter?.extraProps?.allowOperatorSelection
            }
            inline
          />
        )}
      </Accordion.Panel>
    </Accordion>
  )
}
