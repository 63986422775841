import type { FieldValues } from 'react-hook-form'

import { FormActions } from './Actions.js'
import { AvatarInput } from './AvatarInput.js'
import { Checkbox } from './Checkbox.js'
import { Form as FormComponent } from './Form.js'
import { FormMemberPicker } from './FormMemberPicker.js'
import { FormPostPicker } from './FormPostPicker.js'
import { FormPostTypeIdPicker } from './FormPostTypeIdPicker.js'
import { FormSpaceIdPicker } from './FormSpaceIdPicker.js'
import { FormSpacePicker } from './FormSpacePicker.js'
import { FormTagPicker } from './FormTagPicker.js'
import { Input } from './Input.js'
import { InputCopy } from './InputCopy.js'
import { FormLayout } from './Layout.js'
import { Multiselect } from './Multiselect.js'
import { RadioGroup } from './Radio.js'
import { Select } from './Select.js'
import { Textarea } from './Textarea.js'
import { Toggle } from './Toggle.js'
import { FormComponentType } from './typings.js'

export * from './typings.js'

export interface FormType<TFormValues extends FieldValues = FieldValues>
  extends FormComponentType<TFormValues> {
  Avatar?: typeof AvatarInput
  Checkbox?: typeof Checkbox
  Input?: typeof Input
  InputCopy?: typeof InputCopy
  MemberPicker?: typeof FormMemberPicker
  RadioGroup?: typeof RadioGroup
  Select?: typeof Select
  Multiselect?: typeof Multiselect
  SpacePicker?: typeof FormSpacePicker
  SpaceIdPicker?: typeof FormSpaceIdPicker
  TagPicker?: typeof FormTagPicker
  Textarea?: typeof Textarea
  Toggle?: typeof Toggle
  Actions?: typeof FormActions
  Layout?: typeof FormLayout
  PostPicker?: typeof FormPostPicker
  PostTypeIdPicker?: typeof FormPostTypeIdPicker
}

export const Form: FormType = FormComponent

/**
 * @deprecated use Form.Image variant="avatar" instead
 */
Form.Avatar = AvatarInput
Form.Checkbox = Checkbox
Form.Input = Input
Form.InputCopy = InputCopy
Form.MemberPicker = FormMemberPicker
Form.RadioGroup = RadioGroup
Form.Select = Select
Form.SpacePicker = FormSpacePicker
Form.SpaceIdPicker = FormSpaceIdPicker
Form.TagPicker = FormTagPicker
Form.Textarea = Textarea
Form.Toggle = Toggle
Form.Actions = FormActions
Form.Multiselect = Multiselect
Form.Layout = FormLayout
Form.PostPicker = FormPostPicker
Form.PostTypeIdPicker = FormPostTypeIdPicker
