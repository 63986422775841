import { useCallback } from 'react'

import { PermissionContext } from '@tribeplatform/gql-client/types'
import {
  useInvalidatePostQuery,
  useInvalidateMemberQuery,
  useInvalidateNetworkQuery,
  useInvalidateSpaceQuery,
} from '@tribeplatform/react-sdk/hooks'

export const useReloadByContext = () => {
  const invalidatePostsQuery = useInvalidatePostQuery()
  const invalidateMemberQuery = useInvalidateMemberQuery()
  const invalidateNetworkQuery = useInvalidateNetworkQuery()
  const invalidateSpaceQuery = useInvalidateSpaceQuery()

  const reloadContext = useCallback(
    (permissionContext: PermissionContext, entityId: string) => {
      switch (permissionContext) {
        case PermissionContext.POST:
          invalidatePostsQuery(entityId)
          return undefined
        case PermissionContext.SPACE:
          invalidateSpaceQuery(entityId)
          return undefined
        case PermissionContext.NETWORK:
          invalidateNetworkQuery()
          return undefined
        case PermissionContext.MEMBER:
          invalidateMemberQuery(entityId)
          return undefined
        default: {
          // Code should never reach the default case
          const exhaustiveCheck: never = permissionContext
          return exhaustiveCheck
        }
      }
    },
    [
      invalidatePostsQuery,
      invalidateSpaceQuery,
      invalidateNetworkQuery,
      invalidateMemberQuery,
    ],
  )
  return {
    reloadContext,
  }
}
