import { cloneElement, ReactNode } from 'react'

import { clsx } from 'clsx'

import { iconStyles } from '../Icon/index.js'
import { getValidChildren } from '../utils/index.js'
import { avatarPlaceholderStyles } from './Avatar.styles.js'
import { AvatarSize } from './types.js'

export interface AvatarGroupOptions {
  children: ReactNode
  size?: AvatarSize
  moreCount?: number
}

export const AvatarGroup = ({
  size,
  moreCount,
  children,
}: AvatarGroupOptions) => {
  const validChildren = getValidChildren(children)
  /**
   * Reversing the children is a great way to avoid using zIndex
   * to overlap the avatars
   */
  const reversedChildren = validChildren.reverse()

  const clones = reversedChildren.map(child => {
    const childProps = {
      className: 'ring-2 ring-surface ring-offset-surface',
      size,
    }

    return cloneElement(child, childProps)
  })
  if (moreCount) {
    clones.push(<MoreIndicator moreCount={moreCount} size={size} />)
  }

  return <div className={clsx('flex items-center')}>{clones}</div>
}

const MoreIndicator = ({ moreCount, size }) => {
  return (
    <span
      className={clsx(
        'inline-flex items-center justify-center rounded-avatar ring-2 ring-surface ring-offset-surface',
        'bg-surface-subdued text-content-subdued',
        iconStyles({ size }),
      )}
    >
      <span className={avatarPlaceholderStyles({ size })}>+{moreCount}</span>
    </span>
  )
}
