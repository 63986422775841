import { createContext, useContext } from 'react'

export interface ModalContextProps {
  id: string
  open: boolean
  showCloseBtn?: boolean
  showZenModeBtn?: boolean
  onClose?: () => void
  zenMode: boolean
  toggleZenMode?: () => void
  scrollable: boolean
}

export const ModalContext = createContext<ModalContextProps>({
  id: '',
  open: false,
  showCloseBtn: true,
  showZenModeBtn: false,
  zenMode: false,
  onClose: undefined,
  toggleZenMode: undefined,
  scrollable: false,
})

export const useModalContext = () => useContext(ModalContext)
