/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-explicit-any */
import EventEmitter from 'eventemitter3'

import type { InteractionEmitterType } from './types.js'

const emitter = new EventEmitter()

export const interactionEmitter: InteractionEmitterType = {
  on: (event, fn) => emitter.on(event, fn),
  emit: (event, payload) => emitter.emit(event, payload),
  removeListener: (event, fn) => emitter.removeListener(event, fn),
}

Object.freeze(interactionEmitter)
