import { useHotkeysSettings } from '@tribeplatform/react-components/common/hooks'
import { useI18n } from '@tribeplatform/react-components/i18n'
import { SpaceActionItems } from '@tribeplatform/react-components/Space'
import { useSpace } from '@tribeplatform/react-sdk/hooks'
import { IconButton } from '@tribeplatform/react-ui-kit/Button'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

interface Props {
  className?: string
  spaceId: string
}

export const OptionsButton = ({ className, spaceId }: Props) => {
  const { $t } = useI18n()
  const { data: space } = useSpace({
    variables: {
      id: spaceId,
    },
    useQueryOptions: {
      enabled: !!spaceId,
    },
  })
  const { disabled: hotkeysDisabled } = useHotkeysSettings()

  if (!space) {
    return null
  }

  return (
    <Dropdown placement="bottom-end" className={className}>
      <Dropdown.ButtonMinimal
        aria-label={$t({
          defaultMessage: 'Options',
          id: 'Generics.Options',
        })}
      >
        <IconButton
          as="span"
          variant="secondaryNeutral"
          icon={<SvgIcon name="dots-horizontal" size="xl" />}
        />
      </Dropdown.ButtonMinimal>
      <SpaceActionItems space={space} hideHotKeys={hotkeysDisabled} />
    </Dropdown>
  )
}
