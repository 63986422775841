import { Suspense, lazy, useState } from 'react'

import type { Image } from '@tribeplatform/gql-client/types'
import { MemberAvatarPlaceholder } from '@tribeplatform/react-components/common/components/member'
import { useFirstRenderContext } from '@tribeplatform/react-components/common/ssr'
import {
  useAuthToken,
  useModerationCount,
} from '@tribeplatform/react-sdk/hooks'
import { Avatar } from '@tribeplatform/react-ui-kit/Avatar'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'

import { DropDownItemRenderer } from '../Components/DropDownItemRenderer.js'
import { Indicator } from '../Components/Indicator.js'
import { useMainMenuItems } from '../hooks/useMainMenuItems.js'

const UserImportModal = lazy(() =>
  import('@tribeplatform/react-components/UserImport').then(m => ({
    default: m.UserImportModal,
  })),
)

interface MainMenuButtonProps {
  label: string
}
export const MainMenuButton = ({ label }: MainMenuButtonProps) => {
  const {
    data: { member },
  } = useAuthToken()
  const { isFirstRender } = useFirstRenderContext()
  const [openMemberInviteModal, setOpenMemberInviteModal] = useState(false)
  const items = useMainMenuItems({ setOpenMemberInviteModal })
  const { total: totalModerationItems } = useModerationCount()
  const avatarUrl = (member?.profilePicture as Image)?.urls?.thumb
  const avatarName = member?.name

  if (isFirstRender) {
    return (
      <div className="h-full flex justify-center items-center shrink-0">
        <Avatar
          src={avatarUrl}
          name={avatarName}
          fallback={MemberAvatarPlaceholder}
        />
      </div>
    )
  }

  return (
    <div className="h-full flex justify-center items-center shrink-0">
      <Dropdown placement="bottom-end" className="block">
        <Dropdown.ButtonMinimal
          className="!rounded-avatar navbar-profile-menu"
          aria-label={label}
        >
          <span>
            <span className="sr-only">{label}</span>
            <Avatar
              src={avatarUrl}
              name={avatarName}
              fallback={MemberAvatarPlaceholder}
              icon={totalModerationItems ? <Indicator /> : null}
              iconSize="md"
            />
          </span>
        </Dropdown.ButtonMinimal>
        <Dropdown.Items className="navbar-profile-menu__dropdown">
          {items.map(item => (
            <DropDownItemRenderer key={item.id} item={item} />
          ))}
        </Dropdown.Items>
      </Dropdown>
      {openMemberInviteModal && (
        <Suspense fallback={<></>}>
          <UserImportModal
            open={openMemberInviteModal}
            onClose={() => {
              setOpenMemberInviteModal(false)
            }}
          />
        </Suspense>
      )}
    </div>
  )
}
