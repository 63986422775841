import { gql } from 'graphql-tag'

import { OAuthCodeFields, OAuthTokenFields } from './oauth.fields.js'

export function oAuthCodeGQLQuery() {
  return gql`
    query oAuthCode($input: OAuthCodeInput!) {
      oAuthCode(input: $input) {
        ${OAuthCodeFields()}
      }
    }
  `
}

export function oAuthTokenGQLQuery() {
  return gql`
    query oAuthTokens($input: OAuthTokenInput!) {
      oAuthTokens(input: $input) {
        ${OAuthTokenFields()}
      }
    }
  `
}
