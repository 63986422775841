import { createContext, useContext, useMemo } from 'react'

import { RadioGroupVariant } from './types.js'

export interface RadioGroupsContext {
  variant: RadioGroupVariant
}

const RadioGroupsContext = createContext<RadioGroupsContext>({
  variant: 'default',
})

export const RadioGroupsProvider = ({ variant, children }) => {
  const contextValue = useMemo(() => ({ variant }), [variant])

  return (
    <RadioGroupsContext.Provider value={contextValue}>
      {children}
    </RadioGroupsContext.Provider>
  )
}

export const useRadioGroups = () => {
  const context = useContext(RadioGroupsContext)
  if (context === undefined) {
    throw new Error('useRadioGroups must be used within a RadioGroupsProvider')
  }
  return context
}
