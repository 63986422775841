import { SystemSpaceSlug } from '@bettermode/common/space'
import { Collection } from '@tribeplatform/gql-client/types'
import { useRouter, useRouterPath } from '@tribeplatform/react-sdk'
import { useSpaceUtil } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { NavVertical } from '@tribeplatform/react-ui-kit/Sidebar'

import { useSlate } from '../../hooks/slate.hook.js'
import { useMobileMenu } from '../Navbar/providers/MobileMenuProvider.js'

export const CollectionMenuSimple = ({
  collections,
  ...rest
}: {
  collections: Collection[]
}) => {
  const { Link } = useRouter()
  const { close } = useMobileMenu()
  const { generatePath, matchPath } = useSpaceUtil()
  const pathname = useRouterPath()
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'

  return (
    <NavVertical {...rest}>
      <div className="space-y-2">
        {collections.map(collection => {
          const hasSpaces = collection?.spaces?.totalCount
          const isActive = matchPath(SystemSpaceSlug.COLLECTION, pathname, {
            id: collection.id,
          })

          if (!hasSpaces) {
            return null
          }

          return (
            <NavVertical.Group key={collection.id}>
              <NavVertical.Item
                // className="group"
                active={!!isActive}
                as={Link}
                href={generatePath(SystemSpaceSlug.COLLECTION, {
                  id: collection.id,
                })}
                leadingIcon={<SvgIcon name="grid" />}
                onClick={e => {
                  if (isPreviewMode) {
                    e.preventDefault()
                    return
                  }

                  close()
                }}
              >
                <span className="truncate flex-grow font-semibold text-sm text-content-on-background-subdued">
                  {collection.name}
                </span>
              </NavVertical.Item>
            </NavVertical.Group>
          )
        })}
      </div>
    </NavVertical>
  )
}
