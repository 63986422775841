import { T } from '@tribeplatform/react-components/i18n'

import type { TableCounterProps } from './types.js'

export const SpacesCounter = ({
  selectedCount,
  totalCount,
}: TableCounterProps) => {
  return (
    <div className="flex items-center space-s-1">
      {totalCount === selectedCount ? (
        <span className="font-medium">
          <T
            defaultMessage="{spaces_count, plural, zero {No {SPACES}} one {# {SPACE}} other {# {SPACES}}}"
            id="Generics.Spaces.Plural"
            values={{
              spaces_count: totalCount,
            }}
          />
        </span>
      ) : (
        <span>
          <T
            defaultMessage="<bold>{matched_count, plural, one {# {SPACE}} other {# {SPACES}}} matched</bold> of {total_count, number}"
            id="Admin.SpacesList.TableCounter"
            values={{
              matched_count: selectedCount,
              total_count: totalCount,
              bold: chunks => <span className="font-medium">{chunks}</span>,
            }}
          />
        </span>
      )}
    </div>
  )
}
