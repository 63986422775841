import { ReactNode } from 'react'

import { useParams } from 'react-router'

import { BettermodeFooter } from '@tribeplatform/react-components/common/components'
import {
  LayoutFrame,
  useLayoutFrame,
  type SlateLayout,
} from '@tribeplatform/react-components/LayoutFrame'
import { useRouterPath } from '@tribeplatform/react-sdk'
import {
  SlateRenderer,
  type SlateRendererProps,
} from '@tribeplatform/react-slate-kit/components'

interface Props {
  layout?: SlateLayout
  forceLayout?: SlateLayout
  navigations?: {
    Header: JSX.Element
    Sidebar1: JSX.Element
  }
  stickyHeader?: boolean
  stickySidebar?: boolean
  slateContext?: SlateRendererProps['context']
  showFloatingFooter?: boolean
  children: ReactNode
}
export const PageLayout = ({
  layout,
  forceLayout,
  navigations,
  stickyHeader,
  stickySidebar,
  slateContext,
  showFloatingFooter = true,
  children,
}: Props) => {
  const pathname = useRouterPath()
  const urlParams = useParams()
  const { header, sidebar1, showHeader, showSidebar } = useLayoutFrame({
    layout,
    forceLayout,
  })
  const context: SlateRendererProps['context'] = {
    path: pathname,
    urlParams,
    ...slateContext,
  }

  if (navigations) {
    const { Header, Sidebar1 } = navigations

    return (
      <LayoutFrame
        header={showHeader ? Header : null}
        sidebar={showSidebar ? Sidebar1 : null}
        content={<>{children}</>}
        floatingFooter={showFloatingFooter ? <BettermodeFooter /> : null}
        stickyHeader={stickyHeader}
        stickySidebar={stickySidebar}
      />
    )
  }

  return (
    <LayoutFrame
      header={showHeader && <SlateRenderer slate={header} context={context} />}
      sidebar={
        showSidebar && <SlateRenderer slate={sidebar1} context={context} />
      }
      content={<>{children}</>}
      floatingFooter={showFloatingFooter ? <BettermodeFooter /> : null}
      stickyHeader={stickyHeader}
      stickySidebar={stickySidebar}
    />
  )
}
