import { indicatorDotStyles } from './IndicatorBadge.styles.js'
import { IndicatorDotStyleProps } from './types.js'

export type IndicatorDotProps = IndicatorDotStyleProps & {
  title?: string
  className?: string
}

/**
 * IndicatorDot is a visual indicator hinting at the presence of something.
 */
export const IndicatorDot = ({
  className,
  variant = 'danger',
  ...rest
}: IndicatorDotProps) => {
  return (
    <span className={indicatorDotStyles({ variant, className })} {...rest} />
  )
}
