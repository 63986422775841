import { mediaGQLFields } from '../media/media.fields.js'

export interface CustomBadgeFields {
  image?: 'basic' | 'all'
}

export interface CustomMemberBadgeFields {
  badge?: BadgeFields
}

export type BadgeFields = 'basic' | 'all' | 'default' | CustomBadgeFields
export type MemberBadgeFields =
  | 'basic'
  | 'all'
  | 'default'
  | CustomMemberBadgeFields

const BASIC_BADGE_FIELDS: CustomBadgeFields = {}
const ALL_BADGE_FIELDS: CustomBadgeFields = {
  image: 'all',
}
const DEFAULT_BADGE_FIELDS: CustomBadgeFields = {
  image: 'all',
}

const BASIC_MEMBER_BADGE_FIELDS: CustomMemberBadgeFields = {}
const ALL_MEMBER_BADGE_FIELDS: CustomMemberBadgeFields = {
  badge: 'all',
}
const DEFAULT_MEMBER_BADGE_FIELDS: CustomMemberBadgeFields = {
  badge: 'default',
}

export function badgeGQLFields(fields: BadgeFields): string {
  if (fields === 'basic') {
    fields = BASIC_BADGE_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_BADGE_FIELDS
  }
  if (fields === 'default') {
    fields = DEFAULT_BADGE_FIELDS
  }

  return `
    active
    backgroundColor
    daysUntilExpired
    id
    imageId
    longDescription
    name
    shortDescription
    textColor
    text
    type
    settings {
      key
      value
    }
    ${
      fields.image
        ? `
        image {
          ${mediaGQLFields(fields.image)}
        }
    `
        : ''
    }
  `
}

export function memberBadgeGQLFields(fields: MemberBadgeFields): string {
  if (fields === 'basic') {
    fields = BASIC_MEMBER_BADGE_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_MEMBER_BADGE_FIELDS
  }
  if (fields === 'default') {
    fields = DEFAULT_MEMBER_BADGE_FIELDS
  }

  return `
    backgroundColor
    badgeId
    imageId
    longDescription
    text
    shortDescription
    textColor
    type
    ${
      fields.badge
        ? `
    badge {
      ${badgeGQLFields(fields.badge)}
    }
    `
        : ''
    }
  `
}
