import { Link } from 'react-router-dom'

import { T } from '@tribeplatform/react-components/i18n'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { EmptyState } from '@tribeplatform/react-ui-kit/EmptyState'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

export const NotFound = ({
  title = '',
  description = '',
  icon = false,
  ...rest
}) => {
  return (
    <Card {...rest}>
      <Card.Content>
        <div className="py-12">
          <EmptyState
            icon={icon || <SvgIcon name="post" />}
            title={
              title || (
                <T
                  defaultMessage="Page not found!"
                  id="Generics.PageNotFound"
                />
              )
            }
            description={
              description || (
                <T
                  defaultMessage="We were not able to find the page you’re looking for."
                  id="Generics.PageNotFound.Explained"
                />
              )
            }
          >
            <Button variant="secondaryNeutral" as={Link} to="/">
              <T defaultMessage="Home" id="Generics.Home" />
            </Button>
          </EmptyState>
        </div>
      </Card.Content>
    </Card>
  )
}
