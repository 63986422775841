import {
  AppInteractionType,
  PermissionContext,
} from '@tribeplatform/gql-client/types'
import type { DynamicBlock } from '@tribeplatform/gql-client/types'
import { ContainerSize } from '@tribeplatform/react-ui-kit/Layout'
// [FOR_FUTURE_DEVELOPERS] when updating this file please update interactionsTypes accordingly
export enum EmitterEvent {
  Event = 'event',
  /**
   * An event for when the library is fully ready to be used for UI components, etc
   */
  READY = 'ready',
  /**
   * An event for when the library is loaded the event emitters and listeners
   */
  ON_LOAD = 'onload',
}

export type OpenToastInteractionProps = {
  title: string
  description?: string
  link: {
    href: string
    text: string
    enableCopy?: boolean
  }
  status: 'ERROR' | 'SUCCESS' | 'WARNING' | 'INFO' | 'NEUTRAL'
}

export type OpenModalInteractionProps = {
  size?: ContainerSize
  title?: string
  description?: string
}

export type ReloadInteractionProps = {
  permissionContext?: PermissionContext
  entityId?: string

  // Reload app blocks with these dynamic block keys in the provided context and entityId
  // Or the entire front page if no context and entityId are provided
  // For example, if the context is Network, and dynamicBlockKeys is ['settings'],
  // It means that we should reload the settings block in the network and not the member
  dynamicBlockKeys?: string[]
}

export type RedirectInteractionProps = {
  url: string
  external?: boolean
}

interface BaseInteractionEventPayload {
  originalInteractionId: string
  appId: string

  interactionId: string
  permissionContext: PermissionContext
  entityId?: string

  slate?: DynamicBlock['slate']
  dynamicBlockKey?: string
  shortcutKey?: string
  blockId?: string
}

export interface RedirectInteractionEventPayload
  extends BaseInteractionEventPayload {
  type: AppInteractionType.Redirect
  props: RedirectInteractionProps
}

export interface ReloadInteractionEventPayload
  extends BaseInteractionEventPayload {
  type: AppInteractionType.Reload
  props: ReloadInteractionProps
}

export interface OpenModalInteractionEventPayload
  extends BaseInteractionEventPayload {
  type: AppInteractionType.OpenModal
  props: OpenModalInteractionProps
}

export interface OpenToastInteractionEventPayload
  extends BaseInteractionEventPayload {
  type: AppInteractionType.OpenToast
  props: OpenToastInteractionProps
}

export interface CloseInteractionEventPayload
  extends BaseInteractionEventPayload {
  type: AppInteractionType.Close
}

export interface ShowInteractionEventPayload
  extends BaseInteractionEventPayload {
  type: AppInteractionType.Show
}

export type DataInteractionProps = {
  items?: Array<{ text: string; value: string }>
}
export interface DataInteractionEventPayload
  extends BaseInteractionEventPayload {
  type: AppInteractionType.Data
  props: DataInteractionProps
}

export enum ExtraInteractionsEvents {
  InteractionError = 'InteractionError',
  InteractionCalled = 'InteractionCalled',
}
export interface ErrorInteractionEventPayload {
  error?: string
  context?: Record<string, unknown>
}
export interface InteractionCalledEventPayload {
  text?: string
}
export type InteractionEventPayload =
  | RedirectInteractionEventPayload
  | ReloadInteractionEventPayload
  | OpenModalInteractionEventPayload
  | OpenToastInteractionEventPayload
  | CloseInteractionEventPayload
  | ShowInteractionEventPayload
  | DataInteractionEventPayload

export interface InteractionEmitterType {
  emit(
    event: AppInteractionType.Redirect,
    payload: RedirectInteractionEventPayload,
  ): void
  emit(
    event: AppInteractionType.Reload,
    payload: ReloadInteractionEventPayload,
  ): void
  emit(
    event: AppInteractionType.OpenModal,
    payload: OpenModalInteractionEventPayload,
  ): void
  emit(
    event: AppInteractionType.OpenToast,
    payload: OpenToastInteractionEventPayload,
  ): void
  emit(
    event: AppInteractionType.Close,
    payload: CloseInteractionEventPayload,
  ): void
  emit(
    event: AppInteractionType.Show,
    payload: ShowInteractionEventPayload,
  ): void
  emit(
    event: AppInteractionType.Data,
    payload: DataInteractionEventPayload,
  ): void
  emit(
    event: ExtraInteractionsEvents.InteractionCalled,
    payload: InteractionCalledEventPayload,
  ): void
  emit(
    event: ExtraInteractionsEvents.InteractionError,
    payload: ErrorInteractionEventPayload,
  ): void

  on(
    event: AppInteractionType.Redirect,
    fn: (props: RedirectInteractionEventPayload) => void,
  ): void
  on(
    event: AppInteractionType.Reload,
    fn: (props: ReloadInteractionEventPayload) => void,
  ): void
  on(
    event: AppInteractionType.OpenModal,
    fn: (props: OpenModalInteractionEventPayload) => void,
  ): void
  on(
    event: AppInteractionType.OpenToast,
    fn: (props: OpenToastInteractionEventPayload) => void,
  ): void
  on(
    event: AppInteractionType.Close,
    fn: (props: CloseInteractionEventPayload) => void,
  ): void
  on(
    event: AppInteractionType.Show,
    fn: (props: ShowInteractionEventPayload) => void,
  ): void
  on(
    event: AppInteractionType.Data,
    fn: (props: DataInteractionEventPayload) => void,
  ): void
  on(
    event: ExtraInteractionsEvents.InteractionError,
    fn: (props: ErrorInteractionEventPayload) => void,
  ): void
  on(
    event: ExtraInteractionsEvents.InteractionCalled,
    fn: (props: InteractionCalledEventPayload) => void,
  ): void

  removeListener(
    event: AppInteractionType | ExtraInteractionsEvents,
    interactionHandler: (
      props:
        | InteractionEventPayload
        | ErrorInteractionEventPayload
        | InteractionCalledEventPayload,
    ) => void,
  ): void
}

export type LoadInteractionsProps = {
  appId: string
  preview?: boolean

  interactionId: string
  originalInteractionId?: string
  permissionContext: PermissionContext
  entityId?: string

  shortcutKey?: string
  dynamicBlockKey?: string
  callbackId?: string
  inputs?: Record<string, unknown>
  props?: Record<string, unknown>
}

type UseInteractionsProps = {
  dynamicBlockKey?: string
}

type LoadInteractions = (options: LoadInteractionsProps) => Promise<void>

export type UseInteractions = (options?: UseInteractionsProps) => {
  // This function will throw an error if the request fails
  // Make sure to handle the error when you are using it
  // We are doing this so that Form can catch the error and show it to the user
  loadInteractions: LoadInteractions
}
