import { radioStyles } from './RadioGroup.styles.js'

export type RadioProps = {
  checked?: boolean
  disabled?: boolean
}

export const Radio = ({ checked = false, disabled, ...rest }: RadioProps) => {
  return (
    <span
      className={radioStyles({
        checked,
        disabled,
      })}
      aria-hidden="true"
      {...rest}
    />
  )
}
