import { Fragment, useRef } from 'react'

import { Post } from '@tribeplatform/gql-client/types'

import { useQuery } from '../common/hooks/useQuery.js'
import { ReplyBar } from '../Post/ReplyBar.js'
import { REPLIES_OF_HIGHLIGHTED_REPLY_LIMIT } from './constants.js'
import { GenericReply } from './GenericReply.js'
import { useGenericReply } from './hooks/useGenericReply.js'
import { useScrollToHighlightedReply } from './hooks/useScrollToHighlightedReply.js'
import { Reply } from './Reply.js'

interface Props {
  reply: Post
  canReply: boolean
  readonly?: boolean
  context?: 'post'
  post?: Post
}

export const HighlightedReplyParent = ({
  reply,
  canReply,
  readonly = false,
  context,
  post,
}: Props) => {
  const { highlight: highlightedReplyId } = useQuery<{ highlight: string }>()

  const isParentReply = reply.id === highlightedReplyId
  const containerRef = useRef<HTMLDivElement>(null)
  const highlightedChildRef = useRef<HTMLDivElement>(null)

  useScrollToHighlightedReply({
    containerRef,
    elementRef: isParentReply ? containerRef : highlightedChildRef,
    highlightedId: highlightedReplyId,
  })

  const {
    isFetching,
    replies,
    unloadedReplies,
    showReplyBar,
    editing,
    replyBarRef,
    isHidden,
    hasReplyPermission,
    fetchNextPage,
    onReply,
    stopEditing,
    startEditing,
  } = useGenericReply({
    limit: REPLIES_OF_HIGHLIGHTED_REPLY_LIMIT,
    reply,
    enableInfiniteQuery: true,
  })

  if (isHidden) {
    return null
  }

  return (
    <GenericReply
      ref={containerRef}
      context={context}
      post={post}
      reply={reply}
      canReply={canReply && hasReplyPermission}
      isFetching={isFetching}
      onLoadMore={fetchNextPage}
      readonly={readonly}
      unloadedReplies={unloadedReplies}
      onReply={onReply}
      editing={editing}
      onStopEditing={stopEditing}
      onStartEditing={startEditing}
      isHighlighted
    >
      {replies?.length > 0 || (canReply && showReplyBar) ? (
        <div className="relative flex flex-col space-y-6 mt-6 p-2">
          {replies
            ?.map(reply => (
              <Fragment key={reply.id}>
                {reply.id === highlightedReplyId ? (
                  <Reply
                    ref={highlightedChildRef}
                    reply={reply}
                    canReply={canReply}
                    readonly={readonly}
                    isHighlighted
                  />
                ) : (
                  <Reply
                    reply={reply}
                    canReply={canReply}
                    readonly={readonly}
                  />
                )}
              </Fragment>
            ))
            ?.reverse()}
          {canReply && showReplyBar && (
            <ReplyBar ref={replyBarRef} post={reply} defaultExpand />
          )}
        </div>
      ) : null}
    </GenericReply>
  )
}
