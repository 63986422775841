import { createContext, ReactNode, useContext, useMemo } from 'react'

/**
 * @deprecated 'secondary' is used for the topbar background.
 * It's not really a background type, there is a limited set of possible subcomponents in topbar,
 * for example it cannot have Tabs or Accordions.
 * Instead, Topbar component should define subcomponents which use css variables with 'topbar' colors.
 */
type LegacyBackgroundType = 'secondary'
export type BackgroundType = 'main' | 'surface' | LegacyBackgroundType

export interface BackgroundContext {
  backgroundType: BackgroundType
  backgroundClsx: string[]
  text400Clsx: string[]
  text500Clsx: string[]
  text900Clsx: string[]
}

const BackgroundContext = createContext<BackgroundContext>({
  backgroundType: 'main',
  backgroundClsx: ['bg-background'],
  text400Clsx: ['text-content-on-background-subdued'],
  text500Clsx: ['text-content-on-background-subdued'],
  text900Clsx: ['text-content-on-background'],
})

export const BackgroundProvider = ({
  backgroundType,
  children,
}: {
  backgroundType: BackgroundType
  children: ReactNode
}) => {
  const value = useMemo(() => {
    const backgroundClsx = [
      backgroundType === 'main' && 'bg-background',
      backgroundType === 'surface' && 'bg-surface',
      backgroundType === 'secondary' && 'bg-topbar',
    ]
    const text500Clsx = [
      backgroundType === 'main' && 'text-content-on-background-subdued',
      backgroundType === 'surface' && 'text-content-subdued',
      backgroundType === 'secondary' && 'text-content-on-topbar',
    ]
    const text400Clsx = [
      backgroundType === 'main' && 'text-content-on-background-subdued',
      backgroundType === 'surface' && 'text-content-subdued',
      backgroundType === 'secondary' && 'text-content-on-topbar',
    ]
    const text900Clsx = [
      backgroundType === 'main' && 'text-content-on-background',
      backgroundType === 'surface' && 'text-content',
      backgroundType === 'secondary' && 'text-content-on-topbar',
    ]

    return {
      backgroundType,
      backgroundClsx,
      text400Clsx,
      text500Clsx,
      text900Clsx,
    }
  }, [backgroundType])

  return (
    <BackgroundContext.Provider value={value}>
      {children}
    </BackgroundContext.Provider>
  )
}

export const useBackgroundContext = () => {
  return useContext(BackgroundContext)
}
