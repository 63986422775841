import { cva } from 'class-variance-authority'

export const radioStyles = cva(
  [
    'shrink-0',
    'focus:outline-none focus-visible:ring',
    'rounded-full',
    'border',
    'flex items-center justify-center',
    'transition duration-200',
    "after:content-[''] after:block after:rounded-full",
  ],
  {
    variants: {
      checked: {
        true: '',
        false: '',
      },
      disabled: {
        true: 'cursor-default bg-interactive-disabled border-line-disabled',
        false: 'cursor-pointer',
      },
      size: {
        md: 'h-5 w-5 after:w-2 after:h-2 mt-[1px]',
        sm: 'h-4 w-4 after:w-1.5 after:h-1.5 mt-[3px]',
      },
    },
    compoundVariants: [
      {
        checked: true,
        disabled: false,
        className: [
          'bg-interactive hover:bg-interactive-hovered group-hover:bg-interactive-hovered',
          'border-interactive hover:border-interactive-hovered group-hover:border-interactive-hovered',
          'after:bg-content-on-interactive',
        ],
      },
      {
        checked: false,
        disabled: false,
        className: [
          'bg-surface border-line hover:border-line-hovered group-hover:border-line-hovered',
        ],
      },
      {
        checked: true,
        disabled: true,
        className: ['after:bg-content-disabled'],
      },
    ],
    defaultVariants: {
      size: 'md',
    },
  },
)

export const radioGroupItemsStyles = cva(['relative isolate'], {
  variants: {
    variant: {
      default: 'space-y-3',
      cards: 'space-y-3',
    },
  },
})

export const radioGroupItemStyles = cva(
  [
    'relative group',
    'text-label-sm',
    'text-content cursor-pointer',
    'transition duration-200',
    'focus:outline-none focus-visible:ring',
    'aria-disabled:text-content-disabled aria-disabled:cursor-default',
  ],
  {
    variants: {
      variant: {
        default: ['flex items-start gap-2'],
        cards: [
          'flex items-center gap-3',
          'bg-surface',
          'px-4 py-4 rounded-base',
          'border border-line-subdued hover:border-line-hovered',
          'aria-disabled:hover:border-line-subdued',
          'aria-checked:border-interactive aria-checked:hover:border-interactive-hovered aria-checked:aria-disabled:border-interactive',
          'aria-checked:ring-interactive aria-checked:ring-1 ring-offset-0',
        ],
      },
    },
  },
)
