import { createContext, useContext } from 'react'

export interface MediaQueryContextType {
  isNavigationCollapsed: boolean
}

export const MediaQueryContext = createContext<
  MediaQueryContextType | undefined
>(undefined)

export function useMediaQuery() {
  const context = useContext(MediaQueryContext)
  if (context === undefined) {
    throw new Error(
      'useMediaQueryContext must be used within a MediaQueryProvider',
    )
  }

  return context
}
