import { useMemo, useState } from 'react'

import { debounce } from '@bettermode/common/debounce'
import { Tag } from '@tribeplatform/gql-client/types'
import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { useSpaceTags } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { MultiselectCreatable } from '@tribeplatform/react-ui-kit/Multiselect'

import { SpaceTagsSelectorProps } from './types.js'

const tagTitleIdMap: Record<string, string> = {}

export const SpaceTagsSelector = ({
  spaceId,
  tags,
  setTags,
  createable = true,
  className = '',
  placement,
}: SpaceTagsSelectorProps) => {
  const { $t } = useI18n()
  const [query, setQuery] = useState('')
  const { data, isFetching } = useSpaceTags({
    variables: {
      spaceId,
      limit: 10,
      query,
    },
  })

  const { nodes: spaceTags } = simplifyPaginatedResult<Tag>(data)
  const options = useMemo(() => spaceTags?.map(t => t.title) || [], [spaceTags])
  const value = useMemo(() => tags.map(tag => tag?.title), [tags])
  useMemo(() => {
    tags.forEach(t => {
      tagTitleIdMap[t.title] = t.id
    })
    spaceTags?.forEach(t => {
      tagTitleIdMap[t.title] = t.id
    })
  }, [tags, spaceTags])

  const onChange = newTags => {
    setTags(
      newTags.map(title => ({
        title,
        id: tagTitleIdMap[title],
      })),
    )
  }
  const onCreateItem = inputValue => {
    if (createable) {
      setTags([
        ...tags,
        {
          title: inputValue,
          id: `local-${Math.random().toString(36).substr(2, 5)}`,
        },
      ])
    }
  }
  const onInputChange = debounce((inputValue: string) => {
    setQuery(inputValue)
  }, 500)

  return (
    <MultiselectCreatable
      options={options}
      value={value}
      onChange={onChange}
      className={className}
      searchable
      onInputChange={onInputChange}
      onCreateItem={onCreateItem}
      createItemPosition="first"
      placement={placement}
    >
      <MultiselectCreatable.Button
        placeholder={$t({
          defaultMessage: 'Add {TAGS}...',
          id: 'AddTags.Placeholder',
        })}
        dense
        leadingIcon={
          isFetching ? (
            <SvgIcon
              className="self-center animate-spin"
              size="lg"
              name="spinner"
            />
          ) : (
            <SvgIcon size="lg" name="tag" />
          )
        }
      />
      <MultiselectCreatable.Items style={{ maxHeight: '200px' }}>
        {({ creating, inputValue }) => {
          return (
            <>
              {createable && creating && query?.length > 0 && !isFetching && (
                <MultiselectCreatable.Item value={query} index={0}>
                  <span>
                    <T id="Generics.Create" defaultMessage="Create" />
                  </span>{' '}
                  <span className="font-bold">{query}</span>
                </MultiselectCreatable.Item>
              )}
              {options.length > 0 &&
                options.map((tag, index) => (
                  <MultiselectCreatable.Item
                    key={tag}
                    value={tag}
                    index={index + (creating ? 1 : 0)}
                  >
                    <span>{tag}</span>
                  </MultiselectCreatable.Item>
                ))}
              {isFetching ? (
                <MultiselectCreatable.Item
                  disabled
                  key=":_search"
                  value=":_search"
                  index={creating ? 1 : 0}
                >
                  <span>
                    <T
                      id="Blocks.HighlightedTags.SearchingForTerm"
                      defaultMessage="Searching for {term}..."
                      values={{ term: inputValue }}
                    />
                  </span>
                </MultiselectCreatable.Item>
              ) : (
                options.length === 0 && (
                  <MultiselectCreatable.Item
                    disabled
                    key=":_none"
                    value=":_none"
                    index={creating ? 1 : 0}
                  >
                    <span>
                      <T
                        id="Blocks.HighlightedTags.NoMatchingResultForItem"
                        defaultMessage="No matching result for {term}"
                        values={{ term: inputValue }}
                      />
                    </span>
                  </MultiselectCreatable.Item>
                )
              )}
            </>
          )
        }}
      </MultiselectCreatable.Items>
    </MultiselectCreatable>
  )
}
