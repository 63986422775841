import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react'

import { IconComponentType } from '../Icon/index.js'
import { Input } from '../Input/index.js'
import { InputProvider } from '../Input/input.context.js'
import { FormControlLayout } from './FormControlLayout.js'
import { FormControlProps } from './types.js'
import { extractLayoutProps } from './utils.js'

export type FormControlInputProps = ComponentPropsWithoutRef<'input'> &
  FormControlProps & {
    leadingIcon?: IconComponentType
    trailingIcon?: IconComponentType
    leadingAddon?: string | ReactNode
    trailingAddon?: string | ReactNode
  }

export const FormControlInput = forwardRef<
  HTMLInputElement,
  FormControlInputProps
>((props, ref) => {
  const { layoutProps, inputProps } = extractLayoutProps(props)

  return (
    <FormControlLayout {...layoutProps}>
      <InputProvider props={inputProps}>
        {props => <Input ref={ref} {...props} />}
      </InputProvider>
    </FormControlLayout>
  )
})
