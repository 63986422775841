import {
  createContext,
  ReactNode,
  startTransition,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useLocation } from 'react-router-dom'

type SsrContextType = {
  /**
   * Flag is true on server render and on first-time client render.
   * This is a workaround to support components which produce hydration error
   */
  isFirstRender: boolean

  /**
   * This value stores the path that was matched during the initial render
   */
  firstRenderPath: string
}

const FirstRenderContext = createContext<SsrContextType>({
  isFirstRender: true,
  firstRenderPath: '',
})

export const useFirstRenderContext = () => useContext(FirstRenderContext)

/**
 * Hydration can fail for a variety of reasons, but one of the common causes is when
 * generated markup structure or attributes on server and client do not match.
 *
 * If the rendering logic uses the condition typeof window === undefined, it can result in an error.
 * Another possible cause of errors is when a library doesn't support server-side rendering and generates different markup or attribute mismatches.
 *
 * To work around this issue, you can use the FirstRenderProvider and skip rendering the problematic component during the initial render.
 * This allows the hydration process to complete before replacing the component with its client-side version.
 */
export const FirstRenderProvider = ({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation()
  const firstRenderPath = useRef(pathname)
  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    startTransition(() => {
      setIsFirstRender(false)
    })
  }, [])

  const context = useMemo(
    () => ({ isFirstRender, firstRenderPath: firstRenderPath.current }),
    [isFirstRender],
  )

  return (
    <FirstRenderContext.Provider value={context}>
      {children}
    </FirstRenderContext.Provider>
  )
}
