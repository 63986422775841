import { useMemo } from 'react'

import { clsx } from 'clsx'

import { HtmlContent } from '@tribeplatform/react-components/HtmlContent'
import { useI18n } from '@tribeplatform/react-components/i18n'
import { useSpace } from '@tribeplatform/react-sdk/hooks'

import { useSlate } from '../../../../hooks/slate.hook.js'
import { DescriptionSettings } from '../../Fields/types.js'

interface Props {
  className?: string
  settings: DescriptionSettings
  spaceId: string
}

export const DescriptionField = ({ className, settings, spaceId }: Props) => {
  const { customDescription, useSpaceDescription } = settings
  const { data: space } = useSpace({
    variables: {
      id: spaceId,
    },
    useQueryOptions: {
      enabled: !!spaceId && !!useSpaceDescription,
    },
  })
  const { $t } = useI18n()
  const { mode } = useSlate()
  const isPreview = mode !== 'live'

  const description = useMemo(() => {
    if (useSpaceDescription) {
      return space?.description
    }
    return customDescription
  }, [useSpaceDescription, customDescription, space?.description])

  if (!isPreview && !description) {
    return null
  }

  return (
    <div className={clsx(className, 'text-md opacity-95')}>
      <article className="prose" style={{ color: 'inherit' }}>
        <HtmlContent
          hideUrl
          value={
            description ||
            (isPreview &&
              $t({
                id: 'Blocks.SpaceHeader.NoDescriptionPlaceholder',
                defaultMessage: 'Description goes here',
              }))
          }
        />
      </article>
    </div>
  )
}
