import { cva } from 'class-variance-authority'

export const baseButtonStyles = cva(
  [
    'relative',
    'min-w-0 max-w-full',
    'font-medium text-center',
    'focus:outline-none focus-visible:ring',
    'transition duration-200',
    'border',
    'disabled:cursor-default disabled:pointer-events-none disabled:border-line-disabled',
  ],
  {
    variants: {
      variant: {
        primary: '',
        secondary: '',
        secondaryNeutral: '',
        tertiary: '',
        tertiaryNeutral: '',
        plain: '',
        plainNeutral: '',
      },
      destructive: {
        true: '',
      },
      loading: {
        true: 'opacity-60 cursor-default pointer-events-none',
      },
    },
    compoundVariants: [
      {
        variant: 'primary',
        destructive: false,
        class: [
          'text-content-on-primary bg-action-primary hover:bg-action-primary-hovered active:bg-action-primary-pressed aria-pressed:bg-action-primary-pressed',
          'disabled:bg-action-primary-disabled disabled:text-content-disabled',
          'border-transparent',
          'focus-visible:ring-focused',
        ],
      },
      {
        variant: 'secondaryNeutral',
        destructive: false,
        class: [
          'text-content bg-action-neutral hover:bg-action-neutral-hovered active:bg-action-neutral-pressed aria-pressed:bg-action-neutral-pressed',
          'disabled:bg-action-neutral-disabled disabled:text-content-disabled',
          'border-line',
        ],
      },
      {
        variant: 'secondary',
        destructive: false,
        class: [
          'text-content-primary bg-action-primary-subdued hover:bg-action-primary-subdued-hovered active:bg-action-primary-subdued-pressed aria-pressed:bg-action-primary-subdued-pressed',
          'disabled:bg-action-primary-disabled disabled:text-content-disabled',
          'border-transparent',
          'focus-visible:ring-focused',
        ],
      },
      {
        variant: 'tertiaryNeutral',
        destructive: false,
        class: [
          'text-content hover:bg-action-neutral-hovered active:bg-action-neutral-pressed aria-pressed:bg-action-neutral-pressed',
          'disabled:bg-action-neutral-disabled disabled:text-content-disabled',
          'border-transparent',
        ],
      },
      {
        variant: 'tertiary',
        destructive: false,
        class: [
          'text-content-primary hover:bg-action-primary-subdued-hovered active:bg-action-primary-subdued-pressed aria-pressed:bg-action-primary-subdued-pressed',
          'disabled:bg-action-primary-disabled disabled:text-content-disabled',
          'border-transparent',
          'focus-visible:ring-focused',
        ],
      },
      {
        variant: 'plainNeutral',
        destructive: false,
        class: [
          'text-content bg-surface hover:underline',
          'disabled:text-content-disabled',
          'border-transparent active:border-line-highlight',
          'focus-visible:ring-focused',
        ],
      },
      {
        variant: 'plain',
        destructive: false,
        class: [
          'text-interactive bg-surface hover:underline',
          'disabled:text-content-disabled',
          'border-transparent active:border-line-highlight',
          'focus-visible:ring-focused',
        ],
      },
      {
        variant: 'primary',
        destructive: true,
        class: [
          'text-content-on-destructive bg-action-destructive hover:bg-action-destructive-hovered active:bg-action-destructive-pressed',
          'disabled:bg-action-destructive-disabled disabled:text-content-disabled',
          'border-transparent',
          'focus:ring-on-error ring-action-destructive',
        ],
      },
      {
        variant: 'secondary',
        destructive: true,
        class: [
          'text-content-on-negative bg-action-destructive-subdued hover:bg-action-destructive-subdued-hovered active:bg-action-destructive-subdued-pressed',
          'disabled:bg-action-destructive-disabled disabled:text-content-disabled',
          'border-transparent',
          'focus:ring-on-error ring-action-destructive',
        ],
      },
      {
        variant: 'secondaryNeutral',
        destructive: true,
        class: [
          'text-content-on-negative bg-action-neutral hover:bg-action-destructive-subdued-hovered active:bg-action-destructive-subdued-pressed',
          'disabled:bg-action-destructive-disabled disabled:text-content-disabled',
          'border-line-negative-subdued',
          'focus:ring-on-error ring-action-destructive',
        ],
      },
      {
        variant: 'tertiary',
        destructive: true,
        class: [
          'text-content-on-negative bg-action-destructive-subdued hover:bg-action-destructive-subdued-hovered active:bg-action-destructive-subdued-pressed',
          'disabled:bg-action-destructive-disabled disabled:text-content-disabled',
          'border-transparent',
          'focus:ring-on-error ring-action-destructive',
        ],
      },
      {
        variant: 'tertiaryNeutral',
        destructive: true,
        class: [
          'text-content-on-negative bg-action-neutral hover:bg-action-destructive-subdued-hovered active:bg-action-destructive-subdued-pressed',
          'disabled:bg-action-destructive-disabled disabled:text-content-disabled',
          'border-transparent',
          'focus:ring-on-error ring-action-destructive',
        ],
      },
      {
        variant: 'plainNeutral',
        destructive: true,
        class: [
          'text-interactive-destructive bg-surface hover:underline',
          'disabled:text-content-disabled',
          'border-transparent active:border-line-highlight',
          'focus:ring-on-error ring-action-destructive',
        ],
      },
      {
        variant: 'plain',
        destructive: true,
        class: [
          'text-interactive-destructive bg-surface hover:underline',
          'disabled:text-content-disabled',
          'border-transparent active:border-line-highlight',
          'focus:ring-on-error ring-action-destructive',
        ],
      },
    ],
    defaultVariants: {
      destructive: false,
    },
  },
)

export const buttonSizeStyles = cva(['rounded-button'], {
  variants: {
    size: {
      sm: ['px-3 py-[1px] min-h-[24px] leading-5 text-label-sm'],
      md: ['px-3.5 py-[5px] min-h-[32px] leading-5 text-label-sm'],
      lg: ['px-4 py-[9px] min-h-[40px] leading-5 text-label-sm'],
      xl: ['px-5 py-[11px] min-h-[48px] leading-6 text-label-md'],
    },
    fullWidth: {
      true: 'w-full max-w-full',
    },
    textAlign: {
      center: 'justify-center',
      start: 'justify-start',
      end: 'justify-end',
    },
    truncate: {
      true: 'truncate',
    },
  },
  defaultVariants: {
    textAlign: 'center',
  },
})

export const iconButtonSizeStyles = cva('', {
  variants: {
    size: {
      sm: ['py-1 w-6 h-6 leading-5 text-label-sm'],
      md: ['py-[5px] w-8 h-8 leading-5 text-label-sm'],
      lg: ['py-[9px] w-10 h-10 leading-5 text-label-sm'],
      xl: ['py-[13px] w-12 h-12 leading-6 text-label-md'],
    },
    rounded: {
      true: [
        'rounded-full',
        'shrink-0', // Prevents flex rounded buttons to be squashed and malformed
      ],
      false: 'rounded-button',
    },
  },
  defaultVariants: {
    rounded: false,
  },
})

export const buttonIconStyles = cva(['shrink-0'], {
  variants: {
    size: {
      sm: ['h-3.5 w-3.5'],
      md: ['h-4 w-4'],
      lg: ['h-5 w-5'],
      xl: ['h-5 w-5'],
    },
  },
})
