import type {
  Filter,
  InlineFilter,
  LegacyFilter,
  ListFilter,
  ListFilterByOperator,
  RawFilter,
  RawInlineFilter,
} from '../types.js'
import { sanitizeFilterOperators } from './legacyConverters.js'
import { getDefaultOperator, getOperators } from './operators.js'
import { isValueNotEmpty, parseFilterValue } from './values.js'

export const convertToFilterArray = (
  listFilters: ListFilter[],
  allFilters: RawFilter[],
): Filter[] => {
  const filterArray: Filter[] = listFilters.map<Filter>(listFilter => {
    const foundFilter = allFilters.find(filter => filter.key === listFilter.key)

    if (!foundFilter) {
      return null
    }

    const { type, subType } = foundFilter
    const operator = convertListFilterOperator(
      listFilter.operator,
      type,
      subType,
    )

    const value = parseFilterValue(listFilter.value)

    if (!operator || value === undefined) {
      return null
    }

    const filter: Filter = {
      ...foundFilter,
      operator,
      value,
    }

    return filter
  })

  const almostFilters = filterArray.filter(filter => !!filter)
  const filters = sanitizeFilterOperators(almostFilters)
  return filters
}

export const convertRawFiltersToFilters = (
  rawFilters: RawFilter[],
): Filter[] => {
  return rawFilters.map<Filter>(rawFilter => {
    const { type, subType } = rawFilter

    return {
      ...rawFilter,
      operator: getDefaultOperator(type, subType),
      value: undefined,
    }
  })
}

const convertListFilterOperator = (
  listFilterOperator: ListFilter['operator'],
  type: Filter['type'],
  subType: Filter['subType'],
): ListFilterByOperator => {
  const relatedOperators = getOperators(type, subType)

  const operator = relatedOperators.find(
    relatedOperated => relatedOperated === listFilterOperator,
  )

  return operator
}

export const convertToLegacyFilterArray = (
  filters: Filter[],
): LegacyFilter[] => {
  const listFilterArray = filters.map(({ key, operator, value }) => ({
    key,
    keyString: key,
    operator,
    value: JSON.stringify(value),
  }))

  return listFilterArray
}

export const convertInlineToFilterArray = (
  rawInlineFilters: RawInlineFilter[],
  allFilters: RawFilter[],
): Filter[] => {
  if (!rawInlineFilters) {
    return []
  }

  const enabledRawInlineFilters = rawInlineFilters.filter(
    ({ enabled }) => !!enabled,
  )
  const filterArray: Filter[] = enabledRawInlineFilters.map<Filter>(
    rawInlineFilter => {
      const foundFilter = allFilters.find(
        filter => filter.key === rawInlineFilter.key,
      )

      if (!foundFilter) {
        return {} as Filter
      }

      const { type, subType, name, extraProps } = foundFilter
      const { customLabel, allowOperatorSelection, narrowedDownOptions } =
        rawInlineFilter
      const operator = getDefaultOperator(type, subType)

      const filter: Filter = {
        ...foundFilter,
        name: customLabel ?? name,
        operator,
        value: undefined,
        extraProps: {
          ...extraProps,
          allowOperatorSelection,
          narrowedDownOptions,
        },
      }

      return filter
    },
  )

  const filters = filterArray.filter(filter => !!filter)
  return filters
}

export const convertToRawInlineFilter = (
  inlineFilters: InlineFilter[],
): RawInlineFilter[] => {
  const rawInlineFilters = inlineFilters.map<RawInlineFilter>(
    ({
      key,
      enabled,
      customLabel,
      allowOperatorSelection,
      narrowedDownOptions,
    }) => {
      const rawInlineFilter: RawInlineFilter = {
        key,
        enabled,
        customLabel,
        allowOperatorSelection,
        narrowedDownOptions,
      }

      return rawInlineFilter
    },
  )

  return rawInlineFilters
}

export const mergeFilters = (
  currentFilters: Filter[],
  allRawFilters: RawFilter[],
): Filter[] => {
  const allFilters = convertRawFiltersToFilters(allRawFilters)

  return allFilters.map(filter => {
    const foundFilter = currentFilters.find(({ id }) => filter.id === id)

    if (foundFilter) {
      return { ...foundFilter, id: filter.id }
    }

    return filter
  })
}

export const prepareFilters = (filters: Filter[]): LegacyFilter[] => {
  const validFilters = filters.filter(({ value }) => isValueNotEmpty(value))
  const listFilters = convertToLegacyFilterArray(validFilters)

  return listFilters
}
