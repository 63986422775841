import type { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { ImageBlock as Component } from './Block.js'
import { ImageBlockProps } from './types.js'

export const RegisteredImageBlock = (
  _intl: I18nShape,
): RegisteredBlock<ImageBlockProps> => ({
  recommendedName: 'Image',
  Component,
  config: { type: BlockType.BuildingBlock },
})
