import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { SupportedIconNames } from './types.js'

interface Props {
  name: SupportedIconNames
}
export const SolidIcon = ({ name }: Props) => {
  switch (name) {
    case SupportedIconNames.RefreshIcon:
      return <SvgIcon name="refresh" />
    case SupportedIconNames.CheckCircleIcon:
      return <SvgIcon name="status-success" />
    case SupportedIconNames.ExclamationCircleIcon:
      return <SvgIcon name="status-info" />
    case SupportedIconNames.PencilAltIcon:
      return <SvgIcon name="edit" />
    case SupportedIconNames.DeleteBinLineIcon:
      return <SvgIcon name="trash" />
    case SupportedIconNames.ArrowsRightLeftIcon:
      return <SvgIcon name="switch-horizontal" />
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = name
      return exhaustiveCheck
    }
  }
}
