import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { RichTextBlock as Component } from './Block.js'
import { RichTextBlockProps } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.RichTextBlockSettings })),
)

export const RegisteredRichTextBlock = (
  intl: I18nShape,
): RegisteredBlock<RichTextBlockProps> => {
  const { $t } = intl

  return {
    recommendedName: 'RichText',
    Component,
    config: {
      type: BlockType.Block,
      usable: true,

      displayName: $t({
        id: 'Blocks.RichText.DisplayName',
        defaultMessage: 'Rich text',
      }),
      Settings,
      Icon: <SvgIcon name="post" />,
      defaultProps: {
        textColor: 'dark',
        content: '',
      },
    },
  }
}
