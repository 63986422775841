import { forwardRef, ReactNode } from 'react'

import { Badge, BadgeVariant } from '../Badge/index.js'
import { Icon } from '../Icon/index.js'
import { SvgIcon } from '../Icon/SvgIcon.js'

export type MultiselectInputBadgeProps = {
  children: ReactNode
  onRemove: () => void
  variant?: BadgeVariant
  className?: string
  disabled?: boolean
}

/** Dismissable badge which are used to display currently selected values in Multiselect */
export const MultiselectInputBadge = forwardRef<
  HTMLDivElement,
  MultiselectInputBadgeProps
>(({ children, variant, className, disabled, onRemove, ...rest }, ref) => {
  return (
    <Badge
      variant={variant}
      className={className}
      truncate
      ref={ref}
      {...rest}
      trailingIcon={
        disabled ? undefined : (
          <button
            type="button"
            className="flex items-center justify-center rounded-base focus:outline-none focus-visible:ring ring-offset-0 transition duration-200"
            onClick={e => {
              e.stopPropagation()
              onRemove?.()
            }}
            disabled={disabled}
          >
            <span className="sr-only">Remove option</span>
            <Icon size="sm">
              <SvgIcon name="close" />
            </Icon>
          </button>
        )
      }
    >
      {children}
    </Badge>
  )
})
