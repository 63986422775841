import { clsx } from 'clsx'

import type { BlockIconProps } from './types.js'

/**
 * @deprecated use SvgIcon instead
 */
export const BlockIcon = ({
  color,
  accessibilityLabel,
  className,
  children,
  size,
}: BlockIconProps) => {
  const iconWrapperSize = [
    size === 'xs' && 'h-4 w-4',
    size === 'sm' && 'h-8 w-8',
    size === 'md' && 'h-10 w-10',
    size === 'lg' && 'h-12 w-12',
  ]

  const colorClsx = [
    color === 'default' && 'text-content-subdued',
    color === 'primary' && 'text-action-primary',
    color === 'positive' && 'text-content-on-positive',
    color === 'negative' && 'text-content-on-negative',
    color === 'attention' && 'text-content-on-attention',
    color === 'highlight' && 'text-content-on-highlight',
  ]

  return (
    <span
      className={clsx(
        className,
        iconWrapperSize,
        colorClsx,
        'inline-block m-0 box-border',
      )}
    >
      {accessibilityLabel && (
        <span className="sr-only">{accessibilityLabel}</span>
      )}
      {children}
    </span>
  )
}
