import { Post } from '@tribeplatform/gql-client/types'
import { useMemberPosts } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'

import { EmptyState } from './EmptyState.js'
import { GenericPostList } from './GenericPostList.js'

export const MemberPostList = ({ memberId, hasParent = false, view }) => {
  const {
    data,
    fetchNextPage = false,
    hasNextPage,
    isLoading,
    isFetched,
    isFetchingNextPage,
  } = useMemberPosts({
    variables: { limit: 5, memberId, hasParent },
    useInfiniteQueryOptions: {
      refetchOnMount: 'always',
    },
    fields: {
      tags: 'basic',
      owner: {
        member: { profilePicture: 'basic', badges: 'all' },
      },
      embeds: 'basic',
      mentions: 'basic',
      space: {
        authMemberProps: 'all',
        image: 'basic',
      },
      attachments: 'basic',
      authMemberProps: 'all',
      repliedTos: hasParent ? 'basic' : null,
      reactions: { fields: 'all', variables: { limit: 10 } },
      replies: hasParent
        ? null
        : {
            fields: {
              authMemberProps: 'all',
              embeds: 'basic',
              mentions: 'basic',
              attachments: 'basic',
              owner: {
                member: { profilePicture: 'basic', badges: 'all' },
              },
              reactions: { fields: 'all', variables: { limit: 10 } },
            },
            variables: {
              limit: 2,
              reverse: true,
            },
          },
    },
  })

  const { nodes: posts } = simplifyPaginatedResult<Post>(data)

  if (!isLoading && !posts?.length) {
    return <EmptyState />
  }

  return (
    <GenericPostList
      posts={posts}
      view={view}
      context="member"
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isLoading={isLoading}
      isFetched={isFetched}
      isFetchingNextPage={isFetchingNextPage}
    />
  )
}
