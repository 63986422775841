import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { Member, Post, Space } from '@tribeplatform/gql-client/types'

export type CommandBarContextType = {
  entity?: Post | Space | Member
  entityType?: 'post' | 'space' | 'member' | 'network'
}

export type ActionType =
  | 'entity'
  | 'posts'
  | 'navigations'
  | 'adminSettings'
  | 'search'

export type ActionOptions = {
  context?: CommandBarContextType[]
  excludeActions?: ActionType[]
}

export interface CommandBarContextProps {
  context: CommandBarContextType[]
  open: (options?: ActionOptions) => void
  close: () => void
  isOpen: boolean
  options: ActionOptions
}

export const CommandBarContext = createContext<
  CommandBarContextProps | undefined
>(undefined)
export const CommandBarCtxSetterContext =
  createContext<React.Dispatch<React.SetStateAction<CommandBarContextType[]>>>(
    undefined,
  )

export const CommandBarProvider = ({ children }) => {
  const [context, setContext] = useState<CommandBarContextType[]>([
    {
      entity: null,
      entityType: 'network',
    },
  ])

  const [options, setOptions] = useState<ActionOptions>({})
  const [isOpen, setOpen] = useState(false)

  const value = useMemo(() => {
    return {
      context,
      setContext,
      isOpen,
      open: (options: ActionOptions) => {
        setOptions(options ?? {})
        setOpen(true)
      },
      close: () => {
        setOptions({})
        setOpen(false)
      },
      options,
    }
  }, [context, isOpen, options])

  return (
    <CommandBarContext.Provider value={value}>
      <CommandBarCtxSetterContext.Provider value={setContext}>
        {children}
      </CommandBarCtxSetterContext.Provider>
    </CommandBarContext.Provider>
  )
}

export function useCommandBar() {
  const context = useContext(CommandBarContext)
  if (context === undefined) {
    throw new Error(
      'useCommandBarContext must be used within a CommandBarProvider',
    )
  }

  return context
}

export function useCommandBarSetContext() {
  const setContext = useContext(CommandBarCtxSetterContext)

  const setCommandBarContext = useCallback(
    (newContext: CommandBarContextType) => {
      setContext(prevContext => {
        const excludedPrevContext = prevContext.filter(
          context => context?.entity?.id !== newContext?.entity?.id,
        )

        return [newContext, ...excludedPrevContext]
      })
    },
    [setContext],
  )

  const unsetCommandBarContext = useCallback(
    (newContext: CommandBarContextType) => {
      setContext(prevContext => {
        return prevContext.filter(
          context => context?.entity?.id !== newContext?.entity?.id,
        )
      })
    },
    [setContext],
  )

  return { setCommandBarContext, unsetCommandBarContext }
}
