import {
  LegacyFilter,
  ListFilter,
  ListFilterByOperator,
} from '@tribeplatform/react-components/Filters'

const convertLegacyFilterKey = (key: string, keyString: string) => {
  if (keyString) {
    return keyString
  }

  // 'owner.member' is now 'ownerId'
  if (key === 'owner.member') {
    return 'ownerId'
  }

  // 'tags' is now 'tagIds'
  if (key === 'tags') {
    return 'tagIds'
  }

  return key
}

const convertLegacyFilterOperator = (key: string, operator: string) => {
  // If operator for 'tags' is 'not' then it should be converted to array and use 'nin'
  if (key === 'tags') {
    if (operator === ListFilterByOperator.not) {
      return ListFilterByOperator.nin
    }

    return ListFilterByOperator.contains
  }

  // The legacy operator might have ':relative' at the end of operator value
  // We should remove it
  const operatorValue = operator.split(':')[0]
  if (
    Object.values(ListFilterByOperator).includes(
      operatorValue as ListFilterByOperator,
    )
  ) {
    return operatorValue as ListFilterByOperator
  }

  return null
}

const convertLegacyFilterValue = (
  key: string,
  operator: string,
  value: string,
) => {
  // If operator for 'tags' is 'not' then it should be converted to array and use 'nin'
  if (key === 'tags' && operator === ListFilterByOperator.not) {
    try {
      const parsedValue = JSON.parse(value)
      const arrayValue = [parsedValue]
      const newValue = JSON.stringify(arrayValue)
      return newValue
    } catch (error) {
      return null
    }
  }

  // Relative times were being calculated by minutes where it should have been calculated by seconds
  if (operator.split(':')?.[1] === 'relative') {
    try {
      const parsedValue = JSON.parse(value)
      if (typeof parsedValue !== 'number') {
        return JSON.stringify(0)
      }
      const newValue = JSON.stringify(parsedValue * 60)
      return newValue
    } catch (error) {
      return null
    }
  }

  return value
}

export const convertToPostListFilter = (
  legacyFilters: LegacyFilter[],
): ListFilter[] => {
  const sanitizedFilters = legacyFilters.map(legacyFilter => {
    const { key, keyString, operator, value } = legacyFilter
    const newLegacyFilter: ListFilter = {
      ...legacyFilter,
      key: convertLegacyFilterKey(key, keyString),
      operator: convertLegacyFilterOperator(key, operator),
      value: convertLegacyFilterValue(key, operator, value),
    }
    return newLegacyFilter
  })

  return sanitizedFilters.filter(({ value, operator }) => !!value && !!operator)
}
