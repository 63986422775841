import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { SelectBlock as Component } from './Block.js'
import { SelectBlockProps } from './types.js'

export const RegisteredSelectBlock = (
  _intl: I18nShape,
): RegisteredBlock<SelectBlockProps> => ({
  recommendedName: 'Select',
  Component,
  config: { type: BlockType.BuildingBlock },
})
