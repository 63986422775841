import type { I18nShape } from '@tribeplatform/react-components/i18n'

import { KnownPostTypes } from '../../types/post.types.js'

export const getTranslatedPostTypeName = (
  { $t }: I18nShape,
  postTypeName: string,
) => {
  const knownPostTypeName = postTypeName as KnownPostTypes
  switch (knownPostTypeName) {
    case 'Discussion':
      return $t({
        defaultMessage: 'Discussion',
        id: 'PostType.Name.Discussion',
      })
    case 'Question':
      return $t({
        defaultMessage: 'Question',
        id: 'PostType.Name.Question',
      })
    case 'Idea':
      return $t({
        defaultMessage: 'Idea',
        id: 'PostType.Name.Idea',
      })
    case 'Article':
      return $t({
        defaultMessage: 'Article',
        id: 'PostType.Name.Article',
      })
    case 'Event':
      return $t({
        defaultMessage: 'Event',
        id: 'PostType.Name.Event',
      })
    case 'Product update':
      return $t({
        defaultMessage: 'Product update',
        id: 'PostType.Name.ProductUpdate',
      })
    case 'Changelog':
      return $t({
        defaultMessage: 'Changelog',
        id: 'PostType.Name.Changelog',
      })
    case 'Job':
      return $t({
        defaultMessage: 'Job',
        id: 'PostType.Name.Job',
      })
    default: {
      const unknownPostTypeName: never = knownPostTypeName

      return $t(
        {
          defaultMessage: '<capital>{post_type}</capital>',
          id: 'PostType.Name',
        },
        {
          post_type: unknownPostTypeName,
        },
      )
    }
  }
}

export const getTranslatedCreatePostText = (
  { $t }: I18nShape,
  postTypeName: string,
) => {
  const knownPostTypeName = postTypeName as KnownPostTypes
  switch (knownPostTypeName) {
    case 'Discussion':
      return $t({
        defaultMessage: 'Create a new discussion',
        id: 'Blocks.Composer.CreatePost.Discussion',
      })
    case 'Question':
      return $t({
        defaultMessage: 'Create a new question',
        id: 'Blocks.Composer.CreatePost.Question',
      })
    case 'Idea':
      return $t({
        defaultMessage: 'Create a new idea',
        id: 'Blocks.Composer.CreatePost.Idea',
      })
    case 'Article':
      return $t({
        defaultMessage: 'Create a new article',
        id: 'Blocks.Composer.CreatePost.Article',
      })
    case 'Event':
      return $t({
        defaultMessage: 'Create a new event',
        id: 'Blocks.Composer.CreatePost.Event',
      })
    case 'Product update':
      return $t({
        defaultMessage: 'Create a new product update',
        id: 'Blocks.Composer.CreatePost.ProductUpdate',
      })
    case 'Changelog':
      return $t({
        defaultMessage: 'Create a new changelog',
        id: 'Blocks.Composer.CreatePost.Changelog',
      })
    case 'Job':
      return $t({
        defaultMessage: 'Create a new job',
        id: 'Blocks.Composer.CreatePost.Job',
      })
    default: {
      const unknownPostTypeName: never = knownPostTypeName

      return $t(
        {
          defaultMessage: 'Create a new <lower>{post_type}</lower>',
          id: 'Blocks.Composer.CreatePost',
        },
        {
          post_type: unknownPostTypeName,
        },
      )
    }
  }
}

export const getTranslatedUpdatePostText = (
  { $t }: I18nShape,
  postTypeName: string,
) => {
  const knownPostTypeName = postTypeName as KnownPostTypes
  switch (knownPostTypeName) {
    case 'Discussion':
      return $t({
        defaultMessage: 'Update discussion',
        id: 'Blocks.Composer.UpdatePost.Discussion',
      })
    case 'Question':
      return $t({
        defaultMessage: 'Update question',
        id: 'Blocks.Composer.UpdatePost.Question',
      })
    case 'Idea':
      return $t({
        defaultMessage: 'Update idea',
        id: 'Blocks.Composer.UpdatePost.Idea',
      })
    case 'Article':
      return $t({
        defaultMessage: 'Update article',
        id: 'Blocks.Composer.UpdatePost.Article',
      })
    case 'Event':
      return $t({
        defaultMessage: 'Update event',
        id: 'Blocks.Composer.UpdatePost.Event',
      })
    case 'Product update':
      return $t({
        defaultMessage: 'Update product update',
        id: 'Blocks.Composer.UpdatePost.ProductUpdate',
      })
    case 'Changelog':
      return $t({
        defaultMessage: 'Update changelog',
        id: 'Blocks.Composer.UpdatePost.Changelog',
      })
    case 'Job':
      return $t({
        defaultMessage: 'Update job',
        id: 'Blocks.Composer.UpdatePost.Job',
      })
    default: {
      const unknownPostTypeName: never = knownPostTypeName

      return $t(
        {
          defaultMessage: 'Update <lower>{post_type}</lower>',
          id: 'Blocks.Composer.UpdatePost',
        },
        {
          post_type: unknownPostTypeName,
        },
      )
    }
  }
}
