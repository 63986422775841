import type { I18nShape } from '../types.js'

export const getLanguageDirection = (
  locale: string,
): I18nShape['languageDirection'] => {
  const localeInstance = new Intl.Locale(locale)

  /**
   * The type-casting is required because typescript compiler doesn't
   * currently understand complete type of Locale object
   * This will be fixed once we upgrade to TS 4.9.
   */

  return (
    (
      localeInstance as {
        textInfo?: { direction: I18nShape['languageDirection'] }
      }
    )?.textInfo?.direction ?? 'ltr'
  )
}
