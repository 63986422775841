import { T } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

export const InvalidSettingsState = () => {
  return (
    <div className="text-sm text-content-subdued text-center">
      <div className="flex gap-x-1 items-center justify-center">
        <SvgIcon name="eye" />
        <T
          defaultMessage="This message is only visible to admins"
          id="Posts.InvalidState.PrivacyMessage"
        />
      </div>
      <T
        defaultMessage="The post list has not been configured correctly. Please check the block settings."
        id="Posts.InvalidState"
      />
    </div>
  )
}
