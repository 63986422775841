import { Embed, Member, File } from '@tribeplatform/gql-client/types'

export enum ComposerModuleName {
  BulletList = 'bulletList',
  NumberedList = 'numberedList',
  LargeHeader = 'largeHeader',
  MediumHeader = 'mediumHeader',
  Image = 'image',
  Attachment = 'attachment',
  Emoji = 'emoji',
  Mention = 'mention',
  Embed = 'embed',
  Code = 'code',
}

export enum AppsEnum {
  Giphy = 'giphy',
}

export type HtmlContentContext = 'full' | 'compact'

export type HtmlContentProps = {
  value: string
  embeds?: Embed[]
  attachments?: File[]
  mentions?: Partial<Member>[]
  isReply?: boolean
  context?: HtmlContentContext
  trustedDomains?: string[]
  hideUrl?: boolean
}
