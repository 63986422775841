import type { Filter } from '../types.js'

// eslint-disable-next-line react/jsx-no-literals
export const EMPTY_VALUE_PLACEHOLDER = <>...</>

export const parseFilterValue = (value: string): Filter['value'] => {
  if (!value) {
    return undefined
  }

  try {
    return JSON.parse(value)
  } catch (error) {
    return undefined
  }
}

export const isValueNotEmpty = (value: Filter['value']): boolean => {
  if (value === undefined || value === null) {
    return false
  }

  if (typeof value === 'string' && value === '') {
    return false
  }

  if (Array.isArray(value) && value.length === 0) {
    return false
  }

  return true
}
