/**
 * See https://formatjs.io/docs/polyfills
 */

import { shouldPolyfill as shouldPolyfillDisplayNames } from '@formatjs/intl-displaynames/should-polyfill.js'
import { shouldPolyfill as shouldPolyfillCanonical } from '@formatjs/intl-getcanonicallocales/should-polyfill.js'
import { shouldPolyfill as shouldPolyfillList } from '@formatjs/intl-listformat/should-polyfill.js'
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill.js'
import { shouldPolyfill as shouldPolyfillNumber } from '@formatjs/intl-numberformat/should-polyfill.js'
import { shouldPolyfill as shouldPolyfillPlural } from '@formatjs/intl-pluralrules/should-polyfill.js'

const localeModules = import.meta.glob(
  '../../../../node_modules/@formatjs/{intl-pluralrules,intl-listformat,intl-numberformat,intl-displaynames}/locale-data/{de,en,es,fr,hi,ja,ko,pt,tr,zh,nl,pl,ro,it,da,fi,nb,sv}?(-+([a-z]|[A-Z])).js',
)

enum LocalizableFeatures {
  PluralRules = 'intl-plluralrules',
  ListFormat = 'intl-listformat',
  NumberFormat = 'intl-numberformat',
  DisplayNames = 'intl-displaynames',
}

const getLocalizedPolyfillForFeature = async (
  moduleName: LocalizableFeatures,
  locale: string,
): Promise<unknown> => {
  for (const path in localeModules) {
    if (path.endsWith(`${moduleName}/locale-data/${locale}.js`)) {
      return localeModules[path]
    }
  }
  return Promise.resolve()
}

export async function intlPolyfill(locale: string): Promise<void> {
  const normalizedLocale = locale.toLowerCase()
  /**
   * Polyfill getCanonicalLocales
   */
  if (shouldPolyfillCanonical()) {
    await import(
      '../../../../../node_modules/@formatjs/intl-getcanonicallocales/lib/polyfill.js'
    )
  }

  /**
   * Polyfill locale
   */
  if (shouldPolyfillLocale()) {
    await import(
      '../../../../../node_modules/@formatjs/intl-locale/lib/polyfill.js'
    )
  }

  /**
   * Polyfill pluralization rule
   */
  const unsupportedLocalePlural = shouldPolyfillPlural(normalizedLocale)
  if (unsupportedLocalePlural) {
    await import(
      '../../../../../node_modules/@formatjs/intl-pluralrules/lib/polyfill-force.js'
    )
    await getLocalizedPolyfillForFeature(
      LocalizableFeatures.PluralRules,
      unsupportedLocalePlural,
    )
  }

  /**
   * Polyfill list formatting
   */
  const unsupportedLocaleList = shouldPolyfillList(normalizedLocale)
  if (unsupportedLocaleList) {
    await import(
      '../../../../../node_modules/@formatjs/intl-listformat/lib/polyfill-force.js'
    )
    await getLocalizedPolyfillForFeature(
      LocalizableFeatures.ListFormat,
      unsupportedLocaleList,
    )
  }

  /**
   * Polyfill number formatting
   */
  const unsupportedLocaleNumber = shouldPolyfillNumber(normalizedLocale)
  if (unsupportedLocaleNumber) {
    await import(
      '../../../../../node_modules/@formatjs/intl-numberformat/lib/polyfill-force.js'
    )
    await getLocalizedPolyfillForFeature(
      LocalizableFeatures.NumberFormat,
      unsupportedLocaleNumber,
    )
  }

  /**
   * Polyfill displayNames
   */
  const unsupportedLocaleDisplayNames =
    shouldPolyfillDisplayNames(normalizedLocale)
  if (unsupportedLocaleDisplayNames) {
    await import(
      '../../../../../node_modules/@formatjs/intl-displaynames/lib/polyfill-force.js'
    )
    if (typeof unsupportedLocaleDisplayNames === 'string') {
      await getLocalizedPolyfillForFeature(
        LocalizableFeatures.DisplayNames,
        unsupportedLocaleDisplayNames,
      )
    }
  }
}
