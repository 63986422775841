import { clsx } from 'clsx'

import { T } from '@tribeplatform/react-components/i18n'
import { AddBlockPosition } from '@tribeplatform/react-slate-kit/types'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

interface Props {
  position: AddBlockPosition
  outside?: boolean
  onClick: () => void
}

export const AddBlockButton = ({
  position,
  outside = false,
  onClick,
}: Props) => {
  return (
    <div
      className={clsx(
        'absolute bg-[#0099F9B2] border border-[#239FED] border-dashed opacity-0 hover:opacity-100 transition-opacity duration-200 w-full cursor-pointer',
        position === AddBlockPosition.Before && 'rounded-t-md pb-1 pt-2 top-0',
        position === AddBlockPosition.After &&
          'rounded-b-md bottom-0 pb-2 pt-1',
        outside && position === AddBlockPosition.Before && '-translate-y-full',
        outside && position === AddBlockPosition.After && 'translate-y-full',
        outside && 'z-20',
      )}
      onClick={event => {
        event.stopPropagation()
        onClick()
      }}
    >
      <div
        className={clsx(
          'text-white rounded-md bg-[#239FED] absolute left-1/2 -translate-x-1/2 p-1',
          position === AddBlockPosition.Before && 'top-0 -translate-y-1/2',
          position === AddBlockPosition.After && 'bottom-0 translate-y-1/2',
        )}
      >
        {position === AddBlockPosition.Before && <SvgIcon name="arrow-up" />}
        {position === AddBlockPosition.After && <SvgIcon name="arrow-down" />}
      </div>
      <div className="text-white font-light text-[0.8rem] flex justify-center items-center gap-1 transition-none">
        <SvgIcon name="plus" size="sm" />
        {position === AddBlockPosition.Before && (
          <T
            id="Customizer.BlockActions.AddBlockAbove"
            defaultMessage="Add block above"
          />
        )}
        {position === AddBlockPosition.After && (
          <T
            id="Customizer.BlockActions.AddBlockBelow"
            defaultMessage="Add block below"
          />
        )}
      </div>
    </div>
  )
}
