import { createContext, useMemo } from 'react'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { useApp, useAuthToken } from '@tribeplatform/react-sdk/hooks'

import { useFirstRenderContext } from '../../common/ssr/FirstRenderProvider.js'
import { DefaultApps } from '../DefaultApps.js'
import { getCookieSettings } from './utils/cookie-settings.js'

type CookieContextProps = {
  isCookieInstalled: boolean
  shouldShowCookiePopup: boolean
  shouldAnonymizeTracking: boolean
}

export const CookieContext = createContext<CookieContextProps>({
  isCookieInstalled: false,
  shouldShowCookiePopup: false,
  shouldAnonymizeTracking: false,
})

export const CookieContextProvider = ({ children }) => {
  const {
    data: { network },
  } = useAuthToken()
  const [hasFetchAppAccess] = hasScopesPermission(network, ['app'])

  /**
   * @tag Vite migration
   * typeof window !== 'undefined' should not be used because it produces different dom on client and server
   */
  const { isFirstRender } = useFirstRenderContext()

  const { data: cookieConsentApp, isInitialLoading } = useApp({
    variables: {
      slug: DefaultApps.CookieConsentManager,
    },
    useQueryOptions: {
      enabled: !isFirstRender && hasFetchAppAccess,
    },
  })
  const cookieSettings = getCookieSettings()
  const isCookieInstalled = useMemo(
    () => Boolean(!isInitialLoading && cookieConsentApp?.installed),
    [cookieConsentApp, isInitialLoading],
  )
  // const { isEnabled: cookieFeatureEnabled } = useTribeFeature(
  //   Features.CookieConsentManager,
  // )
  const cookieFeatureEnabled = true

  const shouldShowCookiePopup = useMemo(
    () =>
      Boolean(
        cookieFeatureEnabled &&
          isCookieInstalled &&
          cookieSettings &&
          !cookieSettings.hasUserConsent,
      ),
    [cookieFeatureEnabled, cookieSettings, isCookieInstalled],
  )

  const shouldAnonymizeTracking = useMemo(
    () =>
      Boolean(
        cookieFeatureEnabled &&
          isCookieInstalled &&
          !cookieSettings?.ANALYTICS_AND_FUNCTIONAL?.enabled,
      ),
    [cookieFeatureEnabled, cookieSettings, isCookieInstalled],
  )

  const context = useMemo(
    () => ({
      isCookieInstalled,
      shouldShowCookiePopup,
      shouldAnonymizeTracking,
    }),
    [isCookieInstalled, shouldAnonymizeTracking, shouldShowCookiePopup],
  )

  return (
    <CookieContext.Provider value={context}>{children}</CookieContext.Provider>
  )
}
