import { isAuthorizedGQLFields } from './isAuthorized.fields.js'

export type InputPathPermissionsFields = 'basic' | 'all'

export function inputPathPermissionsGQLFields(
  // eslint-disable-next-line unused-imports/no-unused-vars
  fields: InputPathPermissionsFields,
): string {
  return `
    path
    isAuthorized {
      ${isAuthorizedGQLFields('basic')}
    }
  `
}
