import type { ClientError } from '@tribeplatform/gql-client/lib'
import type { Action } from '@tribeplatform/gql-client/types'

import {
  useMutation,
  type UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useTribeClient } from '../../useTribeClient.js'

export const useResendVerificationCode = (options?: {
  useMutationOptions?: UseMutationOptions<Action, ClientError>
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()

  return useMutation<Action, ClientError>(
    () => client.auth.resendVerification(),
    useMutationOptions,
  )
}
