import type { TemplateRequestFields } from '@tribeplatform/gql-client/graphql'
import type {
  QueryTemplatesArgs,
  QueryTemplateRequestArgs,
  Fields,
  QueryTemplateArgs,
} from '@tribeplatform/gql-client/types'

import { KeyConstructor } from './keyConstructor.js'

const TEMPLATE_KEY = 'template'
const TEMPLATES_KEY = 'templates'
const TEMPLATE_REQUEST_KEY = 'templateRequest'

export const getTemplateKey = KeyConstructor<
  QueryTemplateArgs,
  Fields.TemplateFields
>(TEMPLATE_KEY)
export const getTemplatesKey = KeyConstructor<
  QueryTemplatesArgs,
  Fields.TemplateFields
>(TEMPLATES_KEY)
export const getTemplateRequestKey = KeyConstructor<
  QueryTemplateRequestArgs,
  TemplateRequestFields
>(TEMPLATE_REQUEST_KEY)
