import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'
import { BannerPlaceholder } from '@tribeplatform/react-components/common/components'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { Avatar } from '@tribeplatform/react-ui-kit/Avatar'

interface Props {
  field: StaticField
  post: Post
  highlighted: boolean
  mediaUrl: string
  aspectRatio: StaticField['settings']['aspectRatio']
}
export const GalleryBanner = ({
  field,
  post,
  highlighted,
  mediaUrl,
  aspectRatio,
}: Props) => {
  const fieldMediaUrl = mediaUrl

  switch (field.settings?.bannerStyle) {
    case 'banner':
      return (
        <div
          className={clsx(
            'w-full relative',
            aspectRatio || 'aspect-video',
            highlighted && 'border-2 border-focused',
          )}
        >
          {!fieldMediaUrl ? (
            <div className="absolute inset-0">
              <BannerPlaceholder name={post?.relativeUrl} />
            </div>
          ) : (
            <div
              className="w-full h-full bg-cover bg-center absolute top-0 start-0"
              style={{ backgroundImage: `url(${fieldMediaUrl})` }}
            ></div>
          )}
        </div>
      )
    case 'square':
      return (
        <div className="pb-4 relative">
          {!fieldMediaUrl ? (
            <BannerPlaceholder
              className={clsx(
                'h-16 w-16 flex justify-center items-center rounded-base',
                highlighted && 'ring-2 ring-offset-surface ring-focused',
              )}
              name={post?.relativeUrl}
            />
          ) : (
            <Avatar src={fieldMediaUrl} size="4x" rounded="md" />
          )}
        </div>
      )
    default:
      // Code should never reach the default case
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = field.settings?.bannerStyle
      return exhaustiveCheck
  }
}
