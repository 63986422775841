import { FC, forwardRef } from 'react'

import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import { BackgroundProvider } from '../BackgroundContext/index.js'
import { TableHeader, TableHeaderCell } from './TableHeader.js'
import { TableCell, TableRow } from './TableRow.js'

export type TableProps = React.ComponentProps<'table'>

/**
 * Used to display data lists to your users in a clean, tabular format.
 */
export const Table = ({ children, className, ...rest }: TableProps) => {
  return (
    <BackgroundProvider backgroundType="surface">
      <div className="relative overflow-x-auto rounded-base rounded-screen-none">
        <table
          className={clsx('min-w-full divide-y divide-line-subdued', className)}
          {...rest}
        >
          {children}
        </table>
      </div>
    </BackgroundProvider>
  )
}

type TableBodyProps = React.ComponentProps<'tbody'>

const TableBody: FC<TableBodyProps> = forwardRef(
  ({ children, className, ...rest }, ref) => {
    return (
      <tbody
        className={twMerge(
          clsx(
            'divide-y divide-line-subdued',
            'bg-surface text-content-subdued',
            className,
          ),
        )}
        ref={ref}
        {...rest}
      >
        {children}
      </tbody>
    )
  },
)

Table.Header = TableHeader
Table.HeaderCell = TableHeaderCell

Table.Body = TableBody

Table.Row = TableRow
Table.Cell = TableCell
