import { PermissionContext } from '@tribeplatform/gql-client/types'
import { FieldView } from '@tribeplatform/react-components/CustomField'
import {
  useMember,
  useNetwork,
  usePost,
  usePostType,
} from '@tribeplatform/react-sdk/hooks'

import { BC } from '../../types/index.js'
import { FieldViewProps } from './types.js'

export const FieldViewBlock: BC<FieldViewProps> = ({
  context,
  entityId,
  fieldKey,
  entityTypeId,
  preview,
  ...rest
}) => {
  const { data: network } = useNetwork({
    fields: { memberFields: 'all' },
  })
  const { data: member } = useMember({
    variables: { id: entityId },
    fields: { fields: 'all' },
    useQueryOptions: {
      enabled: context === PermissionContext.MEMBER && !!entityId,
    },
  })
  const { data: post } = usePost({
    variables: { id: entityId },
    fields: { postType: { postFields: 'all' } },
    useQueryOptions: {
      enabled: context === PermissionContext.POST && !!entityId,
    },
  })
  const { data: postType } = usePostType({
    variables: { id: entityTypeId },
    fields: { postFields: 'all' },
    useQueryOptions: {
      enabled:
        context === PermissionContext.POST && !entityId && !!entityTypeId,
    },
  })

  const getFieldViewProps = () => {
    switch (context) {
      case PermissionContext.MEMBER:
        return {
          entity: member,
          field: network?.memberFields?.fields?.find(
            field => field.key === fieldKey,
          ),
        }
      case PermissionContext.POST:
        return {
          entity: post,
          field: (post?.postType || postType)?.postFields?.fields?.find(
            field => field.key === fieldKey,
          ),
        }
      default:
        return {}
    }
  }

  const { entity, field } = getFieldViewProps()
  if ((!preview && !entity) || !field) {
    return null
  }

  return <FieldView entity={entity} field={field} preview={preview} {...rest} />
}
