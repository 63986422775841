import { useEffect, useState } from 'react'

import { T } from '@tribeplatform/react-components/i18n'
import {
  getUserSettings,
  setUserSetting,
} from '@tribeplatform/react-components/utils/userSettings'
import { Button, IconButton } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Popover } from '@tribeplatform/react-ui-kit/Popover'
import { SortableList } from '@tribeplatform/react-ui-kit/SortableList'

export const FieldsDropdown = ({ table, footer, storageId }) => {
  const { allColumns: fields, toggleHideColumn, setColumnOrder } = table
  useEffect(() => {
    if (storageId) {
      const userSettings = getUserSettings()
      const allSelectedFields = userSettings?.tableSelectedFields ?? {}

      const visibleFields = fields
        .filter(({ id, isVisible }) => !!id && !!isVisible)
        .map(({ id }) => id)
      setUserSetting('tableSelectedFields', {
        ...allSelectedFields,
        [storageId]: visibleFields,
      })
    }
  }, [fields, storageId])

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }

    const newItems = SortableList.reorder(
      fields,
      result.source.index,
      result.destination.index,
    )
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setColumnOrder(newItems.map(item => item.id))
  }

  const [popoverActive, setPopoverActive] = useState(false)
  const activator = (
    <Button
      variant="secondaryNeutral"
      size="lg"
      leadingIcon={<SvgIcon name="menu" />}
      trailingIcon={<SvgIcon name="chevron-down" />}
      onClick={() => setPopoverActive(!popoverActive)}
    >
      <T defaultMessage="Fields" id="Generics.Fields" />
    </Button>
  )

  return (
    <Popover
      placement="bottom-end"
      activator={activator}
      open={popoverActive}
      onClose={() => setPopoverActive(false)}
    >
      <div className="w-64 p-3 max-h-120 overflow-auto">
        <SortableList
          id="field-picker"
          onDragEnd={onDragEnd}
          items={fields.filter(field => !field.locked)}
          /**
           * Nor this component or its parent are properly typed,
           * Requires a deep-dive to refactor and fix type issues with this one.
           */
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          renderItem={(field: any) => (
            <SortableList.Item id={field.id}>
              <SortableList.DefaultView>
                <p className="flex flex-grow text-content-subdued overflow-hidden">
                  <div className="max-w-full truncate">
                    {field.name || field.Header}
                  </div>
                </p>
                <IconButton
                  variant="tertiaryNeutral"
                  className="hover:bg-skeleton"
                  onClick={() => {
                    toggleHideColumn(field.id)
                  }}
                  size="sm"
                  icon={<SvgIcon name={field.isVisible ? 'eye' : 'eye-off'} />}
                />
              </SortableList.DefaultView>
            </SortableList.Item>
          )}
        />
        {footer}
      </div>
    </Popover>
  )
}
