import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'

import { getColoredLabels } from '../../CMS/utils/utils.js'
import { Form } from '../../Form/index.js'

export const ArrayInput = ({ field, name, onChange, value, extraProps }) => {
  if (field?.items?.validators?.length) {
    const selectOptions = getColoredLabels(field)
    const options = selectOptions.map(({ id }) => id)
    const labels = selectOptions.reduce(
      (acc, { id, name }) => ({ ...acc, [id]: name }),
      {} as Record<string, string>,
    )

    if (selectOptions) {
      if (extraProps?.label) {
        return (
          <Form.Multiselect
            name={name}
            onChange={value => {
              if (typeof onChange === 'function') {
                onChange([value])
              }
            }}
            options={options}
            labels={labels}
            {...extraProps}
          />
        )
      }

      return (
        <Dropdown>
          <Dropdown.Button>{labels[value] ?? value}</Dropdown.Button>
          <Dropdown.Items>
            {options.map(value => (
              <Dropdown.Item
                key={value}
                onClick={() => {
                  if (typeof onChange === 'function') {
                    onChange(value)
                  }
                }}
              >
                {labels[value] ?? value}
              </Dropdown.Item>
            ))}
          </Dropdown.Items>
        </Dropdown>
      )
    }
  }
  return null
}
