import { lazy } from 'react'

import type { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { IframeBlock as Component } from './Block.js'
import { IframeProps } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.IframeBlockSettings })),
)

export const RegisteredIframeBlock = ({
  $t,
}: I18nShape): RegisteredBlock<IframeProps> => ({
  recommendedName: 'Iframe',
  Component,
  config: {
    type: BlockType.Block,
    usable: true,
    displayName: $t({
      defaultMessage: 'iFrame',
      id: 'Blocks.IFrame.DisplayName',
    }),
    Settings,
    Icon: <SvgIcon name="device-desktop" />,
    defaultProps: { hidden: false },
  },
})
