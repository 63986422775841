import { useMemo } from 'react'

import {
  ClientError,
  isForbidden,
  isNotFound,
} from '@tribeplatform/gql-client/lib'
import { useStaticIntl } from '@tribeplatform/react-components/i18n'
import { PostCardLoading } from '@tribeplatform/react-components/LoadingStates'
import { usePost } from '@tribeplatform/react-sdk/hooks'
import { useBlock, useSlate } from '@tribeplatform/react-slate-kit/hooks'
import type { BC } from '@tribeplatform/react-slate-kit/types'

import { PostCard } from './components/PostCard.js'
import { PostNotFound } from './components/PostNotFound.js'
import { staticFieldsFactory } from './config.js'
import type { SinglePostBlockProps } from './types.js'

export const SinglePostBlock: BC<SinglePostBlockProps> = ({
  postId,
  fields,
  postTypeId,
  postTypeSlug,
  ...rest
}) => {
  const {
    mode,
    context: { postId: slateContextPostId, postTypeId: postTypeIdFromParams },
  } = useSlate()
  const staticFields = useStaticIntl(staticFieldsFactory)
  const {
    extraContext: { activeFieldId },
  } = useBlock<SinglePostBlockProps, { activeFieldId?: string }>()
  const postIdToFetch = postId || slateContextPostId
  const preview = mode !== 'live'
  const {
    data: post,
    error: _error,
    isInitialLoading,
  } = usePost({
    variables: { id: postIdToFetch },
    fields: 'default',
    useQueryOptions: {
      refetchOnMount: 'always',
      retry: false,
      enabled: !!postIdToFetch,
    },
  })
  const postFields = useMemo(() => {
    if (!fields?.length) {
      return staticFields.filter(
        field =>
          field.enabled &&
          !(field.styles && field.styles.indexOf('card') === -1),
      )
    }

    return fields
  }, [fields, staticFields])

  if (!postTypeId && !postTypeSlug && !postIdToFetch && !preview) {
    return null
  }

  const error = _error as ClientError

  if (!post && !isInitialLoading && error) {
    if (isNotFound(error) || isForbidden(error)) {
      return <PostNotFound />
    }
  }

  if ((isInitialLoading || !post) && !preview) {
    return <PostCardLoading />
  }

  return (
    <PostCard
      fetchedPost={post}
      postId={postIdToFetch}
      fields={postFields}
      staticFields={staticFields}
      postTypeId={postTypeId ?? post?.postTypeId ?? postTypeIdFromParams}
      postTypeSlug={postTypeSlug}
      activeFieldId={activeFieldId}
      isPostPage={!!slateContextPostId}
      preview={preview}
      {...rest}
    />
  )
}
