/**
 * @deprecated
 * Instead of js variable we should use pure css to display responsive content, otherwise we will have problems with SSR
 */
const Breakpoints = {
  navigationBarCollapsed: '1024px',
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

const noWindowMatches = (matches = false) => ({
  media: '',
  addListener: noop,
  removeListener: noop,
  matches,
  onchange: noop,
  addEventListener: noop,
  removeEventListener: noop,
  dispatchEvent: (_: Event) => true,
})

export function isNavigationBarCollapsed(): MediaQueryList {
  return typeof window === 'undefined'
    ? noWindowMatches()
    : window.matchMedia(`(max-width: ${Breakpoints.navigationBarCollapsed})`)
}
