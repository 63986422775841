import { clsx } from 'clsx'

import {
  PublishDateSettings,
  DescriptionSettings,
  IconSettings,
  MembersCountSettings,
  PostsCountSettings,
  TitleSettings,
  MembersSettings,
  ActionButtonSettings,
  StoredField,
} from '../../Fields/types.js'
import { SpaceHeaderFieldKey } from '../../types.js'
import { getAlignmentClass } from '../../utils/utils.js'
import { ActionButton } from '../ActionButtons/ActionButton.js'
import { ButtonsField } from './ButtonsField.js'
import { CreationDateField } from './CreationDateField.js'
import { DescriptionField } from './DescriptionField.js'
import { IconField } from './IconField.js'
import { MembersCountField } from './MembersCountField.js'
import { MembersField } from './MembersField.js'
import { PostsCountField } from './PostsCountField.js'
import { StatsField } from './StatsField.js'
import { TitleField } from './TitleField.js'

interface Props {
  field: StoredField
  className?: string
  highlighted?: boolean
  spaceId: string
  isSimple?: boolean
}

export const FieldRenderer = ({
  field,
  className,
  highlighted,
  spaceId,
  isSimple = false,
}: Props) => {
  const stretched = field.settings?.stretchX
  const alignment = field.settings?.alignment
  const alignmentClass = getAlignmentClass(alignment)
  const classes = clsx(
    className,
    highlighted && 'outline outline-1 outline-customizer-blue rounded-sm',
    stretched && 'basis-full',
    alignmentClass,
  )
  const fieldKey = field.key

  switch (fieldKey) {
    case SpaceHeaderFieldKey.Buttons:
      return (
        <ButtonsField field={field} className={classes} spaceId={spaceId} />
      )
    case SpaceHeaderFieldKey.Icon:
      return (
        <IconField
          className={classes}
          settings={field.settings as IconSettings}
          spaceId={spaceId}
          isSimple={isSimple}
        />
      )
    case SpaceHeaderFieldKey.Title:
      return (
        <TitleField
          className={classes}
          settings={field.settings as TitleSettings}
          spaceId={spaceId}
        />
      )
    case SpaceHeaderFieldKey.Description:
      return (
        <DescriptionField
          className={classes}
          settings={field.settings as DescriptionSettings}
          spaceId={spaceId}
        />
      )
    case SpaceHeaderFieldKey.Members:
      return (
        <MembersField
          className={classes}
          settings={field.settings as MembersSettings}
          spaceId={spaceId}
          isSimple={isSimple}
        />
      )
    case SpaceHeaderFieldKey.Stats:
      return <StatsField field={field} className={classes} spaceId={spaceId} />
    case SpaceHeaderFieldKey.TotalMembers:
      return (
        <MembersCountField
          className={classes}
          settings={field.settings as MembersCountSettings}
          spaceId={spaceId}
        />
      )
    case SpaceHeaderFieldKey.TotalPosts:
      return (
        <PostsCountField
          className={classes}
          settings={field.settings as PostsCountSettings}
          spaceId={spaceId}
        />
      )
    case SpaceHeaderFieldKey.DateCreated:
      return (
        <CreationDateField
          className={classes}
          settings={field.settings as PublishDateSettings}
          spaceId={spaceId}
        />
      )
    case SpaceHeaderFieldKey.OptionsButton:
    case SpaceHeaderFieldKey.JoinButton:
    case SpaceHeaderFieldKey.AddPostButton:
    case SpaceHeaderFieldKey.ShareButton:
    case SpaceHeaderFieldKey.SearchButton:
    case SpaceHeaderFieldKey.NotificationsButton:
    case SpaceHeaderFieldKey.CustomButton:
      return (
        <ActionButton
          field={field}
          highlighted={highlighted}
          settings={field.settings as ActionButtonSettings}
          spaceId={spaceId}
        />
      )
    default: {
      const _exhaustiveCheck: never = fieldKey
      return null
    }
  }
}
