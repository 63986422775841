import { useStaticIntl } from '../../i18n/hooks/useStaticIntl.js'
import { I18nShape } from '../../i18n/types.js'

export const canQuitEditor =
  ({ $t }: I18nShape) =>
  ({ isTouched }: { isTouched: boolean }) => {
    if (isTouched) {
      // eslint-disable-next-line no-alert
      return window.confirm(
        $t({
          id: 'Alert.UnsavedChanges',
          defaultMessage:
            'You have unsaved changes. Are you sure you want to discard them?',
        }),
      )
    }

    return true
  }

export const useCanQuitEditor = () => {
  return useStaticIntl(canQuitEditor)
}
