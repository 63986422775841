import { Space } from '@tribeplatform/gql-client/types'
import type { StaticField, View } from '@tribeplatform/react-components/Views'

import { EmptyState } from './EmptyState.js'
import { SpacesCard } from './SpacesCard.js'
import { SpacesCarousel } from './SpacesCarousel.js'
import { SpacesGallery } from './SpacesGallery.js'
import { SpacesList } from './SpacesList.js'

interface Props {
  style: View['style']
  spaces: Space[]
  fields: StaticField[]
  gallerySize?: View['gallerySize']
  showDivider?: View['showDivider']
  activeFieldId?: string
  showMoreButton: JSX.Element
  preview: boolean
}
export const GenericSpacesList = ({
  style,
  spaces,
  fields,
  gallerySize,
  showDivider,
  activeFieldId,
  showMoreButton,
  preview,
}: Props) => {
  if (spaces.length === 0) {
    return <EmptyState />
  }

  switch (style) {
    case 'gallery':
      return (
        <SpacesGallery
          spaces={spaces}
          fields={fields}
          gallerySize={gallerySize}
          activeFieldId={activeFieldId}
          showMoreButton={showMoreButton}
          preview={preview}
        />
      )
    case 'list':
      return (
        <SpacesList
          spaces={spaces}
          fields={fields}
          showDivider={showDivider}
          activeFieldId={activeFieldId}
          showMoreButton={showMoreButton}
          preview={preview}
        />
      )
    case 'card':
      return (
        <SpacesCard
          spaces={spaces}
          fields={fields}
          activeFieldId={activeFieldId}
          showMoreButton={showMoreButton}
          preview={preview}
        />
      )
    case 'carousel':
      return (
        <SpacesCarousel
          spaces={spaces}
          fields={fields}
          gallerySize={gallerySize}
          activeFieldId={activeFieldId}
          showMoreButton={showMoreButton}
          preview={preview}
        />
      )
    default:
      return null
  }
}
