import { useSpacesByIds } from '@tribeplatform/react-sdk/hooks'
import { Multiselect } from '@tribeplatform/react-ui-kit/Multiselect'

import { useI18n } from '../i18n/index.js'
import { SpaceImage } from '../Space/SpaceImage.js'

interface Props {
  value: string[]
  options: string[]
  onChange: (spaceIds: string[]) => void
  placeholder?: string
  noWrapper?: boolean
  className?: string
}
export const SpacePickerFromIds = ({
  value,
  options,
  onChange,
  placeholder,
  noWrapper,
  className,
}: Props) => {
  const { $t } = useI18n()
  const { data: spaceOptions, isInitialLoading } = useSpacesByIds({
    variables: { ids: options },
    fields: {
      image: 'basic',
    },
    useQueryOptions: {
      enabled: options.length > 0,
    },
  })

  const getPlaceHolder = () => {
    if (isInitialLoading || !spaceOptions) {
      return $t({
        id: 'Generics.LoadingDotDotDot',
        defaultMessage: 'Loading...',
      })
    }

    return placeholder
  }

  return (
    <Multiselect
      value={value}
      options={options}
      onChange={newValues => onChange(newValues)}
      disabled={isInitialLoading}
      className={className}
    >
      <Multiselect.Button placeholder={getPlaceHolder()}>
        {spaceOptions &&
          spaceOptions
            .filter(({ id }) => value.includes(id))
            .map((space, index) => (
              <Multiselect.SelectedItem
                key={space.id}
                value={space.id}
                index={index}
              >
                <span className="flex items-center">
                  <SpaceImage size="lg" space={space} />
                  <span className="ms-3 block truncate">{space.name}</span>
                </span>
              </Multiselect.SelectedItem>
            ))}
      </Multiselect.Button>
      <Multiselect.Items noWrapper={noWrapper}>
        {spaceOptions &&
          spaceOptions.map((space, index) => (
            <Multiselect.Item key={space.id} value={space.id} index={index}>
              <div className="flex items-center">
                <SpaceImage size="lg" space={space} />
                <span className="ms-3 block truncate">{space.name}</span>
              </div>
            </Multiselect.Item>
          ))}
      </Multiselect.Items>
    </Multiselect>
  )
}
