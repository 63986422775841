import { ReactNode, useMemo } from 'react'

import type { Network } from '@bettermode/common/gql/generated'
import { getDomainInfo } from '@tribeplatform/react-components/common/utils'
import {
  getIconSocialSpriteUrl,
  getIconSpriteUrl,
  getIconPickerSpriteUrl,
} from '@tribeplatform/react-components/Picker'
import {
  ColorSchemeProvider,
  ThemeProvider,
  calculateColorSchemeMode,
  convertNetworkToAppearance,
  type UserColorScheme,
} from '@tribeplatform/react-ui-kit/ThemeProvider'

export type WebThemeProviderProps = {
  children?: ReactNode
  oldColors: Network['themes']['active']['tokens']['colors']
  networkThemeLightColors: Network['activeTheme']['colors']['light']
  networkThemeDarkColors: Network['activeTheme']['colors']['dark']
  networkThemeTypography: Network['activeTheme']['typography']
  colorScheme: UserColorScheme
  domain: string
  domainSubfolder: string
}

export const WebThemeProvider = ({
  children,
  oldColors,
  networkThemeLightColors,
  networkThemeDarkColors,
  networkThemeTypography,
  colorScheme,
  domain,
  domainSubfolder,
}: WebThemeProviderProps) => {
  const { subfolder } = getDomainInfo({
    domain,
    domainSubfolder,
  })

  const appearance = useMemo(() => {
    return convertNetworkToAppearance({
      oldColors,
      networkThemeLightColors,
      networkThemeDarkColors,
      networkThemeTypography,
    })
  }, [
    networkThemeDarkColors,
    networkThemeLightColors,
    networkThemeTypography,
    oldColors,
  ])

  const colorSchemeMode = useMemo(
    () =>
      calculateColorSchemeMode({
        userColorScheme: colorScheme,
        networkColorScheme: appearance.networkColorScheme,
      }),
    [appearance, colorScheme],
  )

  const assets = useMemo(
    () => ({
      iconSpriteUrl: getIconSpriteUrl(appearance.style.icons, {
        subfolder,
      }),
      iconSocialSpriteUrl: getIconSocialSpriteUrl({ subfolder }),
      iconPickerLineSpriteUrl: getIconPickerSpriteUrl('line', {
        subfolder,
      }),
      iconPickerSolidSpriteUrl: getIconPickerSpriteUrl('solid', {
        subfolder,
      }),
      iconPickerDuocolorSpriteUrl: getIconPickerSpriteUrl('duocolor', {
        subfolder,
      }),
    }),
    [appearance.style.icons, subfolder],
  )

  return (
    <ColorSchemeProvider colorSchemeMode={colorSchemeMode}>
      <ThemeProvider
        theme={appearance.theme}
        typography={appearance.typography}
        style={appearance.style}
        networkColorScheme={appearance.networkColorScheme}
        assets={assets}
      >
        {children}
      </ThemeProvider>
    </ColorSchemeProvider>
  )
}
