import { useCallback, useEffect } from 'react'

import { useClipboard } from 'use-clipboard-copy'

import { AppInteractionType } from '@tribeplatform/gql-client/types'
import { T } from '@tribeplatform/react-components/i18n'
import { Link } from '@tribeplatform/react-components/Link'
import { toast, ToastComponent } from '@tribeplatform/react-ui-kit/Toast'
import type { ToastStatus } from '@tribeplatform/react-ui-kit/Toast'

import { interactionEmitter } from './InteractionEmitter.js'
import {
  InteractionEventPayload,
  OpenToastInteractionEventPayload,
  OpenToastInteractionProps,
} from './types.js'

export const ToastInteraction = () => {
  const clipboard = useClipboard()
  const openToast = useCallback(
    ({ link, description, title, status }: OpenToastInteractionProps) => {
      const toastTitle = title ?? ''
      const toastDescription = description ?? ''
      const toastStatus = (status?.toLowerCase() as ToastStatus) || 'info'

      if (!link) {
        return toast({
          title: toastTitle,
          description: toastDescription,
          status: toastStatus,
        })
      }

      toast({
        duration: Infinity,
        status: toastStatus,
        custom: () => (
          <ToastComponent.Content
            title={toastTitle}
            description={toastDescription}
          >
            <div className="pt-2">
              <Link variant="accent" className="font-semibold" href={link.href}>
                {link.text}
              </Link>

              {link?.enableCopy && (
                <Link
                  className="ms-2 font-semibold"
                  variant="accent"
                  onClick={() => clipboard.copy(link.href)}
                >
                  <T
                    defaultMessage="Copy link"
                    id="ToastInteraction.CopyLink"
                  />
                </Link>
              )}
            </div>
          </ToastComponent.Content>
        ),
      })
    },
    [],
  )
  useEffect(() => {
    const openToastListener = (args: InteractionEventPayload) => {
      const toastProps = (args as OpenToastInteractionEventPayload).props
      openToast({
        ...toastProps,
      })
    }

    if (interactionEmitter) {
      interactionEmitter.on(AppInteractionType.OpenToast, openToastListener)
    }

    return () => {
      if (interactionEmitter) {
        interactionEmitter.removeListener(
          AppInteractionType.OpenToast,
          openToastListener,
        )
      }
    }
  }, [openToast])

  return null
}
