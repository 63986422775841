import {
  addPostTypeFieldGQLMutation,
  archivePostTypeFieldGQLMutation,
  archivePostTypeGQLMutation,
  deletePostTypeFieldGQLMutation,
  getPostTypeGQLQuery,
  getPostTypesGQLQuery,
  PostTypeFields,
  reorderPostTypeFieldsGQLMutation,
  unarchivePostTypeFieldGQLMutation,
  unarchivePostTypeGQLMutation,
  updatePostTypeFieldGQLMutation,
} from '../../graphql/index.js'
import {
  MutationAddPostTypeFieldArgs,
  MutationArchivePostTypeArgs,
  MutationArchivePostTypeFieldArgs,
  MutationDeletePostTypeFieldArgs,
  MutationReorderPostTypeFieldsArgs,
  MutationUnarchivePostTypeArgs,
  MutationUnarchivePostTypeFieldArgs,
  MutationUpdatePostTypeFieldArgs,
  PaginatedPostType,
  PostType,
  QueryPostTypeArgs,
  QueryPostTypesArgs,
} from '../../types/index.js'
import { GraphqlClient } from '../graphql.client.js'

export class PostTypeClient {
  private client: GraphqlClient

  constructor(client: GraphqlClient) {
    this.client = client
  }

  async list(
    variables: QueryPostTypesArgs,
    fields: PostTypeFields = 'basic',
    accessToken?: string,
  ): Promise<PaginatedPostType> {
    type QueryResult = { postTypes: PaginatedPostType }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getPostTypesGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.postTypes
  }

  async get(
    variables: QueryPostTypeArgs,
    fields: PostTypeFields = 'basic',
  ): Promise<PostType> {
    type QueryResult = { postType: PostType }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getPostTypeGQLQuery(fields),
      variables,
    })
    return result.postType
  }

  async addField(
    variables: MutationAddPostTypeFieldArgs,
    fields: PostTypeFields = 'basic',
    accessToken?: string,
  ): Promise<PostType> {
    type QueryResult = { updatePostTypeField: PostType }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: addPostTypeFieldGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.updatePostTypeField
  }

  async archiveField(
    variables: MutationArchivePostTypeFieldArgs,
    fields: PostTypeFields = 'basic',
    accessToken?: string,
  ): Promise<PostType> {
    type QueryResult = { archivePostTypeField: PostType }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: archivePostTypeFieldGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.archivePostTypeField
  }

  async deleteField(
    variables: MutationDeletePostTypeFieldArgs,
    fields: PostTypeFields = 'basic',
    accessToken?: string,
  ): Promise<PostType> {
    type QueryResult = { deletePostTypeField: PostType }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: deletePostTypeFieldGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.deletePostTypeField
  }

  async reorderFields(
    variables: MutationReorderPostTypeFieldsArgs,
    fields: PostTypeFields = 'basic',
    accessToken?: string,
  ): Promise<PostType> {
    type QueryResult = { reorderPostTypeFields: PostType }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: reorderPostTypeFieldsGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.reorderPostTypeFields
  }

  async archive(
    variables: MutationArchivePostTypeArgs,
    fields: PostTypeFields = 'basic',
    accessToken?: string,
  ): Promise<PostType> {
    type QueryResult = { archivePostType: PostType }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: archivePostTypeGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.archivePostType
  }

  async unarchive(
    variables: MutationUnarchivePostTypeArgs,
    fields: PostTypeFields = 'basic',
    accessToken?: string,
  ): Promise<PostType> {
    type QueryResult = { unarchivePostType: PostType }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: unarchivePostTypeGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.unarchivePostType
  }

  async unarchiveField(
    variables: MutationUnarchivePostTypeFieldArgs,
    fields: PostTypeFields = 'basic',
    accessToken?: string,
  ): Promise<PostType> {
    type QueryResult = { unarchivePostTypeField: PostType }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: unarchivePostTypeFieldGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.unarchivePostTypeField
  }

  async updateField(
    variables: MutationUpdatePostTypeFieldArgs,
    fields: PostTypeFields = 'basic',
    accessToken?: string,
  ): Promise<PostType> {
    type QueryResult = { updatePostTypeField: PostType }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: updatePostTypeFieldGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.updatePostTypeField
  }
}
