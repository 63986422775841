import { privateMessageGQLQuery, SpaceFields } from '../../graphql/index.js'
import { QueryPrivateMessageArgs, Space } from '../../types/index.js'
import { GraphqlClient } from '../graphql.client.js'

export class MessagingClient {
  private client: GraphqlClient

  constructor(client: GraphqlClient) {
    this.client = client
  }

  async privateMessage(
    variables: QueryPrivateMessageArgs,
    fields: SpaceFields = 'default',
    accessToken?: string,
  ): Promise<Space> {
    type QueryResult = {
      privateMessage: Space
    }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: privateMessageGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.privateMessage
  }
}
