import { lazy } from 'react'

import { Route, Routes } from 'react-router'

import type { Network } from '@bettermode/common/gql/generated'
import { useTimeFrame } from '@tribeplatform/react-components/Analytics/hooks'

const MessagesAnalytics = lazy(() =>
  import('./NetworkAnalytics/MessagesAnalytics.js').then(m => ({
    default: m.MessagesAnalytics,
  })),
)
const OverviewAnalytics = lazy(() =>
  import('./NetworkAnalytics/OverviewAnalytics.js').then(m => ({
    default: m.OverviewAnalytics,
  })),
)
const PeopleAnalytics = lazy(() =>
  import('./NetworkAnalytics/PeopleAnalytics.js').then(m => ({
    default: m.PeopleAnalytics,
  })),
)
const PostsAnalytics = lazy(() =>
  import('./NetworkAnalytics/PostsAnalytics.js').then(m => ({
    default: m.PostsAnalytics,
  })),
)
const ReachAnalytics = lazy(() =>
  import('./NetworkAnalytics/ReachAnalytics.js').then(m => ({
    default: m.ReachAnalytics,
  })),
)
const SpacesAnalytics = lazy(() =>
  import('./NetworkAnalytics/SpacesAnalytics.js').then(m => ({
    default: m.SpacesAnalytics,
  })),
)
const AuditLogs = lazy(() =>
  import('../Admin/AuditLogs/index.js').then(m => ({
    default: m.AuditLogs,
  })),
)

interface Props {
  networkId: Network['id']
  networkCreatedAt: Network['createdAt']
}

export const Analytics = ({ networkId, networkCreatedAt }: Props) => {
  const { timeFrame, TimeFrameSelect } = useTimeFrame({
    createdAt: networkCreatedAt,
  })

  return (
    <Routes>
      <Route
        path="analytics"
        element={
          <OverviewAnalytics
            networkId={networkId}
            timeFrame={timeFrame}
            TimeFrameSelect={TimeFrameSelect}
          />
        }
      />
      <Route
        path="analytics/dashboard"
        element={
          <OverviewAnalytics
            networkId={networkId}
            timeFrame={timeFrame}
            TimeFrameSelect={TimeFrameSelect}
          />
        }
      />
      <Route
        path="analytics/people"
        element={
          <PeopleAnalytics
            networkId={networkId}
            timeFrame={timeFrame}
            TimeFrameSelect={TimeFrameSelect}
          />
        }
      />
      <Route
        path="analytics/posts"
        element={
          <PostsAnalytics
            networkId={networkId}
            timeFrame={timeFrame}
            TimeFrameSelect={TimeFrameSelect}
          />
        }
      />
      <Route
        path="analytics/spaces"
        element={
          <SpacesAnalytics
            networkId={networkId}
            timeFrame={timeFrame}
            TimeFrameSelect={TimeFrameSelect}
          />
        }
      />
      <Route
        path="analytics/reach"
        element={
          <ReachAnalytics
            networkId={networkId}
            timeFrame={timeFrame}
            TimeFrameSelect={TimeFrameSelect}
          />
        }
      />
      <Route
        path="analytics/messages"
        element={
          <MessagesAnalytics
            networkId={networkId}
            timeFrame={timeFrame}
            TimeFrameSelect={TimeFrameSelect}
          />
        }
      />
      <Route path="audit-logs" element={<AuditLogs />} />
    </Routes>
  )
}
