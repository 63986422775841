import { Card } from '@tribeplatform/react-ui-kit/Card'
import { GridList } from '@tribeplatform/react-ui-kit/Layout'

export const CollectionListLoading = ({ count = 16 }) => {
  return (
    <>
      {[...Array(count)].map((_e, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <GridList.Item key={i}>
          <Card className="h-full">
            <div className="animate-pulse flex-1 flex flex-col mb-2 p-6 pt-4 space-y-3 items-center text-center">
              <div className="mb-3 w-24 h-24 rounded-avatar bg-skeleton" />
              <div className="h-4 bg-skeleton rounded-full w-2/3" />
              <div className="h-4 bg-skeleton rounded-full w-3/4" />
            </div>
          </Card>
        </GridList.Item>
      ))}
    </>
  )
}
