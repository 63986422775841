import { setPropertyValue } from '../utils/css-utils.js'

export const SCROLL_LOCKING_ATTRIBUTE = 'data-lock-scrolling'

const SCROLL_LOCKING_HIDDEN_ATTRIBUTE = 'data-lock-scrolling-hidden'

const SCROLL_LOCKING_WRAPPER_ATTRIBUTE = 'data-lock-scrolling-wrapper'

const CSS_VAR_SCROLLBAR_WIDTH = '--c-scrollbar-width'

let scrollPosition = 0

export class ScrollLockManager {
  private scrollLocks = 0

  private locked = false

  registerScrollLock() {
    this.scrollLocks += 1
    this.handleScrollLocking()
  }

  unregisterScrollLock() {
    this.scrollLocks -= 1
    this.handleScrollLocking()
  }

  handleScrollLocking() {
    if (typeof window === 'undefined') {
      return
    }

    const { scrollLocks } = this
    const { body } = document
    const wrapper = body.firstElementChild

    if (scrollLocks === 0) {
      setPropertyValue(document, CSS_VAR_SCROLLBAR_WIDTH, '0')
      body.removeAttribute(SCROLL_LOCKING_ATTRIBUTE)
      body.removeAttribute(SCROLL_LOCKING_HIDDEN_ATTRIBUTE)
      if (wrapper) {
        wrapper.removeAttribute(SCROLL_LOCKING_ATTRIBUTE)
        wrapper.removeAttribute(SCROLL_LOCKING_WRAPPER_ATTRIBUTE)
      }

      window.scroll(0, scrollPosition)
      this.locked = false
    } else if (scrollLocks > 0 && !this.locked) {
      scrollPosition = window.pageYOffset

      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth
      setPropertyValue(document, CSS_VAR_SCROLLBAR_WIDTH, `${scrollbarWidth}px`)

      body.setAttribute(SCROLL_LOCKING_ATTRIBUTE, '')
      body.setAttribute(SCROLL_LOCKING_HIDDEN_ATTRIBUTE, '')

      if (wrapper) {
        wrapper.setAttribute(SCROLL_LOCKING_ATTRIBUTE, '')
        wrapper.setAttribute(SCROLL_LOCKING_WRAPPER_ATTRIBUTE, '')
        wrapper.scrollTop = scrollPosition
      }
      this.locked = true
    }
  }

  resetScrollPosition() {
    scrollPosition = 0
  }
}
