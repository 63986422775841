import { useState } from 'react'

import { useRouter } from '@tribeplatform/react-sdk'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { T } from '../../../i18n/index.js'
import { useFirstRenderContext } from '../../ssr/FirstRenderProvider.js'

export const UnpublishedBanner = () => {
  const { isFirstRender } = useFirstRenderContext()
  const { Link } = useRouter()
  const [dismissed, setDismissed] = useState(() => {
    if (typeof sessionStorage === 'undefined') {
      return false
    }
    const savedDismissed = sessionStorage.getItem('dismissedBanner')
    return savedDismissed === 'true'
  })

  const onDismiss = () => {
    sessionStorage.setItem('dismissedBanner', 'true')
    setDismissed(true)
  }

  if (dismissed || isFirstRender) {
    return null
  }

  return (
    <div className="flex justify-center px-3 py-3.5 bg-banner-primary text-banner-content pointer-events-auto">
      <div className="flex-grow flex flex-row gap-3 justify-between items-center max-w-7xl">
        <span className="font-medium text-sm md:text-md">
          <T
            defaultMessage="Your website is currently unpublished and can only be viewed by administrators."
            id="Generics.YourSiteIsUnpublished"
          />
        </span>
        <div className="flex items-center gap-2 shrink-0">
          <Button
            as={Link}
            variant="primary"
            href="/manage/settings/network/#publish"
            className="text-sm font-semibold border-banner-line text-banner-primary
            bg-white hover:bg-banner-primary-hovered active:bg-banner-primary-hovered"
          >
            <T
              defaultMessage="Publish settings"
              id="Generics.PublishSettings"
            />
          </Button>

          <div className="cursor-pointer text-white p-2 hover:text-banner-primary-hovered">
            <SvgIcon name="close" onClick={onDismiss} className="h-5 w-5" />
          </div>
        </div>
      </div>
    </div>
  )
}
