import { useRouter } from '@tribeplatform/react-sdk'
import { noop } from '@tribeplatform/react-ui-kit/hooks'

import { Target } from '../../HeroBanner/types.js'
import {
  ItemAlignment,
  SubMenuStyle,
  type MenuItem as TMenuItem,
} from '../types.js'
import { MenuItemWithDropDown } from './MenuItemWithDropDown.js'
import { MenuItemWithGird } from './MenuItemWithGird.js'

interface Props extends TMenuItem {
  parentAlignment: ItemAlignment
}
export const MainMenuItem = ({
  parentAlignment,
  id,
  name,
  url,
  target,
  subMenuStyle,
  menuItems,
}: Props) => {
  const { Link } = useRouter()
  const hasSubMenu = menuItems && menuItems.length > 0

  if (!hasSubMenu) {
    return (
      <Link
        variant="neutral"
        href={url}
        target={target === Target.blank ? '_blank' : '_self'}
        className="flex items-center shrink-0 h-full font-medium transition-none"
        tabIndex={0}
      >
        {name}
      </Link>
    )
  }

  switch (subMenuStyle) {
    case SubMenuStyle.grid:
      return <MenuItemWithGird id={id} name={name} menuItems={menuItems} />
    case SubMenuStyle.dropdown:
      return (
        <MenuItemWithDropDown
          id={id}
          name={name}
          menuItems={menuItems}
          parentAlignment={parentAlignment}
          itemSize="lg"
        />
      )
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = subMenuStyle
      noop(exhaustiveCheck)
      return (
        <MenuItemWithDropDown
          id={id}
          name={name}
          menuItems={menuItems}
          parentAlignment={parentAlignment}
          itemSize="lg"
        />
      )
    }
  }
}
