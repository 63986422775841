import type { ReactNode } from 'react'

import {
  CustomFieldType,
  MemberListFilterByOperator,
  PostListFilterByOperator,
  type CustomFieldSchema,
} from '@tribeplatform/gql-client/types'
import {
  CustomFieldSubtype,
  getFieldSetting,
} from '@tribeplatform/react-components/CustomField'
import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'

type TableFilterField = {
  accessor: string
  Header: string | ReactNode
  key?: string
  orderKey?: string
  type?: CustomFieldSchema['type']
  renderer?: string | ((arg) => string | ReactNode) | CustomFieldSchema['type']
  validators?: CustomFieldSchema['validators']
  items?: CustomFieldSchema['items']
  settings?: CustomFieldSchema['settings']
  subtype?: unknown
  exact?: boolean
  name?: string
  labels?: Record<string, string>
  extraProps?: Record<string, unknown>
}

export const FieldOperatorPicker = ({
  field,
  onChange,
  value,
}: {
  field: TableFilterField
  onChange: (string) => void
  value: string
}) => {
  const { $t } = useI18n()
  if (!field) {
    return null
  }

  if (
    field?.exact ||
    (field.type === CustomFieldType.relation &&
      field.subtype !== CustomFieldSubtype.TAG)
  ) {
    return (
      <div className="flex-shrink-0">
        <T defaultMessage="is" id="Admin.FieldOps.Exact" />
      </div>
    )
  }

  let operators = []
  if (field.type === CustomFieldType.date) {
    operators = [
      {
        title: $t({
          defaultMessage: 'Absolute',
          id: 'Admin.FieldOps.Date.Absolute',
        }),
      },
      {
        text: $t({
          defaultMessage: 'is after',
          id: 'Admin.FieldOps.Date.GT',
        }),
        value: MemberListFilterByOperator.gt,
      },
      {
        text: $t({
          defaultMessage: 'is before',
          id: 'Admin.FieldOps.Date.LT',
        }),
        value: MemberListFilterByOperator.lt,
      },
      {
        text: $t({
          defaultMessage: 'is on or after',
          id: 'Admin.FieldOps.Date.GTE',
        }),
        value: MemberListFilterByOperator.gte,
      },
      {
        text: $t({
          defaultMessage: 'is on or before',
          id: 'Admin.FieldOps.Date.LTE',
        }),
        value: MemberListFilterByOperator.lte,
      },
      {
        text: $t({
          defaultMessage: 'on',
          id: 'Admin.FieldOps.Date.Eq',
        }),
        value: MemberListFilterByOperator.equals,
      },
      {
        title: $t({
          defaultMessage: 'Relative',
          id: 'Admin.FieldOps.Date.Relative',
        }),
      },
      {
        text: $t({
          defaultMessage: 'is relatively after',
          id: 'Admin.FieldOps.Date.GTE.Relative',
        }),
        value: 'gte:relative',
      },
      {
        text: $t({
          defaultMessage: 'is relatively before',
          id: 'Admin.FieldOps.Date.LTE.Relative',
        }),
        value: 'lte:relative',
      },
    ]
  } else if (field.type === CustomFieldType.number) {
    operators = [
      { text: '=', value: MemberListFilterByOperator.equals },
      { text: '≠', value: MemberListFilterByOperator.not },
      { text: '>', value: MemberListFilterByOperator.gt },
      { text: '<', value: MemberListFilterByOperator.lt },
      { text: '≥', value: MemberListFilterByOperator.gte },
      { text: '≤', value: MemberListFilterByOperator.lte },
    ]
  } else if (field.type === CustomFieldType.array) {
    operators = [
      {
        // <T defaultMessage="is" id="Admin.FieldOps.Exact" />
        text: $t({
          defaultMessage: 'is',
          id: 'Admin.FieldOps.Array.Contains',
        }),
        value: MemberListFilterByOperator.contains,
      },
    ]
  } else if (field.type === CustomFieldType.text) {
    const subtype = getFieldSetting(field, 'subtype') || field?.subtype
    if (subtype === CustomFieldSubtype.SELECT) {
      operators = [
        {
          text: $t({
            defaultMessage: 'is',
            id: 'Admin.FieldOps.Text.Select.Eq',
          }),
          value: MemberListFilterByOperator.equals,
        },
        {
          text: $t({
            defaultMessage: 'is not',
            id: 'Admin.FieldOps.Text.Select.NEq',
          }),
          value: MemberListFilterByOperator.not,
        },
        {
          text: $t({
            defaultMessage: 'is one of',
            id: 'Admin.FieldOps.Text.Select.In',
          }),
          value: PostListFilterByOperator.in,
        },
      ]
    } else {
      operators = [
        {
          text: $t({
            defaultMessage: 'is',
            id: 'Admin.FieldOps.Text.Eq',
          }),
          value: MemberListFilterByOperator.equals,
        },
        {
          text: $t({
            defaultMessage: 'contains',
            id: 'Admin.FieldOps.Text.Contains',
          }),
          value: MemberListFilterByOperator.contains,
        },
        {
          text: $t({
            defaultMessage: 'is not',
            id: 'Admin.FieldOps.Text.NEq',
          }),
          value: MemberListFilterByOperator.not,
        },
      ]
    }
  } else {
    operators = [
      {
        text: $t({
          defaultMessage: 'is',
          id: 'Admin.FieldOps.Exact',
        }),
        value: MemberListFilterByOperator.equals,
      },
      {
        text: $t({
          defaultMessage: 'is not',
          id: 'Admin.FieldOps.NEq',
        }),
        value: MemberListFilterByOperator.not,
      },
    ]
  }

  return (
    <div className="flex-shrink-0">
      <Dropdown>
        <Dropdown.Button>
          {operators?.find(operator => operator.value === value)?.text}
        </Dropdown.Button>
        <Dropdown.Items>
          {operators.map(operator => {
            const { value: operatorValue, text, title } = operator
            if (title) {
              return (
                <div className="text-sm text-content-subdued font-medium px-4 pt-2">
                  {title}
                </div>
              )
            }

            return (
              <Dropdown.Item
                onClick={() => {
                  onChange({
                    operator: operatorValue,
                  })
                }}
                key={operatorValue}
              >
                {text}
              </Dropdown.Item>
            )
          })}
        </Dropdown.Items>
      </Dropdown>
    </div>
  )
}
