import { NetworkVisibility } from '@tribeplatform/gql-client/types'
import { T } from '@tribeplatform/react-components/i18n'
import { useRouter } from '@tribeplatform/react-sdk'
import { useAuthMember, useAuthToken } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { NavVertical } from '@tribeplatform/react-ui-kit/Sidebar'

import { useSlate } from '../../../../hooks/slate.hook.js'

interface Props {
  close: () => void
}
export const SearchAction = ({ close }: Props) => {
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'
  const { Link } = useRouter()
  const { isGuest } = useAuthMember()
  const {
    data: { networkPublicInfo },
  } = useAuthToken()
  const isNetworkPrivate =
    networkPublicInfo?.visibility === NetworkVisibility.private

  if (isNetworkPrivate && isGuest) {
    return null
  }

  return (
    <NavVertical.Item
      as={Link}
      href="/search"
      leadingAddon={<SvgIcon name="search" size="lg" className="me-3 -ms-1" />}
      onClick={e => {
        if (isPreviewMode) {
          e.preventDefault()
        }

        close()
      }}
    >
      <T defaultMessage="Search" id="Generics.Search" />
    </NavVertical.Item>
  )
}
