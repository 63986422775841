import { cva } from 'class-variance-authority'

export const inputStyles = cva(
  [
    'block rounded-input',
    'appearance-none',
    'border',
    'placeholder:text-content-disabled placeholder:text-label-md',
    'transition duration-200',
  ],
  {
    variants: {
      size: {
        default: 'px-3 py-[11px] min-h-[48px] text-label-md',
        compact: 'px-2 py-[3px] min-h-[40px] text-label-md',
      },
      invalid: {
        true: [
          'border-line-negative bg-surface-negative-subdued',
          'focus-within:ring-action-destructive-subdued-pressed',
        ],
        false:
          'focus-within:ring-action-primary focus-within:border-action-primary',
      },
      // not using 'disabled:' selector because input styles are used in other components and can be applied to div
      disabled: {
        true: [
          'bg-surface-disabled text-content-disabled',
          'border-line-disabled',
          'pointer-events-none',
        ],
        false: ['bg-surface text-content', 'border-line'],
      },
      readOnly: {
        true: [
          'read-only:bg-surface-disabled read-only:border-line-disabled read-only:text-content-subdued',
        ],
      },
      fullWidth: {
        true: 'w-full',
        false: '',
      },
      showFocusRing: {
        true: 'focus:outline-none focus-within:ring-1 ring-offset-0',
        false: 'focus:outline-none',
      },
    },
    defaultVariants: {
      fullWidth: true,
      size: 'default',
      disabled: false,
      invalid: false,
      showFocusRing: true,
    },
  },
)

export const inputIconStyles = cva(['shrink-0'], {
  variants: {
    size: {
      default: 'h-[1.25em] w-[1.25em] text-label-md',
      compact: 'h-[1.25em] w-[1.25em] text-label-sm',
    },
    disabled: {
      true: 'text-content-disabled',
      false: 'text-content-subdued',
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

export const inputAriaExpandedStyles = cva(
  ['aria-expanded:ring-1 aria-expanded:ring-offset-0'],
  {
    variants: {
      invalid: {
        false:
          'aria-expanded:ring-action-primary aria-expanded:border-action-primary',
        true: 'aria-expanded:ring-action-destructive-subdued-pressed',
      },
    },
    defaultVariants: {
      invalid: false,
    },
  },
)
