import { PostCardListLoading } from '@tribeplatform/react-components/LoadingStates'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Container } from '@tribeplatform/react-ui-kit/Layout'

/**
 * @deprecated Use the GenericSpaceLoading instead
 */
export const SpaceWithBannerLoading = () => (
  <Container size="full">
    <div className="bg-surface shadow-card sm:rounded-card">
      <div className="h-16 lg:h-24 bg-skeleton rounded-t-md"></div>
      <div className="flex space-s-4 px-4 py-6 sm:px-6 lg:px-8 items-center">
        <div className="flex justify-between animate-pulse flex-1">
          <div className="h-8 bg-skeleton rounded-full w-1/4" />
          <div className="h-8 bg-skeleton rounded-full w-1/4" />
        </div>
      </div>
    </div>
    <PostCardListLoading />
  </Container>
)

export const GenericSpaceLoading = () => (
  // This loading is more generic, the previous one assumed that there is a banner with post list which is wrong
  // We should have a generic one (this one), and then a loading state for each block
  <div className="flex h-96 w-full">
    <SvgIcon className="animate-spin h-7 w-7 m-auto" name="spinner" />
  </div>
)
