export const iconNames = [
  'alert-circle',
  'alert-triangle',
  'align-center',
  'align-left',
  'align-right',
  'archive',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'at-symbol',
  'ban',
  'bars-uneven-last',
  'bars-uneven-middle',
  'bars',
  'bell-plus',
  'bell-ringing-01',
  'bell',
  'bold',
  'book-open',
  'bookmark',
  'calendar',
  'chart',
  'check-circle-broken-filled',
  'check-circle-broken',
  'check-circle',
  'check-verified',
  'check',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'clapperboard',
  'clipboard-check',
  'clipboard',
  'clock',
  'close',
  'code-box',
  'code',
  'cog',
  'colors',
  'columns-01',
  'columns-1-1-active-1',
  'columns-1-1-active-2',
  'columns-1-2-active-1',
  'columns-1-2-active-2',
  'columns-1-active',
  'columns-2-1-active-1',
  'columns-2-1-active-2',
  'columns-3-active-1',
  'columns-3-active-2',
  'columns-3-active-3',
  'columns-4-active-1',
  'columns-4-active-2',
  'columns-4-active-3',
  'columns-4-active-4',
  'copy',
  'corners-in',
  'corners-out',
  'credit-card',
  'cube',
  'cursor-click',
  'device-mobile',
  'device-tablet',
  'direction-horizontal',
  'direction-vertical',
  'document-ban',
  'document-left',
  'document-right',
  'document-text-folded-corner',
  'document-text',
  'dots-drag-and-drop',
  'dots-horizontal',
  'dots-vertical',
  'double-quotes-l',
  'download',
  'drawing-compass',
  'duplicate',
  'emotion-add',
  'emotion',
  'eye-off',
  'eye',
  'file-plus-01',
  'fire',
  'flag',
  'folder-plus',
  'folder',
  'font-size',
  'gift',
  'globe',
  'grid',
  'h-1',
  'h-2',
  'h-3',
  'hand',
  'hash',
  'heart',
  'help-circle',
  'home',
  'image-add',
  'image-align-center',
  'image-align-left',
  'image-align-right',
  'image-float-left',
  'image-float-right',
  'image',
  'inbox',
  'info-circle',
  'italic',
  'key',
  'layout-header-sidebar',
  'layout-left',
  'layout-quadrants',
  'layout-top',
  'life-buoy',
  'light-bulb',
  'link-external',
  'link-unlink',
  'link',
  'list-ordered',
  'list-unordered',
  'location-marker',
  'lock-unlocked',
  'lock',
  'login',
  'logout',
  'mail-open',
  'mail',
  'message-text-circle',
  'message-text-square',
  'minus',
  'monitor',
  'moon',
  'package-plus',
  'paperclip',
  'pencil',
  'phone',
  'pin',
  'play-circle',
  'play',
  'plus-circle',
  'plus',
  'puzzle',
  'refresh',
  'reverse-left',
  'reverse-right',
  'rows',
  'rss',
  'safari-line',
  'search',
  'section',
  'selector',
  'send',
  'share',
  'shield-check',
  'shield-exclamation',
  'shield',
  'shut-down',
  'sliders',
  'spinner',
  'star',
  'strikethrough',
  'sun',
  'switch-horizontal',
  'tag',
  'terminal',
  'thumbs-down',
  'thumbs-up',
  'trash',
  'underline',
  'upload',
  'user-add',
  'user-ban',
  'user-left',
  'user-remove',
  'user-square',
  'user',
  'users',
  'x-circle',
  'zap',
  'zoom-in',
  'zoom-out',
] as const
export type IconName = (typeof iconNames)[number]
