/* eslint-disable formatjs/enforce-id */
/* eslint-disable formatjs/enforce-default-message  */
import { FormattedMessage } from 'react-intl'

import { useI18n } from '../providers/I18nProvider.js'

type FormattedMessageType = Pick<
  React.ComponentProps<typeof FormattedMessage>,
  'defaultMessage' | 'id' | 'values' | 'description'
>
export const T = ({ values, ...props }: FormattedMessageType) => {
  const { $t } = useI18n()
  return (
    <>
      {$t(
        {
          ...props,
        },
        values,
      )}
    </>
  )
}
