import { I18nShape } from '@tribeplatform/react-components/i18n'

import { QuickLinksItem } from './types.js'

export const defaultQuickLinksItemsFactory = (
  intl: I18nShape,
): QuickLinksItem[] => {
  const { $t } = intl

  return [
    {
      title: $t(
        {
          id: 'Blocks.QuickLinks.DefaultQuickLinkTitleNum',
          defaultMessage: 'Quick link #{num}',
        },
        { num: 1 },
      ),
      description: '',
      action: {
        text: $t({
          id: 'Blocks.QuickLinks.DefaultActionText',
          defaultMessage: 'Quick link',
        }),
        href: '#',
      },
      image: {
        id: ':heart_eyes:',
        text: 'heart_eyes',
      },
    },
    {
      title: $t(
        {
          id: 'Blocks.QuickLinks.DefaultQuickLinkTitleNum',
          defaultMessage: 'Quick link #{num}',
        },
        { num: 2 },
      ),

      description: '',
      action: {
        text: $t({
          id: 'Blocks.QuickLinks.DefaultActionText',
          defaultMessage: 'Quick link',
        }),
        href: '#',
      },
      image: {
        id: ':yum:',
        text: 'yum',
      },
    },
  ]
}
