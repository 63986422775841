import type { I18nShape } from '@tribeplatform/react-components/i18n'
import { StaticFieldTypes } from '@tribeplatform/react-components/Views'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { getAssetPath } from '../../../../configs/static.js'
import type {
  QuickLinksBlockProps,
  QuickLinksSource,
  QuickLinksView,
} from './types.js'

export const genericSourceFactory: (intl: I18nShape) => QuickLinksSource = ({
  $t,
}) => ({
  id: 'genericSource',
  name: $t({
    defaultMessage: 'Title',
    id: 'Generics.Title',
  }),
  title: $t({
    defaultMessage: 'Title',
    id: 'Generics.Title',
  }),
  description: $t({
    defaultMessage: 'Description',
    id: 'Generics.Description',
  }),
  linkText: 'Quick link',
  linkUrl: '#',
})

export const genericViewFactory: (intl: I18nShape) => QuickLinksView = intl => {
  const { $t } = intl
  return {
    id: 'genericView',
    name: $t({
      defaultMessage: 'Default',
      id: 'Generics.Default',
    }),
    style: 'list',
    gallerySize: 'medium',
    showDivider: true,
    sources: [genericSourceFactory(intl)],
    fields: [],
  }
}

export const staticFieldsFactory: (intl: I18nShape) => StaticField[] = ({
  $t,
}) => [
  {
    id: 'quickLinkListImage',
    label: $t({
      defaultMessage: 'Image',
      id: 'Generics.Image',
    }),
    enabled: true,
    locked: false,
    styles: ['list'],
    type: StaticFieldTypes.avatar,
    settings: {
      avatarSize: 'lg',
      bannerSource: 'member',
    },
    isStatic: true,
    icon: <SvgIcon name="image" />,
  },
  {
    id: 'quickLinkGalleryBanner',
    label: $t({
      defaultMessage: 'Image',
      id: 'Generics.Image',
    }),
    enabled: true,
    locked: false,
    styles: ['gallery', 'carousel'],
    type: StaticFieldTypes.banner,
    settings: {
      bannerStyle: 'banner',
      bannerSource: 'post',
      aspectRatio: 'aspect-video',
    },
    isStatic: true,
    icon: <SvgIcon name="image" />,
    default: getAssetPath('images/banners/banner-placeholder.jpg'),
  },
  {
    id: 'quickLinkCardBanner',
    label: $t({
      defaultMessage: 'Image',
      id: 'Generics.Image',
    }),
    enabled: true,
    locked: true,
    styles: ['card'],
    type: StaticFieldTypes.banner,
    settings: {
      bannerStyle: 'banner',
      bannerSource: 'post',
      aspectRatio: 'aspect-[4/1]',
    },
    isStatic: true,
    icon: <SvgIcon name="image" />,
    default: getAssetPath('images/banners/banner-placeholder.jpg'),
  },
  {
    id: 'quickLinkListContent',
    label: $t({
      defaultMessage: 'Content',
      id: 'Generics.Content',
    }),
    enabled: true,
    locked: false,
    styles: ['list'],
    settings: {
      stretchX: true,
    },
    isStatic: true,
    icon: <SvgIcon name="posts" />,
    fields: [
      {
        id: 'quickLinkListTitle',
        label: $t({
          defaultMessage: 'Title',
          id: 'Generics.Title',
        }),
        enabled: true,
        locked: false,
        type: StaticFieldTypes.title,
        settings: {
          titleSize: 'md',
        },
        isStatic: true,
        icon: <SvgIcon name="field" />,
      },
      {
        id: 'quickLinkListDescription',
        label: $t({
          defaultMessage: 'Description',
          id: 'QuickLinksBlocks.FieldLabels.Description',
        }),
        enabled: true,
        locked: false,
        settings: {},
        isStatic: true,
        icon: <SvgIcon name="field-text" />,
      },
    ],
  },
  {
    id: 'quickLinkGalleryTitle',
    label: $t({
      defaultMessage: 'Title',
      id: 'Generics.Title',
    }),
    enabled: true,
    locked: false,
    styles: ['gallery', 'carousel', 'card'],
    type: StaticFieldTypes.title,
    settings: {
      fullWidth: true,
      titleSize: 'md',
    },
    isStatic: true,
    icon: <SvgIcon name="field" />,
  },
  {
    id: 'quickLinkGalleryDescription',
    label: $t({
      defaultMessage: 'Description',
      id: 'QuickLinksBlocks.FieldLabels.Description',
    }),
    enabled: true,
    locked: false,
    styles: ['gallery', 'carousel'],
    settings: {
      fullWidth: true,
    },
    isStatic: true,
    icon: <SvgIcon name="field-text" />,
  },
  {
    id: 'quickLinkCardDescription',
    label: $t({
      defaultMessage: 'Description',
      id: 'QuickLinksBlocks.FieldLabels.Description',
    }),
    enabled: true,
    locked: false,
    styles: ['card'],
    settings: {
      fullWidth: true,
    },
    isStatic: true,
    icon: <SvgIcon name="field-text" />,
  },
  {
    id: 'quickLinkButton',
    label: $t({
      defaultMessage: 'Link',
      id: 'Generics.Link.Noun',
    }),
    enabled: true,
    locked: false,
    type: StaticFieldTypes.button,
    settings: { linkStyle: 'button', openInNewWindow: true },
    isStatic: true,
    icon: <SvgIcon name="link" />,
  },
]

export const QuickLinksConfig: (intl: I18nShape) => {
  defaultProps: QuickLinksBlockProps
  displayName: string
  removable: boolean
  Icon: JSX.Element
} = ({ $t }) => ({
  defaultProps: {
    views: [],
    title: '',
    description: '',
    linkText: '',
    linkUrl: '',
  },
  displayName: $t({
    defaultMessage: 'Quick links',
    id: 'Blocks.QuickLinks.DisplayName',
  }),
  removable: true,
  Icon: <SvgIcon name="link" />,
})
