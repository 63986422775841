import type { CollectionFields } from '@tribeplatform/gql-client/graphql'
import type {
  QueryCollectionArgs,
  QueryCollectionsArgs,
} from '@tribeplatform/gql-client/types'

import { KeyConstructor } from './keyConstructor.js'

const COLLECTION_KEY = 'collection'
const COLLECTIONS_KEY = 'collections'

export const getCollectionKey = KeyConstructor<
  QueryCollectionArgs,
  CollectionFields
>(COLLECTION_KEY)
export const getCollectionsKey = KeyConstructor<
  QueryCollectionsArgs,
  CollectionFields
>(COLLECTIONS_KEY)
