import { T } from '@tribeplatform/react-components/i18n'

import type { TableCounterProps } from './types.js'

export const TemplatesCounter = ({
  selectedCount,
  totalCount,
}: TableCounterProps) => {
  return (
    <div className="flex items-center space-s-1">
      {totalCount === selectedCount ? (
        <span className="font-medium">
          <T
            defaultMessage="{templates_count, plural, zero {No templates} one {# template} other {# templates}}"
            id="Generics.Templates.Plural"
            values={{
              templates_count: totalCount,
            }}
          />
        </span>
      ) : (
        <span>
          <T
            defaultMessage="<bold>{matched_count, plural, one {# template} other {# templates}} matched</bold> of {total_count, number}"
            id="Admin.TemplatesList.TableCounter"
            values={{
              matched_count: selectedCount,
              total_count: totalCount,
              bold: chunks => <span className="font-medium">{chunks}</span>,
            }}
          />
        </span>
      )}
    </div>
  )
}
