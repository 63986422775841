import { createContext, useContext } from 'react'

import { SlateProps, UnknownExtraContext } from '../types/index.js'

export const SlateContext = createContext<SlateProps>({
  mode: 'live',
  slate: null,
  compiledSlate: null,
  context: null,
  extraContext: { slate: {}, blocks: {} },
})

export const useSlate = <
  P extends UnknownExtraContext = UnknownExtraContext,
  K extends UnknownExtraContext = UnknownExtraContext,
>(): SlateProps<P, K> => {
  const context = useContext(SlateContext)
  return context as SlateProps<P, K>
}
