export const parseSearchParams = <T = Record<string, string>>(
  search: string,
): T => {
  const urlSearchParams = new URLSearchParams(search)
  const queryParams = {} as T
  urlSearchParams.forEach((value, key) => {
    queryParams[key] = value
  })

  return queryParams
}

export const isHomePath = (path: string) =>
  typeof path === 'string' && (path === '' || path === '/')
