import type { PostTypeFields } from '@tribeplatform/gql-client/graphql'
import type {
  PostType,
  QueryPostTypeArgs,
} from '@tribeplatform/gql-client/types'

import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPostTypeKey } from '../../utils/keys/post.key.js'

export const usePostType = (options: {
  variables: QueryPostTypeArgs
  fields?: PostTypeFields
  useQueryOptions?: UseQueryOptions<PostType>
}) => {
  const { variables, fields = 'basic', useQueryOptions } = options
  const { client } = useTribeClient()
  const postTypeKey = getPostTypeKey({ variables, fields })

  return useQuery<PostType>(
    postTypeKey,
    () => client.postType.get(variables, fields),
    {
      ...useQueryOptions,
    },
  )
}
