import {
  InQueryType,
  ObjectQuery,
  QueryType,
  UnknownProps,
} from '../types/index.js'

export const In = <P>(value: P[]): InQueryType<P> => ({
  _type: QueryType.In,
  value,
})

export const isInQuery = <P>(
  value: P | InQueryType<P>,
): value is InQueryType<P> => {
  return (
    typeof value === 'object' &&
    (value as InQueryType<P>)._type === QueryType.In
  )
}

export const applyQuery = <P extends UnknownProps>(
  object: P,
  filter: ObjectQuery<P>,
): boolean => {
  return Object.keys(filter).every((key: keyof P) => {
    const filterValue = filter[key]
    if (isInQuery(filterValue)) {
      return filterValue.value.includes(object[key])
    }
    return filter[key] === object[key]
  })
}
