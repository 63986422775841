import { startCase } from '@bettermode/common/startcase'

import { LimitedIntlShape } from '../types.js'
import { CollectionResourceRules } from './CollectionResourceRules.js'
import { CommunityResourceRules } from './CommunityResourceRules.js'
import { MemberResourceRules } from './MemberResourceRules.js'
import { SpaceResourceRules } from './SpaceResourceRules.js'
import { TagResourceRules } from './TagResourceRules.js'

export const ResourceAliases = (intl: LimitedIntlShape) => {
  return {
    ...CommunityResourceRules(intl),
    ...CollectionResourceRules(intl),
    ...SpaceResourceRules(intl),
    ...MemberResourceRules(intl),
    ...TagResourceRules(intl),
    /**
     * @tag Re-branding
     */
    BRAND: 'Bettermode',
    DOMAIN: 'bettermode.io',
    // Default rich text elements doesn't support self-closing tags
    // See https://github.com/formatjs/formatjs/issues/1597
    br: <br />,
  }
}

export const DefaultElements = {
  small: chunks => <small>{chunks}</small>,
  strong: chunks => <strong>{chunks}</strong>,
  p: chunks => <p>{chunks}</p>,
  h1: chunks => <h1>{chunks}</h1>,
  h2: chunks => <h2>{chunks}</h2>,
  h3: chunks => <h3>{chunks}</h3>,
  h4: chunks => <h4>{chunks}</h4>,
  lower: chunks =>
    chunks.map(chunk => {
      if (typeof chunk === 'string') {
        return chunk.toLowerCase()
      }
      return chunk
    }),
  capital: chunks =>
    chunks.map(chunk => {
      if (typeof chunk === 'string') {
        return startCase(chunk)
      }
      return chunk
    }),
}
