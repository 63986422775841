import { clsx } from 'clsx'
import { useFormContext } from 'react-hook-form'

import { NetworkVisibility, type Space } from '@tribeplatform/gql-client/types'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

import { SeoImagePicker } from '../common/components/SeoImagePicker/SeoImagePicker.js'
import { Form } from '../Form/index.js'
import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { SitemapWarnings } from './Components/SitemapWarnings.js'

interface Props {
  space: Space
}
export const PostSeoOptions = ({ space }: Props) => {
  const { $t } = useI18n()
  const { data: network } = useNetwork()
  const isPrivateNetwork = network?.visibility === NetworkVisibility.private
  const isNetworkNoIndex = !!network?.customSeoDetail?.noIndex
  const isPrivateSpace = !!space?.private
  const isSpaceNoindex = !!space?.customSeoDetail?.noIndex
  const { watch, setValue } = useFormContext()
  const title = watch('customSeoDetail.title')
  const description = watch('customSeoDetail.description')
  const seoImage = watch('customSeoDetail.thumbnail')

  return (
    <div className="space-y-6 mt-3 text-content-subdued">
      <div>
        <h4 className="text-lg font-medium">
          <T
            id="Post.PostSeoOptions.HeaderTitle"
            defaultMessage="Search engine optimization"
          />
        </h4>
        <p className="text-sm mt-2">
          <T
            id="Post.PostSeoOptions.SeoOptionsDescription"
            defaultMessage="Search engine optimization (SEO) allows you to improve your ranking in search results. Use these features to make it easier for users to find your page when they search for it."
          />
        </p>
      </div>
      <Form.Input
        name="slug"
        label={$t({
          id: 'Generics.Slug',
          defaultMessage: 'Slug',
        })}
        helperText={$t({
          id: 'Post.PostSeoOptions.SlugHelperText',
          defaultMessage:
            'SEO friendly, descriptive web address URL. The slug will have a unique post ID appended to it. Modifying the slug creates a redirect',
        })}
        placeholder={$t({
          id: 'Post.PostSeoOptions.SeoOptionPlaceholder',
          defaultMessage:
            'If empty, we will autogenerate this based on post title',
        })}
      />
      <Form.Input
        name="customSeoDetail.title"
        label={$t({
          id: 'Post.PostSeoOptions.MetaTitleLabel',
          defaultMessage: 'Meta title',
        })}
        placeholder={$t({
          id: 'Post.PostSeoOptions.SeoOptionPlaceholder',
          defaultMessage:
            'If empty, we will autogenerate this based on post title',
        })}
        helperText={
          <div className="flex justify-between">
            <span>
              <T
                defaultMessage="Recommended length is {length, number} characters"
                id="Generics.RecommendedLength"
                values={{
                  length: 60,
                }}
              />
            </span>
            <span
              className={clsx(
                'text-bold',
                // eslint-disable-next-line no-nested-ternary
                title
                  ? title.length > 60
                    ? 'text-content-on-attention'
                    : 'text-content-on-positive'
                  : '',
              )}
            >
              <T
                id="Post.PostSeoOptions.LengthIndicator"
                defaultMessage="Length: {length}"
                values={{ length: title?.length || 0 }}
              />
            </span>
          </div>
        }
      />
      <Form.Textarea
        name="customSeoDetail.description"
        label={$t({
          id: 'Post.PostSeoOptions.MetaDescLabel',
          defaultMessage: 'Meta description',
        })}
        placeholder={$t({
          id: 'Post.PostSeoOptions.SeoOptionPlaceholder',
          defaultMessage:
            'If empty, we will autogenerate this based on post title',
        })}
        helperText={
          <div className="flex justify-between">
            <span>
              <T
                defaultMessage="Recommended length is {min_length, number} to {max_length, number} characters"
                id="Generics.RecommendedLengthRange"
                values={{
                  min_length: 155,
                  max_length: 160,
                }}
              />
            </span>
            <span
              className={clsx(
                'text-bold',
                // eslint-disable-next-line no-nested-ternary
                description
                  ? description.length > 160 || description.length < 155
                    ? 'text-content-on-attention'
                    : 'text-content-on-positive'
                  : '',
              )}
            >
              <T
                id="Post.PostSeoOptions.LengthIndicator"
                defaultMessage="Length: {length}"
                values={{ length: description?.length || 0 }}
              />
            </span>
          </div>
        }
      />
      <Form.Input
        name="customSeoDetail.canonicalUrl"
        type="url"
        label={$t({
          id: 'Post.PostSeoOptions.CanonicalUrl',
          defaultMessage: 'Canonical URL',
        })}
        helperText={$t({
          id: 'Post.PostSeoOptions.CanonicalUrlHelperText',
          defaultMessage:
            "The canonical URL that this post should point to. If it's a duplicate post, enter the original post's URL here",
        })}
        placeholder={$t({
          id: 'Post.PostSeoOptions.CanonicalUrlPlaceholder',
          defaultMessage: "If empty, we will default to post's URL",
        })}
      />
      <div>
        <SeoImagePicker
          image={seoImage}
          onChange={image => setValue('customSeoDetail.thumbnail', image)}
          label={
            <h4 className="text-lg font-medium">
              <T
                id="Post.PostSeoOptions.SocialImageHeaderText"
                defaultMessage="Social image sharing"
              />
            </h4>
          }
        />
        <p className="text-sm mt-2">
          <T
            id="Post.PostSeoOptions.SocialImageHeaderDescription"
            defaultMessage="Social networks typically show your social sharing image together with your meta title and description. If you don’t add a social sharing image, we’ll use your community social sharing image or community logo instead. Recommended size is 1200 x 630 pixels."
          />
        </p>
      </div>
      <div className="flex flex-col space-y-3">
        <p className="text-sm">
          <T
            id="Post.PostSeoOptions.TitleAndDescriptionHelperText"
            defaultMessage="Search results typically show your SEO title and description. Your title is also the browser window title. Depending on the search engine, descriptions displayed can be 50 to 300 characters long. If you don’t add a title or description, search engines will use your page title and content."
          />
        </p>
        <Form.Toggle
          label={$t({
            id: 'Post.PostSeoOptions.NoIndexToggleLabel',
            defaultMessage: 'Hide from search results',
          })}
          helperText={$t({
            defaultMessage:
              'Enabling this option will prevent this post from being indexed by search engines. As a result, this post will also be excluded from the sitemap.',
            id: 'Admin.Post.SeoSettings.HideSeo.Description',
          })}
          disabled={
            isPrivateNetwork ||
            isPrivateSpace ||
            isNetworkNoIndex ||
            isSpaceNoindex
          }
          name="customSeoDetail.noIndex"
        />
        <SitemapWarnings
          isPrivateNetwork={isPrivateNetwork}
          isPrivateSpace={isPrivateSpace}
          isNetworkNoIndex={isNetworkNoIndex}
          isSpaceNoindex={isSpaceNoindex}
        />
      </div>
    </div>
  )
}
