import React from 'react'

import { Controller, useFormContext } from 'react-hook-form'

import {
  DatePicker,
  DatePickerProps,
  DateTimePicker,
  DateRangePicker,
  DateRangePickerProps,
  DateTimePickerProps,
  DateValue,
} from '@tribeplatform/react-ui-kit/DateTimePicker'
import {
  FormControl,
  extractLayoutProps,
} from '@tribeplatform/react-ui-kit/FormControl'

import { dayjs } from '../../common/lib/dayjs.js'
import { FormFieldProps } from '../typings.js'

export type FormDatePickerProps =
  | (DatePickerProps & {
      type: 'date'
    })
  | (DateTimePickerProps & {
      type: 'datetime-local'
    })
  | (DateRangePickerProps & {
      type: 'date-range'
    })

const renderElement = ({
  type,
  ...rest
}: {
  type: FormDatePickerProps['type']
  onChange: (newValue) => void
}) => {
  switch (type) {
    case 'date':
      return <DatePicker {...rest} />
    case 'datetime-local':
      return <DateTimePicker {...rest} />
    case 'date-range':
      return <DateRangePicker {...rest} />
    default:
      // Code should never reach the default case
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = type
      return exhaustiveCheck
  }
}

const removeTimezoneData = (date: DateValue) => {
  if (!date) {
    return undefined
  }
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ss')
}

const cleanTimezone = (value: DateValue | [DateValue, DateValue]) => {
  return Array.isArray(value)
    ? value.map(removeTimezoneData)
    : removeTimezoneData(value)
}

export function FormDateTime({
  name,
  validation,
  onChange: fieldOnChange,
  type = 'date',
  ...rest
}: FormFieldProps<FormDatePickerProps>) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => {
        const { layoutProps, inputProps } = extractLayoutProps({
          ...rest,
          name,
          invalid,
          error: error?.message,
        })

        return (
          <FormControl.Layout {...layoutProps}>
            {renderElement({
              type,
              invalid,
              value: cleanTimezone(value),
              onChange: value => {
                const localValue = cleanTimezone(value)
                onChange(localValue)
                if (typeof fieldOnChange === 'function') {
                  fieldOnChange(localValue)
                }
              },
              ...inputProps,
            })}
          </FormControl.Layout>
        )
      }}
    />
  )
}
