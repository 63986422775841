import { Link } from 'react-router-dom'

import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { EmptyState } from '@tribeplatform/react-ui-kit/EmptyState'

export const PostNotFound = () => {
  const { $t } = useI18n()
  return (
    <Card>
      <Card.Content>
        <div className="py-12">
          <EmptyState
            title={$t({
              id: 'Post.NotFound',
              defaultMessage: 'Post not found',
            })}
            description={$t({
              id: 'Post.NotFound.Description',
              defaultMessage:
                'This post does not exist, is removed, or you don’t have permission to access it.',
            })}
          >
            <Button variant="secondaryNeutral" as={Link} to="/">
              <T id="Generics.Home" defaultMessage="Home" />
            </Button>
          </EmptyState>
        </div>
      </Card.Content>
    </Card>
  )
}
