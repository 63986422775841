import { ComponentProps, forwardRef } from 'react'

import { clsx } from 'clsx'

import { iconNames, type IconName } from '@bettermode/icons/social/types'

import { useThemeContext } from '../ThemeProvider/ThemeContext.js'
import { iconStyles } from './Icon.styles.js'
import { IconStyleProps } from './types.js'

export type SvgSocialIconProps = Omit<ComponentProps<'svg'>, 'name'> &
  IconStyleProps & {
    name: IconName
    rounded?: boolean
    inline?: boolean
    className?: string
  }

export const SvgSocialIcon = forwardRef<SVGSVGElement, SvgSocialIconProps>(
  ({ name, size, inline, rounded, className, ...rest }, ref) => {
    const {
      assets: { iconSocialSpriteUrl },
    } = useThemeContext()
    if (!iconNames.includes(name)) {
      console.warn('SvgSocialIcon: icon name not found', name)
    }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          iconStyles({ size }),
          rounded && 'rounded-full',
          inline && 'inline-icon',
        )}
        ref={ref}
        width="1em"
        height="1em"
        {...rest}
      >
        <use href={`${iconSocialSpriteUrl}#icon-${name}`} />
      </svg>
    )
  },
)
