import { lazy, ReactNode, Suspense, useEffect, useRef } from 'react'

import { NavigationType, useNavigationType } from 'react-router-dom'

import { isSupportEmail } from '@bettermode/common/feature-flags'
import type { Member, Network } from '@bettermode/common/gql/generated'
import { CookieContextProvider } from '@tribeplatform/react-components/Apps'
import { useStaticIntl } from '@tribeplatform/react-components/i18n'
import { useRouterPath } from '@tribeplatform/react-sdk'
import { useAuthToken } from '@tribeplatform/react-sdk/hooks'
import { SlateKitProvider } from '@tribeplatform/react-slate-kit/components'
import { useSlateKit } from '@tribeplatform/react-slate-kit/hooks'

import { Wrapper } from './components/Admin/Editor/SlateWrappers/index.js'
import { AccountVerificationAlert } from './components/Auth/components/AccountVerificationAlert.js'
import { RenderError } from './components/Error/Error.js'
import { ErrorFallback } from './components/Error/ErrorBoundry.js'
import { InteractionComponents } from './interactions/InteractionComponents.js'
import { getRegisteredBlocks } from './registered-blocks.js'

function ScrollToTop() {
  const pathname = useRouterPath()
  const navigationType = useNavigationType()
  const prevPathname = useRef(pathname)

  useEffect(() => {
    if (
      navigationType !== NavigationType.Pop &&
      pathname !== prevPathname.current
    ) {
      window.scrollTo(0, 0)
    }
    prevPathname.current = pathname
  }, [navigationType, pathname])

  return null
}

const CookieConsent = lazy(() =>
  import('@tribeplatform/react-components/Apps').then(m => ({
    default: m.CookieConsent,
  })),
)

interface Props {
  networkId?: Network['id']
  networkExtraProperties: Network['extraProperties']
  memberId?: Member['id']
  children: ReactNode
}

export const SlateApp = ({
  networkId,
  networkExtraProperties,
  memberId,
  children,
}: Props) => {
  const registeredBlocks = useStaticIntl(getRegisteredBlocks)
  const {
    data: { network, member },
  } = useAuthToken({
    useQueryOptions: {
      // this is here to prevent the whole page from re-rendering
      notifyOnChangeProps: ['data'],
    },
  })
  const decisionDynamicBlockSettings = isSupportEmail(member?.email ?? '')
  const slateKit = useSlateKit({
    blocks: registeredBlocks,
    blockWrapper: Wrapper,
    dynamicBlockSettings: decisionDynamicBlockSettings,
  })
  const networkTermsOfServiceUrl =
    networkExtraProperties.find(
      property => property.key === 'termsOfServiceUrl',
    )?.value ?? ''
  const networkPrivacyPolicyUrl =
    networkExtraProperties.find(property => property.key === 'privacyPolicyUrl')
      ?.value ?? ''

  return (
    <SlateKitProvider
      kit={slateKit}
      context={{
        networkTermsOfServiceUrl:
          networkTermsOfServiceUrl ?? network?.termsOfServiceUrl,
        networkExtraProperties:
          networkExtraProperties ?? network?.extraProperties,
        networkPrivacyPolicyUrl:
          networkPrivacyPolicyUrl ?? network?.privacyPolicyUrl,
        actorId: memberId ?? member?.id,
        networkId: networkId ?? network?.id,
      }}
      onError={({ error, ...extras }) => {
        const slateError = new RenderError({
          message: extras,
          error,
          name: 'SlateError',
        })
        logger.error(slateError)
      }}
      ErrorFallback={ErrorFallback}
    >
      <ScrollToTop />
      <InteractionComponents />
      <AccountVerificationAlert />
      {children}
      <Suspense fallback={<></>}>
        <CookieContextProvider>
          <CookieConsent />
        </CookieContextProvider>
      </Suspense>
    </SlateKitProvider>
  )
}
