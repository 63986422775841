import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { DynamicBlock as Component } from './Block.js'
import { DynamicBlockProps } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.DynamicBlockSettings })),
)

export const RegisteredDynamicBlock = (
  _intl: I18nShape,
): RegisteredBlock<DynamicBlockProps> => ({
  recommendedName: 'DynamicBlock',
  Component,
  config: {
    type: BlockType.Block,
    usable: false,

    Settings,
    defaultProps: {
      staffOnly: false,
    },
  },
})
