import { createContext, useContext } from 'react'

import {
  BlockProps,
  UnknownExtraContext,
  UnknownProps,
} from '../types/index.js'

export const BlockContext = createContext<BlockProps<UnknownProps>>({
  id: null,
  block: null,
  extraContext: {},
})

export const useBlock = <
  P extends UnknownProps = UnknownProps,
  K extends UnknownExtraContext = UnknownExtraContext,
>(): BlockProps<P, K> => {
  const context = useContext(BlockContext)
  return context as BlockProps<P, K>
}
