import { NetworkVisibility } from '@tribeplatform/gql-client/types'
import {
  SquareLogo,
  Logo,
  usePreviewLogo,
  type LogoSize,
} from '@tribeplatform/react-components/Logo'
import { useRouter } from '@tribeplatform/react-sdk'
import { useAuthMember, useAuthToken } from '@tribeplatform/react-sdk/hooks'

import { useSlate } from '../../../hooks/slate.hook.js'

interface Props {
  logoSize: LogoSize
}
export const LogoRenderer = ({ logoSize }: Props) => {
  const { mode } = useSlate()
  const preview = mode !== 'live'
  const {
    data: { networkPublicInfo },
  } = useAuthToken()
  const { isGuest } = useAuthMember()
  const isNetworkPrivate =
    networkPublicInfo?.visibility === NetworkVisibility.private
  const { Link } = useRouter()
  const href = isGuest && isNetworkPrivate ? '#' : '/'
  const previewLogo = usePreviewLogo()

  if (preview && previewLogo) {
    const { logo, squareLogo } = previewLogo
    return (
      <Link
        href={href}
        className="block logo max-w-[var(--c-header-logo-max-width)]"
        rounded="none"
      >
        <div className="block sm:hidden">
          <SquareLogo image={squareLogo} />
        </div>
        <div className="hidden sm:block">
          <Logo image={logo} logoSize={logoSize} />
        </div>
      </Link>
    )
  }

  return (
    <Link
      href={href}
      className="block logo max-w-[var(--c-header-logo-max-width)]"
      rounded="none"
    >
      <div className="block sm:hidden">
        <SquareLogo />
      </div>
      <div className="hidden sm:block">
        <Logo logoSize={logoSize} />
      </div>
    </Link>
  )
}
