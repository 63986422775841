import { ComponentProps, forwardRef } from 'react'

import { clsx } from 'clsx'

import { iconNames, type IconName } from '@bettermode/icons/types'

import { useThemeContext } from '../ThemeProvider/ThemeContext.js'
import { semanticIconMap, SemanticIconName } from './constants.js'
import { iconStyles } from './Icon.styles.js'
import { IconStyleProps } from './types.js'

export type SvgIconName = IconName | SemanticIconName

export type SvgIconProps = Omit<ComponentProps<'svg'>, 'name'> &
  IconStyleProps & {
    name: SvgIconName
    inline?: boolean
    className?: string
    iconSpriteUrl?: string
  }

export const SvgIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  (
    {
      name,
      size,
      inline,
      className,
      iconSpriteUrl: givenIconSpriteUrl,
      ...rest
    },
    ref,
  ) => {
    const {
      assets: { iconSpriteUrl: themeIconSpriteUrl },
    } = useThemeContext()

    const iconSpriteUrl = givenIconSpriteUrl ?? themeIconSpriteUrl
    const resolvedIconName = semanticIconMap[name] ?? name

    if (!iconNames.includes(resolvedIconName)) {
      console.warn('SvgIcon: icon name not found', name)
    }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          className,
          iconStyles({ size }),
          inline && 'inline-icon',
        )}
        ref={ref}
        width="1em"
        height="1em"
        {...rest}
      >
        <use href={`${iconSpriteUrl}#icon-${resolvedIconName}`} />
      </svg>
    )
  },
)
