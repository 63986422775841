import {
  getSpaceRolesGQLQuery,
  SpaceRoleFields,
  updateMemberSpaceRoleGQLMutation,
} from '../../graphql/index.js'
import {
  Action,
  MutationUpdateMemberSpaceRoleArgs,
  QuerySpaceRolesArgs,
  SpaceRole,
} from '../../types/index.js'
import { GraphqlClient } from '../graphql.client.js'

export class SpaceRolesClient {
  private client: GraphqlClient

  constructor(client: GraphqlClient) {
    this.client = client
  }

  async list(
    variables: QuerySpaceRolesArgs,
    fields: SpaceRoleFields = 'basic',
    accessToken?: string,
  ): Promise<Array<SpaceRole>> {
    type QueryResult = { spaceRoles: Array<SpaceRole> }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getSpaceRolesGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.spaceRoles
  }

  async updateMemberRole(
    variables: MutationUpdateMemberSpaceRoleArgs,
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { updateMemberSpaceRole: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: updateMemberSpaceRoleGQLMutation(),
      variables,
      customToken: accessToken,
    })
    return result.updateMemberSpaceRole
  }
}
