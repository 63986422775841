import './styles.css'
import { lazy, Suspense } from 'react'

import { HelmetProvider } from 'react-helmet-async'
import type { IntlShape } from 'react-intl/src/types.js'

import { GraphqlClient } from '@tribeplatform/gql-client/client'
import type { ClientError, ErrorResponse } from '@tribeplatform/gql-client/lib'
import { CommandBarProvider } from '@tribeplatform/react-components/CommandBar'
import { FirstRenderProvider } from '@tribeplatform/react-components/common/ssr'
import { I18nProvider } from '@tribeplatform/react-components/i18n'
import { QueryLayoutProvider } from '@tribeplatform/react-components/LayoutFrame'
import { ModalProvider } from '@tribeplatform/react-components/Modal'
import { Provider } from '@tribeplatform/react-sdk'
import { AppProvider } from '@tribeplatform/react-ui-kit/AppProvider'
import { DocumentContext } from '@tribeplatform/react-ui-kit/hooks'

import { RuntimeConfigs } from '../../configs/runtime.js'
import { getAssetPath } from '../../configs/static.js'
import type { InjectedAppProps } from '../server/types/server.types.js'
import { AppRoutes } from './AppRoutes.js'
import { Hydrate } from './components/Hydrate/Hydrate.js'
import { useTracker } from './hooks/useTracker.js'
import { useInteractions, interactionEmitter } from './interactions/index.js'
import {
  TribeEmitter,
  TribeEmitterEvent,
} from './lib/tribe-events/TribeEmitter.js'
import { WebThemeProvider } from './new-themes/WebThemeProvider.js'
import { IntercomBootProvider } from './providers/IntercomBootProvider.js'
import { DesktopNotificationProvider } from './providers/notification/index.js'
import { TribeWeb } from './TribeWeb.js'
import { onClientError, errorBoundaryHandler } from './utils/onError.js'

const CommandBar = lazy(() =>
  import('@tribeplatform/react-components/CommandBar').then(m => ({
    default: m.CommandBar,
  })),
)

const EmojiLoader = lazy(() =>
  import('./components/EmojiLoader.js').then(m => ({
    default: m.EmojiLoader,
  })),
)

interface Props {
  i18n: IntlShape
  injectedAppProps: InjectedAppProps
}

export const App = ({ i18n, injectedAppProps }: Props) => {
  const {
    intercom,
    accessToken,
    colorScheme,
    otsm,
    authMember: {
      id: memberId,
      role: { id: memberRoleId, type: memberRoleType },
      locale,
    },
    network: {
      id: networkId,
      domain,
      domainSubfolder,
      organizationId,
      themes: oldTheme,
      activeTheme,
    },
  } = injectedAppProps

  const tracker = useTracker({
    memberId,
    memberRoleId,
    memberRoleType,
    memberLocale: locale,
    networkId,
    organizationId,
  })

  return (
    <DocumentContext.Provider
      value={{
        document: typeof document !== 'undefined' ? document : undefined,
      }}
    >
      <I18nProvider i18n={i18n}>
        <Provider
          config={{
            baseUrl: RuntimeConfigs.GQL_ENDPOINT,
            networkDomain: domain,
            accessToken,
            giphyApiKey: RuntimeConfigs.GIPHY_API_KEY,
            unsplashApiKey: RuntimeConfigs.UNSPLASH_ACCESS_KEY,
            getAssetPath,
            interactionsContext: {
              useInteractions,
              interactionEmitter,
            },
            onError: (
              errors: ErrorResponse[] | undefined | null,
              client: GraphqlClient,
              error?: ClientError,
            ) => {
              if (!errors) {
                return
              }
              const nonNetworkRelatedErrors = errors
                .filter(
                  _error => typeof _error !== 'undefined' && _error !== null,
                )
                .filter(_error => !_error.code)
              onClientError(
                nonNetworkRelatedErrors,
                client,
                domain,
                domainSubfolder,
                error,
              )
            },
            onEvent: event => {
              TribeEmitter.emit(TribeEmitterEvent.Event, {
                ...event,
                network: injectedAppProps.network,
              })
            },
            errorBoundary: {
              handler: errorBoundaryHandler,
            },
            tracker,
          }}
        >
          <HelmetProvider>
            <Hydrate injectedAppProps={injectedAppProps}>
              <IntercomBootProvider
                enabled={intercom.enabled}
                appId={intercom.appId}
                bootProps={intercom.bootProps}
              >
                <Suspense fallback={<></>}>
                  <EmojiLoader />
                </Suspense>
                <WebThemeProvider
                  oldColors={oldTheme?.active?.tokens?.colors}
                  networkThemeLightColors={activeTheme?.colors?.light}
                  networkThemeDarkColors={activeTheme?.colors?.dark}
                  networkThemeTypography={activeTheme?.typography}
                  colorScheme={colorScheme}
                  domain={domain}
                  domainSubfolder={domainSubfolder}
                >
                  <AppProvider>
                    <TribeWeb otsm={otsm}>
                      <QueryLayoutProvider>
                        <FirstRenderProvider>
                          <ModalProvider>
                            <CommandBarProvider>
                              <Suspense fallback={<></>}>
                                <CommandBar />
                              </Suspense>
                              <DesktopNotificationProvider>
                                <AppRoutes
                                  injectedAppProps={injectedAppProps}
                                />
                              </DesktopNotificationProvider>
                            </CommandBarProvider>
                          </ModalProvider>
                        </FirstRenderProvider>
                      </QueryLayoutProvider>
                    </TribeWeb>
                  </AppProvider>
                </WebThemeProvider>
              </IntercomBootProvider>
            </Hydrate>
          </HelmetProvider>
        </Provider>
      </I18nProvider>
    </DocumentContext.Provider>
  )
}
