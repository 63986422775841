import { useState } from 'react'

import type { Glyph, Post } from '@tribeplatform/gql-client/types'
import { Button } from '@tribeplatform/react-ui-kit/Button'

import { EntityImage } from '../../common/components/EntityImage.js'
import { convertIdToGlyph } from '../../MediaPicker/utils/utils.js'
import { PopoverEmojiPicker } from '../../Picker/PopoverEmojiPicker.js'
import { ReactAction } from '../../Views/types.js'
import { getTextIconSettings } from '../utils/utils.js'

interface Props {
  post: Post
  action: ReactAction
  disabled: boolean
  onReact: (key: string, shouldRemove: boolean, shouldReplace: boolean) => void
}
export const EmojiReactionAction = ({
  post,
  action,
  disabled,
  onReact,
}: Props) => {
  const {
    defaultLabel,
    variant,
    glyphId,
    color,
    activeLabel,
    activeGlyphId,
    activeColor,
  } = action
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const { showText, showIcon } = getTextIconSettings(variant)
  const hasReacted = post?.reactions?.some(reaction => !!reaction?.reacted)

  const handleChange = (glyph: Glyph) => {
    const key = glyph.id
    const remove = post?.reactions?.some(
      reaction => reaction?.reaction === key && !!reaction?.reacted,
    )
    const replace = !!action.isSingleReaction
    onReact(key, remove, replace)
  }

  return (
    <PopoverEmojiPicker
      open={showEmojiPicker}
      onClose={() => setShowEmojiPicker(false)}
      onChange={handleChange}
    >
      <Button
        variant="secondaryNeutral"
        className="group select-none"
        pressed={hasReacted}
        disabled={disabled}
        size="lg"
        fullWidth
        leadingIcon={
          showIcon &&
          (hasReacted ? (
            <EntityImage
              entity={{
                image: convertIdToGlyph(activeGlyphId || glyphId),
                name: activeLabel || (defaultLabel ?? ''),
              }}
              size="xl"
              displayColor={activeColor}
              iconStyle="solid"
            />
          ) : (
            <EntityImage
              entity={{
                image: convertIdToGlyph(glyphId),
                name: defaultLabel || '',
              }}
              size="xl"
              displayColor={color}
            />
          ))
        }
        onClick={() => setShowEmojiPicker(prev => !prev)}
      >
        {showText && (
          <span className="truncate">
            {hasReacted ? activeLabel || defaultLabel : defaultLabel}
          </span>
        )}
      </Button>
    </PopoverEmojiPicker>
  )
}
