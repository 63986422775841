import { Fragment } from 'react'

import { clsx } from 'clsx'

import type { StaticField } from '@tribeplatform/react-components/Views'

import { FieldProps } from '../types.js'

interface Props {
  field: StaticField
  highlighted: boolean
  stretchX: string
  fieldProps: Omit<FieldProps, 'field'>
  FieldRenderer: (props: FieldProps) => JSX.Element
}
export const ListMemberInfo = ({
  field,
  highlighted,
  stretchX,
  fieldProps,
  FieldRenderer,
}: Props) => {
  return (
    <div
      className={clsx(
        'empty:hidden flex flex-col gap-1 min-w-0',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
    >
      {field?.fields?.map(subField => (
        <Fragment key={subField.id}>
          {subField.enabled && (
            <FieldRenderer field={subField} {...fieldProps} />
          )}
        </Fragment>
      ))}
    </div>
  )
}
