import { useCallback, useMemo } from 'react'

import { type CustomFieldSchema } from '@tribeplatform/gql-client/types'

import type { RawFilter, ListFilter, Filter, LegacyFilter } from '../types.js'
import { convertToFilterArray, prepareFilters } from '../utils/converters.js'
import { getDynamicFilters } from '../utils/dynamicFilters.js'

interface UseListFiltersProps {
  entityListFilters: ListFilter[]
  customFields: CustomFieldSchema[]
  staticFilters: RawFilter[]
  setFilters?: (filters: LegacyFilter[]) => void
}
export const useListFilters = ({
  entityListFilters,
  customFields,
  staticFilters,
  setFilters,
}: UseListFiltersProps) => {
  const allFilters: RawFilter[] = useMemo(() => {
    const dynamicFilters = getDynamicFilters(customFields)

    return [...staticFilters, ...dynamicFilters]
  }, [customFields, staticFilters])

  const filters: Filter[] = useMemo(
    () => convertToFilterArray(entityListFilters, allFilters),
    [allFilters, entityListFilters],
  )

  const availableFilters: RawFilter[] = useMemo(() => {
    return allFilters.filter(({ key, allowMultiple }) => {
      if (allowMultiple) {
        return true
      }

      return !filters.some(filter => filter.key === key)
    })
  }, [allFilters, filters])

  const setFilter = useCallback(
    (newFilter: Filter) => {
      if (!setFilters) {
        return
      }

      const isNew = !filters.some(({ id }) => newFilter.id === id)
      if (isNew) {
        const newFilters = [...filters, newFilter]
        const legacyFilters = prepareFilters(newFilters)
        setFilters(legacyFilters)
        return
      }

      const newFilters = filters.map(filter => {
        if (filter.id === newFilter.id) {
          return newFilter
        }

        return filter
      })
      const legacyFilters = prepareFilters(newFilters)
      setFilters(legacyFilters)
    },
    [filters, setFilters],
  )

  const removeFilter = useCallback(
    (filter: Filter) => {
      if (!setFilters) {
        return
      }

      const newFilters = filters.filter(({ id }) => filter.id !== id)
      const legacyFilters = prepareFilters(newFilters)
      setFilters(legacyFilters)
    },
    [filters, setFilters],
  )

  return {
    filters,
    allFilters,
    availableFilters,
    setFilter,
    removeFilter,
  }
}
