import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { SpaceMemberListBlock as Component } from './Block.js'
import { SpaceMemberListProps } from './types.js'

/**
 * @deprecated use RegisteredMembersBlock instead
 */
export const RegisteredSpaceMemberListBlock = (
  intl: I18nShape,
): RegisteredBlock<SpaceMemberListProps> => {
  const { $t } = intl

  return {
    recommendedName: 'WhySpaceMemberList',
    Component,
    config: {
      type: BlockType.Block,
      displayName: $t({
        id: 'Blocks.SpaceMemberList.DisplayName',
        defaultMessage: '{SPACE_CC} member list',
      }),
      deprecated: true,
      Icon: <SvgIcon name="members" />,
    },
  }
}
