import { clsx } from 'clsx'

import { EntityImage } from '@tribeplatform/react-components/common/components'
import { useRouter } from '@tribeplatform/react-sdk'

import { Target } from '../../HeroBanner/types.js'
import type { MenuItem } from '../types.js'

interface Props extends MenuItem {
  active?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}
export const SubMenuItem = ({
  id,
  name,
  description,
  icon,
  url,
  target,
  active,
  onClick,
}: Props) => {
  const { Link } = useRouter()

  return (
    <Link
      variant="neutral"
      href={url}
      target={target === Target.blank ? '_blank' : '_self'}
      className={clsx(
        active && 'bg-topbar-subdued',
        'col-span-1 flex gap-x-4 cursor-pointer hover:text-content-on-topbar hover:bg-topbar-subdued p-3 rounded-base transition-colors duration-150',
      )}
      tabIndex={0}
      onClick={onClick}
    >
      {!!icon && (
        <div className="shrink-0">
          <EntityImage entity={{ image: icon, name: id }} size="xl" />
        </div>
      )}
      <div className="flex-1 space-y-1">
        <div className="text-content-on-background font-medium">{name}</div>
        {!!description && (
          <div className="text-content-on-background-subdued text-sm">
            {description}
          </div>
        )}
      </div>
    </Link>
  )
}
