import { clsx } from 'clsx'

import { ContentRenderer } from '../Components/ContentRenderer.js'
import { MediaRenderer } from '../Components/MediaRenderer.js'
import { MediaType, type HeroBannerProps } from '../types.js'
import { getContentSpacingClass } from '../utils/utils.js'

interface Props extends HeroBannerProps {
  reverse?: boolean
}
export const LeftRightLayout = ({
  contentSpacing,
  icon,
  eyebrow,
  heading,
  description,
  textColor,
  textAlignment,
  mediaType,
  image,
  video,
  mediaTint,
  backgroundColor,
  actionType,
  reverse,
  buttonActions,
  searchAction,
}: Props) => {
  const spacingClass = getContentSpacingClass(contentSpacing)
  const renderMedia = mediaType !== MediaType.none

  return (
    <div
      className={clsx(
        'flex rounded-base overflow-hidden shadow-card',
        'screen-rounded-none',
        'border-base border-card screen-border-x-0',
        reverse
          ? 'flex-col-reverse @3xl:flex-row-reverse'
          : 'flex-col @3xl:flex-row',
        !backgroundColor && renderMedia && 'bg-surface',
      )}
      style={{ backgroundColor: renderMedia ? backgroundColor : 'transparent' }}
    >
      <div className="@3xl:flex-1">
        <ContentRenderer
          actionType={actionType}
          buttonActions={buttonActions}
          searchAction={searchAction}
          textColor={textColor}
          textAlignment={textAlignment}
          icon={icon}
          eyebrow={eyebrow}
          heading={heading}
          description={description}
          className={clsx('px-6 @3xl:px-10 @5xl:px-16', spacingClass)}
        />
      </div>
      {renderMedia && (
        <div
          className={clsx('aspect-video @3xl:aspect-auto @3xl:flex-1 relative')}
        >
          <MediaRenderer
            mediaType={mediaType}
            image={image}
            video={video}
            mediaTint={mediaTint}
            textColor={textColor}
          />
        </div>
      )}
    </div>
  )
}
