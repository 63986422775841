import { FC, useEffect, useState } from 'react'

import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'
import { Toggle } from '@tribeplatform/react-ui-kit/Toggle'

import { T } from '../../i18n/components/T.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { CookiesBox } from './CookiesBox.js'
import {
  CookieKind,
  disableCookie,
  disableCookies,
  enableCookie,
  getCookieSettings,
} from './utils/cookie-settings.js'

type CookieConsentPreferencesModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const CookieConsentPreferencesModal: FC<
  CookieConsentPreferencesModalProps
> = ({ isOpen, onClose }) => {
  const {
    [CookieKind.ADVERTISING_AND_TRACKING]: adsAndTrackCookies,
    [CookieKind.ANALYTICS_AND_FUNCTIONAL]: analyticsAndFuncCookies,
  } = getCookieSettings() || {}
  const { $t } = useI18n()
  const [isAnalyticsSelected, setIsAnalyticsSelected] = useState(
    analyticsAndFuncCookies?.enabled,
  )
  const [isAdvertisingSelected, setIsAdvertisingSelected] = useState(
    adsAndTrackCookies?.enabled,
  )

  useEffect(() => {
    setIsAnalyticsSelected(analyticsAndFuncCookies?.enabled)
    setIsAdvertisingSelected(adsAndTrackCookies?.enabled)
  }, [adsAndTrackCookies?.enabled, analyticsAndFuncCookies?.enabled])

  const onRequiredOnly = () => {
    disableCookies([
      CookieKind.ADVERTISING_AND_TRACKING,
      CookieKind.ANALYTICS_AND_FUNCTIONAL,
    ])
    onClose()
  }
  const onSubmit = () => {
    if (isAnalyticsSelected) {
      enableCookie(CookieKind.ANALYTICS_AND_FUNCTIONAL)
    }
    if (isAdvertisingSelected) {
      enableCookie(CookieKind.ADVERTISING_AND_TRACKING)
    }
    if (!isAnalyticsSelected) {
      disableCookie(CookieKind.ANALYTICS_AND_FUNCTIONAL)
    }
    if (!isAdvertisingSelected) {
      disableCookie(CookieKind.ADVERTISING_AND_TRACKING)
    }
    onClose()
  }

  return (
    <Modal open={isOpen} onClose={onClose} size="xl">
      <Modal.Header
        title={
          <T
            defaultMessage="About the cookies on this site"
            id="CookieConsent.Modal.Title"
          />
        }
        description={
          <T
            defaultMessage="Some cookies are required to use our services. To continue using this website, review the available cookie controls and make any optional changes you’d like before selecting accept below."
            id="CookieConsent.Modal.Description"
          />
        }
      />
      <Modal.Content>
        <div className="flex flex-col space-y-6">
          <CookiesBox
            title={$t({
              defaultMessage: 'Essential website cookies',
              id: 'CookieConsent.Modal.Essentials.Title',
            })}
            description={$t({
              defaultMessage:
                'These cookies are strictly necessary to provide you with the services and features available through our site.',
              id: 'CookieConsent.Modal.Essentials.Description',
            })}
          >
            <div className="text-content">
              <T
                defaultMessage="Necessary"
                id="CookieConsent.Modal.Necessary"
              />
            </div>
          </CookiesBox>

          <CookiesBox
            title={$t({
              defaultMessage: 'Analytics and functional cookies',
              id: 'CookieConsent.Modal.Analytics.Title',
            })}
            description={$t({
              defaultMessage:
                'These cookies collect information to help us understand how the site is being used and to see how visitors move around the site.',
              id: 'CookieConsent.Modal.Analytics.Description',
            })}
          >
            <Toggle
              data-testid="Cookie-analytics-switch"
              onChange={value => {
                setIsAnalyticsSelected(value)
              }}
              checked={isAnalyticsSelected}
            />
          </CookiesBox>

          <CookiesBox
            title={$t({
              defaultMessage: 'Advertising and tracking cookies',
              id: 'CookieConsent.Modal.Ads.Title',
            })}
            description={$t({
              defaultMessage:
                'These cookies are used to make advertising messages more relevant to you and your interests.',
              id: 'CookieConsent.Modal.Ads.Description',
            })}
          >
            <Toggle
              data-testid="Cookie-advertising-switch"
              onChange={value => setIsAdvertisingSelected(value)}
              checked={isAdvertisingSelected}
            />
          </CookiesBox>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="primary" onClick={onSubmit} fullWidth>
          <T
            defaultMessage="Submit preferences"
            id="CookieConsent.Modal.Actions.Submit"
          />
        </Button>
        <Button variant="secondaryNeutral" onClick={onRequiredOnly} fullWidth>
          <T
            defaultMessage="Required cookies only"
            id="CookieConsent.Modal.Actions.RequiredOnly"
          />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
