import { ContainerProps } from '@tribeplatform/react-ui-kit/Layout'

import { CompiledBlock, UnknownCompiledBlock } from '../../types/index.js'

export const isContainerBlock = (
  block: UnknownCompiledBlock | CompiledBlock<ContainerProps>,
): block is CompiledBlock<ContainerProps> => {
  return block.name === 'Container'
}

export const isColumnBlock = (name: string) => name === 'Columns'

export const isDeprecatedBlock = (name: string) => name !== 'Container'
