import { type Glyph, GlyphMediaVariant } from '@tribeplatform/gql-client/types'
import { noop } from '@tribeplatform/react-ui-kit/hooks'

export const checkIconById = (id: string): boolean => {
  if (typeof id !== 'string') {
    return false
  }

  return id.startsWith(GlyphMediaVariant.icon)
}
export const convertIdToGlyph = (id: string): Glyph => {
  if (typeof id !== 'string') {
    return null
  }

  const [variant, text] = id.split('/') as [GlyphMediaVariant, string]

  if (!text) {
    return null
  }

  switch (variant) {
    case GlyphMediaVariant.emoji: {
      const glyph: Glyph = {
        id,
        text,
        variant: GlyphMediaVariant.emoji,
      }

      return glyph
    }
    case GlyphMediaVariant.icon: {
      const glyph: Glyph = {
        id,
        text,
        variant: GlyphMediaVariant.icon,
      }

      return glyph
    }
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = variant
      noop(exhaustiveCheck)
      return null
    }
  }
}
