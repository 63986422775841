import { cva } from 'class-variance-authority'

export const labelStyles = cva(['block font-medium '], {
  variants: {
    size: {
      default: 'text-label-md',
      compact: 'text-label-sm',
    },
    disabled: {
      true: 'text-content-disabled',
      false: 'text-content',
    },
  },
  defaultVariants: {
    size: 'default',
  },
})

export const helperTextStyles = cva(['first-letter:capitalize'], {
  variants: {
    size: {
      default: 'text-label-sm',
      compact: 'text-label-xs',
    },
    invalid: {
      true: 'text-content-on-negative',
      false: 'text-content-subdued',
    },
  },
  defaultVariants: {
    size: 'default',
  },
})
