import { forwardRef } from 'react'

import { useFormContext } from 'react-hook-form'

import {
  FormControl,
  FormControlInputCopyProps,
} from '@tribeplatform/react-ui-kit/FormControl'

import { FormValidationProps } from './typings.js'

export type InputCopyProps = FormControlInputCopyProps & FormValidationProps

export const InputCopy = forwardRef<HTMLInputElement, InputCopyProps>(
  ({ name, validation, ...rest }, ref) => {
    const { register, formState } = useFormContext()

    // This is to get the nested errors from formState.errors
    // Example: name = "a.b", we want to get formState.errors.a.b
    const error = name
      .split('.')
      .reduce((error, name) => error?.[name], formState.errors)

    const { ref: registerRef, ...registerRest } = register(name, validation)

    return (
      <FormControl.InputCopy
        ref={node => {
          if (ref) {
            // Not sure what's happening here with forwarded refs. Skipping the any check for now.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ;(ref as any).current = node
          }
          registerRef(node)
        }}
        name={name}
        invalid={!!error?.message}
        error={error?.message as string}
        {...registerRest}
        {...rest}
      />
    )
  },
)
