import { ReactNode, useEffect, useState } from 'react'

import { clsx } from 'clsx'
import { createPortal } from 'react-dom'

import { useDocument } from '@tribeplatform/react-ui-kit/hooks'

import { getSubMenuPortalRootElement } from '../utils/utils.js'

export type MenuPortalProps = {
  shouldOpen: boolean
  transition?: boolean
  children: ReactNode
}

export const MenuPortal = ({
  shouldOpen = false,
  transition = true,
  children,
}: MenuPortalProps) => {
  const [hidden, setHidden] = useState(true)
  const [invisible, setInvisible] = useState(true)
  const { document } = useDocument()

  const handleTransitionEnd = () => {
    if (!shouldOpen) {
      setHidden(true)
    }
  }

  useEffect(() => {
    if (shouldOpen) {
      setHidden(false)

      requestAnimationFrame(() => {
        setInvisible(false)
      })

      return
    }

    setInvisible(true)
  }, [shouldOpen])

  if (hidden) {
    return null
  }

  return createPortal(
    <div
      className={clsx(
        transition && 'transition-opacity ease-out duration-300',
        invisible ? 'opacity-0' : 'opacity-100',
      )}
      onTransitionEnd={handleTransitionEnd}
    >
      {children}
    </div>,
    getSubMenuPortalRootElement(document),
  )
}
