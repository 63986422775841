import { clsx } from 'clsx'

import type { Member } from '@tribeplatform/gql-client/types'
import type { StaticField } from '@tribeplatform/react-components/Views'

import { Field } from './Field.js'

interface Props {
  member: Member
  fields: StaticField[]
  activeFieldId: string
}
export const MembersMagazineItem = ({
  member,
  fields,
  activeFieldId,
}: Props) => {
  return (
    <div className="flex space-s-2 items-center">
      {fields
        .filter(field => field.id === 'magazineAvatar')
        .map(field => (
          <div
            key={field.id}
            className={clsx(
              fields.filter(field => field.id !== 'magazineAvatar').length >
                2 && 'self-start',
            )}
          >
            <Field
              field={field}
              member={member}
              activeFieldId={activeFieldId}
              viewStyle="magazine"
            />
          </div>
        ))}
      <div className="flex-1 flex flex-wrap gap-2 min-w-0">
        {fields
          .filter(field => field.id !== 'magazineAvatar')
          .map(field => (
            <Field
              key={field.id}
              field={field}
              member={member}
              activeFieldId={activeFieldId}
              viewStyle="magazine"
            />
          ))}
      </div>
    </div>
  )
}
