import { FC } from 'react'

import { clsx } from 'clsx'

import { Alert } from '../Alert/index.js'
import { BackgroundProvider } from '../BackgroundContext/index.js'
import type { IconComponentType } from '../Icon/types.js'
import { ToastProvider, ToastStatus, useToastContext } from './ToastContext.js'

/**
 * Toast panel, dynamically insert this into the live region when it needs to be displayed
 */
export type ToastComponentProps = React.ComponentProps<'div'> & {
  status?: ToastStatus
  onClose: () => void
}

/**
 * Toast panel, dynamically insert this into the live region when it needs to be displayed
 */
export const ToastComponent = ({
  onClose,
  status = 'neutral',
  children,
  className,
  ...rest
}: ToastComponentProps) => {
  return (
    <div
      className={clsx(
        'max-w-sm w-full shadow-popover rounded-base overflow-hidden pointer-events-auto',
        'bg-surface text-content-subdued',
        'focus-visible:ring-1 focus:outline-none',
        className,
      )}
      {...rest}
    >
      <ToastProvider onClose={onClose} status={status}>
        <BackgroundProvider backgroundType="surface">
          {children}
        </BackgroundProvider>
      </ToastProvider>
    </div>
  )
}

export type ToastContentProps = React.ComponentProps<'div'> & {
  title?: string
  description?: string
  withClose?: boolean
  icon?: IconComponentType
}
const ToastContent: FC<ToastContentProps> = props => {
  const { withClose = true, description, children, ...rest } = props

  const { status, onClose } = useToastContext()

  return (
    <Alert status={status} withClose={withClose} onClose={onClose} {...rest}>
      {description}
      {children}
    </Alert>
  )
}

ToastComponent.Content = ToastContent
