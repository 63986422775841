import type {
  AvatarProps,
  LegacyAvatarSize,
} from '@tribeplatform/react-ui-kit/Avatar'
import type { FormControlSelectProps } from '@tribeplatform/react-ui-kit/FormControl'

export enum SelectItemsQueryTypes {
  Search = 'Search',
  ShowMore = 'ShowMore',
}
export type SelectBlockProps = Omit<FormControlSelectProps, 'onChange'> & {
  callbackId?: string
  isSearchable?: boolean
  dataCallbackId?: string
  hideClearIcon?: boolean
  avatarRounded: AvatarProps['rounded']
  avatarSize: LegacyAvatarSize
}
