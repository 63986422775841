import type { EmblaCarouselType } from 'embla-carousel-react'

export const preventEdgeScrolling = (embla: EmblaCarouselType) => {
  const { limit, target, location, scrollTo } = embla.internalEngine()

  return () => {
    if (limit.reachedMax(target.get())) {
      if (limit.reachedMax(location.get())) {
        location.set(limit.max)
      }
      target.set(limit.max)
      scrollTo.distance(0, false)
    }
    if (limit.reachedMin(target.get())) {
      if (limit.reachedMin(location.get())) {
        location.set(limit.min)
      }
      target.set(limit.min)
      scrollTo.distance(0, false)
    }
  }
}

export const setStyleAttributes = (
  element: HTMLElement,
  attrs: { [key: string]: string },
): void => {
  if (element && attrs !== undefined) {
    Object.keys(attrs).forEach((key: string) => {
      element.style.setProperty(key, attrs[key])
    })
  }
}
