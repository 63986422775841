import { clsx } from 'clsx'

import { Button } from '../Button/index.js'
import { SvgIcon } from '../Icon/SvgIcon.js'
import { Modal } from '../Modal/index.js'
import { Text } from '../Text/index.js'

type ConfirmableProps = {
  proceed: (value: boolean) => void
  show: boolean
}

export type CreateAlertOptions = {
  title: string
  description?: string
  proceedLabel?: string
  danger?: boolean
}

export type AlertDialogProps = ConfirmableProps & CreateAlertOptions

/**
 *
 */
export const AlertDialog = ({
  show,
  proceed,
  title,
  description,
  proceedLabel,
  danger = false,
}: AlertDialogProps) => {
  const dismiss = () => proceed(false)

  return (
    <Modal open={show} onClose={dismiss} showCloseBtn={false}>
      <Modal.Content>
        <div className="sm:flex sm:items-start">
          <div
            className={clsx(
              'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10',
              !danger && 'bg-surface-subdued',
              danger && 'bg-surface-negative',
            )}
          >
            <SvgIcon
              size="lg"
              className={clsx(
                !danger && 'text-content-subdued',
                danger && 'text-content-on-negative',
              )}
              name="status-warning"
            />
          </div>
          <div className="mt-3 text-center sm:text-start sm:mt-0 sm:ms-4 min-w-0 break-words flex flex-col gap-3 pt-0 sm:pt-1">
            <Text variant="headingXs" as="h3">
              {title}
            </Text>
            {description && <Text variant="bodyLg">{description}</Text>}
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={dismiss}
          variant="primary"
          size="lg"
          destructive={danger}
        >
          {proceedLabel || 'OK'}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
