import { useCallback } from 'react'

import { useDomain } from '@tribeplatform/react-components/common/hooks'

import { processAuthResponse } from './utils/process-auth-response.js'

export const useLogoutAndRedirect = () => {
  const { getResourceUrl } = useDomain()

  const logout = useCallback(async () => {
    const logoutApiPath = getResourceUrl('/api/auth/logout')

    try {
      await processAuthResponse(logoutApiPath, {})
    } catch (e) {
      console.warn(`Could not logout the user - ${e}`)
    }
  }, [getResourceUrl])

  return {
    logout,
  }
}
