import { useUniqueId } from '../utils/unique-id.js'
import { CalendarProps } from './types.js'
import { useDatePicker } from './useDatePicker.js'
import { startOfDay } from './utils.js'

/**
 * Calendar displays a grid of days in one or more months and allow users to select a single date.
 */
export const Calendar = ({ value, onChange, ...rest }: CalendarProps) => {
  const prefix = useUniqueId('Calendar')

  const handleOnChange = dates => {
    if (!Array.isArray(dates) || dates.length !== 1) {
      onChange?.(undefined)
      return
    }

    onChange?.(startOfDay(dates[0]))
  }

  const { startInputField } = useDatePicker({
    value,
    onChange: handleOnChange,
    mode: 'single',
    prefix,
    inline: true,
    showMonths: 1,
    ...rest,
  })

  return <input type="hidden" className="hidden" ref={startInputField} />
}
