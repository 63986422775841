import { RefObject } from 'react'

export const nodeContainsDescendant = (
  rootNode: HTMLElement,
  descendant: HTMLElement,
): boolean => {
  if (rootNode === descendant) {
    return true
  }

  let parent = descendant.parentNode

  while (parent != null) {
    if (parent === rootNode) {
      return true
    }
    parent = parent.parentNode
  }

  return false
}

export const wasContentNodeDescendant = (
  composedPath: readonly EventTarget[],
  contentNode: RefObject<HTMLDivElement>,
) => contentNode.current != null && composedPath.includes(contentNode.current)
