import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Fields,
  ModerationItem,
  MutationCreateModerationReportArgs,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import {
  getModerationItemsKey,
  getPostModerationItemsKey,
} from '../../utils/keys/moderation.key.js'

export const useCreateModerationReport = (options?: {
  fields?: Fields.ModerationItemFields
  useMutationOptions?: UseMutationOptions<
    ModerationItem,
    ClientError,
    MutationCreateModerationReportArgs
  >
}) => {
  const { useMutationOptions, fields = 'basic' } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()
  const moderationItemsKey = getModerationItemsKey()
  const postModerationItemsKey = getPostModerationItemsKey()

  return useMutation<
    ModerationItem,
    ClientError,
    MutationCreateModerationReportArgs
  >(
    variables =>
      client.mutation({
        name: 'createModerationReport',
        args: {
          variables,
          fields,
        },
      }),
    {
      onSettled: () => {
        // invalidate to refetch again
        queryClient.invalidateQueries(moderationItemsKey)
        queryClient.invalidateQueries(postModerationItemsKey)
      },
      ...useMutationOptions,
    },
  )
}
