import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { CollectionListBlock as Component } from './Block.js'

export const RegisteredCollectionListBlock = (
  _intl: I18nShape,
): RegisteredBlock<Record<string, never>> => ({
  recommendedName: 'CollectionList',
  Component,
  config: {
    type: BlockType.Block,
    deprecated: true,
  },
})
