import { clsx } from 'clsx'

import { useRouter } from '@tribeplatform/react-sdk'
import { Carousel } from '@tribeplatform/react-ui-kit/Carousel'
import { SectionHeader } from '@tribeplatform/react-ui-kit/SectionHeader'

import { BC } from '../../types/index.js'
import { CallToActionBlock } from '../CallToAction/Block.js'
import { QuickLinksProps } from './types.js'

const colsToWidth = {
  2: '260px',
  3: '170px',
  4: '120px',
  5: '95px',
}

const GridList = ({ children, columns = 3, className = '' }) => {
  const columnsClsx = {
    'web:grid-cols-1 sm:grid-cols-2 lg:grid-cols-2': columns === 2,
    'web:grid-cols-2 sm:grid-cols-3 lg:grid-cols-3': columns === 3,
    'web:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4': columns === 4,
    'web:grid-cols-3 web:lg:grid-cols-5 web:xl:grid-cols-5': columns === 5,
  }

  return (
    <div className={clsx('grid gap-4', columnsClsx, className)}>{children}</div>
  )
}

export const QuickLinksBlock: BC<QuickLinksProps> = ({
  alignment,
  actionType,
  horizontalScroll,
  gridCols,
  title,
  items,
  className,
  ...rest
}) => {
  const { Link } = useRouter()
  if (!items) {
    return null
  }

  const cards = items.map((item, idx) => {
    const style = horizontalScroll
      ? {
          minWidth: colsToWidth[gridCols],
          maxWidth: colsToWidth[gridCols],
        }
      : {}

    if (actionType === 'clickable-card') {
      return (
        <Link
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          href={item.action.href}
          target={item.action.openInNewWindow ? '_blank' : undefined}
          className={clsx('flex', horizontalScroll && 'min-w-40', className)}
          style={style}
          {...rest}
        >
          <CallToActionBlock
            alignment={alignment}
            {...item}
            className={clsx(
              'hover:shadow-card-hovered transition-all ease-in-out duration-200 flex-1',
              horizontalScroll && 'rounded-lg',
            )}
            action={{
              href: item.action.href,
              type: 'link',
              text: undefined,
            }}
          />
        </Link>
      )
    }
    return (
      <CallToActionBlock
        alignment={alignment}
        {...item}
        action={{
          ...item.action,
          type: actionType,
        }}
        // eslint-disable-next-line react/no-array-index-key
        key={idx}
        className={clsx(horizontalScroll && 'min-w-40 rounded-lg', className)}
        style={style}
        {...rest}
      />
    )
  })

  return (
    <div>
      {title && <SectionHeader title={title ?? ''} className="pb-5" />}
      {horizontalScroll ? (
        <Carousel className="pt-1 pb-4" items={cards} />
      ) : (
        <GridList columns={gridCols}>{cards}</GridList>
      )}
    </div>
  )
}
