import { FC } from 'react'

import { useSpace } from '@tribeplatform/react-sdk/hooks'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { Time } from '../../common/components/Time.js'
import { dayjs } from '../../common/lib/dayjs.js'
import { T } from '../../i18n/components/T.js'
import { SpaceAboutError } from './SpaceAboutError.js'
import { SpaceAboutProps } from './types.js'

export const SpaceAbout: FC<SpaceAboutProps> = ({
  space: _space,
  spaceId: _spaceId,
  title,
  showMembersCount,
  showPostsCount,
  showCreationDate,
  ...rest
}) => {
  const spaceId = _spaceId || _space?.id
  const { data: space } = useSpace({
    variables: { id: spaceId || _space?.id },
    useQueryOptions: {
      enabled: !!spaceId,
    },
  })

  if (!space) {
    return <SpaceAboutError />
  }

  return (
    <Card {...rest}>
      {title && <Card.Header title={title} size="sm" />}
      <Card.Content>
        <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-1">
          {space?.description ? (
            <div className="-mt-2 text-sm text-content">
              {space?.description}
            </div>
          ) : null}
          {showMembersCount && space?.membersCount ? (
            <div className="text-sm space-s-2 flex items-center">
              <SvgIcon name="members" />
              <span>
                <T
                  id="Space.SpaceAbout.MemberCount"
                  defaultMessage="{member_count} {member_count, plural, =1 {{MEMBER}} other {{MEMBERS}}}"
                  values={{ member_count: space?.membersCount }}
                />
              </span>
            </div>
          ) : null}
          {showPostsCount && space?.postsCount ? (
            <div className="text-sm space-s-2 flex items-center">
              <SvgIcon name="post" />
              <span>
                <T
                  id="Space.SpaceAbout.PostCount"
                  defaultMessage="{post_count} {post_count, plural, =1 {post} other {posts}}"
                  values={{ post_count: space?.postsCount }}
                />
              </span>
            </div>
          ) : null}
          {showCreationDate && (
            <div className="text-sm space-s-2 flex items-center">
              <SvgIcon name="calendar" />
              <span>
                <T id="Generics.Created" defaultMessage="Created" />{' '}
                <Time dateTime={space.createdAt}>
                  {dayjs(space.createdAt).fromNow()}
                </Time>
              </span>
            </div>
          )}
        </div>
      </Card.Content>
    </Card>
  )
}
