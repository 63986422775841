import { ReactNode } from 'react'

import { clsx } from 'clsx'

import { useBackgroundContext } from '../BackgroundContext/index.js'
import { Text } from '../Text/Text.js'

export type SectionHeaderProps = {
  title: string | ReactNode
  description?: string | ReactNode
  action?: ReactNode
  className?: string
  size?: 'sm' | 'md'
}

/**
 *  Section headers provide content and actions related to the current section on a screen.
 */
export const SectionHeader = ({
  title,
  description,
  action,
  className,
  size = 'md',
}: SectionHeaderProps) => {
  const { backgroundType } = useBackgroundContext()
  const onBackground = backgroundType === 'main'

  return (
    <div className={clsx('min-w-0 break-words px-4 sm:px-0', className)}>
      <div className="sm:flex sm:justify-between">
        <div className="sm:w-0 sm:flex-1 space-y-2">
          <Text
            as="h3"
            variant={size === 'md' ? 'headingXs' : 'bodyMd'}
            fontWeight={size === 'sm' ? 'semibold' : 'regular'}
            onBackground={onBackground}
          >
            {title}
          </Text>
          {description && (
            <Text
              as="div"
              variant="bodySm"
              color="subdued"
              onBackground={onBackground}
            >
              {description}
            </Text>
          )}
        </div>

        {action && (
          <div className="mt-3 flex sm:mt-0 sm:ms-4 space-s-3">{action}</div>
        )}
      </div>
    </div>
  )
}
