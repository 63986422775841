import { clsx } from 'clsx'

import {
  Avatar,
  AvatarRounded,
  legacySizeMapper,
} from '@tribeplatform/react-ui-kit/Avatar'

import { BC } from '../../types/index.js'
import { ImageBlockProps } from './types.js'

export type ImageRounded = 'circle' | 'squared' | 'rounded'

const roundedMap: Record<ImageRounded, AvatarRounded> = {
  circle: 'avatar',
  squared: 'none',
  rounded: 'base',
}
export const roundedMapper = (size: ImageBlockProps['shape']) =>
  roundedMap[size]

export const ImageBlock: BC<ImageBlockProps> = ({
  url,
  size = 'md',
  shape = 'circle',
  className,
  ...rest
}) => {
  if (!url) {
    return null
  }

  return (
    <Avatar
      size={legacySizeMapper(size)}
      rounded={roundedMapper(shape)}
      src={url}
      className={clsx('flex', className)}
      {...rest}
    />
  )
}
