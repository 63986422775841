import { Checkbox } from '../Checkbox/Checkbox.js'
import { SimpleDivider } from '../Divider/Divider.js'
import { SvgIcon } from '../Icon/SvgIcon.js'
import { Input } from '../Input/Input.js'
import { LoadingOverlay } from '../LoadingOverlay/LoadingOverlay.js'

interface InlineMultiselectProps {
  className?: string
  children: React.ReactNode
}
export const InlineMultiselect = ({
  className,
  children,
}: InlineMultiselectProps) => {
  return <div className={className}>{children}</div>
}

interface ItemsProps {
  isLoading?: boolean
  children: React.ReactNode
}
const Items = ({ isLoading, children }: ItemsProps) => {
  return (
    <LoadingOverlay isLoading={isLoading}>
      <div className="max-h-[13.8rem] overflow-y-auto mt-4 space-y-1">
        {children}
      </div>
    </LoadingOverlay>
  )
}

interface ItemProps {
  selected: boolean
  children: React.ReactNode
  onSelect: () => void
}
const Item = ({ selected, children, onSelect }: ItemProps) => {
  return (
    <div
      className="flex items-center gap-x-2 p-2 rounded-base cursor-pointer hover:bg-surface-hovered h-10"
      onClick={() => onSelect()}
    >
      <div className="shrink-0">
        <Checkbox
          checked={selected}
          onChange={() => {
            // no operations
          }}
          className="block"
          size="md"
          noLabel
        />
      </div>
      <div className="flex-1 min-w-0">{children}</div>
    </div>
  )
}

interface SearchBarProps {
  value: string
  placeHolder?: string
  onChange: (value: string) => void
}
const SearchBar = ({ value, placeHolder = '', onChange }: SearchBarProps) => {
  return (
    <>
      <div className="relative">
        <Input
          leadingIcon={<SvgIcon name="search" />}
          value={value}
          onChange={e => onChange(e.target.value)}
          name="search"
          placeholder={placeHolder}
          className="pr-7"
        />
        {!!value && (
          <div className="h-full flex items-center absolute top-0 right-1 rtl:left-0 p-1">
            <button type="button" onClick={() => onChange('')}>
              <SvgIcon className="w-5 h-5" name="close" />
            </button>
          </div>
        )}
      </div>
      <SimpleDivider variant="subdued" className="mt-5" />
    </>
  )
}

InlineMultiselect.SearchBar = SearchBar
InlineMultiselect.Items = Items
InlineMultiselect.Item = Item
