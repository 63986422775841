import type { CSSProperties } from 'react'

import { noop } from '@tribeplatform/react-ui-kit/hooks'

import { SearchSource, TextAlignment, TextColor } from '../types.js'

export const getTintStyle = (
  tint: number,
  textColor: TextColor,
): CSSProperties => {
  const tintInRange = Math.min(tint / 100, 1)
  if (textColor === TextColor.dark) {
    return { backgroundColor: `rgba(255,255,255,${tintInRange})` }
  }

  return { backgroundColor: `rgba(0,0,0,${tintInRange})` }
}

export const getAlignmentClass = (alignment: TextAlignment) => {
  switch (alignment) {
    case TextAlignment.center:
      return 'text-center items-center'
    case TextAlignment.start:
      return 'text-start items-start'
    case TextAlignment.end:
      return 'text-end items-end'
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = alignment
      noop(exhaustiveCheck)
      return ''
    }
  }
}

export const getTextColorClass = (textColor: TextColor) => {
  if (textColor === TextColor.default) {
    return 'text-content'
  }

  if (textColor === TextColor.dark) {
    return 'text-black'
  }

  return 'text-white'
}

export const getContentSpacingClass = (contentSpacing: number): string => {
  switch (contentSpacing) {
    case 1:
      return 'py-[1.5rem] @3xl:py-[2rem] @5xl:py-[2rem]'
    case 2:
      return 'py-[2rem] @3xl:py-[2rem] @5xl:py-[4rem]'
    case 3:
      return 'py-[2.5rem] @3xl:py-[3rem] @5xl:py-[6rem]'
    case 4:
      return 'py-[3rem] @3xl:py-[4rem] @5xl:py-[8rem]'
    case 5:
      return 'py-[3.5rem] @3xl:py-[5rem] @5xl:py-[10rem]'
    case 6:
      return 'py-[4rem] @3xl:py-[6rem] @5xl:py-[12rem]'
    case 7:
      return 'py-[4.5rem] @3xl:py-[7rem] @5xl:py-[14rem]'
    case 8:
      return 'py-[5rem] @3xl:py-[8rem] @5xl:py-[16rem]'
    case 9:
      return 'py-[5.5rem] @3xl:py-[9rem] @5xl:py-[18rem]'
    case 10:
      return 'py-[6rem] @3xl:py-[10rem] @5xl:py-[20rem]'
    default:
      return 'py-[6.5rem] @3xl:py-[2rem] @5xl:py-[2rem]'
  }
}

export const getMediaAspectRatioClass = (ratioDivider: number) => {
  switch (ratioDivider) {
    case 1:
      return 'aspect-[16/1]'
    case 2:
      return 'aspect-[16/2]'
    case 3:
      return 'aspect-[16/3]'
    case 4:
      return 'aspect-[16/4]'
    case 5:
      return 'aspect-[16/5]'
    case 6:
      return 'aspect-[16/6]'
    case 7:
      return 'aspect-[16/7]'
    case 8:
      return 'aspect-[16/8]'
    case 9:
      return 'aspect-[16/9]'
    case 10:
      return 'aspect-[16/10]'
    default:
      return 'aspect-video'
  }
}

export const getSpaceIdsToSearch = (
  searchSource: SearchSource,
  spaceIds: string[],
  currentSpaceId,
): string[] => {
  switch (searchSource) {
    case SearchSource.allSpaces:
      return []
    case SearchSource.currentSpace:
      return [currentSpaceId]
    case SearchSource.specificSpaces:
      return spaceIds
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = searchSource
      noop(exhaustiveCheck)
      return []
    }
  }
}
