import { Suspense, lazy, useState } from 'react'

import { SystemSpaceSlug } from '@bettermode/common/space'
import { T } from '@tribeplatform/react-components/i18n'
import {
  NotificationDropdownLoading,
  NotificationsMarkAsRead,
  NotificationSettingsLink,
} from '@tribeplatform/react-components/Notification'
import { useRouter } from '@tribeplatform/react-sdk'
import {
  useNotificationsCount,
  useSpaceUtil,
} from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import {
  IndicatorBadge,
  IndicatorBadgeWrapper,
} from '@tribeplatform/react-ui-kit/IndicatorBadge'
import { Popover } from '@tribeplatform/react-ui-kit/Popover'
import { TopBarButton } from '@tribeplatform/react-ui-kit/TopBar'

const NOTIFICATION_COUNT_REFETCH_INTERVAL = 12000 // ms

const NotificationDropdownList = lazy(() =>
  import('@tribeplatform/react-components/Notification').then(m => ({
    default: m.NotificationDropdownList,
  })),
)

interface NotificationsButtonProps {
  label: string
}
export const NotificationsButton = ({ label }: NotificationsButtonProps) => {
  const { Link } = useRouter()
  const [popoverActive, setPopoverActive] = useState(false)
  const { generatePath } = useSpaceUtil()
  const { data: notificationCount = 0 } = useNotificationsCount({
    useQueryOptions: {
      refetchInterval: NOTIFICATION_COUNT_REFETCH_INTERVAL,
    },
  })

  return (
    <div className="h-full flex justify-center items-center shrink-0">
      <Popover
        placement="bottom-end"
        open={popoverActive}
        onClose={() => setPopoverActive(false)}
        activator={
          <IndicatorBadgeWrapper
            indicatorBadge={
              <IndicatorBadge count={notificationCount} size="sm" />
            }
          >
            <TopBarButton
              onClick={() => setPopoverActive(prev => !prev)}
              icon={<SvgIcon name="bell" />}
              aria-label={label}
            />
          </IndicatorBadgeWrapper>
        }
        fluidContent={false}
        fixed
      >
        <div className="flex flex-col">
          <div className="border-b border-line-subdued font-semibold px-3 py-2 flex items-center gap-1">
            <Link
              className="flex-grow text-content-subdued hover:text-content-hovered"
              href={generatePath(SystemSpaceSlug.NOTIFICATIONS)}
              onClick={() => setPopoverActive(false)}
            >
              <T defaultMessage="Notifications" id="Generics.Notifications" />
            </Link>
            <NotificationsMarkAsRead />
            <NotificationSettingsLink onClick={() => setPopoverActive(false)} />
          </div>
          <div className="grow">
            <Suspense fallback={<NotificationDropdownLoading />}>
              <NotificationDropdownList
                closeDropdown={() => setPopoverActive(false)}
              />
            </Suspense>
          </div>
        </div>
      </Popover>
    </div>
  )
}
