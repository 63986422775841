export const NotificationDropdownLoading = () => {
  return (
    <div className="grid grid-cols-1 gap-1 ">
      {[...Array(5)].map((_e, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="animate-pulse flex space-s-3 p-3" key={i}>
          <div className="rounded-full h-6 w-6 bg-skeleton " />
          <div className="flex-1 space-y-1">
            <div className="flex items-center justify-between mb-3">
              <div className="h-4 bg-skeleton rounded-full w-5/6" />
            </div>
            <div className="text-sm text-content-subdued">
              <div className="h-4 bg-skeleton rounded-full" />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
