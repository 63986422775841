import type { Member } from '@tribeplatform/gql-client/types'
import { useNavigate } from '@tribeplatform/react-components/Link'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { Card } from '@tribeplatform/react-ui-kit/Card'

import { MembersMagazineItem } from './MembersMagazineItem.js'

interface Props {
  members: Member[]
  fields: StaticField[]
  activeFieldId?: string
  showMoreButton: JSX.Element
}
export const MembersMagazine = ({
  members,
  fields,
  activeFieldId,
  showMoreButton,
}: Props) => {
  const navigate = useNavigate()

  return (
    <div className="space-y-4">
      {members.map(member => (
        <Card
          onClick={() => navigate(member?.relativeUrl)}
          className="cursor-pointer"
          padding="md"
          key={member.id}
        >
          <Card.Content>
            <MembersMagazineItem
              member={member}
              fields={fields.filter(
                field =>
                  field.enabled &&
                  !(field.styles && field.styles.indexOf('magazine') === -1),
              )}
              activeFieldId={activeFieldId}
            />
          </Card.Content>
        </Card>
      ))}
      {showMoreButton}
    </div>
  )
}
