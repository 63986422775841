import { I18nShape } from '@tribeplatform/react-components/i18n'
import { type SvgIconName } from '@tribeplatform/react-ui-kit/Icon'

import { MainMenuAction, MainMenuIcon, MainMenuItem } from './types.js'

export const mainMenuIconNames: Record<MainMenuIcon, SvgIconName> = {
  [MainMenuIcon.BellIcon]: 'bell',
  [MainMenuIcon.HomeIcon]: 'home',
  [MainMenuIcon.NewspaperIcon]: 'posts',
  [MainMenuIcon.SearchIcon]: 'search',
  [MainMenuIcon.UserCircleIcon]: 'member',
  [MainMenuIcon.UserGroupIcon]: 'members',
  [MainMenuIcon.ViewGridIcon]: 'grid',
}

export const mainMenuDefaultItemsFactory = (
  intl: I18nShape,
): MainMenuItem[] => {
  const { $t } = intl

  return [
    {
      enabled: true,
      label: $t({ id: 'Navigation.Home', defaultMessage: 'Home' }),
      action: MainMenuAction.OPEN_SPACE,
      icon: MainMenuIcon.HomeIcon,
      spaceSlug: 'default::home',
      link: null,
    },
    {
      enabled: true,
      label: $t({ id: 'Navigation.Members', defaultMessage: '{MEMBERS_CC}' }),
      action: MainMenuAction.OPEN_SPACE,
      icon: MainMenuIcon.UserGroupIcon,
      spaceSlug: 'default::members',
      link: null,
    },
    {
      enabled: true,
      label: $t({ id: 'Navigation.Spaces', defaultMessage: '{SPACES_CC}' }),
      action: MainMenuAction.OPEN_SPACE,
      icon: MainMenuIcon.ViewGridIcon,
      spaceSlug: 'default::spaces',
      link: null,
    },
    {
      enabled: false,
      label: $t({ id: 'Navigation.Profile', defaultMessage: 'Profile' }),
      action: MainMenuAction.OPEN_PROFILE,
      icon: MainMenuIcon.UserCircleIcon,
      spaceSlug: null,
      link: null,
    },
  ]
}
