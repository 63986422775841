import React from 'react'

import { fileSize } from '@bettermode/common/filesize'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

import { T } from '../i18n/components/T.js'

export interface ImageSizeHelperProps {
  recommendedWidth?: number
  recommendedHeight?: number
  showSizeLimit?: boolean
}

export const ImageSizeHelper = ({
  recommendedWidth,
  recommendedHeight,
  showSizeLimit = true,
}: ImageSizeHelperProps) => {
  const { data: network } = useNetwork()

  return (
    <>
      {recommendedWidth > 0 && recommendedHeight > 0 && (
        <p>
          <T
            defaultMessage="Recommended size is {width, number} x {height, number} pixels."
            id="Generics.RecommendedImageSize"
            values={{
              width: recommendedWidth,
              height: recommendedHeight,
            }}
          />
        </p>
      )}
      {showSizeLimit && (
        <p>
          <T
            id="Generics.FileSizeLimit"
            defaultMessage="The file size limit is {size_limit}."
            values={{
              size_limit: fileSize(network?.settings?.imageSizeLimit || 10000),
            }}
          />
        </p>
      )}
    </>
  )
}
