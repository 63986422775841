import {
  ComponentProps,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { clsx } from 'clsx'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'

import { CarouselArrow } from './CarouselArrow.js'
import { useEmblaCarousel } from './useEmblaCarousel.js'
import { preventEdgeScrolling, setStyleAttributes } from './utils.js'

const wheelGesturesPluginInstance = WheelGesturesPlugin()

export declare type CarouselItemType = ReactElement<{
  /**
    Required. id for every item, should be unique
   */
  itemId: string
}>

type CarouselProps = ComponentProps<'div'> & {
  items?: CarouselItemType[]
  slidesToScroll?: number | 'auto'
}

/** A slideshow component for cycling through elements */
export const Carousel = ({
  className,
  items = [],
  slidesToScroll = 'auto',
}: CarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      slidesToScroll,
      containScroll: 'keepSnaps',
      inViewThreshold: 0.5,
    },
    [wheelGesturesPluginInstance],
  )

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const scrollPrev = useCallback(() => {
    if (!emblaApi) {
      return
    }
    emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (!emblaApi) {
      return
    }
    emblaApi.scrollNext()
  }, [emblaApi])

  useEffect(() => {
    if (!emblaApi) {
      return
    }
    const listener = preventEdgeScrolling(emblaApi)
    emblaApi.on('scroll', listener)
    return () => {
      emblaApi.off('scroll', listener)
    }
  }, [emblaApi])

  const filteredItems = items.filter(it => !!it)
  const lastSlideIndex = filteredItems.length - 1

  // This is a hack to make sure card shadows and borders are not cut off on initial render and while scrolling
  const updateRootNodeStyle = useCallback(() => {
    if (!emblaApi) {
      return
    }

    if (window.innerWidth < 640) {
      return
    }

    const rootNode = emblaApi.rootNode()
    const slidesInView = emblaApi.slidesInView(true)
    const firstSlideInView = slidesInView.includes(0)
    const lastSlideInView = slidesInView.includes(lastSlideIndex)

    setStyleAttributes(rootNode, {
      'margin-inline-start': firstSlideInView ? '-12px' : '-1px',
      'padding-inline-start': firstSlideInView ? '12px' : '1px',
      'margin-inline-end': lastSlideInView ? '-12px' : '-1px',
      'padding-inline-end': lastSlideInView ? '12px' : '1px',
    })
  }, [emblaApi, lastSlideIndex])

  const onSelect = useCallback(() => {
    if (!emblaApi) {
      return
    }
    setPrevBtnEnabled(emblaApi.canScrollPrev())
    setNextBtnEnabled(emblaApi.canScrollNext())
    updateRootNodeStyle()
  }, [emblaApi, updateRootNodeStyle])

  useEffect(() => {
    if (!emblaApi) {
      return
    }
    emblaApi.on('select', onSelect)
    onSelect()

    return () => {
      emblaApi.off('select', onSelect)
    }
  }, [emblaApi, onSelect])

  return (
    <div className="relative">
      <div ref={emblaRef} className="overflow-hidden">
        <div
          className={clsx(
            'flex items-stretch snap-x h-auto space-s-2',
            className,
          )}
        >
          {filteredItems.map(item => (
            <div
              className="snap-start grow-0 shrink-0 min-w-0 flex"
              key={item?.key}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <CarouselArrow
        onClick={scrollPrev}
        disabled={!prevBtnEnabled}
        direction="left"
        label="Previous"
      />
      <CarouselArrow
        onClick={scrollNext}
        disabled={!nextBtnEnabled}
        direction="right"
        label="Next"
      />
    </div>
  )
}
