import { noop } from '@tribeplatform/react-ui-kit/hooks'

import { FullLayout } from '../Layouts/FullLayout.js'
import { LeftRightLayout } from '../Layouts/LeftRightLayout.js'
import { TopBottomLayout } from '../Layouts/TopBottomLayout.js'
import { HeroBannerLayout, type HeroBannerProps } from '../types.js'

export const HeroBanner = (props: HeroBannerProps) => {
  const { layout } = props

  switch (layout) {
    case HeroBannerLayout.imageFull:
      return <FullLayout {...props} />
    case HeroBannerLayout.imageRight:
      return <LeftRightLayout {...props} />
    case HeroBannerLayout.imageLeft:
      return <LeftRightLayout reverse {...props} />
    case HeroBannerLayout.imageTop:
      return <TopBottomLayout {...props} />
    case HeroBannerLayout.imageBottom:
      return <TopBottomLayout reverse {...props} />
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = layout
      noop(exhaustiveCheck)
      return null
    }
  }
}
