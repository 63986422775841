import { DateValue } from './types.js'

export const updateClassNames = (calendar, prefix) => {
  const { calendarContainer } = calendar
  const daysContainer = calendar.days
  if (calendarContainer && daysContainer) {
    // calendarContainer and daysContainer are undefined if flatpickr detects a mobile device
    calendarContainer.classList.add(`${prefix}--date-picker__calendar`)
    calendarContainer
      .querySelector('.flatpickr-month')
      .classList.add(`${prefix}--date-picker__month`)
    calendarContainer
      .querySelector('.flatpickr-weekdays')
      .classList.add(`${prefix}--date-picker__weekdays`)
    calendarContainer
      .querySelector('.flatpickr-days')
      .classList.add(`${prefix}--date-picker__days`)
    calendarContainer.querySelectorAll('.flatpickr-weekday').forEach(item => {
      const currentItem = item
      currentItem.innerHTML = currentItem.innerHTML.replace(/\s+/g, '')
      currentItem.classList.add(`${prefix}--date-picker__weekday`)
    })
    daysContainer.querySelectorAll('.flatpickr-day').forEach(item => {
      item.classList.add(`${prefix}--date-picker__day`)
      if (
        item.classList.contains('today') &&
        calendar.selectedDates.length > 0
      ) {
        item.classList.add('no-border')
      } else if (
        item.classList.contains('today') &&
        calendar.selectedDates.length === 0
      ) {
        item.classList.remove('no-border')
      }
    })
  }
}

export const startOfDay = (date: DateValue): DateValue => {
  if (!date) {
    return undefined
  }

  return new Date(new Date(date).setHours(0, 0, 0, 0))
}

export const cleanDate = (date: DateValue) => {
  return new Date(new Date(date).setSeconds(0, 0))
}

export const cleanDateRange = (
  dates: [DateValue, DateValue],
): [DateValue, DateValue] => {
  if (!Array.isArray(dates) || dates.length !== 2) {
    return undefined
  }

  return [startOfDay(dates[0]), startOfDay(dates[1])]
}
