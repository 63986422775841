import type { ClientError } from '@tribeplatform/gql-client/lib'
import {
  Action,
  MutationRemoveFcmTokenFromSubscriberSettingsArgs,
  Subscriber,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSubscriberSettingsKey } from '../../utils/keys/notification.key.js'

export const useRemoveFcmTokenFromSubscriberSettings = (options?: {
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationRemoveFcmTokenFromSubscriberSettingsArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<
    Action,
    ClientError,
    MutationRemoveFcmTokenFromSubscriberSettingsArgs
  >(
    variables =>
      client.mutation({
        name: 'removeFcmTokenFromSubscriberSettings',
        args: { fields: 'all', variables },
      }),
    {
      onMutate: async ({ token }) => {
        const subscriberKey = getSubscriberSettingsKey()
        await queryClient.cancelQueries(subscriberKey)

        const subscriberUpdater = (old: Subscriber): Subscriber =>
          old
            ? {
                ...old,
                fcmDeviceTokens: old.fcmDeviceTokens.filter(t => t !== token),
              }
            : old
        queryClient.setQueriesData<Subscriber>(subscriberKey, subscriberUpdater)

        return { queryKey: subscriberKey }
      },
      onSettled: async () => {
        const subscriberKey = getSubscriberSettingsKey()
        queryClient.invalidateQueries(subscriberKey)
      },
      ...useMutationOptions,
    },
  )
}
