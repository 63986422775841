import { noop } from '@tribeplatform/react-ui-kit/hooks'

import {
  MobileMenuSectionItem,
  type MenuSection,
  type MobileMenuSection,
  type NavbarProps,
} from '../../types.js'
import { MobileMenuFromHeaderMenu } from './MobileMenuFromHeaderMenu.js'
import { MobileMenuFromSidebar } from './MobileMenuFromSidebar.js'

interface Props {
  section: MobileMenuSection
  headerMenu: MenuSection
  headerSubmenu: NavbarProps['subNavigation']
  close: () => void
}
export const MobileMenuSectionRenderer = ({
  section,
  headerMenu,
  headerSubmenu,
  close,
}: Props) => {
  const { id } = section

  switch (id) {
    case MobileMenuSectionItem.headerMenu:
      return (
        <MobileMenuFromHeaderMenu menuItems={headerMenu.items} close={close} />
      )
    case MobileMenuSectionItem.headerSubMenu:
      return (
        <MobileMenuFromHeaderMenu
          menuItems={headerSubmenu.menuItems}
          close={close}
        />
      )
    case MobileMenuSectionItem.sidebar:
      return <MobileMenuFromSidebar close={close} />
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = id
      noop(exhaustiveCheck)
      return null
    }
  }
}
