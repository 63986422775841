import { ReactNode, FC, ComponentProps } from 'react'

import { clsx } from 'clsx'

import { Icon } from '../Icon/index.js'
import type { IconComponentType } from '../Icon/types.js'
import { Text } from '../Text/index.js'

export type DashedProps = React.ComponentProps<'button'> & {
  icon: IconComponentType
}
const Dashed: FC<DashedProps> = props => {
  const { icon, children, className, ...rest } = props
  return (
    <button
      type="button"
      className={clsx(
        'relative block w-full border-2 border-line-subdued border-dashed rounded-lg p-12 text-center hover:border-line',
        'focus:outline-none focus-visible:ring',
        className,
      )}
      {...rest}
    >
      <Icon className="mx-auto h-12 w-12 text-content-subdued">{icon}</Icon>
      <span className="mt-2 block text-content">{children}</span>
    </button>
  )
}

export interface EmptyStateProps extends Omit<ComponentProps<'div'>, 'title'> {
  icon?: IconComponentType
  title: string | ReactNode
  description?: string | ReactNode
}
/**
 * Empty states occur when an item’s content can’t be shown.
 */
export const EmptyState = ({
  title,
  description,
  icon,
  children,
  className,
}: EmptyStateProps) => {
  return (
    <div className={clsx('text-center', className)}>
      <Icon className="mx-auto h-12 w-12 text-content-subdued">{icon}</Icon>
      <Text as="h3" variant="headingSm" className="mt-2">
        {title}
      </Text>
      {description && (
        <p className="mt-1 text-content-subdued">{description}</p>
      )}
      {children && <div className="mt-6">{children}</div>}
    </div>
  )
}

EmptyState.Dashed = Dashed
