import { DocumentNode } from 'graphql'
import { gql } from 'graphql-tag'

import { TemplateFields, templateGQLFields } from './template.fields.js'

export function getTemplatesGQLQuery(fields: TemplateFields): DocumentNode {
  return gql`
    query templates(
      $after: String
      $before: String
      $limit: Int!
      $offset: Int
      $reverse: Boolean
      $entityTypes: [TemplateEntityType!]
      $status: StoreItemStatus
    ) {
      templates(
        after: $after
        before: $before
        limit: $limit
        offset: $offset
        reverse: $reverse
        entityTypes: $entityTypes
        status: $status
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            ${templateGQLFields(fields)}
          }
        }
        nodes {
          ${templateGQLFields(fields)}
        }
      }
    }
  `
}
