import { clsx } from 'clsx'

import { Member } from '@tribeplatform/gql-client/types'
import { MemberAvatarPlaceholder } from '@tribeplatform/react-components/common/components/member'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { Avatar, legacySizeMapper } from '@tribeplatform/react-ui-kit/Avatar'

interface Props {
  field: StaticField
  member: Member
  highlighted: boolean
  mediaUrl: string
}
export const ListAvatar = ({ field, member, highlighted, mediaUrl }: Props) => {
  return (
    <div className="shrink-0 px-2">
      <Avatar
        size={legacySizeMapper(field.settings?.avatarSize ?? 'lg')}
        src={mediaUrl}
        name={member?.name}
        className={clsx(
          highlighted && 'ring-2 ring-offset-surface ring-focused',
        )}
        fallback={MemberAvatarPlaceholder}
      />
    </div>
  )
}
