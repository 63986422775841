import { ReactNode } from 'react'

import { Controller, useFormContext } from 'react-hook-form'

import {
  FormControl,
  FormControlSelectProps,
} from '@tribeplatform/react-ui-kit/FormControl'
import { Select as SelectComponent } from '@tribeplatform/react-ui-kit/Select'

import { FormValidationProps } from './typings.js'

export type SelectProps = Omit<
  FormControlSelectProps,
  'value' | 'onChange' | 'children'
> &
  FormValidationProps & {
    value?: unknown
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: (newValue: any) => void
    children?: ReactNode
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    renderButton?: (value: any) => ReactNode
  }

export function Select({
  renderButton,
  children,
  name,
  validation,
  value: fieldValue,
  onChange: selectOnChange,
  ...rest
}: SelectProps) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl.Select
          {...rest}
          name={name}
          invalid={!!error?.message}
          error={error?.message}
          value={fieldValue ?? value}
          onChange={value => {
            onChange(value)
            if (typeof selectOnChange === 'function') {
              selectOnChange(value)
            }
          }}
        >
          <SelectComponent.Button>
            {typeof renderButton === 'function' ? renderButton(value) : value}
          </SelectComponent.Button>
          {children}
        </FormControl.Select>
      )}
    />
  )
}
