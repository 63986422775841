export const font = {
  'family-mono':
    "ui-monospace, SFMono-Regular, 'SF Mono', Consolas, 'Liberation Mono', Menlo, monospace",
  'size-xs': '12px',
  'size-sm': '14px',
  'size-md': '16px',
  'size-lg': '18px',
  'size-xl': '20px',
  'size-h-3xs': '16px',
  'size-h-2xs': '18px',
  'size-h-xs': '22px',
  'size-h-sm': '24px',
  'size-h-md': '28px',
  'size-h-lg': '32px',
  'size-h-xl': '36px',
  'size-h-2xl': '40px',
  'letter-spacing-heading': '-0.01em',
  'letter-spacing-body': '-0.01em',
  'letter-spacing-label': '-0.025em',
  'line-height-xs': '18px',
  'line-height-sm': '20px',
  'line-height-md': '24px',
  'line-height-lg': '28px',
  'line-height-xl': '30px',
  'line-height-h-3xs': '26px',
  'line-height-h-2xs': '28px',
  'line-height-h-xs': '30px',
  'line-height-h-sm': '32px',
  'line-height-h-md': '36px',
  'line-height-h-lg': '40px',
  'line-height-h-xl': '44px',
  'line-height-h-2xl': '52px',
  'family-display': '',
  'family-sans': '',
}
