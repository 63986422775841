import { EmailLogsCounter } from './EmailLogsCounter.js'
import { InvitationsCounter } from './InvitationsCounter.js'
import { MembersCounter } from './MembersCounter.js'
import { PostsCounter } from './PostsCounter.js'
import { SpacesCounter } from './SpacesCounter.js'
import { TagsCounter } from './TagsCounter.js'
import { TemplatesCounter } from './TemplatesCounter.js'
import type { TableHeaderCounterProps } from './types.js'

export const HeaderCounter = ({
  entityType,
  isLoading,
  selectedCount,
  totalCount,
}: TableHeaderCounterProps) => {
  if (!totalCount || isLoading) {
    return null
  }
  switch (entityType) {
    case 'invitation':
      return (
        <InvitationsCounter
          selectedCount={selectedCount}
          totalCount={totalCount}
        />
      )
    case 'member':
      return (
        <MembersCounter selectedCount={selectedCount} totalCount={totalCount} />
      )
    case 'post':
      return (
        <PostsCounter selectedCount={selectedCount} totalCount={totalCount} />
      )
    case 'space':
      return (
        <SpacesCounter selectedCount={selectedCount} totalCount={totalCount} />
      )
    case 'tag':
      return (
        <TagsCounter selectedCount={selectedCount} totalCount={totalCount} />
      )
    case 'template':
      return (
        <TemplatesCounter
          selectedCount={selectedCount}
          totalCount={totalCount}
        />
      )
    case 'emailLog':
      return (
        <EmailLogsCounter
          selectedCount={selectedCount}
          totalCount={totalCount}
        />
      )
    default: {
      // This is an exhaustive check, hence bypassing the linter
      // eslint-disable-next-line unused-imports/no-unused-vars
      const exhaustiveCheck: never = entityType

      throw new Error(
        `Table counter component does not support the ${entityType}. You need to add it to the list of countable entities and provide its counter component.`,
      )
    }
  }
}
