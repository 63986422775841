import { ReactNode, useCallback, useRef } from 'react'

import { fileSize } from '@bettermode/common/filesize'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { FormControl } from '@tribeplatform/react-ui-kit/FormControl'

import { useI18n } from '../i18n/providers/I18nProvider.js'

export interface FileUploadProps {
  onChange: (files: Array<File>) => void
  accept: string
  label?: string
  helper?: ReactNode | string
  multiple?: boolean
}

export const FileUpload = ({
  onChange,
  accept,
  label: _label,
  helper: _helper,
  multiple = false,
}: FileUploadProps) => {
  const { $t } = useI18n()
  const { data: network } = useNetwork()
  const hiddenFileInput = useRef<HTMLInputElement>()
  const label =
    _label ??
    $t({
      id: 'Generics.ChooseAFile',
      defaultMessage: 'Choose a file',
    })
  const helper =
    _helper ??
    (network?.settings?.fileSizeLimit &&
      $t(
        {
          id: 'Generics.FileSizeLimit',

          defaultMessage: 'The file size limit is {size_limit}.',
        },
        {
          size_limit: fileSize(network?.settings?.fileSizeLimit || 50_000),
        },
      ))
  const _onChange = useCallback(
    event => {
      const files: Array<File> = Array.from(event.target.files || [])
      // allow to select same file twice
      event.target.value = ''
      onChange(files)
    },
    [onChange],
  )

  return (
    <div className="flex flex-col items-center justify-center w-96 max-w-full pt-10 mx-auto">
      <Button
        onClick={() => {
          hiddenFileInput.current.click()
        }}
      >
        {label}
      </Button>
      <input
        type="file"
        className="hidden"
        ref={hiddenFileInput}
        onChange={_onChange}
        accept={accept}
        multiple={multiple}
        hidden
      />
      <FormControl.HelperText className="mt-3 text-center">
        {helper}
      </FormControl.HelperText>
    </div>
  )
}
