import { FC, useEffect, useRef } from 'react'

import { ErrorBoundary } from '../common/components/ErrorBoundary/ErrorBoundary.js'
import { PostCard, PostCardProps } from './PostCard.js'
import { PostRow } from './PostRow.js'

export const Post: FC<
  PostCardProps & {
    view?: 'card' | 'row'
    scrollObserver?: IntersectionObserver
  }
> = ({
  view = 'card',
  post,
  context,
  activeTagId = '',
  activeTagSlug = '',
  replyBar = true,
  collapsible = true,
  readonly,
  showContent,
  scrollObserver,
  ...rest
}) => {
  const postRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = postRef.current

    if (scrollObserver && element) {
      element.id = post?.id ?? ''
      scrollObserver.observe(element)
    }

    return () => {
      if (scrollObserver && element) {
        scrollObserver.unobserve(element)
      }
    }
  }, [post?.id, post?.title, scrollObserver])

  if (view === 'row') {
    return (
      <ErrorBoundary>
        <PostRow post={post} context={context} />
      </ErrorBoundary>
    )
  }
  return (
    <ErrorBoundary>
      <PostCard
        post={post}
        context={context}
        activeTagId={activeTagId}
        activeTagSlug={activeTagSlug}
        replyBar={replyBar}
        collapsible={collapsible}
        readonly={readonly}
        showContent={showContent}
        ref={postRef}
        {...rest}
      />
    </ErrorBoundary>
  )
}
