import type { SpaceFields } from '@tribeplatform/gql-client/graphql'
import type { ClientError } from '@tribeplatform/gql-client/lib'
import { MutationUpdateSpaceArgs, Space } from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSpaceKey } from '../../utils/keys/space.key.js'
import { invalidateSpaceQueries } from '../../utils/space.utils.js'
import { getCachedSpace } from '../cache/useCachedSpace.js'
import { useSpaceUtil } from '../utils/useSpaceUtil.js'

export const useUpdateSpace = (options?: {
  fields?: SpaceFields
  useMutationOptions?: UseMutationOptions<
    Space,
    ClientError,
    MutationUpdateSpaceArgs,
    { snapshot: Space }
  >
}) => {
  const { useMutationOptions, fields = 'default' } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()
  const spaceUtils = useSpaceUtil()

  return useMutation<
    Space,
    ClientError,
    MutationUpdateSpaceArgs,
    { snapshot: Space }
  >((input: MutationUpdateSpaceArgs) => client.spaces.update(input, fields), {
    ...useMutationOptions,
    onMutate: async data => {
      const spaceKey = getSpaceKey({ variables: { id: data.id } })
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(spaceKey)

      // Snapshot the previous value
      const snapshot = getCachedSpace({ id: data.id }, queryClient, spaceUtils)

      // Optimistically update to the new value
      const spaceInput = data.input
      const spaceUpdater = (old: Space): Space => ({
        ...old,
        ...{
          ...spaceInput,
          slate: old?.slate,
          address: { ...old?.address, ...spaceInput?.address },
          seoDetail: { ...old?.seoDetail, ...spaceInput?.seoDetail },
        },
      })
      queryClient.setQueriesData<Space>(spaceKey, spaceUpdater)

      useMutationOptions?.onMutate?.(data)
      // Return a context object with the snapshotted value
      return { snapshot }
    },
    onSuccess: (data, variables, context) => {
      const spaceKey = getSpaceKey({ variables: { id: data.id } })
      queryClient.setQueriesData<Space>(spaceKey, oldData => ({
        ...oldData,
        ...data,
      }))
      useMutationOptions?.onSuccess?.(data, variables, context)
    },
    onError: (err, newSpace, context) => {
      const spaceKey = getSpaceKey({ variables: { id: newSpace.id } })
      // revert back the optimistic response
      queryClient.setQueriesData<Space>(spaceKey, context.snapshot)
      useMutationOptions?.onError?.(err, newSpace, context)
    },
    onSettled: (data, error, variables, context) => {
      invalidateSpaceQueries({
        queryClient,
        spaceId: variables?.id,
        spaceUtils,
      })
      useMutationOptions?.onSettled?.(data, error, variables, context)
    },
  })
}
