import type {
  Fields,
  PaginatedTemplate,
  QueryTemplatesArgs,
} from '@tribeplatform/gql-client/types'

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '../../lib/react-query/useInfiniteQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getTemplatesKey } from '../../utils/keys/template.key.js'

export const DefaultTemplatesLimit = 10

export const useTemplates = (options?: {
  variables?: QueryTemplatesArgs
  fields?: Fields.TemplateFields
  useInfiniteQueryOptions?: UseInfiniteQueryOptions<PaginatedTemplate>
}) => {
  const {
    variables = { limit: DefaultTemplatesLimit },
    fields = 'basic',
    useInfiniteQueryOptions,
  } = options || {}
  const { client } = useTribeClient()
  const templatesKey = getTemplatesKey({ variables, fields })

  return useInfiniteQuery<PaginatedTemplate>(
    templatesKey,
    ({ pageParam: after }) =>
      client.query({
        name: 'templates',
        args: {
          fields: {
            pageInfo: 'all',
            nodes: fields,
          },
          variables: {
            after,
            ...variables,
          },
        },
      }),
    useInfiniteQueryOptions,
  )
}
