import { Subscriber } from '@tribeplatform/gql-client/types'
import { SubscriberFields } from '@tribeplatform/gql-client/types/generated'

import { UseQueryOptions, useQuery } from '../../lib/index.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSubscriberSettingsKey } from '../../utils/keys/notification.key.js'

export const useSubscriberSettings = (options?: {
  fields?: SubscriberFields
  useQueryOptions?: UseQueryOptions<Subscriber>
}) => {
  const { fields = 'basic', useQueryOptions } = options || {}
  const { client } = useTribeClient()
  const publisherKey = getSubscriberSettingsKey({ fields })

  return useQuery<Subscriber>(
    publisherKey,
    () =>
      client.query({
        name: 'subscriberSettings',
        args: { fields } as unknown as SubscriberFields,
      }),
    useQueryOptions,
  )
}
