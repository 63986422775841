import { I18nShape } from '@tribeplatform/react-components/i18n'
import { RAW_TEXT_BLOCK } from '@tribeplatform/slate-kit/constants'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { TextBlock as Component } from './Block.js'
import { TextProps } from './types.js'

export const RegisteredTextBlock = (
  _intl: I18nShape,
): RegisteredBlock<TextProps> => ({
  recommendedName: RAW_TEXT_BLOCK,
  Component,
  config: {
    type: BlockType.BuildingBlock,
    defaultProps: {
      value: '',
      size: 'sm',
      format: 'plain',
    },
  },
})
