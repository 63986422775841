import { useCallback, useMemo, useState } from 'react'

import { useBlock, useSlate } from '@tribeplatform/react-slate-kit/hooks'

import { useQueryParam } from '../../common/hooks/useQueryParam.js'
import { View } from '../types.js'
import { encodeViewName } from '../utils.js'

interface Props {
  views: View[]
}

export const useMultiViewBlockQueryParam = ({ views }: Props) => {
  const { block } = useBlock()
  const { mode } = useSlate()
  const [selectedIndexInPreview, setSelectedIndexInPreview] = useState(0)
  const preview = mode !== 'live'

  const blockName = block.extraProps.displayName ?? block.name

  const [viewParam, setParam] = useQueryParam(
    { name: encodeURIComponent(blockName), property: 'view' },
    {
      navigationMode: 'replace',
    },
  )

  const selectedIndex = useMemo(() => {
    if (preview) {
      return selectedIndexInPreview
    }

    const index = views?.findIndex(view => {
      return encodeViewName(view.name) === viewParam
    })
    return index < 0 ? 0 : index
  }, [preview, selectedIndexInPreview, viewParam, views])

  const setIndex = useCallback(
    (newIndex: number) => {
      if (preview) {
        setSelectedIndexInPreview(newIndex)
        return
      }

      if (!preview) {
        if (newIndex === 0) {
          setParam(undefined)
        } else {
          setParam(encodeViewName(views?.[newIndex]?.name ?? ''))
        }
      }
    },
    [preview, setParam, views],
  )

  return [selectedIndex, setIndex] as const
}
