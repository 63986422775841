interface Props {
  children: React.ReactNode
}

export const FloatingFooter = ({ children }: Props) => {
  return (
    // z-[2147483647] is the highest z-index used to prevent the bettermode branding from being covered by other elements
    // possibly due to users custom codes / html blocks
    <div className="fixed bottom-0 left-0 right-0 !z-[2147483647] pointer-events-none">
      {children}
    </div>
  )
}
