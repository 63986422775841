import { forwardRef, ReactElement } from 'react'

import { clsx } from 'clsx'

import { Icon } from '../Icon/index.js'
import { SvgIcon } from '../Icon/SvgIcon.js'
import type { IconComponentType } from '../Icon/types.js'
import { Tooltip } from '../Tooltip/index.js'
import type { HTMLTribeProps } from '../types/index.js'
import {
  baseButtonStyles,
  buttonIconStyles,
  buttonSizeStyles,
} from './Button.styles.js'
import type { ButtonStyleProps } from './types.js'

export type ButtonProps = HTMLTribeProps<'button'> &
  ButtonStyleProps & {
    leadingIcon?: IconComponentType
    trailingIcon?: IconComponentType
    tooltip?: ReactElement | string
    pressed?: boolean
  }
/**
 * The Button component is used to trigger an action or event, such as submitting a form, opening a dialog,
 * canceling an action, or performing a delete operation.
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      as,
      variant = 'primary',
      size = 'lg',
      fullWidth,
      disabled,
      loading,
      destructive,
      textAlign,
      className,
      children,
      leadingIcon,
      trailingIcon,
      tooltip,
      truncate = true,
      pressed,
      ...rest
    },
    ref,
  ) => {
    const Component = as || 'button'

    /**
     * This type issue exists due to a component design flaw
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((as as any)?.name === 'Link') {
      rest = { ...rest, ...{ variant: 'inherit' } }
    }

    const nestedSpan = !!leadingIcon || !!trailingIcon || loading || fullWidth

    const button = (
      <Component
        ref={ref}
        type={as ? undefined : 'button'}
        className={clsx(
          'touch-manipulation',
          nestedSpan ? 'inline-flex gap-2 items-center' : 'inline-block',
          buttonSizeStyles({
            size,
            fullWidth,
            textAlign,
            truncate,
          }),
          baseButtonStyles({
            variant,
            loading,
            destructive,
          }),
          className,
        )}
        disabled={disabled}
        aria-pressed={pressed}
        {...rest}
      >
        {leadingIcon && (
          <Icon
            className={clsx(
              buttonIconStyles({
                size,
              }),
              loading && 'invisible',
            )}
          >
            {leadingIcon}
          </Icon>
        )}
        {nestedSpan && !!children ? (
          <span
            className={clsx(
              'min-w-0',
              loading && 'invisible',
              truncate && 'truncate',
            )}
          >
            {children}
          </span>
        ) : (
          children
        )}
        {trailingIcon && (
          <Icon
            className={clsx(
              buttonIconStyles({
                size,
              }),
              '-mx-1',
              loading && 'invisible',
            )}
          >
            {trailingIcon}
          </Icon>
        )}
        {loading && (
          <span className="absolute inset-0 inline-flex items-center justify-center">
            <SvgIcon className="animate-spin" name="spinner" />
          </span>
        )}
      </Component>
    )

    if (tooltip) {
      return (
        <Tooltip>
          <Tooltip.Trigger asChild>{button}</Tooltip.Trigger>
          <Tooltip.Panel>{tooltip}</Tooltip.Panel>
        </Tooltip>
      )
    }
    return button
  },
)
