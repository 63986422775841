import { FeedProps } from '@tribeplatform/react-components/Feed'
import { I18nShape } from '@tribeplatform/react-components/i18n'
import { RegisteredFeedBlock as DumbRegisteredFeedBlock } from '@tribeplatform/react-slate-kit/blocks/registered'
import { RegisteredBlock } from '@tribeplatform/react-slate-kit/types'

import { FeedBlock } from './Block.js'

export const RegisteredFeedBlocks = (
  intl: I18nShape,
): RegisteredBlock<FeedProps> => ({
  recommendedName: DumbRegisteredFeedBlock(intl).recommendedName,
  Component: FeedBlock,
  config: DumbRegisteredFeedBlock(intl).config,
})
