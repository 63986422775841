import { useNavigate } from 'react-router-dom'

import { SystemSpaceSlug } from '@bettermode/common/space'
import { RoleType } from '@tribeplatform/gql-client/types'
import { useRouterPath } from '@tribeplatform/react-sdk'
import { useAuthToken, useSpaceUtil } from '@tribeplatform/react-sdk/hooks'

export const useLogin = () => {
  const { data: authToken } = useAuthToken()
  const isGuest =
    !authToken?.role?.type || authToken?.role?.type === RoleType.guest
  const pathname = useRouterPath()
  const navigate = useNavigate()
  const { generatePath } = useSpaceUtil()

  const showLogin = () => {
    if (!isGuest) {
      return
    }

    navigate(
      generatePath(
        SystemSpaceSlug.LOGIN,
        null,
        pathname ? { redirect: encodeURIComponent(pathname) } : null,
      ),
    )
  }

  const isLoggedIn = !isGuest

  return {
    showLogin,
    isLoggedIn,
  }
}
