import { lazy, Suspense, useState } from 'react'

import { Link as RouterLink } from 'react-router-dom'

import { generateNewChatPath } from '@bettermode/common/utils'
import {
  hasActionPermission,
  hasScopesPermission,
} from '@tribeplatform/gql-client/lib'
import { Member } from '@tribeplatform/gql-client/types'
import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { SubscriptionBell } from '@tribeplatform/react-components/Notification'
import { useAuthToken } from '@tribeplatform/react-sdk/hooks'
import { Button, IconButton } from '@tribeplatform/react-ui-kit/Button'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { MemberActionItems } from './MemberActionItems.js'

const ProfileSettingsModal = lazy(() =>
  import('@tribeplatform/react-components/ProfileSettingsModal').then(m => ({
    default: m.ProfileSettingsModal,
  })),
)

export const MemberActionButtons = ({ member }: { member: Member }) => {
  const { $t } = useI18n()

  const [editModal, setEditModal] = useState(false)
  const {
    data: { network, member: authMember },
  } = useAuthToken()
  const [canUpdateMember] = hasScopesPermission(member, ['updateMember'])
  const { authorized: canSubscribe } = hasActionPermission(
    network.authMemberProps?.permissions ?? [],
    'subscribe',
  )

  return (
    <>
      {member?.authMemberProps?.canSendPrivateMessages &&
        member?.id !== authMember?.id && (
          <Button
            variant="primary"
            as={RouterLink}
            to={generateNewChatPath(member)}
            className="flex-shrink-0"
            leadingIcon={<SvgIcon name="message" />}
          >
            <T defaultMessage="Message" id="Generics.Message.Verb" />
          </Button>
        )}
      {canUpdateMember && (
        <Button
          onClick={() => setEditModal(true)}
          variant="secondaryNeutral"
          className="flex-shrink-0"
          leadingIcon={<SvgIcon name="edit" />}
        >
          <T defaultMessage="Edit profile" id="Generics.EditProfile" />
        </Button>
      )}
      {canSubscribe && member?.id !== authMember?.id && (
        <SubscriptionBell
          publisherType="member"
          publisherId={member.id}
          subscribed={member.authMemberProps?.subscribed}
        />
      )}
      <Dropdown placement="bottom-end">
        <Dropdown.ButtonMinimal
          aria-label={$t({
            defaultMessage: 'Options',
            id: 'Generics.Options',
          })}
        >
          <IconButton
            as="span"
            variant="secondaryNeutral"
            icon={<SvgIcon name="dots-horizontal" />}
          />
        </Dropdown.ButtonMinimal>
        <MemberActionItems member={member} context="member" />
      </Dropdown>
      {editModal && (
        <Suspense fallback={<></>}>
          <ProfileSettingsModal
            open
            onClose={() => {
              setEditModal(false)
            }}
            member={member}
          />
        </Suspense>
      )}
    </>
  )
}
