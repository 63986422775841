import { useBlock, useSlate } from '@tribeplatform/react-slate-kit/hooks'
import {
  AddBlockPosition,
  BlockType,
  BlockWrapperComponent,
} from '@tribeplatform/react-slate-kit/types'

import { BlockWrapper } from './BlockWrapper.js'
import { LayoutWrapper } from './LayoutWrapper.js'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const Wrapper: BlockWrapperComponent = ({ children, ...props }) => {
  const {
    mode,
    compiledSlate: { blocks },
    extraContext: {
      slate: { openAddBlock },
    },
  } = useSlate<{
    openAddBlock: (id: string, position?: number) => unknown
  }>()

  const {
    block: {
      id: blockId,
      config: { type },
    },
  } = useBlock()

  const addBlock = (position: AddBlockPosition) => {
    const parentId = blocks[blockId]?.parentId
    if (!blocks[parentId]?.config?.acceptsChildren) {
      return
    }

    const positionInParent = blocks[parentId].children.findIndex(
      childId => childId === blockId,
    )
    const newBlockPosition =
      position === AddBlockPosition.After
        ? positionInParent + 1
        : positionInParent

    openAddBlock(parentId, newBlockPosition)
  }

  if (mode === 'live') {
    return children
  }

  if (type === BlockType.Layout) {
    return (
      <LayoutWrapper {...props} onAddBlock={addBlock}>
        {children}
      </LayoutWrapper>
    )
  }

  return (
    <BlockWrapper {...props} onAddBlock={addBlock}>
      {children}
    </BlockWrapper>
  )
}
