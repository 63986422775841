import { gql } from 'graphql-tag'

import { PostTypeFields, postTypeGQLFields } from './postType.fields.js'

export function getPostTypeGQLQuery(fields: PostTypeFields) {
  return gql`
    query GetPostType($id: ID!) {
      postType(id: $id) {
        __typename
        ${postTypeGQLFields(fields)}
      }
    }
  `
}

export function getPostTypesGQLQuery(fields: PostTypeFields) {
  return gql`
    query GetPostTypes(
      $after: String
      $before: String
      $limit: Int!
      $offset: Int
      $reverse: Boolean
      $context: PostTypeContext
      $query: String
    ) {
      postTypes(
        after: $after
        before: $before
        limit: $limit
        offset: $offset
        reverse: $reverse
        context: $context
        query: $query
      ) {
        edges {
          cursor
          node {
            __typename
            ${postTypeGQLFields(fields)}
          }
        }
        nodes {
          __typename
          ${postTypeGQLFields(fields)}
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  `
}

export const addPostTypeFieldGQLMutation = (fields: PostTypeFields) => gql`
  mutation AddPostTypeField($id: ID!, $input: CustomFieldSchemaInput!) {
    addPostTypeField(id: $id, input: $input) {
      ${postTypeGQLFields(fields)}
    }
  }
`

export const archivePostTypeFieldGQLMutation = (fields: PostTypeFields) => gql`
  mutation ArchivePostTypeField($id: ID!, $key: String!) {
    archivePostTypeField(id: $id, key: $key) {
      ${postTypeGQLFields(fields)}
    }
  }
`

export const deletePostTypeFieldGQLMutation = (fields: PostTypeFields) => gql`
  mutation DeletePostTypeField($id: ID!, $key: String!) {
    deletePostTypeField(id: $id, key: $key) {
      ${postTypeGQLFields(fields)}
    }
  }
`

export const reorderPostTypeFieldsGQLMutation = (fields: PostTypeFields) => gql`
  mutation ReorderPostTypeFields(
    $id: ID!
    $input: [ReOrderSchemaFieldInput!]!
  ) {
    reorderPostTypeFields(id: $id, input: $input) {
      ${postTypeGQLFields(fields)}
    }
  }
`

export const unarchivePostTypeFieldGQLMutation = (
  fields: PostTypeFields,
) => gql`
  mutation UnarchivePostTypeField($id: ID!, $key: String!) {
    unarchivePostTypeField(id: $id, key: $key) {
      ${postTypeGQLFields(fields)}
    }
  }
`

export const updatePostTypeFieldGQLMutation = (fields: PostTypeFields) => gql`
  mutation UpdatePostTypeField(
    $id: ID!
    $input: UpdateCustomFieldSchemaInput!
  ) {
    updatePostTypeField(id: $id, input: $input) {
      ${postTypeGQLFields(fields)}
    }
  }
`

export const archivePostTypeGQLMutation = (fields: PostTypeFields) => gql`
  mutation ArchivePostType($id: ID!) {
    archivePostType(id: $id) {
      ${postTypeGQLFields(fields)}
    }
  }
`

export const unarchivePostTypeGQLMutation = (fields: PostTypeFields) => gql`
  mutation UnarchivePostType($id: ID!) {
    unarchivePostType(id: $id) {
      ${postTypeGQLFields(fields)}
    }
  }
`
