export enum PopoverCloseSource {
  Click,
  EscapeKeypress,
  FocusOut,
  ScrollOut,
}

export type PopoverAutofocusTarget = 'none' | 'first-node' | 'container'

export type PopoverPadding = 'sm' | 'md' | 'lg' | 'none'
export type PopoverTriggerType = 'click' | 'hover'
