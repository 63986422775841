import { useMemo } from 'react'

import { Image } from '@tribeplatform/gql-client/types'

import { useMediaModal } from '../../MediaModal/MediaModalProvider.js'

type ImageViewProps = {
  image: Image
}
export const ImageView = ({ image }: ImageViewProps) => {
  const { pushSlide, openSlideWithIndex } = useMediaModal()
  const slideIndex = useMemo(() => {
    return pushSlide({
      ...image,
      url: image.urls?.large ?? image.url,
    })
  }, [image, pushSlide])

  return (
    <button
      type="button"
      onClick={() => {
        openSlideWithIndex(slideIndex)
      }}
      className="w-full rounded-base screen-rounded-none overflow-hidden my-2"
    >
      <div className="w-full h-full flex not-prose justify-center bg-surface-subdued">
        <img
          className="bg-surface-subdued object-cover object-center max-h-120"
          src={image.urls?.large ?? image.url}
          alt={image.name}
        />
      </div>
    </button>
  )
}
