import type {
  LeaderboardFields,
  MemberFields,
  ReportRecordFields,
} from '@tribeplatform/gql-client/graphql'
import type {
  QueryActivityLogsArgs,
  QueryAnalyticsArgs,
  QueryLeaderboardArgs,
  QueryLeaderboardWithScoresArgs,
} from '@tribeplatform/gql-client/types'

import { KeyConstructor } from './keyConstructor.js'

const LEADERBOARD_KEY = 'leaderBoard'
const LEADERBOARD_WITH_SCORES_KEY = 'leaderboardWithScores'
const ACTIVITY_LOG_KEY = 'activityLog'
const ANALYTICS_KEY = 'analytics'

export const getLeaderboardKey = KeyConstructor<
  QueryLeaderboardArgs,
  MemberFields
>(LEADERBOARD_KEY)
export const getLeaderboardWithScoresKey = KeyConstructor<
  QueryLeaderboardWithScoresArgs,
  LeaderboardFields
>(LEADERBOARD_WITH_SCORES_KEY)
export const getActivityLogKey = KeyConstructor<
  QueryActivityLogsArgs,
  ReportRecordFields
>(ACTIVITY_LOG_KEY)
export const getAnalyticsKey = KeyConstructor<
  QueryAnalyticsArgs,
  ReportRecordFields
>(ANALYTICS_KEY)
