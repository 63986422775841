import { useCollections } from '@tribeplatform/react-sdk/hooks'
import { GridList } from '@tribeplatform/react-ui-kit/Layout'

import { BC } from '../../types/index.js'
import { CollectionCard } from './CollectionCard.js'
import { CollectionListLoading } from './CollectionListLoading.js'

export const CollectionListBlock: BC<Record<string, never>> = ({ ...rest }) => {
  const { data: collections, isFetching } = useCollections({
    fields: 'basic',
  })
  return (
    <GridList columns={4} {...rest}>
      {collections?.map(collection => (
        <GridList.Item key={collection.id}>
          <CollectionCard collection={collection} />
        </GridList.Item>
      ))}
      {isFetching ? <CollectionListLoading /> : null}
    </GridList>
  )
}
