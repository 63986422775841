import { EntityImage } from '../common/components/EntityImage.js'
import { convertIdToGlyph } from '../MediaPicker/utils/utils.js'

interface Props {
  glyphId: string
  activeColor?: string
  label?: string
}
export const Reaction = ({ glyphId, activeColor, label }: Props) => {
  const icon = convertIdToGlyph(glyphId)

  if (!icon) {
    return null
  }

  return (
    <button
      type="button"
      className="rounded-full w-9 h-7 text-lg border-2 border-line-subdued -me-1.5 flex items-center justify-center overflow-hidden bg-surface-pressed hover:bg-skeleton ring-surface ring-1 ring-offset-0"
      aria-label={label}
    >
      <EntityImage
        entity={{
          image: icon,
          name: label || '',
        }}
        size="md"
        displayColor={activeColor}
        iconStyle="solid"
      />
    </button>
  )
}
