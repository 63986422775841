import { DocumentNode } from 'graphql'
import { gql } from 'graphql-tag'

import { ActionFields, actionGQLFields } from '../common/action.fields.js'
import { paginatedGQLFields } from '../common/paginated.fields.js'
import { PostFields, postGQLFields } from '../post/posts.fields.js'
import {
  TemplateRequestFields,
  templateRequestGQLFields,
} from '../template/templateRequest.fields.js'
import { PageFields, pageGqlFields } from './page.fields.js'
import {
  PaginatedSpaceFields,
  SpaceFields,
  spaceGQLFields,
} from './space.fields.js'

export function createSpaceGQLQuery(fields: SpaceFields): DocumentNode {
  return gql`
    mutation CreateSpace($input: CreateSpaceInput!) {
      createSpace(input: $input) {
        ${spaceGQLFields(fields)}
      }
    }
  `
}

export function getSpacesGQLQuery(fields: SpaceFields): DocumentNode {
  return gql`
    query GetSpaces(
      $after: String
      $before: String
      $collectionId: String
      $limit: Int!
      $memberId: ID
      $offset: Int
      $orderBy: SpaceListOrderByEnum
      $filterBy: [EntityListFilterByInput!]
      $query: String
      $reverse: Boolean
      $type: [SpaceType!]
    ) {
      spaces(
        after: $after
        before: $before
        collectionId: $collectionId
        limit: $limit
        memberId: $memberId
        filterBy: $filterBy
        offset: $offset
        orderBy: $orderBy
        query: $query
        reverse: $reverse
        type: $type
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            ${spaceGQLFields(fields)}
          }
        }
        nodes {
          ${spaceGQLFields(fields)}
        }
      }
    }
  `
}

export function getSpacesByIdsGQLQuery(fields: SpaceFields): DocumentNode {
  return gql`
    query GetSpacesByIds($ids: [ID!]!) {
      spacesByIds(ids: $ids) {
        ${spaceGQLFields(fields)}
      }
    }
  `
}

export function getSpacesBySlugsGQLQuery(fields: SpaceFields): DocumentNode {
  return gql`
    query GetSpacesBySlugs($slugs: [String!]!) {
      spacesBySlugs(slugs: $slugs) {
        ${spaceGQLFields(fields)}
      }
    }
  `
}

export function getSpaceGQLQuery(fields: SpaceFields): DocumentNode {
  return gql`
    query GetSpace($id: ID, $slug: ID, $path: String) {
      space(id: $id, slug: $slug, path: $path) {
        ${spaceGQLFields(fields)}
      }
    }
  `
}

export function getPageGqlQuery(fields): DocumentNode {
  return gql`
    query page($path: String!) {
      page(path:$path) {
        ${pageGqlFields(fields)}
      }
    }
  `
}

export function getHomepageQuery(fields: PageFields): DocumentNode {
  return gql`
    query homepage($type: HomeSpaceType) {
      homepage(type: $type) {
        ${pageGqlFields(fields)}
      }
    }
  `
}

export function changeHomepageGQLMutation(fields: ActionFields): DocumentNode {
  return gql`
    mutation changeHomepage($spaceId: String!, $type: HomeSpaceType) {
      changeHomepage(spaceId: $spaceId, type: $type) {
        ${actionGQLFields(fields)}
      }
    }
  `
}

export function unsetHomepageGQLMutation(fields: ActionFields): DocumentNode {
  return gql`
    mutation unsetHomepage($type: HomeSpaceType!) {
      unsetHomepage(type: $type) {
        ${actionGQLFields(fields)}
      }
    }
  `
}

export const organizeSpacesInCollectionGQLMutation = (
  fields: ActionFields,
) => gql`
  mutation OrganizeSpacesInCollection(
    $collectionId: String!
    $spaceIds: [String!]!
  ) {
    organizeSpacesInCollection(
      collectionId: $collectionId
      spaceIds: $spaceIds
    ) {
      ${actionGQLFields(fields)}
    }
  }
`

export const updateSpaceGQLMutation = (
  fields: SpaceFields,
): DocumentNode => gql`
    mutation updateSpace($id: ID!, $input: UpdateSpaceInput!) {
      updateSpace(id: $id input: $input) {
        ${spaceGQLFields(fields)}
      }
    }
  `

export const joinSpaceGQLMutation = (): DocumentNode => gql`
  mutation joinSpace($spaceId: ID!) {
    joinSpace(spaceId: $spaceId) {
      status
      __typename
    }
  }
`

export const leaveSpaceGQLMutation = (): DocumentNode => gql`
  mutation leaveSpace($spaceId: ID!) {
    leaveSpace(spaceId: $spaceId) {
      status
      __typename
    }
  }
`

export const getSpacePinnedPostGQLQuery = (
  fields: PostFields,
): DocumentNode => gql`
  query GetSpacePinnedPosts($spaceId: ID!) {
    spacePinnedPosts(spaceId: $spaceId) {
      ${postGQLFields(fields)}
    }
  }
`

export const getSpaceTaggedPostsGQLQuery = (
  fields: PostFields,
): DocumentNode => gql`
  query GetPostsByTag(
    $spaceId: ID
    $tagId: ID
    $tagSlug: String
    $after: String
    $before: String
    $limit: Int!
    $offset: Int
    $reverse: Boolean
  ) {
    tagPosts(
      spaceId: $spaceId
      tagId: $tagId
      tagSlug: $tagSlug
      after: $after
      before: $before
      limit: $limit
      offset: $offset
      reverse: $reverse
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ${postGQLFields(fields)}
        }
      }
      nodes {
        ${postGQLFields(fields)}
      }
    }
  }
`

export const updateSpaceHighlightedTagsMutation = (): DocumentNode => gql`
  mutation updateSpaceHighlightedTags(
    $input: UpdateHighlightedTags!
    $spaceId: ID!
  ) {
    updateSpaceHighlightedTags(input: $input, spaceId: $spaceId) {
      status
      __typename
    }
  }
`

export const exploreSpacesGQLQuery = (
  fields: PaginatedSpaceFields,
): DocumentNode => gql`
  query ExploreSpaces(
    $after: String
    $before: String
    $collectionId: String
    $limit: Int!
    $offset: Int
    $reverse: Boolean
  ) {
    exploreSpaces(
      after: $after
      before: $before
      collectionId: $collectionId
      limit: $limit
      offset: $offset
      reverse: $reverse
    ) {
      ${paginatedGQLFields(fields, spaceGQLFields)}
    }
  }
`

export const deleteSpaceGQLMutation = (
  fields: ActionFields,
): DocumentNode => gql`
  mutation DeleteSpace($id: ID!) {
    deleteSpace(id: $id) {
      ${actionGQLFields(fields)}
    }
  }
`

export function createSpaceFromTemplateGQLMutation(
  fields: TemplateRequestFields,
): DocumentNode {
  return gql`
    mutation createSpaceFromTemplate($input: CreateSpaceInput!, $style: String, $templateId: String!) {
      createSpaceFromTemplate(input: $input, style: $style, templateId: $templateId) {
        ${templateRequestGQLFields(fields)}
      }
    }
  `
}

export const readSpaceGQLMutation = () => gql`
  mutation ReadSpace($date: DateTime!, $spaceId: String!) {
    readSpace(date: $date, spaceId: $spaceId) {
      status
    }
  }
`

export const closeSpaceGQLMutation = () => gql`
  mutation CloseSpace($spaceId: ID!) {
    closeSpace(spaceId: $spaceId) {
      status
    }
  }
`
