import { Fragment, useState } from 'react'

import { Button } from '@tribeplatform/react-ui-kit/Button'
import { SimpleDivider } from '@tribeplatform/react-ui-kit/Divider'
import { Link } from '@tribeplatform/react-ui-kit/Link'
import type { ModalProps } from '@tribeplatform/react-ui-kit/Modal'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'

import { T, useI18n } from '../../i18n/index.js'
import type { Filter } from '../types.js'
import { ModalFilterItem } from './ModalFilterItem.js'

interface PostsFilterDrawerProps extends ModalProps {
  filters: Filter[]
  onSave: (newFilters: Filter[]) => void
}

export const InlineFiltersModal = ({
  open,
  filters,
  onClose,
  onSave,
}: PostsFilterDrawerProps) => {
  const intl = useI18n()
  const { $t } = intl
  const [draftFilters, setDraftFilters] = useState<Filter[]>(filters)

  const setFilter = (newFilter: Filter) => {
    setDraftFilters(prevFilters => {
      return prevFilters.map(filter => {
        if (filter.id === newFilter.id) {
          return newFilter
        }

        return filter
      })
    })
  }

  const removeFilter = (filterId: string) => {
    setDraftFilters(prevFilters =>
      prevFilters.map(filter => {
        if (filter.id === filterId) {
          const newFilter: Filter = {
            ...filter,
            value: undefined,
          }

          return newFilter
        }

        return filter
      }),
    )
  }

  const clearFilters = () => {
    const newFilters = filters.map(filter => {
      const newFilter: Filter = {
        ...filter,
        value: undefined,
      }

      return newFilter
    })

    onSave(newFilters)
    onClose()
  }

  const handleSave = () => {
    onSave(draftFilters)
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose} size="2xl">
      <Modal.Header
        title={$t({
          defaultMessage: 'Filters',
          id: 'Generics.Filters',
        })}
      />
      <Modal.Content>
        <div className="space-y-2">
          {draftFilters.map((filter, index) => (
            <Fragment key={filter.id}>
              <SimpleDivider variant="subdued" />
              <ModalFilterItem
                filter={filter}
                setFilter={setFilter}
                removeFilter={() => removeFilter(filter.id)}
                defaultOpen={index === 0}
              />
            </Fragment>
          ))}
        </div>
      </Modal.Content>
      <Modal.Actions className="items-center gap-x-2">
        <Button variant="primary" onClick={handleSave}>
          <T defaultMessage="Apply" id="Generics.Apply" />
        </Button>
        <Link variant="accent" onClick={clearFilters}>
          <T defaultMessage="Clear" id="Generics.Clear.Verb" />
        </Link>
      </Modal.Actions>
    </Modal>
  )
}
