import { ReportTimeFrame } from '@tribeplatform/gql-client/types'
import { I18nShape } from '@tribeplatform/react-components/i18n'

export const DEFAULT_MEMBERS_COUNT = 10
export const MAX_MEMBERS_COUNT = 20

export const leaderboardTabsFactory = ({ $t }: I18nShape) => [
  {
    text: $t({ id: 'Generics.AllTime', defaultMessage: 'All time' }),
    value: ReportTimeFrame.allTime,
  },
  {
    text: $t({ id: 'Generics.Month', defaultMessage: 'Month' }),
    value: ReportTimeFrame.lastThirtyDays,
  },
  {
    text: $t({ id: 'Generics.Week', defaultMessage: 'Week' }),
    value: ReportTimeFrame.lastSevenDays,
  },
]
