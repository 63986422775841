import type { Role } from '@tribeplatform/gql-client/types'
import {
  LegacyFilter,
  ListFilter,
  ListFilterByOperator,
} from '@tribeplatform/react-components/Filters'

const convertLegacyFilterKey = (key: string, keyString: string) => {
  if (keyString) {
    return keyString
  }

  // 'role.name' is now 'roleId'
  if (key === 'role.name') {
    return 'roleId'
  }

  return key
}

const convertLegacyFilterOperator = (key: string, operator: string) => {
  // The legacy operator might have ':relative' at the end of operator value
  // We should remove it
  const operatorValue = operator.split(':')[0]
  if (
    Object.values(ListFilterByOperator).includes(
      operatorValue as ListFilterByOperator,
    )
  ) {
    return operatorValue as ListFilterByOperator
  }

  return null
}

const convertLegacyFilterValue = (
  key: string,
  operator: string,
  value: string,
  roles: Role[],
) => {
  // role.name used to be filtered by value and not by id
  // The new filter system requires the role id for filtering
  // As a result, we need to replace the role name with role id
  if (key === 'role.name') {
    const role = roles.find(({ name }) => JSON.stringify(name) === value)
    if (!role) {
      return null
    }
    return JSON.stringify(role.id)
  }

  // Relative times where being calculated by minutes where it should have been calculated by seconds
  if (operator.split(':')?.[1] === 'relative') {
    try {
      const parsedValue = JSON.parse(value)
      if (typeof parsedValue !== 'number') {
        return JSON.stringify(0)
      }
      const newValue = JSON.stringify(parsedValue * 60)
      return newValue
    } catch (error) {
      return null
    }
  }

  return value
}

export const convertToMemberListFilter = (
  legacyFilters: LegacyFilter[],
  roles: Role[],
): ListFilter[] => {
  const sanitizedFilters = legacyFilters.map(legacyFilter => {
    const { key, keyString, operator, value } = legacyFilter
    const newLegacyFilter: ListFilter = {
      ...legacyFilter,
      key: convertLegacyFilterKey(key, keyString),
      operator: convertLegacyFilterOperator(key, operator),
      value: convertLegacyFilterValue(key, operator, value, roles),
    }

    return newLegacyFilter
  })

  return sanitizedFilters.filter(({ value, operator }) => !!value && !!operator)
}
