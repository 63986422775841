import { hasLocalStorage } from '@bettermode/common/local-storage-detect'

import { SlateKitUserStorage } from '../types/index.js'

export class UserLocalStorage implements SlateKitUserStorage {
  storage: Record<string, unknown> = {}

  getDeprecatedItem<P>(key: string): P {
    if (!hasLocalStorage()) {
      return undefined
    }

    try {
      const settings = JSON.parse(localStorage.getItem('userSettings') || '{}')
      return settings[key] as P
    } catch (e) {
      return undefined
    }
  }

  getItem<P>(key: string): P {
    if (!hasLocalStorage()) {
      return undefined
    }

    const item = localStorage.getItem(key)
    if (!item) {
      return this.getDeprecatedItem<P>(key)
    }

    try {
      return JSON.parse(item)
    } catch {
      return undefined
    }
  }

  setItem<P>(key: string, value: P) {
    if (!hasLocalStorage()) {
      return
    }
    localStorage.setItem(key, JSON.stringify(value))
  }
}
