import { createContext, useContext, useMemo } from 'react'

export type SelectContext = {
  open: boolean
  invalid?: boolean
  disabled?: boolean
}

const SelectContext = createContext<SelectContext>(undefined)

export const SelectProvider = ({ open, invalid, disabled, children }) => {
  const value = useMemo(
    () => ({
      open,
      invalid,
      disabled,
    }),
    [open, invalid, disabled],
  )

  return (
    <SelectContext.Provider value={value}>{children}</SelectContext.Provider>
  )
}

export const useSelect = () => {
  const context = useContext(SelectContext)
  if (context === undefined) {
    throw new Error('useSelect must be used within a SelectProvider')
  }
  return context
}
