import { CustomFieldType } from '@tribeplatform/gql-client/types'

import { logger } from '../../common/lib/logger.js'
import { CustomFieldSubtype } from '../../CustomField/utils.js'
import type { I18nShape } from '../../i18n/types.js'
import { arrayOperators } from '../operatorPickers/ArrayOperatorPicker.js'
import { booleanOperators } from '../operatorPickers/BooleanOperatorPicker.js'
import { dateOperators } from '../operatorPickers/DateOperatorPicker.js'
import { numberOperators } from '../operatorPickers/NumberOperatorPicker.js'
import { relationOperators } from '../operatorPickers/RelationOperatorPicker.js'
import { selectOperators } from '../operatorPickers/SelectOperatorPicker.js'
import { textOperators } from '../operatorPickers/TextOperatorPicker.js'
import { type Filter, ListFilterByOperator } from '../types.js'
import {
  translateOperator,
  translateSelectOperator,
  translateSingleArrayOperator,
} from './operatorTranslations.js'

export const getOperators = (
  type: CustomFieldType,
  subType: CustomFieldSubtype,
): ListFilterByOperator[] => {
  switch (type) {
    case CustomFieldType.array:
      return arrayOperators
    case CustomFieldType.boolean:
      return booleanOperators
    case CustomFieldType.date:
      return dateOperators
    case CustomFieldType.number:
      return numberOperators
    case CustomFieldType.relation:
      return relationOperators
    case CustomFieldType.text: {
      if (subType === CustomFieldSubtype.SELECT) {
        return selectOperators
      }
      return textOperators
    }
    case CustomFieldType.object:
    case CustomFieldType.richText:
      return textOperators
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = type
      logger.warn('Not supported field type: ', exhaustiveCheck)
      return textOperators
    }
  }
}

export const getDefaultOperator = (
  type: CustomFieldType,
  subType: CustomFieldSubtype,
): ListFilterByOperator => {
  // The first item is the default operator
  const [defaultOperator] = getOperators(type, subType)
  return defaultOperator
}

export const getOperatorName = (
  operator: ListFilterByOperator,
  type: CustomFieldType,
  subType: CustomFieldSubtype,
  value: Filter['value'],
  intl: I18nShape,
): string => {
  // For array values, we show 'is' and 'is not' if no value is selected
  if (
    (type === CustomFieldType.array ||
      type === CustomFieldType.relation ||
      (type === CustomFieldType.text &&
        subType === CustomFieldSubtype.SELECT)) &&
    value === undefined
  ) {
    return translateSingleArrayOperator(intl)?.[operator] ?? ''
  }

  // For array values, we show 'is' and 'is not' if there is only one item selected
  if (Array.isArray(value)) {
    if (value.length < 2) {
      return translateSingleArrayOperator(intl)?.[operator] ?? ''
    }
  }

  // Subtype of "select" is an exception to the "text" type fields
  if (subType === CustomFieldSubtype.SELECT) {
    return translateSelectOperator(intl)?.[operator] ?? ''
  }

  return translateOperator(intl)?.[type]?.[operator] ?? ''
}
