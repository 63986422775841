import { Text, TextVariant } from '@tribeplatform/react-ui-kit/Text'

import { BC } from '../../types/index.js'
import { MarkdownBlock } from '../Markdown/Block.js'
import { TextProps, TextSize } from './types.js'

export const TextBlock: BC<TextProps> = ({
  value,
  size,
  align,
  truncate,
  format,
  ...rest
}) => {
  const mapping: Record<TextSize, TextVariant> = {
    xs: 'bodyXs',
    sm: 'bodySm',
    md: 'bodyMd',
    lg: 'bodyLg',
    xl: 'bodyXl',
    '2xl': 'headingSm',
  }

  return (
    <Text
      variant={mapping[size] ?? 'bodyMd'}
      align={align}
      truncate={truncate}
      color="inherit"
      {...rest}
    >
      {format === 'markdown' ? <MarkdownBlock text={value} /> : value}
    </Text>
  )
}
