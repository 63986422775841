import { useEffect, useRef } from 'react'

import { clsx } from 'clsx'

import { customizerPreviewScrollTo } from '@bettermode/common/customizer'
import { useBlock, useSlate } from '@tribeplatform/react-slate-kit/hooks'
import {
  BlockWrapperComponent,
  LayoutBlockProps,
  LBC,
  UnknownProps,
} from '@tribeplatform/react-slate-kit/types'
import { useDocument } from '@tribeplatform/react-ui-kit/hooks'
import { getPropertyValue } from '@tribeplatform/react-ui-kit/utils'

import { EditorBlockExtraContext, EditorSlateExtraContext } from '../types.js'
import { LayoutBlockHighlightWrapper } from './LayoutBlockHighlightWrapper.js'

export const LayoutWrapper: BlockWrapperComponent = ({
  Block,
  childrenBlocks,
  onAddBlock,
}) => {
  const { document } = useDocument()
  const {
    mode,
    compiledSlate: { rootBlock },
    extraContext: {
      slate: { activeBlockMode },
    },
  } = useSlate<EditorSlateExtraContext, EditorBlockExtraContext>()

  const {
    block: { id, compiledProps },
    extraContext: { focused, selected, activated },
  } = useBlock<UnknownProps, EditorBlockExtraContext>()
  const isRootBlock = rootBlock === id

  const LayoutBlock = Block as LBC<LayoutBlockProps>
  const layoutProps = compiledProps as LayoutBlockProps
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (
      ref.current &&
      selected &&
      mode === 'edit' &&
      // When adding a new block, the parent block get's activated
      // however, we don't want to scroll to the parent block in this case
      // rather scroll to the new block placeholder
      activeBlockMode !== 'add'
    ) {
      customizerPreviewScrollTo({
        headerHeightString: getPropertyValue(document, '--c-header-height'),
        element: ref.current,
        document,
      })
    }
  }, [selected, ref, mode, document, activeBlockMode])

  return (
    <LayoutBlock
      {...layoutProps}
      containerProps={{
        ref,
        className: clsx(
          'relative',
          // When adding a new block the parent block get's activated
          // however, we don't want to gray out other blocks and highlight the parent block
          // we only gray out other blocks when the block is being edited.
          activated && activeBlockMode === 'edit' && 'z-40 bg-background',
          !isRootBlock &&
            'hover:outline hover:outline-2 hover:outline-customizer-blue hover:rounded-base',
        ),
      }}
      selected={selected}
      focused={focused}
      HighlightWrapper={<LayoutBlockHighlightWrapper onAddBlock={onAddBlock} />}
    >
      {childrenBlocks}
    </LayoutBlock>
  )
}
