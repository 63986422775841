import { ComponentProps, FC, ReactNode } from 'react'

import { clsx } from 'clsx'
import { useCombobox } from 'downshift'

import { IconProps } from '../Icon/index.js'
import { SvgIcon } from '../Icon/SvgIcon.js'
import { TopBarInput } from '../TopBar/TopBarInput.js'
import {
  FloatingContextProvider,
  useFloatingContext,
} from '../utils/floating/index.js'
import { AutocompleteContext, useAutocomplete } from './AutocompleteContext.js'
import {
  AutocompleteItem,
  AutocompleteItems,
  AutocompleteItemsEmpty,
} from './AutocompleteItems.js'

export type AutocompleteProps<Item> = {
  itemToString: (newValue: Item) => string
  value: Item
  options: Item[]
  onChange: (newValue: Item, isEnterKey: boolean) => void
  id: string
  className?: string
  children: ReactNode
  disabled?: boolean
  onInputChange?: (newValue: string) => void
  loading?: boolean
  defaultHighlightedIndex?: number
  clearInputOnSelect?: boolean
  clearInputOnBlur?: boolean
}

/**
 * The Autocomplete component is suitable for search, it does not have predefined options
 */

export const Autocomplete = <Item,>(props: AutocompleteProps<Item>) => {
  const {
    itemToString,
    children,
    options,
    value,
    onChange,
    className,
    disabled = false,
    loading = false,
    onInputChange,
    defaultHighlightedIndex,
    clearInputOnSelect = false,
    clearInputOnBlur = false,
    id,
    ...rest
  } = props

  const dsCombobox = useCombobox<Item>({
    ...(typeof value === 'string' ? { initialInputValue: value } : {}),
    id,
    itemToString,
    items: options,
    defaultHighlightedIndex,
    onSelectedItemChange: changes => {
      onChange(
        changes.selectedItem,
        changes.type === useCombobox.stateChangeTypes.InputKeyDownEnter,
      )
    },
    onInputValueChange: changes => {
      onInputChange?.(changes.inputValue)
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useCombobox.stateChangeTypes.ItemClick:
          if (clearInputOnSelect) {
            return {
              ...changes,
              inputValue: '', // don't add the item string as input value at selection.
            }
          }
          return changes
        case useCombobox.stateChangeTypes.InputBlur:
          if (clearInputOnBlur) {
            return {
              ...changes,
              inputValue: '', // don't add the item string as input value at selection.
            }
          }
          return changes
        default:
          return changes
      }
    },
  })

  return (
    <div className={clsx('relative isolate', className)} {...rest}>
      <AutocompleteContext.Provider
        value={{
          ...dsCombobox,
          disabled,
          loading,
        }}
      >
        <FloatingContextProvider placement="bottom-start">
          {children}
        </FloatingContextProvider>
      </AutocompleteContext.Provider>
    </div>
  )
}

export type AutocompleteInputProps = ComponentProps<'input'> & {
  leadingIcon?: IconProps
  onEnter?: ({
    highlightedIndex,
    inputValue,
  }: {
    highlightedIndex: number
    inputValue: string
  }) => void
  showClearButton?: boolean
}

export const AutocompleteInput: FC<AutocompleteInputProps> = props => {
  const {
    className,
    placeholder,
    leadingIcon: _,
    onEnter,
    showClearButton = true,
    ...rest
  } = props
  const {
    disabled,
    getComboboxProps,
    getInputProps,
    reset,
    highlightedIndex,
    inputValue,
  } = useAutocomplete()
  const { reference } = useFloatingContext()

  const clearButton = (
    <button
      type="button"
      aria-label="clear selection"
      onClick={e => {
        e.stopPropagation()
        reset()
      }}
    >
      <SvgIcon
        className="w-5 h-5 text-content-subdued"
        aria-hidden="true"
        name="close"
      />
    </button>
  )

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      onEnter?.({ highlightedIndex, inputValue })
    }
  }

  return (
    <div ref={reference}>
      <div {...getComboboxProps({ disabled })}>
        <TopBarInput
          {...getInputProps({
            placeholder,
            disabled,
            className,
            onKeyDown,
            ...rest,
          })}
          trailingAddon={showClearButton && !!inputValue && clearButton}
        />
      </div>
    </div>
  )
}

Autocomplete.Input = AutocompleteInput
Autocomplete.Items = AutocompleteItems
Autocomplete.ItemsEmpty = AutocompleteItemsEmpty
Autocomplete.Item = AutocompleteItem
