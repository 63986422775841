import { useContext } from 'react'

import { clsx } from 'clsx'

import { FileDropZoneContext } from './FileDropZoneContext.js'

export interface FileUploadProps {
  actionTitle: string
  actionHint?: string
  className?: string
}

export function FileDropZoneLabel(props: FileUploadProps) {
  const { disabled, focused } = useContext(FileDropZoneContext)
  const { actionTitle, actionHint, className } = props

  const actionMarkup = (
    <div
      className={clsx(
        'text-sm',
        focused ? 'text-content' : 'text-content-subdued',
        disabled
          ? 'text-content-disabled cursor-default pointer-events-none'
          : 'cursor-pointer',
      )}
    >
      {actionTitle}
    </div>
  )

  const actionHintMarkup = actionHint && (
    <div className="text-xs first-letter:capitalize text-content-subdued text-center">
      {actionHint}
    </div>
  )

  const viewMarkup = (
    <div className="space-y-1">
      {actionMarkup}
      {actionHintMarkup}
    </div>
  )

  return (
    <div
      className={clsx(
        'py-3 px-4 text-center flex items-center justify-center h-full',
        className,
      )}
    >
      {viewMarkup}
    </div>
  )
}
