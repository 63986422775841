import { createContext, ReactNode, useEffect, useState } from 'react'

import { GlobalClient } from '@tribeplatform/gql-client/client'

import type { TribeClientConfig } from './@types/index.js'

export type GlobalClientContextProps = {
  client: GlobalClient
  config: TribeClientConfig
}
export type GlobalClientProviderType = React.FC<{
  children: ReactNode
  config: TribeClientConfig
}>

export const GlobalClientContext = createContext<GlobalClientContextProps>({
  client: null,
  config: null,
})

export const GlobalClientProvider: GlobalClientProviderType = ({
  children,
  config,
}) => {
  const { accessToken, baseUrl: graphqlUrl, clientId, clientSecret } = config

  const [globalClient, setGlobalClient] = useState<GlobalClient>(
    new GlobalClient({
      accessToken,
      graphqlUrl,
      clientId,
      clientSecret,
      onError: config.onError,
      onEvent: config.onEvent,
      notifyOnTokenExpiration: true,
    }),
  )

  useEffect(() => {
    if (typeof accessToken !== 'undefined' && accessToken !== '') {
      setGlobalClient(
        new GlobalClient({
          accessToken,
          graphqlUrl,
          clientId,
          clientSecret,
          onError: config.onError,
          onEvent: config.onEvent,
          notifyOnTokenExpiration: true,
        }),
      )
    }
  }, [clientId, clientSecret, accessToken, config])

  return (
    <GlobalClientContext.Provider value={{ client: globalClient, config }}>
      {children}
    </GlobalClientContext.Provider>
  )
}
