import { useRouter } from '@tribeplatform/react-sdk'
import { Button } from '@tribeplatform/react-ui-kit/Button'

import { CustomFieldViewProps } from '../utils.js'

export const ButtonView = ({
  value,
  text,
}: CustomFieldViewProps & { text: string }) => {
  const { Link } = useRouter()
  if (!value || !text) {
    return null
  }

  return (
    <Button
      as={Link}
      href={value}
      size="lg"
      fullWidth
      onClick={e => e.stopPropagation()}
    >
      {text}
    </Button>
  )
}
