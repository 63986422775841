import { SearchEntityType } from '@tribeplatform/gql-client/types'
import type { Post, SearchEntity } from '@tribeplatform/gql-client/types'

import { dayjs } from '../common/lib/dayjs.js'
import { T } from '../i18n/components/T.js'

interface Props {
  hit: SearchEntity
}
export const SearchSubtitle = ({
  hit: { entityType, in: space, by: author, content, created, entity },
}: Props) => {
  switch (entityType) {
    case SearchEntityType.member:
    case SearchEntityType.space:
      return <>{content}</>
    case SearchEntityType.External:
      return <>{content}</>
    case SearchEntityType.post:
    default: {
      const authorName = author?.name || 'Unknown'
      const time =
        entityType === SearchEntityType.post
          ? (entity as Post)?.publishedAt
          : created
      const timeFromNow = dayjs(time ?? created).fromNow()
      const spaceName = space?.name || 'Untitled'

      return (
        <T
          defaultMessage="Posted on {space} {time} by {author}"
          description="The subtitle that appears under each search result"
          id="SearchResult.Subtitle"
          values={{
            space: <span className="text-content">{spaceName}</span>,
            author: <span className="text-content">{authorName}</span>,
            time: timeFromNow,
          }}
        />
      )
    }
  }
}
