import type {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query'

import { T } from '@tribeplatform/react-components/i18n'
import type { View } from '@tribeplatform/react-components/Views'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { getCardWidth } from '../utils/index.js'

interface Props {
  fetchNextPage?: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult>
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
  gallerySize: View['gallerySize']
}
export const CarouselShowMore = ({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  gallerySize,
}: Props) => {
  const width = getCardWidth(gallerySize)

  if (!hasNextPage) {
    return null
  }

  return (
    <Card
      as="button"
      className="overflow-hidden"
      style={{ maxWidth: `${width}px`, minWidth: `${width}px` }}
      onClick={() => fetchNextPage()}
    >
      <div className="w-full h-full flex items-center justify-center text-content-subdued font-medium text-sm">
        <T defaultMessage="Show more" id="Generics.ShowMore" />
        {isFetchingNextPage && (
          <SvgIcon size="lg" className="animate-spin ms-2" name="spinner" />
        )}
      </div>
    </Card>
  )
}
