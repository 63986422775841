import { useState } from 'react'

import { T, useStaticIntl } from '@tribeplatform/react-components/i18n'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Tabs } from '@tribeplatform/react-ui-kit/Tabs'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

import { BC } from '../../types/block.types.js'
import { leaderboardTabsFactory } from './constants.js'
import { LeaderboardTab } from './LeaderboardTab.js'
import { LeaderboardProps } from './types.js'

export const LeaderboardBlock: BC<LeaderboardProps> = ({
  heading,
  spaceId,
  membersCount,
  excludeStaff,
  defaultTab,
  showScore,
  ...rest
}) => {
  const leaderboardTabs = useStaticIntl(leaderboardTabsFactory)

  let defaultTabIndex = leaderboardTabs.findIndex(
    tab => tab.value === defaultTab,
  )
  if (defaultTabIndex === -1) {
    defaultTabIndex = 0
  }
  const [activeTab, setActiveTab] = useState<number>(defaultTabIndex)

  return (
    <Card {...rest}>
      <Card.Header
        title={heading}
        action={
          <Tooltip placement="left">
            <Tooltip.Trigger>
              <SvgIcon className="w-5 h-5 text-content-subdued" name="info" />
            </Tooltip.Trigger>
            <Tooltip.Panel>
              <T
                id="Blocks.Leaderboard.ScoreCalculationDescription"
                defaultMessage="Scores are calculated based on the number of posts, replies, and reactions"
              />
            </Tooltip.Panel>
          </Tooltip>
        }
        size="sm"
      />
      <Card.Content>
        <Tabs.Group onChange={setActiveTab} defaultIndex={activeTab}>
          <Tabs.List variant="plain" fullWidth>
            {leaderboardTabs.map(tab => (
              <Tabs.Tab
                className="whitespace-nowrap"
                name={tab.value}
                key={tab.value}
              >
                {tab.text}
              </Tabs.Tab>
            ))}
          </Tabs.List>
          <Tabs.Panels>
            {leaderboardTabs.map(tab => (
              <Tabs.Panel key={tab.value}>
                <LeaderboardTab
                  membersCount={membersCount}
                  spaceId={spaceId}
                  timeFrame={tab.value}
                  excludeStaff={excludeStaff}
                  showScore={showScore}
                />
              </Tabs.Panel>
            ))}
          </Tabs.Panels>
        </Tabs.Group>
      </Card.Content>
    </Card>
  )
}
