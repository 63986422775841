import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Link } from '@tribeplatform/react-ui-kit/Link'

import { CustomFieldViewProps } from '../utils.js'

export const LocationView = ({ value }: CustomFieldViewProps) => {
  if (!value) {
    return null
  }
  return (
    <Link
      className="text-content-subdued flex space-s-1 items-center"
      onClick={e => e.stopPropagation()}
    >
      <SvgIcon className="h-[1.25em] w-[1.25em]" name="location-marker" />
      <span>{value}</span>
    </Link>
  )
}
