import { clsx } from 'clsx'

import {
  BannerPlaceholder,
  EntityImage,
} from '@tribeplatform/react-components/common/components'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { Avatar, legacySizeMapper } from '@tribeplatform/react-ui-kit/Avatar'
import { iconStyles } from '@tribeplatform/react-ui-kit/Icon'

import type { QuickLinksSource } from '../types.js'

interface Props {
  source: QuickLinksSource
  field: StaticField
  highlighted: boolean
  mediaUrl: string
}
export const ListImage = ({ source, field, highlighted, mediaUrl }: Props) => {
  const iconSize = legacySizeMapper(field.settings?.avatarSize ?? 'lg')
  const { bannerImage } = source

  if (mediaUrl) {
    return (
      <div className="shrink-0">
        <Avatar
          size={iconSize}
          src={mediaUrl}
          name={source.title}
          rounded="md"
          className={clsx(
            highlighted &&
              'ring-2 ring-offset-surface ring-focused rounded-base',
          )}
        />
      </div>
    )
  }

  if (bannerImage) {
    return (
      <div
        className={clsx(
          'shrink-0 flex items-center justify-center text-content-on-background',
          highlighted && 'ring-2 ring-offset-surface ring-focused rounded-md',
          iconStyles({
            size: iconSize,
          }),
        )}
      >
        <EntityImage entity={{ image: bannerImage }} size={iconSize} />
      </div>
    )
  }

  return (
    <div className="shrink-0">
      <BannerPlaceholder
        className={clsx(
          'flex justify-center items-center rounded-base',
          'overflow-hidden',
          highlighted && 'ring-2 ring-offset-surface ring-focused',
          iconStyles({
            size: iconSize,
          }),
        )}
        name={source?.id}
      />
    </div>
  )
}
