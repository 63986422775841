import {
  createContext,
  memo,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react'

import isEqual from 'react-fast-compare'

export type DropdownContext = {
  open: boolean
  disabled: boolean
}

const DropdownContext = createContext<DropdownContext>(undefined)

export const DropdownProvider = memo(
  ({ open, disabled, children }: PropsWithChildren<DropdownContext>) => {
    const value = useMemo(
      () => ({
        open,
        disabled,
      }),
      [open, disabled],
    )

    return (
      <DropdownContext.Provider value={value}>
        {children}
      </DropdownContext.Provider>
    )
  },
  isEqual,
)

export const useDropdown = () => {
  const context = useContext(DropdownContext)
  if (context === undefined) {
    throw new Error('useDropdown must be used within a DropdownProvider')
  }
  return context
}
