import {
  ReactNode,
  startTransition,
  useEffect,
  useReducer,
  useRef,
} from 'react'

import { animate } from 'motion'
import { createPortal } from 'react-dom'

import { useDocument } from '../hooks/index.js'
import { getPortalRootElement } from '../Portal/utils.js'
import { useUniqueId } from '../utils/unique-id.js'

export type AnimatePortalProps = {
  open: boolean
  children: ReactNode
  idPrefix?: string
  noAnimation?: boolean
}

/**
 * AnimatePortal allows components to animate out when they're removed from the React tree.
 */
export const AnimatePortal = ({
  open = false,
  children,
  idPrefix,
  noAnimation,
}: AnimatePortalProps) => {
  const { document } = useDocument()

  const uniqueId = useUniqueId(idPrefix || 'portal')

  const ref = useRef(null)
  const canSafelyRemove = useRef(true)
  const [_, forceUpdate] = useReducer(x => x + 1, 0)

  const durationInMs = 100

  useEffect(() => {
    if (noAnimation) {
      return
    }

    if (!ref.current) {
      return
    }

    if (open) {
      canSafelyRemove.current = false
    }

    try {
      const animation = animate(
        ref.current,
        { opacity: open ? 1 : 0 },
        { duration: durationInMs / 1000 },
      )
      animation.finished.then(() => {
        if (!open) {
          canSafelyRemove.current = true
          startTransition(() => {
            forceUpdate()
          })
        }
      })
    } catch (e) {
      if (!open) {
        canSafelyRemove.current = true
        startTransition(() => {
          forceUpdate()
        })
      }
    }
  }, [noAnimation, open])

  if (!document?.body) {
    return null
  }

  if (!open && canSafelyRemove.current) {
    return null
  }

  return createPortal(
    <div
      data-portal-id={uniqueId}
      className="isolate"
      ref={ref}
      style={noAnimation ? { opacity: 1 } : { opacity: 0 }}
    >
      {children}
    </div>,
    getPortalRootElement(document) ?? document.body,
  )
}
