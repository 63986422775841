export const formatCount = (count: number, max: number) => {
  if (typeof count !== 'number') {
    return ''
  }

  if (count > max) {
    return `${max}+`
  }

  if (count <= 0) {
    return '0'
  }

  return String(count)
}
