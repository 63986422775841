import type { Member } from '@tribeplatform/gql-client/types'
import { useNavigate } from '@tribeplatform/react-components/Link'
import type { StaticField } from '@tribeplatform/react-components/Views'

import { Field } from './Field.js'

interface Props {
  member: Member
  fields: StaticField[]
  activeFieldId: string
}
export const MembersListItem = ({ member, fields, activeFieldId }: Props) => {
  const navigate = useNavigate()

  return (
    <div
      className="cursor-pointer flex items-center space-s-2"
      onClick={() => navigate(member?.relativeUrl)}
    >
      {fields
        .filter(field => field.id !== 'listMessageButton')
        .map(field => (
          <Field
            key={field.id}
            field={field}
            member={member}
            activeFieldId={activeFieldId}
            viewStyle="list"
          />
        ))}
      {fields
        .filter(field => field.id === 'listMessageButton')
        .map(field => (
          <Field
            key={field.id}
            field={field}
            member={member}
            activeFieldId={activeFieldId}
            viewStyle="list"
          />
        ))}
    </div>
  )
}
