import { useState, useEffect, useCallback } from 'react'

import { Link } from 'react-router-dom'

import {
  AnalyticsResponse,
  Image,
  Member,
  MemberStatus,
  RoleType,
} from '@tribeplatform/gql-client/types'
import {
  analyticsNumberFormatter,
  normalizeListData,
} from '@tribeplatform/react-components/Analytics/utils'
import { MemberAvatarPlaceholder } from '@tribeplatform/react-components/common/components/member'
import { T } from '@tribeplatform/react-components/i18n'
import { useAuthMember, useNetwork } from '@tribeplatform/react-sdk/hooks'
import { Avatar } from '@tribeplatform/react-ui-kit/Avatar'

import { MAX_MEMBERS_COUNT } from './constants.js'
import { EmptyState } from './EmptyState.js'

interface Props {
  data: AnalyticsResponse[]
  count: number
  showScore: boolean
  excludeStaff: boolean
}

interface TopMemberItem {
  id: Member['id']
  profilePicture: Member['profilePicture']
  name: Member['name']
  score: Member['score']
  member: Member
}

export const LeaderboardList = ({
  data,
  count,
  showScore,
  excludeStaff,
}: Props) => {
  const { data: authMember } = useAuthMember()
  const {
    data: { roles },
  } = useNetwork()
  const [topMembers, setTopMembers] = useState<TopMemberItem[]>([])

  const isAdmin = useCallback(
    (roleId: string) => {
      const roleType = roles?.find(role => roleId === role.id)?.type

      return roleType === RoleType.admin || roleType === RoleType.moderator
    },
    [roles],
  )

  useEffect(() => {
    if (data.length) {
      const normalizedData = normalizeListData(data[0])
      const topMembers: TopMemberItem[] = normalizedData.map(record => {
        return {
          id: record?.entities?.person?.id,
          profilePicture: record?.entities?.person?.profilePicture,
          name: record?.entities?.person?.name,
          score: +record?.payload?.score,
          member: record?.entities?.person,
        }
      })
      const topActiveMembers = topMembers
        .filter(
          ({ member }) =>
            member?.status === MemberStatus.VERIFIED &&
            !(excludeStaff && isAdmin(member?.roleId)),
        )
        .slice(0, Math.min(MAX_MEMBERS_COUNT, count))

      if (
        !topActiveMembers?.find(({ member }) => member?.id === authMember?.id)
      ) {
        const loggedInMember = topMembers.find(
          ({ member }) => member?.id === authMember?.id,
        )
        if (loggedInMember && !(excludeStaff && isAdmin(authMember?.roleId))) {
          topActiveMembers.push(loggedInMember)
        }
      }

      setTopMembers(topActiveMembers)
    }
  }, [authMember?.id, authMember?.roleId, count, data, excludeStaff, isAdmin])

  if (!topMembers.length) {
    return <EmptyState />
  }

  return (
    <div className="grid grid-cols-1 gap-1 -ms-2 -me-2">
      {topMembers.map(({ id, name, profilePicture, score, member }, index) => {
        if (!id) {
          return null
        }

        return (
          <Link
            key={id}
            to={member?.relativeUrl}
            className="group flex space-s-3 items-center p-2 rounded-base hover:bg-surface-hovered"
          >
            <div className="text-lg font-medium w-5 text-center flex-shrink-0 whitespace-nowrap">
              {id === authMember?.id && index === topMembers.length - 1
                ? '-'
                : index + 1}
            </div>
            <Avatar
              size="2.5x"
              src={(profilePicture as Image)?.urls?.thumb}
              name={name}
              fallback={MemberAvatarPlaceholder}
            />
            <div className="flex-1 text-content truncate" title={name}>
              {name}
              {id === authMember?.id && (
                <span className="text-xs text-content-subdued px-1.5">
                  <T id="Generics.YouInParens" defaultMessage="(You)" />
                </span>
              )}
            </div>
            {showScore && (
              <div className="px-1 text-content-subdued">
                {analyticsNumberFormatter(score)}
              </div>
            )}
          </Link>
        )
      })}
    </div>
  )
}
