import { useEffect, useState } from 'react'

const preloadImage = src =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = resolve
    image.onerror = reject
    image.src = src
  })

export const usePreloadImage = ({ src }) => {
  const [preloaded, setPreloaded] = useState(false)
  useEffect(() => {
    if (src) {
      preloadImage(src).then(() => setPreloaded(true))
    }
  }, [src])

  return {
    preloaded,
  }
}
