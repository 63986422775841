import { PropsWithChildren } from 'react'

import { DragOverlay, defaultDropAnimationSideEffects } from '@dnd-kit/core'
import type { DropAnimation } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'

import { Portal } from '../Portal/index.js'

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
}

// eslint-disable-next-line @typescript-eslint/ban-types
type SortableOverlayProps = {}

export const SortableOverlay = ({
  children,
}: PropsWithChildren<SortableOverlayProps>) => {
  return (
    <Portal>
      <DragOverlay
        wrapperElement="ul"
        modifiers={[restrictToWindowEdges]}
        dropAnimation={dropAnimationConfig}
      >
        {children}
      </DragOverlay>
    </Portal>
  )
}
