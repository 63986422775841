import { useEffect, useState } from 'react'

import { IconButton } from '../Button/index.js'
import { SvgIcon } from '../Icon/SvgIcon.js'
import { Input } from '../Input/Input.js'

export interface NumberInputProps {
  value: number
  onChange: (value: number) => void
  disabled?: boolean
  step?: number
  min?: number
  max?: number
  disableInput?: boolean
}

export const NumberInput = ({
  value,
  disabled,
  step = 1,
  min = 0,
  max = 100,
  onChange,
  disableInput = false,
}: NumberInputProps) => {
  const [inputValue, setInputValue] = useState<string | number>(value)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const setNewNumber = (inputValue: string | number) => {
    setInputValue(inputValue)

    if (inputValue === '') {
      onChange(NaN)
      return
    }

    const newNumber = Number(inputValue)
    if (Number.isNaN(newNumber) || newNumber > max || newNumber < min) {
      onChange(NaN)
      return
    }

    onChange(newNumber)
  }

  return (
    <div className="relative">
      <Input
        value={inputValue}
        className="text-center"
        onChange={e => {
          if (!disableInput) {
            setNewNumber(e.target.value)
          }
        }}
        disabled={disabled}
        readOnly={disableInput}
      />
      <div className="h-full absolute top-0 right-0 rtl:left-0 p-1">
        <IconButton
          variant="tertiaryNeutral"
          className="h-full"
          onClick={() => setNewNumber(value + step)}
          disabled={value >= max || disabled}
          icon={<SvgIcon name="plus" />}
        />
      </div>
      <div className="h-full absolute top-0 left-0 rtl:right-0 p-1">
        <IconButton
          variant="tertiaryNeutral"
          className="h-full"
          onClick={() => setNewNumber(value - step)}
          disabled={value <= min || disabled}
          icon={<SvgIcon name="minus" />}
        />
      </div>
    </div>
  )
}
