import { useCallback } from 'react'

import { Editor } from '@tiptap/react'

import { Post } from '@tribeplatform/gql-client/types'
import { usePost, useUpdatePost } from '@tribeplatform/react-sdk/hooks'

import { getMappingFields } from '../common/utils/post.js'
import { useCanQuitEditor } from '../Composer/utils/state.js'
import { PostContent } from '../Post/PostContent.js'
import type { PostContext } from '../Post/types.js'
import { ReplyEditor, ReplyEditorProps } from './ReplyEditor.js'

export const ReplyContent = ({
  reply,
  edit,
  onEdit,
  onCancel,
  context,
}: {
  reply: Post
  edit?: boolean
  onEdit?: () => void
  context?: PostContext
} & Pick<ReplyEditorProps, 'onCancel'>) => {
  const { mutate: updatePost, isLoading } = useUpdatePost()
  const { data: fullReply, isFetching: fetchingFullPost } = usePost({
    variables: { id: reply?.id },
    useQueryOptions: {
      enabled: edit,
    },
  })
  const canQuitEditor = useCanQuitEditor()

  const handleCancel = useCallback(
    isTouched => {
      if (
        canQuitEditor({
          isTouched,
        })
      ) {
        onCancel(isTouched)
      }
    },
    [onCancel, canQuitEditor],
  )
  const loading = isLoading || fetchingFullPost

  const update = useCallback(
    async (content, editor: Editor) => {
      const _content = content || ''
      const postType = reply?.postType || fullReply?.postType
      const mappingFields = getMappingFields({
        content: _content,
        postTypeName: postType.name,
      })

      updatePost(
        {
          id: reply.id,
          input: {
            mappingFields,
            publish: true,
          },
        },
        {
          onSuccess: () => {
            editor.commands.clearContent()
            onEdit?.()
          },
        },
      )
    },
    [reply?.postType, reply.id, fullReply?.postType, updatePost, onEdit],
  )

  if (edit) {
    return (
      <ReplyEditor
        reply={reply}
        onCancel={handleCancel}
        isLoading={loading}
        onReply={update}
      />
    )
  }
  return <PostContent post={reply} context={context} />
}
