import { CustomFieldType } from '@tribeplatform/gql-client/types'

import type { I18nShape } from '../../i18n/types.js'
import { ListFilterByOperator } from '../types.js'

type TranslateOperator = (
  intl: I18nShape,
) => Record<CustomFieldType, Partial<Record<ListFilterByOperator, string>>>
export const translateOperator: TranslateOperator = intl => {
  const { $t } = intl

  return {
    [CustomFieldType.boolean]: {
      [ListFilterByOperator.equals]: $t({
        defaultMessage: 'is',
        id: 'Filters.Operators.Boolean.Equals',
      }),
      [ListFilterByOperator.not]: $t({
        defaultMessage: 'is not',
        id: 'Filters.Operators.Boolean.Not',
      }),
    },
    [CustomFieldType.array]: {
      [ListFilterByOperator.in]: $t({
        defaultMessage: 'is one of',
        id: 'Filters.Operators.Array.In',
      }),
      [ListFilterByOperator.nin]: $t({
        defaultMessage: 'is none of',
        id: 'Filters.Operators.Array.Nin',
      }),
      [ListFilterByOperator.contains]: $t({
        defaultMessage: 'is',
        id: 'Filters.Operators.Array.Contains',
      }),
    },
    [CustomFieldType.date]: {
      [ListFilterByOperator.gt]: $t({
        defaultMessage: 'is after',
        id: 'Filters.Operators.Date.GT',
      }),
      [ListFilterByOperator.lt]: $t({
        defaultMessage: 'is before',
        id: 'Filters.Operators.Date.LT',
      }),
      [ListFilterByOperator.gte]: $t({
        defaultMessage: 'is on or after',
        id: 'Filters.Operators.Date.GTE',
      }),
      [ListFilterByOperator.lte]: $t({
        defaultMessage: 'is on or before',
        id: 'Filters.Operators.Date.LTE',
      }),
      [ListFilterByOperator.equals]: $t({
        defaultMessage: 'is on',
        id: 'Filters.Operators.Date.Eq',
      }),
    },
    [CustomFieldType.number]: {
      [ListFilterByOperator.equals]: $t({
        defaultMessage: 'is',
        id: 'Filters.Operators.Number.Equals',
      }),
      [ListFilterByOperator.not]: $t({
        defaultMessage: 'is not',
        id: 'Filters.Operators.Number.Not',
      }),
      [ListFilterByOperator.gt]: $t({
        defaultMessage: 'is greater than',
        id: 'Filters.Operators.Number.GT',
      }),
      [ListFilterByOperator.lt]: $t({
        defaultMessage: 'is less than',
        id: 'Filters.Operators.Number.LT',
      }),
      [ListFilterByOperator.gte]: $t({
        defaultMessage: 'is greater than or equals',
        id: 'Filters.Operators.Number.GTE',
      }),
      [ListFilterByOperator.lte]: $t({
        defaultMessage: 'is less than or equals',
        id: 'Filters.Operators.Number.LTE',
      }),
    },
    [CustomFieldType.relation]: {
      [ListFilterByOperator.equals]: $t({
        defaultMessage: 'is',
        id: 'Filters.Operators.Array.Equals',
      }),
      [ListFilterByOperator.in]: $t({
        defaultMessage: 'is one of',
        id: 'Filters.Operators.Array.In',
      }),
      [ListFilterByOperator.nin]: $t({
        defaultMessage: 'is none of',
        id: 'Filters.Operators.Array.Nin',
      }),
    },
    [CustomFieldType.text]: {
      [ListFilterByOperator.contains]: $t({
        defaultMessage: 'contains',
        id: 'Filters.Operators.Text.Contains',
      }),
      [ListFilterByOperator.equals]: $t({
        defaultMessage: 'is',
        id: 'Filters.Operators.Text.Eq',
      }),
      [ListFilterByOperator.not]: $t({
        defaultMessage: 'is not',
        id: 'Filters.Operators.Text.NEq',
      }),
      [ListFilterByOperator.startsWith]: $t({
        defaultMessage: 'starts with',
        id: 'Filters.Operators.Text.StartsWith',
      }),
      [ListFilterByOperator.endsWith]: $t({
        defaultMessage: 'ends with',
        id: 'Filters.Operators.Text.EndsWith',
      }),
    },
    [CustomFieldType.object]: {},
    [CustomFieldType.richText]: {},
  }
}

export const translateSelectOperator = (
  intl: I18nShape,
): Partial<Record<ListFilterByOperator, string>> => {
  const { $t } = intl

  return {
    [ListFilterByOperator.equals]: $t({
      defaultMessage: 'is',
      id: 'Filters.Operators.Array.Equals',
    }),
    [ListFilterByOperator.not]: $t({
      defaultMessage: 'is not',
      id: 'Filters.Operators.Array.NEq',
    }),
    [ListFilterByOperator.in]: $t({
      defaultMessage: 'is one of',
      id: 'Filters.Operators.Array.In',
    }),
    [ListFilterByOperator.nin]: $t({
      defaultMessage: 'is none of',
      id: 'Filters.Operators.Array.Nin',
    }),
  }
}

export const translateSingleArrayOperator = (
  intl: I18nShape,
): Partial<Record<ListFilterByOperator, string>> => {
  const { $t } = intl

  return {
    [ListFilterByOperator.in]: $t({
      defaultMessage: 'is',
      id: 'Filters.Operators.Array.Equals',
    }),
    [ListFilterByOperator.nin]: $t({
      defaultMessage: 'is not',
      id: 'Filters.Operators.Array.NEq',
    }),
  }
}
