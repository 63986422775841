import { useState } from 'react'

import { clsx } from 'clsx'

import { Form } from '@tribeplatform/react-components/Form'
import { useTribeClient } from '@tribeplatform/react-sdk'
import { ExtraInteractionsEvents } from '@tribeplatform/react-sdk/types'
import { legacySizeMapper, Avatar } from '@tribeplatform/react-ui-kit/Avatar'
import type { FormControlSelectProps } from '@tribeplatform/react-ui-kit/FormControl'
import { Select } from '@tribeplatform/react-ui-kit/Select'

import { useSlate } from '../../../hooks/slate.hook.js'
import { CallbackExtraContext } from '../../../types/callback.types.js'
import { MarkdownBlock } from '../../blocks.js'
import { SelectBlockProps } from '../types.js'

type FormSelectProps = Omit<SelectBlockProps, 'value'> & {
  name: string
  items: FormControlSelectProps['items']
  getValues: () => Record<string, string>
}
export const FormSelect = ({
  items,
  helperText: markdownHelperText,
  name,
  callbackId,
  disabled,
  getValues,
  avatarRounded = 'md',
  avatarSize = 'xs',
  ...rest
}: FormSelectProps) => {
  const {
    extraContext: {
      slate: { callback },
    },
  } = useSlate<CallbackExtraContext>()

  const [loading, setLoading] = useState(false)

  const { config } = useTribeClient()
  const {
    interactionsContext: { interactionEmitter },
  } = config ?? {}

  return (
    <Form.Select
      name={name}
      onChange={async value => {
        if (disabled !== true && callbackId) {
          setLoading(true)
          interactionEmitter.emit(ExtraInteractionsEvents.InteractionCalled, {})
          try {
            await callback(callbackId, {
              data: {
                value,
                label: items.find(item => item.value === value)?.text ?? '',
                formValues: getValues(), // we might need the form context in backend
              },
            })
          } catch {
            // We are catching the error here because we don't want to crash the app
            // We are already logging the error in the callback function
          }
          setLoading(false)
        }
      }}
      {...rest}
      helperText={
        typeof markdownHelperText === 'string' ? (
          <MarkdownBlock text={markdownHelperText} />
        ) : (
          markdownHelperText
        )
      }
      disabled={loading || disabled}
      renderButton={value => {
        const selectedItem = items.find(item => item.value === value)
        if (!selectedItem) {
          return null
        }

        return (
          <span className="flex items-center">
            {selectedItem?.avatar ? (
              <Avatar
                src={selectedItem.avatar}
                size={legacySizeMapper(avatarSize)}
                rounded={avatarRounded}
                name={selectedItem.text as string}
              />
            ) : null}

            <span
              className={clsx([
                'block truncate',
                selectedItem?.avatar && 'ms-3',
              ])}
            >
              {selectedItem.text}
            </span>
          </span>
        )
      }}
    >
      <Select.Items>
        {items.map(item => (
          <Select.Item key={item.value} value={item.value}>
            <div className="flex items-center">
              {item?.avatar && (
                <Avatar
                  src={item.avatar}
                  size={legacySizeMapper(avatarSize)}
                  rounded={avatarRounded}
                  name={item.text as string}
                />
              )}
              <span className={clsx(['block truncate', item.avatar && 'ms-3'])}>
                {item.text}
              </span>
            </div>
          </Select.Item>
        ))}
      </Select.Items>
    </Form.Select>
  )
}
