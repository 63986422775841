import { MembersAvatars } from '@tribeplatform/react-components/MembersAvatars'

import { BC } from '../../types/index.js'
import { MembersAvatarsProps } from './types.js'

export const MembersAvatarsBlock: BC<MembersAvatarsProps> = ({
  ids,
  moreCount,
  ...rest
}) => {
  if (!ids) {
    return null
  }

  return <MembersAvatars ids={ids} moreCount={moreCount} {...rest} />
}
