import { memo, useEffect } from 'react'

import { useDocument } from '../hooks/useDocument.js'
import { focusFirstFocusableNode } from '../utils/focus.js'

export interface FocusProps {
  children?: React.ReactNode
  disabled?: boolean
  root: React.RefObject<HTMLElement> | HTMLElement | null
}

export const Focus = memo(({ children, disabled, root }: FocusProps) => {
  const { document } = useDocument()

  useEffect(() => {
    if (disabled || !root) {
      return
    }

    const node = isRef(root) ? root.current : root

    if (!node || node.querySelector('[autofocus]')) {
      return
    }

    focusFirstFocusableNode(document, node, false)
  }, [document, disabled, root])

  return <>{children}</>
})

function isRef(
  ref: React.RefObject<HTMLElement> | HTMLElement,
): ref is React.RefObject<HTMLElement> {
  return (ref as React.RefObject<HTMLElement>).current !== undefined
}
