import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { CustomFieldViewProps } from '../utils.js'

export const DateView = ({ value }: CustomFieldViewProps) => {
  if (!value) {
    return null
  }

  return (
    <div className="text-content-subdued flex space-s-1 items-center">
      <SvgIcon className="h-[1.25em] w-[1.25em]" name="calendar" />
      <span suppressHydrationWarning>{value}</span>
    </div>
  )
}
