import { ComponentProps, FC, forwardRef } from 'react'

import { clsx } from 'clsx'

import { selectItemsStyles } from '../Select/Select.styles.js'
import { HTMLTribeProps } from '../types/index.js'
import { FloatingWrapper } from '../utils/floating/index.js'
import { useMultiselect } from './MultiselectContext.js'

export type MultiselectItemsProps = ComponentProps<'ul'> & {
  variant?: 'default' | 'unstyled'
  noWrapper?: boolean
}
export const MultiselectItems = forwardRef<
  HTMLUListElement,
  MultiselectItemsProps
>((props, ref) => {
  const { children, className, variant = 'default', noWrapper, ...rest } = props
  const { isOpen, getMenuProps } = useMultiselect()

  if (variant === 'unstyled') {
    return (
      <ul
        {...getMenuProps(
          {
            ref,
            ...rest,
          },
          { suppressRefError: true },
        )}
      >
        {children}
      </ul>
    )
  }

  if (noWrapper && isOpen) {
    return (
      <ul
        className={clsx(
          'w-full bg-surface shadow-lg max-h-60 rounded-base py-1 text-base border border-line-subdued overflow-auto',
          'empty:hidden',
          'focus-visible:ring-1 focus:outline-none',
          !isOpen && 'invisible',
          className,
        )}
        {...getMenuProps(
          {
            ref,
            ...rest,
          },
          { suppressRefError: true },
        )}
      >
        {children}
      </ul>
    )
  }

  return (
    <FloatingWrapper open={isOpen} sameWidth>
      <ul
        className={clsx(selectItemsStyles(), className)}
        {...getMenuProps(
          {
            ref,
            ...rest,
          },
          { suppressRefError: true },
        )}
      >
        {children}
      </ul>
    </FloatingWrapper>
  )
})

export type MultiselectItemsEmptyProps = HTMLTribeProps<'div'>
export const MultiselectItemsEmpty: FC<MultiselectItemsEmptyProps> = props => {
  const { children, className, ...rest } = props

  return (
    <div
      className={clsx(
        'text-content-subdued',
        'cursor-default select-none relative py-2 px-3',
        className,
      )}
      {...rest}
    >
      <span className="font-normal block">{children}</span>
    </div>
  )
}
