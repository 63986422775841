import { clsx } from 'clsx'

import { Space } from '@tribeplatform/gql-client/types'
import { HtmlContent } from '@tribeplatform/react-components/HtmlContent'
import type { StaticField } from '@tribeplatform/react-components/Views'

interface Props {
  field: StaticField
  space: Space
  highlighted: boolean
  stretchX: string
  preview: boolean
}
export const CardSpaceDescription = ({
  field,
  space,
  highlighted,
  stretchX,
  preview,
}: Props) => {
  const { default: defaultDescription } = field
  const description = space?.description || (preview ? defaultDescription : '')

  return (
    <div
      className={clsx(
        'empty:hidden break-words min-w-0',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
    >
      <article className="prose">
        <HtmlContent value={description} />
      </article>
    </div>
  )
}
