import { createContext, useContext } from 'react'

import { TabVariant } from './types.js'

export interface TabsContext {
  variant: TabVariant
  fullWidth?: boolean
}

const TabsContext = createContext<TabsContext>(undefined)

export const TabsProvider = ({ variant, fullWidth, children }) => (
  <TabsContext.Provider
    value={{
      variant,
      fullWidth,
    }}
  >
    {children}
  </TabsContext.Provider>
)

export const useTabs = () => {
  const context = useContext(TabsContext)
  if (context === undefined) {
    throw new Error('useTabs must be used within a TabsProvider')
  }
  return context
}
