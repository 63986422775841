import { Controller, useFormContext } from 'react-hook-form'

import {
  PickerFormControl,
  PickerFormControlPostTypeIdProps,
} from '../EntityPicker/PickerFormControl.js'
import { FormFieldProps } from './typings.js'

export function FormPostTypeIdPicker({
  name,
  validation,
  ...rest
}: Omit<
  FormFieldProps<PickerFormControlPostTypeIdProps>,
  'onChange' | 'value'
>) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <PickerFormControl.PostTypeIdPicker
          {...rest}
          name={name}
          invalid={!!error?.message}
          error={error?.message}
          value={value}
          onChange={value => {
            onChange(value)
          }}
        />
      )}
    />
  )
}
