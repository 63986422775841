import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { MemberListBlock as Component } from './Block.js'
import { MemberListProps } from './types.js'

/**
 * @deprecated use RegisteredMembersBlock instead
 */
export const RegisteredMemberListBlock = ({
  $t,
}: I18nShape): RegisteredBlock<MemberListProps> => ({
  recommendedName: 'MemberList',
  Component,
  config: {
    type: BlockType.Block,
    displayName: $t({
      id: 'Generics.Members',
      defaultMessage: '{MEMBERS_CC}',
    }),
    deprecated: true,
    Icon: <SvgIcon name="members" />,
  },
})
