import { twMerge } from 'tailwind-merge'

import { TagPillWithLink } from '../common/components/TagPill.js'

export const TagPills = ({
  tags,
  activeTagId = undefined,
  activeTagSlug = undefined,
  className = '',
  truncate = false,
}) => {
  if (!tags?.length) {
    return null
  }
  return (
    <div className={twMerge('flex flex-wrap gap-2 min-w-0', className)}>
      {tags.map(({ id, title, slug }) => (
        <TagPillWithLink
          className="min-w-0"
          link={`/search?query=${encodeURIComponent(title)}&type=post`}
          active={activeTagId === id || activeTagSlug === slug}
          key={id}
          title={title}
          truncate={truncate}
        />
      ))}
    </div>
  )
}
