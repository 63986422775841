/* eslint-disable import/no-cycle */
import {
  CustomFieldRelationGQLFields,
  ShortcutFields,
  ShortcutGQLFields,
} from '../../types/fields.generated.js'
import { EmbedFields, embedGQLFields } from '../media/embed.fields.js'
import { mediaGQLFields } from '../media/media.fields.js'
import { MediaURLFields } from '../media/mediaURL.fields.js'
import { MemberFields, memberGQLFields } from '../network/member.fields.js'
import {
  PostAuthMemberPropsFields,
  postAuthMemberPropsGQLFields,
} from '../permission/postAuthMemberProps.fields.js'
import {
  PostTypeFields,
  postTypeGQLFields,
} from '../post-type/postType.fields.js'
import { SpaceFields, spaceGQLFields } from '../space/space.fields.js'
import {
  SpaceMemberFields,
  spaceMemberGQLFields,
} from '../space/spaceMember.fields.js'
import { FileFields, fileGQLFields } from './file.fields.js'
import {
  PostLastActivityDetailFields,
  postLastActivityDetailGQLFields,
} from './post-last-activity-detail.fields.js'
import { ReactionFields, reactionGQLFields } from './reaction.fields.js'
import { RepliesFields } from './replies.fields.js'
import { TagFields, tagGQLFields } from './tag.fields.js'

export type PostFields = 'basic' | 'all' | 'default' | CustomPostFields

export interface CustomPostFields {
  postType?: PostTypeFields
  owner?: SpaceMemberFields
  createdBy?: SpaceMemberFields
  tags?: TagFields
  embeds?: EmbedFields
  mentions?: MemberFields
  attachments?: FileFields
  space?: SpaceFields
  image?: MediaURLFields
  banner?: MediaURLFields
  reactions?: ReactionFields
  replies?: RepliesFields
  authMemberProps?: PostAuthMemberPropsFields
  repliedTo?: PostFields
  repliedTos?: PostFields
  shortcuts?: ShortcutFields
  lastActivityDetail?: PostLastActivityDetailFields
  pinnedReplies?: PostFields
  textContent?: boolean
}

export const BASIC_POST_FIELDS: CustomPostFields = {
  owner: 'all',
}
export const ALL_POST_FIELDS: CustomPostFields = {
  authMemberProps: 'all',
  owner: 'all',
  createdBy: 'basic',
  tags: 'basic',
  attachments: 'basic',
  embeds: 'basic',
  mentions: 'basic',
  space: 'basic',
  reactions: { fields: 'basic', variables: { limit: 10 } },
  replies: { fields: 'basic', variables: { limit: 2 } },
  repliedTos: 'basic',
  repliedTo: 'basic',
}
export const DEFAULT_POST_FIELDS: CustomPostFields = {
  tags: 'basic',
  owner: {
    member: { profilePicture: 'basic', badges: 'all' },
  },
  postType: {
    postFields: 'all',
    validReplyTypes: 'all',
  },
  embeds: 'basic',
  mentions: 'basic',
  space: 'default',
  attachments: 'basic',
  authMemberProps: 'all',
  reactions: { fields: 'all', variables: { limit: 10 } },
  repliedTos: 'basic',
  pinnedReplies: {
    mentions: 'basic',
    embeds: 'basic',
    attachments: 'basic',
    owner: {
      member: { profilePicture: 'basic', badges: 'all' },
    },
  },
  replies: {
    fields: {
      authMemberProps: 'all',
      embeds: 'basic',
      mentions: 'basic',
      attachments: 'basic',
      owner: {
        member: { profilePicture: 'basic', badges: 'all' },
      },
      reactions: { fields: 'all', variables: { limit: 10 } },
    },
    variables: {
      limit: 2,
      reverse: true,
    },
  },
}

export function postGQLFields(fields: PostFields): string {
  if (fields === 'basic') {
    fields = BASIC_POST_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_POST_FIELDS
  }
  if (fields === 'default') {
    fields = DEFAULT_POST_FIELDS
  }

  return `
    id
    slug
    mappingFields {
      key
      type
      value
    }
    fields {
      key
      value
      relationEntities {
        ${CustomFieldRelationGQLFields({
          medias: {
            onEmoji: 'all',
            onFile: 'all',
            onImage: 'all',
          },
          members: {
            profilePicture: {
              onImage: {
                urls: 'all',
              },
            },
          },
          posts: 'basic',
          spaces: {
            image: {
              onEmoji: 'all',
              onImage: {
                urls: 'all',
              },
            },
          },
          tags: 'basic',
        })}
      }
    }
    subscribersCount
    postTypeId
    reactionsCount
    hasMoreContent
    isAnonymous
    isHidden
    shortContent
    createdAt
    publishedAt
    ownerId
    createdById
    status
    spaceId
    imageIds
    pinnedInto
    repliesCount
    totalRepliesCount
    locked
    repliedToIds
    repliedToId
    title
    description
    ${fields.textContent ? `textContent` : ``}
    ${
      fields.shortcuts
        ? `
        shortcuts{${ShortcutGQLFields({
          favicon: 'all',
        })}}

        `
        : ''
    }
    thumbnail {
      ${mediaGQLFields()}
    }
    embedIds
    mentionedMembers
    primaryReactionType
    lastActivityAt
    language
    customSeoDetail {
      description
      noIndex
      thumbnail {
        ${mediaGQLFields()}
      }
      thumbnailId
      title
      canonicalUrl
    }
    relativeUrl
    url
    ${
      fields.attachments
        ? `
        attachments {
          ${fileGQLFields()}
        }
      `
        : ''
    }
    ${
      fields.authMemberProps
        ? `
      authMemberProps {
        ${postAuthMemberPropsGQLFields(fields.authMemberProps)}
      }`
        : ``
    }
    ${
      fields.postType
        ? `
      postType {
        ${postTypeGQLFields(fields.postType)}
      }
    `
        : ``
    }
    ${
      fields.owner
        ? `
      owner {
        ${spaceMemberGQLFields(fields.owner)}
      }
    `
        : ``
    }
    ${
      fields.createdBy
        ? `
      createdBy {
        ${spaceMemberGQLFields(fields.createdBy)}
      }
    `
        : ``
    }
    ${
      fields.tags
        ? `
      tags {
        ${tagGQLFields(fields.tags)}
      }
    `
        : ``
    }
    ${
      fields.embeds
        ? `
      embeds {
        ${embedGQLFields(fields.embeds)}
      }
    `
        : ``
    }
    ${
      fields.mentions
        ? `
      mentions {
        ${memberGQLFields(fields.mentions)}
      }
    `
        : ``
    }
    ${
      fields.space
        ? `
      space {
        ${spaceGQLFields(fields.space)}
      }
    `
        : ``
    }
    ${
      fields.reactions
        ? `
      reactions {
        ${reactionGQLFields(fields.reactions)}
      }
    `
        : ``
    }
    ${
      fields.replies?.fields
        ? `
      replies(
          limit: ${fields.replies.variables.limit || 1},
          reverse: ${fields.replies?.variables?.reverse || false},
          orderBy: ${fields.replies?.variables?.orderBy || 'createdAt'}
      ) {
        nodes {
          ${postGQLFields(fields.replies.fields)}
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    `
        : ``
    }
    ${
      fields.repliedTo
        ? `
      repliedTo {
        ${postGQLFields(fields.repliedTo)}
      }
    `
        : ``
    }
    ${
      fields.repliedTos
        ? `
      repliedTos {
        ${postGQLFields(fields.repliedTos)}
      }
    `
        : ``
    }
    ${
      fields.pinnedReplies
        ? `
        pinnedReplies {
        ${postGQLFields(fields.pinnedReplies)}
      }
    `
        : ``
    }
    ${
      fields.lastActivityDetail
        ? `
      lastActivityDetail {
        ${postLastActivityDetailGQLFields(fields.lastActivityDetail)}
      }
    `
        : ``
    }
  `
}
