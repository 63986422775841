import {
  ReportEntitiesFields,
  reportEntitiesGQLFields,
} from './reportEntitiesFields.js'

export type ReportRecordFields = 'basic' | 'all' | CustomReportRecordFields

interface CustomReportRecordFields {
  entities?: ReportEntitiesFields
}

const BASIC_REPORT_PAYLOAD_FIELDS: CustomReportRecordFields = {}
const ALL_REPORT_PAYLOAD_FIELDS: CustomReportRecordFields = {
  entities: 'basic',
}

export const reportRecordGQLFields = (fields: ReportRecordFields) => {
  if (fields === 'basic') fields = BASIC_REPORT_PAYLOAD_FIELDS
  if (fields === 'all') fields = ALL_REPORT_PAYLOAD_FIELDS

  return `
    payload {
      key
      value
    }
    ${
      fields.entities
        ? `
    entities {
      ${reportEntitiesGQLFields(fields?.entities)}
    }
    `
        : ``
    }
  `
}
