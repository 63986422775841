export const getPropertyValue = (
  document: Document,
  property: string,
): string | undefined => {
  if (typeof window === 'undefined') {
    return undefined
  }

  return window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(property)
}

export const setPropertyValue = (
  document: Document,
  property: string,
  value: string,
) => {
  document.documentElement.style.setProperty(property, value)
}
