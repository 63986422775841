import { T } from '@tribeplatform/react-components/i18n'
import { useRouter } from '@tribeplatform/react-sdk'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { NavVertical } from '@tribeplatform/react-ui-kit/Sidebar'

import { useSlate } from '../../../../hooks/slate.hook.js'

interface Props {
  close: () => void
}
export const CreatePostAction = ({ close }: Props) => {
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'
  const { Link } = useRouter()

  return (
    <NavVertical.Item
      as={Link}
      href="/new"
      leadingAddon={<SvgIcon name="plus" size="lg" className="me-3 -ms-1" />}
      onClick={e => {
        if (isPreviewMode) {
          e.preventDefault()
        }

        close()
      }}
    >
      <T defaultMessage="Create post" id="Generics.CreatePost" />
    </NavVertical.Item>
  )
}
