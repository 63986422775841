import {
  ActionFields,
  getNotificationsCountGQLQuery,
  getNotificationsGQLQuery,
  NotificationFields,
  readNotificationGQLMutation,
  readNotificationsGQLMutation,
  deleteNotificationGQLMutation,
  deleteNotificationsGQLMutation,
  clearNotificationsCountGQLMutation,
  unsubscribeFromNotificationGQLMutation,
  updateNetworkNotificationSettingsGQLMutation,
} from '../../graphql/index.js'
import {
  PaginatedNotification,
  QueryNotificationsArgs,
  Scalars,
  MutationReadNotificationArgs,
  Action,
  MutationReadNotificationsArgs,
  MutationDeleteNotificationArgs,
  MutationDeleteNotificationsArgs,
  MutationUnsubscribeFromNotificationArgs,
  MutationUpdateNetworkNotificationSettingsArgs,
  NotificationNetworkSettings,
} from '../../types/index.js'
import { GraphqlClient } from '../graphql.client.js'

export class NotificationsClient {
  private client: GraphqlClient

  constructor(client: GraphqlClient) {
    this.client = client
  }

  async count(accessToken?: string): Promise<Scalars['Float']> {
    type QueryResult = { notificationsCount: { new: Scalars['Float'] } }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getNotificationsCountGQLQuery(),
      customToken: accessToken,
    })
    return result.notificationsCount.new
  }

  async list(
    variables: QueryNotificationsArgs = { limit: 10 },
    fields: NotificationFields = 'basic',
    accessToken?: string,
  ): Promise<PaginatedNotification> {
    type QueryResult = { notifications: PaginatedNotification }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getNotificationsGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.notifications
  }

  async readNotification(
    variables: MutationReadNotificationArgs,
    fields: ActionFields = 'basic',
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { readNotification: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: readNotificationGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.readNotification
  }

  async readNotifications(
    variables: MutationReadNotificationsArgs,
    fields: ActionFields = 'basic',
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { readNotifications: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: readNotificationsGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.readNotifications
  }

  async deleteNotification(
    variables: MutationDeleteNotificationArgs,
    fields: ActionFields = 'basic',
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { deleteNotification: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: deleteNotificationGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.deleteNotification
  }

  async deleteNotifications(
    variables: MutationDeleteNotificationsArgs,
    fields: ActionFields = 'basic',
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { deleteNotifications: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: deleteNotificationsGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.deleteNotifications
  }

  async clearNotificationsCount(
    fields: ActionFields = 'basic',
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { clearNotificationsCount: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: clearNotificationsCountGQLMutation(fields),
      customToken: accessToken,
    })
    return result.clearNotificationsCount
  }

  async unsubscribe(
    variables: MutationUnsubscribeFromNotificationArgs,
    fields: ActionFields = 'basic',
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { unsubscribeFromNotification: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: unsubscribeFromNotificationGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.unsubscribeFromNotification
  }

  async updateNetworkNotificationSettings(
    variables: MutationUpdateNetworkNotificationSettingsArgs,
    accessToken?: string,
  ): Promise<NotificationNetworkSettings> {
    type QueryResult = {
      updateNetworkNotificationSettings: NotificationNetworkSettings
    }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: updateNetworkNotificationSettingsGQLMutation(),
      variables,
      customToken: accessToken,
    })
    return result.updateNetworkNotificationSettings
  }
}
