import {
  type UseMutationOptions,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query'

import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Action,
  MutationUnhidePostArgs,
} from '@tribeplatform/gql-client/types'

import { useTribeClient } from '../../useTribeClient.js'
import { getFeedKey } from '../../utils/keys/feed.keys.js'
import { getPostKey, getPostsKey } from '../../utils/keys/post.key.js'
import { pinnedPostsFilter } from './filters.js'

export const useUnhidePost = (options?: {
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationUnhidePostArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<Action, ClientError, MutationUnhidePostArgs>(
    (variables: MutationUnhidePostArgs) => client.posts.unhide(variables),
    {
      onSettled: (data, error, variables) => {
        const postKey = getPostKey({ variables: { id: variables?.id } })
        queryClient.invalidateQueries(postKey)

        const postsKey = getPostsKey()
        queryClient.invalidateQueries(postsKey)
        queryClient.invalidateQueries({ ...pinnedPostsFilter, type: 'active' })

        const feedKey = getFeedKey()
        queryClient.invalidateQueries(feedKey)
      },
      ...useMutationOptions,
    },
  )
}
