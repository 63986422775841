import { clsx } from 'clsx'

import type { RegisteredBlockProps } from '../../types/block.types.js'
import { HeroBanner } from './Components/HeroBanner.js'
import { type HeroBannerProps } from './types.js'

export const HeroBannerBlock = ({
  'data-block-id': dataBlockId,
  'data-block-name': dataBlockName,
  className,
  ...props
}: RegisteredBlockProps<HeroBannerProps>) => {
  return (
    <div
      className={clsx('@container', className)}
      data-block-id={dataBlockId}
      data-block-name={dataBlockName}
    >
      <HeroBanner {...props} />
    </div>
  )
}
