import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../../types/block.types.js'
import { COLUMN_BLOCK_NAME } from '../constants.js'
import { Spacing } from '../types.js'
import { ColumnBlock } from './Block.js'
import { ColumnBlockSettings } from './Settings.js'
import { ColumnProps } from './types.js'

export const RegisteredColumnBlock = (
  intl: I18nShape,
): RegisteredBlock<ColumnProps> => {
  const { $t } = intl
  return {
    recommendedName: COLUMN_BLOCK_NAME,
    Component: ColumnBlock,
    config: {
      type: BlockType.Layout,
      displayName: $t({
        id: 'Blocks.Column.DisplayName',
        defaultMessage: 'Column',
      }),
      usable: false,
      editable: true,
      removable: false,
      copyDisabled: true,
      fullSidebarSettings: true,
      Settings: ColumnBlockSettings,
      acceptsChildren: true,
      initialProps: {
        gap: Spacing.Medium,
        horizontalPadding: Spacing.None,
        verticalPadding: Spacing.None,
        horizontalMargin: Spacing.None,
        verticalMargin: Spacing.None,
      },
    },
  }
}
