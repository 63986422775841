import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'

import { useCMS } from '../CMS/hooks/useCMS.js'
import { sanitizePostType } from '../CMS/utils/utils.js'
import {
  LabelAlignments,
  type ActionBar,
  type ActionVariants,
} from '../Views/types.js'
import { ActionRenderer } from './components/ActionRenderer.js'
import { getActionBar } from './utils/actionBar.js'

interface Props {
  post: Post
  actionBar?: ActionBar
  variant?: ActionVariants
}
export const PostActionBar = ({
  post,
  actionBar: maybeActionBar,
  variant,
}: Props) => {
  const postTypeId = post?.postTypeId
  const { postTypes } = useCMS()
  const maybePostType = postTypes?.find(({ id }) => id === postTypeId)
  const sanitizedPostType = sanitizePostType(maybePostType)
  const { actions, label, labelAlignment } = getActionBar({
    customActionBar: maybeActionBar,
    postType: sanitizedPostType,
    variant,
  })

  return (
    <div className="flex-1 space-y-3">
      {!!label && (
        <div
          className={clsx(
            'text-sm text-content-subdued',
            labelAlignment === LabelAlignments.start && 'text-start',
            labelAlignment === LabelAlignments.center && 'text-center',
            labelAlignment === LabelAlignments.end && 'text-end',
          )}
        >
          {label}
        </div>
      )}
      <div className="flex flex-wrap gap-2">
        {actions.map(action => (
          <ActionRenderer key={action.id} action={action} post={post} />
        ))}
      </div>
    </div>
  )
}
