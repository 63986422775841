import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'
import { MemberAvatarPlaceholder } from '@tribeplatform/react-components/common/components/member'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { useRouter } from '@tribeplatform/react-sdk'
import { Avatar, legacySizeMapper } from '@tribeplatform/react-ui-kit/Avatar'

interface Props {
  field: StaticField
  post: Post
  highlighted: boolean
  mediaUrl: string
}
export const ListAvatar = ({ field, post, highlighted, mediaUrl }: Props) => {
  const { Link } = useRouter()
  return (
    <div className="shrink-0">
      <Link
        href={post?.owner?.member?.relativeUrl}
        onClick={e => e.stopPropagation()}
        className="block"
        rounded="avatar"
      >
        <Avatar
          size={legacySizeMapper(field.settings?.avatarSize ?? 'lg')}
          src={mediaUrl}
          name={post?.owner?.member?.name}
          className={clsx(
            highlighted && 'ring-2 ring-offset-surface ring-focused',
          )}
          fallback={MemberAvatarPlaceholder}
        />
      </Link>
    </div>
  )
}
