import { useMemo } from 'react'

import { Image } from '@tribeplatform/gql-client/types'
import { useStaticIntl } from '@tribeplatform/react-components/i18n'
import { useSpace } from '@tribeplatform/react-sdk/hooks'

import { staticFieldsFactory } from '../config.js'
import {
  MediaType,
  SpaceHeaderPreset,
  type SpaceHeaderProps,
} from '../types.js'
import { sanitizeStoredFields } from '../utils/converters.js'
import { MediaFullPresetRenderer } from './Presets/MediaFullPresetRenderer.js'
import { MediaTopPresetRenderer } from './Presets/MediaTopPresetRenderer.js'

export const SpaceHeader = (props: SpaceHeaderProps) => {
  const { preset, useSpaceBanner, image, fields, spaceId, mediaType } = props
  const { data: space } = useSpace({
    variables: {
      id: spaceId,
    },
  })
  const staticFields = useStaticIntl(staticFieldsFactory)
  const adjustedProps: SpaceHeaderProps = useMemo(() => {
    return {
      ...props,
      image: useSpaceBanner ? (space?.banner as Image) : image,
      // To not lose the media type of the selected preset, when useSpaceBanner setting
      // is toggled, we keep the media type of the preset as is but pass Image type as a
      // prop. So when toggled back, the preset is rendered with the correct media type.
      mediaType:
        useSpaceBanner && mediaType !== MediaType.None
          ? MediaType.Image
          : mediaType,
      fields: sanitizeStoredFields(fields, staticFields),
    }
  }, [
    props,
    useSpaceBanner,
    space?.banner,
    image,
    mediaType,
    fields,
    staticFields,
  ])

  if (!space) {
    return null
  }

  switch (preset) {
    case SpaceHeaderPreset.MediaFullIconTop:
    case SpaceHeaderPreset.MediaFullIconCenter:
    case SpaceHeaderPreset.MediaFullNoIcon:
    case SpaceHeaderPreset.NoMediaIconTop:
    case SpaceHeaderPreset.NoMediaCenter:
      return <MediaFullPresetRenderer {...adjustedProps} />
    case SpaceHeaderPreset.NoMediaIconLeft:
      return <MediaTopPresetRenderer {...adjustedProps} />
    case SpaceHeaderPreset.MediaTopIconTop:
      return <MediaTopPresetRenderer {...adjustedProps} moveIconToTop />
    default: {
      const _exhaustiveCheck: never = preset
      return _exhaustiveCheck
    }
  }
}
