import type { Post } from '@tribeplatform/gql-client/types'
import type { StaticField, View } from '@tribeplatform/react-components/Views'
import { GridList } from '@tribeplatform/react-ui-kit/Layout'

import { getColumns } from '../utils/index.js'
import { PostsGalleryItem } from './PostsGalleryItem.js'

interface Props {
  posts: Post[]
  fields: StaticField[]
  gallerySize: View['gallerySize']
  activeFieldId?: string
  showMoreButton: JSX.Element
  linkTo?: string
  singlePostType?: boolean
  preview: boolean
}
export const PostsGallery = ({
  posts,
  fields,
  gallerySize = 'medium',
  activeFieldId,
  showMoreButton,
  linkTo,
  singlePostType,
  preview,
}: Props) => {
  const columns = getColumns(gallerySize)

  return (
    <>
      <GridList columns={columns}>
        {posts.map(post => (
          <PostsGalleryItem
            key={post.id}
            post={post}
            linkTo={linkTo}
            fields={fields.filter(
              field =>
                field.enabled &&
                !(field.styles && field.styles.indexOf('gallery') === -1),
            )}
            activeFieldId={activeFieldId}
            singlePostType={singlePostType}
            preview={preview}
          />
        ))}
      </GridList>
      {!!showMoreButton && <div className="mt-2">{showMoreButton}</div>}
    </>
  )
}
