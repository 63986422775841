import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import {
  FlaggedType,
  ModerationStatus,
  SpaceJoinRequestStatus,
} from '@tribeplatform/gql-client/types'
import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { useRouter } from '@tribeplatform/react-sdk'
import {
  useAuthToken,
  usePostModerationItems,
  useSpace,
  useSpaceMembershipRequests,
} from '@tribeplatform/react-sdk/hooks'
import { Alert } from '@tribeplatform/react-ui-kit/Alert'

export const PendingModerationAlert = ({ spaceId = undefined }) => {
  const { $t } = useI18n()
  const {
    data: { network },
  } = useAuthToken()

  const { data: space } = useSpace({
    variables: { id: spaceId },
    useQueryOptions: {
      enabled: !!spaceId,
    },
  })
  const { Link } = useRouter()

  const [canUpdateNetwork] = hasScopesPermission(network, ['updateNetwork'])
  const [canGetModerations] = hasScopesPermission(network, ['getModerations'])
  const [isSpaceAdmin] = hasScopesPermission(space, ['updateSpace'])
  const hasModerationPermission =
    canUpdateNetwork || canGetModerations || isSpaceAdmin

  const {
    data: moderationItemsData,
    isInitialLoading: moderationItemsLoading,
  } = usePostModerationItems({
    fields: 'basic',
    variables: {
      limit: 0,
      spaceId,
      status: ModerationStatus.REVIEW,
      flaggedBy: FlaggedType.SYSTEM,
    },
    useInfiniteQueryOptions: {
      enabled: hasModerationPermission,
    },
  })

  const { data: memberRequestsData, isInitialLoading: memberRequestsLoading } =
    useSpaceMembershipRequests({
      variables: {
        spaceId,
        status: SpaceJoinRequestStatus.PENDING,
      },
      fields: {},
      useQueryOptions: {
        enabled: hasModerationPermission && !!spaceId,
      },
    })

  const totalPendingPosts = moderationItemsData?.pages?.[0]?.totalCount || 0
  const totalMemberRequests = memberRequestsData?.length || 0
  const totalModerationItems = totalPendingPosts + totalMemberRequests

  if (
    !hasModerationPermission ||
    typeof window === 'undefined' ||
    !totalModerationItems ||
    moderationItemsLoading ||
    memberRequestsLoading
  ) {
    return null
  }

  return (
    <Alert
      className="shadow-card"
      title={$t({
        defaultMessage: 'There are items pending moderation.',
        id: 'Moderation.Alert.Title',
      })}
      status="warning"
    >
      <div className="space-s-4">
        {totalPendingPosts > 0 && (
          <Link
            href={
              space?.id
                ? `/settings/moderation/${space?.id}/pending-posts`
                : '/settings/moderation'
            }
          >
            <T
              defaultMessage="View {pending_posts, number} pending {pending_posts, plural, =1 {post} other {posts}}"
              id="Moderation.Alert.PendingPosts"
              values={{
                pending_posts: totalPendingPosts,
              }}
            />
          </Link>
        )}
        {totalMemberRequests > 0 && (
          <Link href={`/settings/moderation/${space?.id}/member-requests`}>
            <T
              defaultMessage="View {member_requests, number} {MEMBER} {member_requests, plural, =1 {request} other {requests}}"
              id="Moderation.Alert.PendingMembers"
              values={{
                member_requests: totalMemberRequests,
              }}
            />
          </Link>
        )}
      </div>
    </Alert>
  )
}
