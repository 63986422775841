import type { StaticField } from '@tribeplatform/react-components/Views'

import type { QuickLinksSource } from '../types.js'
import { QuickLinksCardItem } from './QuickLinksCardItem.js'

interface Props {
  sources: QuickLinksSource[]
  fields: StaticField[]
  activeFieldId?: string
  preview: boolean
}
export const QuickLinksCard = ({
  sources,
  fields,
  activeFieldId,
  preview,
}: Props) => {
  return (
    <div className="space-y-5">
      {sources.map(source => (
        <QuickLinksCardItem
          key={source.id}
          source={source}
          fields={fields.filter(
            field =>
              field.enabled &&
              !(field.styles && field.styles.indexOf('card') === -1),
          )}
          activeFieldId={activeFieldId}
          preview={preview}
        />
      ))}
    </div>
  )
}
