import { useCallback, useEffect, useRef, useState } from 'react'

import { nanoid } from 'nanoid'
import { useParams } from 'react-router'

import {
  AppInteractionType,
  PermissionContext,
} from '@tribeplatform/gql-client/types'
import { useRouterPath, useTribeClient } from '@tribeplatform/react-sdk'
import { useAuthMember } from '@tribeplatform/react-sdk/hooks'
import {
  InteractionEventPayload,
  ReloadInteractionEventPayload,
} from '@tribeplatform/react-sdk/types'
import { SlateDto } from '@tribeplatform/slate-kit/dtos'

import { SlateRenderer } from '../../components/index.js'
import { useSlate } from '../../hooks/index.js'
import {
  BC,
  CallbackExtraContext,
  SlateExtraContext,
} from '../../types/index.js'
import { LoadingComponent } from './LoadingComponent.js'
import { DynamicBlockProps } from './types.js'
import type { UrlParamsType } from './types.js'

export const DynamicBlock: BC<DynamicBlockProps> = ({
  appId,
  blockKey,
  context: customContext,
  entityId: customEntityId,
  staffOnly,
  props,
}) => {
  const interactionId = useRef(nanoid())
  const urlParams = useParams<UrlParamsType>()
  const pathname = useRouterPath()
  const {
    mode,
    context: slateContext,
    extraContext: parentExtraContext,
  } = useSlate()
  const [slate, setSlate] = useState<SlateDto>(undefined)
  const { data: member } = useAuthMember({ fields: 'basic' })

  const hidden = staffOnly && !member?.teammate

  const { config } = useTribeClient()
  const {
    interactionsContext: { useInteractions, interactionEmitter },
  } = config ?? {}

  const { loadInteractions } = useInteractions({
    dynamicBlockKey: blockKey,
  })

  type GetContextAndEntity = () => {
    permissionContext: PermissionContext
    entityId: string
  }
  const getContextAndEntity = useCallback<GetContextAndEntity>(() => {
    if (slateContext && !customContext) {
      if (slateContext?.memberId) {
        return {
          permissionContext: PermissionContext.MEMBER,
          entityId: slateContext.memberId,
        }
      }

      if (slateContext?.postTypeId) {
        return {
          permissionContext: PermissionContext.POST,
          entityId: slateContext.postTypeId,
        }
      }

      if (slateContext?.postId) {
        return {
          permissionContext: PermissionContext.POST,
          entityId: slateContext.postId,
        }
      }

      if (slateContext?.spaceId) {
        return {
          permissionContext: PermissionContext.SPACE,
          entityId: slateContext.spaceId,
        }
      }
    }

    return {
      permissionContext: customContext ?? PermissionContext.NETWORK,
      entityId: customEntityId,
    }
  }, [slateContext, customEntityId, customContext])

  const { permissionContext, entityId } = getContextAndEntity() ?? {}

  const extraContext: SlateExtraContext<CallbackExtraContext> = {
    ...parentExtraContext,
    slate: {
      ...parentExtraContext.slate,
      callback: async (id, options = {}) => {
        const { data } = options
        await callLoadInteractions({
          callbackId: id,
          inputs: data,
          props,
          // We don't catch errors here because we want the error to be thrown
          // and handled by the caller of the callback
          onError: error => {
            throw error
          },
        })
      },
    },
  }

  const callLoadInteractions = useCallback(
    async (
      options: {
        callbackId?: string
        inputs?: Record<string, unknown>
        props?: Record<string, unknown>
        onError?: (error: Error) => void
      } = {},
    ) => {
      const { callbackId, inputs, props, onError } = options
      try {
        await loadInteractions({
          interactionId: interactionId.current,
          appId,
          permissionContext,
          entityId,
          preview: mode !== 'live',
          dynamicBlockKey: blockKey,
          callbackId,
          inputs,
          props,
        })
      } catch (error) {
        if (onError) {
          onError(error)
        }
      }
    },
    [appId, entityId, permissionContext, blockKey, loadInteractions, mode],
  )

  useEffect(() => {
    if (!hidden) {
      callLoadInteractions({ props })
    }
  }, [hidden, appId, urlParams?.appSlug, props, callLoadInteractions])

  useEffect(() => {
    const showListener = (args: InteractionEventPayload) => {
      if (args.interactionId === interactionId.current) {
        if (args.slate) {
          setSlate(args.slate)
        }
      }
    }
    const reloadListener = (args: ReloadInteractionEventPayload) => {
      if (
        args.interactionId === interactionId.current ||
        args.props?.dynamicBlockKeys?.includes(blockKey)
      ) {
        callLoadInteractions()
      }
    }

    if (interactionEmitter) {
      if (interactionId || blockKey) {
        interactionEmitter.on(AppInteractionType.Show, showListener)
        interactionEmitter.on(AppInteractionType.Reload, reloadListener)
      }
    }

    return () => {
      if (interactionEmitter) {
        if (interactionId || blockKey) {
          interactionEmitter.removeListener(
            AppInteractionType.Show,
            showListener,
          )
          interactionEmitter.removeListener(
            AppInteractionType.Reload,
            reloadListener,
          )
        }
      }
    }
  }, [
    interactionId,
    blockKey,
    loadInteractions,
    callLoadInteractions,
    interactionEmitter,
  ])

  if (hidden) {
    return null
  }

  if (!slate) {
    return <LoadingComponent />
  }

  return (
    <SlateRenderer
      slate={slate}
      context={{
        path: pathname,
        urlParams,
        ...slateContext,
        permissionContext,
        appId,
        dynamicBlockKey: blockKey,
      }}
      extraContext={extraContext}
    />
  )
}
