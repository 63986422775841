import { DocumentNode } from 'graphql'
import { gql } from 'graphql-tag'

export function getApiRateLimitStatsGQLQuery(): DocumentNode {
  return gql`
    query ApiRateLimitStats {
      apiRateLimitStats {
        remainingPoints
        resetsAt
        duration
        consumableType
        limit
      }
    }
  `
}

export function getApiUsagesGQLQuery(): DocumentNode {
  return gql`
    query ApiUsages {
      apiUsages {
        total
        usageType
        date
        values {
          value
          clientId
          clientName
        }
      }
    }
  `
}
