import { useState } from 'react'

import { clsx } from 'clsx'
import InfiniteScroll from 'react-infinite-scroller'

import type {
  PostReactionDetail,
  PostReactionParticipant,
  CustomReaction,
} from '@tribeplatform/gql-client/types'
import { usePostReactionParticipants } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'
import { Tabs } from '@tribeplatform/react-ui-kit/Tabs'

import { useI18n } from '../i18n/providers/I18nProvider.js'
import { ReactionMember, ReactionMemberLoading } from './ReactionMember.js'
import { ReactionRenderer } from './ReactionRenderer.js'

interface Props {
  open: boolean
  onClose: () => void
  reactions: PostReactionDetail[]
  customReactions: CustomReaction[]
  postId: string
}
export const ReactionsModal = ({
  open,
  onClose,
  reactions,
  customReactions,
  postId,
}: Props) => {
  const { $t } = useI18n()
  const [activeTab, setActiveTab] = useState<number>(0)
  const [activeReaction, setActiveReaction] = useState<string>(
    reactions?.length ? reactions[0]?.reaction : '',
  )

  const {
    data,
    fetchNextPage = false,
    hasNextPage,
    isLoading,
  } = usePostReactionParticipants({
    variables: {
      limit: 5,
      postId,
      reaction: activeReaction,
    },
    fields: 'all',
  })

  const { nodes: participants } =
    simplifyPaginatedResult<PostReactionParticipant>(data)

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Content padding={false}>
        <div className="flex border-b-2 border-line-subdued rounded-t-lg">
          <div className="flex-grow overflow-y-auto no-scrollbar ms-3">
            <Tabs.Group
              onChange={index => {
                setActiveTab(index)
                setActiveReaction(reactions[index]?.reaction)
              }}
              defaultIndex={activeTab}
            >
              <Tabs.List variant="filled">
                {reactions?.map(reaction => (
                  <Tabs.Tab key={reaction?.reaction} name={reaction?.reaction}>
                    <div className="flex items-center gap-x-4">
                      <ReactionRenderer
                        reaction={reaction}
                        customReactions={customReactions}
                        label={$t({
                          defaultMessage: 'Reactions',
                          id: 'Generics.Reactions',
                        })}
                      />
                      <span>{reaction.count}</span>
                    </div>
                  </Tabs.Tab>
                ))}
              </Tabs.List>
            </Tabs.Group>
          </div>
          <div className="flex cursor-pointer mx-3" onClick={onClose}>
            <SvgIcon size="xl" className="m-auto" name="close" />
          </div>
        </div>

        <ul className="h-[calc(100vh_-_44px)] min-h-[30rem] sm:h-fit overflow-y-auto bg-surface rounded-b-lg">
          <InfiniteScroll
            pageStart={0}
            loadMore={fetchNextPage}
            hasMore={hasNextPage || isLoading}
            threshold={800}
            useWindow={false}
            loader={<ReactionMemberLoading />}
          >
            {participants?.map(p => (
              <li
                key={p.participant.id}
                className={clsx('flex hover:bg-surface-hovered')}
              >
                <ReactionMember member={p.participant} />
              </li>
            ))}
          </InfiniteScroll>
        </ul>
      </Modal.Content>
    </Modal>
  )
}
