import type { SVGProps } from 'react'

export type CalendarItem = {
  name: string
  icon?: SVGProps<SVGSVGElement>
  url?: string
  type?: CalendarType
}
export type AddToCalendarButtonProps = {
  event: CalendarEvent
}
export type CalendarEvent = {
  title: string
  description?: string
  location?: string
  start: string
  end: string
}
export enum CalendarType {
  ICS = 'ics',
  APPLE = 'apple',
  GOOGLE = 'google',
  YAHOO = 'yahoo',
  OUTLOOK = 'outlook',
}
export type CalendarItemProps = {
  actionItem: CalendarItem
  event: CalendarEvent
}
