import { BlockIcon as Icon } from '@tribeplatform/react-ui-kit/Icon'

import { BC } from '../../types/index.js'
import { OutlineIcon } from './OutlineIcon.js'
import { SolidIcon } from './SolidIcon.js'
import { IconBlockProps, SupportedIconNames } from './types.js'

export const IconBlock: BC<IconBlockProps> = ({
  name = SupportedIconNames.RefreshIcon,
  size = 'md',
  color = 'default',
  iconType = 'outline',
  ...rest
}: IconBlockProps) => {
  return (
    <Icon color={color} size={size} {...rest}>
      {iconType === 'solid' ? (
        <SolidIcon name={name} />
      ) : (
        <OutlineIcon name={name} />
      )}
    </Icon>
  )
}
