import { useDomain } from '../hooks/useDomain.js'

export const Image = ({ src, alt = '', ...rest }) => {
  const { getResourceUrl } = useDomain()
  if (src?.indexOf('/') === 0 && src?.indexOf('//') !== 0) {
    src = getResourceUrl(`${src}`)
  }

  return <img src={src} alt={alt} {...rest} />
}
