import {
  NetworkImagesFields,
  NetworkImagesGQLFields,
  NetworkUsageLimitsGQLFields,
} from '../../types/fields.generated.js'
import { MediaFields, mediaGQLFields } from '../media/index.js'
import {
  NetworkAuthMemberPropsFields,
  networkAuthMemberPropsGQLFields,
} from '../permission/index.js'
import {
  PostTypeFields,
  postTypeGQLFields,
} from '../post-type/postType.fields.js'
import { NetworkExtraPropertiesFields } from '../properties/index.js'
import {
  navigationSlatesFields,
  navigationSlatesGQLFields,
} from '../slate/index.js'
import { SpaceFields, spaceGQLFields } from '../space/index.js'
import {
  NewThemeFields,
  newThemesGQLFields,
  ThemesFields,
  themesGQLFields,
} from '../theme/index.js'
import { BadgeFields, badgeGQLFields } from './badge.fields.js'
import { CustomCodeFields } from './customCode.fields.js'
import {
  CustomFieldsSchemaFields,
  customFieldsSchemaGQLFields,
} from './customFieldsSchema.fields.js'
import { customCodeGQLFields, topNavigationGQLFields } from './network.gql.js'
import { RoleFields, roleGQLFields } from './role.fields.js'
import { TopNavigationFields } from './topNavigation.fields.js'

export type NetworkFields = 'basic' | 'all' | 'default' | CustomNetworkFields

export interface CustomNetworkFields {
  navigationSlates?: navigationSlatesFields
  activeTheme?: NewThemeFields
  themes?: ThemesFields
  topNavigation?: TopNavigationFields
  logo?: MediaFields
  favicon?: MediaFields
  whoCanInvite?: RoleFields
  defaultSpaces?: SpaceFields
  roles?: RoleFields
  customCode?: CustomCodeFields
  authMemberProps?: NetworkAuthMemberPropsFields
  memberFields?: CustomFieldsSchemaFields
  systemSpaces?: SpaceFields
  customSeoDetail?: 'basic' | 'all'
  extraProperties?: NetworkExtraPropertiesFields
  badges?: BadgeFields
  settings?: 'basic' | 'all'
  usages?: 'basic' | 'all'
  limits?: 'basic' | 'all'
  postTypes?: PostTypeFields
  images?: NetworkImagesFields
}

const BASIC_NETWORK_FIELDS: CustomNetworkFields = {}
const ALL_NETWORK_FIELDS: CustomNetworkFields = {
  activeTheme: 'basic',
  themes: 'basic',
  topNavigation: 'basic',
  logo: 'basic',
  favicon: 'basic',
  whoCanInvite: 'basic',
  defaultSpaces: 'basic',
  roles: 'basic',
  customCode: 'basic',
  authMemberProps: 'basic',
  systemSpaces: 'basic',
  memberFields: 'basic',
  customSeoDetail: 'basic',
  badges: 'basic',
  limits: 'basic',
  usages: 'basic',
  settings: 'basic',
  postTypes: 'basic',
  images: 'all',
}
const DEFAULT_NETWORK_FIELDS: CustomNetworkFields = {
  authMemberProps: { permissions: 'all' },
  customCode: 'all',
  favicon: 'all',
  logo: 'all',
  roles: 'all',
  navigationSlates: 'all',
  themes: 'all',
  activeTheme: 'all',
  topNavigation: 'all',
  systemSpaces: {
    slate: 'all',
  },
  memberFields: 'all',
  customSeoDetail: 'all',
  badges: 'default',
  settings: 'basic',
  postTypes: {
    icon: 'basic',
    authMemberProps: {
      availableSpaces: {
        image: 'basic',
        authMemberProps: {
          spaceMember: {
            role: 'basic',
          },
        },
      },
    },
    postFields: {
      fields: 'all',
    },
    validReplyTypes: 'basic',
    slate: 'all',
  },
  extraProperties: {
    variables: {},
  },
  images: {
    lightLogo: 'all',
    lightMobileLogo: 'all',
    lightFavicon: 'all',
    darkLogo: 'all',
    darkMobileLogo: 'all',
    darkFavicon: 'all',
  },
}

export function networkGQLFields(fields: NetworkFields): string {
  if (fields === 'basic') {
    fields = BASIC_NETWORK_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_NETWORK_FIELDS
  }
  if (fields === 'default') {
    fields = DEFAULT_NETWORK_FIELDS
  }

  return `
    id
    aliases
    companyName
    name
    description
    visibility
    membership
    logoId
    faviconId
    locale
    availableLocales
    domain
    createdAt
    newDomain
    incidentEmails
    privacyPolicyUrl
    termsOfServiceUrl
    brandColor
    billingEmail
    status
    memberCapacity
    memberCapacityDeclared
    passwordComplexity
    seatsCapacity
    additionalSeatsCapacity
    seatCapacityDeclared
    tribeBranding
    referralProgramEnabled
    referralLink
    whoCanInviteIds
    hideDefaultAuthenticationForm
    releaseChannel
    domainSubfolder
    willUnpublishAt
    landingPages {
      landingPageForGuest
      landingPageForMember
      landingPageForNewMember
    }
    activeSso {
      logoutUrl
      settingsUrl
    }
    ${
      fields.settings
        ? `
    settings {
      emailEnabled
      emailFrom
      emailNotificationEnabled
      emailDigestEnabled
      fileSizeLimit
      fileTypesLimit
      imageSizeLimit
      imageTypesLimit
      emailDigestRecentPostsFilter {
        filterOption
        spaceIds
      }
      emailDigestLayoutId
      contentTranslation {
        enabled
      }
      memberPermissions {
        canDeleteAccount
      }
      privateMessaging {
        privateMessagingEnabled
        membersCanUsePrivateMessaging
        membersCanTurnOffPrivateMessaging
      }
      captcha {
        enabled
        siteKey
        secretKey
      }
    }
    `
        : ``
    }
    ${
      fields.limits
        ? `
    limits {
      fileSizeLimit
      fileTypesLimit
      imageSizeLimit
      imageTypesLimit
      analyticsLimits {
        planLimits {
          daysLimitation
          plan
        }
      }
      usageLimits {
        ${NetworkUsageLimitsGQLFields('all')}
      }
    }
    `
        : ``
    }
    ${
      fields.usages
        ? `
    usages {
      usages {
        membersCapacity
        monthlyEmailCapacity
        monthlyVisitorCapacity
        postTypesCapacity
        seatsCapacity
        spacesCapacity
        storageCapacity
        translationLocaleCapacity
      }
    }
    `
        : ``
    }
    ${
      fields.navigationSlates
        ? `
      navigationSlates {
        ${navigationSlatesGQLFields(fields.navigationSlates)}
      }
    `
        : ``
    }
    ${
      fields.activeTheme
        ? `
      activeTheme {
        ${newThemesGQLFields(fields.activeTheme)}
      }
    `
        : ``
    }
    ${
      fields.themes
        ? `
      themes {
        ${themesGQLFields(fields.themes)}
      }
    `
        : ``
    }
    ${
      fields.topNavigation
        ? `
      topNavigation {
        ${topNavigationGQLFields(fields.topNavigation)}
      }
    `
        : ``
    }
    ${
      fields.logo
        ? `
      logo {
        ${mediaGQLFields(fields.logo)}
      }
    `
        : ``
    }
    ${
      fields.favicon
        ? `
      favicon {
        ${mediaGQLFields(fields.favicon)}
      }
    `
        : ``
    }
    ${
      fields.whoCanInvite
        ? `
      whoCanInvite {
        ${roleGQLFields(fields.whoCanInvite)}
      }
    `
        : ``
    }
    ${
      fields.defaultSpaces
        ? `
      defaultSpaces {
        ${spaceGQLFields(fields.defaultSpaces)}
      }
    `
        : ``
    }
    ${
      fields.roles
        ? `
      roles {
        ${roleGQLFields(fields.roles)}
      }
    `
        : ``
    }
    ${
      fields.customCode
        ? `
      customCodes(anonymize: false) {
        ${customCodeGQLFields(fields.customCode)}
      }
    `
        : ``
    }
    ${
      fields.authMemberProps
        ? `
      authMemberProps {
        ${networkAuthMemberPropsGQLFields(fields.authMemberProps)}
      }
    `
        : ``
    }
    ${
      fields.systemSpaces
        ? `
      systemSpaces {
        ${spaceGQLFields(fields.systemSpaces)}
      }
    `
        : ``
    }
    ${
      fields.memberFields
        ? `
      memberFields {
        ${customFieldsSchemaGQLFields(fields.memberFields)}
      }
    `
        : ``
    }
    ${
      fields?.customSeoDetail
        ? `
      customSeoDetail {
        description
        noIndex
        thumbnail {
          ${mediaGQLFields('basic')}
        }
        thumbnailId
        title
      }
    `
        : ``
    }
    ${
      fields?.extraProperties
        ? `
      extraProperties ${
        fields?.extraProperties?.variables?.keys
          ? `(keys: ["${fields.extraProperties.variables.keys.join('","')}"])`
          : ``
      } {
        key
        value
      }
    `
        : ``
    }
    ${
      fields.postTypes
        ? `
      postTypes {
        ${postTypeGQLFields(fields.postTypes)}
      }
    `
        : ``
    }
    ${
      fields?.badges
        ? `
      badges {
        ${badgeGQLFields(fields?.badges)}
      }
    `
        : ``
    }
    ${
      fields?.images
        ? `
      images {
        ${NetworkImagesGQLFields(fields?.images)}
      }
    `
        : ``
    }
  `
}
