import { useMemo, useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { NetworkVisibility, RoleType } from '@tribeplatform/gql-client/types'

import {
  generateLoginUrl,
  isNoAuthPath,
} from '../../../server/middlewares/auth/utils/permission.utils.js'

export const usePrivateNetworkGuard = (
  roleType: RoleType,
  networkVisibility: NetworkVisibility,
): void => {
  const { search, pathname } = useLocation()
  const navigate = useNavigate()
  const authNotRequired = useMemo(() => isNoAuthPath(pathname), [pathname])
  const isGuest = roleType === RoleType.guest

  useEffect(() => {
    if (authNotRequired || !isGuest) {
      return
    }
    const searchParams = new URLSearchParams(search)

    // Prevent automatic login attempts (SSO, SAML, etc.) if instructed to do so
    const disableAutoLogin = searchParams.get('auto_login') === 'false'
    searchParams.delete('auto_login')

    // Redirect guests to the login page if auth=true query param exists
    const forceAuthentication = searchParams.get('auth') === 'true'
    searchParams.delete('auth')

    const forwardParams = searchParams.toString().length
      ? `?${searchParams.toString()}`
      : ''

    const redirectParam = `${pathname}${forwardParams}`
    if (forceAuthentication) {
      // navigate method returns void, but consistent-return does not understand it.
      // eslint-disable-next-line consistent-return
      return navigate(
        generateLoginUrl({
          redirect: redirectParam,
          disableAutoLogin,
        }),
      )
    }

    // Checking for guest users visiting a private network
    if (networkVisibility === NetworkVisibility.private) {
      // navigate method void, but consistent-return does not understand it.
      // eslint-disable-next-line consistent-return
      return navigate(
        generateLoginUrl({
          redirect: redirectParam,
          disableAutoLogin,
        }),
      )
    }
    // We don't want to run the hook if search changes, hence ignoring it from this list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, pathname, authNotRequired, isGuest, networkVisibility])
}
