import { useSpace } from '@tribeplatform/react-sdk/hooks'

import { useJoinSpace } from '../../hooks/useJoinSpace.js'
import { JoinButton } from './JoinButton.js'
import { LeaveButton } from './LeaveButton.js'

interface Props {
  showText?: boolean
  showIcon?: boolean
  className?: string
  spaceId: string
}

export const JoinSpaceButton = ({
  showText,
  showIcon,
  className,
  spaceId,
}: Props) => {
  const { data: space } = useSpace({
    variables: {
      id: spaceId,
    },
  })
  const {
    joinSpace,
    joined,
    joining,
    leaving,
    leaveSpace,
    permissions,
    status,
  } = useJoinSpace({ spaceId })

  return (
    <>
      {joined ? (
        <LeaveButton
          space={space}
          showIcon={showIcon}
          showText={showText}
          onClick={leaveSpace}
          loading={joining}
          className={className}
        />
      ) : (
        <JoinButton
          showIcon={showIcon}
          showText={showText}
          permission={permissions}
          loading={leaving}
          onClick={joinSpace}
          status={status}
          className={className}
        />
      )}
    </>
  )
}
