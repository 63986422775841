import { LimitedIntlShape } from '../types.js'

const MemberPluralRule =
  ({ $t }: LimitedIntlShape) =>
  chunks =>
    $t(
      {
        defaultMessage: '{member_count, plural, =1 {member} other {members}}',
        id: 'RESOURCE.Member.Pluralization',
      },
      {
        member_count: chunks[0],
      },
    )

const MemberCapitalPluralRule =
  ({ $t }: LimitedIntlShape) =>
  chunks =>
    $t(
      {
        defaultMessage: '{member_count, plural, =1 {Member} other {Members}}',
        id: 'RESOURCE.Member.Pluralization.Capital',
      },
      {
        member_count: chunks[0],
      },
    )

export const MemberResourceRules = (intl: LimitedIntlShape) => {
  const memberPlural = MemberPluralRule(intl)
  const memberCapitalPlural = MemberCapitalPluralRule(intl)
  return {
    'MEMBER.PLURAL': memberPlural,
    MEMBER: memberPlural([1]),
    MEMBERS: memberPlural([5]),
    'MEMBER_CC.PLURAL': memberCapitalPlural,
    MEMBER_CC: memberCapitalPlural([1]),
    MEMBERS_CC: memberCapitalPlural([5]),
  }
}
