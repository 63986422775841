import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'
import { analyticsNumberFormatter } from '@tribeplatform/react-components/Analytics/utils'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

interface Props {
  post: Post
  highlighted: boolean
  stretchX: string
}
export const ReplyCount = ({ post, highlighted, stretchX }: Props) => {
  return (
    <div
      className={clsx(
        'flex space-s-2 text-content-subdued items-center',
        'text-sm',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
    >
      <SvgIcon className="h-[1.25em] w-[1.25em]" name="reply" />
      <span>{analyticsNumberFormatter(post?.totalRepliesCount ?? '0')}</span>
    </div>
  )
}
