import { useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { RoleType } from '@tribeplatform/gql-client/types'

const guestOnlyPaths = [
  '/auth/login',
  '/auth/login/direct',
  '/auth/signup',
  '/auth/accept',
  '/auth/join/',
  '/auth/reset-password',
  '/auth/forgot-password',
]

export const useRedirectAuthUser = (roleType: RoleType): void => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (roleType === RoleType.guest) {
      return
    }

    const isGuestOnlyPath = guestOnlyPaths.some(guestPath =>
      pathname.startsWith(guestPath),
    )
    if (isGuestOnlyPath) {
      navigate('/', { replace: true })
    }
  }, [navigate, roleType, pathname])
}
