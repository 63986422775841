import { Post } from '@tribeplatform/gql-client/types'
import { confirm } from '@tribeplatform/react-ui-kit/Dialog'

import { I18nShape } from '../i18n/types.js'

export const confirmActionOnLockedPost =
  ({ $t }: I18nShape) =>
  async (post: Post) => {
    if (post?.locked) {
      const confirmed = await confirm({
        title: $t({
          id: 'Post.Utils.PostIsLockedConfirmationTitle',
          defaultMessage: 'This post is locked',
        }),
        description: $t({
          id: 'Post.Utils.PostIsLockedConfirmationDescription',
          defaultMessage:
            'As a community staff, you can perform actions on this post although it’s locked. Are you sure you want to continue?',
        }),
        proceedLabel: $t({
          id: 'Generics.Continue',
          defaultMessage: 'Continue',
        }),
      })
      return confirmed
    }
    return true
  }
