import {
  Post,
  PostListFilterByEnum,
  PostListFilterByOperator,
} from '@tribeplatform/gql-client/types'
import { EntityImage } from '@tribeplatform/react-components/common/components'
import { T } from '@tribeplatform/react-components/i18n'
import { useRouter } from '@tribeplatform/react-sdk'
import { useAuthToken, usePosts } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { IconButton } from '@tribeplatform/react-ui-kit/Button'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { List } from '@tribeplatform/react-ui-kit/Layout'

export const Drafts = ({ postTypeId }) => {
  const {
    data: { member, network },
  } = useAuthToken()
  const { Link, push, location } = useRouter()

  const getPostTypeIcon = (post: Post) => {
    const postType = network?.postTypes?.find(
      postType => postType.id === post?.postTypeId,
    )
    return postType?.icon
  }

  const { data } = usePosts({
    variables: {
      postTypeIds: postTypeId ? [postTypeId] : undefined,
      limit: 50,
      filterBy: [
        {
          keyString: 'status',
          key: PostListFilterByEnum.publishedAt,
          operator: PostListFilterByOperator.equals,
          value: JSON.stringify('DRAFTED'),
        },
        {
          keyString: 'createdById',
          key: PostListFilterByEnum.publishedAt,
          operator: PostListFilterByOperator.equals,
          value: JSON.stringify(member?.id),
        },
      ],
    },
    fields: 'basic',
  })

  const drafts = simplifyPaginatedResult<Post>(data)
  return (
    <Card className="w-full">
      <Card.Header
        title={
          <div className="flex space-s-3 items-center">
            <IconButton
              variant="tertiaryNeutral"
              rounded
              size="lg"
              icon={<SvgIcon className="rtl:-scale-x-100" name="arrow-left" />}
              onClick={() => {
                if (location?.key) {
                  window?.history?.back()
                } else {
                  push('/')
                }
              }}
            ></IconButton>
            <span className="text-xl flex-1">
              <T id="Generics.YourDrafts" defaultMessage="Your drafts" />
            </span>
          </div>
        }
      />
      <Card.Content>
        <List divider spacing="none" className="border-t border-line-subdued">
          {drafts.nodes.map(draft => {
            const postTypeIcon = getPostTypeIcon(draft)
            return (
              <List.Item key={draft.id}>
                <Link
                  variant="inherit"
                  className="hover:bg-surface-hovered px-4 py-5 sm:p-6 flex space-s-3 items-center"
                  href={`${draft?.relativeUrl}/edit`}
                >
                  {postTypeIcon ? (
                    <EntityImage
                      size="xl"
                      entity={{ image: postTypeIcon, name: draft?.title }}
                    />
                  ) : (
                    <SvgIcon className="w-6 h-6" name="post" />
                  )}
                  <h4 className="text-lg font-medium flex-1">{draft?.title}</h4>
                  <SvgIcon
                    className="w-6 h-6 rtl:-scale-x-100"
                    name="chevron-right"
                  />
                </Link>
              </List.Item>
            )
          })}
        </List>
      </Card.Content>
    </Card>
  )
}
