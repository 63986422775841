import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { QuickLinksBlock as Component } from './Block.js'
import { defaultQuickLinksItemsFactory } from './constants.js'
import { QuickLinksProps } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.QuickLinksBlockSettings })),
)

export const RegisteredQuickLinksBlock = (
  intl: I18nShape,
): RegisteredBlock<QuickLinksProps> => {
  const { $t } = intl
  const defaultQuickLinksItems = defaultQuickLinksItemsFactory(intl)

  return {
    recommendedName: 'QuickLinks',
    Component,
    config: {
      type: BlockType.Block,
      usable: false,
      displayName: $t({
        id: 'Blocks.QuickLinks.DisplayName',
        defaultMessage: 'Quick links',
      }),
      Settings,
      Icon: <SvgIcon name="bookmark" />,
      defaultProps: {
        alignment: 'left',
        actionType: 'link',
        horizontalScroll: true,
        gridCols: 2,
        items: defaultQuickLinksItems,
      },
    },
  }
}
