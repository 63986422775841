import { useEffect, useMemo } from 'react'

import type { Member, Network } from '@bettermode/common/gql/generated'

import {
  ActorAnalyticsContext,
  SnowplowTrackerFactory,
  TargetAnalyticsContext,
} from '../lib/snowplow.js'

function getLang() {
  if (typeof window === 'undefined') {
    return undefined
  }

  if (Array.isArray(navigator?.languages)) {
    return navigator.languages[0]
  }

  return navigator?.language
}

const unsetContextValues = (
  context:
    | Partial<ActorAnalyticsContext>
    | Partial<TargetAnalyticsContext> = {},
) => {
  return Object.keys(context).reduce((acc, key: string) => {
    acc[key] = undefined
    return acc
  }, {})
}

interface UseTracker {
  actor?: Partial<ActorAnalyticsContext>
  target?: Partial<TargetAnalyticsContext>
  memberId?: Member['id']
  memberRoleId?: Member['role']['id']
  memberRoleType?: Member['role']['type']
  memberLocale?: Member['locale']
  networkId?: Network['id']
  organizationId?: Network['organizationId']
}

export const useTracker = ({
  actor = {},
  target = {},
  memberId,
  memberRoleId,
  memberRoleType,
  memberLocale,
  networkId,
  organizationId,
}: UseTracker) => {
  const targetDefaultContext: TargetAnalyticsContext = useMemo(
    () => ({
      organizationId: organizationId || '',
      networkId,
    }),
    [networkId, organizationId],
  )

  const actorDefaultContext: ActorAnalyticsContext = useMemo(
    () => ({
      id: memberId || '',
      roleId: memberRoleId || '',
      roleType: memberRoleType || '',
      locale: memberLocale || getLang(),
    }),
    [memberId, memberLocale, memberRoleId, memberRoleType],
  )

  const tracker = useMemo(() => {
    return SnowplowTrackerFactory('tribe-analytics', {})
  }, [])

  useEffect(() => {
    tracker.setActor({ ...actorDefaultContext, ...actor })
    tracker.setTarget({ ...targetDefaultContext, ...target })

    return () => {
      tracker.setActor(unsetContextValues(actor))
      tracker.setTarget(unsetContextValues(target))
    }
  }, [tracker, actor, target, actorDefaultContext, targetDefaultContext])

  return tracker
}
