import type { StaticField, View } from '@tribeplatform/react-components/Views'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { List } from '@tribeplatform/react-ui-kit/Layout'

import type { QuickLinksSource } from '../types.js'
import { QuickLinksListItem } from './QuickLinksListItem.js'

interface Props {
  sources: QuickLinksSource[]
  fields: StaticField[]
  showDivider: View['showDivider']
  activeFieldId?: string
  preview: boolean
}
export const QuickLinksList = ({
  sources,
  fields,
  showDivider = true,
  activeFieldId,
  preview,
}: Props) => {
  return (
    <Card padding="md">
      <Card.Content>
        <List direction="vertical" divider={showDivider} spacing="lg">
          {sources.map(source => (
            <List.Item key={source.id}>
              <QuickLinksListItem
                source={source}
                fields={fields.filter(
                  field =>
                    field.enabled &&
                    !(field.styles && field.styles.indexOf('list') === -1),
                )}
                activeFieldId={activeFieldId}
                preview={preview}
              />
            </List.Item>
          ))}
        </List>
      </Card.Content>
    </Card>
  )
}
