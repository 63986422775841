import {
  MassActionRequest,
  MassActionRequestAction as OriginalMassActionRequestAction,
} from '@tribeplatform/gql-client/types'

enum ExtraMassActionRequestAction {
  UpdateOwner = 'UpdateOwner',
  UpdatePublishedAt = 'UpdatePublishedAt',
}

export type MassActionRequestAction =
  | OriginalMassActionRequestAction
  | ExtraMassActionRequestAction

export const MassActionRequestAction = {
  ...OriginalMassActionRequestAction,
  ...ExtraMassActionRequestAction,
}

export type MassActionModalState =
  | { type: 'closed' }
  | {
      type: 'confirm'
      massActionRequest: MassActionRequest
    }
  | {
      type: 'immediate'
      action: MassActionRequestAction
    }
