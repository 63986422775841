import { forwardRef } from 'react'

import { clsx } from 'clsx'

import { Icon } from '../Icon/index.js'
import { SvgIcon } from '../Icon/SvgIcon.js'
import type { IconComponentType } from '../Icon/types.js'
import { Tooltip } from '../Tooltip/index.js'
import type { HTMLTribeProps } from '../types/index.js'
import {
  baseButtonStyles,
  buttonIconStyles,
  iconButtonSizeStyles,
} from './Button.styles.js'
import type { IconButtonStyleProps } from './types.js'

export type IconButtonProps = Omit<HTMLTribeProps<'button'>, 'title'> &
  IconButtonStyleProps & {
    icon: IconComponentType
    accessibilityLabel?: string
    tooltip?: boolean
    pressed?: boolean
  }
/**
 * The Button component is used to trigger an action or event, such as submitting a form, opening a dialog,
 * canceling an action, or performing a delete operation.
 */
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      as,
      variant = 'primary',
      size = 'lg',
      disabled,
      loading,
      destructive,
      rounded,
      className,
      icon,
      accessibilityLabel,
      tooltip,
      pressed,
      ...rest
    },
    ref,
  ) => {
    const Component = as || 'button'

    /**
     * This type issue exists due to a component design flaw
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((as as any)?.name === 'Link') {
      rest = { ...rest, ...{ variant: 'inherit' } }
    }

    const button = (
      <Component
        ref={ref}
        type={as ? undefined : 'button'}
        aria-label={accessibilityLabel}
        title={tooltip ? undefined : accessibilityLabel}
        className={clsx(
          'inline-block',
          iconButtonSizeStyles({
            size,
            rounded,
          }),
          baseButtonStyles({
            variant,
            loading,
            destructive,
            className,
          }),
        )}
        disabled={disabled}
        aria-pressed={pressed}
        {...rest}
      >
        <Icon
          className={clsx(
            buttonIconStyles({
              size,
            }),
            'mx-auto',
            loading && 'invisible',
          )}
        >
          {icon}
        </Icon>
        {loading && (
          <span className="absolute inset-0 inline-flex items-center justify-center">
            <SvgIcon className="animate-spin" name="spinner" />
          </span>
        )}
      </Component>
    )

    if (tooltip) {
      return (
        <Tooltip>
          <Tooltip.Trigger asChild>{button}</Tooltip.Trigger>
          <Tooltip.Panel>{accessibilityLabel}</Tooltip.Panel>
        </Tooltip>
      )
    }
    return button
  },
)
