import { clsx } from 'clsx'

import { CloseIcon } from '@bettermode/icons/line/Close'

type ImageAltProps = {
  description: string
  imageId: string
}

export const ImageAlt = (props: ImageAltProps) => {
  const { description, imageId } = props

  return (
    <>
      <input aria-hidden type="checkbox" id={imageId} hidden className="peer" />
      <div className="absolute bottom-2 start-2">
        <label
          aria-hidden
          htmlFor={imageId}
          className="block cursor-pointer rounded-sm bg-black px-1 text-xs font-medium text-white"
          // eslint-disable-next-line react/jsx-no-literals
        >
          ALT
        </label>
      </div>
      {/* The secondary label acts as a backdrop to allow dismissing the alt text when clicked on image */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label
        aria-hidden
        htmlFor={imageId}
        className="absolute h-0 w-0 start-0 top-0 peer-checked:w-full peer-checked:h-full"
      />
      <div
        aria-hidden
        className={clsx(
          // Base alignments
          'absolute -bottom-0.5 end-0.5 w-full max-h-full overflow-y-auto no-scrollbar invisible',
          // Rest state
          'opacity-0',
          // Theme classes
          'bg-black/70 backdrop-blur-sm text-white text-xs',
          // Animation options
          'transition-all duration-200',
          // Active state
          'peer-checked:bottom-0 peer-checked:end-0 peer-checked:opacity-100 peer-checked:visible',
        )}
      >
        <p className="px-3 pt-2 pb-0 !mb-0" dir="auto">
          {description}
        </p>
        <div className="flex justify-end sticky bottom-0 p-3 bg-gradient-to-t from-black/50">
          <label
            aria-hidden
            htmlFor={imageId}
            className="flex grow-0 items-center cursor-pointer border border-white rounded-full text-xs p-1 bg-black"
          >
            <CloseIcon />
          </label>
        </div>
      </div>
    </>
  )
}
