import { Card } from '@tribeplatform/react-ui-kit/Card'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Link } from '@tribeplatform/react-ui-kit/Link'

import { T } from '../../i18n/components/T.js'

export const SpaceAboutError = () => {
  return (
    <Card>
      <Card.Content>
        <div className="space-y-2 items-center flex flex-col justify-center">
          <SvgIcon
            className="w-4 h-4 text-content-subdued"
            name="status-info"
          />
          <div className="text-sm text-content-subdued">
            <T
              id="Generics.CouldNotConnectToServer"
              defaultMessage="Could not connect to server."
            />
          </div>
          <Link className="text-sm">
            <T id="Generics.TryAgain" defaultMessage="Try again" />
          </Link>
        </div>
      </Card.Content>
    </Card>
  )
}
