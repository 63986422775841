import { lazy, Suspense, type ReactNode } from 'react'

import { useFirstRenderContext } from '../common/ssr/FirstRenderProvider.js'
import { MediaModalProvider, useMediaModal } from './MediaModalProvider.js'

const MediaModal = lazy(() =>
  import('./MediaModal.js').then(m => ({ default: m.MediaModal })),
)
type MediaModalBoundaryProps = {
  title?: ReactNode
  /**
   * Default: true
   */
  allowDownload?: boolean
  children: ReactNode
}

export const MediaModalBoundary = ({
  title,
  allowDownload = true,
  children,
}: MediaModalBoundaryProps) => {
  return (
    <MediaModalProvider allowDownload={allowDownload} title={title}>
      {children}
      <MediaModalBoundaryInner />
    </MediaModalProvider>
  )
}

const MediaModalBoundaryInner = () => {
  const { isVisible, slidesRef } = useMediaModal()
  const { isFirstRender } = useFirstRenderContext()
  const shouldRender = !isFirstRender && isVisible && slidesRef.current.length
  if (!shouldRender) {
    return null
  }
  return (
    <Suspense fallback={<></>}>
      <MediaModal />
    </Suspense>
  )
}
