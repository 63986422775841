import { useCallback } from 'react'

import { useNavigate as useNavigateRouter } from 'react-router'

import { useAuthToken } from '@tribeplatform/react-sdk/hooks'

import {
  getIsInternal,
  getLinkFeatures,
  getLinkTarget,
  parseHref,
} from '../utils/index.js'

export const useNavigate = () => {
  const {
    data: { networkPublicInfo },
  } = useAuthToken()
  const navigateRouter = useNavigateRouter()

  const navigate = useCallback(
    (href: string, target?: HTMLAnchorElement['target']) => {
      const parsedHref = parseHref(href, networkPublicInfo)
      const isInternal = getIsInternal(parsedHref)
      const linkTarget = getLinkTarget(target, isInternal)
      const windowFeatures = getLinkFeatures(linkTarget)

      if (linkTarget === '_blank') {
        window.open(parsedHref, linkTarget, windowFeatures)
        return
      }

      if (isInternal) {
        navigateRouter(parsedHref)
        return
      }

      window.open(parsedHref, linkTarget, windowFeatures)
    },
    [navigateRouter, networkPublicInfo],
  )

  return navigate
}
