export class RenderError {
  name = 'RenderError'

  message: string = ''

  stack = ''

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(options: { message: any; error?: Error; name?: string }) {
    const { message, error, name } = options
    this.message = message
    this.stack = (error || new Error()).stack
    this.name = name || this.name
  }
}
