import { useMemo, useRef, useState } from 'react'

import InfiniteScroll from 'react-infinite-scroller'

import type { Member } from '@tribeplatform/gql-client/types'
import {
  useAuthMember,
  useMembers,
  useNetworkRoles,
} from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { InlineMultiselect } from '@tribeplatform/react-ui-kit/Multiselect'

import { MemberAvatar } from '../../../common/components/index.js'
import { useDebounce } from '../../../common/hooks/useDebounce.js'
import { MemberPickerProps } from '../../../EntityPicker/MemberPicker.js'
import { useI18n, T } from '../../../i18n/index.js'
import { INLINE_MULTI_SELECT_PAGE_SIZE } from '../../utils/inlineFilters.js'

interface Props {
  value: string[]
  memberGroup?: MemberPickerProps['memberGroup']
  className?: string
  onChange: (memberIds: string[]) => void
}
export const InlineMemberIdPicker = ({
  value,
  memberGroup,
  className,
  onChange,
}: Props) => {
  const { $t } = useI18n()
  const { data: member, isAdmin, isModerator, isGuest } = useAuthMember()
  const dataRef = useRef<Member[]>(undefined)
  const [searchInput, setSearchInput] = useState('')
  const query = useDebounce(searchInput, 500)
  const networkRoles = useNetworkRoles()

  const roleIds = useMemo(() => {
    switch (memberGroup) {
      case 'member':
        return [networkRoles.member.id]
      case 'staff':
        return [networkRoles.admin.id, networkRoles.moderator.id]
      case 'all':
      default: {
        return undefined
      }
    }
  }, [
    memberGroup,
    networkRoles.admin,
    networkRoles.member,
    networkRoles.moderator,
  ])

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isInitialLoading,
  } = useMembers({
    fields: { profilePicture: 'basic' },
    variables: {
      limit: INLINE_MULTI_SELECT_PAGE_SIZE,
      query: searchInput ? query : '',
      roleIds,
    },
  })

  const members = useMemo(() => {
    const prevData = dataRef.current
    if (prevData && !data && isInitialLoading) {
      return prevData
    }

    if (!data && !prevData) {
      return []
    }

    const { nodes } = simplifyPaginatedResult<Member>(data)
    if (query || isGuest) {
      dataRef.current = nodes
      return nodes
    }

    if (memberGroup === 'staff' && !isAdmin && !isModerator) {
      dataRef.current = nodes
      return nodes
    }

    if (memberGroup === 'member' && (isAdmin || isModerator)) {
      dataRef.current = nodes
      return nodes
    }

    const membersExceptAuthMember = nodes.filter(({ id }) => id !== member.id)
    const membersWithAuthMemberOnTop = [member, ...membersExceptAuthMember]
    dataRef.current = membersWithAuthMemberOnTop
    return membersWithAuthMemberOnTop
  }, [
    data,
    isAdmin,
    isGuest,
    isInitialLoading,
    isModerator,
    member,
    memberGroup,
    query,
  ])

  const handleClick = (memberId: string) => {
    if (value.includes(memberId)) {
      const newValue = value.filter(id => id !== memberId)
      onChange(newValue)
      return
    }

    const newValue = [...value, memberId]
    onChange(newValue)
  }

  return (
    <InlineMultiselect className={className}>
      <InlineMultiselect.SearchBar
        value={searchInput}
        placeHolder={$t({
          defaultMessage: 'Search...',
          id: 'Generics.SearchDotDotDot',
        })}
        onChange={setSearchInput}
      />
      <InlineMultiselect.Items isLoading={isInitialLoading}>
        {members.length === 0 && isInitialLoading && (
          <div className="text-sm text-content-subdued flex justify-center">
            <SvgIcon className="animate-spin" size="lg" name="spinner" />
          </div>
        )}
        {!isInitialLoading && members.length === 0 && (
          <div className="text-sm text-content-subdued">
            <T id="Generics.NoResults" defaultMessage="No results" />
          </div>
        )}
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchNextPage}
          useWindow={false}
          hasMore={hasNextPage ?? false}
        >
          {members.map(member => (
            <InlineMultiselect.Item
              key={member.id}
              selected={value.includes(member.id)}
              onSelect={() => handleClick(member.id)}
            >
              <div className="flex items-center gap-x-2 h-6">
                <MemberAvatar size="xl" member={member} />
                <div className="flex-1 truncate text-base">{member.name}</div>
              </div>
            </InlineMultiselect.Item>
          ))}
          {isFetchingNextPage && (
            <div className="flex justify-center">
              <SvgIcon className="animate-spin" size="lg" name="spinner" />
            </div>
          )}
        </InfiniteScroll>
      </InlineMultiselect.Items>
    </InlineMultiselect>
  )
}
