import { ClientError as CError } from 'graphql-request'

export enum ApiErrorCodes {
  UNKNOWN_ERROR = '1',
  SERVER_ERROR = '10',
  PARAMETER_VALIDATION_FAILED = '100',
  PERMISSION_ERROR = '102',
  USER_REQUEST_REACHED = '104',
  INVALID_PARAMETER = '106',
  INVALID_ACCESS_TOKEN = '108',
  RESOURCE_NOT_FOUND = '110',
  MISSING_PARAMETER = '112',
  PARAMETER_TYPE_PROBLEM = '114',
  DATABASE_ERROR = '116',
  INVALID_REQUEST = '118',
  REQUEST_BLOCKED_FOR_POLICIES = '120',
  BACKEND_ERROR = '122',
}

export type ErrorResponse = {
  code: ApiErrorCodes
  field?: string
  message: string
  subcode?: string
  help?: string
  timestamp?: string
}

interface ClientResponse<T = any> {
  data?: T
  errors?: ErrorResponse[]
  extensions?: any
  status: number
  [key: string]: any
}

export type ClientError = Omit<CError, 'response'> & {
  response: ClientResponse
}

export type ClientFileError = {
  fileName?: string
  message?: string
}
export type ClientFileErrorHandler = (error: ClientFileError) => void

export const errObj = (e: any) => {
  // removing errors and inserting the rest
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { code, message, field, subcode, timestamp, help, errors, ...rest } =
    e || {}
  return {
    code,
    message,
    field,
    help,
    subcode,
    timestamp,
    ...rest,
  } as ErrorResponse
}

export const flattenErrors = (
  e: Array<unknown>,
  errors?: Array<ErrorResponse>,
): Array<ErrorResponse> => {
  const isRoot = !errors
  const output = errors || []
  if (Array.isArray(e)) {
    const arr = e
      .map(_e => {
        if ((_e as any)?.errors) {
          flattenErrors((_e as any).errors, output)
        } else if (!isRoot || (_e as any)?.message) {
          return errObj(_e)
        }
        return undefined
      })
      .filter(Boolean)
    output.push(...arr)
  } else if ((e as any)?.code || (e as any)?.message) {
    output.push(errObj(e))
  }

  if (isRoot) {
    if (output.length === 0) {
      output.push(errObj(e))
    }
  }

  return output
}

export const isClientError = (payload: unknown): payload is ClientError => {
  if (!payload) {
    return false
  }
  if (
    typeof payload === 'object' &&
    'response' in (payload as ClientError) &&
    'errors' in (payload as ClientError).response
  ) {
    return true
  }
  return false
}

export const isNotFound = (error: unknown) => {
  if (isClientError(error)) {
    return error.response.errors.some(
      error => String(error.code) === String(ApiErrorCodes.RESOURCE_NOT_FOUND),
    )
  }
  return false
}

export const isForbidden = (error: unknown) => {
  if (isClientError(error)) {
    return error.response.errors.some(
      error => String(error.code) === String(ApiErrorCodes.PERMISSION_ERROR),
    )
  }
  return false
}
