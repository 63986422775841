import type { SpaceFields } from '@tribeplatform/gql-client/graphql'
import type {
  QuerySpacesByIdsArgs,
  Space,
} from '@tribeplatform/gql-client/types'

import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSpacesByIdsKey } from '../../utils/keys/space.key.js'
import { useCachedSpaces } from '../cache/useCachedSpace.js'

export const useSpacesByIds = (options?: {
  variables: QuerySpacesByIdsArgs
  fields?: SpaceFields
  useQueryOptions?: UseQueryOptions<Space[]>
}) => {
  const { variables, fields = 'basic', useQueryOptions } = options || {}
  const { client } = useTribeClient()
  const cachedSpaces = useCachedSpaces(variables.ids.map(id => ({ id })))
  const spacesByIdsKey = getSpacesByIdsKey({ variables, fields })

  return useQuery<Space[]>(
    spacesByIdsKey,
    () => client?.spaces.listByIds(variables, fields),
    { placeholderData: () => cachedSpaces, ...useQueryOptions },
  )
}
