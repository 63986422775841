import { ComponentProps } from 'react'

import { clsx } from 'clsx'

import { DragHandle } from './SortableItem.js'

export type DefaultViewProps = ComponentProps<'div'> & {
  disabled?: boolean
}
export const DefaultView = ({
  children,
  disabled,
  className,
}: DefaultViewProps) => {
  return (
    <div
      className={clsx(
        'truncate flex space-s-2 items-center',
        'border border-line-subdued rounded-base p-2 px-2 bg-surface ',
        !disabled && 'hover:bg-surface-hovered',
        className,
      )}
    >
      <DragHandle disabled={disabled} />
      <div className="grow min-w-0 flex space-s-2 items-center">{children}</div>
    </div>
  )
}
