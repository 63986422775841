import { QuerySpacesArgs } from '../../types/tribe-graphql.generated.js'
import { SpaceFields, spaceGQLFields } from './space.fields.js'

export type CollectionFields = 'basic' | 'all' | CustomCollectionFields

export interface CustomCollectionFields {
  spaces?: {
    fields: SpaceFields
    variables?: QuerySpacesArgs
  }
}

const BASIC_COLLECTION_FIELDS: CustomCollectionFields = {}
const ALL_COLLECTION_FIELDS: CustomCollectionFields = {
  spaces: {
    fields: 'basic',
    variables: { limit: 100 },
  },
}

export function collectionGQLFields(fields: CollectionFields) {
  if (fields === 'basic') {
    fields = BASIC_COLLECTION_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_COLLECTION_FIELDS
  }

  return `
    id
    slug
    name
    description
    createdAt
    relativeUrl
    url
    ${
      fields.spaces
        ? `
      spaces(
        limit: ${fields?.spaces?.variables?.limit || 100}
        ${
          fields?.spaces?.variables?.memberId
            ? `,memberId: "${fields?.spaces?.variables?.memberId}"`
            : ''
        }
        ${
          fields?.spaces?.variables?.exploreOnly
            ? `,exploreOnly: ${fields?.spaces?.variables?.exploreOnly}`
            : ''
        }
      ) {
        totalCount
        edges {
          node {
            ${spaceGQLFields(fields.spaces?.fields)}
          }
        }
        nodes {
          ${spaceGQLFields(fields.spaces?.fields)}
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    `
        : ``
    }
  `
}
