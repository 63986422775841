import {
  createContext,
  FC,
  MutableRefObject,
  useContext,
  ReactNode,
} from 'react'

type ScrollContext = {
  scrollRef: MutableRefObject<HTMLElement>
}

const ScrollContext = createContext<ScrollContext>({
  scrollRef: null,
})

export const ScrollProvider: FC<{
  children: ReactNode
  scrollRef: MutableRefObject<HTMLElement>
}> = ({ children, scrollRef }) => {
  return (
    <ScrollContext.Provider value={{ scrollRef }}>
      {children}
    </ScrollContext.Provider>
  )
}

export const useScroll = () => useContext(ScrollContext)
