import type { LimitedIntlShape } from '../types.js'

const CommunityPluralRule =
  ({ $t }: LimitedIntlShape) =>
  chunks =>
    $t(
      {
        defaultMessage:
          '{community_count, plural, =1 {community} other {communities}}',
        id: 'RESOURCE.Community.Pluralization',
      },
      {
        community_count: chunks[0],
      },
    )

const CommunityCapitalPluralRule =
  ({ $t }: LimitedIntlShape) =>
  chunks =>
    $t(
      {
        defaultMessage:
          '{community_count, plural, =1 {Community} other {Communities}}',
        id: 'RESOURCE.Community.Pluralization.Capital',
      },
      {
        community_count: chunks[0],
      },
    )

export const CommunityResourceRules = (intl: LimitedIntlShape) => {
  const communityPlural = CommunityPluralRule(intl)
  const communityCapitalPlural = CommunityCapitalPluralRule(intl)
  return {
    'COMMUNITY.PLURAL': communityPlural,
    COMMUNITY: communityPlural([1]),
    COMMUNITIES: communityPlural([5]),
    'COMMUNITY_CC.PLURAL': communityCapitalPlural,
    COMMUNITY_CC: communityCapitalPlural([1]),
    COMMUNITIES_CC: communityCapitalPlural([5]),
  }
}
