import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { Space } from '@tribeplatform/gql-client/types'
import { T } from '@tribeplatform/react-components/i18n'
import { useLeaveSpace } from '@tribeplatform/react-sdk/hooks'
import { Button, ButtonSize } from '@tribeplatform/react-ui-kit/Button'

interface Props {
  space: Space
  size?: ButtonSize
  fullWidth?: boolean
}
export const SpaceLeaveButton = ({
  space,
  size = 'lg',
  fullWidth = false,
}: Props) => {
  const { mutate: leave, isLoading: leaving } = useLeaveSpace()
  const [canLeaveSpace] = hasScopesPermission(space, ['leaveSpace'])

  if (!canLeaveSpace) {
    return null
  }

  return (
    <Button
      fullWidth={fullWidth}
      disabled={leaving}
      loading={leaving}
      variant="secondaryNeutral"
      size={size}
      onClick={() => {
        leave({ spaceId: space.id })
      }}
    >
      <T id="Generics.Leave" defaultMessage="Leave" />
    </Button>
  )
}
