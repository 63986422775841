import { clsx } from 'clsx'

import type {
  File as FileType,
  Image as ImageType,
} from '@tribeplatform/gql-client/types'
import { Video, Image } from '@tribeplatform/react-components/common/components'

import { MediaType, type TextColor } from '../types.js'
import { getTintStyle } from '../utils/utils.js'

interface Props {
  mediaType: MediaType
  image: ImageType
  video: FileType
  mediaTint: number
  textColor: TextColor
  className?: string
}
export const MediaRenderer = ({
  mediaType,
  image,
  video,
  mediaTint,
  textColor,
  className,
}: Props) => {
  const tintStyle = getTintStyle(mediaTint, textColor)

  if (mediaType === MediaType.image && !!image) {
    return (
      <div className="w-full h-full absolute inset-0 z-0">
        <Image
          src={image?.urls?.large ?? image?.url}
          className={clsx(
            'w-full h-full object-cover object-center',
            className,
          )}
        />
        <div className="w-full h-full absolute inset-0" style={tintStyle}></div>
      </div>
    )
  }

  if (mediaType === MediaType.video && !!video) {
    return (
      <div className="w-full h-full absolute inset-0 z-0">
        <Video
          src={video.url}
          className={clsx(
            'w-full h-full object-cover object-center',
            className,
          )}
        />
        <div className="w-full h-full absolute inset-0" style={tintStyle}></div>
      </div>
    )
  }

  return null
}
