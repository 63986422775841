export enum SystemSpaceSlug {
  LOGIN = 'default::login',
  DIRECT_LOGIN = 'default::direct-login',
  SIGNUP = 'default::signup',
  JOIN_BY_INVITATION = 'default::join-with-invite',
  ACCEPT_INVITATION = 'default::accept',
  FORGOT_PASSWORD = 'default::forgot-password',
  RESET_PASSWORD = 'default::reset-password',
  VERIFY_EMAIL = 'default::verify',
  MEMBER = 'default::member',
  POST = 'default::post',
  COLLECTION = 'default::collection',
  SEARCH = 'default::search',
  NOTIFICATIONS = 'default::notifications',
  PROFILE_SETTINGS = 'default::profile-settings',
  NOT_FOUND = 'default::not-found',
  HOME = 'default::home',
  MEMBERS = 'default::members',
  SPACES = 'default::spaces',
}
