import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'
import { BannerPlaceholder } from '@tribeplatform/react-components/common/components'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { useRouter } from '@tribeplatform/react-sdk'

import { getLinkToUrl, getMediaUrl } from '../utils/index.js'

interface Props {
  field: StaticField
  post: Post
  highlighted: boolean
  aspectRatio: StaticField['settings']['aspectRatio']
  linkTo: string
  preview: boolean
  isSinglePost: boolean
}
export const CardBanner = ({
  // eslint-disable-next-line unused-imports/no-unused-vars
  field,
  post,
  highlighted,
  aspectRatio,
  linkTo,
  preview,
  isSinglePost,
}: Props) => {
  const { Link } = useRouter()
  const mediaUrl = getMediaUrl({ field, post, quality: 'full' })

  if (!mediaUrl && !preview) {
    return null
  }

  const fieldMediaUrl = mediaUrl

  const linkToUrl = getLinkToUrl({ linkTo, post })

  if (isSinglePost) {
    return (
      <div
        className={clsx(
          'w-full relative',
          aspectRatio || 'aspect-video',
          highlighted && 'border-2 border-focused',
        )}
      >
        {!fieldMediaUrl ? (
          <div className="absolute inset-0">
            <BannerPlaceholder name={post?.relativeUrl} />
          </div>
        ) : (
          <div
            className="w-full h-full bg-cover bg-center absolute top-0 start-0"
            style={{ backgroundImage: `url(${fieldMediaUrl})` }}
          ></div>
        )}
      </div>
    )
  }

  return (
    <Link href={linkToUrl} variant="inherit" tabIndex={-1}>
      <div
        className={clsx(
          'w-full relative',
          aspectRatio || 'aspect-video',
          highlighted && 'border-2 border-focused',
        )}
      >
        {!fieldMediaUrl ? (
          <div className="absolute inset-0">
            <BannerPlaceholder name={post?.relativeUrl} />
          </div>
        ) : (
          <div
            className="w-full h-full bg-cover bg-center absolute top-0 start-0"
            style={{ backgroundImage: `url(${fieldMediaUrl})` }}
          ></div>
        )}
      </div>
    </Link>
  )
}
