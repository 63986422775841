import { useMemo } from 'react'

import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'
import { PostInfoBar } from '@tribeplatform/react-components/Post'
import type { StaticField } from '@tribeplatform/react-components/Views'

interface Props {
  field: StaticField
  post: Post
  highlighted: boolean
  stretchX: string
  preview: boolean
  isPostPage: boolean
}
export const InfoBarField = ({
  field,
  post,
  highlighted,
  stretchX,
  preview,
  isPostPage,
}: Props) => {
  const fieldPost = useMemo(() => {
    const previewSubscribersCount = post?.subscribersCount || 4
    if (preview) {
      return {
        ...post,
        totalRepliesCount: post?.totalRepliesCount || 3,
        subscribersCount:
          previewSubscribersCount > 1 ? previewSubscribersCount : 4,
        reactionsCount: post?.reactionsCount || 5,
        reactions:
          post?.reactions && post.reactions?.length > 0
            ? post.reactions
            : [
                {
                  count: 5,
                  reacted: false,
                  reaction: '+1',
                },
              ],
      }
    }

    return post
  }, [post, preview])

  return (
    <div
      className={clsx(
        'flex space-s-2 text-content-subdued items-center empty:hidden',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
    >
      <PostInfoBar
        post={fieldPost}
        showReactionCount={field.settings?.showReactionCount ?? true}
        showFollowCount={field.settings?.showFollowCount ?? true}
        showReplyCount={field.settings?.showReplyCount ?? true}
        className="flex-1"
        isPostPage={isPostPage}
      />
    </div>
  )
}
