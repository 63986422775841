import { ManipulateType } from 'dayjs'

import type { AnalyticsResponse } from '@tribeplatform/gql-client/types'

import { dayjs } from '../../common/lib/dayjs.js'
import type { GroupBy, ChartTimeFrame } from '../types/index.js'

const convertBucketByGranularity = (date: string, granularity: string) => {
  // timeBucket returned from server has an unwanted space in the end
  // we use trim() to get rid of spaces to be able to parse the date
  const bucket = JSON.parse(date?.trim() ?? '""')
  switch (granularity) {
    case 'day':
    case 'week':
      return dayjs(bucket).format('YYYY-MM-DD')
    case 'month':
      return dayjs(bucket).format('YYYY-MM')
    default:
      return bucket
  }
}

export const analyticsNumberFormatter = (
  count: number | string,
  decimals = 2,
) => {
  const SI_SYMBOLS = ['', 'K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y']
  const countNum = +count

  if (Number.isNaN(countNum) || !Number.isFinite(countNum)) {
    return '0'
  }

  const countAbs = Math.abs(countNum)

  if (countAbs > 0 && countAbs < 1) {
    return countAbs.toFixed(decimals)
  }

  if (countAbs <= 0) {
    return '0'
  }

  const sign = countNum < 0 ? '-' : ''
  let symbolIndex = Math.floor(Math.log(countAbs) / Math.log(1000))
  let result = parseFloat((countAbs / 1000 ** symbolIndex).toFixed(decimals))

  // to handle 999995,6,7,8,9 -> 1000K and 999999995,6,7,8,9 -> 1000M problem
  if (result >= 1000) {
    result = 1
    symbolIndex += 1
  }

  const symbol = SI_SYMBOLS[symbolIndex]

  return `${sign}${result}${symbol}`
}

const parsePayload = (
  data: AnalyticsResponse['records'][number]['payload'],
): Record<string, string | number> => {
  if (!data) {
    return {}
  }

  return data?.reduce((obj, keyValue) => {
    return { ...obj, [keyValue.key]: keyValue.value }
  }, {})
}

export const getValueFromAnalyticsData = (
  data: AnalyticsResponse,
  key: string,
) => {
  if (!data) {
    return ''
  }

  return data?.records[0]?.payload?.find(p => p?.key === key)?.value
}

const mergeAnalyticsData = <T extends { [x: string]: number }>(
  obj1: T,
  obj2: T,
): T => {
  const mergedObj = Object.entries(obj1).reduce((output, [key, value]) => {
    return { ...output, [key]: value + (obj2?.[key] ?? 0) }
  }, obj1)

  return mergedObj
}

const normalizeDataOnTimeBucket = (
  data: AnalyticsResponse,
  indexBy: string[],
  timeFrame: ChartTimeFrame,
) => {
  return data?.records?.reduce((output, record) => {
    const payload = parsePayload(record?.payload)
    const { timeBucket, ...keyValues } = payload
    const values = Object.entries(keyValues).reduce((obj, [key, value]) => {
      if (indexBy.indexOf(key) !== -1) {
        let numericValue = +value
        if (Number.isNaN(numericValue)) {
          numericValue = 0
        }
        return { ...obj, [key]: numericValue }
      }

      return obj
    }, {})

    const timeBucketKey = convertBucketByGranularity(
      timeBucket as string,
      timeFrame.granularity,
    )
    const prevValues = output?.[timeBucketKey]
    const currentValues = prevValues
      ? mergeAnalyticsData(values, prevValues)
      : values
    return {
      ...output,
      [timeBucketKey]: currentValues,
    }
  }, {})
}

const parseLineChartData = (
  normalizedData: { [x: string]: { [x: string]: number } },
  indexBy: string[],
  timeFrame: ChartTimeFrame,
) => {
  const allZeroObj = indexBy.reduce((obj, label) => {
    return { ...obj, [label]: 0 }
  }, {})
  let parsedData: Record<string, string>[]
  const startDate = timeFrame?.to ? dayjs(timeFrame.to) : dayjs()

  switch (timeFrame.granularity) {
    case 'day':
    case 'week':
      parsedData = [...Array(timeFrame.xAxisCount)].map((_, index) => {
        const time = startDate.subtract(
          timeFrame.xAxisCount - index - 1,
          timeFrame.granularity as ManipulateType,
        )
        const timeBucketToFind = time.format('YYYY-MM-DD')
        const dateString = time.format('ddd MMM DD')
        return {
          date: dateString,
          ...allZeroObj,
          ...(normalizedData?.[timeBucketToFind] ?? {}),
        }
      })
      break
    case 'month':
      parsedData = [...Array(timeFrame.xAxisCount)].map((_, index) => {
        const time = startDate.subtract(
          timeFrame.xAxisCount - index - 1,
          timeFrame.granularity as ManipulateType,
        )
        const timeBucketToFind = time.format('YYYY-MM')
        const dateString = time.format('MMM YYYY')

        return {
          date: dateString,
          ...allZeroObj,
          ...(normalizedData?.[timeBucketToFind] ?? {}),
        }
      })
      break
    case 'dayOfWeek':
      parsedData = [...Array(timeFrame.xAxisCount)].map((_, index) => {
        const time = startDate.day(index + 1)
        const timeBucketToFind = index + 1
        const dateString = time.format('ddd')

        return {
          date: dateString,
          ...allZeroObj,
          ...(normalizedData?.[timeBucketToFind] ?? {}),
        }
      })
      break
    case 'hour':
      parsedData = [...Array(timeFrame.xAxisCount)].map((_, index) => {
        const time = startDate.hour(index)
        const timeBucketToFind = index
        const dateString = `${time.format('HH')}:00`

        return {
          date: dateString,
          ...allZeroObj,
          ...(normalizedData?.[timeBucketToFind] ?? {}),
        }
      })
      break
    default:
      // Code should never reach the default case
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = timeFrame.granularity
      return exhaustiveCheck
  }

  return parsedData
}

const groupAnalyticsData = (
  data: AnalyticsResponse,
  groupBy: GroupBy[],
  timeFrame: ChartTimeFrame,
) => {
  let output = {}
  groupBy.forEach(({ indexBy, field, values, as }) => {
    data?.records?.forEach(record => {
      const payload = parsePayload(record?.payload)
      const fieldValue = JSON.parse((payload?.[field] as string) ?? '""')
      let indexValue = +payload?.[indexBy]

      if (Number.isNaN(indexValue)) {
        indexValue = 0
      }

      const timeBucket = convertBucketByGranularity(
        payload?.timeBucket as string,
        timeFrame.granularity,
      )

      const prevTimeBucketObj = output?.[timeBucket] ?? {}
      const prevValue = output?.[timeBucket]?.[as] ?? 0

      if (values.indexOf(fieldValue as string) !== -1) {
        output = {
          ...output,
          [timeBucket]: { ...prevTimeBucketObj, [as]: prevValue + indexValue },
        }
      }
    })
  })

  return output
}

export const rechartsLineChartParser = (
  data: AnalyticsResponse,
  indexBy: string[],
  timeFrame: ChartTimeFrame,
  groupBy: GroupBy[],
) => {
  const normalizedData = groupBy
    ? groupAnalyticsData(data, groupBy, timeFrame)
    : normalizeDataOnTimeBucket(data, indexBy, timeFrame)

  return parseLineChartData(normalizedData, indexBy, timeFrame)
}

export const rechartsCumulativeAreaChartParser = (
  data: AnalyticsResponse,
  indexBy: string[],
  timeFrame: ChartTimeFrame,
  groupBy: GroupBy[],
) => {
  const parsedData = rechartsLineChartParser(data, indexBy, timeFrame, groupBy)

  for (let i = 1; i < parsedData.length; i++) {
    const keys = Object.keys(parsedData[i])
    keys.forEach(key => {
      if (indexBy.indexOf(key) !== -1) {
        parsedData[i][key] += parsedData[i - 1][key]
      }
    })
  }

  return parsedData
}

const groupPieChartData = (data: AnalyticsResponse, groupBy: GroupBy[]) => {
  return groupBy.map(({ indexBy, field, values, as }) => {
    let output = { label: as, value: 0 }

    data?.records?.forEach(record => {
      const payload = parsePayload(record?.payload)
      const fieldValue = JSON.parse((payload?.[field] as string) ?? '""')
      let indexValue = +payload?.[indexBy]

      if (Number.isNaN(indexValue) || !Number.isFinite(indexValue)) {
        indexValue = 0
      }

      const prevValue = output?.value ?? 0

      if (values.indexOf(fieldValue as string) !== -1) {
        output = {
          ...output,
          value: prevValue + indexValue,
        }
      }
    })

    return output
  })
}

const normalizePieChartData = (data: AnalyticsResponse, indexBy: string[]) => {
  return indexBy.map(label => {
    let value = +getValueFromAnalyticsData(data, label)

    if (Number.isNaN(value) || !Number.isFinite(value)) {
      value = 0
    }

    return { label, value }
  })
}

export const removeNegativeValues = parsedData => {
  return parsedData.map(data => {
    const value = Math.max(0, data.value)
    return { ...data, value }
  })
}

export const rechartsPieChartParser = (
  data: AnalyticsResponse,
  indexBy: string[],
  groupBy: GroupBy[],
) => {
  const parsedData = groupBy
    ? groupPieChartData(data, groupBy)
    : normalizePieChartData(data, indexBy)

  return removeNegativeValues(parsedData)
}

interface NormalizeListData {
  payload: Record<string, string | number>
  entities: AnalyticsResponse['records'][number]['entities']
}
export const normalizeListData = (data: AnalyticsResponse) => {
  if (!data) {
    return []
  }

  return data?.records?.reduce((output, record) => {
    const payload = parsePayload(record?.payload)
    const entities = record?.entities ?? {}

    return [...output, { payload, entities }]
  }, [] as NormalizeListData[])
}
