import type { SpaceRoleFields } from '@tribeplatform/gql-client/graphql'
import type { QuerySpaceRolesArgs } from '@tribeplatform/gql-client/types'

import { KeyConstructor } from './keyConstructor.js'

const SPACE_ROLES_KEY = 'spaceRoles'

export const getSpaceRolesKey = KeyConstructor<
  QuerySpaceRolesArgs,
  SpaceRoleFields
>(SPACE_ROLES_KEY)
