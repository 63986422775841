import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { In } from '../../utils/query.utils.js'
import { HtmlScriptBlock as Component } from './Block.js'
import { HtmlScriptBlockProps } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.HtmlScriptBlockSettings })),
)

export const RegisteredHtmlScriptBlock = (
  intl: I18nShape,
): RegisteredBlock<HtmlScriptBlockProps> => {
  const { $t } = intl

  return {
    recommendedName: 'HtmlScript',
    Component,
    config: {
      type: BlockType.Block,
      availabilityConditions: {
        header: In([true, false]),
      },
      usable: true,

      displayName: $t({
        id: 'Blocks.HtmlScript.DisplayName',
        defaultMessage: 'Html script',
      }),
      Settings,
      Icon: <SvgIcon name="code" />,
      defaultProps: {
        html: '',
        wrapper: 'card-with-padding',
      },
    },
  }
}
