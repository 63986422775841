import { NetworkUsageLimits } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { useBillingProducts } from '@tribeplatform/react-sdk/hooks'
import { Badge, BadgeProps } from '@tribeplatform/react-ui-kit/Badge'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { T } from '../../i18n/index.js'

type PlanBadgeProps = BadgeProps & {
  authorized: boolean
  addonCategory?: keyof NetworkUsageLimits
  size?: 'lg' | 'md' | 'sm'
}

export const PlanBadge = ({
  authorized,
  addonCategory,
  size = 'lg',
}: PlanBadgeProps) => {
  const { Link } = useRouter()
  const { data: billingProducts } = useBillingProducts()

  const linkUrl = addonCategory
    ? `/manage/app-store/addons?category=${addonCategory}`
    : '/manage/billing/plans'

  const upgradePlan = billingProducts?.plans.find(plan =>
    plan.categories.includes(addonCategory),
  )
  const upgradeAddon = billingProducts?.addons.find(addon =>
    addon.categories.includes(addonCategory),
  )

  if (authorized) {
    return null
  }

  return (
    <Link href={linkUrl}>
      <Badge
        leadingIcon={<SvgIcon name="lock" />}
        size={size}
        variant={authorized ? 'positive' : 'neutral'}
        className="cursor-pointer"
      >
        {!!upgradeAddon && <T defaultMessage="Add-on" id="Generics.Addon" />}
        {!upgradeAddon && !!upgradePlan && <span>{upgradePlan.name}</span>}
        {!upgradePlan && !upgradeAddon && (
          <T defaultMessage="Upgrade plan" id="Generics.UpgradePlan" />
        )}
      </Badge>
    </Link>
  )
}
