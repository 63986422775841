import { cva } from 'class-variance-authority'

export const optionDescriptorTitleStyles = cva(
  ['text-label-sm font-medium', 'transition duration-200'],
  {
    variants: {
      disabled: {
        true: ['text-content-disabled'],
        false: ['text-content'],
      },
    },
    defaultVariants: {
      disabled: false,
    },
  },
)

export const optionDescriptorDescriptionStyles = cva(
  ['text-sm', 'transition duration-200'],
  {
    variants: {
      disabled: {
        true: ['text-content-disabled'],
        false: ['text-content-subdued'],
      },
    },
    defaultVariants: {
      disabled: false,
    },
  },
)
