export const TinyChartColors = ['#12B76A']
export const SecondaryTinyChartColors = ['#6fa1eb']
export const DefaultChartColors = ['#6fceeb', '#6fa1eb', '#9b6feb']
export const SecondaryChartColors = ['#c78df8', '#f29cf7', '#b0a1fd']
export const SecondaryBarColors = ['#e3c0f9', '#d7a1f9', '#cb85f7']
export const PrimaryPieColors = ['#5046E6', '#31BC85', '#F63589', '#EBB056']
export const SCORE_SELECT_QUERY =
  '2*count(post) + 2*count(reply) + count(react)'
export const ONLINE_NOW_MINUTES = 30
export const DEFAULT_LIST_LIMIT = 10
export const OVERVIEW_LIST_LIMIT = 5
export const PIE_INACTIVE_OPACITY = 0.9
export const AREA_INACTIVE_OPACITY = 0.15
export const BAR_INACTIVE_OPACITY = 0.15
export const LINE_INACTIVE_OPACITY = 0.15
export const CHART_ANIMATION_ACTIVE = true
export const CHART_ANIMATION_DURATION = 500
export const PIE_STROKE_COLOR = 'var(--bm-color-line-subdued)'
