import { useEffect } from 'react'

import { useScrollLockManager } from '../ScrollLock/useScrollLockManager.js'

export interface BackdropProps {
  onClick?: () => void
}

export const Backdrop = ({ onClick }: BackdropProps) => {
  const scrollLockManager = useScrollLockManager()

  useEffect(() => {
    scrollLockManager.registerScrollLock()

    return () => {
      scrollLockManager.unregisterScrollLock()
    }
  }, [scrollLockManager])

  return (
    <div
      className="fixed inset-0 bg-background-backdrop"
      onClick={onClick}
      onTouchStart={onClick}
    />
  )
}
