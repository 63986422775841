import type {
  CustomFieldType,
  CustomFieldTypeOptions,
} from '@tribeplatform/gql-client/types'

import type { SelectOption } from '../CMS/types.js'
import { CustomFieldSubtype } from '../CustomField/utils.js'
import type { MemberPickerProps } from '../EntityPicker/MemberPicker.js'

// There are two enums for operators, one is PostListFilterByOperator and other is
// MemberListFilterByOperator. To avoid duplication, we decided to add this enum
// for now until b/e supports it too
export enum ListFilterByOperator {
  contains = 'contains',
  endsWith = 'endsWith',
  equals = 'equals',
  gt = 'gt',
  gte = 'gte',
  in = 'in',
  lt = 'lt',
  lte = 'lte',
  nin = 'nin',
  not = 'not',
  startsWith = 'startsWith',
}

export interface LegacyFilter {
  key: string
  keyString: string
  operator: string
  value: string
}

export interface ListFilter {
  key: string
  operator: ListFilterByOperator
  value: string
}

export interface RawFilter {
  id: string
  key: string
  name: string
  type: CustomFieldType
  subType?: CustomFieldSubtype
  icon: JSX.Element
  allowMultiple?: boolean
  extraProps?: {
    selectOptions?: SelectOption[]
    memberGroup?: MemberPickerProps['memberGroup']
    inlineSelectOptions?: string[]
    allowOperatorSelection?: boolean
    narrowedDownOptions?: string[]
    typeOptions?: CustomFieldTypeOptions
  }
  customOperatorPickerComponent?: OperatorPickerComponent
  customValuePickerComponent?: ValuePickerComponent
  customValueRendererComponent?: ValueRendererComponent
}
export interface Filter extends RawFilter {
  operator: ListFilterByOperator
  value: string | boolean | number | string[]
}

export type OperatorPickerComponent = ({
  filter,
  onChange,
}: {
  filter: Filter
  onChange: (operator: ListFilterByOperator) => void
}) => JSX.Element

export type ValuePickerComponent = ({
  filter,
  spread,
  inline,
  className,
  onChange,
}: {
  filter: Filter
  spread?: boolean
  inline?: boolean
  className?: string
  onChange: (value: Filter['value']) => void
}) => JSX.Element

export type ValueRendererComponent = ({
  filter,
}: {
  filter: Filter
}) => JSX.Element

export interface RawInlineFilter {
  key: string
  customLabel: string
  enabled: boolean
  allowOperatorSelection: boolean
  narrowedDownOptions: string[]
}

export interface InlineFilter extends RawInlineFilter {
  id: string
  label: string
  icon: JSX.Element
}

export enum FilterAppearances {
  INLINE = 'inline',
  MODAL = 'modal',
}
