import { useEffect, useMemo, useState } from 'react'

import { decode, encode } from 'js-base64'
import { Link as ReactLink } from 'react-router-dom'

import {
  CustomFieldType,
  CustomFieldValidators,
  Space,
  Template,
  TemplateEntityType,
} from '@tribeplatform/gql-client/types'
import { EntityImage } from '@tribeplatform/react-components/common/components'
import { CustomFieldSubtype } from '@tribeplatform/react-components/CustomField'
import { T, useI18n, useStaticIntl } from '@tribeplatform/react-components/i18n'
import { SpaceImage } from '@tribeplatform/react-components/Space'
import { categoriesFactory } from '@tribeplatform/react-components/Templates'
import { useRouter } from '@tribeplatform/react-sdk'
import { useTemplates } from '@tribeplatform/react-sdk/hooks'
import { Badge } from '@tribeplatform/react-ui-kit/Badge'
import { Button, IconButton } from '@tribeplatform/react-ui-kit/Button'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Link } from '@tribeplatform/react-ui-kit/Link'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

import { useQuery } from '../../../hooks/useQuery.js'
import { useDebounce } from '../../../utils/useDebounce.js'
import { BooleanCell, Table } from '../../Table/index.js'
import { TemplateActionItems } from './TemplateActionItems.js'

export const Templates = () => {
  const intl = useI18n()
  const { $t } = intl
  const categories = useStaticIntl(categoriesFactory)
  const { state: queryState } = useQuery<{ state?: string }>()
  const { replace } = useRouter()

  const queryStringState = JSON.parse(decode(queryState ?? encode('{}')))
  const initialState = {
    query: null,
    filterBy: [],
    ...queryStringState,
  }

  const [state, setState] = useState(initialState)
  const debouncedQuery = useDebounce(state?.query, 300)

  useEffect(() => {
    replace(`?state=${encode(JSON.stringify(state))}`)
  }, [state, replace])

  const {
    data,
    isInitialLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useTemplates({
    fields: {
      banner: {
        onEmoji: 'all',
        onImage: 'all',
        onGlyph: 'all',
      },
      createdBy: 'all',
      entity: {
        onSpace: {
          image: {
            onEmoji: 'all',
            onImage: 'all',
            onGlyph: 'all',
          },
        },
      },
    },
    variables: {
      entityTypes: [TemplateEntityType.Space],
      limit: 10,
      query: debouncedQuery,
      categories: state?.filterBy?.map(filter => JSON.parse(filter.value)),
    },
  })

  const columns = useMemo(
    () => [
      {
        Header: $t({ defaultMessage: 'ID', id: 'Generics.ID' }),
        accessor: 'id',
        visible: false,
      },
      {
        Header: $t({ defaultMessage: 'Slug', id: 'Generics.Slug' }),
        accessor: 'slug',
        visible: false,
      },
      {
        Header: $t({ defaultMessage: 'Icon', id: 'Generics.Icon' }),
        accessor: 'banner',
        visible: false,
        renderer: template => {
          return <EntityImage entity={{ image: template?.banner }} size="xl" />
        },
      },
      {
        Header: $t({ defaultMessage: 'Name', id: 'Generics.Name' }),
        accessor: 'name',
        visible: true,
        renderer: template => {
          return (
            <div className="flex items-center gap-3">
              <EntityImage entity={{ image: template?.banner }} size="xl" />
              <div className="truncate">{template?.name}</div>
            </div>
          )
        },
      },
      {
        Header: $t({
          defaultMessage: 'Description',
          id: 'Generics.Description',
        }),
        accessor: 'description',
        visible: true,
      },
      {
        Header: $t({ defaultMessage: 'Categories', id: 'Generics.Categories' }),
        accessor: 'categories',
        visible: true,
        subtype: CustomFieldSubtype.SELECT,
        type: CustomFieldType.text,
        exact: true,
        validators: [
          {
            validation: CustomFieldValidators.enum,
            value: JSON.stringify(categories?.map(category => category.value)),
          },
        ],
        labels: categories.reduce((categoryLabels, category) => {
          return {
            ...categoryLabels,
            [category.value]: category.text,
          }
        }, {}),
        renderer: template => {
          return (
            <div className="flex gap-3 flex-wrap">
              {template.categories
                .map(
                  category => categories?.find(c => c.value === category)?.text,
                )
                .map(category => (
                  <Badge key={category}>{category}</Badge>
                ))}
            </div>
          )
        },
      },
      {
        Header: $t({ defaultMessage: 'Created at', id: 'Generics.CreatedAt' }),
        accessor: 'createdAt',
        visible: false,
        renderer: 'date',
      },
      {
        Header: $t({
          defaultMessage: 'Sync Status',
          id: 'Templates.SyncStatus',
        }),
        visible: true,
        accessor: 'syncedAt',
        renderer: template => {
          const space = template?.entity as Space
          const isSynced =
            new Date(space?.updatedAt) < new Date(template?.createdAt) ||
            new Date(space?.updatedAt) < new Date(template?.syncedAt)
          return (
            <Tooltip>
              <Tooltip.Trigger>
                <div className="flex justify-center">
                  <BooleanCell value={isSynced} />
                </div>
              </Tooltip.Trigger>
              <Tooltip.Panel>
                {isSynced
                  ? $t({
                      defaultMessage: 'Template is in sync with {SPACE}',
                      id: 'Templates.SyncStatus.Synced',
                    })
                  : $t({
                      defaultMessage:
                        '{SPACE_CC} has been modified since the template was synced',
                      id: 'Templates.SyncStatus.NotSynced',
                    })}
              </Tooltip.Panel>
            </Tooltip>
          )
        },
      },
      {
        Header: $t({ defaultMessage: '{SPACE_CC}', id: 'Generics.SPACE_CC' }),
        visible: true,
        accessor: 'entity',
        renderer: template => {
          const space = template?.entity as Space
          return (
            <Link
              as={ReactLink}
              to={space?.relativeUrl}
              className="flex items-center gap-3"
            >
              <SpaceImage space={space} size="xl" />
              <div className="truncate">{space?.name}</div>
            </Link>
          )
        },
      },
      {
        Header: $t({
          defaultMessage: 'Author name',
          id: 'Generics.AuthorName',
        }),
        accessor: 'authorName',
        visible: false,
      },
      {
        Header: $t({ defaultMessage: 'Author URL', id: 'Generics.AuthorURL' }),
        accessor: 'authorUrl',
        visible: false,
      },
      {
        Header: $t({ defaultMessage: 'Status', id: 'Generics.Status' }),
        accessor: 'status',
        visible: true,
      },
      {
        Header: $t({ defaultMessage: 'Standing', id: 'Generics.Standing' }),
        accessor: 'standing',
        visible: false,
      },
      {
        Header: $t({ defaultMessage: 'Author', id: 'Generics.Author' }),
        accessor: 'createdBy',
        renderer: 'member',
        visible: false,
      },
      {
        visible: true,
        accessor: 'actions',
        locked: true,
        width: 'collapse',
        renderer: (_, template: Template) => (
          <Dropdown placement="bottom-end">
            <Dropdown.ButtonMinimal as="div">
              <IconButton
                variant="tertiaryNeutral"
                rounded
                icon={<SvgIcon name="dots-horizontal" />}
                size="sm"
              />
            </Dropdown.ButtonMinimal>
            <TemplateActionItems template={template} />
          </Dropdown>
        ),
      },
    ],
    [$t, categories],
  )

  return (
    <div className="w-full">
      <Table
        state={state}
        onStateChange={setState}
        storageId="templates"
        isLoading={isInitialLoading}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        columns={columns}
        data={data}
        title={$t({ defaultMessage: 'Templates', id: 'Generics.Templates' })}
        keyboardControl
        entityType="template"
        actions={
          <div className="flex space-s-3">
            <Button
              size="lg"
              variant="primary"
              as={ReactLink}
              to="/manage/customizer/templates/new"
            >
              <T defaultMessage="Create template" id="Admin.Templates.Create" />
            </Button>
          </div>
        }
      />
    </div>
  )
}
