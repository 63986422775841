// keep headless-ui and react-ui-kit portals in same root
const PORTAL_ROOT = 'headlessui-portal-root'

export const getPortalRootElement = (document: Document) => {
  const root = document.getElementById(PORTAL_ROOT)
  if (root) {
    return root
  }

  const newRoot = document.createElement('div')
  newRoot.setAttribute('id', PORTAL_ROOT)
  newRoot.setAttribute('class', 'relative z-50')
  document.body.appendChild(newRoot)
  return newRoot
}
