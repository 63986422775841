import type { ReportRecordFields } from '@tribeplatform/gql-client/graphql'
import type {
  QueryAnalyticsArgs,
  AnalyticsResponse,
} from '@tribeplatform/gql-client/types'

import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getAnalyticsKey } from '../../utils/keys/report.keys.js'

export const useAnalytics = (options: {
  variables: QueryAnalyticsArgs
  fields?: ReportRecordFields
  useQueryOptions?: UseQueryOptions<AnalyticsResponse[]>
}) => {
  const { variables, fields = 'basic', useQueryOptions } = options
  const { client } = useTribeClient()
  const analyticsKey = getAnalyticsKey({ variables, fields })

  return useQuery<AnalyticsResponse[]>(
    analyticsKey,
    () => client.report.analytics(variables, fields),
    useQueryOptions,
  )
}
