import { clsx } from 'clsx'

import { Space } from '@tribeplatform/gql-client/types'
import {
  BannerPlaceholder,
  EntityImage,
} from '@tribeplatform/react-components/common/components'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { Avatar, legacySizeMapper } from '@tribeplatform/react-ui-kit/Avatar'
import { iconStyles } from '@tribeplatform/react-ui-kit/Icon'

import { getGlyph, getMedia } from '../utils/index.js'

interface Props {
  field: StaticField
  space: Space
  highlighted: boolean
}
export const ListImage = ({ field, space, highlighted }: Props) => {
  const imageSource = field.settings?.bannerSource
  const mediaUrl = getMedia({
    source: imageSource,
    space,
    size: 'small',
  })
  const glyph = getGlyph({
    source: imageSource,
    space,
  })
  const iconSize = legacySizeMapper(field.settings?.avatarSize ?? 'lg')

  if (mediaUrl || imageSource !== 'image') {
    return (
      <div className="shrink-0">
        {!mediaUrl ? (
          <BannerPlaceholder
            className={clsx(
              'flex justify-center items-center rounded-base',
              'overflow-hidden',
              highlighted && 'ring-2 ring-offset-surface ring-focused',
              iconStyles({
                size: iconSize,
              }),
            )}
            name={space?.relativeUrl}
          />
        ) : (
          <Avatar
            size={iconSize}
            src={mediaUrl}
            name={space.name}
            rounded="base"
            className={clsx(
              highlighted &&
                'ring-2 ring-offset-surface ring-focused rounded-base',
            )}
          />
        )}
      </div>
    )
  }

  return (
    <div
      className={clsx(
        'shrink-0 flex items-center justify-center text-content-on-background',
        highlighted && 'ring-2 ring-offset-surface ring-focused rounded-md',
        iconStyles({
          size: iconSize,
        }),
      )}
    >
      <EntityImage entity={{ image: glyph }} size={iconSize} />
    </div>
  )
}
