import { localizeDayJs } from './localizeDayJs.js'
import { localizeFlatpickr } from './localizeFlatpickr.js'

export const localizeLibsForClient = async (locale: string): Promise<void> => {
  await Promise.allSettled([
    await localizeDayJs(locale),
    await localizeFlatpickr(locale),
  ])
}

export const localizeLibsForServer = async (locale: string): Promise<void> => {
  await localizeDayJs(locale)
}
