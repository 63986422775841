import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Fields,
  Action,
  MutationPinReplyToPostArgs,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPostKey, getPostsKey } from '../../utils/keys/post.key.js'
import { getPinnedPostsKey } from '../../utils/keys/space.key.js'

export const usePinReplyToPost = (options?: {
  fields?: Fields.ActionFields
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationPinReplyToPostArgs
  >
}) => {
  const { useMutationOptions, fields = 'basic' } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<Action, ClientError, MutationPinReplyToPostArgs>(
    variables =>
      client.mutation({
        name: 'pinReplyToPost',
        args: {
          variables,
          fields,
        },
      }),
    {
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(
          getPostKey({ variables: { id: variables?.postId } }),
        )
        queryClient.invalidateQueries(
          getPostKey({ variables: { id: variables?.replyId } }),
        )
        queryClient.invalidateQueries(getPostsKey())
        queryClient.invalidateQueries(getPinnedPostsKey())
      },
      ...useMutationOptions,
    },
  )
}
