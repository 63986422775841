const localeMapping = {
  // flag emoji code will be resolved by emoji-mart
  // https://projects.iamcal.com/emoji-data/table.htm
  de: ':flag-de: Deutsch',
  'de-de': ':flag-de: Deutsch (Deutschland)',
  'en-us': ':flag-us: English (US)',
  fr: ':flag-fr: Français',
  'fr-fr': ':flag-fr: Français (France)',
  es: ':flag-es: Español',
  'es-es': ':flag-es: Español (España)',
  hi: ':flag-in: हिंदी',
  ja: ':flag-jp: 日本語',
  ko: ':flag-kr: 한국어',
  pt: ':flag-pt: Português',
  'pt-br': ':flag-br: Português (Brasil)',
  tr: ':flag-tr: Türkçe',
  'zh-hans': ':flag-cn: 简体中文',
  'zh-hant': ':flag-tw: 繁體中文',
  nl: ':flag-nl: Nederlands',
  pl: ':flag-pl: Polski',
  ro: ':flag-ro: Română',
  it: ':flag-it: Italiano',
  da: ':flag-dk: Danish',
  fi: ':flag-fi: Finnish',
  nb: ':flag-no: Norwegian',
  sv: ':flag-se: Swedish',
}

export const EMOJI_REGEX = /(:.*:) (.*)/

type TLocaleMappingKeys = keyof typeof localeMapping
const isKnownLocale = (locale: string): locale is TLocaleMappingKeys =>
  Object.keys(localeMapping).includes(locale)

export const localeToLanguageName = (locale: string) => {
  const lowerCase = locale.toLowerCase()

  if (isKnownLocale(lowerCase)) {
    return localeMapping[lowerCase]
  }

  return locale
}

interface LocaleDetails {
  shortCode: string
  languageName: string
}
export const getLocaleDetails = (locale: string): LocaleDetails => {
  const languageName = localeToLanguageName(locale)
  const match = EMOJI_REGEX.exec(languageName)

  if (!match) {
    return {
      shortCode: null,
      languageName,
    }
  }

  const [, matchEmoji, matchLanguageName] = match ?? []
  return {
    shortCode: matchEmoji,
    languageName: matchLanguageName,
  }
}

export const getAllLocales = () => {
  return Object.keys(localeMapping)
}

export const getAvailableLocales = (): Array<keyof typeof localeMapping> => {
  return [
    'de',
    'en-us',
    'es',
    'fr',
    'hi',
    'ja',
    'ko',
    'pt-br',
    'tr',
    'zh-hans',
    'zh-hant',
    'nl',
    'pl',
    'ro',
    'it',
    'da',
    'fi',
    'nb',
    'sv',
  ]
}
