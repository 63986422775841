import { MemberFields, memberGQLFields } from '../network/member.fields.js'
import { ReactionFields, reactionGQLFields } from './reaction.fields.js'

export type PostReactionParticipantFields =
  | 'basic'
  | 'all'
  | CustomPostReactionParticipantFields

interface CustomPostReactionParticipantFields {
  participant?: MemberFields
  reaction?: ReactionFields
}

const BASIC_POST_REACTION_PARTICIPANTS_FIELDS: CustomPostReactionParticipantFields =
  {}
const ALL_POST_REACTION_PARTICIPANTS_FIELDS: CustomPostReactionParticipantFields =
  {
    participant: 'basic',
    reaction: {
      fields: 'basic',
      variables: {
        limit: 25,
      },
    },
  }

export const postReactionParticipantsGQLFields = (
  fields: PostReactionParticipantFields,
) => {
  if (fields === 'basic') fields = BASIC_POST_REACTION_PARTICIPANTS_FIELDS
  if (fields === 'all') fields = ALL_POST_REACTION_PARTICIPANTS_FIELDS

  return `
    __typename
    ${
      fields.participant
        ? `
      participant {
        ${memberGQLFields(fields.participant)}
      }
    `
        : ``
    }
    ${
      fields.reaction
        ? `
      reaction {
        ${reactionGQLFields(fields.reaction)}        
      }
    `
        : ``
    }
  `
}
