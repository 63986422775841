export enum LogLevels {
  silent = 0,
  error,
  warn,
  info,
  debug,
}

export type LogLevel = keyof typeof LogLevels

export interface WebLogger {
  error(message: unknown, ...data: unknown[]): void
  warn(message: unknown, ...data: unknown[]): void
  info(message: unknown, ...data: unknown[]): void
  debug(message: unknown, ...data: unknown[]): void
}
