export const enum MainMenuIcon {
  BellIcon = 'BellIcon',
  HomeIcon = 'HomeIcon',
  NewspaperIcon = 'NewspaperIcon',
  SearchIcon = 'SearchIcon',
  UserCircleIcon = 'UserCircleIcon',
  UserGroupIcon = 'UserGroupIcon',
  ViewGridIcon = 'ViewGridIcon',
}

export const enum MainMenuAction {
  OPEN_SPACE = 'OPEN_SPACE',
  OPEN_PROFILE = 'OPEN_PROFILE',
  OPEN_LINK = 'OPEN_LINK',
}

export type MainMenuItem = {
  enabled: boolean
  label: string
  action: MainMenuAction
  icon: string
  link?: string
  spaceSlug?: string
  entityId?: string

  /**
   * @deprecated use spaceSlug instead
   */
  page?: string
}

export type MainMenuProps = {
  items?: MainMenuItem[]
}
