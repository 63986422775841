import { useMemo } from 'react'

import {
  PermissionContext,
  Post,
  PostStatus,
} from '@tribeplatform/gql-client/types'
import { findPostType } from '@tribeplatform/react-components/CMS'
import { dayjs } from '@tribeplatform/react-components/common/lib'
import {
  convertToStatic,
  type StaticField,
} from '@tribeplatform/react-components/Views'
import { useAuthMember, useNetwork } from '@tribeplatform/react-sdk/hooks'

import { PostsCardItem } from '../../PostsBlock/components/PostsCardItem.js'
import { useCustomFields } from '../../PostsBlock/hooks/useCustomFields.js'
import type { SinglePostBlockProps } from '../types.js'
import { PostNotFound } from './PostNotFound.js'

interface Props {
  fetchedPost: Post
  postId: string
  fields: SinglePostBlockProps['fields']
  staticFields: StaticField[]
  postTypeId: string
  postTypeSlug: string
  activeFieldId: string
  isPostPage?: boolean
  preview: boolean
}
export const PostCard = ({
  fetchedPost,
  postId,
  fields,
  staticFields,
  postTypeId,
  postTypeSlug,
  activeFieldId,
  isPostPage,
  preview,
  ...rest
}: Props) => {
  const {
    data: { id: networkId, postTypes },
  } = useNetwork()
  const { isModerator, data: authMember } = useAuthMember()
  const postType = findPostType({
    networkId,
    id: postTypeId,
    slug: postTypeSlug,
    postTypes,
  })
  const { customFields } = useCustomFields({
    postTypeSlugs:
      preview && !postId ? [postType?.slug] : [fetchedPost?.postType?.slug],
    excludeRichText: false,
    excludeTitle: false,
    excludePrivateFields: false,
  })
  const post = useMemo(() => {
    if (preview && !postId) {
      return {
        postTypeId,
        postType: { id: postTypeId, slug: postTypeSlug },
        status: PostStatus.PUBLISHED,
        owner: { member: authMember },
        primaryReactionType: postType?.primaryReactionType,
        relativeUrl: '/',
        fields: [{ key: 'title', value: '"Title"' }],
        space: {
          name: 'Space',
          slug: 'space',
        },
        authMemberProps: {
          context: PermissionContext.POST,
          canReact: true,
          permissions: [
            {
              name: 'addReaction',
              isAuthorized: { authorized: true },
              inputPermissions: [],
              outputPermissions: [],
            },
            {
              name: 'updatePost',
              isAuthorized: { authorized: true },
              inputPermissions: [],
              outputPermissions: [],
            },
            {
              name: 'createReply',
              isAuthorized: { authorized: true },
              inputPermissions: [],
              outputPermissions: [],
            },
          ],
        },
        publishedAt: dayjs().subtract(5, 'day').toDate(),
      } as Post
    }

    return fetchedPost
  }, [
    authMember,
    fetchedPost,
    postId,
    postType?.primaryReactionType,
    postTypeId,
    postTypeSlug,
    preview,
  ])

  const postFields = useMemo(() => {
    const convertedFields = convertToStatic(
      fields,
      staticFields,
      customFields ?? [],
    ).filter(
      field =>
        field.enabled && !(field.styles && field.styles.indexOf('card') === -1),
    )

    return convertedFields
  }, [customFields, fields, staticFields])

  if (
    post &&
    !isModerator &&
    post?.status !== PostStatus.PUBLISHED &&
    authMember?.id &&
    post?.owner?.member?.id !== authMember?.id
  ) {
    return <PostNotFound />
  }

  if (!post) {
    return null
  }

  return (
    <PostsCardItem
      post={post}
      fields={postFields}
      preview={preview || !postId}
      activeFieldId={activeFieldId}
      isPostPage={isPostPage}
      isSinglePost
      {...rest}
    />
  )
}
