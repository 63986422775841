import { FC, Children } from 'react'

import { clsx } from 'clsx'

import { ContainerProvider, useContainer } from './ContainerContext.js'
import {
  ContainerAlignment,
  ContainerDirection,
  ContainerHide,
  ContainerMaxSize,
  ContainerOpacity,
  ContainerOrder,
  ContainerPadding,
  ContainerPaddingAxis,
  ContainerSize,
} from './types.js'
import {
  CONTAINER_HORIZONTAL_DIRECTIONS,
  extractDetailFromContainerAlignment,
  extractDetailFromContainerHide,
  extractDetailFromContainerMaxSize,
  extractDetailFromContainerOrder,
  extractDetailFromContainerPadding,
  extractDetailFromContainerPaddingAxis,
  getContainerAlignment,
  getContainerHide,
  getContainerMaxSize,
  getContainerOrder,
  getContainerPadding,
  getContainerSpacing,
  minContainerSize,
  sumContainerSizes,
} from './utils.js'

type DataProp = `data-${string}`
type DataProps = Record<DataProp, boolean | string>

export type AdditionalContainerProps = React.HTMLAttributes<HTMLDivElement> &
  DataProps & {
    ref?: React.Ref<HTMLDivElement>
  }

export type ContainerProps = {
  size: ContainerSize
  maxSize?: ContainerMaxSize
  childrenSizes?: ContainerSize[]
  direction?: ContainerDirection
  grow?: boolean
  shrink?: boolean
  spacing?: ContainerPaddingAxis
  padding?: ContainerPadding
  alignment?: ContainerAlignment
  order?: ContainerOrder
  hide?: ContainerHide
  opacity?: ContainerOpacity
  as?: 'div' | 'main' | 'header' | 'aside'
  containerProps?: AdditionalContainerProps
  children?: React.HTMLAttributes<HTMLDivElement>['children']
}

export const Container: FC<ContainerProps> = ({
  size,
  maxSize,
  childrenSizes = [],
  direction = 'vertical',
  grow,
  shrink,
  spacing = 'md',
  padding,
  alignment,
  order,
  hide,
  opacity,
  as: Component = 'div',
  containerProps: { className, ...containerProps } = {},
  children,
}) => {
  const { direction: parentDirection } = useContainer()

  const items = Children.toArray(children)

  const gridSize = minContainerSize(size, sumContainerSizes(...childrenSizes))
  return (
    <ContainerProvider size={size} direction={direction}>
      <Component
        className={clsx(
          !CONTAINER_HORIZONTAL_DIRECTIONS.includes(parentDirection) &&
            'w-full',
          CONTAINER_HORIZONTAL_DIRECTIONS.includes(parentDirection) && [
            grow === true && 'grow',
            grow === false && 'grow-0',
            shrink === true && 'shrink',
            shrink === false && 'shrink-0',
          ],
          direction === 'vertical' && 'flex flex-col',
          direction === 'horizontal' && 'flex flex-row',
          direction === 'vertical-reverse' && 'flex flex-col-reverse',
          direction === 'horizontal-reverse' && 'flex flex-row-reverse',
          direction === 'grid' && [
            'grid grid-cols-1',
            gridSize === 'sm' && 'sm:grid-cols-2',
            gridSize === 'md' && 'md:grid-cols-4',
            gridSize === 'lg' && 'md:grid-cols-6',
            (gridSize === 'xl' || gridSize === 'full') &&
              'md:grid-cols-6 lg:grid-cols-8',
          ],
          parentDirection === 'grid' && [
            'col-span-1',
            size === 'sm' && 'sm:col-span-2',
            size === 'md' && 'md:col-span-4',
            size === 'lg' && 'md:col-span-6',
            (size === 'xl' || size === 'full') && 'md:col-span-6 lg:col-span-8',
          ],
          opacity === 'none' && 'opacity-100',
          opacity === 'low' && 'opacity-75',
          opacity === 'medium' && 'opacity-50',
          opacity === 'high' && 'opacity-25',
          getContainerMaxSize(extractDetailFromContainerMaxSize(size, maxSize)),
          getContainerAlignment({
            alignment: extractDetailFromContainerAlignment(alignment),
            direction,
            parentDirection,
          }),
          getContainerSpacing({
            direction,
            spacing: extractDetailFromContainerPaddingAxis(spacing),
          }),
          getContainerPadding(extractDetailFromContainerPadding(padding)),
          getContainerOrder(extractDetailFromContainerOrder(order)),
          getContainerHide(extractDetailFromContainerHide(hide)),
          className,
        )}
        {...containerProps}
      >
        {items}
      </Component>
    </ContainerProvider>
  )
}
