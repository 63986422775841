import { themeColorGQLFields } from './themeColor.fields.js'

export type ThemeColorsFields = 'basic' | 'all'

// eslint-disable-next-line unused-imports/no-unused-vars
export const themeColorsGQLFields = (fields: ThemeColorsFields) => `
  __typename
  dark {
    ${themeColorGQLFields('all')}
  }
  light {
    ${themeColorGQLFields('all')}
  }
`
