import { forwardRef, type ReactNode } from 'react'

import { Link as RouterLink } from 'react-router-dom'

import { useAuthToken } from '@tribeplatform/react-sdk/hooks'
import type { LinkProps as LinkUiProps } from '@tribeplatform/react-ui-kit/Link'
import { Link as LinkUI } from '@tribeplatform/react-ui-kit/Link'

import {
  getIsInternal,
  getLinkRel,
  getLinkTarget,
  parseHref,
} from './utils/index.js'

export interface LinkProps extends Omit<LinkUiProps, 'rel'> {
  children: ReactNode
  trusted?: boolean
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      href,
      target,
      trusted,
      children,
      variant = 'inherit',
      ...props
    }: LinkProps,
    ref,
  ) => {
    const {
      data: { networkPublicInfo },
    } = useAuthToken()

    const parsedHref = parseHref(href, networkPublicInfo)
    const isInternal = getIsInternal(parsedHref)
    const linkTarget = getLinkTarget(target, isInternal)
    const isTrusted = trusted || isInternal
    const linkRel = getLinkRel(linkTarget, isTrusted)

    if (!href) {
      return (
        <LinkUI ref={ref} variant={variant} {...props}>
          {children}
        </LinkUI>
      )
    }

    if (isInternal) {
      return (
        <LinkUI
          ref={ref}
          as={RouterLink}
          to={parsedHref}
          variant={variant}
          {...(linkTarget ? { target: linkTarget } : {})}
          {...(linkRel ? { rel: linkRel } : {})}
          {...props}
        >
          {children}
        </LinkUI>
      )
    }

    return (
      <LinkUI
        ref={ref}
        href={parsedHref}
        variant={variant}
        {...(linkTarget ? { target: linkTarget } : {})}
        {...(linkRel ? { rel: linkRel } : {})}
        {...props}
      >
        {children}
      </LinkUI>
    )
  },
)
