import { useCallback, useState } from 'react'

import { clsx } from 'clsx'

import { Media } from '@tribeplatform/gql-client/types'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Popover } from '@tribeplatform/react-ui-kit/Popover'

import { EntityImage } from '../../common/components/EntityImage.js'
import { T } from '../../i18n/components/T.js'
import { ImagePickerTabs } from '../../Picker/ImagePicker.js'
import type { PickerEmoji, PickerIcon } from '../../Picker/index.js'
import { FormImageConfig, UploadableMedia } from './types.js'

export const VariantCompact = ({
  image,
  onChange,
  onEmojiChange,
  onIconChange,
  onRemove,
  config,
}: {
  image?: UploadableMedia
  onChange?: (files: File[]) => void
  onEmojiChange?: (emoji: PickerEmoji) => void
  onIconChange?: (emoji: PickerIcon) => void
  onRemove?: (image: Media) => void
  config?: FormImageConfig
}) => {
  const [active, setActive] = useState(false)
  const toggleActive = useCallback(() => setActive(active => !active), [])

  const compactActivator = (
    <Button
      size="md"
      variant="secondaryNeutral"
      loading={image?.isLoading}
      onClick={toggleActive}
      trailingIcon={
        image && <EntityImage size="lg" entity={{ image, name: '' }} />
      }
    >
      <div className={clsx(image && 'me-1')}>
        {image ? (
          <T defaultMessage="Change image" id="Generics.ChangeImage" />
        ) : (
          <T defaultMessage="Pick image" id="Generics.PickImage" />
        )}
      </div>
    </Button>
  )

  const onRemoveImage = () => {
    onRemove(image)
  }

  return (
    <Popover
      activator={compactActivator}
      open={active}
      onClose={() => setActive(false)}
      autofocusTarget="first-node"
      fluidContent
      placement="top-start"
    >
      <ImagePickerTabs
        tabs={[
          config.emoji ? 'emoji' : null,
          config.icon ? 'icon' : null,
          config.link ? 'link' : null,
          'upload',
          config.unsplash ? 'unsplash' : null,
        ]}
        removable={!!image}
        onSelect={() => setActive(false)}
        onRemove={image && onRemoveImage}
        onEmojiChange={onEmojiChange}
        onIconChange={onIconChange}
        onImageChange={onChange}
      />
    </Popover>
  )
}
