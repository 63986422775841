import { ReactNode, FC } from 'react'

import { clsx } from 'clsx'

export type MediaProps = React.ComponentProps<'div'> & {
  icon?: ReactNode
  trailingVisual?: ReactNode
  align?: 'leading' | 'center'
  truncate?: boolean
}

export const Media: FC<MediaProps> = props => {
  const {
    children,
    className,
    trailingVisual,
    icon,
    align = 'leading',
    truncate,
    ...rest
  } = props

  return (
    <div
      className={clsx(
        'relative group flex space-s-3',
        [
          align === 'leading' && 'items-start',
          align === 'center' && 'items-center',
        ],
        className,
      )}
      {...rest}
    >
      {icon && <div className="shrink-0">{icon}</div>}
      <div className={clsx('grow min-w-0', truncate && 'truncate')}>
        {children}
      </div>
      {trailingVisual && <div className="shrink-0">{trailingVisual}</div>}
    </div>
  )
}
