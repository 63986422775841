import { ComponentProps, ReactNode } from 'react'

import { VariantProps } from 'class-variance-authority'

import { labelStyles } from './FormControl.styles.js'

export type FormControlLabelProps = ComponentProps<'label'> &
  VariantProps<typeof labelStyles> & {
    /**
     * additional context, typically used to indicate optionality of a field,
     */
    secondaryLabel?: ReactNode
    /**
     * further context, typically used for providing assistance with a field.
     */
    tertiaryLabel?: ReactNode
  }

export const Label = ({
  children,
  className,
  secondaryLabel,
  tertiaryLabel,
  size,
  ...rest
}: FormControlLabelProps) => {
  const label = (
    <label
      className={labelStyles({
        size,
        className,
      })}
      {...rest}
    >
      {children}
    </label>
  )

  if (!!secondaryLabel || !!tertiaryLabel) {
    return (
      <div className="flex space-s-2 items-center justify-between">
        {!secondaryLabel ? (
          label
        ) : (
          <div className="flex space-s-1 items-center">
            {label}
            <div>{secondaryLabel}</div>
          </div>
        )}
        <div>{tertiaryLabel}</div>
      </div>
    )
  }

  return label
}
