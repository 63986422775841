import { gql } from 'graphql-tag'

export const extraPropertiesGQLQuery = () => gql`
  query ExtraProperties(
    $entityId: String
    $entityType: ExtraPropertyEntityType!
    $keys: [String!]
  ) {
    extraProperties(entityId: $entityId, entityType: $entityType, keys: $keys) {
      key
      value
    }
  }
`

export const upsertExtraPropertyGQLMutation = () => gql`
  mutation UpsertExtraProperty($input: UpsertExtraPropertyInput!) {
    upsertExtraProperty(input: $input) {
      status
    }
  }
`
