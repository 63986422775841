import { useMemo } from 'react'

import BoringAvatar from 'boring-avatars'
import { clsx } from 'clsx'

import { useThemeContext } from '@tribeplatform/react-ui-kit/ThemeProvider'

import { getPlaceholderColors } from './member/utils.js'

export interface BannerPlaceholderProps {
  name: string
  className?: string
}

export const BannerPlaceholder = ({
  name,
  className,
}: BannerPlaceholderProps) => {
  const { theme, style } = useThemeContext()

  const colors = useMemo(
    () => getPlaceholderColors({ theme, style: style.avatars }),
    [style, theme],
  )

  return (
    <div className={clsx('w-full aspect-square overflow-hidden', className)}>
      <BoringAvatar
        size="100%"
        name={name}
        square
        variant="marble"
        colors={colors}
      />
    </div>
  )
}
