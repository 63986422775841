import { gql } from 'graphql-tag'

import { BadgeFields, badgeGQLFields } from './badge.fields.js'

export const createBadgeGQLMutation = (fields: BadgeFields) => gql`
  mutation CreateBadge($input: CreateBadgeInput!) {
    createBadge(input: $input) {
      ${badgeGQLFields(fields)}
    }
  }
`

export const updateBadgeGQLMutation = (fields: BadgeFields) => gql`
  mutation UpdateBadge($id: String!, $input: UpdateBadgeInput!) {
    updateBadge(id: $id, input: $input) {
      ${badgeGQLFields(fields)}
    }
  }
`

export const deleteBadgeGQLMutation = () => gql`
  mutation DeleteBadge($id: String!) {
    deleteBadge(id: $id) {
      status
    }
  }
`

export const assignBadgeGQLMutation = () => gql`
  mutation AssignBadge($id: String!, $input: AssignOrRevokeBadgeInput!) {
    assignBadge(id: $id, input: $input) {
      status
    }
  }
`

export const revokeBadgeGQLMutation = () => gql`
  mutation RevokeBadge($id: String!, $input: AssignOrRevokeBadgeInput!) {
    revokeBadge(id: $id, input: $input) {
      status
    }
  }
`
