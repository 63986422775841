import { createContext, useContext } from 'react'

import { PopoverPadding } from './types.js'

export type PopoverContext = {
  padding: PopoverPadding
}

export const PopoverContext = createContext<PopoverContext>(undefined)

export const usePopover = () => {
  const context = useContext(PopoverContext)
  if (context === undefined) {
    throw new Error('usePopover must be used within a PopoverProvider')
  }
  return context
}
