interface WithId {
  id: string | number
}

export const hasId = (obj: unknown): obj is WithId => {
  return (
    (obj as WithId).id !== undefined &&
    (typeof (obj as WithId).id === 'string' ||
      typeof (obj as WithId).id === 'number')
  )
}
