import { clsx } from 'clsx'

import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { CustomFieldViewProps } from '../utils.js'

export const RatingView = ({ value }: CustomFieldViewProps) => {
  if (!value) {
    return null
  }

  return (
    <div className="flex">
      {[1, 2, 3, 4, 5].map(val => {
        return (
          <SvgIcon
            key={val}
            className={clsx(
              'w-6 h-6 -me-1',
              val <= value
                ? 'text-action-primary'
                : 'text-content-subdued opacity-50',
            )}
            name="star"
          />
        )
      })}
    </div>
  )
}
