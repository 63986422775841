import { useRouter } from '@tribeplatform/react-sdk'
import { useAuthMember, useNetwork } from '@tribeplatform/react-sdk/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'

import { T } from '../../../i18n/index.js'
import { dayjs } from '../../lib/dayjs.js'
import { useFirstRenderContext } from '../../ssr/FirstRenderProvider.js'
import { convertUtcToTz } from '../../utils/date.js'

export const WillUnpublishBanner = () => {
  const { isFirstRender } = useFirstRenderContext()
  const { data: network } = useNetwork()
  const { isAdmin } = useAuthMember()
  const { Link } = useRouter()

  const willUnpublishAt = network?.willUnpublishAt

  if (isFirstRender || !isAdmin) {
    return null
  }

  return (
    <div className="flex justify-center px-3 py-3.5 bg-action-destructive text-content-on-primary pointer-events-auto">
      <div className="flex-grow flex flex-row gap-3 justify-between items-center max-w-7xl">
        <span className="font-medium text-sm md:text-md">
          <T
            defaultMessage="Your current usage exceeds the limits of your plan. Please take action <hover>{relativeDate}</hover>, as your site is at risk of being temporarily unpublished."
            id="Banner.WillUnpublishMessage"
            values={{
              relativeDate: dayjs(willUnpublishAt).fromNow(),
              hover: chunk => (
                <span
                  className="font-bold"
                  title={convertUtcToTz(willUnpublishAt, 'MMM D, YYYY HH:mm')}
                >
                  {chunk}
                </span>
              ),
            }}
          />
        </span>
        <div className="flex items-center gap-2 shrink-0">
          <Button
            as={Link}
            href="/manage/billing/usages"
            variant="primary"
            size="lg"
            className="text-sm font-semibold border-line-negative-subdued
            bg-action-destructive-subdued hover:bg-action-destructive-subdued-hovered active:bg-action-destructive-subdued-hovered text-content-on-negative"
          >
            <T
              defaultMessage="Check usage limits"
              id="Generics.CheckUsageLimits"
            />
          </Button>
        </div>
      </div>
    </div>
  )
}
