import { cva } from 'class-variance-authority'

export const badgeStyles = cva(
  [
    'inline-block',
    'min-w-0',
    'font-normal',
    'rounded-base',
    'focus:outline-none focus-visible:ring',
  ],
  {
    variants: {
      variant: {
        neutral: 'bg-surface-neutral text-content-subdued',
        highlight: 'text-content-on-highlight bg-surface-highlight',
        negative: 'text-content-on-negative bg-surface-negative',
        attention: 'text-content-on-attention bg-surface-attention',
        positive: 'text-content-on-positive bg-surface-positive',
        decorative1:
          'text-decorative-content-1 bg-decorative-surface-1-subdued',
        decorative2:
          'text-decorative-content-2 bg-decorative-surface-2-subdued',
        decorative3:
          'text-decorative-content-3 bg-decorative-surface-3-subdued',
        decorative4:
          'text-decorative-content-4 bg-decorative-surface-4-subdued',
        decorative5:
          'text-decorative-content-5 bg-decorative-surface-5-subdued',
        decorative6:
          'text-decorative-content-6 bg-decorative-surface-6-subdued',
        decorative7:
          'text-decorative-content-7 bg-decorative-surface-7-subdued',
      },
      size: {
        lg: 'px-2.5 py-1 min-h-[28px] text-label-sm gap-1',
        md: 'px-2 py-0.5 min-h-[24px] text-label-sm gap-1',
        sm: 'px-1.5 py-[1px] min-h-[20px] text-label-xs gap-0.5',
      },
      truncate: {
        true: 'truncate',
      },
      maxWidth: {
        md: 'max-w-[150px]',
        full: ' max-w-full',
      },
    },
    defaultVariants: {
      size: 'md',
      maxWidth: 'full',
    },
  },
)

export const badgeIconStyles = cva(['shrink-0'], {
  variants: {
    size: {
      lg: 'h-4 w-4',
      md: 'h-4 w-4',
      sm: 'h-4 w-4',
    },
    defaultVariants: {
      size: 'md',
    },
  },
})
