import { theme as defaultTheme } from '@tribeplatform/design-system/themes/default'
import { AppearanceStyle, Theme } from '@tribeplatform/design-system/types'

export const getPlaceholderColors = ({
  style,
  theme,
}: {
  style: AppearanceStyle['avatars']
  theme: Theme
}): string[] => {
  const themeColors = { ...defaultTheme.light1, ...theme.light1 }

  switch (style) {
    case 'cute':
    case 'neutral':
    case 'initials':
      return [
        // a mix of light and dark colors, one from each category
        themeColors['decorative-surface-1'],
        themeColors['decorative-content-2'],
        themeColors['decorative-surface-3'],
        themeColors['decorative-content-4'],
        themeColors['decorative-surface-5'],
        themeColors['decorative-content-6'],
        themeColors['decorative-surface-7'],
      ]
    default: {
      const exhaustiveCheck: never = style
      return exhaustiveCheck
    }
  }
}

export const getAvatarPlaceholderVariant = (
  style: Exclude<AppearanceStyle['avatars'], 'initials'>,
) => {
  switch (style) {
    case 'cute':
      return 'beam'
    case 'neutral':
      return 'bauhaus'
    default: {
      const exhaustiveCheck: never = style
      return exhaustiveCheck
    }
  }
}

export const initials = (name: string) => {
  if (!name) {
    return ''
  }

  const [firstName, lastName] = name.split(' ')
  return firstName && lastName
    ? `${firstName.charAt(0)}${lastName.charAt(0)}`
    : firstName.charAt(0)
}
