import { useContext } from 'react'

import { ClientContext } from './ClientProvider.js'

import { ClientContextProps } from './index.js'

export const useTribeClient = (): ClientContextProps => {
  const context = useContext(ClientContext)
  return {
    ...context,
    client: context.client,
    ready: context.ready,
    boot: context.boot,
    config: context.config,
  }
}
