import { clsx } from 'clsx'

import { useRouter } from '@tribeplatform/react-sdk'
import { Badge } from '@tribeplatform/react-ui-kit/Badge'

type TagPillProps = {
  title: string
  active?: boolean
  className?: string
  truncate?: boolean
}

type TagPillWithLinkProps = TagPillProps & {
  link: string | (() => string)
  title: string
  truncate?: boolean
}

export const TagPill = ({
  title,
  active,
  className,
  truncate,
}: TagPillProps) => (
  <Badge
    size="lg"
    variant={active ? 'positive' : 'neutral'}
    className={className}
  >
    <span className={clsx('min-w-0', truncate && 'truncate')}>{title}</span>
  </Badge>
)

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction<T extends Function = Function>(
  value: unknown,
): value is T {
  return typeof value === 'function'
}

export const TagPillWithLink = ({
  link,
  className,
  truncate,
  ...pillProps
}: TagPillWithLinkProps) => {
  const { Link } = useRouter()
  let to = ''
  if (isFunction(link)) {
    to = link()
  } else {
    to = link
  }
  return (
    <Link href={to} className={clsx(className, 'block rounded-base')}>
      <TagPill {...pillProps} className="max-w-full" truncate={truncate} />
    </Link>
  )
}
