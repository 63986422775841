import React, { ReactNode } from 'react'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { Image, Member } from '@tribeplatform/gql-client/types'
import { useUpdateMember, useUploadFile } from '@tribeplatform/react-sdk/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { Banner } from '../common/components/Banner.js'
import { useCropImageModal } from '../CropImageModal/useCropImageModal.js'
import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { MemberCoverImageModalTitle } from '../MediaModal/TitleComponents/MemberCoverImageModalTitle.js'
import { PopoverImagePicker } from '../Picker/PopoverImagePicker.js'

const RECOMMENDED_WIDTH = 1200
const RECOMMENDED_HEIGHT = 250

export const MemberBackgroundImage = ({
  member,
  fallback,
}: {
  member: Member
  fallback?: ReactNode
}) => {
  const { $t } = useI18n()
  const {
    loadingFile,
    uploadFile,
    loading: uploadFileLoading,
  } = useUploadFile()
  const { mutateAsync: updateMember, isLoading: updateMemberLoading } =
    useUpdateMember()

  const onUpload = (file: File) => {
    uploadFile(file, async uploadedImage => {
      await updateMember(
        {
          id: member.id,
          input: {
            bannerId: uploadedImage.id,
          },
        },
        {
          onError: () => {
            toast({
              title: $t({
                id: 'Banner.FailedToUpdate',
                defaultMessage:
                  'Something went wrong and we were unable to update your banner! Please try again.',
              }),
              status: 'error',
            })
          },
        },
      )
    })
  }

  const banner = loadingFile || (member?.banner as Image)
  const loading = uploadFileLoading || updateMemberLoading

  const [canUpdateMember] = hasScopesPermission(member, ['updateMember'])

  const { onSelectFile, children: cropModal } = useCropImageModal({
    onSuccess: ({ croppedImage }) => onUpload(croppedImage),
    options: {
      shape: 'rect',
      aspectRatio: RECOMMENDED_WIDTH / RECOMMENDED_HEIGHT,
      minZoom: 1,
      format: 'image/jpeg',
    },
  })

  const handleSelectImage = (files: File[]) => {
    if (files.length === 1) {
      onSelectFile(files?.[0])
    }
  }

  const onRemoveBanner = () =>
    updateMember({
      id: member.id,
      input: {
        bannerId: null,
      },
    })

  return (
    <div className="group relative bg-background">
      <Banner
        banner={banner}
        title={<MemberCoverImageModalTitle member={member} />}
        className="h-32 lg:h-48"
        fallback={fallback}
        zoom
      />
      {loading && (
        <div className="absolute inset-0 flex bg-background-overlay">
          <SvgIcon className="animate-spin h-7 w-7 m-auto" name="spinner" />
        </div>
      )}
      {canUpdateMember && !loading && (
        <div className="absolute bottom-5 end-5">
          <PopoverImagePicker
            tabs={['upload', 'link', 'unsplash']}
            removable={!!banner}
            onRemove={onRemoveBanner}
            onImageChange={handleSelectImage}
            sizeConfig={{
              recommendedWidth: RECOMMENDED_WIDTH,
              recommendedHeight: RECOMMENDED_HEIGHT,
            }}
          >
            <Button
              size="md"
              variant="secondaryNeutral"
              className="group-hover:opacity-100 focus-visible:opacity-100 opacity-0 duration-300 z-10"
            >
              {banner ? (
                <T defaultMessage="Change cover" id="Generics.ChangeCover" />
              ) : (
                <T defaultMessage="Add cover image" id="Generics.AddCover" />
              )}
            </Button>
          </PopoverImagePicker>
        </div>
      )}
      {cropModal}
    </div>
  )
}
