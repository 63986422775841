import { useState } from 'react'

import { getFieldIcon } from '@tribeplatform/react-components/CustomField'
import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SearchInput } from '@tribeplatform/react-ui-kit/Input'

export const FieldPicker = ({ fields, onChange, value }) => {
  const { $t } = useI18n()
  const [query, setQuery] = useState('')

  return (
    <Dropdown>
      {value ? (
        <Dropdown.Button>
          {fields.find(field => field.accessor === value)?.Header}
        </Dropdown.Button>
      ) : (
        <Dropdown.Button>
          <T defaultMessage="Add filter" id="Generics.AddFilter" />
        </Dropdown.Button>
      )}
      <Dropdown.Items>
        <div className="p-2">
          <SearchInput
            onChange={e => setQuery(e.target.value)}
            value={query}
            placeholder={$t({
              defaultMessage: 'Search...',
              id: 'Generics.SearchDotDotDot',
            })}
            size="compact"
          />
        </div>
        <div className="max-h-120 overflow-auto">
          {fields
            .filter(field => {
              if (!query) {
                return true
              }
              const name = field?.name || field?.Header || ''
              return name.toLowerCase().includes(query?.toLowerCase())
            })
            .map(field => (
              <Dropdown.Item
                key={field.accessor}
                onClick={() => {
                  onChange(field?.accessor)
                }}
                leadingIcon={getFieldIcon(field)}
              >
                {field.name || field.Header}
              </Dropdown.Item>
            ))}
        </div>
      </Dropdown.Items>
    </Dropdown>
  )
}
