import { Image, Member } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { Avatar, type AvatarSize } from '@tribeplatform/react-ui-kit/Avatar'

import { MemberAvatarPlaceholder } from './MemberAvatarPlaceholder.js'

export interface MemberAvatarProps {
  member?: Pick<Member, 'name' | 'profilePicture' | 'relativeUrl'>
  size: AvatarSize
  clickable?: boolean
  className?: string
}

export const MemberAvatar = ({
  member,
  size,
  clickable = false,
  className,
}: MemberAvatarProps) => {
  const { Link } = useRouter()

  const profilePicture = member?.profilePicture as Image

  const avatar = (
    <Avatar
      size={size}
      name={member?.name}
      className={className}
      src={profilePicture?.urls?.small || profilePicture?.url}
      fallback={MemberAvatarPlaceholder}
    />
  )

  if (clickable && !!member) {
    return (
      <Link
        href={member?.relativeUrl}
        rounded="avatar"
        className="shrink-0 block"
        variant="inherit"
      >
        {avatar}
      </Link>
    )
  }

  return avatar
}
