import { useI18n } from '@tribeplatform/react-components/i18n'
import { SearchBar } from '@tribeplatform/react-components/Search'

import { useSlate } from '../../../hooks/index.js'
import { SearchFor, type SearchAction } from '../types.js'
import { getSpaceIdsToSearch } from '../utils/utils.js'

interface Props {
  action: SearchAction
}
export const SearchActionRenderer = ({
  action: { searchFor, searchSource, spaceIds, placeholder },
}: Props) => {
  const { $t } = useI18n()
  const {
    context: { spaceId: slateSpaceId },
  } = useSlate()
  const entityType = searchFor === SearchFor.all ? '' : searchFor
  const defaultPlaceholder = $t({
    id: 'Generics.SearchDotDotDot',
    defaultMessage: 'Search...',
  })
  const spaceIdsToSearch = getSpaceIdsToSearch(
    searchSource,
    spaceIds,
    slateSpaceId,
  )

  return (
    <div className="inline-block w-full max-w-[40rem]">
      <SearchBar
        entityType={entityType}
        spaceIds={spaceIdsToSearch}
        autoFocus={false}
        placeholder={placeholder ?? defaultPlaceholder}
      />
    </div>
  )
}
