import { Paginated } from '../common/paginated.fields.js'

export type TagFields = 'basic' | 'all'
export type PaginatedTagFields = Paginated<TagFields>

// eslint-disable-next-line unused-imports/no-unused-vars
export function tagGQLFields(fields: TagFields) {
  return `
    description
    id
    slug
    title
  `
}
