import { ComponentPropsWithoutRef, forwardRef } from 'react'

import { clsx } from 'clsx'

import { inputStyles } from '../Input/Input.styles.js'

export type TextareaProps = ComponentPropsWithoutRef<'textarea'> & {
  invalid?: boolean
}
/**
 * The Textarea component allows you to easily create multi-line text inputs.
 */
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ invalid = false, hidden, className, disabled, ...rest }, ref) => {
    return (
      <textarea
        ref={ref}
        className={clsx(
          inputStyles({
            invalid,
            disabled,
          }),
          className,
          hidden && 'hidden',
        )}
        rows={4}
        disabled={disabled}
        hidden={hidden}
        {...rest}
      />
    )
  },
)
