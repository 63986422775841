import { createContext, useContext } from 'react'

import { ContainerDirection, ContainerSize } from './types.js'

export interface ContainerContext {
  size: ContainerSize
  direction: ContainerDirection
}

const ContainerContext = createContext<ContainerContext>(undefined)

export const ContainerProvider = ({
  size,
  direction = 'vertical' as ContainerDirection,
  children,
}) => {
  const { size: parentSize } = useContainer()
  return (
    <ContainerContext.Provider
      value={{
        size: size || parentSize,
        direction,
      }}
    >
      {children}
    </ContainerContext.Provider>
  )
}

export const useContainer = (): ContainerContext => {
  const context = useContext(ContainerContext)
  if (context === undefined) {
    return {
      size: 'full',
      direction: 'vertical',
    }
  }
  return context
}
