import { clsx } from 'clsx'

import { useStaticIntl } from '@tribeplatform/react-components/i18n'

import { useSlate } from '../../../hooks/slate.hook.js'
import { ActionItem } from '../Components/ActionItem.js'
import { SignInActions } from '../Components/SignInActions.js'
import { actionItemsFactory } from '../config.js'
import {
  ItemAlignment,
  type ActionsSection as TActionsSection,
} from '../types.js'
import { convertToActionItems } from '../utils/converters.js'

interface Props extends TActionsSection {
  active: boolean
  fixedWidth?: boolean
}
export const ActionsSection = ({
  active,
  stretch,
  alignment,
  items,
  fixedWidth,
}: Props) => {
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'
  const allActionItems = useStaticIntl(actionItemsFactory)
  const actionItems = convertToActionItems(items, allActionItems)

  return (
    <div
      className={clsx(
        'h-full flex',
        fixedWidth && 'w-1/2 justify-end',
        isPreviewMode && 'px-1',
        active &&
          'rounded-base ring ring-customizer-blue ring-inset ring-offset-0',
        stretch && [
          'flex-grow',
          alignment === ItemAlignment.start && 'justify-start',
          alignment === ItemAlignment.center && 'justify-center',
          alignment === ItemAlignment.end && 'justify-end',
        ],
      )}
    >
      <div className="h-full flex flex-row-reverse flex-wrap gap-x-2 gap-y-10">
        <SignInActions />
        {actionItems
          .filter(({ enabled }) => enabled)
          .map(({ id, label }) => (
            <ActionItem key={id} id={id} label={label} />
          ))}
      </div>
    </div>
  )
}
