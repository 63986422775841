import { ReactNode } from 'react'

import { clsx } from 'clsx'

import { FormControlMeta } from './FormControlMeta.js'
import { Label } from './Label.js'

export type FormControlLayoutProps = {
  name: string
  label?: ReactNode
  secondaryLabel?: ReactNode
  tertiaryLabel?: ReactNode
  helperText?: ReactNode
  error?: ReactNode
  invalid?: boolean
  hidden?: boolean
  className?: string
  size?: 'default' | 'compact'
  children: ReactNode
}

export const FormControlLayout = ({
  name,
  label,
  secondaryLabel,
  tertiaryLabel,
  helperText,
  error,
  invalid,
  hidden,
  size,
  className,
  children,
}: FormControlLayoutProps) => {
  return (
    <div className={clsx('space-y-1', className)} hidden={hidden}>
      {!!label || !!secondaryLabel || !!tertiaryLabel ? (
        <Label
          htmlFor={name}
          secondaryLabel={secondaryLabel}
          tertiaryLabel={tertiaryLabel}
          size={size}
        >
          {label}
        </Label>
      ) : null}
      <div className="space-y-2">
        {children}
        <FormControlMeta {...{ helperText, error, invalid, size }} />
      </div>
    </div>
  )
}
