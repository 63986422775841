import {
  confirmable,
  createReactTreeMounter,
  createConfirmationCreater,
  createMountPoint,
} from 'react-confirm'

import { AlertDialog, CreateAlertOptions } from './AlertDialog.js'
import { ConfirmDialog, CreateConfirmationOptions } from './ConfirmDialog.js'
import { CreatePromptOptions, PromptDialog } from './PromptDialog.js'

const mounter = createReactTreeMounter()
const createConfirmation = createConfirmationCreater(mounter)
export const DialogMountPoint = createMountPoint(mounter)

export type AlertType = (options: CreateAlertOptions) => Promise<void>
export type ConfirmType = (
  options: CreateConfirmationOptions,
) => Promise<boolean>
export type PromptType = (
  options: CreatePromptOptions,
) => Promise<{ label: string; value: string }[]>

export const alert: AlertType = createConfirmation(confirmable(AlertDialog))
export const confirm: ConfirmType = createConfirmation(
  confirmable(ConfirmDialog),
)
export const prompt: PromptType = createConfirmation(confirmable(PromptDialog))

export const Alert = alert
export const Confirm = confirm
export const Prompt = prompt
