import { Card } from '@tribeplatform/react-ui-kit/Card'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Input } from '@tribeplatform/react-ui-kit/Input'

import { useI18n } from '../i18n/providers/I18nProvider.js'

export const FilterBar = ({ setFilters }) => {
  const { $t } = useI18n()

  return (
    <Card>
      <Card.Content>
        <Input
          leadingIcon={<SvgIcon name="search" />}
          onChange={e => setFilters({ query: e.target.value || undefined })}
          name="search"
          placeholder={$t({
            id: 'MemberList.FilterBar.SearchMembersPlaceholder',
            defaultMessage: 'Search {MEMBERS}...',
          })}
        />
      </Card.Content>
    </Card>
  )
}
