import { HideOnDeviceSettings } from './ResponsiveSettings/HideOnDeviceSettings.js'
import { RestrictMaxWidthSettings } from './ResponsiveSettings/RestrictMaxWidthSettings.js'
import { ReverseSettings } from './ResponsiveSettings/ReverseSettings.js'
import { SettingSections } from './SettingSections.js'

interface Props {
  hideOnMobile?: boolean
  hideOnTablet?: boolean
  hideOnDesktop?: boolean
  reverse?: boolean
  restrictMaxWidth?: boolean
  onHideOnMobileChange: (value: boolean) => unknown
  onHideOnTabletChange: (value: boolean) => unknown
  onHideOnDesktopChange: (value: boolean) => unknown
  onReverseChange?: (value: boolean) => unknown
  onRestrictMaxWidthChange?: (value: boolean) => unknown
}

export const ResponsiveSettings = ({
  hideOnMobile,
  hideOnTablet,
  hideOnDesktop,
  reverse,
  restrictMaxWidth,
  onHideOnMobileChange,
  onHideOnTabletChange,
  onHideOnDesktopChange,
  onReverseChange,
  onRestrictMaxWidthChange,
}: Props) => {
  return (
    <SettingSections.Responsive>
      <ReverseSettings reverse={reverse} onChange={onReverseChange} />
      <HideOnDeviceSettings
        hideOnMobile={hideOnMobile}
        hideOnTablet={hideOnTablet}
        hideOnDesktop={hideOnDesktop}
        onHideOnMobileChange={onHideOnMobileChange}
        onHideOnTabletChange={onHideOnTabletChange}
        onHideOnDesktopChange={onHideOnDesktopChange}
      />
      <RestrictMaxWidthSettings
        restrict={restrictMaxWidth}
        onChange={onRestrictMaxWidthChange}
      />
    </SettingSections.Responsive>
  )
}
