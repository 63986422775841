import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Action,
  MutationHidePostArgs,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getFeedKey } from '../../utils/keys/feed.keys.js'
import { getPostKey, getPostsKey } from '../../utils/keys/post.key.js'
import { pinnedPostsFilter } from './filters.js'

export const useHidePost = (options?: {
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationHidePostArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<Action, ClientError, MutationHidePostArgs>(
    (variables: MutationHidePostArgs) => client.posts.hide(variables),
    {
      onSettled: (data, error, variables) => {
        const postKey = getPostKey({ variables: { id: variables?.id } })
        queryClient.invalidateQueries(postKey)

        const postsKey = getPostsKey()
        queryClient.invalidateQueries(postsKey)
        queryClient.invalidateQueries({ ...pinnedPostsFilter, type: 'active' })
        const feedKey = getFeedKey()
        queryClient.invalidateQueries(feedKey)
      },
      ...useMutationOptions,
    },
  )
}
