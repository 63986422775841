import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { CollectionMenuBlock as Component } from './Block.js'
import { CollectionMenuProps, CollectionMenuViewStyle } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({
    default: m.CollectionMenuBlockSettings,
  })),
)

export const RegisteredCollectionMenuBlock = (
  intl: I18nShape,
): RegisteredBlock<CollectionMenuProps> => {
  const { $t } = intl

  return {
    recommendedName: 'CollectionMenu',
    Component,
    config: {
      type: BlockType.Block,
      usable: true,
      displayName: $t({
        id: 'Blocks.CollectionMenu.DisplayName',
        defaultMessage: 'Collections menu',
      }),
      Settings,
      Icon: <SvgIcon name="grid" />,
      defaultProps: {
        items: [],
        viewStyle: CollectionMenuViewStyle.Collapsible,
      },
    },
  }
}
