import { useCallback } from 'react'

import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { getAuthTokenKey } from '../../utils/keys/authToken.keys.js'
import { getNetworkKey } from '../../utils/keys/network.key.js'

export const useInvalidateNetworkQuery = () => {
  const queryClient = useQueryClient()
  const invalidate = useCallback(() => {
    const networkKey = getNetworkKey()
    const authTokenKey = getAuthTokenKey()
    // Re-fetch the network
    queryClient.invalidateQueries(networkKey)

    // Re-fetch the auth tokens
    queryClient.invalidateQueries(authTokenKey)
  }, [queryClient])

  return invalidate
}
