import React, { ReactNode } from 'react'

import { Placement } from '@floating-ui/react'

import { Glyph, GlyphMediaVariant } from '@tribeplatform/gql-client/types'
import { Popover } from '@tribeplatform/react-ui-kit/Popover'

import { EmojiPicker } from './EmojiPicker.js'
import type { PickerEmoji } from './types.js'
import { emojiToImageId } from './utils/picker.utils.js'

type Props = {
  open: boolean
  onChange: (glyph: Glyph) => void
  onClose: () => void
  placement?: Placement
  children: ReactNode
}
export const PopoverEmojiPicker = ({
  open,
  onChange,
  onClose,
  placement,
  children,
}: Props) => {
  const handleEmojiChange = async (pickerEmoji: PickerEmoji) => {
    const glyph: Glyph = {
      id: emojiToImageId(pickerEmoji),
      text: pickerEmoji.unified,
      variant: GlyphMediaVariant.emoji,
    }
    onChange(glyph)
    onClose()
  }

  return (
    <Popover
      activator={<>{children}</>}
      wrapperClassName="flex-1"
      open={open}
      onClose={onClose}
      autofocusTarget="first-node"
      fluidContent
      allowPanelOverflow={false}
      placement={placement}
    >
      <EmojiPicker onEmojiChange={handleEmojiChange} />
    </Popover>
  )
}
