import { useState } from 'react'

import { HelperText } from '@tribeplatform/react-ui-kit/HelperText'
import { NumberInput } from '@tribeplatform/react-ui-kit/NumberInput'

import { T } from '../../i18n/index.js'
import type { ValuePickerComponent } from '../types.js'

export const NumberValuePicker: ValuePickerComponent = ({
  filter,
  onChange,
}) => {
  const [error, setError] = useState(false)
  const { value } = filter
  const currentValue = Number(value ?? 0)

  if (Number.isNaN(currentValue)) {
    return null
  }

  return (
    <>
      <NumberInput
        value={currentValue}
        onChange={number => {
          if (Number.isNaN(number)) {
            setError(true)
            return
          }

          setError(false)
          onChange(number)
        }}
        min={-Infinity}
        max={Infinity}
        step={1}
      />
      {error && (
        <HelperText status="error">
          <T
            defaultMessage="Please enter a valid number"
            id="Filters.ValuePickers.Number.ValidationError"
          />
        </HelperText>
      )}
    </>
  )
}
