import { useEffect, useRef, useState } from 'react'

import { debounce as _debounce } from '@bettermode/common/debounce'
import { Tag } from '@tribeplatform/gql-client/types'
import { Form, FormRef } from '@tribeplatform/react-components/Form'
import { T } from '@tribeplatform/react-components/i18n'
import {
  useMember,
  useSpace,
  useSpaceTags,
} from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'

import { FieldPicker } from './FieldPicker.js'
import { FilterItem } from './FilterItem.js'
import {
  TableFilterField,
  TableFilterValue,
  TableFilterValueParsed,
} from './types.js'
import { getDefaultOperator } from './utils.js'

interface Props {
  fields: TableFilterField[]
  filters?: TableFilterValue[]
  setFilters: (value: TableFilterValue[]) => void
}

export const FieldsFilter = ({ fields, filters = [], setFilters }: Props) => {
  const formRef = useRef<FormRef>()

  const filterFields = [...fields.filter(field => field.type)]

  const parsedFilters = filters.map(filter => ({
    ...filter,
    key: filter?.key?.replace('fields.', 'mappedFields.'),
    value: JSON.parse(filter.value),
  }))

  const spaceId = parsedFilters.find(filter => filter.key === 'space')?.value
  const memberId = parsedFilters?.find(
    filter => filter?.key === 'owner.member',
  )?.value
  const tagId = parsedFilters?.find(filter => filter?.key === 'tags')?.value

  const { data: space } = useSpace({
    fields: { image: 'basic' },
    variables: { id: spaceId },
    useQueryOptions: {
      enabled: !!spaceId,
    },
  })
  const { data: member } = useMember({
    fields: { profilePicture: 'basic' },
    variables: { id: memberId },
    useQueryOptions: { enabled: !!memberId },
  })

  const { data: tags } = useSpaceTags({
    variables: {
      limit: 1,
      ids: [tagId],
    },
    useInfiniteQueryOptions: {
      enabled: !!tagId,
    },
  })
  const { nodes: spaceTags } = simplifyPaginatedResult<Tag>(tags)

  const [filterBy, setFilterBy] =
    useState<TableFilterValueParsed[]>(parsedFilters)

  const defaultValues: Record<string, TableFilterValueParsed['value']> = {}
  filterBy.forEach((filter, index) => {
    let defaultValue = filter.value
    if (filter.key === 'owner.member') {
      defaultValue = member
    } else if (filter.key === 'space') {
      defaultValue = space
    } else if (filter.key === 'tags') {
      defaultValue = spaceTags?.[0]
    }
    defaultValues[`${filter.key}-${index}`] = defaultValue
  })

  useEffect(() => {
    if (formRef.current?.methods) {
      formRef.current.methods.reset(defaultValues)
    }
  }, [defaultValues])

  useEffect(
    _debounce(() => {
      const newFilterBy = []
      filterBy.forEach(filter => {
        const isBareFilter = fields?.some(
          field => field.accessor === filter.key && field.extraProps?.bare,
        )

        if (
          !isBareFilter &&
          (typeof filter?.value === 'undefined' || filter?.value === '')
        ) {
          return
        }
        newFilterBy.push({
          operator: filter?.operator,
          value: JSON.stringify(filter.value),
          key: filter?.key?.replace('mappedFields.', 'fields.'),
        })
      })
      setFilters(newFilterBy)
    }, 300),
    [filterBy],
  )

  if (!filterFields.length) {
    return null
  }

  return (
    <Form ref={formRef} onSubmit={null} defaultValues={defaultValues}>
      <div className="flex flex-wrap items-center gap-3">
        {filterBy.map((filter, index) => {
          const field = filterFields?.find(
            field => field.accessor === filter.key,
          )
          const name = `${filter.key}-${index}`

          return (
            // We have to use index here because there is no way to distinguish the elements
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="flex items-center gap-3">
              <FilterItem
                fields={filterFields}
                name={name}
                field={field}
                filter={filter}
                value={defaultValues[name]}
                onChange={newValue => {
                  const newFilterBy = [...filterBy]
                  newFilterBy[index] = {
                    ...newFilterBy[index],
                    ...newValue,
                  }
                  setFilterBy(newFilterBy)
                }}
                onReset={() => {
                  const newFilter = [...filterBy]
                  newFilter.splice(index, 1)
                  setFilterBy(newFilter)
                }}
              />
              {index !== filterBy.length - 1 && (
                <T defaultMessage="and" id="Generics.and" />
              )}
            </div>
          )
        })}
        <div className="flex-shrink-0 flex space-s-3">
          <FieldPicker
            fields={filterFields}
            value={null}
            onChange={key => {
              const newFilterBy = [
                ...filterBy,
                {
                  key,
                  operator: getDefaultOperator(
                    fields?.find(field => field.accessor === key),
                  ),
                  value: '',
                },
              ]
              setFilterBy(newFilterBy)
            }}
          />
        </div>
      </div>
    </Form>
  )
}
