import { ReactNode } from 'react'

import { Text } from '../Text/index.js'

export interface DropdownGroupProps {
  title?: string
  children: ReactNode
}

export const DropdownGroup = ({ children, title }: DropdownGroupProps) => {
  return (
    <div className="py-1">
      {title && (
        <Text
          variant="labelSm"
          fontWeight="bold"
          color="subdued"
          className="px-2 py-2"
        >
          {title}
        </Text>
      )}
      {children}
    </div>
  )
}
