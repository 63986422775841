export enum QueryType {
  In = 'IN',
}

export type InQueryType<P> = {
  _type: QueryType.In
  value: P[]
}

export type Query<P> = P | InQueryType<P>

export type ObjectQuery<P extends Record<string, unknown>> = {
  [K in keyof P]?: Query<P[K]>
}
