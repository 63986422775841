import { lazy, Suspense, useState } from 'react'

import { Link as RouterLink } from 'react-router-dom'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import {
  NetworkVisibility,
  Space,
  SpaceMembershipStatus,
} from '@tribeplatform/gql-client/types'
import { useRouterPath } from '@tribeplatform/react-sdk'
import {
  useAuthMember,
  useAuthToken,
  useLeaveSpace,
} from '@tribeplatform/react-sdk/hooks'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { T } from '../i18n/components/T.js'

const SpaceSettingsModal = lazy(() =>
  import('./SpaceSettingsModal/index.js').then(module => ({
    default: module.SpaceSettingsModal,
  })),
)
const ShareModal = lazy(() =>
  import('../ShareModal/ShareModal.js').then(m => ({
    default: m.ShareModal,
  })),
)
const SpaceMembersModal = lazy(() =>
  import('../MemberList/SpaceMembersModal.js').then(m => ({
    default: m.SpaceMembersModal,
  })),
)
const SpaceAnalyticsModal = lazy(() =>
  import('../Analytics/SpaceAnalytics/SpaceAnalyticsModal.js').then(m => ({
    default: m.SpaceAnalyticsModal,
  })),
)
const AuditLogsModal = lazy(() =>
  import('../AuditLog/AuditLogsModal.js').then(m => ({
    default: m.AuditLogsModal,
  })),
)
const AddMembersModal = lazy(() =>
  import('./AddMembersModal.js').then(m => ({
    default: m.AddMembersModal,
  })),
)

interface Props {
  space: Space
  hideHotKeys?: boolean
}

export const SpaceActionItems = ({ space, hideHotKeys = false }: Props) => {
  const [auditLogsModal, setAuditLogsModal] = useState<boolean>(false)
  const [spaceAnalyticsModal, setSpaceAnalyticsModal] = useState<boolean>(false)
  const [settingsModal, setSettingsModal] = useState<boolean>(false)
  const [shareModal, setShareModal] = useState<boolean>(false)
  const [addMemberModal, setAddMemberModal] = useState<boolean>(false)
  const [spaceMembersModal, setSpaceMemberModal] = useState<boolean>(false)

  const { mutate: leave } = useLeaveSpace()

  const joined =
    space?.authMemberProps?.membershipStatus === SpaceMembershipStatus.joined

  const {
    data: { network },
  } = useAuthToken()
  const [canUpdateNetwork] = hasScopesPermission(network, [
    'updateNetwork',
    'createTemplate',
  ])
  const [canUpdateSpace, canAddMembers] = hasScopesPermission(space, [
    'updateSpace',
    'addSpaceMembers',
  ])
  const { isAdmin } = useAuthMember()

  const pathname = useRouterPath()
  const spacePath = space?.relativeUrl
  const isSpacePage = pathname === spacePath

  return (
    <>
      <Dropdown.Items>
        {!isSpacePage && (
          <Dropdown.Item
            as={RouterLink}
            to={spacePath}
            leadingIcon={<SvgIcon name="grid" />}
          >
            <T defaultMessage="View {SPACE}" id="Space.Actions.View" />
          </Dropdown.Item>
        )}
        {canUpdateSpace && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="settings" />}
            onClick={() => setSettingsModal(true)}
          >
            <T defaultMessage="Settings" id="Generics.Settings" />
          </Dropdown.Item>
        )}
        {canAddMembers && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="member-add" />}
            onClick={() => setAddMemberModal(true)}
          >
            <T defaultMessage="Add {MEMBERS}" id="Space.Actions.AddMembers" />
          </Dropdown.Item>
        )}
        {canAddMembers && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="members" />}
            onClick={() => setSpaceMemberModal(true)}
          >
            <T defaultMessage="View {MEMBERS}" id="Space.Actions.ViewMembers" />
          </Dropdown.Item>
        )}
        <Dropdown.Item
          leadingIcon={<SvgIcon name="share" />}
          onClick={() => {
            setShareModal(true)
          }}
          hotkey={!hideHotKeys ? 'option+s,alt+s' : undefined}
        >
          <T defaultMessage="Share" id="Generics.Share" />
        </Dropdown.Item>
        {canUpdateSpace && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="moderation" />}
            as={RouterLink}
            to={`/settings/moderation/${space.id}/pending-posts`}
            hotkey={!hideHotKeys ? 'm' : undefined}
          >
            <T defaultMessage="Moderation" id="Generics.Moderation" />
          </Dropdown.Item>
        )}
        {canUpdateSpace && isAdmin && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="analytics" />}
            onClick={() => setSpaceAnalyticsModal(true)}
          >
            <T defaultMessage="Analytics" id="Generics.Analytics" />
          </Dropdown.Item>
        )}
        {canUpdateSpace && canUpdateNetwork && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="audit-log" />}
            onClick={() => setAuditLogsModal(true)}
          >
            <T defaultMessage="Audit logs" id="Generics.AuditLogs" />
          </Dropdown.Item>
        )}
        {canUpdateSpace && canUpdateNetwork && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="customizer" />}
            as={RouterLink}
            to={`/manage/customizer/spaces/${space.id}/blocks`}
            hotkey={!hideHotKeys ? 'c' : undefined}
          >
            <T defaultMessage="Customize" id="Generics.Customize" />
          </Dropdown.Item>
        )}
        {joined && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="logout" />}
            onClick={() => leave({ spaceId: space?.id })}
            hotkey={!hideHotKeys ? 'j' : undefined}
          >
            <T defaultMessage="Leave" id="Generics.Leave" />
          </Dropdown.Item>
        )}
      </Dropdown.Items>
      {shareModal && (
        <Suspense fallback={<></>}>
          <ShareModal
            isPrivate={
              space?.hidden || network.visibility === NetworkVisibility.private
            }
            spaceId={space?.id}
            open
            onClose={() => setShareModal(false)}
            title={space.name}
            url={space?.url}
          />
        </Suspense>
      )}
      {auditLogsModal && (
        <Suspense fallback={<></>}>
          <AuditLogsModal
            open
            onClose={() => setAuditLogsModal(false)}
            spaceId={space?.id}
          />
        </Suspense>
      )}
      {settingsModal && (
        <Suspense fallback={<></>}>
          <SpaceSettingsModal
            open
            onClose={() => setSettingsModal(false)}
            space={space}
          />
        </Suspense>
      )}
      {spaceAnalyticsModal && (
        <Suspense fallback={<></>}>
          <SpaceAnalyticsModal
            open
            onClose={() => setSpaceAnalyticsModal(false)}
            space={space}
            linkToContent={false}
          />
        </Suspense>
      )}
      {addMemberModal && (
        <Suspense fallback={<></>}>
          <AddMembersModal
            space={space}
            onClose={() => setAddMemberModal(false)}
          />
        </Suspense>
      )}
      {spaceMembersModal && (
        <Suspense fallback={<></>}>
          <SpaceMembersModal
            open
            onClose={() => setSpaceMemberModal(false)}
            space={space}
          />
        </Suspense>
      )}
    </>
  )
}
