import type { SpaceFields } from '@tribeplatform/gql-client/graphql'
import type {
  QuerySpacesBySlugsArgs,
  Space,
} from '@tribeplatform/gql-client/types'

import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSpacesBySlugsKey } from '../../utils/keys/space.key.js'

export const useSpacesBySlugs = (options?: {
  variables: QuerySpacesBySlugsArgs
  fields?: SpaceFields
  useQueryOptions?: UseQueryOptions<Space[]>
}) => {
  const { variables, fields = 'basic', useQueryOptions } = options || {}
  const { client } = useTribeClient()
  const spacesBySlugsKey = getSpacesBySlugsKey({ variables, fields })

  return useQuery<Space[]>(
    spacesBySlugsKey,
    () => client?.spaces.listBySlugs(variables, fields),
    useQueryOptions,
  )
}
