import { Space } from '@tribeplatform/gql-client/types'

import { SpaceMemberList } from './SpaceMemberList.js'

export const WhySpaceMemberList = ({
  space,
  spaceId,
  ...rest
}: {
  space: Space
  spaceId: string
}) => {
  return (
    <SpaceMemberList
      spaceId={spaceId || space?.id}
      key="members-list"
      {...rest}
    />
  )
}
