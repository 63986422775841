import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { Space } from '@tribeplatform/gql-client/types'
import { T } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { ActionButtonRenderer } from './ActionButtonRenderer.js'

interface Props {
  space: Space
  fullWidth?: boolean
  showText?: boolean
  loading?: boolean
  showIcon?: boolean
  onClick?: () => void
  className?: string
}
export const LeaveButton = ({
  space,
  fullWidth = false,
  showText = true,
  loading,
  showIcon = true,
  onClick,
  className,
}: Props) => {
  const [canLeaveSpace] = hasScopesPermission(space, ['leaveSpace'])

  if (!canLeaveSpace) {
    return null
  }

  return (
    <ActionButtonRenderer
      pressed
      className={className}
      icon={<SvgIcon name="check-circle-broken-filled" size="xl" />}
      label={<T id="Generics.Joined" defaultMessage="Joined" />}
      showText={showText}
      showIcon={showIcon}
      onClick={onClick}
      disabled={loading}
      loading={loading}
      variant="secondaryNeutral"
      fullWidth={fullWidth}
      tooltip={
        <T
          defaultMessage="Leave the space"
          id="Blocks.SpaceHeader.LeaveButton.Tooltip"
        />
      }
    />
  )
}
