import { FC, cloneElement, ReactElement } from 'react'

import { Modal } from '../Modal/index.js'

type ConfirmableProps = {
  proceed: (value: boolean) => void
  show: boolean
}

export type CreatePromptOptions = {
  title: string
  description?: string
  proceedLabel?: string
  cancelLabel?: string
  children: ReactElement
}

export type PromptDialogProps = ConfirmableProps & CreatePromptOptions

/**
 *
 */
export const PromptDialog: FC<PromptDialogProps> = props => {
  const { show, proceed, title, description, children } = props

  const dismiss = () => proceed(false)

  return (
    <Modal open={show} onClose={dismiss} showCloseBtn={false}>
      <Modal.Header title={title} description={description} />
      <Modal.Content>
        {cloneElement(children, { close: dismiss })}
      </Modal.Content>
    </Modal>
  )
}
