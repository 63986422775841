import { Member } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { Badge } from '@tribeplatform/react-ui-kit/Badge'

import { MemberAvatar } from '../common/components/index.js'

export const MemberPill = ({ member }: { member: Member }) => {
  const { Link } = useRouter()
  if (!member?.name) {
    return null
  }

  return (
    <Link
      href={member?.relativeUrl}
      className="rounded-base inline-block"
      translate="no"
    >
      <Badge
        className="ps-1"
        leadingIcon={<MemberAvatar size="xl" member={member} />}
      >
        {member?.name}
      </Badge>
    </Link>
  )
}
