import { useEffect, useMemo, useRef } from 'react'

import { clsx } from 'clsx'
import { useParams } from 'react-router'

import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { useIsomorphicLayoutEffect } from '@tribeplatform/react-ui-kit/hooks'
import { Container } from '@tribeplatform/react-ui-kit/Layout'

import { useAdminPanel } from '../AdminPanel/providers/AdminPanelProvider.js'
import { FieldInput } from '../CustomField/FieldInput.js'
import { FieldPrivacyBadges } from '../CustomField/FieldPrivacyBadges.js'
import { Form } from '../Form/index.js'
import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { getTranslatedPostTypeName } from '../Post/utils/postTranslate.js'
import { PostForm } from '../PostForm/PostForm.js'
import { useCMS } from './hooks/useCMS.js'
import type { CMSSharedState } from './types.js'
import { getFieldSetting } from './utils/utils.js'

import { findPostType } from './index.js'

export const CMSFormPreview = () => {
  const intl = useI18n()
  const firstRenderRef = useRef(true)
  const { sharedState, resetSharedState } = useAdminPanel<CMSSharedState>()
  const { highlightedFieldId, selectedField, newField } = sharedState
  const { slug } = useParams<{ slug: string }>()
  const {
    data: { id: networkId },
  } = useNetwork()
  const { postTypes } = useCMS()
  const postType = useMemo(
    () => findPostType({ networkId, slug, postTypes }),
    [networkId, slug, postTypes],
  )
  const previewFields = useMemo(() => {
    if (!postType || !selectedField) {
      return null
    }

    const fields = postType?.postFields?.fields ?? []
    const newFields = fields.map(field => {
      if (field.key === selectedField?.key) {
        return selectedField
      }

      return field
    })

    return newFields
  }, [postType, selectedField])

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false
      return
    }

    if (selectedField) {
      const fieldInput = document.getElementById(selectedField.key)
      if (fieldInput) {
        fieldInput.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
      return
    }

    if (highlightedFieldId) {
      const fieldInput = document.getElementById(highlightedFieldId)
      if (fieldInput) {
        fieldInput.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }, [selectedField, highlightedFieldId])

  useIsomorphicLayoutEffect(() => {
    resetSharedState()
  }, [resetSharedState])

  if (!postType) {
    return null
  }

  return (
    <div className="w-full h-full flex justify-center">
      <Container size="md">
        <h3 className="text-heading-sm font-medium">
          <T
            defaultMessage="Form preview"
            id="Admin.PostTypeFields.FormPreview"
          />
        </h3>
        <Card className="relative">
          <Card.Content>
            {newField ? (
              <Form>
                <FieldInput
                  name={newField?.key}
                  field={newField}
                  label={
                    <div className="flex space-s-1 items-center">
                      <span>
                        {getTranslatedPostTypeName(intl, newField.name)}
                      </span>
                      <FieldPrivacyBadges field={newField} />
                    </div>
                  }
                  helperText={getFieldSetting(newField, 'helperText')}
                  placeholder={getFieldSetting(newField, 'placeholder')}
                />
              </Form>
            ) : (
              <PostForm
                previewFields={previewFields}
                postType={selectedField ? postType : postType}
                preview
                isPostReady
                highlightedField={selectedField?.key ?? highlightedFieldId}
              />
            )}
          </Card.Content>
        </Card>
      </Container>
      <div
        className={clsx(
          'w-full h-full absolute inset-0 bg-background-backdrop backdrop-blur-xs z-1 opacity-0 transition-opacity duration-200',
          !!selectedField && 'opacity-100',
        )}
      ></div>
    </div>
  )
}
