import {
  CustomField,
  CustomFieldSchema,
  CustomFieldType,
  Post,
  TranslationMarkupFormat,
  TranslationMarkupInput,
} from '@tribeplatform/gql-client/types'

import { logger } from '../../common/lib/logger.js'
import { I18nShape } from '../../i18n/types.js'

export const getTranslatableFields = (
  postFields: CustomField[],
  networkFields: CustomFieldSchema[],
) =>
  postFields.reduce((acc, cur) => {
    try {
      const field = networkFields.find(field => field.key === cur.key)
      const value = JSON.parse(cur.value)
      if (value && field) {
        if (field.type === CustomFieldType.richText) {
          return {
            ...acc,
            [cur.key]: {
              markup: value as string,
              format: TranslationMarkupFormat.HTML,
            },
          }
        }

        if (field.type === CustomFieldType.text) {
          return {
            ...acc,
            [cur.key]: {
              markup: value as string,
              format: TranslationMarkupFormat.TEXT,
            },
          }
        }
      }
    } catch (e) {
      logger.error('Error parsing member field value', e.message)
    }

    return acc
  }, {} as Record<string, TranslationMarkupInput>)

export const getTranslatedPost = (
  post: Post,
  translatedFieldsData: string[],
  translatableFields: Record<string, TranslationMarkupInput>,
) => {
  const translatedFields = post?.fields?.map(field => {
    const translatableKeyIndex = Object.keys(translatableFields).indexOf(
      field?.key,
    )
    if (translatableKeyIndex !== -1) {
      return {
        key: field?.key,
        value: JSON.stringify(translatedFieldsData[translatableKeyIndex]),
      }
    }

    return field
  })

  return { ...post, fields: translatedFields }
}

// Sort following list alphabetically
type KnowPostFields =
  | 'Apply link'
  | 'Assignee'
  | 'Category'
  | 'Company'
  | 'Content'
  | 'Cover image'
  | 'Date'
  | 'Date & time'
  | 'Department'
  | 'Description'
  | 'Employment type'
  | 'Event link'
  | 'Hosts'
  | 'Location'
  | 'Release date'
  | 'Remote'
  | 'Resolved'
  | 'Salary range'
  | 'Status'
  | 'Tags'
  | 'Title'
  | 'Type'
  | 'Version'
  | 'Website'

export const getTranslatedPostTypeName = (
  { $t }: I18nShape,
  fieldName: string,
) => {
  const knownPostField = fieldName as KnowPostFields
  switch (knownPostField) {
    case 'Apply link':
      return $t({
        defaultMessage: 'Apply link',
        id: 'DefaultPostFields.Label.ApplyLink',
      })
    case 'Assignee':
      return $t({
        defaultMessage: 'Assignee',
        id: 'DefaultPostFields.Label.Assignee',
      })
    case 'Category':
      return $t({
        defaultMessage: 'Category',
        id: 'DefaultPostFields.Label.Category',
      })
    case 'Company':
      return $t({
        defaultMessage: 'Company',
        id: 'DefaultPostFields.Label.Company',
      })
    case 'Content':
      return $t({
        defaultMessage: 'Content',
        id: 'Generics.Content',
      })
    case 'Cover image':
      return $t({
        defaultMessage: 'Cover image',
        id: 'Generics.CoverImage',
      })
    case 'Date':
      return $t({
        defaultMessage: 'Date',
        id: 'DefaultPostFields.Label.Date',
      })
    case 'Date & time':
      return $t({
        defaultMessage: 'Date & time',
        id: 'DefaultPostFields.Label.DateAndTime',
      })
    case 'Department':
      return $t({
        defaultMessage: 'Department',
        id: 'DefaultPostFields.Label.Department',
      })
    case 'Description':
      return $t({
        defaultMessage: 'Description',
        id: 'DefaultPostFields.Label.Description',
      })
    case 'Employment type':
      return $t({
        defaultMessage: 'Employment type',
        id: 'DefaultPostFields.Label.EmploymentType',
      })
    case 'Event link':
      return $t({
        defaultMessage: 'Event link',
        id: 'DefaultPostFields.Label.EventLink',
      })
    case 'Hosts':
      return $t({
        defaultMessage: 'Hosts',
        id: 'DefaultPostFields.Label.Hosts',
      })
    case 'Location':
      return $t({
        defaultMessage: 'Location',
        id: 'Generics.Location',
      })
    case 'Release date':
      return $t({
        defaultMessage: 'Release date',
        id: 'DefaultPostFields.Label.ReleaseDate',
      })
    case 'Remote':
      return $t({
        defaultMessage: 'Remote',
        id: 'DefaultPostFields.Label.Remote',
      })
    case 'Resolved':
      return $t({
        defaultMessage: 'Resolved',
        id: 'DefaultPostFields.Label.Resolved',
      })
    case 'Salary range':
      return $t({
        defaultMessage: 'Salary range',
        id: 'DefaultPostFields.Label.SalaryRange',
      })
    case 'Status':
      return $t({
        defaultMessage: 'Status',
        id: 'Generics.Status',
      })
    case 'Tags':
      return $t({
        defaultMessage: '{TAGS_CC}',
        id: 'Generics.Tags',
      })
    case 'Title':
      return $t({
        defaultMessage: 'Title',
        id: 'Generics.Title',
      })
    case 'Type':
      return $t({
        defaultMessage: 'Type',
        id: 'Generics.Type',
      })
    case 'Version':
      return $t({
        defaultMessage: 'Version',
        id: 'DefaultPostFields.Label.Version',
      })
    case 'Website':
      return $t({
        defaultMessage: 'Website',
        id: 'DefaultPostFields.Label.Website',
      })
    default: {
      const uknownPostField: never = knownPostField

      return $t(
        {
          defaultMessage: '<capital>{post_type}</capital>',
          id: 'PostType.Name',
        },
        {
          post_type: uknownPostField,
        },
      )
    }
  }
}
