import { InfiniteData } from '@tanstack/react-query'

import { Image, PaginatedPost, Post } from '@tribeplatform/gql-client/types'
import {
  getCachedPost,
  getCachedRootPost,
  useAuthMember,
} from '@tribeplatform/react-sdk/hooks'
import { useQueryClient } from '@tribeplatform/react-sdk/lib'
import { getPostsKey } from '@tribeplatform/react-sdk/utils/keys'

import { Mention } from '../../Composer/@types/index.js'
import { logger } from '../lib/logger.js'

type MentionMap = { [key: string]: Mention }

const gatherMentionsFromPost = (_post: Post, mentions: MentionMap = {}) => {
  const author = _post?.createdBy?.member || _post?.owner?.member
  if (!author) {
    return
  }

  mentions[author.id] = {
    id: author.id,
    title: author.name,
    icon: (author.profilePicture as Image)?.url,
  }

  _post.mentions?.forEach(m => {
    mentions[m.id] = {
      id: m.id,
      title: m.name,
      icon: (m.profilePicture as Image)?.id,
    }
  })
}

const gatherMentionsFromPaginatedPosts = (
  page: PaginatedPost,
  mentions: MentionMap = {},
) => {
  try {
    if (page?.edges) {
      page.edges.forEach(edge => {
        if (edge?.node) {
          gatherMentionsFromPost(edge.node, mentions)
          if (edge.node.replies) {
            gatherMentionsFromPaginatedPosts(edge.node.replies, mentions)
          }
        }
      })
    } else if (page?.nodes) {
      page.nodes.forEach(node => {
        if (node) {
          gatherMentionsFromPost(node, mentions)
          if (node?.replies) {
            gatherMentionsFromPaginatedPosts(node.replies, mentions)
          }
        }
      })
    }
  } catch (e) {
    logger.error('could not gather mentions from paginated post', e)
  }
}

const gatherMentionsFromInfinitePaginatedPosts = (
  paginatedPosts: InfiniteData<PaginatedPost>,
  mentions: MentionMap = {},
) => {
  try {
    if (!paginatedPosts?.pages?.[0]?.totalCount) {
      return
    }

    paginatedPosts.pages.forEach(page =>
      gatherMentionsFromPaginatedPosts(page, mentions),
    )
  } catch (e) {
    logger.error('could not gather mentions from infinite paginated post', e)
  }
}

export const useCachedMentionsFromPost = (
  postId: string,
  options: {
    enabled?: boolean
  },
) => {
  const { enabled } = options
  const { data: authMember } = useAuthMember()
  const queryClient = useQueryClient()

  if (!postId || !enabled) {
    return
  }
  const mentionsMap: MentionMap = {}

  // get the post and post.replies
  const cachedPost = getCachedPost(postId, queryClient)
  gatherMentionsFromPost(cachedPost, mentionsMap)
  if (cachedPost?.replies) {
    gatherMentionsFromPaginatedPosts(cachedPost.replies, mentionsMap)
  }

  // find root parent
  const cachedRootPost = getCachedRootPost(postId, queryClient)
  if (cachedRootPost && cachedRootPost.id !== postId) {
    gatherMentionsFromPost(cachedRootPost, mentionsMap)
  }

  // find replies
  const cachedReplies = queryClient.getQueriesData<InfiniteData<PaginatedPost>>(
    {
      type: 'active',
      predicate: query => {
        const { queryKey } = query
        if (Array.isArray(queryKey)) {
          const [main, args] = queryKey || []
          return main === getPostsKey()[0] && args.variables.postId === postId
        }
        return false
      },
    },
  )

  cachedReplies.forEach(data => {
    gatherMentionsFromInfinitePaginatedPosts(
      data[1] as InfiniteData<PaginatedPost>,
      mentionsMap,
    )
  })

  // we should not display the user itself
  if (mentionsMap[authMember.id]) {
    delete mentionsMap[authMember.id]
  }

  return Array.from(Object.values(mentionsMap))
}
