import type { PostFields } from '@tribeplatform/gql-client/graphql'
import type {
  QueryFeedArgs,
  PaginatedPost,
} from '@tribeplatform/gql-client/types'

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '../../lib/react-query/useInfiniteQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getFeedKey } from '../../utils/keys/feed.keys.js'

export const DefaultFeedLimit = 10

export const useFeed = (options: {
  variables: QueryFeedArgs
  fields?: PostFields
  useInfiniteQueryOptions?: UseInfiniteQueryOptions<PaginatedPost>
}) => {
  const {
    variables = { limit: DefaultFeedLimit },
    fields = 'default',
    useInfiniteQueryOptions,
  } = options || {}

  const { client } = useTribeClient()
  const feedKey = getFeedKey({ variables, fields })

  return useInfiniteQuery<PaginatedPost>(
    feedKey,
    ({ pageParam: after }) =>
      client?.posts.feed(
        {
          after,
          ...variables,
        },
        fields,
      ),
    useInfiniteQueryOptions,
  )
}
