import { useStaticIntl } from '@tribeplatform/react-components/i18n'
import {
  MultiViewBlock,
  convertToStatic,
} from '@tribeplatform/react-components/Views'
import { useBlock } from '@tribeplatform/react-slate-kit/hooks'
import type { BC } from '@tribeplatform/react-slate-kit/types'

import { GenericQuickLinks } from './components/GenericQuickLinks.js'
import { genericViewFactory, staticFieldsFactory } from './config.js'
import type { QuickLinksBlockProps } from './types.js'

export const QuickLinksBlock: BC<QuickLinksBlockProps> = ({
  title,
  description,
  linkText,
  linkUrl,
  views,
  ...rest
}) => {
  const genericView = useStaticIntl(genericViewFactory)
  const staticFields = useStaticIntl(staticFieldsFactory)
  const {
    extraContext: { activeViewIndex, activeFieldId },
  } = useBlock<
    QuickLinksBlockProps,
    { activeViewIndex?: number; activeFieldId?: string }
  >()

  return (
    <MultiViewBlock
      activeViewIndex={activeViewIndex}
      title={title}
      description={description}
      views={views}
      linkUrl={linkUrl}
      linkText={linkText}
      {...rest}
    >
      {({ view, preview }) => (
        <>
          {view && (
            <GenericQuickLinks
              style={view.style}
              sources={view.sources}
              fields={convertToStatic(view.fields, staticFields, [])}
              gallerySize={view.gallerySize}
              showDivider={view.showDivider}
              activeFieldId={activeFieldId}
              preview={preview}
            />
          )}
          {!view && (
            <GenericQuickLinks
              style={genericView.style}
              sources={genericView.sources}
              fields={convertToStatic(staticFields, staticFields, [])}
              gallerySize={genericView.gallerySize}
              showDivider={genericView.showDivider}
              activeFieldId={activeFieldId}
              preview={preview}
            />
          )}
        </>
      )}
    </MultiViewBlock>
  )
}
