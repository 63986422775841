import type { View, StaticField } from '@tribeplatform/react-components/Views'
import type { IconSize } from '@tribeplatform/react-ui-kit/Icon'
import { GridListColumns } from '@tribeplatform/react-ui-kit/Layout'
import type { TextVariant } from '@tribeplatform/react-ui-kit/Text'

export const getColumns = (
  gallerySize: View['gallerySize'],
): GridListColumns => {
  if (!gallerySize) {
    return 3
  }

  switch (gallerySize) {
    case 'small':
      return 4
    case 'medium':
      return 3
    case 'large':
      return 2
    default:
      // Code should never reach the default case
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = gallerySize
      return exhaustiveCheck
  }
}

export const getCardWidth = (gallerySize: View['gallerySize']) => {
  switch (gallerySize) {
    case 'small':
      return 300
    case 'medium':
      return 400
    case 'large':
      return 500
    default:
      // Code should never reach the default case
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = gallerySize
      return exhaustiveCheck
  }
}

export const getTitleVariant = (
  size: StaticField['settings']['titleSize'],
): TextVariant => {
  switch (size) {
    case 'xs':
      return 'heading3xs'
    case 'sm':
      return 'heading2xs'
    case 'md':
    default:
      return 'headingXs'
    case 'lg':
      return 'headingSm'
  }
}

export const getGlyphSize = (gallerySize: View['gallerySize']): IconSize => {
  switch (gallerySize) {
    case 'small':
      return '2x'
    case 'medium':
      return '4x'
    case 'large':
      return '6x'
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = gallerySize
      return exhaustiveCheck
    }
  }
}
