import { Fragment } from 'react'

import { clsx } from 'clsx'

import type { Post } from '@tribeplatform/gql-client/types'
import { useNavigate } from '@tribeplatform/react-components/Link'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { Card } from '@tribeplatform/react-ui-kit/Card'

import { getLinkToUrl, groupFieldsByStretchY } from '../utils/index.js'
import { Field } from './Field.js'

interface Props {
  post: Post
  fields: StaticField[]
  activeFieldId: string
  width?: number
  className?: string
  linkTo?: string
  singlePostType?: boolean
  preview: boolean
}
export const PostsGalleryItem = ({
  post,
  fields,
  activeFieldId,
  width,
  className,
  linkTo,
  singlePostType,
  preview,
}: Props) => {
  const navigate = useNavigate()
  const {
    data: { postTypes },
  } = useNetwork()

  const bannerIsFirst =
    fields.findIndex(
      field =>
        field.id === 'galleryBanner' &&
        field.settings?.bannerStyle === 'banner',
    ) === 0
  const bannerIsMiddle =
    fields.findIndex(
      field =>
        field.id === 'galleryBanner' &&
        field.settings?.bannerStyle === 'banner',
    ) > 0
  const bannerIsLast =
    fields.findIndex(
      field =>
        field.id === 'galleryBanner' &&
        field.settings?.bannerStyle === 'banner',
    ) ===
    fields.length - 1
  const linkToUrl = getLinkToUrl({ linkTo, post })

  return (
    <Card
      onClick={() => navigate(linkToUrl)}
      className={clsx(
        'cursor-pointer hover:shadow-card-hovered transition-all ease-in-out duration-200',
        'justify-start stretch',
        className,
      )}
      {...(width
        ? { style: { maxWidth: `${width}px`, minWidth: `${width}px` } }
        : {})}
    >
      {bannerIsMiddle && (
        <Card.Content
          className={clsx(
            !bannerIsLast && 'flex-grow-0',
            'flex flex-col gap-4',
          )}
        >
          {groupFieldsByStretchY(
            fields
              .filter((_, index) => {
                const bannerIndex = fields.findIndex(
                  field =>
                    field.id === 'galleryBanner' &&
                    field.settings?.bannerStyle === 'banner',
                )
                return bannerIndex === -1 || index < bannerIndex
              })
              .filter(field => !field.settings?.snapBottom),
          ).map(group => (
            <div
              key={group[0].id}
              className={clsx(
                group[0].settings?.stretchY ? 'flex-1' : 'grow-0 shrink-0',
              )}
            >
              <div className="flex flex-wrap items-center gap-4">
                {group.map(field => (
                  <Field
                    key={field.id}
                    field={field}
                    post={post}
                    activeFieldId={activeFieldId}
                    postTypes={postTypes}
                    viewStyle="gallery"
                    linkTo={linkTo}
                    singlePostType={singlePostType}
                    preview={preview}
                  />
                ))}
              </div>
            </div>
          ))}
        </Card.Content>
      )}
      {fields.map(field => (
        <Fragment key={field.id}>
          {field.id === 'galleryBanner' &&
            field.settings?.bannerStyle === 'banner' && (
              <Card.Media top={bannerIsFirst} bottom={bannerIsLast}>
                <Field
                  key={field.id}
                  field={field}
                  post={post}
                  activeFieldId={activeFieldId}
                  postTypes={postTypes}
                  viewStyle="gallery"
                  linkTo={linkTo}
                  singlePostType={singlePostType}
                  preview={preview}
                />
              </Card.Media>
            )}
        </Fragment>
      ))}
      {!bannerIsLast && (
        <Card.Content className="flex flex-col gap-4">
          {groupFieldsByStretchY(
            fields
              .filter((_, index) => {
                const bannerIndex = fields.findIndex(
                  field =>
                    field.id === 'galleryBanner' &&
                    field.settings?.bannerStyle === 'banner',
                )
                return index > bannerIndex
              })
              .filter(field => !field.settings?.snapBottom),
          ).map(group => (
            <div
              key={group[0].id}
              className={clsx(
                group[0].settings?.stretchY ? 'flex-1' : 'grow-0 shrink-0',
              )}
            >
              <div className="flex flex-wrap items-center gap-4">
                {group.map(field => (
                  <Field
                    key={field.id}
                    field={field}
                    post={post}
                    activeFieldId={activeFieldId}
                    postTypes={postTypes}
                    viewStyle="gallery"
                    linkTo={linkTo}
                    singlePostType={singlePostType}
                    preview={preview}
                  />
                ))}
              </div>
            </div>
          ))}
        </Card.Content>
      )}
      {fields.map(field => (
        <Fragment key={field.id}>
          {field.settings?.snapBottom && (
            <Card.Footer>
              <Field
                key={field.id}
                field={field}
                post={post}
                activeFieldId={activeFieldId}
                postTypes={postTypes}
                viewStyle="gallery"
                linkTo={linkTo}
                singlePostType={singlePostType}
                preview={preview}
              />
            </Card.Footer>
          )}
        </Fragment>
      ))}
    </Card>
  )
}
