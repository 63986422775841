import { lazy, useState, Suspense } from 'react'

import { clsx } from 'clsx'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { Image } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { useAuthMember, useNetwork } from '@tribeplatform/react-sdk/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { Link as UILink } from '@tribeplatform/react-ui-kit/Link'

import { Banner } from '../common/components/Banner.js'
import { MemberBannerPlaceholder } from '../common/components/member/MemberBannerPlaceholder.js'
import { ProfilePicture } from '../common/components/member/ProfilePicture.js'
import { dayjs } from '../common/lib/dayjs.js'
import { HtmlContent } from '../HtmlContent/HtmlContent.js'
import { T } from '../i18n/components/T.js'
import { useStaticIntl } from '../i18n/hooks/useStaticIntl.js'
import { AuthMemberCardProps } from './types.js'
import { getWelcomeMessageText } from './utils.js'

const ProfileSettingsModal = lazy(() =>
  import('../ProfileSettingsModal/ProfileSettingsModal.js').then(m => ({
    default: m.ProfileSettingsModal,
  })),
)

export const AuthMemberCard = ({
  guestContent,
  className,
  ...rest
}: AuthMemberCardProps) => {
  const { Link } = useRouter()
  const [profileModal, setProfileModal] = useState(false)
  const { data: network } = useNetwork()
  const { data: member, isGuest } = useAuthMember({
    fields: 'default',
  })
  const hour = parseInt(dayjs().format('HH'), 10)
  const welcomeMessage = useStaticIntl(getWelcomeMessageText, hour)
  if (!member) {
    return null
  }
  if (isGuest) {
    const [canJoinNetwork] = hasScopesPermission(network, ['joinNetwork'])
    return (
      <Card
        className={clsx('block-welcome-member block-welcome-guest', className)}
        {...rest}
      >
        <Card.Content>
          <div className="text-xl mb-3 text-content font-medium truncate">
            {/* Punctuations and spacings can differ for different languages, hence being a translatable template */}
            <T
              defaultMessage="{greetings} {hi_emoji}"
              id="Member.GuestGreeting"
              values={{
                greetings: welcomeMessage,
                hi_emoji: '👋',
              }}
              description="The {hi_emoji} is replaced with '👋'."
            />
          </div>
          <div className="flex flex-col space-y-3 mb-5">
            {guestContent ? (
              <article className="prose">
                <HtmlContent value={guestContent} />
              </article>
            ) : (
              <>
                <p>
                  <T
                    id="Generics.WelcomeToCommunity"
                    defaultMessage="Welcome to {community_name}"
                    values={{ community_name: network?.name }}
                  />
                </p>
                <p>
                  <T
                    id="Generics.WelcomeToCommunity.Description"
                    defaultMessage="Connect, share, and engage with {COMMUNITY} and build relationships."
                  />
                </p>
                {canJoinNetwork && (
                  <p>
                    <T
                      id="Auth.LoginIfAlreadyMember"
                      defaultMessage="Please log in if you are already a member or sign up for an account."
                    />
                  </p>
                )}
              </>
            )}
          </div>
          <div className="flex flex-col space-y-3">
            {canJoinNetwork ? (
              <>
                <Button
                  as={Link}
                  href="/auth/signup"
                  size="lg"
                  variant="primary"
                  fullWidth
                >
                  <T id="Auth.SignUp" defaultMessage="Join the {COMMUNITY}" />
                </Button>
                <Button
                  as={Link}
                  href="/auth/login"
                  size="lg"
                  variant="secondaryNeutral"
                  fullWidth
                >
                  <T
                    id="Auth.SignUp.LoginInstruct"
                    defaultMessage="Already a member?"
                  />
                </Button>
              </>
            ) : (
              <>
                <p className="text-sm">
                  <T
                    id="Auth.InviteOnlyCommunity"
                    defaultMessage="This is an invitation-only community."
                  />
                </p>
                <Button
                  as={Link}
                  href="/auth/login"
                  size="lg"
                  variant="secondaryNeutral"
                  fullWidth
                >
                  <T
                    id="Auth.SignUp.LoginInstruct"
                    defaultMessage="Already a member?"
                  />
                </Button>
              </>
            )}
          </div>
        </Card.Content>
      </Card>
    )
  }

  return (
    <>
      <Card className={clsx('block-welcome-member', className)} {...rest}>
        <Card.Media top>
          <div className="w-full group relative">
            <Link href={member.relativeUrl} aria-label={member.name}>
              <Banner
                banner={member?.banner as Image}
                className="h-20 lg:h-28"
                fallback={<MemberBannerPlaceholder member={member} />}
              />
            </Link>
          </div>
        </Card.Media>
        <div className="-mt-12 flex justify-center">
          <ProfilePicture
            member={member}
            size="6x"
            editable={!member?.profilePicture}
            className="ring-2 bg-surface ring-offset-surface ring-surface"
          />
        </div>
        <Card.Content className="overflow-hidden text-center">
          {/* Punctuations and spacings can differ for different languages, hence being a translatable template */}
          <T
            defaultMessage="<basic>{greetings},</basic> {member_name}"
            id="Member.KnownMemberGreeting"
            values={{
              greetings: welcomeMessage,
              member_name: (
                <Link
                  href={`/member/${member?.id}`}
                  className="text-xl text-content font-medium truncate inline-block max-w-full"
                >
                  {member?.name}
                </Link>
              ),
              basic: chunks => (
                <div className="text-sm mb-5 text-content-subdued">
                  {chunks}
                </div>
              ),
            }}
          />
          {member?.tagline ? (
            <div className="text-md mb-8 text-content-subdued line-clamp-2">
              {member?.tagline}
            </div>
          ) : (
            <div className="text-md mb-8 text-content-subdued truncate">
              <UILink onClick={() => setProfileModal(true)}>
                <T id="Generics.AddTagline" defaultMessage="Add tagline" />
              </UILink>
            </div>
          )}

          <Button
            size="lg"
            as={Link}
            href={`/member/${member?.id}`}
            variant="secondaryNeutral"
            fullWidth
          >
            <T id="Generics.ViewProfile" defaultMessage="View profile" />
          </Button>
        </Card.Content>
      </Card>
      {profileModal && (
        <Suspense fallback={<></>}>
          <ProfileSettingsModal
            member={member}
            onClose={() => setProfileModal(false)}
            open={profileModal}
          />
        </Suspense>
      )}
    </>
  )
}
