import { forwardRef } from 'react'

import { Checkbox } from '../Checkbox/index.js'
import { FormControlMeta } from './FormControlMeta.js'
import { FormControlCheckboxProps } from './types.js'

export const FormControlCheckbox = forwardRef<
  HTMLInputElement,
  FormControlCheckboxProps
>(({ name, label, helperText, error, invalid, hidden, size, ...rest }, ref) => {
  return (
    <div hidden={hidden}>
      <Checkbox
        {...rest}
        name={name}
        invalid={invalid}
        ref={ref}
        title={label}
        description={<FormControlMeta {...{ helperText, error, invalid }} />}
        size={size === 'compact' ? 'sm' : 'md'}
      >
        {label}
      </Checkbox>
    </div>
  )
})
