import type { Image, File, Media } from '@tribeplatform/gql-client/types'

export enum HeroBannerLayout {
  imageFull = 'imageFull',
  imageRight = 'imageRight',
  imageLeft = 'imageLeft',
  imageBottom = 'imageBottom',
  imageTop = 'imageTop',
}
export enum TextAlignment {
  center = 'center',
  start = 'start',
  end = 'end',
}
export enum MediaType {
  image = 'image',
  video = 'video',
  color = 'color',
  none = 'none',
}
export enum ActionType {
  button = 'button',
  search = 'search',
  none = 'none',
}
export enum TextColor {
  default = 'default',
  light = 'light',
  dark = 'dark',
}
export enum SearchFor {
  all = 'all',
  post = 'post',
  member = 'member',
  space = 'space',
}
export enum Target {
  blank = 'blank',
  self = 'self',
}
export enum ButtonActionType {
  primaryButton = 'primaryButton',
  secondaryButton = 'secondaryButton',
  textLink = 'textLink',
}
export enum SearchSource {
  allSpaces = 'allSpaces',
  currentSpace = 'currentSpace',
  specificSpaces = 'specificSpaces',
}
export interface ButtonAction {
  id: string
  name: string
  url: string
  type: ButtonActionType
  target: Target
}
export interface SearchAction {
  searchFor: SearchFor
  placeholder?: string
  searchSource: SearchSource
  spaceIds?: string[]
}

export type HeroBannerProps = {
  layout: HeroBannerLayout
  contentSpacing: number
  mediaAspectRatio: number
  icon?: Media
  eyebrow: string
  heading: string
  description: string
  textColor: TextColor
  textAlignment: TextAlignment
  mediaType: MediaType
  image: Image
  video: File
  mediaTint: number
  backgroundColor: string
  actionType: ActionType
  buttonActions: ButtonAction[]
  searchAction: SearchAction
}
