import { useBlock } from '../../hooks/block.hook.js'
import { useSlate } from '../../hooks/slate.hook.js'
import { RegisteredBlockProps } from '../../types/block.types.js'
import { SectionRenderer } from './Components/SectionRenderer.js'
import { SectionProps } from './types.js'

export const SectionBlock = ({
  'data-block-id': dataBlockId,
  'data-block-name': dataBlockName,
  layout,
  gap,
  horizontalPadding,
  verticalPadding,
  horizontalMargin,
  verticalMargin,
  hideOnMobile,
  hideOnTablet,
  hideOnDesktop,
  restrictMaxWidth,
  children,
  containerProps,
  HighlightWrapper,
}: RegisteredBlockProps<SectionProps>) => {
  const {
    block: { children: columns },
  } = useBlock<SectionProps>()
  const {
    mode,
    compiledSlate: { blocks },
  } = useSlate()

  // Should not render the section if none of it's columns have any blocks
  // to avoid rendering empty sections, hence, empty spaces.
  const hasBlocks = columns.some(id => blocks[id].children.length !== 0)
  const shouldNotRender = mode === 'live' && !hasBlocks

  if (shouldNotRender) {
    return null
  }

  return (
    <SectionRenderer
      ref={containerProps?.ref}
      dataBlockId={dataBlockId}
      dataBlockName={dataBlockName}
      layout={layout}
      gap={gap}
      horizontalPadding={horizontalPadding}
      verticalPadding={verticalPadding}
      horizontalMargin={horizontalMargin}
      verticalMargin={verticalMargin}
      hideOnMobile={hideOnMobile}
      hideOnTablet={hideOnTablet}
      hideOnDesktop={hideOnDesktop}
      restrictMaxWidth={restrictMaxWidth}
      className={containerProps?.className}
    >
      {children}
      {HighlightWrapper}
    </SectionRenderer>
  )
}
