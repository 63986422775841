import { useMemo } from 'react'

import type {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query'

import { View } from '@tribeplatform/react-components/Views'

import { CarouselShowMore } from '../components/CarouselShowMore.js'
import { ShowMoreButton } from '../components/ShowMoreButton.js'

interface Props {
  style: View['style']
  gallerySize: View['gallerySize']
  showMore: boolean
  fetchNextPage?: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult>
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
}
export const useShowMoreButton = ({
  style,
  gallerySize,
  showMore,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}: Props) => {
  const showMoreButton = useMemo(() => {
    if (!showMore) {
      return null
    }

    return (
      <ShowMoreButton
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    )
  }, [fetchNextPage, hasNextPage, isFetchingNextPage, showMore])

  const carouselShowMoreButton = useMemo(() => {
    if (!showMore) {
      return null
    }

    return (
      <CarouselShowMore
        key="MembersCarouselShowMore"
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        gallerySize={gallerySize}
      />
    )
  }, [fetchNextPage, gallerySize, hasNextPage, isFetchingNextPage, showMore])

  return style === 'carousel' ? carouselShowMoreButton : showMoreButton
}
