import type { NonOptional, TimeFrame, WhereObject } from '../types/index.js'

interface SelectObject {
  field: string
  as: string
}

const generateSelect = (selects: SelectObject[]) => {
  return `select ${selects
    .map(select => `${select.field} as ${select.as}`)
    .join(', ')}`
}

const generateWhere = (where: WhereObject[]) => {
  const whereString = where.map(where => {
    if ('in' in where) {
      const inString = where.in.map(w => `'${w}'`).join(',')
      return `${where.field} ${where?.not ? 'not in' : 'in'} (${inString})`
    }

    return `${where.field} ${where?.operator ?? '='} ${
      where.value === null ? 'null' : `'${where.value}'`
    }`
  }, '')

  return ` where ${whereString.join(' and ')}`
}

interface HavingObject {
  field: string
  operator: '>' | '<' | '='
  value: number
}

const generateHaving = (having: HavingObject[]) => {
  if (!having) {
    return ''
  }

  const havingString = having.map(having => {
    return `${having.field} ${having.operator} ${having.value}`
  }, '')

  return ` having ${havingString.join(' and ')}`
}

interface OrderObject {
  by: string
  dir: 'asc' | 'desc'
}

const generateOrder = (order: OrderObject) => {
  if (!order) {
    return ''
  }

  const direction = order.dir === 'desc' ? ' desc' : ''

  return ` order by ${order.by}${direction}`
}

interface TimeFrameObject {
  from?: number
  to?: number
}

const generateTimeFrameString = (timeFrame: TimeFrameObject) => {
  if (!timeFrame) {
    return ''
  }

  if ('to' in timeFrame && 'from' in timeFrame) {
    return ` timeFrame from ${timeFrame.from} to ${timeFrame.to}`
  }

  if ('to' in timeFrame) {
    return ` timeFrame to ${timeFrame.to}`
  }

  if ('from' in timeFrame) {
    return ` timeFrame from ${timeFrame.from}`
  }
}

const generateLimit = (limit: number) => {
  return ` limit ${limit}`
}

interface groupByField {
  field: string
}
interface groupByEvery {
  every: string
  field?: string
}
interface groupByEach {
  field?: string
  each: string
}
type GroupByObject = groupByField | groupByEvery | groupByEach

const generateGroupByString = (groupBy: GroupByObject) => {
  if (!groupBy) {
    return ''
  }

  const groupField = groupBy?.field ? `, ${groupBy.field}` : ''

  if ('each' in groupBy) {
    return ` group by each '${groupBy.each}' ${groupField}`
  }

  if ('every' in groupBy) {
    return ` group by every ${groupBy.every} ${groupField}`
  }

  if ('field' in groupBy) {
    return ` group by ${groupBy.field}`
  }

  return ''
}

export interface AnalyticsQuery {
  selects: SelectObject[]
  where: WhereObject[]
  limit: number
  having?: HavingObject[]
  order?: OrderObject
  timeFrame?: TimeFrameObject
  groupBy?: GroupByObject
}

export const generateAnalyticsQuery = ({
  selects,
  where,
  limit,
  having,
  order,
  timeFrame,
  groupBy,
}: AnalyticsQuery) => {
  const query = `${generateSelect(selects)}${generateTimeFrameString(
    timeFrame,
  )}${generateWhere(where)}${generateGroupByString(groupBy)}${generateHaving(
    having,
  )}${generateOrder(order)}${generateLimit(limit)}`

  return query
}

interface GenerateTimeFrame {
  timeFrame: NonOptional<Partial<TimeFrame>, 'from'>
  target?: 'current' | 'previous'
}

export const generateTimeFrame = ({
  timeFrame,
  target = 'current',
}: GenerateTimeFrame): TimeFrameObject => {
  if (target === 'current') {
    return {
      from: timeFrame.from,
      ...(timeFrame?.to
        ? {
            to: timeFrame.to,
          }
        : {}),
    }
  }

  if (target === 'previous') {
    const from =
      timeFrame.from - ((timeFrame?.to ?? Date.now()) - timeFrame.from)

    return {
      from,
      to: timeFrame.from,
    }
  }
}
export const generateGroupBy = (
  granularity: TimeFrame['granularity'],
): GroupByObject => {
  switch (granularity) {
    case 'day':
      return { every: 'day' }
    case 'week':
      return { every: 'week' }
    case 'month':
      return { every: 'month' }
    case 'hour':
      return { each: 'hour' }
    case 'dayOfWeek':
      return { each: 'day of week' }
    default:
      // Code should never reach the default case
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = granularity
      return exhaustiveCheck
  }
}
