import { useEffect, type ReactNode } from 'react'

import { useLogin } from '@tribeplatform/react-components/common/hooks'

interface Props {
  children: ReactNode
}

export const MemberSettingsRouteGuard = ({ children }: Props) => {
  const { isLoggedIn, showLogin } = useLogin()

  useEffect(() => {
    if (!isLoggedIn) {
      showLogin()
    }
  }, [isLoggedIn, showLogin])

  if (!isLoggedIn) {
    return null
  }

  return <>{children}</>
}
