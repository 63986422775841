import { FeedProps } from '@tribeplatform/react-components/Feed'
import { FeedBlock as DumbFeedBlock } from '@tribeplatform/react-slate-kit/blocks'
import { BC } from '@tribeplatform/react-slate-kit/types'

import { PendingModerationAlert } from '../../components/Moderation/PendingModerationAlert.js'

export const FeedBlock: BC<FeedProps> = props => (
  <div className="flex flex-col space-y-5">
    <PendingModerationAlert />
    <DumbFeedBlock {...props} />
  </div>
)
