import { clsx } from 'clsx'

import {
  baseButtonStyles,
  buttonIconStyles,
  iconButtonSizeStyles,
} from '../Button/Button.styles.js'
import { IconButton, IconButtonProps } from '../Button/index.js'
import { Icon } from '../Icon/index.js'

export type TopBarButtonProps = IconButtonProps

export const TopBarButton = ({
  variant,
  icon,
  className,
  rounded: _,
  as,
  ...rest
}: TopBarButtonProps) => {
  const size = 'lg'

  if (variant === 'primary') {
    return (
      <IconButton
        as={as}
        icon={icon}
        variant="primary"
        rounded={false}
        size={size}
        className={clsx('transition-none', className)}
        {...rest}
      />
    )
  }

  const Component = as || 'button'

  return (
    <Component
      type={as ? undefined : 'button'}
      className={clsx(
        'inline-block',
        'text-content-on-topbar bg-topbar hover:bg-topbar-subdued disabled:bg-topbar transition-none',
        iconButtonSizeStyles({
          size,
        }),
        baseButtonStyles(),
        className,
      )}
      {...rest}
    >
      <Icon
        className={clsx(
          buttonIconStyles({
            size,
          }),
          'mx-auto',
        )}
      >
        {icon}
      </Icon>
    </Component>
  )
}
