import { Member } from '@tribeplatform/gql-client/types'

import { MemberAvatar } from '../../common/components/index.js'
import { T } from '../../i18n/index.js'

type MemberCoverImageModalTitleProps = {
  member: Member
}
export const MemberCoverImageModalTitle = ({
  member,
}: MemberCoverImageModalTitleProps) => {
  if (!member) {
    return null
  }

  return (
    <div className="overflow-hidden pe-3 hidden sm:flex">
      <div className="flex space-s-2 min-w-0 items-center">
        <div className="shrink-0">
          <MemberAvatar member={member} size="2x" />
        </div>

        <p className="text-md">
          <T
            defaultMessage="{member_name}’s cover image"
            id="ModalTitle.MemberCoverImage"
            values={{
              member_name: (
                <strong className="text-content">{member.name}</strong>
              ),
            }}
          />
        </p>
      </div>
    </div>
  )
}
