import { Fragment, useMemo } from 'react'

import { clsx } from 'clsx'

import { useBlock } from '../../../hooks/block.hook.js'
import { IconSettings, StoredField } from '../Fields/types.js'
import {
  Alignment,
  SpaceHeaderFieldKey,
  SpaceHeaderProps,
  type TextColor,
} from '../types.js'
import { getAlignmentClass, getTextColorClass } from '../utils/utils.js'
import { FieldRenderer } from './Fields/FieldRenderer.js'
import { GroupedFieldRenderer } from './Fields/GroupedFieldRenderer.js'
import { IconRenderer } from './IconRenderer.js'
import {
  getPaddingTopWhenIconEnabled,
  groupNotStretchedItems,
  shouldApplyDefaultAlignment,
} from './utils.js'

interface Props {
  textColor: TextColor
  alignment: Alignment
  className?: string
  moveIconToTop?: boolean
  fields: StoredField[]
  spaceId: string
  /**
   * True if the space header doesn't have media (is a simple preset).
   * This is used to apply the correct background color to the fields
   * that are inside a card (like the icon field).
   */
  isSimple?: boolean
}

export const ContentRenderer = ({
  textColor,
  alignment,
  className = '',
  fields,
  moveIconToTop = false,
  spaceId,
  isSimple = false,
}: Props) => {
  const {
    extraContext: { activeFieldId },
  } = useBlock<SpaceHeaderProps, { activeFieldId?: string }>()
  const alignmentClass = getAlignmentClass(alignment)
  const textColorClass = getTextColorClass(textColor)
  const enabledFields = useMemo(
    () => fields.filter(({ enabled }) => enabled),
    [fields],
  )
  const iconField = useMemo(
    () => enabledFields.find(field => field.key === SpaceHeaderFieldKey.Icon),
    [enabledFields],
  )
  const otherFields = useMemo(
    () => enabledFields.filter(field => field.key !== SpaceHeaderFieldKey.Icon),
    [enabledFields],
  )
  const groupedFields = useMemo(
    () => groupNotStretchedItems(otherFields),
    [otherFields],
  )
  const justifyBetween = alignment !== Alignment.Center

  return (
    <div
      className={clsx(
        'w-full flex justify-between items-center relative inset-0 z-10 gap-3 flex-wrap text-content-on-background',
        className,
        textColorClass,
      )}
    >
      {!!iconField?.enabled && (
        <IconRenderer
          field={iconField}
          settings={iconField.settings as IconSettings}
          highlighted={iconField.id === activeFieldId}
          moveToTop={moveIconToTop}
          alignmentClass={clsx(
            shouldApplyDefaultAlignment(iconField) && alignmentClass,
          )}
          spaceId={spaceId}
          isSimple={isSimple}
        />
      )}
      <div
        className={clsx(
          'flex items-center relative inset-0 z-10 gap-4 flex-wrap flex-1 max-w-full',
          justifyBetween && 'justify-between',
          moveIconToTop &&
            getPaddingTopWhenIconEnabled(iconField?.settings as IconSettings),
          textColorClass,
          alignmentClass,
        )}
      >
        {groupedFields.map(field => (
          <Fragment key={Array.isArray(field) ? field[0].id : field?.id}>
            {Array.isArray(field) ? (
              <GroupedFieldRenderer
                fields={field}
                alignment={alignment}
                activeFieldId={activeFieldId}
                spaceId={spaceId}
                isSimple={isSimple}
              />
            ) : (
              <FieldRenderer
                field={field}
                highlighted={field.id === activeFieldId}
                className={clsx(
                  shouldApplyDefaultAlignment(field) && alignmentClass,
                )}
                spaceId={spaceId}
                isSimple={isSimple}
              />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  )
}
