import { clsx } from 'clsx'

import { IconSettings, StoredField } from '../Fields/types.js'
import { FieldRenderer } from './Fields/FieldRenderer.js'

interface Props {
  field: StoredField
  settings: IconSettings
  highlighted: boolean
  moveToTop: boolean
  alignmentClass?: string
  spaceId: string
  isSimple?: boolean
}

export const IconRenderer = ({
  field,
  highlighted,
  settings,
  alignmentClass,
  moveToTop,
  spaceId,
  isSimple = false,
}: Props) => {
  const stretched = !!settings.stretchX

  return (
    <div
      className={clsx(
        'basis-full',
        stretched && 'flex',
        stretched && moveToTop && 'absolute top-0 -translate-y-1/2',
        !stretched && 'sm:basis-auto',
        stretched && 'left-0 right-0',
      )}
      style={
        // If the icon position is absolute, we need to inherit the padding from the parent
        stretched && moveToTop
          ? { paddingLeft: 'inherit', paddingRight: 'inherit' }
          : undefined
      }
    >
      <FieldRenderer
        key={field?.id}
        field={field}
        highlighted={highlighted}
        className={clsx(stretched && alignmentClass, stretched && 'flex')}
        spaceId={spaceId}
        isSimple={isSimple}
      />
    </div>
  )
}
