import { I18nShape } from '@tribeplatform/react-components/i18n'
import { LinkProps } from '@tribeplatform/react-ui-kit/Link'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { LinkBlock as Component } from './Block.js'

export const RegisteredLinkBlock = (
  _intl: I18nShape,
): RegisteredBlock<LinkProps> => ({
  recommendedName: 'Link',
  Component,
  config: { type: BlockType.BuildingBlock },
})
