import { forwardRef } from 'react'

import { useFormContext } from 'react-hook-form'

import { Form } from '@tribeplatform/react-components/Form'
import { FormControl } from '@tribeplatform/react-ui-kit/FormControl'

import { BC } from '../../types/index.js'
import { MarkdownBlock } from '../Markdown/Block.js'
import { TextareaProps } from './types.js'

export const TextareaBlock: BC<TextareaProps> = forwardRef<
  HTMLTextAreaElement,
  TextareaProps
>((props, ref) => {
  const { value: defaultValue, helperText: markdownHelperText, ...rest } = props

  const formContext = useFormContext()
  const { control: enabled, watch } = formContext || {}

  const value = enabled ? watch(rest.name, defaultValue) : defaultValue
  const helperText =
    typeof markdownHelperText === 'string' ? (
      <MarkdownBlock text={markdownHelperText} />
    ) : (
      markdownHelperText
    )

  const additionalProps = enabled ? {} : { value }
  const Component = enabled ? Form.Textarea : FormControl.Textarea
  return (
    <Component
      {...rest}
      {...additionalProps}
      ref={ref}
      helperText={helperText}
      defaultValue={value}
    />
  )
})
