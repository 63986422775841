import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { T } from '../../i18n/components/T.js'
import { useStaticIntl } from '../../i18n/hooks/useStaticIntl.js'
import type { SortOption } from '../types.js'
import { sortOptionsFactory } from '../utils/sortOptions.js'

export interface Props {
  sortOption: SortOption
  setSortOption: (sortOption: SortOption) => void
}

export const ReplySort = ({ sortOption, setSortOption }: Props) => {
  const sortOptions = useStaticIntl(sortOptionsFactory)

  return (
    <div className="flex justify-end post-list-filter">
      <Dropdown>
        <Dropdown.ButtonMinimal className="flex space-s-1 text-sm">
          <span>
            <T id="Generics.SortBy" defaultMessage="Sort by" />{' '}
            <span className="font-medium">
              {sortOptions
                .find(({ id }) => id === sortOption?.id)
                ?.name?.toLowerCase() ?? ''}
            </span>
          </span>
          <SvgIcon className="w-4 h-4" name="chevron-down" />
        </Dropdown.ButtonMinimal>
        <Dropdown.Items>
          {sortOptions.map(sortOption => (
            <Dropdown.Item
              onClick={() => setSortOption(sortOption)}
              key={sortOption.id}
            >
              {sortOption.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Items>
      </Dropdown>
    </div>
  )
}
