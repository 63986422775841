import { clsx } from 'clsx'

import { useRouter } from '@tribeplatform/react-sdk'
import { useAuthMember, useNetwork } from '@tribeplatform/react-sdk/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Card } from '@tribeplatform/react-ui-kit/Card'

import { EntityImage } from '../common/components/EntityImage.js'
import { MemberAvatar } from '../common/components/index.js'
import { useLogin } from '../common/hooks/useLogin.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { PostComposerProps } from './@types/index.js'

export const PostComposer = ({
  buttons,
  infoText,
  spaceId: defaultSpaceId,
  space: defaultSpace,
  ...rest
}: PostComposerProps) => {
  const { $t } = useI18n()
  const { push } = useRouter()

  const { data: network } = useNetwork()
  const { data: member } = useAuthMember()
  const { isLoggedIn, showLogin } = useLogin()

  defaultSpaceId = defaultSpaceId || defaultSpace?.id

  const openNewPost = ({ spaceId, postTypeId }) => {
    if (!isLoggedIn) {
      return showLogin()
    }

    const queryParams = []
    if (postTypeId) {
      queryParams.push(`post_type=${postTypeId}`)
    }
    if (spaceId || defaultSpaceId) {
      queryParams.push(`space_id=${spaceId || defaultSpaceId}`)
    }

    push(`/new${queryParams.length ? `?${queryParams.join('&')}` : ''}`)
  }

  const hasPermission = ({ spaceId, postTypeId }) => {
    if (!spaceId && !postTypeId) {
      return true
    }

    const authMemberProps = network?.postTypes?.find(
      postType => postType?.id === postTypeId,
    )?.authMemberProps

    if (!spaceId && authMemberProps?.availableSpaces?.length) {
      return true
    }

    return !!authMemberProps?.availableSpaces?.find(
      space => space?.id === spaceId,
    )
  }

  const buttonsCount = buttons?.length
  buttons = buttons?.filter(button =>
    hasPermission({ spaceId: button?.spaceId, postTypeId: button?.postTypeId }),
  )
  if (buttonsCount && !buttons?.length) {
    return null
  }

  if (
    !buttonsCount &&
    !buttons?.length &&
    !network?.postTypes?.find(postType =>
      hasPermission({ spaceId: defaultSpaceId, postTypeId: postType.id }),
    )
  ) {
    return null
  }

  let primaryButton = buttons?.find(button => button?.primary)
  const secondaryButtons = buttons?.filter(button => !button?.primary)

  if (!buttons?.length && !primaryButton) {
    primaryButton = {
      text: $t({
        id: 'PostComposer.WhatsOnYourMind',
        defaultMessage: 'What’s on your mind?',
      }),
    }
  }

  const hasPrimaryInput = !!primaryButton?.text

  return (
    <Card {...rest}>
      {hasPrimaryInput ? (
        <Card.Content className="flex space-s-4 items-center">
          <MemberAvatar size="3x" member={member} clickable />
          <Button
            type="button"
            variant="tertiaryNeutral"
            size="lg"
            fullWidth
            textAlign="start"
            onClick={() =>
              openNewPost({
                postTypeId: primaryButton.postTypeId,
                spaceId: primaryButton.spaceId,
              })
            }
            className="bg-surface-subdued hover:bg-surface-subdued-hovered text-content-subdued !font-normal rounded-input px-3"
          >
            {primaryButton?.text || "What's on your mind?"}
          </Button>
        </Card.Content>
      ) : null}
      {secondaryButtons?.length ? (
        <div
          className={clsx(
            'flex text-center space-s-2',
            hasPrimaryInput ? 'border-t border-line-subdued p-2' : 'p-2',
          )}
        >
          {secondaryButtons.map(({ id, text, icon, postTypeId, spaceId }) => {
            return (
              <Button
                type="button"
                key={id || text}
                fullWidth
                variant="tertiaryNeutral"
                size="lg"
                onClick={() =>
                  openNewPost({
                    postTypeId,
                    spaceId,
                  })
                }
                leadingIcon={
                  <div className="m-0.5">
                    <EntityImage
                      entity={{ image: icon, name: text }}
                      size="lg"
                    />
                  </div>
                }
                className="!rounded-base"
              >
                {text}
              </Button>
            )
          })}
        </div>
      ) : null}
      {infoText ? (
        <div className="rounded-lg bg-surface-pressed text-center px-5 p-2 mt-2">
          {infoText}
        </div>
      ) : null}
    </Card>
  )
}

PostComposer.displayName = 'Composer'
