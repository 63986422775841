import { clsx } from 'clsx'

import { Post, ShortContentFormat } from '@tribeplatform/gql-client/types'
import { HtmlContent } from '@tribeplatform/react-components/HtmlContent'
import type { StaticField } from '@tribeplatform/react-components/Views'

interface Props {
  field: StaticField
  post: Post
  highlighted: boolean
  stretchX: string
  lineClamp?: 'none' | 'sm' | 'md' | 'lg'
  contentFormat?: ShortContentFormat
  preview: boolean
}

export const GalleryPostContent = ({
  field,
  post,
  highlighted,
  stretchX,
  lineClamp = 'none',
  contentFormat,
  preview,
}: Props) => {
  const { default: defaultContent } = field
  const postContent =
    contentFormat === ShortContentFormat.Text
      ? post?.textContent
      : post?.shortContent
  const content = postContent || (preview ? defaultContent : '')

  return (
    <div
      className={clsx(
        'empty:hidden min-w-0',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
    >
      <article
        className={clsx(
          'prose break-words',
          lineClamp === 'sm' && 'line-clamp-2',
          lineClamp === 'md' && 'line-clamp-3',
          lineClamp === 'lg' && 'line-clamp-5',
        )}
      >
        {contentFormat === ShortContentFormat.Text ? (
          <>{content}</>
        ) : (
          <HtmlContent
            value={content}
            attachments={post?.attachments}
            embeds={post?.embeds}
            mentions={post?.mentions}
          />
        )}
      </article>
    </div>
  )
}
