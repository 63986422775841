import { clsx } from 'clsx'

import type { IconSize } from '@tribeplatform/react-ui-kit/Icon'
import { iconStyles, iconSizeStyles } from '@tribeplatform/react-ui-kit/Icon'

import { useFirstRenderContext } from '../ssr/FirstRenderProvider.js'
import '../../types/index.js'

type IconEmojiProps = {
  text: string
  size?: IconSize
  onClick?: (event) => void
  className?: string
}

export const IconEmoji = ({
  text,
  size,
  className,
  ...rest
}: IconEmojiProps) => {
  const shortCode = !text.startsWith(':') ? `:${text}:` : text

  /**
   * @tag Vite migration
   * em-emoji produces hydration error
   * As a workaround, it is initialized client-side only
   */
  const { isFirstRender } = useFirstRenderContext()

  if (isFirstRender) {
    return (
      <div
        className={clsx(
          'bg-skeleton rounded-base',
          iconStyles({ size }),
          className,
        )}
      />
    )
  }

  // TODO emoji: use native emoji calculated from unicode codepoint instead of em-emoji, see MediaGlyphEmoji component
  return (
    <div
      className={clsx(
        iconStyles({ size }),
        'flex items-center justify-center',
        className,
      )}
    >
      <em-emoji
        shortcodes={shortCode}
        size={iconSizeStyles({ size })}
        {...rest}
      />
    </div>
  )
}
