import { createRef, CSSProperties, useCallback, useEffect } from 'react'

import {
  autoUpdate,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { Menu } from '@headlessui/react'
import { clsx } from 'clsx'

import { AnimatePortal } from '../animation/AnimatePortal.js'
import { ScrollLock } from '../ScrollLock/ScrollLock.js'
import { HTMLTribeProps } from '../types/index.js'
import { useFloatingContext } from '../utils/floating/index.js'
import { useFloatingMiddleware } from '../utils/floating/useFloatingMiddleware.js'
import { runIfFn } from '../utils/index.js'
import { dropdownItemsStyles } from './Dropdown.styles.js'
import { useDropdown } from './DropdownContext.js'
import { DropdownItemsStyleProps } from './types.js'

export type DropdownItemsProps = HTMLTribeProps<'div'> &
  DropdownItemsStyleProps & {
    noWrapper?: boolean
    noAnimation?: boolean
  }
export const Items = ({
  children,
  className,
  noWrapper,
  maxWidth = 'md',
  maxHeight = 'full',
  noAnimation,
  ...rest
}: DropdownItemsProps) => {
  const { open } = useDropdown()

  const { placement, triggerRef } = useFloatingContext()

  const { middleware, availableHeight } = useFloatingMiddleware()

  const { context } = useFloating<HTMLElement>({
    placement,
    open,
    middleware,
    whileElementsMounted: autoUpdate,
  })
  const { refs, floatingStyles } = context

  const { getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context),
  ])

  useEffect(() => {
    refs.setReference(triggerRef.current)
  }, [refs, triggerRef])

  const style: CSSProperties = {
    ...floatingStyles,
    // `flip` will take over if there is not enough space
    maxHeight:
      maxHeight === 'full' ? `${Math.max(240, availableHeight)}px` : undefined,
  }

  const contentNode = createRef<HTMLDivElement>()

  const close = useCallback(() => {
    triggerRef.current?.click()
  }, [triggerRef])

  const focusContent = () => {
    if (contentNode == null) {
      return
    }

    requestAnimationFrame(() => {
      if (contentNode.current == null) {
        return
      }
      contentNode.current.focus()
    })
  }

  const FocusTrigger = () => {
    useEffect(() => {
      focusContent()
    }, [])
    return null
  }

  const items = (
    <Menu.Items
      ref={refs.setFloating}
      {...getFloatingProps({
        style,
      })}
      static
      className={clsx(
        dropdownItemsStyles({
          maxWidth,
          maxHeight,
        }),
        !open && noWrapper && 'invisible',
        className,
      )}
      {...rest}
    >
      {runIfFn(children, { close })}
    </Menu.Items>
  )

  if (noWrapper) {
    return items
  }

  return (
    <AnimatePortal idPrefix="dropdown" open={open} noAnimation={noAnimation}>
      {open && <FocusTrigger />}
      {open && <ScrollLock />}
      {items}
    </AnimatePortal>
  )
}
