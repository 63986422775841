import type { Space } from '@tribeplatform/gql-client/types'
import type { StaticField } from '@tribeplatform/react-components/Views'

import { SpacesCardItem } from './SpacesCardItem.js'

interface Props {
  spaces: Space[]
  fields: StaticField[]
  activeFieldId?: string
  showMoreButton: JSX.Element
  preview: boolean
}
export const SpacesCard = ({
  spaces,
  fields,
  activeFieldId,
  showMoreButton,
  preview,
}: Props) => {
  return (
    <div className="space-y-5">
      {spaces.map(space => (
        <SpacesCardItem
          key={space.id}
          space={space}
          fields={fields.filter(
            field =>
              field.enabled &&
              !(field.styles && field.styles.indexOf('card') === -1),
          )}
          activeFieldId={activeFieldId}
          preview={preview}
        />
      ))}
      {showMoreButton}
    </div>
  )
}
