import type { I18nShape } from '../../i18n/types.js'

export const categoriesFactory = (intl: I18nShape) => {
  const { $t } = intl

  return [
    {
      value: 'project-management',
      text: $t({
        id: 'TemplateCategory.ProjectManagement',
        defaultMessage: 'Project management',
      }),
    },
    {
      value: 'note-taking',
      text: $t({
        id: 'TemplateCategory.NoteTaking',
        defaultMessage: 'Note taking',
      }),
    },
    {
      value: 'content-creation',
      text: $t({
        id: 'TemplateCategory.ContentCreation',
        defaultMessage: 'Content creation',
      }),
    },
    {
      value: 'data-management',
      text: $t({
        id: 'TemplateCategory.DataManagement',
        defaultMessage: 'Data management',
      }),
    },
    {
      value: 'personal-growth',
      text: $t({
        id: 'TemplateCategory.PersonalGrowth',
        defaultMessage: 'Personal growth',
      }),
    },
    {
      value: 'education',
      text: $t({
        id: 'TemplateCategory.Education',
        defaultMessage: 'Education',
      }),
    },
    {
      value: 'design-and-creativity',
      text: $t({
        id: 'TemplateCategory.DesignAndCreativity',
        defaultMessage: 'Design and creativity',
      }),
    },
    {
      value: 'health-and-fitness',
      text: $t({
        id: 'TemplateCategory.HealthAndFitness',
        defaultMessage: 'Health and fitness',
      }),
    },
    {
      value: 'finance',
      text: $t({
        id: 'TemplateCategory.Finance',
        defaultMessage: 'Finance',
      }),
    },
    {
      value: 'event-planning',
      text: $t({
        id: 'TemplateCategory.EventPlanning',
        defaultMessage: 'Event planning',
      }),
    },
  ]
}
