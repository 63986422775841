import { Fragment } from 'react'

import type { Post } from '@tribeplatform/gql-client/types'
import { useNavigate } from '@tribeplatform/react-components/Link'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

import { getLinkToUrl } from '../utils/index.js'
import { Field } from './Field.js'

interface Props {
  post: Post
  fields: StaticField[]
  activeFieldId: string
  linkTo?: string
  singlePostType?: boolean
  preview: boolean
}
export const PostsListItem = ({
  post,
  fields,
  activeFieldId,
  linkTo,
  singlePostType,
  preview,
}: Props) => {
  const {
    data: { postTypes },
  } = useNetwork()
  const navigate = useNavigate()
  const linkToUrl = getLinkToUrl({ linkTo, post })

  return (
    <div
      className="cursor-pointer flex flex-wrap items-center gap-y-2 gap-x-5"
      onClick={() => navigate(linkToUrl)}
    >
      {fields.map(field => (
        <Fragment key={field.id}>
          <Field
            field={field}
            post={post}
            activeFieldId={activeFieldId}
            postTypes={postTypes}
            viewStyle="list"
            linkTo={linkTo}
            singlePostType={singlePostType}
            preview={preview}
          />
          {field.settings?.stretchX && (
            <div className="basis-full web:sm:basis-0"></div>
          )}
        </Fragment>
      ))}
    </div>
  )
}
