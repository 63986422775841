import type { ValueRendererComponent } from '@tribeplatform/react-components/Filters'
import { useI18n } from '@tribeplatform/react-components/i18n'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

import { getRoleNames } from '../utils/roles.js'

export const CustomRoleIdRenderer: ValueRendererComponent = ({ filter }) => {
  const intl = useI18n()
  const { data: network } = useNetwork()
  const { value } = filter

  if (!Array.isArray(value)) {
    return null
  }

  const roles = network?.roles
  const rolesNamesMapper = getRoleNames(roles, intl)
  const roleNames = value.map(roleId => rolesNamesMapper?.[roleId] ?? '')

  return <>{roleNames.join(', ')}</>
}
