import type { SpacePostTypeFields } from '@tribeplatform/gql-client/graphql'
import type {
  QuerySpacePostTypeArgs,
  QuerySpacePostTypesArgs,
} from '@tribeplatform/gql-client/types'

import { KeyConstructor } from './keyConstructor.js'

const SPACE_POST_TYPE_KEY = 'spacePostType'
const SPACE_POST_TYPES_KEY = 'spacePostTypes'

export const getSpacePostTypeKey = KeyConstructor<
  QuerySpacePostTypeArgs,
  SpacePostTypeFields
>(SPACE_POST_TYPE_KEY)
export const getSpacePostTypesKey = KeyConstructor<
  QuerySpacePostTypesArgs,
  SpacePostTypeFields
>(SPACE_POST_TYPES_KEY)
