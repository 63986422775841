import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { CardBlock as Component } from './Block.js'
import { CardProps } from './types.js'

export const RegisteredCardBlock = (
  _intl: I18nShape,
): RegisteredBlock<CardProps> => ({
  recommendedName: 'Card',
  Component,
  config: {
    type: BlockType.BuildingBlock,
  },
})
