import { useCallback, useEffect, useMemo } from 'react'

import { clsx } from 'clsx'

import { Image } from '@tribeplatform/gql-client/types'

import { useMediaModal } from '../../../MediaModal/MediaModalProvider.js'
import { ImageGalleryItem, ImageGalleryItemProps } from './ImageGalleryItem.js'

const MAX_VISIBLE_IMAGES = 5

export const ImageGalleryView = ({
  images,
  className,
}: {
  images: Image[]
  className?: string
}) => {
  const { openSlideWithIndex, pushSlide } = useMediaModal()

  const imageToItemProps = useCallback(
    (
      image: Image,
      slideIndex: number,
      span: ImageGalleryItemProps['span'],
      overlayText?: string,
    ): ImageGalleryItemProps => ({
      image,
      thumbnailUrl: image.urls?.medium,
      onClick: () => {
        openSlideWithIndex(slideIndex)
      },
      span,
      overlayText,
    }),
    [openSlideWithIndex],
  )

  const imagesPerRow = useMemo(() => {
    if (images.length === 2) {
      return [2]
    }
    if (images.length === 3) {
      return [1, 2]
    }
    if (images.length === 4) {
      return [1, 3]
    }
    if (images.length >= MAX_VISIBLE_IMAGES) {
      return [2, 3]
    }
    return [1]
  }, [images.length])

  const firstRowImageSet = useMemo(
    () => images.slice(0, imagesPerRow[0]),
    [images, imagesPerRow],
  )

  const firstRowImageProps: ImageGalleryItemProps[] = useMemo(() => {
    const span = firstRowImageSet.length === 2 ? 'half' : 'full'
    return firstRowImageSet.map(image => {
      const slideIndex = pushSlide({
        ...image,
        url: image.urls?.large ?? image.url,
      })
      return imageToItemProps(image, slideIndex, span)
    })
  }, [firstRowImageSet, imageToItemProps, pushSlide])

  const secondRowImageSet = useMemo(() => {
    return images.slice(imagesPerRow[0], imagesPerRow[0] + imagesPerRow[1])
  }, [images, imagesPerRow])

  const secondRowImageProps: ImageGalleryItemProps[] = useMemo(() => {
    const span = secondRowImageSet.length === 3 ? 'third' : 'half'
    const remainingCount = images.length - MAX_VISIBLE_IMAGES

    return secondRowImageSet.map((image, index, list) => {
      const slideIndex = pushSlide({
        ...image,
        url: image.urls?.large ?? image.url,
      })
      if (remainingCount > 0 && index === list.length - 1) {
        return imageToItemProps(image, slideIndex, span, `+${remainingCount}`)
      }

      return imageToItemProps(image, slideIndex, span)
    })
  }, [imageToItemProps, images.length, pushSlide, secondRowImageSet])

  useEffect(() => {
    const remainingItems = images.slice(MAX_VISIBLE_IMAGES)
    remainingItems.forEach(image => {
      pushSlide({
        ...image,
        url: image.urls?.large ?? image.url,
      })
    })
  }, [images, pushSlide])

  return (
    <div className={clsx('grid grid-cols-6 gap-1', className)}>
      {firstRowImageProps.map(itemProp => (
        <ImageGalleryItem key={itemProp.image.id} {...itemProp} />
      ))}
      {secondRowImageProps.map(itemProp => (
        <ImageGalleryItem key={itemProp.image.id} {...itemProp} />
      ))}
    </div>
  )
}
