import { type ButtonAction as TButtonAction } from '../types.js'
import { ButtonAction } from './ButtonAction.js'

interface Props {
  action: TButtonAction[]
}
export const ButtonActionsRenderer = ({ action: buttonActions }: Props) => {
  return (
    <div className="flex flex-wrap items-center gap-3">
      {buttonActions.map(({ id, url, type, target, name }) => (
        <ButtonAction
          key={id}
          id={id}
          name={name}
          url={url}
          type={type}
          target={target}
        />
      ))}
    </div>
  )
}
