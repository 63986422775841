import { ContainerSize } from '@tribeplatform/react-ui-kit/Layout'
import type { ModalProps } from '@tribeplatform/react-ui-kit/Modal'

const sizesMap: Record<ContainerSize, ModalProps['size']> = {
  xs: 'md',
  sm: 'lg',
  md: 'xl',
  lg: '2xl',
  xl: '3xl',
  full: 'full',
}
export const interactionMapper = (size: ContainerSize) => sizesMap[size]
export const parseProps = props => {
  try {
    return props ? JSON.parse(props) : undefined
  } catch (error) {
    logger.error(error)
  }
  return undefined
}
