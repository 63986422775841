import type { QueryFilters } from '@tanstack/react-query'

import { getFeedKey } from '../../utils/keys/feed.keys.js'
import { getPostsKey } from '../../utils/keys/post.key.js'
import { getPinnedPostsKey } from '../../utils/keys/space.key.js'

// get all active/inactive posts queries
export const postsQueryFilter: QueryFilters = {
  predicate: query => {
    const { state, queryKey } = query
    const [main] = queryKey || []
    if (
      (main === getFeedKey()[0] || main === getPostsKey()[0]) &&
      Array.isArray((state?.data as any)?.pages) // checking if the disabled(inactive) query actually has data to update or not
    ) {
      return true
    }
    return false
  },
}
export const pinnedPostsFilter: QueryFilters = {
  predicate: query => {
    const queryMainKey = query?.queryKey?.[0]
    if (queryMainKey === getPinnedPostsKey()[0]) {
      return true
    }
    return false
  },
}

// to get one replies/posts query with a specific id
export const getPostsQueryById: (postId: string) => QueryFilters = postId => ({
  predicate: query => {
    const { state, queryKey } = query
    const [main, args] = queryKey || []
    if (
      main === getPostsKey()[0] &&
      (args as any)?.variables?.postId === postId &&
      Array.isArray((state?.data as any)?.pages) // checking if the disabled(inactive) query actually has data to update or not
    ) {
      return true
    }
    return false
  },
})
