import { BlockIconProps } from '@tribeplatform/react-ui-kit/Icon'

export type IconType = 'solid' | 'outline'

export type IconBlockProps = {
  name: SupportedIconNames
  size?: BlockIconProps['size']
  iconType?: IconType
  color?: BlockIconProps['color']
}

export enum SupportedIconNames {
  RefreshIcon = 'refresh-cw-hr',
  CheckCircleIcon = 'check-circle',
  ExclamationCircleIcon = 'alert-triangle',
  ArrowsRightLeftIcon = 'switch-horizontal',
  PencilAltIcon = 'pencil-alt',
  DeleteBinLineIcon = 'delete-bin-line',
}
