import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { TextareaBlock as Component } from './Block.js'
import { TextareaProps } from './types.js'

export const RegisteredTextareaBlock = (
  _intl: I18nShape,
): RegisteredBlock<TextareaProps> => ({
  recommendedName: 'Textarea',
  Component,
  config: {
    type: BlockType.BuildingBlock,
    defaultProps: {},
  },
})
