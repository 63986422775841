import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { AuthMemberCardBlock as Component } from './Block.js'

export const RegisteredAuthMemberCardBlock = (
  intl: I18nShape,
): RegisteredBlock<Record<string, never>> => {
  const { $t } = intl

  return {
    recommendedName: 'AuthMemberCard',
    Component,
    config: {
      type: BlockType.Block,
      usable: true,

      displayName: $t({
        id: 'Blocks.AuthMemberCard.DisplayName',
        defaultMessage: 'Auth member card',
      }),
      Icon: <SvgIcon name="member" />,
    },
  }
}
