import { FormControlLayoutProps } from './FormControlLayout.js'
import { FormControlProps } from './types.js'

export const extractLayoutProps = <T extends FormControlProps>({
  name,
  label,
  secondaryLabel,
  tertiaryLabel,
  helperText,
  error,
  invalid,
  hidden,
  size,
  ...rest
}: T) => {
  const layoutProps: Omit<FormControlLayoutProps, 'children'> = {
    name,
    label,
    secondaryLabel,
    tertiaryLabel,
    helperText,
    error,
    invalid,
    hidden,
    size,
  }

  const inputProps = {
    id: name,
    name,
    invalid,
    size,
    ...rest,
  }

  return { layoutProps, inputProps }
}
