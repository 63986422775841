const getSubdomain = (domainSubfolder: string, networkDomain: string) => {
  try {
    if (domainSubfolder) {
      const url = new URL(`https://${domainSubfolder}`)
      return {
        subfolder: url?.pathname === '/' ? '' : url?.pathname,
        domain: url?.host || networkDomain,
      }
    }
  } catch (err) {
    // TODO: Throw error instead of catching it. Let the consumer handle it.
  }
  return { subfolder: '', subfolderDomain: null, domain: networkDomain }
}

export const getOriginURLs = (
  networkDomain: string,
  hostDomain: string,
  subfolder: string,
) => {
  if (!subfolder) {
    return [`${networkDomain}`]
  }

  if (networkDomain === hostDomain) {
    return [`${networkDomain}${subfolder}`]
  }

  if (subfolder) {
    return [`${networkDomain}${subfolder}`, `${hostDomain}${subfolder}`]
  }

  return []
}

export type DomainPathInfo = {
  domain: string
  /**
   * The subfolder which the community is installed on.
   * If community is accessible at the root of domain, it's an empty string.
   * Otherwise, it has a leading slash without trailing slash. (e.g. `/hub`)
   */
  subfolder: string

  /**
   * A normalized version of the subfolder, with trailing slash,
   * that can be provided to the router for consistent routing
   */
  basename: string

  /**
   * When dealing with subfolder communities, API won't recognize the domain
   * if the domain that is associated with the subfolder is not the same as the
   * one stored in the domain property. We need a way to make the original domain
   * available for related operations.
   */
  originalDomain: string

  /**
   * The public address of the site considering factors such as domain alias (a.k.a subfolder setups).
   * Includes the protocol and the trailing slash.
   * @example 'https://bettermode.com/hub/'
   */
  publicPath: string

  /**
   * Generates the public URL based on the site’s publicPath.
   */
  getResourceUrl: (path?: string) => string
}

export const getDomainInfo = ({
  domain: networkDomain,
  domainSubfolder: networkDomainSubfolder,
}: {
  domain: string
  domainSubfolder: string
}): DomainPathInfo => {
  const effectiveAddress = getEffectiveAddress(
    networkDomainSubfolder,
    networkDomain,
  )
  const publicPath = rootifyPath(`https://${effectiveAddress}`)
  const { subfolder, domain } = getSubdomain(effectiveAddress, networkDomain)
  const basename = rootifyPath(subfolder)

  const getResourceUrl = (path: string = '') => {
    if (!path) {
      return publicPath
    }
    if (path.startsWith('http')) {
      return path
    }

    if (path.startsWith('//')) {
      return path
    }
    return `${publicPath}${path.replace(/^\//, '')}`
  }

  return {
    domain,
    subfolder,
    basename,
    originalDomain: domain,
    publicPath,
    getResourceUrl,
  }
}

const rootifyPath = (path: string) => {
  if (!path) {
    return '/'
  }

  return path.endsWith('/') ? path : `${path}/`
}

const getEffectiveAddress = (domainSubfolder: string, domain: string) => {
  /**
   * RuntimeConfigs is injected by the web package. The type is borrowed from and should conform
   * to the type in packages/web/configs/runtime.ts.
   */
  type TWindow = {
    RuntimeConfigs: {
      LOCAL_DEV_ADDRESS: string
    }
  }

  const runtimeVars =
    typeof window !== 'undefined'
      ? (window as unknown as TWindow).RuntimeConfigs
      : process.env

  /**
   * Handle the case where module is being used in a local development environment.
   */
  if (runtimeVars.LOCAL_DEV_ADDRESS) {
    return runtimeVars.LOCAL_DEV_ADDRESS
  }

  return domainSubfolder || domain
}
