import { domToReact, Element } from 'html-react-parser'

import { File } from '@tribeplatform/gql-client/types'
import { useMedia } from '@tribeplatform/react-sdk/hooks'

import { FileListItem } from '../common/components/FileListItem.js'

export const AttachmentRenderer = ({
  domNode,
  attachments,
  onClick,
  mediaIndex,
}: {
  domNode: Element
  attachments?: Array<File>
  onClick?: (e) => void
  mediaIndex: number | undefined
}) => {
  // Fetch the corresponding attachment id
  const attachmentId = domNode.attribs['data-id']

  const initialData = attachments?.find(
    attachment => attachment.id === attachmentId,
  )
  const { data: media, isInitialLoading } = useMedia({
    variables: {
      id: attachmentId,
    },
    fields: {
      onFile: 'basic',
    },
    useQueryOptions: {
      initialData,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!attachmentId,
    },
  })

  if (isInitialLoading) {
    return (
      <figure>
        <FileListItem name="" />
      </figure>
    )
  }
  if (!media) {
    return null
  }

  const file: File = media as File

  const name = file?.name || domToReact(domNode?.children)?.toString?.()

  return (
    <figure>
      <FileListItem
        name={name}
        extension={file?.extension}
        size={file?.size}
        downloadUrl={file?.downloadUrl || file?.url}
        data-slide-index={mediaIndex}
        onPreview={onClick}
      />
    </figure>
  )
}
