import { useCallback, useState } from 'react'

import { clsx } from 'clsx'

import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Link as UILink } from '@tribeplatform/react-ui-kit/Link'

import { useBlock } from '../../../hooks/block.hook.js'
import { useSlate } from '../../../hooks/slate.hook.js'
import type { MenuItem as TMenuItem, NavbarProps } from '../types.js'
import { SubMenuWrapper } from './SubMenuWrapper.js'

interface Props {
  id: string
  name: string
  menuItems: TMenuItem[]
}
export const MenuItemWithGird = ({ id, name, menuItems }: Props) => {
  const {
    extraContext: { activeMenuItemId, activeSubMenuItemId },
  } = useBlock<
    NavbarProps,
    { activeMenuItemId?: string; activeSubMenuItemId?: string }
  >()
  const [open, setOpen] = useState(false)
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'
  const isMenuOpenInCustomizer = isPreviewMode && activeMenuItemId === id

  const handleClose = useCallback(() => {
    if (isMenuOpenInCustomizer) {
      return
    }

    setOpen(false)
  }, [isMenuOpenInCustomizer])

  return (
    <>
      <UILink
        as="button"
        variant="neutral"
        className="flex items-center gap-x-1 shrink-0 h-full text-md transition-none font-medium"
        onClick={() => {
          setTimeout(() => setOpen(!open), 0)
        }}
      >
        {name}
        <SvgIcon
          className={clsx(
            'w-4 h-4 transform transition-transform ease-in-out duration-150',
            open || isMenuOpenInCustomizer ? '-rotate-180 rtl:rotate-180' : '',
          )}
          name="chevron-down"
        />
      </UILink>
      <SubMenuWrapper
        isOpen={open || isMenuOpenInCustomizer}
        close={handleClose}
        items={menuItems}
        isPreviewMode={isPreviewMode}
        activeItemId={activeSubMenuItemId}
      />
    </>
  )
}
