import { createContext, PropsWithChildren, useContext, useMemo } from 'react'

export type TooltipContext = {
  disabled?: boolean
  inverted?: boolean
  arrow?: boolean
}

export const TooltipContext = createContext<TooltipContext>(undefined)

export const useTooltipContext = () => {
  const context = useContext(TooltipContext)
  if (context === undefined) {
    throw new Error('useTooltipContext must be used within a TooltipProvider')
  }
  return context
}

export const TooltipContextProvider = ({
  children,
  disabled,
  inverted,
  arrow,
}: PropsWithChildren<TooltipContext>) => {
  const context = useMemo(
    () => ({
      disabled,
      inverted,
      arrow,
    }),
    [arrow, disabled, inverted],
  )

  return (
    <TooltipContext.Provider value={context}>
      {children}
    </TooltipContext.Provider>
  )
}
