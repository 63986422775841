import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'

import { useI18n } from '../../i18n/index.js'
import { GenericOperatorPicker } from '../components/GenericOperatorPicker.js'
import { ListFilterByOperator, type OperatorPickerComponent } from '../types.js'
import { getOperatorName } from '../utils/operators.js'

// Only "in" and "nin" are used. The rest is kept for backward compatibility
export const selectOperators: ListFilterByOperator[] = [
  ListFilterByOperator.in,
  ListFilterByOperator.nin,
  ListFilterByOperator.equals,
  ListFilterByOperator.not,
]

export const SelectOperatorPicker: OperatorPickerComponent = ({
  filter,
  onChange,
}) => {
  const intl = useI18n()
  const { operator, type, subType, value } = filter
  const currentOperatorName = getOperatorName(
    operator,
    type,
    subType,
    value,
    intl,
  )

  return (
    <GenericOperatorPicker operatorName={currentOperatorName}>
      <Dropdown.Item
        key={ListFilterByOperator.in}
        onClick={() => {
          onChange(ListFilterByOperator.in)
        }}
      >
        {getOperatorName(ListFilterByOperator.in, type, subType, value, intl)}
      </Dropdown.Item>
      <Dropdown.Item
        key={ListFilterByOperator.nin}
        onClick={() => {
          onChange(ListFilterByOperator.nin)
        }}
      >
        {getOperatorName(ListFilterByOperator.nin, type, subType, value, intl)}
      </Dropdown.Item>
    </GenericOperatorPicker>
  )
}
