import { forwardRef } from 'react'

import { clsx } from 'clsx'

import { Icon } from '../Icon/index.js'
import type { IconComponentType } from '../Icon/types.js'
import { Tooltip } from '../Tooltip/index.js'
import { HTMLTribeProps } from '../types/index.js'
import { badgeIconStyles, badgeStyles } from './Badge.styles.js'
import { BadgeStyleProps } from './types.js'

export type BadgeProps = HTMLTribeProps<'div'> &
  BadgeStyleProps & {
    leadingIcon?: IconComponentType
    trailingIcon?: IconComponentType
    tooltip?: string
  }

/**
 * Badges help highlight important information, such as notifications or new and unread messages.
 * They’re primarily used for communicating secondary or additional information to text.
 */
export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  (
    {
      as,
      variant = 'neutral',
      size = 'md',
      children,
      leadingIcon,
      trailingIcon,
      truncate,
      className,
      maxWidth,
      tooltip,
      ...rest
    },
    ref,
  ) => {
    const Component = as || 'div'

    const nestedSpan = !!leadingIcon || !!trailingIcon

    const badge = (
      <Component
        ref={ref}
        className={clsx(
          'badge',
          nestedSpan ? 'inline-flex items-center' : '',
          badgeStyles({
            variant,
            size,
            truncate,
            maxWidth,
          }),
          className,
        )}
        {...rest}
      >
        {leadingIcon && (
          <Icon className={badgeIconStyles({ size })}>{leadingIcon}</Icon>
        )}
        {nestedSpan ? (
          <span className={clsx('min-w-0', truncate && 'truncate')}>
            {children}
          </span>
        ) : (
          children
        )}
        {trailingIcon && (
          <Icon className={badgeIconStyles({ size })}>{trailingIcon}</Icon>
        )}
      </Component>
    )

    if (tooltip) {
      return (
        <Tooltip>
          <Tooltip.Trigger asChild>{badge}</Tooltip.Trigger>
          <Tooltip.Panel>{tooltip}</Tooltip.Panel>
        </Tooltip>
      )
    }

    return badge
  },
)
