import { useState } from 'react'

import { nanoid } from 'nanoid'

import { ClientError, ClientFileError } from '@tribeplatform/gql-client/lib'
import { type Glyph, GlyphMediaVariant } from '@tribeplatform/gql-client/types'
import { useCreateImages } from '@tribeplatform/react-sdk/hooks'

import { mergeArrayByKey } from '../../common/utils/array.utils.js'
import type { PickerEmoji, PickerIcon } from '../../Picker/types.js'
import {
  emojiToImageId,
  iconToImageId,
} from '../../Picker/utils/picker.utils.js'
import { useUploadQueue } from '../../Providers/UploadQueueProvider.js'
import { UploadableImage, UploadableMedia } from './types.js'

export type UseHandleUploadableMediaProps = {
  defaultValue: UploadableMedia[]
  multiple: boolean
  onChange: (images: UploadableMedia[]) => void
  onUploadStart?: (images: UploadableMedia[]) => void
  onError: (error: ClientFileError) => void
}

export const useHandleUploadableMedia = ({
  defaultValue,
  multiple,
  onChange,
  onUploadStart,
  onError,
}: UseHandleUploadableMediaProps) => {
  const [images, setImages] = useState<UploadableMedia[]>(defaultValue)

  const { addToUploadQueue, removeFromUploadQueue } = useUploadQueue()
  const { mutateAsync: createImages, isLoading: isImagesLoading } =
    useCreateImages({
      useMutationOptions: {
        onMutate: () => {
          onUploadStart?.(images)
        },
        onError,
      },
    })

  const onImageUpload = async (files: File[]) => {
    if (!files?.length) {
      return null
    }
    if (!multiple) {
      files = files.slice(0, 1)
    }

    const tempImageData = await Promise.all(
      files.map(async file => {
        const fileSrc = URL.createObjectURL(file)

        return {
          id: nanoid(),
          name: file.name,
          file,
          isLoading: true,
          url: fileSrc,
        } as UploadableImage
      }),
    )

    setImages(images =>
      multiple ? mergeArrayByKey(images, tempImageData, 'name') : tempImageData,
    )

    /**
     * Since we are using a single mutation to upload multiple files
     * and the request for all files will fail or resolve together,
     * we will assign a signle upload queue item to all files.
     */
    const batchUploadQueueItem = {
      id: nanoid(),
    }
    addToUploadQueue(batchUploadQueueItem)

    try {
      const uploadedImages = await createImages(
        files.map(file => ({
          name: file.name,
          file,
          size: file.size,
        })),
      )

      const updatedImages = multiple
        ? mergeArrayByKey(images, uploadedImages, 'name')
        : uploadedImages
      setImages(updatedImages)
      onChange(updatedImages)
    } catch (error) {
      // Revert back
      const updatedImages = [...images.filter(a => !a.isLoading)]
      setImages(updatedImages)
      onChange(updatedImages)

      const message: string =
        (error as ClientError)?.response?.errors
          ?.map(e => e.message)
          ?.join('\n') || error.message

      onError({
        fileName: '',
        message,
      })
    } finally {
      removeFromUploadQueue(batchUploadQueueItem)
    }
  }

  const onEmojiChange = async (pickerEmoji: PickerEmoji) => {
    const glyph: Glyph = {
      id: emojiToImageId(pickerEmoji),
      text: pickerEmoji.unified,
      variant: GlyphMediaVariant.emoji,
    }
    onChange([glyph])
    setImages([glyph])
  }

  const onIconChange = async (pickerIcon: PickerIcon) => {
    const glyph: Glyph = {
      id: iconToImageId(pickerIcon),
      text: pickerIcon.name,
      variant: GlyphMediaVariant.icon,
    }
    onChange([glyph])
    setImages([glyph])
  }

  const onRemove = (image: UploadableImage) => {
    const updatedImages = [...images.filter(it => it.id !== image.id)]
    setImages(updatedImages)
    onChange(updatedImages)
  }

  return {
    medias: images,
    isLoading: isImagesLoading,
    onImageUpload,
    onIconChange,
    onEmojiChange,
    onRemove,
  }
}
