import { clsx } from 'clsx'

import { EntityImage } from '@tribeplatform/react-components/common/components'
import { useSpace } from '@tribeplatform/react-sdk/hooks'

import { IconSettings } from '../../Fields/types.js'
import { IconWrapperStyle } from '../../types.js'

interface Props {
  className?: string
  settings: IconSettings
  spaceId: string
  isSimple?: boolean
}

export const IconField = ({
  className,
  settings,
  spaceId,
  isSimple = false,
}: Props) => {
  const { size, useSpaceIcon, customIcon, wrapInCard, iconWrapperStyle } =
    settings
  const { data: space } = useSpace({
    variables: {
      id: spaceId,
    },
    useQueryOptions: {
      enabled: !!spaceId && !!useSpaceIcon,
    },
  })
  const icon = useSpaceIcon ? space?.image : customIcon

  return (
    <div className={clsx(className)}>
      {wrapInCard ? (
        <div
          className={clsx(
            'p-4 inline-flex items-center justify-center text-content',
            isSimple && 'bg-surface',
            !isSimple && 'bg-surface-subdued',
            iconWrapperStyle === IconWrapperStyle.Circle && 'rounded-full',
            iconWrapperStyle === IconWrapperStyle.Rounded && 'rounded-base',
            iconWrapperStyle === IconWrapperStyle.Sharp && 'rounded-none',
          )}
        >
          <EntityImage entity={{ image: icon }} size={size} />
        </div>
      ) : (
        <EntityImage entity={{ image: icon }} size={size} />
      )}
    </div>
  )
}
