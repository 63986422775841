import { CustomPostFields } from '@tribeplatform/gql-client/graphql/post'
import { ShortContentFormat } from '@tribeplatform/gql-client/types'
import { StaticField, View } from '@tribeplatform/react-components/Views'

import {
  getRepliesOrderBy,
  getRepliesReverse,
} from './sortOptionIdToPostListOrder.js'

interface GenerateActiveFieldIds {
  staticFields: StaticField[]
  style: View['style']
}
const getActiveFields = ({
  staticFields,
  style,
}: GenerateActiveFieldIds): StaticField[] => {
  const output = staticFields
    .filter(
      ({ enabled, styles, isStatic }) =>
        enabled && isStatic && !(styles && !styles.includes(style)),
    )
    .reduce<StaticField[]>((result, field) => {
      if (field?.fields) {
        return [
          ...result,
          ...getActiveFields({ staticFields: field.fields, style }),
        ]
      }

      return [...result, field]
    }, [])

  return output
}

interface Props {
  staticFields: StaticField[]
  style: View['style']
}
export const generateGqlFields = ({
  staticFields,
  style,
}: Props): CustomPostFields => {
  const activeFields = getActiveFields({ staticFields, style })

  const postFields = activeFields.reduce((result, field) => {
    switch (field.id) {
      case 'tags':
      case 'listTags':
        return { ...result, tags: 'all' } as CustomPostFields
      case 'replyList':
        return {
          ...result,
          attachments: 'basic',
          authMemberProps: 'all',
          replies: {
            fields: {
              authMemberProps: 'all',
              embeds: 'basic',
              mentions: 'basic',
              attachments: 'basic',
              owner: {
                member: { profilePicture: 'basic', badges: 'all' },
              },
              reactions: { fields: 'all', variables: { limit: 10 } },
            },
            variables: {
              limit: 2,
              orderBy: getRepliesOrderBy(field.settings?.defaultReplySortId),
              reverse: getRepliesReverse(field.settings?.defaultReplySortId),
            },
          },
        } as CustomPostFields
      case 'listAvatar':
        return {
          ...result,
          owner: {
            member: { profilePicture: 'basic', badges: 'all' },
          },
        } as CustomPostFields
      case 'galleryMemberInfo': {
        const postFields: CustomPostFields = {
          ...result,
          owner: {
            member: { profilePicture: 'basic', badges: 'all' },
          },
          ...(field.settings?.showSpaceName ? { space: 'basic' } : {}),
        }

        return postFields
      }
      case 'cardPostInfoBar':
        return {
          ...result,
          reactions: { fields: 'all', variables: { limit: 10 } },
        } as CustomPostFields
      case 'galleryPostContent':
      case 'listPostContent':
        if (field.settings?.contentFormat === ShortContentFormat.Text) {
          return {
            ...result,
            textContent: true,
          }
        }

        return { ...result }
      case 'cardMemberInfo': {
        const postFields: CustomPostFields = {
          ...result,
          owner: {
            member: { profilePicture: 'basic', badges: 'all' },
          },
          ...(field.settings?.showPostOptions
            ? { authMemberProps: 'all' }
            : {}),
          ...(field.settings?.showSpaceName ? { space: 'basic' } : {}),
        }

        return postFields
      }
      case 'reactButton':
        return {
          ...result,
          authMemberProps: 'all',
          reactions: { fields: 'basic', variables: { limit: 10 } },
        } as CustomPostFields

      case 'actionBar':
        return {
          ...result,
          authMemberProps: 'all',
          reactions: { fields: 'all', variables: { limit: 10 } },
        } as CustomPostFields
      case 'postInfoBar':
        return {
          ...result,
          authMemberProps: 'all',
          space: 'basic',
        } as CustomPostFields
      default:
        return result
    }
  }, {} as CustomPostFields)

  return {
    ...postFields,
    // For better UX we should always fetch space and owner basic info
    owner: {
      member: { profilePicture: 'basic', badges: 'all' },
    },
    space: { image: 'basic' },
  }
}
