import { ReactNode } from 'react'

import type { Placement } from '@floating-ui/react'
import { Menu } from '@headlessui/react'
import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import { Divider } from '../Divider/Divider.js'
import { HTMLTribeProps } from '../types/index.js'
import { FloatingContextProvider } from '../utils/floating/index.js'
import { runIfFn } from '../utils/index.js'
import { ButtonMinimal, DropdownButton } from './DropdownButton.js'
import { DropdownProvider } from './DropdownContext.js'
import { DropdownGroup } from './DropdownGroup.js'
import { Item } from './DropdownItem.js'
import { Items } from './DropdownItems.js'

interface DropdownRenderProps {
  open: boolean
}

export type DropdownProps = Omit<HTMLTribeProps<'div'>, 'children'> & {
  /**
   * see https://floating-ui.com/docs/computePosition#placement
   */
  placement?: Placement
  children?: ReactNode | ((props: DropdownRenderProps) => ReactNode)
  disabled?: boolean
  forceOpen?: boolean
}

/**
 * Menus offer an easy way to build custom, accessible dropdown components with robust support for keyboard navigation.
 */
export const Dropdown = ({
  children,
  as,
  className,
  placement = 'bottom-start',
  disabled,
  forceOpen,
}: DropdownProps) => {
  return (
    <Menu
      as={as || 'div'}
      className={twMerge(clsx('relative inline-block text-left', className))}
    >
      {renderProps => (
        <FloatingContextProvider placement={placement}>
          <DropdownProvider
            open={renderProps.open || forceOpen}
            disabled={disabled}
          >
            {runIfFn(children, renderProps)}
          </DropdownProvider>
        </FloatingContextProvider>
      )}
    </Menu>
  )
}

const DropdownDivider = ({ className }: { className?: string }) => {
  return <Divider padding="sm" className={clsx('-mx-2', className)} />
}

Dropdown.Button = DropdownButton
Dropdown.ButtonMinimal = ButtonMinimal
Dropdown.Items = Items
Dropdown.Item = Item
Dropdown.Divider = DropdownDivider
Dropdown.Group = DropdownGroup
