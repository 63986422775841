import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { FirebaseApp } from 'firebase/app'
import { Messaging } from 'firebase/messaging'

import { usePrevious } from '@bettermode/common/hooks'
import { useDomain } from '@tribeplatform/react-components/common/hooks'
import { useI18n } from '@tribeplatform/react-components/i18n'
import {
  useAddFcmTokenToSubscriberSettings,
  useRemoveFcmTokenFromSubscriberSettings,
  useSubscriberSettings,
} from '@tribeplatform/react-sdk/hooks'
import { noop } from '@tribeplatform/react-ui-kit/hooks'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { useLogin } from '../../hooks/useLogin.js'
import { DesktopNotificationContext } from './DesktopNotificationProvider.js'
import {
  getFcmToken,
  getFirebaseApp,
  getFirebaseMessaging,
} from './firebase-messaging.js'
import { DesktopNotification } from './types.js'

interface Props {
  children: React.ReactNode
}

export const DesktopNotificationProviderClient = ({ children }: Props) => {
  const { $t } = useI18n()
  const { isLoggedIn } = useLogin()
  const { getResourceUrl } = useDomain()
  const { data: settings } = useSubscriberSettings({
    useQueryOptions: { enabled: isLoggedIn },
  })
  const { mutateAsync: addFcmToken } = useAddFcmTokenToSubscriberSettings()
  const { mutateAsync: removeFcmToken } =
    useRemoveFcmTokenFromSubscriberSettings()
  const [browserNotificationsEnabled, setBrowserNotificationsEnabled] =
    useState<boolean>(false)
  const [fcmToken, setFcmToken] = useState<string | null>(null)
  const previousFcmToken = usePrevious(fcmToken)
  const firebaseApp = useRef<FirebaseApp | null>(null)
  const firebaseMessaging = useRef<Messaging | null>(null)
  const appNotificationEnabled = settings?.fcmDeviceTokens?.includes(fcmToken)

  const initializeFirebase = useCallback(() => {
    firebaseApp.current = getFirebaseApp()
    firebaseMessaging.current = getFirebaseMessaging(firebaseApp.current)
  }, [])

  const getToken = useCallback(async () => {
    const token = await getFcmToken(firebaseMessaging.current, getResourceUrl)
    setFcmToken(token)
    setBrowserNotificationsEnabled(!!token)
  }, [getResourceUrl])

  /**
   * Listen for changes in browser notification permission
   * and sync the state with the app notification settings.
   */
  const setBrowserNotificationListener = useCallback(() => {
    if (typeof navigator === 'undefined') {
      return
    }

    navigator?.permissions
      ?.query({ name: 'notifications' })
      .then(permissionStatus => {
        permissionStatus.onchange = (event: Event) => {
          const permission = (event.target as PermissionStatus).state

          // User has reset the permission to default (prompt)
          if (permission === 'prompt') {
            setBrowserNotificationsEnabled(false)
            setFcmToken(null)
            return
          }

          const granted = permission === 'granted'
          setBrowserNotificationsEnabled(granted)

          if (!granted) {
            setFcmToken(null)
          } else {
            // Fetch the FCM token here so that when user toggles the
            // app notification settings, we already have the token and just
            // add it to the subscriber settings.
            getToken()
          }
        }
      })
  }, [getToken])

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    initializeFirebase()

    getToken().then(setBrowserNotificationListener)
  }, [getToken, initializeFirebase, isLoggedIn, setBrowserNotificationListener])

  useEffect(() => {
    if (previousFcmToken && previousFcmToken !== fcmToken) {
      removeFcmToken({ token: previousFcmToken }).catch(noop)
    }
  }, [fcmToken, previousFcmToken, removeFcmToken])

  const updateNotificationSettings = useCallback(
    async (action: 'enable' | 'disable') => {
      if (!fcmToken) {
        return
      }

      try {
        if (action === 'enable') {
          await addFcmToken({ token: fcmToken })
        } else {
          await removeFcmToken({ token: fcmToken })
        }
      } catch (error) {
        toast({
          title: $t({
            defaultMessage: 'Your notification settings cannot be updated.',
            id: 'Settings.Notification.FailedToUpdate',
          }),
          description: error?.message,
          status: 'error',
        })
      }
    },
    [$t, addFcmToken, fcmToken, removeFcmToken],
  )

  const providerValue = useMemo<DesktopNotification>(
    () => ({
      enabled: appNotificationEnabled,
      blocked: !browserNotificationsEnabled,
      enable: () => updateNotificationSettings('enable'),
      disable: () => updateNotificationSettings('disable'),
    }),
    [
      appNotificationEnabled,
      browserNotificationsEnabled,
      updateNotificationSettings,
    ],
  )

  return (
    <DesktopNotificationContext.Provider value={providerValue}>
      {children}
    </DesktopNotificationContext.Provider>
  )
}
