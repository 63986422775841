import { useRouter } from '@tribeplatform/react-sdk'
import { useAuthMember } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { TopBarButton } from '@tribeplatform/react-ui-kit/TopBar'

export const CreatePostButton = () => {
  const { Link } = useRouter()
  const { isGuest } = useAuthMember()
  const canCreatePost = !isGuest

  if (!canCreatePost) {
    return null
  }

  return (
    <div className="h-full hidden lg:flex justify-center items-center shrink-0">
      <TopBarButton
        as={Link}
        href="/new"
        variant="primary"
        icon={<SvgIcon name="plus" className="w-5 h-5" />}
      />
    </div>
  )
}
