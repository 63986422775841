import { Fragment, useMemo } from 'react'

import { clsx } from 'clsx'

import { CustomFieldType } from '@tribeplatform/gql-client/types'
import { findPostType } from '@tribeplatform/react-components/CMS'
import { TruncateSettingsContext } from '@tribeplatform/react-components/Feed'
import type { TruncateSettings } from '@tribeplatform/react-components/Feed'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { useBlock } from '@tribeplatform/react-slate-kit/hooks'

import { ActionBar } from '../fields/ActionBar.js'
import { CardBanner } from '../fields/CardBanner.js'
import { CardPostContent } from '../fields/CardPostContent.js'
import { CustomField } from '../fields/CustomField.js'
import { CustomFields } from '../fields/CustomFields.js'
import { GalleryBanner } from '../fields/GalleryBanner.js'
import { GalleryMemberInfo } from '../fields/GalleryMemberInfo.js'
import { GalleryPostContent } from '../fields/GalleryPostContent.js'
import { InfoBarField } from '../fields/InfoBarField.js'
import { ListAvatar } from '../fields/ListAvatar.js'
import { ListPostSummary } from '../fields/ListPostSummary.js'
import { PinnedReply } from '../fields/PinnedReply.js'
import { PostInfoBarField } from '../fields/PostInfoBarField.js'
import { PostTitle } from '../fields/PostTitle.js'
import { PublishDate } from '../fields/PublishDate.js'
import { ReactButton } from '../fields/ReactButton.js'
import { ReactionCount } from '../fields/ReactionCount.js'
import { ReplyCount } from '../fields/ReplyCount.js'
import { ReplyListField } from '../fields/ReplyListField.js'
import { Tags } from '../fields/Tags.js'
import { FieldProps, PostsBlockProps } from '../types.js'
import { getMediaUrl, getTitleVariant } from '../utils/index.js'

export const Field = ({
  field,
  post,
  originalPost,
  postTypes,
  viewStyle,
  activeFieldId,
  linkTo,
  preview,
  isSinglePost,
  singlePostType,
  isPostPage,
  setTranslatedPost,
}: FieldProps) => {
  const {
    data: { id: networkId },
  } = useNetwork()
  const {
    extraContext: { activated },
  } = useBlock<PostsBlockProps, { activated: boolean }>()
  const mediaUrl = getMediaUrl({ field, post })

  const stretchX = clsx(
    viewStyle === 'list' && field.settings?.stretchX && 'flex-1',
    viewStyle === 'gallery' && field.settings?.fullWidth && 'basis-full',
    viewStyle === 'carousel' && field.settings?.fullWidth && 'basis-full',
    viewStyle === 'card' && field.settings?.fullWidth && 'basis-full',
  )
  const titleVariant = getTitleVariant(field.settings?.titleSize)

  const highlighted = activated && activeFieldId === field.id
  // If a field should stretch, then we should allow it to be empty and not return null
  const allowEmpty =
    (viewStyle === 'list' && field.settings?.stretchX) ||
    (viewStyle === 'gallery' && field.settings?.stretchY) ||
    (viewStyle === 'carousel' && field.settings?.stretchY)

  const truncateSettings = useMemo<TruncateSettings>(() => {
    return {
      displayStyle: field.settings?.displayStyle,
      truncateSize: field.settings?.truncateSize,
    }
  }, [field.settings?.displayStyle, field.settings?.truncateSize])
  const postType = findPostType({
    networkId,
    id: post?.postTypeId,
    slug: post?.postType?.slug,
    postTypes,
  })

  switch (field.id) {
    case 'listAvatar':
    case 'magazineAvatar':
      return (
        <ListAvatar
          field={field}
          mediaUrl={mediaUrl}
          post={post}
          highlighted={highlighted}
        />
      )
    case 'galleryBanner':
      return (
        <GalleryBanner
          field={field}
          mediaUrl={mediaUrl}
          aspectRatio={field.settings?.aspectRatio}
          post={post}
          highlighted={highlighted}
        />
      )
    case 'cardBanner':
      return (
        <CardBanner
          field={field}
          aspectRatio={field.settings?.aspectRatio}
          post={post}
          highlighted={highlighted}
          linkTo={linkTo}
          preview={preview}
          isSinglePost={isSinglePost}
        />
      )
    case 'listPostSummary':
      return (
        <ListPostSummary
          field={field}
          highlighted={highlighted}
          stretchX={stretchX}
          fieldProps={{
            post,
            originalPost,
            activeFieldId,
            postTypes,
            viewStyle,
            linkTo,
            preview,
          }}
          FieldRenderer={Field}
        />
      )
    case 'galleryMemberInfo':
    case 'cardMemberInfo':
      return (
        <GalleryMemberInfo
          post={post}
          highlighted={highlighted}
          stretchX={stretchX}
          showSpaceName={field.settings?.showSpaceName}
          hasPostOptions={viewStyle === 'card'}
          showPostOptions={field.settings?.showPostOptions}
        />
      )
    case 'listPostTitle':
    case 'galleryPostTitle':
      return (
        <PostTitle
          field={field}
          post={post}
          highlighted={highlighted}
          stretchX={stretchX}
          titleVariant={titleVariant}
          linkTo={linkTo}
          preview={preview}
          isSinglePost={isSinglePost}
          isPostPage={isPostPage}
        />
      )
    case 'listPostContent':
      return (
        <GalleryPostContent
          field={field}
          post={post}
          highlighted={highlighted}
          stretchX={stretchX}
          lineClamp="sm"
          contentFormat={field.settings?.contentFormat}
          preview={preview}
        />
      )
    case 'galleryPostContent':
      return (
        <GalleryPostContent
          field={field}
          post={post}
          highlighted={highlighted}
          stretchX={stretchX}
          lineClamp="lg"
          contentFormat={field.settings?.contentFormat}
          preview={preview}
        />
      )
    case 'cardPostContent':
      return (
        <CardPostContent
          field={field}
          post={post}
          originalPost={originalPost}
          highlighted={highlighted}
          stretchX={stretchX}
          displayStyle={field.settings?.displayStyle}
          truncateSize={field.settings?.truncateSize}
          setTranslatedPost={setTranslatedPost}
          preview={preview}
        />
      )
    case 'listTags':
    case 'tags':
      return (
        <Tags
          post={post}
          highlighted={highlighted}
          stretchX={stretchX}
          preview={preview}
        />
      )
    case 'reactionCount':
      return (
        <ReactionCount
          post={post}
          highlighted={highlighted}
          stretchX={stretchX}
        />
      )
    case 'cardPostInfoBar':
      return (
        <InfoBarField
          field={field}
          post={post}
          highlighted={highlighted}
          stretchX={stretchX}
          preview={preview}
          isPostPage={isPostPage}
        />
      )
    case 'replyCount':
      return (
        <ReplyCount post={post} highlighted={highlighted} stretchX={stretchX} />
      )
    case 'publishDate':
      return (
        <PublishDate
          field={field}
          post={post}
          highlighted={highlighted}
          stretchX={stretchX}
        />
      )
    case 'actionBar':
      return (
        <ActionBar
          post={post}
          highlighted={highlighted}
          stretchX={stretchX}
          buttonVariant={field.settings?.buttonVariant}
          actionBar={field.settings?.actionBar}
        />
      )
    case 'reactButton':
      return (
        <ReactButton
          post={post}
          highlighted={highlighted}
          stretchX={stretchX}
          buttonVariant={field.settings?.buttonVariant}
          showReactionCount={field.settings?.showReactionCount}
          reactionCountPosition={field.settings?.reactionCountPosition}
        />
      )
    case 'replyList':
      return (
        <ReplyListField
          post={post}
          highlighted={highlighted}
          stretchX={stretchX}
          postTypes={postTypes}
          showReplySortOptions={field.settings?.showReplySortOptions}
          defaultReplySortId={field.settings?.defaultReplySortId}
          isSinglePost={isSinglePost}
        />
      )
    case 'postInfoBar':
      return (
        <PostInfoBarField
          post={post}
          highlighted={highlighted}
          isSinglePost={isSinglePost}
          showBackToSpaceButton={field.settings?.showBackToSpaceButton}
        />
      )
    case 'pinnedReply':
      return (
        <PinnedReply
          field={field}
          post={post}
          highlighted={highlighted}
          preview={preview}
        />
      )
    case 'customFields': {
      if (!singlePostType) {
        return (
          <CustomFields
            field={field}
            postType={postType}
            post={post}
            highlighted={highlighted}
            allowEmpty={allowEmpty}
            stretchX={stretchX}
            truncateSettings={truncateSettings}
            isPostPage={isPostPage}
            preview={preview}
          />
        )
      }

      return null
    }
    default:
      return (
        <>
          {postType?.postFields?.fields
            .filter(postField => postField.key === field.id)
            .map(postField => (
              <Fragment key={postField?.key}>
                {postField.type === CustomFieldType.richText ? (
                  <TruncateSettingsContext.Provider value={truncateSettings}>
                    <CustomField
                      field={field}
                      postField={postField}
                      post={post}
                      highlighted={highlighted}
                      allowEmpty={allowEmpty}
                      stretchX={stretchX}
                      preview={preview}
                      showLabel={field.settings?.showFieldName}
                    />
                  </TruncateSettingsContext.Provider>
                ) : (
                  <CustomField
                    field={field}
                    postField={postField}
                    post={post}
                    highlighted={highlighted}
                    allowEmpty={allowEmpty}
                    stretchX={stretchX}
                    preview={preview}
                    context={isPostPage ? 'post' : null}
                    showLabel={field.settings?.showFieldName}
                  />
                )}
              </Fragment>
            ))}
        </>
      )
  }
}
