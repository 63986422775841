import type { Fields } from '@tribeplatform/gql-client/types'

import { KeyConstructor } from './keyConstructor.js'

const BILLING_PRODUCTS_KEY = 'billingProducts'
const SUBSCRIPTION_INFORMATION_KEY = 'subscriptionInformation'

export const getBillingProductsKey = KeyConstructor<
  never,
  Fields.BillingProductsFields
>(BILLING_PRODUCTS_KEY)
export const getSubscriptionInformationKey = KeyConstructor<never, never>(
  SUBSCRIPTION_INFORMATION_KEY,
)
