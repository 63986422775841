import { dehydrate } from '@tanstack/react-query'

import {
  type NetworkPublicInfo,
  type Member,
  type Network,
  type Page,
  type AuthToken,
  SsoStatus,
} from '@bettermode/common/gql/generated'
import type { DeepPartial } from '@bettermode/common/types'
import { QueryClient } from '@tribeplatform/react-sdk'
import {
  getAuthMemberKey,
  getAuthTokenKey,
  getMemberInvitationLinkValidityKey,
  getMemberInvitationValidityKey,
  getMemberKey,
  getNetworkKey,
  getPageKey,
  getPostKey,
  getSpaceKey,
  getSsosKey,
} from '@tribeplatform/react-sdk/utils/keys'

import type { InjectedAppProps } from '../../../../server/types/server.types.js'

export const getDehydratedState = (injectedAppProps: InjectedAppProps) => {
  const queryClient = new QueryClient()
  const network = injectedAppProps.network satisfies DeepPartial<Network>
  const networkPublicInfo = {
    id: network?.id,
    membership: network?.membership,
    status: network?.status,
    name: network?.name,
    domain: network?.domain,
    domainSubfolder: network?.domainSubfolder,
    visibility: network?.visibility,
    favicon: network?.favicon,
    images: network?.images,
    logo: network?.logo,
  } satisfies DeepPartial<NetworkPublicInfo>
  const authMember = injectedAppProps.authMember satisfies DeepPartial<Member>
  const page = injectedAppProps.page satisfies DeepPartial<Page>
  const authToken = {
    accessToken: injectedAppProps.accessToken,
    network,
    member: authMember,
    networkPublicInfo,
    role: authMember?.role,
  } satisfies DeepPartial<AuthToken>

  queryClient.setQueryData(getAuthTokenKey({ fields: 'default' }), authToken)
  queryClient.setQueryData(getNetworkKey({ fields: 'default' }), network)
  queryClient.setQueryData(getAuthMemberKey({ fields: 'default' }), authMember)
  queryClient.setQueryData(
    getPageKey({
      variables: { path: injectedAppProps?.pagePath },
      fields: 'default',
    }),
    page,
  )
  queryClient.setQueryData(
    getSpaceKey({
      variables: { id: page.space.id },
      fields: 'default',
    }),
    page.space,
  )

  if (injectedAppProps?.sso) {
    queryClient.setQueryData(
      getSsosKey({ variables: { status: SsoStatus.enable } }),
      injectedAppProps.sso,
    )
  }

  if (injectedAppProps?.routeData?.post) {
    const postId = injectedAppProps.routeData.post.id
    queryClient.setQueryData(
      getPostKey({ variables: { id: postId } }),
      injectedAppProps.routeData.post,
    )
  }

  if (injectedAppProps?.routeData?.member) {
    const memberId = injectedAppProps.routeData.member.id
    queryClient.setQueryData(
      getMemberKey({
        variables: { id: memberId },
        fields: {
          authMemberProps: 'all',
          badges: 'all',
          banner: 'all',
          profilePicture: 'all',
          role: 'basic',
          fields: 'all',
        },
      }),
      injectedAppProps.routeData.member,
    )
  }

  if (injectedAppProps?.routeData?.invitationTokenData) {
    const token = injectedAppProps.routeData.invitationToken
    queryClient.setQueryData(
      getMemberInvitationValidityKey({
        variables: { token },
      }),
      injectedAppProps.routeData.invitationTokenData,
    )
  }

  if (injectedAppProps?.routeData?.invitationLinkData) {
    const { id } = injectedAppProps.routeData.invitationLinkData
    queryClient.setQueryData(
      getMemberInvitationLinkValidityKey({ variables: { id } }),
      injectedAppProps.routeData.invitationLinkData,
    )
  }

  const state = dehydrate(queryClient)

  return state
}
