import { clsx } from 'clsx'

import type { Image } from '@tribeplatform/gql-client/types'
import { useBlock } from '@tribeplatform/react-slate-kit/hooks'

import { getTitleVariant } from '../../MembersBlock/utils/utils.js'
import { CardBanner } from '../fields/CardBanner.js'
import { GalleryBanner } from '../fields/GalleryBanner.js'
import { GalleryContent } from '../fields/GalleryContent.js'
import { LinkField } from '../fields/LinkField.js'
import { ListContent } from '../fields/ListContent.js'
import { ListImage } from '../fields/ListImage.js'
import { TitleField } from '../fields/TitleField.js'
import type { FieldProps, QuickLinksBlockProps } from '../types.js'

export const Field = ({
  field,
  source,
  viewStyle,
  activeFieldId,
  preview,
  gallerySize,
}: FieldProps) => {
  const {
    extraContext: { activated },
  } = useBlock<QuickLinksBlockProps, { activated: boolean }>()
  const mediaUrl =
    (source?.bannerImage as Image)?.urls?.medium ||
    (source?.bannerImage as Image)?.url

  const stretchX = clsx(
    viewStyle === 'list' && field.settings?.stretchX && 'flex-1',
    viewStyle === 'gallery' && field.settings?.fullWidth && 'basis-full',
    viewStyle === 'carousel' && field.settings?.fullWidth && 'basis-full',
    viewStyle === 'card' && field.settings?.fullWidth && 'basis-full',
  )
  const titleVariant = getTitleVariant(field.settings?.titleSize)
  const highlighted = activated && activeFieldId === field.id

  switch (field.id) {
    case 'quickLinkListImage':
      return (
        <ListImage
          source={source}
          field={field}
          mediaUrl={mediaUrl}
          highlighted={highlighted}
        />
      )
    case 'quickLinkGalleryBanner':
      return (
        <GalleryBanner
          field={field}
          mediaUrl={mediaUrl}
          aspectRatio={field.settings?.aspectRatio}
          source={source}
          highlighted={highlighted}
          gallerySize={gallerySize}
        />
      )
    case 'quickLinkCardBanner':
      return (
        <CardBanner
          field={field}
          mediaUrl={mediaUrl}
          aspectRatio={field.settings?.aspectRatio}
          source={source}
          highlighted={highlighted}
          preview={preview}
        />
      )
    case 'quickLinkListContent':
      return (
        <ListContent
          field={field}
          highlighted={highlighted}
          stretchX={stretchX}
          fieldProps={{
            source,
            activeFieldId,
            viewStyle,
            preview,
          }}
          FieldRenderer={Field}
        />
      )
    case 'quickLinkListTitle':
    case 'quickLinkGalleryTitle':
      return (
        <TitleField
          source={source}
          highlighted={highlighted}
          stretchX={stretchX}
          titleVariant={titleVariant}
        />
      )
    case 'quickLinkListDescription':
      return (
        <GalleryContent
          source={source}
          highlighted={highlighted}
          stretchX={stretchX}
          lineClamp="sm"
        />
      )
    case 'quickLinkGalleryDescription':
      return (
        <GalleryContent
          source={source}
          highlighted={highlighted}
          stretchX={stretchX}
          lineClamp="lg"
        />
      )
    case 'quickLinkCardDescription':
      return (
        <GalleryContent
          source={source}
          highlighted={highlighted}
          stretchX={stretchX}
        />
      )
    case 'quickLinkButton':
      return (
        <LinkField
          source={source}
          field={field}
          highlighted={highlighted}
          stretchX={stretchX}
        />
      )
    default:
      return null
  }
}
