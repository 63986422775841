import type { SpaceRoleFields } from '@tribeplatform/gql-client/graphql'
import type {
  SpaceRole,
  QuerySpaceRolesArgs,
} from '@tribeplatform/gql-client/types'

import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSpaceRolesKey } from '../../utils/keys/spaceRoles.key.js'

export const useSpaceRoles = (options: {
  variables: QuerySpaceRolesArgs
  fields?: SpaceRoleFields
  useQueryOptions?: UseQueryOptions<Array<SpaceRole>>
}) => {
  const { variables, fields = 'basic', useQueryOptions } = options
  const { client } = useTribeClient()
  const spaceRolesKey = getSpaceRolesKey({ variables, fields })

  return useQuery<Array<SpaceRole>>(
    spaceRolesKey,
    () => client.spaceRoles.list(variables, fields),
    useQueryOptions,
  )
}
