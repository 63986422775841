import { cva } from 'class-variance-authority'

export const indicatorBadgeStyles = cva(
  [
    'inline-flex',
    'shrink-0',
    'rounded-button-sm',
    'items-center justify-center',
    'text-label-xs leading-none font-bold',
  ],
  {
    variants: {
      variant: {
        primary: 'text-content-on-primary bg-action-primary',
        neutral: 'bg-background-pressed text-content-on-background-pressed',
        danger: 'text-content-on-destructive bg-action-destructive',
      },
      size: {
        sm: 'min-w-[18px] min-h-[18px]',
        md: 'min-w-[20px] min-h-[20px]',
      },
    },
  },
)

export const indicatorDotStyles = cva(
  ['inline-block', 'shrink-0', 'rounded-full', 'h-2 w-2'],
  {
    variants: {
      variant: {
        primary: 'bg-action-primary',
        neutral: 'bg-surface-neutral',
        danger: 'bg-action-destructive',
      },
    },
  },
)
