import { CustomFieldSubtype } from '../../CustomField/utils.js'
import type { ValuePickerComponent } from '../types.js'
import { getLabelsFromOptions } from '../utils/inlineFilters.js'
import { InlineMultiselectPicker } from './entityPickers/InlineMultiselectPicker.js'
import { MultiselectPicker } from './MultiselectPicker.js'
import { RelationMemberPicker } from './RelationMemberPicker.js'
import { RelationSpacePicker } from './RelationSpacePicker.js'
import { RelationTagPicker } from './RelationTagPicker.js'

export const ArrayValuePicker: ValuePickerComponent = ({
  filter,
  spread,
  inline,
  className,
  onChange,
}) => {
  const { value = [], subType, extraProps } = filter
  const selectOptions = extraProps?.selectOptions ?? []
  const memberGroup = extraProps?.memberGroup
  const inlineSelectOptions = extraProps?.inlineSelectOptions
  const narrowedDownOptions = extraProps?.narrowedDownOptions
  const options =
    narrowedDownOptions?.length > 0 ? narrowedDownOptions : inlineSelectOptions

  if (!Array.isArray(value)) {
    return null
  }

  switch (subType) {
    case CustomFieldSubtype.SELECT:
    case CustomFieldSubtype.MULTISELECT: {
      if (!selectOptions || !Array.isArray(selectOptions)) {
        return null
      }

      const multiSelectOptions = options ?? selectOptions.map(({ id }) => id)
      const labels = getLabelsFromOptions(selectOptions)

      return (
        <>
          {inline ? (
            <InlineMultiselectPicker
              value={value}
              options={multiSelectOptions}
              labels={labels}
              onChange={onChange}
              spread={spread}
              className={className}
            />
          ) : (
            <MultiselectPicker
              value={value}
              options={selectOptions.map(option => option.id)}
              labels={labels}
              onChange={onChange}
              className={className}
            />
          )}
        </>
      )
    }
    case CustomFieldSubtype.MEMBERS:
      return (
        <RelationMemberPicker
          value={value}
          options={options}
          memberGroup={memberGroup}
          spread={spread}
          inline={inline}
          className={className}
          onChange={onChange}
        />
      )
    case CustomFieldSubtype.SPACES:
      return (
        <RelationSpacePicker
          value={value}
          options={options}
          inline={inline}
          spread={spread}
          onChange={onChange}
          className={className}
        />
      )
    case CustomFieldSubtype.TAGS:
      return (
        <RelationTagPicker
          value={value}
          options={options}
          inline={inline}
          spread={spread}
          onChange={onChange}
          className={className}
        />
      )
    default:
      return null
  }
}
