import { FC } from 'react'

import { clsx } from 'clsx'

export type TableHeaderProps = React.ComponentProps<'thead'>

export const TableHeader: FC<TableHeaderProps> = props => {
  const { children, className, ...rest } = props

  return (
    <thead className={clsx('bg-surface', className)} {...rest}>
      {children}
    </thead>
  )
}

export type TableHeaderCellProps = React.ComponentProps<'th'>

export const TableHeaderCell: FC<TableHeaderCellProps> = props => {
  const { children, className, ...rest } = props

  return (
    <th
      scope="col"
      className={clsx(
        'px-6 py-3 text-start font-medium text-content-subdued',
        className,
      )}
      {...rest}
    >
      {children}
    </th>
  )
}
