import { forwardRef } from 'react'

import { InputCopy, InputCopyProps } from '../Input/index.js'
import { FormControlInputProps } from './FormControlInput.js'
import { FormControlLayout } from './FormControlLayout.js'
import { extractLayoutProps } from './utils.js'

export type FormControlInputCopyProps = FormControlInputProps & InputCopyProps

export const FormControlInputCopy = forwardRef<
  HTMLInputElement,
  FormControlInputCopyProps
>((props, ref) => {
  const { layoutProps, inputProps } = extractLayoutProps(props)

  return (
    <FormControlLayout {...layoutProps}>
      <InputCopy ref={ref} {...inputProps} />
    </FormControlLayout>
  )
})
