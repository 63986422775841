import { CustomFieldType } from '@tribeplatform/gql-client/types'

import { dayjs } from '../../common/lib/dayjs.js'
import { Form } from '../../Form/index.js'
import { CustomFieldSubtype } from '../utils.js'

export const TextInput = ({ field, name, onChange, subtype, extraProps }) => {
  let inputType

  if (subtype === CustomFieldSubtype.URL) {
    inputType = 'url'
  } else if (subtype === CustomFieldSubtype.EMAIL) {
    inputType = 'email'
  } else if (subtype === CustomFieldSubtype.DATETIME) {
    inputType = 'datetime-local'
  } else if (field?.type === CustomFieldType.number) {
    inputType = 'number'
  } else if (field?.type === CustomFieldType.date) {
    inputType = 'date'
  }

  if (subtype === CustomFieldSubtype.DATETIME) {
    extraProps.trailingAddon = `${dayjs.tz.guess()}`
  }

  return (
    <Form.Input
      name={name}
      type={inputType || 'text'}
      onChange={e => {
        if (typeof onChange === 'function') {
          onChange(e.target.value)
        }
      }}
      className="py-1.5"
      {...extraProps}
    />
  )
}
