import { clsx } from 'clsx'

import { Button } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

import { useI18n, T } from '../../i18n/index.js'
import type { Filter } from '../types.js'
import { getOperatorName } from '../utils/operators.js'
import { isValueNotEmpty } from '../utils/values.js'
import { FilterValue } from './FilterValue.js'

interface ActivatorValueProps {
  name: string
  operatorName: string
  filter: Filter
}
const ActivatorValue = ({
  name,
  operatorName,
  filter,
}: ActivatorValueProps) => {
  return (
    <>
      <span className="font-medium">{name}</span>{' '}
      <span className="font-normal">{operatorName}</span>{' '}
      <span className="font-medium">
        <FilterValue filter={filter} />
      </span>
    </>
  )
}

interface FilterItemActivatorProps {
  filter: Filter
  open: boolean
  setOpen: (open: boolean) => void
  removeFilter: () => void
  inline?: boolean
}
export const FilterItemActivator = ({
  filter,
  open,
  setOpen,
  removeFilter,
  inline = false,
}: FilterItemActivatorProps) => {
  const intl = useI18n()
  const { name, operator, type, subType, value } = filter
  const showValue = isValueNotEmpty(value)
  const operatorName = getOperatorName(operator, type, subType, value, intl)

  return (
    <Button
      variant="tertiaryNeutral"
      onClick={() => setOpen(!open)}
      className={clsx(
        inline ? 'max-w-[15rem]' : 'w-full max-w-full',
        inline ? 'bg-surface' : 'bg-surface-subdued',
        'text-sm text-start pr-3',
        showValue && inline && 'text-interactive',
      )}
    >
      <span className="w-full inline-flex justify-between items-center gap-x-1">
        <Tooltip>
          <Tooltip.Trigger className="flex-1 truncate">
            <ActivatorValue
              name={name}
              filter={filter}
              operatorName={showValue ? operatorName : ''}
            />
          </Tooltip.Trigger>
          <Tooltip.Panel>
            {showValue && (
              <ActivatorValue
                name={name}
                filter={filter}
                operatorName={showValue ? operatorName : ''}
              />
            )}
          </Tooltip.Panel>
        </Tooltip>
        {showValue ? (
          <Tooltip>
            <Tooltip.Trigger>
              <SvgIcon
                className="w-4 h-4 hover:text-action-primary-hovered"
                onClick={e => {
                  e.stopPropagation()
                  removeFilter()
                }}
                name="close"
              />
            </Tooltip.Trigger>
            <Tooltip.Panel>
              <T id="Generics.Clear.Verb" defaultMessage="Clear" />
            </Tooltip.Panel>
          </Tooltip>
        ) : (
          <SvgIcon
            className={clsx(
              'w-4 h-4 transform transition-all ease-in-out duration-150',
              open ? '-rotate-180 rtl:rotate-180' : '',
            )}
            name="chevron-down"
          />
        )}
      </span>
    </Button>
  )
}
