import {
  addMemberSchemaFieldGQLMutation,
  archiveMemberSchemaFieldGQLMutation,
  clearNewDomainGQLMutation,
  deleteMemberSchemaFieldGQLMutation,
  domainAvailabilityGQLQuery,
  getApiUsagesGQLQuery,
  getNetworkGQLQuery,
  getApiRateLimitStatsGQLQuery,
  NetworkFields,
  newDomainStatusGQLQuery,
  reorderMemberSchemaFieldsGQLMutation,
  transferToNewDomainGQLMutation,
  unArchiveMemberSchemaFieldGQLMutation,
  updateMemberSchemaFieldGQLMutation,
  updateNetworkGQLQuery,
  updateNewDomainGQLMutation,
  probeDomainGQLQuery,
  nominateNewDomainGQLMutation,
  commitDomainTransferGQLMutation,
  cancelDomainTransferGQLMutation,
} from '../../graphql/index.js'
import {
  Action,
  ApiRateLimitStat,
  DailyApiUsage,
  DomainAvailability,
  DomainProbeResult,
  DomainTransferStatus,
  MutationAddMemberSchemaFieldArgs,
  MutationArchiveMemberSchemaFieldArgs,
  MutationDeleteMemberSchemaFieldArgs,
  MutationNominateNewDomainArgs,
  MutationReorderMemberSchemaFieldsArgs,
  MutationUpdateMemberSchemaFieldArgs,
  MutationUpdateNetworkArgs,
  MutationUpdateNewDomainArgs,
  Network,
  QueryDomainAvailabilityArgs,
  QueryNewDomainStatusArgs,
  QueryProbeDomainArgs,
} from '../../types/index.js'
import { GraphqlClient } from '../graphql.client.js'

export class NetworkClient {
  private client: GraphqlClient

  constructor(client: GraphqlClient) {
    this.client = client
  }

  async get(
    fields: NetworkFields = 'basic',
    accessToken?: string,
  ): Promise<Network> {
    type QueryResult = { network: Network }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getNetworkGQLQuery(fields),
      customToken: accessToken,
    })
    return result.network
  }

  async update(
    variables: MutationUpdateNetworkArgs,
    fields: NetworkFields = 'default',
    accessToken?: string,
  ): Promise<Network> {
    type QueryResult = { updateNetwork: Network }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: updateNetworkGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.updateNetwork
  }

  async probeDomain(
    variables: QueryProbeDomainArgs,
    accessToken?: string,
  ): Promise<DomainProbeResult> {
    type QueryResult = { probeDomain: DomainProbeResult }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: probeDomainGQLQuery(),
      variables,
      customToken: accessToken,
    })
    return result.probeDomain
  }

  async nominateNewDomain(
    variables: MutationNominateNewDomainArgs,
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { nominateNewDomain: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: nominateNewDomainGQLMutation(),
      variables,
      customToken: accessToken,
    })
    return result.nominateNewDomain
  }

  async commitDomainTransfer(accessToken?: string): Promise<Action> {
    type QueryResult = { commitDomainTransfer: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: commitDomainTransferGQLMutation(),
      customToken: accessToken,
    })
    return result.commitDomainTransfer
  }

  async cancelDomainTransfer(accessToken?: string): Promise<Action> {
    type QueryResult = { cancelDomainTransfer: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: cancelDomainTransferGQLMutation(),
      customToken: accessToken,
    })
    return result.cancelDomainTransfer
  }

  /**
   * @deprecated
   */
  async clearNewDomain(accessToken?: string): Promise<Action> {
    type QueryResult = { clearNewDomain: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: clearNewDomainGQLMutation(),
      customToken: accessToken,
    })
    return result.clearNewDomain
  }

  /**
   * @deprecated
   */
  async updateNewDomain(
    variables: MutationUpdateNewDomainArgs,
    accessToken?: string,
  ): Promise<DomainTransferStatus> {
    type QueryResult = { updateNewDomain: DomainTransferStatus }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: updateNewDomainGQLMutation(),
      variables,
      customToken: accessToken,
    })
    return result.updateNewDomain
  }

  /**
   * @deprecated
   */
  async transferToNewDomain(accessToken?: string): Promise<Action> {
    type QueryResult = { transferToNewDomain: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: transferToNewDomainGQLMutation(),
      customToken: accessToken,
    })
    return result.transferToNewDomain
  }

  /**
   * @deprecated
   */
  async domainAvailability(
    variables: QueryDomainAvailabilityArgs,
    accessToken?: string,
  ): Promise<DomainAvailability> {
    type QueryResult = { domainAvailability: DomainAvailability }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: domainAvailabilityGQLQuery(),
      variables,
      customToken: accessToken,
    })
    return result.domainAvailability
  }

  /**
   * @deprecated
   */
  async newDomainStatus(
    variables: QueryNewDomainStatusArgs,
    accessToken?: string,
  ): Promise<DomainTransferStatus> {
    type QueryResult = { newDomainStatus: DomainTransferStatus }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: newDomainStatusGQLQuery(),
      variables,
      customToken: accessToken,
    })
    return result.newDomainStatus
  }

  async apiRateLimitStats(accessToken?: string): Promise<ApiRateLimitStat[]> {
    type QueryResult = { apiRateLimitStats: ApiRateLimitStat[] }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getApiRateLimitStatsGQLQuery(),
      customToken: accessToken,
    })
    return result.apiRateLimitStats
  }

  async apiUsages(accessToken?: string): Promise<DailyApiUsage[]> {
    type QueryResult = { apiUsages: DailyApiUsage[] }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getApiUsagesGQLQuery(),
      customToken: accessToken,
    })
    return result.apiUsages
  }

  async addMemberSchemaField(
    variables: MutationAddMemberSchemaFieldArgs,
    fields: NetworkFields = 'basic',
    accessToken?: string,
  ): Promise<Network> {
    type QueryResult = { addMemberSchemaField: Network }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: addMemberSchemaFieldGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.addMemberSchemaField
  }

  async updateMemberSchemaField(
    variables: MutationUpdateMemberSchemaFieldArgs,
    fields: NetworkFields = 'basic',
    accessToken?: string,
  ): Promise<Network> {
    type QueryResult = { updateMemberSchemaField: Network }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: updateMemberSchemaFieldGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.updateMemberSchemaField
  }

  async archiveMemberSchemaField(
    variables: MutationArchiveMemberSchemaFieldArgs,
    fields: NetworkFields = 'basic',
    accessToken?: string,
  ): Promise<Network> {
    type QueryResult = { archiveMemberSchemaField: Network }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: archiveMemberSchemaFieldGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.archiveMemberSchemaField
  }

  async unArchiveMemberSchemaField(
    variables: MutationArchiveMemberSchemaFieldArgs,
    fields: NetworkFields = 'basic',
    accessToken?: string,
  ): Promise<Network> {
    type QueryResult = { unArchiveMemberSchemaField: Network }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: unArchiveMemberSchemaFieldGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.unArchiveMemberSchemaField
  }

  async deleteMemberSchemaField(
    variables: MutationDeleteMemberSchemaFieldArgs,
    fields: NetworkFields = 'basic',
    accessToken?: string,
  ): Promise<Network> {
    type QueryResult = { deleteMemberSchemaField: Network }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: deleteMemberSchemaFieldGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.deleteMemberSchemaField
  }

  async reorderMemberSchemaFields(
    variables: MutationReorderMemberSchemaFieldsArgs,
    fields: NetworkFields = 'basic',
    accessToken?: string,
  ): Promise<Network> {
    type QueryResult = { reorderMemberSchemaFields: Network }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: reorderMemberSchemaFieldsGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.reorderMemberSchemaFields
  }
}
