import { clsx } from 'clsx'

import type { TextVariant } from '@tribeplatform/react-ui-kit/Text'
import { Text } from '@tribeplatform/react-ui-kit/Text'

import type { QuickLinksSource } from '../types.js'

interface Props {
  source: QuickLinksSource
  highlighted: boolean
  stretchX: string
  titleVariant: TextVariant
}
export const TitleField = ({
  source,
  highlighted,
  stretchX,
  titleVariant,
}: Props) => {
  return (
    <div className={clsx(stretchX, 'break-words min-w-0')}>
      <Text
        as="h2"
        variant={titleVariant}
        className={clsx(
          'line-clamp-3',
          highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        )}
      >
        {source.title}
      </Text>
    </div>
  )
}
