import { Controller, useFormContext } from 'react-hook-form'

import {
  FormControl,
  FormControlTextareaProps,
} from '@tribeplatform/react-ui-kit/FormControl'

import { FormFieldProps } from './typings.js'

export function Textarea({
  name,
  validation,
  value: fieldValue,
  onChange: selectOnChange,
  defaultValue,
  ...rest
}: FormFieldProps<FormControlTextareaProps>) {
  const { control } = useFormContext()
  const maxLength: number | undefined =
    typeof validation?.maxLength === 'object' &&
    typeof validation?.maxLength?.value === 'number'
      ? validation?.maxLength?.value
      : undefined

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl.Textarea
          {...rest}
          name={name}
          invalid={!!error?.message}
          error={error?.message}
          onChange={value => {
            onChange(value)
            if (typeof selectOnChange === 'function') {
              selectOnChange(value)
            }
          }}
          value={fieldValue ?? value ?? ''}
          tertiaryLabel={
            maxLength > 0 && !!value
              ? `${value.length}/${maxLength}`
              : undefined
          }
        />
      )}
    />
  )
}
