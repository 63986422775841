import { useState } from 'react'

import { clsx } from 'clsx'

import { DateTypeOptions } from '@tribeplatform/gql-client/types'
import { Checkbox } from '@tribeplatform/react-ui-kit/Checkbox'
import { Calendar } from '@tribeplatform/react-ui-kit/DateTimePicker'
import { RadioGroup } from '@tribeplatform/react-ui-kit/RadioGroup'

import { formatDateTime } from '../../common/utils/date.js'
import { type I18nShape, T, useI18n } from '../../i18n/index.js'
import type { ValuePickerComponent } from '../types.js'

const DAY_IN_SECONDS = 60 * 24 * 60
export const relativeDatesFactory = (intl: I18nShape) => {
  const { $t } = intl

  return [
    {
      text: $t(
        {
          defaultMessage:
            '{days_count, plural, =1 {Tomorrow} =0 {Now} =-1 {Yesterday} other {{days_count, number, ::sign-never} days ago}}',
          id: 'Filter.Date.Values.XDaysAgo',
        },
        {
          days_count: -30,
        },
      ),
      value: -30 * DAY_IN_SECONDS,
    },
    {
      text: $t(
        {
          defaultMessage:
            '{days_count, plural, =1 {Tomorrow} =0 {Now} =-1 {Yesterday} other {{days_count, number, ::sign-never} days ago}}',
          id: 'Filter.Date.Values.XDaysAgo',
        },
        {
          days_count: -7,
        },
      ),
      value: -7 * DAY_IN_SECONDS,
    },
    {
      text: $t(
        {
          defaultMessage:
            '{days_count, plural, =1 {Tomorrow} =0 {Now} =-1 {Yesterday} other {{days_count, number, ::sign-never} days ago}}',
          id: 'Filter.Date.Values.XDaysAgo',
        },
        {
          days_count: -1,
        },
      ),
      value: -1 * DAY_IN_SECONDS,
    },
    {
      text: $t(
        {
          defaultMessage:
            '{days_count, plural, =1 {Tomorrow} =0 {Now} =-1 {Yesterday} other {{days_count, number, ::sign-never} days ago}}',
          id: 'Filter.Date.Values.XDaysAgo',
        },
        {
          days_count: 0,
        },
      ),
      value: 0,
    },
    {
      text: $t(
        {
          defaultMessage:
            '{days_count, plural, =1 {Tomorrow} =0 {Now} =-1 {Yesterday} other {{days_count, number, ::sign-never} days ago}}',
          id: 'Filter.Date.Values.XDaysAgo',
        },
        {
          days_count: 1,
        },
      ),
      value: DAY_IN_SECONDS,
    },
  ]
}

export const DateValuePicker: ValuePickerComponent = ({
  filter,
  onChange,
  inline,
}) => {
  const intl = useI18n()
  const { value } = filter
  const [isRelative, setIsRelative] = useState(
    typeof value === 'number' || value === undefined,
  )
  const relativeItems = relativeDatesFactory(intl)

  return (
    <div className="space-y-4">
      <Checkbox
        checked={isRelative}
        onChange={() => {
          onChange(undefined)
          setIsRelative(prev => !prev)
        }}
      >
        <p className="text-content">
          <T defaultMessage="Relative date" id="Generics.RelativeDate" />
        </p>
      </Checkbox>
      {isRelative ? (
        <RadioGroup
          value={value === undefined ? '' : value.toString()}
          onChange={newValue => onChange(Number(newValue))}
        >
          <RadioGroup.Items>
            {relativeItems.map(({ text, value }) => (
              <RadioGroup.Item
                key={text}
                value={value.toString()}
                title={text}
              />
            ))}
          </RadioGroup.Items>
        </RadioGroup>
      ) : (
        <div className={clsx('origin-top-left', !inline && 'scale-90')}>
          <Calendar
            // Force render on value change
            key={JSON.stringify(filter.value)}
            value={filter.value as string}
            onChange={date => {
              const dateType = filter?.extraProps?.typeOptions?.dateType
              if (dateType === DateTypeOptions.datetime) {
                const dateValue = formatDateTime(date as string)
                onChange(dateValue)
                return
              }

              const dateValue = formatDateTime(date as string, 'YYYY-MM-DD')
              onChange(dateValue)
            }}
          />
        </div>
      )}
    </div>
  )
}
