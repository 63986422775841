import { Link as RouterLink } from 'react-router-dom'

import { useAuthMember } from '@tribeplatform/react-sdk/hooks'
import { useSlate } from '@tribeplatform/react-slate-kit/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'

export const EmptyState = () => {
  const {
    mode,
    context: { spaceId },
  } = useSlate()
  const { isAdmin } = useAuthMember()

  if (mode !== 'live') {
    return (
      <div className="space-y-5">
        <div className="text-sm text-content-subdued">
          No members to show here. Please review the block’s settings.
        </div>
      </div>
    )
  }

  if (isAdmin) {
    return (
      <div className="space-y-5">
        <div className="text-sm text-content-subdued">
          No members to show here. Please review the block’s settings.
        </div>
        <Button
          variant="secondaryNeutral"
          fullWidth
          as={RouterLink}
          to={`/manage/customizer/spaces/${spaceId}/blocks`}
          target="_blank"
        >
          Open editor
        </Button>
      </div>
    )
  }

  return null
}
