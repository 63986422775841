import { clsx } from 'clsx'

import { Post, ReactionType } from '@tribeplatform/gql-client/types'
import { analyticsNumberFormatter } from '@tribeplatform/react-components/Analytics/utils'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

const reactionIcon: Record<keyof typeof ReactionType, JSX.Element> = {
  [ReactionType.VOTE_BASE]: <SvgIcon size="lg" name="chevron-up" />,
  [ReactionType.LIKE_BASE]: <SvgIcon size="lg" name="heart" />,
  [ReactionType.EMOJI_BASE]: <SvgIcon size="lg" name="heart" />,
}

interface Props {
  post: Post
  highlighted: boolean
  stretchX: string
}
export const ReactionCount = ({ post, highlighted, stretchX }: Props) => {
  return (
    <div
      className={clsx(
        'flex space-s-2 text-content-subdued items-center empty:hidden',
        'text-sm',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
    >
      {reactionIcon?.[post?.primaryReactionType] ?? (
        <SvgIcon size="lg" name="heart" />
      )}
      <span>{analyticsNumberFormatter(post?.reactionsCount ?? '0')}</span>
    </div>
  )
}
