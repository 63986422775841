import { PostListOrderByEnum } from '@tribeplatform/gql-client/types'

import { GenericPostListProps } from './GenericPostList.js'
import { MemberPostList } from './MemberPostList.js'
import { SpacePostList } from './SpacePostList.js'

export type PostListProps = {
  view?: GenericPostListProps['view']
  memberId?: string
  spaceId?: string
  tagId?: string
  tagSlug?: string
  hasParent?: boolean
  orderBy?: PostListOrderByEnum
}

export const PostList = ({
  memberId,
  spaceId,
  tagId,
  tagSlug,
  view,
  hasParent = false,
  orderBy = PostListOrderByEnum.publishedAt,
  ...rest
}: PostListProps) => {
  if (spaceId) {
    return (
      <SpacePostList
        // add 'key' prop to force rerender when a space changes
        // otherwise `orderBy` in SpacePostList state is kept from previous render (and previous space)
        key={spaceId}
        orderBy={orderBy}
        spaceId={spaceId}
        tagId={tagId}
        tagSlug={tagSlug}
        view={view}
        {...rest}
      />
    )
  }
  return (
    <MemberPostList
      hasParent={hasParent}
      memberId={memberId}
      view={view}
      {...rest}
    />
  )
}
