import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'
import { HtmlContent } from '@tribeplatform/react-components/HtmlContent'
import { T } from '@tribeplatform/react-components/i18n'
import { PostContent } from '@tribeplatform/react-components/Post'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { useRouter } from '@tribeplatform/react-sdk'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

interface Props {
  field: StaticField
  post: Post
  highlighted: boolean
  preview: boolean
}
export const PinnedReply = ({ field, post, highlighted, preview }: Props) => {
  const { Link } = useRouter()
  const pinnedReply = post?.pinnedReplies?.[0]

  return (
    <div
      className={clsx(
        'basis-full',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
      )}
    >
      {(pinnedReply || preview) && (
        <div className="bg-surface-positive-subdued rounded-base text-content-on-positive my-1 p-4">
          <div className="text-content font-medium mb-2 flex space-s-2 items-center">
            <SvgIcon
              className="w-5 h-5 text-content-on-positive"
              name="status-success"
            />
            {pinnedReply ? (
              <span>
                <T
                  id="Post.PostCardContent.BestReplyByText"
                  defaultMessage="Best reply by {best_reply_member_name}"
                  values={{
                    best_reply_member_name: pinnedReply?.owner?.member?.name,
                  }}
                />
              </span>
            ) : (
              <span>
                <T
                  id="Post.PostCardContent.BestReplyByText"
                  defaultMessage="Best reply by {best_reply_member_name}"
                  values={{
                    best_reply_member_name: post?.owner?.member?.name,
                  }}
                />
              </span>
            )}
          </div>
          {pinnedReply ? (
            <PostContent post={pinnedReply} context="pinnedReply" />
          ) : (
            <article className="prose break-words text-md text-content line-clamp-2">
              <HtmlContent value={field.default} />
            </article>
          )}
          <Link variant="inherit" href={pinnedReply?.relativeUrl ?? ''}>
            <T
              id="Post.PostCardContent.ViewOriginalLinkText"
              defaultMessage="View original"
            />
          </Link>
        </div>
      )}
    </div>
  )
}
