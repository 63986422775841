import { useRef, useMemo } from 'react'

interface UseLatchProps<T> {
  value: T
  trigger: boolean
  enabled?: boolean
}
type UseLatch = <T>(props: UseLatchProps<T>) => T
// useLatch is used to latch on a value until a rising edge (from false to true) happens on the trigger input
export const useLatch: UseLatch = ({ value, trigger, enabled = true }) => {
  const triggerRef = useRef(trigger)
  const latchedValueRef = useRef(value)
  const latchedValue = useMemo(() => {
    const prevTrigger = triggerRef.current
    // Detect a rising edge (from false to true)
    if ((trigger && !prevTrigger) || !enabled) {
      latchedValueRef.current = value
    }
    triggerRef.current = trigger

    return latchedValueRef.current
  }, [enabled, trigger, value])

  return latchedValue
}
