import { Component, FC, ReactNode } from 'react'

export class ErrorBoundary extends Component<
  {
    children?: ReactNode
    onError: (error, errorInfo) => void
    ErrorFallback: FC
  },
  { hasError: boolean }
> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    const { onError } = this.props
    onError(error, errorInfo)
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      return <this.props.ErrorFallback />
    }
    return children
  }
}
