import { Form } from '../../Form/index.js'

export const TextareaInput = ({ name, onChange, extraProps }) => {
  return (
    <Form.Textarea
      name={name}
      onChange={e => {
        if (typeof onChange === 'function') {
          onChange(e.target.value)
        }
      }}
      className="py-1.5"
      rows={!extraProps?.label ? 1 : 4}
      {...extraProps}
    />
  )
}
