import { nanoid } from 'nanoid'

import type { I18nShape } from '@tribeplatform/react-components/i18n'
import { LogoSize } from '@tribeplatform/react-components/Logo'
import { noop } from '@tribeplatform/react-ui-kit/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { Target } from '../HeroBanner/types.js'
import {
  ActionItemId,
  NavbarSectionItem,
  ItemAlignment,
  NavbarSection,
  SectionDetails,
  SubMenuStyle,
  MobileMenuSectionItem,
  type ActionItem,
} from './types.js'
import { convertToRawActionItems } from './utils/converters.js'

export const actionItemsFactory = ({ $t }: I18nShape): ActionItem[] => {
  return [
    {
      id: ActionItemId.mainMenu,
      label: $t({
        defaultMessage: 'Main menu',
        id: 'Navbar.ActionItems.MainMenu',
      }),
      enabled: true,
      icon: <SvgIcon name="member" />,
    },
    {
      id: ActionItemId.createEntity,
      label: $t({
        defaultMessage: 'Create post',
        id: 'Navbar.ActionItems.CreatePost',
      }),
      enabled: true,
      icon: <SvgIcon name="plus" />,
    },
    {
      id: ActionItemId.notifications,
      label: $t({
        defaultMessage: 'Notifications',
        id: 'Navbar.ActionItems.Notifications',
      }),
      enabled: true,
      icon: <SvgIcon name="bell" />,
    },
    {
      id: ActionItemId.messages,
      label: $t({
        defaultMessage: 'Messages',
        id: 'Navbar.ActionItems.Messages',
      }),
      enabled: true,
      icon: <SvgIcon name="message" />,
    },
    {
      id: ActionItemId.themeSwitch,
      label: $t({
        defaultMessage: 'Theme switch',
        id: 'Navbar.ActionItems.ThemeSwitch',
      }),
      enabled: true,
      icon: <SvgIcon name="moon" />,
    },
    {
      id: ActionItemId.languageSwitch,
      label: $t({
        defaultMessage: 'Language switch',
        id: 'Navbar.ActionItems.LanguageSwitch',
      }),
      enabled: false,
      icon: <SvgIcon name="flag" />,
    },
    {
      id: ActionItemId.search,
      label: $t({
        defaultMessage: 'Search',
        id: 'Navbar.ActionItems.Search',
      }),
      enabled: true,
      icon: <SvgIcon name="search" />,
    },
  ]
}

export const getSectionDetails = (
  id: NavbarSectionItem,
  { $t }: I18nShape,
): SectionDetails => {
  switch (id) {
    case NavbarSectionItem.logo:
      return {
        label: $t({ defaultMessage: 'Logo', id: 'Generics.Logo' }),
        icon: <SvgIcon name="image" />,
      }
    case NavbarSectionItem.search:
      return {
        label: $t({ defaultMessage: 'Search box', id: 'Generics.SearchBox' }),
        icon: <SvgIcon name="search" />,
      }
    case NavbarSectionItem.menu:
      return {
        label: $t({ defaultMessage: 'Menu', id: 'Generics.Menu' }),
        icon: <SvgIcon name="menu" />,
      }
    case NavbarSectionItem.actions:
      return {
        label: $t({ defaultMessage: 'Actions', id: 'Generics.Actions' }),
        icon: <SvgIcon name="member" />,
      }
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = id
      noop(exhaustiveCheck)
      return { label: '', icon: <></> }
    }
  }
}

export const getMobileMenuSectionDetails = (
  id: MobileMenuSectionItem,
  { $t }: I18nShape,
): SectionDetails => {
  switch (id) {
    case MobileMenuSectionItem.headerMenu:
      return {
        label: $t({
          defaultMessage: 'Header main menu',
          id: 'Navbar.MobileMenu.HeaderMainMenu',
        }),
        icon: null,
      }
    case MobileMenuSectionItem.headerSubMenu:
      return {
        label: $t({
          defaultMessage: 'Header sub menu',
          id: 'Navbar.MobileMenu.HeaderSubMenu',
        }),
        icon: null,
      }
    case MobileMenuSectionItem.sidebar:
      return {
        label: $t({ defaultMessage: 'Sidebar', id: 'Generics.Sidebar' }),
        icon: null,
      }
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = id
      noop(exhaustiveCheck)
      return { label: '', icon: <></> }
    }
  }
}

export const sectionsFactory = (intl: I18nShape): NavbarSection[] => {
  return [
    {
      id: NavbarSectionItem.logo,
      enabled: true,
      stretch: false,
      alignment: ItemAlignment.center,
      logoSize: LogoSize.medium,
    },
    {
      id: NavbarSectionItem.search,
      enabled: false,
      stretch: true,
      alignment: ItemAlignment.center,
    },
    {
      id: NavbarSectionItem.menu,
      enabled: true,
      stretch: true,
      alignment: ItemAlignment.center,
      items: [
        {
          id: nanoid(),
          name: 'Item 1',
          url: '',
          target: Target.blank,
          subMenuStyle: SubMenuStyle.dropdown,
          menuItems: [],
        },
        {
          id: nanoid(),
          name: 'Item 2',
          url: '',
          target: Target.blank,
          subMenuStyle: SubMenuStyle.dropdown,
          menuItems: [],
        },
      ],
    },
    {
      id: NavbarSectionItem.actions,
      enabled: true,
      stretch: false,
      alignment: ItemAlignment.center,
      items: convertToRawActionItems(actionItemsFactory(intl)),
    },
  ]
}
