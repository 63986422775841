import { ComponentProps, forwardRef } from 'react'

import { clsx } from 'clsx'

import { SvgIcon } from '../Icon/SvgIcon.js'
import {
  inputAriaExpandedStyles,
  inputIconStyles,
  inputStyles,
} from '../Input/Input.styles.js'
import { useFloatingContext } from '../utils/floating/index.js'
import { useSearchableSelect } from './SearchableSelectContext.js'

export type SearchableSelectButtonProps = ComponentProps<'button'> & {
  dense?: boolean
  hideIcon?: boolean
  clearable?: boolean
}

export const SearchableSelectButton = forwardRef<
  HTMLButtonElement,
  SearchableSelectButtonProps
>((props, ref) => {
  const {
    children,
    className,
    placeholder,
    dense = false,
    hideIcon = false,
    clearable = true,
    ...rest
  } = props
  const {
    inputValue,
    disabled,
    invalid,
    // isOpen,
    getComboboxProps,
    getInputProps,
    getToggleButtonProps,
    selectedItem,
    reset,
  } = useSearchableSelect()
  const { reference } = useFloatingContext()

  // TODO support compact size
  const size = 'default'

  const clearButton = (
    <button
      type="button"
      className="absolute inset-y-0 end-0 flex items-center px-2 rounded-base"
      aria-label="clear selection"
      onClick={e => {
        e.stopPropagation()
        reset()
      }}
    >
      <SvgIcon
        className={inputIconStyles({ size })}
        aria-hidden="true"
        name="close"
      />
    </button>
  )

  const toggleButton = (
    <span
      className="absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none"
      aria-label="toggle menu"
    >
      <SvgIcon
        className={inputIconStyles({ size })}
        aria-hidden="true"
        name="selector"
      />
    </span>
  )

  const onKeyDown = event => {
    if (event.key === 'Backspace' && !inputValue && clearable) {
      reset()
    }
  }

  const getIcon = () => {
    if (dense || hideIcon) {
      return null
    }
    if (selectedItem && clearable) {
      return clearButton
    }
    return toggleButton
  }

  return (
    <div ref={reference}>
      <div
        className={clsx(
          'group relative',
          'cursor-default',
          inputStyles({
            size,
            invalid,
            disabled,
          }),
          inputAriaExpandedStyles({ invalid }),
          !dense && 'ps-3 pe-10',
          className,
        )}
        {...getComboboxProps(getToggleButtonProps({ disabled, ref, ...rest }))}
      >
        <span className="block truncate">
          <div className="flex space-s-2">
            {selectedItem && !inputValue && (
              <div className="min-w-0">{children}</div>
            )}
            <input
              className={clsx(
                'focus:outline-none bg-transparent flex-1 overflow-hidden',
                'text-md',
                'placeholder:text-content-disabled placeholder:text-label-md',
              )}
              {...getInputProps({
                placeholder: selectedItem ? '' : placeholder,
                disabled,
                onKeyDown,
              })}
            />
          </div>
        </span>
        {getIcon()}
      </div>
    </div>
  )
})
