import { useMember } from '@tribeplatform/react-sdk/hooks'

import { MemberAvatar as MemberAvatarDumb } from '../common/components/index.js'

type MemberAvatarProps = { id: string }

export const MemberAvatar = ({ id }: MemberAvatarProps) => {
  const { data: member, isInitialLoading } = useMember({
    variables: { id },
    fields: { profilePicture: 'all' },
  })

  if (isInitialLoading) {
    return (
      <div role="status" className="max-w-sm animate-pulse">
        <div className="rounded-avatar bg-skeleton h-10 w-10" />
      </div>
    )
  }
  if (!member && !isInitialLoading) {
    return null
  }

  return <MemberAvatarDumb key={member.id} size="2.5x" member={member} />
}
