import { useCallback, useEffect, useState } from 'react'

import { clsx } from 'clsx'
import { Link as RouterLink } from 'react-router-dom'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { Tag } from '@tribeplatform/gql-client/types'
import { TagPillWithLink } from '@tribeplatform/react-components/common/components'
import { useQuery } from '@tribeplatform/react-components/common/hooks'
import { T } from '@tribeplatform/react-components/i18n'
import {
  useAuthMember,
  useSpace,
  useSpaceUtil,
  useUpdateSpaceHighlightedTags,
} from '@tribeplatform/react-sdk/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Link } from '@tribeplatform/react-ui-kit/Link'

import { useSlate } from '../../hooks/slate.hook.js'
import { BC } from '../../types/block.types.js'
import { SpaceTagsSelector } from './SpaceTagsSelector.js'
import { HighlightedTagsBlockProps, SelectableTag } from './types.js'

export const HighlightedTags: BC<HighlightedTagsBlockProps> = ({
  title,
  space: _space,
  spaceId: _spaceId,
  ...rest
}) => {
  const { tag: activeTagSlug, tag_id: activeTagId } = useQuery() || {}
  const { mode } = useSlate()
  const { generatePath } = useSpaceUtil()

  const {
    context: { spaceId: contextSpaceId },
  } = useSlate()
  const spaceId = _spaceId || _space?.id || contextSpaceId

  const { data: space } = useSpace({
    variables: { id: spaceId },
    useQueryOptions: {
      enabled: !!spaceId,
    },
  })

  const tagFilterEnabled = !!activeTagSlug || !!activeTagSlug

  const highlightedTags = space?.highlightedTags

  const [canGetPosts] = hasScopesPermission(space, ['getPosts'])

  const [currentTags, setCurrentTags] = useState<SelectableTag[]>(
    mapToSelectableTag(highlightedTags),
  )
  const [isEditing, setIsEditing] = useState(false)
  // Reset tags on spaceId/url change
  useEffect(() => {
    setCurrentTags(mapToSelectableTag(highlightedTags))
    setIsEditing(false)
  }, [highlightedTags])

  const { isAdmin } = useAuthMember()
  const { mutate: updateTags, isLoading: isUpdatingTags } =
    useUpdateSpaceHighlightedTags({
      useMutationOptions: {
        onSuccess: () => {
          setIsEditing(false)
        },
      },
    })

  const handleUpdateTags = useCallback(
    (tags: SelectableTag[]) => {
      updateTags({
        spaceId,
        input: {
          highlightedTags: tags.map(tag => ({
            tagId: tag.id,
          })),
        },
      })
    },
    [spaceId, updateTags],
  )

  if (!canGetPosts || highlightedTags === undefined) {
    /**
     * When highlightedTags is undefined, the space is not loaded yet.
     * When highlightedTags is null, the space is loaded but has no highlighted tags
     * When canGetPosts is false, member does not have access to get posts and tags in the space
     */
    return null
  }

  if (highlightedTags === null || currentTags.length === 0) {
    if (mode === 'edit') {
      return (
        <Card {...rest}>
          <Card.Header
            title={title}
            size="sm"
            action={
              <SvgIcon
                className="text-content-subdued w-4 h-4"
                name="eye-off"
              />
            }
          />
          <Card.Content>
            <div className="text-sm text-content-subdued">
              <T
                id="Blocks.HighlightedTags.NothingToShow"
                defaultMessage="There’s nothing to show here, so it’s automatically hidden from members."
              />
            </div>
          </Card.Content>
        </Card>
      )
    }

    if (!isAdmin) {
      return null
    }
  }

  return (
    <Card {...rest}>
      <Card.Header
        title={title}
        size="sm"
        action={
          tagFilterEnabled ? (
            <Link as={RouterLink} variant="accent" to={space?.relativeUrl}>
              <T id="Generics.Clear.Verb" defaultMessage="Clear" />
            </Link>
          ) : null
        }
      />

      {isEditing || currentTags.length > 0 ? (
        <Card.Content className={clsx(!isEditing && '-m-1')}>
          {isEditing ? (
            <SpaceTagsSelector
              spaceId={spaceId}
              tags={currentTags}
              setTags={setCurrentTags}
              createable={false}
            />
          ) : (
            currentTags.map(({ id, slug, title }) => (
              <TagPillWithLink
                className="inline-flex m-1 max-w-full break-words"
                key={id}
                active={id === activeTagId || slug === activeTagSlug}
                link={
                  id === activeTagId || slug === activeTagSlug
                    ? generatePath(space)
                    : generatePath(space, null, { tag: slug })
                }
                title={title}
              />
            ))
          )}
        </Card.Content>
      ) : (
        <div className="py-3" />
      )}
      {isAdmin && (
        <Card.Footer className="flex justify-end space-s-2">
          {isEditing ? (
            <>
              <Button
                variant="secondaryNeutral"
                disabled={isUpdatingTags}
                onClick={() => {
                  setIsEditing(false)
                  setCurrentTags(mapToSelectableTag(highlightedTags))
                }}
              >
                <T id="Generics.Cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                variant="primary"
                loading={isUpdatingTags}
                onClick={() => {
                  handleUpdateTags(currentTags)
                }}
              >
                <T id="Generics.Save" defaultMessage="Save" />
              </Button>
            </>
          ) : (
            <Button
              fullWidth
              variant="secondaryNeutral"
              onClick={() => {
                setIsEditing(true)
              }}
            >
              <T
                id="Blocks.HighlightedTags.EditHighlightedTagsButton"
                defaultMessage="Edit highlighted {TAGS}"
              />
            </Button>
          )}
        </Card.Footer>
      )}
    </Card>
  )
}

const mapToSelectableTag = (highlightedTags?: Tag[]): SelectableTag[] => {
  return highlightedTags || []
}
