import { Liquid } from 'liquidjs'

import { SlateContextProps, UnknownProps } from '../types/index.js'

const liquid = new Liquid()

const getPathValue = (obj: unknown, paths: string[]): unknown => {
  if (typeof obj === 'undefined' || paths.length === 0) {
    return obj
  }
  return getPathValue(obj[paths[0]], paths.slice(1))
}

const compileValue = (
  value: unknown,
  params: Record<string, unknown>,
): unknown => {
  if (value === null) {
    return value
  }

  switch (typeof value) {
    case 'object':
      if (Array.isArray(value)) {
        return value.map(v => compileValue(v, params))
      }
      return Object.keys(value).reduce(
        (preValue, key) => ({
          ...preValue,
          [key]: compileValue(value[key], params),
        }),
        {},
      )
    case 'string':
      if (value.startsWith('$')) {
        return getPathValue(params, value.slice(1).split('.'))
      }
      if (value.indexOf('{{') > -1) {
        try {
          return liquid.parseAndRenderSync(value, params)
        } catch {
          return value
        }
      }
      return value
    default:
      return value
  }
}

export const compileProps = (
  props: UnknownProps,
  context: SlateContextProps,
): UnknownProps => {
  if (typeof props === 'string') {
    return props
  }

  const compiledProps = {}
  Object.keys(props).forEach(key => {
    compiledProps[key] = compileValue(props[key], context)
  })
  return compiledProps
}
