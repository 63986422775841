export const processAuthResponse = async (
  apiPath: string,
  data: Record<string, string>,
) => {
  try {
    const dataType = 'application/json'
    const response = await fetch(apiPath, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': dataType,
        Accept: dataType,
      },
    })

    if (!response.ok) {
      const errorText = await response.text()
      throw errorText
    }
    const jsonResponse = await response.json()
    window.location.href = jsonResponse.location
  } catch (e) {
    const backendException = JSON.parse(e)

    // Let the form handle the error object
    throw backendException
  }
}
