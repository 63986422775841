import { clsx } from 'clsx'

import type { StaticField } from '@tribeplatform/react-components/Views'
import { useRouter } from '@tribeplatform/react-sdk'
import { Button } from '@tribeplatform/react-ui-kit/Button'

import type { QuickLinksSource } from '../types.js'

interface Props {
  source: QuickLinksSource
  field: StaticField
  highlighted: boolean
  stretchX: string
}
export const LinkField = ({ source, field, highlighted, stretchX }: Props) => {
  const { Link } = useRouter()

  return (
    <div
      className={clsx(
        'flex space-s-2 text-content-subdued items-center max-w-full',
        'text-sm',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
    >
      {field.settings?.linkStyle === 'link' && (
        <Link
          href={source.linkUrl}
          truncate
          target={field.settings?.openInNewWindow ? '_blank' : '_self'}
          variant="accent"
          onClick={e => e.stopPropagation()}
        >
          {source.linkText}
        </Link>
      )}
      {field.settings?.linkStyle === 'button' && (
        <Button
          fullWidth
          textAlign="center"
          size="md"
          as={props => <Link {...props} variant="inherit" />}
          href={source.linkUrl}
          target={field.settings?.openInNewWindow ? '_blank' : '_self'}
          onClick={e => e.stopPropagation()}
        >
          {source.linkText}
        </Button>
      )}
    </div>
  )
}
