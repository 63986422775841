import { NavbarSectionItem, type NavbarSection } from '../types.js'

const SUB_MENU_PORTAL_ROOT = 'navbar-grid-submenu-root'

export const getSubMenuPortalRootElement = (document: Document) => {
  const root = document.getElementById(SUB_MENU_PORTAL_ROOT)
  if (root) {
    return root
  }

  const newRoot = document.createElement('div')
  newRoot.setAttribute('id', SUB_MENU_PORTAL_ROOT)
  document.body.appendChild(newRoot)
  return newRoot
}

export const extractSections = (sections: NavbarSection[]) => {
  const logo = sections.find(({ id }) => id === NavbarSectionItem.logo)
  const search = sections.find(({ id }) => id === NavbarSectionItem.search)
  const menu = sections.find(({ id }) => id === NavbarSectionItem.menu)
  const actions = sections.find(({ id }) => id === NavbarSectionItem.actions)

  return {
    logo: logo.id === NavbarSectionItem.logo && logo,
    search: search.id === NavbarSectionItem.search && search,
    menu: menu.id === NavbarSectionItem.menu && menu,
    actions: actions.id === NavbarSectionItem.actions && actions,
  }
}

export const checkIfTrueCenter = (sections: NavbarSection[]) => {
  const enabledSections = sections.filter(({ enabled }) => enabled)
  if (enabledSections.length !== 3) {
    return false
  }

  const [firstSection, secondSection, thirdSection] = enabledSections
  if (secondSection.id !== NavbarSectionItem.logo) {
    return false
  }

  const isSecondSectionStretched =
    !firstSection.stretch && secondSection.stretch && !thirdSection.stretch
  const isSecondSectionCentered = secondSection.alignment === 'center'

  if (isSecondSectionStretched && isSecondSectionCentered) {
    return true
  }

  return false
}
