import {
  MassActionRequestAction as OriginalMassActionRequestAction,
  MassActionRequestContext,
  MassActionRequestStatus,
  MassActionRequest,
} from '@tribeplatform/gql-client/types'
import {
  I18nShape,
  TranslateFunctionType,
} from '@tribeplatform/react-components/i18n'
import { ButtonVariant } from '@tribeplatform/react-ui-kit/Button'

import { MassActionRequestAction } from './types.js'

type MassActionItemSettings = {
  title: string
  description: string
  buttonText: string
  buttonVariant: ButtonVariant
  buttonDestructive?: boolean
  actionText: string
  toastMessage?: string
  countConfirmationMsg?: string
}

export const getNormalizedAction: (
  action: MassActionRequestAction,
) => OriginalMassActionRequestAction = action => {
  if (action === MassActionRequestAction.UpdateOwner) {
    return MassActionRequestAction.Update
  }
  if (action === MassActionRequestAction.UpdatePublishedAt) {
    return MassActionRequestAction.Update
  }
  return action
}

export const MassActionSettingsFactory = (
  intl: I18nShape,
  context: MassActionRequestContext,
  action: MassActionRequestAction,
  affectedItemsCount: number,
): MassActionItemSettings => {
  switch (context) {
    case MassActionRequestContext.Member:
      return MemberMassActionSettingsFactory[action](intl, affectedItemsCount)

    case MassActionRequestContext.MemberInvitation:
      return MemberInvitationMassActionSettingsFactory[action](
        intl,
        affectedItemsCount,
      )

    case MassActionRequestContext.Post:
      return PostMassActionSettingsFactory[action](intl, affectedItemsCount)

    default: {
      throw new Error(`${context} is not supported`)
    }
  }
}

const MemberMassActionSettingsFactory: {
  [index in MassActionRequestAction]?: (
    intl: I18nShape,
    affectedItemsCount: number,
  ) => MassActionItemSettings
} = {
  [MassActionRequestAction.Suspend]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Are you sure you want to suspend {members_count, plural, one {# {MEMBER}} other {# {MEMBERS}}}?',
        id: 'MassAction.Suspend.TitleWithCount',
      },
      {
        members_count: affectedItemsCount,
      },
    ),
    description: $t({
      defaultMessage:
        'Suspended members won’t be able to login and engage with your community. You can revoke this suspension later on. Admins’ and moderators’ accounts will be excluded from this action.',
      id: 'MassAction.Suspend.Description',
    }),
    buttonText: $t({ defaultMessage: 'Suspend', id: 'Generics.Suspend' }),
    buttonVariant: 'primary',
    actionText: $t({ defaultMessage: 'Suspend', id: 'Generics.Suspend' }),
    countConfirmationMsg: $t({
      defaultMessage:
        'To confirm this action, enter the number of {MEMBERS} that will be suspended in the box below.',
      id: 'MassAction.Member.Suspend.CountConfirmationMsg',
    }),
  }),
  [MassActionRequestAction.Delete]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Are you sure you want to delete {members_count, plural, one {# selected {MEMBER}} other {# selected {MEMBERS}}}?',
        id: 'MassAction.Delete.TitleWithCount',
      },
      {
        members_count: affectedItemsCount,
      },
    ),
    description: $t({
      defaultMessage:
        'Member deletion is permanent and you won’t be able to revert this action later on. Your account and the community owner’s account will be excluded from this action.',
      id: 'MassAction.Delete.Description',
    }),
    buttonText: $t({ defaultMessage: 'Delete', id: 'Generics.Delete' }),
    buttonVariant: 'primary',
    buttonDestructive: true,
    actionText: $t({ defaultMessage: 'Delete', id: 'Generics.Delete' }),
    countConfirmationMsg: $t({
      defaultMessage:
        'To confirm this action, enter the number of {MEMBERS} that will be permanently deleted in the box below.',
      id: 'MassAction.Delete.InputCountLabelTitle',
    }),
  }),
  [MassActionRequestAction.AddToSpace]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Add {members_count, plural, one {# {MEMBER}} other {# {MEMBERS}}} to a {SPACE}',
        id: 'MassActions.AddToSpace.TitleWithCount',
      },
      {
        members_count: affectedItemsCount,
      },
    ),
    description: null,
    buttonText: $t({ defaultMessage: 'Add', id: 'Generics.Add' }),
    buttonVariant: 'primary',
    actionText: $t({
      defaultMessage: 'Add to {SPACE}',
      id: 'Generics.AddToSpace',
    }),
  }),
  [MassActionRequestAction.AssignBadge]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Assign badges to {members_count, plural, one {# {MEMBER}} other {# {MEMBERS}}}',
        id: 'MassAction.AssignBadge.TitleWithCount',
      },
      {
        members_count: affectedItemsCount,
      },
    ),
    description: null,
    buttonText: $t({
      defaultMessage: 'Assign badges',
      id: 'Generics.AssignBadges',
    }),
    buttonVariant: 'primary',
    actionText: $t({
      defaultMessage: 'Assign badges',
      id: 'Generics.AssignBadges',
    }),
  }),
  [MassActionRequestAction.RevokeBadge]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Revoke badges from {members_count, plural, one {# {MEMBER}} other {# {MEMBERS}}}',
        id: 'MassAction.RevokeBadge.TitleWithCount',
      },
      {
        members_count: affectedItemsCount,
      },
    ),
    description: null,
    buttonText: $t({
      defaultMessage: 'Revoke badges',
      id: 'Generics.RevokeBadges',
    }),
    buttonVariant: 'primary',
    actionText: $t({
      defaultMessage: 'Revoke badges',
      id: 'Generics.RevokeBadges',
    }),
  }),
  [MassActionRequestAction.Flag]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Are you sure you want to flag {members_count, plural, one {# selected {MEMBER}} other {# selected {MEMBERS}}}?',
        id: 'MassAction.Flag.TitleWithCount',
      },
      {
        members_count: affectedItemsCount,
      },
    ),
    description: $t({
      defaultMessage:
        'Flagging a {MEMBER} will send all their posts and replies for moderators’ approval first. Admins’ and moderators’ accounts will be excluded from this action.',
      id: 'MassAction.ResendInvite.Description',
    }),
    buttonText: $t({ defaultMessage: 'Flag', id: 'Generics.Flag.Verb' }),
    buttonVariant: 'primary',
    actionText: $t({ defaultMessage: 'Flag', id: 'Generics.Flag.Verb' }),
    countConfirmationMsg: $t({
      defaultMessage:
        'To confirm this action, enter the number of {MEMBERS} that will be flagged in the box below.',
      id: 'MassAction.Member.Flag.CountConfirmationMsg',
    }),
  }),
  [MassActionRequestAction.Unflag]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Are you sure you want to unflag {members_count, plural, one {# selected {MEMBER}} other {# selected {MEMBERS}}}?',
        id: 'MassAction.Unflag.TitleWithCount',
      },
      {
        members_count: affectedItemsCount,
      },
    ),
    description: $t({
      defaultMessage:
        'Unflagging a member will allow all their posts and replies to be published without moderators’ approval.',
      id: 'MassAction.Unflag.Description',
    }),
    buttonText: $t({ defaultMessage: 'Unflag', id: 'Generics.Unflag' }),
    buttonVariant: 'primary',
    actionText: $t({ defaultMessage: 'Unflag', id: 'Generics.Unflag' }),
  }),
}

const MemberInvitationMassActionSettingsFactory: {
  [index in MassActionRequestAction]?: (
    intl: I18nShape,
    affectedItemsCount: number,
  ) => MassActionItemSettings
} = {
  [MassActionRequestAction.Revoke]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Are you sure you want to revoke {invitations_count} selected invitations?',
        id: 'MassAction.CancelInvitation.CountedTitle',
      },
      {
        invitations_count: affectedItemsCount,
      },
    ),
    description: null,
    buttonText: $t({ defaultMessage: 'Revoke', id: 'Generics.Revoke' }),
    buttonVariant: 'primary',
    actionText: $t({ defaultMessage: 'Revoke', id: 'Generics.Revoke' }),
    toastMessage: $t({
      defaultMessage:
        'We will revoke the email invitations on the list. Already revoked or accepted invitations would be excluded.',
      id: 'MassAction.CancelInvitation.SuccessNotice',
    }),
  }),
  [MassActionRequestAction.Resend]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Are you sure you want to resend {invitations_count} selected invitations?',
        id: 'MassAction.ResendInvitation.CountedTitle',
      },
      {
        invitations_count: affectedItemsCount,
      },
    ),
    description: null,
    buttonText: $t({ defaultMessage: 'Resend', id: 'Generics.Resend' }),
    buttonVariant: 'primary',
    actionText: $t({ defaultMessage: 'Resend', id: 'Generics.Resend' }),
    toastMessage: $t({
      defaultMessage:
        'We will send a new email invitation to all the users on the list. Revoked and accepted invitations would be excluded.',
      id: 'MassAction.ResendInvitation.SuccessNotice',
    }),
  }),
}

const PostMassActionSettingsFactory: {
  [index in MassActionRequestAction]?: (
    intl: I18nShape,
    affectedItemsCount: number,
  ) => MassActionItemSettings
} = {
  [MassActionRequestAction.Move]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Move {posts_count, plural, one {# selected post} other {# selected posts}} to a new space',
        id: 'MassAction.Post.Move.TitleWithCount',
      },
      {
        posts_count: affectedItemsCount,
      },
    ),
    description: null,
    buttonText: $t({ defaultMessage: 'Confirm', id: 'Generics.Confirm' }),
    buttonVariant: 'primary',
    actionText: $t({
      defaultMessage: 'Move to space',
      id: 'Generics.MoveToSpace',
    }),
    countConfirmationMsg: $t(
      {
        defaultMessage:
          'Are you sure you want to move {posts_count, plural, one {# selected post} other {# selected posts}} to selected space?',
        id: 'MassAction.Post.Move.CountConfirmationMsg',
      },
      { posts_count: affectedItemsCount },
    ),
  }),
  [MassActionRequestAction.Hide]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Are you sure you want to hide {posts_count, plural, one {# selected post} other {# selected posts}}?',
        id: 'MassAction.Post.Hide.TitleWithCount',
      },
      {
        posts_count: affectedItemsCount,
      },
    ),
    description: $t({
      defaultMessage:
        'Hidden posts will be excluded from the feed. You can unhide the posts later on.',
      id: 'MassAction.Post.Hide.Description',
    }),
    buttonText: $t({ defaultMessage: 'Confirm', id: 'Generics.Confirm' }),
    buttonVariant: 'primary',
    actionText: $t({
      defaultMessage: 'Hide',
      id: 'Generics.Hide',
    }),
    countConfirmationMsg: $t({
      defaultMessage:
        'To confirm this action, enter the number of posts that will be hidden in the box below.',
      id: 'MassAction.Post.Hide.CountConfirmationMsg',
    }),
  }),
  [MassActionRequestAction.Delete]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Are you sure you want to delete {posts_count, plural, one {# selected post} other {# selected posts}}?',
        id: 'MassAction.Post.Delete.TitleWithCount',
      },
      {
        posts_count: affectedItemsCount,
      },
    ),
    description: $t({
      defaultMessage:
        'Post deletion is permanent and you won’t be able to revert this action later on.',
      id: 'MassAction.Post.Delete.Description',
    }),
    buttonText: $t({ defaultMessage: 'Delete', id: 'Generics.Delete' }),
    buttonVariant: 'primary',
    buttonDestructive: true,
    actionText: $t({ defaultMessage: 'Delete', id: 'Generics.Delete' }),
    countConfirmationMsg: $t({
      defaultMessage:
        'To confirm this action, enter the number of posts that will be permanently deleted in the box below.',
      id: 'MassAction.Post.Delete.CountConfirmationMsg',
    }),
  }),
  [MassActionRequestAction.AddTag]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Add tag to {posts_count, plural, one {# selected post} other {# selected posts}}',
        id: 'MassAction.Post.AddTag.TitleWithCount',
      },
      {
        posts_count: affectedItemsCount,
      },
    ),
    description: null,
    buttonText: $t({ defaultMessage: 'Confirm', id: 'Generics.Confirm' }),
    buttonVariant: 'primary',
    actionText: $t({
      defaultMessage: 'Add tag',
      id: 'Generics.AddTag',
    }),
    countConfirmationMsg: $t(
      {
        defaultMessage:
          'Are you sure you want to add these tags to {posts_count, plural, one {# selected post} other {# selected posts}}?',
        id: 'MassAction.Post.AddTag.CountConfirmationMsg',
      },
      { posts_count: affectedItemsCount },
    ),
  }),
  [MassActionRequestAction.RemoveTag]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Remove tag from {posts_count, plural, one {# selected post} other {# selected posts}}',
        id: 'MassAction.Post.RemoveTag.TitleWithCount',
      },
      {
        posts_count: affectedItemsCount,
      },
    ),
    description: null,
    buttonText: $t({ defaultMessage: 'Confirm', id: 'Generics.Confirm' }),
    buttonVariant: 'primary',
    actionText: $t({
      defaultMessage: 'Remove tag',
      id: 'Generics.RemoveTag',
    }),
    countConfirmationMsg: $t(
      {
        defaultMessage:
          'Are you sure you want to remove these tags from {posts_count, plural, one {# selected post} other {# selected posts}}?',
        id: 'MassAction.Post.RemoveTag.CountConfirmationMsg',
      },
      { posts_count: affectedItemsCount },
    ),
  }),
  [MassActionRequestAction.Lock]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Are you sure you want to lock {posts_count, plural, one {# selected post} other {# selected posts}}?',
        id: 'MassAction.Post.Lock.TitleWithCount',
      },
      {
        posts_count: affectedItemsCount,
      },
    ),
    description: $t({
      defaultMessage:
        'Members cannot comment or react to locked posts. You can unlock the posts later on.',
      id: 'MassAction.Post.Lock.Description',
    }),
    buttonText: $t({ defaultMessage: 'Confirm', id: 'Generics.Confirm' }),
    buttonVariant: 'primary',
    actionText: $t({ defaultMessage: 'Lock', id: 'Generics.Lock' }),
    countConfirmationMsg: $t({
      defaultMessage:
        'To confirm this action, enter the number of posts that will be locked in the box below.',
      id: 'MassAction.Post.Lock.CountConfirmationMsg',
    }),
  }),
  [MassActionRequestAction.UpdateOwner]: ({ $t }, affectedItemsCount) => ({
    title: $t(
      {
        defaultMessage:
          'Change the author of {posts_count, plural, one {# selected post} other {# selected posts}}',
        id: 'MassAction.Post.UpdateOwner.TitleWithCount',
      },
      {
        posts_count: affectedItemsCount,
      },
    ),
    description: null,
    buttonText: $t({ defaultMessage: 'Confirm', id: 'Generics.Confirm' }),
    buttonVariant: 'primary',
    actionText: $t({
      defaultMessage: 'Change author',
      id: 'Generics.ChangeAuthor',
    }),
    countConfirmationMsg: $t(
      {
        defaultMessage:
          'Are you sure you want to change the author of {posts_count, plural, one {# selected post} other {# selected posts}}?',
        id: 'MassAction.Post.UpdateOwner.CountConfirmationMsg',
      },
      { posts_count: affectedItemsCount },
    ),
  }),
  [MassActionRequestAction.UpdatePublishedAt]: (
    { $t },
    affectedItemsCount,
  ) => ({
    title: $t(
      {
        defaultMessage:
          'Change the publish time of {posts_count, plural, one {# selected post} other {# selected posts}}',
        id: 'MassAction.Post.UpdatePublishedAt.TitleWithCount',
      },
      {
        posts_count: affectedItemsCount,
      },
    ),
    description: null,
    buttonText: $t({ defaultMessage: 'Confirm', id: 'Generics.Confirm' }),
    buttonVariant: 'primary',
    actionText: $t({
      defaultMessage: 'Change publish time',
      id: 'Generics.ChangePublishTime',
    }),
    countConfirmationMsg: $t(
      {
        defaultMessage:
          'Are you sure you want to change the publish time of {posts_count, plural, one {# selected post} other {# selected posts}}?',
        id: 'MassAction.Post.UpdatePublishedAt.CountConfirmationMsg',
      },
      { posts_count: affectedItemsCount },
    ),
  }),
}

export const MassActionAlertStatus = (massActionRequest: MassActionRequest) => {
  if (
    massActionRequest?.status === MassActionRequestStatus.Initialized ||
    massActionRequest?.status === MassActionRequestStatus.InProgress
  ) {
    return 'info'
  }
  if (massActionRequest?.status === MassActionRequestStatus.Failed) {
    return 'error'
  }
  if (massActionRequest?.failedCount === 0) {
    return 'success'
  }
  if (massActionRequest?.failedCount === massActionRequest?.entitiesCount) {
    return 'error'
  }
  if (massActionRequest?.failedCount > 0) {
    return 'warning'
  }
  return 'success'
}

export const MassActionTitleStatus = (
  $t: TranslateFunctionType,
  massActionRequest: MassActionRequest,
) => {
  switch (massActionRequest?.status) {
    case MassActionRequestStatus.Initialized:
      return $t({
        defaultMessage: 'Action has been initialized',
        id: 'MassAction.Status.Initialized',
      })
    case MassActionRequestStatus.InProgress:
      return $t({
        defaultMessage: 'Action is in progress',
        id: 'MassAction.Status.InProgress',
      })
    case MassActionRequestStatus.Done:
      return $t({
        defaultMessage: 'Action has been completed',
        id: 'MassAction.Status.Done',
      })
    case MassActionRequestStatus.Failed:
    default:
      return $t({
        defaultMessage: 'Action has failed',
        id: 'MassAction.Status.Failed',
      })
  }
}
