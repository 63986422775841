import { Collection } from '@tribeplatform/gql-client/types'
import { T } from '@tribeplatform/react-components/i18n'
import { SpaceImage } from '@tribeplatform/react-components/Space'
import { useRouter, useRouterPath } from '@tribeplatform/react-sdk'
import { useSpaceUtil } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { NavVertical } from '@tribeplatform/react-ui-kit/Sidebar'

import { useSlate } from '../../hooks/slate.hook.js'
import { useMobileMenu } from '../Navbar/providers/MobileMenuProvider.js'

export const CollectionMenuHeading = ({
  collections,
  canCreateSpace,
  ...rest
}: {
  collections: Collection[]
  canCreateSpace: boolean
}) => {
  const { Link } = useRouter()
  const { close } = useMobileMenu()
  const { matchPath } = useSpaceUtil()
  const pathname = useRouterPath()
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'

  return (
    <>
      <NavVertical {...rest}>
        <div className="space-y-2">
          {collections.map(collection => {
            const hasSpaces = collection?.spaces?.totalCount

            if (!hasSpaces && !canCreateSpace) {
              return null
            }
            return (
              <NavVertical.Group key={collection.id}>
                <NavVertical.GroupHeader>
                  {collection.name}
                </NavVertical.GroupHeader>
                <NavVertical.Group className="pb-3">
                  {collection?.spaces?.edges?.map(({ node: space }) => {
                    const isSpaceActive = matchPath(space, pathname)
                    return (
                      <NavVertical.Item
                        as={Link}
                        key={space.id}
                        href={space?.relativeUrl}
                        active={!!isSpaceActive}
                        leadingAddon={
                          <div className="me-3 -ms-1">
                            <SpaceImage space={space} size="lg" />
                          </div>
                        }
                        onClick={e => {
                          if (isPreviewMode) {
                            e.preventDefault()
                            return
                          }

                          close()
                        }}
                      >
                        {space.name}
                      </NavVertical.Item>
                    )
                  })}
                  {!hasSpaces ? (
                    <NavVertical.Item
                      as={Link}
                      href={`/manage/customizer/create-space?collectionId=${collection.id}`}
                      onClick={e => {
                        if (isPreviewMode) {
                          e.preventDefault()
                          return
                        }

                        close()
                      }}
                    >
                      <span className="w-6 h-6 -ms-1 me-3 flex justify-center items-center">
                        <SvgIcon className="w-5 h-5" name="package-plus" />
                      </span>
                      <span className="truncate">
                        <T
                          id="Generics.AddSpace"
                          defaultMessage="Add {SPACE}"
                        />
                      </span>
                    </NavVertical.Item>
                  ) : null}
                </NavVertical.Group>
              </NavVertical.Group>
            )
          })}
        </div>
      </NavVertical>
    </>
  )
}
