import type {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query'

import { T } from '@tribeplatform/react-components/i18n'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

interface Props {
  fetchNextPage?: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult>
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
}
export const ShowMoreButton = ({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}: Props) => {
  if (!hasNextPage) {
    return null
  }

  return (
    <Button
      variant="secondaryNeutral"
      onClick={() => fetchNextPage()}
      className="flex-shrink-0"
      trailingIcon={
        isFetchingNextPage ? (
          <SvgIcon className="animate-spin" size="lg" name="spinner" />
        ) : null
      }
      fullWidth
    >
      <T defaultMessage="Show more" id="Generics.ShowMore" />
    </Button>
  )
}
