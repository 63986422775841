export const colors = {
  'action-primary': '#00843b',
  'action-primary-hovered': '#006223',
  'action-primary-pressed': '#004b16',
  'action-primary-subdued': '#f4f9f5',
  'action-primary-subdued-hovered': '#ecf6ee',
  'action-primary-subdued-pressed': '#d6ecda',
  'action-primary-disabled': '#f7f7f7',
  'action-neutral': '#FFFFFF',
  'action-neutral-hovered': '#f7f7f7',
  'action-neutral-pressed': '#f3f3f3',
  'action-neutral-subdued': '#f3f3f3',
  'action-neutral-subdued-hovered': '#e7e7e7',
  'action-neutral-subdued-pressed': '#d4d4d4',
  'action-neutral-disabled': '#FFFFFF',
  'action-destructive': '#d83028',
  'action-destructive-hovered': '#a71613',
  'action-destructive-pressed': '#840a08',
  'action-destructive-subdued': '#fff5f3',
  'action-destructive-subdued-hovered': '#fff0ec',
  'action-destructive-subdued-pressed': '#ffe0da',
  'action-destructive-disabled': '#f3f3f3',
  background: '#f3f3f3',
  'background-hovered': '#e7e7e7',
  'background-pressed': '#d4d4d4',
  'background-selected': '#d4d4d4',
  'background-backdrop': '#00000080',
  'background-overlay': '#ffffff80',
  'background-divider': '#a1a1a1',
  content: '#060606',
  'content-subdued': '#535353',
  'content-muted': '#727272',
  'content-hovered': '#171717',
  'content-pressed': '#535353',
  'content-disabled': '#a1a1a1',
  'content-on-positive': '#004b22',
  'content-on-attention': '#663200',
  'content-on-highlight': '#04255b',
  'content-on-negative': '#840a08',
  'content-on-negative-hovered': '#580202',
  'content-on-negative-pressed': '#380101',
  'content-primary': '#00300a',
  'content-on-primary': '#FFFFFF',
  'content-on-inverse': '#f7f7f7',
  'content-on-destructive': '#FFFFFF',
  'content-on-interactive': '#FFFFFF',
  'content-on-background': '#060606',
  'content-on-background-subdued': '#535353',
  'content-on-background-hovered': '#171717',
  'content-on-background-pressed': '#535353',
  'content-on-background-disabled': '#a1a1a1',
  interactive: '#006223',
  'interactive-hovered': '#004b16',
  'interactive-pressed': '#00300a',
  'interactive-disabled': '#f7f7f7',
  'interactive-destructive': '#d83028',
  'interactive-destructive-hovered': '#a71613',
  'interactive-destructive-pressed': '#840a08',
  'interactive-destructive-disabled': '#f7f7f7',
  topbar: '#FFFFFF',
  'topbar-subdued': '#f3f3f3',
  'content-on-topbar': '#060606',
  'line-on-topbar': '#d4d4d4',
  'line-on-topbar-pressed': '#727272',
  link: '#00612f',
  'link-hovered': '#004b22',
  'link-pressed': '#002f13',
  line: '#d4d4d4',
  'line-subdued': '#e7e7e7',
  'line-hovered': '#a1a1a1',
  'line-pressed': '#727272',
  'line-selected': '#535353',
  'line-disabled': '#e7e7e7',
  'line-transparent': '#0000001a',
  'line-positive': '#00612f',
  'line-positive-subdued': '#90e6af',
  'line-highlight': '#1650ab',
  'line-highlight-subdued': '#b4d8ff',
  'line-negative': '#a71613',
  'line-negative-subdued': '#ffc7bc',
  'line-negative-disabled': '#ffe0da',
  'line-attention': '#844200',
  'line-attention-subdued': '#ffcb89',
  focused: '#006223',
  skeleton: '#e7e7e7',
  surface: '#FFFFFF',
  'surface-subdued': '#f3f3f3',
  'surface-hovered': '#f3f3f3',
  'surface-subdued-hovered': '#e7e7e7',
  'surface-pressed': '#e7e7e7',
  'surface-disabled': '#f7f7f7',
  'surface-selected': '#f4f9f5',
  'surface-selected-hovered': '#ecf6ee',
  'surface-selected-pressed': '#d6ecda',
  'surface-attention': '#ffe2bb',
  'surface-attention-subdued': '#fff7ed',
  'surface-negative': '#ffe0da',
  'surface-negative-subdued': '#fff5f3',
  'surface-negative-subdued-hovered': '#fff0ec',
  'surface-negative-subdued-pressed': '#ffe0da',
  'surface-positive': '#c1f2d0',
  'surface-positive-subdued': '#f2fbf5',
  'surface-highlight': '#d3e8ff',
  'surface-highlight-subdued': '#f4f9ff',
  'surface-highlight-subdued-hovered': '#e9f3ff',
  'surface-highlight-subdued-pressed': '#d3e8ff',
  'surface-neutral': '#e7e7e7',
  'surface-neutral-subdued': '#f7f7f7',
  'surface-inverse': '#171717',
  'decorative-surface-1': '#FFC96B',
  'decorative-surface-1-subdued': '#FFE4B5',
  'decorative-content-1': '#7E5700',
  'decorative-surface-2': '#FFC4B0',
  'decorative-surface-2-subdued': '#FFE1D7',
  'decorative-content-2': '#AF294E',
  'decorative-surface-3': '#92E6B5',
  'decorative-surface-3-subdued': '#C9F3DA',
  'decorative-content-3': '#006D41',
  'decorative-surface-4': '#91E0D6',
  'decorative-surface-4-subdued': '#C8EFEB',
  'decorative-content-4': '#006A68',
  'decorative-surface-5': '#FDC9D0',
  'decorative-surface-5-subdued': '#FEE4E7',
  'decorative-content-5': '#AE2B4C',
  'decorative-surface-6': '#7CD4FD',
  'decorative-surface-6-subdued': '#BDE9FE',
  'decorative-content-6': '#065986',
  'decorative-surface-7': '#BDB4FE',
  'decorative-surface-7-subdued': '#DED9FF',
  'decorative-content-7': '#4A1FB8',
}
