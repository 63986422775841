import type { Tag } from '@tribeplatform/gql-client/types'
import { useSpaceTags } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'

import { EMPTY_VALUE_PLACEHOLDER } from '../utils/values.js'

interface Props {
  value: string[]
}
export const TagRenderer = ({ value }: Props) => {
  const { data, isInitialLoading } = useSpaceTags({
    variables: { ids: value, limit: value.length },
    useInfiniteQueryOptions: {
      enabled: value.length > 0,
    },
  })
  const { nodes: tags } = simplifyPaginatedResult<Tag>(data)
  const tagNames = tags?.map(tag => tag.title)

  if (isInitialLoading) {
    return EMPTY_VALUE_PLACEHOLDER
  }

  return <>{tagNames.join(', ')}</>
}
