import { useState } from 'react'

import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import {
  Member,
  Post,
  PostReactionParticipant,
} from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { useAuthMember } from '@tribeplatform/react-sdk/hooks'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

import { useCMS } from '../CMS/hooks/useCMS.js'
import { sanitizePostType } from '../CMS/utils/utils.js'
import { useStaticIntl, T, useI18n } from '../i18n/index.js'
import { I18nShape } from '../i18n/types.js'
import { REPLIES_SECTION_HASH } from '../Reply/constants.js'
import { ReactionRenderer } from './ReactionRenderer.js'
import { ReactionsModal } from './ReactionsModal.js'
import { ReplyCount } from './ReplyCount.js'

const MAX_REACTION_NAMES = 10

export const getListFromParticipants =
  (intl: I18nShape) =>
  (
    nodes: PostReactionParticipant[],
    totalCount: number,
    authUser?: Member,
  ): string => {
    // TODO this fn needs refactor / optimization
    const { $t } = intl
    if (typeof nodes !== 'object') {
      return ''
    }
    const moreCount = totalCount - MAX_REACTION_NAMES
    const namesList = nodes.slice(0, MAX_REACTION_NAMES).map(node => {
      const { participant } = node
      return authUser?.id === participant?.id
        ? $t({
            id: 'Generics.you',
            defaultMessage: 'you',
          })
        : participant?.name
    })

    if (moreCount > 0) {
      return $t(
        {
          id: 'Post.PostInfoBar.ReactionParticipantsLongText',
          defaultMessage: '{name_list} and {more_count} more',
        },
        {
          name_list: intl.formatList(namesList, {
            type: 'conjunction',
            style: 'short',
          }),
          more_count: moreCount,
        },
      )
    }
    return intl.formatList(namesList, {
      type: 'conjunction',
      style: 'long',
    })
  }

interface Props {
  post: Post
  showReactionCount?: boolean
  showReplyCount?: boolean
  showFollowCount?: boolean
  className?: string
  isPostPage: boolean
}
export const PostInfoBar = ({
  post,
  showReactionCount = true,
  showReplyCount = true,
  showFollowCount = true,
  className,
  isPostPage,
}: Props) => {
  const { $t } = useI18n()
  const { Link } = useRouter()
  const postTypeId = post?.postTypeId
  const participantsSummary = useStaticIntl(getListFromParticipants)
  const { postTypes } = useCMS()
  const maybePostType = postTypes?.find(({ id }) => id === postTypeId)
  const sanitizedPostType = sanitizePostType(maybePostType)
  const customReactions = sanitizedPostType?.customReactions
  const { reactions, totalRepliesCount, subscribersCount } = post
  const { data: authMember } = useAuthMember()

  const [reactionsModal, setReactionsModal] = useState(false)
  const showSubscribersCount =
    typeof subscribersCount === 'number' && subscribersCount > 1

  if (!totalRepliesCount && !showSubscribersCount && !post?.reactionsCount) {
    return null
  }

  if (!postTypeId) {
    return null
  }

  return (
    <>
      <div
        className={twMerge(clsx('flex items-center h-7 space-s-3', className))}
      >
        {showReactionCount && !!post?.reactionsCount && (
          <div className="flex-grow min-w-0">
            <div className="flex items-center max-w-full">
              {reactions?.length > 0 && (
                <>
                  {reactions
                    ?.sort((a, b) => b?.count - a?.count)
                    ?.map(reaction => {
                      return (
                        <Tooltip key={reaction.reaction}>
                          <Tooltip.Trigger
                            onClick={() => setReactionsModal(true)}
                          >
                            <ReactionRenderer
                              reaction={reaction}
                              customReactions={customReactions}
                              label={$t({
                                defaultMessage: 'Reactions',
                                id: 'Generics.Reactions',
                              })}
                            />
                          </Tooltip.Trigger>
                          {typeof reaction.participants?.nodes === 'object' && (
                            <Tooltip.Panel>
                              {participantsSummary(
                                reaction.participants?.nodes,
                                reaction.count,
                                authMember,
                              )}
                            </Tooltip.Panel>
                          )}
                        </Tooltip>
                      )
                    })}
                  <div className="mx-3 text-content-subdued text-sm">
                    {post?.reactionsCount}
                  </div>
                </>
              )}
              {reactions?.length === 0 && <div>&nbsp;</div>}
              {reactions?.length === undefined && (
                <div className="flex items-center animate-pulse">
                  <button
                    type="button"
                    className="rounded-full p-1 bg-skeleton w-9"
                  >
                    &nbsp;
                  </button>
                  <div className="mx-3 text-content-subdued text-sm">
                    {post?.reactionsCount}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {showSubscribersCount && showFollowCount ? (
          <div className="text-content-subdued hidden sm:block shrink-0 whitespace-nowrap text-sm">
            <T
              defaultMessage="{followers_count, plural, =1 {# follower} other {# followers}}"
              id="Follower.Count"
              values={{
                followers_count: subscribersCount - 1,
              }}
            />
          </div>
        ) : null}
        {totalRepliesCount && showReplyCount ? (
          <div className="text-content-subdued shrink-0 whitespace-nowrap text-sm">
            {isPostPage ? (
              <ReplyCount repliesCount={totalRepliesCount} />
            ) : (
              <Link
                variant="neutral"
                href={
                  post?.relativeUrl
                    ? `${post.relativeUrl}${REPLIES_SECTION_HASH}`
                    : ''
                }
              >
                <ReplyCount repliesCount={totalRepliesCount} />
              </Link>
            )}
          </div>
        ) : null}
      </div>
      {reactionsModal && (
        <ReactionsModal
          open={reactionsModal}
          onClose={() => setReactionsModal(false)}
          reactions={reactions}
          customReactions={customReactions}
          postId={post?.id}
        />
      )}
    </>
  )
}
