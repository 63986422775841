import { useFormContext } from 'react-hook-form'

import { legacySizeMapper } from '@tribeplatform/react-ui-kit/Avatar'
import type { FormControlSelectProps } from '@tribeplatform/react-ui-kit/FormControl'
import { FormControlSearchableSelect } from '@tribeplatform/react-ui-kit/FormControl/FormControlSearchableSelect'

import { useSlate } from '../../../hooks/slate.hook.js'
import { CallbackExtraContext } from '../../../types/callback.types.js'
import { MarkdownBlock } from '../../blocks.js'
import { SelectBlockProps } from '../types.js'

type StandAloneSearchableSelectProps = SelectBlockProps & {
  name: string
  items: FormControlSelectProps['items']
  isFetching: boolean
  setSearch: React.Dispatch<string>
}
export const StandAloneSearchableSelect = ({
  value: defaultValue,
  items,
  helperText: markdownHelperText,
  name,
  callbackId,
  isFetching,
  setSearch,
  hideClearIcon,
  avatarRounded = 'md',
  avatarSize = 'xs',
  ...rest
}: StandAloneSearchableSelectProps) => {
  const {
    extraContext: {
      slate: { callback },
    },
  } = useSlate<CallbackExtraContext>()

  const formContext = useFormContext()
  const { watch } = formContext || {}

  const value = watch(name, defaultValue)

  return (
    <FormControlSearchableSelect
      {...rest}
      name={name}
      loading={isFetching}
      value={value}
      onChange={value => {
        if (callbackId) {
          callback(callbackId, {
            data: {
              value,
            },
          })
        }
      }}
      onInputChange={setSearch}
      avatarProps={{
        rounded: avatarRounded,
        size: legacySizeMapper(avatarSize),
      }}
      options={items.map(item => ({
        value: item?.value?.toString(),
        key: item?.value?.toString(),
        text: item.text,
        ...(item?.avatar ? { avatar: item?.avatar } : {}),
      }))}
      hideClearIcon={hideClearIcon}
      helperText={
        typeof markdownHelperText === 'string' ? (
          <MarkdownBlock text={markdownHelperText} />
        ) : (
          markdownHelperText
        )
      }
    />
  )
}
