import { createContext, useContext } from 'react'

import type { ScrollLockManager } from '../utils/scroll-lock-manager.js'

export const ScrollLockManagerContext = createContext<
  ScrollLockManager | undefined
>(undefined)

export function useScrollLockManager() {
  const scrollLockManager = useContext(ScrollLockManagerContext)

  if (!scrollLockManager) {
    throw new Error('No ScrollLockManager was provided.')
  }
  return scrollLockManager
}
