import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { InputProps } from './Input.js'

export const InputContext = createContext<{
  props: InputProps
  setProps: (props: InputProps) => void
}>({
  props: {},
  setProps: () => {},
})

export function InputProvider({
  children,
  props,
}: {
  children: (props: InputProps) => React.ReactNode
  props?: InputProps
}) {
  const [contextProps, setContextProps] = useState(props || {})

  useEffect(() => {
    setContextProps(prevContextProps => ({ ...prevContextProps, ...props }))
  }, [props])

  const updateContextProps = useCallback((props: InputProps) => {
    setContextProps(prevContextProps => ({ ...prevContextProps, ...props }))
  }, [])

  return (
    <InputContext.Provider
      value={{ props: contextProps, setProps: updateContextProps }}
    >
      {children(contextProps)}
    </InputContext.Provider>
  )
}

export const useInputContext = () => {
  return useContext(InputContext)
}
