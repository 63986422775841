import type { RawSlateUpdatesDto } from '../dtos/raw-slate-updates.dto.js'
import type { RawSlateDto } from '../dtos/raw-slate.dto.js'
import type { SlateRestrictionsDto } from '../dtos/slate-restrictions.dto.js'

export class SlateRestrictionsError extends Error {
  updates: RawSlateUpdatesDto

  restrictions: SlateRestrictionsDto

  details?: unknown

  constructor(
    restrictions: SlateRestrictionsDto,
    updates: RawSlateUpdatesDto,
    details?: unknown,
  ) {
    super(`The update violates the slate restrictions`)
    this.name = 'SlateRestrictionsError'
    this.updates = updates
    this.restrictions = restrictions
    this.details = details
  }
}

const areChildrenMoved = (
  children: string[],
  newChildren: string[],
): boolean => {
  let cIdx = 0
  let nIdx = 0
  while (cIdx < children.length && nIdx < newChildren.length) {
    if (children[cIdx] === newChildren[nIdx]) {
      cIdx += 1
      nIdx += 1
    } else {
      cIdx += 1
    }
  }
  return nIdx !== newChildren.length
}

export const validateRawSlateUpdates = (
  slate: RawSlateDto,
  updates: RawSlateUpdatesDto,
): void => {
  const {
    lockedRootBlock = false,
    nonEditableBlocks = [],
    lockedChildrenBlocks = [],
    nonRemovableBlocks = [],
  } = slate.restrictions || {}
  const { rootBlock, updatedBlocks = [], removedBlocks = [] } = updates
  const blocksByIds = slate.blocks.reduce(
    (preValue, block) => ({ ...preValue, [block.id]: block }),
    {},
  )

  const violatesRootBlockRestriction = lockedRootBlock && rootBlock
  const violatesEditBlockRestriction = nonEditableBlocks.find(id =>
    updatedBlocks.find(
      updatedBlock =>
        updatedBlock.id === id && (updatedBlock.props || updatedBlock.output),
    ),
  )
  const violatesLockedChildrenRestrictions = lockedChildrenBlocks.find(id =>
    updatedBlocks.find(
      updatedBlock =>
        updatedBlock.id === id &&
        updatedBlock.children &&
        areChildrenMoved(blocksByIds[id].children, updatedBlock.children),
    ),
  )
  const violatesRemoveBlockRestriction = nonRemovableBlocks.find(id =>
    removedBlocks.find(removedBlock => removedBlock === id),
  )

  if (
    violatesRootBlockRestriction ||
    violatesEditBlockRestriction ||
    violatesLockedChildrenRestrictions ||
    violatesRemoveBlockRestriction
  ) {
    throw new SlateRestrictionsError(slate.restrictions, updates, {
      violatesRootBlockRestriction,
      violatesEditBlockRestriction,
      violatesLockedChildrenRestrictions,
      violatesRemoveBlockRestriction,
    })
  }
}
