import { getExtraProperty } from '@tribeplatform/gql-client/lib'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

import { DISABLE_HOTKEYS_KEY } from '../constants/settings.js'

export const useHotkeysSettings = () => {
  const { data: network } = useNetwork()
  const disabled = !!getExtraProperty(
    network.extraProperties,
    DISABLE_HOTKEYS_KEY,
  )

  return {
    disabled,
  }
}
