import { ModalInteraction } from './ModalInteraction.js'
import { ToastInteraction } from './ToastInteraction.js'

export const InteractionComponents = () => {
  return (
    <>
      <ToastInteraction />
      <ModalInteraction />
    </>
  )
}
