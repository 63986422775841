import { gql } from 'graphql-tag'

import {
  PaginatedAppInstallationFields,
  PaginatedAppInstallationGQLFields,
} from '../../types/fields.generated.js'
import { AppFields, appGQLFields } from './app.fields.js'
import {
  AppInstallationFields,
  appInstallationGQLFields,
} from './appInstallation.fields.js'
import { CallbackFields, callbackGQLFields } from './callback.fields.js'
import {
  DynamicBlockFields,
  dynamicBlockGQLFields,
} from './dynamicBlock.fields.js'
import { LimitedTokenFields } from './limitedToken.fields.js'

// eslint-disable-next-line unused-imports/no-unused-vars
export function getLimitedTokenGQLQuery(fields: LimitedTokenFields) {
  return gql`
    query limitedToken(
      $networkId: String!
      $context: PermissionContext!
      $entityId: String!
      $impersonateMemberId: String
    ) {
      limitedToken(
        networkId: $networkId
        context: $context
        entityId: $entityId
        impersonateMemberId: $impersonateMemberId
      ) {
        accessToken
        gatewayUrl
      }
    }
  `
}

export const appGQLQuery = (fields: AppFields) => gql`
  query App($id: ID, $slug: String, $clientId: String, $redirectUri: String) {
    app(id: $id, slug: $slug, clientId: $clientId, redirectUri: $redirectUri) {
      ${appGQLFields(fields)}
    }
  }
`

export const appsGQLQuery = (fields: AppFields) => gql`
  query Apps(
    $after: String
    $before: String
    $limit: Int!
    $offset: Int
    $reverse: Boolean
    $status: StoreItemStatus
  ) {
    apps(
      after: $after
      before: $before
      limit: $limit
      offset: $offset
      reverse: $reverse
      status: $status
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ${appGQLFields(fields)}
        }
      }
      nodes {
        ${appGQLFields(fields)}
      }
    }
  }
`

export const getAppNetworkSettingsGQLQuery = () => gql`
  query GetAppNetworkSettings($appId: ID!) {
    getAppNetworkSettings(appId: $appId)
  }
`

export const updateAppNetworkSettingsGQLMutation = () => gql`
  mutation UpdateAppNetworkSettings($appId: ID!, $settings: String!) {
    updateAppNetworkSettings(appId: $appId, settings: $settings) {
      data
      status
    }
  }
`

export const getAppSpaceSettingsGQLQuery = () => gql`
  query GetAppSpaceSettings($appId: ID!, $spaceId: ID!) {
    getAppSpaceSettings(appId: $appId, spaceId: $spaceId)
  }
`

export const installAppGQLMutation = (fields: AppInstallationFields) => gql`
  mutation InstallApp($appId: ID!, $input: InstallAppInput!) {
    installApp(appId: $appId, input: $input) {
      ${appInstallationGQLFields(fields)}
    }
  }
`

export const uninstallAppGQLMutation = (fields: AppInstallationFields) => gql`
  mutation UninstallApp($appInstallationId: ID!, $reason: String) {
    uninstallApp(appInstallationId: $appInstallationId, reason: $reason) {
      ${appInstallationGQLFields(fields)}
    }
  }
`

export const appInstallationsGQLQuery = (fields: AppInstallationFields) => gql`
  query NetworkAppInstallations(
    $after: String
    $before: String
    $limit: Int!
    $offset: Int
    $reverse: Boolean
    $status: AppInstallationStatus
  ) {
    getNetworkAppInstallations(
      after: $after
      before: $before
      limit: $limit
      offset: $offset
      reverse: $reverse
      status: $status
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ${appInstallationGQLFields(fields)}
        }
      }
      nodes {
        ${appInstallationGQLFields(fields)}
      }
    }
  }
`

export const dynamicBlockGQLQuery = (fields: DynamicBlockFields) => gql`
  query DynamicBlock($blockId: ID!, $context: PermissionContext!, $entityId: ID) {
    dynamicBlock(blockId: $blockId) {
      ${dynamicBlockGQLFields(fields)}
    }
  }
`

export const callbackGQLMutation = (fields: CallbackFields) => gql`
  mutation Callback($appId: ID!, $callbackId: ID!, $context: PermissionContext!, $entityId: ID, $dynamicBlockId: ID, $inputs: String) {
    callback(
      appId: $appId
      callbackId: $callbackId
      context: $context
      entityId: $entityId
      dynamicBlockId: $dynamicBlockId
      inputs: $inputs
    ) {
      ${callbackGQLFields(fields)}
    }
  }
`

export const authMemberAppInstallationGQLQuery = (
  fields: AppInstallationFields,
) => gql`
  query GetAuthMemberAppInstallation($appId: String!) {
    authMemberAppInstallation(appId: $appId) {
      ${appInstallationGQLFields(fields)}
    }
  }
`
export const authMemberAppInstallationsGQLQuery = (
  fields: PaginatedAppInstallationFields,
) => gql`
  query GetAuthMemberAppInstallations($appId: String!) {
    authMemberAppInstallations(appId: $appId) {
      ${PaginatedAppInstallationGQLFields(fields)}
    }
  }
`
