import { FC, ReactElement, ReactNode } from 'react'

import { clsx } from 'clsx'

import { CloseButton } from '../Button/CloseButton.js'
import { IconButton } from '../Button/index.js'
import { SvgIcon } from '../Icon/SvgIcon.js'
import { Text } from '../Text/index.js'
import { useModalContext } from './ModalContext.js'
import { labelledById } from './utils.js'

export interface ModalHeaderProps
  extends Omit<React.ComponentProps<'div'>, 'title'> {
  title?: string | ReactNode | ReactElement
  description?: ReactNode | ReactElement
  withBorder?: boolean
}

export const ModalHeader: FC<ModalHeaderProps> = props => {
  const { id, onClose, zenMode, showZenModeBtn, showCloseBtn, toggleZenMode } =
    useModalContext()

  const {
    title,
    description,
    withBorder = false,
    className,
    children,
    ...rest
  } = props

  return (
    <div
      className={clsx(
        'px-4 py-5 sm:p-6',
        'flex justify-between',
        !withBorder && 'pb-0 sm:pb-0',
        withBorder && 'border-b border-line-subdued',
        'items-start',
        className,
      )}
      {...rest}
    >
      {children || (
        <>
          <div className="text-start sm:mt-0 sm:text-start min-w-0 break-words">
            <Text
              variant="headingXs"
              as="h3"
              id={labelledById(id)}
              className="pe-10"
            >
              {title}
            </Text>
            {description && (
              <Text variant="bodySm" color="subdued" className="mt-1">
                {description}
              </Text>
            )}
          </div>
        </>
      )}
      {(showZenModeBtn || showCloseBtn) && (
        <div className="flex gap-1 items-center absolute top-4 right-4">
          {showZenModeBtn && (
            <IconButton
              variant="tertiaryNeutral"
              size="lg"
              icon={
                !zenMode ? (
                  <SvgIcon name="fullscreen" />
                ) : (
                  <SvgIcon name="fullscreen-exit" />
                )
              }
              accessibilityLabel="Zen Mode"
              onClick={toggleZenMode}
            />
          )}
          {showCloseBtn && <CloseButton size="lg" onClick={onClose} />}
        </div>
      )}
    </div>
  )
}
