import { NetworkStatus } from '@tribeplatform/gql-client/types'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

import { MadeWithBettermode } from './MadeWithBettermode.js'
import { UnpublishedBanner } from './UnpublishedBanner.js'
import { WillUnpublishBanner } from './WillUnpublishBanner.js'

export const BettermodeFooter = () => {
  const { data: network } = useNetwork()
  const unpublished = network?.status === NetworkStatus.unpublished
  const willUnpublishAt = network?.willUnpublishAt

  return (
    <div className="flex flex-col gap-3">
      <div className="flex-shrink flex justify-end items-end me-3">
        <MadeWithBettermode />
      </div>
      <div className="flex-grow">
        {unpublished && <UnpublishedBanner />}
        {!unpublished && !!willUnpublishAt && <WillUnpublishBanner />}
      </div>
    </div>
  )
}
