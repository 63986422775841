import { useRef } from 'react'

import type { ElementRects } from '@floating-ui/core'
import { flip, offset, shift, size } from '@floating-ui/react'

export const useFloatingMiddleware = () => {
  const referenceSizeData = useRef<ElementRects['reference']>()
  const availableHeight = useRef<number>()

  const middleware = [
    offset(4),
    shift(),
    flip({
      fallbackStrategy: 'initialPlacement',
    }),
    size({
      apply({ rects, availableHeight: height }) {
        availableHeight.current = height
        referenceSizeData.current = rects.reference
      },
    }),
  ]

  return {
    middleware,
    maxWidth: referenceSizeData.current?.width,
    availableHeight: availableHeight.current,
  }
}
