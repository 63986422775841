import { ReactNode, useState } from 'react'

import { Button } from '@tribeplatform/react-ui-kit/Button'
import { FormControl } from '@tribeplatform/react-ui-kit/FormControl'
import { Input } from '@tribeplatform/react-ui-kit/Input'

import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'

export type ImageLinkPickerProps = {
  placeholder?: string
  helper?: ReactNode
  onChange: (url: string) => void
}

export const ImageLinkPicker: React.FC<ImageLinkPickerProps> = ({
  placeholder,
  helper,
  onChange,
}) => {
  const { $t } = useI18n()
  const [link, setLink] = useState<string>('')

  placeholder =
    placeholder ??
    $t({
      id: 'Picker.LinkPicker.DefaultPlaceholder',
      defaultMessage: 'Paste an image link...',
    })

  helper =
    helper ??
    $t({
      id: 'Picker.LinkPicker.DefaultHelperText',
      defaultMessage: 'Works with any image from the web',
    })

  return (
    <div className="flex flex-col items-center justify-center max-w-full mx-auto">
      <Input
        value={link}
        onChange={e => setLink(e?.target?.value)}
        placeholder={placeholder}
        name="link"
        wrapperClassName="w-full"
      />
      <Button
        className="mt-3"
        onClick={() => {
          onChange(link)
        }}
      >
        <T id="Generics.Submit" defaultMessage="Submit" />
      </Button>
      <FormControl.HelperText className="mt-3">{helper}</FormControl.HelperText>
    </div>
  )
}
