import { gql } from 'graphql-tag'

import { SpaceRoleFields, spaceRoleGQLFields } from './spaceRole.fields.js'

export function getSpaceRolesGQLQuery(fields: SpaceRoleFields) {
  return gql`
    query GetSpaceRoles(
      $spaceId: ID!
      $orderBy: SpaceRoleListOrderByEnum
      $reverse: Boolean
    ) {
      spaceRoles(spaceId: $spaceId, orderBy: $orderBy, reverse: $reverse) {
        ${spaceRoleGQLFields(fields)}
      }
    }
  `
}

export const updateMemberSpaceRoleGQLMutation = () => {
  return gql`
    mutation UpdateMemberSpaceRole(
      $input: UpdateSpaceMemberRoleInput!
      $memberId: ID!
      $spaceId: ID!
    ) {
      updateMemberSpaceRole(
        input: $input
        memberId: $memberId
        spaceId: $spaceId
      ) {
        status
      }
    }
  `
}
