import { ReactNode } from 'react'

import { clsx } from 'clsx'
import { useCombobox } from 'downshift'

import { FloatingContextProvider } from '../utils/floating/index.js'
import { SearchableSelectButton } from './SearchableSelectButton.js'
import { SearchableSelectContext } from './SearchableSelectContext.js'
import {
  SearchableSelectItem,
  SearchableSelectItems,
  SearchableSelectItemsEmpty,
} from './SearchableSelectItems.js'

export type SearchableSelectProps<Item> = {
  value: Item
  options: Item[]
  onChange: (newValue: Item) => void
  className?: string
  children: ReactNode
  disabled?: boolean
  invalid?: boolean
  onInputChange?: (newValue: string) => void
  id?: string
}

/**
 * The SearchableSelect component is a component that allows users pick a value from predefined options.
 */

export const SearchableSelect = <Item,>(props: SearchableSelectProps<Item>) => {
  const {
    children,
    value,
    options,
    onChange,
    className,
    disabled = false,
    invalid = false,
    onInputChange,
    ...rest
  } = props

  const dsCombobox = useCombobox<Item>({
    selectedItem: value,
    items: options,
    itemToString: () => '',
    onSelectedItemChange: changes => {
      onChange(changes.selectedItem)
    },
    onInputValueChange: changes => {
      onInputChange?.(changes.inputValue)
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: '',
          }
        default:
          return changes
      }
    },
    scrollIntoView: node => {
      requestAnimationFrame(() => {
        if (node == null) {
          return
        }
        node.scrollIntoView({
          behavior: 'auto',
          block: 'nearest',
          inline: 'start',
        })
      })
    },
  })

  return (
    <div className={clsx('relative isolate', className)} {...rest}>
      <SearchableSelectContext.Provider
        value={{
          ...dsCombobox,
          disabled,
          invalid,
        }}
      >
        <FloatingContextProvider placement="bottom-start">
          {children}
        </FloatingContextProvider>
      </SearchableSelectContext.Provider>
    </div>
  )
}

SearchableSelect.Button = SearchableSelectButton
SearchableSelect.Items = SearchableSelectItems
SearchableSelect.ItemsEmpty = SearchableSelectItemsEmpty
SearchableSelect.Item = SearchableSelectItem
