import { Multiselect } from '@tribeplatform/react-ui-kit/Multiselect'

import { useI18n } from '../../i18n/index.js'

interface Props {
  value: string[]
  options: string[]
  labels?: Record<string, string>
  onChange: (newValues: string[]) => void
  className?: string
}
export const MultiselectPicker = ({
  value,
  options,
  labels,
  onChange,
  className,
}: Props) => {
  const { $t } = useI18n()

  return (
    <Multiselect
      value={value}
      options={options}
      onChange={newValues => onChange(newValues)}
      className={className}
    >
      <Multiselect.Button
        placeholder={$t({
          defaultMessage: 'Select options',
          id: 'Filters.Values.Select.Placeholder',
        })}
        hideInput={value?.length > 0}
      >
        {value.map((option, index) => (
          <Multiselect.SelectedItem key={option} value={option} index={index}>
            {labels?.[option] ?? option}
          </Multiselect.SelectedItem>
        ))}
      </Multiselect.Button>
      <Multiselect.Items noWrapper>
        {options.map((option, index) => (
          <Multiselect.Item key={option} value={option} index={index}>
            {labels?.[option] ?? option}
          </Multiselect.Item>
        ))}
      </Multiselect.Items>
    </Multiselect>
  )
}
