import { useRouter } from '@tribeplatform/react-sdk'

import { Target } from '../../HeroBanner/types.js'
import { ItemAlignment, type MenuItem as TMenuItem } from '../types.js'
import { MenuItemWithDropDown } from './MenuItemWithDropDown.js'

interface Props extends TMenuItem {
  parentAlignment: ItemAlignment
}
export const SubNavItem = ({
  parentAlignment,
  id,
  name,
  url,
  target,
  menuItems,
}: Props) => {
  const { Link } = useRouter()
  const hasSubMenu = menuItems && menuItems.length > 0

  if (!hasSubMenu) {
    return (
      <Link
        variant="neutral"
        href={url}
        target={target === Target.blank ? '_blank' : '_self'}
        className="shrink-0 text-sm"
        tabIndex={0}
      >
        {name}
      </Link>
    )
  }

  return (
    <MenuItemWithDropDown
      id={id}
      name={name}
      menuItems={menuItems}
      parentAlignment={parentAlignment}
      itemSize="sm"
    />
  )
}
