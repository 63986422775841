import { useState } from 'react'

import { nanoid } from 'nanoid'

import { ClientError, ClientFileError } from '@tribeplatform/gql-client/lib'
import { useCreateFiles } from '@tribeplatform/react-sdk/hooks'

import { mergeArrayByKey } from '../../common/utils/array.utils.js'
import { useUploadQueue } from '../../Providers/UploadQueueProvider.js'
import { UploadableFile } from './types.js'

export const useHandleUploadableFiles = ({
  defaultValue,
  multiple,
  onChange,
  onUploadStart,
  onError,
}: {
  defaultValue: UploadableFile[]
  multiple: boolean
  onChange: (files: UploadableFile[]) => void
  onUploadStart?: () => void
  onError: (error: ClientFileError) => void
}) => {
  const { addToUploadQueue, removeFromUploadQueue } = useUploadQueue()
  const { mutateAsync: createFiles, isLoading } = useCreateFiles({
    useMutationOptions: {
      onMutate: () => {
        onUploadStart?.()
      },
      onError,
    },
  })

  const [files, setFiles] = useState<UploadableFile[]>(defaultValue)

  const onFileUpload = async (newFiles: File[]) => {
    // If not file, do nothing
    if (!newFiles?.length) {
      return null
    }
    if (!multiple) {
      newFiles = newFiles.slice(0, 1)
    }

    const tempFileData = newFiles.map(
      ({ name, size }) =>
        ({
          id: `${name}-${size}`,
          url: '',
          downloadUrl: '',
          size,
          extension: name.slice(name.lastIndexOf('.') + 1).toLowerCase(),
          name,
          isLoading: true,
        } as UploadableFile),
    )

    setFiles(files =>
      multiple ? mergeArrayByKey(files, tempFileData, 'name') : tempFileData,
    )

    /**
     * Since we are using a single mutation to upload multiple files
     * and the request for all files will fail or resolve together,
     * we will assign a signle upload queue item to all files.
     */
    const batchUploadQueueItem = {
      id: nanoid(),
    }
    addToUploadQueue(batchUploadQueueItem)

    try {
      const uploadedFiles = await createFiles(
        newFiles.map(file => {
          const { type, name, size } = file

          return {
            file,
            contentType: type,
            name,
            extension: name.slice(name.lastIndexOf('.') + 1).toLowerCase(),
            size,
          }
        }),
      )

      const updatedFiles = multiple
        ? mergeArrayByKey(files, uploadedFiles, 'name')
        : uploadedFiles
      setFiles(updatedFiles)
      onChange(updatedFiles)
    } catch (error) {
      // Revert back
      const updatedFiles = [...files.filter(a => !a.isLoading)]
      setFiles(updatedFiles)
      onChange(updatedFiles)

      const message: string =
        (error as ClientError)?.response?.errors
          ?.map(e => e.message)
          ?.join('\n') || error.message

      onError({
        fileName: '',
        message,
      })
    } finally {
      removeFromUploadQueue(batchUploadQueueItem)
    }
  }

  const onRemove = (file: UploadableFile) => {
    const updatedFiles = [...files.filter(it => it.id !== file.id)]
    setFiles(updatedFiles)
    onChange(updatedFiles)
  }

  return {
    files,
    isLoading,
    onFileUpload,
    onRemove,
  }
}
