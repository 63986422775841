import { Card } from '@tribeplatform/react-ui-kit/Card'

import { BC } from '../../types/index.js'
import { CardHeaderProps } from './types.js'

export const CardHeaderBlock: BC<CardHeaderProps> = ({
  title,
  description,
  size,
  withBorder,
}) => {
  return (
    <Card.Header
      title={title}
      description={description}
      size={size}
      withBorder={withBorder}
    />
  )
}
