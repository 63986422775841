import { Controller, useFormContext } from 'react-hook-form'

import { FormControl } from '@tribeplatform/react-ui-kit/FormControl'

import { PostPicker, PostPickerProps } from '../EntityPicker/PostPicker.js'
import { FormFieldProps, Optional } from './typings.js'

// TODO: form pickers should not be controlled by 'value' and 'onChange' props
export function FormPostPicker({
  name,
  label,
  helperText,
  validation,
  value: fieldValue,
  onChange: fieldOnChange,
  ...rest
}: FormFieldProps<Optional<PostPickerProps, 'value'>>) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl>
          {label ? (
            <FormControl.Label htmlFor={name}>{label}</FormControl.Label>
          ) : null}
          <div>
            <PostPicker
              {...rest}
              {...rest}
              value={fieldValue ?? value}
              onChange={value => {
                onChange(value)
                if (typeof fieldOnChange === 'function') {
                  fieldOnChange(value)
                }
              }}
            />
            <FormControl.Meta
              helperText={helperText}
              error={error?.message}
              invalid={!!error?.message}
            />
          </div>
        </FormControl>
      )}
    />
  )
}
