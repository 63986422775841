import {
  useEffect,
  useState,
  useCallback,
  useMemo,
  ReactNode,
  startTransition,
} from 'react'

import { debounce as _debounce } from '@bettermode/common/debounce'

import { useEventListener } from '../utils/useEventListener.js'
import { isNavigationBarCollapsed } from './breakpoints.js'
import { MediaQueryContext } from './MediaQueryContext.js'

export interface MediaQueryProviderProps {
  children?: ReactNode
}
/**
 * @deprecated
 * Instead of js variable we should use pure css to display responsive content, otherwise we will have problems with SSR
 */
export const MediaQueryProvider = function MediaQueryProvider({
  children,
}: MediaQueryProviderProps) {
  const [isNavigationCollapsed, setIsNavigationCollapsed] = useState(
    isNavigationBarCollapsed().matches,
  )

  const handleResize = useCallback(
    _debounce(() => {
      setIsNavigationCollapsed(isNavigationBarCollapsed().matches)
      // if (isNavigationCollapsed !== navigationBarCollapsed().matches) {
      // setIsNavigationCollapsed(!isNavigationCollapsed)
      // }
    }, 40),
    [],
  )

  useEffect(() => {
    startTransition(() => {
      setIsNavigationCollapsed(isNavigationBarCollapsed().matches)
    })
  }, [])

  const context = useMemo(
    () => ({ isNavigationCollapsed }),
    [isNavigationCollapsed],
  )

  useEventListener('resize', handleResize)

  return (
    <MediaQueryContext.Provider value={context}>
      {children}
    </MediaQueryContext.Provider>
  )
}
