import { ReactNode } from 'react'

import {
  toast as hotToast,
  ToastOptions as _ToastOptions,
} from 'react-hot-toast/headless'

import { runIfFn } from '../utils/index.js'
import { ToastComponent, ToastContentProps } from './ToastComponent.js'
import { ToastStatus } from './ToastContext.js'

interface ToastRenderProps {
  onClose: () => void
}

export type ToastOptions = ToastContentProps & {
  status?: ToastStatus
  custom?: ReactNode | ((props: ToastRenderProps) => ReactNode)
  duration?: _ToastOptions['duration']
}

export const toast = (options: ToastOptions) => {
  const { title, description, icon, status, custom, duration } = options

  if (custom) {
    return hotToast.custom(
      t => (
        <ToastComponent onClose={() => hotToast.dismiss(t.id)} status={status}>
          {runIfFn(custom, {
            onClose: () => hotToast.dismiss(t.id),
          })}
        </ToastComponent>
      ),
      {
        duration,
      },
    )
  }

  return hotToast.custom(
    t => (
      <ToastComponent onClose={() => hotToast.dismiss(t.id)} status={status}>
        <ToastComponent.Content
          title={title}
          description={description}
          icon={icon}
        />
      </ToastComponent>
    ),
    {
      duration,
    },
  )
}
