import React, { Suspense } from 'react'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { Space } from '@tribeplatform/gql-client/types'
import type { IconSize } from '@tribeplatform/react-ui-kit/Icon'

import { EntityImage } from '../common/components/EntityImage.js'
import { SystemSpacesIcons } from '../common/constants/icons.js'
import { SpaceImagePicker } from './SpaceImagePicker.js'

interface Props {
  space: Space
  size?: IconSize
  readOnly?: boolean
  as?: 'div' | 'span'
}
export const SpaceImage = ({
  space,
  size = 'md',
  readOnly = true,
  as,
}: Props) => {
  if (!space) {
    return null
  }

  const [canUpdateSpace] = hasScopesPermission(space, ['updateSpace'])
  const defaultIcon = SystemSpacesIcons[space.slug]

  return (
    <div className="relative text-content-on-background">
      <EntityImage
        entity={space}
        size={size}
        defaultIcon={defaultIcon}
        as={as}
      />
      {canUpdateSpace && !readOnly && (
        <Suspense>
          <div className="absolute inset-0">
            <SpaceImagePicker space={space} size={size} />
          </div>
        </Suspense>
      )}
    </div>
  )
}
