import { useRouter } from '@tribeplatform/react-sdk'
import { Badge } from '@tribeplatform/react-ui-kit/Badge'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'

import { MemberAvatar } from '../common/components/index.js'
import { MemberBadgeLocation, MemberBadges } from '../MemberBadge/index.js'

export const MemberCard = ({
  member,
  fields = [],
  actions = null,
  extraBadge = null,
}) => {
  const { Link } = useRouter()
  if (!fields.length) {
    fields = ['profilePicture', 'name', 'badge', 'tagline']
  }
  return (
    <Card className="h-full relative group">
      {actions ? (
        <Dropdown
          className="absolute end-4 top-4 opacity-50 group-hover:opacity-100"
          placement="bottom-end"
        >
          <Dropdown.ButtonMinimal />
          <Dropdown.Items>{actions}</Dropdown.Items>
        </Dropdown>
      ) : null}
      <Link href={member?.relativeUrl} variant="inherit" className="h-full">
        <div className="flex-1 flex flex-col space-y-3 mb-2 p-6 pt-4 items-center text-center">
          {fields.map(field => {
            switch (field) {
              case 'profilePicture':
                return (
                  <MemberAvatar
                    key={field}
                    className="mb-3"
                    size="8x"
                    member={member}
                  />
                )
              case 'name':
                return (
                  <h4
                    key={field}
                    className="text-lg font-medium text-content max-w-full truncate mb-2"
                    translate="no"
                  >
                    {member?.name}
                  </h4>
                )

              case 'badge':
                if (extraBadge) {
                  return (
                    <Badge key={field} truncate>
                      {extraBadge}
                    </Badge>
                  )
                }
                return (
                  <MemberBadges
                    key={field}
                    member={member}
                    size="sm"
                    location={MemberBadgeLocation.MembersList}
                    singleLine={false}
                    className="justify-center max-w-full"
                  />
                )

              case 'tagline':
                return (
                  <div
                    key={field}
                    className="text-content-subdued text-sm max-w-full line-clamp-2"
                  >
                    {member?.tagline}
                  </div>
                )
              default:
                return null
            }
          })}
        </div>
      </Link>
    </Card>
  )
}
