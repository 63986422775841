import { clsx } from 'clsx'

import { useI18n } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { useColorScheme } from '@tribeplatform/react-ui-kit/ThemeProvider'
import { TopBarButton } from '@tribeplatform/react-ui-kit/TopBar'

interface Props {
  showOnAllScreens?: boolean
}
export const ThemeSwitchButton = ({ showOnAllScreens }: Props) => {
  const { $t } = useI18n()
  const { colorSchemeMode, setMode } = useColorScheme()

  if (!colorSchemeMode) {
    return (
      <div
        className={clsx(
          'h-full flex justify-center items-center shrink-0',
          !showOnAllScreens && 'hidden lg:flex',
        )}
      >
        <TopBarButton
          className="dark:hidden"
          onClick={() => setMode('dark')}
          aria-label={$t({
            id: 'Generics.DarkMode',
            defaultMessage: 'Dark mode',
          })}
          icon={<SvgIcon name="moon" />}
        />
        <TopBarButton
          className="hidden dark:inline"
          onClick={() => setMode('light')}
          aria-label={$t({
            id: 'Generics.LightMode',
            defaultMessage: 'Light mode',
          })}
          icon={<SvgIcon name="sun" />}
        />
      </div>
    )
  }

  if (colorSchemeMode === 'dark') {
    return (
      <div
        className={clsx(
          'h-full flex justify-center items-center shrink-0',
          !showOnAllScreens && 'hidden lg:flex',
        )}
      >
        <TopBarButton
          onClick={() => setMode('light')}
          aria-label={$t({
            id: 'Generics.LightMode',
            defaultMessage: 'Light mode',
          })}
          icon={<SvgIcon name="sun" />}
        />
      </div>
    )
  }

  return (
    <div
      className={clsx(
        'h-full flex justify-center items-center shrink-0',
        !showOnAllScreens && 'hidden lg:flex',
      )}
    >
      <TopBarButton
        onClick={() => setMode('dark')}
        aria-label={$t({
          id: 'Generics.DarkMode',
          defaultMessage: 'Dark mode',
        })}
        icon={<SvgIcon name="moon" />}
      />
    </div>
  )
}
