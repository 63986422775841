import { Post } from '@tribeplatform/gql-client/types'

import { MemberAvatar, Time } from '../../common/components/index.js'
import { dayjs } from '../../common/lib/dayjs.js'
import { T } from '../../i18n/index.js'

type PostMediaModalTitleProps = {
  post: Post
}
export const PostMediaModalTitle = ({ post }: PostMediaModalTitleProps) => {
  if (!post || !post.owner?.member) {
    return null
  }

  const author = post.owner.member
  const postDate = post.publishedAt || post.createdAt
  return (
    <div className="overflow-hidden pe-3 hidden sm:flex">
      <div className="flex space-s-2 min-w-0">
        <div className="shrink-0">
          <MemberAvatar member={author} size="2.5x" />
        </div>
        <div className="flex flex-col">
          <p className="text-xs">
            <span className="text-md">{post.title}</span>
          </p>
          <p className="text-xs">
            <T
              defaultMessage="{relative_date} by {author_name}"
              id="Generics.RelativeDateByAuthor"
              values={{
                relative_date: (
                  <strong>
                    <Time dateTime={postDate}>{dayjs(postDate).fromNow()}</Time>
                  </strong>
                ),
                author_name: <strong>{author.name}</strong>,
              }}
            />
          </p>
        </div>
      </div>
    </div>
  )
}
