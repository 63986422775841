import type { Member } from '@tribeplatform/gql-client/types'
import type { StaticField, View } from '@tribeplatform/react-components/Views'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { List } from '@tribeplatform/react-ui-kit/Layout'

import { MembersListItem } from './MembersListItem.js'

interface Props {
  members: Member[]
  fields: StaticField[]
  showDivider: View['showDivider']
  activeFieldId?: string
  showMoreButton: JSX.Element
}
export const MembersList = ({
  members,
  fields,
  showDivider = true,
  activeFieldId,
  showMoreButton,
}: Props) => {
  return (
    <Card padding="md">
      <Card.Content>
        <List direction="vertical" divider={showDivider} spacing="md">
          {members.map(member => (
            <List.Item key={member.id}>
              <MembersListItem
                member={member}
                fields={fields.filter(
                  field =>
                    field.enabled &&
                    !(field.styles && field.styles.indexOf('list') === -1),
                )}
                activeFieldId={activeFieldId}
              />
            </List.Item>
          ))}
          {!!showMoreButton && <List.Item>{showMoreButton}</List.Item>}
        </List>
      </Card.Content>
    </Card>
  )
}
