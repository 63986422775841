import EventEmitter from 'eventemitter3'

import { TribeClient } from '@tribeplatform/react-sdk'
import { AlertType, ConfirmType } from '@tribeplatform/react-ui-kit/Dialog'
import { ToastOptions } from '@tribeplatform/react-ui-kit/Toast'

export enum TribeEmitterEvent {
  PAGE_VIEW = 'pageView',
  Event = 'event',
  /**
   * An event for when the library is fully ready to be used for UI components, etc
   */
  READY = 'ready',
  /**
   * An event for when the library is loaded the event emitters and listeners
   */
  ON_LOAD = 'onload',
}

export type BmEventEmitter = {
  on: (event: string, callback: (...args) => void) => void
  off: (event: string, callback: (...args) => void) => void
  toast: (props: ToastOptions) => void
  confirm: ConfirmType
  alert: AlertType
  client: TribeClient
  // prompt: PromptType
  emitter: EventEmitter
  /**
   * When its truthy it means that the library is loaded the event emitters and listeners
   */
  _loaded: boolean
}

declare global {
  interface Window {
    /**
     * @deprecated use window.BM
     */
    Tribe: BmEventEmitter
    BM: BmEventEmitter
    prevPage?: string
  }
}

export const TribeEmitter =
  typeof window !== 'undefined'
    ? {
        on: (event, fn) => window.BM?.emitter?.on(event, fn),
        once: (event, fn) => window.BM?.emitter?.once(event, fn),
        off: (event, fn) => window.BM?.emitter?.off(event, fn),
        emit: (event, payload) => window.BM?.emitter?.emit(event, payload),
        clearListeners: (eventName: TribeEmitterEvent) =>
          window.BM?.emitter?.removeAllListeners(eventName),
      }
    : undefined

Object.freeze(TribeEmitter)
