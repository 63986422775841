import type { StaticField, View } from '@tribeplatform/react-components/Views'

import type { QuickLinksSource } from '../types.js'
import { QuickLinksCard } from './QuickLinksCard.js'
import { QuickLinksCarousel } from './QuickLinksCarousel.js'
import { QuickLinksGallery } from './QuickLinksGallery.js'
import { QuickLinksList } from './QuickLinksList.js'

interface Props {
  style: View['style']
  sources: QuickLinksSource[]
  fields: StaticField[]
  gallerySize?: View['gallerySize']
  showDivider?: View['showDivider']
  activeFieldId?: string
  preview: boolean
}
export const GenericQuickLinks = ({
  style,
  sources,
  fields,
  gallerySize,
  showDivider,
  activeFieldId,
  preview,
}: Props) => {
  switch (style) {
    case 'gallery':
      return (
        <QuickLinksGallery
          sources={sources}
          fields={fields}
          gallerySize={gallerySize}
          activeFieldId={activeFieldId}
          preview={preview}
        />
      )
    case 'list':
      return (
        <QuickLinksList
          sources={sources}
          fields={fields}
          showDivider={showDivider}
          activeFieldId={activeFieldId}
          preview={preview}
        />
      )
    case 'card':
      return (
        <QuickLinksCard
          sources={sources}
          fields={fields}
          activeFieldId={activeFieldId}
          preview={preview}
        />
      )
    case 'carousel':
      return (
        <QuickLinksCarousel
          sources={sources}
          fields={fields}
          gallerySize={gallerySize}
          activeFieldId={activeFieldId}
          preview={preview}
        />
      )
    default:
      return null
  }
}
