import { I18nShape } from '@tribeplatform/react-components/i18n'
import { AlertProps } from '@tribeplatform/react-ui-kit/Alert'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { AlertBlock as Component } from './Block.js'

export const RegisteredAlertBlock = (
  _intl: I18nShape,
): RegisteredBlock<AlertProps> => ({
  recommendedName: 'Alert',
  Component,
  config: {
    type: BlockType.BuildingBlock,
  },
})
