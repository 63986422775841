import { clsx } from 'clsx'

import { Post, PostStatus } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { MemberAvatar } from '../common/components/index.js'
import { Time } from '../common/components/Time.js'
import { dayjs } from '../common/lib/dayjs.js'
import { T } from '../i18n/components/T.js'
import { MemberBadgeLocation } from '../MemberBadge/MemberBadge.js'
import { MemberBadges } from '../MemberBadge/MemberBadges.js'

export const PostMemberBar = ({
  post,
  showSpace = true,
}: {
  showSpace?: boolean
  post: Partial<Post>
}) => {
  const { Link } = useRouter()
  const member = post?.owner?.member

  const postDate = post?.publishedAt || post?.createdAt

  return (
    <div
      className={clsx(
        'flex items-center space-s-4 min-w-0 grow post-member-bar',
        !member && 'animate-pulse',
      )}
    >
      <div className="shrink-0">
        <MemberAvatar member={member} size="3x" clickable />
      </div>
      <div className="min-w-0 grow">
        <div className="flex items-center flex-wrap gap-x-2 gap-y-0.5 text-content max-w-full">
          <Link
            href={member?.relativeUrl}
            variant="inherit"
            className="font-medium truncate block focus-visible:z-10"
            translate="no"
            title={member?.name}
          >
            {member?.name ? (
              <>{member?.name}</>
            ) : (
              <div className="h-4 w-32 bg-surface-pressed rounded-full"></div>
            )}
          </Link>
          <MemberBadges
            member={member}
            size="sm"
            location={MemberBadgeLocation.PostMemberBar}
          />
        </div>
        <div className="text-sm text-content-subdued mt-0.5 flex max-w-full">
          {post?.status === PostStatus.DELETED && (
            <span>
              <span className="text-action-destructive inline-flex items-center">
                <span>
                  <T defaultMessage="Deleted" id="Generics.Deleted" />
                </span>
                <SvgIcon className="w-4 h-4 ms-0.5" name="trash" />
              </span>
              {/* eslint-disable-next-line react/jsx-no-literals */}
              <span>&nbsp;·&nbsp;</span>
            </span>
          )}
          {showSpace && post.space?.slug ? (
            <>
              <Link
                variant="inherit"
                href={post.relativeUrl}
                className="shrink-0"
                truncate
              >
                <Time dateTime={postDate}>{dayjs(postDate).fromNow()}</Time>
              </Link>
              {/* eslint-disable-next-line react/jsx-no-literals */}
              <span>&nbsp;·&nbsp;</span>
              <Link
                variant="inherit"
                href={post.space.relativeUrl}
                truncate
                title={post.space.name}
              >
                <T
                  defaultMessage="Posted in {space}"
                  id="PostMemberBar.PostSpace"
                  values={{
                    space: post.space.name,
                  }}
                />
              </Link>
            </>
          ) : (
            <>
              <Link variant="inherit" href={post.relativeUrl} truncate>
                <Time dateTime={postDate}>
                  {member?.name ? (
                    <>{dayjs(postDate).fromNow()}</>
                  ) : (
                    <div className="h-3 w-16 bg-surface-pressed rounded-full"></div>
                  )}
                </Time>
              </Link>
              {member?.tagline ? (
                // eslint-disable-next-line react/jsx-no-literals
                <span className="truncate">&nbsp;·&nbsp;{member?.tagline}</span>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
