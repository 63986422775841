import {
  ActionFields,
  deleteSpacePostTypesGQLMutation,
  getSpacePostTypeGQLQuery,
  getSpacePostTypesGQLQuery,
  SpacePostTypeFields,
  updateSpacePostTypesGQLMutation,
} from '../../graphql/index.js'
import {
  Action,
  MutationDeleteSpacePostTypesArgs,
  MutationUpdateSpacePostTypesArgs,
  PaginatedSpacePostType,
  QuerySpacePostTypeArgs,
  QuerySpacePostTypesArgs,
  SpacePostType,
} from '../../types/index.js'
import { GraphqlClient } from '../graphql.client.js'

export class SpacePostTypeClient {
  private client: GraphqlClient

  constructor(client: GraphqlClient) {
    this.client = client
  }

  async list(
    variables: QuerySpacePostTypesArgs,
    fields: SpacePostTypeFields = 'basic',
    accessToken?: string,
  ): Promise<PaginatedSpacePostType> {
    type QueryResult = { spacePostTypes: PaginatedSpacePostType }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getSpacePostTypesGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.spacePostTypes
  }

  async get(
    variables: QuerySpacePostTypeArgs,
    fields: SpacePostTypeFields = 'basic',
    accessToken?: string,
  ): Promise<SpacePostType> {
    type QueryResult = { spacePostType: SpacePostType }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getSpacePostTypeGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.spacePostType
  }

  async update(
    variables: MutationUpdateSpacePostTypesArgs,
    fields: SpacePostTypeFields = 'basic',
    accessToken?: string,
  ): Promise<Array<SpacePostType>> {
    type QueryResult = { updateSpacePostTypes: Array<SpacePostType> }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: updateSpacePostTypesGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.updateSpacePostTypes
  }

  async delete(
    variables: MutationDeleteSpacePostTypesArgs,
    fields: ActionFields = 'basic',
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { deleteSpacePostTypes: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: deleteSpacePostTypesGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.deleteSpacePostTypes
  }
}
