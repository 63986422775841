import { clsx } from 'clsx'

import { Button, type ButtonProps } from '@tribeplatform/react-ui-kit/Button'

export type MultiViewBlockTabProps = ButtonProps

export const MultiViewBlockTab = ({
  pressed,
  ...rest
}: MultiViewBlockTabProps) => {
  return (
    <Button
      variant="tertiaryNeutral"
      className={clsx(
        '!text-label-md px-5 py-3 whitespace-nowrap',
        pressed && 'bg-background-pressed hover:bg-background-pressed',
        pressed
          ? 'text-content-on-background-pressed'
          : 'text-content-on-background-subdued hover:text-content-on-background-hovered',
      )}
      {...rest}
    />
  )
}
