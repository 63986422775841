import { FC } from 'react'

import { clsx } from 'clsx'

import {
  ListDirection,
  ListProvider,
  ListSpacing,
  useList,
} from './ListContext.js'

export type ListProps = React.ComponentProps<'ul'> & {
  spacing?: ListSpacing
  divider?: boolean
  direction?: ListDirection
}
/**
 * List component is used to display list items. It renders a <ul> element by default.
 * @deprecated use Container instead
 */
export const List = ({
  children,
  divider = false,
  spacing = 'md',
  direction = 'vertical',
  className,
  ...rest
}: ListProps) => {
  if (!children) {
    return null
  }

  return (
    <ul
      className={clsx(
        [
          'flex',
          direction === 'vertical' && 'flex-col',
          direction === 'horizontal' && 'flex-row',
        ],
        direction === 'vertical' && [
          spacing === 'lg' && '-my-4',
          spacing === 'md' && '-my-2.5',
          spacing === 'sm' && '-my-1.5',
          spacing === 'xs' && '-my-1',
        ],
        direction === 'horizontal' && [
          spacing === 'lg' && '-mx-4',
          spacing === 'md' && '-mx-2.5',
          spacing === 'sm' && '-mx-1.5',
          spacing === 'xs' && '-mx-1',
        ],
        divider && [
          'divide-line-subdued',
          direction === 'vertical' && 'divide-y',
          direction === 'horizontal' && 'divide-x',
        ],
        className,
      )}
      {...rest}
    >
      <ListProvider spacing={spacing} direction={direction}>
        {children}
      </ListProvider>
    </ul>
  )
}

export type ListItemProps = React.ComponentProps<'li'>

const ListItem: FC<ListItemProps> = props => {
  const { children, className, ...rest } = props

  const { spacing, direction } = useList()

  return (
    <li
      className={clsx(
        direction === 'vertical' && [
          spacing === 'lg' && 'py-4',
          spacing === 'md' && 'py-2.5',
          spacing === 'sm' && 'py-1.5',
          spacing === 'xs' && 'py-1',
          spacing === 'none' && 'py-0',
          className,
        ],
        direction === 'horizontal' && [
          'flex-1',
          spacing === 'lg' && 'px-4',
          spacing === 'md' && 'px-2.5',
          spacing === 'sm' && 'px-1.5',
          spacing === 'xs' && 'px-1',
          spacing === 'none' && 'px-0',
          className,
        ],
        'empty:hidden',
      )}
      {...rest}
    >
      {children}
    </li>
  )
}

List.Item = ListItem
