import { useEffect, useMemo, useState } from 'react'

import {
  ActionStatus,
  SpaceMembershipStatus,
} from '@tribeplatform/gql-client/types'
import {
  useDomain,
  useLogin,
} from '@tribeplatform/react-components/common/hooks'
import { useI18n } from '@tribeplatform/react-components/i18n'
import { getIconSpriteUrl } from '@tribeplatform/react-components/Picker'
import { useSpace, useUpdateSubscription } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { ActionButtonRenderer } from './ActionButtonRenderer.js'

interface Props {
  showText: boolean
  showIcon: boolean
  className?: string
  spaceId: string
}

export const NotificationsButton = ({
  showText,
  showIcon,
  className,
  spaceId,
}: Props) => {
  const { data: space } = useSpace({
    variables: {
      id: spaceId,
    },
    useQueryOptions: {
      enabled: !!spaceId,
    },
  })
  const { $t } = useI18n()
  const { isLoggedIn, showLogin } = useLogin()
  const { subfolder } = useDomain()
  const subscribed = space?.authMemberProps?.subscribed
  const { mutateAsync: updateSubscription } = useUpdateSubscription()
  const [optimisticSubscribed, setOptimisticSubscribed] =
    useState<boolean>(subscribed)
  const joined =
    space?.authMemberProps?.membershipStatus === SpaceMembershipStatus.joined
  const canSubscribe = joined || !space?.private

  useEffect(() => {
    setOptimisticSubscribed(subscribed)
  }, [subscribed])

  const label = useMemo(() => {
    if (optimisticSubscribed) {
      return $t({
        defaultMessage: 'Subscribed',
        id: 'Generics.Subscribed',
      })
    }
    return $t({
      defaultMessage: 'Subscribe',
      id: 'Generics.Subscribe',
    })
  }, [$t, optimisticSubscribed])

  const tooltip = useMemo(() => {
    if (optimisticSubscribed) {
      return $t({
        defaultMessage: 'Turn off notifications for new updates',
        id: 'Blocks.SpaceHeader.Subscribed.Tooltip',
      })
    }
    return $t({
      defaultMessage: 'Get notified about new updates',
      id: 'Blocks.SpaceHeader.Subscribe.Tooltip',
    })
  }, [$t, optimisticSubscribed])

  const onClick = async () => {
    if (!isLoggedIn) {
      showLogin()
      return
    }

    const isSubscribing = !optimisticSubscribed
    setOptimisticSubscribed(isSubscribing)
    try {
      await updateSubscription(
        {
          action: isSubscribing ? 'subscribe' : 'unsubscribe',
          publisherType: 'space',
          publisherId: spaceId,
        },
        {
          onSuccess: ({ status }) => {
            if (isSubscribing && status === ActionStatus.succeeded) {
              toast({
                title: $t({
                  defaultMessage:
                    'You’re now receiving notifications for new updates!',
                  id: 'Settings.Notification.Subscribed',
                }),
                status: 'success',
              })
            } else if (status === ActionStatus.failed) {
              setOptimisticSubscribed(!isSubscribing)
            }
          },
        },
      )
    } catch (error) {
      setOptimisticSubscribed(!isSubscribing)
    }
  }

  if (!canSubscribe) {
    return null
  }

  return (
    <ActionButtonRenderer
      className={className}
      showIcon={showIcon}
      showText={showText}
      pressed={optimisticSubscribed}
      onClick={onClick}
      label={label}
      tooltip={tooltip}
      variant="secondaryNeutral"
      icon={
        <SvgIcon
          iconSpriteUrl={getIconSpriteUrl(
            optimisticSubscribed ? 'solid' : 'line',
            { subfolder },
          )}
          name={optimisticSubscribed ? 'bell-ringing-01' : 'bell-plus'}
          size="xl"
        />
      }
    />
  )
}
