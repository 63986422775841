import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

export const getIcon = (iconName: string) => {
  switch (iconName) {
    case 'LinkExternalIcon':
      return <SvgIcon name="link-external" />
    case 'PlusIcon':
      return <SvgIcon name="plus" />
    case 'DotsHorizontalIcon':
      return <SvgIcon name="dots-horizontal" />
    case 'AccountSettingIcon':
      return <SvgIcon name="account-setting" />
    case 'Calendar':
      return <SvgIcon name="calendar" />
    default:
      return null
  }
}
