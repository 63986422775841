import type {
  ActionItem,
  MobileMenuSection,
  NavbarSection,
  RawActionItem,
  SectionDetails,
} from '../types.js'

export const convertToActionItems = (
  rawActionItems: RawActionItem[],
  allActionItems: ActionItem[],
): ActionItem[] => {
  const availableActionItems = rawActionItems.filter(({ id }) =>
    allActionItems.some(actionItem => actionItem.id === id),
  )
  const actionItems: ActionItem[] = availableActionItems.map(
    ({ id, enabled }) => {
      const actionItem = allActionItems.find(actionItem => actionItem.id === id)

      return { ...actionItem, enabled }
    },
  )

  return actionItems
}

export const convertToRawActionItems = (
  actionItems: ActionItem[],
): RawActionItem[] => {
  const rawActionItems = actionItems.map(({ id, enabled }) => {
    const rawActionItem: RawActionItem = { id, enabled }

    return rawActionItem
  })

  return rawActionItems
}

export const convertToNavbarSection = (
  richSections: Array<NavbarSection & SectionDetails>,
): NavbarSection[] => {
  const navbarSections: NavbarSection[] = richSections.map(section => {
    const { label: _, icon: __, ...navbarSection } = section

    return navbarSection
  })

  return navbarSections
}

export const convertToMobileMenuSections = (
  richSections: Array<MobileMenuSection & SectionDetails>,
): MobileMenuSection[] => {
  const mobileMenuSections: MobileMenuSection[] = richSections.map(section => {
    const { label: _, icon: __, ...mobileMenuSection } = section

    return mobileMenuSection
  })

  return mobileMenuSections
}
