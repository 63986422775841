import { Select } from '../Select/index.js'
import { FormControlLayout } from './FormControlLayout.js'
import { FormControlSelectProps } from './types.js'
import { extractLayoutProps } from './utils.js'

export const FormControlSelect = (props: FormControlSelectProps) => {
  const { layoutProps, inputProps } = extractLayoutProps(props)

  return (
    <FormControlLayout {...layoutProps}>
      <Select {...inputProps} />
    </FormControlLayout>
  )
}
