import { T } from '@tribeplatform/react-components/i18n'
import { useAuthToken } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { NavVertical } from '@tribeplatform/react-ui-kit/Sidebar'
import {
  useColorScheme,
  convertNetworkToAppearance,
} from '@tribeplatform/react-ui-kit/ThemeProvider'

import { useSlate } from '../../../../hooks/slate.hook.js'

interface Props {
  close: () => void
}
export const ThemeSwitchAction = ({ close }: Props) => {
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'
  const { colorSchemeMode, setMode } = useColorScheme()
  const {
    data: { network },
  } = useAuthToken()
  const appearance = convertNetworkToAppearance({
    oldColors: network?.themes?.active?.tokens?.colors,
    networkThemeLightColors: network?.activeTheme?.colors?.light,
    networkThemeDarkColors: network?.activeTheme?.colors?.dark,
    networkThemeTypography: network?.activeTheme?.typography,
  })
  const canSwitchTheme =
    appearance.networkColorScheme.mode === 'user-preference'

  if (!canSwitchTheme) {
    return null
  }

  return (
    <NavVertical.Item
      leadingAddon={
        colorSchemeMode === 'dark' ? (
          <SvgIcon name="sun" size="lg" className="me-3 -ms-1" />
        ) : (
          <SvgIcon name="moon" size="lg" className="me-3 -ms-1" />
        )
      }
      onClick={e => {
        if (isPreviewMode) {
          e.preventDefault()
          return
        }

        if (colorSchemeMode === 'dark') {
          setMode('light')
          close()
          return
        }

        setMode('dark')
        close()
      }}
      className="cursor-pointer"
    >
      {colorSchemeMode === 'dark' ? (
        <T
          defaultMessage="Light mode"
          id="Navbar.Controls.ThemeSwitch.LightMode"
        />
      ) : (
        <T
          defaultMessage="Dark mode"
          id="Navbar.Controls.ThemeSwitch.DarkMode"
        />
      )}
    </NavVertical.Item>
  )
}
