import type { PostFields } from '@tribeplatform/gql-client/graphql'
import type {
  PaginatedPost,
  QueryRepliesArgs,
} from '@tribeplatform/gql-client/types'

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '../../lib/react-query/useInfiniteQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPostsKey } from '../../utils/keys/post.key.js'

export interface UseRepliesProps {
  variables: QueryRepliesArgs
  fields?: PostFields
  useInfiniteQueryOptions?: UseInfiniteQueryOptions<PaginatedPost>
}
export const useReplies = (options: UseRepliesProps) => {
  const {
    variables,
    fields = 'default',
    useInfiniteQueryOptions,
  } = options || {}
  const { client } = useTribeClient()
  const repliesKey = getPostsKey({ variables, fields })

  return useInfiniteQuery<PaginatedPost>(
    repliesKey,
    ({ pageParam: after }) =>
      client?.posts.replies({ after, ...variables }, fields),
    useInfiniteQueryOptions,
  )
}
