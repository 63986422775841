import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { AddToCalendarButtonBlock as Component } from './Block.js'
import { AddToCalendarButtonProps } from './types.js'

export const RegisteredAddToCalendarButtonBlock = (
  _intl: I18nShape,
): RegisteredBlock<AddToCalendarButtonProps> => ({
  recommendedName: 'AddToCalendarButton',
  Component,
  config: { type: BlockType.BuildingBlock },
})
