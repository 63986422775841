import { I18nShape } from '@tribeplatform/react-components/i18n'

export const MemberPageSlate = ({ $t }: I18nShape) => ({
  id: 'SLHVnfduvos833',
  rootBlock: 'h4f0vhsjdnfuq',
  blocks: [
    {
      id: 'h4f0vhsjdnfuq',
      name: 'List',
      props: {
        spacing: 'none',
      },
      children: ['jhslfuhlub4la', 'GgahsdgASDgah42'],
    },
    {
      id: 'jhslfuhlub4la',
      name: 'MemberHeader',
      props: {
        memberId: '$memberId',
      },
    },
    {
      id: 'GgahsdgASDgah42',
      name: 'Tabs',
      acceptsChildren: true,
      props: {
        rounded: 'desktop',
        fullWidth: true,
        attached: 'top',
        tabs: [
          {
            name: $t({ defaultMessage: 'Posts', id: 'Generics.Posts' }),
            path: '/',
          },
          {
            name: $t({
              defaultMessage: 'Replies',
              id: 'Generics.Replies',
            }),
            path: '/replies',
          },
          {
            name: $t({
              defaultMessage: '{SPACES_CC}',
              id: 'Generics.Spaces',
            }),
            path: '/spaces',
          },
        ],
      },
      children: ['HBfb7349vsgvjalsa', 'JNnvuhvlskjv834', 'VBNjfdgphgpa8s'],
    },
    {
      id: 'HBfb7349vsgvjalsa',
      name: 'Columns',
      acceptsChildren: true,
      props: {
        columns: [
          {
            main: true,
          },
          {
            sticky: true,
          },
        ],
        smColumns: [1, 0],
      },
      children: ['VNofvbsahufa536', 'BVLnuiahf834'],
    },
    {
      id: 'JNnvuhvlskjv834',
      name: 'List',
      props: {
        spacing: 'md',
      },
      children: ['huhg38790gsfghph'],
    },
    {
      id: 'VBNjfdgphgpa8s',
      name: 'List',
      props: {
        spacing: 'md',
      },
      children: ['huhg38790gsfghsd'],
    },
    {
      id: 'VNofvbsahufa536',
      name: 'List',
      props: {
        spacing: 'md',
      },
      children: ['huhg38790gsfghpd'],
    },
    {
      id: 'BVLnuiahf834',
      name: 'List',
      props: {
        spacing: 'md',
      },
      children: ['huhg38790gsfghsw'],
    },
    {
      id: 'huhg38790gsfghsw',
      name: 'MemberAbout',
      props: {
        memberId: '$memberId',
      },
    },
    {
      id: 'huhg38790gsfghph',
      name: 'PostList',
      acceptsAfter: false,
      props: {
        memberId: '$memberId',
        hasParent: true,
      },
    },
    {
      id: 'huhg38790gsfghpd',
      name: 'PostList',
      acceptsAfter: false,
      props: {
        memberId: '$memberId',
      },
    },
    {
      id: 'huhg38790gsfghsd',
      name: 'SpaceList',
      acceptsAfter: false,
      props: {
        memberId: '$memberId',
      },
    },
  ],
})
