import { ReactNode } from 'react'

import { Listbox } from '@headlessui/react'
import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import { FloatingContextProvider } from '../utils/floating/index.js'
import { runIfFn } from '../utils/index.js'
import { SelectButton } from './SelectButton.js'
import { SelectProvider } from './SelectContext.js'
import { SelectItem } from './SelectItem.js'
import { SelectItems } from './SelectItems.js'

export type SelectProps<T> = {
  value: T
  onChange: (newValue: T) => void
  className?: string
  children?: ReactNode
  disabled?: boolean
  placeholder?: string
  items?: Array<{
    value: string | number
    text: string | ReactNode
    avatar?: string
  }>
  invalid?: boolean
  name?: string
  id?: string
}

/**
 * The Select component is a component that allows users pick a value from predefined options.
 */
export const Select = <T,>(props: SelectProps<T>) => {
  const {
    children,
    className,
    value,
    placeholder,
    items,
    invalid = false,
    disabled = false,
    ...rest
  } = props

  return (
    <Listbox {...rest} disabled={disabled} value={value}>
      {renderProps => {
        let content
        if (items) {
          content = (
            <>
              <SelectButton placeholder={placeholder}>
                {items.find(item => value === item.value)?.text || null}
              </SelectButton>
              <SelectItems>
                {items.map(item => (
                  <SelectItem key={item.value} value={item.value}>
                    {item.text}
                  </SelectItem>
                ))}
              </SelectItems>
            </>
          )
        } else {
          content = runIfFn(children, renderProps)
        }

        return (
          <div className={twMerge(clsx('relative isolate', className))}>
            <SelectProvider
              open={renderProps.open}
              invalid={invalid}
              disabled={disabled}
            >
              <FloatingContextProvider placement="bottom-start">
                {content}
              </FloatingContextProvider>
            </SelectProvider>
          </div>
        )
      }}
    </Listbox>
  )
}

Select.Button = SelectButton
Select.Items = SelectItems
Select.Item = SelectItem
