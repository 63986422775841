import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { clsx } from 'clsx'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { Post, PostStatus } from '@tribeplatform/gql-client/types'
import {
  useAddReply,
  useAuthMember,
  usePost,
} from '@tribeplatform/react-sdk/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { MemberAvatar } from '../common/components/index.js'
import { useCachedMentionsFromPost } from '../common/hooks/useCachedMentionsFromPost.js'
import { useLogin } from '../common/hooks/useLogin.js'
import { getMappingFields } from '../common/utils/post.js'
import { useCanQuitEditor } from '../Composer/utils/state.js'
import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { ReplyEditor, ReplyEditorRef } from '../Reply/ReplyEditor.js'
import { confirmActionOnLockedPost } from './utils.js'

export type ReplyBarRef = {
  scrollIntoView: () => void
  expand: (toggle: boolean) => void
}

type ReplyBarProps = {
  post: Post
  defaultExpand?: boolean
}

export const ReplyBar = forwardRef<ReplyBarRef, ReplyBarProps>(
  ({ post, defaultExpand = false }, ref) => {
    const intl = useI18n()
    const { $t } = intl

    const containerRef = useRef<HTMLDivElement>()
    const [expanded, setExpanded] = useState(defaultExpand)
    const editorRef = useRef<ReplyEditorRef>(null)
    const { data: member } = useAuthMember()
    const { mutate: addReply, isLoading } = useAddReply()
    const { isLoggedIn, showLogin } = useLogin()
    const canQuitEditor = useCanQuitEditor()

    const { data: fullPost, isFetching: fetchingFullPost } = usePost({
      variables: { id: post.id },
      useQueryOptions: {
        enabled: expanded,
      },
    })
    const cachedMentions = useCachedMentionsFromPost(fullPost?.id, {
      enabled: expanded,
    })

    const loading = isLoading || fetchingFullPost

    const availableReplyTypes =
      fullPost?.authMemberProps?.availableReplyTypes ||
      post?.authMemberProps?.availableReplyTypes

    useImperativeHandle(ref, () => ({
      scrollIntoView: () => {
        containerRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        })
        editorRef.current?.focus()
      },
      expand: setExpanded,
    }))

    const quit = useCallback(() => {
      setExpanded(false)
    }, [])

    const cancel = useCallback(
      isTouched => {
        if (
          canQuitEditor({
            isTouched,
          })
        ) {
          quit()
        }
      },
      [canQuitEditor, quit],
    )

    const onReply = useCallback(
      async (content = '', editor) => {
        const { isEmpty: noText } = editor

        const isEmpty = noText

        if (loading || isEmpty || content === '') {
          return
        }

        const postType = availableReplyTypes?.[0]
        if (!postType?.id) {
          return
        }

        const mappingFields = getMappingFields({
          content,
          postTypeName: postType.name,
        })

        addReply(
          {
            postId: post.id,
            input: {
              postTypeId: postType.id,
              mappingFields,
              publish: true,
            },
          },
          {
            onSuccess: data => {
              if (data.status === PostStatus.BLOCKED) {
                toast({
                  title: $t({
                    id: 'Generics.PendingReview',
                    defaultMessage: 'Pending review',
                  }),
                  description: $t({
                    id: 'Post.Replybar.SuccessToastDescription',
                    defaultMessage:
                      'Your reply will be visible to others once it’s been reviewed by a moderator.',
                  }),
                  status: 'info',
                })
              }

              quit()
            },
            onError: error => {
              const message = error?.response?.errors
                ?.map(e => e.message)
                ?.join('\n')
              toast({
                title: $t({
                  id: 'Post.Replybar.ReplyFailedTitle',
                  defaultMessage: 'Reply failed',
                }),
                description:
                  message ||
                  $t({
                    id: 'Generics.SomethingWentWrongTryAgain',
                    defaultMessage: 'Something went wrong, please try again.',
                  }),
                status: 'error',
              })
            },
          },
        )
      },
      [loading, availableReplyTypes, addReply, post.id, quit, $t],
    )

    const repliedToCount = post.repliedToIds?.length

    // If it is not a reply to a reply, show the avatar below
    const withAvatar = !repliedToCount || repliedToCount > 1

    const containerClasses = 'flex space-s-3 sm:space-s-4'

    if (fullPost) {
      const [canCreateReply] = hasScopesPermission(post, ['createReply'])
      if ((isLoggedIn && !canCreateReply) || !availableReplyTypes?.length) {
        return null
      }
    }

    if (expanded) {
      return (
        <div className={containerClasses} ref={containerRef}>
          {withAvatar && (
            <div>
              <div className="hidden sm:block">
                <MemberAvatar size="3x" member={member} clickable />
              </div>
              <div className="sm:hidden">
                <MemberAvatar size="2.5x" member={member} clickable />
              </div>
            </div>
          )}
          <div className="flex flex-col bg-surface flex-grow max-w-full min-w-0">
            <div
              className={clsx({
                'text-content-subdued': loading,
              })}
            >
              <ReplyEditor
                onReply={onReply}
                ref={editorRef}
                onCancel={cancel}
                cachedMentions={cachedMentions}
                isLoading={loading}
              />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={containerClasses}>
        {withAvatar && (
          <div>
            <div className=" hidden sm:flex">
              <MemberAvatar size="3x" member={member} clickable />
            </div>
            <div className="sm:hidden">
              <MemberAvatar size="2.5x" member={member} clickable />
            </div>
          </div>
        )}
        <Button
          variant="tertiaryNeutral"
          size="md"
          fullWidth
          textAlign="start"
          onClick={async e => {
            if (!isLoggedIn) {
              e.preventDefault()
              return showLogin()
            }

            if (!(await confirmActionOnLockedPost(intl)(post))) {
              return
            }

            setExpanded(true)
            setTimeout(() => {
              editorRef?.current?.focus()
            }, 300)
          }}
          className="mt-0 sm:mt-1 !font-normal bg-surface-subdued hover:bg-surface-subdued-hovered rounded-input"
        >
          <T
            id="Post.ReplyBar.ReplyButtonText"
            defaultMessage="What are your thoughts?"
          />
        </Button>
      </div>
    )
  },
)
