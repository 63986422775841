import { Container } from '@tribeplatform/react-ui-kit/Layout'

import { Body } from './components/Body.js'
import { Content } from './components/Content.js'
import { FloatingFooter } from './components/FloatingFooter.js'
import { Header } from './components/Header.js'
import { Sidebar } from './components/Sidebar.js'

interface LayoutProps {
  header: JSX.Element
  sidebar: JSX.Element
  content: JSX.Element
  floatingFooter: JSX.Element
  stickyHeader?: boolean
  stickySidebar?: boolean
}
export const LayoutFrame = ({
  header,
  sidebar,
  content,
  floatingFooter,
  stickyHeader = true,
  stickySidebar = true,
}: LayoutProps) => {
  return (
    <Container
      size="full"
      spacing="none"
      containerProps={{
        className:
          'bg-background min-h-screen transition duration-200 ms-[calc(env(safe-area-inset-left))] me-[calc(env(safe-area-inset-right))]',
      }}
    >
      <Header sticky={stickyHeader}>{header}</Header>
      <Body
        sidebar={
          !!sidebar && (
            <Sidebar sticky={stickySidebar} layoutHasHeader={!!header}>
              {sidebar}
            </Sidebar>
          )
        }
        content={<Content layoutHasSidebar={!!sidebar}>{content}</Content>}
      />
      <FloatingFooter>{floatingFooter}</FloatingFooter>
    </Container>
  )
}
