import { Input } from '@tribeplatform/react-ui-kit/Input'

import { CustomFieldSubtype } from '../../CustomField/utils.js'
import { useI18n } from '../../i18n/index.js'
import type { Filter, ValuePickerComponent } from '../types.js'

const getInputType = (subType: Filter['subType']) => {
  switch (subType) {
    case CustomFieldSubtype.URL:
      return 'url'
    case CustomFieldSubtype.EMAIL:
      return 'email'
    default:
      return 'text'
  }
}

export const TextValuePicker: ValuePickerComponent = ({ filter, onChange }) => {
  const { $t } = useI18n()

  return (
    <Input
      value={(filter.value as string) ?? ''}
      type={getInputType(filter.subType)}
      onChange={e => onChange(e.target.value)}
      placeholder={$t({
        defaultMessage: 'Search...',
        id: 'Generics.SearchDotDotDot',
      })}
    />
  )
}
