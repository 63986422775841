import { useMemo } from 'react'

import { useI18n } from '@tribeplatform/react-components/i18n'

import { SlateKit } from '../slate-kit.js'
import { SlateKitInterface, SlateKitOptions } from '../types/index.js'

export const useSlateKit = (options: SlateKitOptions): SlateKitInterface => {
  const intl = useI18n()
  const slateKit = useMemo(() => new SlateKit(intl, options), [intl, options])

  return slateKit
}
