import { useCallback, useMemo } from 'react'

import {
  useListFilters,
  useInlineFilters,
  prepareFilters,
  applyStoredFilters,
  type LegacyFilter,
  type RawInlineFilter,
  type Filter,
} from '@tribeplatform/react-components/Filters'
import { useStaticIntl } from '@tribeplatform/react-components/i18n'

import { useCustomFields } from '../hooks/useCustomFields.js'
import { convertToPostListFilter } from '../utils/convertToPostListFilter.js'
import { staticFiltersFactory } from '../utils/staticFilters.js'

interface UsePostFiltersProps {
  filterBy: LegacyFilter[]
  postTypeSlugs: string[]
  rawInlineFilters: RawInlineFilter[]
  storedFilters: LegacyFilter[]
  setStoredFilters: (newFilters: Filter[]) => void
}
export const usePostFilters = ({
  filterBy,
  postTypeSlugs,
  rawInlineFilters,
  storedFilters,
  setStoredFilters,
}: UsePostFiltersProps) => {
  const { customFields } = useCustomFields({ postTypeSlugs })
  const staticFilters = useStaticIntl(staticFiltersFactory)
  const listFilters = useMemo(
    () => convertToPostListFilter(filterBy),
    [filterBy],
  )
  const { filters, allFilters } = useListFilters({
    entityListFilters: listFilters,
    customFields,
    staticFilters,
  })
  const { processedInlineFilters, setProcessedInlineFilters } =
    useInlineFilters({
      entityListFilters: listFilters,
      filters,
      allFilters,
      rawInlineFilters,
    })
  const inlineFiltersMergedWithStoredFilters = useMemo(() => {
    return applyStoredFilters(storedFilters, processedInlineFilters)
  }, [storedFilters, processedInlineFilters])
  const combinedFilters = useMemo(
    () => prepareFilters([...filters, ...inlineFiltersMergedWithStoredFilters]),
    [filters, inlineFiltersMergedWithStoredFilters],
  )
  const hasInlineFilters = rawInlineFilters.some(({ enabled }) => !!enabled)

  const setInlineFilters = useCallback(
    (newFilters: Filter[]) => {
      setStoredFilters(newFilters)
      setProcessedInlineFilters(newFilters)
    },
    [setProcessedInlineFilters, setStoredFilters],
  )

  return {
    inlineFilters: inlineFiltersMergedWithStoredFilters,
    setInlineFilters,
    combinedFilters,
    hasInlineFilters,
  }
}
