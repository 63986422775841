import iconSpriteManifest from '@bettermode/icons/manifest.json' assert { type: 'json' }
import type { AppearanceStyle } from '@tribeplatform/design-system/types'
import { GlyphMediaVariant } from '@tribeplatform/gql-client/types'
import type { ThemeAssets } from '@tribeplatform/react-ui-kit/ThemeProvider'

import type { PickerEmoji, PickerIcon, PickerIconPack } from '../types.js'

const { checksums } = iconSpriteManifest

export const emojiToImageId = (emoji: PickerEmoji) =>
  `${GlyphMediaVariant.emoji}/${emoji.unified}`

export const iconToImageId = (icon: PickerIcon) =>
  `${GlyphMediaVariant.icon}/${icon.name}`

export const getPickerIconSpriteUrl = (
  pack: PickerIconPack,
  assets: ThemeAssets,
) => {
  switch (pack) {
    case 'line':
      return assets.iconPickerLineSpriteUrl
    case 'solid':
      return assets.iconPickerSolidSpriteUrl
    case 'duocolor':
      return assets.iconPickerDuocolorSpriteUrl
    default: {
      const exhaustiveCheck: never = pack
      return exhaustiveCheck
    }
  }
}

export const getIconSpriteUrl = (
  iconsStyle: AppearanceStyle['icons'],
  { subfolder }: { subfolder?: string },
) => {
  switch (iconsStyle) {
    case 'line':
      return `${subfolder || ''}/icon-sprite-line.svg?v=${checksums.line}`
    case 'solid':
      return `${subfolder || ''}/icon-sprite-solid.svg?v=${checksums.solid}`
    case 'duocolor':
      return `${subfolder || ''}/icon-sprite-duocolor.svg?v=${
        checksums.duocolor
      }`
    default: {
      const exhaustiveCheck: never = iconsStyle
      return exhaustiveCheck
    }
  }
}

export const getIconSocialSpriteUrl = ({
  subfolder,
}: {
  subfolder?: string
}) => {
  return `${subfolder || ''}/icon-sprite-social.svg?v=${checksums.social}`
}

export const getIconPickerSpriteUrl = (
  pack: PickerIconPack,
  { subfolder }: { subfolder?: string },
) => {
  switch (pack) {
    case 'line':
      return `${subfolder || ''}/icons/icon-sprite-picker-line.svg`
    case 'solid':
      return `${subfolder || ''}/icons/icon-sprite-picker-solid.svg`
    case 'duocolor':
      return `${subfolder || ''}/icons/icon-sprite-picker-duocolor.svg`
    default: {
      const exhaustiveCheck: never = pack
      return exhaustiveCheck
    }
  }
}
