import type {
  ModerationItemFields,
  ModerationItemReporterFields,
} from '@tribeplatform/gql-client/graphql'
import type {
  QueryModerationItemArgs,
  QueryModerationItemReportersArgs,
  QueryModerationItemsArgs,
  QueryPostModerationItemsArgs,
} from '@tribeplatform/gql-client/types'
import type { PaginatedModerationFields } from '@tribeplatform/gql-client/types/generated'

import { KeyConstructor } from './keyConstructor.js'

const POST_MODERATION_ITEMS_KEY = 'postModerationsItem'
const MODERATION_ITEM_KEY = 'moderationItem'
const MODERATION_ITEMS_KEY = 'moderationItems'
const MODERATION_ITEM_REPORTERS_KEY = 'moderationItemReporters'
const MODERATION_SETTINGS_KEY = 'moderationSettings'

export const getPostModerationItemsKey = KeyConstructor<
  QueryPostModerationItemsArgs,
  PaginatedModerationFields
>(POST_MODERATION_ITEMS_KEY)
export const getModerationItemKey = KeyConstructor<
  QueryModerationItemArgs,
  ModerationItemFields
>(MODERATION_ITEM_KEY)
export const getModerationItemsKey = KeyConstructor<
  QueryModerationItemsArgs,
  ModerationItemFields
>(MODERATION_ITEMS_KEY)
export const getModerationItemReportersKey = KeyConstructor<
  QueryModerationItemReportersArgs,
  ModerationItemReporterFields
>(MODERATION_ITEM_REPORTERS_KEY)
export const getModerationSettingsKey = KeyConstructor<never, never>(
  MODERATION_SETTINGS_KEY,
)
