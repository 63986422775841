import type { InfiniteData, QueryKey } from '@tanstack/react-query'

import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Action,
  MutationRemoveReactionArgs,
  PaginatedPost,
  Post,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPostKey } from '../../utils/keys/post.key.js'
import { useAuthMember } from '../auth/useAuthMember.js'
import {
  getCachedPost,
  removeReactionFromPost,
  infinitePostArrayUpdater,
  infinitePostUpdater,
  getCachedRootPostByReplies,
} from '../cache/useCachedPost.js'
import { pinnedPostsFilter, postsQueryFilter } from './filters.js'

type Snapshot = [
  QueryKey,
  Post | InfiniteData<PaginatedPost> | InfiniteData<Post>,
][]

export const useRemoveReaction = (options?: {
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationRemoveReactionArgs,
    Snapshot
  >
}) => {
  const queryClient = useQueryClient()
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const { data: authMember } = useAuthMember()

  return useMutation<Action, ClientError, MutationRemoveReactionArgs, Snapshot>(
    (input: MutationRemoveReactionArgs) => client.posts.removeReaction(input),

    {
      onMutate: async variables => {
        const { reaction, postId } = variables
        // updating the post itself
        const postKey = getPostKey({ variables: { id: postId } })
        await queryClient.cancelQueries(postKey)

        const postSnapshot = getCachedPost(postId, queryClient)

        if (postSnapshot) {
          queryClient.setQueriesData<Post>(
            postKey,
            removeReactionFromPost(reaction, authMember.id),
          )
        }

        // updating pinned posts within the space
        const pinnedPostsSnapshot =
          queryClient.getQueriesData<InfiniteData<Post>>(pinnedPostsFilter)
        if (pinnedPostsSnapshot) {
          queryClient.setQueriesData<Post[]>(pinnedPostsFilter, oldPosts =>
            oldPosts.map(
              infinitePostArrayUpdater(
                postId,
                removeReactionFromPost(reaction, authMember.id),
                true,
              ),
            ),
          )
        }

        // take a snapshot from all matched posts queries
        const postsSnapshots =
          queryClient.getQueriesData<InfiniteData<PaginatedPost>>(
            postsQueryFilter,
          )

        // update posts and replies within the found post everywhere (posts and the feed queries)
        queryClient.setQueriesData<InfiniteData<PaginatedPost>>(
          postsQueryFilter,
          infinitePostUpdater(
            postId,
            removeReactionFromPost(reaction, authMember.id),
            true,
          ),
        )

        // updating root post if adding the reaction on a reply if no feed/posts have found
        let rootPostSnapshot
        let rootPostKey
        if (!pinnedPostsSnapshot?.length && !postsSnapshots?.length) {
          const rootPost = getCachedRootPostByReplies(postId, queryClient)
          if (rootPost && rootPost.replies && rootPost.id !== postId) {
            rootPostKey = getPostKey({ variables: { id: rootPost?.id } })
            rootPostSnapshot = getCachedPost(rootPost?.id, queryClient)
            if (rootPostSnapshot) {
              queryClient.setQueriesData<Post>(
                rootPostKey,
                infinitePostArrayUpdater(
                  postId,
                  removeReactionFromPost(reaction, authMember.id),
                  true,
                ),
              )
            }
          }
        }

        return [
          [postKey, postSnapshot],
          [rootPostKey, rootPostSnapshot],
          ...pinnedPostsSnapshot,
          ...postsSnapshots,
        ]
      },
      onError: (error, variables, context) => {
        context.forEach(([queryKey, snapshot]) => {
          if (queryKey) {
            queryClient.setQueriesData(queryKey, snapshot)
          }
        })
      },
      ...useMutationOptions,
    },
  )
}
