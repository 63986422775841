import { MemberFields, memberGQLFields } from '../network/index.js'

export type LeaderboardFields =
  | 'basic'
  | 'all'
  | 'default'
  | CustomLeaderboardFields

interface CustomLeaderboardFields {
  member?: MemberFields
  score?: 'basic' | 'all'
}

const BASIC_LEADERBOARD_FIELDS: CustomLeaderboardFields = {}
const ALL_LEADERBOARD_FIELDS: CustomLeaderboardFields = {
  member: 'basic',
  score: 'basic',
}
const DEFAULT_LEADERBOARD_FIELDS: CustomLeaderboardFields = {
  member: 'default',
  score: 'basic',
}

export const leaderboardGQLFields = (fields: LeaderboardFields) => {
  if (fields === 'basic') fields = BASIC_LEADERBOARD_FIELDS
  if (fields === 'all') fields = ALL_LEADERBOARD_FIELDS
  if (fields === 'default') fields = DEFAULT_LEADERBOARD_FIELDS

  return `
    memberId
    ${fields.score ? 'score' : ''}
    ${
      fields.member
        ? `
      member {
        ${memberGQLFields(fields.member)}
      }
    `
        : ``
    }
  `
}
