import { HtmlContent } from '@tribeplatform/react-components/HtmlContent'
import { Accordion as AccordionComponent } from '@tribeplatform/react-ui-kit/Accordion'
import { Card } from '@tribeplatform/react-ui-kit/Card'

import { BC } from '../../types/index.js'
import { AccordionProps } from './types.js'

export const AccordionBlock: BC<AccordionProps> = ({
  items,
  title,
  ...rest
}) => {
  if (!items) {
    return null
  }

  return (
    <Card {...rest}>
      {title && <Card.Header title={title} size="sm" />}
      <Card.Content>
        {items.map((item, index) => (
          <AccordionComponent
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            variant="plus"
            iconAlign="trailing"
            defaultOpen={item.defaultOpen}
            inset="flush"
          >
            <AccordionComponent.Button>{item.title}</AccordionComponent.Button>
            <AccordionComponent.Panel>
              <article className="prose">
                <HtmlContent value={item.description} trustedDomains={['*']} />
              </article>
            </AccordionComponent.Panel>
          </AccordionComponent>
        ))}
      </Card.Content>
    </Card>
  )
}
