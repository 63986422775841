import { useEffect, useMemo } from 'react'

import { useAuthToken } from '@tribeplatform/react-sdk/hooks'

import {
  ActorAnalyticsContext,
  SnowplowTrackerFactory,
  TargetAnalyticsContext,
} from '../lib/snowplow.js'

function getLang() {
  if (typeof window === 'undefined') {
    return undefined
  }

  if (Array.isArray(navigator?.languages)) {
    return navigator.languages[0]
  }

  return navigator?.language
}

const unsetContextValues = (
  context:
    | Partial<ActorAnalyticsContext>
    | Partial<TargetAnalyticsContext> = {},
) => {
  return Object.keys(context).reduce((acc, key: string) => {
    acc[key] = undefined
    return acc
  }, {})
}

export const useAnalytics = ({
  actor,
  target,
}: {
  actor?: Partial<ActorAnalyticsContext>
  target?: Partial<TargetAnalyticsContext>
} = {}) => {
  const {
    data: { member, network },
  } = useAuthToken()
  const { id: networkId, organizationId } = network || {}
  const targetDefaultContext: TargetAnalyticsContext = useMemo(
    () => ({
      organizationId: organizationId || '',
      networkId,
    }),
    [networkId, organizationId],
  )

  const actorDefaultContext: ActorAnalyticsContext = useMemo(
    () => ({
      id: member?.id || '',
      roleId: member?.role?.id || '',
      roleType: member?.role?.type || '',
      locale: member?.locale || getLang(),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [member?.id],
  )

  const tracker = useMemo(() => {
    return SnowplowTrackerFactory('tribe-analytics', {})
  }, [])

  useEffect(() => {
    tracker.setActor({ ...actorDefaultContext, ...actor })
    tracker.setTarget({ ...targetDefaultContext, ...target })

    return () => {
      tracker.setActor(unsetContextValues(actor))
      tracker.setTarget(unsetContextValues(target))
    }
  }, [tracker, actor, target, actorDefaultContext, targetDefaultContext])

  return tracker
}
