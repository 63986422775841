import { SpaceFields, spaceGQLFields } from '../space/space.fields.js'

export type TemplateRequestFields =
  | 'basic'
  | 'all'
  | CustomTemplateRequestFields

export interface CustomTemplateRequestFields {
  createdEntity?: SpaceFields
}

const BASIC_TEMPLATE_REQUEST_FIELDS: CustomTemplateRequestFields = {}
const ALL_TEMPLATE_REQUEST_FIELDS: CustomTemplateRequestFields = {
  createdEntity: 'basic',
}

export function templateRequestGQLFields(
  fields: TemplateRequestFields,
): string {
  if (fields === 'basic') {
    fields = BASIC_TEMPLATE_REQUEST_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_TEMPLATE_REQUEST_FIELDS
  }

  return `
    id
    status
    networkId
    templateId
    createdAt
    createdById
    createdEntityId
    createdEntityType
    processedPercentage
    updatedAt
    ${
      fields.createdEntity
        ? `
      createdEntity {
      ...on Space {
        ${spaceGQLFields(fields.createdEntity)}
        }
      }
    `
        : ``
    }
  `
}
