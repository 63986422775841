import type { PostFields } from '@tribeplatform/gql-client/graphql'
import type {
  Post,
  QuerySpacePinnedPostsArgs,
} from '@tribeplatform/gql-client/types'

import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPinnedPostsKey } from '../../utils/keys/space.key.js'

export const usePinnedPosts = (options: {
  useQueryOptions?: UseQueryOptions<Post[]>
  variables?: QuerySpacePinnedPostsArgs
  fields?: PostFields
}) => {
  const { variables, fields = 'default', useQueryOptions } = options || {}
  const { client } = useTribeClient()
  const pinnedPostsKey = getPinnedPostsKey({ variables, fields })

  return useQuery<Post[]>(
    pinnedPostsKey,
    () => client?.spaces.pinnedPosts(variables, fields),
    useQueryOptions,
  )
}
