import { useEffect } from 'react'

import { useScrollLockManager } from './useScrollLockManager.js'

/**
 * Blocks scroll when rendered
 */
export const ScrollLock = () => {
  const scrollLockManager = useScrollLockManager()

  useEffect(() => {
    scrollLockManager.registerScrollLock()

    return () => {
      scrollLockManager.unregisterScrollLock()
    }
  }, [scrollLockManager])

  return null
}
