import { isEmpty } from '@bettermode/common/isempty'
import type { AuthToken } from '@tribeplatform/gql-client/types'

import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { getAuthTokenKey } from '../../utils/keys/authToken.keys.js'

export const useCachedAuthToken = () => {
  const queryClient = useQueryClient()
  const queries = queryClient.getQueriesData<AuthToken>({
    queryKey: getAuthTokenKey(),
  })

  let authToken: AuthToken

  queries?.forEach(query => {
    const data = query?.[1]
    if (data) {
      authToken = {
        ...authToken,
        ...data,
      }
    }
  })

  if (!isEmpty(authToken)) {
    return authToken
  }
}
