import type { AppFields } from '@tribeplatform/gql-client/graphql'
import type { App, QueryAppArgs } from '@tribeplatform/gql-client/types'

import {
  useQuery,
  type UseQueryOptions,
} from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getAppKey } from '../../utils/keys/app.key.js'
import { useCachedApp } from '../cache/useCachedApp.js'

export const useApp = (options: {
  variables: QueryAppArgs
  fields?: AppFields
  useQueryOptions?: UseQueryOptions<App>
}) => {
  const { variables, fields = 'basic', useQueryOptions } = options
  const { client } = useTribeClient()
  const appKey = getAppKey({ variables, fields })
  const cachedApp = useCachedApp(variables)

  return useQuery<App>(appKey, () => client.app.get(variables, fields), {
    placeholderData: cachedApp,
    ...useQueryOptions,
  })
}
