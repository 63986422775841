import { clsx } from 'clsx'

import { HtmlContent } from '@tribeplatform/react-components/HtmlContent'
import { T } from '@tribeplatform/react-components/i18n'
import { Card } from '@tribeplatform/react-ui-kit/Card'

import { useSlate } from '../../hooks/index.js'
import { BC } from '../../types/index.js'
import { RichTextBlockProps } from './types.js'

interface RichTextBlockContentProps {
  content: string
}
const RichTextBlockContent = ({ content }: RichTextBlockContentProps) => {
  if (content) {
    return (
      <article className="prose">
        <HtmlContent value={content} />
      </article>
    )
  }

  return (
    <p>
      <T
        id="Blocks.RichText.InsertRichTextPlaceholder"
        defaultMessage="Insert rich text in this block"
      />
    </p>
  )
}

export const RichTextBlock: BC<RichTextBlockProps> = ({
  textColor,
  backgroundColor,
  content,
  hidden,
  wrapper,
  ...rest
}) => {
  const { mode } = useSlate()

  if (hidden) {
    return null
  }
  if (!content && mode === 'live') {
    return null
  }

  const style: { background?: string } = {}
  if (backgroundColor) {
    style.background = backgroundColor
  }

  switch (wrapper) {
    case 'card':
      return (
        <Card
          className={clsx(
            'overflow-hidden',
            textColor === 'light' && 'text-white',
            textColor === 'dark' && backgroundColor && 'text-black',
          )}
          style={style}
          {...rest}
        >
          <RichTextBlockContent content={content} />
        </Card>
      )
    case 'none':
      return <RichTextBlockContent content={content} {...rest} />
    case 'card-with-padding':
      return (
        <Card
          className={clsx(
            'overflow-hidden',
            textColor === 'light' && 'text-white',
            textColor === 'dark' && backgroundColor && 'text-black',
          )}
          style={style}
          {...rest}
        >
          <Card.Content>
            <RichTextBlockContent content={content} />
          </Card.Content>
        </Card>
      )
    default: {
      // Code might reach the default case for legacy blocks
      // To handle exhaustiveCheck, we are assigning never to the variable
      // The lint ignore is needed because we are not actually using this variable
      // eslint-disable-next-line unused-imports/no-unused-vars
      const exhaustiveCheck: never = wrapper

      return (
        <Card
          className={clsx(
            'overflow-hidden',
            textColor === 'light' && 'text-white',
            textColor === 'dark' && backgroundColor && 'text-black',
          )}
          style={style}
          {...rest}
        >
          <Card.Content>
            <RichTextBlockContent content={content} />
          </Card.Content>
        </Card>
      )
    }
  }
}
