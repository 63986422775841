import { SlateRenderer } from '@tribeplatform/react-slate-kit/components'
import { useSlate } from '@tribeplatform/react-slate-kit/hooks'
import { SlateProps } from '@tribeplatform/react-slate-kit/types'

import sampleCollectionSlate from '../../samples/collection-slate.json'

type CollectionPageProps = {
  id: string
}

export const CollectionPage = ({ id }: CollectionPageProps) => {
  const { context } = useSlate()

  return (
    <SlateRenderer
      slate={sampleCollectionSlate as unknown as SlateProps['slate']}
      context={{ ...context, collectionId: id }}
    />
  )
}
