import { lazy } from 'react'

import { PostListOrderByEnum } from '@tribeplatform/gql-client/types'
import { I18nShape } from '@tribeplatform/react-components/i18n'
import { PostListProps } from '@tribeplatform/react-components/PostList'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { PostListBlock as Component } from './Block.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.PostListBlockSettings })),
)

export const RegisteredPostListBlock = (
  intl: I18nShape,
): RegisteredBlock<PostListProps> => {
  const { $t } = intl

  return {
    recommendedName: 'PostList',
    Component,
    config: {
      type: BlockType.Block,
      displayName: $t({
        id: 'Blocks.PostList.DisplayName',
        defaultMessage: 'Post list',
      }),
      deprecated: true,
      Settings,
      Icon: <SvgIcon name="posts" />,
      defaultProps: {
        view: 'card',
        orderBy: PostListOrderByEnum.publishedAt,
      },
    },
  }
}
