import { type IconName } from '@bettermode/icons/types'

export const semanticIconMap = {
  block: 'cube',
  'block-accordion': 'rows',
  space: 'cube',
  settings: 'cog',
  'account-setting': 'sliders',
  analytics: 'chart',
  app: 'drawing-compass',
  apps: 'drawing-compass',
  collection: 'grid',
  member: 'user',
  'member-add': 'user-add',
  'member-remove': 'user-remove',
  members: 'users',
  moderation: 'shield-check',
  'moderation-pending-user': 'user-left',
  'moderation-pending-post': 'document-left',
  'moderation-reported-user': 'user-ban',
  'moderation-reported-post': 'document-ban',
  identification: 'user-square',
  post: 'document-text-folded-corner',
  posts: 'document-text-folded-corner',
  'post-type': 'document-text',
  'post-move': 'document-right',
  appearance: 'colors',
  attachment: 'paperclip',
  'audit-log': 'shield-exclamation',
  'badge-check': 'check-verified',
  content: 'folder',
  fullscreen: 'corners-out',
  'fullscreen-exit': 'corners-in',
  customizer: 'layout-header-sidebar',
  'device-desktop': 'monitor',
  message: 'message-text-circle',
  menu: 'bars',
  field: 'bars-uneven-middle',
  'field-text': 'bars-uneven-last',
  edit: 'pencil',
  translate: 'globe',
  reply: 'message-text-square',
  undo: 'reverse-left',
  redo: 'reverse-right',
  embed: 'safari-line',
  'status-error': 'x-circle',
  'status-success': 'check-circle',
  'status-info': 'alert-circle',
  'status-warning': 'alert-triangle',
  help: 'help-circle',
  info: 'info-circle',
  support: 'life-buoy',
  template: 'layout-quadrants',
  delete: 'trash',
  unlock: 'lock-unlocked',
  video: 'clapperboard',
} satisfies Record<string, IconName>

export type SemanticIconName = keyof typeof semanticIconMap
