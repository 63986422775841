export enum CollectionMenuViewStyle {
  Simple = 'simple',
  Collapsible = 'collapsible',
  Heading = 'heading',
}

export enum CollectionMenuSpaceFilter {
  All = 'all',
  Joined = 'joined',
  NotJoined = 'notJoined',
}

export type CollectionMenuItem = {
  id: string
  enabled: boolean
}

export type CollectionMenuDetailedItem = CollectionMenuItem & {
  name: string
}

export type CollectionMenuProps = {
  items?: CollectionMenuItem[]
  viewStyle: CollectionMenuViewStyle
  spaceFilter?: CollectionMenuSpaceFilter
}
