import { useCallback } from 'react'

import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { getCollectionsKey } from '../../utils/keys/collection.key.js'
import { getSpacesKey, getSpaceKey } from '../../utils/keys/space.key.js'

export const useInvalidateSpaceQuery = () => {
  const queryClient = useQueryClient()
  const invalidate = useCallback(
    (spaceId: string) => {
      const collectionsKey = getCollectionsKey()
      const spacesKey = getSpacesKey()
      queryClient.invalidateQueries(spacesKey)

      const spaceKey = getSpaceKey({ variables: { id: spaceId } })
      queryClient.invalidateQueries(spaceKey)

      queryClient.invalidateQueries(collectionsKey)
    },
    [queryClient],
  )

  return invalidate
}
