import { clsx } from 'clsx'

import { Space } from '@tribeplatform/gql-client/types'
import {
  BannerPlaceholder,
  EntityImage,
} from '@tribeplatform/react-components/common/components'
import type { StaticField, View } from '@tribeplatform/react-components/Views'
import { Avatar } from '@tribeplatform/react-ui-kit/Avatar'

import { getGlyph, getGlyphSize, getMedia } from '../utils/index.js'

interface Props {
  field: StaticField
  space: Space
  highlighted: boolean
  aspectRatio: StaticField['settings']['aspectRatio']
  gallerySize: View['gallerySize']
}
export const GalleryBanner = ({
  field,
  space,
  highlighted,
  aspectRatio,
  gallerySize,
}: Props) => {
  const bannerSource = field.settings?.bannerSource
  const mediaUrl = getMedia({
    source: field.settings?.bannerSource,
    space,
  })
  const glyph = getGlyph({
    source: bannerSource,
    space,
  })
  const glyphSize = getGlyphSize(gallerySize)

  if (mediaUrl || bannerSource !== 'image') {
    switch (field.settings?.bannerStyle) {
      case 'banner':
        return (
          <div
            className={clsx(
              'w-full relative',
              aspectRatio || 'aspect-video',
              highlighted && 'border-2 border-focused',
            )}
          >
            {!mediaUrl ? (
              <div className="absolute inset-0">
                <BannerPlaceholder name={space?.relativeUrl} />
              </div>
            ) : (
              <div
                className="w-full h-full bg-cover bg-center absolute top-0 start-0"
                style={{ backgroundImage: `url(${mediaUrl})` }}
              ></div>
            )}
          </div>
        )
      case 'square':
        return (
          <div className="pb-4 relative">
            {!mediaUrl ? (
              <BannerPlaceholder
                className={clsx(
                  'h-16 w-16 flex justify-center items-center rounded-base',
                  'overflow-hidden',
                  highlighted && 'ring-2 ring-offset-surface ring-focused',
                )}
                name={space?.relativeUrl}
              />
            ) : (
              <Avatar src={mediaUrl} size="4x" rounded="md" />
            )}
          </div>
        )
      default:
        // Code should never reach the default case
        // eslint-disable-next-line no-case-declarations
        const exhaustiveCheck: never = field.settings?.bannerStyle
        return exhaustiveCheck
    }
  }

  switch (field.settings?.bannerStyle) {
    case 'banner':
      return (
        <div
          className={clsx(
            'w-full relative',
            aspectRatio || 'aspect-video',
            highlighted && 'border-2 border-focused',
          )}
        >
          <div className="w-full h-full flex justify-center items-center text-content-on-background">
            <EntityImage entity={{ image: glyph }} size={glyphSize} />
          </div>
        </div>
      )
    case 'square':
      return (
        <div className="pb-4 relative">
          <div
            className={clsx(
              'h-16 w-16 flex justify-center items-center rounded-base text-content-on-background',
              highlighted && 'ring-2 ring-offset-surface ring-focused',
            )}
          >
            <EntityImage entity={{ image: glyph }} size="4x" />
          </div>
        </div>
      )
    default:
      // Code should never reach the default case
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = field.settings?.bannerStyle
      return exhaustiveCheck
  }
}
