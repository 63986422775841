import { DocumentNode } from 'graphql'
import { gql } from 'graphql-tag'

import { MemberFields, memberGQLFields } from '../network/index.js'
import {
  LeaderboardFields,
  leaderboardGQLFields,
} from './leaderboard.fields.js'

export function getLeaderboardGQLQuery(fields: MemberFields): DocumentNode {
  return gql`
    query getLeaderboard(
      $input: ReportInput!
    ){
      leaderboard(input: $input) {
        ${memberGQLFields(fields)}
      }
    }
  `
}

export const getLeaderboardWithScoresGQLQuery = (
  fields: LeaderboardFields,
) => gql`
  query LeaderboardWithScores(
    $includeAuthMember: Boolean!
    $input: ReportInput!
  ) {
    leaderboardWithScores(
      includeAuthMember: $includeAuthMember
      input: $input
    ) {
      ${leaderboardGQLFields(fields)}
    }
  }
`
