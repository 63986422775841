import type {
  NetworkVisibility,
  RoleType,
  MemberEmailStatus,
} from '@bettermode/common/gql/generated'

import { useHardRefresh } from '../hooks/useHardRefresh.js'
import { usePrivateNetworkGuard } from '../hooks/usePrivateNetworkGuard.js'
import { useRedirectAuthUser } from '../hooks/useRedirectAuthUser.js'
import { useRedirectUnconfirmedUser } from '../hooks/useRedirectUnconfirmedUser.js'

interface Props {
  subfolder: string
  roleType: RoleType
  visibility: NetworkVisibility
  emailStatus: MemberEmailStatus
  memberId: string
}
export const RouteGuard = ({
  subfolder,
  roleType,
  visibility,
  emailStatus,
  memberId,
}: Props) => {
  useHardRefresh({ pathPrefixes: ['/auth/'], subfolder })
  usePrivateNetworkGuard(roleType, visibility)
  useRedirectAuthUser(roleType)
  useRedirectUnconfirmedUser({ emailStatus, roleType, memberId })

  return <></>
}
