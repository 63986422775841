import { useMemo, useState } from 'react'

import { clsx } from 'clsx'

import type { CustomReaction, Post } from '@tribeplatform/gql-client/types'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Popover } from '@tribeplatform/react-ui-kit/Popover'

import { EntityImage } from '../../common/components/EntityImage.js'
import { convertIdToGlyph } from '../../MediaPicker/utils/utils.js'
import type { ReactAction } from '../../Views/types.js'
import { getTextIconSettings } from '../utils/utils.js'

interface Props {
  post: Post
  action: ReactAction
  disabled: boolean
  onReact: (key: string, shouldRemove: boolean, shouldReplace: boolean) => void
}
export const MultipleReactionAction = ({
  post,
  action,
  disabled,
  onReact,
}: Props) => {
  const {
    defaultLabel,
    variant,
    glyphId,
    color,
    activeLabel,
    activeGlyphId,
    activeColor,
  } = action
  const customReactions = action.reactions
  const [showEmojiOptions, setShowEmojiOptions] = useState(false)
  const { showText, showIcon } = getTextIconSettings(variant)
  const activeReactions = customReactions.filter(({ key }) =>
    post?.reactions?.some(
      reaction => reaction.reaction === key && !!reaction?.reacted,
    ),
  )
  const hasReacted = activeReactions.length > 0
  const {
    glyphId: selectedGlyphId,
    name: selectedName,
    activeGlyphId: selectedActiveGlyphId,
    activeName: selectedActiveName,
    activeColor: selectedActiveColor,
  } = useMemo(() => {
    if (activeReactions.length > 0) {
      const [firstReaction] = activeReactions
      return firstReaction
    }

    return {} as CustomReaction
  }, [activeReactions])

  const handleChange = (key: string) => {
    const remove = post?.reactions?.some(
      reaction => reaction?.reaction === key && !!reaction?.reacted,
    )
    const replace = !!action.isSingleReaction
    onReact(key, remove, replace)
  }

  return (
    <Popover
      activator={
        <Button
          variant="secondaryNeutral"
          className="group select-none"
          pressed={hasReacted}
          disabled={disabled}
          size="lg"
          fullWidth
          leadingIcon={
            showIcon &&
            (hasReacted ? (
              <EntityImage
                entity={{
                  image: convertIdToGlyph(
                    selectedActiveGlyphId ||
                      selectedGlyphId ||
                      activeGlyphId ||
                      glyphId,
                  ),
                  name:
                    selectedActiveName ||
                    selectedName ||
                    activeLabel ||
                    (defaultLabel ?? ''),
                }}
                size="xl"
                displayColor={selectedActiveColor || activeColor}
                iconStyle="solid"
              />
            ) : (
              <EntityImage
                entity={{
                  image: convertIdToGlyph(glyphId),
                  name: defaultLabel || '',
                }}
                size="xl"
                displayColor={color}
              />
            ))
          }
          onClick={() => setShowEmojiOptions(true)}
        >
          {showText && (
            <span className="truncate">
              {hasReacted
                ? selectedActiveName ||
                  selectedName ||
                  activeLabel ||
                  defaultLabel
                : defaultLabel}
            </span>
          )}
        </Button>
      }
      open={showEmojiOptions}
      onClose={() => setShowEmojiOptions(false)}
      placement="top"
      padding="sm"
      className="flex-1 flex group"
      wrapperClassName="flex-1"
      roundedStyleButton
    >
      <div className="flex gap-1 p-2">
        {customReactions.map(({ key, glyphId }) => (
          <button
            type="button"
            key={key}
            className={clsx(
              'p-1 transform transition-transform',
              activeReactions.some(reaction => reaction.key === key)
                ? 'p-2 rounded-full bg-action-neutral-pressed inline-flex justify-center items-center'
                : 'hover:scale-125',
            )}
            onClick={() => {
              handleChange(key)
              setShowEmojiOptions(false)
            }}
          >
            <EntityImage
              entity={{
                image: convertIdToGlyph(glyphId),
                name: defaultLabel ?? '',
              }}
              size="xl"
              displayColor={activeColor}
            />
          </button>
        ))}
      </div>
    </Popover>
  )
}
