import { ComponentProps, FC, forwardRef } from 'react'

import { clsx } from 'clsx'

import { SvgIcon } from '../Icon/SvgIcon.js'
import { HTMLTribeProps } from '../types/index.js'
import { FloatingWrapper } from '../utils/floating/index.js'
import { getValidChildren, runIfFn } from '../utils/index.js'
import { useAutocomplete } from './AutocompleteContext.js'

export type AutocompleteItemsProps = ComponentProps<'ul'> & {
  variant?: 'default' | 'unstyled'
}

export const AutocompleteItems: FC<AutocompleteItemsProps> = forwardRef(
  (props, ref) => {
    const { children, className, variant = 'default', ...rest } = props

    const { isOpen, inputValue, getMenuProps } = useAutocomplete()

    const validChildren = getValidChildren(children)

    if (variant === 'unstyled') {
      return (
        <ul
          className={clsx(
            'bg-surface overflow-auto',
            'focus-visible:ring-1 focus:outline-none',
            (!isOpen || validChildren?.length === 0) && 'hidden',
            className,
          )}
          {...getMenuProps(
            { open: isOpen, ref, ...rest },
            { suppressRefError: true },
          )}
        >
          {(() => {
            if (!isOpen) {
              return null
            }

            if (!inputValue) {
              return (
                <AutocompleteItemsEmpty>
                  Please enter a search query
                </AutocompleteItemsEmpty>
              )
            }

            return validChildren
          })()}
        </ul>
      )
    }

    return (
      <FloatingWrapper open={isOpen} sameWidth>
        <ul
          className={clsx(
            'w-full bg-surface shadow-popover max-h-96 rounded-base text-md border border-line-subdued overflow-auto',
            'focus-visible:ring-1 focus:outline-none',
            (!isOpen || validChildren?.length === 0) && 'hidden',
            className,
          )}
          {...getMenuProps(
            { open: isOpen, ref, ...rest },
            { suppressRefError: true },
          )}
        >
          {(() => {
            if (!isOpen) {
              return null
            }

            if (!inputValue) {
              return (
                <AutocompleteItemsEmpty>
                  Please enter a search query
                </AutocompleteItemsEmpty>
              )
            }

            return validChildren
          })()}
        </ul>
      </FloatingWrapper>
    )
  },
)

export type AutocompleteItemProps<T> = Omit<ComponentProps<'li'>, 'value'> & {
  value: T
  index?: number
  disabled?: boolean
}
export const AutocompleteItem: FC<AutocompleteItemProps<unknown>> = props => {
  const { value, children, disabled, index, className, ...rest } = props

  const { selectedItem, getItemProps, highlightedIndex } = useAutocomplete()

  const selected = selectedItem === value
  const active = highlightedIndex === index

  return (
    <li
      className={clsx(
        active
          ? 'text-content-hovered bg-surface-hovered'
          : 'text-content-subdued bg-surface',
        'cursor-default select-none relative py-2 ps-3 pe-9',
        className,
      )}
      {...getItemProps({ item: value, index, disabled, ...rest })}
    >
      <>
        <span
          className={clsx(
            selected ? 'font-semibold' : 'font-normal',
            disabled ? 'opacity-50' : '',
            'block truncate',
          )}
        >
          {runIfFn(children, {
            selected,
            active,
          })}
        </span>

        {selected ? (
          <span
            className={clsx(
              active ? 'text-content-subdued' : 'text-content-subdued',
              'absolute inset-y-0 end-0 flex items-center pe-4',
            )}
          >
            <SvgIcon size="lg" name="check" />
          </span>
        ) : null}
      </>
    </li>
  )
}

export type AutocompleteItemsEmptyProps = HTMLTribeProps<'div'>

export const AutocompleteItemsEmpty: FC<
  AutocompleteItemsEmptyProps
> = props => {
  const { children, className, ...rest } = props

  return (
    <div
      className={clsx(
        'text-content',
        'cursor-default select-none relative py-2 ps-3 pe-9',
        className,
      )}
      {...rest}
    >
      <span className="font-normal block truncate">{children}</span>
    </div>
  )
}
