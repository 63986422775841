import type { Member } from '@tribeplatform/gql-client/types'
import type { StaticField, View } from '@tribeplatform/react-components/Views'
import { Carousel } from '@tribeplatform/react-ui-kit/Carousel'

import { getCardWidth } from '../utils/utils.js'
import { MembersGalleryItem } from './MembersGalleryItem.js'

interface Props {
  members: Member[]
  fields: StaticField[]
  gallerySize: View['gallerySize']
  activeFieldId?: string
  showMoreButton: JSX.Element
}
export const MembersCarousel = ({
  members,
  fields,
  gallerySize = 'medium',
  activeFieldId,
  showMoreButton,
}: Props) => {
  const width = getCardWidth(gallerySize)

  return (
    <Carousel
      className="pt-1 pb-4"
      items={[
        ...members.map(member => (
          <MembersGalleryItem
            key={member.id}
            width={width}
            member={member}
            fields={fields.filter(
              field =>
                field.enabled &&
                !(field.styles && field.styles.indexOf('carousel') === -1),
            )}
            activeFieldId={activeFieldId}
            className="sm:rounded-lg"
          />
        )),
        showMoreButton,
      ]}
    />
  )
}
