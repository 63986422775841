import type { Tag } from '@tribeplatform/gql-client/types'
import { useSpaceTags } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { InlineMultiselect } from '@tribeplatform/react-ui-kit/Multiselect'

import { T } from '../../../i18n/index.js'

interface Props {
  value: string[]
  options: string[]
  className?: string
  onChange: (tagIds: string[]) => void
}
export const InlineTagPickerFromIds = ({
  value,
  options,
  className,
  onChange,
}: Props) => {
  const { data, isInitialLoading } = useSpaceTags({
    variables: { ids: options, limit: options.length },
    useInfiniteQueryOptions: {
      enabled: options.length > 0,
    },
  })
  const { nodes: tagOptions } = simplifyPaginatedResult<Tag>(data)

  const handleClick = (tagId: string) => {
    if (value.includes(tagId)) {
      const newValue = value.filter(id => id !== tagId)
      onChange(newValue)
      return
    }

    const newValue = [...value, tagId]
    onChange(newValue)
  }

  return (
    <InlineMultiselect className={className}>
      <InlineMultiselect.Items isLoading={isInitialLoading}>
        {!data && isInitialLoading && (
          <div className="text-sm text-content-subdued flex justify-center">
            <SvgIcon className="animate-spin" size="lg" name="spinner" />
          </div>
        )}
        {!isInitialLoading && data && tagOptions.length === 0 && (
          <div className="text-sm text-content-subdued">
            <T id="Generics.NoResults" defaultMessage="No results" />
          </div>
        )}
        {tagOptions.map(tag => (
          <InlineMultiselect.Item
            key={tag.id}
            selected={value.includes(tag.id)}
            onSelect={() => handleClick(tag.id)}
          >
            <div className="truncate text-base">{tag.title}</div>
          </InlineMultiselect.Item>
        ))}
      </InlineMultiselect.Items>
    </InlineMultiselect>
  )
}
