import { ReactNode } from 'react'

import { Menu } from '@headlessui/react'
import { VariantProps } from 'class-variance-authority'
import { clsx } from 'clsx'

import { Hotkey } from '../Hotkey/Hotkey.js'
import { Icon } from '../Icon/index.js'
import type { IconComponentType } from '../Icon/index.js'
import { HTMLTribeProps } from '../types/index.js'
import { dropdownItemStyles } from './Dropdown.styles.js'
import { TitledItemDescriptor } from './types.js'

export type DropdownItemProps = Omit<
  HTMLTribeProps<'button'>,
  'children' | 'title'
> &
  VariantProps<typeof dropdownItemStyles> &
  TitledItemDescriptor & {
    disabled?: boolean
    leadingIcon?: IconComponentType
    trailingAddon?: ReactNode
    hotkey?: string
  }

export const Item = ({
  as,
  children,
  leadingIcon,
  trailingAddon,
  disabled,
  destructive,
  className,
  title,
  description,
  truncate,
  hotkey,
  ...rest
}: DropdownItemProps) => {
  const Component = as || 'button'
  const type = Component === 'button' ? 'button' : undefined

  const trailingElement = hotkey ? (
    <Hotkey className="ms-3 shrink-0">{hotkey}</Hotkey>
  ) : (
    trailingAddon
  )

  return (
    <Menu.Item disabled={disabled}>
      <Component
        type={type}
        className={dropdownItemStyles({
          destructive,
          className,
        })}
        disabled={disabled}
        {...rest}
      >
        {leadingIcon && (
          <Icon size="lg" className="me-2">
            {leadingIcon}
          </Icon>
        )}
        <div
          className={clsx('grow min-w-0 break-words', truncate && 'truncate')}
        >
          {title ? (
            <>
              <div
                className={clsx(
                  'text-content break-words',
                  truncate && 'truncate',
                )}
              >
                {title}
              </div>
              <div
                className={clsx(
                  'text-content-subdued text-label-xs break-words',
                  truncate && 'truncate',
                )}
              >
                {description}
              </div>
            </>
          ) : (
            children
          )}
        </div>
        {trailingElement}
      </Component>
    </Menu.Item>
  )
}
