import { T } from '@tribeplatform/react-components/i18n'
import { FormControl } from '@tribeplatform/react-ui-kit/FormControl'

interface Props {
  restrict?: boolean
  onChange?: (value: boolean) => unknown
}

export const RestrictMaxWidthSettings = ({ restrict, onChange }: Props) => {
  return (
    <FormControl.Toggle
      name="restrictMaxWidth"
      label={
        <T
          id="Blocks.Section.Settings.Responsive.RestrictMaxWidth"
          defaultMessage="Restrict max width"
        />
      }
      helperText={
        <T
          id="Blocks.Section.Settings.Responsive.RestrictMaxWidthHelperText"
          defaultMessage="Restrict the maximum width of the section on larger screens."
        />
      }
      checked={restrict}
      onChange={onChange}
    />
  )
}
