import { TITLE_FIELD_KEY } from '@bettermode/common/CMS'
import {
  type CustomFieldSchema,
  CustomFieldType,
  RelationTypeOptions,
} from '@tribeplatform/gql-client/types'

import {
  convertToSelectOptions,
  getColoredLabels,
  isFieldPrivate,
} from '../../CMS/utils/utils.js'
import { getFieldIcon, getFieldSetting } from '../../CustomField/utils.js'
import type { RawFilter, Filter } from '../types.js'

const DYNAMIC_FILTER_PREFIX = 'fields'

const canUseAsFilter = ({
  archived,
  type,
  key,
  typeOptions,
  items,
  readPrivacy,
}: CustomFieldSchema) => {
  const isRichText = type === CustomFieldType.richText
  const isTitle = key === TITLE_FIELD_KEY
  const isMedia =
    type === CustomFieldType.relation &&
    typeOptions?.relationType === RelationTypeOptions.Media
  const isMediaArray =
    type === CustomFieldType.array &&
    items?.type === CustomFieldType.relation &&
    items?.typeOptions?.relationType === RelationTypeOptions.Media
  const isPrivate = isFieldPrivate(readPrivacy)

  if (
    archived ||
    isRichText ||
    isTitle ||
    isMedia ||
    isMediaArray ||
    isPrivate
  ) {
    return false
  }

  return true
}

const getFieldSelectOptions = (
  field: CustomFieldSchema,
): Filter['extraProps']['selectOptions'] => {
  const coloredLabels = getColoredLabels(field)
  const selectOptions = convertToSelectOptions(coloredLabels)

  return selectOptions
}

export const getDynamicFilters = (customFields: CustomFieldSchema[]) => {
  if (!customFields) {
    return []
  }

  const dynamicFilters: RawFilter[] = customFields
    .filter(canUseAsFilter)
    .map<RawFilter>((field, index) => {
      const { key, name, type, typeOptions } = field
      const subType = getFieldSetting(field, 'subtype') as Filter['subType']
      const selectOptions = getFieldSelectOptions(field)
      const memberGroup = getFieldSetting(field, 'inputProps')
        ?.memberGroup as Filter['extraProps']['memberGroup']
      const rawFilter: RawFilter = {
        id: `${DYNAMIC_FILTER_PREFIX}.${key}.${index}`,
        key: `${DYNAMIC_FILTER_PREFIX}.${key}`,
        name,
        type,
        subType,
        icon: getFieldIcon(field),
        extraProps: {
          selectOptions,
          memberGroup,
          typeOptions,
        },
      }

      return rawFilter
    })

  return dynamicFilters
}
