import { useRouter } from '@tribeplatform/react-sdk'
import { Button } from '@tribeplatform/react-ui-kit/Button'

import {
  ButtonActionType,
  Target,
  type ButtonAction as TButtonAction,
} from '../types.js'

export const ButtonAction = ({ name, type, url, target }: TButtonAction) => {
  const { Link } = useRouter()

  if (!name) {
    return null
  }

  if (type === ButtonActionType.textLink) {
    return (
      <Link
        variant="accent"
        href={url}
        target={target === Target.blank ? '_blank' : '_self'}
        tabIndex={0}
      >
        {name}
      </Link>
    )
  }

  return (
    <Button
      variant={
        type === ButtonActionType.primaryButton ? 'primary' : 'secondary'
      }
      textAlign="center"
      as={props => <Link {...props} variant="inherit" />}
      href={url}
      target={target === Target.blank ? '_blank' : '_self'}
      tabIndex={0}
    >
      {name}
    </Button>
  )
}
