import type { Post } from '@tribeplatform/gql-client/types'
import { noop } from '@tribeplatform/react-ui-kit/hooks'

import { ActionTypes, type ActionBarAction } from '../../Views/types.js'
import { FollowAction } from '../Actions/FollowAction.js'
import { ReactAction } from '../Actions/ReactAction.js'
import { ShareAction } from '../Actions/ShareAction.js'

interface Props {
  action: ActionBarAction
  post: Post
}
export const ActionRenderer = ({ action, post }: Props) => {
  const { type, enabled } = action

  if (!enabled) {
    return null
  }

  switch (type) {
    case ActionTypes.react:
      return <ReactAction post={post} action={action} />
    case ActionTypes.follow:
      return <FollowAction post={post} action={action} />
    case ActionTypes.share:
      return <ShareAction post={post} action={action} />
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = type
      noop(exhaustiveCheck)
      return null
    }
  }
}
