import { Avatar } from '@tribeplatform/react-ui-kit/Avatar'

import { MembersAvatarSize } from '../../types.js'

interface Props {
  size?: MembersAvatarSize
  count: number
}

export const MembersFieldSkeleton = ({ size, count }: Props) => {
  return (
    <div className="flex -space-x-2 items-stretch">
      {Array.from({ length: count }).map((_, index) => (
        <Avatar
          size={size}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          fallback={() => (
            <div className="animate-pulse rounded-full bg-skeleton w-full h-full" />
          )}
        />
      ))}
    </div>
  )
}
