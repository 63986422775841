import { clsx } from 'clsx'

import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { useSpace } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { PublishDateSettings } from '../../Fields/types.js'
import { formatCreationDate } from '../../utils/utils.js'

interface Props {
  className?: string
  settings: PublishDateSettings
  spaceId: string
}

export const CreationDateField = ({ className, settings, spaceId }: Props) => {
  const { data: space } = useSpace({
    variables: {
      id: spaceId,
    },
    useQueryOptions: {
      enabled: !!spaceId,
    },
  })
  const { localeCode } = useI18n()
  const { format } = settings
  const formattedDate = formatCreationDate(space?.createdAt, format, localeCode)

  return (
    <div className={clsx(className, 'flex items-center gap-1')}>
      <SvgIcon name="calendar" size="lg" />
      <span className="whitespace-nowrap">
        {format === 'relative' ? (
          <T
            id="SpaceHeader.CreationDate.Created"
            defaultMessage="created {creation_date}"
            values={{
              creation_date: formattedDate,
            }}
          />
        ) : (
          <T
            id="SpaceHeader.CreationDate.CreatedOn"
            defaultMessage="created on {creation_date}"
            values={{
              creation_date: formattedDate,
            }}
          />
        )}
      </span>
    </div>
  )
}
