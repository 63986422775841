import type { PostTypeFields } from '@tribeplatform/gql-client/graphql'
import type {
  PaginatedPostType,
  QueryPostTypesArgs,
} from '@tribeplatform/gql-client/types'

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '../../lib/react-query/useInfiniteQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPostTypesKey } from '../../utils/keys/post.key.js'

export const usePostTypes = (options: {
  variables: QueryPostTypesArgs
  fields?: PostTypeFields
  useInfiniteQueryOptions?: UseInfiniteQueryOptions<PaginatedPostType>
}) => {
  const { variables, fields = 'basic', useInfiniteQueryOptions } = options || {}
  const { client } = useTribeClient()
  const postTypesKey = getPostTypesKey({ variables, fields })

  return useInfiniteQuery<PaginatedPostType>(
    postTypesKey,
    ({ pageParam: after }) =>
      client?.postType.list({ after, ...variables }, fields),
    useInfiniteQueryOptions,
  )
}
