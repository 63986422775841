import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Fields,
  Tag,
  MutationCreateTagArgs,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getTagsKey } from '../../utils/keys/post.key.js'

export const useCreateTag = (options?: {
  fields?: Fields.TagFields
  useMutationOptions?: UseMutationOptions<
    Tag,
    ClientError,
    MutationCreateTagArgs
  >
}) => {
  const { useMutationOptions, fields = 'basic' } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()
  const tagsKey = getTagsKey()

  return useMutation<Tag, ClientError, MutationCreateTagArgs>(
    variables =>
      client.mutation({
        name: 'createTag',
        args: {
          fields,
          variables,
        },
      }),
    {
      onMutate: () => {
        queryClient.cancelQueries(tagsKey)
      },
      onSettled: () => {
        queryClient.invalidateQueries(tagsKey)
      },
      ...useMutationOptions,
    },
  )
}
