import { useEffect, useState } from 'react'

import { Form as DumbForm } from '@tribeplatform/react-components/Form'
import { useI18n } from '@tribeplatform/react-components/i18n'
import { useTribeClient } from '@tribeplatform/react-sdk'
import { ExtraInteractionsEvents } from '@tribeplatform/react-sdk/types'

import { useSlate } from '../../hooks/index.js'
import { BC, CallbackExtraContext } from '../../types/index.js'
import { FormProps } from './types.js'

export const FormBlock: BC<FormProps> = ({
  callbackId,
  defaultValues: uncleanDefaultValues,
  children,
  ...rest
}) => {
  const { $t } = useI18n()
  const { config } = useTribeClient()
  const {
    interactionsContext: { interactionEmitter },
  } = config ?? {}
  const [loading, setLoading] = useState(false)
  const {
    extraContext: {
      slate: { callback },
    },
  } = useSlate<CallbackExtraContext>()

  let defaultValues: Record<string, unknown> = {}
  try {
    defaultValues =
      typeof uncleanDefaultValues === 'string'
        ? JSON.parse(uncleanDefaultValues)
        : uncleanDefaultValues
    // eslint-disable-next-line no-empty
  } catch {}

  useEffect(() => {
    const errorInteractionListenerHandler = () => {
      setLoading(false)
    }

    const appInteractionCalledListenerHandler = () => {
      setLoading(true)
    }

    if (interactionEmitter) {
      interactionEmitter.on(
        ExtraInteractionsEvents.InteractionError,
        errorInteractionListenerHandler,
      )
      interactionEmitter.on(
        ExtraInteractionsEvents.InteractionCalled,
        appInteractionCalledListenerHandler,
      )
    }

    return () => {
      if (interactionEmitter) {
        interactionEmitter.removeListener(
          ExtraInteractionsEvents.InteractionError,
          errorInteractionListenerHandler,
        )
        interactionEmitter.removeListener(
          ExtraInteractionsEvents.InteractionCalled,
          appInteractionCalledListenerHandler,
        )
      }
    }
  }, [interactionEmitter, setLoading, $t])

  return (
    <DumbForm
      loading={loading}
      onSubmit={data => callback(callbackId, { data })}
      defaultValues={defaultValues}
      {...rest}
    >
      {children}
    </DumbForm>
  )
}
