import { JwtDecode } from './jwt-decode/index.js'

export interface AccessToken {
  id: string
  networkId: string
  networkDomain: string
  role: string
  roleId: string
  roleType: string
  iat: number
  exp: number
}

export const parseToken = (accessToken: string): AccessToken | undefined => {
  if (!accessToken) {
    return
  }
  try {
    return JwtDecode<AccessToken>(accessToken)
  } catch (e) {
    console.error('error - parsing token', accessToken, e)
  }
}
