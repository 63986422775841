import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { DividerBlock as Component } from './Block.js'
import { DividerProps } from './types.js'

export const RegisteredDividerBlock = (
  _intl: I18nShape,
): RegisteredBlock<DividerProps> => ({
  recommendedName: 'Divider',
  Component,
  config: {
    type: BlockType.Block,
    usable: false,
  },
})
