import { clsx } from 'clsx'

import { StoredField } from '../../Fields/types.js'
import { Alignment } from '../../types.js'
import { FieldRenderer } from './FieldRenderer.js'

interface Props {
  fields: Array<StoredField>
  alignment: Alignment
  activeFieldId: string | null
  spaceId: string
  isSimple?: boolean
}

export const GroupedFieldRenderer = ({
  fields,
  alignment,
  activeFieldId,
  spaceId,
  isSimple = false,
}: Props) => {
  return (
    <div
      className={clsx(
        'max-w-full flex basis-full flex-wrap @3xl:flex-nowrap gap-4 @3xl:gap-6',
        alignment === Alignment.Center ? 'justify-center' : 'justify-between',
      )}
    >
      {fields.map(field => (
        <FieldRenderer
          key={field.id}
          field={field}
          highlighted={field.id === activeFieldId}
          className="max-w-full @3xl:max-w-[70%]"
          spaceId={spaceId}
          isSimple={isSimple}
        />
      ))}
    </div>
  )
}
