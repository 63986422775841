import { CSSProperties, FC, lazy, Suspense } from 'react'

import { clsx } from 'clsx'

import {
  FormControl,
  FormControlProps,
} from '@tribeplatform/react-ui-kit/FormControl'
import { inputStyles } from '@tribeplatform/react-ui-kit/Input'

const Composer = lazy(() =>
  import('../Composer/Composer.js').then(m => ({ default: m.Composer })),
)
const ComposerControls = lazy(() =>
  import('../Composer/components/ComposerControls.js').then(m => ({
    default: m.ComposerControls,
  })),
)
const ComposerProvider = lazy(() =>
  import('../Composer/ComposerProvider.js').then(m => ({
    default: m.ComposerProvider,
  })),
)

export type FormControlHtmlEditorProps = FormControlProps & {
  value?: string
  onChange?: (value: string) => void
  className?: string
  style?: CSSProperties
}

export const FormControlHtmlEditor: FC<FormControlHtmlEditorProps> = ({
  placeholder,
  name,
  value,
  error,
  invalid,
  label,
  helperText,
  onChange,
  className,
  style,
}) => {
  return (
    <div className={clsx('space-y-1', className)}>
      {label && <FormControl.Label htmlFor={name}>{label}</FormControl.Label>}
      <Suspense fallback={<></>}>
        <ComposerProvider
          content={value}
          placeholder={placeholder}
          editorOptions={{
            onUpdate: ({ editor }) => {
              onChange(editor.isEmpty ? '' : editor.getHTML())
            },
          }}
          key={placeholder}
        >
          <div
            style={style}
            className={clsx(
              inputStyles({
                invalid,
              }),
              'flex flex-col gap-3 relative',
            )}
          >
            <Composer minLines={8} />
            <ComposerControls />
          </div>
        </ComposerProvider>
      </Suspense>
      <FormControl.Meta
        invalid={invalid}
        error={error}
        helperText={helperText}
      />
    </div>
  )
}
