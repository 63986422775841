import type { QueryFilters } from '../../lib/react-query/QueryFilters.js'
import { getSpaceKey, getSpacesKey } from '../../utils/keys/space.key.js'

// get all active spaces query
export const getSpacesQueryById: (
  spaceId: string,
) => QueryFilters = spaceId => ({
  type: 'active',
  predicate: query => {
    const [main, args] = query?.queryKey || []
    if (
      ((args as any)?.variables?.spaceId !== spaceId &&
        main === getSpaceKey()[0]) ||
      main === getSpacesKey()[0]
    ) {
      return true
    }
    return false
  },
})
