import { SectionLayout, Spacing } from '../types.js'

export const ColumnsPerLayout = {
  [SectionLayout.Single]: 1,
  [SectionLayout.OneVOne]: 2,
  [SectionLayout.OneVTwo]: 2,
  [SectionLayout.TwoVOne]: 2,
  [SectionLayout.Three]: 3,
  [SectionLayout.Four]: 4,
}

type Axis = 'x' | 'y'

type SpacingClassesProps = {
  gap: Spacing
  horizontalPadding: Spacing
  verticalPadding: Spacing
  horizontalMargin: Spacing
  verticalMargin: Spacing
}

export const getSpacingClasses = ({
  gap,
  horizontalMargin,
  verticalMargin,
  horizontalPadding,
  verticalPadding,
}: SpacingClassesProps) => {
  const className = `
  ${getGapClass(gap)} 
  ${getHorizontalPaddingClass(horizontalPadding)} 
  ${getVerticalPaddingClass(verticalPadding)} 
  ${getHorizontalMarginClass(horizontalMargin)} 
  ${getVerticalMarginClass(verticalMargin)}
  `

  return className
}

/**
 * Get the number of grid columns based on the layout.
 * The number of columns here and the span of each column
 * are correlated. So any changes here requires appropriate
 * changes in the getColumnSpan function in Column/utils.ts
 */
export const getNumberOfColumns = (layout: SectionLayout) => {
  switch (layout) {
    case SectionLayout.Single:
      return 'grid-cols-1'
    case SectionLayout.OneVOne:
      return 'grid-cols-2'
    case SectionLayout.TwoVOne:
    case SectionLayout.OneVTwo:
      return 'grid-cols-3'
    case SectionLayout.Three:
      return 'grid-cols-3'
    case SectionLayout.Four:
      return 'grid-cols-4'
    default: {
      const _exhaustiveCheck: never = layout
      return null
    }
  }
}

export const getPaddingClass = (padding: Spacing, axis: Axis) => {
  switch (padding) {
    case Spacing.None:
      return ''
    case Spacing.ExtraSmall:
      return `p${axis}-1 sm:p${axis}-1 lg:p${axis}-1`
    case Spacing.Small:
      return `p${axis}-2 sm:p${axis}-2.5 lg:p${axis}-3`
    case Spacing.Medium:
      return `p${axis}-3 sm:p${axis}-3.5 md:p${axis}-4 lg:p${axis}-5`
    case Spacing.Large:
      return `p${axis}-5 sm:p${axis}-6 md:p${axis}-7 lg:p${axis}-8`
    case Spacing.ExtraLarge:
      return `p${axis}-7 sm:p${axis}-8 md:p${axis}-9 lg:p${axis}-10`
    default: {
      // Code should never reach the default case
      const _exhaustiveCheck: never = padding
      return ''
    }
  }
}

export const getHorizontalPaddingClass = (padding: Spacing) =>
  getPaddingClass(padding, 'x')

export const getVerticalPaddingClass = (padding: Spacing) =>
  getPaddingClass(padding, 'y')

export const getMarginClass = (margin: Spacing, axis: Axis) => {
  switch (margin) {
    case Spacing.None:
      return ''
    case Spacing.ExtraSmall:
      return `m${axis}-1 sm:m${axis}-1 lg:m${axis}-1`
    case Spacing.Small:
      return `m${axis}-2 sm:m${axis}-2.5 lg:m${axis}-3`
    case Spacing.Medium:
      return `m${axis}-3 sm:m${axis}-3.5 md:m${axis}-4 lg:m${axis}-5`
    case Spacing.Large:
      return `m${axis}-5 sm:m${axis}-6 md:m${axis}-7 lg:m${axis}-8`
    case Spacing.ExtraLarge:
      return `m${axis}-7 sm:m${axis}-8 md:m${axis}-9 lg:m${axis}-10`
    default: {
      // Code should never reach the default case
      const _exhaustiveCheck: never = margin
      return ''
    }
  }
}

export const getHorizontalMarginClass = (margin: Spacing) =>
  getPaddingClass(margin, 'x')

export const getVerticalMarginClass = (margin: Spacing) =>
  getPaddingClass(margin, 'y')

export const getGapClass = (gap: Spacing) => {
  switch (gap) {
    case Spacing.None:
      return ''
    case Spacing.ExtraSmall:
      return 'gap-1 sm:gap-1 lg:gap-1'
    case Spacing.Small:
      return 'gap-2 sm:gap-2.5 md:gap-2 lg:gap-3'
    case Spacing.Medium:
      return 'gap-3 sm:gap-3.5 md:gap-4 lg:gap-5'
    case Spacing.Large:
      return 'gap-5 sm:gap-6 md:gap-7 lg:gap-8'
    case Spacing.ExtraLarge:
      return 'gap-7 sm:gap-8 md:gap-9 lg:gap-10'
    default: {
      // Code should never reach the default case
      const _exhaustiveCheck: never = gap
      return ''
    }
  }
}

/**
 * Gets the hidden class based on the breakpoints. The defaultDisplay
 * is used to determine display when not hidden. This has to be the same
 * as the current display of the element.
 */
export const getHideOnBreakpointClass = (
  hideOnMobile?: boolean,
  hideOnTablet?: boolean,
  hideOnDesktop?: boolean,
  defaultDisplay: string = 'block',
) => {
  const hideOnMobileClass = hideOnMobile ? `hidden sm:${defaultDisplay}` : ''
  const hideOnTabletClass = hideOnTablet ? `md:hidden lg:${defaultDisplay}` : ''
  const hideOnDesktopClass = hideOnDesktop ? `lg:hidden` : ''

  return `${hideOnMobileClass} ${hideOnTabletClass} ${hideOnDesktopClass}`
}

export const spacingToSliderNumber: Record<Spacing, number> = {
  [Spacing.None]: 0,
  [Spacing.ExtraSmall]: 1,
  [Spacing.Small]: 2,
  [Spacing.Medium]: 3,
  [Spacing.Large]: 4,
  [Spacing.ExtraLarge]: 5,
}

export const sliderNumberToSpacing: Record<number, Spacing> = {
  0: Spacing.None,
  1: Spacing.ExtraSmall,
  2: Spacing.Small,
  3: Spacing.Medium,
  4: Spacing.Large,
  5: Spacing.ExtraLarge,
}

export const cleanupClassName = (className: string) =>
  className.replace(/\s+/g, ' ').trim()
