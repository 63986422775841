import { ComponentProps, ComponentPropsWithoutRef, FC, ReactNode } from 'react'

import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react'

import { radioGroupItemsStyles } from './RadioGroup.styles.js'
import { RadioGroupsProvider, useRadioGroups } from './RadioGroupContext.js'
import { RadioGroupItem } from './RadioGroupItem.js'
import { RadioGroupVariant } from './types.js'

interface RadioGroupRenderProps {
  active: boolean
  selected: boolean
  disabled: boolean
}

export type RadioGroupProps<T = string> = Omit<
  ComponentPropsWithoutRef<'div'>,
  'onChange'
> & {
  value?: T
  onChange?: (newValue: T) => void
  defaultValue?: T
  children?: ReactNode | ((props: RadioGroupRenderProps) => ReactNode)
  disabled?: boolean
  variant?: RadioGroupVariant
  name?: string
}
/**
 * Radios are used when only one choice may be selected in a series of options.
 */
export const RadioGroup = ({
  children,
  variant = 'default',
  ...rest
}: RadioGroupProps) => {
  return (
    <HeadlessRadioGroup {...rest}>
      <RadioGroupsProvider variant={variant}>{children}</RadioGroupsProvider>
    </HeadlessRadioGroup>
  )
}

export type RadioGroupItemsProps = ComponentProps<'div'>

const RadioGroupItems: FC<RadioGroupItemsProps> = props => {
  const { children, className, ...rest } = props

  const { variant } = useRadioGroups()

  return (
    <div className={radioGroupItemsStyles({ variant, className })} {...rest}>
      {children}
    </div>
  )
}

RadioGroup.Items = RadioGroupItems
RadioGroup.Item = RadioGroupItem
