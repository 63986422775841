import { clsx } from 'clsx'

import { useBlock } from '@tribeplatform/react-slate-kit/hooks'

import { CardBanner } from '../fields/CardBanner.js'
import { CardSpaceDescription } from '../fields/CardSpaceDescription.js'
import { GalleryBanner } from '../fields/GalleryBanner.js'
import { GallerySpaceDescription } from '../fields/GallerySpaceDescription.js'
import { JoinButton } from '../fields/JoinButton.js'
import { ListImage } from '../fields/ListImage.js'
import { ListSpaceSummary } from '../fields/ListSpaceSummary.js'
import { MembersCount } from '../fields/MembersCount.js'
import { PostsCount } from '../fields/PostsCount.js'
import { SpaceTitle } from '../fields/SpaceTitle.js'
import { FieldProps, SpacesListBlockProps } from '../types.js'

export const Field = ({
  field,
  space,
  viewStyle,
  gallerySize,
  activeFieldId,
  preview,
}: FieldProps) => {
  const {
    extraContext: { activated },
  } = useBlock<SpacesListBlockProps, { activated: boolean }>()

  const stretchX = clsx(
    viewStyle === 'list' && field.settings?.stretchX && 'flex-1',
    viewStyle === 'gallery' && field.settings?.fullWidth && 'basis-full',
    viewStyle === 'carousel' && field.settings?.fullWidth && 'basis-full',
    viewStyle === 'card' && field.settings?.fullWidth && 'basis-full',
  )
  const highlighted = activated && activeFieldId === field.id

  switch (field.id) {
    case 'listImage':
      return <ListImage field={field} space={space} highlighted={highlighted} />
    case 'galleryBanner':
      return (
        <GalleryBanner
          field={field}
          aspectRatio={field.settings?.aspectRatio}
          space={space}
          highlighted={highlighted}
          gallerySize={gallerySize}
        />
      )
    case 'cardBanner':
      return (
        <CardBanner
          field={field}
          aspectRatio={field.settings?.aspectRatio}
          space={space}
          highlighted={highlighted}
          preview={preview}
        />
      )
    case 'listSpaceName':
    case 'gallerySpaceName':
      return (
        <SpaceTitle
          field={field}
          space={space}
          highlighted={highlighted}
          stretchX={stretchX}
          preview={preview}
        />
      )
    case 'listSpaceDescription':
      return (
        <GallerySpaceDescription
          field={field}
          space={space}
          highlighted={highlighted}
          stretchX={stretchX}
          lineClamp="sm"
          preview={preview}
        />
      )
    case 'gallerySpaceDescription':
      return (
        <GallerySpaceDescription
          field={field}
          space={space}
          highlighted={highlighted}
          stretchX={stretchX}
          lineClamp="lg"
          preview={preview}
        />
      )
    case 'cardSpaceDescription':
      return (
        <CardSpaceDescription
          field={field}
          space={space}
          highlighted={highlighted}
          stretchX={stretchX}
          preview={preview}
        />
      )
    case 'listSpaceSummary':
      return (
        <ListSpaceSummary
          field={field}
          highlighted={highlighted}
          stretchX={stretchX}
          fieldProps={{
            space,
            activeFieldId,
            viewStyle,
            preview,
          }}
          FieldRenderer={Field}
        />
      )
    case 'membersCount':
      return (
        <MembersCount
          space={space}
          highlighted={highlighted}
          stretchX={stretchX}
          variant={field.settings?.buttonVariant}
        />
      )
    case 'postsCount':
      return (
        <PostsCount
          space={space}
          highlighted={highlighted}
          stretchX={stretchX}
          variant={field.settings?.buttonVariant}
        />
      )
    case 'joinButton':
      return (
        <JoinButton
          space={space}
          highlighted={highlighted}
          stretchX={stretchX}
          size={field.settings?.buttonSize}
        />
      )
    default:
      return null
  }
}
