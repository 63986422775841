import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'

import { Form } from '../../Form/index.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { CustomFieldSubtype } from '../utils.js'

export const BooleanInput = ({
  name,
  onChange,
  value,
  subtype,
  extraProps,
}) => {
  const { $t } = useI18n()

  if (extraProps?.bare) {
    return null
  }

  if (extraProps?.label) {
    if (subtype === CustomFieldSubtype.CHECKBOX) {
      return (
        <Form.Checkbox
          name={name}
          onChange={value => {
            if (typeof onChange === 'function') {
              onChange(value)
            }
          }}
          {...extraProps}
        />
      )
    }
    return (
      <Form.Toggle
        name={name}
        onChange={value => {
          if (typeof onChange === 'function') {
            onChange(value)
          }
        }}
        {...extraProps}
      />
    )
  }

  const items = [
    {
      text: $t({
        id: 'CustomField.FieldInput.Checked',
        defaultMessage: 'Checked',
      }),
      value: true,
    },
    {
      text: $t({
        id: 'CustomField.FieldInput.Unchecked',
        defaultMessage: 'Unchecked',
      }),
      value: false,
    },
  ]
  return (
    <Dropdown>
      <Dropdown.Button>
        {items?.find(item => item.value === value)?.text}
      </Dropdown.Button>
      <Dropdown.Items>
        {items.map(item => (
          <Dropdown.Item
            key={name}
            onClick={() => {
              if (typeof onChange === 'function') {
                onChange(item.value)
              }
            }}
          >
            {item.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Items>
    </Dropdown>
  )
}
