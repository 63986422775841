import type { OTSM } from '../../server/types/server.types.js'
import { useOneTimeServerMessage } from '../hooks/useOneTimeServerMessage.js'

export interface OtsmProps {
  otsm: OTSM
}

export const Otsm = ({ otsm }: OtsmProps) => {
  useOneTimeServerMessage(otsm)

  return null
}
