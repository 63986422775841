import { Fragment } from 'react'

import { clsx } from 'clsx'

import type { Member } from '@tribeplatform/gql-client/types'
import { useNavigate } from '@tribeplatform/react-components/Link'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { Card } from '@tribeplatform/react-ui-kit/Card'

import { groupFieldsByStretchY } from '../../PostsBlock/utils/index.js'
import { Field } from './Field.js'

interface Props {
  member: Member
  fields: StaticField[]
  activeFieldId: string
  width?: number
  className?: string
}
export const MembersGalleryItem = ({
  member,
  fields,
  activeFieldId,
  width,
  className,
}: Props) => {
  const navigate = useNavigate()

  const bannerIsFirst =
    fields.findIndex(
      field =>
        field.id === 'galleryBanner' &&
        field.settings?.bannerStyle === 'banner',
    ) === 0
  const bannerIsLast =
    fields.findIndex(
      field =>
        field.id === 'galleryBanner' &&
        field.settings?.bannerStyle === 'banner',
    ) ===
    fields.length - 1
  const bannerIsMiddle =
    fields.findIndex(
      field =>
        field.id === 'galleryBanner' &&
        field.settings?.bannerStyle === 'banner',
    ) > 0

  return (
    <Card
      onClick={() => navigate(member?.relativeUrl)}
      className={clsx(
        'cursor-pointer hover:shadow-card-hovered transition-all ease-in-out duration-200',
        'justify-start',
        className,
      )}
      {...(width
        ? { style: { maxWidth: `${width}px`, minWidth: `${width}px` } }
        : {})}
    >
      {bannerIsMiddle && (
        <Card.Content
          className={clsx(
            !bannerIsLast && 'flex-grow-0',
            'flex flex-col gap-4',
          )}
        >
          {groupFieldsByStretchY(
            fields.filter((field, index) => {
              const bannerIndex = fields.findIndex(
                field =>
                  field.id === 'galleryBanner' &&
                  field.settings?.bannerStyle === 'banner',
              )
              return index < bannerIndex && field.id !== 'listMessageButton'
            }),
          ).map(group => (
            <div
              key={group[0].id}
              className={clsx(
                group[0].settings?.stretchY ? 'flex-1' : 'grow-0 shrink-0',
              )}
            >
              <div className="flex flex-wrap items-center gap-4">
                {group.map(field => (
                  <Field
                    key={field.id}
                    field={field}
                    member={member}
                    activeFieldId={activeFieldId}
                    viewStyle="gallery"
                  />
                ))}
              </div>
            </div>
          ))}
        </Card.Content>
      )}
      {fields.map(field => (
        <Fragment key={field.id}>
          {field.id === 'galleryBanner' &&
            field.settings?.bannerStyle === 'banner' && (
              <Card.Media top={bannerIsFirst} bottom={bannerIsLast}>
                <Field
                  key={field.id}
                  field={field}
                  member={member}
                  activeFieldId={activeFieldId}
                  viewStyle="gallery"
                />
              </Card.Media>
            )}
        </Fragment>
      ))}
      {!bannerIsLast && (
        <Card.Content className="flex flex-col gap-4">
          {groupFieldsByStretchY(
            fields.filter((field, index) => {
              const bannerIndex = fields.findIndex(
                field =>
                  field.id === 'galleryBanner' &&
                  field.settings?.bannerStyle === 'banner',
              )
              return index > bannerIndex && field.id !== 'listMessageButton'
            }),
          ).map(group => (
            <div
              key={group[0].id}
              className={clsx(
                group[0].settings?.stretchY ? 'flex-1' : 'grow-0 shrink-0',
              )}
            >
              <div className="flex flex-wrap items-center gap-4">
                {group.map(field => (
                  <Field
                    key={field.id}
                    field={field}
                    member={member}
                    activeFieldId={activeFieldId}
                    viewStyle="gallery"
                  />
                ))}
              </div>
            </div>
          ))}
        </Card.Content>
      )}
      {fields.map(field => (
        <Fragment key={field.id}>
          {field.id === 'listMessageButton' && (
            <Card.Footer>
              <Field
                key={field.id}
                field={field}
                member={member}
                activeFieldId={activeFieldId}
                viewStyle="gallery"
              />
            </Card.Footer>
          )}
        </Fragment>
      ))}
    </Card>
  )
}
