import { FC, useMemo, useState } from 'react'

import {
  Member,
  QueryMembersArgs,
  RoleType,
} from '@tribeplatform/gql-client/types'
import { useMembers, useNetwork } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Multiselect } from '@tribeplatform/react-ui-kit/Multiselect'
import { SearchableSelect } from '@tribeplatform/react-ui-kit/SearchableSelect'

import type { MemberGroups } from '../CMS/types.js'
import { MemberAvatar } from '../common/components/index.js'
import { useDebounce } from '../common/hooks/useDebounce.js'
import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { pickIfExists } from './arrays.utils.js'

type MemberPickerSingleProps = {
  value?: Member
  onChange?: (values: Member) => void
}

type MemberPickerMultipleProps = {
  value?: Member[]
  onChange?: (values: Member[]) => void
}

export type MemberPickerProps = (
  | MemberPickerSingleProps
  | MemberPickerMultipleProps
) & {
  placeholder?: string
  variables?: (query: string) => Pick<QueryMembersArgs, 'query' | 'filterBy'>
  className?: string
  emptyMessage?: string
  /**
   * Allows to pick multiple values at once
   * @default false
   */
  multiple?: boolean
  /**
   * all - everyone
   * staff - community moderators and admins
   * member - regular members excluding moderators and admins
   *
   * @default all
   */
  memberGroup?: MemberGroups
  disabled?: boolean
  noWrapper?: boolean
  addLoggedInMember?: boolean
}

const isMultiple = (
  props: MemberPickerProps,
): props is MemberPickerMultipleProps => props.multiple === true

export const LOGGED_IN_MEMBER_ID = '{{ actorId }}'

export const MemberPicker: FC<MemberPickerProps> = props => {
  const { $t } = useI18n()
  const {
    placeholder,
    variables: queryVariables,
    emptyMessage,
    className,
    memberGroup = 'all',
    disabled,
    noWrapper,
    addLoggedInMember = false,
  } = props

  const { data: network } = useNetwork()

  const [search, setSearch] = useState('')
  const debouncedQuery = useDebounce(search, 300)

  let roleTypes: RoleType[]
  switch (memberGroup) {
    case 'member':
      roleTypes = [RoleType.member]
      break
    case 'staff':
      roleTypes = [RoleType.admin, RoleType.moderator]
      break
    case 'all':
    default:
      roleTypes = []
      break
  }

  const roleIds =
    roleTypes.length > 0
      ? network?.roles
          ?.filter(role => roleTypes?.includes(role.type))
          ?.map(it => it.id)
      : undefined

  let hookVariables: QueryMembersArgs = {
    limit: 20,
    roleIds,
  }
  if (typeof queryVariables !== 'function') {
    hookVariables.query = debouncedQuery
  } else {
    const variables = queryVariables(debouncedQuery)
    hookVariables = { ...hookVariables, ...variables }
  }

  const { data, isFetching } = useMembers({
    fields: { profilePicture: 'basic' },
    variables: hookVariables,
  })

  const members = useMemo(() => {
    if (!data) {
      return []
    }

    const { nodes } = simplifyPaginatedResult<Member>(data)
    if (!addLoggedInMember) {
      return nodes
    }

    const loggedInMember = {
      id: LOGGED_IN_MEMBER_ID,
      name: $t({
        defaultMessage: 'Logged in {MEMBER}',
        id: 'Generics.LoggedInMember',
      }),
    } as Member
    return [loggedInMember, ...nodes]
  }, [$t, addLoggedInMember, data])

  if (isMultiple(props)) {
    const { value, onChange } = props
    const suggestedMembers: Member[] = pickIfExists(members, value, 'id')

    return (
      <Multiselect
        value={value ?? []}
        options={suggestedMembers}
        onChange={onChange}
        searchable
        onInputChange={setSearch}
        className={className}
        disabled={disabled}
      >
        <Multiselect.Button
          placeholder={placeholder}
          hideInput={value?.length > 0}
        >
          {value?.map((member, index) => (
            <Multiselect.SelectedItem
              key={member.id}
              value={member}
              index={index}
            >
              <div className="flex items-center space-s-2" translate="no">
                {member.id !== LOGGED_IN_MEMBER_ID && (
                  <MemberAvatar size="xl" member={member} />
                )}
                <span className="truncate">{member?.name}</span>
              </div>
            </Multiselect.SelectedItem>
          ))}
        </Multiselect.Button>
        <Multiselect.Items noWrapper={noWrapper}>
          {suggestedMembers.map((member, index) => (
            <Multiselect.Item key={member.id} value={member} index={index}>
              <div className="flex items-center space-s-2" translate="no">
                {member.id !== LOGGED_IN_MEMBER_ID && (
                  <MemberAvatar size="xl" member={member} />
                )}
                <span className="truncate">{member?.name}</span>
              </div>
            </Multiselect.Item>
          ))}
          {isFetching && (
            <Multiselect.ItemsEmpty>
              <T id="Generics.LoadingDotDotDot" defaultMessage="Loading..." />
            </Multiselect.ItemsEmpty>
          )}
          {!isFetching && search && suggestedMembers.length === 0 && (
            <Multiselect.ItemsEmpty>
              <T id="Generics.NoResults" defaultMessage="No results" />
            </Multiselect.ItemsEmpty>
          )}
        </Multiselect.Items>
      </Multiselect>
    )
  }

  const { value, onChange } = props
  const suggestedMembers: Member[] = value
    ? pickIfExists(members, [value], 'id')
    : members

  return (
    <SearchableSelect
      value={value}
      options={suggestedMembers}
      onChange={onChange}
      onInputChange={setSearch}
      className={className}
    >
      <SearchableSelect.Button placeholder={placeholder}>
        {value && (
          <div className="flex items-center space-s-2">
            {value.id !== LOGGED_IN_MEMBER_ID && (
              <MemberAvatar size="xl" member={value} />
            )}
            <span className="truncate">{value.name}</span>
          </div>
        )}
      </SearchableSelect.Button>
      <SearchableSelect.Items>
        {suggestedMembers.map(member => (
          <SearchableSelect.Item key={member.id} value={member.id}>
            <div className="flex items-center space-s-2" translate="no">
              {member.id !== LOGGED_IN_MEMBER_ID && (
                <MemberAvatar size="xl" member={member} />
              )}
              <span className="truncate">{member?.name}</span>
            </div>
          </SearchableSelect.Item>
        ))}
        {isFetching && (
          <SearchableSelect.ItemsEmpty>
            <T id="Generics.LoadingDotDotDot" defaultMessage="Loading..." />
          </SearchableSelect.ItemsEmpty>
        )}
        {!isFetching && search && suggestedMembers.length === 0 && (
          <SearchableSelect.ItemsEmpty>
            {emptyMessage ??
              $t({
                id: 'Generics.NoResults',
                defaultMessage: 'No results',
              })}
          </SearchableSelect.ItemsEmpty>
        )}
      </SearchableSelect.Items>
    </SearchableSelect>
  )
}
