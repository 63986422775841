import { useColorSchemeContext } from '@tribeplatform/react-ui-kit/ThemeProvider'

import { Picker } from '../utils/emoji-mart-picker.js'
import { PickerEmoji } from './types.js'

export interface EmojiPickerProps {
  onEmojiChange?: (icon: PickerEmoji) => void
}

export const EmojiPicker = ({ onEmojiChange }: EmojiPickerProps) => {
  const { colorSchemeMode } = useColorSchemeContext()

  return (
    <div className="w-[352px] flex flex-col gap-3 items-center justify-stretch">
      <Picker
        theme={colorSchemeMode}
        onEmojiSelect={onEmojiChange}
        noCountryFlags={false}
        previewPosition="none"
      />
    </div>
  )
}
