import { Post } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { MemberAvatar } from '../common/components/index.js'
import { getPostFieldValue } from '../common/utils/post.js'
import { PostHiddenIndicator } from './PostHiddenIndicator.js'
import { PostLockedIndicator } from './PostLockedIndicator.js'
import { PostOptions } from './PostOptions.js'
import type { PostContext } from './types.js'

export const PostRow = ({
  post,
  context,
}: {
  post: Post
  context: PostContext
}) => {
  const { Link } = useRouter()
  if (!post?.id) {
    return null
  }
  const isHidden = post?.isHidden
  const isLocked = post?.locked

  const title =
    getPostFieldValue(post, 'title') || getPostFieldValue(post, 'question')
  const member = post?.owner?.member
  return (
    <div className="flex space-s-5 p-5 items-center post-row">
      <MemberAvatar member={member} size="2x" clickable />
      <Link
        className="flex-grow overflow-hidden max-w-full"
        href={post?.relativeUrl}
        variant="inherit"
      >
        <h4 className="text-lg font-medium line-clamp-2">
          {isHidden && <PostHiddenIndicator />}
          {isLocked && <PostLockedIndicator />}
          {title}
        </h4>
      </Link>
      <div className="space-s-1 text-content-subdued">
        <SvgIcon inline name="heart" />
        <span> {post?.reactionsCount?.toLocaleString() || '0'}</span>
      </div>
      <div className="space-s-1 text-content-subdued">
        <SvgIcon inline name="reply" />
        <span> {post?.totalRepliesCount?.toLocaleString() || '0'}</span>
      </div>
      <PostOptions post={post} context={context} />
    </div>
  )
}
