import { ComponentPropsWithoutRef, ReactNode } from 'react'

import { textStyles } from './Text.styles.js'
import { FontWeight, TextAlign, TextColor, TextVariant } from './types.js'

type Element =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'div'
  | 'legend'
// | 'label'

export type TextProps = ComponentPropsWithoutRef<'div'> & {
  /** Text to display */
  children: ReactNode
  /** Typographic style of text */
  variant?: TextVariant
  /** The element type */
  as?: Element
  /** Adjust horizontal alignment of text */
  align?: TextAlign
  truncate?: boolean
  /** Adjust weight of text. Does not apply for heading variants */
  fontWeight?: FontWeight
  /** Adjust color of text */
  color?: TextColor
  /** Affects default and subdued colors */
  onBackground?: boolean
  /** Prevent text from overflowing */
  breakWords?: boolean
}

/**
 * Typography helps establish hierarchy and communicate important content by creating clear visual patterns.
 */
export const Text = ({
  align,
  as,
  className,
  truncate,
  color,
  children,
  variant,
  fontWeight,
  breakWords,
  onBackground,
  ...rest
}: TextProps) => {
  const Component = as || 'span'

  const isHeading = variant && variant.startsWith('heading')
  return (
    <Component
      className={textStyles({
        align,
        truncate,
        fontWeight: isHeading ? 'medium' : fontWeight,
        color,
        variant,
        breakWords,
        onBackground,
        className,
      })}
      {...rest}
    >
      {children}
    </Component>
  )
}
