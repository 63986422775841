import { useMemo } from 'react'

import {
  type Post,
  type PostListFilterByInput,
  type Space,
  PostListFilterByOperator,
  SpaceType,
} from '@tribeplatform/gql-client/types'
import type {
  Filter,
  LegacyFilter,
} from '@tribeplatform/react-components/Filters'
import { useStaticIntl } from '@tribeplatform/react-components/i18n'
import { convertToStatic } from '@tribeplatform/react-components/Views'
import {
  useAuthMember,
  useNetwork,
  usePosts,
  useSpaces,
} from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { useSlate } from '@tribeplatform/react-slate-kit/hooks'

import { useQuery } from '../../../hooks/useQuery.js'
import { staticFieldsFactory } from '../config.js'
import { useCustomFields } from '../hooks/useCustomFields.js'
import { useShowMoreButton } from '../hooks/useShowMoreButton.js'
import {
  SortDirections,
  PostListSource,
  type PostsBlockView,
} from '../types.js'
import { generateGqlFields } from '../utils/generateGqlFields.js'
import {
  getInvalidState,
  getPostTypeIdsFromSlugs,
  getQuerySpaceIds,
} from '../utils/index.js'
import { usePostFilters } from './usePostFilters.js'

const MAX_JOINED_SPACES_LIMIT = 100

export const usePostsList = (
  view: PostsBlockView,
  storedFilters: LegacyFilter[],
  setStoredFilters: (newFilters: Filter[]) => void,
) => {
  const {
    spaceIds,
    source,
    postTypeSource,
    sort: orderBy,
    sortDirection,
    fields,
    showMore,
    postTypeSlugs,
    style,
    limit,
    gallerySize,
    filterBy,
    inlineFilters: rawInlineFilters,
  } = view
  const { context } = useSlate()
  const currentSpaceId = context?.spaceId
  const staticFields = useStaticIntl(staticFieldsFactory)
  const { data: member } = useAuthMember()
  const {
    data: { id: networkId, postTypes },
  } = useNetwork()
  const { tag_id: tagId } = useQuery()
  const { customFields } = useCustomFields({
    postTypeSlugs,
    excludeRichText: style !== 'card',
    excludeTitle: false,
    excludePrivateFields: false,
  })
  const isStateInvalid = getInvalidState(
    source,
    spaceIds,
    postTypeSource,
    postTypeSlugs,
  )
  const postTypeIds = useMemo(
    () =>
      getPostTypeIdsFromSlugs(
        networkId,
        postTypeSource,
        postTypeSlugs,
        postTypes ?? [],
      ),
    [networkId, postTypeSlugs, postTypeSource, postTypes],
  )
  const { combinedFilters, inlineFilters, setInlineFilters, hasInlineFilters } =
    usePostFilters({
      filterBy,
      postTypeSlugs,
      rawInlineFilters,
      storedFilters,
      setStoredFilters,
    })

  // For posts, we use keyString instead of key
  const legacyListFilters = combinedFilters.map<PostListFilterByInput>(
    ({ key, operator, value }) => ({
      keyString: key,
      operator: operator as unknown as PostListFilterByOperator,
      value,
    }),
  )
  const postsFilterBy = [
    ...legacyListFilters,
    ...(tagId
      ? [
          {
            keyString: 'tagIds',
            operator: PostListFilterByOperator.contains,
            value: JSON.stringify(tagId),
          } as PostListFilterByInput,
        ]
      : []),
  ]
  const mergedStaticFields = convertToStatic(
    fields,
    staticFields,
    customFields ?? [],
  )

  const {
    data: joinedSpacesData,
    isInitialLoading: isJoinedSpacesInitialLoading,
  } = useSpaces({
    fields: 'basic',
    variables: {
      limit: MAX_JOINED_SPACES_LIMIT,
      memberId: member.id,
      type: [SpaceType.Group],
    },
    useInfiniteQueryOptions: {
      enabled: source === PostListSource.joinedSpaces,
    },
  })
  const { nodes: joinedSpaces } = simplifyPaginatedResult(joinedSpacesData) as {
    nodes: Space[]
  }
  const joinedSpacesIds = joinedSpaces.map(space => space.id)
  const querySpaceIds = getQuerySpaceIds(
    source,
    spaceIds,
    currentSpaceId,
    joinedSpacesIds,
  )

  const {
    data: postsData,
    isInitialLoading: isPostsInitialLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePosts({
    variables: {
      limit,
      ...(querySpaceIds.length !== 0 ? { spaceIds: querySpaceIds } : {}),
      ...(postTypeIds.length !== 0 ? { postTypeIds } : {}),
      orderByString: orderBy,
      reverse: sortDirection === SortDirections.DESC,
      filterBy: postsFilterBy,
    },
    fields: generateGqlFields({ staticFields: mergedStaticFields, style }),
    useInfiniteQueryOptions: {
      refetchOnMount: 'always',
      enabled: !(
        isStateInvalid ||
        (source === PostListSource.joinedSpaces && joinedSpacesIds.length === 0)
      ),
    },
  })

  const { nodes: posts } = simplifyPaginatedResult<Post>(postsData)

  const showMoreButton = useShowMoreButton({
    style,
    gallerySize,
    showMore,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  })

  const isLoading =
    !postsData && (isPostsInitialLoading || isJoinedSpacesInitialLoading)

  return {
    posts,
    showMoreButton,
    isLoading,
    isInvalid: isStateInvalid,
    mergedStaticFields,
    inlineFilters,
    setInlineFilters,
    hasInlineFilters,
    view,
  }
}
