import { clsx } from 'clsx'

import { Member } from '@tribeplatform/gql-client/types'
import { MemberBadges } from '@tribeplatform/react-components/MemberBadge'

interface Props {
  member: Member
  highlighted: boolean
  stretchX: string
}
export const Badges = ({ member, highlighted, stretchX }: Props) => {
  return member?.badges?.length ? (
    <div
      className={clsx(
        'text-content-subdued text-sm',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
    >
      <MemberBadges member={member} size="sm" singleLine={false} />
    </div>
  ) : null
}
