import { useEffect, type ReactNode, useCallback } from 'react'

import { IntercomProps, IntercomProvider } from 'react-use-intercom'

import { useDomain } from '@tribeplatform/react-components/common/hooks'

type IntercomProviderProps = {
  children: ReactNode
  enabled: boolean
  appId: string
  bootProps: Omit<IntercomProps, 'hideDefaultLauncher'>
}

export const IntercomBootProvider = (props: IntercomProviderProps) => {
  const { children, enabled, appId, bootProps } = props
  useIntercomAttributeAssertion({ intercomEnabled: enabled })

  return (
    <IntercomProvider
      shouldInitialize
      appId={appId}
      autoBoot={enabled}
      autoBootProps={{
        ...bootProps,
        hideDefaultLauncher: true,
      }}
    >
      <IntercomShutdown intercomEnabled={enabled} />
      {children}
    </IntercomProvider>
  )
}

const useIntercomAttributeAssertion = ({
  intercomEnabled,
}: {
  intercomEnabled: boolean
}) => {
  const { getResourceUrl } = useDomain()

  const assertVisitorAttributes = useCallback(async () => {
    await fetch(getResourceUrl('/api/intercom/sync-attributes'), {
      method: 'POST',
      credentials: 'include',
    })
  }, [getResourceUrl])

  useEffect(() => {
    if (!intercomEnabled) {
      return
    }
    assertVisitorAttributes()
  }, [intercomEnabled, assertVisitorAttributes])
}

const IntercomShutdown = ({
  intercomEnabled,
}: {
  intercomEnabled: boolean
}) => {
  /**
   * The `shutdown` method exposed from `useIntercom` does not function if Intercom is not booted,
   * but the method exists if Intercom script itself is initialized.
   * On the other hand, we don’t want to boot Intercom for non-Admin users.
   * As result we have to use the global Intercom object to clear the session.
   */
  useEffect(() => {
    if (!intercomEnabled) {
      if (window.Intercom) {
        window.Intercom('shutdown')
      }
    }
  }, [intercomEnabled])
  return null
}

declare global {
  interface Window {
    Intercom: (key: 'shutdown') => void
  }
}
