import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'
import { PostComposerProps } from '@tribeplatform/react-components/PostComposer/@types'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { PostComposerBlock as Component } from './Block.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.PostComposerBlockSettings })),
)

export const RegisteredPostComposerBlock = (
  intl: I18nShape,
): RegisteredBlock<PostComposerProps> => {
  const { $t } = intl

  return {
    recommendedName: 'PostComposer',
    Component,
    config: {
      type: BlockType.Block,
      usable: true,

      displayName: $t({
        id: 'Generics.Composer',
        defaultMessage: 'Composer',
      }),
      Settings,
      Icon: <SvgIcon name="edit" />,
      defaultProps: {
        buttons: [],
      },
    },
  }
}
