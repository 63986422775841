import { forwardRef, Ref, ComponentPropsWithoutRef, ReactNode } from 'react'

import {
  optionDescriptorDescriptionStyles,
  optionDescriptorTitleStyles,
} from '../utils/OptionDescriptor.styles.js'
import { checkboxStyles } from './Checkbox.styles.js'

export type CheckboxProps = Omit<
  ComponentPropsWithoutRef<'input'>,
  'title' | 'size'
> & {
  /** If title is set, children are ignored */
  title?: ReactNode
  description?: ReactNode
  invalid?: boolean
  noLabel?: boolean
  size?: 'sm' | 'md'
}
/**
 * The Checkbox component is used in forms when a user needs to select multiple values from several options.
 */
export const Checkbox = forwardRef(
  (
    {
      title,
      description,
      invalid = false,
      disabled,
      children,
      className,
      noLabel = false,
      size = 'sm',
      ...rest
    }: CheckboxProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const input = (
      <input
        ref={ref}
        type="checkbox"
        className={checkboxStyles({ invalid, className, size })}
        disabled={disabled}
        {...rest}
      />
    )

    if (noLabel) {
      return input
    }

    return (
      <label className="flex items-start gap-2">
        {input}
        <div className="min-w-0">
          {title ? (
            <>
              <div className={optionDescriptorTitleStyles({ disabled })}>
                {title}
              </div>
              <div className={optionDescriptorDescriptionStyles({ disabled })}>
                {description}
              </div>
            </>
          ) : (
            <div className={optionDescriptorTitleStyles({ disabled })}>
              {children}
            </div>
          )}
        </div>
      </label>
    )
  },
)
