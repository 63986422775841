import { ComponentProps, forwardRef, ReactNode, useMemo } from 'react'

import { clsx } from 'clsx'

import { SectionLayout, Spacing } from '../types.js'
import {
  getHideOnBreakpointClass,
  getSpacingClasses,
  cleanupClassName,
  getNumberOfColumns,
} from '../utils/utils.js'

interface Props extends ComponentProps<'section'> {
  layout: SectionLayout
  gap: Spacing
  horizontalPadding: Spacing
  verticalPadding: Spacing
  horizontalMargin: Spacing
  verticalMargin: Spacing
  hideOnMobile?: boolean
  hideOnTablet?: boolean
  hideOnDesktop?: boolean
  restrictMaxWidth?: boolean
  className?: string
  children: ReactNode
  dataBlockId?: string
  dataBlockName?: string
}

export const SectionRenderer = forwardRef<HTMLElement, Props>(
  (props: Props, ref) => {
    const {
      layout,
      gap,
      horizontalPadding,
      verticalPadding,
      horizontalMargin,
      verticalMargin,
      hideOnMobile,
      hideOnTablet,
      hideOnDesktop,
      children,
      restrictMaxWidth,
      dataBlockId,
      dataBlockName,
      className,
    } = props

    const hiddenClass = useMemo(() => {
      return getHideOnBreakpointClass(
        hideOnMobile,
        hideOnTablet,
        hideOnDesktop,
        'grid',
      )
    }, [hideOnDesktop, hideOnMobile, hideOnTablet])

    const spacingClassNames = useMemo(() => {
      return getSpacingClasses({
        gap,
        horizontalMargin,
        verticalMargin,
        horizontalPadding,
        verticalPadding,
      })
    }, [
      gap,
      horizontalMargin,
      horizontalPadding,
      verticalMargin,
      verticalPadding,
    ])

    const numberOfColumnsClass = useMemo(() => {
      return getNumberOfColumns(layout)
    }, [layout])

    const allClassNames = useMemo(() => {
      return cleanupClassName(
        clsx(numberOfColumnsClass, className, spacingClassNames, hiddenClass),
      )
    }, [className, hiddenClass, numberOfColumnsClass, spacingClassNames])

    return (
      <section
        data-block-id={dataBlockId}
        data-block-name={dataBlockName}
        ref={ref}
        className={clsx(
          'grid w-full',
          allClassNames,
          restrictMaxWidth && 'md:max-w-8xl md:mx-auto',
        )}
      >
        {children}
      </section>
    )
  },
)
