import { File, GlyphMediaVariant, Image } from '@tribeplatform/gql-client/types'
import { I18nShape } from '@tribeplatform/react-components/i18n'

import {
  ActionButtonVariant,
  Alignment,
  IconWrapperStyle,
  MediaType,
  MembersAvatarSize,
  SpaceHeaderFieldKey,
  SpaceHeaderIconSize,
  SpaceHeaderPreset,
  TextColor,
  type LegacySpaceHeaderProps,
  type SpaceHeaderProps,
} from '../types.js'

interface Params {
  props?: SpaceHeaderProps | LegacySpaceHeaderProps
  intl: I18nShape
}

export const migrateProps = ({ props, intl }: Params): SpaceHeaderProps => {
  // The legacy space header doesn't add anything to the props until
  // the view is updated in the settings. So by default we'll use the card view
  if (!props) {
    return getLegacyCardViewProps(intl)
  }

  if ('preset' in props) {
    return props
  }

  const view = props?.view ?? 'card'

  if (view === 'card') {
    return getLegacyCardViewProps(intl)
  }

  return getLegacySimpleViewProps(intl)
}

const getLegacySimpleViewProps = (intl: I18nShape): SpaceHeaderProps => {
  const { $t } = intl

  return {
    preset: SpaceHeaderPreset.NoMediaIconLeft,
    contentSpacing: 1,
    mediaAspectRatio: 3,
    textColor: TextColor.Default,
    contentAlignment: Alignment.Start,
    mediaType: MediaType.None,
    useSpaceBanner: false,
    image: {
      url: 'https://tribe-s3-production.imgix.net/DspBwptWGkZ5ShIdKvzjm?w=500&auto=compress,format&dl',
    } as Image,
    video: {
      url: 'https://files-us-east-1.t-cdn.net/files/r7zBnRPvbOSeWlppDkPAs?dl',
      downloadUrl:
        'https://files-us-east-1.t-cdn.net/files/r7zBnRPvbOSeWlppDkPAs?dl',
    } as File,
    mediaTint: 25,
    fields: [
      {
        id: 'Icon',
        enabled: true,
        key: SpaceHeaderFieldKey.Icon,
        settings: {
          stretchX: false,
          size: SpaceHeaderIconSize.Large,
          iconWrapperStyle: IconWrapperStyle.Circle,
          useSpaceIcon: true,
          customIcon: {
            id: 'emoji/1f508',
            text: '1f508',
            variant: GlyphMediaVariant.emoji,
          },
        },
      },
      {
        id: 'Title',
        key: SpaceHeaderFieldKey.Title,
        enabled: true,
        settings: {
          stretchX: false,
          customTitle: $t(
            {
              id: 'Blocks.SpaceHeader.CustomTitlePlaceholder',
              defaultMessage: 'Welcome to {space_name_liquid_var}',
            },
            {
              space_name_liquid_var: '{{ space.name }}',
            },
          ),
          useSpaceName: true,
          showPrivateIcon: true,
          size: 'headingMd',
        },
      },
      {
        id: 'Description',
        key: SpaceHeaderFieldKey.Description,
        enabled: false,
        settings: {
          stretchX: true,
          customDescription:
            'Stay up-to-date with the latest news, updates, and important announcements.',
          useSpaceDescription: true,
        },
      },
      {
        enabled: false,
        id: 'Stats',
        key: SpaceHeaderFieldKey.Stats,
        settings: {
          stretchX: true,
        },
        fields: [
          {
            enabled: true,
            id: 'TotalMembers',
            key: SpaceHeaderFieldKey.TotalMembers,
            settings: {
              isSubField: true,
              stretchX: false,
              showText: true,
            },
          },
          {
            enabled: true,
            id: 'TotalPosts',
            key: SpaceHeaderFieldKey.TotalPosts,
            settings: {
              isSubField: true,
              stretchX: false,
              showText: true,
            },
          },
          {
            enabled: false,
            id: 'DateCreated',
            key: SpaceHeaderFieldKey.DateCreated,
            settings: {
              isSubField: true,
              stretchX: false,
              format: 'relative',
            },
          },
        ],
      },
      {
        enabled: false,
        id: 'Members',
        key: SpaceHeaderFieldKey.Members,
        settings: {
          stretchX: true,
          showCount: true,
          size: MembersAvatarSize.Small,
        },
      },
      {
        enabled: true,
        id: 'Buttons',
        key: SpaceHeaderFieldKey.Buttons,
        fields: [
          {
            enabled: true,
            id: 'AddPostButton',
            key: SpaceHeaderFieldKey.AddPostButton,
            settings: {
              stretchX: true,
              isSubField: true,
            },
          },
          {
            enabled: true,
            id: 'JoinButton',
            key: SpaceHeaderFieldKey.JoinButton,
            settings: {
              stretchX: true,
              isSubField: true,
              variant: ActionButtonVariant.IconText,
            },
          },
          {
            enabled: true,
            id: 'NotificationsButton',
            key: SpaceHeaderFieldKey.NotificationsButton,
            settings: {
              stretchX: true,
              isSubField: true,
              variant: ActionButtonVariant.Icon,
            },
          },
          {
            enabled: false,
            id: 'SearchButton',
            key: SpaceHeaderFieldKey.SearchButton,
            settings: {
              stretchX: true,
              isSubField: true,
              variant: ActionButtonVariant.Icon,
              icon: {
                id: 'icon/search-md',
                text: 'search-md',
                variant: GlyphMediaVariant.icon,
              },
            },
          },
          {
            enabled: false,
            id: 'ShareButton',
            key: SpaceHeaderFieldKey.ShareButton,
            settings: {
              stretchX: true,
              isSubField: true,
              variant: ActionButtonVariant.Text,
              icon: {
                id: 'icon/share-06',
                text: 'share-06',
                variant: GlyphMediaVariant.icon,
              },
            },
          },
          {
            enabled: true,
            id: 'OptionsButton',
            key: SpaceHeaderFieldKey.OptionsButton,
            settings: {
              stretchX: true,
              isSubField: true,
              variant: ActionButtonVariant.Icon,
            },
          },
        ],
        settings: {
          stretchX: false,
        },
      },
    ],
  }
}

const getLegacyCardViewProps = (intl: I18nShape): SpaceHeaderProps => {
  const { $t } = intl

  return {
    preset: SpaceHeaderPreset.MediaTopIconTop,
    contentSpacing: 1,
    mediaAspectRatio: 3,
    textColor: TextColor.Default,
    contentAlignment: Alignment.Start,
    mediaType: MediaType.Image,
    useSpaceBanner: true,
    image: {
      url: 'https://tribe-s3-production.imgix.net/DspBwptWGkZ5ShIdKvzjm?w=500&auto=compress,format&dl',
    } as Image,
    video: {
      url: 'https://files-us-east-1.t-cdn.net/files/r7zBnRPvbOSeWlppDkPAs?dl',
      downloadUrl:
        'https://files-us-east-1.t-cdn.net/files/r7zBnRPvbOSeWlppDkPAs?dl',
    } as File,
    mediaTint: 25,
    fields: [
      {
        id: 'Icon',
        enabled: true,
        key: SpaceHeaderFieldKey.Icon,
        settings: {
          stretchX: true,
          size: SpaceHeaderIconSize.Large,
          iconWrapperStyle: IconWrapperStyle.Circle,
          useSpaceIcon: true,
          customIcon: {
            id: 'emoji/1f508',
            text: '1f508',
            variant: GlyphMediaVariant.emoji,
          },
        },
      },
      {
        id: 'Title',
        key: SpaceHeaderFieldKey.Title,
        enabled: true,
        settings: {
          stretchX: false,
          customTitle: $t(
            {
              id: 'Blocks.SpaceHeader.CustomTitlePlaceholder',
              defaultMessage: 'Welcome to {space_name_liquid_var}',
            },
            {
              space_name_liquid_var: '{{ space.name }}',
            },
          ),
          useSpaceName: true,
          showPrivateIcon: true,
          size: 'headingMd',
        },
      },
      {
        id: 'Description',
        key: SpaceHeaderFieldKey.Description,
        enabled: false,
        settings: {
          stretchX: true,
          customDescription:
            'Stay up-to-date with the latest news, updates, and important announcements.',
          useSpaceDescription: true,
        },
      },
      {
        enabled: false,
        id: 'Stats',
        key: SpaceHeaderFieldKey.Stats,
        settings: {
          stretchX: true,
        },
        fields: [
          {
            enabled: true,
            id: 'TotalMembers',
            key: SpaceHeaderFieldKey.TotalMembers,
            settings: {
              isSubField: true,
              stretchX: false,
              showText: true,
            },
          },
          {
            enabled: true,
            id: 'TotalPosts',
            key: SpaceHeaderFieldKey.TotalPosts,

            settings: {
              isSubField: true,
              stretchX: false,
              showText: true,
            },
          },
          {
            enabled: false,
            id: 'DateCreated',
            key: SpaceHeaderFieldKey.DateCreated,
            settings: {
              isSubField: true,
              stretchX: false,
              format: 'relative',
            },
          },
        ],
      },
      {
        enabled: false,
        id: 'Members',
        key: SpaceHeaderFieldKey.Members,
        settings: {
          stretchX: true,
          showCount: true,
          size: MembersAvatarSize.Small,
        },
      },
      {
        enabled: true,
        id: 'Buttons',
        key: SpaceHeaderFieldKey.Buttons,
        fields: [
          {
            enabled: true,
            id: 'AddPostButton',
            key: SpaceHeaderFieldKey.AddPostButton,
            settings: {
              stretchX: true,
              isSubField: true,
            },
          },
          {
            enabled: true,
            id: 'JoinButton',
            key: SpaceHeaderFieldKey.JoinButton,
            settings: {
              stretchX: true,
              isSubField: true,
              variant: ActionButtonVariant.IconText,
            },
          },
          {
            enabled: true,
            id: 'NotificationsButton',
            key: SpaceHeaderFieldKey.NotificationsButton,
            settings: {
              stretchX: true,
              isSubField: true,
              variant: ActionButtonVariant.Icon,
            },
          },
          {
            enabled: false,
            id: 'SearchButton',
            key: SpaceHeaderFieldKey.SearchButton,
            settings: {
              stretchX: true,
              isSubField: true,
              variant: ActionButtonVariant.Icon,
              icon: {
                id: 'icon/search-md',
                text: 'search-md',
                variant: GlyphMediaVariant.icon,
              },
            },
          },
          {
            enabled: false,
            id: 'ShareButton',
            key: SpaceHeaderFieldKey.ShareButton,
            settings: {
              stretchX: true,
              isSubField: true,
              variant: ActionButtonVariant.Text,
              icon: {
                id: 'icon/share-06',
                text: 'share-06',
                variant: GlyphMediaVariant.icon,
              },
            },
          },
          {
            enabled: true,
            id: 'OptionsButton',
            key: SpaceHeaderFieldKey.OptionsButton,
            settings: {
              stretchX: true,
              isSubField: true,
              variant: ActionButtonVariant.Icon,
            },
          },
        ],
        settings: {
          stretchX: false,
        },
      },
    ],
  }
}
