import * as tailwindColors from 'tailwindcss/colors.js'

/**
 * Tailwindcss/colors is unsafe for use in browser since it references process.env
 * to detect the environment and log warnings based env variables.
 * We need the following hack to make it work in browser.
 */
if (typeof window !== 'undefined') {
  if (typeof window.process === 'undefined') {
    window.process = {} as NodeJS.Process
  }
  if (typeof window.process.env === 'undefined') {
    window.process.env = {} as NodeJS.ProcessEnv
  }
}

/**
 * @tag Vite migration
 * tailwindColors module provides a default export and breaks Rollup ESM-CJS interop
 * https://github.com/vitejs/vite/issues/2139#issuecomment-1024852072
 */
export const availableColors = {
  ...(tailwindColors.default ? tailwindColors.default : tailwindColors),
}
