/**
 * Append values from `addition` to `source`. Values with same key will be taken from `addition`
 */
export const mergeArrayByKey = <T>(
  source: T[],
  addition: T[],
  key: keyof T,
): T[] => {
  const result = [...source]

  // replace existing keys
  addition.forEach(newValue => {
    const currentIndex = source.findIndex(it => it[key] === newValue[key])
    if (currentIndex > -1) {
      result.splice(currentIndex, 1, newValue)
    } else {
      result.push(newValue)
    }
  })

  return result
}
