import { useMemo } from 'react'

import { clsx } from 'clsx'

import { Container } from '@tribeplatform/react-ui-kit/Layout'

import { useSlate } from '../../hooks/slate.hook.js'
import type { RegisteredBlockProps } from '../../types/block.types.js'
import { NavbarWrapper } from './Components/NavbarWrapper.js'
import { SubNavbar } from './Components/SubNavbar.js'
import { SearchBarProvider } from './providers/SearchBarProvider.js'
import {
  SubNavigationPosition,
  NavbarSectionItem,
  type NavbarProps,
} from './types.js'
import { migrateNavbar } from './utils/migration.js'

export const NavbarBlock = (props: RegisteredBlockProps<NavbarProps>) => {
  const { mode } = useSlate()
  const isEditMode = mode === 'edit'
  const migratedProps = useMemo(() => migrateNavbar(props), [props])
  const {
    'data-block-id': dataBlockId,
    'data-block-name': dataBlockName,
    className,
    sections,
    mobileMenuSections,
    searchSettings,
    subNavigation,
  } = migratedProps

  const {
    enabled: isSubNavigationEnabled,
    position: subNavigationPosition,
    menuItems,
    alignment,
  } = subNavigation
  const hasSearchSection =
    sections.find(({ id }) => id === NavbarSectionItem.search)?.enabled ?? false

  return (
    <div
      className={clsx(
        'flex justify-center main-navbar text-content-on-topbar',
        isEditMode && 'bg-topbar',
        className,
      )}
      data-block-id={dataBlockId}
      data-block-name={dataBlockName}
    >
      <div
        className={clsx(
          'flex flex-1',
          subNavigationPosition === SubNavigationPosition.top
            ? 'flex-col'
            : 'flex-col-reverse',
        )}
      >
        {isSubNavigationEnabled && (
          <SubNavbar menuItems={menuItems} alignment={alignment} />
        )}
        <Container
          size="xl"
          containerProps={{
            className: '@container mx-auto',
          }}
        >
          <div className="screen-padding-x-xs">
            <SearchBarProvider
              settings={searchSettings}
              hasSearchSection={hasSearchSection}
            >
              <NavbarWrapper
                sections={sections}
                mobileMenuSections={mobileMenuSections}
                subNavigation={subNavigation}
              />
            </SearchBarProvider>
          </div>
        </Container>
      </div>
    </div>
  )
}
