import { MemberStatus } from '@tribeplatform/gql-client/types'
import type { ValuePickerComponent } from '@tribeplatform/react-components/Filters'
import { SpreadFilterItems } from '@tribeplatform/react-components/Filters'
import { useI18n } from '@tribeplatform/react-components/i18n'
import { Multiselect } from '@tribeplatform/react-ui-kit/Multiselect'

import { emailStatusFactory } from './CustomEmailStatusRenderer.js'

export const CustomEmailStatusPicker: ValuePickerComponent = ({
  filter,
  spread,
  className,
  onChange,
}) => {
  const intl = useI18n()
  const { $t } = intl
  const { value = [] } = filter
  const emailStatusMapper = emailStatusFactory(intl)
  const emailStatusOptions = [MemberStatus.VERIFIED, MemberStatus.UNVERIFIED]
  const inlineSelectOptions = filter?.extraProps?.inlineSelectOptions
  const narrowedDownOptions = filter?.extraProps?.narrowedDownOptions
  const options =
    (narrowedDownOptions?.length > 0
      ? narrowedDownOptions
      : inlineSelectOptions) ?? emailStatusOptions

  if (!Array.isArray(value)) {
    return null
  }

  if (spread) {
    return (
      <SpreadFilterItems options={options} value={value} onChange={onChange}>
        {selectedOption => (
          <span title={emailStatusMapper?.[selectedOption] ?? ''}>
            {emailStatusMapper?.[selectedOption] ?? ''}
          </span>
        )}
      </SpreadFilterItems>
    )
  }

  return (
    <div className={className}>
      <Multiselect
        value={value}
        options={options}
        onChange={newValues => onChange(newValues)}
      >
        <Multiselect.Button
          placeholder={$t({
            defaultMessage: 'Select email status',
            id: 'Filters.Values.EmailStatus.Placeholder',
          })}
          hideInput={value?.length > 0}
        >
          {value.map((emailStatus, index) => (
            <Multiselect.SelectedItem
              key={emailStatus}
              value={emailStatus}
              index={index}
            >
              {emailStatusMapper?.[emailStatus] ?? ''}
            </Multiselect.SelectedItem>
          ))}
        </Multiselect.Button>
        <Multiselect.Items noWrapper>
          {options.map((emailStatus, index) => (
            <Multiselect.Item
              key={emailStatus}
              value={emailStatus}
              index={index}
            >
              {emailStatusMapper?.[emailStatus] ?? ''}
            </Multiselect.Item>
          ))}
        </Multiselect.Items>
      </Multiselect>
    </div>
  )
}
