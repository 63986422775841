import type { Post } from '@tribeplatform/gql-client/types'
import { Button } from '@tribeplatform/react-ui-kit/Button'

import { EntityImage } from '../../common/components/EntityImage.js'
import { convertIdToGlyph } from '../../MediaPicker/utils/utils.js'
import { type ReactAction } from '../../Views/types.js'
import { ReactionButtonContent } from '../components/ReactionButtonContent.js'
import { getTextIconSettings } from '../utils/utils.js'

interface Props {
  post: Post
  action: ReactAction
  disabled: boolean
  onReact: (key: string, shouldRemove: boolean, shouldReplace: boolean) => void
}
export const SingleReactionAction = ({
  post,
  action,
  disabled,
  onReact,
}: Props) => {
  const {
    defaultLabel,
    variant,
    glyphId,
    color,
    activeLabel,
    activeGlyphId,
    activeColor,
    showCount,
    reactionCountPosition,
  } = action
  const { showText, showIcon } = getTextIconSettings(variant)
  const [customReaction] = action.reactions
  const { key } = customReaction
  const hasReacted = post?.reactions?.some(
    reaction => reaction.reaction === key && !!reaction?.reacted,
  )
  const reactionCount =
    post?.reactions?.find(reaction => reaction.reaction === key)?.count ?? 0

  const handleChange = () => {
    const remove = post?.reactions?.some(
      reaction => reaction?.reaction === key && !!reaction?.reacted,
    )
    const replace = !!action.isSingleReaction
    onReact(key, remove, replace)
  }

  return (
    <div className="flex-1">
      <Button
        variant="secondaryNeutral"
        className="group select-none"
        pressed={hasReacted}
        disabled={disabled}
        size="lg"
        fullWidth
        onClick={handleChange}
      >
        <ReactionButtonContent
          icon={
            showIcon &&
            (hasReacted ? (
              <EntityImage
                entity={{
                  image: convertIdToGlyph(activeGlyphId || glyphId),
                  name: activeLabel || (defaultLabel ?? ''),
                }}
                size="lg"
                displayColor={activeColor}
                iconStyle="solid"
              />
            ) : (
              <EntityImage
                entity={{
                  image: convertIdToGlyph(glyphId),
                  name: defaultLabel || '',
                }}
                size="lg"
                displayColor={color}
              />
            ))
          }
          text={
            showText &&
            (hasReacted ? activeLabel || defaultLabel : defaultLabel)
          }
          count={showCount && reactionCount}
          countPosition={reactionCountPosition}
        />
      </Button>
    </div>
  )
}
