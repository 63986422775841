import type { SpacePostTypeFields } from '@tribeplatform/gql-client/graphql/post'
import type {
  PaginatedSpacePostType,
  QuerySpacePostTypesArgs,
} from '@tribeplatform/gql-client/types'

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '../../lib/react-query/useInfiniteQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSpacePostTypesKey } from '../../utils/keys/spacePostType.key.js'

export const useSpacePostTypes = (options: {
  variables: QuerySpacePostTypesArgs
  fields?: SpacePostTypeFields
  useInfiniteQueryOptions?: UseInfiniteQueryOptions<PaginatedSpacePostType>
}) => {
  const {
    variables,
    fields = 'default',
    useInfiniteQueryOptions,
  } = options || {}
  const { client } = useTribeClient()
  const spacePostTypesKey = getSpacePostTypesKey({ variables, fields })

  return useInfiniteQuery<PaginatedSpacePostType>(
    spacePostTypesKey,
    ({ pageParam: after }) =>
      client?.spacePostType.list({ after, ...variables }, fields),
    useInfiniteQueryOptions,
  )
}
