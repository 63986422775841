import { ChangeEvent, useRef } from 'react'

import { clsx } from 'clsx'

import { Avatar } from '@tribeplatform/react-ui-kit/Avatar'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { useThemeContext } from '@tribeplatform/react-ui-kit/ThemeProvider'

import { useCropImageModal } from '../../CropImageModal/useCropImageModal.js'
import { T } from '../../i18n/components/T.js'
import { UploadableImage } from './types.js'

export type AvatarInputProps = {
  image: UploadableImage
  onChange(file: File): void
  className?: string
  cropable?: boolean
}
export const VariantAvatar = (props: AvatarInputProps) => {
  const { onChange, image, className, cropable = false } = props

  const { style } = useThemeContext()
  const fileInputRef = useRef<HTMLInputElement>()

  const { onSelectFile, children: cropModal } = useCropImageModal({
    onSuccess: ({ croppedImage }) => onChange?.(croppedImage),
    options: {
      shape: style.avatarShape === 'full-rounded' ? 'round' : 'rect',
      format: 'image/png',
    },
  })

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = Array.from(e.target.files)?.[0] as File
    // allow to select same file twice
    e.target.value = ''

    onSelectFile(file)
    if (!cropable) {
      onChange?.(file)
    }
  }

  return (
    <>
      <div
        className={clsx(
          'hover:bg-surface-hovered rounded-button overflow-hidden relative w-32 h-32',
          className,
        )}
      >
        <Avatar size="8x" src={image?.urls?.small || image?.url} />
        <input
          type="file"
          className="hidden"
          multiple={false}
          ref={fileInputRef}
          onChange={onChangeFile}
          accept="image/*"
        />
        {image?.isLoading && (
          <div
            className={clsx(
              'flex items-center justify-center text-white bg-background-backdrop absolute top-0 bottom-0 start-0 end-0',
              'rounded-avatar',
            )}
          >
            <SvgIcon className="animate-spin h-7 w-7" name="spinner" />
          </div>
        )}
        {!image?.isLoading && (
          <div
            onClick={() => {
              fileInputRef.current.click()
            }}
            className={clsx(
              'flex items-center justify-center text-white bg-background-backdrop opacity-0 hover:opacity-100 absolute top-0 bottom-0 start-0 end-0 cursor-pointer',
              'rounded-avatar',
            )}
          >
            <T id="Generics.Change.Verb" defaultMessage="Change" />
          </div>
        )}
      </div>
      {cropable && cropModal}
    </>
  )
}
