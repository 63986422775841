import { I18nShape } from '@tribeplatform/react-components/i18n'

import { RegisteredBlocks } from '../types/index.js'
import * as registeredBlocks from './registered.js'

export const getRecommendedRegisteredBlocks = (intl: I18nShape) => {
  return Object.values(registeredBlocks).reduce((acc, currentBlockFactory) => {
    const currentBlock = currentBlockFactory(intl)

    return {
      ...acc,
      [currentBlock.recommendedName]: currentBlock,
    }
  }, {} as RegisteredBlocks)
}
