import { createContext, useContext } from 'react'

import { theme as defaultTheme } from '@tribeplatform/design-system/themes/default'
import type { AppearanceStyle, Theme } from '@tribeplatform/design-system/types'

import { DEFAULT_STYLE, DEFAULT_THEME_ASSETS } from './constants.js'
import { ThemeAssets } from './types.js'

export type ThemeProps = {
  theme: Theme
  style: AppearanceStyle
  assets: ThemeAssets
}

export const ThemeContext = createContext<ThemeProps>({
  theme: defaultTheme,
  style: DEFAULT_STYLE,
  assets: DEFAULT_THEME_ASSETS,
})

export const useThemeContext = () => {
  const context = useContext(ThemeContext)
  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeProvider')
  }
  return context
}
