import { ReactNode, useCallback, useState } from 'react'

import { clsx } from 'clsx'

import { Button, IconButton } from '@tribeplatform/react-ui-kit/Button'
import { FileDropZone } from '@tribeplatform/react-ui-kit/FileUpload'
import { usePreloadImage } from '@tribeplatform/react-ui-kit/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Popover } from '@tribeplatform/react-ui-kit/Popover'

import { useStaticIntl } from '../../i18n/hooks/useStaticIntl.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { I18nShape } from '../../i18n/types.js'
import { ImagePickerTabs } from '../../Picker/ImagePicker.js'
import { FormImageConfig, UploadableImage } from './types.js'

export type VariantDefaultProps = {
  images: UploadableImage[]
  onChange: (images: File[]) => void
  onRemove: (media: UploadableImage) => void
  multiple?: boolean
  config: FormImageConfig
}

export const VariantDefault = ({
  images,
  multiple,
  onChange,
  onRemove,
  config,
}: VariantDefaultProps) => {
  const hasImage = !!images?.length

  const [active, setActive] = useState(false)
  const toggleActive = useCallback(() => setActive(active => !active), [])

  const onClick = () => {
    setActive(true)
  }

  const removable = !multiple && !!images?.[0]

  const onRemoveImage = () => {
    onRemove(images?.[0])
  }

  const imagePickerTabs = (
    <ImagePickerTabs
      onSelect={() => setActive(false)}
      multiple={multiple}
      removable={removable}
      tabs={[
        config.emoji ? 'emoji' : null,
        config.icon ? 'icon' : null,
        'upload',
        config.link ? 'link' : null,
        config.unsplash ? 'unsplash' : null,
      ]}
      onRemove={onRemoveImage}
      onImageChange={onChange}
    />
  )

  const popoverActivator = (
    <PopoverActivator
      isActive={active}
      onActivate={toggleActive}
      hasImage={hasImage}
      isMultiple={multiple}
      onClose={() => setActive(false)}
    >
      {imagePickerTabs}
    </PopoverActivator>
  )

  return (
    <div className="relative group isolate">
      <FileDropZone
        type="image"
        accept="image/*"
        allowMultiple={multiple}
        onClick={onClick}
        onDrop={onChange}
        className={multiple ? 'min-h-32' : 'h-[180px] sm:h-[260px]'}
      >
        {multiple ? (
          <div
            className={
              images.length > 0 && 'grid grid-cols-4 sm:grid-cols-5 gap-1'
            }
          >
            {images.map(image => (
              <PreviewMultiple
                key={image.name}
                image={image}
                onRemove={onRemove}
              />
            ))}
            {popoverActivator}
          </div>
        ) : (
          <PreviewSingle image={images?.[0]}>{popoverActivator}</PreviewSingle>
        )}
      </FileDropZone>
    </div>
  )
}

const PopoverActivator = ({
  isMultiple,
  hasImage,
  onActivate,
  isActive,
  onClose,
  children,
}: {
  isMultiple: boolean
  hasImage: boolean
  onActivate: () => void
  isActive: boolean
  onClose: () => void
  children: ReactNode
}) => {
  const actionTitle = useStaticIntl(getDropZoneLabel, isMultiple, hasImage)
  const activatorClassName = isMultiple
    ? clsx(
        hasImage
          ? 'h-full w-full border border-line-subdued rounded-base'
          : 'h-32 w-full',
      )
    : ''

  return (
    <Popover
      wrapperClassName={clsx(
        'relative',
        isMultiple && hasImage && 'col-span-1 aspect-square',
      )}
      activator={
        !isMultiple && hasImage ? (
          <Button
            size="md"
            variant="secondaryNeutral"
            className="group-hover:opacity-100 focus-visible:opacity-100 opacity-0 duration-300 z-10"
          >
            {actionTitle}
          </Button>
        ) : (
          <button
            type="button"
            onClick={onActivate}
            className={activatorClassName}
          >
            <FileDropZone.Label actionTitle={actionTitle} />
          </button>
        )
      }
      open={isActive}
      onClose={onClose}
      autofocusTarget="first-node"
      fluidContent
    >
      {children}
    </Popover>
  )
}

const getDropZoneLabel = (
  { $t }: I18nShape,
  isMultiple: boolean,
  hasImage: boolean,
) => {
  if (hasImage) {
    if (isMultiple) {
      // Case 1: There are images but we can add more
      return $t({
        defaultMessage: 'Add images',
        id: 'Form.Image.VariantDefault.AddImages',
      })
    }

    // Case 2: There is an image, but we only change it
    return $t({
      id: 'Form.Image.VariantDefault.ChangeImage',
      defaultMessage: 'Change the image',
    })
  }

  if (isMultiple) {
    // Case 3: There are no images yet, and we can add many
    return $t({
      defaultMessage: 'Click to pick the images, or drag and drop them here.',
      id: 'Form.Image.VariantDefault.PickImage',
    })
  }

  // Case 4: There are no images yet, but we can only add one
  return $t({
    defaultMessage: 'Click to pick an image, or drag and drop it here.',
    id: 'Form.Image.VariantDefault.PickImages',
  })
}

const PreviewSingle = ({ image, children }) => {
  const { preloaded } = usePreloadImage({ src: image?.urls?.medium })

  return (
    <div className="relative w-full h-full">
      {image && (
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        <img
          src={preloaded ? image?.urls?.medium : image?.url}
          alt={image?.name}
          className={clsx(
            'w-full h-full',
            'transition-opacity duration-300',
            !image?.isLoading ? 'opacity-100' : 'opacity-50',
            'bg-surface inline-block object-cover object-center',
            'border border-line-subdued rounded-base',
          )}
        />
      )}
      <div className="absolute top-1/2 start-1/2 transform -translate-x-1/2 -translate-y-1/2">
        {image?.isLoading ? (
          <SvgIcon className="w-9 h-9 animate-spin" name="spinner" />
        ) : (
          children
        )}
      </div>
    </div>
  )
}

const PreviewMultiple = ({ image, onRemove }) => {
  const { preloaded } = usePreloadImage({ src: image?.urls?.thumb })
  const { $t } = useI18n()

  return (
    <div className="relative col-span-1 aspect-square">
      {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
      <img
        src={preloaded ? image?.urls?.thumb : image?.url}
        alt={image?.name}
        className={clsx(
          'transition-opacity duration-300',
          !image?.isLoading ? 'opacity-100' : 'opacity-50',
          'bg-surface inline-block object-cover object-center',
          'h-full w-full border border-line-subdued rounded-base',
        )}
      />
      <div className="absolute top-1 end-1">
        {image.isLoading ? (
          <SvgIcon className="animate-spin w-5 h-5" name="spinner" />
        ) : (
          <IconButton
            variant="plain"
            size="sm"
            rounded
            className="z-20 opacity-50 hover:opacity-100"
            accessibilityLabel={$t({
              defaultMessage: 'Delete',
              id: 'Generics.Delete',
            })}
            icon={<SvgIcon name="close" />}
            onClick={e => {
              e.stopPropagation()
              onRemove(image)
            }}
            tooltip
          />
        )}
      </div>
    </div>
  )
}
