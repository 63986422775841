import type { TagFields } from '@tribeplatform/gql-client/graphql'
import type {
  PaginatedTag,
  QueryTagsArgs,
} from '@tribeplatform/gql-client/types'

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '../../lib/react-query/useInfiniteQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getTagsKey } from '../../utils/keys/post.key.js'

export const useSpaceTags = (options: {
  variables: QueryTagsArgs
  fields?: TagFields
  useInfiniteQueryOptions?: UseInfiniteQueryOptions<PaginatedTag>
}) => {
  const { variables, fields = 'basic', useInfiniteQueryOptions } = options || {}
  const { client } = useTribeClient()
  const tagsKey = getTagsKey({ variables, fields })

  return useInfiniteQuery<PaginatedTag>(
    tagsKey,
    ({ pageParam: after }) => client.tags.list({ after, ...variables }, fields),
    useInfiniteQueryOptions,
  )
}
