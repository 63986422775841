import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import {
  useAuthMember,
  useCollections,
  useNetwork,
} from '@tribeplatform/react-sdk/hooks'

import { BC } from '../../types/index.js'
import { CollectionsLoading } from './CollectionLoading.js'
import { CollectionMenuCollapsible } from './CollectionMenuCollapsible.js'
import { CollectionMenuHeading } from './CollectionMenuHeading.js'
import { CollectionMenuSimple } from './CollectionMenuSimple.js'
import {
  CollectionMenuProps,
  CollectionMenuSpaceFilter,
  CollectionMenuViewStyle,
} from './types.js'
import { getOrderedCollections } from './utils.js'

export const CollectionMenuBlock: BC<CollectionMenuProps> = ({
  items,
  viewStyle,
  spaceFilter = CollectionMenuSpaceFilter.All,
  ...rest
}) => {
  const { data: member, isGuest } = useAuthMember()
  const { data: network = {} } = useNetwork()

  const { data: collections, isInitialLoading } = useCollections({
    fields: {
      spaces: {
        fields:
          viewStyle === CollectionMenuViewStyle.Simple
            ? {}
            : {
                image: 'basic',
                banner: 'basic',
                slate: 'all',
                authMemberProps: {},
              },
        variables: {
          limit: 100,
          exploreOnly:
            !isGuest && spaceFilter === CollectionMenuSpaceFilter.NotJoined,
          memberId:
            !isGuest && spaceFilter === CollectionMenuSpaceFilter.Joined
              ? member?.id
              : null,
        },
      },
    },
    variables: {},
    useQueryOptions: {
      retry: 10,
      retryDelay: 1000,
    },
  })

  const [canCreateSpace] = hasScopesPermission(network, ['addSpace'])

  if (!Array.isArray(collections) || isInitialLoading) {
    return <CollectionsLoading />
  }

  const orderedCollections = getOrderedCollections(items, collections)

  let CollectionMenuComponent = null
  if (viewStyle === CollectionMenuViewStyle.Simple) {
    CollectionMenuComponent = (
      <CollectionMenuSimple collections={orderedCollections} {...rest} />
    )
  } else if (viewStyle === CollectionMenuViewStyle.Collapsible) {
    CollectionMenuComponent = (
      <CollectionMenuCollapsible
        canCreateSpace={canCreateSpace}
        collections={orderedCollections}
        {...rest}
      />
    )
  } else if (viewStyle === CollectionMenuViewStyle.Heading) {
    CollectionMenuComponent = (
      <CollectionMenuHeading
        canCreateSpace={canCreateSpace}
        collections={orderedCollections}
        {...rest}
      />
    )
  }

  return <>{CollectionMenuComponent}</>
}
