import {
  MouseEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { Post } from '@tribeplatform/gql-client/types'
import {
  useReplies,
  type UseRepliesProps,
} from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'

import { useLogin } from '../../common/hooks/useLogin.js'
import { ReplyBarRef } from '../../Post/ReplyBar.js'

interface Props {
  limit: number
  reply: Post
  enableInfiniteQuery?: boolean
}

export const useGenericReply = ({
  limit,
  reply,
  enableInfiniteQuery = false,
}: Props) => {
  const replyBarRef = useRef<ReplyBarRef>()
  const [showReplyBar, setShowReplyBar] = useState(false)
  const { isLoggedIn, showLogin } = useLogin()
  const [editing, setEditing] = useState(false)
  const repliesOptions: UseRepliesProps = useMemo(() => {
    return {
      variables: {
        limit,
        postId: reply.id,
        reverse: true,
      },
      fields: {
        owner: {
          member: { profilePicture: 'basic', badges: 'all' },
        },
        embeds: 'basic',
        mentions: 'basic',
        attachments: 'basic',
        authMemberProps: 'all',
        reactions: { fields: 'all', variables: { limit: 10 } },
      },
      useInfiniteQueryOptions: {
        enabled: enableInfiniteQuery,
        refetchOnMount: 'always',
        placeholderData:
          reply?.replies?.totalCount > 0
            ? {
                pageParams: [],
                pages: [reply.replies],
              }
            : undefined,
      },
    }
  }, [enableInfiniteQuery, limit, reply.id, reply.replies])

  const { data, fetchNextPage, refetch, isFetching } =
    useReplies(repliesOptions)

  const startEditing = useCallback(() => setEditing(true), [])
  const stopEditing = useCallback(() => setEditing(false), [])

  const { nodes: replies } = simplifyPaginatedResult<Post>(data)

  const onReply = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    event => {
      if (!isLoggedIn) {
        event.preventDefault()
        return showLogin()
      }

      setShowReplyBar(true)
      refetch({ refetchPage: (page, index) => index === 0 })
      // to re-expand it
      if (showReplyBar && replyBarRef.current) {
        replyBarRef.current.expand(true)
      }
      // to jump into the input box
      setTimeout(() => {
        if (replyBarRef) {
          replyBarRef.current?.scrollIntoView()
        }
      }, 100)
      // to jump again after loading the replies first page
      setTimeout(() => {
        if (replyBarRef) {
          replyBarRef.current?.scrollIntoView()
        }
      }, 1000)

      return undefined
    },
    [isLoggedIn, refetch, showLogin, showReplyBar],
  )

  const [canCreateReply, canHidePost] = hasScopesPermission(reply, [
    'createReply',
    'hidePost',
  ])

  const isHidden = !canHidePost && reply.isHidden
  const hasReplyPermission =
    !!canCreateReply && !!reply.authMemberProps.availableReplyTypes?.length

  const unloadedReplies = Math.min(limit, reply.repliesCount - replies.length)

  return {
    isFetching,
    unloadedReplies,
    replies,
    showReplyBar,
    editing,
    replyBarRef,
    isHidden,
    hasReplyPermission,
    fetchNextPage,
    onReply,
    startEditing,
    stopEditing,
  }
}
