import { QueryClient } from '@tanstack/react-query'
import { generatePath as routerGeneratePath } from 'react-router'

import {
  HomeSpaceType,
  type Member,
  type Post,
  type Space,
} from '@bettermode/common/gql/generated'

import { SpaceUtilResult, getCachedSpace } from '../hooks/index.js'
import { getCollectionsKey } from './keys/collection.key.js'
import {
  getHomepageKey,
  getMemberSpacesKey,
  getPageKey,
  getSpaceKey,
  getSpacesKey,
} from './keys/space.key.js'

export const generatePath = (
  spaceAddressPath: string,
  params?: Record<string, string>,
  queryParams?: Record<string, string>,
): string => {
  /**
   * The `(.+)` matcher is a remenant of the old router and the new API in React Router v6
   * does not recognize it. It must be removed from the path before generating the URL.
   */
  const normalizedPath = spaceAddressPath?.replace('(.+)', '') ?? ''

  const spacePath = `/${normalizedPath}`
  const path = params ? routerGeneratePath(spacePath, params || {}) : spacePath
  const encodedURI = queryParams
    ? `${path}?${new URLSearchParams(queryParams).toString()}`
    : path
  return decodeURI(encodedURI)
}

export const generateMemberPath = (
  memberSpace: Partial<Space>,
  member: Partial<Member>,
) =>
  memberSpace && member
    ? generatePath(memberSpace?.address?.path, { id: member.id || '' })
    : '/'

export const generatePostPath = (
  postSpace: Partial<Space>,
  space: Partial<Space>,
  post: Partial<Post>,
  reply?: Partial<Post>,
) =>
  postSpace && space && post
    ? generatePath(
        postSpace?.address?.path,
        {
          spacePath: generatePath(space?.address?.path).slice(1),
          postPath: post.slug ? `${post.slug}-${post.id}` : post.id,
        },
        reply?.id ? { highlight: reply?.id } : null,
      )
    : '/'

export const invalidateSpaceQueries = ({
  queryClient,
  spaceId,
  spaceUtils,
}: {
  queryClient: QueryClient
  spaceId: string
  spaceUtils: SpaceUtilResult
}) => {
  const space = getCachedSpace({ id: spaceId }, queryClient, spaceUtils)

  const spacesKey = getSpacesKey()
  queryClient.invalidateQueries(spacesKey)

  const spaceKey = getSpaceKey({ variables: { id: spaceId } })
  queryClient.invalidateQueries(spaceKey)

  const pageKey = getPageKey({ variables: { path: space?.address?.path } })
  queryClient.invalidateQueries(pageKey)

  const memberSpacesKey = getMemberSpacesKey()
  queryClient.invalidateQueries(memberSpacesKey)

  const homePageKey = getHomepageKey({
    variables: { type: HomeSpaceType.Default },
  })
  queryClient.invalidateQueries(homePageKey)

  const collectionsKey = getCollectionsKey()
  queryClient.invalidateQueries(collectionsKey)
}
