import { useEffect, useRef } from 'react'

export function useEventListener(
  event: string,
  handler: (event: Event) => void,
  options?: AddEventListenerOptions,
) {
  const handlerRef = useRef(handler)
  const optionsRef = useRef(options)

  const SSR = typeof window === 'undefined'

  useEffect(() => {
    handlerRef.current = handler
    optionsRef.current = options
  }, [handler, options])

  useEffect(() => {
    const handler = handlerRef.current
    const options = optionsRef.current

    if (SSR) {
      return
    }
    window.addEventListener(event, handler, options)

    return () => window?.removeEventListener(event, handler, options)
  }, [event])
}
