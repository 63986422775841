import type { StaticField } from '@tribeplatform/react-components/Views'
import { Card } from '@tribeplatform/react-ui-kit/Card'

import type { QuickLinksSource } from '../types.js'
import { Field } from './Field.js'

interface Props {
  source: QuickLinksSource
  fields: StaticField[]
  preview: boolean
  activeFieldId: string
}
export const QuickLinksCardItem = ({
  source,
  fields,
  preview,
  activeFieldId,
}: Props) => {
  const bannerField = fields.find(field => field.id === 'quickLinkCardBanner')

  return (
    <Card padding="md">
      {!!bannerField && (
        <Card.Media top>
          <Field
            key={bannerField.id}
            field={bannerField}
            source={source}
            activeFieldId={activeFieldId}
            viewStyle="card"
            preview={preview}
          />
        </Card.Media>
      )}
      <Card.Content>
        <div className="flex flex-wrap gap-4 items-center">
          {fields
            .filter(field => field.id !== 'quickLinkCardBanner')
            .map(field => (
              <Field
                key={field.id}
                field={field}
                source={source}
                activeFieldId={activeFieldId}
                viewStyle="card"
                preview={preview}
              />
            ))}
        </div>
      </Card.Content>
    </Card>
  )
}
