import { createContext } from 'react'

interface FileDropZoneContextType {
  disabled: boolean
  focused: boolean
  allowMultiple: boolean
  type: string
}

export const FileDropZoneContext = createContext<FileDropZoneContextType>({
  disabled: false,
  focused: false,
  type: 'file',
  allowMultiple: true,
})
