import {
  useState,
  useEffect,
  FormEvent,
  forwardRef,
  useImperativeHandle,
  useRef,
  ReactNode,
} from 'react'

import { clsx } from 'clsx'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import type { FieldValues, DefaultValues, FieldPath } from 'react-hook-form'

import { ClientError } from '@tribeplatform/gql-client/lib'
import { Alert } from '@tribeplatform/react-ui-kit/Alert'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import {
  UploadQueueProvider,
  useUploadQueue,
} from '../Providers/UploadQueueProvider.js'
import { FormRenderProps } from './typings.js'
import type { FormComponentType, FormProps } from './typings.js'

const FormContent = ({
  children,
  submitForm,
}: {
  children:
    | ReactNode
    | ((renderProps: FormRenderProps<FieldValues>) => ReactNode)
  submitForm: (data: FieldValues) => Promise<void>
}) => {
  const methods = useFormContext()
  const { isUploading } = useUploadQueue()

  if (typeof children === 'function') {
    const childrenToRender = children({ ...methods, isUploading, submitForm })
    return <>{childrenToRender}</>
  }

  return <>{children}</>
}

const FormInner = <TFormValues extends FieldValues>(
  {
    defaultValues = {} as DefaultValues<TFormValues>,
    children,
    onSubmit,
    alertClasses,
    loading,
    className,
    ...rest
  }: FormProps<TFormValues>,
  ref,
) => {
  const methods = useForm<TFormValues>({
    defaultValues,
  })
  const [generalErrors, setGeneralErrors] = useState([])
  const submittedData = useRef<DefaultValues<TFormValues>>()

  const {
    setError,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = methods

  const submitForm = async data => {
    try {
      setGeneralErrors([])
      submittedData.current = data
      await onSubmit(data, methods)
    } catch (err) {
      const errors = (err as ClientError)?.response?.errors
      const errMsgs = []
      errors?.forEach(e => {
        if (e?.field) {
          setError(e?.field as FieldPath<TFormValues>, {
            type: 'validate',
            message: e?.message,
          })
        } else if (e?.message) {
          errMsgs.push(e)
        }
      })

      if (errMsgs.length) {
        setGeneralErrors(errMsgs)
      }
    }
  }

  useEffect(() => {
    if (isSubmitSuccessful && submittedData.current) {
      reset({ ...submittedData.current })
    }
  }, [isSubmitSuccessful, reset])

  useImperativeHandle(ref, () => ({
    methods,
  }))

  /**
   * Submit event still propagates to parent when using portal
   * https://reactjs.org/docs/portals.html#event-bubbling-through-portals
   * Event bubbling goes through React DOM instead of HTML DOM
   * Portals don't have an effect on this one, we need to stop event propagation
   * This should be our default form handling method
   */
  const handleSubmitWithoutPropagation = (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()
    handleSubmit(submitForm)(e)
  }

  return (
    <FormProvider {...methods}>
      <UploadQueueProvider>
        <form
          className={clsx('relative', className)}
          onSubmit={handleSubmitWithoutPropagation}
          data-submit-successful={isSubmitSuccessful || undefined}
          {...rest}
        >
          {loading && (
            <div className="absolute cursor-not-allowed bg-background-overlay w-full h-full z-50 flex start-0 end-0 items-center justify-center rounded-base">
              <SvgIcon className="animate-spin" size="lg" name="spinner" />
            </div>
          )}

          {generalErrors?.length ? (
            <Alert status="error" className={alertClasses || 'mb-3'}>
              {generalErrors.map(err => {
                if (!err?.message) {
                  return null
                }
                return <div key={err?.message}>{err?.message}</div>
              })}
            </Alert>
          ) : null}

          <FormContent submitForm={submitForm}>{children}</FormContent>
        </form>
      </UploadQueueProvider>
    </FormProvider>
  )
}

export const Form = forwardRef(FormInner) as FormComponentType
