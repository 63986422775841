import { isElementInViewport } from './viewport.js'

type Filter = (element: Element) => void
export type MouseUpBlurHandler = (
  event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
) => void

const FOCUSABLE_SELECTOR =
  'a,frame,iframe,input:not([type=hidden]):not(:disabled),select:not(:disabled),textarea:not(:disabled),button:not(:disabled),*[tabindex]'
const KEYBOARD_FOCUSABLE_SELECTORS =
  'a,frame,iframe,input:not([type=hidden]):not(:disabled),select:not(:disabled),textarea:not(:disabled),button:not(:disabled),*[tabindex]:not([tabindex="-1"])'
const MENUITEM_FOCUSABLE_SELECTORS =
  'a[role="menuitem"],frame[role="menuitem"],iframe[role="menuitem"],input[role="menuitem"]:not([type=hidden]):not(:disabled),select[role="menuitem"]:not(:disabled),textarea[role="menuitem"]:not(:disabled),button[role="menuitem"]:not(:disabled),*[tabindex]:not([tabindex="-1"])'
export const handleMouseUpByBlurring: MouseUpBlurHandler = ({
  currentTarget,
}) => currentTarget.blur()

export function nextFocusableNode(
  node: HTMLElement,
  filter?: Filter,
): HTMLElement | Element | null {
  const allFocusableElements = [
    ...document.querySelectorAll(FOCUSABLE_SELECTOR),
  ]
  const sliceLocation = allFocusableElements.indexOf(node) + 1
  const focusableElementsAfterNode = allFocusableElements.slice(sliceLocation)

  // eslint-disable-next-line no-restricted-syntax
  for (const focusableElement of focusableElementsAfterNode) {
    if (
      isElementInViewport(focusableElement) &&
      (!filter || (filter && filter(focusableElement)))
    ) {
      return focusableElement
    }
  }

  return null
}

export function findFirstFocusableNode(
  document: Document,
  element: HTMLElement,
  onlyDescendants = true,
): HTMLElement | null {
  if (!onlyDescendants && matches(document, element, FOCUSABLE_SELECTOR)) {
    return element
  }

  return element.querySelector(FOCUSABLE_SELECTOR)
}

// Popover needs to be able to find its activator even if it is disabled, which FOCUSABLE_SELECTOR doesn't support.
export function findFirstFocusableNodeIncludingDisabled(
  document: Document,
  element: HTMLElement,
): HTMLElement | null {
  const focusableSelector = `a,button,frame,iframe,input:not([type=hidden]),select,textarea,*[tabindex]`

  if (matches(document, element, focusableSelector)) {
    return element
  }

  return element.querySelector(focusableSelector)
}

export function focusFirstFocusableNode(
  document: Document,
  element: HTMLElement,
  onlyDescendants = true,
) {
  findFirstFocusableNode(document, element, onlyDescendants)?.focus()
}

export function focusNextFocusableNode(node: HTMLElement, filter?: Filter) {
  const nextFocusable = nextFocusableNode(node, filter)
  if (nextFocusable && nextFocusable instanceof HTMLElement) {
    nextFocusable.focus()
    return true
  }

  return false
}

export function findFirstKeyboardFocusableNode(
  document: Document,
  element: HTMLElement,
  onlyDescendants = true,
): HTMLElement | null {
  if (
    !onlyDescendants &&
    matches(document, element, KEYBOARD_FOCUSABLE_SELECTORS)
  ) {
    return element
  }
  return element.querySelector(KEYBOARD_FOCUSABLE_SELECTORS)
}

export function focusFirstKeyboardFocusableNode(
  document: Document,
  element: HTMLElement,
  onlyDescendants = true,
) {
  const firstFocusable = findFirstKeyboardFocusableNode(
    document,
    element,
    onlyDescendants,
  )
  if (firstFocusable) {
    firstFocusable.focus()
    return true
  }

  return false
}

export function findLastKeyboardFocusableNode(
  document: Document,
  element: HTMLElement,
  onlyDescendants = true,
) {
  if (
    !onlyDescendants &&
    matches(document, element, KEYBOARD_FOCUSABLE_SELECTORS)
  ) {
    return element
  }
  const allFocusable = element.querySelectorAll(KEYBOARD_FOCUSABLE_SELECTORS)
  return allFocusable[allFocusable.length - 1] as HTMLElement | null
}

export function focusLastKeyboardFocusableNode(
  document: Document,
  element: HTMLElement,
  onlyDescendants = true,
) {
  const lastFocusable = findLastKeyboardFocusableNode(
    document,
    element,
    onlyDescendants,
  )
  if (lastFocusable) {
    lastFocusable.focus()
    return true
  }

  return false
}

export function wrapFocusPreviousFocusableMenuItem(
  parentElement: HTMLElement,
  currentFocusedElement: HTMLElement,
) {
  const allFocusableChildren = getMenuFocusableDescendants(parentElement)
  const currentItemIdx = getCurrentFocusedElementIndex(
    allFocusableChildren,
    currentFocusedElement,
  )
  if (currentItemIdx === -1) {
    allFocusableChildren[0].focus()
  } else {
    allFocusableChildren[
      (currentItemIdx - 1 + allFocusableChildren.length) %
        allFocusableChildren.length
    ].focus()
  }
}

export function wrapFocusNextFocusableMenuItem(
  parentElement: HTMLElement,
  currentFocusedElement: HTMLElement,
) {
  const allFocusableChildren = getMenuFocusableDescendants(parentElement)
  const currentItemIdx = getCurrentFocusedElementIndex(
    allFocusableChildren,
    currentFocusedElement,
  )
  if (currentItemIdx === -1) {
    allFocusableChildren[0].focus()
  } else {
    allFocusableChildren[
      (currentItemIdx + 1) % allFocusableChildren.length
    ].focus()
  }
}

function getMenuFocusableDescendants(
  element: HTMLElement,
): NodeListOf<HTMLElement> {
  return element.querySelectorAll(
    MENUITEM_FOCUSABLE_SELECTORS,
  ) as NodeListOf<HTMLElement>
}

function getCurrentFocusedElementIndex(
  allFocusableChildren: NodeListOf<HTMLElement>,
  currentFocusedElement: HTMLElement,
): number {
  let currentItemIdx = 0

  // eslint-disable-next-line no-restricted-syntax
  for (const focusableChild of allFocusableChildren) {
    if (focusableChild === currentFocusedElement) {
      break
    }
    // eslint-disable-next-line no-plusplus
    currentItemIdx++
  }
  return currentItemIdx === allFocusableChildren.length ? -1 : currentItemIdx
}

function matches(document: Document, node: HTMLElement, selector: string) {
  if (node.matches) {
    return node.matches(selector)
  }

  const matches = (node.ownerDocument || document).querySelectorAll(selector)
  let i = matches.length
  // eslint-disable-next-line no-plusplus
  while (--i >= 0 && matches.item(i) !== node) return i > -1
}
