import {
  CustomReaction,
  PostReactionDetail,
} from '@tribeplatform/gql-client/types'

import { legacyEmojiReactions } from '../CMS/utils/legacyReactions.js'
import { Reaction } from './Reaction.js'

interface Props {
  reaction: PostReactionDetail
  customReactions: CustomReaction[]
  label?: string
}
export const ReactionRenderer = ({
  reaction,
  customReactions,
  label,
}: Props) => {
  if (!customReactions?.length) {
    const glyphId =
      legacyEmojiReactions.find(({ key }) => key === reaction.reaction)
        ?.glyphId ?? reaction.reaction

    return <Reaction glyphId={glyphId} label={label} />
  }

  const foundReaction = customReactions?.find(
    ({ key }) => key === reaction.reaction,
  )

  if (!foundReaction) {
    return null
  }

  const { glyphId, activeGlyphId, activeColor } = foundReaction

  return (
    <Reaction
      glyphId={activeGlyphId || glyphId}
      activeColor={activeColor}
      label={label}
    />
  )
}
