import { useEffect, useMemo, useRef, useState } from 'react'

import { clsx } from 'clsx'
import InfiniteScroll from 'react-infinite-scroller'
import { Link as ReactLink } from 'react-router-dom'
import { useColumnOrder, useTable } from 'react-table'

import { CustomFieldType } from '@tribeplatform/gql-client/types'
import { useCommandBar } from '@tribeplatform/react-components/CommandBar'
import {
  MemberAvatar,
  MemberAvatarPlaceholder,
} from '@tribeplatform/react-components/common/components/member'
import { useControlledHotkeys } from '@tribeplatform/react-components/common/hooks'
import { dayjs } from '@tribeplatform/react-components/common/lib'
import { getLocalizedDateFormat } from '@tribeplatform/react-components/common/utils'
import {
  CustomFieldSubtype,
  FieldView,
} from '@tribeplatform/react-components/CustomField'
import { HtmlContent } from '@tribeplatform/react-components/HtmlContent'
import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { SpaceImage } from '@tribeplatform/react-components/Space'
import { getUserSettings } from '@tribeplatform/react-components/utils/userSettings'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Avatar } from '@tribeplatform/react-ui-kit/Avatar'
import { Badge } from '@tribeplatform/react-ui-kit/Badge'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Input } from '@tribeplatform/react-ui-kit/Input'
import { Link } from '@tribeplatform/react-ui-kit/Link'
import { Table as TableComponent } from '@tribeplatform/react-ui-kit/Table'

import { MassActionSettingsFactory } from '../Admin/MassActions/constants.js'
import { useScroll } from '../Admin/ScrollProvider.js'
import { ErrorBoundary } from '../Error/ErrorBoundry.js'
import { FieldsDropdown } from './FieldsDropdown.js'
import { FieldsFilter } from './Filters/FieldsFilter.js'
import { HeaderCounter } from './HeaderCounter/index.js'

export const Table = ({
  columns,
  data,
  fetchNextPage,
  hasNextPage = false,
  isLoading = false,
  isFetchingNextPage = false,
  fieldsFooter = null,
  hasSearch = true,
  state = {
    query: null,
    filterBy: [],
    orderBy: {
      column: undefined,
      reverse: false,
    },
  },
  onStateChange = null,
  title = null,
  actions = null,
  customTotalCount = null,
  filterBarPrefix = null,
  onExport = null,
  storageId = '',
  keyboardControl = false,
  entityType = null,
  massActionContext = null,
  massActions = null,
  onMassAction = null,
  ...props
}) => {
  const intl = useI18n()
  const { $t, localeCode } = intl
  const { open: openCommandBar } = useCommandBar()

  const { orderBy, filterBy } = state

  const memoData = useMemo(() => data, [data]) || []
  const memoColumns =
    useMemo(() => {
      if (!storageId) {
        return columns
      }

      const userSettings = getUserSettings()
      const allSelectedFields = userSettings?.tableSelectedFields ?? {}
      const selectedFields = allSelectedFields?.[storageId]

      if (!selectedFields) {
        return columns
      }

      const visibleFields = columns.map(column => ({
        ...column,
        visible: column?.accessor
          ? !!selectedFields.find(field => field === column.accessor)
          : column.visible,
      }))
      const fields = visibleFields.sort((a, b) => {
        const indexOfA = selectedFields.indexOf(a?.accessor)
        const indexOfB = selectedFields.indexOf(b?.accessor)

        if (indexOfA === -1 || indexOfB === -1) {
          return 0
        }

        return indexOfA - indexOfB
      })

      return fields
    }, [columns, storageId]) || []

  const { nodes: items } = simplifyPaginatedResult(memoData)
  const selectedCount = data?.pages?.[0]?.totalCount ?? 0
  const totalCount = customTotalCount ?? selectedCount

  const [activeIndex, setActiveIndex] = useState(null)
  const tableBodyRef = useRef(null)

  useControlledHotkeys(
    'j,down',
    e => {
      e.preventDefault()
      setActiveIndex(activeIndex => {
        if (activeIndex === null) {
          return 0
        }
        return activeIndex + 1
      })
    },
    { enabled: keyboardControl },
  )
  useControlledHotkeys(
    'k,up',
    e => {
      e.preventDefault()
      setActiveIndex(activeIndex => {
        if (activeIndex === null) {
          return 0
        }
        return activeIndex > 0 ? activeIndex - 1 : activeIndex
      })
    },
    { enabled: keyboardControl },
  )

  useEffect(() => {
    if (!keyboardControl || activeIndex === null || !items?.length) {
      return
    }
    if (activeIndex > items?.length - 1) {
      setActiveIndex(activeIndex - 1)
      return
    }

    tableBodyRef.current.children[activeIndex].focus()
  }, [activeIndex, items?.length, keyboardControl])

  const { scrollRef } = useScroll()

  const CellRenderer = ({
    value,
    row: { values, original },
    column: { renderer, format, id: accessor, subtype, items, settings },
  }) => {
    if (typeof renderer === 'function') {
      if (renderer.length === 2) {
        return renderer(values, original)
      }
      return renderer(values)
    }

    switch (renderer) {
      case 'memberPrimary':
        return (
          <Link
            className="hover:underline"
            as={ReactLink}
            to={original?.relativeUrl}
            translate="no"
          >
            {value}
          </Link>
        )
      case 'postPrimary':
        return (
          <Link
            className="hover:underline"
            as={ReactLink}
            to={original?.relativeUrl}
          >
            {value}
          </Link>
        )
      case 'spacePrimary':
        return (
          <Link
            className="hover:underline"
            as={ReactLink}
            to={original?.relativeUrl}
          >
            {value}
          </Link>
        )
      case 'tagPrimary':
        return (
          <Link
            className="hover:underline"
            as={ReactLink}
            to={`/search?type=post&query=${encodeURIComponent(values.title)}`}
          >
            {value}
          </Link>
        )
      case 'avatar':
      case 'image':
        return (
          <Avatar
            size="2x"
            name={values?.name}
            src={value}
            fallback={MemberAvatarPlaceholder}
          />
        )

      case 'spaceImage':
        return (
          <div className="flex items-center justify-center h-10 w-10">
            <SpaceImage size="2x" space={values} />
          </div>
        )

      case 'number':
        return value?.toLocaleString ? value.toLocaleString() : 0

      case 'html':
        return <HtmlContent value={value} trustedDomains={['*']} />

      case 'postField':
        return value ? (
          <Link as={ReactLink} to={original?.relativeUrl}>
            {JSON.parse(value)}
          </Link>
        ) : (
          ''
        )

      case 'member':
        return (
          <>
            {!!value && (
              <Badge
                as={ReactLink}
                to={value?.relativeUrl}
                maxWidth="full"
                truncate
                leadingIcon={
                  <MemberAvatar size="lg" member={value} className="-ms-1" />
                }
              >
                {value?.name}
              </Badge>
            )}
          </>
        )

      case 'boolean':
        if (typeof value === 'boolean') {
          return <BooleanCell value={value} />
        }
        return '-'

      case 'longtext':
        return (
          <div className="truncate" title={value}>
            {value}
          </div>
        )

      case 'space':
        return (
          <Link as={ReactLink} to={value?.relativeUrl}>
            <div className="truncate">{value?.name}</div>
          </Link>
        )

      case 'date': {
        if (!value) {
          return '-'
        }
        if (format === 'fromNow') {
          return (
            <div title={new Date(value).toLocaleString()}>
              {dayjs(value).fromNow()}
            </div>
          )
        }

        const { date, time } = getLocalizedDateFormat(value, localeCode)
        if (format === 'datetime') {
          return (
            <div title={date}>
              {date}
              {/* eslint-disable-next-line react/jsx-no-literals */}
              {', '}
              {time}
            </div>
          )
        }
        return <div title={date}>{date}</div>
      }
      case 'email':
        return (
          <a href={`mailto:${value}`} target="_blank">
            {value}
          </a>
        )

      case 'relation':
        if (!value || !accessor) {
          return null
        }
        return (
          <FieldView
            entity={original}
            field={{
              type: CustomFieldType.relation,
              key: accessor?.replace('mappedFields.', ''),
              settings,
            }}
          />
        )

      case 'array':
        if (subtype && subtype !== CustomFieldSubtype.MULTISELECT) {
          return (
            <FieldView
              entity={original}
              field={{
                type: CustomFieldType.array,
                items,
                key: accessor?.replace('mappedFields.', ''),
                settings,
              }}
            />
          )
        }

        if (value?.join) {
          return value?.join(', ')
        }
        if (!value?.length) {
          return '-'
        }
        return value

      default:
        return value || ''
    }
  }

  const tableConfig = {
    defaultColumn: {},
    initialState: {
      hiddenColumns: memoColumns.map(column => {
        if (column.visible !== true) {
          return column.accessor
        }
        return null
      }),
    },
    columns: memoColumns,
    data: items,
  }

  if (CellRenderer) {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    tableConfig.defaultColumn.Cell = CellRenderer
  }

  const table = useTable(tableConfig, useColumnOrder)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    visibleColumns,
  } = table

  const loader = (
    <>
      {[...Array(10)].map((_e, i) => {
        return headerGroups.map(headerGroup => (
          // eslint-disable-next-line
          <TableComponent.Row key={i}>
            {headerGroup.headers.map((_e, j) => (
              // eslint-disable-next-line
              <TableComponent.Cell key={j} className="animate-pulse">
                <div className="h-4 bg-skeleton rounded-full w-3/4" />
              </TableComponent.Cell>
            ))}
          </TableComponent.Row>
        ))
      })}
    </>
  )

  const columnWidths = {
    collapse: 'w-8',
    xs: 'w-12',
    sm: 'w-32',
    md: 'w-48',
    lg: 'w-64',
    xl: 'w-96',
  }

  return (
    <div className="flex flex-col p-1 space-y-3 overflow-hidden">
      <div className="grid grid-cols-12 gap-3 items-center">
        <div className="web:col-span-12 web:lg:col-span-4 text-content-on-background">
          <h3 className="text-heading-sm font-medium">{title}</h3>
        </div>
        <div className="flex space-s-3 web:col-span-12 web:lg:col-span-8">
          <div className="flex-grow">
            {hasSearch && typeof onStateChange === 'function' && (
              <Input
                size="compact"
                onChange={e => {
                  onStateChange({
                    ...state,
                    ...{ query: e.target.value || undefined },
                  })
                }}
                value={state?.query}
                leadingIcon={<SvgIcon name="search" />}
                placeholder={$t({
                  defaultMessage: 'Search...',
                  id: 'Generics.SearchDotDotDot',
                })}
              />
            )}
          </div>
          <FieldsDropdown
            table={table}
            footer={fieldsFooter}
            storageId={storageId}
          />
          {actions ? <div className="flex-shrink-0">{actions}</div> : null}
        </div>
      </div>
      <div className="flex flex-wrap gap-3 items-center">
        <ErrorBoundary>
          {filterBarPrefix}
          <FieldsFilter
            fields={columns}
            filters={filterBy}
            setFilters={filterBy => {
              onStateChange?.({ ...state, ...{ filterBy: filterBy || [] } })
            }}
          />
        </ErrorBoundary>
      </div>
      <div className="flex space-s-3">
        <HeaderCounter
          entityType={entityType}
          isLoading={isLoading}
          selectedCount={selectedCount}
          totalCount={totalCount}
        />
        {!!onExport && !!totalCount && (
          <>
            <Button
              size="lg"
              variant="secondaryNeutral"
              onClick={() => onExport(columns, visibleColumns)}
              trailingIcon={<SvgIcon name="download" />}
              disabled={selectedCount === 0}
            >
              <T defaultMessage="Export" id="Generics.Export" />
            </Button>
          </>
        )}
        {!!massActions && !!totalCount && (
          <>
            <Dropdown>
              <Dropdown.Button disabled={selectedCount === 0}>
                <T defaultMessage="Actions" id="Generics.Actions" />
              </Dropdown.Button>
              <Dropdown.Items>
                {massActions.map(action => (
                  <Dropdown.Item
                    key={action}
                    onClick={() => onMassAction(action)}
                  >
                    {
                      MassActionSettingsFactory(
                        intl,
                        massActionContext,
                        action,
                        totalCount,
                      ).actionText
                    }
                  </Dropdown.Item>
                ))}
              </Dropdown.Items>
            </Dropdown>
          </>
        )}
      </div>
      <Card className="overflow-hidden">
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchNextPage}
          hasMore={(hasNextPage && !isFetchingNextPage) || false}
          useWindow={false}
          getScrollParent={() => scrollRef?.current}
        >
          <TableComponent
            {...props}
            {...getTableProps({
              className: 'table-fixed',
            })}
          >
            <TableComponent.Header>
              {headerGroups.map(headerGroup => (
                <TableComponent.Row
                  key={headerGroup.getHeaderGroupProps().key}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map(column => (
                    <TableComponent.HeaderCell
                      key={column.getHeaderProps().key}
                      {...column.getHeaderProps({
                        className: clsx(
                          'whitespace-nowrap',
                          columnWidths[column.width] || '',
                        ),
                      })}
                    >
                      {column.orderKey ? (
                        <Link
                          onClick={() => {
                            onStateChange({
                              ...state,
                              ...{
                                orderBy: {
                                  column: column.orderKey,
                                  reverse:
                                    orderBy?.column === column.orderKey
                                      ? !orderBy.reverse
                                      : true,
                                },
                              },
                            })
                          }}
                          className="flex items-center"
                        >
                          {column.render('Header')}
                          {
                            // eslint-disable-next-line no-nested-ternary
                            orderBy?.column === column.orderKey ? (
                              orderBy?.reverse ? (
                                <SvgIcon
                                  className="mx-1 w-4 h-4 text-content-subdued"
                                  name="chevron-down"
                                />
                              ) : (
                                <SvgIcon
                                  className="mx-1 w-4 h-4 text-content-subdued"
                                  name="chevron-up"
                                />
                              )
                            ) : null
                          }
                        </Link>
                      ) : (
                        column.render('Header')
                      )}
                    </TableComponent.HeaderCell>
                  ))}
                </TableComponent.Row>
              ))}
            </TableComponent.Header>
            <TableComponent.Body {...getTableBodyProps()} ref={tableBodyRef}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <TableComponent.Row
                    key={row.getRowProps().key}
                    {...row.getRowProps()}
                    tabIndex={0}
                    onKeyDown={e => {
                      if (
                        keyboardControl &&
                        entityType &&
                        (e.key === 'Enter' || e.key === ' ') &&
                        activeIndex
                      ) {
                        e.preventDefault()
                        openCommandBar()
                      }
                    }}
                  >
                    {row.cells.map(cell => {
                      return (
                        <TableComponent.Cell
                          key={cell.getCellProps().key}
                          {...cell.getCellProps()}
                        >
                          <div
                            className={clsx(
                              cell?.column?.width !== 'collapse' &&
                                'overflow-hidden truncate',
                              columnWidths[cell?.column?.width] || '',
                            )}
                          >
                            {cell.render('Cell')}
                          </div>
                        </TableComponent.Cell>
                      )
                    })}
                  </TableComponent.Row>
                )
              })}
              {!isLoading && selectedCount === 0 ? (
                <TableComponent.Row>
                  <TableComponent.Cell
                    colSpan={headerGroups?.[0].headers.length}
                  >
                    <div className="py-12 text-center">
                      <T
                        defaultMessage="Nothing to show here..."
                        id="Generics.NothingToShowHere"
                      />
                    </div>
                  </TableComponent.Cell>
                </TableComponent.Row>
              ) : null}
              {isLoading || isFetchingNextPage ? loader : null}
            </TableComponent.Body>
          </TableComponent>
        </InfiniteScroll>
      </Card>
    </div>
  )
}

export const BooleanCell = ({ value }: { value: boolean }) => {
  if (value === true) {
    return <SvgIcon className="w-4 h-4 text-content-on-positive" name="check" />
  }
  return <SvgIcon className="w-4 h-4 text-action-destructive" name="close" />
}
