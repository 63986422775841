import { useMemo } from 'react'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import {
  Space,
  SpaceJoinRequestStatus,
  SpaceMembershipStatus,
} from '@tribeplatform/gql-client/types'
import { I18nShape, useI18n } from '@tribeplatform/react-components/i18n'
import {
  useJoinSpace,
  useMemberSpaceMembershipRequest,
  useRequestSpaceMembership,
} from '@tribeplatform/react-sdk/hooks'
import { Button, ButtonSize } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { useLogin } from '../../hooks/useLogin.js'

interface Permissions {
  canJoinSpace: boolean
  canRequestSpaceMembership: boolean
  isLoggedIn: boolean
}

const getButtonProps = (
  { $t }: I18nShape,
  state: SpaceMembershipStatus | SpaceJoinRequestStatus,
  permission: Permissions,
) => {
  const { canJoinSpace, canRequestSpaceMembership, isLoggedIn } = permission
  switch (state) {
    case SpaceMembershipStatus.notJoined:
      if (canJoinSpace || !isLoggedIn) {
        return {
          label: $t({
            defaultMessage: 'Join',
            id: 'Generics.Join',
          }),
        }
      }
      if (canRequestSpaceMembership) {
        return {
          label: $t({
            defaultMessage: 'Request to join',
            id: 'Space.MembershipActions.RequestToJoin',
          }),
        }
      }
      return undefined
    case SpaceJoinRequestStatus.PENDING:
    case SpaceMembershipStatus.requested:
      return {
        label: $t({
          defaultMessage: 'Pending',
          id: 'Generics.Pending',
        }),
        icon: <SvgIcon name="clock" />,
        disabled: true,
      }
    default:
      return undefined
  }
}

interface Props {
  space: Space
  size?: ButtonSize
  fullWidth?: boolean
}

export const SpaceMembershipButton = ({
  space,
  size = 'lg',
  fullWidth = false,
}: Props) => {
  const intl = useI18n()
  const { $t } = intl
  const { mutate: join, isLoading: joining } = useJoinSpace()
  const { data } = useMemberSpaceMembershipRequest({
    variables: {
      status: SpaceJoinRequestStatus.PENDING,
    },
    useQueryOptions: {
      refetchOnMount: 'always',
    },
  })
  const spaceJoinRequest = useMemo(
    () => data?.find(d => d.spaceId === space.id),
    [data, space.id],
  )
  const { mutate: requestSpaceMembership } = useRequestSpaceMembership()
  const { isLoggedIn, showLogin } = useLogin()

  const [canJoinSpace, canRequestSpaceMembership] = hasScopesPermission(space, [
    'joinSpace',
    'requestSpaceMembership',
  ])

  if (!space) {
    return null
  }

  const { membershipStatus } = space?.authMemberProps || {}
  const btnProps = getButtonProps(
    intl,
    spaceJoinRequest?.status ||
      membershipStatus ||
      SpaceMembershipStatus.notJoined,
    { canJoinSpace, canRequestSpaceMembership, isLoggedIn },
  )
  if (!btnProps) {
    return null
  }

  return (
    <Button
      fullWidth={fullWidth}
      disabled={btnProps.disabled || joining}
      loading={joining}
      variant="secondaryNeutral"
      leadingIcon={btnProps.icon}
      size={size}
      onClick={() => {
        if (!isLoggedIn) {
          showLogin()
          return
        }
        const spaceId = space.id

        if (membershipStatus === SpaceMembershipStatus.notJoined) {
          if (canJoinSpace) {
            join({
              spaceId,
            })
          } else {
            requestSpaceMembership(
              {
                spaceId,
              },
              {
                onError: () => {
                  toast({
                    title: $t({
                      defaultMessage: 'Cannot request to join',
                      id: 'Space.MembershipActions.JoinFailed',
                    }),
                    description: $t({
                      defaultMessage:
                        'Please ask the {SPACE} admin to add you.',
                      id: 'Space.MembershipActions.JoinFailed.Description',
                    }),
                    status: 'error',
                  })
                },
              },
            )
          }
        }
      }}
    >
      {btnProps.label}
    </Button>
  )
}
