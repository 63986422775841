import type { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { IconBlock as Component } from './Block.js'
import { IconBlockProps } from './types.js'

export const RegisteredIconBlock = (
  _intl: I18nShape,
): RegisteredBlock<IconBlockProps> => ({
  recommendedName: 'Icon',
  Component,
  config: { type: BlockType.BuildingBlock },
})
