import { ExtraProperty } from '../types/index.js'

export const getExtraProperty = (
  extraProperties: ExtraProperty[],
  key: string,
  defaultValue: unknown = '',
) => {
  try {
    const value = extraProperties?.find(xp => xp.key === key)?.value
    return JSON.parse(value)
  } catch {
    return defaultValue
  }
}
