import { clsx } from 'clsx'

import { EntityImage } from '@tribeplatform/react-components/common/components'
import { useRouter } from '@tribeplatform/react-sdk'

import { ActionButtonSettings, StoredField } from '../../Fields/types.js'
import { ActionButtonVariant, ButtonActionType, Target } from '../../types.js'
import { ActionButtonRenderer } from './ActionButtonRenderer.js'
import { getButtonVariant } from './utils/utils.js'

interface Props {
  settings: ActionButtonSettings
  className?: string
  field: StoredField
}

export const CustomActionButton = ({ settings, className }: Props) => {
  const { Link } = useRouter()
  const { type, url, target, icon, variant, name } = settings

  const showIcon =
    variant === ActionButtonVariant.Icon ||
    variant === ActionButtonVariant.IconText
  const showText =
    variant === ActionButtonVariant.Text ||
    variant === ActionButtonVariant.IconText

  if (type === ButtonActionType.TextLink) {
    return (
      <Link
        variant="accent"
        href={url}
        target={target === Target.Blank ? '_blank' : '_self'}
        tabIndex={0}
        className={className}
      >
        {name}
      </Link>
    )
  }

  return (
    <ActionButtonRenderer
      as={props => (
        <Link
          {...props}
          variant="inherit"
          className={clsx(props?.className, 'text-label-sm')}
        />
      )}
      href={url}
      target={target === Target.Blank ? '_blank' : '_self'}
      showText={showText}
      showIcon={showIcon}
      className={className}
      variant={getButtonVariant(type)}
      icon={<EntityImage entity={{ image: icon }} size="xl" />}
      label={name}
    />
  )
}
