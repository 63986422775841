import { Component, ErrorInfo, FC, ReactNode } from 'react'

import { Card } from '@tribeplatform/react-ui-kit/Card'

import { T } from '../../../i18n/components/T.js'
import { logger } from '../../lib/logger.js'

export const ErrorFallback: FC = () => (
  <Card>
    <Card.Content>
      <h4>
        <T
          id="Generics.SomethingWentWrong"
          defaultMessage="Something went wrong"
        />
      </h4>
    </Card.Content>
  </Card>
)

interface Props {
  children?: ReactNode
  onError?: (error: Error, errorInfo: ErrorInfo) => void
  fallback?: ReactNode
}

interface State {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error({ error, errorInfo })
    const { onError } = this.props
    if (typeof onError === 'function') {
      onError(error, errorInfo)
    }
  }

  render() {
    const { hasError } = this.state
    const { fallback, children } = this.props

    if (hasError) {
      // You can render any custom fallback UI
      return fallback || <ErrorFallback />
    }

    return children
  }
}
