import { memo, useState, useMemo } from 'react'

import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

import { FieldView } from '../CustomField/FieldView.js'
import { T } from '../i18n/components/T.js'
import { MediaModalBoundary } from '../MediaModal/MediaModalBoundary.js'
import { PostMediaModalTitle } from '../MediaModal/TitleComponents/PostMediaModalTitle.js'
import { PostTranslate } from './PostTranslate.js'
import type { PostContext } from './types.js'

export const PostContent = memo(
  ({
    post,
    collapsible = true,
    context,
  }: {
    post: Post
    collapsible?: boolean
    context?: PostContext
  }) => {
    const { Link } = useRouter()
    const [translatedPost, setTranslatedPost] = useState<Post>(null)
    const {
      data: { postTypes },
    } = useNetwork()

    const fields = useMemo(
      () =>
        postTypes?.find(postType => postType?.id === post?.postTypeId)
          ?.postFields?.fields || [],
      [post?.postTypeId, postTypes],
    )

    const rootPost = post?.repliedTos?.[post?.repliedTos.length - 1]

    return (
      <MediaModalBoundary
        key={`${post.id}-post-content`}
        title={<PostMediaModalTitle post={post} />}
      >
        <div className={clsx('max-w-full break-words mb-2')}>
          {context === 'moderation' && rootPost?.title && (
            <h2>
              <Link
                variant="inherit"
                href={rootPost?.relativeUrl}
                className="text-content block text-heading-sm font-medium mb-4"
              >
                <T
                  id="Post.PostContent.RepliedToText"
                  defaultMessage="Replied to: {root_post_title}"
                  values={{ root_post_title: rootPost?.title }}
                />
              </Link>
            </h2>
          )}
          <div className="flex flex-col space-y-2">
            {fields?.map(field => {
              return (
                <FieldView
                  collapsible={collapsible}
                  key={field?.key}
                  field={field}
                  entity={translatedPost || post}
                  context={context}
                />
              )
            })}
            <PostTranslate post={post} onTranslate={setTranslatedPost} />
          </div>
        </div>
      </MediaModalBoundary>
    )
  },
)
