import { cva } from 'class-variance-authority'

export const tabListStyles = cva(
  [
    'isolate relative',
    'transition duration-200',
    'overflow-x-auto scrollbar-hide',
  ],
  {
    variants: {
      variant: {
        filled: [],
        underlined: [],
        plain: ['rounded-base p-1'],
      },
      backgroundType: {
        onSurface: '',
        onBackground: '',
      },
      fullWidth: {
        true: 'inline-flex gap-2 flex-nowrap w-full',
        false: 'flex gap-2 flex-nowrap',
      },
    },
    defaultVariants: {
      fullWidth: true,
    },

    compoundVariants: [
      {
        variant: 'plain',
        backgroundType: 'onSurface',
        className: ['bg-surface-subdued'],
      },
      {
        variant: 'plain',
        backgroundType: 'onBackground',
        className: ['bg-background-pressed'],
      },
    ],
  },
)

export const tabItemStyles = cva(
  [
    'shrink-0',
    'relative',
    'focus:outline-none focus-visible:ring ring-inset ring-offset-0',
    'transition duration-200',
    'px-3.5 py-2 min-h-[40px] rounded-base',
    'text-label-sm font-semibold',
  ],
  {
    variants: {
      variant: {
        filled: [],
        underlined: [],
        plain: [],
      },
      backgroundType: {
        onSurface: '',
        onBackground: '',
      },
      fullWidth: {
        true: 'grow',
        false: '',
      },
    },
    compoundVariants: [
      {
        variant: 'filled',
        backgroundType: 'onSurface',
        className: [
          'text-content-subdued bg-surface hover:bg-surface-hovered',
          'aria-selected:text-content aria-selected:bg-surface-selected',
          'disabled:text-content-disabled disabled:hover:bg-surface',
        ],
      },
      {
        variant: 'filled',
        backgroundType: 'onBackground',
        className: [
          'text-content-subdued bg-background hover:bg-background-hovered',
          'aria-selected:text-content aria-selected:bg-background-selected',
          'disabled:text-content-disabled disabled:hover:bg-background',
        ],
      },
      {
        variant: 'underlined',
        backgroundType: 'onSurface',
        className: [
          'text-content-subdued bg-surface hover:bg-surface-hovered',
          'aria-selected:text-content aria-selected:bg-surface',
          'disabled:text-content-disabled disabled:hover:bg-surface',
        ],
      },
      {
        variant: 'underlined',
        backgroundType: 'onBackground',
        className: [
          'text-content-subdued bg-background hover:bg-background-hovered',
          'aria-selected:text-content aria-selected:bg-background',
          'disabled:text-content-disabled disabled:hover:bg-background',
        ],
      },
      {
        variant: 'plain',
        backgroundType: 'onSurface',
        className: [
          'text-content-subdued bg-surface-subdued hover:bg-surface-subdued-hovered',
          'aria-selected:text-content aria-selected:bg-surface',
          'disabled:text-content-disabled disabled:hover:bg-surface-subdued',
        ],
      },
      {
        variant: 'plain',
        backgroundType: 'onBackground',
        className: [
          'text-content-subdued bg-background-subdued hover:bg-background-hovered',
          'aria-selected:text-content aria-selected:bg-background',
          'disabled:text-content-disabled disabled:hover:bg-background-subdued',
        ],
      },
    ],
  },
)
