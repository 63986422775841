import ColorContrastChecker from 'color-contrast-checker'

import { Badge } from '@tribeplatform/react-ui-kit/Badge'

import { getColoredLabels } from '../../CMS/utils/utils.js'
import { CustomFieldViewProps } from '../utils.js'

export const PillsView = ({ value, field }: CustomFieldViewProps) => {
  if (!value?.length) {
    return null
  }
  if (typeof value === 'string') {
    value = [value]
  }

  const contrastChecker = new ColorContrastChecker()

  return (
    <div className="flex flex-wrap gap-2">
      {value?.map(id => {
        let textColor
        const coloredLabels = getColoredLabels(field)
        const text = coloredLabels?.find(option => option?.id === id)?.name
        const currentOption = coloredLabels?.find(option => option?.id === id)
        const color = currentOption?.colorHex
        if (color) {
          textColor =
            contrastChecker.hexToLuminance(color) < 0.5 ? '#ffffff' : '#000000'
        }

        return (
          <Badge
            key={text}
            size="lg"
            style={{ backgroundColor: color, color: textColor }}
          >
            {text}
          </Badge>
        )
      })}
    </div>
  )
}
