import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { SectionLayout, Spacing } from '../types.js'

export const layoutOptionsFactory: (intl: I18nShape) => {
  text: string
  value: SectionLayout
  icon: JSX.Element
}[] = ({ $t }) => [
  {
    value: SectionLayout.Single,
    text: $t({
      defaultMessage: '1 column',
      id: 'Blocks.Section.Layout.Single',
    }),
    icon: <SvgIcon size="lg" name="columns-1-active" />,
  },
  {
    value: SectionLayout.OneVOne,
    text: $t({
      defaultMessage: '1:1',
      id: 'Blocks.Section.Layout.OneVOne',
    }),
    icon: <SvgIcon size="lg" name="columns-1-1-active-1" />,
  },
  {
    value: SectionLayout.OneVTwo,
    text: $t({
      defaultMessage: '1:2',
      id: 'Blocks.Section.Layout.OneVTwo',
    }),
    icon: <SvgIcon size="lg" name="columns-1-2-active-1" />,
  },
  {
    value: SectionLayout.TwoVOne,
    text: $t({
      defaultMessage: '2:1',
      id: 'Blocks.Section.Layout.TwoVOne',
    }),
    icon: <SvgIcon size="lg" name="columns-2-1-active-1" />,
  },
  {
    value: SectionLayout.Three,
    text: $t({
      defaultMessage: '3 columns',
      id: 'Blocks.Section.Layout.ThreeColumns',
    }),
    icon: <SvgIcon size="lg" name="columns-3-active-1" />,
  },
  {
    value: SectionLayout.Four,
    text: $t({
      defaultMessage: '4 columns',
      id: 'Blocks.Section.Layout.FourColumns',
    }),
    icon: <SvgIcon size="lg" name="columns-4-active-1" />,
  },
]

export const spacingOptionsFactory: (intl: I18nShape) => {
  text: string
  value: Spacing
}[] = ({ $t }) => [
  {
    value: Spacing.None,
    text: $t({
      defaultMessage: 'None',
      id: 'Generics.None',
    }),
  },
  {
    value: Spacing.ExtraSmall,
    text: $t({
      defaultMessage: 'Extra small',
      id: 'Generics.ExtraSmall',
    }),
  },
  {
    value: Spacing.Small,
    text: $t({
      defaultMessage: 'Small',
      id: 'Generics.Small',
    }),
  },
  {
    value: Spacing.Medium,
    text: $t({
      defaultMessage: 'Medium',
      id: 'Generics.Medium',
    }),
  },
  {
    value: Spacing.Large,
    text: $t({
      defaultMessage: 'Large',
      id: 'Generics.Large',
    }),
  },
  {
    value: Spacing.ExtraLarge,
    text: $t({
      defaultMessage: 'Extra large',
      id: 'Generics.ExtraLarge',
    }),
  },
]

export const spacingTitleMapper: (
  intl: I18nShape,
) => Record<Spacing, string> = ({ $t }) => ({
  [Spacing.None]: $t({
    defaultMessage: 'None',
    id: 'Generics.None',
  }),
  [Spacing.ExtraSmall]: $t({
    defaultMessage: 'Extra small',
    id: 'Generics.ExtraSmall',
  }),
  [Spacing.Small]: $t({
    defaultMessage: 'Small',
    id: 'Generics.Small',
  }),
  [Spacing.Medium]: $t({
    defaultMessage: 'Medium',
    id: 'Generics.Medium',
  }),
  [Spacing.Large]: $t({
    defaultMessage: 'Large',
    id: 'Generics.Large',
  }),
  [Spacing.ExtraLarge]: $t({
    defaultMessage: 'Extra large',
    id: 'Generics.ExtraLarge',
  }),
})

export const sectionLayoutDescriptionMapper: (
  intl: I18nShape,
) => Record<SectionLayout, string> = ({ $t }) => ({
  [SectionLayout.Single]: $t({
    defaultMessage: '1 column',
    id: 'Blocks.Section.Layout.Single.Description',
  }),
  [SectionLayout.OneVOne]: $t({
    defaultMessage: '2 columns, 1:1',
    id: 'Blocks.Section.Layout.OneVOne.Description',
  }),
  [SectionLayout.OneVTwo]: $t({
    defaultMessage: '2 columns, 1:2',
    id: 'Blocks.Section.Layout.OneVTwo.Description',
  }),
  [SectionLayout.TwoVOne]: $t({
    defaultMessage: '2 columns, 2:1',
    id: 'Blocks.Section.Layout.TwoVOne.Description',
  }),
  [SectionLayout.Three]: $t({
    defaultMessage: '3 columns',
    id: 'Blocks.Section.Layout.Three.Description',
  }),
  [SectionLayout.Four]: $t({
    defaultMessage: '4 columns',
    id: 'Blocks.Section.Layout.Four.Description',
  }),
})
