import type { LimitedIntlShape } from '../types.js'

const spacePluralRule =
  ({ $t }: LimitedIntlShape) =>
  chunks =>
    $t(
      {
        defaultMessage: '{space_count, plural, =1 {space} other {spaces}}',
        id: 'RESOURCE.Space.Pluralization',
      },
      {
        space_count: chunks[0],
      },
    )

const spaceCapitalPluralRule =
  ({ $t }: LimitedIntlShape) =>
  chunks =>
    $t(
      {
        defaultMessage: '{space_count, plural, =1 {Space} other {Spaces}}',
        id: 'RESOURCE.Space.Pluralization.Capital',
      },
      {
        space_count: chunks[0],
      },
    )

export const SpaceResourceRules = (intl: LimitedIntlShape) => {
  const spacePlural = spacePluralRule(intl)
  const spaceCapitalPlural = spaceCapitalPluralRule(intl)
  return {
    'SPACE.PLURAL': spacePlural,
    SPACE: spacePlural([1]),
    SPACES: spacePlural([5]),
    'SPACE_CC.PLURAL': spaceCapitalPlural,
    SPACE_CC: spaceCapitalPlural([1]),
    SPACES_CC: spaceCapitalPlural([5]),
  }
}
