export const getFileInfo = (
  file: File,
): { contentType: string; extension: string } => {
  const { type, name } = file
  const extension = name.split('.').pop().toLowerCase()
  const contentType =
    type ||
    (name.split('.').length > 1 ? `${extension}/*` : 'application/unknown')
  return { extension, contentType }
}
