import { noop } from '@tribeplatform/react-ui-kit/hooks'

import { CompiledBlock } from '../../../types/block.types.js'
import { SectionLayout, SectionProps } from '../types.js'
import { ColumnProps } from './types.js'

/**
 * Generates the order class for the column.
 * @param defaultOrder  - the position of the column in the section.
 * @param numOfColumns  - the total number of columns in the section.
 * @param reverse - whether the order should be reversed.
 */
export const getColumnOrderClass = (
  defaultOrder: number,
  numOfColumns: number,
  reverse?: boolean,
) => {
  if (!reverse) {
    return ''
  }
  // subtracting defaultOrder from childrenCount to reverse the order
  // creates a mirror effect: the last item becomes first, second-to-last becomes second, and so on.
  return `order-${numOfColumns - defaultOrder} lg:order-${defaultOrder}`
}

/**
 * Generates the column span class based on the section layout and the column position.
 * In some layouts, for example OneVTwo, one column is wider than the other. Based
 * on the position of the column in the section, the function returns the appropriate
 * column span class.
 */
const getColumnSpan = (
  sectionBlock: CompiledBlock<SectionProps>,
  columnBlock: CompiledBlock<ColumnProps>,
) => {
  const positionInSection = sectionBlock.children.indexOf(columnBlock.id)
  const isFirstColumn = positionInSection === 0
  const { layout } = sectionBlock.props

  /**
   * The classes are strictly related to the layout of the section.
   * The number of the grid columns of a section is set based on the
   * layout, hence, should the num of columns in section change, the
   * classes should be updated accordingly.
   */
  switch (layout) {
    case SectionLayout.Single:
      return 'col-span-1'
    case SectionLayout.OneVOne:
      return 'col-span-2 md:col-span-1'
    case SectionLayout.OneVTwo:
      return isFirstColumn
        ? 'col-span-3 md:col-span-1'
        : 'col-span-3 md:col-span-2'
    case SectionLayout.TwoVOne:
      return isFirstColumn
        ? 'col-span-3 md:col-span-2'
        : 'col-span-3 md:col-span-1'
    case SectionLayout.Three:
      return 'col-span-3 md:col-span-1 lg:col-span-1'
    case SectionLayout.Four:
      return 'col-span-4 md:col-span-2 lg:col-span-1'
    default: {
      const exhaustiveCheck: never = layout
      noop(exhaustiveCheck)
      return null
    }
  }
}

export const getSpanAndOrderClasses = (
  sectionBlock: CompiledBlock<SectionProps>,
  columnBlock: CompiledBlock<ColumnProps>,
) => {
  const defaultOrder = sectionBlock.children.indexOf(columnBlock.id) + 1
  const { reverse } = sectionBlock.props
  const numOfColumns = sectionBlock.children.length

  const colSpan = getColumnSpan(sectionBlock, columnBlock)
  const order = getColumnOrderClass(defaultOrder, numOfColumns, reverse)

  return `${colSpan} ${order}`
}
