import { type ReactNode, useRef, useState } from 'react'

import { clsx } from 'clsx'

import { useIsomorphicLayoutEffect } from '../hooks/useIsomorphicLayoutEffect.js'
import { SvgIcon } from '../Icon/SvgIcon.js'

interface Props {
  scrollStep?: number
  className?: string
  gradientFrom?: string
  gradientVia?: string
  children: ReactNode
}
export const Scrollable = ({
  scrollStep = 200,
  className,
  gradientFrom = 'from-background',
  gradientVia = 'via-background',
  children,
}: Props) => {
  const [showLeftAnchor, setShowLeftAnchor] = useState(false)
  const [showRightAnchor, setShowRightAnchor] = useState(false)
  const scrollableRef = useRef<HTMLDivElement>(null)
  const scrollableContentRef = useRef<HTMLDivElement>(null)

  useIsomorphicLayoutEffect(() => {
    const scrollableElement = scrollableRef.current
    const scrollableContentElement = scrollableContentRef.current
    const onScroll = () => {
      const { offsetWidth, scrollWidth } = scrollableElement
      const scrollLeft = Math.floor(Math.abs(scrollableElement.scrollLeft))
      const isStartOfScroll = scrollLeft === 0
      const isEndOfScroll = scrollLeft + offsetWidth - scrollWidth >= -1
      const hasScroll = scrollWidth > offsetWidth

      if (isStartOfScroll) {
        setShowLeftAnchor(false)
      } else {
        setShowLeftAnchor(true)
      }

      if (hasScroll && !isEndOfScroll) {
        setShowRightAnchor(true)
      } else {
        setShowRightAnchor(false)
      }
    }

    const resizeObserver = new ResizeObserver(() => {
      onScroll()
    })

    onScroll()
    scrollableElement.addEventListener('scroll', onScroll)
    resizeObserver.observe(scrollableElement)
    resizeObserver.observe(scrollableContentElement)

    return () => {
      scrollableElement.removeEventListener('scroll', onScroll)
      resizeObserver.unobserve(scrollableElement)
      resizeObserver.unobserve(scrollableContentElement)
    }
  }, [])

  const scroll = (step: number) => {
    const coefficient = document.dir === 'rtl' ? -1 : +1
    const scrollableElement = scrollableRef.current
    scrollableElement.scrollBy({
      left: coefficient * step,
      behavior: 'smooth',
    })
  }

  return (
    <div className={clsx('relative', className)}>
      <div
        ref={scrollableRef}
        className="overflow-x-auto scrollbar-hide overscroll-none"
      >
        <div ref={scrollableContentRef} className="inline-block">
          {children}
        </div>
      </div>
      {showLeftAnchor && (
        <div
          className={clsx(
            'h-full w-20 bg-gradient-to-r rtl:bg-gradient-to-l to-[rgba(255,255,255,0)] absolute top-0 start-0 flex justify-start items-center cursor-pointer text-content',
            gradientFrom,
            gradientVia,
          )}
          onClick={() => scroll(-1 * scrollStep)}
        >
          <SvgIcon
            className="w-6 h-6 rounded-full p-1 hover:bg-surface rtl:rotate-180"
            name="chevron-left"
          />
        </div>
      )}
      {showRightAnchor && (
        <div
          className={clsx(
            'h-full w-20 bg-gradient-to-l rtl:bg-gradient-to-r to-[rgba(255,255,255,0)] absolute top-0 end-0 flex justify-end items-center cursor-pointer text-content',
            gradientFrom,
            gradientVia,
          )}
          onClick={() => scroll(scrollStep)}
        >
          <SvgIcon
            className="w-6 h-6 rounded-full p-1 hover:bg-surface rtl:rotate-180"
            name="chevron-right"
          />
        </div>
      )}
    </div>
  )
}
