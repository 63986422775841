import { useRouter } from '@tribeplatform/react-sdk'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { IndicatorBadge } from '@tribeplatform/react-ui-kit/IndicatorBadge'

import { DropDownItem, DropDownItemType } from '../types.js'

interface Props {
  item: DropDownItem
}
export const DropDownItemRenderer = ({ item }: Props) => {
  const { Link } = useRouter()

  const { id, type } = item
  if (type === DropDownItemType.divider) {
    return <Dropdown.Divider className="menu-divider" />
  }

  const { label, href, icon, action, indicator } = item
  if (href) {
    return (
      <Dropdown.Item
        leadingIcon={icon}
        as={Link}
        href={href}
        data-id={id}
        trailingAddon={!!indicator && <IndicatorBadge count={indicator} />}
      >
        {label}
      </Dropdown.Item>
    )
  }

  if (action) {
    return (
      <Dropdown.Item leadingIcon={icon} onClick={action} data-id={id}>
        {label}
      </Dropdown.Item>
    )
  }

  return null
}
