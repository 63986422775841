import {
  type UseMutationOptions,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query'

import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Fields,
  Action,
  MutationUnpinReplyFromPostArgs,
} from '@tribeplatform/gql-client/types'

import { useTribeClient } from '../../useTribeClient.js'
import { getPostKey, getPostsKey } from '../../utils/keys/post.key.js'
import { getPinnedPostsKey } from '../../utils/keys/space.key.js'

export const useUnpinReplyFromPost = (options?: {
  fields?: Fields.ActionFields
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationUnpinReplyFromPostArgs
  >
}) => {
  const { useMutationOptions, fields = 'basic' } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<Action, ClientError, MutationUnpinReplyFromPostArgs>(
    variables =>
      client.mutation({
        name: 'unpinReplyFromPost',
        args: {
          variables,
          fields,
        },
      }),
    {
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(
          getPostKey({ variables: { id: variables?.postId } }),
        )
        queryClient.invalidateQueries(
          getPostKey({ variables: { id: variables?.replyId } }),
        )
        queryClient.invalidateQueries(getPostsKey())
        queryClient.invalidateQueries(getPinnedPostsKey())
      },
      ...useMutationOptions,
    },
  )
}
