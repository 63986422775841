import type { Image } from '@tribeplatform/gql-client/types'
import { noop } from '@tribeplatform/react-ui-kit/hooks'

import { LOGO_MAX_HEIGHT, LOGO_MAX_WIDTH } from './constants.js'
import { LogoSize } from './types.js'

const getLogoHeight = (logoSize: LogoSize, logoHeight: number) => {
  const referenceHeight = Math.min(logoHeight, LOGO_MAX_HEIGHT)

  switch (logoSize) {
    case LogoSize.small:
      return Math.floor(referenceHeight * 0.6)
    case LogoSize.medium:
      return Math.floor(referenceHeight * 0.8)
    case LogoSize.large:
      return referenceHeight
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = logoSize
      noop(exhaustiveCheck)
      return Math.floor(referenceHeight * 0.8)
    }
  }
}

const getLogoWidth = (logoSize: LogoSize, logoWidth: number) => {
  const referenceWidth = Math.min(logoWidth, LOGO_MAX_WIDTH)

  switch (logoSize) {
    case LogoSize.small:
      return Math.floor(referenceWidth * 0.6)
    case LogoSize.medium:
      return Math.floor(referenceWidth * 0.8)
    case LogoSize.large:
      return referenceWidth
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = logoSize
      noop(exhaustiveCheck)
      return Math.floor(referenceWidth * 0.8)
    }
  }
}

export const getLogoSize = (
  image: Image,
  logoSize: LogoSize = LogoSize.medium,
) => {
  const { width: logoWidth, height: logoHeight } = image ?? {}
  if (!logoWidth || !logoHeight) {
    return {
      width: getLogoWidth(logoSize, LOGO_MAX_WIDTH),
      height: getLogoHeight(logoSize, LOGO_MAX_HEIGHT),
    }
  }

  const logoRatio = logoWidth / logoHeight
  const thresholdRatio = LOGO_MAX_WIDTH / LOGO_MAX_HEIGHT

  if (logoRatio > thresholdRatio) {
    const width = getLogoWidth(logoSize, logoWidth)
    const height = Math.round((logoHeight * width) / logoWidth)

    return { width, height }
  }

  const height = getLogoHeight(logoSize, logoHeight)
  const width = Math.round((logoWidth * height) / logoHeight)

  return { width, height }
}
