import { lazy, Suspense, useState } from 'react'

import { NetworkVisibility, type Post } from '@tribeplatform/gql-client/types'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'

import { EntityImage } from '../../common/components/EntityImage.js'
import { convertIdToGlyph } from '../../MediaPicker/utils/utils.js'
import { useWebShare } from '../../utils/react-use-web-share.js'
import { useMobileDetect } from '../../utils/use-mobile-detect-hook.js'
import { ShareAction as TShareAction } from '../../Views/types.js'
import { getTextIconSettings } from '../utils/utils.js'

const ShareModal = lazy(() =>
  import('../../ShareModal/ShareModal.js').then(module => ({
    default: module.ShareModal,
  })),
)

interface Props {
  post: Post
  action: TShareAction
}
export const ShareAction = ({ post, action }: Props) => {
  const { defaultLabel, variant, glyphId, color } = action
  const { data: network } = useNetwork()
  const [showShareModal, setShowShareModal] = useState(false)
  const { isSupported, share } = useWebShare()
  const detectMobile = useMobileDetect()
  const { showText, showIcon } = getTextIconSettings(variant)
  const isPrivate =
    post?.space?.private || network?.visibility === NetworkVisibility.private

  const handleClick = () => {
    if (!isPrivate && isSupported && detectMobile.isMobile()) {
      share({ title: post.title, url: post.url })
    } else {
      setShowShareModal(true)
    }
  }

  return (
    <>
      <div className="flex-1">
        <Button
          variant="secondaryNeutral"
          className="group select-none"
          size="lg"
          fullWidth
          leadingIcon={
            showIcon && (
              <EntityImage
                entity={{
                  image: convertIdToGlyph(glyphId),
                  name: defaultLabel || '',
                }}
                size="lg"
                displayColor={color}
              />
            )
          }
          onClick={handleClick}
        >
          {showText && <span className="truncate">{defaultLabel}</span>}
        </Button>
      </div>
      {showShareModal && (
        <Suspense fallback={<></>}>
          <ShareModal
            isPrivate={isPrivate}
            open
            onClose={() => setShowShareModal(false)}
            title={post.title}
            postId={post.id}
            url={post.url}
          />
        </Suspense>
      )}
    </>
  )
}
