import { forwardRef, useRef, useState } from 'react'

import { clsx } from 'clsx'
import { useClipboard } from 'use-clipboard-copy'

import { SvgIcon } from '../Icon/SvgIcon.js'
import { Input, InputProps } from '../Input/Input.js'
import { Tooltip } from '../Tooltip/index.js'

export type InputCopyProps = InputProps & {
  hideValue?: boolean
  controlLabels?: {
    show?: string
    hide?: string
    copy?: string
    copied?: string
  }
}

export const InputCopy = forwardRef<HTMLInputElement, InputCopyProps>(
  (props, ref) => {
    const { value, hideValue = false, controlLabels = {}, ...rest } = props

    const {
      show: showLabel = 'Show',
      hide: hideLabel = 'Hide',
      copy: copyLabel = 'Copy',
      copied: copiedLabel = 'Copied',
    } = controlLabels

    const [copied, setCopied] = useState(false)
    const clipboard = useClipboard()

    const [showValue, setShowValue] = useState(!hideValue)
    const timeoutHandle = useRef<NodeJS.Timeout>(null)

    const onCopy = () => {
      clipboard.copy(value)
      setCopied(true)
      clearTimeout(timeoutHandle.current)
      timeoutHandle.current = setTimeout(() => setCopied(false), 2000)
    }

    return (
      <Input
        ref={ref}
        {...rest}
        value={value}
        onClick={e => e.currentTarget.select()}
        type={showValue ? 'text' : 'password'}
        trailingAddon={
          <div className="flex items-center divide-x divide-line-subdued">
            {hideValue && (
              <div className="flex px-2 first:ps-0 last:pe-0">
                <Tooltip>
                  <Tooltip.Trigger asChild>
                    <button
                      type="button"
                      onClick={() => setShowValue(!showValue)}
                      className={clsx(
                        'p-0.5',
                        'transition duration-200',
                        'text-content-subdued hover:text-content',
                        'focus:outline-none focus-visible:ring',
                        'rounded-base',
                      )}
                    >
                      <SvgIcon name={showValue ? 'eye-off' : 'eye'} size="lg" />
                    </button>
                  </Tooltip.Trigger>
                  <Tooltip.Panel>
                    {showValue ? hideLabel : showLabel}
                  </Tooltip.Panel>
                </Tooltip>
              </div>
            )}
            <div className="flex px-2 first:ps-0 last:pe-0">
              <Tooltip defaultOpen={copied}>
                <Tooltip.Trigger asChild>
                  <button
                    type="button"
                    onClick={onCopy}
                    className={clsx(
                      'p-0.5',
                      'transition duration-200',
                      'text-content-subdued hover:text-content',
                      'focus:outline-none focus-visible:ring',
                      'rounded-base',
                    )}
                  >
                    <SvgIcon name={copied ? 'check' : 'copy'} size="lg" />
                  </button>
                </Tooltip.Trigger>
                <Tooltip.Panel>
                  {copied ? copiedLabel : copyLabel}
                </Tooltip.Panel>
              </Tooltip>
            </div>
          </div>
        }
      />
    )
  },
)
