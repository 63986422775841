import type { Post } from '@tribeplatform/gql-client/types'
import type { StaticField, View } from '@tribeplatform/react-components/Views'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { List } from '@tribeplatform/react-ui-kit/Layout'

import { PostsListItem } from './PostsListItem.js'

interface Props {
  posts: Post[]
  fields: StaticField[]
  showDivider: View['showDivider']
  activeFieldId?: string
  showMoreButton: JSX.Element
  linkTo?: string
  singlePostType?: boolean
  preview: boolean
}
export const PostsList = ({
  posts,
  fields,
  showDivider = true,
  activeFieldId,
  showMoreButton,
  linkTo,
  singlePostType,
  preview,
}: Props) => {
  return (
    <Card padding="md">
      <Card.Content>
        <List direction="vertical" divider={showDivider} spacing="lg">
          {posts.map(post => (
            <List.Item key={post.id}>
              <PostsListItem
                post={post}
                linkTo={linkTo}
                fields={fields.filter(
                  field =>
                    field.enabled &&
                    !(field.styles && field.styles.indexOf('list') === -1),
                )}
                activeFieldId={activeFieldId}
                singlePostType={singlePostType}
                preview={preview}
              />
            </List.Item>
          ))}
          {!!showMoreButton && <List.Item>{showMoreButton}</List.Item>}
        </List>
      </Card.Content>
    </Card>
  )
}
