import { gql } from 'graphql-tag'

import { TagFields, tagGQLFields } from './tag.fields.js'

export function getTagsGQLQuery(fields: TagFields) {
  return gql`
    query GetTags(
      $spaceId: ID
      $limit: Int!
      $reverse: Boolean
      $query: String
      $after: String
      $ids: [ID!]
    ) {
      tags(
        spaceId: $spaceId
        limit: $limit
        reverse: $reverse
        query: $query
        after: $after
        ids: $ids
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            ${tagGQLFields(fields)}
          }
        }
        nodes {
          ${tagGQLFields(fields)}
        }
      }
    }
  `
}

export const createTagGQLMutation = (fields: TagFields) => gql`
  mutation CreateTag($spaceId: ID!, $input: CreateTagInput!) {
    createTag(spaceId: $spaceId, input: $input) {
      ${tagGQLFields(fields)}
    }
  }
`

export const updateTagGQLMutation = (fields: TagFields) => gql`
  mutation UpdateTag($id: ID!, $spaceId: ID!, $input: UpdateTagInput!) {
    updateTag(id: $id, spaceId: $spaceId, input: $input) {
      ${tagGQLFields(fields)}
    }
  }
`
