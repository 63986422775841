import { Element } from 'html-react-parser'

import { DeferredFile } from '../MediaModal/@types.js'

export const getOriginalImageSrc = (src: string) => {
  return src?.replace(/w=\d+&?/, '').replace(/auto=[A-Za-z,]+&?/, '')
}

export const imgToSlide = (domNode: Element) => {
  const { src, 'data-id': id } = domNode?.attribs || {}

  if (!src) {
    return undefined
  }

  return {
    id,
    downloadUrl: getOriginalImageSrc(src),
    url: src,
    cropX: 0,
    cropY: 0,
    cropZoom: 1,
  }
}

/**
 * No proper type is available for domNode prop and is hard to efficiently narrow.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFigCaption = (domNode: any) => {
  const figcaptionNode = domNode.children.find(
    node => node.name === 'figcaption',
  )
  return figcaptionNode?.children[0]?.data
}

/**
 * No proper type is available for domNode prop and is hard to efficiently narrow.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const imageFigureToSlide = (domNode: any) => {
  const imageNode = domNode.children.find(node => node.name === 'img')
  const { src, 'data-id': id, alt } = imageNode?.attribs || {}

  const description = getFigCaption(domNode) || alt

  if (!src) {
    return undefined
  }

  return {
    id,
    downloadUrl: getOriginalImageSrc(src),
    description,
    url: src,
    cropX: 0,
    cropY: 0,
    cropZoom: 1,
  }
}

/**
 * No proper type is available for domNode prop and is hard to efficiently narrow.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const audioVideFigureToSlide = (domNode: any) => {
  const { 'data-file-type': extension } = domNode?.attribs || {}
  const avNode = domNode.children.find(
    ({ name }) => name === 'video' || name === 'audio',
  )
  const { src, 'data-id': id } = avNode?.attribs || {}

  if (!src) {
    return undefined
  }

  return {
    id,
    downloadUrl: src,
    url: src,
    extension,
  }
}

/**
 * No proper type is available for domNode prop and is hard to efficiently narrow.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const figureToSlide = (domNode: any) => {
  const { 'data-type': dataType } = domNode.attribs
  switch (dataType) {
    case 'image':
      return imageFigureToSlide(domNode)
    case 'audio':
    case 'video':
      return audioVideFigureToSlide(domNode)
    default:
      return undefined
  }
}

/**
 * No proper type is available for domNode prop and is hard to efficiently narrow.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const attachmentToDeferredSlide = (domNode: any): DeferredFile => {
  const attachmentId = domNode.attribs['data-id']
  return {
    id: attachmentId,
    deferred: true,
    downloadUrl: undefined,
    url: undefined,
    extension: undefined,
  }
}
