import { useMemo } from 'react'

import { MESSAGING_POST_TYPE_SLUG } from '@bettermode/common/utils'
import { PostTypeContext } from '@tribeplatform/gql-client/types'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

export const useCMS = () => {
  const { data: network } = useNetwork()
  const allPostTypes = useMemo(
    () => network?.postTypes ?? [],
    [network?.postTypes],
  )
  const allCMSTypes = useMemo(
    () => allPostTypes.filter(({ slug }) => slug !== MESSAGING_POST_TYPE_SLUG),
    [allPostTypes],
  )
  const allActiveCMSTypes = useMemo(
    () => allCMSTypes.filter(({ archived }) => !archived),
    [allCMSTypes],
  )
  const allArchivedCMSTypes = useMemo(
    () => allCMSTypes.filter(({ archived }) => !!archived),
    [allCMSTypes],
  )
  const postTypes = useMemo(
    () =>
      allCMSTypes.filter(
        postType =>
          postType?.context === PostTypeContext.post &&
          postType.slug !== MESSAGING_POST_TYPE_SLUG,
      ),
    [allCMSTypes],
  )
  const allActivePostTypes = useMemo(
    () => postTypes.filter(({ archived }) => !archived),
    [postTypes],
  )
  const replyTypes = useMemo(
    () =>
      allCMSTypes.filter(
        ({ context, archived }) =>
          !archived && context === PostTypeContext.reply,
      ),
    [allCMSTypes],
  )
  const allActiveReplyTypes = useMemo(
    () => replyTypes.filter(({ archived }) => !archived),
    [replyTypes],
  )
  const composablePostTypes = useMemo(
    () =>
      postTypes.filter(
        ({ authMemberProps }) => authMemberProps?.availableSpaces?.length > 0,
      ),
    [postTypes],
  )

  return {
    allCMSTypes,
    allActiveCMSTypes,
    allActiveReplyTypes,
    allActivePostTypes,
    allArchivedCMSTypes,
    postTypes,
    replyTypes,
    composablePostTypes,
  }
}
