import { useMembersByIds } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { InlineMultiselect } from '@tribeplatform/react-ui-kit/Multiselect'

import { MemberAvatar } from '../../../common/components/index.js'
import { T } from '../../../i18n/index.js'

interface Props {
  value: string[]
  options: string[]
  className?: string
  onChange: (memberIds: string[]) => void
}
export const InlineMemberPickerFromIds = ({
  value,
  options,
  className,
  onChange,
}: Props) => {
  const { data: memberOptions, isInitialLoading } = useMembersByIds({
    variables: { ids: options },
    fields: {
      profilePicture: { onImage: { urls: 'basic' } },
    },
    useQueryOptions: {
      enabled: options.length > 0,
    },
  })

  const handleClick = (memberId: string) => {
    if (value.includes(memberId)) {
      const newValue = value.filter(id => id !== memberId)
      onChange(newValue)
      return
    }

    const newValue = [...value, memberId]
    onChange(newValue)
  }

  return (
    <InlineMultiselect className={className}>
      <InlineMultiselect.Items isLoading={isInitialLoading}>
        {!memberOptions && isInitialLoading && (
          <div className="text-sm text-content-subdued flex justify-center">
            <SvgIcon className="animate-spin" size="lg" name="spinner" />
          </div>
        )}
        {!isInitialLoading && memberOptions && memberOptions.length === 0 && (
          <div className="text-sm text-content-subdued">
            <T id="Generics.NoResults" defaultMessage="No results" />
          </div>
        )}
        {memberOptions &&
          memberOptions.map(member => (
            <InlineMultiselect.Item
              key={member.id}
              selected={value.includes(member.id)}
              onSelect={() => handleClick(member.id)}
            >
              <div className="flex items-center gap-x-2 h-6">
                <MemberAvatar size="xl" member={member} />
                <div className="flex-1 truncate text-base">{member.name}</div>
              </div>
            </InlineMultiselect.Item>
          ))}
      </InlineMultiselect.Items>
    </InlineMultiselect>
  )
}
