import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { clsx } from 'clsx'
import { animate } from 'motion'
import { twMerge } from 'tailwind-merge'

import { AnimatePortal } from '../animation/AnimatePortal.js'
import { Backdrop } from '../Backdrop/index.js'
import { BackgroundProvider } from '../BackgroundContext/index.js'
import { useDocument } from '../hooks/useDocument.js'
import {
  nodeContainsDescendant,
  wasContentNodeDescendant,
} from '../utils/descendant.js'
import { focusFirstFocusableNode } from '../utils/focus.js'
import { useUniqueId } from '../utils/unique-id.js'
import { useKeypressListener } from '../utils/useKeypressListener.js'
import { ModalPanel } from './components/ModalPanel.js'
import { ModalContent } from './ModalContent.js'
import { ModalContext } from './ModalContext.js'
import { ModalActions, ModalFooter } from './ModalFooter.js'
import { ModalHeader } from './ModalHeader.js'
import { ModalProps } from './types.js'
import { animateTransition, labelledById } from './utils.js'

/**
 * A dialog is a window overlaid on either the primary window or another dialog window.
 * Content behind a modal dialog is inert, meaning that users cannot interact with it.
 */
export const Modal = ({
  open = false,
  onClose,
  children,
  size = 'lg',
  className,
  panelClassName,
  initialFocus,
  closeOnBackdropClick = true,
  activator,
  showCloseBtn = true,
  defaultZenState = false,
  showZenModeBtn = false,
  scrollable = true,
  transition = 'scale',
}: ModalProps) => {
  const { document } = useDocument()
  const id = useUniqueId('modal')

  const activatorRef = useRef<HTMLDivElement>(null)
  const containerNode = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (
      open &&
      containerNode.current &&
      !containerNode.current.contains(document.activeElement)
    ) {
      if (initialFocus && initialFocus.current) {
        initialFocus.current.focus()
      } else {
        focusFirstFocusableNode(document, containerNode.current)
      }
    }
  }, [document, initialFocus, open])

  useEffect(() => {
    if (!containerNode.current) {
      return
    }

    try {
      animate(
        containerNode.current,
        {
          transform: animateTransition(transition, open),
        },
        { duration: 0.3 },
      )
    } catch (e) {
      // ignore
    }
  }, [open, transition])

  const [zenMode, setZenMode] = useState(defaultZenState)
  const toggleZenMode = useCallback(() => {
    setZenMode(zenMode => !zenMode)
  }, [])

  const handleClose = useCallback(() => {
    onClose()

    const activatorElement =
      activator && isRef(activator)
        ? activator && activator.current
        : activatorRef.current
    if (activatorElement) {
      requestAnimationFrame(() =>
        focusFirstFocusableNode(document, activatorElement),
      )
    }
  }, [document, activator, onClose])

  const activatorMarkup =
    activator && !isRef(activator) ? (
      <div ref={activatorRef}>{activator}</div>
    ) : null

  const handleEscape = (event: Event) => {
    if (!open) {
      return
    }

    const target = event.target as HTMLElement
    const composedPath = event.composedPath()
    const wasDescendant = wasContentNodeDescendant(composedPath, containerNode)
    const isActivatorDescendant = nodeContainsDescendant(
      activatorRef.current,
      target,
    )

    if (wasDescendant || isActivatorDescendant) {
      handleClose()
    }
  }

  const handleBackdropClick = () => {
    if (!closeOnBackdropClick) {
      return
    }
    handleClose()
  }

  const contextValue = useMemo(
    () => ({
      id,
      open,
      onClose: handleClose,
      showCloseBtn,
      showZenModeBtn,
      zenMode,
      toggleZenMode,
      scrollable,
    }),
    [
      id,
      open,
      handleClose,
      showCloseBtn,
      showZenModeBtn,
      zenMode,
      toggleZenMode,
      scrollable,
    ],
  )

  useKeypressListener('Escape', handleEscape, 'keyup')

  return (
    <>
      {activatorMarkup}
      <AnimatePortal idPrefix="modal" open={open}>
        <ModalContext.Provider value={contextValue}>
          <BackgroundProvider backgroundType="surface">
            <div
              className={twMerge(
                clsx(
                  'pointer-events-none fixed inset-0 z-10 flex flex-col justify-center',
                  'overflow-y-hidden overscroll-contain',
                  'transition-all',
                  !zenMode && 'p-0 sm:p-4',
                  className,
                ),
              )}
            >
              <div className="min-h-0">
                <ModalPanel
                  ref={containerNode}
                  aria-labelledby={labelledById(id)}
                  size={size}
                  zenMode={zenMode}
                  className={panelClassName}
                >
                  {children}
                </ModalPanel>
              </div>
            </div>

            <Backdrop onClick={handleBackdropClick} />
          </BackgroundProvider>
        </ModalContext.Provider>
      </AnimatePortal>
    </>
  )
}

Modal.Header = ModalHeader
Modal.Content = ModalContent
Modal.Footer = ModalFooter
Modal.Actions = ModalActions

function isRef(
  ref: React.RefObject<HTMLElement> | React.ReactElement,
): ref is React.RefObject<HTMLElement> {
  return Object.prototype.hasOwnProperty.call(ref, 'current')
}
