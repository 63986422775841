import { nanoid } from 'nanoid'

import { fileSize } from '@bettermode/common/filesize'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { I18nShape } from '../../i18n/types.js'

const linkToAssetDescriptor = (
  link: string,
): { url: string; fileName: string } => {
  if (link.startsWith('data:')) {
    return { url: link, fileName: `${nanoid(10)}-${Date.now()}` }
  }
  const url = new URL(link)
  return {
    url: url.href,
    fileName: url.href.split('/').slice(-1)[0],
  }
}

export const linkToFile = async (
  link: string,
  { $t }: I18nShape,
): Promise<File | undefined> => {
  const { url, fileName } = linkToAssetDescriptor(link)

  try {
    const blob: Blob = await fetch(url, {
      method: 'GET',
    }).then(r => r.blob())

    const file = new File([blob], fileName, {
      type: blob.type,
    })

    return file
  } catch (error) {
    toast({
      title: $t({
        id: 'Generics.Error',
        defaultMessage: 'Error',
      }),
      description: error.message,
      status: 'error',
    })
    return undefined
  }
}

export const fileSizeLimitError = (
  limit: number | undefined,
  type: 'file' | 'image',
  { $t }: I18nShape,
) => {
  if (!limit) {
    return ''
  }

  const fileSizeStr = fileSize(limit)
  if (type === 'image') {
    return $t(
      {
        id: 'Generics.ImageSizeLimit',
        defaultMessage: 'The image size limit is {size_limit}.',
      },
      { file_type: type, size_limit: fileSizeStr },
    )
  }
  return $t(
    {
      id: 'Generics.FileSizeLimit',
      defaultMessage: 'The file size limit is {size_limit}.',
    },
    { file_type: type, size_limit: fileSizeStr },
  )
}
