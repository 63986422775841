import { forwardRef } from 'react'

import { Post, PostStatus } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { T } from '../i18n/components/T.js'
import { PostContent } from './PostContent.js'
import { PostHeader } from './PostHeader.js'
import { PostInfoBar } from './PostInfoBar.js'
import { PostMemberBar } from './PostMemberBar.js'
import { PostOptions } from './PostOptions.js'
import { PostPendingIndicator } from './PostPendingIndicator.js'
import { TagPills } from './TagPills.js'
import type { PostContext } from './types.js'

export type PostCardContentProps = {
  post: Post
  context: PostContext
  activeTagId: string
  activeTagSlug: string
  collapsible: boolean
  readonly?: boolean
  showContent?: boolean
}

export const PostCardContent = forwardRef<HTMLDivElement, PostCardContentProps>(
  (
    {
      post,
      context,
      activeTagId,
      activeTagSlug,
      collapsible,
      readonly = false,
      showContent = true,
    },
    ref,
  ) => {
    const showSpace = context !== 'space'
    const showPostOptions =
      !readonly &&
      context !== 'post' &&
      (context !== 'moderation' || !post.repliedToId)
    const showPendingReview =
      context === 'moderation' && post.status === PostStatus.BLOCKED
    const isDeleted = post.status === PostStatus.DELETED
    const pinnedReply = post?.pinnedReplies?.[0]
    const { Link } = useRouter()

    return (
      <div className="flex flex-col space-y-4">
        {context === 'post' && (
          <PostHeader post={post} context={context} className="pb-3" />
        )}
        <div className="flex items-center space-s-2 w-full" ref={ref}>
          <PostMemberBar post={post} showSpace={showSpace} />
          {!isDeleted && (
            <div className="flex items-center space-s-2">
              {showPendingReview && <PostPendingIndicator />}
              {showPostOptions && <PostOptions post={post} context={context} />}
            </div>
          )}
        </div>
        {showContent && (
          <div className="post-content flex flex-col space-y-2">
            <PostContent
              post={post}
              collapsible={collapsible}
              context={context}
            />
            <TagPills
              tags={post?.tags}
              activeTagId={activeTagId}
              activeTagSlug={activeTagSlug}
            />
            {pinnedReply ? (
              <div className="bg-surface-positive-subdued rounded-base text-content-on-positive my-1 p-4">
                <div className="text-content font-medium mb-2 flex space-s-2 items-center">
                  <SvgIcon
                    className="w-5 h-5 text-content-on-positive"
                    name="status-success"
                  />
                  <span>
                    <T
                      id="Post.PostCardContent.BestReplyByText"
                      defaultMessage="Best reply by {best_reply_member_name}"
                      values={{
                        best_reply_member_name:
                          pinnedReply?.owner?.member?.name,
                      }}
                    />
                  </span>
                </div>
                <PostContent
                  post={pinnedReply}
                  context="pinnedReply"
                  collapsible
                />
                <Link variant="inherit" href={pinnedReply?.relativeUrl}>
                  <T
                    id="Post.PostCardContent.ViewOriginalLinkText"
                    defaultMessage="View original"
                  />
                </Link>
              </div>
            ) : null}
            {!readonly && (
              <PostInfoBar post={post} isPostPage={context === 'post'} />
            )}
          </div>
        )}
      </div>
    )
  },
)
