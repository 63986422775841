import { getTrackBackground, Range } from 'react-range'

import { sliderThumbStyles, sliderTrackStyles } from './Slider.styles.js'

export type SliderProps = {
  value: number
  onChange: (value: number) => void
  disabled?: boolean
  step?: number
  min?: number
  max?: number
  id?: string
  invalid?: boolean
  /** Show current value when moving slider */
  tooltip?: boolean
}

/**
 * Sliders allow users to select a range of data points. They’re useful for dynamic filtering of data.
 */
export const Slider = ({
  value = undefined,
  disabled,
  step = 1,
  min = 0,
  max = 100,
  onChange,
  tooltip = true,
  ...rest
}: SliderProps) => {
  const values = value ? [value] : [0]

  const renderTrack = ({ props, children }) => {
    return (
      <div
        onMouseDown={props.onMouseDown}
        onTouchStart={props.onTouchStart}
        style={props.style}
        className={sliderTrackStyles({ disabled })}
      >
        {/* workaround for slider thumb getting out of bounds */}
        <div className="absolute start-0 w-4 h-1 rounded-base bg-interactive"></div>
        <div className="absolute end-0 w-4 h-1 rounded-base bg-surface-neutral"></div>
        <div
          ref={props.ref}
          className="w-full h-1 rounded-base"
          style={{
            background: getTrackBackground({
              values,
              colors: [
                'var(--bm-color-interactive)',
                'var(--bm-color-surface-neutral)',
              ],
              min,
              max,
            }),
          }}
        >
          {children}
        </div>
      </div>
    )
  }

  const renderThumb = ({ props, value, isDragged }) => {
    const { key, ...restProps } = props

    return (
      <div key={key} {...restProps} className={sliderThumbStyles()}>
        {isDragged && tooltip && (
          <div className="absolute bottom-7">
            <div className="text-label-xs bg-surface-inverse text-content-on-inverse rounded-lg px-1.5 py-[1px]">
              {value}
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <Range
      min={min}
      max={max}
      step={step}
      values={values}
      onChange={values => {
        onChange(values?.[0])
      }}
      disabled={disabled}
      renderTrack={renderTrack}
      renderThumb={renderThumb}
      {...rest}
    />
  )
}
