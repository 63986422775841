import { FC, ReactNode } from 'react'

import { clsx } from 'clsx'

import { selectItemsStyles } from '../Select/Select.styles.js'
import { HTMLTribeProps } from '../types/index.js'
import { FloatingWrapper } from '../utils/floating/index.js'
import { runIfFn } from '../utils/index.js'
import { useMultiselect } from './MultiselectContext.js'

interface MultiselectCreatableItemsRenderProps {
  creating: boolean
  inputValue: string
}

export type MultiselectCreatableItemsProps = Omit<
  HTMLTribeProps<'ul'>,
  'children'
> & {
  children?:
    | ReactNode
    | ((props: MultiselectCreatableItemsRenderProps) => ReactNode)
}

export const MultiselectCreatableItems: FC<
  MultiselectCreatableItemsProps
> = props => {
  const { children, className, ...rest } = props
  const { isOpen, getMenuProps, creating, inputValue } = useMultiselect()

  return (
    <FloatingWrapper open={isOpen} sameWidth>
      <ul
        className={clsx(
          selectItemsStyles(),
          'focus-visible:ring-1 focus:outline-none',
          className,
        )}
        {...getMenuProps({}, { suppressRefError: true })}
        {...rest}
      >
        {runIfFn(children, { creating, inputValue })}
      </ul>
    </FloatingWrapper>
  )
}
