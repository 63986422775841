import type { HelperTextProps } from '../HelperText/HelperText.js'
import { helperTextStyles } from '../HelperText/HelperText.styles.js'

export const HelperText = ({
  status = 'neutral',
  highlighted = false,
  size,
  children,
  className,
}: HelperTextProps) => {
  return (
    <div
      className={helperTextStyles({
        size,
        status,
        className,
        highlighted,
      })}
    >
      {children}
    </div>
  )
}
