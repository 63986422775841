import { I18nShape } from '@tribeplatform/react-components/i18n'
import { PostListProps } from '@tribeplatform/react-components/PostList'
import { RegisteredPostListBlock as DumbRegisteredPostListBlock } from '@tribeplatform/react-slate-kit/blocks/registered'
import { RegisteredBlock } from '@tribeplatform/react-slate-kit/types'

import { PostListBlock } from './Block.js'

export const RegisteredPostListBlocks = (
  intl: I18nShape,
): RegisteredBlock<PostListProps> => ({
  recommendedName: DumbRegisteredPostListBlock(intl).recommendedName,
  Component: PostListBlock,
  config: DumbRegisteredPostListBlock(intl).config,
})
