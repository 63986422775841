import type { SpaceFields } from '@tribeplatform/gql-client/graphql'
import type { QuerySpaceArgs, Space } from '@tribeplatform/gql-client/types'

import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSpaceKey } from '../../utils/keys/space.key.js'
import { useCachedSpace } from '../cache/useCachedSpace.js'

export const useSpace = (options: {
  variables: QuerySpaceArgs
  fields?: SpaceFields
  useQueryOptions?: UseQueryOptions<Space>
}) => {
  const { variables, fields = 'default', useQueryOptions } = options
  const { client } = useTribeClient()
  const cachedSpace = useCachedSpace(variables)
  const spaceKey = getSpaceKey({ variables, fields })

  return useQuery<Space>(spaceKey, () => client.spaces.get(variables, fields), {
    placeholderData: () => cachedSpace,
    ...useQueryOptions,
  })
}
