import { StaffReason } from '@tribeplatform/gql-client/types'

import type { I18nShape } from '../i18n/index.js'

type KnownRoleNames = 'Admin' | 'Moderator' | 'Member' | 'Unverified'

export const getTranslatedRoleName = (
  { $t }: I18nShape,
  roleName: string,
): string => {
  const knownRoleName = roleName as KnownRoleNames
  switch (knownRoleName) {
    case 'Admin':
      return $t({
        defaultMessage: 'Admin',
        id: 'Roles.Admin',
      })
    case 'Moderator':
      return $t({
        defaultMessage: 'Moderator',
        id: 'Roles.Moderator',
      })
    case 'Member':
      return $t({
        defaultMessage: 'Member',
        id: 'Roles.Member',
      })
    case 'Unverified':
      return $t({
        defaultMessage: 'Unverified',
        id: 'Generics.Unverified',
      })
    default: {
      const exhaustiveCheck: never = knownRoleName
      return exhaustiveCheck
    }
  }
}

export const getTranslatedStaffReason = (
  { $t }: I18nShape,
  reason: StaffReason,
): string => {
  switch (reason) {
    case StaffReason.NetworkStaffRole:
      return $t({
        defaultMessage: 'Site role',
        id: 'Generics.CommunityRole',
      })
    case StaffReason.SamlSso:
      return $t({
        defaultMessage: 'SAML user',
        id: 'Generics.SamlUser',
      })
    case StaffReason.SpaceStaffRole:
      return $t({
        defaultMessage: 'Space admin',
        id: 'Generics.SpaceAdmin',
      })
    default: {
      const exhaustiveCheck: never = reason
      return exhaustiveCheck
    }
  }
}
