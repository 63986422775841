import { useMemo } from 'react'

import { clsx } from 'clsx'

import { useQuery } from '@tribeplatform/react-components/common/hooks'

import { useBlock } from '../../../../hooks/block.hook.js'
import { StoredField } from '../../Fields/types.js'
import { SpaceHeaderProps } from '../../types.js'
import { FieldRenderer } from './FieldRenderer.js'

interface Props {
  field: StoredField
  className?: string
  spaceId: string
}

export const ButtonsField = ({ className, field, spaceId }: Props) => {
  const {
    extraContext: { activeFieldId },
  } = useBlock<SpaceHeaderProps, { activeFieldId?: string }>()
  const enabledFields = useMemo(
    () => field.fields.filter(field => field.enabled),
    [field.fields],
  )
  const { readonly: readonlyQuery = 'false' } = useQuery()
  const isReadonly = readonlyQuery === 'true'

  if (isReadonly || enabledFields.length === 0) {
    return null
  }

  return (
    <div
      className={clsx(
        'flex gap-2 items-center flex-wrap max-w-full',
        className,
      )}
    >
      {enabledFields.map(field => {
        return (
          <FieldRenderer
            key={field.id}
            field={field}
            highlighted={field.id === activeFieldId}
            spaceId={spaceId}
          />
        )
      })}
    </div>
  )
}
