import {
  useClearNotificationsCount,
  useReadNotifications,
} from '@tribeplatform/react-sdk/hooks'
import { IconButton } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { useI18n } from '../../i18n/providers/I18nProvider.js'

export const NotificationsMarkAsRead = () => {
  const { mutate: clearNotificationCount } = useClearNotificationsCount()
  const { mutate: readAllNotifications } = useReadNotifications()
  const { $t } = useI18n()

  return (
    <IconButton
      type="button"
      onClick={() => {
        readAllNotifications({ ids: [] })
        clearNotificationCount()
      }}
      icon={<SvgIcon name="check" />}
      variant="tertiaryNeutral"
      size="lg"
      accessibilityLabel={$t({
        defaultMessage: 'Mark all as read',
        id: 'Notifications.MarkAllAsRead',
      })}
      tooltip
    />
  )
}
