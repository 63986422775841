import { useEffect, useRef } from 'react'

import { useUniqueId } from '../utils/unique-id.js'
import { DateValue, RangeCalendarProps } from './types.js'
import { useDatePicker } from './useDatePicker.js'
import { cleanDateRange } from './utils.js'

/**
 * RangeCalendar displays a grid of days in two months and allow users to select a date range.
 */
export const RangeCalendar = ({
  value,
  onChange,
  ...rest
}: RangeCalendarProps) => {
  const prefix = useUniqueId('RangeCalendar')

  const shouldAdjust = useRef(true)
  const prevValueRef = useRef(value)

  const handleOnChange = (dates: [DateValue, DateValue]) => {
    if (!Array.isArray(dates) || dates.length !== 2) {
      onChange?.(undefined)
      return
    }

    onChange?.(cleanDateRange(dates))
  }

  const { startInputField, calendarRef } = useDatePicker({
    value: cleanDateRange(value),
    onChange: handleOnChange,
    mode: 'range',
    prefix,
    inline: true,
    showMonths: 2,
    ...rest,
  })

  useEffect(() => {
    if (calendarRef.current) {
      const prevValue = prevValueRef.current
      if (Array.isArray(value)) {
        // If date is not manually picked, adjust the shown month and picked dates according to previous and new selection
        // When end date was changed, show the month of the end date
        // and if start date was changed, show the month of start date
        if (shouldAdjust.current) {
          if (!value) {
            calendarRef.current.changeMonth(-1)
          }

          if (value?.length === 2) {
            const [start, end] = value

            if (prevValue?.length === 2) {
              const [prevStart, prevEnd] = prevValue

              if (
                start.toString() !== prevStart.toString() &&
                end.toString() === prevEnd.toString()
              ) {
                calendarRef.current.jumpToDate(start, false)
              } else {
                calendarRef.current.jumpToDate(end, false)
                calendarRef.current.changeMonth(-1)
              }
            } else {
              calendarRef.current.jumpToDate(end, false)
              calendarRef.current.changeMonth(-1)
            }
          }
        } else {
          shouldAdjust.current = true
        }

        prevValueRef.current = value
      }
    }
  }, [calendarRef, value])

  return <input type="hidden" className="hidden" ref={startInputField} />
}
