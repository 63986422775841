import { clsx } from 'clsx'

export const SidebarCardLoading = ({ count = 1 }: { count?: number }) => {
  return (
    <>
      {[...Array(count)].map((_e, i) => (
        <div
          className="bg-surface px-4 py-6 shadow-card sm:p-6 sm:rounded-card"
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        >
          <div className="space-y-2 animate-pulse">
            <div className="h-4 bg-skeleton rounded-full" />
            <div className="h-4 bg-skeleton rounded-full w-5/6" />
            <div className="h-4 bg-skeleton rounded-full" />
          </div>
        </div>
      ))}
    </>
  )
}
export const PostCardLoading = () => (
  <div className="bg-surface px-4 py-6 shadow-card sm:p-6 sm:rounded-card">
    <div className="flex space-s-4 animate-pulse">
      <div className="rounded-avatar bg-skeleton h-12 w-12" />
      <div className="flex-1 space-y-4 py-1">
        <div className="h-4 bg-skeleton rounded-full w-3/4" />
        <div className="space-y-2">
          <div className="h-4 bg-skeleton rounded-full" />
          <div className="h-4 bg-skeleton rounded-full w-5/6" />
          <div className="h-4 bg-skeleton rounded-full" />
          <div className="h-4 bg-skeleton rounded-full" />
          <div className="h-4 bg-skeleton rounded-full w-5/6" />
          <div className="h-4 bg-skeleton rounded-full" />
          <div className="h-4 bg-skeleton rounded-full" />
        </div>
      </div>
    </div>
  </div>
)
export const PostCardListLoading = ({ count = 5, pulse = true }) => {
  return (
    <>
      {[...Array(count)].map((_e, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className="bg-surface px-4 py-6 shadow-card sm:p-6 sm:rounded-card"
        >
          <div className={clsx('flex space-s-4', pulse && 'animate-pulse')}>
            <div className="rounded-avatar bg-skeleton h-12 w-12" />
            <div className="flex-1 space-y-4 py-1">
              <div className="h-4 bg-skeleton rounded-full w-3/4" />
              <div className="space-y-2">
                <div className="h-4 bg-skeleton rounded-full" />
                <div className="h-4 bg-skeleton rounded-full w-5/6" />
                <div className="h-4 bg-skeleton rounded-full" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export const PostRowListLoading = ({ count = 5 }) => {
  return (
    <>
      {[...Array(count)].map((_e, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} className="bg-surface px-4 py-6 sm:p-6 sm:rounded-card">
          <div className="animate-pulse flex space-s-4 items-center">
            <div className="rounded-avatar bg-skeleton h-8 w-8" />
            <div className="flex-1 space-y-4 py-1">
              <div className="h-4 bg-skeleton rounded-full w-3/4" />
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
