import { clsx } from 'clsx'

type FigcaptionProps = {
  children: string
  asOverlay: boolean
}

export const Figcaption = (props: FigcaptionProps) => {
  const { children, asOverlay } = props

  if (asOverlay) {
    return <OverlayCaption>{children}</OverlayCaption>
  }
  return (
    <figcaption
      className={clsx(
        /**
         * There are global styles affecting this component. That's why we are
         * marking the following properties as important.
         */
        '!text-center !mx-auto',
        '!text-content-subdued !text-xs ',
        '!px-0.5 !my-1 !max-w-prose',
        '!mt-1 !rounded-none',
      )}
    >
      {children}
    </figcaption>
  )
}

const OverlayCaption = (props: { children: string }) => {
  const { children } = props
  return (
    <figcaption
      data-as-overlay="true"
      className={clsx(
        /**
         * There are global styles affecting this component. That's why we are
         * marking the following properties as important.
         */
        '!text-start !text-xs',
        'bg-black/70 backdrop-blur-sm',
        'absolute bottom-0 end-0 w-full max-h-full overflow-y-scroll',
        '!rounded-b-base',
        '!text-white !mt-0 !p-2',
      )}
    >
      {children}
    </figcaption>
  )
}
