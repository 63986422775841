import { PostListOrderByEnum } from '@tribeplatform/gql-client/types'

import { I18nShape } from '../../i18n/types.js'
import { type SortOption, SortOptionId } from '../types.js'

export const sortOptionsFactory = (intl: I18nShape) => {
  const { $t } = intl
  const sortOptions: SortOption[] = [
    {
      id: SortOptionId.latest,
      name: $t({
        id: 'Reply.Sort.NewestFirst',
        defaultMessage: 'Newest first',
      }),
      value: PostListOrderByEnum.publishedAt,
      reverse: true,
    },
    {
      id: SortOptionId.oldest,
      name: $t({
        id: 'Reply.Sort.OldestFirst',
        defaultMessage: 'Oldest first',
      }),
      value: PostListOrderByEnum.publishedAt,
      reverse: false,
    },
    {
      id: SortOptionId.recentActivity,
      name: $t({
        id: 'Reply.Sort.RecentActivity',
        defaultMessage: 'Recent activity',
      }),
      value: PostListOrderByEnum.lastActivityAt,
      reverse: true,
    },
    {
      id: SortOptionId.mostReplied,
      name: $t({
        id: 'Reply.Sort.MostReplied',
        defaultMessage: 'Most replied',
      }),
      value: PostListOrderByEnum.totalRepliesCount,
      reverse: true,
    },
    {
      id: SortOptionId.mostReacted,
      name: $t({
        id: 'Reply.Sort.MostReacted',
        defaultMessage: 'Most reacted',
      }),
      value: PostListOrderByEnum.reactionsCount,
      reverse: true,
    },
  ]

  return sortOptions
}
