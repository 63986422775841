import { Card } from '@tribeplatform/react-ui-kit/Card'
import { EmptyState as EmptyStateComponent } from '@tribeplatform/react-ui-kit/EmptyState'

import { useI18n } from '../i18n/providers/I18nProvider.js'

export const EmptyState = () => {
  const { $t } = useI18n()

  return (
    <Card>
      <Card.Content>
        <div className="my-12">
          <EmptyStateComponent
            title={$t({
              id: 'Generics.NothingHereYet',
              defaultMessage: 'Nothing here yet.',
            })}
            description={$t({
              id: 'Feed.EmptyState.Description',
              defaultMessage:
                'Join spaces that you’re interested in or create new posts to enjoy your personalized feed.',
            })}
          />
        </div>
      </Card.Content>
    </Card>
  )
}
