import { Post } from '@tribeplatform/gql-client/types'
import type { StaticField, View } from '@tribeplatform/react-components/Views'

import { EmptyState } from './EmptyState.js'
import { PostsCard } from './PostsCard.js'
import { PostsCarousel } from './PostsCarousel.js'
import { PostsGallery } from './PostsGallery.js'
import { PostsList } from './PostsList.js'

interface Props {
  style: View['style']
  posts: Post[]
  fields: StaticField[]
  gallerySize?: View['gallerySize']
  showDivider?: View['showDivider']
  activeFieldId?: string
  showMoreButton: JSX.Element
  linkTo?: string
  singlePostType?: boolean
  preview: boolean
}
export const GenericPostsList = ({
  style,
  posts,
  fields,
  gallerySize,
  showDivider,
  activeFieldId,
  showMoreButton,
  linkTo,
  singlePostType,
  preview,
}: Props) => {
  if (posts.length === 0) {
    return <EmptyState />
  }

  switch (style) {
    case 'gallery':
      return (
        <PostsGallery
          posts={posts}
          fields={fields}
          gallerySize={gallerySize}
          activeFieldId={activeFieldId}
          showMoreButton={showMoreButton}
          linkTo={linkTo}
          singlePostType={singlePostType}
          preview={preview}
        />
      )
    case 'list':
      return (
        <PostsList
          posts={posts}
          fields={fields}
          showDivider={showDivider}
          activeFieldId={activeFieldId}
          showMoreButton={showMoreButton}
          linkTo={linkTo}
          singlePostType={singlePostType}
          preview={preview}
        />
      )
    case 'card':
      return (
        <PostsCard
          posts={posts}
          fields={fields}
          activeFieldId={activeFieldId}
          showMoreButton={showMoreButton}
          singlePostType={singlePostType}
          preview={preview}
        />
      )
    case 'carousel':
      return (
        <PostsCarousel
          posts={posts}
          fields={fields}
          gallerySize={gallerySize}
          activeFieldId={activeFieldId}
          showMoreButton={showMoreButton}
          linkTo={linkTo}
          singlePostType={singlePostType}
          preview={preview}
        />
      )
    default:
      return null
  }
}
