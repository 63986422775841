import type { PostFields } from '@tribeplatform/gql-client/graphql'
import type { Post, QueryPostArgs } from '@tribeplatform/gql-client/types'

import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPostKey } from '../../utils/keys/post.key.js'
import { useCachedPost } from '../cache/useCachedPost.js'

export interface UsePostProps {
  variables: QueryPostArgs
  fields?: PostFields
  useQueryOptions?: UseQueryOptions<Post>
}
export const usePost = ({
  variables,
  fields = 'default',
  useQueryOptions,
}: UsePostProps) => {
  const { client } = useTribeClient()
  const postKey = getPostKey({ variables, fields })
  const placeHolderPost = useCachedPost(variables?.id)

  return useQuery<Post>(postKey, () => client.posts.get(variables, fields), {
    placeholderData: (): Post => placeHolderPost,
    ...useQueryOptions,
  })
}
