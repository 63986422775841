import type { Post } from '@tribeplatform/gql-client/types'
import type { StaticField, View } from '@tribeplatform/react-components/Views'
import { Carousel } from '@tribeplatform/react-ui-kit/Carousel'

import { getCardWidth } from '../utils/index.js'
import { PostsGalleryItem } from './PostsGalleryItem.js'

interface Props {
  posts: Post[]
  fields: StaticField[]
  gallerySize: View['gallerySize']
  activeFieldId?: string
  showMoreButton: JSX.Element
  linkTo?: string
  singlePostType?: boolean
  preview: boolean
}
export const PostsCarousel = ({
  posts,
  fields,
  gallerySize = 'medium',
  activeFieldId,
  showMoreButton,
  linkTo,
  singlePostType,
  preview,
}: Props) => {
  const width = getCardWidth(gallerySize)

  return (
    <Carousel
      className="pt-1 pb-4"
      items={[
        ...posts.map(post => (
          <PostsGalleryItem
            key={post.id}
            width={width}
            post={post}
            linkTo={linkTo}
            fields={fields.filter(
              field =>
                field.enabled &&
                !(field.styles && field.styles.indexOf('carousel') === -1),
            )}
            activeFieldId={activeFieldId}
            className="sm:rounded-lg h-full"
            singlePostType={singlePostType}
            preview={preview}
          />
        )),
        showMoreButton,
      ]}
    ></Carousel>
  )
}
