import {
  CustomFieldType,
  CustomFieldValidators,
} from '@tribeplatform/gql-client/types'

import { FormFile } from '../Form/FormFile/FormFile.js'
import { FormImage } from '../Form/FormImage/FormImage.js'
import { Form } from '../Form/index.js'
import { SimpleHtmlEditor } from '../HtmlEditor/SimpleHtmlEditor.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { ArrayInput } from './components/ArrayInput.js'
import { BooleanInput } from './components/BooleanInput.js'
import { NumberInput } from './components/NumberInput.js'
import { SelectInput } from './components/SelectInput.js'
import { TextareaInput } from './components/TextareaInput.js'
import { TextInput } from './components/TextInput.js'
import { CustomFieldSubtype, getFieldSetting } from './utils.js'

export const FieldInputResolver = ({
  field,
  name = undefined,
  onChange = undefined,
  value = undefined,
  ...extraProps
}) => {
  const { $t } = useI18n()
  if (!field) {
    return null
  }
  name = name || field.accessor || field.key
  const subtype = getFieldSetting(field, 'subtype') || field.subtype

  if (!extraProps) {
    extraProps = {}
  }

  if (field?.extraProps) {
    extraProps = {
      ...extraProps,
      ...(field.extraProps ?? {}),
    }
  }

  if (field?.required && field.type !== CustomFieldType.boolean) {
    extraProps.validation = {
      required: $t({
        id: 'Generic.FieldIsRequired',
        defaultMessage: 'This field is required.',
      }),
    }
  }
  if (value) {
    extraProps.value = value
  }

  const inputProps = {
    field,
    name,
    onChange,
    value,
    extraProps,
    subtype,
    validation: undefined,
  }
  const enumValidator = field?.validators?.find(
    validator => validator.validation === CustomFieldValidators.enum,
  )

  switch (field.type) {
    case CustomFieldType.boolean:
      return <BooleanInput {...inputProps} />

    case CustomFieldType.richText:
      return (
        <div>
          <SimpleHtmlEditor name={name} {...extraProps} />
        </div>
      )

    case CustomFieldType.text:
      if (enumValidator?.value) {
        return <SelectInput {...inputProps} />
      }
      if (subtype === CustomFieldSubtype.TEXTAREA) {
        return <TextareaInput {...inputProps} />
      }
      return <TextInput {...inputProps} />

    case CustomFieldType.number:
      return <NumberInput {...inputProps} />

    case CustomFieldType.date:
      return <TextInput {...inputProps} />

    case CustomFieldType.relation:
    case CustomFieldType.array:
      switch (subtype) {
        case CustomFieldSubtype.SELECT:
        case CustomFieldSubtype.MULTISELECT:
          return <ArrayInput {...inputProps} />

        case CustomFieldSubtype.IMAGE:
        case CustomFieldSubtype.IMAGES:
          return (
            <FormImage
              variant="default"
              multiple={subtype === CustomFieldSubtype.IMAGES}
              {...inputProps}
              {...extraProps}
            />
          )

        case CustomFieldSubtype.FILE:
        case CustomFieldSubtype.FILES:
          return (
            <FormFile
              multiple={subtype === CustomFieldSubtype.FILES}
              {...inputProps}
              {...extraProps}
            />
          )

        case CustomFieldSubtype.MEMBER:
        case CustomFieldSubtype.MEMBERS:
          return (
            <Form.MemberPicker
              {...extraProps}
              {...inputProps}
              memberGroup={getFieldSetting(field, 'inputProps')?.memberGroup}
              onChange={values => {
                if (typeof onChange === 'function') {
                  onChange(values?.id)
                }
              }}
              multiple={subtype === CustomFieldSubtype.MEMBERS}
            />
          )
        case CustomFieldSubtype.TAG:
        case CustomFieldSubtype.TAGS:
          return (
            <Form.TagPicker
              {...extraProps}
              {...inputProps}
              onChange={values => {
                if (typeof onChange === 'function') {
                  onChange(values?.id)
                }
              }}
              multiple={subtype === CustomFieldSubtype.TAGS}
            />
          )
        case CustomFieldSubtype.SPACE:
        case CustomFieldSubtype.SPACES:
          return (
            <Form.SpacePicker
              {...extraProps}
              {...inputProps}
              onChange={values => {
                if (typeof onChange === 'function') {
                  onChange(values?.id)
                }
              }}
              multiple={subtype === CustomFieldSubtype.SPACES}
            />
          )
        default:
          return null
      }

    default:
      return null
  }
}
