import { T } from '@tribeplatform/react-components/i18n'
import { FormControl } from '@tribeplatform/react-ui-kit/FormControl'

interface Props {
  reverse?: boolean
  onChange?: (value: boolean) => unknown
}

export const ReverseSettings = ({ reverse, onChange }: Props) => {
  return (
    <FormControl.Toggle
      name="reverse"
      label={
        <T
          id="Blocks.Section.Settings.Responsive.Reverse"
          defaultMessage="Reverse"
        />
      }
      helperText={
        <T
          id="Blocks.Section.Settings.Responsive.ReverseHelperText"
          defaultMessage="Reverse the order of columns on small screens."
        />
      }
      checked={reverse}
      onChange={onChange}
    />
  )
}
