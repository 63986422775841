import { radius, shadow } from '@tribeplatform/design-system/common'
import type { AppearanceStyle } from '@tribeplatform/design-system/types'

export const getButtonRadius = (style: AppearanceStyle) => {
  switch (style.buttons) {
    case 'pill':
      return radius.full
    case 'sharp':
      return radius.none
    case 'rounded':
    default:
      return radius.button
  }
}
export const getButtonSmRadius = (style: AppearanceStyle) => {
  switch (style.buttons) {
    case 'pill':
      return radius.full
    case 'sharp':
      return radius.none
    case 'rounded':
    default:
      return radius['button-sm']
  }
}

export const getAvatarRadius = (style: AppearanceStyle) => {
  switch (style.avatarShape) {
    case 'full-rounded':
      return radius.full
    case 'sharp':
      return radius.none
    case 'rounded':
    default:
      return radius.md
  }
}

export const getRadiusConfig = (style: AppearanceStyle): typeof radius => {
  const isSharp = style.corners === 'sharp'

  return {
    ...radius,
    button: getButtonRadius(style),
    'button-sm': getButtonSmRadius(style),
    avatar: getAvatarRadius(style),
    base: isSharp ? radius.none : radius.base,
    'base-sm': isSharp ? radius.none : radius['base-sm'],
    card: isSharp ? radius.none : radius.card,
    input: isSharp ? radius.none : radius.input,
    modal: isSharp ? radius.none : radius.modal,
    popover: isSharp ? radius.none : radius.popover,
  }
}

export const getCardShadow = (style: AppearanceStyle) => {
  switch (style.cards) {
    case 'elevated':
      return shadow.card
    case 'bordered':
    case 'plain':
    default:
      return 'none'
  }
}
export const getTopBarShadow = (style: AppearanceStyle) => {
  switch (style.cards) {
    case 'elevated':
      return shadow.topbar
    case 'bordered':
    case 'plain':
    default:
      return 'none'
  }
}

export const getShadowConfig = (style: AppearanceStyle): typeof shadow => {
  return {
    ...shadow,
    card: getCardShadow(style),
    topbar: getTopBarShadow(style),
  }
}

export const getCardBorder = (style: AppearanceStyle) => {
  switch (style.cards) {
    case 'bordered':
      return 'var(--bm-color-line)'
    case 'elevated':
    case 'plain':
    default:
      return 'transparent'
  }
}

export const getTopBarBorder = (style: AppearanceStyle) => {
  switch (style.cards) {
    case 'bordered':
    case 'plain':
      return 'var(--bm-color-line-subdued)'
    case 'elevated':
    default:
      return 'transparent'
  }
}
export const getBorderColorConfig = (style: AppearanceStyle) => {
  return {
    card: getCardBorder(style),
    topbar: getTopBarBorder(style),
  }
}

export const getBaseBorderWidth = (style: AppearanceStyle) => {
  switch (style.cards) {
    case 'bordered':
      return '1px'
    case 'plain':
    default:
      return '0'
  }
}
export const getBorderWidthConfig = (style: AppearanceStyle) => {
  return {
    base: getBaseBorderWidth(style),
  }
}
