import type { Space } from '@tribeplatform/gql-client/types'
import type { StaticField, View } from '@tribeplatform/react-components/Views'
import { Carousel } from '@tribeplatform/react-ui-kit/Carousel'

import { getCardWidth } from '../utils/index.js'
import { SpacesGalleryItem } from './SpacesGalleryItem.js'

interface Props {
  spaces: Space[]
  fields: StaticField[]
  gallerySize: View['gallerySize']
  activeFieldId?: string
  showMoreButton: JSX.Element
  preview: boolean
}
export const SpacesCarousel = ({
  spaces,
  fields,
  gallerySize = 'medium',
  activeFieldId,
  showMoreButton,
  preview,
}: Props) => {
  const width = getCardWidth(gallerySize)

  return (
    <Carousel
      className="pt-1 pb-4"
      items={[
        ...spaces.map(space => (
          <SpacesGalleryItem
            key={space.id}
            width={width}
            space={space}
            fields={fields.filter(
              field =>
                field.enabled &&
                !(field.styles && field.styles.indexOf('carousel') === -1),
            )}
            activeFieldId={activeFieldId}
            gallerySize={gallerySize}
            className="sm:rounded-lg h-full"
            preview={preview}
          />
        )),
        showMoreButton,
      ]}
    ></Carousel>
  )
}
