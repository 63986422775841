import { Card } from '@tribeplatform/react-ui-kit/Card'

export const LoadingComponent = () => {
  return (
    <Card>
      <Card.Content>
        <div className="animate-pulse">
          <div className="flex-1 space-y-4 py-1">
            <div className="h-6 bg-skeleton rounded-full w-1/4" />
            <div className="space-y-2">
              <div className="h-4 bg-skeleton rounded-full" />
              <div className="h-4 bg-skeleton rounded-full w-5/6" />
              <div className="h-4 bg-skeleton rounded-full" />
              <div className="h-4 bg-skeleton rounded-full w-5/6" />
              <div className="h-4 bg-skeleton rounded-full" />
              <div className="h-4 bg-skeleton rounded-full w-5/6" />
              <div className="h-4 bg-skeleton rounded-full" />
            </div>
          </div>
        </div>
      </Card.Content>
    </Card>
  )
}
