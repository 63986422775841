import React from 'react'

import { clsx } from 'clsx'

export type FormLayoutProps = React.ComponentProps<'div'>

export const FormLayout = (props: FormLayoutProps) => {
  const { children, className, ...rest } = props
  return (
    <div className={clsx('space-y-5', className)} {...rest}>
      {children}
    </div>
  )
}
