import { T } from '@tribeplatform/react-components/i18n'
import { GenericSettings } from '@tribeplatform/react-components/Views'

import { useBlock } from '../../../hooks/block.hook.js'
import { LayoutSettings } from '../Components/LayoutSettings.js'
import { MarginSettings } from '../Components/MarginSettings.js'
import { PaddingSettings } from '../Components/PaddingSettings.js'
import { ResponsiveSettings } from '../Components/ResponsiveSettings.js'
import { SettingSections } from '../Components/SettingSections.js'
import { useUpdateLayout } from '../hooks/useUpdateLayout.js'
import { SectionProps } from '../types.js'

interface Props {
  deactivateBlock?: () => void
}

export const SectionBlockSettings = ({ deactivateBlock }: Props) => {
  const {
    block: {
      config: { displayName },
      props: {
        layout,
        gap,
        horizontalPadding,
        verticalPadding,
        horizontalMargin,
        verticalMargin,
        hideOnMobile,
        hideOnTablet,
        hideOnDesktop,
        reverse,
        restrictMaxWidth,
      },
    },
    upsertProp,
  } = useBlock<SectionProps>()
  const { updateLayout } = useUpdateLayout()

  return (
    <GenericSettings
      title={displayName}
      goBack={deactivateBlock}
      description={
        <T
          id="Blocks.Section.Settings.Description"
          defaultMessage="Design dynamic and responsive layouts with ease. Effortlessly configure to suit any screen size and content type."
        />
      }
    >
      <SettingSections>
        <LayoutSettings
          layout={layout}
          gap={gap}
          onLayoutChange={updateLayout}
          onGapChange={value => upsertProp('gap', value)}
        />
        <PaddingSettings
          horizontalPadding={horizontalPadding}
          verticalPadding={verticalPadding}
          onHorizontalPaddingChange={value =>
            upsertProp('horizontalPadding', value)
          }
          onVerticalPaddingChange={value =>
            upsertProp('verticalPadding', value)
          }
        />
        <MarginSettings
          horizontalMargin={horizontalMargin}
          verticalMargin={verticalMargin}
          onHorizontalMarginChange={value =>
            upsertProp('horizontalMargin', value)
          }
          onVerticalMarginChange={value => upsertProp('verticalMargin', value)}
        />
        <ResponsiveSettings
          reverse={reverse}
          restrictMaxWidth={restrictMaxWidth}
          hideOnMobile={hideOnMobile}
          hideOnTablet={hideOnTablet}
          hideOnDesktop={hideOnDesktop}
          onReverseChange={value => upsertProp('reverse', value)}
          onRestrictMaxWidthChange={value =>
            upsertProp('restrictMaxWidth', value)
          }
          onHideOnMobileChange={value => upsertProp('hideOnMobile', value)}
          onHideOnTabletChange={value => upsertProp('hideOnTablet', value)}
          onHideOnDesktopChange={value => upsertProp('hideOnDesktop', value)}
        />
      </SettingSections>
    </GenericSettings>
  )
}
