import type {
  Member,
  QueryMembersByIdsArgs,
} from '@tribeplatform/gql-client/types'
import type { MemberFields } from '@tribeplatform/gql-client/types/generated'

import {
  useQuery,
  type UseQueryOptions,
} from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getMembersByIdsKey } from '../../utils/keys/member.key.js'

export const useMembersByIds = (options: {
  variables: QueryMembersByIdsArgs
  fields?: MemberFields
  useQueryOptions?: UseQueryOptions<Member[]>
}) => {
  const { variables, fields = 'basic', useQueryOptions } = options
  const { client } = useTribeClient()
  const memberKey = getMembersByIdsKey({ variables, fields })

  return useQuery<Member[]>(
    memberKey,
    () => client.query({ name: 'membersByIds', args: { variables, fields } }),
    useQueryOptions,
  )
}
