import type { Member } from '@tribeplatform/gql-client/types'
import type { StaticField, View } from '@tribeplatform/react-components/Views'

import { EmptyState } from './EmptyState.js'
import { MembersCarousel } from './MembersCarousel.js'
import { MembersGallery } from './MembersGallery.js'
import { MembersList } from './MembersList.js'
import { MembersMagazine } from './MembersMagazine.js'

interface Props {
  style: View['style']
  members: Member[]
  fields: StaticField[]
  gallerySize?: View['gallerySize']
  showDivider?: View['showDivider']
  activeFieldId?: string
  showMoreButton: JSX.Element
}
export const GenericMembersList = ({
  style,
  members,
  fields,
  gallerySize,
  showDivider,
  activeFieldId,
  showMoreButton,
}: Props) => {
  if (members.length === 0) {
    return <EmptyState />
  }

  switch (style) {
    case 'gallery':
      return (
        <MembersGallery
          members={members}
          fields={fields}
          gallerySize={gallerySize}
          activeFieldId={activeFieldId}
          showMoreButton={showMoreButton}
        />
      )
    case 'list':
      return (
        <MembersList
          members={members}
          fields={fields}
          showDivider={showDivider}
          activeFieldId={activeFieldId}
          showMoreButton={showMoreButton}
        />
      )
    case 'carousel':
      return (
        <MembersCarousel
          members={members}
          fields={fields}
          gallerySize={gallerySize}
          activeFieldId={activeFieldId}
          showMoreButton={showMoreButton}
        />
      )
    case 'magazine':
      return (
        <MembersMagazine
          members={members}
          fields={fields}
          activeFieldId={activeFieldId}
          showMoreButton={showMoreButton}
        />
      )
    default:
      return null
  }
}
