import { PostListOrderByEnum } from '@tribeplatform/gql-client/types'
import { SortOptionId } from '@tribeplatform/react-components/Reply'

export const getRepliesReverse = (sortOrder?: SortOptionId): boolean => {
  if (!sortOrder || sortOrder === SortOptionId.oldest) {
    return false
  }

  return true
}

export const getRepliesOrderBy = (
  sortOrder?: SortOptionId,
): PostListOrderByEnum | null => {
  if (!sortOrder) {
    return null
  }

  switch (sortOrder) {
    case SortOptionId.latest:
      return PostListOrderByEnum.createdAt
    case SortOptionId.oldest:
      return PostListOrderByEnum.createdAt
    case SortOptionId.recentActivity:
      return PostListOrderByEnum.lastActivityAt
    case SortOptionId.mostReplied:
      return PostListOrderByEnum.repliesCount
    case SortOptionId.mostReacted:
      return PostListOrderByEnum.reactionsCount
    default: {
      // Code should never reach the default case
      const _exhaustiveCheck: never = sortOrder
      return null
    }
  }
}
