import type { ValueRendererComponent } from '../types.js'

export const TextRenderer: ValueRendererComponent = ({ filter }) => {
  const { value } = filter
  if (typeof value !== 'string') {
    return null
  }

  return <>{value}</>
}
