import type {
  ClientError,
  ClientFileErrorHandler,
} from '@tribeplatform/gql-client/lib'
import type {
  AttachmentUploadType,
  File,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getFileInfo } from './utils.js'

export const useCreateFiles = (options?: {
  useMutationOptions?: Omit<
    UseMutationOptions<File[], unknown, AttachmentUploadType>,
    'onError'
  > & {
    onError: ClientFileErrorHandler
  }
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  return useMutation<File[], ClientError, AttachmentUploadType>(
    input => {
      const cleanInput: AttachmentUploadType = input.map(item => {
        const { extension, contentType } = getFileInfo(item.file)
        return { ...item, extension, contentType }
      })
      return client.media.uploadFiles(cleanInput, useMutationOptions?.onError)
    },
    {
      ...useMutationOptions,
    },
  )
}
