import { clsx } from 'clsx'

import type { Space } from '@tribeplatform/gql-client/types'
import { HtmlContent } from '@tribeplatform/react-components/HtmlContent'
import type { StaticField } from '@tribeplatform/react-components/Views'

interface Props {
  field: StaticField
  space: Space
  highlighted: boolean
  stretchX: string
  lineClamp?: 'none' | 'sm' | 'md' | 'lg'
  preview: boolean
}

export const GallerySpaceDescription = ({
  field,
  space,
  highlighted,
  stretchX,
  lineClamp = 'none',
  preview,
}: Props) => {
  const { default: defaultDescription } = field
  const description = space?.description || (preview ? defaultDescription : '')

  return (
    <div
      className={clsx(
        'empty:hidden min-w-0',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
    >
      <article
        className={clsx(
          'prose break-words',
          lineClamp === 'sm' && 'line-clamp-2',
          lineClamp === 'md' && 'line-clamp-3',
          lineClamp === 'lg' && 'line-clamp-5',
        )}
      >
        <HtmlContent value={description} />
      </article>
    </div>
  )
}
