import { clsx } from 'clsx'

import { Member } from '@tribeplatform/gql-client/types'
import {
  MemberAvatar,
  MemberAvatarPlaceholder,
} from '@tribeplatform/react-components/common/components/member'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { Avatar } from '@tribeplatform/react-ui-kit/Avatar'

interface Props {
  field: StaticField
  member: Member
  highlighted: boolean
  mediaUrl: string
  aspectRatio: StaticField['settings']['aspectRatio']
}
export const GalleryBanner = ({
  field,
  member,
  highlighted,
  mediaUrl,
  aspectRatio,
}: Props) => {
  switch (field.settings?.bannerStyle) {
    case 'banner':
      return (
        <div
          className={clsx(
            'w-full relative',
            aspectRatio || 'aspect-[5/4]',
            highlighted && 'border-2 border-focused',
          )}
        >
          <div className="w-full h-full flex justify-center items-center bg-skeleton">
            <MemberAvatar size="8x" member={member} />
          </div>
          <div
            className="w-full h-full bg-cover bg-center absolute top-0 start-0"
            style={{ backgroundImage: `url(${mediaUrl})` }}
          ></div>
        </div>
      )
    case 'square':
      return (
        <div className="pb-4 relative">
          <div
            className={clsx(
              'h-16 w-16 flex justify-center items-center rounded-base',
              highlighted && 'ring-2 ring-offset-surface ring-focused',
            )}
          >
            <Avatar
              src={mediaUrl}
              size="4x"
              name={member?.name}
              rounded="md"
              fallback={MemberAvatarPlaceholder}
            />
          </div>
        </div>
      )
    default:
      // Code should never reach the default case
      // eslint-disable-next-line no-case-declarations
      const exhaustiveCheck: never = field.settings?.bannerStyle
      return exhaustiveCheck
  }
}
