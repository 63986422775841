import { useCallback, useEffect, useState } from 'react'

import { ColorSchemeMode } from '@tribeplatform/design-system/types'

import { useColorSchemeContext } from '../ColorSchemeProvider.js'
import type { UserColorScheme } from '../types.js'
import { getColorSchemeCookie, setColorSchemeCookie } from '../utils.js'

export const useColorScheme = () => {
  const [colorScheme, setColorScheme] = useState<UserColorScheme>(() => {
    return getColorSchemeCookie() ?? 'auto'
  })
  const [deviceColorMode, setDeviceColorMode] =
    useState<ColorSchemeMode | null>(null)
  const { setColorSchemeMode, colorSchemeMode } = useColorSchemeContext()

  const setMode = useCallback(
    (colorMode: UserColorScheme) => {
      setColorSchemeCookie(colorMode)
      setColorScheme(colorMode)
      setColorSchemeMode(colorMode !== 'auto' ? colorMode : null)
    },
    [setColorSchemeMode],
  )

  useEffect(() => {
    setColorScheme(getColorSchemeCookie() ?? 'auto')
  }, [colorSchemeMode])

  useEffect(() => {
    const mql = window.matchMedia('(prefers-color-scheme: dark)')

    const handler = list => {
      setDeviceColorMode(list?.matches ? 'dark' : 'light')
    }

    handler(mql)

    mql.addEventListener('change', handler)

    return () => {
      mql.removeEventListener('change', handler)
    }
  }, [])

  return { colorScheme, deviceColorMode, colorSchemeMode, setMode }
}
