import type { CollectionFields } from '@tribeplatform/gql-client/graphql'
import type {
  Collection,
  QueryCollectionArgs,
} from '@tribeplatform/gql-client/types'

import {
  useQuery,
  type UseQueryOptions,
} from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getCollectionKey } from '../../utils/keys/collection.key.js'
import { useCachedCollection } from '../cache/useCachedCollection.js'

export const useCollection = (options: {
  variables?: QueryCollectionArgs
  fields?: CollectionFields
  useQueryOptions?: UseQueryOptions<Collection>
}) => {
  const { variables, fields = 'basic', useQueryOptions } = options
  const { client } = useTribeClient()
  const collectionKey = getCollectionKey({ variables, fields })
  const cachedCollection = useCachedCollection(variables?.id)

  return useQuery<Collection>(
    collectionKey,
    () => client.collections.get(variables, fields),
    {
      placeholderData: cachedCollection,
      ...useQueryOptions,
    },
  )
}
