import { PostListOrderByEnum } from '@tribeplatform/gql-client/types'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { useStaticIntl, T } from '../i18n/index.js'
import { I18nShape } from '../i18n/types.js'

export const postListOrdersFactory = (intl: I18nShape) => {
  const { $t } = intl
  return [
    {
      name: $t({
        id: 'PostList.PostListFilter.Latest',
        defaultMessage: 'Latest',
      }),
      value: PostListOrderByEnum.publishedAt,
    },
    {
      name: $t({
        id: 'PostList.PostListFilter.RecentActivity',
        defaultMessage: 'Recent activity',
      }),
      value: PostListOrderByEnum.lastActivityAt,
    },
    {
      name: $t({
        id: 'PostList.PostListFilter.MostReplied',
        defaultMessage: 'Most replied',
      }),
      value: PostListOrderByEnum.totalRepliesCount,
    },
    {
      name: $t({
        id: 'PostList.PostListFilter.MostReacted',
        defaultMessage: 'Most reacted',
      }),
      value: PostListOrderByEnum.reactionsCount,
    },
  ]
}

export interface PostListFilterProps {
  orderBy: PostListOrderByEnum
  setOrderBy: (orderBy: PostListOrderByEnum) => void
}

export const PostListFilter = ({
  setOrderBy,
  orderBy,
}: PostListFilterProps) => {
  const listOrders = useStaticIntl(postListOrdersFactory)
  return (
    <div className="flex justify-end pb-2 post-list-filter">
      <Dropdown>
        <Dropdown.ButtonMinimal className="flex space-s-1 text-sm">
          <span>
            <T id="Generics.SortBy" defaultMessage="Sort by" />{' '}
            <span className="font-medium">
              {listOrders.find(o => o.value === orderBy)?.name?.toLowerCase()}
            </span>
          </span>
          <SvgIcon className="w-4 h-4" name="chevron-down" />
        </Dropdown.ButtonMinimal>
        <Dropdown.Items>
          {listOrders.map(order => (
            <Dropdown.Item
              onClick={() => setOrderBy(order.value)}
              key={order.value}
            >
              {order.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Items>
      </Dropdown>
    </div>
  )
}

export const PostListFilterLoading = () => (
  <div className="flex justify-end pb-2 animate-pulse">
    <div className="h-5 bg-skeleton rounded-full w-1/4" />
  </div>
)
