import { SlateRestrictions } from '@tribeplatform/gql-client/types'
import { RawBlockDto, RawSlateDto } from '@tribeplatform/slate-kit/dtos'

import {
  RegisteredBlockWithName,
  SlateContextProps,
  SlateKitInterface,
  UnknownCompiledBlock,
  UnknownProps,
  CompiledSlate,
} from '../types/index.js'
import { getBlockConfigs } from './block.utils.js'
import { compileProps } from './props-compiler.utils.js'

export const compileBlock = (options: {
  context: SlateContextProps
  registeredBlock: RegisteredBlockWithName<UnknownProps>
  block: RawBlockDto
  parentsMapping: Record<string, string>
  restrictions?: SlateRestrictions
}): UnknownCompiledBlock => {
  const { context, registeredBlock, block, parentsMapping, restrictions } =
    options
  const {
    nonEditableBlocks = [],
    lockedChildrenBlocks = [],
    nonRemovableBlocks = [],
  } = restrictions || {}

  const defaultProps = registeredBlock.config?.defaultProps
  const props = { ...defaultProps, ...block.props }
  const compiledProps = compileProps(props, context)
  const config = getBlockConfigs({
    block,
    registeredBlock,
    props,
    compiledProps,
    context,
  })
  const compiledBlock: UnknownCompiledBlock = {
    id: block.id,
    parentId: parentsMapping[block.id],
    name: block.name,
    props,
    extraProps: block.extraProps || {},
    compiledProps,
    children: block.children || [],
    config: {
      ...config,
      editable:
        config.editable !== false &&
        registeredBlock.config?.Settings &&
        !nonEditableBlocks.includes(block.id),
      lockedChildren:
        config.lockedChildren || lockedChildrenBlocks.includes(block.id),
      removable:
        config.removable !== false && !nonRemovableBlocks.includes(block.id),
    },
    Component: registeredBlock.Component,
    output: block.output || {},
  }
  return compiledBlock
}

export const compileSlate = (
  kit: SlateKitInterface,
  rawSlate: RawSlateDto,
  context: SlateContextProps,
): CompiledSlate => {
  const parentsMapping: Record<string, string> = {}
  rawSlate.blocks.forEach(block => {
    block.children?.forEach(child => {
      parentsMapping[child] = block.id
    })
  })
  const compiledSlate: CompiledSlate = {
    ...rawSlate,
    blocks: rawSlate.blocks.reduce((pre, block) => {
      const registeredBlock = kit.getRegisteredBlock<UnknownProps>(block.name)
      if (!registeredBlock) {
        return pre
      }

      return {
        ...pre,
        [block.id]: compileBlock({
          context,
          registeredBlock,
          block,
          parentsMapping,
          restrictions: rawSlate.restrictions,
        }),
      }
    }, {}),
  }
  compiledSlate.blocks[compiledSlate.rootBlock].config.removable = false
  return compiledSlate
}
