import { forwardRef } from 'react'

import { Textarea } from '../Textarea/index.js'
import { FormControlLayout } from './FormControlLayout.js'
import { FormControlTextareaProps } from './types.js'
import { extractLayoutProps } from './utils.js'

export const FormControlTextarea = forwardRef<
  HTMLTextAreaElement,
  FormControlTextareaProps
>((props, ref) => {
  const { layoutProps, inputProps } = extractLayoutProps(props)

  return (
    <FormControlLayout {...layoutProps}>
      <Textarea ref={ref} {...inputProps} />
    </FormControlLayout>
  )
})
