import { noop } from '@tribeplatform/react-ui-kit/hooks'

import {
  NavbarLayout,
  ItemAlignment,
  type NavbarSection,
  type LogoSection,
  type ActionsSection,
  type SearchSection,
  MenuSection,
} from '../types.js'
import { extractSections } from './utils.js'

type GetSectionPreset = (
  sections: NavbarSection[],
  preset: NavbarLayout,
) => NavbarSection[]
export const getSectionPreset: GetSectionPreset = (sections, preset) => {
  const { logo, search, menu, actions } = extractSections(sections)

  switch (preset) {
    case NavbarLayout.logoSearchActions: {
      const newLogo: LogoSection = {
        ...logo,
        enabled: true,
        stretch: false,
      }
      const newSearch: SearchSection = {
        ...search,
        enabled: true,
        stretch: true,
      }
      const newActions: ActionsSection = {
        ...actions,
        enabled: true,
        stretch: false,
      }
      const newMenu: MenuSection = { ...menu, enabled: false }

      return [newLogo, newSearch, newActions, newMenu]
    }
    case NavbarLayout.logoMenuActions: {
      const newLogo: LogoSection = { ...logo, enabled: true, stretch: false }
      const newMenu: MenuSection = {
        ...menu,
        enabled: true,
        stretch: true,
        alignment: ItemAlignment.center,
      }
      const newActions: ActionsSection = {
        ...actions,
        enabled: true,
        stretch: false,
      }
      const newSearch: SearchSection = { ...search, enabled: false }

      return [newLogo, newMenu, newActions, newSearch]
    }
    case NavbarLayout.logoMenuLeftActions: {
      const newLogo: LogoSection = { ...logo, enabled: true, stretch: false }
      const newMenu: MenuSection = {
        ...menu,
        enabled: true,
        stretch: true,
        alignment: ItemAlignment.start,
      }
      const newActions: ActionsSection = {
        ...actions,
        enabled: true,
        stretch: false,
      }
      const newSearch: SearchSection = { ...search, enabled: false }

      return [newLogo, newMenu, newActions, newSearch]
    }
    case NavbarLayout.logoActions: {
      const newLogo: LogoSection = { ...logo, enabled: true, stretch: false }
      const newActions: ActionsSection = {
        ...actions,
        enabled: true,
        stretch: true,
        alignment: ItemAlignment.end,
      }
      const newMenu: MenuSection = { ...menu, enabled: false }
      const newSearch: SearchSection = { ...search, enabled: false }

      return [newLogo, newActions, newMenu, newSearch]
    }
    case NavbarLayout.menuLogoActions: {
      const newMenu: MenuSection = {
        ...menu,
        enabled: true,
        stretch: false,
      }
      const newLogo: LogoSection = {
        ...logo,
        enabled: true,
        stretch: true,
        alignment: ItemAlignment.center,
      }
      const newActions: ActionsSection = {
        ...actions,
        enabled: true,
        stretch: false,
      }
      const newSearch: SearchSection = { ...search, enabled: false }

      return [newMenu, newLogo, newActions, newSearch]
    }
    case NavbarLayout.logoSearchLeftActions: {
      const newLogo: LogoSection = {
        ...logo,
        enabled: true,
        stretch: false,
      }
      const newSearch: SearchSection = {
        ...search,
        enabled: true,
        stretch: false,
      }
      const newActions: ActionsSection = {
        ...actions,
        enabled: true,
        stretch: true,
        alignment: ItemAlignment.end,
      }
      const newMenu: MenuSection = { ...menu, enabled: false }

      return [newLogo, newSearch, newActions, newMenu]
    }
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = preset
      noop(exhaustiveCheck)
      return sections
    }
  }
}

export const getPresetFromSections = (sections: NavbarSection[]) => {
  const { logo, search, menu, actions } = extractSections(sections)

  if (
    logo.enabled &&
    search.enabled &&
    actions.enabled &&
    !menu.enabled &&
    !logo.stretch &&
    search.stretch &&
    !actions.stretch
  ) {
    return NavbarLayout.logoSearchActions
  }

  if (
    logo.enabled &&
    menu.enabled &&
    actions.enabled &&
    !search.enabled &&
    !logo.stretch &&
    menu.stretch &&
    !actions.stretch &&
    menu.alignment === ItemAlignment.center
  ) {
    return NavbarLayout.logoMenuActions
  }

  if (
    logo.enabled &&
    menu.enabled &&
    actions.enabled &&
    !search.enabled &&
    !logo.stretch &&
    menu.stretch &&
    !actions.stretch &&
    menu.alignment === ItemAlignment.start
  ) {
    return NavbarLayout.logoMenuLeftActions
  }

  if (
    logo.enabled &&
    actions.enabled &&
    !menu.enabled &&
    !search.enabled &&
    !logo.stretch &&
    actions.stretch &&
    actions.alignment === ItemAlignment.end
  ) {
    return NavbarLayout.logoActions
  }

  if (
    menu.enabled &&
    logo.enabled &&
    actions.enabled &&
    !search.enabled &&
    !menu.stretch &&
    logo.stretch &&
    !actions.stretch &&
    logo.alignment === ItemAlignment.center
  ) {
    return NavbarLayout.menuLogoActions
  }

  if (
    logo.enabled &&
    search.enabled &&
    actions.enabled &&
    !menu.enabled &&
    !logo.stretch &&
    !search.stretch &&
    actions.stretch &&
    actions.alignment === ItemAlignment.end
  ) {
    return NavbarLayout.logoSearchLeftActions
  }

  return null
}
