import chroma from 'chroma-js'

import { theme as defaultTheme } from '@tribeplatform/design-system/themes/default'
import type { ThemeColors } from '@tribeplatform/design-system/types'

import { availableColors } from './colors/available-colors.js'
import type { ColorGroup, CustomizerTheme, LegacyThemeColors } from './types.js'

type DeepPartial<T> = {
  [K in keyof T]?: DeepPartial<T[K]>
}

const availableColorsMapper: Record<string, ColorGroup> = {}
Object.keys(availableColors).forEach(key => {
  if (availableColors[key][500]) {
    availableColorsMapper[availableColors[key][500]] = availableColors[key]
  }
})

export const applyDarkMode = (el: HTMLElement, darkMode: boolean): void => {
  if (darkMode) {
    el.classList.add('dark')
  } else {
    el.classList.remove('dark')
  }
}

export const extendTheme = (
  extending: CustomizerTheme,
  newTheme: DeepPartial<CustomizerTheme>,
): CustomizerTheme => {
  return {
    ...extending,
    ...newTheme,
    newColors: {
      ...extending.newColors,
      ...newTheme?.newColors,
      palette: {
        ...extending.newColors.palette,
        ...newTheme?.newColors?.palette,
      },
      light1: {
        ...extending.newColors.light1,
        ...newTheme?.newColors?.light1,
      } as ThemeColors,
      light2: {
        ...extending.newColors.light2,
        ...newTheme?.newColors?.light2,
      } as ThemeColors,
      light3: {
        ...extending.newColors.light3,
        ...newTheme?.newColors?.light3,
      } as ThemeColors,
      dark1: {
        ...extending.newColors.dark1,
        ...newTheme?.newColors?.dark1,
      } as ThemeColors,
      dark2: {
        ...extending.newColors.dark2,
        ...newTheme?.newColors?.dark2,
      } as ThemeColors,
    },
    colors: {
      light: {
        ...extending.colors.light,
        ...newTheme?.colors?.light,
      } as LegacyThemeColors,
      dark: {
        ...extending.colors.dark,
        ...newTheme?.colors?.dark,
      } as LegacyThemeColors,
    },
    typography: {
      theme: newTheme?.typography?.theme ?? extending.typography.theme,
      colorTheme:
        newTheme?.typography?.colorTheme ?? extending.typography.colorTheme,
      style: {
        ...extending.typography.style,
        ...newTheme?.typography?.style,
      },
      customTheme: {
        ...extending.typography.customTheme,
        ...newTheme?.typography?.customTheme,
        palette: {
          ...extending.typography.customTheme?.palette,
          ...newTheme?.typography?.customTheme?.palette,
        },
        light1: {
          ...extending.typography.customTheme?.light1,
          ...newTheme?.typography?.customTheme?.light1,
        } as ThemeColors,
        light2: {
          ...extending.typography.customTheme?.light2,
          ...newTheme?.typography?.customTheme?.light2,
        } as ThemeColors,
        light3: {
          ...extending.typography.customTheme?.light3,
          ...newTheme?.typography?.customTheme?.light3,
        } as ThemeColors,
        dark1: {
          ...extending.typography.customTheme?.dark1,
          ...newTheme?.typography?.customTheme?.dark1,
        } as ThemeColors,
        dark2: {
          ...extending.typography.customTheme?.dark2,
          ...newTheme?.typography?.customTheme?.dark2,
        } as ThemeColors,
      },
      colorThemeSettings: {
        ...extending.typography.colorThemeSettings,
        ...newTheme?.typography?.colorThemeSettings,
      },
    },
  }
}

const colorWeights = [
  '50',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
]
export const createColorGroup = (
  hex: string,
  dark: boolean,
  background = false,
): ColorGroup => {
  if (availableColorsMapper[hex] && !dark && !background) {
    return availableColorsMapper[hex]
  }

  let scale
  if (!background) {
    const luminance = chroma(hex).luminance()
    scale = chroma
      .scale(
        luminance < 0.5
          ? ['white', hex, 'black']
          : [availableColors.gray[400], hex, 'white'],
      )
      .mode('lch')
      .padding(0.1)
      // generate odd number of colors to make
      // scale.500 === hex in light mode
      // scale.400 === hex in dark mode
      .colors(11)
  } else {
    scale = chroma
      .scale(dark ? ['white', hex] : [hex, 'black'])
      .mode('lch')
      .padding(dark ? [0.6, 0] : [0, 0.6])
      // generate odd number of colors to make
      // scale.50 === hex in light mode
      // scale.900 === hex in dark mode
      .colors(11)
  }
  const cleanedScale = scale.slice(dark ? 1 : 0, dark ? undefined : -1)
  const result = {}
  for (let i = 0; i < colorWeights.length; i++) {
    result[colorWeights[i]] = cleanedScale[i]
  }
  return result as ColorGroup
}

export const invertThemeColors = (
  themeColors: LegacyThemeColors,
): LegacyThemeColors => {
  const invertedThemeColors = {}
  Object.keys(themeColors).forEach(cssVar => {
    if (typeof themeColors[cssVar] === 'object') {
      invertedThemeColors[cssVar] = {}
      Object.keys(themeColors[cssVar]).forEach(weight => {
        const invertWeightIndex = 9 - colorWeights.indexOf(weight)
        const invertWeight = String(colorWeights[invertWeightIndex])

        invertedThemeColors[cssVar][weight] = themeColors[cssVar][invertWeight]
      })
    }
  })

  return invertedThemeColors as LegacyThemeColors
}

const withOpacity = (color: string) => {
  // opacity-60 in RGBA notation
  return `${color}99`
}
export const convertLegacyThemeColors = (
  legacyThemeColors: LegacyThemeColors,
): ThemeColors => {
  const {
    main,
    basicMain,
    surface,
    basicSurface,
    actionPrimary,
    basicPrimary,
    actionSecondary,
    basicSecondary,
    actionAccent,
    actionAccentHover,
    danger,
    warning,
    info,
    success,
  } = legacyThemeColors

  return {
    ...defaultTheme.light1,
    'action-primary': actionPrimary['500'],
    'action-primary-hovered': actionPrimary['600'],
    'action-primary-pressed': actionPrimary['600'],
    'action-primary-subdued': actionPrimary['50'],
    'action-primary-subdued-hovered': actionPrimary['100'],
    'action-primary-subdued-pressed': actionPrimary['100'],
    'action-primary-disabled': surface['100'],
    'action-neutral': surface['50'],
    'action-neutral-hovered': surface['100'],
    'action-neutral-pressed': surface['100'],
    'action-neutral-subdued': surface['100'],
    'action-neutral-subdued-hovered': surface['200'],
    'action-neutral-subdued-pressed': surface['200'],
    'action-neutral-disabled': withOpacity(surface['50']),
    'action-destructive': danger['500'],
    'action-destructive-hovered': danger['600'],
    'action-destructive-pressed': danger['600'],
    'action-destructive-subdued': danger['50'],
    'action-destructive-subdued-hovered': danger['100'],
    'action-destructive-subdued-pressed': danger['100'],
    'action-destructive-disabled': surface['100'],
    background: main['50'],
    'background-hovered': main['100'],
    'background-pressed': main['200'],
    'background-selected': main['200'],
    'background-divider': `${surface['200']}80`,
    line: `${basicSurface['300']}40`,
    'line-subdued': `${surface['200']}80`,
    'line-hovered': `${surface['400']}80`,
    'line-pressed': `${surface['500']}80`,
    'line-selected': `${surface['600']}80`,
    'line-disabled': `${surface['200']}80`,
    'line-positive': success['600'],
    'line-positive-subdued': success['300'],
    'line-negative': danger['600'],
    'line-negative-subdued': danger['300'],
    'line-negative-disabled': danger['200'],
    'line-attention': warning['600'],
    'line-attention-subdued': warning['300'],
    'line-highlight': info['600'],
    'line-highlight-subdued': info['300'],
    link: actionAccent['600'],
    'link-hovered': actionAccentHover['500'],
    'link-pressed': actionAccentHover['500'],
    content: basicSurface['900'],
    'content-subdued': basicSurface['500'],
    'content-muted': basicSurface['500'],
    'content-hovered': basicSurface['900'],
    'content-pressed': basicSurface['900'],
    'content-disabled': withOpacity(basicSurface['900']),
    'content-on-positive': success['700'],
    'content-on-attention': warning['700'],
    'content-on-negative': danger['700'],
    'content-on-negative-hovered': danger['800'],
    'content-on-negative-pressed': danger['900'],
    'content-on-highlight': info['700'],
    'content-primary': actionPrimary['500'],
    'content-on-primary': basicPrimary['500'],
    'content-on-inverse': surface['50'],
    'content-on-destructive': '#FFFFFF',
    'content-on-background': basicMain['900'],
    'content-on-background-subdued': basicMain['500'],
    'content-on-background-hovered': basicMain['900'],
    'content-on-background-pressed': basicMain['900'],
    'content-on-background-disabled': withOpacity(basicMain['900']),
    interactive: actionPrimary['500'],
    'interactive-hovered': actionPrimary['600'],
    'interactive-pressed': actionPrimary['600'],
    topbar: actionSecondary['50'],
    'topbar-subdued': actionSecondary['100'],
    'content-on-topbar': basicSecondary['500'],
    'line-on-topbar': `${basicSecondary['300']}40`,
    'line-on-topbar-pressed': actionSecondary['600'],
    focused: actionPrimary['500'],
    skeleton: surface['300'],
    surface: surface['50'],
    'surface-subdued': surface['100'],
    'surface-subdued-hovered': surface['200'],
    'surface-hovered': surface['100'],
    'surface-pressed': surface['200'],
    'surface-disabled': withOpacity(surface['50']),
    'surface-selected': actionPrimary['50'],
    'surface-selected-hovered': actionPrimary['100'],
    'surface-selected-pressed': actionPrimary['100'],
    'surface-attention': warning['50'],
    'surface-attention-subdued': warning['50'],
    'surface-negative': danger['50'],
    'surface-negative-subdued': danger['50'],
    'surface-negative-subdued-hovered': danger['100'],
    'surface-negative-subdued-pressed': danger['100'],
    'surface-positive': success['50'],
    'surface-positive-subdued': success['50'],
    'surface-highlight': info['50'],
    'surface-highlight-subdued': info['50'],
    'surface-highlight-subdued-hovered': info['100'],
    'surface-highlight-subdued-pressed': info['100'],
    'surface-inverse': basicSurface['900'],
    'surface-neutral': surface['200'],
  }
}
