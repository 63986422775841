import { clsx } from 'clsx'

import {
  type AvatarSize,
  avatarStyles,
  avatarPlaceholderStyles,
} from '@tribeplatform/react-ui-kit/Avatar'
import { iconStyles } from '@tribeplatform/react-ui-kit/Icon'

import { initials } from './utils.js'

const getIndexFromHash = (name: string) => {
  if (!name) {
    return 0
  }

  const decorativeColorsLength = 7
  const index =
    name.split('').reduce((prev, curr) => prev + curr.charCodeAt(0), 0) %
    decorativeColorsLength

  return index
}

export interface InitialsAvatarPlaceholderProps {
  name: string
  size: AvatarSize
  className?: string
}

export const InitialsAvatarPlaceholder = ({
  name,
  size,
  className,
}: InitialsAvatarPlaceholderProps) => {
  const decorativeIndex = getIndexFromHash(name) + 1
  return (
    <div
      className={clsx(
        avatarStyles(),
        iconStyles({ size }),
        'flex items-center justify-center',
        [
          decorativeIndex === 1 &&
            'bg-decorative-surface-1-subdued text-decorative-content-1',
          decorativeIndex === 2 &&
            'bg-decorative-surface-2-subdued text-decorative-content-2',
          decorativeIndex === 3 &&
            'bg-decorative-surface-3-subdued text-decorative-content-3',
          decorativeIndex === 4 &&
            'bg-decorative-surface-4-subdued text-decorative-content-4',
          decorativeIndex === 5 &&
            'bg-decorative-surface-5-subdued text-decorative-content-5',
          decorativeIndex === 6 &&
            'bg-decorative-surface-6-subdued text-decorative-content-6',
          decorativeIndex === 7 &&
            'bg-decorative-surface-7-subdued text-decorative-content-7',
        ],
        className,
      )}
    >
      <span translate="no" className={avatarPlaceholderStyles({ size })}>
        {initials(name)}
      </span>
    </div>
  )
}
