import { useMemo } from 'react'

import BoringAvatar from 'boring-avatars'
import { clsx } from 'clsx'

import { AppearanceStyle } from '@tribeplatform/design-system/types'
import { type AvatarSize } from '@tribeplatform/react-ui-kit/Avatar'
import { iconSizeStyles } from '@tribeplatform/react-ui-kit/Icon'
import { useThemeContext } from '@tribeplatform/react-ui-kit/ThemeProvider'

import { InitialsAvatarPlaceholder } from './InitialsAvatarPlaceholder.js'
import { getPlaceholderColors, getAvatarPlaceholderVariant } from './utils.js'

export interface AvatarPlaceholderProps {
  name: string
  size: AvatarSize
  avatarStyle?: AppearanceStyle['avatars']
  avatarShapeStyle?: AppearanceStyle['avatarShape']
}

export const MemberAvatarPlaceholder = ({
  name,
  size,
  avatarStyle: avatarStyleProp,
  avatarShapeStyle: avatarShapeStyleProp,
}: AvatarPlaceholderProps) => {
  const { theme, style } = useThemeContext()

  const avatarStyle = avatarStyleProp ?? style.avatars

  const colors = useMemo(() => {
    return getPlaceholderColors({ theme, style: avatarStyle })
  }, [avatarStyle, theme])

  if (avatarStyle === 'initials') {
    return (
      <InitialsAvatarPlaceholder
        name={name}
        size={size}
        className={clsx(
          avatarShapeStyleProp === 'full-rounded' && 'rounded-full',
          avatarShapeStyleProp === 'rounded' && 'rounded-md',
          avatarShapeStyleProp === 'sharp' && 'rounded-none',
        )}
      />
    )
  }

  return (
    <div
      className={clsx(
        'overflow-hidden',
        !avatarShapeStyleProp && 'rounded-avatar',
        avatarShapeStyleProp === 'full-rounded' && 'rounded-full',
        avatarShapeStyleProp === 'rounded' && 'rounded-md',
        avatarShapeStyleProp === 'sharp' && 'rounded-none',
      )}
    >
      <BoringAvatar
        size={iconSizeStyles({ size }) || '1em'}
        name={name}
        variant={getAvatarPlaceholderVariant(avatarStyle)}
        square
        /** Colors should be passed as hex codes to determine contrast */
        colors={colors}
      />
    </div>
  )
}
