import { AvatarGroup } from '@tribeplatform/react-ui-kit/Avatar'

import { MemberAvatar } from './MemberAvatar.js'
import { MembersAvatarsProps } from './types.js'

export const MembersAvatars = ({
  ids,
  moreCount,
  ...rest
}: MembersAvatarsProps) => {
  if (!ids?.length) {
    return null
  }

  return (
    <AvatarGroup moreCount={moreCount} {...rest}>
      {ids.map(memberId => {
        return <MemberAvatar key={memberId} id={memberId} />
      })}
    </AvatarGroup>
  )
}
