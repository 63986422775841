import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  MutationLeaveSpaceArgs,
  Action,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPostsKey } from '../../utils/keys/post.key.js'
import {
  getPageKey,
  getSpaceKey,
  getSpacesByIdsKey,
  getSpacesBySlugsKey,
  getSpacesKey,
} from '../../utils/keys/space.key.js'

export const useLeaveSpace = (options?: {
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationLeaveSpaceArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<Action, ClientError, MutationLeaveSpaceArgs>(
    (input: MutationLeaveSpaceArgs) => client.spaces.leave(input),
    {
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(
          getSpaceKey({ variables: { id: variables.spaceId } }),
        )
        queryClient.invalidateQueries(getPostsKey())
        queryClient.invalidateQueries(getSpacesKey())
        queryClient.invalidateQueries(getSpacesByIdsKey())
        queryClient.invalidateQueries(getSpacesBySlugsKey())
        queryClient.invalidateQueries(getPageKey())
      },
      ...useMutationOptions,
    },
  )
}
