import { type ReactNode, createContext, useContext } from 'react'

import { useQuery } from '../../common/hooks/useQuery.js'

const QueryLayoutContext = createContext<string>('')

export const useQueryLayout = () => {
  return useContext(QueryLayoutContext)
}

interface QueryLayoutProviderProps {
  children: ReactNode
}
export const QueryLayoutProvider = ({ children }: QueryLayoutProviderProps) => {
  const { layout } = useQuery<{ layout: string }>()
  return (
    <QueryLayoutContext.Provider value={layout}>
      {children}
    </QueryLayoutContext.Provider>
  )
}
