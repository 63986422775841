import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'
import {
  PostMemberBar,
  PostOptions,
} from '@tribeplatform/react-components/Post'

interface Props {
  post: Post
  highlighted: boolean
  stretchX: string
  showSpaceName: boolean
  hasPostOptions: boolean
  showPostOptions: boolean
}
export const GalleryMemberInfo = ({
  post,
  highlighted,
  stretchX,
  showSpaceName,
  hasPostOptions,
  showPostOptions,
}: Props) => {
  return (
    <div
      className={clsx(
        'flex items-center empty:hidden space-s-4 min-w-0',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
      onClick={e => e.stopPropagation()}
    >
      {hasPostOptions ? (
        <div className="flex-1 flex items-center space-s-2 min-w-0">
          <PostMemberBar post={post} showSpace={showSpaceName} />
          {showPostOptions && <PostOptions post={post} context="post" />}
        </div>
      ) : (
        <PostMemberBar post={post} showSpace={showSpaceName} />
      )}
    </div>
  )
}
