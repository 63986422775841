import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'

import { useI18n } from '../../i18n/index.js'
import { GenericOperatorPicker } from '../components/GenericOperatorPicker.js'
import { ListFilterByOperator, type OperatorPickerComponent } from '../types.js'
import { getOperatorName } from '../utils/operators.js'

export const dateOperators: ListFilterByOperator[] = [
  ListFilterByOperator.gte,
  ListFilterByOperator.gt,
  ListFilterByOperator.lte,
  ListFilterByOperator.lt,
  ListFilterByOperator.equals,
]

export const DateOperatorPicker: OperatorPickerComponent = ({
  filter,
  onChange,
}) => {
  const intl = useI18n()
  const { operator, type, subType, value } = filter
  const currentOperatorName = getOperatorName(
    operator,
    type,
    subType,
    value,
    intl,
  )

  return (
    <GenericOperatorPicker operatorName={currentOperatorName}>
      {dateOperators.map(operator => (
        <Dropdown.Item
          key={operator}
          onClick={() => {
            onChange(operator)
          }}
        >
          {getOperatorName(operator, type, subType, value, intl)}
        </Dropdown.Item>
      ))}
    </GenericOperatorPicker>
  )
}
