import { clsx } from 'clsx'

import { Emoji, Image } from '@tribeplatform/gql-client/types'
import { IconEmoji } from '@tribeplatform/react-components/common/components'
import { HtmlContent } from '@tribeplatform/react-components/HtmlContent'
import { useRouter } from '@tribeplatform/react-sdk'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Card } from '@tribeplatform/react-ui-kit/Card'

import { BC } from '../../types/index.js'
import { CallToActionProps } from './types.js'

const Action = ({ action }: { action: CallToActionProps['action'] }) => {
  const { Link } = useRouter()
  if (!action) {
    return null
  }
  const { href, text, type, openInNewWindow } = action
  if (!text) {
    return null
  }

  const target = openInNewWindow ? '_blank' : undefined
  const props = {
    href,
    target,
  }

  if (type === 'link') {
    return (
      <Link
        variant="accent"
        className="inline-block max-w-full truncate"
        {...props}
      >
        {text}
      </Link>
    )
  }
  return (
    <Button
      as={props => <Link {...props} variant="inherit" />}
      {...props}
      className="inline-block max-w-full truncate"
    >
      {text}
    </Button>
  )
}

export const CallToActionBlock: BC<CallToActionProps> = ({
  alignment,
  title,
  description,
  image,
  action,
  className,
  style,
  ...rest
}) => {
  let emoji: Emoji
  let img: Image

  if ((image as Image)?.url) {
    img = image as Image
  } else if ((image as Emoji)?.text) {
    emoji = image as Emoji
  }
  return (
    <Card
      className={clsx('overflow-hidden', className)}
      style={style}
      {...rest}
    >
      {img && (
        <div
          className="bg-center bg-cover"
          style={{
            aspectRatio: '11/4',
            backgroundColor: img.dominantColorHex,
            backgroundImage: `url(${img.urls?.medium || img.url})`,
          }}
        />
      )}
      {emoji && (
        <div className="pt-3 justify-center items-center flex">
          <IconEmoji text={emoji.text} size="xl" />
        </div>
      )}
      {(title || description) && (
        <Card.Content
          className={clsx(
            'space-y-3',
            alignment === 'center' ? 'text-center' : 'text-start',
          )}
        >
          {title && (
            <h4 className="text-lg font-medium text-content">{title}</h4>
          )}
          {description && (
            <article className="prose">
              <HtmlContent value={description} trustedDomains={['*']} />
            </article>
          )}
        </Card.Content>
      )}
      {action?.text && (
        <Card.Footer
          className={clsx(
            alignment === 'center' ? 'text-center' : 'text-start',
          )}
        >
          <Action action={action} />
        </Card.Footer>
      )}
    </Card>
  )
}
