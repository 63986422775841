import { createContext, HTMLProps, Ref, useContext } from 'react'

import type { FloatingContext } from '@floating-ui/react'

export type FloatingFullContextType = {
  context?: FloatingContext
  getReferenceProps?: (
    userProps?: HTMLProps<Element>,
  ) => Record<string, unknown>
  getFloatingProps?: (
    userProps?: HTMLProps<HTMLElement>,
  ) => Record<string, unknown>
  arrowRef?: Ref<SVGSVGElement>
}

export const FloatingFullContext =
  createContext<FloatingFullContextType>(undefined)

export const useFloatingFullContext = () => {
  const context = useContext(FloatingFullContext)
  if (context === undefined) {
    throw new Error('useFloating must be used within a FloatingProvider')
  }
  return context
}
