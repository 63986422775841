import { useMemo } from 'react'

import { Button } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Input } from '@tribeplatform/react-ui-kit/Input'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'

import { PlanBadge } from '../../common/components/PlanBadge.js'
import { dayjs } from '../../common/lib/dayjs.js'
import { T } from '../../i18n/components/T.js'
import { useStaticIntl } from '../../i18n/hooks/useStaticIntl.js'
import { useCustomTimeFrame } from '../hooks/useCustomTimeFrame.js'
import type { DateTimeOptions } from '../hooks/useDateTimePicker.js'
import type { TimeFrame } from '../types/index.js'
import {
  createDynamicTimeFrame,
  DateTimeFormat,
  getRangeDateText,
} from '../utils/timeFrame.js'
import { DateTimePicker } from './DateTimePicker.js'

interface Props {
  open: boolean
  onClose: () => void
  daysLimitation: number
  timeFrame: TimeFrame
  setTimeFrame: (timeFrame: TimeFrame) => void
}
export const CustomTimeFrameModal = ({
  open,
  onClose,
  daysLimitation,
  timeFrame,
  setTimeFrame,
}: Props) => {
  const {
    date,
    setDate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    startDateError,
    endDateError,
    shouldUpgradePlan,
  } = useCustomTimeFrame({ timeFrame, daysLimitation })

  const options = useMemo<DateTimeOptions>(() => {
    return {
      mode: 'range',
      maxDate: 'today',
      showMonths: 2,
      inline: true,
      clickOpens: false,
      enable: [
        date => {
          if (daysLimitation === Infinity) {
            return date <= dayjs().toDate()
          }

          return (
            date >
              dayjs().subtract(daysLimitation, 'day').startOf('day').toDate() &&
            date <= dayjs().toDate()
          )
        },
      ],
    }
  }, [daysLimitation])

  const getDynamicTimeFrame = useStaticIntl(createDynamicTimeFrame)
  const handleSave = () => {
    if (!Array.isArray(date) || date.length !== 2) {
      return
    }

    const [startDate, endDate] = date
    const from =
      dayjs(endDate > startDate ? startDate : endDate)
        .startOf('day')
        .unix() * 1000
    const to =
      dayjs(endDate > startDate ? endDate : startDate)
        .endOf('day')
        .unix() * 1000
    const text = getRangeDateText({ from, to })

    const customTimeFrame = getDynamicTimeFrame({
      id: 'custom',
      text,
      from,
      to,
    })

    setTimeFrame(customTimeFrame)
  }

  return (
    <Modal open={open} onClose={onClose} size="2xl">
      <Modal.Header title="Custom timeframe" />
      <Modal.Content>
        <div className="flex flex-col items-center gap-2">
          <div className="flex w-full gap-2 items-center justify-between">
            <Input
              leadingIcon={<SvgIcon name="calendar" />}
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
              placeholder={`Start date (${DateTimeFormat})`}
              invalid={startDateError}
            />
            <Input
              leadingIcon={<SvgIcon name="calendar" />}
              value={endDate}
              onChange={e => setEndDate(e.target.value)}
              placeholder={`End date (${DateTimeFormat})`}
              invalid={endDateError}
            />
          </div>
          {shouldUpgradePlan && <PlanBadge authorized={false} />}
          <div className="min-h-80">
            <DateTimePicker value={date} onChange={setDate} options={options} />
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer className="space-s-2 justify-end flex">
        <Button variant="secondaryNeutral" onClick={onClose}>
          <T id="Generics.Cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={
            (Array.isArray(date) && date.length !== 2) ||
            startDateError ||
            endDateError
          }
        >
          <T id="Generics.Save" defaultMessage="Save" />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
