import { createContext, useContext } from 'react'

import { ResponsiveLogo } from './types.js'

export interface FrameContextType {
  logo?: ResponsiveLogo
  fullWidth: boolean
}

export const FrameContext = createContext<FrameContextType | undefined>(
  undefined,
)

export function useFrameContext() {
  const context = useContext(FrameContext)
  if (context === undefined) {
    throw new Error('useFrameContext must be used within a FrameProvider')
  }

  return context
}
