import { Image, Space } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { Avatar } from '@tribeplatform/react-ui-kit/Avatar'
import { Badge } from '@tribeplatform/react-ui-kit/Badge'

export const SpacePill = ({ space }: { space: Space }) => {
  const { Link } = useRouter()
  if (!space?.name) {
    return null
  }

  return (
    <Link href={space?.relativeUrl} className="rounded-full" translate="no">
      <Badge
        className="ps-1"
        leadingIcon={
          <Avatar
            size="xl"
            rounded="md"
            src={(space?.image as Image)?.urls?.thumb}
            name={space?.name}
          />
        }
      >
        {space?.name}
      </Badge>
    </Link>
  )
}
