import { FC, useState } from 'react'

import { Button } from '../Button/Button.js'
import { ButtonGroup } from '../Button/ButtonGroup.js'
import { Icon } from '../Icon/index.js'
import { FormControlLayout } from './FormControlLayout.js'
import { FormControlButtonGroupSelectProps } from './types.js'
import { extractLayoutProps } from './utils.js'

export const FormControlButtonGroupSelect: FC<
  FormControlButtonGroupSelectProps
> = props => {
  const { items, value, onChange, fullWidth, ...rest } = props

  const { layoutProps } = extractLayoutProps(rest)

  const [selected, setSelected] = useState(value)
  return (
    <FormControlLayout {...layoutProps}>
      <ButtonGroup className={fullWidth ? 'w-full' : null}>
        {items.map(({ icon, text, value }) => (
          <Button
            key={value}
            fullWidth={fullWidth}
            className={fullWidth ? 'flex-1' : null}
            pressed={selected && selected === value}
            onClick={() => {
              setSelected(value)
              if (typeof onChange !== 'undefined') {
                onChange(value)
              }
            }}
          >
            {text ?? <Icon className="w-4 h-4">{icon}</Icon>}
          </Button>
        ))}
      </ButtonGroup>
    </FormControlLayout>
  )
}
