import { RadioGroup } from '@tribeplatform/react-ui-kit/RadioGroup'

import { useI18n } from '../../i18n/index.js'
import type { ValuePickerComponent } from '../types.js'
import { booleanValuesFactory } from '../valueRenderers/BooleanRenderer.js'

export const BooleanValuePicker: ValuePickerComponent = ({
  filter,
  onChange,
}) => {
  const intl = useI18n()
  const [checkedValue, unCheckedValue] = booleanValuesFactory(intl)
  const { value } = filter
  const currentValue = value ? checkedValue.key : unCheckedValue.key

  // The only acceptable value other than a boolean, is 'undefined'
  if (typeof value !== 'boolean' && value !== undefined) {
    return null
  }

  return (
    <RadioGroup
      value={value === undefined ? '' : currentValue}
      onChange={newValue => {
        onChange(newValue === checkedValue.key)
      }}
    >
      <RadioGroup.Items>
        <RadioGroup.Item
          key={checkedValue.key}
          value={checkedValue.key}
          title={<>{checkedValue.text}</>}
        />
        <RadioGroup.Item
          key={unCheckedValue.key}
          value={unCheckedValue.key}
          title={<>{unCheckedValue.text}</>}
        />
      </RadioGroup.Items>
    </RadioGroup>
  )
}
