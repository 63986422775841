import { AppFields, appGQLFields } from '../app/app.fields.js'
import { MemberFields, memberGQLFields } from '../network/member.fields.js'
import { NetworkFields, networkGQLFields } from '../network/network.fields.js'
import { PostFields, postGQLFields } from '../post/posts.fields.js'
import { TagFields, tagGQLFields } from '../post/tag.fields.js'
import { SpaceFields, spaceGQLFields } from '../space/space.fields.js'

export type ReportEntitiesFields = 'basic' | 'all' | CustomReportEntitiesFields

interface CustomReportEntitiesFields {
  actor?: MemberFields
  app?: AppFields
  member?: MemberFields
  person?: MemberFields
  network?: NetworkFields
  post?: PostFields
  space?: SpaceFields
  tag?: TagFields
}

const BASIC_REPORT_ENTITIES_FIELDS: CustomReportEntitiesFields = {
  actor: 'basic',
  app: 'basic',
  member: 'basic',
  network: 'basic',
  post: 'basic',
  space: 'basic',
  tag: 'basic',
}
const ALL_REPORT_ENTITIES_FIELDS: CustomReportEntitiesFields = {
  actor: 'all',
  app: 'all',
  member: 'all',
  person: 'basic',
  network: 'all',
  post: 'all',
  space: 'all',
  tag: 'all',
}

export const reportEntitiesGQLFields = (fields: ReportEntitiesFields) => {
  if (fields === 'basic') fields = BASIC_REPORT_ENTITIES_FIELDS
  if (fields === 'all') fields = ALL_REPORT_ENTITIES_FIELDS

  return `
    __typename
    ${
      fields.actor
        ? `
    actor {
      ${memberGQLFields(fields.actor)}
    }
    `
        : ``
    }
    ${
      fields.app
        ? `
    app {
      ${appGQLFields(fields.app)}
    }
    `
        : ``
    }
    ${
      fields.member
        ? `
    member {
      ${memberGQLFields(fields.member)}
    }
    `
        : ``
    }
    ${
      fields.person
        ? `
    person {
      ${memberGQLFields(fields.person)}
    }
    `
        : ``
    }
    ${
      fields.network
        ? `
    network {
      ${networkGQLFields(fields.network)}
    }
    `
        : ``
    }
    ${
      fields.post
        ? `
      post {
        ${postGQLFields(fields.post)}
      }
    `
        : ``
    }
    ${
      fields.space
        ? `
      space {
        ${spaceGQLFields(fields.space)}
      }
    `
        : ``
    }
    ${
      fields.tag
        ? `
      tag {
        ${tagGQLFields(fields.tag)}
      }
    `
        : ``
    }
  `
}
