import { CustomFieldSubtype } from '../../CustomField/utils.js'
import type { ValueRendererComponent } from '../types.js'
import { MemberRenderer } from './MemberRenderer.js'
import { SpaceRenderer } from './SpaceRenderer.js'
import { TagRenderer } from './TagRenderer.js'

export const RelationRenderer: ValueRendererComponent = ({ filter }) => {
  const { value, subType } = filter

  if (!Array.isArray(value)) {
    return null
  }

  switch (subType) {
    case CustomFieldSubtype.MEMBER:
      return <MemberRenderer value={value} />
    case CustomFieldSubtype.SPACE:
      return <SpaceRenderer value={value} />
    case CustomFieldSubtype.TAG:
      return <TagRenderer value={value} />
    default:
      return null
  }
}
