import { Controller, useFormContext } from 'react-hook-form'

import {
  FormControl,
  FormControlMultiselectProps,
} from '@tribeplatform/react-ui-kit/FormControl'

import { FormFieldProps, Optional } from './typings.js'

export function Multiselect({
  name,
  validation,
  value: fieldValue,
  onChange: multiselectOnChange,
  ...rest
}: FormFieldProps<
  Optional<FormControlMultiselectProps, 'value' | 'onChange'>
>) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl.Multiselect
          {...rest}
          name={name}
          invalid={!!error?.message}
          error={error?.message}
          value={fieldValue ?? value}
          onChange={value => {
            onChange(value)
            if (typeof multiselectOnChange === 'function') {
              multiselectOnChange(value)
            }
          }}
        />
      )}
    />
  )
}
