import { useCallback, useState } from 'react'

import { ClientError } from '@tribeplatform/gql-client/lib'
import { I18nShape, useI18n } from '@tribeplatform/react-components/i18n'
import { useResendVerificationCode as useResendCodeSdkHook } from '@tribeplatform/react-sdk/hooks'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

const displayErrorToast = ({ $t }: I18nShape, message?: string) => {
  toast({
    title: $t({
      defaultMessage: 'Could not send verification email',
      id: 'Auth.VerificationCode.Email.NotSent',
    }),
    description:
      message ||
      $t({
        defaultMessage: 'Something went wrong. Please try again later.',
        id: 'Generics.SomethingWentWrongTryAgainLater',
      }),
    status: 'error',
  })
}

const displaySuccessToast = ({ $t }: I18nShape) => {
  toast({
    title: $t({
      defaultMessage: 'Verification email sent',
      id: 'Auth.VerificationCode.Email.Sent',
    }),
    description: $t({
      defaultMessage:
        'Please check your spam if you couldn’t find the email in your inbox.',
      id: 'Auth.VerificationCode.Email.Sent.Description',
    }),
    status: 'neutral',
  })
}

type UseResendVerificationCodeType = (props?: {
  onSuccess?: () => Promise<void>
  onError?: (message?: string) => Promise<void>
}) => {
  onResend: () => Promise<void>
  isResending?: boolean
  codeResent?: boolean
}

export const useResendVerificationCode: UseResendVerificationCodeType = ({
  onError,
  onSuccess,
} = {}) => {
  const intl = useI18n()
  const { mutateAsync: resendCode, isLoading: isResending } =
    useResendCodeSdkHook()
  const [codeResent, setCodeResent] = useState(false)

  const onResend = useCallback(async () => {
    try {
      const result = await resendCode()

      if (result?.status === 'succeeded') {
        setCodeResent(true)
        if (onSuccess) {
          await onSuccess()
        } else {
          displaySuccessToast(intl)
        }
        return
      }

      if (onError) {
        await onError()
      } else {
        displayErrorToast(intl)
      }
    } catch (e) {
      const message = (e as ClientError)?.response?.errors
        ?.map(e => e.message)
        ?.join('\n')
      displayErrorToast(intl, message)
    }
  }, [intl, onError, onSuccess, resendCode])

  return {
    onResend,
    isResending,
    codeResent,
  }
}
