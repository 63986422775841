import { Link, useNavigate } from 'react-router-dom'

import {
  SpaceMember,
  SpaceMemberListOrderByEnum,
} from '@tribeplatform/gql-client/types'
import { MemberAvatar } from '@tribeplatform/react-components/common/components/member'
import { useSpace, useSpaceMembers } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Button } from '@tribeplatform/react-ui-kit/Button'

import { EmptyState } from './EmptyState.js'
import { LoadingState } from './LoadingState.js'
import { MembersBlockProps } from './types.js'

export const SpaceMemberList = ({
  limit,
  spaceId,
  roleIds,
  sort,
  showTagline,
  showViewAll,
}: Partial<MembersBlockProps>) => {
  let orderBy: SpaceMemberListOrderByEnum
  let reverse = false

  switch (sort) {
    case 'oldest-first':
      orderBy = SpaceMemberListOrderByEnum.CREATED_AT
      break
    // TODO not supported from backend
    // case 'alphabetical':
    // case 'number-of-posts':
    case 'newest-first':
    default:
      orderBy = SpaceMemberListOrderByEnum.CREATED_AT
      reverse = true
      break
  }

  const { data, isInitialLoading, isFetched } = useSpaceMembers({
    fields: {
      member: { profilePicture: 'basic', role: 'basic' },
      role: 'basic',
    },
    variables: { limit, spaceId, orderBy, reverse, roleIds },
    useInfiniteQueryOptions: {
      enabled: !!spaceId,
    },
  })
  const { nodes: spaceMembers } = simplifyPaginatedResult<SpaceMember>(data)

  const { data: space } = useSpace({
    variables: { id: spaceId },
    useQueryOptions: {
      enabled: !!spaceId,
    },
  })
  const navigate = useNavigate()

  const members = spaceMembers
    .filter(
      ({ role }) =>
        !roleIds || (roleIds?.length > 0 && roleIds.includes(role.id)),
    )
    .map(({ member }) => member)

  if (!isFetched || isInitialLoading) {
    return <LoadingState count={limit} />
  }

  if (!members?.length) {
    return <EmptyState />
  }

  return (
    <div className="space-y-5">
      <div className="grid grid-cols-1 gap-1 -ms-2 -me-2">
        {members.map(member => {
          if (!member?.id) {
            return null
          }
          return (
            <Link
              key={member.id}
              to={member?.relativeUrl}
              className="group flex space-s-3 items-center p-2 rounded-base hover:bg-surface-hovered"
            >
              <MemberAvatar size="2.5x" member={member} />
              <div className="flex-grow max-w-full overflow-hidden">
                <div className="truncate text-content flex items-center">
                  {member?.name}
                </div>
                {showTagline && member?.tagline && (
                  <div className="text-md text-content-subdued truncate">
                    {member?.tagline}
                  </div>
                )}
              </div>
            </Link>
          )
        })}
      </div>
      {false && showViewAll && (
        <Button
          fullWidth
          variant="secondaryNeutral"
          onClick={() => {
            navigate(space?.relativeUrl)
          }}
        >
          View all
        </Button>
      )}
    </div>
  )
}
