import { FC } from 'react'

import { clsx } from 'clsx'

export type ModalFooterProps = React.ComponentProps<'div'> & {
  withBorder?: boolean
}

export const ModalFooter: FC<ModalFooterProps> = props => {
  const { withBorder = false, children, className, ...rest } = props

  return (
    <div
      className={clsx(
        'px-4 py-5 sm:p-6',
        !withBorder && 'pt-0 sm:pt-0',
        withBorder && 'border-t border-line-subdued',
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

export const ModalActions: FC<ModalFooterProps> = props => {
  const { withBorder = false, children, className, ...rest } = props

  return (
    <div
      className={clsx(
        'px-4 py-5 sm:p-6',
        'flex flex-col sm:flex-row-reverse gap-3 space-s-reverse',
        !withBorder && 'pt-0 sm:pt-0',
        withBorder && 'border-t border-line-subdued',
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  )
}
