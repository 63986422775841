import { COLUMN_BLOCK_NAME } from '@tribeplatform/react-slate-kit/constants'
import { useBlock, useSlate } from '@tribeplatform/react-slate-kit/hooks'
import {
  AddBlockPosition,
  UnknownProps,
} from '@tribeplatform/react-slate-kit/types'

import { EditorBlockExtraContext } from '../types.js'
import { AddBlockButton } from './AddBlockButton.js'

interface Props {
  onAddBlock: (position: AddBlockPosition) => unknown
}

export const LayoutBlockHighlightWrapper = ({ onAddBlock }: Props) => {
  const {
    block: { id, name },
    extraContext: { focused, selected, activated },
  } = useBlock<UnknownProps, EditorBlockExtraContext>()
  const {
    slate: { rootBlock },
  } = useSlate<EditorBlockExtraContext>()

  const isColumnBlock = name === COLUMN_BLOCK_NAME
  const isRootBlock = rootBlock === id
  const isDeprecatedContainer =
    name === 'Container' || name === 'List' || name === 'Columns'

  const hideAddButtons = isColumnBlock || isRootBlock || isDeprecatedContainer

  return (
    <>
      {(selected || focused || activated) && (
        <div className="absolute inset-0 z-9 !m-0 rounded-base ring-inset ring-offset-0 ring ring-customizer-blue pointer-events-none"></div>
      )}

      {!hideAddButtons && (
        <>
          <AddBlockButton
            outside
            position={AddBlockPosition.Before}
            onClick={() => onAddBlock(AddBlockPosition.Before)}
          />
          <AddBlockButton
            outside
            position={AddBlockPosition.After}
            onClick={() => onAddBlock(AddBlockPosition.After)}
          />
        </>
      )}
    </>
  )
}
