import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { AccordionBlock as Component } from './Block.js'
import { AccordionProps } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.AccordionBlockSettings })),
)

export const RegisteredAccordionBlock = (
  intl: I18nShape,
): RegisteredBlock<AccordionProps> => {
  const { $t } = intl

  return {
    recommendedName: 'Accordion',
    Component,
    config: {
      type: BlockType.Block,
      usable: true,
      displayName: $t({
        id: 'Blocks.Accordion.DisplayName',
        defaultMessage: 'Accordions',
      }),
      Settings,
      Icon: <SvgIcon name="block-accordion" />,
      defaultProps: {
        title: $t({
          id: 'Blocks.Accordion.Title',
          defaultMessage: 'Accordions',
        }),
        items: [
          {
            title: $t({
              id: 'Blocks.Accordion.ItemTitle',
              defaultMessage: 'Accordion title',
            }),
            description: $t({
              id: 'Blocks.Accordion.ItemDescription',
              defaultMessage:
                'This is an example of a paragraph text inside of an accordion that’s visibly open.',
            }),
            defaultOpen: true,
          },
        ],
      },
    },
  }
}
