import { clsx } from 'clsx'

import { MediaType, type SpaceHeaderProps } from '../../types.js'
import {
  getContentSpacingClass,
  getMediaAspectRatioClass,
} from '../../utils/utils.js'
import { ContentRenderer } from '../ContentRenderer.js'
import { MediaRenderer } from '../MediaRenderer.js'

interface Props extends SpaceHeaderProps {
  moveIconToTop?: boolean
}

export const MediaTopPresetRenderer = ({
  contentSpacing,
  textColor,
  contentAlignment,
  mediaType,
  image,
  video,
  mediaTint,
  backgroundColor,
  fields,
  mediaAspectRatio,
  moveIconToTop = false,
  spaceId,
}: Props) => {
  const spacingClass = getContentSpacingClass(contentSpacing)
  const mediaAspectRatioClass = getMediaAspectRatioClass(mediaAspectRatio)
  const renderMedia = mediaType !== MediaType.None

  return (
    <div
      className={clsx(
        'flex overflow-hidden flex-col-reverse screen-rounded-none screen-border-x-0 relative',
        !backgroundColor && renderMedia && 'bg-surface',
        renderMedia && 'shadow-card border-base border-card rounded-base',
      )}
      style={{ backgroundColor: renderMedia ? backgroundColor : 'transparent' }}
    >
      <ContentRenderer
        moveIconToTop={renderMedia && moveIconToTop}
        textColor={textColor}
        alignment={contentAlignment}
        className={clsx(
          'px-6 @3xl:px-10 @5xl:px-16',
          !renderMedia && 'sm:px-0',
          spacingClass,
        )}
        fields={fields}
        spaceId={spaceId}
        isSimple={!renderMedia}
      />
      {renderMedia && (
        <div className={clsx('relative', mediaAspectRatioClass)}>
          <MediaRenderer
            mediaType={mediaType}
            image={image}
            video={video}
            mediaTint={mediaTint}
            textColor={textColor}
          />
        </div>
      )}
    </div>
  )
}
