import originalDayjs from 'dayjs/esm/index.mjs'
import customParseFormat from 'dayjs/esm/plugin/customParseFormat/index.mjs'
import isToday from 'dayjs/esm/plugin/isToday/index.mjs'
import localizedFormat from 'dayjs/esm/plugin/localizedFormat/index.mjs'
import relativeTime from 'dayjs/esm/plugin/relativeTime/index.mjs'
import timezone from 'dayjs/esm/plugin/timezone/index.mjs'
import utc from 'dayjs/esm/plugin/utc/index.mjs'

import type { ExtendedDayjs } from './@types/dayjs.js'

/**
 * Make sure to update ./@types/dayjs.dt.s plugins are added or removed
 */
originalDayjs.extend(localizedFormat)
originalDayjs.extend(customParseFormat)
originalDayjs.extend(isToday)
originalDayjs.extend(utc)
originalDayjs.extend(relativeTime)
originalDayjs.extend(timezone)

/**
 * Since the inferred type for originalDayjs does not include
 * the augmented methods and properties provided by the plugins,
 * we need to force-cast the type. This is an issue with the library
 * itself due to lack of consistency in type declarations.
 */
export const dayjs = originalDayjs as unknown as ExtendedDayjs
