import { Disclosure } from '@headlessui/react'
import { clsx } from 'clsx'

import { SystemSpaceSlug } from '@bettermode/common/space'
import { Collection } from '@tribeplatform/gql-client/types'
import { T } from '@tribeplatform/react-components/i18n'
import { SpaceImage } from '@tribeplatform/react-components/Space'
import { useRouter, useRouterPath } from '@tribeplatform/react-sdk'
import { useSpaceUtil } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { NavVertical } from '@tribeplatform/react-ui-kit/Sidebar'

import { useSlate, useSlateKitContext } from '../../hooks/index.js'
import { useMobileMenu } from '../Navbar/providers/MobileMenuProvider.js'

export const CollectionMenuCollapsible = ({
  collections,
  canCreateSpace,
  ...rest
}: {
  collections: Collection[]
  canCreateSpace: boolean
}) => {
  const { Link } = useRouter()
  const { close } = useMobileMenu()
  const { generatePath, matchPath } = useSpaceUtil()
  const pathname = useRouterPath()
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'
  const {
    kit: { userStorage: storage },
  } = useSlateKitContext()
  const spaceCollections =
    storage.getItem<{ id: string; isExpanded: boolean }[]>('spaceCollections')

  return (
    <>
      <NavVertical {...rest}>
        <div className="space-y-2">
          {collections.map(collection => {
            const hasSpaces = collection?.spaces?.totalCount
            const isActive = matchPath(SystemSpaceSlug.COLLECTION, pathname, {
              id: collection.id,
            })

            if (!hasSpaces && !canCreateSpace) {
              return null
            }
            return (
              <Disclosure
                key={collection.id}
                defaultOpen={
                  spaceCollections?.[collection?.id]?.isExpanded ?? true
                }
              >
                {({ open }) => (
                  <NavVertical.Group>
                    <NavVertical.Item
                      active={!!isActive}
                      as={Link}
                      href={generatePath(SystemSpaceSlug.COLLECTION, {
                        id: collection.id,
                      })}
                      onClick={e => {
                        if (isPreviewMode) {
                          e.preventDefault()
                          return
                        }

                        close()
                      }}
                    >
                      <div
                        onClick={e => {
                          e.stopPropagation()
                          e.preventDefault()
                        }}
                        className={clsx(
                          'items-center justify-center w-5 h-5 rounded-base me-2 -ms-1',
                          isActive
                            ? 'hover:bg-background-hovered'
                            : 'hover:bg-background-pressed',
                        )}
                      >
                        <Disclosure.Button as="div" role="navigation">
                          <SvgIcon
                            onClick={() => {
                              storage.setItem('spaceCollections', {
                                ...spaceCollections,
                                [collection?.id]: {
                                  id: collection?.id,
                                  isExpanded: !open,
                                  name: collection?.name,
                                },
                              })
                            }}
                            className={clsx(
                              'w-5 h-5 transform transition-transform',
                              !open ? '-rotate-90 rtl:rotate-90' : '',
                            )}
                            name="chevron-down"
                          />
                        </Disclosure.Button>
                      </div>
                      <span className="truncate flex-grow font-medium">
                        {collection.name}
                      </span>
                    </NavVertical.Item>
                    <Disclosure.Panel>
                      <NavVertical.Group>
                        {collection?.spaces?.edges?.map(({ node: space }) => {
                          const isSpaceActive = matchPath(space, pathname)
                          return (
                            <NavVertical.Item
                              as={Link}
                              key={space.id}
                              href={space?.relativeUrl}
                              active={!!isSpaceActive}
                              leadingAddon={
                                <div className="me-3 -ms-1">
                                  <SpaceImage space={space} size="lg" />
                                </div>
                              }
                              onClick={e => {
                                if (isPreviewMode) {
                                  e.preventDefault()
                                  return
                                }

                                close()
                              }}
                            >
                              {space.name}
                            </NavVertical.Item>
                          )
                        })}
                        {!hasSpaces ? (
                          <NavVertical.Item
                            as={Link}
                            href={`/manage/customizer/create-space?collectionId=${collection.id}`}
                            onClick={e => {
                              if (isPreviewMode) {
                                e.preventDefault()
                                return
                              }

                              close()
                            }}
                          >
                            <span className="w-6 h-6 -ms-1 me-3 flex justify-center items-center">
                              <SvgIcon
                                className="w-5 h-5"
                                name="package-plus"
                              />
                            </span>
                            <span className="truncate">
                              <T
                                id="Generics.AddSpace"
                                defaultMessage="Add {SPACE}"
                              />
                            </span>
                          </NavVertical.Item>
                        ) : null}
                      </NavVertical.Group>
                    </Disclosure.Panel>
                  </NavVertical.Group>
                )}
              </Disclosure>
            )
          })}
        </div>
      </NavVertical>
    </>
  )
}
