import { clsx } from 'clsx'

import { useSpace } from '@tribeplatform/react-sdk/hooks'
import { Text } from '@tribeplatform/react-ui-kit/Text'

import { TitleSettings } from '../../Fields/types.js'
import { PrivacyIcon } from '../PrivacyIcon.js'
import { getPrivacyIconSize } from './utils.js'

interface Props {
  className?: string
  settings: TitleSettings
  spaceId: string
}

export const TitleField = ({ className, settings, spaceId }: Props) => {
  const { useSpaceName, showPrivateIcon, customTitle, size } = settings
  const { data: space } = useSpace({
    variables: {
      id: spaceId,
    },
    useQueryOptions: {
      enabled: !!spaceId && !!useSpaceName,
    },
  })

  return (
    <div
      className={clsx(className, 'flex items-center gap-1 flex-wrap font-bold')}
    >
      <Text
        as="h2"
        className="line-clamp-3"
        variant={size}
        color="inherit"
        fontWeight="bold"
      >
        {useSpaceName ? space?.name : customTitle}
      </Text>
      {space?.private && showPrivateIcon && (
        <PrivacyIcon size={getPrivacyIconSize(size)} />
      )}
    </div>
  )
}
