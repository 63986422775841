import { Member } from '@tribeplatform/gql-client/types'

import { BannerPlaceholder } from '../BannerPlaceholder.js'

export const MemberBannerPlaceholder = ({ member }: { member: Member }) => {
  return (
    <div className="w-full bg-cover bg-center absolute top-0 start-0">
      <BannerPlaceholder name={member?.name ?? ''} />
    </div>
  )
}
