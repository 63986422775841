import { useMemo } from 'react'

import { isFieldPrivate } from '@tribeplatform/react-components/CMS'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

interface UseCustomFieldsProps {
  excludePrivateFields: boolean
}
export const useCustomFields = ({
  excludePrivateFields,
}: UseCustomFieldsProps) => {
  const {
    data: { memberFields: networkMemberFields },
  } = useNetwork()

  const memberFields = useMemo(() => {
    if (!networkMemberFields) {
      return []
    }

    const { fields } = networkMemberFields
    return fields.filter(
      field =>
        !field.archived &&
        !(excludePrivateFields && isFieldPrivate(field?.readPrivacy)),
    )
  }, [excludePrivateFields, networkMemberFields])

  return { memberFields }
}
