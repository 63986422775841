import { Controller, useFormContext } from 'react-hook-form'

import {
  FormControlSearchableSelect,
  FormControlSearchableSelectProps,
} from '@tribeplatform/react-ui-kit/FormControl/FormControlSearchableSelect'

import { FormFieldProps, Optional } from '../typings.js'

export function FormSearchableSelect({
  name,
  validation,
  value: fieldValue,
  onChange: selectOnChange,
  ...rest
}: FormFieldProps<
  Optional<FormControlSearchableSelectProps, 'value' | 'onChange'>
>) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControlSearchableSelect
          {...rest}
          name={name}
          invalid={!!error?.message}
          error={error?.message}
          value={fieldValue ?? value}
          onChange={value => {
            onChange(value)
            if (typeof selectOnChange === 'function') {
              selectOnChange(value)
            }
          }}
        />
      )}
    />
  )
}
