import { T } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

interface Props {
  onClick: () => void
  onHover: () => void
  onMouseLeave: () => void
}

export const EmptyColumnButton = ({
  onClick,
  onHover,
  onMouseLeave,
}: Props) => {
  return (
    <div
      className="@container border border-dashed h-[100px] rounded-base flex flex-col items-center justify-center cursor-pointer truncate"
      onMouseOver={onHover}
      onMouseLeave={onMouseLeave}
      onFocus={onHover}
      onClick={onClick}
    >
      <div className="flex items-center justify-center gap-1">
        <SvgIcon name="plus" size="sm" />
        <span className="text-lg font-light hidden @[7rem]:block">
          <T id="BlockActions.AddBlock" defaultMessage="Add block" />
        </span>
      </div>
      <div className="text-content-disabled font-light hidden @[12rem]:block">
        <T
          id="Blocks.Column.Empty.AddBlockHelperText"
          defaultMessage="Click to add blocks here"
        />
      </div>
    </div>
  )
}
