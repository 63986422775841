import type {
  MemberFields,
  SpaceMemberFields,
} from '@tribeplatform/gql-client/graphql'
import type {
  Fields,
  QueryMemberArgs,
  QueryMembersArgs,
  QueryMembersByIdsArgs,
  QuerySpaceMembersArgs,
  QuerySpaceMemberArgs,
  QuerySearchMembersForSpaceInvitationArgs,
} from '@tribeplatform/gql-client/types'
import {
  MemberFields as AutoMemberFields,
  MemberForSpaceInvitationFields,
} from '@tribeplatform/gql-client/types/generated'

import { KeyConstructor } from './keyConstructor.js'

const MEMBER_KEY = 'member'
const MEMBERS_KEY = 'members'
const SPACE_MEMBER_KEY = 'spaceMember'
const MEMBERS_BY_IDS_KEY = 'membersByIds'
const SPACE_MEMBERS_KEY = 'spaceMembers'
const SEARCH_MEMBERS_FOR_SPACE_INVITATION = 'searchMembersForSpaceInvitation'

export const getMemberKey = KeyConstructor<QueryMemberArgs, MemberFields>(
  MEMBER_KEY,
)
export const getMembersByIdsKey = KeyConstructor<
  QueryMembersByIdsArgs,
  AutoMemberFields
>(MEMBERS_BY_IDS_KEY)
export const getMembersKey = KeyConstructor<QueryMembersArgs, MemberFields>(
  MEMBERS_KEY,
)
export const getSpaceMemberKey = KeyConstructor<
  QuerySpaceMemberArgs,
  Fields.SpaceMemberFields
>(SPACE_MEMBER_KEY)
export const getSpaceMembersKey = KeyConstructor<
  QuerySpaceMembersArgs,
  SpaceMemberFields
>(SPACE_MEMBERS_KEY)
export const getSearchMembersForSpaceInvitation = KeyConstructor<
  QuerySearchMembersForSpaceInvitationArgs,
  MemberForSpaceInvitationFields
>(SEARCH_MEMBERS_FOR_SPACE_INVITATION)
