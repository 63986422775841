import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'

interface UploadQueueItem {
  id: string
}
export interface UploadQueueContextProps {
  isUploading: boolean
  addToUploadQueue: (item: UploadQueueItem) => void
  removeFromUploadQueue: (item: UploadQueueItem) => void
}

const noop = (_: UploadQueueItem) => {
  // no operation
}

const UploadQueueContext = createContext<UploadQueueContextProps>({
  isUploading: false,
  addToUploadQueue: noop,
  removeFromUploadQueue: noop,
})

export const UploadQueueProvider = ({ children }: { children: ReactNode }) => {
  const [uploading, setUploading] = useState(false)

  const uploadQueue = useRef<Array<UploadQueueItem>>([])

  const addToUploadQueue = useCallback(
    (item: UploadQueueItem) => {
      uploadQueue.current = [...uploadQueue.current, item]
      setUploading(true)
    },
    [uploadQueue],
  )

  const removeFromUploadQueue = useCallback(
    (item: UploadQueueItem) => {
      uploadQueue.current = uploadQueue.current.filter(
        currentItem => currentItem.id !== item.id,
      )
      if (uploadQueue.current.length === 0) {
        setUploading(false)
      }
    },
    [uploadQueue],
  )

  const value = useMemo(
    () => ({
      isUploading: uploading,
      uploadQueue,
      addToUploadQueue,
      removeFromUploadQueue,
    }),
    [addToUploadQueue, removeFromUploadQueue, uploading],
  )

  return (
    <UploadQueueContext.Provider value={value}>
      {children}
    </UploadQueueContext.Provider>
  )
}

export const useUploadQueue = () => {
  const context = useContext(UploadQueueContext)
  if (context === undefined) {
    throw new Error('useUploadQueue must be used within a UploadQueueProvider')
  }
  return context
}
