import { useState, useRef, useCallback, useMemo, useEffect } from 'react'

import { clsx } from 'clsx'

import { useDocument, useToggle } from '../hooks/index.js'
import { SvgIcon } from '../Icon/SvgIcon.js'
import { useUniqueId } from '../utils/unique-id.js'
import { FileDropZoneContext } from './FileDropZoneContext.js'
import { FileDropZoneInput } from './FileDropZoneInput.js'
import { FileDropZoneLabel } from './FileDropZoneLabel.js'
import { FileDropZoneProps } from './types.js'
import { fileAccepted, getDataTransferFiles } from './utils.js'

/**
 * The drop zone component lets users upload files by dragging and dropping the files into an area on a page, or activating a button.
 */
export const FileDropZone = ({
  dropOnPage,
  children,
  disabled = false,
  outline = true,
  accept,
  active,
  overlay = true,
  allowMultiple = true,
  overlayText,
  errorOverlayText,
  id: idProp,
  type = 'file',
  onClick,
  invalid = false,
  openFileDialog,
  onFileDialogClose,
  customValidator,
  onDrop,
  onDropAccepted,
  onDropRejected,
  onDragEnter,
  onDragOver,
  onDragLeave,
  loading,
  className,
}: FileDropZoneProps) => {
  const { document } = useDocument()
  const node = useRef<HTMLDivElement>(null)
  const dragTargets = useRef<EventTarget[]>([])

  const [dragging, setDragging] = useState(false)
  const [internalError, setInternalError] = useState(false)
  const {
    value: focused,
    setTrue: handleFocus,
    setFalse: handleBlur,
  } = useToggle(false)

  const getValidatedFiles = useCallback(
    (files: File[] | DataTransferItem[]) => {
      const acceptedFiles: File[] = []
      const rejectedFiles: File[] = []

      Array.from(files as File[]).forEach((file: File) => {
        if (
          !fileAccepted(file, accept) ||
          (customValidator && !customValidator(file))
        ) {
          rejectedFiles.push(file)
        } else {
          acceptedFiles.push(file)
        }
      })

      if (!allowMultiple) {
        acceptedFiles.splice(1, acceptedFiles.length)
        rejectedFiles.push(...acceptedFiles.slice(1))
      }

      return { files, acceptedFiles, rejectedFiles }
    },
    [accept, allowMultiple, customValidator],
  )

  const handleDrop = useCallback(
    (event: DragEvent) => {
      stopEvent(event)
      if (disabled) {
        return
      }

      const fileList = getDataTransferFiles(event)

      const { files, acceptedFiles, rejectedFiles } =
        getValidatedFiles(fileList)

      dragTargets.current = []

      setDragging(false)
      setInternalError(rejectedFiles.length > 0)

      onDrop?.(files as File[], acceptedFiles, rejectedFiles)
      if (onDropAccepted && acceptedFiles.length) {
        onDropAccepted?.(acceptedFiles)
      }
      if (onDropRejected && rejectedFiles.length) {
        onDropRejected(rejectedFiles)
      }
      ;(event.target as HTMLInputElement).value = ''
    },
    [disabled, getValidatedFiles, onDrop, onDropAccepted, onDropRejected],
  )

  const handleDragEnter = useCallback(
    (event: DragEvent) => {
      stopEvent(event)
      if (disabled) {
        return
      }

      const fileList = getDataTransferFiles(event)

      if (event.target && !dragTargets.current.includes(event.target)) {
        dragTargets.current.push(event.target)
      }

      if (dragging) {
        return
      }

      const { rejectedFiles } = getValidatedFiles(fileList)

      setDragging(true)
      setInternalError(rejectedFiles.length > 0)

      onDragEnter?.()
    },
    [disabled, dragging, getValidatedFiles, onDragEnter],
  )

  const handleDragOver = useCallback(
    (event: DragEvent) => {
      stopEvent(event)
      if (disabled) {
        return
      }
      onDragOver?.()
    },
    [disabled, onDragOver],
  )

  const handleDragLeave = useCallback(
    (event: DragEvent) => {
      event.preventDefault()

      if (disabled) {
        return
      }

      const isServer = typeof window === 'undefined'

      dragTargets.current = dragTargets.current.filter((el: Node) => {
        const compareNode = dropOnPage && !isServer ? document : node.current

        return el !== event.target && compareNode && compareNode.contains(el)
      })

      if (dragTargets.current.length > 0) {
        return
      }

      setDragging(false)
      setInternalError(false)

      onDragLeave?.()
    },
    [dropOnPage, disabled, onDragLeave],
  )

  useEffect(() => {
    const dropNode = dropOnPage ? document : node.current

    if (!dropNode) {
      return
    }

    dropNode.addEventListener('drop', handleDrop)
    dropNode.addEventListener('dragover', handleDragOver)
    dropNode.addEventListener('dragenter', handleDragEnter)
    dropNode.addEventListener('dragleave', handleDragLeave)

    return () => {
      dropNode.removeEventListener('drop', handleDrop)
      dropNode.removeEventListener('dragover', handleDragOver)
      dropNode.removeEventListener('dragenter', handleDragEnter)
      dropNode.removeEventListener('dragleave', handleDragLeave)
    }
  }, [dropOnPage, handleDrop, handleDragOver, handleDragEnter, handleDragLeave])

  const id = useUniqueId('DropZone', idProp)
  const escapedUniqueId = id.replace(/:/g, '\\:')

  const typeLabel = `${type}${allowMultiple ? 's' : ''}`
  const overlayTextWithDefault =
    overlayText === undefined ? `Drop ${typeLabel} to upload` : overlayText

  const errorOverlayTextWithDefault =
    errorOverlayText === undefined
      ? `${type.toUpperCase()} type is not valid`
      : errorOverlayText

  const inputAttributes = {
    id,
    accept,
    disabled,
    type: 'file' as const,
    multiple: allowMultiple,
    onChange: handleDrop,
    onFocus: handleFocus,
    onBlur: handleBlur,
  }

  const classes = clsx(
    'relative flex justify-center bg-surface rounded-base min-h-[60px]',
    'border border-line hover:border-line-hovered',
    outline && 'p-1',
    focused && 'focus-within:ring ring-offset-0',
    (active || dragging) && 'bg-surface-hovered border-line-hovered',
    disabled
      ? 'opacity-60 cursor-default pointer-events-none'
      : 'cursor-pointer',
    internalError || invalid
      ? 'border-line-negative'
      : 'border-line hover:border-line-hovered',
    className,
  )

  const dragOverlay =
    (active || dragging) &&
    !internalError &&
    !invalid &&
    overlay &&
    overlayMarkup('interactive', overlayTextWithDefault)

  const dragErrorOverlay =
    dragging &&
    (internalError || invalid) &&
    overlayMarkup('critical', errorOverlayTextWithDefault)

  const context = useMemo(
    () => ({
      disabled,
      focused,
      type: type || 'file',
      allowMultiple,
    }),
    [disabled, focused, type, allowMultiple],
  )

  return (
    <FileDropZoneContext.Provider value={context}>
      <div
        ref={node}
        className={classes}
        aria-disabled={disabled}
        onClick={handleClick}
        onDragStart={stopEvent}
      >
        {dragOverlay}
        {dragErrorOverlay}
        <div className="sr-only">
          <FileDropZoneInput
            {...inputAttributes}
            openFileDialog={openFileDialog}
            onFileDialogClose={onFileDialogClose}
          />
        </div>
        <div className="flex-1 min-w-0">{children}</div>
        {loading && (
          <div className="absolute inset-0 cursor-default pointer-events-none z-10 bg-background-overlay flex items-center justify-center">
            <SvgIcon className="animate-spin h-7 w-7" name="spinner" />
          </div>
        )}
      </div>
    </FileDropZoneContext.Provider>
  )

  function overlayMarkup(color: 'critical' | 'interactive', text: string) {
    return (
      <div
        className={clsx(
          'absolute inset-0',
          'flex items-center justify-center',
          'bg-surface rounded-base pointer-events-none',
          'z-10',
        )}
      >
        <div className="text-sm first-letter:capitalize text-content-subdued">
          {text}
        </div>
      </div>
    )
  }

  function open() {
    const fileInputNode =
      node.current && node.current.querySelector(`#${escapedUniqueId}`)
    if (fileInputNode && fileInputNode instanceof HTMLElement) {
      fileInputNode.click()
    }
  }

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    if (disabled) {
      return
    }

    return onClick ? onClick(event) : open()
  }
}

function stopEvent(event: DragEvent | React.DragEvent) {
  event.preventDefault()
  event.stopPropagation()
}

FileDropZone.Label = FileDropZoneLabel
