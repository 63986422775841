import { Controller, useFormContext } from 'react-hook-form'

import {
  FormControl,
  FormControlToggleProps,
} from '@tribeplatform/react-ui-kit/FormControl'

import { FormFieldProps, Optional } from './typings.js'

export function Toggle({
  name,
  validation,
  value: fieldValue,
  onChange: selectOnChange,
  ...rest
}: FormFieldProps<Optional<FormControlToggleProps, 'checked' | 'onChange'>>) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={false}
      rules={validation}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl.Toggle
          name={name}
          invalid={!!error?.message}
          error={error?.message}
          checked={fieldValue ?? value}
          onChange={value => {
            onChange(value)
            if (typeof selectOnChange === 'function') {
              selectOnChange(value)
            }
          }}
          {...rest}
        />
      )}
    />
  )
}
