import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { PostBlock as Component } from './Block.js'
import { PostBlockProps } from './types.js'

export const RegisteredPostBlock = (
  intl: I18nShape,
): RegisteredBlock<PostBlockProps> => {
  const { $t } = intl

  return {
    recommendedName: 'Post',
    Component,
    config: {
      type: BlockType.Block,
      displayName: $t({
        id: 'Generics.Post.Noun',
        defaultMessage: 'Post',
      }),
      usable: false,

      defaultProps: {
        id: '{{ postId }}',
      },
      Icon: <SvgIcon name="post" />,
    },
  }
}
