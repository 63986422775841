import { useEffect, useRef } from 'react'

import { logger } from '@tribeplatform/react-components/common'
import { T } from '@tribeplatform/react-components/i18n'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { useDocument } from '@tribeplatform/react-ui-kit/hooks'

import { BC } from '../../types/index.js'
import { HtmlScriptBlockProps } from './types.js'

interface HtmlBlockContentProps {
  value: string
}
const HtmlBlockContent = ({ value }: HtmlBlockContentProps) => {
  const { document } = useDocument()
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const contentElement = contentRef.current
    if (!contentElement) {
      return
    }

    // As dangerouslySetInnerHTML won't run the scripts we should
    // use createContextualFragment and append it to the div as a workaround
    const doc = document.createRange().createContextualFragment(value)
    contentElement.innerHTML = ''
    contentElement.appendChild(doc)
  }, [document, value])

  if (typeof window === 'undefined') {
    return null
  }

  if (!value) {
    return (
      <p>
        <T
          id="Blocks.HtmlScript.InsertHtmlInThisBlock"
          defaultMessage="Insert html text in this block"
        />
      </p>
    )
  }

  return <div className="flex justify-center items-center" ref={contentRef} />
}

export const HtmlScriptBlock: BC<HtmlScriptBlockProps> = ({
  html,
  wrapper = 'card-with-padding',
  ...rest
}) => {
  switch (wrapper) {
    case 'card':
      return (
        <Card {...rest}>
          <HtmlBlockContent value={html} />
        </Card>
      )
    case 'none':
      return <HtmlBlockContent value={html} {...rest} />
    case 'card-with-padding':
      return (
        <Card {...rest}>
          <Card.Content>
            <HtmlBlockContent value={html} />
          </Card.Content>
        </Card>
      )
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = wrapper
      logger.warn('Not supported wrapper: ', exhaustiveCheck)
      return (
        <Card {...rest}>
          <Card.Content>
            <HtmlBlockContent value={html} />
          </Card.Content>
        </Card>
      )
    }
  }
}
