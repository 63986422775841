import type { NetworkFields } from '@tribeplatform/gql-client/graphql'
import type { Network } from '@tribeplatform/gql-client/types'

import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getNetworkKey } from '../../utils/keys/network.key.js'
import { useCachedNetwork } from '../cache/useCachedNetwork.js'

export const useNetwork = (options?: {
  useQueryOptions?: UseQueryOptions<Network>
  fields?: NetworkFields
  ignorePlaceholderData?: boolean
}) => {
  const {
    useQueryOptions,
    fields = 'default',
    ignorePlaceholderData = false,
  } = options || {}
  const { client } = useTribeClient()
  const networkKey = getNetworkKey({ fields })
  const placeholderData = useCachedNetwork()

  return useQuery<Network>(networkKey, () => client.network.get(fields), {
    ...(ignorePlaceholderData ? {} : { placeholderData }),
    ...useQueryOptions,
  })
}
