import { useRef, useEffect, useState } from 'react'

import { useDocument } from '../hooks/useDocument.js'
import {
  focusFirstFocusableNode,
  findFirstKeyboardFocusableNode,
  focusFirstKeyboardFocusableNode,
  findLastKeyboardFocusableNode,
  focusLastKeyboardFocusableNode,
} from '../utils/focus.js'
import { useEventListener } from '../utils/useEventListener.js'
import { useKeypressListener } from '../utils/useKeypressListener.js'
import { Focus } from './Focus.js'
import { useFocusManager } from './useFocusManager.js'

export interface TrapFocusProps extends React.HTMLAttributes<HTMLDivElement> {
  trapping?: boolean
}

/**
 * A component which allows you to trap keyboard focus inside of a container.
 *
 * TrapFocus internally employs Focus to focus it's first focusable child on mount.
 *
 * Whenever a blur event occurs that would take the user outside the trap, we reset to the first focusable child.
 */
export function TrapFocus({
  trapping = true,
  children,
  ...rest
}: TrapFocusProps) {
  const { document } = useDocument()

  const { canSafelyFocus } = useFocusManager({ trapping })
  const focusTrapWrapper = useRef<HTMLDivElement>(null)
  const [disableFocus, setDisableFocus] = useState(true)

  useEffect(() => {
    const disable =
      canSafelyFocus &&
      !(
        focusTrapWrapper.current &&
        focusTrapWrapper.current.contains(document.activeElement)
      )
        ? !trapping
        : true

    setDisableFocus(disable)
  }, [document, canSafelyFocus, trapping])

  const handleFocusIn = (event: FocusEvent) => {
    const containerContentsHaveFocus =
      focusTrapWrapper.current &&
      focusTrapWrapper.current.contains(document.activeElement)

    if (
      trapping === false ||
      !focusTrapWrapper.current ||
      containerContentsHaveFocus ||
      (event.target instanceof Element &&
        event.target.matches(`[data-portal-id] *`))
    ) {
      return
    }

    if (
      canSafelyFocus &&
      event.target instanceof HTMLElement &&
      focusTrapWrapper.current !== event.target &&
      !focusTrapWrapper.current.contains(event.target)
    ) {
      focusFirstFocusableNode(document, focusTrapWrapper.current)
    }
  }

  const handleTab = (event: KeyboardEvent) => {
    if (trapping === false || !focusTrapWrapper.current) {
      return
    }

    const firstFocusableNode = findFirstKeyboardFocusableNode(
      document,
      focusTrapWrapper.current,
    )
    const lastFocusableNode = findLastKeyboardFocusableNode(
      document,
      focusTrapWrapper.current,
    )

    if (event.target === lastFocusableNode && !event.shiftKey) {
      event.preventDefault()
      focusFirstKeyboardFocusableNode(document, focusTrapWrapper.current)
    }

    if (event.target === firstFocusableNode && event.shiftKey) {
      event.preventDefault()
      focusLastKeyboardFocusableNode(document, focusTrapWrapper.current)
    }
  }

  useKeypressListener('Tab', handleTab, 'keydown')
  useEventListener('focusin', handleFocusIn)

  return (
    <Focus disabled={disableFocus} root={focusTrapWrapper.current}>
      <div ref={focusTrapWrapper} {...rest}>
        {children}
      </div>
    </Focus>
  )
}
