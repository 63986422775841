import type {
  CustomFieldSchema,
  CustomReaction,
} from '@tribeplatform/gql-client/types'

export enum ArrayOptions {
  single = 'single',
  multiple = 'multiple',
}
export enum ReadOptions {
  everyone = 'everyone',
  admin = 'admin',
  own = 'own',
}
export enum WriteOptions {
  admin = 'admin',
  own = 'own',
}
export enum MemberGroups {
  all = 'all',
  staff = 'staff',
  member = 'member',
}
export enum ReactionTypes {
  all = 'all',
  custom = 'custom',
  none = 'none',
}
export enum ReactionStates {
  default = 'default',
  active = 'active',
}
export enum ColorModes {
  default = 'default',
  custom = 'custom',
}
export enum ReactionErrors {
  name = 'name',
  icon = 'icon',
  key = 'key',
  none = 'none',
}
export interface FieldItem {
  id: string
  label: string
  icon: JSX.Element
  enabled: boolean
  isPrivate: boolean
  isLocked: boolean
  draggable: boolean
}
export interface CMSSharedState {
  selectedField?: CustomFieldSchema
  newField?: CustomFieldSchema
  highlightedFieldId?: string
}
export interface InputProps {
  memberGroup: MemberGroups
}
export interface SelectOption {
  id: string
  value: string
  color: string
}
export interface ReactionItem extends CustomReaction {
  id: string
  name: string
  itemIcon: JSX.Element
}
