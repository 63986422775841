import {
  CustomFieldPrivacyOptions,
  CustomFieldSchema,
} from '@tribeplatform/gql-client/types'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

import { T } from '../i18n/components/T.js'

export const FieldPrivacyBadges = ({
  field,
}: {
  field: Partial<CustomFieldSchema>
}) => {
  const badges = []
  if (field?.readPrivacy?.allow && field?.readPrivacy?.allow.length) {
    if (
      field?.readPrivacy?.allow.indexOf(CustomFieldPrivacyOptions.OWN) === -1
    ) {
      badges.push(
        <Tooltip key="privacy-admin">
          <Tooltip.Trigger>
            <SvgIcon className="text-content-subdued" name="eye-off" inline />
          </Tooltip.Trigger>
          <Tooltip.Panel>
            <T
              id="CustomField.FieldPrivacyBadges.FieldPrivacyOwnMessage"
              defaultMessage="This field is only visible to {COMMUNITY} admins and moderators."
            />
          </Tooltip.Panel>
        </Tooltip>,
      )
    } else if (
      field?.readPrivacy?.allow.indexOf(CustomFieldPrivacyOptions.ADMIN) === -1
    ) {
      badges.push(
        <Tooltip key="privacy-owner">
          <Tooltip.Trigger>
            <SvgIcon className="text-content-subdued" name="eye-off" inline />
          </Tooltip.Trigger>
          <Tooltip.Panel>
            <T
              id="CustomField.FieldPrivacyBadges.FieldPrivacyAdminMessage"
              defaultMessage="This field is only visible to the owner, {COMMUNITY} admins, and moderators."
            />
          </Tooltip.Panel>
        </Tooltip>,
      )
    }
  }

  if (field?.writePrivacy?.allow) {
    if (
      field?.writePrivacy?.allow.indexOf(CustomFieldPrivacyOptions.OWN) === -1
    ) {
      badges.push(
        <Tooltip key="update-admin">
          <Tooltip.Trigger>
            <SvgIcon className="text-content-subdued" name="lock" inline />
          </Tooltip.Trigger>
          <Tooltip.Panel>
            <T
              id="CustomField.FieldPrivacyBadges.FieldPrivacyAdminWritableMessage"
              defaultMessage="Only admins can update this field."
            />
          </Tooltip.Panel>
        </Tooltip>,
      )
    }
  }

  return <>{badges}</>
}
