import type { LimitedIntlShape } from '../types.js'

const CollectionPluralRule =
  ({ $t }: LimitedIntlShape) =>
  chunks =>
    $t(
      {
        defaultMessage:
          '{collection_count, plural, =1 {collection} other {collections}}',
        id: 'RESOURCE.Collection.Pluralization',
      },
      {
        collection_count: chunks[0],
      },
    )

const CollectionCapitalPluralRule =
  ({ $t }: LimitedIntlShape) =>
  chunks =>
    $t(
      {
        defaultMessage:
          '{collection_count, plural, =1 {Collection} other {Collections}}',
        id: 'RESOURCE.Collection.Pluralization.Capital',
      },
      {
        collection_count: chunks[0],
      },
    )

export const CollectionResourceRules = (intl: LimitedIntlShape) => {
  const collectionPlural = CollectionPluralRule(intl)
  const collectionCapitalPlural = CollectionCapitalPluralRule(intl)
  return {
    'COLLECTION.PLURAL': collectionPlural,
    COLLECTION: collectionPlural([1]),
    COLLECTIONS: collectionPlural([5]),
    'COLLECTION_CC.PLURAL': collectionCapitalPlural,
    COLLECTION_CC: collectionCapitalPlural([1]),
    COLLECTIONS_CC: collectionCapitalPlural([5]),
  }
}
