import type { PostFields } from '@tribeplatform/gql-client/graphql'
import type {
  PaginatedPost,
  QueryMemberPostsArgs,
} from '@tribeplatform/gql-client/types'

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '../../lib/react-query/useInfiniteQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getPostsKey } from '../../utils/keys/post.key.js'

export const useMemberPosts = (options: {
  variables: QueryMemberPostsArgs
  fields?: PostFields
  useInfiniteQueryOptions?: UseInfiniteQueryOptions<PaginatedPost>
}) => {
  const { variables, fields = 'basic', useInfiniteQueryOptions } = options || {}
  const { client } = useTribeClient()
  const postsKey = getPostsKey({ variables, fields })

  return useInfiniteQuery<PaginatedPost>(
    postsKey,
    ({ pageParam: after }) =>
      client?.posts.byMember(
        variables.memberId,
        { after, ...variables },
        fields,
      ),
    useInfiniteQueryOptions,
  )
}
