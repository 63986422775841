import { Children, forwardRef } from 'react'

import { useFormContext } from 'react-hook-form'

import { Form } from '@tribeplatform/react-components/Form'
import { FormControl } from '@tribeplatform/react-ui-kit/FormControl'

import { BC } from '../../types/index.js'
import { getIcon } from '../Button/utils.js'
import { MarkdownBlock } from '../Markdown/Block.js'
import { InputProps } from './types.js'

export const InputBlock: BC<InputProps> = forwardRef<
  HTMLInputElement,
  InputProps
>((props, ref) => {
  const {
    copy,
    value: defaultValue,
    helperText: markdownHelperText,
    leadingIcon,
    children: nonArrayChildren,
    ...rest
  } = props
  const children = Children.toArray(nonArrayChildren)

  const formContext = useFormContext()
  const { control: enabled, watch } = formContext || {}

  const value = enabled ? watch(rest.name, defaultValue) : defaultValue
  const helperText =
    typeof markdownHelperText === 'string' ? (
      <MarkdownBlock text={markdownHelperText} />
    ) : (
      markdownHelperText
    )

  const additionalProps = enabled ? {} : { value }
  if (copy) {
    const Component = enabled ? Form.InputCopy : FormControl.InputCopy
    return (
      <Component
        {...rest}
        {...additionalProps}
        helperText={helperText}
        defaultValue={value}
      />
    )
  }

  const Component = enabled ? Form.Input : FormControl.Input
  return (
    <Component
      {...rest}
      {...additionalProps}
      ref={ref}
      helperText={helperText}
      defaultValue={value}
      trailingAddon={children?.length ? children : undefined}
      leadingIcon={getIcon(leadingIcon)}
    />
  )
})
