import {
  useMutation as rqUseMutation,
  type UseMutationOptions as RQUseMutationOptions,
  type UseMutateAsyncFunction as RQUseMutateAsyncFunction,
  type MutationFunction,
  type UseMutationResult,
} from '@tanstack/react-query'

export type UseMutationOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
> = RQUseMutationOptions<TData, TError, TVariables, TContext>

type TUseMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn?: MutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, TError, TVariables, TContext>,
) => UseMutationResult<TData, TError, TVariables, TContext>

export const useMutation: TUseMutation = (mutationFn, options) => {
  return rqUseMutation(mutationFn, options)
}

export type UseMutateAsyncFunction<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
> = RQUseMutateAsyncFunction<TData, TError, TVariables, TContext>
