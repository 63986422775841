import { useMemo } from 'react'

import { useRouter } from '@tribeplatform/react-sdk'

export const getSearchParams = <T = Record<string, string>>(
  search: string,
): T => {
  const urlSearchParams = new URLSearchParams(search)
  const queryParams = {} as T
  urlSearchParams.forEach((value, key) => {
    queryParams[key] = value
  })

  return queryParams
}
export const useQuery = <T = Record<string, string>>(): T => {
  const { location } = useRouter()
  const result = useMemo(
    () => getSearchParams<T>(location.search),
    [location.search],
  )

  return result
}
