import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Action,
  MutationSendResetPasswordEmailArgs,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  type UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useTribeClient } from '../../useTribeClient.js'

export const useSendResetPasswordEmail = (options?: {
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationSendResetPasswordEmailArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()

  return useMutation<Action, ClientError, MutationSendResetPasswordEmailArgs>(
    input => client.auth.sendResetPasswordEmail(input),
    useMutationOptions,
  )
}
