import { startTransition, useEffect } from 'react'

import { Helmet } from 'react-helmet-async'

import { ClientError, isNotFound } from '@tribeplatform/gql-client/lib'
import { RoleType } from '@tribeplatform/gql-client/types'
import { useCommandBarSetContext } from '@tribeplatform/react-components/CommandBar'
import { useDomain } from '@tribeplatform/react-components/common/hooks'
import { useStaticIntl } from '@tribeplatform/react-components/i18n'
import { useAuthToken, useMember } from '@tribeplatform/react-sdk/hooks'
import { SlateRenderer } from '@tribeplatform/react-slate-kit/components'
import { useSlate } from '@tribeplatform/react-slate-kit/hooks'
import { SlateProps } from '@tribeplatform/react-slate-kit/types'

import { useAnalytics } from '../../hooks/useAnalytics.js'
import { NotFound } from '../Error/NotFound.js'
import { SpaceWithBannerLoading } from '../Space/SpaceLoading.js'
import { MemberPageSlate } from './MemberPage.slate.js'

type MemberPageProps = {
  id: string
}

export const MemberPage = ({ id }: MemberPageProps) => {
  const memberPageSlate = useStaticIntl(MemberPageSlate)
  const { mode, context } = useSlate()
  const { getResourceUrl } = useDomain()
  const {
    data: {
      network,
      member: { id: authMemberId },
    },
  } = useAuthToken()
  const { setCommandBarContext, unsetCommandBarContext } =
    useCommandBarSetContext()

  const memberId = mode === 'edit' ? authMemberId : id
  const { data: member, error: memberError } = useMember({
    variables: {
      id: memberId,
    },
    fields: {
      authMemberProps: 'all',
      badges: 'all',
      banner: 'all',
      profilePicture: 'all',
      role: 'basic',
      fields: 'all',
    },
    useQueryOptions: {
      refetchOnMount: 'always',
      retry: false,
    },
  })

  const title = `${member?.name} - ${network?.name}`

  const analytics = useAnalytics()
  useEffect(() => {
    if (!member?.id) {
      return undefined
    }
    analytics.trackPageView(title)

    startTransition(() => {
      setCommandBarContext({ entity: member, entityType: 'member' })
    })

    return () =>
      unsetCommandBarContext({ entity: member, entityType: 'member' })
  }, [analytics, member, setCommandBarContext, title, unsetCommandBarContext])

  if (!member) {
    if (isNotFound(memberError as ClientError)) {
      return <NotFound />
    }
    return <SpaceWithBannerLoading />
  }

  if (member?.role?.type === RoleType.guest) {
    return <NotFound />
  }

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          type="application/rss+xml"
          title={member?.name}
          href={getResourceUrl(`/rss/member/${member?.id}/posts`)}
        />
      </Helmet>
      <SlateRenderer
        slate={memberPageSlate as unknown as SlateProps['slate']}
        context={{ ...context, memberId: member.id }}
      />
    </>
  )
}
