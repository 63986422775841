import { Badge } from '@tribeplatform/react-ui-kit/Badge'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

import { T } from '../i18n/components/T.js'

export const PostPendingIndicator = () => (
  <Tooltip>
    <Tooltip.Trigger className="flex">
      <Badge trailingIcon={<SvgIcon name="clock" />}>
        <T id="Generics.PendingReview" defaultMessage="Pending review" />
      </Badge>
    </Tooltip.Trigger>
    <Tooltip.Panel>
      <T
        id="Post.PendingReview.Description"
        defaultMessage="Your post will be visible to others once it’s been reviewed by a moderator."
      />
    </Tooltip.Panel>
  </Tooltip>
)
