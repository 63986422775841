import type {
  PageFields,
  PostFields,
  SpaceFields,
  SpaceMemberFields,
} from '@tribeplatform/gql-client/graphql'
import type {
  QueryHomepageArgs,
  QueryMemberSpacesArgs,
  QueryPageArgs,
  QuerySpaceArgs,
  QuerySpacePinnedPostsArgs,
  QuerySpacesArgs,
  QuerySpacesByIdsArgs,
  QuerySpacesBySlugsArgs,
} from '@tribeplatform/gql-client/types'

import { KeyConstructor } from './keyConstructor.js'

const SPACE_KEY = 'space'
const PAGE_KEY = 'page'
const SPACES_KEY = 'spaces'
const MEMBER_SPACES_KEY = 'memberSpaces'
const SPACES_BY_IDS_KEY = 'spacesByIds'
const SPACES_BY_SLUGS_KEY = 'spacesBySlugs'
const PINNED_POSTS_KEY = 'pinnedPosts'
const HOMEPAGE_KEY = 'homepage'

export const getHomepageKey = KeyConstructor<QueryHomepageArgs, PageFields>(
  HOMEPAGE_KEY,
)
export const getPageKey = KeyConstructor<QueryPageArgs, PageFields>(PAGE_KEY)
export const getSpaceKey = KeyConstructor<QuerySpaceArgs, SpaceFields>(
  SPACE_KEY,
)
export const getSpacesKey = KeyConstructor<QuerySpacesArgs, SpaceFields>(
  SPACES_KEY,
)
export const getMemberSpacesKey = KeyConstructor<
  QueryMemberSpacesArgs,
  SpaceMemberFields
>(MEMBER_SPACES_KEY)
export const getSpacesByIdsKey = KeyConstructor<
  QuerySpacesByIdsArgs,
  SpaceFields
>(SPACES_BY_IDS_KEY)
export const getSpacesBySlugsKey = KeyConstructor<
  QuerySpacesBySlugsArgs,
  SpaceFields
>(SPACES_BY_SLUGS_KEY)
export const getPinnedPostsKey = KeyConstructor<
  QuerySpacePinnedPostsArgs,
  PostFields
>(PINNED_POSTS_KEY)
