import { useCallback, useMemo } from 'react'

import {
  hasActionPermission,
  hasInputPermission,
} from '@tribeplatform/gql-client/lib'
import { Member } from '@tribeplatform/gql-client/types'
import {
  useNetworkRoles,
  useUpdateMember,
} from '@tribeplatform/react-sdk/hooks'
import { confirm } from '@tribeplatform/react-ui-kit/Dialog'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { useI18n } from '../../i18n/providers/I18nProvider.js'
import type { MemberActionItem } from '../types.js'

export const useFlagMemberActionItem: (
  member: Member,
) => MemberActionItem = member => {
  const { $t } = useI18n()
  const { mutateAsync: updateMember } = useUpdateMember()
  const roles = useNetworkRoles()

  const categoryText = $t({
    id: 'Generics.Member',
    defaultMessage: '{MEMBER_CC}',
  })

  const { actionPermission } = hasActionPermission(
    member?.authMemberProps?.permissions ?? [],
    'updateMember',
  )

  const { authorized: canFlagMember } = hasInputPermission(
    actionPermission?.inputPermissions ?? [],
    'input.flagged',
  )

  const onFlag = useCallback(
    async (member: Member) => {
      if (!member?.id) {
        return
      }

      const confirmed = await confirm({
        title: $t({
          defaultMessage: 'Flagging {MEMBER}',
          id: 'MemberAction.Flag.Confirmation',
        }),
        description: $t({
          defaultMessage:
            "Flagging a {MEMBER} will send all their posts and replies for moderators' approval first.",
          id: 'MemberAction.Flag.Confirmation.Description',
        }),
        proceedLabel: $t({
          defaultMessage: 'Flag',
          id: 'Generics.Flag.Verb',
        }),
        cancelLabel: $t({
          defaultMessage: 'Cancel',
          id: 'Generics.Cancel',
        }),
      })
      if (!confirmed) {
        return
      }

      try {
        await updateMember({
          id: member.id,
          input: {
            flagged: true,
          },
        })
        if (!member.flagged) {
          toast({
            title: $t({
              defaultMessage: '{MEMBER_CC} successfully flagged',
              id: 'MemberAction.Flagged',
            }),
            status: 'success',
          })
        }
        if (member.flagged) {
          toast({
            title: $t({
              defaultMessage: '{MEMBER_CC} successfully unflagged',
              id: 'MemberAction.Unflagged',
            }),
            status: 'success',
          })
        }
      } catch {
        toast({
          title: $t({
            defaultMessage: 'Something went wrong',
            id: 'Generics.SomethingWentWrong',
          }),
          description: $t({
            defaultMessage: 'Cannot flag this {MEMBER}.',
            id: 'MemberAction.FailedToFlag.Description',
          }),
          status: 'error',
        })
      }
    },
    [$t, updateMember],
  )

  const onUnflag = useCallback(
    async (member: Member) => {
      try {
        await updateMember({
          id: member.id,
          input: {
            flagged: false,
          },
        })
        toast({
          title: $t({
            defaultMessage: '{MEMBER_CC} successfully unflagged',
            id: 'MemberAction.Unflagged',
          }),
          status: 'success',
        })
      } catch {
        toast({
          title: $t({
            defaultMessage: 'Something went wrong',
            id: 'Generics.SomethingWentWrong',
          }),
          description: $t({
            defaultMessage: 'Cannot unflag this {MEMBER}.',
            id: 'MemberAction.FailedToUnflag.Description',
          }),
          status: 'error',
        })
      }
    },
    [$t, updateMember],
  )

  const flagMemberActionItem: MemberActionItem = useMemo(() => {
    if (
      member?.role?.type !== roles?.admin?.type &&
      member?.role?.type !== roles?.moderator?.type &&
      !member?.flagged &&
      canFlagMember
    ) {
      return {
        action: () => onFlag(member),
        icon: 'flag',
        name: $t({ defaultMessage: 'Flag {MEMBER}', id: 'Member.Flag' }),
        category: categoryText,
      }
    }

    if (member?.flagged && canFlagMember) {
      return {
        action: () => onUnflag(member),
        icon: 'flag',
        name: $t({ defaultMessage: 'Unflag {MEMBER}', id: 'Member.Unflag' }),
        category: categoryText,
      }
    }

    return null
  }, [$t, canFlagMember, categoryText, member, onFlag, onUnflag, roles])

  return flagMemberActionItem
}
