import { MediaFields, mediaGQLFields } from '../media/media.fields.js'
import { MemberFields, memberGQLFields } from '../network/member.fields.js'
import { NetworkFields, networkGQLFields } from '../network/network.fields.js'
import { SlateFields, slateGQLFields } from '../slate/slate.fields.js'

export type TemplateFields = 'basic' | 'all' | CustomTemplateFields

export interface CustomTemplateFields {
  banner?: MediaFields
  createdBy?: MemberFields
  favicon?: MediaFields
  image?: MediaFields
  images?: MediaFields
  network?: NetworkFields
  updatedBy?: MemberFields
  requiredTemplates?: TemplateFields
  slate?: SlateFields
}

const BASIC_TEMPLATE_FIELDS: CustomTemplateFields = {}
const ALL_TEMPLATE_FIELDS: CustomTemplateFields = {
  banner: 'basic',
  createdBy: 'basic',
  favicon: 'basic',
  image: 'basic',
  images: 'basic',
  network: 'basic',
  updatedBy: 'basic',
  requiredTemplates: 'basic',
  slate: 'all',
}

export function templateGQLFields(fields: TemplateFields): string {
  if (fields === 'basic') {
    fields = BASIC_TEMPLATE_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_TEMPLATE_FIELDS
  }

  return `
    id
    name
    description
    slug
    status
    standing
    createdById
    about
    authorName
    authorUrl
    entityType
    imageId
    bannerId
    createdAt
    categories
    comingSoon
    embedIds
    faviconId
    networkId
    updatedAt
    updatedById
    entityProperties
    previewHtml
    ${
      fields.banner
        ? `
      banner {
        ${mediaGQLFields(fields.banner)}
      }
    `
        : ``
    }
    ${
      fields.createdBy
        ? `
      createdBy {
        ${memberGQLFields(fields.createdBy)}
      }
    `
        : ``
    }
    ${
      fields.favicon
        ? `
      favicon {
        ${mediaGQLFields(fields.favicon)}
      }
    `
        : ``
    }
    ${
      fields.image
        ? `
      image {
        ${mediaGQLFields(fields.image)}
      }
    `
        : ``
    }
    ${
      fields.images
        ? `
      images {
        ${mediaGQLFields(fields.images)}
      }
    `
        : ``
    }
    ${
      fields.network
        ? `
      network {
        ${networkGQLFields(fields.network)}
      }
    `
        : ``
    }
    ${
      fields.updatedBy
        ? `
      updatedBy {
        ${memberGQLFields(fields.updatedBy)}
      }
    `
        : ``
    }
    ${
      fields.requiredTemplates
        ? `
      requiredTemplates {
        ${templateGQLFields(fields.requiredTemplates)}
      }
    `
        : ``
    }
    ${
      fields.slate
        ? `
      slate {
        ${slateGQLFields(fields.slate)}
      }
    `
        : ``
    }
  `
}
