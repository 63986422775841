import type { BlockUpdatesDto } from '../dtos/block-updates.dto.js'
import type { BlockDto } from '../dtos/block.dto.js'
import type { RawBlockUpdatesDto } from '../dtos/raw-block-updates.dto.js'
import type { RawBlockDto } from '../dtos/raw-block.dto.js'
import type { RawSlateUpdatesDto } from '../dtos/raw-slate-updates.dto.js'
import type { RawSlateDto } from '../dtos/raw-slate.dto.js'
import type { SlateUpdatesDto } from '../dtos/slate-updates.dto.js'
import type { SlateDto } from '../dtos/slate.dto.js'
import { equals } from './equality.utils.js'
import { validateRawSlateUpdates } from './validation.utils.js'

const removeUndefinedKeys = <T>(obj: T): Partial<T> => {
  const newObj = {}
  Object.assign(newObj, obj)
  Object.keys(newObj).forEach(key => !newObj[key] && delete newObj[key])
  if (Object.keys(newObj).length === 0) {
    return null
  }
  return newObj
}

const objectToJson = (object): string => {
  if (typeof object === 'string') {
    return object
  }
  if (!object || Object.keys(object).length <= 0) {
    return undefined
  }
  try {
    return JSON.stringify(object)
  } catch {
    return undefined
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const arrayToJson = (object: any[]): string => {
  if (typeof object === 'string') {
    return object
  }
  if (!object || Object.length <= 0) {
    return undefined
  }
  try {
    return JSON.stringify(object)
  } catch {
    return undefined
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const jsonToObject = (json: string): Record<string, any> => {
  if (!json) {
    return {}
  }
  if (typeof json !== 'string') {
    return json
  }
  try {
    return JSON.parse(json)
  } catch {
    return {}
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const jsonToArray = (json: string): any[] => {
  if (!json) {
    return []
  }
  if (typeof json !== 'string') {
    return json
  }
  try {
    return JSON.parse(json)
  } catch {
    return []
  }
}

export const rawBlockToDto = (rawBlock: RawBlockDto): BlockDto => {
  if (!rawBlock) {
    return null
  }
  return {
    ...rawBlock,
    props: objectToJson(rawBlock.props),
    extraProps: objectToJson(rawBlock.extraProps),
    children: arrayToJson(rawBlock.children),
    output: objectToJson(rawBlock.output),
  }
}

export const blockDtoToRaw = (block: BlockDto): RawBlockDto => {
  if (!block) {
    return null
  }
  return {
    ...block,
    props: jsonToObject(block.props),
    extraProps: jsonToObject(block.extraProps),
    children: jsonToArray(block.children),
    output: jsonToObject(block.output),
  }
}

export const rawBlockUpdatesToDto = (
  rawBlockUpdates: RawBlockUpdatesDto,
): BlockUpdatesDto => {
  const result = rawBlockToDto(rawBlockUpdates as RawBlockDto)
  return removeUndefinedKeys(result) as BlockUpdatesDto
}

export const blockUpdatesDtoToRaw = (
  blockUpdates: BlockUpdatesDto,
): RawBlockUpdatesDto => {
  if (!blockUpdates) {
    return null
  }
  return {
    ...blockUpdates,
    props: blockUpdates.props ? jsonToObject(blockUpdates.props) : undefined,
    extraProps: blockUpdates.extraProps
      ? jsonToObject(blockUpdates.extraProps)
      : undefined,
    children: blockUpdates.children
      ? jsonToArray(blockUpdates.children)
      : undefined,
    output: blockUpdates.output ? jsonToObject(blockUpdates.output) : undefined,
  }
}

export const rawSlateToDto = (rawSlate: RawSlateDto): SlateDto => {
  if (!rawSlate) {
    return null
  }
  return {
    ...rawSlate,
    blocks: rawSlate.blocks.map(c => rawBlockToDto(c)),
  }
}

export const slateDtoToRaw = (slate: SlateDto): RawSlateDto => {
  if (!slate) {
    return null
  }
  return {
    ...slate,
    blocks: slate.blocks.map(c => blockDtoToRaw(c)),
  }
}

export const rawSlateUpdatesToDto = (
  rawSlateUpdates: RawSlateUpdatesDto,
): SlateUpdatesDto => {
  if (!rawSlateUpdates) {
    return null
  }
  const result = {
    ...rawSlateUpdates,
    updatedBlocks: rawSlateUpdates.updatedBlocks?.map(c =>
      rawBlockUpdatesToDto(c),
    ),
    addedBlocks: rawSlateUpdates.addedBlocks?.map(c => rawBlockToDto(c)),
  }
  return removeUndefinedKeys(result)
}

export const slateUpdatesDtoToRaw = (
  slateUpdates: SlateUpdatesDto,
): RawSlateUpdatesDto => {
  if (!slateUpdates) {
    return null
  }
  const result = {
    ...slateUpdates,
    updatedBlocks: slateUpdates.updatedBlocks?.map(c =>
      blockUpdatesDtoToRaw(c),
    ),
    addedBlocks: slateUpdates.addedBlocks?.map(c => blockDtoToRaw(c)),
  }
  return removeUndefinedKeys(result)
}

export const applyRawSlateUpdates = (
  slate: RawSlateDto,
  updates: RawSlateUpdatesDto,
  validate = true,
): RawSlateDto => {
  if (!slate) {
    return null
  }
  if (!updates) {
    return slate
  }
  if (validate) {
    validateRawSlateUpdates(slate, updates)
  }

  const {
    rootBlock,
    updatedBlocks = [],
    addedBlocks = [],
    removedBlocks = [],
  } = updates
  const updatedBlocksById: Record<string, RawBlockUpdatesDto> =
    updatedBlocks.reduce((pre, value) => ({ ...pre, [value.id]: value }), {})
  const newRootBlock = rootBlock || slate.rootBlock
  const newBlocks: RawBlockDto[] = [
    ...slate.blocks.map(block => {
      const { children, output, props, extraProps } =
        updatedBlocksById[block.id] || {}
      return {
        id: block.id,
        name: block.name,
        children: typeof children !== 'undefined' ? children : block.children,
        props: typeof props !== 'undefined' ? props : block.props,
        extraProps:
          typeof extraProps !== 'undefined' ? extraProps : block.extraProps,
        output: typeof output !== 'undefined' ? output : block.output,
      }
    }),
    ...addedBlocks,
  ]
    .filter(block => !removedBlocks.includes(block.id))
    .map(block => ({
      ...block,
      children: block.children
        ? block.children.filter(id => !removedBlocks.includes(id))
        : undefined,
    }))

  const newBlocksById: Record<string, RawBlockDto> = newBlocks.reduce(
    (pre, value) => ({ ...pre, [value.id]: value }),
    {},
  )
  let activeBlocks = [newRootBlock]
  let index = 0
  while (index < activeBlocks.length) {
    const blockId = activeBlocks[index]
    index += 1
    const { children = [] } = newBlocksById[blockId] || {}
    activeBlocks = activeBlocks.concat(
      // eslint-disable-next-line no-loop-func
      children.filter(childId => !activeBlocks.includes(childId)),
    )
  }

  const activateBlockSet = new Set(activeBlocks)
  return {
    ...slate,
    rootBlock: newRootBlock,
    blocks: newBlocks.filter(block => {
      const isActive = activateBlockSet.has(block.id)
      if (isActive) {
        activateBlockSet.delete(block.id)
      }
      return isActive
    }),
  }
}

export const mergeSlateUpdates = (
  slate: RawSlateDto,
  oldChanges: RawSlateUpdatesDto,
  newChanges: RawSlateUpdatesDto,
): RawSlateUpdatesDto => {
  const {
    rootBlock: oldRootBlock = undefined,
    updatedBlocks: oldUpdatedBlocks = [],
    addedBlocks: oldAddedBlocks = [],
    removedBlocks: oldRemovedBlocks = [],
  } = oldChanges
  const {
    rootBlock: newRootBlock = undefined,
    updatedBlocks: newUpdatedBlocks = [],
    addedBlocks: newAddedBlocks = [],
    removedBlocks: newRemovedBlocks = [],
  } = newChanges

  const originalBlocks: Record<string, RawBlockDto> = slate.blocks.reduce(
    (pre, value) => ({ ...pre, [value.id]: value }),
    {},
  )
  const finalUpdatedBlocks = oldUpdatedBlocks
  const oldUpdatedBlockIds = oldUpdatedBlocks.map(block => block.id)
  const oldAddedBlockIds = oldAddedBlocks.map(block => block.id)

  newUpdatedBlocks.forEach(block => {
    if (oldUpdatedBlockIds.includes(block.id)) {
      const index = oldUpdatedBlockIds.indexOf(block.id)
      finalUpdatedBlocks[index] = { ...finalUpdatedBlocks[index], ...block }
    } else if (oldAddedBlockIds.includes(block.id)) {
      const index = oldAddedBlockIds.indexOf(block.id)
      oldAddedBlocks[index] = { ...oldAddedBlocks[index], ...block }
    } else {
      finalUpdatedBlocks.push(block)
    }
  })
  const removedIds = new Set([...oldRemovedBlocks, ...newRemovedBlocks])

  const rootBlock = newRootBlock || oldRootBlock
  const updatedBlocks = finalUpdatedBlocks
    .filter(b => !removedIds.has(b.id))
    .filter(
      b => !equals(originalBlocks[b.id], { ...originalBlocks[b.id], ...b }),
    )
  const addedBlocks = [...oldAddedBlocks, ...newAddedBlocks].filter(
    b => !removedIds.has(b.id) && !originalBlocks[b.id],
  )
  const removedBlocks = [...removedIds].filter(id => !!originalBlocks[id])

  return {
    rootBlock: slate.rootBlock !== rootBlock ? rootBlock : undefined,
    updatedBlocks: updatedBlocks.length > 0 ? updatedBlocks : undefined,
    addedBlocks: addedBlocks.length > 0 ? addedBlocks : undefined,
    removedBlocks: removedBlocks.length > 0 ? removedBlocks : undefined,
  }
}
