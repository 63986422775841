import { CustomSpaceFields } from '@tribeplatform/gql-client/graphql/space'
import { StaticField, View } from '@tribeplatform/react-components/Views'

interface GenerateActiveFieldIds {
  staticFields: StaticField[]
  style: View['style']
}
const getActiveFields = ({
  staticFields,
  style,
}: GenerateActiveFieldIds): StaticField[] => {
  const output = staticFields
    .filter(
      ({ enabled, styles, isStatic }) =>
        enabled && isStatic && !(styles && !styles.includes(style)),
    )
    .reduce<StaticField[]>((result, field) => {
      if (field?.fields) {
        return [
          ...result,
          ...getActiveFields({ staticFields: field.fields, style }),
        ]
      }

      return [...result, field]
    }, [])

  return output
}

interface Props {
  staticFields: StaticField[]
  style: View['style']
}
export const generateGqlFields = ({
  staticFields,
  style,
}: Props): CustomSpaceFields => {
  const activeFields = getActiveFields({ staticFields, style })

  const spaceFields = activeFields.reduce((result, field) => {
    switch (field.id) {
      case 'listImage':
      case 'galleryBanner':
      case 'cardBanner':
        return {
          ...result,
          image: 'basic',
          banner: 'basic',
        } as CustomSpaceFields
      case 'postsCount':
        return { ...result, postsCount: 'basic' } as CustomSpaceFields
      case 'joinButton':
        return {
          ...result,
          authMemberProps: { permissions: 'basic' },
        } as CustomSpaceFields
      default:
        return result
    }
  }, {} as CustomSpaceFields)

  return spaceFields
}
