import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/block.types.js'
import { SectionBlock } from './Block.js'
import { SECTION_BLOCK_NAME } from './constants.js'
import { SectionBlockSettings } from './Settings.js'
import { SectionLayout, Spacing, type SectionProps } from './types.js'
import { sectionLayoutDescriptionMapper } from './utils/selectItems.js'

export const RegisteredSectionBlock = (
  intl: I18nShape,
): RegisteredBlock<SectionProps> => {
  const { $t } = intl
  const layoutDescriptionMap = sectionLayoutDescriptionMapper(intl)

  return {
    recommendedName: SECTION_BLOCK_NAME,
    Component: SectionBlock,
    config: {
      type: BlockType.Layout,
      Icon: <SvgIcon name="section" />,
      displayName: $t({
        id: 'Blocks.Section.DisplayName',
        defaultMessage: 'Section',
      }),
      usable: true,
      fullSidebarSettings: true,
      Settings: SectionBlockSettings,
      acceptsChildren: true,
      lockedChildren: true,
      initialProps: {
        layout: SectionLayout.OneVTwo,
        gap: Spacing.Medium,
        horizontalPadding: Spacing.None,
        verticalPadding: Spacing.None,
        horizontalMargin: Spacing.None,
        verticalMargin: Spacing.None,
      },
      getContextAwareConfig: ({ props: { layout } }) => ({
        description: layoutDescriptionMap[layout],
      }),
    },
    meta: {
      releaseDate: '2024-08-19',
      releaseMessage: $t({
        id: 'Blocks.Section.ReleaseMessage',
        defaultMessage:
          'A powerful block for building flexible, grid-based layouts',
      }),
    },
  }
}

export * from './Column/registered.js'
