import React from 'react'

import { SvgIcon } from '../Icon/SvgIcon.js'
import { IconButton, IconButtonProps } from './IconButton.js'

export type CloseButtonProps = Omit<IconButtonProps, 'icon'>

export const CloseButton = ({
  accessibilityLabel,
  ...rest
}: CloseButtonProps) => {
  return (
    <IconButton
      variant="tertiaryNeutral"
      icon={<SvgIcon name="close" />}
      aria-label={accessibilityLabel ?? 'Close'}
      {...rest}
    />
  )
}
