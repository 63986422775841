import { gql } from 'graphql-tag'

import { SpaceFields, spaceGQLFields } from '../space/index.js'

export const privateMessageGQLQuery = (fields: SpaceFields) => gql`
  query privateMessage($input: PrivateMessageInput!) {
    privateMessage(input: $input) {
      ${spaceGQLFields(fields)}
    }
  }
`
