import type { UnknownCompiledBlock } from '../../types/block.types.js'
import { NEW_BLOCK_PLACEHOLDER_ID } from '../PlaceholderBlock/constants.js'
import type { SlateExtraContext } from '../types.js'

interface Params {
  block: UnknownCompiledBlock
  context: SlateExtraContext
}

/**
 * Returns an array of the children to render with the new block placeholder
 * if the block is activated for adding a new block
 */
export const getChildrenToRender = ({
  context: { activeBlock, activeBlockMode, newBlockPosition },
  block: { id, children = [] },
}: Params) => {
  if (activeBlock !== id || activeBlockMode !== 'add') {
    return children
  }

  // If newBlockPosition is undefined it means the user has
  // clicked the add button of the slate tree item on the left sidebar
  // so adding the new block as the last child
  if (newBlockPosition === undefined) {
    return [...children, NEW_BLOCK_PLACEHOLDER_ID]
  }

  return [
    ...children.slice(0, newBlockPosition),
    NEW_BLOCK_PLACEHOLDER_ID,
    ...children.slice(newBlockPosition),
  ]
}
