import {
  CustomFieldType,
  MemberListFilterByOperator,
} from '@tribeplatform/gql-client/types'
import {
  CustomFieldSubtype,
  getFieldSetting,
} from '@tribeplatform/react-components/CustomField'

import { TableFilterField } from './types.js'

export const getDefaultOperator = (field: TableFilterField) => {
  const subtype = field.subtype || getFieldSetting(field, 'subtype')
  const defaultOperator = {
    [CustomFieldSubtype.SELECT]: MemberListFilterByOperator.equals,
    [CustomFieldType.date]: MemberListFilterByOperator.gt,
    [CustomFieldType.text]: MemberListFilterByOperator.contains,
    [CustomFieldType.array]: MemberListFilterByOperator.contains,
  }
  return (
    defaultOperator[subtype] ||
    defaultOperator[field?.type] ||
    MemberListFilterByOperator.equals
  )
}
