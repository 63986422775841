import {
  Badge as BadgeType,
  MemberBadge as MemberBadgeType,
} from '@tribeplatform/gql-client/types'
import { Badge, BadgeSize } from '@tribeplatform/react-ui-kit/Badge'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

import { EntityImage } from '../common/components/EntityImage.js'
import { logger } from '../common/lib/logger.js'

export enum MemberBadgeLocation {
  PostMemberBar = 'PostMemberBar',
  ProfileName = 'ProfileName',
  MembersList = 'MembersList',
}

export const getBadgeSetting = (badge: BadgeType, key: string) => {
  if (!badge?.settings) {
    return null
  }
  const property = badge?.settings?.find(prop => prop.key === key)
  if (!property) {
    return null
  }
  try {
    return JSON.parse(property?.value)
  } catch (err) {
    logger.error('Could not read extra property of badge', err)
  }
}

export const MemberBadge = ({
  badge,
  memberBadge,
  size = 'sm',
  showTooltip = false,
  className,
}: {
  badge?: BadgeType
  memberBadge?: MemberBadgeType
  size?: BadgeSize
  showTooltip?: boolean
  className?: string
}) => {
  if (!badge && memberBadge.badge) {
    badge = memberBadge.badge
  }

  const style = getBadgeSetting(badge, 'style')

  if (style === 'image' && badge?.image) {
    const badgeComponent = (
      <EntityImage entity={badge} size="lg" className={className} />
    )
    if (!showTooltip || !badge?.shortDescription) {
      return badgeComponent
    }
    return (
      <Tooltip>
        <Tooltip.Trigger>{badgeComponent}</Tooltip.Trigger>
        <Tooltip.Panel>{badge.shortDescription}</Tooltip.Panel>
      </Tooltip>
    )
  }

  return (
    <Badge
      className={className}
      size={size}
      style={{
        backgroundColor:
          badge.backgroundColor || 'var(--bm-color-surface-neutral)',
        color: badge.textColor || 'var(--bm-color-content)',
      }}
      truncate
      maxWidth="md"
      tooltip={showTooltip && badge?.shortDescription}
    >
      {memberBadge?.text || badge?.text || badge?.name}
    </Badge>
  )
}
