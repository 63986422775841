import { useFormContext } from 'react-hook-form'

import {
  FormControl,
  FormControlCheckboxProps,
} from '@tribeplatform/react-ui-kit/FormControl'

import { FormFieldProps } from './typings.js'

export function Checkbox({
  name,
  validation,
  ...rest
}: FormFieldProps<FormControlCheckboxProps>) {
  const { register, formState } = useFormContext()
  const error = formState.errors[name]

  return (
    <FormControl.Checkbox
      name={name}
      invalid={!!error?.message}
      error={error?.message as string}
      {...register(name, validation)}
      {...rest}
    />
  )
}
