import { MemberIdPicker } from '../../EntityPicker/MemberIdPicker.js'
import { MemberPickerProps } from '../../EntityPicker/MemberPicker.js'
import { MemberPickerFromIds } from '../../EntityPicker/MemberPickerFromIds.js'
import { useI18n } from '../../i18n/index.js'
import type { Filter } from '../types.js'
import { InfiniteSpreadMemberPicker } from './entityPickers/InfiniteSpreadMemberPicker.js'
import { InlineMemberIdPicker } from './entityPickers/InlineMemberIdPicker.js'
import { InlineMemberPickerFromIds } from './entityPickers/InlineMemberPickerFromIds.js'
import { SpreadMemberPicker } from './entityPickers/SpreadMemberPicker.js'

interface Props {
  spread: boolean
  options: string[]
  value: string[]
  inline: boolean
  memberGroup?: MemberPickerProps['memberGroup']
  className?: string
  onChange: (value: Filter['value']) => void
}
export const RelationMemberPicker = ({
  spread,
  options,
  value,
  inline,
  className,
  memberGroup,
  onChange,
}: Props) => {
  const { $t } = useI18n()

  if (!inline) {
    return options ? (
      <MemberPickerFromIds
        value={value}
        options={options}
        onChange={onChange}
        placeholder={$t({
          defaultMessage: 'Select {MEMBERS}',
          id: 'Filters.Values.Members.Placeholder',
        })}
        className={className}
        noWrapper
      />
    ) : (
      <MemberIdPicker
        value={value}
        onChange={onChange}
        placeholder={$t({
          defaultMessage: 'Select {MEMBERS}',
          id: 'Filters.Values.Members.Placeholder',
        })}
        memberGroup={memberGroup}
        className={className}
        addLoggedInMember
        multiple
        noWrapper
      />
    )
  }

  if (spread) {
    return options ? (
      <SpreadMemberPicker value={value} options={options} onChange={onChange} />
    ) : (
      <InfiniteSpreadMemberPicker
        value={value}
        onChange={onChange}
        memberGroup={memberGroup}
      />
    )
  }

  return options ? (
    <InlineMemberPickerFromIds
      value={value}
      options={options}
      className={className}
      onChange={onChange}
    />
  ) : (
    <InlineMemberIdPicker
      value={value}
      memberGroup={memberGroup}
      className={className}
      onChange={onChange}
    />
  )
}
