import type { ClientError } from '@tribeplatform/gql-client/lib'
import { Action, MutationSubscribeArgs } from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getMemberKey } from '../../utils/keys/member.key.js'
import { getPostKey } from '../../utils/keys/post.key.js'
import { invalidateSpaceQueries } from '../../utils/space.utils.js'
import { useSpaceUtil } from '../utils/useSpaceUtil.js'

type SubscribeArgs = MutationSubscribeArgs & {
  action: 'subscribe' | 'unsubscribe'
  publisherType: 'space' | 'post' | 'member'
}

export const useUpdateSubscription = (options?: {
  useMutationOptions?: UseMutationOptions<Action, ClientError, SubscribeArgs>
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()
  const spaceUtils = useSpaceUtil()

  return useMutation<Action, ClientError, SubscribeArgs>(
    ({ action, publisherId }) =>
      action === 'subscribe'
        ? client.mutation({
            name: 'subscribe',
            args: { fields: 'all', variables: { publisherId } },
          })
        : client.mutation({
            name: 'unsubscribe',
            args: { fields: 'all', variables: { publisherId } },
          }),
    {
      onSettled: (data, error, { publisherType, publisherId }) => {
        switch (publisherType) {
          case 'space': {
            invalidateSpaceQueries({
              queryClient,
              spaceId: publisherId,
              spaceUtils,
            })
            return undefined
          }

          case 'post': {
            const postKey = getPostKey({ variables: { id: publisherId } })
            queryClient.invalidateQueries(postKey)
            return undefined
          }

          case 'member': {
            const memberKey = getMemberKey({ variables: { id: publisherId } })
            queryClient.invalidateQueries(memberKey)
            return undefined
          }

          default: {
            const _exhaustiveCheck: never = publisherType
            return _exhaustiveCheck
          }
        }
      },
      ...useMutationOptions,
    },
  )
}
