import { useEffect } from 'react'

import { clsx } from 'clsx'

import { getContainerMaxSize } from '@tribeplatform/react-ui-kit/Layout'
import { useScrollLockManager } from '@tribeplatform/react-ui-kit/ScrollLock'
import { TrapFocus } from '@tribeplatform/react-ui-kit/TrapFocus'

import type { MenuItem } from '../types.js'
import { SubMenuItem } from './SubMenuItem.js'

interface Props {
  items: MenuItem[]
  activeItemId?: string
  isPreviewMode?: boolean
  close: () => void
}
export const SubMenu = ({
  items,
  activeItemId,
  isPreviewMode,
  close,
}: Props) => {
  const scrollLockManager = useScrollLockManager()

  useEffect(() => {
    scrollLockManager.registerScrollLock()

    return () => {
      scrollLockManager.unregisterScrollLock()
    }
  }, [scrollLockManager])

  return (
    <TrapFocus
      trapping={!isPreviewMode}
      className={clsx(
        'flex justify-center absolute bg-topbar w-screen xl:py-8 lg:py-4 z-[60] top-[var(--c-header-height)] shadow-md',
      )}
      onClick={e => e.stopPropagation()}
    >
      <div
        className={clsx(
          'w-full hidden lg:grid grid-cols-3 gap-4',
          getContainerMaxSize('xl'),
        )}
      >
        {items.map(item => (
          <SubMenuItem
            key={item.id}
            active={activeItemId === item.id}
            onClick={e => {
              if (isPreviewMode) {
                e.preventDefault()
              }

              close()
            }}
            {...item}
          />
        ))}
      </div>
    </TrapFocus>
  )
}
