import { useEffect } from 'react'

import { Helmet } from 'react-helmet-async'
import { Navigate, useNavigate } from 'react-router-dom'

import {
  ClientError,
  isForbidden,
  isNotFound,
} from '@tribeplatform/gql-client/lib'
import { useCommandBarSetContext } from '@tribeplatform/react-components/CommandBar'
import { useDomain } from '@tribeplatform/react-components/common/hooks'
import {
  getParentPostId,
  getPostIdFromPath,
} from '@tribeplatform/react-components/common/utils'
import { useRouterPath } from '@tribeplatform/react-sdk'
import {
  useAuthToken,
  usePost,
  useSpaceUtil,
} from '@tribeplatform/react-sdk/hooks'
import { SlateRenderer } from '@tribeplatform/react-slate-kit/components'
import { useSlate } from '@tribeplatform/react-slate-kit/hooks'

import { useAnalytics } from '../../hooks/useAnalytics.js'
import { PostNotFound } from '../SinglePostBlock/components/PostNotFound.js'

export const Post = ({ spacePath, postPath }) => {
  const { context: slateContext } = useSlate()
  const {
    data: { network },
  } = useAuthToken()
  const { setCommandBarContext, unsetCommandBarContext } =
    useCommandBarSetContext()
  const { generatePostPath } = useSpaceUtil()
  const navigate = useNavigate()
  const pathname = useRouterPath()
  const { getResourceUrl } = useDomain()
  const id = postPath ? getPostIdFromPath(postPath) : ''
  const {
    data: post,
    isLoading,
    error: _error,
  } = usePost({
    variables: { id },
    fields: 'default',
    useQueryOptions: {
      refetchOnMount: 'always',
      retry: false,
      enabled: !!id,
    },
  })

  const error = _error as ClientError
  const space = post?.space
  const postSpacePath = space?.address?.path
  const analytics = useAnalytics({
    target: {
      postId: post?.id,
      spaceId: space?.id,
      spaceType: space?.type,
      postTypeId: post?.postType?.id,
    },
  })

  const title = `${post?.title} - ${network?.name}`

  useEffect(() => {
    if (!post?.id) {
      return undefined
    }
    analytics.trackPageView(title)
    setCommandBarContext({ entity: post, entityType: 'post' })

    return () => unsetCommandBarContext({ entity: post, entityType: 'post' })
  }, [title, post, analytics, setCommandBarContext, unsetCommandBarContext])

  useEffect(() => {
    if (postSpacePath && spacePath !== postSpacePath) {
      navigate(post?.relativeUrl, { replace: true })
      return
    }

    // Check to replace the url with the full post url after redirecting to the root post id.
    if (post) {
      const parentPostId = getParentPostId(post)
      const postPath = post?.relativeUrl
      if (!parentPostId && postPath !== pathname) {
        navigate(postPath, { replace: true })
      }
    }
  })

  if (post && space) {
    const parentPostId = getParentPostId(post)
    if (parentPostId) {
      return <Navigate to={generatePostPath(space, { id: parentPostId })} />
    }
  }

  if (!post && !isLoading && error) {
    if (isNotFound(error) || isForbidden(error)) {
      return <PostNotFound />
    }
  }

  const postType = network?.postTypes?.find(
    postType => postType.id === post?.postTypeId,
  )

  const canonicalUrl = post?.customSeoDetail?.canonicalUrl

  return (
    <>
      <Helmet>
        <link
          rel="alternate"
          type="application/rss+xml"
          title={post?.title?.substring(0, 120)}
          href={getResourceUrl(`/rss/post/${post?.id}/replies`)}
        />
        {canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null}
      </Helmet>
      <SlateRenderer
        slate={postType?.slate}
        context={{ ...slateContext, postId: post?.id }}
      />
    </>
  )
}
