import {
  Children,
  ComponentProps,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  Placement,
  arrow,
} from '@floating-ui/react'
import { clsx } from 'clsx'

import { AnimatePortal } from '../animation/AnimatePortal.js'
import { useDocument } from '../hooks/useDocument.js'
import { FloatingFullContext } from '../utils/floating/index.js'
import { findFirstFocusableNodeIncludingDisabled } from '../utils/focus.js'
import { useUniqueId } from '../utils/unique-id.js'
import { PopoverContext, usePopover } from './PopoverContext.js'
import { PopoverPanel } from './PopoverPanel.js'
import {
  PopoverAutofocusTarget,
  PopoverCloseSource,
  PopoverPadding,
} from './types.js'
import { setActivatorAttributes } from './utils.activator.js'

export type PopoverProps = {
  /** The content to display inside the popover */
  children: ReactNode

  className?: string

  delayShow?: boolean | number

  /**
   * Whether popover is shown by default
   * @default false
   */
  defaultVisible?: boolean

  /** Show or hide the Popover */
  open: boolean
  /** The element to activate the Popover */
  activator: ReactElement

  /** Callback when popover is closed */
  onClose(source: PopoverCloseSource): void

  /**
   * The element type to wrap the activator with
   * @default 'div'
   */
  activatorWrapper?: string
  /** The className for the activator wrapper element */
  wrapperClassName?: string

  /**
   * The preferred auto focus target defaulting to the popover container
   * @default 'container'
   */
  autofocusTarget?: PopoverAutofocusTarget
  /** Prevents focusing the activator or the next focusable element when the popover is deactivated */
  preventFocusOnClose?: boolean

  /**
   * Allow popover content to determine the overlay width and height
   * @default true
   */
  fluidContent?: boolean
  /** Remains in a fixed position */
  fixed?: boolean

  roundedStyleButton?: boolean

  /**
   *
   */
  padding?: PopoverPadding
  /**
   * see https://floating-ui.com/docs/computePosition#placement
   */
  placement?: Placement
  allowPanelOverflow?: boolean
  arrow?: boolean
  autoPosition?: boolean
}

/**
 * Popovers are perfect for floating panels with arbitrary content like navigation menus, mobile menus and flyout menus.
 */
export const Popover = ({
  children,
  padding = 'md',
  placement = 'bottom',
  activator,
  activatorWrapper = 'div',
  wrapperClassName = '',
  open,
  onClose,
  preventFocusOnClose,
  fixed,
  allowPanelOverflow = true,
  autoPosition = true,
  ...rest
}: PopoverProps) => {
  const { document } = useDocument()
  const [activatorNode, setActivatorNode] = useState<HTMLElement>()

  const activatorContainer = useRef<HTMLElement>(null)
  const arrowRef = useRef(null)

  /**
   * Casted to `any` by design
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WrapperComponent = activatorWrapper as any
  const id = useUniqueId('popover')

  const { context } = useFloating({
    placement,
    open,
    onOpenChange: open => {
      if (!open) {
        onClose(PopoverCloseSource.Click)
      }
    },
    ...(autoPosition ? { whileElementsMounted: autoUpdate } : {}),
    strategy: fixed ? 'fixed' : undefined,
    middleware: [
      offset(8),
      shift({ padding: 16 }),
      flip({
        fallbackStrategy: 'initialPlacement',
        fallbackAxisSideDirection: 'end',
        padding: 5,
      }),
      arrow({
        element: arrowRef,
      }),
    ],
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context),
  ])

  const setAccessibilityAttributes = useCallback(() => {
    if (activatorContainer.current == null) {
      return
    }

    const firstFocusable = findFirstFocusableNodeIncludingDisabled(
      document,
      activatorContainer.current,
    )
    const focusableActivator: HTMLElement & {
      disabled?: boolean
    } = firstFocusable || activatorContainer.current

    const activatorDisabled =
      'disabled' in focusableActivator && Boolean(focusableActivator.disabled)

    setActivatorAttributes(focusableActivator, {
      id,
      active: open,
      activatorDisabled,
      ariaHaspopup: 'true',
    })
  }, [document, id, open])

  const handleClose = (source: PopoverCloseSource) => {
    onClose(source)
    if (activatorContainer.current == null || preventFocusOnClose) {
      return
    }

    if (
      (source === PopoverCloseSource.FocusOut ||
        source === PopoverCloseSource.EscapeKeypress) &&
      activatorNode
    ) {
      const focusableActivator =
        findFirstFocusableNodeIncludingDisabled(document, activatorNode) ||
        findFirstFocusableNodeIncludingDisabled(
          document,
          activatorContainer.current,
        ) ||
        activatorContainer.current
      // to maintain consistent focus management with Dropdown, focus is kept at activator element
      // if (!focusNextFocusableNode(focusableActivator, isInPortal)) {
      focusableActivator.focus()
      // }
    }
  }
  useEffect(() => {
    if (!activatorNode && activatorContainer.current) {
      setActivatorNode(
        activatorContainer.current.firstElementChild as HTMLElement,
      )
    } else if (
      activatorNode &&
      activatorContainer.current &&
      !activatorContainer.current.contains(activatorNode)
    ) {
      setActivatorNode(
        activatorContainer.current.firstElementChild as HTMLElement,
      )
    }
    setAccessibilityAttributes()
  }, [activatorNode, setAccessibilityAttributes])

  useEffect(() => {
    if (activatorNode && activatorContainer.current) {
      const element = activatorContainer.current
        .firstElementChild as HTMLElement
      setActivatorNode(element)
      context.refs.setReference(element)
    }
    setAccessibilityAttributes()
  }, [activatorNode, context.refs, setAccessibilityAttributes])

  const portal = activatorNode ? (
    <AnimatePortal idPrefix="popover" open={open}>
      <PopoverPanel
        id={id}
        onClose={handleClose}
        allowOverflow={allowPanelOverflow}
        {...rest}
      >
        {children}
      </PopoverPanel>
    </AnimatePortal>
  ) : null

  return (
    <PopoverContext.Provider
      value={{
        padding,
      }}
    >
      <FloatingFullContext.Provider
        value={{
          context,
          getReferenceProps,
          getFloatingProps,
          arrowRef,
        }}
      >
        <WrapperComponent ref={activatorContainer} className={wrapperClassName}>
          {Children.only(activator)}
          {portal}
        </WrapperComponent>
      </FloatingFullContext.Provider>
    </PopoverContext.Provider>
  )
}

type PopoverContentProps = ComponentProps<'div'>

const PopoverContent: FC<PopoverContentProps> = props => {
  const { children, className, ...rest } = props
  const { padding } = usePopover()

  return (
    <div
      className={clsx([
        'relative',
        padding === 'sm' && 'px-2 py-3 sm:px-3',
        padding === 'md' && 'px-4 py-5 sm:p-6 ',
        padding === 'lg' && 'px-5 py-6 sm:p-8',
        className,
      ])}
      {...rest}
    >
      {children}
    </div>
  )
}

type PopoverFooterProps = ComponentProps<'div'>

const PopoverFooter: FC<PopoverFooterProps> = props => {
  const { children, className, ...rest } = props
  const { padding } = usePopover()

  return (
    <div
      className={clsx([
        'bg-surface-subdued',
        padding === 'sm' && 'px-2 py-3 sm:px-3',
        padding === 'md' && 'px-4 py-5 sm:p-6 ',
        padding === 'lg' && 'px-5 py-5 sm:px-8',
        className,
      ])}
      {...rest}
    >
      {children}
    </div>
  )
}

Popover.Content = PopoverContent
Popover.Footer = PopoverFooter
