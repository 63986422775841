import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  lazy,
  Suspense,
} from 'react'

import { Editor } from '@tiptap/react'
import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { inputStyles } from '@tribeplatform/react-ui-kit/Input'

import { memberToMentionConverter } from '../common/utils/composer.js'
import { getPostFieldValue } from '../common/utils/post.js'
import { AttachmentsProps, Mention } from '../Composer/@types/index.js'
import { useComposer } from '../Composer/hooks/useComposer.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { Prompt } from '../Prompt/index.js'
import {
  UploadQueueProvider,
  useUploadQueue,
} from '../Providers/UploadQueueProvider.js'

const Composer = lazy(() =>
  import('../Composer/Composer.js').then(m => ({ default: m.Composer })),
)
const ComposerControls = lazy(() =>
  import('../Composer/components/ComposerControls.js').then(m => ({
    default: m.ComposerControls,
  })),
)
const ComposerProvider = lazy(() =>
  import('../Composer/ComposerProvider.js').then(m => ({
    default: m.ComposerProvider,
  })),
)

export type ReplyEditorRef = {
  focus: () => void
}
export type ReplyEditorProps = {
  onReply: (content: string, editor: Editor) => void
  onCancel: (isTouched: boolean) => void
  reply?: Post
  isLoading?: boolean
  cachedMentions?: Mention[]
} & Pick<AttachmentsProps, 'attachments' | 'onAttachmentDelete'>

export const ReplyEditor = forwardRef<ReplyEditorRef, ReplyEditorProps>(
  (props, ref) => {
    const { reply, cachedMentions } = props
    const content =
      getPostFieldValue(reply, 'content') ||
      getPostFieldValue(reply, 'answer') ||
      ''

    return (
      <Suspense fallback={<></>}>
        <UploadQueueProvider>
          <ComposerProvider
            content={content}
            embeds={reply?.embeds}
            mentions={reply?.mentions?.map(memberToMentionConverter)}
            cachedMentions={cachedMentions}
          >
            <InnerReplyEditor {...props} ref={ref} />
          </ComposerProvider>
        </UploadQueueProvider>
      </Suspense>
    )
  },
)
export const InnerReplyEditor = forwardRef<ReplyEditorRef, ReplyEditorProps>(
  ({ onReply, reply, isLoading }, ref) => {
    const { $t } = useI18n()
    const { editor, isTouched } = useComposer()
    const { isUploading } = useUploadQueue()
    const isNew = !reply

    useEffect(() => {
      if (editor) {
        editor.setOptions({
          editorProps: {
            handleKeyDown: (view, event) => {
              if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
                onReply(editor.getHTML(), editor)
                return true
              }
              return false
            },
          },
        })
      }
    }, [editor, onReply])

    useImperativeHandle(ref, () => ({
      focus: () => {
        editor.commands.focus()
      },
    }))

    return (
      <div
        className={clsx(
          inputStyles(),
          'relative overflow-y-auto flex flex-col gap-3 overflow-hidden',
          'focus:outline-none focus-within:ring',
          { 'my-2': !isNew },
        )}
      >
        <div className="grow flex flex-col">
          <Suspense fallback={<></>}>
            <Composer />
          </Suspense>
        </div>
        <Suspense fallback={<></>}>
          <ComposerControls
            action={
              <Button
                loading={isLoading || isUploading}
                size="md"
                variant="primary"
                onClick={() => onReply(editor.getHTML(), editor)}
              >
                <SvgIcon
                  className="w-4 h-4 transform rtl:-scale-x-100"
                  name="send"
                />
              </Button>
            }
          />
        </Suspense>
        <Prompt
          when={isTouched}
          message={$t({
            id: 'Reply.ReplyEditor.EditorDirtyPromptText',
            defaultMessage: 'Changes you made may not be saved.',
          })}
        />
      </div>
    )
  },
)
