import type { BillingProducts, Fields } from '@tribeplatform/gql-client/types'

import {
  useQuery,
  type UseQueryOptions,
} from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getBillingProductsKey } from '../../utils/keys/billing.js'

export const useBillingProducts = (options?: {
  fields: Fields.BillingProductsFields
  useQueryOptions?: UseQueryOptions<BillingProducts>
}) => {
  const {
    useQueryOptions,
    fields = {
      plans: {
        prices: 'all',
      },
      addons: {
        prices: 'all',
        hubContent: {
          attachments: 'all',
          embeds: 'all',
          fields: {
            relationEntities: {
              medias: 'all',
            },
          },
          images: 'all',
          thumbnail: 'all',
        },
        app: {
          image: 'all',
        },
      },
    },
  } = options || {}
  const { client } = useTribeClient()
  const billingProductsKey = getBillingProductsKey({
    fields,
  })

  return useQuery<BillingProducts>(
    billingProductsKey,
    () =>
      client.query({
        name: 'billingProducts',
        args: fields,
      }),
    useQueryOptions,
  )
}
