export const colors = {
  'action-primary': '#3c7f4e',
  'action-primary-hovered': '#235f34',
  'action-primary-pressed': '#164925',
  'action-primary-subdued': '#164925',
  'action-primary-subdued-hovered': '#235f34',
  'action-primary-subdued-pressed': '#235f34',
  'action-primary-disabled': '#404040',
  'action-neutral': '#282828',
  'action-neutral-hovered': '#404040',
  'action-neutral-pressed': '#535353',
  'action-neutral-subdued': '#282828',
  'action-neutral-subdued-hovered': '#404040',
  'action-neutral-subdued-pressed': '#535353',
  'action-neutral-disabled': '#404040',
  'action-destructive': '#bc5044',
  'action-destructive-hovered': '#90372e',
  'action-destructive-pressed': '#712821',
  'action-destructive-subdued': '#4a1712',
  'action-destructive-subdued-hovered': '#712821',
  'action-destructive-subdued-pressed': '#90372e',
  'action-destructive-disabled': '#404040',
  background: '#171717',
  'background-hovered': '#28282880',
  'background-pressed': '#282828',
  'background-selected': '#282828',
  'background-backdrop': '#00000080',
  'background-overlay': '#00000080',
  'background-divider': '#a1a1a1',
  content: '#f7f7f7',
  'content-subdued': '#d4d4d4',
  'content-muted': '#a1a1a1',
  'content-hovered': '#f3f3f3',
  'content-pressed': '#d4d4d4',
  'content-disabled': '#727272',
  'content-on-positive': '#cceed6',
  'content-on-attention': '#ffe1c3',
  'content-on-highlight': '#d8e7ff',
  'content-on-negative': '#ffdfda',
  'content-on-negative-hovered': '#ffc6bd',
  'content-on-negative-pressed': '#ffc6bd',
  'content-primary': '#eef5ef',
  'content-on-primary': '#FFFFFF',
  'content-on-inverse': '#171717',
  'content-on-destructive': '#f7f7f7',
  'content-on-interactive': '#f7f7f7',
  'content-on-background': '#f7f7f7',
  'content-on-background-subdued': '#d4d4d4',
  'content-on-background-hovered': '#f3f3f3',
  'content-on-background-pressed': '#d4d4d4',
  'content-on-background-disabled': '#727272',
  interactive: '#3c7f4e',
  'interactive-hovered': '#235f34',
  'interactive-pressed': '#164925',
  'interactive-disabled': '#171717',
  'interactive-destructive': '#bc5044',
  'interactive-destructive-hovered': '#90372e',
  'interactive-destructive-pressed': '#712821',
  'interactive-destructive-disabled': '#171717',
  topbar: '#282828',
  'topbar-subdued': '#404040',
  'content-on-topbar': '#f7f7f7',
  'line-on-topbar': '#53535380',
  'line-on-topbar-pressed': '#727272',
  link: '#ace1ba',
  'link-hovered': '#61b27c',
  'link-pressed': '#3a7f52',
  line: '#53535380',
  'line-subdued': '#404040',
  'line-hovered': '#535353',
  'line-pressed': '#727272',
  'line-selected': '#535353',
  'line-disabled': '#404040',
  'line-transparent': '#ffffff1a',
  'line-positive': '#051b0e',
  'line-positive-subdued': '#051b0e',
  'line-highlight': '#08162f',
  'line-highlight-subdued': '#08162f',
  'line-negative': '#2f0b08',
  'line-negative-subdued': '#2f0b08',
  'line-negative-disabled': '#712821',
  'line-attention': '#241304',
  'line-attention-subdued': '#241304',
  focused: '#3c7f4e',
  skeleton: '#404040',
  surface: '#282828',
  'surface-subdued': '#404040',
  'surface-hovered': '#404040',
  'surface-subdued-hovered': '#535353',
  'surface-pressed': '#535353',
  'surface-disabled': '#404040',
  'surface-selected': '#164925',
  'surface-selected-hovered': '#235f34',
  'surface-selected-pressed': '#235f34',
  'surface-attention': '#5b38144d',
  'surface-attention-subdued': '#3c220a4d',
  'surface-negative': '#7128214d',
  'surface-negative-subdued': '#4a17124d',
  'surface-negative-subdued-hovered': '#2f0b084d',
  'surface-negative-subdued-pressed': '#2f0b084d',
  'surface-positive': '#17492d4d',
  'surface-positive-subdued': '#0b2e1a4d',
  'surface-highlight': '#213f734d',
  'surface-highlight-subdued': '#12274c4d',
  'surface-highlight-subdued-hovered': '#08162f4d',
  'surface-highlight-subdued-pressed': '#08162f4d',
  'surface-neutral': '#535353',
  'surface-neutral-subdued': '#404040',
  'surface-inverse': '#f7f7f7',
  'decorative-surface-1': '#FFC96B',
  'decorative-surface-1-subdued': '#FFE4B5',
  'decorative-content-1': '#7E5700',
  'decorative-surface-2': '#FFC4B0',
  'decorative-surface-2-subdued': '#FFE1D7',
  'decorative-content-2': '#AF294E',
  'decorative-surface-3': '#92E6B5',
  'decorative-surface-3-subdued': '#C9F3DA',
  'decorative-content-3': '#006D41',
  'decorative-surface-4': '#91E0D6',
  'decorative-surface-4-subdued': '#C8EFEB',
  'decorative-content-4': '#006A68',
  'decorative-surface-5': '#FDC9D0',
  'decorative-surface-5-subdued': '#FEE4E7',
  'decorative-content-5': '#AE2B4C',
  'decorative-surface-6': '#7CD4FD',
  'decorative-surface-6-subdued': '#BDE9FE',
  'decorative-content-6': '#065986',
  'decorative-surface-7': '#BDB4FE',
  'decorative-surface-7-subdued': '#DED9FF',
  'decorative-content-7': '#4A1FB8',
}
