import { useMemo } from 'react'

import { ReportTimeFrame } from '@tribeplatform/gql-client/types'
import {
  AnalyticsFeeder,
  generateAnalyticsQuery,
  SCORE_SELECT_QUERY,
} from '@tribeplatform/react-components/Analytics/utils'
import { dayjs } from '@tribeplatform/react-components/common/lib'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

import { LeaderboardList } from './LeaderboardList.js'
import { LoadingState } from './LoadingState.js'

export interface LeaderboardTabProps {
  spaceId: string
  membersCount: number
  excludeStaff: boolean
  showScore: boolean
  timeFrame: ReportTimeFrame
}

export const LeaderboardTab = ({
  timeFrame,
  spaceId,
  membersCount,
  excludeStaff,
  showScore,
}: LeaderboardTabProps) => {
  const { data: network } = useNetwork()

  const query = useMemo(() => {
    let from = 0
    if (timeFrame === ReportTimeFrame.allTime) {
      from = dayjs(network?.createdAt ?? 0).unix() * 1000
    } else if (timeFrame === ReportTimeFrame.lastThirtyDays) {
      from = dayjs().subtract(30, 'day').unix() * 1000
    } else if (timeFrame === ReportTimeFrame.lastSevenDays) {
      from = dayjs().subtract(6, 'day').unix() * 1000
    }

    return generateAnalyticsQuery({
      selects: [
        {
          field: 'person',
          as: 'person',
        },
        {
          field: SCORE_SELECT_QUERY,
          as: 'score',
        },
      ],
      groupBy: { field: 'person' },
      order: { by: 'score', dir: 'desc' },
      timeFrame: { from, to: dayjs().unix() * 1000 },
      where: [
        { field: 'network', value: network?.id ?? '' },
        ...(spaceId ? [{ field: 'space', value: spaceId }] : []),
        { field: 'space_type', in: ['GROUP', 'BROADCAST'] },
        { field: 'person', operator: '!=', value: null },
      ],
      having: [{ field: 'score', operator: '>', value: 0 }],
      // As we cannot filter out admins and non admins with this query,
      // we fetch 40 more to make sure that the required amount of records are fetched
      // TODO: We should fix this when the query is fixed from the b/e
      limit: membersCount + 40,
    })
  }, [membersCount, network?.createdAt, network?.id, timeFrame, spaceId])

  return (
    <AnalyticsFeeder
      queries={[query]}
      fields={{ entities: { person: { profilePicture: 'all' } } }}
    >
      {({ data, isLoading }) => (
        <>
          {isLoading ? (
            <LoadingState count={membersCount} />
          ) : (
            <LeaderboardList
              data={data}
              count={membersCount}
              showScore={showScore}
              excludeStaff={excludeStaff}
            />
          )}
        </>
      )}
    </AnalyticsFeeder>
  )
}
