import type {
  CustomFieldSettings,
  CustomFieldType,
  ShortContentFormat,
  CustomReaction,
} from '@tribeplatform/gql-client/types'

import type { SortOptionId } from '../Reply/types.js'

type ViewStyles = 'gallery' | 'card' | 'list' | 'carousel' | 'magazine'

export enum StaticFieldTypes {
  avatar = 'avatar',
  banner = 'banner',
  title = 'title',
  button = 'button',
  info = 'info',
  custom = 'custom',
}
export enum LabelAlignments {
  start = 'start',
  center = 'center',
  end = 'end',
}
export enum ActionTypes {
  react = 'react',
  follow = 'follow',
  share = 'share',
}
export enum ActionVariants {
  icon = 'icon',
  text = 'text',
  iconText = 'iconText',
}
export enum ReactionActionTypes {
  adaptive = 'adaptive',
  single = 'single',
  multiple = 'multiple',
  allEmojis = 'allEmojis',
}
export enum ReactionCountPositions {
  next = 'next',
  below = 'below',
}
interface GenericActionBarAction {
  id: string
  enabled: boolean
  type: ActionTypes
  defaultLabel: string
  label: string
  variant: ActionVariants
  glyphId?: string
  color?: string
  activeLabel?: string
  activeGlyphId?: string
  activeColor?: string
}
export interface ReactAction extends GenericActionBarAction {
  type: ActionTypes.react
  reactionActionType: ReactionActionTypes
  reactions: CustomReaction[]
  isSingleReaction: boolean
  showCount?: boolean
  reactionCountPosition?: ReactionCountPositions
}
export interface FollowAction extends GenericActionBarAction {
  type: ActionTypes.follow
}
export interface ShareAction extends GenericActionBarAction {
  type: ActionTypes.share
}
export type ActionBarAction = ReactAction | FollowAction | ShareAction
export type ActionBarActionItem = ActionBarAction & {
  itemIcon: JSX.Element
}
export interface ActionBar {
  actions: ActionBarAction[]
  label?: string
  labelAlignment?: LabelAlignments
}
export interface ViewField {
  id: string
  enabled: boolean
  fields?: ViewField[]
  settings: {
    customFieldSettings?: CustomFieldSettings[]
    stretchX?: boolean
    stretchY?: boolean
    fullWidth?: boolean
    snapBottom?: boolean
    bannerStyle?: 'banner' | 'square'
    bannerSource?: string
    avatarSize?: 'md' | 'lg' | 'xl'
    titleSize?: 'xs' | 'sm' | 'md' | 'lg'
    showSpaceName?: boolean
    buttonVariant?: 'icon' | 'text' | 'iconText'
    showReactionCount?: boolean
    showReplyCount?: boolean
    showFollowCount?: boolean
    showPostOptions?: boolean
    aspectRatio?: string
    truncateSize?: 'sm' | 'md' | 'lg'
    displayStyle?: 'full' | 'truncated' | 'expandable'
    contentFormat?: ShortContentFormat
    showBackToSpaceButton?: boolean
    linkStyle?: 'link' | 'button'
    openInNewWindow?: boolean
    buttonSize?: 'sm' | 'md' | 'lg'
    showFieldName?: boolean
    actionBar?: ActionBar
    reactionCountPosition?: ReactionCountPositions
    showReplySortOptions?: boolean
    defaultReplySortId?: SortOptionId
  }
  isStatic: boolean
  isPrivate?: boolean
}

export interface StaticField extends ViewField {
  label: string
  locked: boolean
  type?: CustomFieldType | StaticFieldTypes
  styles?: ViewStyles[]
  icon?: JSX.Element
  fields?: StaticField[]
  default?: string
}

export interface View {
  id: string
  name: string
  style: ViewStyles
  gallerySize?: 'small' | 'medium' | 'large'
  showDivider?: boolean
  fields: ViewField[]
}

export type MultiViewBlockProps<T> = {
  views: T[]
  title: string
  description: string
  linkText: string
  linkUrl: string
}

export type SetProperties<T> = (props: Partial<T>) => void
interface ViewSettingsProps<T> {
  view: T
  compiledView: T
  setProperties: SetProperties<T>
  goBack: () => void
}

export type ViewSettingsComponent<T> = (
  props: ViewSettingsProps<T>,
) => JSX.Element

export type SetFieldProperty = <T extends keyof ViewField>(
  key: T,
  value: ViewField[T],
) => void

export type GetFieldSettings = <T extends keyof ViewField['settings']>(
  key: T,
) => ViewField['settings'][T]
export type SetFieldSettings = <T extends keyof ViewField['settings']>(
  key: T,
  value: ViewField['settings'][T],
) => void
export type SetActionBarSettings = <
  T extends keyof ViewField['settings']['actionBar'],
>(
  key: T,
  value: ViewField['settings']['actionBar'][T],
) => void
