import { LogoSize } from '@tribeplatform/react-components/Logo'

import type { RegisteredBlockProps } from '../../../types/block.types.js'
import { SearchFor, SearchSource, Target } from '../../HeroBanner/types.js'
import {
  ItemAlignment,
  SubNavigationPosition,
  SubMenuStyle,
  MobileMenuSectionItem,
  NavbarSectionItem,
  NavbarLayout,
  ActionItemId,
  type NavbarProps,
  type MenuItem,
} from '../types.js'
import { getSectionPreset } from './presets.js'

enum LegacyNavbarStyle {
  DEFAULT = 'default',
  STACKED = 'stacked',
}
enum LegacyNavbarNavigationAction {
  OPEN_LINK = 'OPEN_LINK',
}
type LegacyNavbarNavigationItem = {
  label: string
  action: LegacyNavbarNavigationAction
  link?: string
  openInNewWindow?: boolean
}
type LegacyNavbarProps = {
  navigationItems?: LegacyNavbarNavigationItem[]
  view?: LegacyNavbarStyle
  controlItems?: unknown[]
}

export const migrateNavbar = (
  props: RegisteredBlockProps<NavbarProps>,
): RegisteredBlockProps<NavbarProps> => {
  if (props?.version === '2') {
    return props
  }

  const legacyNavbarProps = props as LegacyNavbarProps
  const { view: legacyViewStyle, navigationItems: legacyNavigationItems = [] } =
    legacyNavbarProps
  const newProps: RegisteredBlockProps<NavbarProps> = {
    ...props,
    version: '2',
    sections: [
      {
        id: NavbarSectionItem.logo,
        enabled: true,
        stretch: false,
        alignment: ItemAlignment.center,
        logoSize: LogoSize.medium,
      },
      {
        id: NavbarSectionItem.search,
        enabled: true,
        stretch: true,
        alignment: ItemAlignment.center,
      },
      {
        id: NavbarSectionItem.menu,
        enabled: true,
        stretch: true,
        alignment: ItemAlignment.center,
        items:
          legacyViewStyle === LegacyNavbarStyle.STACKED
            ? getDefaultMenuItems('menu-section')
            : [
                ...convertToMenuItem(legacyNavigationItems),
                ...(legacyNavigationItems.length === 0
                  ? getDefaultMenuItems('menu-section')
                  : []),
              ],
      },
      {
        id: NavbarSectionItem.actions,
        enabled: true,
        stretch: false,
        alignment: ItemAlignment.center,
        items: [
          {
            id: ActionItemId.mainMenu,
            enabled: true,
          },
          {
            id: ActionItemId.createEntity,
            enabled: true,
          },
          {
            id: ActionItemId.notifications,
            enabled: true,
          },
          {
            id: ActionItemId.messages,
            enabled: true,
          },
          {
            id: ActionItemId.themeSwitch,
            enabled: true,
          },
          {
            id: ActionItemId.languageSwitch,
            enabled: false,
          },
          {
            id: ActionItemId.search,
            enabled: true,
          },
        ],
      },
    ],
    searchSettings: {
      searchFor: SearchFor.all,
      searchSource: SearchSource.allSpaces,
      spaceIds: [],
    },
    subNavigation: {
      enabled:
        legacyViewStyle === LegacyNavbarStyle.STACKED &&
        legacyNavigationItems.length > 0,
      position: SubNavigationPosition.top,
      alignment: ItemAlignment.start,
      menuItems:
        legacyViewStyle !== LegacyNavbarStyle.STACKED
          ? getDefaultMenuItems('sub-navigation')
          : [
              ...convertToMenuItem(legacyNavigationItems),
              ...(legacyNavigationItems.length === 0
                ? getDefaultMenuItems('sub-navigation')
                : []),
            ],
    },
    mobileMenuSections: [
      {
        id: MobileMenuSectionItem.sidebar,
        enabled: true,
      },
      {
        id: MobileMenuSectionItem.headerMenu,
        enabled: false,
      },
      {
        id: MobileMenuSectionItem.headerSubMenu,
        enabled: false,
      },
    ],
  }

  if (legacyViewStyle === LegacyNavbarStyle.STACKED) {
    const migratedNavbarProps: RegisteredBlockProps<NavbarProps> = {
      ...newProps,
      sections: getSectionPreset(
        newProps.sections,
        NavbarLayout.logoSearchActions,
      ),
      ...props,
    }
    return migratedNavbarProps
  }

  if (legacyNavigationItems.length > 0) {
    const migratedNavbarProps: RegisteredBlockProps<NavbarProps> = {
      ...newProps,
      sections: getSectionPreset(
        newProps.sections,
        NavbarLayout.logoMenuActions,
      ),
      ...props,
    }
    return migratedNavbarProps
  }

  const migratedNavbarProps: RegisteredBlockProps<NavbarProps> = {
    ...newProps,
    sections: getSectionPreset(
      newProps.sections,
      NavbarLayout.logoSearchActions,
    ),
    ...props,
  }
  return migratedNavbarProps
}

const getDefaultMenuItems = (suffix: string) => {
  const menuItems: MenuItem[] = [
    {
      id: `item1-${suffix}`,
      name: 'Item 1',
      url: '',
      target: Target.blank,
      subMenuStyle: SubMenuStyle.dropdown,
      menuItems: [],
    },
    {
      id: `item2-${suffix}`,
      name: 'Item 2',
      url: '',
      target: Target.blank,
      subMenuStyle: SubMenuStyle.dropdown,
      menuItems: [],
    },
  ]

  return menuItems
}

const convertToMenuItem = (items: LegacyNavbarNavigationItem[]): MenuItem[] => {
  const menuItems: MenuItem[] = items.map<MenuItem>(
    ({ label, link, openInNewWindow }, index) => {
      const item: MenuItem = {
        id: `${label}-${index}}`,
        name: label ?? '',
        url: link ?? '',
        target: openInNewWindow ? Target.blank : Target.self,
        subMenuStyle: SubMenuStyle.dropdown,
        menuItems: [],
      }
      return item
    },
  )

  return menuItems
}
