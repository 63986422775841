import { emailLogGQLFields } from '../email/index.js'
import { MemberFields, memberGQLFields } from './member.fields.js'
import { RoleFields, roleGQLFields } from './role.fields.js'

export type MemberInvitationFields =
  | 'basic'
  | 'all'
  | 'default'
  | CustomMemberInvitationFields

export interface CustomMemberInvitationFields {
  invitee?: MemberFields
  inviter?: MemberFields
  role?: RoleFields
  emailLog?: 'basic' | 'all'
}

const BASIC_MEMBER_INVITATION_FIELDS: CustomMemberInvitationFields = {}
const ALL_MEMBER_INVITATION_FIELDS: CustomMemberInvitationFields = {
  invitee: 'basic',
  inviter: 'basic',
  role: 'basic',
  emailLog: 'basic',
}
const DEFAULT_MEMBER_INVITATION_FIELDS: CustomMemberInvitationFields = {
  inviter: { profilePicture: 'basic' },
  emailLog: 'basic',
  role: 'basic',
}

export function memberInvitationGQLFields(fields: MemberInvitationFields) {
  if (fields === 'basic') fields = BASIC_MEMBER_INVITATION_FIELDS
  if (fields === 'all') fields = ALL_MEMBER_INVITATION_FIELDS
  if (fields === 'default') fields = DEFAULT_MEMBER_INVITATION_FIELDS

  return `
    createdAt
    expiresAt
    id
    invitationMessage
    inviteeEmail
    inviteeId
    inviteeName
    inviterId
    joinedAt
    networkId
    roleId
    status
    ${
      fields.invitee
        ? `
      invitee {
        ${memberGQLFields(fields.invitee)}
      }
    `
        : ``
    }
    ${
      fields.inviter
        ? `
      inviter {
        ${memberGQLFields(fields.inviter)}
      }
    `
        : ``
    }
    ${
      fields.role
        ? `
      role {
        ${roleGQLFields(fields.role)}
      }
    `
        : ``
    }
    ${
      fields.emailLog
        ? `
      emailLog {
        ${emailLogGQLFields()} 
      }
    `
        : ``
    }
  `
}
