import { Post } from '@tribeplatform/gql-client/types'
import { ButtonSize, IconButton } from '@tribeplatform/react-ui-kit/Button'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { useFirstRenderContext } from '../common/ssr/index.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { PostActionItems } from './PostActionItems.js'
import type { PostContext } from './types.js'

export const PostOptions = ({
  post,
  size,
  context,
}: {
  post: Post
  size?: ButtonSize
  context: PostContext
}) => {
  const { $t } = useI18n()

  const activator = (
    <IconButton
      variant="tertiaryNeutral"
      size={size}
      icon={<SvgIcon name="dots-horizontal" />}
      aria-label={$t({
        defaultMessage: 'Options',
        id: 'Generics.Options',
      })}
    />
  )

  /**
   * @tag Vite migration
   * Dropdown produces hydration error because of prop `id` did not match.
   * As a workaround, Dropdown is initialized client-side only
   */
  const { isFirstRender } = useFirstRenderContext()

  if (isFirstRender) {
    return activator
  }

  return (
    <>
      <Dropdown placement="bottom-end">
        {({ open }) => (
          <>
            <Dropdown.ButtonMinimal>{activator}</Dropdown.ButtonMinimal>
            <PostActionItems
              post={post}
              context={context}
              showShortcuts={open}
              className="w-56"
            />
          </>
        )}
      </Dropdown>
    </>
  )
}
