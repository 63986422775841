import { createContext, useContext } from 'react'

import { SlateKitProps } from '../types/index.js'

export const SlateKitContext = createContext<SlateKitProps>({
  kit: null,
  context: null,
})

export const useSlateKitContext = (): SlateKitProps => {
  const context = useContext(SlateKitContext)
  return context
}
