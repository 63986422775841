import type {
  EmbedInput,
  QueryMediaArgs,
} from '@tribeplatform/gql-client/types'
import type { MediaFields } from '@tribeplatform/gql-client/types/generated'

import { KeyConstructor } from './keyConstructor.js'

const EMBED_KEY = 'embed'
const MEDIA_KEY = 'media'

export const getEmbedKey = KeyConstructor<EmbedInput, never>(EMBED_KEY)
export const getMediaKey = KeyConstructor<QueryMediaArgs, MediaFields>(
  MEDIA_KEY,
)
