import { useRef, useEffect, useCallback, memo } from 'react'

import dayjs from 'dayjs'
import isEqual from 'react-fast-compare'

import { useDateTimePicker } from '../hooks/useDateTimePicker.js'
import type {
  DateTimeValue,
  DateTimeOptions,
} from '../hooks/useDateTimePicker.js'

interface Props {
  value: DateTimeValue
  onChange: (value: DateTimeValue) => void
  options?: Omit<DateTimeOptions, 'defaultDate' | 'onChange'>
}
export const DateTimePicker = memo(
  ({ value, onChange, options = {} }: Props) => {
    const pickerRef = useRef<HTMLInputElement>(null)
    const prevValueRef = useRef<typeof value>([])
    const shouldAdjust = useRef(true)

    const handleChange = useCallback(
      (newDate: DateTimeValue) => {
        if (!Array.isArray(newDate)) {
          return
        }

        // Do not adjust the picker if date is manually picked
        shouldAdjust.current = false
        onChange(newDate.map(date => dayjs(date).startOf('day').toDate()))
      },
      [onChange],
    )

    const pickerInstanceRef = useDateTimePicker({
      pickerRef,
      onChange: handleChange,
      options,
    })

    useEffect(() => {
      if (pickerInstanceRef.current) {
        const prevValue = prevValueRef.current
        if (Array.isArray(value) && Array.isArray(prevValue)) {
          // If date is not manually picked, adjust the shown month and picked dates according to previous and new selection
          // When end date was changed, show the month of the end date
          // and if start date was changed, show the month of start date
          if (shouldAdjust.current) {
            if (value.length === 0) {
              pickerInstanceRef.current.changeMonth(-1)
            }

            if (value.length === 2) {
              pickerInstanceRef.current.setDate(value)
              const [start, end] = value

              if (prevValue.length === 2) {
                const [prevStart, prevEnd] = prevValue

                if (
                  start.toString() !== prevStart.toString() &&
                  end.toString() === prevEnd.toString()
                ) {
                  pickerInstanceRef.current.jumpToDate(start, false)
                } else {
                  pickerInstanceRef.current.jumpToDate(end, false)
                  pickerInstanceRef.current.changeMonth(-1)
                }
              } else {
                pickerInstanceRef.current.jumpToDate(end, false)
                pickerInstanceRef.current.changeMonth(-1)
              }
            }
          } else {
            shouldAdjust.current = true
          }

          prevValueRef.current = value
        }
      }
    }, [pickerInstanceRef, value])

    return <div ref={pickerRef}></div>
  },
  isEqual,
)
