import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'
import { TagPills } from '@tribeplatform/react-components/Post'

interface Props {
  post: Post
  highlighted: boolean
  stretchX: string
  preview: boolean
}
export const Tags = ({ post, highlighted, stretchX, preview }: Props) => {
  const defaultTags = [
    { id: '1', title: 'Sample', slug: 'sample' },
    { id: '2', title: 'Tag', slug: 'tag' },
  ]
  const previewTags = preview ? defaultTags : []
  const tags = post?.tags && post.tags?.length ? post.tags : previewTags

  return (
    <div
      className={clsx(
        'flex items-center text-content empty:hidden min-w-0',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
      onClick={e => e.stopPropagation()}
    >
      <TagPills tags={tags} />
    </div>
  )
}
