import { SlateFields, slateGQLFields } from '../slate/slate.fields.js'

export type CallbackFields = 'basic' | 'default' | CustomCallbackFields

export interface CustomCallbackFields {
  slate?: SlateFields
}

const BASIC_CALLBACK_FIELDS: CustomCallbackFields = {
  slate: 'all',
}
const DEFAULT_CALLBACK_FIELDS: CustomCallbackFields = {
  slate: 'all',
}

export const callbackGQLFields = (fields: CallbackFields) => {
  if (!fields || fields === 'default') fields = DEFAULT_CALLBACK_FIELDS
  if (fields === 'basic') fields = BASIC_CALLBACK_FIELDS
  return `
    action
    toast {
      status
      duration
      title
      description
    }
    ${
      fields.slate
        ? `
      slate {
        ${slateGQLFields(fields.slate)}
      }
    `
        : ``
    }
  `
}
