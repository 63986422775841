import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react'

import { clsx } from 'clsx'

import type { IconComponentType } from '../Icon/index.js'
import { Icon } from '../Icon/index.js'
import { inputIconStyles, inputStyles } from './Input.styles.js'
import { InputStyleProps } from './types.js'

export type InputProps = Omit<ComponentPropsWithoutRef<'input'>, 'size'> &
  InputStyleProps & {
    /** @deprecated use leadingAddon instead */
    leadingIcon?: IconComponentType
    /** @deprecated use trailingAddon instead */
    trailingIcon?: IconComponentType
    leadingAddon?: string | ReactNode
    trailingAddon?: string | ReactNode
    wrapperClassName?: string
    showFocusRing?: boolean
  }
/**
 * The Input component is a component that is used to get user input in a text field.
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      invalid = false,
      hidden,
      disabled,
      readOnly,
      size,
      leadingIcon,
      trailingIcon,
      leadingAddon,
      trailingAddon,
      className,
      wrapperClassName,
      showFocusRing = true,
      ...rest
    },
    ref,
  ) => {
    const wrapperStyle = inputStyles({
      size,
      invalid,
      disabled,
      readOnly,
      className,
      showFocusRing,
    })

    const leadingElement = leadingIcon ? (
      <Icon
        className={inputIconStyles({
          size,
          disabled,
        })}
      >
        {leadingIcon}
      </Icon>
    ) : (
      leadingAddon
    )
    const trailingElement = trailingIcon ? (
      <Icon
        className={inputIconStyles({
          size,
          disabled,
        })}
      >
        {trailingIcon}
      </Icon>
    ) : (
      trailingAddon
    )

    if (!leadingElement && !trailingElement) {
      return (
        <input
          ref={ref}
          className={clsx(wrapperStyle, hidden && 'hidden')}
          type="text"
          disabled={disabled}
          hidden={hidden}
          {...rest}
        />
      )
    }

    return (
      <div
        className={clsx(
          'relative inline-flex items-center gap-2',
          hidden && 'hidden',
          wrapperClassName,
          wrapperStyle,
        )}
      >
        {leadingElement && <div className="shrink-0">{leadingElement}</div>}

        <input
          ref={ref}
          className={clsx(
            'grow appearance-none',
            'focus-visible:outline-none bg-transparent',
          )}
          type="text"
          disabled={disabled}
          hidden={hidden}
          {...rest}
        />

        {trailingElement && <div className="shrink-0">{trailingElement}</div>}
      </div>
    )
  },
)
