import { useRef, useState, useEffect, useCallback } from 'react'

import { nanoid } from 'nanoid'

import {
  AppInteractionType,
  PermissionContext,
} from '@tribeplatform/gql-client/types'
import {
  InteractionEmitterType,
  UseInteractions,
  DataInteractionEventPayload,
} from '@tribeplatform/react-sdk/types'
import { FormControlSelectProps } from '@tribeplatform/react-ui-kit/FormControl'

import { SelectBlockProps, SelectItemsQueryTypes } from './types.js'

type FetchMoreProps = {
  type: SelectItemsQueryTypes
  text: string
}
type UseSelectItemsProps = {
  items: FormControlSelectProps['items']
  callbackId: string
  dynamicBlockKey?: string
  shortcutKey?: string
  permissionContext?: PermissionContext
  appId: string
  useInteractions: UseInteractions
  interactionEmitter: InteractionEmitterType
  getValues: () => Record<string, string>
}
type UseSelectItemsReturn = {
  items?: FormControlSelectProps['items']
  isFetching?: boolean
  fetchMore?: (props: FetchMoreProps) => void
}

export type UseSelectItems = (
  props?: UseSelectItemsProps,
) => UseSelectItemsReturn

export const useSelectItems: UseSelectItems = ({
  callbackId,
  items: uncleanItems,
  appId,
  getValues,
  useInteractions,
  interactionEmitter,
  shortcutKey,
  permissionContext,
  dynamicBlockKey,
}) => {
  const interactionId = useRef(nanoid())
  const isFetching = useRef(false)

  const [items, setItems] = useState<SelectBlockProps['items']>([])

  const { loadInteractions } = useInteractions({})

  const fetchMore = useCallback(
    async ({ type, text }: FetchMoreProps) => {
      try {
        if (!appId || !callbackId) {
          return
        }
        isFetching.current = true

        // [TODO] handle ShowMore
        if (type === SelectItemsQueryTypes.Search) {
          await loadInteractions({
            appId,
            interactionId: interactionId.current,
            permissionContext: permissionContext ?? PermissionContext.NETWORK,
            callbackId,
            ...(shortcutKey ? { shortcutKey } : {}),
            ...(dynamicBlockKey ? { dynamicBlockKey } : {}),
            inputs: {
              type: 'SEARCH',
              query: text,
              formValues: typeof getValues === 'function' ? getValues() : {},
            },
          })
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    [
      appId,
      callbackId,
      loadInteractions,
      shortcutKey,
      dynamicBlockKey,
      getValues,
      permissionContext,
    ],
  )

  const getUncleanItems = useCallback((): SelectBlockProps['items'] => {
    try {
      return typeof uncleanItems === 'string'
        ? JSON.parse(uncleanItems)
        : uncleanItems
    } catch (err) {
      return []
    }
  }, [uncleanItems])

  useEffect(() => {
    const cleanedItems = getUncleanItems()
    if (cleanedItems.length) {
      setItems(cleanedItems)
    }
  }, [getUncleanItems])

  useEffect(() => {
    const dataListenerHandler = (args: DataInteractionEventPayload) => {
      if (args.interactionId === interactionId.current) {
        isFetching.current = false
        const newItems: SelectBlockProps['items'] = args.props.items
        if (!newItems.length) {
          return
        }
        setItems(newItems)
      }
    }
    if (interactionEmitter) {
      interactionEmitter.on(AppInteractionType.Data, dataListenerHandler)
    }

    return () => {
      if (interactionEmitter) {
        if (interactionId) {
          interactionEmitter.removeListener(
            AppInteractionType.Data,
            dataListenerHandler,
          )
        }
      }
    }
  }, [interactionEmitter])

  return { fetchMore, items, isFetching: isFetching.current }
}
