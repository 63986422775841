import { clsx } from 'clsx'

import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Card } from '@tribeplatform/react-ui-kit/Card'

import { BC } from '../../types/index.js'
import { AnnouncementProps } from './types.js'

export const AnnouncementBlock: BC<AnnouncementProps> = ({
  title,
  description,
  actionText,
  actionUrl,
  align,
  viewStyle,
  hidden,
  className,
  ...rest
}) => {
  if (hidden) {
    return null
  }

  const cardStyle = [
    viewStyle === 'vibrant' && 'bg-action-primary text-content-on-primary',
  ]
  const buttonVariant = viewStyle === 'vibrant' ? 'secondaryNeutral' : 'primary'

  return (
    <Card className={clsx('overflow-hidden', cardStyle, className)} {...rest}>
      <Card.Content className={clsx(align === 'center' && 'text-center')}>
        {title ? (
          <Card.Header>
            <h2>{title}</h2>
          </Card.Header>
        ) : null}
        {description ? <Card.Content>{description}</Card.Content> : null}
        {actionText && actionUrl ? (
          <Card.Footer>
            <Button size="lg" as="a" href={actionUrl} variant={buttonVariant}>
              {actionText}
            </Button>
          </Card.Footer>
        ) : null}
      </Card.Content>
    </Card>
  )
}
