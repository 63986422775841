import { useState } from 'react'

import { useFormContext } from 'react-hook-form'

import { Form } from '@tribeplatform/react-components/Form'
import { FormControl } from '@tribeplatform/react-ui-kit/FormControl'

import { useSlate } from '../../hooks/index.js'
import { BC, CallbackExtraContext } from '../../types/index.js'
import { MarkdownBlock } from '../Markdown/Block.js'
import { ToggleProps } from './types.js'

export const ToggleBlock: BC<ToggleProps> = ({
  showChildrenOnEnabled,
  checked: defaultValue,
  childrenClassName,
  helperText: markdownHelperText,
  callbackId,
  disabled,
  children,
  ...rest
}) => {
  const {
    extraContext: {
      slate: { callback },
    },
  } = useSlate<CallbackExtraContext>()
  const formContext = useFormContext()
  const [loading, setLoading] = useState(false)
  const { control: enabled, watch } = formContext || {}
  const value = enabled ? watch(rest.name, defaultValue) : defaultValue
  const helperText =
    typeof markdownHelperText === 'string' ? (
      <MarkdownBlock text={markdownHelperText} />
    ) : (
      markdownHelperText
    )
  const withChildren =
    !children || (Array.isArray(children) && children.length > 0)

  let toggle: JSX.Element
  if (enabled) {
    toggle = (
      <Form.Toggle
        {...rest}
        helperText={helperText}
        onChange={async value => {
          if (disabled !== true && callbackId) {
            setLoading(true)
            try {
              await callback(callbackId, { data: { value } })
              // We are catching the error here because we don't want to crash the app
              // We are already logging the error in the callback function
              // eslint-disable-next-line no-empty
            } catch {}
            setLoading(false)
          }
        }}
        defaultChecked={defaultValue}
        disabled={loading || disabled}
      />
    )
  } else {
    toggle = (
      <FormControl.Toggle
        {...rest}
        helperText={helperText}
        defaultChecked={defaultValue}
        checked={value}
        onChange={async value => {
          if (disabled !== true && rest.readOnly !== true && callbackId) {
            setLoading(true)
            try {
              await callback(callbackId, { data: { value } })
              // We are catching the error here because we don't want to crash the app
              // We are already logging the error in the callback function
              // eslint-disable-next-line no-empty
            } catch {}
            setLoading(false)
          }
        }}
        disabled={loading || disabled}
      />
    )
  }

  return (
    <>
      {toggle}
      {withChildren && (!showChildrenOnEnabled || value) && (
        <div className={childrenClassName}>{children}</div>
      )}
    </>
  )
}
