import React from 'react'

import { SearchEntityType } from '@tribeplatform/gql-client/types'
import { Autocomplete } from '@tribeplatform/react-ui-kit/Autocomplete'
import { StackedList } from '@tribeplatform/react-ui-kit/StackedList'

import { useI18n } from '../i18n/providers/I18nProvider.js'
import { SearchRow } from './SearchRow.js'
import { cleanupSearchResult } from './utils.js'

export const SearchAutocompleteItems = ({ data }) => {
  const { $t } = useI18n()

  return (
    <>
      {data?.totalCount > 0 &&
        cleanupSearchResult(data)?.hits.reduce(
          (result, category, sectionIndex) => {
            result.sections.push(
              // eslint-disable-next-line react/no-array-index-key
              <StackedList.Group key={sectionIndex}>
                <StackedList.GroupTitle className="first-letter:capitalize">
                  {category.entityType === SearchEntityType.External
                    ? $t({
                        id: 'Generics.KnowledgeBase',
                        defaultMessage: 'Knowledge base',
                      })
                    : category.entityType}
                </StackedList.GroupTitle>
                {category.hits.map((hit, personIndex) => {
                  const index = result.itemIndex
                  result.itemIndex += 1
                  return (
                    <Autocomplete.Item
                      // eslint-disable-next-line react/no-array-index-key
                      key={personIndex}
                      value={hit}
                      index={index}
                    >
                      <SearchRow
                        spacing="sm"
                        entityType={category.entityType}
                        hit={hit}
                      />
                    </Autocomplete.Item>
                  )
                })}
              </StackedList.Group>,
            )
            return result
          },
          { sections: [], itemIndex: 1 },
        ).sections}
    </>
  )
}
