import type {
  Fields,
  MutationPublishTemplateArgs,
  Action,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import {
  getTemplateKey,
  getTemplatesKey,
} from '../../utils/keys/template.key.js'

export const usePublishTemplate = (options?: {
  fields?: Fields.ActionFields
  useMutationOptions?: UseMutationOptions<
    Action,
    Error,
    MutationPublishTemplateArgs
  >
}) => {
  const { useMutationOptions, fields = 'basic' } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<Action, Error, MutationPublishTemplateArgs>(
    variables =>
      client.mutation({
        name: 'publishTemplate',
        args: {
          fields,
          variables,
        },
      }),
    {
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(getTemplatesKey())
        queryClient.invalidateQueries(
          getTemplateKey({
            variables: {
              id: variables?.id,
            },
          }),
        )
      },
      ...useMutationOptions,
    },
  )
}
