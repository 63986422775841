import { clsx } from 'clsx'

import { iconStyles } from '../Icon/index.js'
import { avatarStyles } from './Avatar.styles.js'
import { AvatarRounded, AvatarSize } from './types.js'

export interface AvatarFallbackProps {
  size?: AvatarSize
  name?: string
  rounded?: AvatarRounded
  className?: string
}

export const AvatarFallback = ({
  size,
  rounded,
  className,
  ...rest
}: AvatarFallbackProps) => {
  return (
    <span
      className={clsx(
        avatarStyles({
          rounded,
        }),
        iconStyles({ size }),
        'bg-surface-pressed text-content-muted overflow-hidden',
        className,
      )}
      {...rest}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  )
}
