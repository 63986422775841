import { useMemo, useState, useEffect } from 'react'

import { CustomFieldType } from '@tribeplatform/gql-client/types'

import type {
  Filter,
  RawFilter,
  ListFilter,
  RawInlineFilter,
} from '../types.js'
import {
  convertInlineToFilterArray,
  convertToFilterArray,
} from '../utils/converters.js'
import {
  narrowDownInlineFilterValues,
  sanitizeInlineFilters,
} from '../utils/inlineFilters.js'

interface UseInlineFiltersProps {
  entityListFilters: ListFilter[]
  filters: Filter[]
  allFilters: RawFilter[]
  rawInlineFilters?: RawInlineFilter[]
}
export const useInlineFilters = ({
  entityListFilters,
  filters,
  allFilters,
  rawInlineFilters,
}: UseInlineFiltersProps) => {
  const availableInlineFilters: RawFilter[] = useMemo(() => {
    const entityFilters = convertToFilterArray(entityListFilters, allFilters)
    return allFilters.filter(({ key, type }) => {
      const entityFilter = entityFilters.find(filter => filter.key === key)

      // Include non-conflicting filters
      if (!entityFilter) {
        return true
      }
      // Exclude conflicting boolean filters
      if (type === CustomFieldType.boolean) {
        return false
      }
      // Exclude conflicting number filters
      if (type === CustomFieldType.number) {
        return false
      }
      // Exclude conflicting arrays with only one option
      if (Array.isArray(entityFilter.value) && entityFilter.value.length <= 1) {
        return false
      }
      // Exclude conflicting absolute dates
      if (
        type === CustomFieldType.date &&
        typeof entityFilter.value !== 'number'
      ) {
        return false
      }

      return true
    })
  }, [allFilters, entityListFilters])

  const narrowedDownInlineFilters: Filter[] = useMemo(() => {
    if (!rawInlineFilters) {
      return []
    }

    const almostProcessedInlineFilters = convertInlineToFilterArray(
      rawInlineFilters,
      allFilters,
    )
    const processedInlineFilters = sanitizeInlineFilters(
      almostProcessedInlineFilters,
      availableInlineFilters,
    )
    const narrowedDownInlineFilters = narrowDownInlineFilterValues(
      processedInlineFilters,
      filters,
    )

    return narrowedDownInlineFilters
  }, [allFilters, availableInlineFilters, filters, rawInlineFilters])

  const [processedInlineFilters, setProcessedInlineFilters] = useState<
    Filter[]
  >(narrowedDownInlineFilters)
  useEffect(() => {
    setProcessedInlineFilters(narrowedDownInlineFilters)
  }, [narrowedDownInlineFilters])

  return {
    processedInlineFilters,
    availableInlineFilters,
    setProcessedInlineFilters,
  }
}
