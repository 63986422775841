import { Fragment } from 'react'

import type { Space } from '@tribeplatform/gql-client/types'
import { useNavigate } from '@tribeplatform/react-components/Link'
import type { StaticField } from '@tribeplatform/react-components/Views'

import { Field } from './Field.js'

interface Props {
  space: Space
  fields: StaticField[]
  activeFieldId: string
  preview: boolean
}
export const SpacesListItem = ({
  space,
  fields,
  activeFieldId,
  preview,
}: Props) => {
  const navigate = useNavigate()

  return (
    <div
      className="cursor-pointer flex flex-wrap items-center gap-y-2 gap-x-5"
      onClick={() => navigate(space?.relativeUrl ?? '')}
    >
      {fields.map(field => (
        <Fragment key={field.id}>
          <Field
            field={field}
            space={space}
            activeFieldId={activeFieldId}
            viewStyle="list"
            preview={preview}
          />
          {field.settings?.stretchX && (
            <div className="basis-full web:sm:basis-0"></div>
          )}
        </Fragment>
      ))}
    </div>
  )
}
