import {
  PostReactionParticipantFields,
  postReactionParticipantsGQLFields,
} from './post-reaction-participant.fields.js'
import { PostFields, postGQLFields } from './posts.fields.js'

export type PostLastActivityEntityFields =
  | 'basic'
  | 'all'
  | CustomPostLastActivityEntityFields

interface CustomPostLastActivityEntityFields {
  onPost?: PostFields
  onPostReactionParticipant?: PostReactionParticipantFields
}

const BASIC_POST_LAST_ACTIVITY_ENTITY_FIELDS: CustomPostLastActivityEntityFields =
  {}
const ALL_POST_LAST_ACTIVITY_ENTITY_FIELDS: CustomPostLastActivityEntityFields =
  {
    onPost: 'basic',
    onPostReactionParticipant: 'basic',
  }

export const postLastActivityEntityGQLFields = (
  fields: PostLastActivityEntityFields,
) => {
  if (fields === 'basic') fields = BASIC_POST_LAST_ACTIVITY_ENTITY_FIELDS
  if (fields === 'all') fields = ALL_POST_LAST_ACTIVITY_ENTITY_FIELDS

  return `
    __typename
    ${
      fields.onPost
        ? `
      ... on Post {
        ${postGQLFields(fields.onPost)}
      }
    `
        : ``
    }
    ${
      fields.onPostReactionParticipant
        ? `
      ... on PostReactionParticipant {
        ${postReactionParticipantsGQLFields(fields?.onPostReactionParticipant)}
      }
    `
        : ``
    }
  `
}
