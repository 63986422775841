import { cloneElement, ReactNode } from 'react'

import { FloatingDelayGroup } from '@floating-ui/react'
import { clsx } from 'clsx'

import { TOOLTIP_HOVER_DELAY_MS } from '../Tooltip/constants.js'
import { getValidChildren } from '../utils/index.js'
import type { ButtonSize } from './types.js'

export type ButtonGroupProps = {
  size?: ButtonSize
  className?: string
  children: ReactNode
}

export const ButtonGroup = ({
  size = 'md',
  className,
  children,
  ...rest
}: ButtonGroupProps) => {
  const validChildren = getValidChildren(children)

  const clones = validChildren.map(child => {
    const childProps = {
      variant: 'secondaryNeutral',
      size,
      className:
        'rounded-s-none rounded-e-none first:rounded-s-button last:rounded-e-button -ms-px focus:z-10',
    }

    return cloneElement(child, childProps)
  })

  return (
    <FloatingDelayGroup delay={TOOLTIP_HOVER_DELAY_MS}>
      <span
        className={clsx(
          'relative isolate z-0 inline-flex',
          'shadow-sm rounded-button',
          className,
        )}
        {...rest}
      >
        {clones}
      </span>
    </FloatingDelayGroup>
  )
}
