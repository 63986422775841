interface Props {
  className?: string
}
export const MadeWithBettermodeBadgeSVG = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="175"
      height="36"
      viewBox="0 0 175 36"
      className={className}
      fill="none"
    >
      <g clipPath="url(#clip0_471_10329)">
        <path
          d="M170 0H5C2.23858 0 0 2.23858 0 5V31C0 33.7614 2.23858 36 5 36H170C172.761 36 175 33.7614 175 31V5C175 2.23858 172.761 0 170 0Z"
          fill="white"
        />
        <circle cx="19" cy="18" r="13" fill="#46CB19" />
        <path
          d="M12.5 18C12.5 15.7563 14.3188 13.9375 16.5625 13.9375H21.4375C23.6811 13.9375 25.5 15.7563 25.5 18V18C25.5 20.2436 23.6811 22.0625 21.4375 22.0625H16.5625C14.3188 22.0625 12.5 20.2436 12.5 18V18Z"
          fill="white"
        />
        <path
          d="M18.5938 17.9623C18.5994 16.2823 19.9658 14.9315 21.6546 14.9356C23.3151 14.9404 24.6895 16.3235 24.6814 17.9833C24.6734 19.6746 23.3054 21.0222 21.6029 21.0149C19.9343 21.0077 18.5889 19.6423 18.5938 17.9615V17.9623Z"
          fill="#46CB19"
        />
        <path
          d="M163.01 22.987C162.209 22.987 161.518 22.8289 160.934 22.5126C160.361 22.1864 159.916 21.7219 159.6 21.119C159.294 20.5063 159.141 19.7699 159.141 18.9101C159.141 18.0403 159.294 17.304 159.6 16.7011C159.916 16.0884 160.366 15.6239 160.949 15.3076C161.532 14.9814 162.219 14.8184 163.01 14.8184C163.771 14.8184 164.438 14.9864 165.011 15.3224C165.594 15.6584 166.039 16.1279 166.346 16.7308C166.652 17.3238 166.805 18.0008 166.805 18.7618C166.805 18.9101 166.795 19.0583 166.776 19.2066C166.766 19.3548 166.751 19.4784 166.731 19.5772H160.638V18.0947H164.745C164.695 17.8476 164.601 17.6104 164.463 17.3831C164.324 17.1558 164.132 16.9729 163.885 16.8346C163.638 16.6962 163.346 16.627 163.01 16.627C162.654 16.627 162.343 16.711 162.076 16.879C161.809 17.0471 161.602 17.2892 161.453 17.6055C161.315 17.9119 161.246 18.2775 161.246 18.7025C161.246 19.2264 161.305 19.676 161.424 20.0516C161.542 20.4173 161.73 20.699 161.987 20.8966C162.244 21.0844 162.58 21.1783 162.995 21.1783C163.381 21.1783 163.672 21.1141 163.87 20.9856C164.067 20.8472 164.245 20.6446 164.404 20.3778H166.657C166.528 20.8522 166.316 21.287 166.019 21.6824C165.733 22.0678 165.338 22.3841 164.833 22.6312C164.329 22.8684 163.722 22.987 163.01 22.987Z"
          fill="black"
        />
        <path
          d="M153.738 22.9848C153.066 22.9848 152.473 22.8316 151.959 22.5252C151.445 22.209 151.045 21.7444 150.758 21.1317C150.471 20.5189 150.328 19.7776 150.328 18.9079C150.328 18.0283 150.471 17.2821 150.758 16.6693C151.045 16.0565 151.445 15.597 151.959 15.2906C152.473 14.9743 153.066 14.8162 153.738 14.8162C154.252 14.8162 154.687 14.9101 155.043 15.0979C155.398 15.2856 155.675 15.508 155.873 15.765H155.947V12.459H158.067V22.8366H156.243L156.021 21.9619H155.947C155.68 22.2584 155.369 22.5055 155.013 22.7031C154.667 22.8909 154.242 22.9848 153.738 22.9848ZM154.197 21.0872C154.524 21.0872 154.815 21.0131 155.072 20.8648C155.339 20.7067 155.551 20.4645 155.71 20.1384C155.868 19.8023 155.947 19.3922 155.947 18.9079C155.947 18.4137 155.868 18.0036 155.71 17.6774C155.551 17.3513 155.339 17.1091 155.072 16.951C154.815 16.7929 154.524 16.7138 154.197 16.7138C153.861 16.7138 153.56 16.7929 153.293 16.951C153.036 17.1091 152.829 17.3513 152.67 17.6774C152.522 18.0036 152.448 18.4137 152.448 18.9079C152.448 19.6294 152.611 20.173 152.937 20.5387C153.273 20.9043 153.693 21.0872 154.197 21.0872Z"
          fill="black"
        />
        <path
          d="M145.822 22.987C145.022 22.987 144.33 22.8289 143.747 22.5126C143.174 22.1864 142.729 21.7219 142.413 21.119C142.106 20.5063 141.953 19.7699 141.953 18.9101C141.953 18.0403 142.106 17.304 142.413 16.7011C142.729 16.0884 143.174 15.6239 143.747 15.3076C144.33 14.9814 145.022 14.8184 145.822 14.8184C146.613 14.8184 147.3 14.9814 147.883 15.3076C148.466 15.6239 148.911 16.0884 149.217 16.7011C149.534 17.304 149.692 18.0403 149.692 18.9101C149.692 19.7699 149.534 20.5063 149.217 21.119C148.911 21.7219 148.466 22.1864 147.883 22.5126C147.3 22.8289 146.613 22.987 145.822 22.987ZM145.822 21.0894C146.149 21.0894 146.44 21.0152 146.697 20.867C146.964 20.7089 147.176 20.4667 147.335 20.1406C147.493 19.8045 147.572 19.3944 147.572 18.9101C147.572 18.4159 147.493 18.0058 147.335 17.6796C147.176 17.3535 146.964 17.1113 146.697 16.9532C146.44 16.795 146.149 16.716 145.822 16.716C145.486 16.716 145.185 16.795 144.918 16.9532C144.661 17.1113 144.454 17.3535 144.295 17.6796C144.147 18.0058 144.073 18.4159 144.073 18.9101C144.073 19.3944 144.147 19.8045 144.295 20.1406C144.454 20.4667 144.661 20.7089 144.918 20.867C145.185 21.0152 145.486 21.0894 145.822 21.0894Z"
          fill="black"
        />
        <path
          d="M129.57 22.8387V14.9666H131.394L131.616 15.8413H131.69C131.918 15.5053 132.184 15.2532 132.491 15.0852C132.797 14.9073 133.148 14.8184 133.543 14.8184C134.008 14.8184 134.433 14.932 134.818 15.1593C135.204 15.3867 135.495 15.693 135.693 16.0785H135.767C135.955 15.7128 136.271 15.4114 136.716 15.1742C137.161 14.937 137.635 14.8184 138.139 14.8184C138.693 14.8184 139.187 14.9468 139.622 15.2038C140.066 15.4509 140.412 15.8116 140.659 16.286C140.907 16.7506 141.03 17.304 141.03 17.9465V22.8387H138.895V18.243C138.895 17.897 138.841 17.6104 138.732 17.3831C138.624 17.1558 138.47 16.9878 138.273 16.879C138.075 16.7703 137.848 16.716 137.591 16.716C137.373 16.716 137.171 16.7753 136.983 16.8939C136.795 17.0125 136.642 17.1854 136.523 17.4128C136.415 17.6401 136.36 17.9168 136.36 18.243V22.8387H134.24V18.243C134.24 17.897 134.181 17.6104 134.062 17.3831C133.954 17.1558 133.8 16.9878 133.603 16.879C133.405 16.7703 133.183 16.716 132.936 16.716C132.708 16.716 132.501 16.7753 132.313 16.8939C132.125 17.0125 131.972 17.1854 131.853 17.4128C131.745 17.6401 131.69 17.9168 131.69 18.243V22.8387H129.57Z"
          fill="black"
        />
        <path
          d="M123.914 22.837V14.9648H125.738L125.96 15.8395H126.034C126.242 15.5331 126.504 15.3108 126.82 15.1724C127.146 15.034 127.502 14.9648 127.887 14.9648H128.866V17.0107H128.035C127.62 17.0107 127.26 17.0898 126.953 17.2479C126.657 17.406 126.429 17.6284 126.271 17.915C126.113 18.2017 126.034 18.5426 126.034 18.938V22.837H123.914Z"
          fill="black"
        />
        <path
          d="M119.119 22.987C118.319 22.987 117.627 22.8289 117.044 22.5126C116.471 22.1864 116.026 21.7219 115.71 21.119C115.403 20.5063 115.25 19.7699 115.25 18.9101C115.25 18.0403 115.403 17.304 115.71 16.7011C116.026 16.0884 116.476 15.6239 117.059 15.3076C117.642 14.9814 118.329 14.8184 119.119 14.8184C119.88 14.8184 120.547 14.9864 121.121 15.3224C121.704 15.6584 122.149 16.1279 122.455 16.7308C122.761 17.3238 122.915 18.0008 122.915 18.7618C122.915 18.9101 122.905 19.0583 122.885 19.2066C122.875 19.3548 122.86 19.4784 122.84 19.5772H116.747V18.0947H120.854C120.804 17.8476 120.711 17.6104 120.572 17.3831C120.434 17.1558 120.241 16.9729 119.994 16.8346C119.747 16.6962 119.455 16.627 119.119 16.627C118.764 16.627 118.452 16.711 118.185 16.879C117.919 17.0471 117.711 17.2892 117.563 17.6055C117.424 17.9119 117.355 18.2775 117.355 18.7025C117.355 19.2264 117.414 19.676 117.533 20.0516C117.652 20.4173 117.839 20.699 118.096 20.8966C118.353 21.0844 118.689 21.1783 119.105 21.1783C119.49 21.1783 119.782 21.1141 119.979 20.9856C120.177 20.8472 120.355 20.6446 120.513 20.3778H122.766C122.638 20.8522 122.425 21.287 122.129 21.6824C121.842 22.0678 121.447 22.3841 120.943 22.6312C120.439 22.8684 119.831 22.987 119.119 22.987Z"
          fill="black"
        />
        <path
          d="M113.252 22.8363C112.698 22.8363 112.238 22.7474 111.873 22.5695C111.507 22.3916 111.23 22.1346 111.043 21.7986C110.865 21.4526 110.776 21.0276 110.776 20.5236V16.9359H109.516V14.9642H110.776L111.087 12.8887H112.896V14.9642H114.749V16.9359H112.896V20.1381C112.896 20.3556 112.965 20.5335 113.103 20.6718C113.242 20.8102 113.415 20.8794 113.622 20.8794H114.749V22.8363H113.252Z"
          fill="black"
        />
        <path
          d="M107.525 22.8363C106.972 22.8363 106.512 22.7474 106.146 22.5695C105.781 22.3916 105.504 22.1346 105.316 21.7986C105.138 21.4526 105.049 21.0276 105.049 20.5236V16.9359H103.789V14.9642H105.049L105.361 12.8887H107.169V14.9642H109.022V16.9359H107.169V20.1381C107.169 20.3556 107.238 20.5335 107.377 20.6718C107.515 20.8102 107.688 20.8794 107.896 20.8794H109.022V22.8363H107.525Z"
          fill="black"
        />
        <path
          d="M99.799 22.987C98.9985 22.987 98.3066 22.8289 97.7235 22.5126C97.1503 22.1864 96.7055 21.7219 96.3893 21.119C96.0829 20.5063 95.9297 19.7699 95.9297 18.9101C95.9297 18.0403 96.0829 17.304 96.3893 16.7011C96.7055 16.0884 97.1552 15.6239 97.7383 15.3076C98.3215 14.9814 99.0084 14.8184 99.799 14.8184C100.56 14.8184 101.227 14.9864 101.8 15.3224C102.384 15.6584 102.828 16.1279 103.135 16.7308C103.441 17.3238 103.594 18.0008 103.594 18.7618C103.594 18.9101 103.584 19.0583 103.565 19.2066C103.555 19.3548 103.54 19.4784 103.52 19.5772H97.427V18.0947H101.534C101.484 17.8476 101.39 17.6104 101.252 17.3831C101.114 17.1558 100.921 16.9729 100.674 16.8346C100.427 16.6962 100.135 16.627 99.799 16.627C99.4432 16.627 99.1319 16.711 98.8651 16.879C98.5982 17.0471 98.3907 17.2892 98.2424 17.6055C98.104 17.9119 98.0349 18.2775 98.0349 18.7025C98.0349 19.2264 98.0942 19.676 98.2128 20.0516C98.3314 20.4173 98.5191 20.699 98.7761 20.8966C99.0331 21.0844 99.3691 21.1783 99.7842 21.1783C100.17 21.1783 100.461 21.1141 100.659 20.9856C100.857 20.8472 101.034 20.6446 101.193 20.3778H103.446C103.318 20.8522 103.105 21.287 102.809 21.6824C102.522 22.0678 102.127 22.3841 101.623 22.6312C101.118 22.8684 100.511 22.987 99.799 22.987Z"
          fill="black"
        />
        <path
          d="M87.1719 22.8366V12.459H92.0938C92.7758 12.459 93.3589 12.5776 93.8432 12.8148C94.3275 13.0421 94.6882 13.3732 94.9254 13.8081C95.1725 14.2429 95.296 14.7569 95.296 15.3499C95.296 15.844 95.1675 16.2888 94.9106 16.6841C94.6635 17.0795 94.3472 17.376 93.9618 17.5736C94.3966 17.7713 94.7524 18.0777 95.0292 18.4928C95.3059 18.898 95.4443 19.3823 95.4443 19.9457C95.4443 20.5387 95.3207 21.0526 95.0737 21.4875C94.8365 21.9223 94.4757 22.2584 93.9914 22.4956C93.517 22.7229 92.9339 22.8366 92.2421 22.8366H87.1719ZM91.9307 20.8648C92.1976 20.8648 92.42 20.8203 92.5979 20.7314C92.7856 20.6424 92.924 20.509 93.013 20.3311C93.1118 20.1532 93.1612 19.9457 93.1612 19.7085C93.1612 19.3428 93.0525 19.0611 92.8351 18.8634C92.6275 18.6559 92.3261 18.5521 91.9307 18.5521H89.4549V20.8648H91.9307ZM91.7825 16.7434C92.0493 16.7434 92.2717 16.699 92.4496 16.61C92.6374 16.5112 92.7758 16.3778 92.8647 16.2097C92.9635 16.0318 93.013 15.8243 93.013 15.5871C93.013 15.2214 92.9042 14.9397 92.6868 14.742C92.4793 14.5345 92.1778 14.4307 91.7825 14.4307H89.4549V16.7434H91.7825Z"
          fill="black"
        />
        <path
          d="M77.0234 22.8373V15.0838H78.1946L78.3725 16.0623H78.4466C78.6937 15.6768 79.0051 15.3951 79.3806 15.2172C79.7661 15.0294 80.1812 14.9355 80.6259 14.9355C81.1794 14.9355 81.6736 15.064 82.1084 15.321C82.5433 15.5681 82.8843 15.9288 83.1314 16.4032C83.3883 16.8776 83.5168 17.4311 83.5168 18.0636V22.8373H82.0788V18.1674C82.0788 17.7622 82.0047 17.4163 81.8564 17.1297C81.7181 16.8332 81.5154 16.6108 81.2486 16.4625C80.9817 16.3143 80.6754 16.2402 80.3294 16.2402C79.9736 16.2402 79.6524 16.3242 79.3658 16.4922C79.0792 16.6602 78.8519 16.8974 78.6838 17.2038C78.5257 17.5003 78.4466 17.8215 78.4466 18.1674V22.8373H77.0234Z"
          fill="black"
        />
        <path
          d="M74.3951 22.8371V15.0836H75.8183V22.8371H74.3951ZM75.1067 13.8235C74.8497 13.8235 74.6322 13.7345 74.4543 13.5566C74.2764 13.3787 74.1875 13.1613 74.1875 12.9043C74.1875 12.6474 74.2764 12.4349 74.4543 12.2669C74.6322 12.089 74.8497 12 75.1067 12C75.3636 12 75.5761 12.089 75.7441 12.2669C75.922 12.4349 76.011 12.6474 76.011 12.9043C76.011 13.1613 75.922 13.3787 75.7441 13.5566C75.5761 13.7345 75.3636 13.8235 75.1067 13.8235Z"
          fill="black"
        />
        <path
          d="M67.5298 22.9856C66.7984 22.9856 66.156 22.8274 65.6025 22.5112C65.0589 22.1949 64.634 21.7403 64.3276 21.1473C64.0311 20.5444 63.8828 19.813 63.8828 18.9531C63.8828 18.1131 64.0311 17.3916 64.3276 16.7887C64.634 16.1759 65.0589 15.7163 65.6025 15.41C66.1461 15.0937 66.7737 14.9355 67.4853 14.9355C68.2364 14.9355 68.8789 15.1134 69.4126 15.4693C69.9562 15.8152 70.3663 16.2747 70.6431 16.848C70.9198 17.4212 71.0582 18.0241 71.0582 18.6566C71.0582 18.8049 71.0483 18.9482 71.0285 19.0866C71.0186 19.2249 71.0038 19.3386 70.984 19.4276H64.8464V18.2415H69.6498C69.6004 17.8857 69.4916 17.5546 69.3236 17.2483C69.1556 16.932 68.9135 16.68 68.5972 16.4922C68.2908 16.3044 67.9202 16.2105 67.4853 16.2105C67.001 16.2105 66.5958 16.3291 66.2697 16.5663C65.9435 16.7936 65.7014 17.105 65.5432 17.5003C65.3851 17.8956 65.306 18.3552 65.306 18.879C65.306 19.4819 65.39 19.9958 65.558 20.4208C65.7359 20.8458 65.9929 21.167 66.329 21.3845C66.665 21.6019 67.0653 21.7106 67.5298 21.7106C67.9548 21.7106 68.3106 21.6266 68.5972 21.4586C68.8838 21.2807 69.1309 21.0237 69.3384 20.6877H70.8654C70.7073 21.1621 70.475 21.5722 70.1687 21.9182C69.8623 22.2542 69.4867 22.5161 69.0419 22.7039C68.5972 22.8917 68.0931 22.9856 67.5298 22.9856Z"
          fill="black"
        />
        <path
          d="M59.1317 22.9848C58.4794 22.9848 57.9012 22.8316 57.3972 22.5252C56.8931 22.209 56.4978 21.7494 56.2112 21.1465C55.9246 20.5337 55.7812 19.8023 55.7812 18.9524C55.7812 18.1024 55.9246 17.376 56.2112 16.7731C56.4978 16.1702 56.8931 15.7156 57.3972 15.4092C57.9012 15.0929 58.4794 14.9348 59.1317 14.9348C59.6654 14.9348 60.1299 15.0336 60.5253 15.2313C60.9206 15.4191 61.2418 15.6711 61.4889 15.9874H61.563V12.459H62.9862V22.8366H61.8002L61.6372 21.8433H61.563C61.2962 22.1991 60.9601 22.4807 60.5549 22.6883C60.1497 22.886 59.6753 22.9848 59.1317 22.9848ZM59.3837 21.6802C59.8087 21.6802 60.1843 21.5814 60.5105 21.3837C60.8366 21.1761 61.0936 20.8698 61.2814 20.4645C61.4691 20.0494 61.563 19.5454 61.563 18.9524C61.563 18.3693 61.4691 17.8751 61.2814 17.4699C61.0936 17.0548 60.8366 16.7484 60.5105 16.5507C60.1843 16.3432 59.8087 16.2394 59.3837 16.2394C58.9489 16.2394 58.5684 16.3432 58.2422 16.5507C57.9161 16.7484 57.6591 17.0548 57.4713 17.4699C57.2934 17.8751 57.2045 18.3693 57.2045 18.9524C57.2045 19.832 57.4021 20.509 57.7975 20.9834C58.2027 21.4479 58.7314 21.6802 59.3837 21.6802Z"
          fill="black"
        />
        <path
          d="M51.3083 22.9856C50.7746 22.9856 50.3002 22.8818 49.8851 22.6742C49.4799 22.4667 49.1686 22.1801 48.9512 21.8144C48.7337 21.4487 48.625 21.0385 48.625 20.5839C48.625 20.1293 48.7387 19.7241 48.966 19.3683C49.1933 19.0026 49.5244 18.7159 49.9593 18.5084C50.404 18.3008 50.9377 18.1971 51.5604 18.1971H54.051V19.3831H51.59C51.0761 19.3831 50.6906 19.4869 50.4337 19.6944C50.1767 19.902 50.0482 20.1836 50.0482 20.5394C50.0482 20.9051 50.1718 21.1967 50.4188 21.4141C50.6758 21.6217 51.0316 21.7254 51.4862 21.7254C51.9112 21.7254 52.2819 21.6414 52.5981 21.4734C52.9243 21.3054 53.1714 21.0781 53.3394 20.7915C53.5173 20.495 53.6062 20.1639 53.6062 19.7982V17.7227C53.6062 17.2581 53.453 16.8925 53.1467 16.6256C52.8403 16.3489 52.435 16.2105 51.931 16.2105C51.5258 16.2105 51.1848 16.2995 50.9081 16.4774C50.6412 16.6454 50.4534 16.8776 50.3447 17.1741H48.8622C49.0401 16.4823 49.3959 15.9387 49.9296 15.5434C50.4633 15.1382 51.1403 14.9355 51.9606 14.9355C52.5932 14.9355 53.1417 15.0492 53.6062 15.2765C54.0708 15.5038 54.4266 15.83 54.6736 16.255C54.9207 16.68 55.0443 17.1791 55.0443 17.7523V22.8373H53.8583L53.6804 21.844H53.6062C53.4382 22.1405 53.1467 22.4074 52.7316 22.6446C52.3263 22.8719 51.8519 22.9856 51.3083 22.9856Z"
          fill="black"
        />
        <path
          d="M37 22.8366V12.459H39.4165L42.3074 20.5683H42.3963L45.2872 12.459H47.6889V22.8366H46.1174V14.5048H46.0433L43.019 22.8366H41.6699L38.6456 14.5048H38.5715V22.8366H37Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_471_10329">
          <rect width="175" height="36" rx="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
