import { cva } from 'class-variance-authority'

export const tooltipStyles = cva(
  [
    'py-2 px-3',
    'w-max max-w-[calc(100vw-20px)] sm:max-w-md rounded-base',
    'text-center text-xs font-normal pointer-events-none',
    'shadow-popover',
  ],
  {
    variants: {
      inverted: {
        true: 'bg-surface-inverse text-content-on-inverse',
        false: 'bg-surface text-content',
      },
    },
    defaultVariants: {
      inverted: true,
    },
  },
)
