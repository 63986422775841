import { useMemo } from 'react'

import type {
  Member,
  MemberListFilterByInput,
  MemberListFilterByOperator,
  SpaceMember,
} from '@tribeplatform/gql-client/types'
import type {
  Filter,
  LegacyFilter,
} from '@tribeplatform/react-components/Filters'
import { useStaticIntl } from '@tribeplatform/react-components/i18n'
import { convertToStatic } from '@tribeplatform/react-components/Views'
import { useMembers, useSpaceMembers } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'

import { staticFieldsFactory } from '../config.js'
import { useCustomFields } from '../hooks/useCustomFields.js'
import { useShowMoreButton } from '../hooks/useShowMoreButton.js'
import type { MembersBlockView } from '../types.js'
import { getOrderBy } from '../utils/utils.js'
import { useMemberFilters } from './useMemberFilters.js'

export const useMembersList = (
  view: MembersBlockView,
  storedFilters: LegacyFilter[],
  setStoredFilters: (newFilters: Filter[]) => void,
) => {
  const {
    spaceId,
    sort,
    roleIds,
    fields,
    limit,
    style,
    gallerySize,
    showMore,
    filterBy,
    inlineFilters: rawInlineFilters,
  } = view
  const staticFields = useStaticIntl(staticFieldsFactory)
  const { memberFields } = useCustomFields({ excludePrivateFields: false })
  const { orderBy, reverse } = getOrderBy(sort)
  const { combinedFilters, inlineFilters, setInlineFilters, hasInlineFilters } =
    useMemberFilters({
      filterBy,
      rawInlineFilters,
      storedFilters,
      setStoredFilters,
    })
  const legacyListFilters = combinedFilters.map<MemberListFilterByInput>(
    ({ key, operator, value }) => ({
      key,
      operator: operator as unknown as MemberListFilterByOperator,
      value,
    }),
  )
  const mergedStaticFields = convertToStatic(
    fields,
    staticFields,
    memberFields ?? [],
  )

  const {
    data: membersData,
    isInitialLoading: isMembersInitialLoading,
    fetchNextPage: membersFetchNextPage,
    hasNextPage: membersHasNextPage,
    isFetchingNextPage: membersIsFetchingNextPage,
  } = useMembers({
    fields: {
      profilePicture: 'basic',
      role: 'basic',
      badges: 'default',
      fields: 'basic',
      authMemberProps: 'basic',
    },
    variables: {
      limit,
      orderBy,
      reverse,
      filterBy: legacyListFilters,
    },
    useInfiniteQueryOptions: {
      enabled: !spaceId,
      refetchOnMount: 'always',
    },
  })

  const {
    data: spaceMembersData,
    isInitialLoading: isSpaceMembersInitialLoading,
    fetchNextPage: spaceMembersFetchNextPage,
    hasNextPage: spaceMembersHasNextPage,
    isFetchingNextPage: spaceMembersIsFetchingNextPage,
  } = useSpaceMembers({
    fields: {
      member: {
        profilePicture: 'basic',
        role: 'basic',
        badges: 'default',
        fields: 'basic',
        authMemberProps: 'basic',
      },
      role: 'basic',
    },
    variables: { limit, spaceId, ...(roleIds ? { roleIds } : {}) },
    useInfiniteQueryOptions: {
      enabled: !!spaceId,
      refetchOnMount: 'always',
    },
  })

  const members = useMemo(() => {
    if (spaceId) {
      const { nodes: spaceMembers } =
        simplifyPaginatedResult<SpaceMember>(spaceMembersData)
      const nodes = spaceMembers
        .filter(
          ({ role }) =>
            !roleIds || (roleIds?.length > 0 && roleIds.includes(role.id)),
        )
        .map(({ member }) => member)

      return nodes
    }

    const { nodes } = simplifyPaginatedResult<Member>(membersData)
    return nodes
  }, [membersData, roleIds, spaceId, spaceMembersData])

  const showMoreButton = useShowMoreButton({
    style,
    gallerySize,
    showMore,
    fetchNextPage: spaceId ? spaceMembersFetchNextPage : membersFetchNextPage,
    hasNextPage: spaceId ? spaceMembersHasNextPage : membersHasNextPage,
    isFetchingNextPage: spaceId
      ? spaceMembersIsFetchingNextPage
      : membersIsFetchingNextPage,
  })

  const isLoading =
    members.length === 0 &&
    (isMembersInitialLoading || isSpaceMembersInitialLoading)

  return {
    members,
    showMoreButton,
    mergedStaticFields,
    isLoading,
    inlineFilters,
    setInlineFilters,
    hasInlineFilters,
    view,
  }
}
