import { PropsWithChildren } from 'react'

import { SlateKitContext } from '../hooks/index.js'
import { SlateKitProps } from '../types/index.js'

export const SlateKitProvider = ({
  children,
  ...props
}: PropsWithChildren<SlateKitProps>) => {
  return (
    <SlateKitContext.Provider value={props}>
      {children}
    </SlateKitContext.Provider>
  )
}
