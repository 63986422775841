import { Component, FC, ReactNode } from 'react'

import { T } from '@tribeplatform/react-components/i18n'
import { Card } from '@tribeplatform/react-ui-kit/Card'

export const ErrorFallback: FC = () => {
  return (
    <Card>
      <Card.Content>
        <h4 className="text-lg font-medium">
          <T
            defaultMessage="Something went wrong"
            id="Generics.SomethingWentWrong"
          />
        </h4>
      </Card.Content>
    </Card>
  )
}

export class ErrorBoundary extends Component<
  { children: ReactNode },
  { hasError: boolean }
> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    logger.error({ error, errorInfo })
  }

  render() {
    if (this.state?.hasError) {
      // You can render any custom fallback UI
      return <ErrorFallback />
    }

    return this.props.children
  }
}
