import { lazy } from 'react'

import {
  GlyphMediaVariant,
  type Image,
  type File,
} from '@tribeplatform/gql-client/types'
import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { SpaceHeaderBlock as Component } from './Block.js'
import {
  MediaType,
  Alignment,
  TextColor,
  SpaceHeaderPreset,
  SpaceHeaderFieldKey,
  SpaceHeaderIconSize,
  ActionButtonVariant,
  MembersAvatarSize,
  IconWrapperStyle,
  type SpaceHeaderProps,
} from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.SpaceHeaderBlockSettings })),
)

export const RegisteredSpaceHeaderBlock = (
  intl: I18nShape,
): RegisteredBlock<SpaceHeaderProps> => {
  const { $t } = intl

  return {
    recommendedName: 'SpaceHeader',
    Component,
    config: {
      type: BlockType.Block,
      usable: true,
      fullSidebarSettings: true,
      displayName: $t({
        id: 'Blocks.SpaceHeader.DisplayName',
        defaultMessage: 'Space header',
      }),
      Settings,
      Icon: <SvgIcon name="image" />,
      initialProps: {
        preset: SpaceHeaderPreset.MediaFullIconTop,
        contentSpacing: 1,
        mediaAspectRatio: 3,
        textColor: TextColor.Light,
        contentAlignment: Alignment.Start,
        mediaType: MediaType.Video,
        useSpaceBanner: false,
        image: {
          url: 'https://tribe-s3-production.imgix.net/v2Ovu9geWx4wWYEdFOQTg?auto=compress,format&dl',
        } as Image,
        video: {
          url: 'https://files-us-east-1.t-cdn.net/files/olKsuhtZHKmyRhBPwwDj8',
          downloadUrl:
            'https://files-us-east-1.t-cdn.net/files/olKsuhtZHKmyRhBPwwDj8',
        } as File,
        mediaTint: 25,
        fields: [
          {
            id: 'Icon',
            enabled: true,
            key: SpaceHeaderFieldKey.Icon,
            settings: {
              stretchX: true,
              size: SpaceHeaderIconSize.Large,
              iconWrapperStyle: IconWrapperStyle.Circle,
              useSpaceIcon: true,
              customIcon: {
                id: 'emoji/1f508',
                text: '1f508',
                variant: GlyphMediaVariant.emoji,
              },
            },
          },
          {
            id: 'Title',
            key: SpaceHeaderFieldKey.Title,
            enabled: true,
            settings: {
              stretchX: true,
              customTitle: $t(
                {
                  id: 'Blocks.SpaceHeader.CustomTitlePlaceholder',
                  defaultMessage: 'Welcome to {space_name_liquid_var}',
                },
                {
                  space_name_liquid_var: '{{ space.name }}',
                },
              ),
              useSpaceName: true,
              showPrivateIcon: true,
              size: 'headingLg',
            },
          },
          {
            id: 'Description',
            key: SpaceHeaderFieldKey.Description,
            enabled: false,
            settings: {
              stretchX: true,
              customDescription: $t({
                id: 'Blocks.SpaceHeader.CustomDescriptionPlaceholder',
                defaultMessage: 'Description goes here...',
              }),
              useSpaceDescription: true,
            },
          },
          {
            enabled: false,
            id: 'Stats',
            key: SpaceHeaderFieldKey.Stats,
            settings: {
              stretchX: true,
            },
            fields: [
              {
                enabled: true,
                id: 'TotalMembers',
                key: SpaceHeaderFieldKey.TotalMembers,
                settings: {
                  isSubField: true,
                  stretchX: false,
                  showText: true,
                },
              },
              {
                enabled: true,
                id: 'TotalPosts',
                key: SpaceHeaderFieldKey.TotalPosts,
                settings: {
                  isSubField: true,
                  stretchX: false,
                  showText: true,
                },
              },
              {
                enabled: false,
                id: 'DateCreated',
                key: SpaceHeaderFieldKey.DateCreated,
                settings: {
                  isSubField: true,
                  stretchX: false,
                  format: 'relative',
                },
              },
            ],
          },
          {
            enabled: true,
            id: 'Members',
            key: SpaceHeaderFieldKey.Members,
            settings: {
              stretchX: false,
              showCount: true,
              size: MembersAvatarSize.Small,
            },
          },
          {
            enabled: true,
            id: 'Buttons',
            key: SpaceHeaderFieldKey.Buttons,
            fields: [
              {
                enabled: true,
                id: 'AddPostButton',
                key: SpaceHeaderFieldKey.AddPostButton,
                settings: {
                  stretchX: true,
                  isSubField: true,
                },
              },
              {
                enabled: true,
                id: 'JoinButton',
                key: SpaceHeaderFieldKey.JoinButton,
                settings: {
                  stretchX: true,
                  isSubField: true,
                  variant: ActionButtonVariant.IconText,
                },
              },
              {
                enabled: true,
                id: 'NotificationsButton',
                key: SpaceHeaderFieldKey.NotificationsButton,
                settings: {
                  stretchX: true,
                  isSubField: true,
                  variant: ActionButtonVariant.Icon,
                },
              },
              {
                enabled: false,
                id: 'SearchButton',
                key: SpaceHeaderFieldKey.SearchButton,
                settings: {
                  stretchX: true,
                  isSubField: true,
                  variant: ActionButtonVariant.Icon,
                  icon: {
                    id: 'icon/search-md',
                    text: 'search-md',
                    variant: GlyphMediaVariant.icon,
                  },
                },
              },
              {
                enabled: false,
                id: 'ShareButton',
                key: SpaceHeaderFieldKey.ShareButton,
                settings: {
                  stretchX: true,
                  isSubField: true,
                  variant: ActionButtonVariant.Text,
                  icon: {
                    id: 'icon/share-06',
                    text: 'share-06',
                    variant: GlyphMediaVariant.icon,
                  },
                },
              },
              {
                enabled: true,
                id: 'OptionsButton',
                key: SpaceHeaderFieldKey.OptionsButton,
                settings: {
                  stretchX: true,
                  isSubField: true,
                  variant: ActionButtonVariant.Icon,
                },
              },
            ],
            settings: {
              stretchX: false,
            },
          },
        ],
      },
    },
    meta: {
      releaseDate: '2024-10-25',
      releaseMessage: $t({
        id: 'Blocks.SpaceHeader.ReleaseMessage',
        defaultMessage: 'Discover the updated layouts and settings',
      }),
    },
  }
}
