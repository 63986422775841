import { LegacyThemeModeColors } from '../types.js'

export type ColorTemplate = {
  name: string
  colors: LegacyThemeModeColors
}

export const colorTemplates: ColorTemplate[] = [
  {
    name: 'Default',
    colors: {
      light: {
        main: {
          '50': '#f3f3f3',
          '100': '#e8e8e8',
          '200': '#dddddd',
          '300': '#d2d2d2',
          '400': '#c8c8c8',
          '500': '#bdbdbd',
          '600': '#b3b3b3',
          '700': '#a8a8a8',
          '800': '#9e9e9e',
          '900': '#949494',
        },
        surface: {
          '50': '#ffffff',
          '100': '#f3f3f3',
          '200': '#e8e8e8',
          '300': '#dddddd',
          '400': '#d1d1d1',
          '500': '#c6c6c6',
          '600': '#bbbbbb',
          '700': '#b0b0b0',
          '800': '#a6a6a6',
          '900': '#9b9b9b',
        },
        actionPrimary: {
          '50': '#d4e7d6',
          '100': '#b1d4b6',
          '200': '#8fc197',
          '300': '#6cae79',
          '400': '#469b5c',
          '500': '#09883f',
          '600': '#00732c',
          '700': '#005f1a',
          '800': '#004b06',
          '900': '#003800',
        },
        actionSecondary: {
          '50': '#ffffff',
          '100': '#f3f3f3',
          '200': '#e8e8e8',
          '300': '#dddddd',
          '400': '#d1d1d1',
          '500': '#c6c6c6',
          '600': '#bbbbbb',
          '700': '#b0b0b0',
          '800': '#a6a6a6',
          '900': '#9b9b9b',
        },
        actionAccent: {
          '50': '#daf1e0',
          '100': '#bde6c7',
          '200': '#9edaaf',
          '300': '#7fce97',
          '400': '#5cc280',
          '500': '#2db669',
          '600': '#00994f',
          '700': '#007d36',
          '800': '#00621d',
          '900': '#004704',
        },
        actionAccentHover: {
          '50': '#d3e6d4',
          '100': '#b0d2b3',
          '200': '#8dbd92',
          '300': '#69a972',
          '400': '#429553',
          '500': '#008135',
          '600': '#006d23',
          '700': '#005a11',
          '800': '#004800',
          '900': '#003600',
        },
        basicMain: {
          '50': '#d8d8d8',
          '100': '#bababa',
          '200': '#9d9d9d',
          '300': '#808080',
          '400': '#656565',
          '500': '#4b4b4b',
          '600': '#3f3f3f',
          '700': '#343434',
          '800': '#292929',
          '900': '#1e1e1e',
        },
        basicSurface: {
          '50': '#dadada',
          '100': '#bcbcbc',
          '200': '#a0a0a0',
          '300': '#858585',
          '400': '#6a6a6a',
          '500': '#515151',
          '600': '#444444',
          '700': '#383838',
          '800': '#2c2c2c',
          '900': '#202020',
        },
        basicPrimary: {
          '50': '#ffffff',
          '100': '#ffffff',
          '200': '#ffffff',
          '300': '#ffffff',
          '400': '#ffffff',
          '500': '#ffffff',
          '600': '#d1d1d1',
          '700': '#a6a6a6',
          '800': '#7c7c7c',
          '900': '#555555',
        },
        basicSecondary: {
          '50': '#dadada',
          '100': '#bcbcbc',
          '200': '#a0a0a0',
          '300': '#858585',
          '400': '#6a6a6a',
          '500': '#515151',
          '600': '#444444',
          '700': '#383838',
          '800': '#2c2c2c',
          '900': '#202020',
        },
        success: {
          '50': '#ecfdf5',
          '100': '#d1fae5',
          '200': '#a7f3d0',
          '300': '#6ee7b7',
          '400': '#34d399',
          '500': '#10b981',
          '600': '#059669',
          '700': '#047857',
          '800': '#065f46',
          '900': '#064e3b',
        },
        danger: {
          '50': '#fef2f2',
          '100': '#fee2e2',
          '200': '#fecaca',
          '300': '#fca5a5',
          '400': '#f87171',
          '500': '#ef4444',
          '600': '#dc2626',
          '700': '#b91c1c',
          '800': '#991b1b',
          '900': '#7f1d1d',
        },
        info: {
          '50': '#eff6ff',
          '100': '#dbeafe',
          '200': '#bfdbfe',
          '300': '#93c5fd',
          '400': '#60a5fa',
          '500': '#3b82f6',
          '600': '#2563eb',
          '700': '#1d4ed8',
          '800': '#1e40af',
          '900': '#1e3a8a',
        },
        warning: {
          '50': '#fffbeb',
          '100': '#fef3c7',
          '200': '#fde68a',
          '300': '#fcd34d',
          '400': '#fbbf24',
          '500': '#f59e0b',
          '600': '#d97706',
          '700': '#b45309',
          '800': '#92400e',
          '900': '#78350f',
        },
      },
      dark: {
        main: {
          '50': '#747474',
          '100': '#6c6c6c',
          '200': '#646564',
          '300': '#5c5d5c',
          '400': '#545554',
          '500': '#4d4e4d',
          '600': '#454645',
          '700': '#3e3f3e',
          '800': '#373837',
          '900': '#303130',
        },
        surface: {
          '50': '#656765',
          '100': '#5d5e5d',
          '200': '#545654',
          '300': '#4c4d4c',
          '400': '#434543',
          '500': '#3b3d3b',
          '600': '#343534',
          '700': '#2c2e2c',
          '800': '#242624',
          '900': '#1d1f1d',
        },
        actionPrimary: {
          '50': '#b1d4b6',
          '100': '#8fc197',
          '200': '#6cae79',
          '300': '#459b5c',
          '400': '#07883f',
          '500': '#00732c',
          '600': '#005f1a',
          '700': '#004b06',
          '800': '#003800',
          '900': '#002900',
        },
        actionSecondary: {
          '50': '#a5a6a5',
          '100': '#7f817f',
          '200': '#5c5e5c',
          '300': '#3a3d3a',
          '400': '#1c1f1c',
          '500': '#181b18',
          '600': '#151815',
          '700': '#101410',
          '800': '#0b100b',
          '900': '#040a04',
        },
        actionAccent: {
          '50': '#c0e4cc',
          '100': '#a4d7b5',
          '200': '#86cba0',
          '300': '#68be8a',
          '400': '#44b175',
          '500': '#20955b',
          '600': '#007942',
          '700': '#005f2b',
          '800': '#004514',
          '900': '#002d00',
        },
        actionAccentHover: {
          '50': '#dbf6e6',
          '100': '#caf1db',
          '200': '#b9edd0',
          '300': '#a8e9c5',
          '400': '#96e4ba',
          '500': '#71be96',
          '600': '#4d9973',
          '700': '#287652',
          '800': '#005433',
          '900': '#003416',
        },
        basicMain: {
          '50': '#f0f3f0',
          '100': '#eaeee9',
          '200': '#e3e9e2',
          '300': '#dce4dc',
          '400': '#d6dfd5',
          '500': '#afb8ae',
          '600': '#8a9289',
          '700': '#676e66',
          '800': '#454d44',
          '900': '#262d25',
        },
        basicSurface: {
          '50': '#f1f4f1',
          '100': '#ebefeb',
          '200': '#e5eae5',
          '300': '#dfe5df',
          '400': '#d9e0d9',
          '500': '#b2b9b2',
          '600': '#8c938c',
          '700': '#686f69',
          '800': '#474d47',
          '900': '#272d28',
        },
        basicPrimary: {
          '50': '#ffffff',
          '100': '#ffffff',
          '200': '#ffffff',
          '300': '#ffffff',
          '400': '#ffffff',
          '500': '#d1d1d1',
          '600': '#a6a6a6',
          '700': '#7c7c7c',
          '800': '#555555',
          '900': '#303030',
        },
        basicSecondary: {
          '50': '#f8f9f7',
          '100': '#f5f6f4',
          '200': '#f1f3f1',
          '300': '#eef1ed',
          '400': '#ebeeea',
          '500': '#c1c4c0',
          '600': '#999b98',
          '700': '#727571',
          '800': '#4e504d',
          '900': '#2c2e2b',
        },
        success: {
          '50': '#ecfdf5',
          '100': '#d1fae5',
          '200': '#a7f3d0',
          '300': '#6ee7b7',
          '400': '#34d399',
          '500': '#10b981',
          '600': '#059669',
          '700': '#047857',
          '800': '#065f46',
          '900': '#064e3b',
        },
        danger: {
          '50': '#fef2f2',
          '100': '#fee2e2',
          '200': '#fecaca',
          '300': '#fca5a5',
          '400': '#f87171',
          '500': '#ef4444',
          '600': '#dc2626',
          '700': '#b91c1c',
          '800': '#991b1b',
          '900': '#7f1d1d',
        },
        info: {
          '50': '#eff6ff',
          '100': '#dbeafe',
          '200': '#bfdbfe',
          '300': '#93c5fd',
          '400': '#60a5fa',
          '500': '#3b82f6',
          '600': '#2563eb',
          '700': '#1d4ed8',
          '800': '#1e40af',
          '900': '#1e3a8a',
        },
        warning: {
          '50': '#fffbeb',
          '100': '#fef3c7',
          '200': '#fde68a',
          '300': '#fcd34d',
          '400': '#fbbf24',
          '500': '#f59e0b',
          '600': '#d97706',
          '700': '#b45309',
          '800': '#92400e',
          '900': '#78350f',
        },
      },
    },
  },
  {
    name: 'Vigilate',
    colors: {
      light: {
        main: {
          '50': '#f2f0ec',
          '100': '#e7e5e1',
          '200': '#dcdad7',
          '300': '#d2d0cc',
          '400': '#c7c5c1',
          '500': '#bdbbb7',
          '600': '#b2b1ad',
          '700': '#a8a6a3',
          '800': '#9e9c99',
          '900': '#94928f',
        },
        surface: {
          '50': '#ffffff',
          '100': '#f3f3f3',
          '200': '#e8e8e8',
          '300': '#dddddd',
          '400': '#d1d1d1',
          '500': '#c6c6c6',
          '600': '#bbbbbb',
          '700': '#b0b0b0',
          '800': '#a6a6a6',
          '900': '#9b9b9b',
        },
        actionPrimary: {
          '50': '#ffe4e3',
          '100': '#ffcfcd',
          '200': '#feb9b8',
          '300': '#fba4a3',
          '400': '#f78e8e',
          '500': '#cc4465',
          '600': '#d35b60',
          '700': '#b34048',
          '800': '#952331',
          '900': '#77001b',
        },
        actionSecondary: {
          '50': '#e8e6df',
          '100': '#dedcd5',
          '200': '#d3d1cb',
          '300': '#c9c7c0',
          '400': '#bfbdb6',
          '500': '#b5b3ad',
          '600': '#aba9a3',
          '700': '#a2a099',
          '800': '#989690',
          '900': '#8e8c86',
        },
        actionAccent: {
          '50': '#ffe4e3',
          '100': '#ffcfcd',
          '200': '#feb9b8',
          '300': '#fba4a3',
          '400': '#f78e8e',
          '500': '#cc4465',
          '600': '#d35b60',
          '700': '#b34048',
          '800': '#952331',
          '900': '#77001b',
        },
        actionAccentHover: {
          '50': '#f0f0f0',
          '100': '#e5e5e5',
          '200': '#dadada',
          '300': '#cecece',
          '400': '#c3c3c3',
          '500': '#7C283C',
          '600': '#989898',
          '700': '#797979',
          '800': '#5c5c5c',
          '900': '#404040',
        },
        basicMain: {
          '50': '#dadada',
          '100': '#bcbcbc',
          '200': '#a0a0a0',
          '300': '#858585',
          '400': '#6a6a6a',
          '500': '#515151',
          '600': '#444444',
          '700': '#383838',
          '800': '#2c2c2c',
          '900': '#202020',
        },
        basicSurface: {
          '50': '#dadada',
          '100': '#bcbcbc',
          '200': '#a0a0a0',
          '300': '#858585',
          '400': '#6a6a6a',
          '500': '#515151',
          '600': '#444444',
          '700': '#383838',
          '800': '#2c2c2c',
          '900': '#202020',
        },
        basicPrimary: {
          '50': '#ffffff',
          '100': '#ffffff',
          '200': '#ffffff',
          '300': '#ffffff',
          '400': '#ffffff',
          '500': '#ffffff',
          '600': '#d1d1d1',
          '700': '#a6a6a6',
          '800': '#7c7c7c',
          '900': '#555555',
        },
        basicSecondary: {
          '50': '#dadada',
          '100': '#bcbcbc',
          '200': '#a0a0a0',
          '300': '#858585',
          '400': '#6a6a6a',
          '500': '#515151',
          '600': '#444444',
          '700': '#383838',
          '800': '#2c2c2c',
          '900': '#202020',
        },
        success: {
          '50': '#ecfdf5',
          '100': '#d1fae5',
          '200': '#a7f3d0',
          '300': '#6ee7b7',
          '400': '#34d399',
          '500': '#10b981',
          '600': '#059669',
          '700': '#047857',
          '800': '#065f46',
          '900': '#064e3b',
        },
        danger: {
          '50': '#fef2f2',
          '100': '#fee2e2',
          '200': '#fecaca',
          '300': '#fca5a5',
          '400': '#f87171',
          '500': '#ef4444',
          '600': '#dc2626',
          '700': '#b91c1c',
          '800': '#991b1b',
          '900': '#7f1d1d',
        },
        info: {
          '50': '#eff6ff',
          '100': '#dbeafe',
          '200': '#bfdbfe',
          '300': '#93c5fd',
          '400': '#60a5fa',
          '500': '#3b82f6',
          '600': '#2563eb',
          '700': '#1d4ed8',
          '800': '#1e40af',
          '900': '#1e3a8a',
        },
        warning: {
          '50': '#fffbeb',
          '100': '#fef3c7',
          '200': '#fde68a',
          '300': '#fcd34d',
          '400': '#fbbf24',
          '500': '#f59e0b',
          '600': '#d97706',
          '700': '#b45309',
          '800': '#92400e',
          '900': '#78350f',
        },
      },
      dark: {
        main: {
          '50': '#6f6e6a',
          '100': '#676561',
          '200': '#5f5d59',
          '300': '#575551',
          '400': '#4f4d49',
          '500': '#474641',
          '600': '#403e39',
          '700': '#383632',
          '800': '#312f2a',
          '900': '#2a2823',
        },
        surface: {
          '50': '#646464',
          '100': '#5b5b5c',
          '200': '#525253',
          '300': '#4a4a4b',
          '400': '#424243',
          '500': '#3a3a3a',
          '600': '#323233',
          '700': '#2a2a2b',
          '800': '#222223',
          '900': '#1b1b1c',
        },
        actionPrimary: {
          '50': '#dbb7b4',
          '100': '#ca9894',
          '200': '#b87976',
          '300': '#a45b59',
          '400': '#903d3d',
          '500': '#7f2e30',
          '600': '#6f1f23',
          '700': '#5e0f17',
          '800': '#4f0008',
          '900': '#400000',
        },
        actionSecondary: {
          '50': '#b0afac',
          '100': '#8e8d89',
          '200': '#6e6c68',
          '300': '#504e48',
          '400': '#33312b',
          '500': '#2c2a24',
          '600': '#25231e',
          '700': '#1e1d17',
          '800': '#181610',
          '900': '#110e05',
        },
        actionAccent: {
          '50': '#dbb7b4',
          '100': '#ca9894',
          '200': '#b87976',
          '300': '#a45b59',
          '400': '#903d3d',
          '500': '#7f2e30',
          '600': '#6f1f23',
          '700': '#5e0f17',
          '800': '#4f0008',
          '900': '#400000',
        },
        actionAccentHover: {
          '50': '#f3c8c6',
          '100': '#ecb0ad',
          '200': '#e39895',
          '300': '#da807e',
          '400': '#cf6767',
          '500': '#b45051',
          '600': '#9a393d',
          '700': '#812129',
          '800': '#670317',
          '900': '#4f0000',
        },
        basicMain: {
          '50': '#e7e5e3',
          '100': '#dddad6',
          '200': '#d2cfca',
          '300': '#c8c4be',
          '400': '#beb9b2',
          '500': '#9d9992',
          '600': '#7e7a73',
          '700': '#605c56',
          '800': '#44403a',
          '900': '#292520',
        },
        basicSurface: {
          '50': '#f3f3f0',
          '100': '#eeedea',
          '200': '#e9e8e4',
          '300': '#e4e2dd',
          '400': '#dfddd7',
          '500': '#b8b6b0',
          '600': '#92908b',
          '700': '#6e6c67',
          '800': '#4c4b46',
          '900': '#2d2b27',
        },
        basicPrimary: {
          '50': '#ffffff',
          '100': '#ffffff',
          '200': '#ffffff',
          '300': '#ffffff',
          '400': '#ffffff',
          '500': '#d1d1d1',
          '600': '#a6a6a6',
          '700': '#7c7c7c',
          '800': '#555555',
          '900': '#303030',
        },
        basicSecondary: {
          '50': '#ebe9e6',
          '100': '#e2dfdc',
          '200': '#d9d5d1',
          '300': '#d0ccc6',
          '400': '#c7c2bc',
          '500': '#a5a09a',
          '600': '#847f7a',
          '700': '#64605b',
          '800': '#46423d',
          '900': '#2a2722',
        },
        success: {
          '50': '#ecfdf5',
          '100': '#d1fae5',
          '200': '#a7f3d0',
          '300': '#6ee7b7',
          '400': '#34d399',
          '500': '#10b981',
          '600': '#059669',
          '700': '#047857',
          '800': '#065f46',
          '900': '#064e3b',
        },
        danger: {
          '50': '#fef2f2',
          '100': '#fee2e2',
          '200': '#fecaca',
          '300': '#fca5a5',
          '400': '#f87171',
          '500': '#ef4444',
          '600': '#dc2626',
          '700': '#b91c1c',
          '800': '#991b1b',
          '900': '#7f1d1d',
        },
        info: {
          '50': '#eff6ff',
          '100': '#dbeafe',
          '200': '#bfdbfe',
          '300': '#93c5fd',
          '400': '#60a5fa',
          '500': '#3b82f6',
          '600': '#2563eb',
          '700': '#1d4ed8',
          '800': '#1e40af',
          '900': '#1e3a8a',
        },
        warning: {
          '50': '#fffbeb',
          '100': '#fef3c7',
          '200': '#fde68a',
          '300': '#fcd34d',
          '400': '#fbbf24',
          '500': '#f59e0b',
          '600': '#d97706',
          '700': '#b45309',
          '800': '#92400e',
          '900': '#78350f',
        },
      },
    },
  },
  {
    name: 'Semita',
    colors: {
      light: {
        main: {
          '50': '#fdf6e3',
          '100': '#f2ebd8',
          '200': '#e7e0cd',
          '300': '#dcd5c2',
          '400': '#d1cab8',
          '500': '#c6bfad',
          '600': '#bbb5a3',
          '700': '#b1aa99',
          '800': '#a6a08e',
          '900': '#9c9684',
        },
        surface: {
          '50': '#fffcf5',
          '100': '#f4f1ea',
          '200': '#e8e5de',
          '300': '#dddad3',
          '400': '#d2cfc8',
          '500': '#c7c4bd',
          '600': '#bcb9b3',
          '700': '#b1aea8',
          '800': '#a6a49d',
          '900': '#9c9993',
        },
        actionPrimary: {
          '50': '#dce2e2',
          '100': '#c1cbcb',
          '200': '#a6b5b5',
          '300': '#8c9f9f',
          '400': '#738a8a',
          '500': '#5a7575',
          '600': '#486262',
          '700': '#365050',
          '800': '#253f3f',
          '900': '#142e2e',
        },
        actionSecondary: {
          '50': '#eee8d5',
          '100': '#e3decb',
          '200': '#d9d3c1',
          '300': '#cfc9b6',
          '400': '#c5bfad',
          '500': '#bab5a3',
          '600': '#b0ab99',
          '700': '#a6a18f',
          '800': '#9d9786',
          '900': '#938e7c',
        },
        actionAccent: {
          '50': '#e9ecec',
          '100': '#d7dcdc',
          '200': '#c6cdcd',
          '300': '#b4bebe',
          '400': '#a4afaf',
          '500': '#A15D00',
          '600': '#788686',
          '700': '#5e6b6b',
          '800': '#465252',
          '900': '#2e3a3a',
        },
        actionAccentHover: {
          '50': '#fcfaf7',
          '100': '#f9f7f0',
          '200': '#f6f3e9',
          '300': '#f4efe2',
          '400': '#f1ecdc',
          '500': '#4E2E00',
          '600': '#c5bfad',
          '700': '#9d9786',
          '800': '#767261',
          '900': '#524e3e',
        },
        basicMain: {
          '50': '#d7dcdc',
          '100': '#b7c1c1',
          '200': '#99a7a7',
          '300': '#7b8d8d',
          '400': '#5f7474',
          '500': '#435c5c',
          '600': '#354e4e',
          '700': '#284040',
          '800': '#1b3333',
          '900': '#0e2626',
        },
        basicSurface: {
          '50': '#dbdfe1',
          '100': '#bfc7ca',
          '200': '#a3afb4',
          '300': '#88979e',
          '400': '#6e8088',
          '500': '#546a73',
          '600': '#435962',
          '700': '#334951',
          '800': '#243941',
          '900': '#142a32',
        },
        basicPrimary: {
          '50': '#ffffff',
          '100': '#ffffff',
          '200': '#ffffff',
          '300': '#ffffff',
          '400': '#ffffff',
          '500': '#ffffff',
          '600': '#d1d1d1',
          '700': '#a6a6a6',
          '800': '#7c7c7c',
          '900': '#555555',
        },
        basicSecondary: {
          '50': '#d7dcdc',
          '100': '#b7c1c1',
          '200': '#99a7a7',
          '300': '#7b8d8d',
          '400': '#5f7474',
          '500': '#435c5c',
          '600': '#354e4e',
          '700': '#284040',
          '800': '#1b3333',
          '900': '#0e2626',
        },
        success: {
          '50': '#ecfdf5',
          '100': '#d1fae5',
          '200': '#a7f3d0',
          '300': '#6ee7b7',
          '400': '#34d399',
          '500': '#10b981',
          '600': '#059669',
          '700': '#047857',
          '800': '#065f46',
          '900': '#064e3b',
        },
        danger: {
          '50': '#fef2f2',
          '100': '#fee2e2',
          '200': '#fecaca',
          '300': '#fca5a5',
          '400': '#f87171',
          '500': '#ef4444',
          '600': '#dc2626',
          '700': '#b91c1c',
          '800': '#991b1b',
          '900': '#7f1d1d',
        },
        info: {
          '50': '#eff6ff',
          '100': '#dbeafe',
          '200': '#bfdbfe',
          '300': '#93c5fd',
          '400': '#60a5fa',
          '500': '#3b82f6',
          '600': '#2563eb',
          '700': '#1d4ed8',
          '800': '#1e40af',
          '900': '#1e3a8a',
        },
        warning: {
          '50': '#fffbeb',
          '100': '#fef3c7',
          '200': '#fde68a',
          '300': '#fcd34d',
          '400': '#fbbf24',
          '500': '#f59e0b',
          '600': '#d97706',
          '700': '#b45309',
          '800': '#92400e',
          '900': '#78350f',
        },
      },
      dark: {
        main: {
          '50': '#706d67',
          '100': '#68655e',
          '200': '#605c56',
          '300': '#58544e',
          '400': '#504c45',
          '500': '#49453d',
          '600': '#413d35',
          '700': '#39352e',
          '800': '#322e26',
          '900': '#2b271f',
        },
        surface: {
          '50': '#686661',
          '100': '#5f5d58',
          '200': '#57554f',
          '300': '#4f4d46',
          '400': '#46443e',
          '500': '#3e3c36',
          '600': '#37342e',
          '700': '#2f2d26',
          '800': '#27251e',
          '900': '#201e17',
        },
        actionPrimary: {
          '50': '#c7d2d1',
          '100': '#afbebe',
          '200': '#98abaa',
          '300': '#819897',
          '400': '#6a8585',
          '500': '#556f6f',
          '600': '#405a5a',
          '700': '#2d4646',
          '800': '#1a3333',
          '900': '#072121',
        },
        actionSecondary: {
          '50': '#aeadaa',
          '100': '#8c8a86',
          '200': '#6b6964',
          '300': '#4c4a44',
          '400': '#2f2d26',
          '500': '#292720',
          '600': '#22211a',
          '700': '#1d1b14',
          '800': '#17150c',
          '900': '#100d02',
        },
        actionAccent: {
          '50': '#d7dcdc',
          '100': '#c6cdcd',
          '200': '#b4bebe',
          '300': '#a4afaf',
          '400': '#93a1a1',
          '500': '#788686',
          '600': '#5e6b6b',
          '700': '#465252',
          '800': '#2e3a3a',
          '900': '#182424',
        },
        actionAccentHover: {
          '50': '#f9f7f0',
          '100': '#f6f3e9',
          '200': '#f4efe2',
          '300': '#f1ecdc',
          '400': '#eee8d5',
          '500': '#c5bfad',
          '600': '#9d9786',
          '700': '#767261',
          '800': '#524e3e',
          '900': '#312d1e',
        },
        basicMain: {
          '50': '#e2ddd7',
          '100': '#d5cfc6',
          '200': '#c8c0b5',
          '300': '#bcb2a4',
          '400': '#afa494',
          '500': '#928878',
          '600': '#766c5d',
          '700': '#5b5244',
          '800': '#41392c',
          '900': '#292215',
        },
        basicSurface: {
          '50': '#fcf6ee',
          '100': '#faf2e6',
          '200': '#f8efdf',
          '300': '#f7ebd7',
          '400': '#f5e7d0',
          '500': '#cbbea8',
          '600': '#a39681',
          '700': '#7c705c',
          '800': '#574d3a',
          '900': '#352c1a',
        },
        basicPrimary: {
          '50': '#9b9b9b',
          '100': '#727272',
          '200': '#4b4b4b',
          '300': '#282828',
          '400': '#000000',
          '500': '#000000',
          '600': '#000000',
          '700': '#000000',
          '800': '#000000',
          '900': '#000000',
        },
        basicSecondary: {
          '50': '#e9e5de',
          '100': '#e0d9cf',
          '200': '#d6cec1',
          '300': '#cdc2b3',
          '400': '#c3b7a5',
          '500': '#a29786',
          '600': '#837867',
          '700': '#655b4b',
          '800': '#483f30',
          '900': '#2d2517',
        },
        success: {
          '50': '#ecfdf5',
          '100': '#d1fae5',
          '200': '#a7f3d0',
          '300': '#6ee7b7',
          '400': '#34d399',
          '500': '#10b981',
          '600': '#059669',
          '700': '#047857',
          '800': '#065f46',
          '900': '#064e3b',
        },
        danger: {
          '50': '#fef2f2',
          '100': '#fee2e2',
          '200': '#fecaca',
          '300': '#fca5a5',
          '400': '#f87171',
          '500': '#ef4444',
          '600': '#dc2626',
          '700': '#b91c1c',
          '800': '#991b1b',
          '900': '#7f1d1d',
        },
        info: {
          '50': '#eff6ff',
          '100': '#dbeafe',
          '200': '#bfdbfe',
          '300': '#93c5fd',
          '400': '#60a5fa',
          '500': '#3b82f6',
          '600': '#2563eb',
          '700': '#1d4ed8',
          '800': '#1e40af',
          '900': '#1e3a8a',
        },
        warning: {
          '50': '#fffbeb',
          '100': '#fef3c7',
          '200': '#fde68a',
          '300': '#fcd34d',
          '400': '#fbbf24',
          '500': '#f59e0b',
          '600': '#d97706',
          '700': '#b45309',
          '800': '#92400e',
          '900': '#78350f',
        },
      },
    },
  },
  {
    name: 'Ornamentes',
    colors: {
      light: {
        main: {
          '50': '#d1b8d1',
          '100': '#c8b0c8',
          '200': '#c0a7c0',
          '300': '#b79fb7',
          '400': '#af97af',
          '500': '#a68fa7',
          '600': '#9e879e',
          '700': '#967f96',
          '800': '#8e778e',
          '900': '#866f86',
        },
        surface: {
          '50': '#f0e4f0',
          '100': '#e6dae6',
          '200': '#dbcfdb',
          '300': '#d1c5d1',
          '400': '#c7bbc7',
          '500': '#bcb1bd',
          '600': '#b2a7b2',
          '700': '#a99da9',
          '800': '#9f949f',
          '900': '#958a95',
        },
        actionPrimary: {
          '50': '#d6deec',
          '100': '#b5c4de',
          '200': '#94abcf',
          '300': '#7292c0',
          '400': '#4c7bb2',
          '500': '#1164a3',
          '600': '#005491',
          '700': '#004580',
          '800': '#003770',
          '900': '#00295f',
        },
        actionSecondary: {
          '50': '#511d52',
          '100': '#4f1b50',
          '200': '#4d194e',
          '300': '#4b174c',
          '400': '#49154a',
          '500': '#471348',
          '600': '#451146',
          '700': '#430e44',
          '800': '#410c42',
          '900': '#3f0a40',
        },
        actionAccent: {
          '50': '#d6deec',
          '100': '#b5c4de',
          '200': '#94abcf',
          '300': '#7292c0',
          '400': '#4c7bb2',
          '500': '#1164a3',
          '600': '#005491',
          '700': '#004580',
          '800': '#003770',
          '900': '#00295f',
        },
        actionAccentHover: {
          '50': '#d4cad3',
          '100': '#b2a1b1',
          '200': '#927990',
          '300': '#725471',
          '400': '#533053',
          '500': '#350d36',
          '600': '#310932',
          '700': '#2d052e',
          '800': '#29012a',
          '900': '#260026',
        },
        basicMain: {
          '50': '#d4cad3',
          '100': '#b2a1b1',
          '200': '#927990',
          '300': '#725471',
          '400': '#533053',
          '500': '#350d36',
          '600': '#310932',
          '700': '#2d052e',
          '800': '#29012a',
          '900': '#260026',
        },
        basicSurface: {
          '50': '#d2d3d3',
          '100': '#b0b1b0',
          '200': '#8e908f',
          '300': '#6e7070',
          '400': '#505251',
          '500': '#333635',
          '600': '#2b2e2d',
          '700': '#242726',
          '800': '#1d1f1e',
          '900': '#151817',
        },
        basicPrimary: {
          '50': '#ffffff',
          '100': '#ffffff',
          '200': '#ffffff',
          '300': '#ffffff',
          '400': '#ffffff',
          '500': '#ffffff',
          '600': '#d1d1d1',
          '700': '#a6a6a6',
          '800': '#7c7c7c',
          '900': '#555555',
        },
        basicSecondary: {
          '50': '#ffffff',
          '100': '#ffffff',
          '200': '#ffffff',
          '300': '#ffffff',
          '400': '#ffffff',
          '500': '#ffffff',
          '600': '#d1d1d1',
          '700': '#a6a6a6',
          '800': '#7c7c7c',
          '900': '#555555',
        },
        success: {
          '50': '#ecfdf5',
          '100': '#d1fae5',
          '200': '#a7f3d0',
          '300': '#6ee7b7',
          '400': '#34d399',
          '500': '#10b981',
          '600': '#059669',
          '700': '#047857',
          '800': '#065f46',
          '900': '#064e3b',
        },
        danger: {
          '50': '#fef2f2',
          '100': '#fee2e2',
          '200': '#fecaca',
          '300': '#fca5a5',
          '400': '#f87171',
          '500': '#ef4444',
          '600': '#dc2626',
          '700': '#b91c1c',
          '800': '#991b1b',
          '900': '#7f1d1d',
        },
        info: {
          '50': '#eff6ff',
          '100': '#dbeafe',
          '200': '#bfdbfe',
          '300': '#93c5fd',
          '400': '#60a5fa',
          '500': '#3b82f6',
          '600': '#2563eb',
          '700': '#1d4ed8',
          '800': '#1e40af',
          '900': '#1e3a8a',
        },
        warning: {
          '50': '#fffbeb',
          '100': '#fef3c7',
          '200': '#fde68a',
          '300': '#fcd34d',
          '400': '#fbbf24',
          '500': '#f59e0b',
          '600': '#d97706',
          '700': '#b45309',
          '800': '#92400e',
          '900': '#78350f',
        },
      },
      dark: {
        main: {
          '50': '#816080',
          '100': '#7a5679',
          '200': '#724d71',
          '300': '#6b446a',
          '400': '#643b63',
          '500': '#5c335c',
          '600': '#552a55',
          '700': '#4e214e',
          '800': '#461847',
          '900': '#3f0e40',
        },
        surface: {
          '50': '#f9fafb',
          '100': '#f3f4f6',
          '200': '#e5e7eb',
          '300': '#d1d5db',
          '400': '#9ca3af',
          '500': '#6b7280',
          '600': '#4b5563',
          '700': '#374151',
          '800': '#1f2937',
          '900': '#111827',
        },
        actionPrimary: {
          '50': '#b5c4de',
          '100': '#94abcf',
          '200': '#7292c0',
          '300': '#4c7bb2',
          '400': '#1164a3',
          '500': '#005491',
          '600': '#004580',
          '700': '#003770',
          '800': '#00295f',
          '900': '#001c4f',
        },
        actionSecondary: {
          '50': '#b2a1b1',
          '100': '#927990',
          '200': '#725471',
          '300': '#533053',
          '400': '#350d36',
          '500': '#310932',
          '600': '#2d052e',
          '700': '#29012a',
          '800': '#260026',
          '900': '#230023',
        },
        actionAccent: {
          '50': '#b5c4de',
          '100': '#94abcf',
          '200': '#7292c0',
          '300': '#4c7bb2',
          '400': '#1164a3',
          '500': '#005491',
          '600': '#004580',
          '700': '#003770',
          '800': '#00295f',
          '900': '#001c4f',
        },
        actionAccentHover: {
          '50': '#c8dcf2',
          '100': '#aeccec',
          '200': '#93bde5',
          '300': '#77afdf',
          '400': '#55a0d9',
          '500': '#3386bd',
          '600': '#006ca1',
          '700': '#005486',
          '800': '#003c6c',
          '900': '#002753',
        },
        basicMain: {
          '50': '#ffffff',
          '100': '#ffffff',
          '200': '#ffffff',
          '300': '#ffffff',
          '400': '#ffffff',
          '500': '#d1d1d1',
          '600': '#a6a6a6',
          '700': '#7c7c7c',
          '800': '#555555',
          '900': '#303030',
        },
        basicSurface: {
          '50': '#ffffff',
          '100': '#ffffff',
          '200': '#ffffff',
          '300': '#ffffff',
          '400': '#ffffff',
          '500': '#d1d1d1',
          '600': '#a6a6a6',
          '700': '#7c7c7c',
          '800': '#555555',
          '900': '#303030',
        },
        basicPrimary: {
          '50': '#ffffff',
          '100': '#ffffff',
          '200': '#ffffff',
          '300': '#ffffff',
          '400': '#ffffff',
          '500': '#d1d1d1',
          '600': '#a6a6a6',
          '700': '#7c7c7c',
          '800': '#555555',
          '900': '#303030',
        },
        basicSecondary: {
          '50': '#ffffff',
          '100': '#ffffff',
          '200': '#ffffff',
          '300': '#ffffff',
          '400': '#ffffff',
          '500': '#d1d1d1',
          '600': '#a6a6a6',
          '700': '#7c7c7c',
          '800': '#555555',
          '900': '#303030',
        },
        success: {
          '50': '#ecfdf5',
          '100': '#d1fae5',
          '200': '#a7f3d0',
          '300': '#6ee7b7',
          '400': '#34d399',
          '500': '#10b981',
          '600': '#059669',
          '700': '#047857',
          '800': '#065f46',
          '900': '#064e3b',
        },
        danger: {
          '50': '#fef2f2',
          '100': '#fee2e2',
          '200': '#fecaca',
          '300': '#fca5a5',
          '400': '#f87171',
          '500': '#ef4444',
          '600': '#dc2626',
          '700': '#b91c1c',
          '800': '#991b1b',
          '900': '#7f1d1d',
        },
        info: {
          '50': '#eff6ff',
          '100': '#dbeafe',
          '200': '#bfdbfe',
          '300': '#93c5fd',
          '400': '#60a5fa',
          '500': '#3b82f6',
          '600': '#2563eb',
          '700': '#1d4ed8',
          '800': '#1e40af',
          '900': '#1e3a8a',
        },
        warning: {
          '50': '#fffbeb',
          '100': '#fef3c7',
          '200': '#fde68a',
          '300': '#fcd34d',
          '400': '#fbbf24',
          '500': '#f59e0b',
          '600': '#d97706',
          '700': '#b45309',
          '800': '#92400e',
          '900': '#78350f',
        },
      },
    },
  },
  {
    name: 'Zuck',
    colors: {
      light: {
        main: {
          '50': '#dfe3ee',
          '100': '#d5d9e4',
          '200': '#cbcfda',
          '300': '#c1c5cf',
          '400': '#b7bbc5',
          '500': '#adb1bb',
          '600': '#a3a7b2',
          '700': '#9a9ea8',
          '800': '#90949e',
          '900': '#878b95',
        },
        surface: {
          '50': '#f7f7f7',
          '100': '#ececec',
          '200': '#e1e1e1',
          '300': '#d6d6d6',
          '400': '#cbcbcb',
          '500': '#c0c0c0',
          '600': '#b6b6b6',
          '700': '#ababab',
          '800': '#a1a1a1',
          '900': '#969696',
        },
        actionPrimary: {
          '50': '#d4d5e5',
          '100': '#b2b5d0',
          '200': '#9196bc',
          '300': '#6f78a8',
          '400': '#4c5c94',
          '500': '#244180',
          '600': '#133774',
          '700': '#002d69',
          '800': '#00245d',
          '900': '#001b52',
        },
        actionSecondary: {
          '50': '#3b5998',
          '100': '#375594',
          '200': '#325290',
          '300': '#2d4e8c',
          '400': '#294b88',
          '500': '#244784',
          '600': '#1e4480',
          '700': '#19407c',
          '800': '#123d78',
          '900': '#0a3974',
        },
        actionAccent: {
          '50': '#d6d7e6',
          '100': '#b5b9d3',
          '200': '#959bbf',
          '300': '#757eac',
          '400': '#546399',
          '500': '#1963FF',
          '600': '#1f3d79',
          '700': '#0b326c',
          '800': '#00285f',
          '900': '#001d53',
        },
        actionAccentHover: {
          '50': '#e0e4f2',
          '100': '#c8cee7',
          '200': '#afbadd',
          '300': '#96a5d2',
          '400': '#7d91c8',
          '500': '#0136A3',
          '600': '#4b69a6',
          '700': '#33548f',
          '800': '#174179',
          '900': '#002e64',
        },
        basicMain: {
          '50': '#c6c6c6',
          '100': '#9b9b9b',
          '200': '#727272',
          '300': '#4b4b4b',
          '400': '#282828',
          '500': '#000000',
          '600': '#000000',
          '700': '#000000',
          '800': '#000000',
          '900': '#000000',
        },
        basicSurface: {
          '50': '#f9fafb',
          '100': '#f3f4f6',
          '200': '#e5e7eb',
          '300': '#d1d5db',
          '400': '#9ca3af',
          '500': '#6b7280',
          '600': '#4b5563',
          '700': '#374151',
          '800': '#1f2937',
          '900': '#111827',
        },
        basicPrimary: {
          '50': '#fdfdfd',
          '100': '#fcfcfc',
          '200': '#fbfbfb',
          '300': '#fafafa',
          '400': '#f8f8f8',
          '500': '#f7f7f7',
          '600': '#cbcbcb',
          '700': '#a1a1a1',
          '800': '#787878',
          '900': '#525252',
        },
        basicSecondary: {
          '50': '#fdfdfd',
          '100': '#fcfcfc',
          '200': '#fbfbfb',
          '300': '#fafafa',
          '400': '#f8f8f8',
          '500': '#f7f7f7',
          '600': '#cbcbcb',
          '700': '#a1a1a1',
          '800': '#787878',
          '900': '#525252',
        },
        success: {
          '50': '#ecfdf5',
          '100': '#d1fae5',
          '200': '#a7f3d0',
          '300': '#6ee7b7',
          '400': '#34d399',
          '500': '#10b981',
          '600': '#059669',
          '700': '#047857',
          '800': '#065f46',
          '900': '#064e3b',
        },
        danger: {
          '50': '#fef2f2',
          '100': '#fee2e2',
          '200': '#fecaca',
          '300': '#fca5a5',
          '400': '#f87171',
          '500': '#ef4444',
          '600': '#dc2626',
          '700': '#b91c1c',
          '800': '#991b1b',
          '900': '#7f1d1d',
        },
        info: {
          '50': '#eff6ff',
          '100': '#dbeafe',
          '200': '#bfdbfe',
          '300': '#93c5fd',
          '400': '#60a5fa',
          '500': '#3b82f6',
          '600': '#2563eb',
          '700': '#1d4ed8',
          '800': '#1e40af',
          '900': '#1e3a8a',
        },
        warning: {
          '50': '#fffbeb',
          '100': '#fef3c7',
          '200': '#fde68a',
          '300': '#fcd34d',
          '400': '#fbbf24',
          '500': '#f59e0b',
          '600': '#d97706',
          '700': '#b45309',
          '800': '#92400e',
          '900': '#78350f',
        },
      },
      dark: {
        main: {
          '50': '#6f7071',
          '100': '#676869',
          '200': '#5f6061',
          '300': '#575859',
          '400': '#4f5051',
          '500': '#474849',
          '600': '#404142',
          '700': '#38393a',
          '800': '#313233',
          '900': '#2a2b2c',
        },
        surface: {
          '50': '#5f5f60',
          '100': '#565657',
          '200': '#4d4d4e',
          '300': '#454546',
          '400': '#3c3c3d',
          '500': '#343435',
          '600': '#2c2c2d',
          '700': '#242425',
          '800': '#1c1c1d',
          '900': '#151516',
        },
        actionPrimary: {
          '50': '#b4c7dd',
          '100': '#93afce',
          '200': '#7098bf',
          '300': '#4982b0',
          '400': '#066ca1',
          '500': '#005b8f',
          '600': '#004b7d',
          '700': '#003b6b',
          '800': '#002c5a',
          '900': '#001e49',
        },
        actionSecondary: {
          '50': '#b3b8cc',
          '100': '#939bb5',
          '200': '#737ea0',
          '300': '#53628a',
          '400': '#314875',
          '500': '#243d69',
          '600': '#16325c',
          '700': '#032851',
          '800': '#001e45',
          '900': '#00143a',
        },
        actionAccent: {
          '50': '#c0d7ec',
          '100': '#a3c6e3',
          '200': '#84b5da',
          '300': '#62a4d2',
          '400': '#3694c9',
          '500': '#007caf',
          '600': '#006596',
          '700': '#004f7e',
          '800': '#003966',
          '900': '#002650',
        },
        actionAccentHover: {
          '50': '#cebbd3',
          '100': '#b99ec0',
          '200': '#a482ae',
          '300': '#8e669b',
          '400': '#794b89',
          '500': '#693c79',
          '600': '#592d69',
          '700': '#491f59',
          '800': '#3a104a',
          '900': '#2b023c',
        },
        basicMain: {
          '50': '#dfe1e5',
          '100': '#d0d4da',
          '200': '#c3c7cf',
          '300': '#b5bbc4',
          '400': '#a7aeb9',
          '500': '#89909b',
          '600': '#6c737d',
          '700': '#515761',
          '800': '#373d46',
          '900': '#1e242d',
        },
        basicSurface: {
          '50': '#f9fafb',
          '100': '#f3f4f6',
          '200': '#e5e7eb',
          '300': '#d1d5db',
          '400': '#9ca3af',
          '500': '#6b7280',
          '600': '#4b5563',
          '700': '#374151',
          '800': '#1f2937',
          '900': '#111827',
        },
        basicPrimary: {
          '50': '#ffffff',
          '100': '#ffffff',
          '200': '#ffffff',
          '300': '#ffffff',
          '400': '#ffffff',
          '500': '#d1d1d1',
          '600': '#a6a6a6',
          '700': '#7c7c7c',
          '800': '#555555',
          '900': '#303030',
        },
        basicSecondary: {
          '50': '#ffffff',
          '100': '#ffffff',
          '200': '#ffffff',
          '300': '#ffffff',
          '400': '#ffffff',
          '500': '#d1d1d1',
          '600': '#a6a6a6',
          '700': '#7c7c7c',
          '800': '#555555',
          '900': '#303030',
        },
        success: {
          '50': '#ecfdf5',
          '100': '#d1fae5',
          '200': '#a7f3d0',
          '300': '#6ee7b7',
          '400': '#34d399',
          '500': '#10b981',
          '600': '#059669',
          '700': '#047857',
          '800': '#065f46',
          '900': '#064e3b',
        },
        danger: {
          '50': '#fef2f2',
          '100': '#fee2e2',
          '200': '#fecaca',
          '300': '#fca5a5',
          '400': '#f87171',
          '500': '#ef4444',
          '600': '#dc2626',
          '700': '#b91c1c',
          '800': '#991b1b',
          '900': '#7f1d1d',
        },
        info: {
          '50': '#eff6ff',
          '100': '#dbeafe',
          '200': '#bfdbfe',
          '300': '#93c5fd',
          '400': '#60a5fa',
          '500': '#3b82f6',
          '600': '#2563eb',
          '700': '#1d4ed8',
          '800': '#1e40af',
          '900': '#1e3a8a',
        },
        warning: {
          '50': '#fffbeb',
          '100': '#fef3c7',
          '200': '#fde68a',
          '300': '#fcd34d',
          '400': '#fbbf24',
          '500': '#f59e0b',
          '600': '#d97706',
          '700': '#b45309',
          '800': '#92400e',
          '900': '#78350f',
        },
      },
    },
  },
]
