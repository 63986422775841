import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { CardHeaderBlock as Component } from './Block.js'
import { CardHeaderProps } from './types.js'

export const RegisteredCardHeaderBlock = (
  _intl: I18nShape,
): RegisteredBlock<CardHeaderProps> => ({
  recommendedName: 'Card.Header',
  Component,
  config: {
    type: BlockType.BuildingBlock,
    defaultProps: {
      size: 'sm',
    },
  },
})
