import { HelperText } from '@tribeplatform/react-ui-kit/FormControl'

import { FieldInputResolver } from './FieldInputResolver.js'
import { FieldInputProps } from './type.js'

export const FieldInput = ({
  field,
  name = undefined,
  onChange = undefined,
  value = undefined,
  error = undefined,
  ...extraProps
}: FieldInputProps) => {
  return (
    <>
      <FieldInputResolver
        field={field}
        name={name}
        onChange={onChange}
        value={value}
        error={error}
        {...extraProps}
      />
      {error && (
        <div>
          <HelperText status="error">{error}</HelperText>
        </div>
      )}
    </>
  )
}
