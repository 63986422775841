import React, { useRef } from 'react'

import { FocusTrap, Transition } from '@headlessui/react'
import { createPortal } from 'react-dom'

import { Backdrop } from '../Backdrop/Backdrop.js'
import { BackgroundProvider } from '../BackgroundContext/BackgroundContext.js'
import { useDocument } from '../hooks/useDocument.js'
import { SvgIcon } from '../Icon/SvgIcon.js'

const MOBILE_MENU_PORTAL_ROOT = 'navbar-mobile-menu-root'

const getMobileMenuPortalRootElement = (document: Document) => {
  const root = document.getElementById(MOBILE_MENU_PORTAL_ROOT)
  if (root) {
    return root
  }

  const newRoot = document.createElement('div')
  newRoot.setAttribute('id', MOBILE_MENU_PORTAL_ROOT)
  newRoot.setAttribute('class', 'relative z-[50] isolate')
  document.body.appendChild(newRoot)
  return newRoot
}

interface MobileMenuProps {
  open: boolean
  onClose: () => void
  children: React.ReactNode
}
export const MobileMenu = ({ open, onClose, children }: MobileMenuProps) => {
  const { document } = useDocument()
  const parentRef = useRef<HTMLDivElement>(null)

  return createPortal(
    <Transition show={open}>
      <Transition.Child
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="z-20 fixed inset-0 overflow-hidden"
      >
        <Backdrop onClick={onClose} />
      </Transition.Child>

      <Transition.Child
        enter="ease-out duration-200 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="ease-in duration-200 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        className="z-20 fixed inset-y-0 start-0 max-w-xs w-full px-3 py-4 overflow-y-auto shadow-lg bg-background overscroll-contain"
        aria-modal="true"
        role="dialog"
      >
        <FocusTrap initialFocus={parentRef}>
          <BackgroundProvider backgroundType="main">
            <div ref={parentRef}>
              <button
                type="button"
                onClick={onClose}
                tabIndex={0}
                className="mb-5"
                aria-label="Close"
              >
                <SvgIcon size="2x" name="close" />
              </button>
            </div>
            {children}
          </BackgroundProvider>
        </FocusTrap>
      </Transition.Child>
    </Transition>,
    getMobileMenuPortalRootElement(document),
  )
}
