import { useEffect } from 'react'

import { useSlate } from '../../hooks/index.js'
import {
  CallbackExtraContext,
  RegisteredBlockProps,
} from '../../types/index.js'
import { DelayedProps } from './types.js'

export const DelayedBlock = ({
  callbackId,
  delay,
}: RegisteredBlockProps<DelayedProps>) => {
  const {
    extraContext: {
      slate: { callback },
    },
  } = useSlate<CallbackExtraContext>()

  useEffect(() => {
    const delayTimeout = setTimeout(async () => {
      try {
        await callback?.(callbackId)
      } catch (error) {
        // We are catching the error here because we don't want to crash the app
        // We are already logging the error in the callback function
      }
    }, delay)

    return () => {
      clearTimeout(delayTimeout)
    }
  }, [callback, callbackId, delay])

  return null
}
