import { DocumentNode } from 'graphql'
import { gql } from 'graphql-tag'

import {
  TemplateRequestFields,
  templateRequestGQLFields,
} from './templateRequest.fields.js'

export function getTemplateRequestGQLQuery(
  fields: TemplateRequestFields,
): DocumentNode {
  return gql`
    query templateRequest($id: String!) {
      templateRequest(id: $id) {
        ${templateRequestGQLFields(fields)}
      }
    }
  `
}
