import { useSpacesByIds } from '@tribeplatform/react-sdk/hooks'

import { EMPTY_VALUE_PLACEHOLDER } from '../utils/values.js'

interface Props {
  value: string[]
}
export const SpaceRenderer = ({ value }: Props) => {
  const { data: spaces, isInitialLoading } = useSpacesByIds({
    variables: { ids: value },
    fields: {
      image: 'basic',
    },
    useQueryOptions: {
      enabled: value.length > 0,
    },
  })
  const spaceNames = spaces?.map(space => space.name)

  if (isInitialLoading) {
    return EMPTY_VALUE_PLACEHOLDER
  }

  return <>{spaceNames.join(', ')}</>
}
