import { Slider, SliderProps } from '../../Slider/index.js'
import { FormControlLayout } from '../FormControlLayout.js'
import { FormControlProps } from '../types.js'
import { extractLayoutProps } from '../utils.js'

export type FormControlSliderProps = FormControlProps &
  SliderProps & { className?: string }

export const FormControlSlider = ({
  value,
  ...rest
}: FormControlSliderProps) => {
  const { layoutProps, inputProps } = extractLayoutProps(rest)

  return (
    <FormControlLayout
      {...layoutProps}
      tertiaryLabel={
        layoutProps.tertiaryLabel || (
          <div className="font-mediums text-md text-content-subdued">
            {value}
          </div>
        )
      }
    >
      <Slider value={value} {...inputProps} tooltip={false} />
    </FormControlLayout>
  )
}
