import { clsx } from 'clsx'

import { SvgIcon } from '../Icon/SvgIcon.js'
import { Input } from '../Input/index.js'
import { useUniqueId } from '../utils/unique-id.js'
import { Calendar } from './Calendar.js'
import { DatePickerProps } from './types.js'
import { useDatePicker } from './useDatePicker.js'
import { startOfDay } from './utils.js'

export const DatePicker = ({
  className,
  invalid,
  onChange,
  value,
  inline = false,
  ...rest
}: DatePickerProps) => {
  const prefix = useUniqueId('DatePicker')

  const handleOnChange = dates => {
    if (!Array.isArray(dates) || dates.length !== 1) {
      onChange?.(undefined)
      return
    }

    onChange?.(startOfDay(dates[0]))
  }

  const { startInputField } = useDatePicker({
    value,
    onChange: handleOnChange,
    mode: 'single',
    prefix,
    allowInput: false,
    noCalendar: inline,
    enableTime: false,
    showMonths: 1,
    ...rest,
  })

  return (
    <div className={clsx(className, 'w-[308px] space-y-1')}>
      <Input
        ref={startInputField}
        invalid={invalid}
        leadingIcon={<SvgIcon size="lg" name="calendar" />}
      />
      {inline && <Calendar value={value} onChange={onChange} {...rest} />}
    </div>
  )
}
