import { VideoHTMLAttributes } from 'react'

import { T } from '../../i18n/index.js'
import { useDomain } from '../hooks/useDomain.js'

export const Video = ({
  src,
  autoPlay = true,
  loop = true,
  muted = true,
  playsInline = true,
  ...rest
}: VideoHTMLAttributes<HTMLVideoElement>) => {
  const { getResourceUrl } = useDomain()
  const source = getResourceUrl(src)

  return (
    <video
      // Force re-render video when source changes to make the browser change to the new source
      key={source}
      src={source}
      autoPlay={autoPlay}
      loop={loop}
      muted={muted}
      playsInline={playsInline}
      {...rest}
    >
      <T
        id="Video.NoBrowserSupport"
        defaultMessage="Sorry, your browser doesn't support embedded videos."
      />
    </video>
  )
}
