import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { MembersAvatarsBlock as Component } from './Block.js'
import { MembersAvatarsProps } from './types.js'

export const RegisteredMembersAvatarsBlock = (
  _intl: I18nShape,
): RegisteredBlock<MembersAvatarsProps> => ({
  recommendedName: 'MembersAvatars',
  Component,
  config: {
    type: BlockType.Block,
    usable: false,
    displayName: 'Members avatars',
  },
})
