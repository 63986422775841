import type { AppearanceStyle } from '@tribeplatform/design-system/types'

import { NetworkColorScheme, ThemeAssets, UserColorScheme } from './types.js'

export const DEFAULT_STYLE: AppearanceStyle = {
  corners: 'round',
  cards: 'elevated',
  buttons: 'rounded',
  avatars: 'initials',
  avatarShape: 'full-rounded',
  icons: 'line',
}

export const DEFAULT_NETWORK_COLOR_SCHEME: NetworkColorScheme = {
  mode: 'user-preference',
  light: 'light1',
  dark: 'dark1',
}
export const LEGACY_NETWORK_COLOR_SCHEME: NetworkColorScheme = {
  mode: 'single',
  default: 'light1',
}

export const DEFAULT_USER_COLOR_SCHEME: UserColorScheme = 'auto'
export const TYPOGRAPHY_LINK_ID = 'font'
export const TYPOGRAPHY_STYLE_ID = 'typography'
export const THEME_STYLE_ID = 'theme'
export const COOKIE_COLOR_SCHEME = 'color_scheme'

export const DEFAULT_THEME_ASSETS: ThemeAssets = {
  iconSpriteUrl: '',
  iconSocialSpriteUrl: '',
  iconPickerLineSpriteUrl: '',
  iconPickerSolidSpriteUrl: '',
  iconPickerDuocolorSpriteUrl: '',
}

export const EMOJI_FONT_NAME =
  '"Apple Color Emoji", "Noto Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Twemoji Mozilla", "Android Emoji"'

export const FONT_FALLBACK = `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, ${EMOJI_FONT_NAME}`
export const FONT_MONO_FALLBACK =
  " ui-monospace, SFMono-Regular, 'SF Mono', Consolas, 'Liberation Mono', Menlo, monospace"
