import { gql } from 'graphql-tag'

export const probeDomainGQLQuery = () => gql`
  query ProbeDomain($domain: String!) {
    probeDomain(domain: $domain) {
      customHostnameStatus
      status
      parseResult {
        isApex
        parts {
          domain
          apex
          tld
          sld
          subdomain
        }
      }
      dnsRecords {
        name
        type
        desiredValue
        status
        message
      }
    }
  }
`

export const nominateNewDomainGQLMutation = () => gql`
  mutation NominateNewDomain($domain: String!) {
    nominateNewDomain(domain: $domain) {
      status
    }
  }
`

export const commitDomainTransferGQLMutation = () => gql`
  mutation CommitDomainTransfer {
    commitDomainTransfer {
      status
    }
  }
`

export const cancelDomainTransferGQLMutation = () => gql`
  mutation CancelDomainTransfer {
    cancelDomainTransfer {
      status
    }
  }
`

/**
 * @deprecated
 */
export const clearNewDomainGQLMutation = () => gql`
  mutation ClearNewDomain {
    clearNewDomain {
      status
    }
  }
`

/**
 * @deprecated
 */
export const updateNewDomainGQLMutation = () => gql`
  mutation UpdateNewDomain($input: UpdateNewDomainInput!) {
    updateNewDomain(input: $input) {
      aaaarecordSuccess
      aaaarecords
      arecordSuccess
      arecords
      cnameSuccess
      cnames
      domain
      ns
      root
      success
      tribeARecords
      tribeCname
    }
  }
`

/**
 * @deprecated
 */
export const transferToNewDomainGQLMutation = () => gql`
  mutation TransferToNewDomain {
    transferToNewDomain {
      status
    }
  }
`

/**
 * @deprecated
 */
export const domainAvailabilityGQLQuery = () => gql`
  query DomainAvailability($input: DomainAvailabilityInput!) {
    domainAvailability(input: $input) {
      available
    }
  }
`

/**
 * @deprecated
 */
export const newDomainStatusGQLQuery = () => gql`
  query NewDomainStatus($domain: String!) {
    newDomainStatus(domain: $domain) {
      aaaarecordSuccess
      aaaarecords
      arecordSuccess
      arecords
      cnameSuccess
      cnames
      domain
      ns
      root
      success
      tribeARecords
      tribeCname
    }
  }
`
