import { google, outlook, yahoo } from 'calendar-link'

import { T } from '@tribeplatform/react-components/i18n'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BC } from '../../types/index.js'
import { CalendarItemComponent } from './CalendarItemComponent.js'
import { GOOGLE_ICON, WINDOWS_ICON, YAHOO_ICON } from './icons.js'
import {
  AddToCalendarButtonProps,
  CalendarType,
  CalendarEvent,
  CalendarItem,
} from './types.js'
import { parseJSON } from './utils.js'

export const AddToCalendarButtonBlock: BC<AddToCalendarButtonProps> = ({
  event,
  ...rest
}) => {
  if (!event) {
    return null
  }
  const eventObject = { ...parseJSON(event) }

  const cleanEvent: CalendarEvent = {
    ...eventObject,
    start: eventObject?.start
      ? new Date(eventObject.start).toUTCString()
      : new Date().toUTCString(),
    ...(!!eventObject?.end && {
      end: new Date(eventObject.end).toUTCString(),
    }),
  }

  const calendarItems = [
    {
      name: 'Google',
      type: CalendarType.GOOGLE,
      url: google(cleanEvent),
      icon: GOOGLE_ICON,
    },
    {
      name: 'Yahoo',
      type: CalendarType.YAHOO,
      url: yahoo(cleanEvent),
      icon: YAHOO_ICON,
    },
    {
      name: 'Outlook',
      type: CalendarType.OUTLOOK,
      url: outlook(cleanEvent),
      icon: WINDOWS_ICON,
    },
    {
      name: 'Download ics file',
      type: CalendarType.ICS,
      icon: <SvgIcon name="download" />,
    },
  ]

  return (
    <Dropdown placement="bottom-start" {...rest}>
      <Dropdown.ButtonMinimal as="button">
        <span className="px-1 text-interactive hover:text-interactive-hovered">
          <T
            defaultMessage="Add to calendar"
            id="AddToCalendarButtonBlock.AddToCalendar"
          />
        </span>
      </Dropdown.ButtonMinimal>
      <Dropdown.Items>
        {calendarItems.map((actionItem: CalendarItem) => (
          <CalendarItemComponent
            key={actionItem.name}
            actionItem={actionItem}
            event={cleanEvent}
          />
        ))}
      </Dropdown.Items>
    </Dropdown>
  )
}
