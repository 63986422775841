import { clsx } from 'clsx'

import {
  BannerPlaceholder,
  EntityImage,
} from '@tribeplatform/react-components/common/components'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { useRouter } from '@tribeplatform/react-sdk'

import type { QuickLinksSource } from '../types.js'

interface Props {
  field: StaticField
  source: QuickLinksSource
  highlighted: boolean
  mediaUrl: string
  aspectRatio: StaticField['settings']['aspectRatio']
  preview: boolean
}
export const CardBanner = ({
  // eslint-disable-next-line unused-imports/no-unused-vars
  field,
  source,
  highlighted,
  mediaUrl,
  aspectRatio,
  preview,
}: Props) => {
  const { Link } = useRouter()
  const { bannerImage } = source

  if (!mediaUrl && !preview) {
    return null
  }

  const fieldMediaUrl = mediaUrl

  if (mediaUrl) {
    return (
      <Link href={source.linkUrl} variant="inherit" tabIndex={-1}>
        <div
          className={clsx(
            'w-full relative',
            aspectRatio || 'aspect-video',
            highlighted && 'border-2 border-focused',
          )}
        >
          <div
            className="w-full h-full bg-cover bg-center absolute top-0 start-0"
            style={{ backgroundImage: `url(${fieldMediaUrl})` }}
          ></div>
        </div>
      </Link>
    )
  }

  if (bannerImage) {
    return (
      <Link href={source.linkUrl} variant="inherit" tabIndex={-1}>
        <div
          className={clsx(
            'w-full relative',
            aspectRatio || 'aspect-video',
            highlighted && 'border-2 border-focused',
          )}
        >
          <div className="w-full h-full flex justify-center items-center text-content-on-background">
            <EntityImage entity={{ image: bannerImage }} size="6x" />
          </div>
        </div>
      </Link>
    )
  }

  return (
    <Link href={source.linkUrl} variant="inherit" tabIndex={-1}>
      <div
        className={clsx(
          'w-full relative',
          aspectRatio || 'aspect-video',
          highlighted && 'border-2 border-focused',
        )}
      >
        <div className="absolute inset-0">
          <BannerPlaceholder name={source?.id} />
        </div>
      </div>
    </Link>
  )
}
