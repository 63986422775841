import { useCallback, useMemo } from 'react'

import { useQueryParam } from '../../common/hooks/useQueryParam.js'
import type { Filter, LegacyFilter } from '../types.js'
import { prepareFilters } from '../utils/converters.js'
import {
  decodeFilterFromQuery,
  encodeFilterToQuery,
} from '../utils/storedFilters.js'

export interface StoredLegacyFilter {
  filters: LegacyFilter[]
}
export const useStoredFilters = (name: string, property: string) => {
  const [filtersFromQueryString, setFiltersFromQueryString] = useQueryParam<{
    filters: string
  }>(
    { name, property },
    {
      navigationMode: 'replace',
    },
  )
  const storedFilters = useMemo(() => {
    const legacyFilter = decodeFilterFromQuery(filtersFromQueryString?.filters)
    return legacyFilter
  }, [filtersFromQueryString])

  const setStoredFilters = useCallback(
    (newFilters: Filter[]) => {
      const legacyFilters = prepareFilters(newFilters)
      const encodedQuery = encodeFilterToQuery(legacyFilters)
      setFiltersFromQueryString({
        filters: encodedQuery,
      })
    },
    [setFiltersFromQueryString],
  )

  return [storedFilters, setStoredFilters] as const
}
