import { ActionVariants } from '../../Views/types.js'

export const getTextIconSettings = (variant: ActionVariants) => {
  switch (variant) {
    case ActionVariants.icon:
      return {
        showIcon: true,
        showText: false,
      }
    case ActionVariants.text:
      return {
        showIcon: false,
        showText: true,
      }
    case ActionVariants.iconText:
      return {
        showIcon: true,
        showText: true,
      }
    default:
      return {
        showIcon: true,
        showText: true,
      }
  }
}
