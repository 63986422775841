import { MemberFields } from '@tribeplatform/gql-client/graphql'
import {
  CustomFieldPrivacyOptions,
  CustomFieldSchema,
} from '@tribeplatform/gql-client/types'
import { dayjs } from '@tribeplatform/react-components/common/lib'
import { getLocalizedDateFormat } from '@tribeplatform/react-components/common/utils'
import {
  FieldPrivacyBadges,
  FieldView,
  getFieldSetting,
} from '@tribeplatform/react-components/CustomField'
import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { MemberBadges } from '@tribeplatform/react-components/MemberBadge'
import { useMember, useNetwork } from '@tribeplatform/react-sdk/hooks'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { Link } from '@tribeplatform/react-ui-kit/Link'

export const MemberAbout = ({
  memberId,
  memberFields,
  highlightedField,
  preview = false,
  ...rest
}: {
  memberId: string
  memberFields?: MemberFields | CustomFieldSchema[]
  highlightedField?: string
  preview?: boolean
}) => {
  const { localeCode, $t } = useI18n()
  const { data: network } = useNetwork()
  const { data: member } = useMember({
    variables: { id: memberId },
    fields: {
      authMemberProps: 'all',
      badges: 'all',
      banner: 'all',
      profilePicture: 'all',
      role: 'basic',
      fields: 'all',
    },
    useQueryOptions: {
      refetchOnMount: 'always',
      retry: false,
    },
  })
  const networkMemberFields = (
    Array.isArray(memberFields)
      ? memberFields
      : false || network?.memberFields?.fields || []
  ).filter(field => !field.archived)

  const { date: createdAtDate } = getLocalizedDateFormat(
    member?.createdAt,
    localeCode,
  )

  return (
    <Card {...rest}>
      <Card.Header
        title={$t({
          defaultMessage: 'About',
          id: 'Generics.About',
        })}
        size="sm"
      />
      <Card.Content>
        <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-1 max-w-full">
          {member?.tagline ? (
            <div className="web:sm:col-span-1 member-field-tagline">
              <div className="font-medium text-content-subdued">
                <T defaultMessage="Tagline" id="Generics.Tagline" />
              </div>
              <div className="mt-1 text-content text-sm">{member?.tagline}</div>
            </div>
          ) : null}
          <div className="web:sm:col-span-1 member-field-member-since">
            <div className="font-medium text-content-subdued">
              <T defaultMessage="{MEMBER_CC} since" id="Member.About.Since" />
            </div>
            <div className="mt-1 text-content text-sm">{createdAtDate}</div>
          </div>
          {member?.lastSeenAt ? (
            <div className="web:sm:col-span-1 member-field-last-seen">
              <div className="font-medium text-content-subdued flex space-s-1 items-center">
                <span>
                  <T defaultMessage="Last seen" id="Generics.LastSeen" />
                </span>
                <FieldPrivacyBadges
                  field={{
                    readPrivacy: { allow: [CustomFieldPrivacyOptions.ADMIN] },
                  }}
                />
              </div>
              <div className="mt-1 text-content text-sm">
                {dayjs(member.lastSeenAt).fromNow()}
              </div>
            </div>
          ) : null}
          {member?.email ? (
            <div className="web:sm:col-span-1 member-field-email">
              <div className="font-medium text-content-subdued flex space-s-1 items-center">
                <span>
                  <T defaultMessage="Email" id="Generics.Email" />
                </span>
                <FieldPrivacyBadges
                  field={{
                    readPrivacy: { allow: [CustomFieldPrivacyOptions.OWN] },
                  }}
                />
              </div>
              <div className="mt-1 text-content text-sm">
                <Link
                  href={`mailto:${member.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="neutral"
                  className="break-words"
                >
                  {member.email}
                </Link>
              </div>
            </div>
          ) : null}
          {member?.score ? (
            <div className="web:sm:col-span-1 member-field-score">
              <div className="font-medium text-content-subdued">
                <T defaultMessage="Score" id="Generics.Score" />
              </div>
              <div className="mt-1 text-content text-sm">
                {member.score?.toLocaleString()}
              </div>
            </div>
          ) : null}
          {member?.badges?.length ? (
            <div className="web:sm:col-span-1 member-field-badges">
              <div className="font-medium text-content-subdued">
                <T defaultMessage="Badges" id="Generics.Badges" />
              </div>
              <div className="mt-1 text-content text-sm">
                <MemberBadges member={member} size="sm" singleLine={false} />
              </div>
            </div>
          ) : null}
          {networkMemberFields &&
            networkMemberFields
              ?.filter(
                field => getFieldSetting(field, 'category') !== 'socialLink',
              )
              .map(networkField => {
                return (
                  <FieldView
                    key={networkField.key}
                    field={networkField}
                    entity={member}
                    highlighted={highlightedField === networkField.key}
                    showLabel
                    preview={preview}
                  />
                )
              })}
        </div>
        <div className="flex flex-wrap mt-5">
          {networkMemberFields &&
            networkMemberFields
              ?.filter(
                field => getFieldSetting(field, 'category') === 'socialLink',
              )
              .map(networkField => {
                return (
                  <div
                    className="w-8 h-8 me-2 mb-2 empty:hidden"
                    key={networkField.key}
                    data-id={networkField.key}
                  >
                    <FieldView
                      field={networkField}
                      entity={member}
                      highlighted={highlightedField === networkField.key}
                      showLabel
                      preview={preview}
                    />
                  </div>
                )
              })}
        </div>
      </Card.Content>
    </Card>
  )
}
