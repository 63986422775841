import { MobileMenuSectionItem, type MobileMenuSection } from '../types.js'

interface CheckMobileMenuEmptyProps {
  sections: MobileMenuSection[]
  hasHeaderMenu: boolean
  hasHeaderSubMenu: boolean
  hasSidebar: boolean
}
export const checkMobileMenuEmpty = ({
  sections,
  hasHeaderMenu,
  hasHeaderSubMenu,
  hasSidebar,
}: CheckMobileMenuEmptyProps) => {
  const headerMenuSection = sections.find(
    ({ id }) => id === MobileMenuSectionItem.headerMenu,
  )
  const headerSubMenuSection = sections.find(
    ({ id }) => id === MobileMenuSectionItem.headerSubMenu,
  )
  const sidebarSection = sections.find(
    ({ id }) => id === MobileMenuSectionItem.sidebar,
  )
  const isHeaderMenuEmpty = !headerMenuSection.enabled || !hasHeaderMenu
  const isHeaderSubMenuEmpty =
    !headerSubMenuSection.enabled || !hasHeaderSubMenu
  const isSidebarEmpty = !sidebarSection.enabled || !hasSidebar

  return isHeaderMenuEmpty && isHeaderSubMenuEmpty && isSidebarEmpty
}
