import type { NetworkFields as GlobalNetworkFields } from '@tribeplatform/gql-client/global-types'
import type { NetworkFields } from '@tribeplatform/gql-client/graphql'
import type {
  QueryGlobalNetworksArgs,
  QueryNetworksArgs,
  QueryNewDomainStatusArgs,
} from '@tribeplatform/gql-client/types'

import { KeyConstructor } from './keyConstructor.js'

const NETWORK_KEY = 'network'
const NEW_DOMAIN_STATUS_KEY = 'newDomainStatus'
const NETWORKS_KEY = 'networks'

export const getGlobalNetworkKey = KeyConstructor<
  QueryNetworksArgs,
  GlobalNetworkFields
>(NETWORK_KEY)
export const getNetworkKey = KeyConstructor<never, NetworkFields>(NETWORK_KEY)
export const getNewDomainStatusKey = KeyConstructor<
  QueryNewDomainStatusArgs,
  never
>(NEW_DOMAIN_STATUS_KEY)
export const getNetworksKey = KeyConstructor<
  QueryGlobalNetworksArgs,
  NetworkFields
>(NETWORKS_KEY)
