import type { InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query'

import type { PostFields } from '@tribeplatform/gql-client/graphql'
import type { ClientError } from '@tribeplatform/gql-client/lib'
import {
  MutationCreatePostArgs,
  PaginatedPost,
  PaginatedSpace,
  Post,
  Space,
  SpaceType,
} from '@tribeplatform/gql-client/types'

import type { QueryFilters } from '../../lib/react-query/QueryFilters.js'
import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getFeedKey } from '../../utils/keys/feed.keys.js'
import { getPostsKey } from '../../utils/keys/post.key.js'
import { getSpaceKey, getSpacesKey } from '../../utils/keys/space.key.js'
import { useAuthMember } from '../auth/useAuthMember.js'
import { addPostToPaginatedPost } from '../cache/useCachedPost.js'
import {
  infiniteSpaceUpdater,
  spacePostsUpdater,
} from '../cache/useCachedSpace.js'
import { getSpacesQueryById } from '../spaces/filters.js'

const spacePostsKey = (spaceId): QueryFilters => ({
  type: 'active',
  predicate: query => {
    const [main, args] = query.queryKey || []
    return (
      main === getFeedKey()[0] ||
      (main === getPostsKey()[0] &&
        (args as any)?.variables?.spaceIds?.indexOf(spaceId) > -1)
    )
  },
})

const onAddPost = (
  variables: MutationCreatePostArgs,
  newPost: Post,
  queryClient: QueryClient,
) => {
  const { spaceId } = variables

  /** Add the post to the feed and posts queries */
  queryClient.setQueriesData<InfiniteData<PaginatedPost>>(
    spacePostsKey(spaceId),
    addPostToPaginatedPost(newPost),
  )

  /**  Add the post to Space.posts in any spaces queries where the parent space exists */
  const spacesQueryFilter = getSpacesQueryById(spaceId)

  // update posts within the found space everywhere (spaces and the feed queries)
  queryClient.setQueriesData<InfiniteData<PaginatedSpace>>(
    spacesQueryFilter,
    infiniteSpaceUpdater(spaceId, spacePostsUpdater(newPost)),
  )

  /**  space.authMemberProps contains update lastReadAt field */
  if (newPost?.space?.authMemberProps) {
    const spaceKey = getSpaceKey({ variables: { id: spaceId } })

    const spaceUpdater = (old: Space): Space => {
      if (!old) {
        return
      }
      return {
        ...old,
        authMemberProps: {
          ...old?.authMemberProps,
          ...newPost?.space?.authMemberProps,
        },
      }
    }
    queryClient.setQueriesData<Space>(spaceKey, spaceUpdater)
  }
}

export const useAddPost = (options?: {
  fields?: PostFields
  useMutationOptions?: UseMutationOptions<
    Post,
    ClientError,
    MutationCreatePostArgs,
    { snapshot: [QueryKey, InfiniteData<PaginatedPost>][] }
  >
}) => {
  const { useMutationOptions, fields = 'default' } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()
  const { data: authMember } = useAuthMember()

  return useMutation<
    Post,
    ClientError,
    MutationCreatePostArgs,
    { snapshot: [QueryKey, InfiniteData<PaginatedPost>][] }
  >(
    (input: MutationCreatePostArgs) => client.posts.create(input, fields),

    {
      onSuccess: (data, variables) => {
        onAddPost(variables, data, queryClient)
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries(spacePostsKey(variables.spaceId))
        const spaceKey = getSpaceKey({ variables: { id: variables.spaceId } })
        queryClient.invalidateQueries(spaceKey)

        const spacesKey = getSpacesKey({
          variables: {
            type: [SpaceType.PrivateMessage],
          },
        })

        queryClient.invalidateQueries(spacesKey)

        queryClient.invalidateQueries(
          getPostsKey({ variables: { memberId: authMember.id } }),
        )
      },
      ...useMutationOptions,
    },
  )
}
