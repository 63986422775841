import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  AppInteraction,
  MutationInteractWithAppsArgs,
} from '@tribeplatform/gql-client/types'
import type { AppInteractionFields } from '@tribeplatform/gql-client/types/generated'

import {
  useMutation,
  type UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useTribeClient } from '../../useTribeClient.js'

export const useInteractWithApps = (options?: {
  fields?: AppInteractionFields
  useMutationOptions?: UseMutationOptions<
    Array<AppInteraction>,
    ClientError,
    MutationInteractWithAppsArgs
  >
}) => {
  const { useMutationOptions, fields = 'basic' } = options || {}
  const { client } = useTribeClient()

  return useMutation<
    Array<AppInteraction>,
    ClientError,
    MutationInteractWithAppsArgs
  >(
    variables =>
      client.mutation({
        name: 'interactWithApps',
        args: {
          fields,
          variables,
        },
      }),
    useMutationOptions,
  )
}
