import { DocumentNode } from 'graphql'
import { gql } from 'graphql-tag'

import { ActionFields, actionGQLFields } from '../common/action.fields.js'
import {
  NotificationFields,
  notificationGQLFields,
} from './notification.fields.js'

export function getNotificationsGQLQuery(
  fields: NotificationFields,
): DocumentNode {
  return gql`
    query getNotifications($after: String, $limit: Int!) {
      notifications(after: $after, limit: $limit) {
        edges {
          cursor
          node {
            ${notificationGQLFields(fields)}
          }
        }
        nodes {
          ${notificationGQLFields(fields)}
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  `
}

export function getNotificationsCountGQLQuery(): DocumentNode {
  return gql`
    query getNotificationsCount {
      notificationsCount {
        new
      }
    }
  `
}

export const readNotificationGQLMutation = (fields: ActionFields) => gql`
  mutation ReadNotification($notificationId: ID!) {
    readNotification(notificationId: $notificationId) {
      ${actionGQLFields(fields)}
    }
  }
`

export const readNotificationsGQLMutation = (fields: ActionFields) => gql`
  mutation ReadNotifications($ids: [ID!]!) {
    readNotifications(ids: $ids) {
      ${actionGQLFields(fields)}
    }
  }
`

export const deleteNotificationGQLMutation = (fields: ActionFields) => gql`
  mutation DeleteNotification($notificationId: ID!) {
    deleteNotification(notificationId: $notificationId) {
      ${actionGQLFields(fields)}
    }
  }
`

export const deleteNotificationsGQLMutation = (fields: ActionFields) => gql`
  mutation DeleteNotifications($ids: [ID!]!) {
    deleteNotifications(ids: $ids) {
      ${actionGQLFields(fields)}
    }
  }
`

export const clearNotificationsCountGQLMutation = (fields: ActionFields) => gql`
  mutation ClearNotificationsCount {
    clearNotificationsCount {
      ${actionGQLFields(fields)}
    }
  }
`

export const unsubscribeFromNotificationGQLMutation = (fields: ActionFields) =>
  gql`
    mutation UnsubscribeFromNotification($input: UnsubscribeWithTokenInput!) {
      unsubscribeFromNotification(input: $input) {
        ${actionGQLFields(fields)}
      }
    }
  `

export const updateNetworkNotificationSettingsGQLMutation = () => gql`
  mutation UpdateNetworkNotificationSettings(
    $input: UpdateNotificationNetworkSettingsInput!
  ) {
    updateNetworkNotificationSettings(input: $input) {
      emailEnabled
      emailFrom
      emailNotificationEnabled
      emailDigestEnabled
    }
  }
`
