import { Container } from '@tribeplatform/react-ui-kit/Layout'

interface Props {
  sidebar: JSX.Element
  content: JSX.Element
}
export const Body = ({ sidebar, content }: Props) => {
  return (
    <Container
      size="full"
      spacing="none"
      padding={
        sidebar ? { horizontal: { size: 'md', disableOnMobile: true } } : null
      }
      containerProps={{
        className: 'flex-1',
      }}
    >
      <Container
        size={sidebar ? 'xl' : 'full'}
        direction="grid"
        containerProps={{
          className: 'flex-1',
        }}
      >
        {sidebar}
        {content}
      </Container>
    </Container>
  )
}
