import type { Filter, LegacyFilter, ListFilterByOperator } from '../types.js'
import { sanitizeLegacyFilters } from './legacyConverters.js'
import { parseFilterValue } from './values.js'

export const decodeFilterFromQuery = (filterQueryString: string) => {
  try {
    const legacyFilterString = atob(filterQueryString)
    const uriDecodedString = decodeURIComponent(legacyFilterString)
    const legacyFilters = JSON.parse(uriDecodedString) as LegacyFilter[]
    const sanitizedLegacyFilters = sanitizeLegacyFilters(legacyFilters)

    return sanitizedLegacyFilters
  } catch (error) {
    return null
  }
}

export const encodeFilterToQuery = (legacyFilter: LegacyFilter[]) => {
  const legacyFilterString = JSON.stringify(legacyFilter)
  const uriEncodedString = encodeURIComponent(legacyFilterString)
  const legacyFilterB64 = btoa(uriEncodedString)

  return legacyFilterB64
}

export const applyStoredFilters = (
  queryLegacyFilters: LegacyFilter[],
  filters: Filter[],
) => {
  if (!queryLegacyFilters) {
    return filters
  }

  const newFilters: Filter[] = filters.map(filter => {
    const { key } = filter
    const queryFilter = queryLegacyFilters.find(
      ({ key: queryKey }) => key === queryKey,
    )

    if (queryFilter) {
      const { value, operator } = queryFilter
      const valueFromQuery = parseFilterValue(value)
      const operatorFromQuery = operator as ListFilterByOperator
      return { ...filter, value: valueFromQuery, operator: operatorFromQuery }
    }

    return filter
  })

  return newFilters
}
