import { Typography } from '@tribeplatform/design-system/types'
import { typography as systemTypography } from '@tribeplatform/design-system/typography/system'
import { logger } from '@tribeplatform/react-components/common'

import { TThemeModules } from './colors.js'

export const legacyTypographyModules = [
  'system',
  'alton',
  'doelger',
  'judah',
  'ocean-beach',
  'wordpress-kubrick',
]

export const typographyModules = [
  'system',
  'space-grotesk',
  'poppins',
  'raleway',
  'plus-jakarta-sans',
  'halyard-display',
  'freight-text',
  'dm-sans',
  'rubik',
  'quicksand',
  'geologica',
  'montserrat-alternates',
  'kanit',
  'libre-baskerville',
  'ibm-condensed',
  'roboto',

  'alton',
  'doelger',
  'judah',
  'ocean-beach',
  'wordpress-kubrick',
] as const

export type TTypographyModules = (typeof typographyModules)[number]
export type TTypographyModule = { typography: Typography }
const isSupportedTypography = (
  typographyName: string,
): typographyName is TTypographyModules =>
  typographyModules.includes(typographyName as TTypographyModules)

/**
 * dynamic import does not support import from module
 * https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#imports-must-start-with--or-
 */
const resolveTypographyJsModule = (name: TTypographyModules) => {
  switch (name) {
    case 'system':
      return import('@tribeplatform/design-system/typography/system')
    // legacy typography
    case 'alton':
      return import('@tribeplatform/design-system/typography/alton')
    case 'doelger':
      return import('@tribeplatform/design-system/typography/doelger')
    case 'judah':
      return import('@tribeplatform/design-system/typography/judah')
    case 'ocean-beach':
      return import('@tribeplatform/design-system/typography/ocean-beach')
    case 'wordpress-kubrick':
      return import('@tribeplatform/design-system/typography/wordpress-kubrick')
    // new typography
    case 'space-grotesk':
      return import('@tribeplatform/design-system/typography/space-grotesk')
    case 'poppins':
      return import('@tribeplatform/design-system/typography/poppins')
    case 'plus-jakarta-sans':
      return import('@tribeplatform/design-system/typography/plus-jakarta-sans')
    case 'kanit':
      return import('@tribeplatform/design-system/typography/kanit')
    case 'halyard-display':
      return import('@tribeplatform/design-system/typography/halyard-display')
    case 'raleway':
      return import('@tribeplatform/design-system/typography/raleway')
    case 'freight-text':
      return import('@tribeplatform/design-system/typography/freight-text')
    case 'montserrat-alternates':
      return import(
        '@tribeplatform/design-system/typography/montserrat-alternates'
      )
    case 'dm-sans':
      return import('@tribeplatform/design-system/typography/dm-sans')
    case 'rubik':
      return import('@tribeplatform/design-system/typography/rubik')
    case 'quicksand':
      return import('@tribeplatform/design-system/typography/quicksand')
    case 'geologica':
      return import('@tribeplatform/design-system/typography/geologica')
    case 'libre-baskerville':
      return import('@tribeplatform/design-system/typography/libre-baskerville')
    case 'ibm-condensed':
      return import('@tribeplatform/design-system/typography/ibm-condensed')
    case 'roboto':
      return import('@tribeplatform/design-system/typography/roboto')
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = name
      return exhaustiveCheck
    }
  }
}

export const importTypographyModule = async (
  typographyName: string,
): Promise<Typography> => {
  try {
    if (!typographyName || !isSupportedTypography(typographyName)) {
      return
    }

    const module = await resolveTypographyJsModule(typographyName)
    return module.typography
  } catch (e) {
    logger.warn('error loading typography module', typographyName)
  }
}

const typographyThemes = {} as Record<TThemeModules, Typography>

Promise.all(
  typographyModules.map(async typographyName => {
    const typography = await importTypographyModule(typographyName)
    if (typography) {
      typographyThemes[typography.id] = typography
    }
  }),
)

const mapLegacyTypographyName = (legacyName: string) => {
  if (legacyName === 'oceanBeach') {
    return 'ocean-beach'
  }
  if (legacyName === 'wordpressKubrick') {
    return 'wordpress-kubrick'
  }
  return legacyName
}

// TODO import only currently used typography
export const resolveTypography = (legacyName: string) => {
  return (
    typographyThemes[mapLegacyTypographyName(legacyName)] ?? systemTypography
  )
}
