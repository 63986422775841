import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'

import { getColoredLabels } from '../../CMS/utils/utils.js'
import { Form } from '../../Form/index.js'

export const SelectInput = ({ field, name, onChange, value, extraProps }) => {
  const selectOptions = getColoredLabels(field)
  const options = selectOptions.map(({ id }) => id)
  const labels = selectOptions.reduce(
    (acc, { id, name }) => ({ ...acc, [id]: name }),
    {} as Record<string, string>,
  )

  if (extraProps?.label) {
    return (
      <Form.Select
        name={name}
        items={options?.map(value => ({
          text: labels[value] ?? (value || '-'),
          value,
        }))}
        onChange={value => {
          if (typeof onChange === 'function') {
            onChange(value)
          }
        }}
        {...extraProps}
      />
    )
  }

  return (
    <Dropdown>
      <Dropdown.Button>{labels[value] ?? value}</Dropdown.Button>
      <Dropdown.Items>
        {options.map(value => (
          <Dropdown.Item
            key={name}
            onClick={() => {
              if (typeof onChange === 'function') {
                onChange(value)
              }
            }}
          >
            {labels[value] ?? value}
          </Dropdown.Item>
        ))}
      </Dropdown.Items>
    </Dropdown>
  )
  return null
}
