import { T } from '@tribeplatform/react-components/i18n'
import { useNavigate } from '@tribeplatform/react-components/Link'
import { Button } from '@tribeplatform/react-ui-kit/Button'

interface Props {
  href: string
}
export const SignUpButton = ({ href }: Props) => {
  const navigate = useNavigate()

  return (
    <Button
      variant="primary"
      href={href}
      className="hidden lg:block shrink-0"
      onClick={() => navigate(href)}
    >
      <T defaultMessage="Sign up" id="Generics.SignUp.Verb" />
    </Button>
  )
}
