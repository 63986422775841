import { useSlate } from '@tribeplatform/react-slate-kit/hooks'
import { SlateProps } from '@tribeplatform/react-slate-kit/types'

import { Post } from './Post.js'
import { PostPreview } from './PostPreview.js'

type PostPageComponentType = (props: {
  spacePath: string
  postPath: string
}) => JSX.Element

export const PostPage: PostPageComponentType = ({ spacePath, postPath }) => {
  const {
    mode,
    extraContext: {
      slate: { postTypeId, postTypeSlateProps },
    },
  } = useSlate<{ postTypeId?: string; postTypeSlateProps?: SlateProps }>()

  if (mode !== 'live' && postTypeId) {
    return (
      <PostPreview
        postTypeId={postTypeId}
        postTypeSlateProps={postTypeSlateProps}
      />
    )
  }

  return <Post postPath={postPath} spacePath={spacePath} />
}
