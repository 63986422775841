import { CustomFieldType } from '@tribeplatform/gql-client/types'

import { logger } from '../../common/lib/logger.js'
import { CustomFieldSubtype } from '../../CustomField/utils.js'
import type { Filter } from '../types.js'
import { ArrayValuePicker } from '../valuePickers/ArrayValuePicker.js'
import { BooleanValuePicker } from '../valuePickers/BooleanValuePicker.js'
import { DateTimeValuePicker } from '../valuePickers/DateTimeValuePicker.js'
import { DateValuePicker } from '../valuePickers/DateValuePicker.js'
import { NumberValuePicker } from '../valuePickers/NumberValuePicker.js'
import { RatingValuePicker } from '../valuePickers/RatingValuePicker.js'
import { RelationValuePicker } from '../valuePickers/RelationValuePicker.js'
import { TextValuePicker } from '../valuePickers/TextValuePicker.js'

interface Props {
  filter: Filter
  spread?: boolean
  inline?: boolean
  className?: string
  onChange: (value: Filter['value']) => void
}
export const ValuePicker = ({
  filter,
  spread = false,
  inline = false,
  className,
  onChange,
}: Props) => {
  const ValuePickerComponent = filter?.customValuePickerComponent
  if (ValuePickerComponent) {
    return (
      <ValuePickerComponent
        filter={filter}
        onChange={onChange}
        spread={spread}
        className={className}
      />
    )
  }
  const { type, subType } = filter
  switch (type) {
    case CustomFieldType.array:
      return (
        <ArrayValuePicker
          filter={filter}
          onChange={onChange}
          spread={spread}
          inline={inline}
          className={className}
        />
      )
    case CustomFieldType.boolean:
      return <BooleanValuePicker filter={filter} onChange={onChange} />
    case CustomFieldType.date:
      if (subType === CustomFieldSubtype.DATETIME) {
        return (
          <DateTimeValuePicker
            filter={filter}
            onChange={onChange}
            inline={inline}
          />
        )
      }
      return (
        <DateValuePicker filter={filter} onChange={onChange} inline={inline} />
      )
    case CustomFieldType.number: {
      if (subType === CustomFieldSubtype.RATING) {
        return <RatingValuePicker filter={filter} onChange={onChange} />
      }
      return <NumberValuePicker filter={filter} onChange={onChange} />
    }
    case CustomFieldType.relation:
      return (
        <RelationValuePicker
          filter={filter}
          onChange={onChange}
          spread={spread}
          inline={inline}
          className={className}
        />
      )
    case CustomFieldType.text:
      if (subType === CustomFieldSubtype.SELECT) {
        return (
          <ArrayValuePicker
            filter={filter}
            onChange={onChange}
            spread={spread}
            inline={inline}
            className={className}
          />
        )
      }
      return <TextValuePicker filter={filter} onChange={onChange} />
    case CustomFieldType.object:
    case CustomFieldType.richText:
      return null
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = type
      logger.warn('Not supported field type: ', exhaustiveCheck)
      return null
    }
  }
}
