import { hasLocalStorage } from '@bettermode/common/local-storage-detect'

import { logger } from '../../../common/lib/logger.js'

export enum CookieKind {
  ESSENTIAL = 'ESSENTIAL',
  ANALYTICS_AND_FUNCTIONAL = 'ANALYTICS_AND_FUNCTIONAL',
  ADVERTISING_AND_TRACKING = 'ADVERTISING_AND_TRACKING',
}

export type OptionalCookieKind = Exclude<CookieKind, CookieKind.ESSENTIAL>

export type Cookie = {
  name: string
  company: string
  domains: string[]
}

export type CookieSettings = {
  hasUserConsent?: boolean
  [CookieKind.ESSENTIAL]?: {
    cookies: Cookie[]
    enabled: boolean
  }
  [CookieKind.ANALYTICS_AND_FUNCTIONAL]?: {
    cookies?: Cookie[]
    enabled?: boolean
  }
  [CookieKind.ADVERTISING_AND_TRACKING]?: {
    cookies?: Cookie[]
    enabled?: boolean
  }
}

let isSupported = false

try {
  isSupported = hasLocalStorage()
} catch (err) {
  logger.warn('localStorage is not supported')
}

const cookieSettings = 'cookieSettings'

const defaultCookieSettings: CookieSettings = {
  hasUserConsent: false,
  [CookieKind.ESSENTIAL]: {
    enabled: true,
    cookies: [
      {
        name: '_bettermode_theme',
        company: 'Bettermode',
        domains: ['bettermode.com', '*.bettermode.com'],
      },
    ],
  },
  [CookieKind.ANALYTICS_AND_FUNCTIONAL]: {
    enabled: false,
    cookies: [
      {
        name: '_bettermode_login',
        company: 'Bettermode',
        domains: ['bettermode.com', '*.bettermode.com'],
      },
      {
        name: '_bettermode_session',
        company: 'Bettermode',
        domains: ['bettermode.com', '*.bettermode.com'],
      },
      {
        name: '_login_id',
        company: 'Google Inc.',
        domains: ['bettermode.com', '*.bettermode.com'],
      },
    ],
  },
  [CookieKind.ADVERTISING_AND_TRACKING]: {
    enabled: false,
    cookies: [],
  },
}

type GetUpdatedCookieSettingsParams = {
  cookieKind: OptionalCookieKind
  enabled: boolean
  cookie?: Cookie
}

const getUpdatedCookieSettings = ({
  cookieKind,
  enabled,
  cookie,
}: GetUpdatedCookieSettingsParams): CookieSettings => {
  if (isSupported) {
    try {
      const prevSettings = JSON.parse(
        localStorage.getItem(cookieSettings) || '""',
      )
      const updatedSettings: CookieSettings = {
        ...prevSettings,
        hasUserConsent: true,
        [cookieKind]: {
          ...prevSettings?.[cookieKind],
          ...cookie,
          enabled,
        },
      }
      return updatedSettings
    } catch (err) {
      logger.error(err)
    }
  }
  return {}
}

export const enableCookie = (cookieKind: OptionalCookieKind) => {
  if (isSupported) {
    const updatedSettings = getUpdatedCookieSettings({
      cookieKind,
      enabled: true,
    })
    localStorage.setItem(cookieSettings, JSON.stringify(updatedSettings))
  }
}

export const enableCookies = (cookieKinds: OptionalCookieKind[]) => {
  if (cookieKinds.length) {
    cookieKinds.forEach(cookieKind => enableCookie(cookieKind))
  }
}

export const disableCookie = (cookieKind: OptionalCookieKind) => {
  if (isSupported) {
    const updatedSettings = getUpdatedCookieSettings({
      cookieKind,
      enabled: false,
    })
    localStorage.setItem(cookieSettings, JSON.stringify(updatedSettings))
  }
}

export const disableCookies = (cookieKinds: OptionalCookieKind[]) => {
  if (cookieKinds.length) {
    cookieKinds.forEach(cookieKind => disableCookie(cookieKind))
  }
}

export const addCookie = (
  cookieKind: OptionalCookieKind,
  enabled: boolean,
  cookie: Cookie,
) => {
  if (isSupported) {
    const updatedSettings = getUpdatedCookieSettings({
      cookieKind,
      enabled,
      cookie,
    })
    localStorage.setItem(cookieSettings, JSON.stringify(updatedSettings))
  }
}

export const setCookieSettings = (newSettings: CookieSettings) =>
  isSupported &&
  localStorage.setItem(cookieSettings, JSON.stringify(newSettings))

export const getCookieSettings = (): CookieSettings | undefined => {
  if (isSupported) {
    const settings = JSON.parse(localStorage.getItem(cookieSettings) || '""')
    if (settings) {
      return settings
    }
    setCookieSettings(defaultCookieSettings)
    return defaultCookieSettings
  }

  return undefined
}
