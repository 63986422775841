import { clsx } from 'clsx'

import { ItemAlignment, type MenuItem as TMenuItem } from '../types.js'
import { SubNavItem } from './SubNavItem.js'

interface Props {
  menuItems: TMenuItem[]
  alignment: ItemAlignment
}
export const SubNavMenuRenderer = ({ menuItems, alignment }: Props) => {
  return (
    <div
      className={clsx(
        'flex items-center gap-x-5',
        clsx(
          alignment === ItemAlignment.start && 'justify-start',
          alignment === ItemAlignment.center && 'justify-center',
          alignment === ItemAlignment.end && 'justify-end',
        ),
      )}
    >
      {menuItems.map(({ id, name, url, target, subMenuStyle, menuItems }) => (
        <SubNavItem
          key={id}
          id={id}
          name={name}
          url={url}
          target={target}
          subMenuStyle={subMenuStyle}
          menuItems={menuItems}
          parentAlignment={alignment}
        />
      ))}
    </div>
  )
}
