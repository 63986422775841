import type { Media } from '@tribeplatform/gql-client/types'
import type { LogoSize } from '@tribeplatform/react-components/Logo'

import type { SearchAction, Target } from '../HeroBanner/types.js'

export enum NavbarLayout {
  logoSearchActions = 'logoSearchActions',
  logoMenuActions = 'logoMenuActions',
  logoMenuLeftActions = 'logoMenuLeftActions',
  logoActions = 'logoActions',
  menuLogoActions = 'menuLogoActions',
  logoSearchLeftActions = 'logoSearchLeftActions',
}
export enum NavbarSectionItem {
  logo = 'logo',
  search = 'search',
  menu = 'menu',
  actions = 'actions',
}
export enum ActionItemId {
  mainMenu = 'mainMenu',
  createEntity = 'createEntity',
  notifications = 'notifications',
  messages = 'messages',
  search = 'search',
  themeSwitch = 'themeSwitch',
  languageSwitch = 'languageSwitch',
}
export enum MobileMenuSectionItem {
  headerMenu = 'headerMenu',
  headerSubMenu = 'headerSubMenu',
  sidebar = 'sidebar',
}
export enum DropDownItemType {
  regular = 'regular',
  divider = 'divider',
}
export enum SubMenuStyle {
  grid = 'grid',
  dropdown = 'dropdown',
}
export enum SubNavigationPosition {
  top = 'top',
  bottom = 'bottom',
}
export enum ItemAlignment {
  start = 'start',
  center = 'center',
  end = 'end',
}
interface GenericDropDownItem {
  type: DropDownItemType
  id: string
}
interface DividerDropDownItem extends GenericDropDownItem {
  type: DropDownItemType.divider
}
interface RegularDropDownItem extends GenericDropDownItem {
  type: DropDownItemType.regular
  label: string
  href?: string
  action?: () => void
  icon: JSX.Element
  indicator?: number
}
export type DropDownItem = RegularDropDownItem | DividerDropDownItem
export interface RawActionItem {
  id: ActionItemId
  enabled: boolean
}
export interface ActionItem extends RawActionItem {
  icon: JSX.Element
  label: string
}
export interface MenuItem {
  id: string
  name: string
  description?: string
  icon?: Media
  url: string
  target: Target
  subMenuStyle: SubMenuStyle
  menuItems: MenuItem[]
}
export interface SubNavigation {
  enabled: boolean
  position: SubNavigationPosition
  alignment: ItemAlignment
  menuItems: MenuItem[]
}
interface GenericNavbarSection {
  id: NavbarSectionItem
  enabled: boolean
  stretch: boolean
  alignment: ItemAlignment
}
export interface LogoSection extends GenericNavbarSection {
  id: NavbarSectionItem.logo
  logoSize: LogoSize
}
export interface SearchSection extends GenericNavbarSection {
  id: NavbarSectionItem.search
}
export interface MenuSection extends GenericNavbarSection {
  id: NavbarSectionItem.menu
  items: MenuItem[]
}
export interface ActionsSection extends GenericNavbarSection {
  id: NavbarSectionItem.actions
  items: RawActionItem[]
}
export interface SectionDetails {
  label: string
  icon: JSX.Element
}
export type NavbarSection =
  | LogoSection
  | SearchSection
  | MenuSection
  | ActionsSection
export interface MobileMenuSection {
  id: MobileMenuSectionItem
  enabled: boolean
}
export type NavbarProps = {
  version: string
  sections: NavbarSection[]
  searchSettings: SearchAction
  subNavigation: SubNavigation
  mobileMenuSections: MobileMenuSection[]
}
