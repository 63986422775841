import { T, useStaticIntl } from '@tribeplatform/react-components/i18n'
import { GenericSettings } from '@tribeplatform/react-components/Views'
import { FormControlSlider } from '@tribeplatform/react-ui-kit/FormControl/FormControlSlider'

import { useBlock } from '../../../hooks/block.hook.js'
import { MarginSettings } from '../Components/MarginSettings.js'
import { PaddingSettings } from '../Components/PaddingSettings.js'
import { HideOnDeviceSettings } from '../Components/ResponsiveSettings/HideOnDeviceSettings.js'
import { SettingSections } from '../Components/SettingSections.js'
import { SliderTertiaryLabel } from '../Components/SliderTertiaryLabel.js'
import { spacingTitleMapper } from '../utils/selectItems.js'
import { sliderNumberToSpacing, spacingToSliderNumber } from '../utils/utils.js'
import { ColumnProps } from './types.js'

interface Props {
  deactivateBlock?: () => void
}

export const ColumnBlockSettings = ({ deactivateBlock }: Props) => {
  const spacingTitleMap = useStaticIntl(spacingTitleMapper)

  const {
    block: {
      config: { displayName },
      props: {
        gap,
        horizontalPadding,
        verticalPadding,
        horizontalMargin,
        verticalMargin,
        hideOnMobile,
        hideOnTablet,
        hideOnDesktop,
      },
    },
    upsertProp,
  } = useBlock<ColumnProps>()

  return (
    <GenericSettings title={displayName} goBack={deactivateBlock}>
      <SettingSections>
        <SettingSections.Layout>
          <FormControlSlider
            name="gap"
            label={<T id="Generics.Gap" defaultMessage="Gap" />}
            tertiaryLabel={<SliderTertiaryLabel value={spacingTitleMap[gap]} />}
            helperText={
              <T
                id="Blocks.Column.Settings.GapHelperText"
                defaultMessage="Space between the blocks inside column."
              />
            }
            min={0}
            max={5}
            step={1}
            value={spacingToSliderNumber[gap]}
            onChange={value => upsertProp('gap', sliderNumberToSpacing[value])}
          />
        </SettingSections.Layout>
        <PaddingSettings
          horizontalPadding={horizontalPadding}
          verticalPadding={verticalPadding}
          onHorizontalPaddingChange={value =>
            upsertProp('horizontalPadding', value)
          }
          onVerticalPaddingChange={value =>
            upsertProp('verticalPadding', value)
          }
        />
        <MarginSettings
          horizontalMargin={horizontalMargin}
          verticalMargin={verticalMargin}
          onHorizontalMarginChange={value =>
            upsertProp('horizontalMargin', value)
          }
          onVerticalMarginChange={value => upsertProp('verticalMargin', value)}
        />
        <SettingSections.Responsive>
          <HideOnDeviceSettings
            hideOnMobile={hideOnMobile}
            hideOnTablet={hideOnTablet}
            hideOnDesktop={hideOnDesktop}
            onHideOnMobileChange={value => upsertProp('hideOnMobile', value)}
            onHideOnTabletChange={value => upsertProp('hideOnTablet', value)}
            onHideOnDesktopChange={value => upsertProp('hideOnDesktop', value)}
          />
        </SettingSections.Responsive>
      </SettingSections>
    </GenericSettings>
  )
}
