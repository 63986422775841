import { useMemo } from 'react'

import {
  FlaggedType,
  ModerationEntityType,
  ModerationStatus,
  SpaceJoinRequestStatus,
} from '@tribeplatform/gql-client/types'

import { useSpaceJoinRequests } from '../spaceMembership/useSpaceJoinRequests.js'
import { useModerationItems } from './useModerationItems.js'
import { usePostModerationItems } from './usePostModerationItems.js'

export const useModerationCount = (spaceId?: string) => {
  const { data: reportedPostsData } = usePostModerationItems({
    fields: {
      pageInfo: 'basic',
    },
    variables: {
      limit: 0,
      spaceId,
      status: ModerationStatus.REVIEW,
      flaggedBy: FlaggedType.MEMBER,
    },
  })
  const reportedPostsCount = reportedPostsData?.pages?.[0]?.totalCount ?? 0

  const { data: pendingPostsData } = usePostModerationItems({
    fields: {
      pageInfo: 'basic',
    },
    variables: {
      limit: 0,
      spaceId,
      status: ModerationStatus.REVIEW,
      flaggedBy: FlaggedType.SYSTEM,
    },
  })
  const pendingPostsCount = pendingPostsData?.pages?.[0]?.totalCount ?? 0

  const { data: reportedMembersData } = useModerationItems({
    fields: {
      entity: {
        onMember: 'basic',
      },
    },
    variables: {
      limit: 0,
      entityType: ModerationEntityType.MEMBER,
      status: ModerationStatus.REVIEW,
    },
  })
  const reportedMembersCount = reportedMembersData?.pages?.[0]?.totalCount ?? 0

  const { data: pendingMembersData } = useSpaceJoinRequests({
    variables: {
      limit: 0,
      spaceId,
      status: SpaceJoinRequestStatus.PENDING,
    },
    fields: {},
  })
  const pendingMembersCount = pendingMembersData?.pages?.[0].totalCount ?? 0

  const total = useMemo(() => {
    return (
      reportedPostsCount +
      pendingPostsCount +
      reportedMembersCount +
      pendingMembersCount
    )
  }, [
    reportedPostsCount,
    pendingPostsCount,
    reportedMembersCount,
    pendingMembersCount,
  ])

  return {
    total,
    reportedPostsCount,
    pendingPostsCount,
    reportedMembersCount,
    pendingMembersCount,
  }
}
