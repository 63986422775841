import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import { TITLE_FIELD_KEY } from '@bettermode/common/CMS'
import {
  CustomFieldType,
  type CustomFieldSchema,
  type Image,
  type Member,
  type Space,
} from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'

import { getColoredLabels } from '../CMS/utils/utils.js'
import { dayjs } from '../common/lib/dayjs.js'
import { logger } from '../common/lib/logger.js'
import { getLocalizedDateFormat } from '../common/utils/date.js'
import { useI18n } from '../i18n/index.js'
import { MemberPill } from '../Member/MemberPill.js'
import { SpacePill } from '../Space/SpacePill.js'
import { BooleanView } from './components/BooleanView.js'
import { ButtonView } from './components/ButtonView.js'
import { DateView } from './components/DateView.js'
import { FilesView } from './components/FilesView.js'
import { ImageGalleryView } from './components/ImageGallery/ImageGalleryView.js'
import { ImageView } from './components/ImageView.js'
import { LocationView } from './components/LocationView.js'
import { PillsView } from './components/PillsView.js'
import { PillView } from './components/PillView.js'
import { RatingView } from './components/RatingView.js'
import { RichTextView } from './components/RichTextView.js'
import { SocialMediaView } from './components/SocialMediaView.js'
import { TextAreaView } from './components/TextAreaView.js'
import { TitleView } from './components/TitleView.js'
import { FieldPrivacyBadges } from './FieldPrivacyBadges.js'
import {
  CustomFieldSubtype,
  CustomFieldViews,
  getFieldSetting,
} from './utils.js'

const getSampleFile = name => ({
  name,
  extension: 'pdf',
  size: 50000,
})

const getSampleImage = path => ({
  url: path,
  urls: {
    full: path,
    large: path,
    medium: path,
    thumb: path,
  },
})

/**
 * Get the view component for a field.
 * To be backwards compatible with the legacy post renderers, it returns Title view
 * for the field with {@link TITLE_FIELD_KEY} key.
 */
const getView = (field: CustomFieldSchema) => {
  const view: CustomFieldViews =
    getFieldSetting(field, 'view') || getFieldSetting(field, 'viewComponent')

  if (field.key === TITLE_FIELD_KEY) {
    return view || CustomFieldViews.Title
  }

  return view
}

export const getFieldPreviewValue = ({ view, field, subtype }) => {
  let options = []

  const getValidatorsEnum = () => {
    try {
      const validators = field?.items?.validators || field?.validators
      options = JSON.parse(
        validators?.find(v => v.validation === 'enum')?.value,
      )
    } catch (e) {
      logger.error('Error parsing network entity field value', e.message)
    }
    return options || []
  }

  switch (view) {
    case CustomFieldViews.Title:
      return 'Lorem sunt excepteur aliquip'
    case CustomFieldViews.Button:
      return '#'
    case CustomFieldViews.Date:
      return dayjs().startOf('hour').toDate()
    case CustomFieldViews.Location:
      return 'New York, USA'
    case CustomFieldViews.Rating:
      return 4
    default:
  }

  switch (field?.type) {
    case CustomFieldType.richText:
      return '<p>Commodo <b>magna esse</b> ea in in. Minim <i>occaecat</i> aute <a>veniam pariatur</a> laboris veniam.</p>'
    case CustomFieldType.number:
      return 1234
    case CustomFieldType.boolean:
      return true
    case CustomFieldType.date:
      return dayjs().startOf('hour').toDate()
    case CustomFieldType.array:
      switch (subtype) {
        case CustomFieldSubtype.MULTISELECT:
          options = getValidatorsEnum()?.slice(0, 2)
          return options?.length ? options : ['Sample', 'Value']
        case CustomFieldSubtype.IMAGES:
          return [
            getSampleImage(
              'https://tribe-s3-production.imgix.net/NflFD6u3VW1YABpEJdeeb?w=2000&auto=compress,format&dl',
            ),
            getSampleImage(
              'https://tribe-s3-production.imgix.net/NflFD6u3VW1YABpEJdeeb?w=2000&auto=compress,format&dl',
            ),
            getSampleImage(
              'https://tribe-s3-production.imgix.net/NflFD6u3VW1YABpEJdeeb?w=2000&auto=compress,format&dl',
            ),
          ]
        case CustomFieldSubtype.FILES:
          return [getSampleFile('Sample file')]
        case CustomFieldSubtype.MEMBERS:
          return [{ name: 'Jane Smith' }, { name: 'John Smith' }]
        case CustomFieldSubtype.SPACES:
          return [{ name: 'Space A' }, { name: 'Space B' }]
        default:
          return []
      }
    case CustomFieldType.text:
      switch (subtype) {
        case CustomFieldSubtype.URL:
          return 'https://example.com'
        case CustomFieldSubtype.EMAIL:
          return 'info@example.com'
        case CustomFieldSubtype.TEXTAREA:
          return 'Lorem sunt excepteur aliquip commodo deserunt pariatur.'
        case CustomFieldSubtype.SELECT:
          return getValidatorsEnum()?.[0] || 'Sample value'
        default:
          return 'Sample value'
      }
    case CustomFieldType.relation:
      switch (subtype) {
        case CustomFieldSubtype.IMAGE:
          return getSampleImage(
            'https://tribe-s3-production.imgix.net/NflFD6u3VW1YABpEJdeeb?w=2000&auto=compress,format&dl',
          )
        case CustomFieldSubtype.FILE:
          return getSampleFile('Sample file')
        case CustomFieldSubtype.SPACE:
          return { name: 'Test space' }
        case CustomFieldSubtype.MEMBER:
          return { name: 'Jane Smith' }
        default:
          return null
      }

    default:
  }
}

const isEmpty = (value, type) => {
  if (type === CustomFieldType.boolean) {
    return value === null || typeof value === 'undefined'
  }
  return !value
}

export const FieldView = ({
  field,
  entity,
  highlighted = false,
  preview = false,
  showLabel = false,
  showFieldPrivacyBadge = true,
  collapsible = false,
  className = '',
  allowEmpty = false,
  context = null,
}) => {
  const { Link } = useRouter()
  const { localeCode } = useI18n()
  if (field?.archived) {
    return null
  }

  let entityField = entity?.fields?.find(f => f.key === field.key)
  if (!entityField) {
    entityField = { key: field.key, value: null }
  }
  let { value } = entityField

  // Fallback for scaffolding. Can be removed after migration
  if (value === JSON.stringify(null) && !!entity?.mappingFields) {
    if (field?.key === 'title') {
      entityField = entity?.mappingFields?.find(
        f => f.key === 'title' || f.key === 'question',
      )
    } else if (field?.key === 'content') {
      entityField = entity?.mappingFields?.find(
        f =>
          f.key === 'content' || f.key === 'description' || f.key === 'answer',
      )
    }

    value = entityField?.value
  }

  if (value) {
    try {
      value = JSON.parse(value)
    } catch (e) {
      logger.error('Error parsing network entity field value', e.message)
    }
  }

  if ((typeof value === 'undefined' || value === null) && !preview) {
    if (allowEmpty) {
      return <div className={className}></div>
    }

    return null
  }

  const view = getView(field)
  const viewProps = getFieldSetting(field, 'viewProps') || {}
  const subtype = getFieldSetting(field, 'subtype')

  const viewMapping = {
    Button: ButtonView,
    Date: DateView,
    SocialMedia: SocialMediaView,
    Title: TitleView,
    Pill: PillView,
    Pills: PillsView,
    Location: LocationView,
    Rating: RatingView,
  }

  const Component = viewMapping[view]

  let isSample = false
  let result = null

  if (isEmpty(value, field?.type)) {
    if (preview) {
      value = getFieldPreviewValue({ view, field, subtype })
      isSample = true
    } else {
      if (allowEmpty) {
        return <div className={className}></div>
      }

      return null
    }
  }

  if (field.type === CustomFieldType.date) {
    if (viewProps.format === 'relative') {
      value = dayjs.utc(value).fromNow()
    } else if (viewProps.format === 'standard') {
      if (subtype === CustomFieldSubtype.DATETIME) {
        value = dayjs.utc(value).tz().format('ddd, MMM D, h:mma')
      } else {
        value = dayjs(value).format('ddd, MMM D')
      }
    } else if (subtype === CustomFieldSubtype.DATETIME) {
      const { dateTime } = getLocalizedDateFormat(value, localeCode)
      value = dateTime
    } else {
      const { date } = getLocalizedDateFormat(value, localeCode, 'UTC')
      value = date
    }
  }

  let flatRelationEntities = null
  if (entityField?.relationEntities) {
    flatRelationEntities = Object.keys(entityField?.relationEntities).reduce(
      (result, key) => [
        ...result,
        ...(Array.isArray(entityField?.relationEntities?.[key])
          ? entityField?.relationEntities?.[key]
          : []),
      ],
      [],
    )
    if (!flatRelationEntities?.length) {
      flatRelationEntities = null
    }
  }

  if (Component) {
    result = (
      <Component
        field={field}
        value={value}
        entity={entity}
        context={context}
        {...viewProps}
      />
    )
    if (Component?.skipLabel) {
      showLabel = false
    }
  } else {
    if (field.type === CustomFieldType.array) {
      if (subtype === CustomFieldSubtype.MULTISELECT) {
        const coloredLabels = getColoredLabels(field)
        const values = coloredLabels
          .filter(({ id }) => value.includes(id))
          .map(({ name }) => name)
        result = values.join(', ')
      } else {
        const entities = flatRelationEntities || value || []
        if (entities.length) {
          if (subtype === CustomFieldSubtype.IMAGES) {
            result = (
              <ImageGalleryView
                className="my-2 rounded-base overflow-hidden"
                images={entities}
              />
            )
          } else if (subtype === CustomFieldSubtype.FILES) {
            result = <FilesView files={entities} />
          } else if (subtype === CustomFieldSubtype.MEMBERS) {
            result = (
              <div className="flex gap-2 flex-wrap">
                {entities.map((entity, index) => (
                  <MemberPill key={entity.id ?? index} member={entity} />
                ))}
              </div>
            )
          } else if (subtype === CustomFieldSubtype.SPACES) {
            result = (
              <div className="flex gap-2 flex-wrap">
                {entities.map((entity, index) => (
                  <SpacePill key={entity.id ?? index} space={entity} />
                ))}
              </div>
            )
          } else {
            value = null
          }
        } else {
          value = null
        }
      }
    } else if (field.type === CustomFieldType.number) {
      if (typeof value?.toLocaleString === 'function') {
        result = value.toLocaleString()
      }
    } else if (field.type === CustomFieldType.boolean) {
      result = <BooleanView value={value} />
    } else if (field.type === CustomFieldType.richText) {
      result = (
        <RichTextView
          context={context}
          collapsible={collapsible}
          value={value}
          entity={entity}
        />
      )
    } else if (field.type === CustomFieldType.text) {
      const subtype = getFieldSetting(field, 'subtype')
      if (subtype === CustomFieldSubtype.URL) {
        result = (
          <Link
            className="truncate"
            href={value}
            variant="neutral"
            onClick={e => e.stopPropagation()}
          >
            {value}
          </Link>
        )
      } else if (subtype === CustomFieldSubtype.EMAIL) {
        result = (
          <Link
            className="truncate"
            href={`mailto:${value}`}
            target="_blank"
            variant="neutral"
            onClick={e => e.stopPropagation()}
          >
            {value}
          </Link>
        )
      } else if (subtype === CustomFieldSubtype.SELECT) {
        const coloredLabels = getColoredLabels(field)
        result = coloredLabels.find(option => option?.id === value)?.name
      } else if (subtype === CustomFieldSubtype.TEXTAREA) {
        result = <TextAreaView value={value} />
      }
    } else if (field.type === CustomFieldType.relation) {
      const entities = flatRelationEntities || [value] || []

      if (entities.length) {
        if (subtype === CustomFieldSubtype.IMAGE) {
          const media = entities?.[0] as Image
          result = <ImageView image={media} />
        } else if (subtype === CustomFieldSubtype.MEMBER) {
          const member = entities?.[0] as Member
          result = <MemberPill member={member} />
        } else if (subtype === CustomFieldSubtype.SPACE) {
          const space = entities?.[0] as Space
          result = <SpacePill space={space} />
        } else if (subtype === CustomFieldSubtype.FILE) {
          result = <FilesView files={[entities?.[0]]} />
        }

        if (!value) {
          value = null
        }
      } else {
        value = null
      }
    }
    if (!result) {
      result = value
    }
  }

  if (!showLabel) {
    return (
      <div
        className={twMerge(
          clsx(
            'mt-1 text-content',
            field.type !== CustomFieldType.richText && 'text-sm',
            highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
            subtype === CustomFieldSubtype.URL && 'truncate',
            subtype === CustomFieldSubtype.EMAIL && 'truncate',
            className,
          ),
        )}
      >
        {result}
      </div>
    )
  }

  return (
    <div
      className={twMerge(
        clsx(
          'web:sm:col-span-1 space-y-1',
          highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        ),
        className,
      )}
      key={entityField.key}
    >
      <dt className="font-medium text-content-subdued flex space-s-1 items-center">
        <span>{field.name}</span>
        {showFieldPrivacyBadge && <FieldPrivacyBadges field={field} />}
      </dt>
      <dd
        className={clsx(
          'text-content',
          field.type !== CustomFieldType.richText && 'text-sm',
          field.type === CustomFieldType.text && 'min-w-0 truncate',
          field.type !== CustomFieldType.text && 'flex items-center',
          isSample && 'opacity-50',
        )}
      >
        {result}
      </dd>
    </div>
  )
}
