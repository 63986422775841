/**
 * Extracted from:
 * - https://github.com/auth0/jwt-decode
 * - https://github.com/davidchambers/Base64.js
 */

import { base64UrlDecode } from './atob.js'
import { InvalidTokenError } from './errors.js'

export function JwtDecode<T>(token: string, options?: { header?: boolean }): T {
  if (typeof token !== 'string') {
    throw new InvalidTokenError('Invalid token specified: must be a string')
  }

  const pos = options?.header === true ? 0 : 1

  const part = token.split('.')[pos]
  if (typeof part !== 'string') {
    throw new InvalidTokenError(
      `Invalid token specified: missing part #${pos + 1}`,
    )
  }

  let decoded = ''
  try {
    decoded = base64UrlDecode(part)
  } catch (e) {
    throw new InvalidTokenError(
      `Invalid token specified: invalid base64 for part #${pos + 1} (${
        e.message
      })`,
    )
  }

  try {
    return JSON.parse(decoded)
  } catch (e) {
    throw new InvalidTokenError(
      `Invalid token specified: invalid json for part #${pos + 1} (${
        e.message
      })`,
    )
  }
}
