import { ComponentProps, forwardRef, ReactNode } from 'react'

import { SvgIcon } from '../Icon/SvgIcon.js'
import {
  selectItemIconStyles,
  selectItemStyles,
} from '../Select/Select.styles.js'
import { runIfFn } from '../utils/index.js'
import { useMultiselect } from './MultiselectContext.js'
import { hasId } from './util.js'

interface MultiselectItemRenderProps {
  selected: boolean
  active: boolean
}

export type MultiselectItemProps<T = unknown> = Omit<
  ComponentProps<'li'>,
  'value' | 'css' | 'children'
> & {
  value: T
  index: number
  disabled?: boolean
  children?: ReactNode | ((props: MultiselectItemRenderProps) => ReactNode)
}
export const MultiselectItem = forwardRef<HTMLLIElement, MultiselectItemProps>(
  (props, ref) => {
    const { value, index, children, disabled, className, ...rest } = props

    const { selectedItems, getItemProps, highlightedIndex } = useMultiselect()

    let selected
    if (hasId(value)) {
      selected = selectedItems.some(it => hasId(it) && it.id === value.id)
    } else {
      selected = selectedItems.includes(value)
    }

    const active = highlightedIndex === index

    return (
      <li
        {...getItemProps({ ref, item: value, index, disabled, ...rest })}
        className={selectItemStyles({
          active,
          selected,
          disabled,
          className,
        })}
      >
        <div className="min-w-0 truncate">
          {runIfFn(children, {
            selected,
            active,
          })}
        </div>
        {selected && (
          <span className="absolute inset-y-0 end-0 flex items-center pe-4">
            <SvgIcon
              className={selectItemIconStyles({ active })}
              aria-hidden="true"
              name="check"
            />
          </span>
        )}
      </li>
    )
  },
)
