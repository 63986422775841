import type { BC } from '@tribeplatform/react-slate-kit/types'
import { Card } from '@tribeplatform/react-ui-kit/Card'

import { NetworkMemberList } from './NetworkMemberList.js'
import { SpaceMemberList } from './SpaceMemberList.js'
import { MembersBlockProps } from './types.js'

export const MembersBlock: BC<MembersBlockProps> = props => {
  const { title, description, showTitle, spaceId, ...rest } = props

  return (
    <Card>
      {(title || description) && (
        <Card.Header
          title={showTitle ? title : ''}
          description={description}
          size="sm"
        />
      )}
      <Card.Content>
        {spaceId ? (
          <SpaceMemberList spaceId={spaceId} {...rest} />
        ) : (
          <NetworkMemberList {...rest} />
        )}
      </Card.Content>
    </Card>
  )
}
