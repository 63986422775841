import type { MemberFields } from '@tribeplatform/gql-client/graphql'
import { type Member, RoleType } from '@tribeplatform/gql-client/types'

import {
  useQuery,
  type UseQueryOptions,
} from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getAuthMemberKey } from '../../utils/keys/authToken.keys.js'
import { useCachedAuthMember } from '../cache/useCachedAuthMember.js'

export const useAuthMember = (options?: {
  useQueryOptions?: UseQueryOptions<Member>
  fields: MemberFields
}) => {
  const { useQueryOptions, fields = 'default' } = options || {}
  const { client } = useTribeClient()
  const authMemberKey = getAuthMemberKey({ fields })

  const query = useQuery<Member>(
    authMemberKey,
    () => client.auth.authMember(fields),
    {
      placeholderData: useCachedAuthMember(),
      ...useQueryOptions,
    },
  )

  const member = query?.data
  const isAdmin = member?.role?.type === RoleType.admin
  const isModerator =
    member?.role?.type === RoleType.moderator ||
    member?.role?.type === RoleType.admin
  const isGuest =
    !member || !member?.role?.type || member?.role?.type === RoleType.guest

  return {
    isAdmin,
    isGuest,
    isModerator,
    ...query,
  }
}
