import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { HighlightedTags as Component } from './Block.js'
import { HighlightedTagsBlockProps } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({
    default: m.HighlightedTagsBlockSettings,
  })),
)

export const RegisteredHighlightedTagsBlock = (
  intl: I18nShape,
): RegisteredBlock<HighlightedTagsBlockProps> => {
  const { $t } = intl

  return {
    recommendedName: 'HighlightedTags',
    Component,
    config: {
      type: BlockType.Block,
      deprecated: true,

      displayName: $t({
        id: 'Blocks.HighlightedTags.displayName',
        defaultMessage: 'Highlighted {TAGS}',
      }),
      Settings,
      Icon: <SvgIcon name="cursor-click" />,
      defaultProps: {
        title: $t({
          id: 'Blocks.HighlightedTags.displayName',
          defaultMessage: 'Highlighted {TAGS}',
        }),
      },
    },
  }
}
