import { oAuthCodeGQLQuery, oAuthTokenGQLQuery } from '../../graphql/index.js'
import {
  OAuthCode,
  OAuthTokens,
  QueryOAuthTokensArgs,
  QueryOAuthCodeArgs,
} from '../../types/index.js'
import { GraphqlClient } from '../graphql.client.js'

export class OAuthClient {
  private client: GraphqlClient

  constructor(client: GraphqlClient) {
    this.client = client
  }

  async getOAuthCode(variables: QueryOAuthCodeArgs): Promise<OAuthCode> {
    type QueryResult = { oAuthCode: OAuthCode }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: oAuthCodeGQLQuery(),
      variables,
    })
    return result.oAuthCode
  }

  async getOAuthToken(
    variables: QueryOAuthTokensArgs,
    accessToken: string,
  ): Promise<OAuthTokens> {
    type QueryResult = { oAuthTokens: OAuthTokens }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: oAuthTokenGQLQuery(),
      variables,
      customToken: accessToken,
      // as we are trying to authenticate the app, we need to force to check basic token
      useBasicToken: true,
    })
    return result.oAuthTokens
  }
}
