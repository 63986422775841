import { useRouter } from '@tribeplatform/react-sdk'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { useColorScheme } from '@tribeplatform/react-ui-kit/ThemeProvider'

import { REFERRAL_LINK_REGEX } from '../../constants/referral.js'
import { MadeWithBettermodeBadgeDarkSVG } from '../SVG/MadeWithBettermodeBadgeDarkSVG.js'
import { MadeWithBettermodeBadgeSVG } from '../SVG/MadeWithBettermodeBadgeSVG.js'

export const MadeWithBettermode = () => {
  const {
    data: { name, domain, tribeBranding, referralProgramEnabled, referralLink },
  } = useNetwork()
  const { colorSchemeMode } = useColorScheme()
  const { Link } = useRouter()

  if (!tribeBranding) {
    return null
  }

  const url = new URL('https://bettermode.com')
  url.searchParams.set('utm_campaign', 'powered-by')
  url.searchParams.set('utm_medium', 'referral')
  url.searchParams.set('utm_source', domain)
  url.searchParams.set('utm_term', name)

  const hasReferralLink = referralProgramEnabled && !!referralLink
  if (hasReferralLink) {
    const match = referralLink.match(REFERRAL_LINK_REGEX)
    if (match) {
      const [_, ucc] = match
      url.searchParams.set('ucc', ucc)
    }
  }

  return (
    <Link
      href={url.toString()}
      target="_blank"
      className="hidden sm:block pointer-events-auto"
    >
      {colorSchemeMode === 'light' && (
        <MadeWithBettermodeBadgeSVG className="w-[177px] shadow bg-white border-branding-line border rounded-md" />
      )}
      {colorSchemeMode === 'dark' && (
        <MadeWithBettermodeBadgeDarkSVG className="w-[177px] shadow bg-black border-branding-line-dark border rounded-md" />
      )}
    </Link>
  )
}
