import { FC, useMemo } from 'react'

import { Disclosure, Transition } from '@headlessui/react'
import { clsx } from 'clsx'

import { useBackgroundContext } from '../BackgroundContext/index.js'
import { Icon } from '../Icon/index.js'
import { SvgIcon } from '../Icon/SvgIcon.js'
import { HTMLTribeProps } from '../types/index.js'
import {
  AccordionContext,
  AccordionIconAlign,
  AccordionInset,
  AccordionVariant,
  useAccordionContext,
} from './AccordionContext.js'

export type AccordionProps = {
  defaultOpen?: boolean
  variant?: AccordionVariant
  iconAlign?: AccordionIconAlign
  inset?: AccordionInset
  size?: 'md' | 'sm'
} & HTMLTribeProps<'div'>

/**
 * Accordions display a list of high-level options that can expand/collapse to reveal more information.
 */
export const Accordion = ({
  as,
  children,
  className,
  variant = 'chevron',
  iconAlign = 'leading',
  inset = 'inset',
  size = 'md',
  ...rest
}: AccordionProps) => {
  const Component = as || 'div'

  const context = useMemo(
    () => ({ variant, iconAlign, inset, size }),
    [variant, iconAlign, inset, size],
  )

  return (
    <Disclosure
      as={Component}
      className={clsx(
        'space-y-1',
        [inset === 'flush' && '-ms-2 -me-2'],
        className,
      )}
      {...rest}
    >
      <AccordionContext.Provider value={context}>
        {children}
      </AccordionContext.Provider>
    </Disclosure>
  )
}

export type AccordionButtonProps = HTMLTribeProps<'button'> & {
  noHover?: boolean
}

/**
 *  The button that toggles the expand/collapse state of the accordion item.
 */
const AccordionButton = ({
  children,
  className,
  noHover = false,
  ...rest
}: AccordionButtonProps) => {
  const { variant, iconAlign, size } = useAccordionContext()
  const { backgroundType } = useBackgroundContext()

  const hoverClsx = [
    backgroundType === 'main' &&
      'text-content-on-background-subdued bg-background',
    backgroundType === 'main' &&
      !noHover &&
      'hover:bg-background-hovered hover:text-content-on-background-hovered',
    backgroundType === 'surface' && 'text-content bg-surface',
    backgroundType === 'surface' &&
      !noHover &&
      'hover:bg-surface-hovered hover:text-content-hovered',
    backgroundType === 'secondary' && 'text-content-on-topbar bg-topbar ',
    backgroundType === 'secondary' &&
      !noHover &&
      'hover:bg-topbar hover:text-content-on-topbar',
  ]

  const iconHoverClsx = [
    backgroundType === 'main' &&
      !noHover &&
      'group-hover:text-content-on-background-subdued',
    backgroundType === 'surface' &&
      !noHover &&
      'group-hover:text-content-hovered',
    backgroundType === 'secondary' &&
      !noHover &&
      'group-hover:text-content-on-topbar',
  ]

  const iconSizeClsx = [
    size === 'md' && 'h-5 w-5',
    size === 'sm' && 'h-4 w-4',
    'transform transition-all ease-in-out duration-150',
  ]

  const icons = ({ open }) => {
    let icon

    switch (variant) {
      case 'chevron':
        icon = (
          <SvgIcon
            className={clsx(
              iconHoverClsx,
              open ? '-rotate-180 rtl:rotate-180' : '',
            )}
            name="chevron-down"
          />
        )
        break
      case 'chevron-down':
        icon = (
          <SvgIcon
            className={clsx(
              iconHoverClsx,
              !open ? '-rotate-90 rtl:rotate-90' : '',
            )}
            name="chevron-down"
          />
        )
        break
      case 'plus':
        icon = !open ? (
          <SvgIcon className={clsx(iconHoverClsx)} name="plus" />
        ) : (
          <SvgIcon className={clsx(iconHoverClsx)} name="minus" />
        )
        break
      default:
        break
    }

    if (iconAlign === 'leading') {
      return { leadingIcon: icon, trailingIcon: undefined }
    }
    return { leadingIcon: undefined, trailingIcon: icon }
  }

  return (
    <Disclosure.Button
      className={clsx(
        hoverClsx,
        'group w-full flex items-center text-start rounded-base',
        'focus:outline-none focus-visible:ring ring-inset ring-offset-0 font-medium',
        'py-2 px-2',
        size === 'sm' ? 'text-sm' : 'text-md',
        className,
      )}
      {...rest}
    >
      {({ open }) => {
        const { leadingIcon, trailingIcon } = icons({ open })

        return (
          <>
            {leadingIcon && (
              <Icon className={clsx(iconSizeClsx, 'flex-shrink-0 me-2')}>
                {leadingIcon}
              </Icon>
            )}
            <span className="flex-grow truncate">{children}</span>
            {trailingIcon && (
              <Icon className={clsx(iconSizeClsx, 'flex-shrink-0 ms-2')}>
                {trailingIcon}
              </Icon>
            )}
          </>
        )
      }}
    </Disclosure.Button>
  )
}

export type AccordionPanelProps = HTMLTribeProps<'div'> & {
  noPadding?: boolean
}

/**
 *  The container for the details to be revealed.
 */
const AccordionPanel: FC<AccordionPanelProps> = props => {
  const { children, noPadding, className, ...rest } = props
  const { text400Clsx } = useBackgroundContext()

  return (
    <Transition
      enter="transition duration-100 ease-out"
      leave="transition duration-75 ease-out"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Disclosure.Panel
        className={clsx(
          'w-full flex items-center text-start rounded-base',
          'focus:outline-none focus-visible:ring',
          !noPadding && 'py-2 px-2',
        )}
        {...rest}
      >
        <div className={clsx('flex-1 min-w-0', text400Clsx, className)}>
          {children}
        </div>
      </Disclosure.Panel>
    </Transition>
  )
}

Accordion.Button = AccordionButton
Accordion.Panel = AccordionPanel
