import { useRouter } from '@tribeplatform/react-sdk'
import { Icon } from '@tribeplatform/react-ui-kit/Icon'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

import { CustomFieldViewProps, getFieldIcon } from '../utils.js'

export const SocialMediaView = ({ field, value }: CustomFieldViewProps) => {
  const { Link } = useRouter()
  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <Link
          href={value}
          target="_blank"
          className="shrink-0 hover:opacity-80"
          onClick={e => e.stopPropagation()}
        >
          <Icon size="xl">{getFieldIcon(field)}</Icon>
        </Link>
      </Tooltip.Trigger>
      <Tooltip.Panel>{field?.name}</Tooltip.Panel>
    </Tooltip>
  )
}

SocialMediaView.skipLabel = true
