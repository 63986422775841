import { useSpacesByIds } from '@tribeplatform/react-sdk/hooks'

import { SpreadFilterItems } from '../../components/SpreadFilterItems.js'

interface Props {
  value: string[]
  options: string[]
  onChange: (spaceIds: string[]) => void
}
export const SpreadSpacePicker = ({ value, options, onChange }: Props) => {
  const { data: spaceOptions } = useSpacesByIds({
    variables: { ids: options },
    fields: {
      image: 'basic',
    },
    useQueryOptions: {
      enabled: options.length > 0,
    },
  })

  return (
    <SpreadFilterItems
      options={spaceOptions?.map(({ id }) => id) ?? []}
      value={value}
      onChange={onChange}
      isLoading={!spaceOptions}
    >
      {selectedOption => (
        <span
          title={
            spaceOptions.find(({ id }) => id === selectedOption)?.name ?? ''
          }
        >
          {spaceOptions.find(({ id }) => id === selectedOption)?.name ?? ''}
        </span>
      )}
    </SpreadFilterItems>
  )
}
