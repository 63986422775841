import { T } from '@tribeplatform/react-components/i18n'
import { useRouter } from '@tribeplatform/react-sdk'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { EmptyState } from '@tribeplatform/react-ui-kit/EmptyState'

export const PostNotFound = () => {
  const { Link } = useRouter()
  return (
    <Card>
      <Card.Content>
        <div className="py-12">
          <EmptyState
            title={<T defaultMessage="Post not found" id="Post.NotFound" />}
            description={
              <T
                defaultMessage="This post does not exist, is removed, or you don’t have permission to access it."
                id="Post.NotFound.Description"
              />
            }
          >
            <Button variant="secondaryNeutral" as={Link} href="/">
              <T defaultMessage="Home" id="Generics.Home" />
            </Button>
          </EmptyState>
        </div>
      </Card.Content>
    </Card>
  )
}
