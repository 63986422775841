import { nanoid } from 'nanoid'

import {
  COLUMN_BLOCK_NAME,
  SECTION_BLOCK_NAME,
} from '@tribeplatform/react-slate-kit/constants'
import {
  SlateKitInterface,
  UnknownCompiledBlock,
} from '@tribeplatform/react-slate-kit/types'
import { RawBlockDto } from '@tribeplatform/slate-kit/dtos'

import { NEW_BLOCK_THRESHOLD_MONTHS } from './constants.js'

interface BlockToCopyPaste {
  rootId: string
  blocks: RawBlockDto[]
}

const checkIfNameDuplicate = (
  name: string,
  suffix: string,
  blocks: Record<string, UnknownCompiledBlock>,
) => {
  return Object.values(blocks ?? {}).some(item => {
    const blockNameWithSuffix = item?.config?.displayName ?? ''
    const [blockSuffix] = blockNameWithSuffix.match(/\d+$/) ?? []
    const blockName = blockNameWithSuffix.replace(/\d+$/, '').trim()

    if (suffix) {
      return name === blockName && blockSuffix === suffix
    }

    return name === blockName
  })
}

export const getUniqueNameInSlate = ({
  blockName,
  blocks,
}: {
  blockName: string
  blocks: Record<string, UnknownCompiledBlock>
}) => {
  const [suffix] = blockName.match(/\d+$/) ?? []
  const name = blockName.replace(/\d+$/, '').trim()
  const newBlockName = `${name} ${suffix ?? ''}`.trim()
  const isNameDuplicate = checkIfNameDuplicate(name, suffix, blocks)

  if (!isNameDuplicate) {
    return newBlockName
  }

  const newSuffix = +(suffix ?? 1) + 1
  return getUniqueNameInSlate({
    blockName: `${name} ${newSuffix}`.trim(),
    blocks,
  })
}

const normalizeBlock = (
  id: string,
  blocks: Record<string, UnknownCompiledBlock>,
): RawBlockDto[] => {
  const block = blocks?.[id]
  if (!block) {
    return null
  }

  const rawBlock: RawBlockDto = {
    id: block.id,
    name: block.name,
    children: block.children,
    props: block.props,
    extraProps: { ...block.extraProps, displayName: block.config.displayName },
    output: block.output,
  }

  if (rawBlock.children) {
    const childrenIds = rawBlock.children
    return [
      rawBlock,
      ...childrenIds.flatMap(child => normalizeBlock(child, blocks)),
    ]
  }
  return [rawBlock]
}

const parseBlock = (
  blocks: Record<string, UnknownCompiledBlock>,
  {
    extraProps = {},
    children = [],
    name = '',
    props = {},
    output = {},
  }: RawBlockDto,
): RawBlockDto => {
  const id = nanoid()
  const displayName = getUniqueNameInSlate({
    blockName: (extraProps?.displayName as string) || 'Block',
    blocks,
  })

  const parsedBlock: RawBlockDto = {
    id,
    name,
    children,
    props,
    extraProps: { ...extraProps, displayName },
    output,
  }

  return parsedBlock
}

export const getClipboardText = async () => {
  try {
    const clipboardText = await navigator.clipboard.readText()
    return clipboardText
  } catch (err) {
    return null
  }
}

export const getBlockToPaste = (
  clipboardText: string,
  blocks: Record<string, UnknownCompiledBlock>,
) => {
  const blockToPaste = clipboardTextToBlock(clipboardText)
  if (!blockToPaste) {
    return null
  }

  return cloneBlock(blockToPaste, blocks)
}

const clipboardTextToBlock = (clipboardText: string): BlockToCopyPaste => {
  try {
    const blockToPaste = JSON.parse(clipboardText) as BlockToCopyPaste
    if (!blockToPaste.rootId && !blockToPaste.blocks) {
      return null
    }

    return blockToPaste
  } catch (error) {
    return null
  }
}

export const getBlockToCopy = (
  id: string,
  blocks: Record<string, UnknownCompiledBlock>,
): BlockToCopyPaste => {
  const normalizedBlock = normalizeBlock(id, blocks)
  if (!normalizedBlock) {
    return null
  }

  const copyData: BlockToCopyPaste = { rootId: id, blocks: normalizedBlock }
  return copyData
}

const cloneBlock = (
  { rootId, blocks }: BlockToCopyPaste,
  slateBlocks: Record<string, UnknownCompiledBlock>,
): BlockToCopyPaste => {
  const block = blocks.find(block => block.id === rootId) ?? ({} as RawBlockDto)
  const parsedBlock = parseBlock(slateBlocks, block)
  const { parsedChildren } = parsedBlock.children.reduce(
    (result, child) => {
      const parsedChild = cloneBlock(
        { rootId: child, blocks },
        result.slateBlocks,
      )
      const childId = parsedChild.rootId
      const childBlock = parsedChild.blocks.find(block => block.id === childId)
      return {
        parsedChildren: [...result.parsedChildren, parsedChild],
        slateBlocks: {
          ...result.slateBlocks,
          [childId]: {
            config: {
              displayName: childBlock.extraProps.displayName,
            },
          },
        },
      }
    },
    {
      parsedChildren: [] as BlockToCopyPaste[],
      slateBlocks: {
        ...slateBlocks,
        [rootId]: {
          config: { displayName: parsedBlock.extraProps.displayName },
        },
      } as Record<string, UnknownCompiledBlock>,
    },
  )

  const newBlock: RawBlockDto = {
    ...parsedBlock,
    children: parsedChildren.map(({ rootId }) => rootId),
  }

  return {
    blocks: [newBlock, ...parsedChildren.flatMap(({ blocks }) => blocks)],
    rootId: newBlock.id,
  }
}

const createSectionBlockInitialChildren = (slateKit: SlateKitInterface) => {
  const colBlock = slateKit.getRegisteredBlock(COLUMN_BLOCK_NAME)

  const columns: RawBlockDto[] = Array.from({ length: 2 }).map((_, index) => ({
    id: nanoid(),
    name: colBlock.name,
    props: colBlock.config.initialProps,
    extraProps: {
      displayName: `${COLUMN_BLOCK_NAME} ${index + 1}`,
    },
  }))

  return columns
}

export const createNewBlockInitialChildren = (
  blockName: string,
  slateKit: SlateKitInterface,
) => {
  if (blockName === SECTION_BLOCK_NAME) {
    return createSectionBlockInitialChildren(slateKit)
  }

  return []
}

export const isNewBlock = (releaseDate?: string) => {
  if (!releaseDate) {
    return false
  }

  const date = new Date(releaseDate)
  const comparisonDate = new Date()
  comparisonDate.setMonth(
    comparisonDate.getMonth() - NEW_BLOCK_THRESHOLD_MONTHS,
  )

  return date > comparisonDate
}
