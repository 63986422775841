import { WebLogger, LogLevel, LogLevels } from '../@types/index.js'

const noop = () => {}

export const createClientLogger = (options: { level?: LogLevel }): WebLogger =>
  new Proxy(console, {
    get(target, prop, receiver) {
      if (!options.level || typeof prop !== 'string' || !isLogLevel(prop)) {
        return noop
      }

      const priority = LogLevels[options.level].valueOf()
      const requestedPriority = LogLevels[prop].valueOf()

      if (priority < requestedPriority) {
        return noop
      }

      return Reflect.get(target, prop, receiver)
    },
  })

function isLogLevel(prop: string): prop is LogLevel {
  return !!LogLevels[prop]
}
