import { FC } from 'react'

import { Menu } from '@headlessui/react'
import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import { useBackgroundContext } from '../BackgroundContext/index.js'
import {
  baseButtonStyles,
  buttonIconStyles,
  buttonSizeStyles,
} from '../Button/Button.styles.js'
import { Icon } from '../Icon/index.js'
import type { IconComponentType } from '../Icon/index.js'
import { SvgIcon } from '../Icon/SvgIcon.js'
import { HTMLTribeProps } from '../types/index.js'
import { useFloatingContext } from '../utils/floating/index.js'
import { useDropdown } from './DropdownContext.js'

export type ButtonProps = HTMLTribeProps<'button'> & {
  icon?: IconComponentType
  leadingIcon?: IconComponentType
}
export const DropdownButton = ({
  children,
  icon,
  leadingIcon,
  className,
  disabled: disabledProp,
  ...rest
}: ButtonProps) => {
  const { reference } = useFloatingContext()
  const { disabled: disabledContextProp } = useDropdown()

  const disabled = disabledProp || disabledContextProp

  const size = 'lg'

  return (
    <Menu.Button
      ref={reference}
      className={clsx(
        'inline-flex gap-1 items-center',
        buttonSizeStyles({
          size,
          fullWidth: true,
          truncate: true,
        }),
        baseButtonStyles({
          variant: 'secondaryNeutral',
        }),
        'sm:max-w-xs',
        'transition duration-200',
        className,
      )}
      disabled={disabled}
      {...rest}
    >
      <span className="sr-only">Open options</span>
      {leadingIcon && (
        <Icon size="lg" className="-ms-1 me-2">
          {leadingIcon}
        </Icon>
      )}
      <span className="min-w-0 truncate">{children}</span>
      {!leadingIcon && (
        <Icon
          className={buttonIconStyles({
            size,
          })}
        >
          {icon || <SvgIcon name="chevron-down" />}
        </Icon>
      )}
    </Menu.Button>
  )
}

export const ButtonMinimal: FC<
  ButtonProps & { as?: 'button' | 'div' }
> = props => {
  const { className, children, disabled, ...rest } = props
  const { reference } = useFloatingContext()

  const { backgroundType } = useBackgroundContext()
  const buttonClsx = [
    backgroundType === 'main' &&
      'text-content-on-background-subdued bg-transparent hover:text-content-on-background-hovered',
    backgroundType === 'surface' &&
      'text-content-subdued bg-transparent hover:text-content-hovered',
    backgroundType === 'secondary' &&
      'text-content-on-topbar bg-topbar hover:text-content-on-topbar',
  ]

  return (
    <Menu.Button
      ref={reference}
      className={twMerge(
        clsx(
          'rounded-button flex items-center',
          buttonClsx,
          disabled && 'opacity-60 cursor-default pointer-events-none',
          'focus:outline-none focus-visible:ring',
          'transition duration-200',
          className,
        ),
      )}
      disabled={disabled}
      {...rest}
    >
      {children || (
        <>
          <span className="sr-only">Open options</span>
          <SvgIcon size="lg" name="dots-horizontal" />
        </>
      )}
    </Menu.Button>
  )
}
