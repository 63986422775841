import { useState, useEffect } from 'react'

import type { ReportRecordFields } from '@tribeplatform/gql-client/graphql'
import { hasActionPermission } from '@tribeplatform/gql-client/lib'
import type {
  AnalyticsResponse,
  QueryAnalyticsArgs,
} from '@tribeplatform/gql-client/types'
import { useAnalytics, useNetwork } from '@tribeplatform/react-sdk/hooks'

interface RenderProps {
  data: AnalyticsResponse[]
  isLoading: boolean
}
interface Props {
  children: (props: RenderProps) => JSX.Element
  queries: QueryAnalyticsArgs['queries']
  fields?: ReportRecordFields
}

export const AnalyticsFeeder = ({ children, queries, fields }: Props) => {
  const [data, setData] = useState<AnalyticsResponse[]>([])
  const { data: network } = useNetwork()
  const { authorized: isSupported } = hasActionPermission(
    network?.authMemberProps?.permissions ?? [],
    'analytics',
  )
  const { data: analyticsData, isInitialLoading } = useAnalytics({
    variables: {
      queries,
    },
    ...(fields
      ? {
          fields,
        }
      : {}),
    useQueryOptions: {
      refetchOnMount: 'always',
      enabled: isSupported,
      cacheTime: 0,
    },
  })

  useEffect(() => {
    if (analyticsData) {
      setData(analyticsData)
    }
  }, [analyticsData])

  if (!isSupported) {
    return null
  }

  return children({
    data,
    isLoading: isInitialLoading,
  })
}
