import { useSpacesByIds } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { InlineMultiselect } from '@tribeplatform/react-ui-kit/Multiselect'

import { T } from '../../../i18n/index.js'

interface Props {
  value: string[]
  options: string[]
  className?: string
  onChange: (spaceIds: string[]) => void
}
export const InlineSpacePickerFromIds = ({
  value,
  options,
  className,
  onChange,
}: Props) => {
  const { data: spaceOptions, isInitialLoading } = useSpacesByIds({
    variables: { ids: options },
    useQueryOptions: {
      enabled: options.length > 0,
    },
  })

  const handleClick = (spaceId: string) => {
    if (value.includes(spaceId)) {
      const newValue = value.filter(id => id !== spaceId)
      onChange(newValue)
      return
    }

    const newValue = [...value, spaceId]
    onChange(newValue)
  }

  return (
    <InlineMultiselect className={className}>
      <InlineMultiselect.Items isLoading={isInitialLoading}>
        {!spaceOptions && isInitialLoading && (
          <div className="text-sm text-content-subdued flex justify-center">
            <SvgIcon className="animate-spin" size="lg" name="spinner" />
          </div>
        )}
        {!isInitialLoading && spaceOptions && spaceOptions.length === 0 && (
          <div className="text-sm text-content-subdued">
            <T id="Generics.NoResults" defaultMessage="No results" />
          </div>
        )}
        {spaceOptions &&
          spaceOptions.map(space => (
            <InlineMultiselect.Item
              key={space.id}
              selected={value.includes(space.id)}
              onSelect={() => handleClick(space.id)}
            >
              <div className="truncate text-base">{space.name}</div>
            </InlineMultiselect.Item>
          ))}
      </InlineMultiselect.Items>
    </InlineMultiselect>
  )
}
