import { clsx } from 'clsx'

import { AppsEnum } from '../../../common/constants/app.js'
import { ImageAlign, ImageSize } from './types.js'

const imageClearClassNames = 'next-[:not(figure)]:!mt-2 clear-both'

export const convertFigureDataAttributesToClassname = ({
  align,
  size,
  app,
  hasCaption,
}: {
  align: ImageAlign
  size: ImageSize
  hasCaption: boolean
  app?: string
}) =>
  clsx([
    'ignore-typography',
    'overflow-hidden',
    'box-border',
    '!m-0',
    '!mb-1',
    'relative',
    'max-w-full',
    'rounded-base screen-rounded-none',
    '[&_img]:!rounded-base [&_img]:!screen-rounded-none',
    hasCaption && '',
    app === AppsEnum.Giphy && 'max-h-48',
    size && convertFigureSizeToClassname(size),
    align && convertFigureAlignToClassName(size, align),
  ])

const convertFigureAlignToClassName = (size: ImageSize, align: ImageAlign) => {
  if (size === ImageSize.Full) {
    return `fig-center !mx-auto ${imageClearClassNames}`
  }

  switch (align) {
    case ImageAlign.Left:
      return `fig-left !mr-auto ${imageClearClassNames}`
    case ImageAlign.Right:
      return `fig-right !ml-auto ${imageClearClassNames}`
    case ImageAlign.Center:
      return `fig-center !mx-auto ${imageClearClassNames}`
    case ImageAlign.FloatLeft:
      return [
        'fig-floated fig-left sm:!float-left',
        'sm:!mr-2 next-[.fig-floated.fig-right.fig-half]:sm:!-ml-1',
      ]
    case ImageAlign.FloatRight:
      return [
        'fig-floated fig-right sm:!float-right',
        'sm:!ml-2 next-[.fig-floated.fig-left.fig-half]:sm:!-mr-1',
      ]
    default: {
      const _: never = align
      return ''
    }
  }
}

const convertFigureSizeToClassname = (size: ImageSize) => {
  switch (size) {
    case ImageSize.Half:
      return 'fig-half !w-full [&_img]:!w-full sm:!w-1/2 [&.fig-floated]:sm:!w-[calc(50%_-_theme(spacing[0.5]))]'
    case ImageSize.Full:
      return 'fig-full !w-full [&_img]:!w-full'
    case ImageSize.Original:
      /**
       * By using table and table caption on original of size image,
       * we ensure a best fit case for image captions. It can look weird on very long words,
       * but that will be a rare case and a content issue. We can revisit this if it becomes a problem.
       */
      return 'table table-fixed text-center [&_img]:!inline-block'
    default: {
      const _: never = size
      return ''
    }
  }
}

export const staticMarkupToDOM = (htmlString: string) => {
  const dummyContainer = document.createElement('div')
  dummyContainer.innerHTML = htmlString
  return dummyContainer.children
}
