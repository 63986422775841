import { gql } from 'graphql-tag'

import {
  MemberInvitationFields,
  memberInvitationGQLFields,
} from './memberInvitation.fields.js'

export const inviteMembersGQLQuery = (fields: MemberInvitationFields) => {
  return gql`
    mutation InviteMembers($input: InviteMembersInput!) {
      inviteMembers(input: $input) {
        ${memberInvitationGQLFields(fields)}
      }
    }
`
}

export const memberInvitationValidityGQLQuery = (
  fields: MemberInvitationFields,
) => {
  return gql`
    query memberInvitationValidity($token: String!) {
      memberInvitationValidity(token: $token) {
        ${memberInvitationGQLFields(fields)}
      }
    }
  `
}

export const invitationLinkValidityGQLQuery = () => {
  return gql`
    query invitationLinkValidity($id: String!) {
      invitationLinkValidity(id: $id) {
        id
        link
      }
    }
  `
}

export const getMemberInvitationsGQLQuery = (
  fields: MemberInvitationFields,
) => {
  return gql`
    query GetMemberInvitations(
      $limit: Int!
      $query: String
      $after: String
      $status: MemberInvitationStatus
      $reverse: Boolean
      $roleIds: [String!]
      $filterBy: [MemberInvitationFilterByInput!]
    ) {
      memberInvitations(
        limit: $limit
        query: $query
        after: $after
        status: $status
        reverse: $reverse
        roleIds: $roleIds
        filterBy: $filterBy
      ) {
        totalCount
        edges {
          node {
            ${memberInvitationGQLFields(fields)}
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `
}

export const getInvitationLinkGQLQuery = () => {
  return gql`
    query getInvitationLink {
      memberInvitationLink {
        link
      }
    }
  `
}

export const updateMemberInvitationGQLMutation = (
  fields: MemberInvitationFields,
) => gql`
  mutation UpdateMemberInvitation(
    $id: ID!
    $input: UpdateMemberInvitationInput!
  ) {
    updateMemberInvitation(id: $id, input: $input) {
      ${memberInvitationGQLFields(fields)}
    }
  }
`
