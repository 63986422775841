import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

import { T } from '../i18n/components/T.js'

export const PostLockedIndicator = () => {
  return (
    <Tooltip>
      <Tooltip.Trigger className="inline-flex self-center">
        <SvgIcon
          className="relative"
          style={{ width: '1em', height: '1em', top: '.125em' }}
          name="lock"
        />
        &nbsp;
      </Tooltip.Trigger>
      <Tooltip.Panel>
        <T
          id="Post.PostLockedIndicator.PostLockedText"
          defaultMessage="This post is locked and {MEMBERS} cannot reply or react to it."
        />
      </Tooltip.Panel>
    </Tooltip>
  )
}
