import { clsx } from 'clsx'
import { Element } from 'html-react-parser'
import { Helmet } from 'react-helmet-async'

import { Embed } from '@tribeplatform/gql-client/types'
import { useEmbed } from '@tribeplatform/react-sdk/hooks'
import { useColorSchemeContext } from '@tribeplatform/react-ui-kit/ThemeProvider'

import { HtmlContentContext } from './@types.js'

export const EmbedRenderer = ({
  domNode,
  embeds,
  className,
  context,
  hideUrl = false,
}: {
  domNode: Element
  embeds?: Array<Embed>
  className?: string
  context?: HtmlContentContext
  hideUrl?: boolean
}) => {
  const { colorSchemeMode } = useColorSchemeContext()

  // Fetch the corresponding embed id
  const embedId = domNode.attribs['data-id']
  const url = domNode.attribs['data-embed-url']
  const initialData = embeds?.find(
    embed => embed.id === embedId && !!embed?.html && !!embed?.type,
  )
  const { data: embed } = useEmbed({
    variables: {
      url,
      options: JSON.stringify({
        iframe: '1',
        omit_script: '1',
        theme: colorSchemeMode ?? 'auto',
      }),
    },
    useQueryOptions: {
      initialData,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!initialData?.html,
    },
  })

  if (!url) {
    return null
  }

  const embedRendered = (
    <>
      <div className="preview relative w-full">
        {embed?.html ? (
          <figure
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: embed?.html }}
            className="rounded-base screen-rounded-none overflow-hidden"
          />
        ) : (
          <a
            href={url}
            target="_blank"
            className={clsx(
              'rounded-base screen-rounded-none overflow-hidden',
              hideUrl && 'hidden',
            )}
          >
            {url}
          </a>
        )}
      </div>
      <Helmet>
        <script src="//cdn.iframe.ly/embed.js?theme=dark" async></script>
      </Helmet>
    </>
  )

  if (embed?.type === 'video') {
    return (
      <div
        className={clsx(
          context === 'compact' && 'aspect-video max-h-64',
          className,
        )}
      >
        {embedRendered}
      </div>
    )
  }

  return embedRendered
}
