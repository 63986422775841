import { useMemo } from 'react'

import { type Space, SpaceType } from '@tribeplatform/gql-client/types'
import { useStaticIntl } from '@tribeplatform/react-components/i18n'
import { convertToStatic } from '@tribeplatform/react-components/Views'
import { useSpaces, useSpacesBySlugs } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'

import { staticFieldsFactory } from '../config.js'
import { useShowMoreButton } from '../hooks/useShowMoreButton.js'
import type { SpacesListBlockView } from '../types.js'
import { generateGqlFields } from '../utils/generateGqlFields.js'

export const useSpacesList = (view: SpacesListBlockView) => {
  const {
    fields,
    style,
    spaceSlugs,
    filterBy,
    gallerySize,
    showMore,
    collectionId,
    sort,
    reverseSort,
    limit,
  } = view
  const staticFields = useStaticIntl(staticFieldsFactory)
  const mergedStaticFields = convertToStatic(fields, staticFields, [])
  const isStateInvalid = filterBy === 'spaceSlugs' && !spaceSlugs?.length

  const {
    data: spacesBySlugs,
    isInitialLoading: isSpacesBySlugsInitialLoading,
  } = useSpacesBySlugs({
    variables: { slugs: spaceSlugs },
    fields: generateGqlFields({ staticFields: mergedStaticFields, style }),
    useQueryOptions: {
      enabled: filterBy === 'spaceSlugs' && spaceSlugs?.length > 0,
    },
  })

  const {
    data: spacesData,
    isInitialLoading: isSpacesInitialLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useSpaces({
    variables: {
      ...(collectionId ? { collectionId } : {}),
      limit,
      reverse: reverseSort ?? true,
      orderBy: sort,
      type: [SpaceType.Group],
    },
    fields: generateGqlFields({ staticFields: mergedStaticFields, style }),
    useInfiniteQueryOptions: {
      enabled: filterBy === 'collectionId',
    },
  })

  const spaces = useMemo(() => {
    if (filterBy === 'collectionId') {
      const { nodes } = simplifyPaginatedResult<Space>(spacesData)

      return nodes
    }

    if (filterBy === 'spaceSlugs') {
      return spacesBySlugs ?? []
    }

    return []
  }, [filterBy, spacesBySlugs, spacesData])

  const showMoreButton = useShowMoreButton({
    style,
    gallerySize,
    showMore,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  })
  const isLoading =
    spaces.length === 0 &&
    (isSpacesBySlugsInitialLoading || isSpacesInitialLoading)

  return {
    spaces,
    showMoreButton,
    isLoading,
    mergedStaticFields,
    view,
    isInvalid: isStateInvalid,
  }
}
