import { clsx } from 'clsx'

import type { IconSize } from '@tribeplatform/react-ui-kit/Icon'
import { iconStyles, iconSizeStyles } from '@tribeplatform/react-ui-kit/Icon'
import { EMOJI_FONT_NAME } from '@tribeplatform/react-ui-kit/ThemeProvider'

import { nativeEmojiFromCodepoint } from '../../utils/glyph.utils.js'

interface IconEmojiProps {
  codepoint: string
  size?: IconSize
}
export const MediaGlyphEmoji = ({ codepoint, size }: IconEmojiProps) => {
  const native = nativeEmojiFromCodepoint(codepoint)

  return (
    <div
      className={clsx(iconStyles({ size }), 'flex items-center justify-center')}
    >
      <span
        style={{
          fontSize: iconSizeStyles({ size }),
          fontFamily: EMOJI_FONT_NAME,
        }}
      >
        {native}
      </span>
    </div>
  )
}
