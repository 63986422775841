import { CustomFieldType } from '@tribeplatform/gql-client/types'

import { logger } from '../../common/lib/logger.js'
import { CustomFieldSubtype } from '../../CustomField/utils.js'
import type { Filter } from '../types.js'
import { ArrayRenderer } from '../valueRenderers/ArrayRenderer.js'
import { BooleanRenderer } from '../valueRenderers/BooleanRenderer.js'
import { DateRenderer } from '../valueRenderers/DateRenderer.js'
import { DateTimeRenderer } from '../valueRenderers/DateTimeRenderer.js'
import { NumberRenderer } from '../valueRenderers/NumberRenderer.js'
import { RatingRenderer } from '../valueRenderers/RatingRenderer.js'
import { RelationRenderer } from '../valueRenderers/RelationRenderer.js'
import { TextRenderer } from '../valueRenderers/TextRenderer.js'

interface Props {
  filter: Filter
}
export const FilterValue = ({ filter }: Props) => {
  const { value, type, subType } = filter
  if (value === undefined) {
    return null
  }

  const ValueRendererComponent = filter?.customValueRendererComponent
  if (ValueRendererComponent) {
    return <ValueRendererComponent filter={filter} />
  }

  switch (type) {
    case CustomFieldType.text: {
      if (subType === CustomFieldSubtype.SELECT) {
        return <ArrayRenderer filter={filter} />
      }
      return <TextRenderer filter={filter} />
    }
    case CustomFieldType.boolean:
      return <BooleanRenderer filter={filter} />
    case CustomFieldType.date:
      if (subType === CustomFieldSubtype.DATETIME) {
        return <DateTimeRenderer filter={filter} />
      }
      return <DateRenderer filter={filter} />
    case CustomFieldType.array:
      return <ArrayRenderer filter={filter} />
    case CustomFieldType.number: {
      if (subType === CustomFieldSubtype.RATING) {
        return <RatingRenderer filter={filter} />
      }
      return <NumberRenderer filter={filter} />
    }
    case CustomFieldType.relation:
      return <RelationRenderer filter={filter} />
    case CustomFieldType.object:
    case CustomFieldType.richText:
      return null
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = type
      logger.warn('Not supported field type: ', exhaustiveCheck)
      return null
    }
  }
}
