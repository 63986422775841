import {
  useCallback,
  useEffect,
  useState,
  ReactElement,
  useMemo,
  lazy,
  Suspense,
} from 'react'

import { nanoid } from 'nanoid'

import {
  hasActionPermission,
  ClientError,
  hasScopesPermission,
} from '@tribeplatform/gql-client/lib'
import {
  Post,
  ModerationEntityType,
  PermissionContext,
  Image,
  Emoji,
  Shortcut,
} from '@tribeplatform/gql-client/types'
import { useRouter, useTribeClient } from '@tribeplatform/react-sdk'
import {
  useAuthMember,
  useDeletePost,
  useHidePost,
  useNetwork,
  usePost,
  useUnhidePost,
} from '@tribeplatform/react-sdk/hooks'
import { Avatar } from '@tribeplatform/react-ui-kit/Avatar'
import { confirm } from '@tribeplatform/react-ui-kit/Dialog'
import { type SvgIconName, SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { IconEmoji } from '../../common/components/IconEmoji.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import type { PostContext } from '../types.js'

const PostAnalyticsModal = lazy(() =>
  import('../../Analytics/PostAnalytics/PostAnalyticsModal.js').then(m => ({
    default: m.PostAnalyticsModal,
  })),
)
const AuditLogsModal = lazy(() =>
  import('../../AuditLog/AuditLogsModal.js').then(m => ({
    default: m.AuditLogsModal,
  })),
)
const ReportModal = lazy(() =>
  import('../../ReportModal/index.js').then(m => ({
    default: m.ReportModal,
  })),
)
const MovePostModal = lazy(() =>
  import('../MovePostModal.js').then(m => ({
    default: m.MovePostModal,
  })),
)

export type PostActionItem = {
  name: string
  url?: string
  icon?: SvgIconName | ReactElement
  action?: (props?: { onSettle: () => void }) => void
  category?: string
  hotkey?: string
  disabled?: boolean
}
type CallLoadAppInteractionsProps = {
  onSettle: () => void
  shortcutKey: string
  appId: string
}
export const usePostActionItems = ({
  post,
  context,
  fetchShortcuts,
}: {
  post: Post
  context: PostContext
  fetchShortcuts?: boolean
}): {
  actions: PostActionItem[]
  shortcuts: PostActionItem[]
  children: JSX.Element
} => {
  const { $t } = useI18n()
  const actionItems: PostActionItem[] = []

  const router = useRouter()
  const { config } = useTribeClient()
  const [shortcutActions, setShortcutActions] =
    useState<Array<PostActionItem>>()
  const [loadingShortcuts, setLoadingShortcuts] = useState<
    Record<string, boolean>
  >({})

  const {
    interactionsContext: { useInteractions },
  } = config ?? {}
  const { loadInteractions } = useInteractions({})

  const [auditLogsModal, setAuditLogsModal] = useState<boolean>(false)
  const [postAnalyticsModal, setPostAnalyticsModal] = useState(false)
  const [movePostModal, setMovePostModal] = useState<boolean>(false)
  const [reportModal, setReportModal] = useState<boolean>(false)

  useEffect(() => {
    setAuditLogsModal(false)
    setPostAnalyticsModal(false)
    setMovePostModal(false)
    setReportModal(false)
  }, [router.params])

  const { mutate: deletePost } = useDeletePost()
  const { mutate: hidePost } = useHidePost()
  const { mutate: unhidePost } = useUnhidePost()
  const { data: network } = useNetwork()
  const { isAdmin } = useAuthMember()

  const categoryText = $t({
    id: 'Generics.Post.Noun',
    defaultMessage: 'Post',
  })

  const { data, isStale } = usePost({
    variables: { id: post?.id },
    fields: { shortcuts: { favicon: 'all' } },
    useQueryOptions: {
      enabled: !!post && fetchShortcuts,
    },
  })
  const shortcuts = useMemo(() => data?.shortcuts ?? [], [data?.shortcuts])
  const callLoadAppInteractions = useCallback(
    async ({ onSettle, shortcutKey, appId }: CallLoadAppInteractionsProps) => {
      setLoadingShortcuts(loadingShortcuts => ({
        ...loadingShortcuts,
        [shortcutKey]: true,
      }))

      try {
        await loadInteractions({
          shortcutKey,
          interactionId: nanoid(),
          appId,
          permissionContext: PermissionContext.POST,
          entityId: post ? post.id : '',
        })
      } catch (e) {
        toast({
          title: $t({
            defaultMessage: 'Something went wrong',
            id: 'Generics.SomethingWentWrong',
          }),
          description: (e as ClientError)?.response?.errors[0]?.message,
          status: 'error',
        })
      }

      setLoadingShortcuts(loadingShortcuts => ({
        ...loadingShortcuts,
        [shortcutKey]: false,
      }))
      if (onSettle) {
        onSettle()
      }
    },
    [$t, loadInteractions, post],
  )

  const getIcon = useCallback(
    (shortcut: Shortcut) => {
      const iconUrl = (shortcut.favicon as Image)?.url
      const iconText = (shortcut.favicon as Emoji)?.text

      if (loadingShortcuts[shortcut.key]) {
        return <SvgIcon className="animate-spin" size="lg" name="spinner" />
      }

      if (iconUrl) {
        return (
          <Avatar
            size="lg"
            rounded="sm"
            name={shortcut.name}
            src={iconUrl}
            className="flex"
          />
        )
      }

      if (iconText) {
        return <IconEmoji text={iconText} />
      }

      return <SvgIcon name="terminal" />
    },
    [loadingShortcuts],
  )
  useEffect(() => {
    if (shortcuts && !isStale) {
      const actions_ = shortcuts.map(shortcut => {
        const shortcutAction: PostActionItem = {
          action: options =>
            callLoadAppInteractions({
              onSettle: options?.onSettle,
              shortcutKey: shortcut.key,
              appId: shortcut.appId,
            }),
          icon: getIcon(shortcut),
          name: shortcut.name,
          category: categoryText,
          disabled: loadingShortcuts[shortcut.key],
        }
        return shortcutAction
      })
      setShortcutActions([...actions_])
    }
  }, [
    shortcuts,
    isStale,
    loadingShortcuts,
    callLoadAppInteractions,
    getIcon,
    categoryText,
  ])

  if (!post?.id) {
    return { actions: [], shortcuts: [], children: null }
  }

  const [canUpdatePost, canRemovePost, canHidePost] = hasScopesPermission(
    post,
    ['updatePost', 'removePost', 'hidePost'],
  )

  const canViewAnalytics =
    hasActionPermission(
      network?.authMemberProps?.permissions ?? [],
      'analytics',
    )?.authorized && isAdmin

  const [canUpdateNetwork, canReportPost] = hasScopesPermission(network, [
    'updateNetwork',
    'createModerationReport',
  ])

  const [canMovePost] = hasScopesPermission(network, ['movePost'])

  const postPath = post?.relativeUrl
  const isPostPage = router.location.pathname === postPath

  const canViewPost = context === 'admin'

  const removePost = async () => {
    if (!post?.id) {
      return
    }

    const confirmed = await confirm({
      title: $t({
        id: 'Post.RemovePost.Confirmation',
        defaultMessage: 'Are you sure you want to remove this post?',
      }),
      proceedLabel: $t({
        id: 'Generics.Remove',
        defaultMessage: 'Remove',
      }),
      cancelLabel: $t({
        id: 'Generics.Cancel',
        defaultMessage: 'Cancel',
      }),
      danger: true,
    })
    if (!confirmed) {
      return
    }

    try {
      deletePost({ id: post?.id })
      toast({
        title: $t({
          id: 'Post.Removed',
          defaultMessage: 'Post removed',
        }),
        description: $t({
          id: 'Post.Removed.Description',
          defaultMessage: 'Post is successfully removed.',
        }),
        status: 'success',
      })
      if (isPostPage) {
        const space = post?.space
        router.replace(space?.relativeUrl ?? '/')
      }
    } catch (err) {
      toast({
        title: $t({
          id: 'Generics.SomethingWentWrong',
          defaultMessage: 'Something went wrong',
        }),
        description: $t({
          id: 'Post.UnableToRemove',
          defaultMessage: 'Cannot remove this post.',
        }),
        status: 'error',
      })
    }
  }

  const toggleHidePost = () => {
    if (post.isHidden) {
      unhidePost({
        id: post.id,
      })
    } else {
      hidePost({
        id: post.id,
      })
    }
  }

  if (canViewPost) {
    actionItems.push({
      url: postPath,
      icon: 'post',
      name: $t({
        id: 'Post.View',
        defaultMessage: 'View post',
      }),
      category: categoryText,
    })
  }

  if (canUpdatePost) {
    actionItems.push({
      url: `${post?.relativeUrl}/edit`,
      icon: 'edit',
      name: $t({
        id: 'Post.Edit',
        defaultMessage: 'Edit post',
      }),
      category: categoryText,
      hotkey: 'e',
    })
  }

  if (canMovePost) {
    actionItems.push({
      action: () => setMovePostModal(true),
      icon: 'post-move',
      name: $t({
        id: 'Post.Move',
        defaultMessage: 'Move post',
      }),
      category: categoryText,
      hotkey: 'm',
    })
  }

  if (canViewAnalytics) {
    actionItems.push({
      action: () => setPostAnalyticsModal(true),
      icon: 'analytics',
      name: $t({
        id: 'Post.Analytics',
        defaultMessage: 'Post analytics',
      }),
      hotkey: 'option+a,alt+a',
      category: categoryText,
    })
  }

  if (canUpdateNetwork) {
    actionItems.push({
      action: () => setAuditLogsModal(true),
      icon: 'audit-log',
      name: $t({
        id: 'Generics.AuditLogs',
        defaultMessage: 'Audit logs',
      }),
      hotkey: 'option+l,alt+l',
      category: categoryText,
    })
  }

  if (canHidePost) {
    actionItems.push({
      action: () => toggleHidePost(),
      icon: 'eye-off',
      name: post.isHidden
        ? $t({
            id: 'Post.Unhide',
            defaultMessage: 'Unhide post',
          })
        : $t({
            id: 'Post.Hide',
            defaultMessage: 'Hide post',
          }),
      category: categoryText,
      hotkey: 'h',
    })
  }

  if (canRemovePost) {
    actionItems.push({
      action: () => removePost(),
      icon: 'trash',
      name: $t({
        id: 'Post.Delete',
        defaultMessage: 'Delete post',
      }),
      category: categoryText,
      hotkey: 'cmd+backspace,ctrl+backspace',
    })
  }

  if (canReportPost) {
    actionItems.push({
      action: () => setReportModal(true),
      icon: 'status-warning',
      name: $t({
        id: 'Post.Report',
        defaultMessage: 'Report post',
      }),
      category: categoryText,
    })
  }

  if (canUpdateNetwork) {
    actionItems.push({
      url: `/manage/customizer/cms/${post?.postTypeId}/blocks`,
      icon: 'customizer',
      name: $t({
        id: 'Generics.Customize',
        defaultMessage: 'Customize',
      }),
      category: categoryText,
      hotkey: 'c',
    })
  }

  const children = (
    <>
      {auditLogsModal && (
        <Suspense fallback={<></>}>
          <AuditLogsModal
            postId={post?.id}
            onClose={() => setAuditLogsModal(false)}
            open
          />
        </Suspense>
      )}
      {movePostModal && (
        <Suspense fallback={<></>}>
          <MovePostModal
            post={post}
            onClose={() => setMovePostModal(false)}
            open
          />
        </Suspense>
      )}
      {postAnalyticsModal && (
        <Suspense fallback={<></>}>
          <PostAnalyticsModal
            post={post}
            onClose={() => setPostAnalyticsModal(false)}
            linkToContent={false}
            open
          />
        </Suspense>
      )}
      {reportModal && (
        <Suspense fallback={<></>}>
          <ReportModal
            entity={post}
            entityType={ModerationEntityType.POST}
            onClose={() => setReportModal(false)}
            open
          />
        </Suspense>
      )}
    </>
  )

  return {
    actions: actionItems,
    shortcuts: shortcutActions,
    children,
  }
}
