import {
  ActionFields,
  changeHomepageGQLMutation,
  closeSpaceGQLMutation,
  createSpaceFromTemplateGQLMutation,
  createSpaceGQLQuery,
  deleteSpaceGQLMutation,
  exploreSpacesGQLQuery,
  getHomepageQuery,
  getPageGqlQuery,
  getSpaceGQLQuery,
  getSpacePinnedPostGQLQuery,
  getSpacesByIdsGQLQuery,
  getSpacesBySlugsGQLQuery,
  getSpacesGQLQuery,
  joinSpaceGQLMutation,
  leaveSpaceGQLMutation,
  organizeSpacesInCollectionGQLMutation,
  PaginatedSpaceFields,
  PostFields,
  readSpaceGQLMutation,
  SpaceFields,
  TemplateRequestFields,
  unsetHomepageGQLMutation,
  updateSpaceGQLMutation,
  updateSpaceHighlightedTagsMutation,
} from '../../graphql/index.js'
import { PageFields } from '../../graphql/space/page.fields.js'
import {
  Action,
  MutationChangeHomepageArgs,
  MutationCloseSpaceArgs,
  MutationCreateSpaceArgs,
  MutationCreateSpaceFromTemplateArgs,
  MutationDeleteSpaceArgs,
  MutationJoinSpaceArgs,
  MutationLeaveSpaceArgs,
  MutationOrganizeSpacesInCollectionArgs,
  MutationReadSpaceArgs,
  MutationUnsetHomepageArgs,
  MutationUpdateSpaceArgs,
  MutationUpdateSpaceHighlightedTagsArgs,
  Page,
  PaginatedSpace,
  Post,
  QueryExploreSpacesArgs,
  QueryHomepageArgs,
  QueryPageArgs,
  QuerySpaceArgs,
  QuerySpacePinnedPostsArgs,
  QuerySpacesArgs,
  QuerySpacesByIdsArgs,
  QuerySpacesBySlugsArgs,
  Space,
  TemplateRequest,
} from '../../types/index.js'
import { GraphqlClient } from '../graphql.client.js'

export class SpacesClient {
  private client: GraphqlClient

  constructor(client: GraphqlClient) {
    this.client = client
  }

  async create(
    variables: MutationCreateSpaceArgs,
    fields: SpaceFields = 'basic',
    accessToken?: string,
  ): Promise<Space> {
    type QueryResult = { createSpace: Space }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: createSpaceGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.createSpace
  }

  async list(
    variables: QuerySpacesArgs,
    fields: SpaceFields,
    accessToken?: string,
  ): Promise<PaginatedSpace> {
    type QueryResult = { spaces: PaginatedSpace }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getSpacesGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.spaces
  }

  async listByIds(
    variables: QuerySpacesByIdsArgs,
    fields: SpaceFields = 'basic',
    accessToken?: string,
  ): Promise<Space[]> {
    type QueryResult = { spacesByIds: Space[] }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getSpacesByIdsGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.spacesByIds
  }

  async listBySlugs(
    variables: QuerySpacesBySlugsArgs,
    fields: SpaceFields = 'basic',
    accessToken?: string,
  ): Promise<Space[]> {
    type QueryResult = { spacesBySlugs: Space[] }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getSpacesBySlugsGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.spacesBySlugs
  }

  async organize(
    variables: MutationOrganizeSpacesInCollectionArgs,
    fields: ActionFields = 'basic',
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { organizeSpacesInCollection: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: organizeSpacesInCollectionGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.organizeSpacesInCollection
  }

  async get(
    variables: QuerySpaceArgs,
    fields: SpaceFields = 'default',
    accessToken?: string,
  ): Promise<Space> {
    type QueryResult = { space: Space }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getSpaceGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.space
  }

  async getByPath(
    variables: QueryPageArgs,
    fields: PageFields,
    customToken?: string,
  ): Promise<Page> {
    const result = await this.client.authorizedRequest<{ page: Page }>({
      query: getPageGqlQuery(fields),
      variables,
      customToken,
    })

    return result.page
  }

  async getHomepage(
    variables: QueryHomepageArgs,
    fields: PageFields,
    customToken?: string,
  ): Promise<Page | null> {
    const result = await this.client.authorizedRequest<{ homepage: Page }>({
      variables,
      query: getHomepageQuery(fields),
      customToken,
    })

    return result.homepage
  }

  async changeHomepage(
    variables: MutationChangeHomepageArgs,
    fields: ActionFields,
    customToken?: string,
  ): Promise<Action> {
    const result = await this.client.authorizedRequest<{
      changeHomepage: Action
    }>({
      query: changeHomepageGQLMutation(fields),
      variables,
      customToken,
    })

    return result.changeHomepage
  }

  async unsetHomepage(
    variables: MutationUnsetHomepageArgs,
    fields: ActionFields,
    customToken?: string,
  ): Promise<Action> {
    const result = await this.client.authorizedRequest<{
      unsetHomepage: Action
    }>({
      query: unsetHomepageGQLMutation(fields),
      variables,
      customToken,
    })

    return result.unsetHomepage
  }

  async update(
    variables: MutationUpdateSpaceArgs,
    fields: SpaceFields = 'basic',
  ): Promise<Space> {
    const result = await this.client.authorizedRequest<{ updateSpace: Space }>({
      query: updateSpaceGQLMutation(fields),
      variables,
    })

    return result.updateSpace
  }

  async join(variables: MutationJoinSpaceArgs): Promise<Action> {
    const result = await this.client.authorizedRequest<{
      joinSpace: Action
    }>({
      query: joinSpaceGQLMutation(),
      variables,
    })

    return result.joinSpace
  }

  async leave(variables: MutationLeaveSpaceArgs): Promise<Action> {
    const result = await this.client.authorizedRequest<{
      leaveSpace: Action
    }>({
      query: leaveSpaceGQLMutation(),
      variables,
    })

    return result.leaveSpace
  }

  async pinnedPosts(
    variables: QuerySpacePinnedPostsArgs,
    fields: PostFields = 'basic',
    accessToken?: string,
  ): Promise<Array<Post>> {
    type QueryResult = { spacePinnedPosts: Array<Post> }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getSpacePinnedPostGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.spacePinnedPosts
  }

  async explore(
    variables: QueryExploreSpacesArgs,
    fields: PaginatedSpaceFields = 'basic',
    accessToken?: string,
  ): Promise<PaginatedSpace> {
    type QueryResult = { exploreSpaces: PaginatedSpace }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: exploreSpacesGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.exploreSpaces
  }

  async updateHighlightedTags(
    variables: MutationUpdateSpaceHighlightedTagsArgs,
    accessToken?: string,
  ): Promise<Action> {
    const result = await this.client.authorizedRequest<{
      updateSpaceHighlightedTags: Action
    }>({
      query: updateSpaceHighlightedTagsMutation(),
      variables,
      customToken: accessToken,
    })
    return result.updateSpaceHighlightedTags
  }

  async delete(
    variables: MutationDeleteSpaceArgs,
    fields: ActionFields = 'basic',
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { deleteSpace: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: deleteSpaceGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.deleteSpace
  }

  async createSpaceFromTemplate(
    variables: MutationCreateSpaceFromTemplateArgs,
    fields: TemplateRequestFields = 'basic',
    accessToken?: string,
  ): Promise<TemplateRequest> {
    type QueryResult = { createSpaceFromTemplate: TemplateRequest }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: createSpaceFromTemplateGQLMutation(fields),
      variables,
      customToken: accessToken,
    })
    return result.createSpaceFromTemplate
  }

  async read(
    variables: MutationReadSpaceArgs,
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { readSpace: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: readSpaceGQLMutation(),
      variables,
      customToken: accessToken,
    })
    return result.readSpace
  }

  async close(
    variables: MutationCloseSpaceArgs,
    accessToken?: string,
  ): Promise<Action> {
    type QueryResult = { closeSpace: Action }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: closeSpaceGQLMutation(),
      variables,
      customToken: accessToken,
    })
    return result.closeSpace
  }
}
