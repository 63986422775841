import { useState } from 'react'

import { Popover } from '@tribeplatform/react-ui-kit/Popover'

import { isFilterSpreadable } from '../index.js'
import type { Filter } from '../types.js'
import { FilterItemActivator } from './FilterItemActivator.js'
import { FilterItemPopOver } from './FilterItemPopOver.js'
import { ValuePicker } from './ValuePicker.js'

interface Props {
  filter: Filter
  inlineActivator?: boolean
  spread?: boolean
  allowOperatorSelection: boolean
  setFilter: (newFilter: Filter) => void
  removeFilter: () => void
}
export const InlineFilterItem = ({
  filter,
  inlineActivator = false,
  spread,
  allowOperatorSelection,
  setFilter,
  removeFilter,
}: Props) => {
  const [open, setOpen] = useState(false)
  const canSpread = isFilterSpreadable(filter)

  if (spread && canSpread) {
    return (
      <ValuePicker
        filter={filter}
        onChange={newValue => {
          if (newValue?.[0] === filter?.value?.[0]) {
            removeFilter()
            return
          }

          setFilter({ ...filter, value: newValue })
        }}
        className="w-64"
        inline
        spread
      />
    )
  }

  return (
    <Popover
      open={open}
      onClose={() => setOpen(false)}
      padding="sm"
      placement="bottom-start"
      className="max-h-none"
      allowPanelOverflow
      autoPosition={false}
      activator={
        <FilterItemActivator
          filter={filter}
          open={open}
          setOpen={setOpen}
          removeFilter={removeFilter}
          inline={inlineActivator}
        />
      }
    >
      <Popover.Content className="w-full">
        <FilterItemPopOver
          filter={filter}
          removeFilter={() => {
            removeFilter()
            setOpen(false)
          }}
          onChange={newFilter => {
            setFilter(newFilter)
            setOpen(false)
          }}
          allowOperatorSelection={allowOperatorSelection}
          inline
          needsApply
        />
      </Popover.Content>
    </Popover>
  )
}
