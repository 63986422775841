import { noop } from '@tribeplatform/react-ui-kit/hooks'

import { useBlock } from '../../../hooks/block.hook.js'
import { useSlate } from '../../../hooks/slate.hook.js'
import { ActionsSection } from '../Sections/ActionsSection.js'
import { LogoSection } from '../Sections/LogoSection.js'
import { MenuSection } from '../Sections/MenuSection.js'
import { SearchSection } from '../Sections/SearchSection.js'
import {
  NavbarSectionItem,
  type NavbarSection,
  type NavbarProps,
} from '../types.js'

interface Props {
  section: NavbarSection
  logoTrueCenter?: boolean
}
export const SectionRenderer = ({ section, logoTrueCenter }: Props) => {
  const { id } = section
  const {
    extraContext: { activeSectionId },
  } = useBlock<NavbarProps, { activeSectionId?: string }>()
  const { mode } = useSlate()
  const isPreviewMode = mode !== 'live'
  const active = isPreviewMode && id === activeSectionId

  switch (id) {
    case NavbarSectionItem.logo:
      return <LogoSection {...section} active={active} />
    case NavbarSectionItem.search:
      return (
        <SearchSection
          {...section}
          active={active}
          fixedWidth={logoTrueCenter}
        />
      )
    case NavbarSectionItem.menu:
      return (
        <MenuSection {...section} active={active} fixedWidth={logoTrueCenter} />
      )
    case NavbarSectionItem.actions:
      return (
        <ActionsSection
          {...section}
          active={active}
          fixedWidth={logoTrueCenter}
        />
      )
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = id
      noop(exhaustiveCheck)
      return null
    }
  }
}
