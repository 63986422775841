import { flatpickr } from './flatpickr.js'

const localeModules = import.meta.glob(
  '../../../../node_modules/flatpickr/dist/esm/l10n/{default,de,en,es,fr,hi,ja,ko,pt,tr,zh,nl,pl,ro,it}?(-+([a-z]|[A-Z])).js',
)
/**
 * @tag ESM WIP
 * Locale polyfills need to be handled via glob imports
 */

const validateLocale = (localeString: string, path: string): string => {
  const normalizedLocale = localeString.toLowerCase()
  const [mainLocale] = normalizedLocale.split('-')

  if (path.endsWith(`/${normalizedLocale}.js`)) {
    return normalizedLocale
  }
  if (path.endsWith(`/${mainLocale}.js`)) {
    return mainLocale
  }

  return ''
}

export const localizeFlatpickr = async (locale: string): Promise<void> => {
  // eslint-disable-next-line guard-for-in
  for (const path in localeModules) {
    const flatpickrLocale = validateLocale(locale, path)
    if (flatpickrLocale) {
      // It'll match only once and we want to block the loop
      // eslint-disable-next-line no-await-in-loop
      await localeModules[path]()
      flatpickr.localize(flatpickr.l10ns[flatpickrLocale])
      return
    }
  }
}
