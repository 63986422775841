import { cva } from 'class-variance-authority'

export const checkboxStyles = cva(
  [
    'appearance-none',
    'shrink-0',
    'rounded-[6px]',
    'bg-surface text-interactive hover:text-interactive-hovered',
    'focus:outline-none focus-visible:ring',
    'disabled:cursor-default disabled:bg-interactive-disabled disabled:border-line-disabled',
    'border',
    'transition duration-200',
  ],
  {
    variants: {
      invalid: {
        true: [
          'border-line-negative',
          'ring-line-negative ring-1 ring-offset-0',
        ],
        false: [
          'border-line hover:border-line-hovered',
          'checked:border-interactive checked:hover:border-interactive-hovered',
          'ring-interactive',
        ],
      },
      size: {
        md: 'h-5 w-5 mt-[1px]',
        sm: 'h-4 w-4 mt-[3px]',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
)
