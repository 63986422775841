import { noop } from '@tribeplatform/react-ui-kit/hooks'

import { ActionItemId } from '../../types.js'
import { CreatePostAction } from '../actions/CreatePostAction.js'
import { LanguageSwitchAction } from '../actions/LanguageSwitchAction.js'
import { SearchAction } from '../actions/SearchAction.js'
import { ThemeSwitchAction } from '../actions/ThemeSwitchAction.js'

interface Props {
  id: ActionItemId
  close: () => void
}
export const MobileMenuActionItem = ({ id, close }: Props) => {
  switch (id) {
    case ActionItemId.mainMenu:
    case ActionItemId.notifications:
    case ActionItemId.messages: {
      return null
    }
    case ActionItemId.createEntity:
      return <CreatePostAction close={close} />
    case ActionItemId.search: {
      return <SearchAction close={close} />
    }
    case ActionItemId.themeSwitch: {
      return <ThemeSwitchAction close={close} />
    }
    case ActionItemId.languageSwitch: {
      return <LanguageSwitchAction close={close} />
    }
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = id
      noop(exhaustiveCheck)
      return null
    }
  }
}
