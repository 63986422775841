import { CustomFieldType } from '@tribeplatform/gql-client/types'

import { CustomFieldSubtype } from '../../CustomField/utils.js'
import type {
  RawInlineFilter,
  RawFilter,
  InlineFilter,
  Filter,
} from '../types.js'

export const INFINITE_SPREAD_PAGE_SIZE = 25
export const INLINE_MULTI_SELECT_PAGE_SIZE = 10

export const mergeInlineFilters = (
  rawInlineFilters: RawInlineFilter[],
  availableInlineFilters: RawFilter[],
): InlineFilter[] => {
  const currentRawInlineFilters = rawInlineFilters
    .filter(({ key }) =>
      availableInlineFilters.some(inlineFilter => inlineFilter.key === key),
    )
    .map(
      ({
        key,
        enabled,
        customLabel,
        allowOperatorSelection,
        narrowedDownOptions,
      }) => {
        const { id, name, icon } = availableInlineFilters.find(
          inlineFilter => inlineFilter.key === key,
        )
        const inlineFilter: InlineFilter = {
          id,
          key,
          label: name,
          customLabel,
          icon,
          enabled,
          allowOperatorSelection,
          narrowedDownOptions,
        }

        return inlineFilter
      },
    )
  const restOfInlineFilters = availableInlineFilters
    .filter(
      inlineFilter =>
        !currentRawInlineFilters.some(({ key }) => key === inlineFilter.key),
    )
    .map(({ id, key, name, icon }) => {
      const inlineFilter: InlineFilter = {
        id,
        key,
        label: name,
        customLabel: name,
        icon,
        enabled: false,
        allowOperatorSelection: false,
        narrowedDownOptions: [],
      }

      return inlineFilter
    })

  return [...currentRawInlineFilters, ...restOfInlineFilters]
}

const sanitizeNarrowedDownOptions = (
  narrowedDownOptions: string[],
  selectOptions: string[],
): string[] => {
  if (selectOptions.length === 0) {
    return narrowedDownOptions
  }

  const newNarrowedDownOptions = narrowedDownOptions.filter(option =>
    selectOptions.some(selectOption => option === selectOption),
  )

  return newNarrowedDownOptions
}

export const narrowDownInlineFilterValues = (
  processedInlineFilters: Filter[],
  currentFilters: Filter[],
): Filter[] => {
  const narrowedDownInlineFilters: Filter[] = processedInlineFilters.map(
    processedInlineFilter => {
      const foundFilter = currentFilters.find(
        filter => processedInlineFilter.key === filter.key,
      )

      if (!foundFilter) {
        return processedInlineFilter
      }

      const { value, extraProps } = foundFilter
      const { extraProps: inlineExtraProps } = processedInlineFilter
      if (!Array.isArray(value)) {
        return processedInlineFilter
      }

      const narrowedDownOptions = sanitizeNarrowedDownOptions(
        inlineExtraProps.narrowedDownOptions ?? [],
        value,
      )
      const newProcessedInlineFilter: Filter = {
        ...processedInlineFilter,
        extraProps: {
          ...extraProps,
          ...inlineExtraProps,
          narrowedDownOptions,
          inlineSelectOptions: value,
        },
      }
      return newProcessedInlineFilter
    },
  )

  return narrowedDownInlineFilters
}

export const sanitizeInlineFilters = (
  processedInlineFilters: Filter[],
  availableInlineFilters: RawFilter[],
): Filter[] => {
  return processedInlineFilters.filter(inlineFilter =>
    availableInlineFilters.some(({ key }) => key === inlineFilter.key),
  )
}

export const isFilterSpreadable = (filter: Filter) => {
  return (
    filter.type === CustomFieldType.relation ||
    filter.type === CustomFieldType.array ||
    filter.subType === CustomFieldSubtype.SELECT ||
    filter.subType === CustomFieldSubtype.MULTISELECT
  )
}

export const getLabelsFromOptions = (
  selectOptions: Filter['extraProps']['selectOptions'],
): Record<string, string> => {
  return selectOptions.reduce((acc, { id, value }) => {
    return { ...acc, [id]: value }
  }, {} as Record<string, string>)
}
