import type { ValueRendererComponent } from '../types.js'
import { getLabelsFromOptions } from '../utils/inlineFilters.js'

export const MultiSelectRenderer: ValueRendererComponent = ({ filter }) => {
  const { value = [], extraProps } = filter
  const selectOptions = extraProps?.selectOptions ?? []
  const labels = getLabelsFromOptions(selectOptions)

  if (!Array.isArray(value)) {
    return null
  }

  return <>{value.map(value => labels?.[value] ?? value).join(', ')}</>
}
