import type { ActionFields } from '@tribeplatform/gql-client/graphql'
import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Action,
  MutationDeleteMemberArgs,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getMemberKey, getMembersKey } from '../../utils/keys/member.key.js'

export const useDeleteMember = (options?: {
  fields?: ActionFields
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationDeleteMemberArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<Action, ClientError, MutationDeleteMemberArgs>(
    (input: MutationDeleteMemberArgs) => client.members.delete(input),
    {
      onSettled: (data, error, input) => {
        const memberKey = getMemberKey({ variables: { id: input.id } })
        queryClient.removeQueries(memberKey)

        const membersKey = getMembersKey()
        queryClient.invalidateQueries(membersKey)
      },
      ...useMutationOptions,
    },
  )
}
