import { cva } from 'class-variance-authority'

export const helperTextStyles = cva(
  ['first-letter:capitalize', 'rounded-base'],
  {
    variants: {
      size: {
        default: 'text-label-sm',
        compact: 'text-label-xs',
      },
      status: {
        info: 'text-content',
        neutral: 'text-content-subdued',
        warn: 'text-content-on-attention',
        error: 'text-content-on-negative',
        success: 'text-content-on-positive',
      },
      highlighted: {
        true: ['p-3'],
        false: [],
      },
    },
    compoundVariants: [
      {
        status: 'info',
        highlighted: true,
        className: ['bg-surface-highlight'],
      },
      {
        status: 'neutral',
        highlighted: true,
        className: ['bg-surface-subdued'],
      },
      {
        status: 'warn',
        highlighted: true,
        className: ['bg-surface-attention'],
      },
      {
        status: 'error',
        highlighted: true,
        className: ['bg-surface-negative'],
      },
      {
        status: 'success',
        highlighted: true,
        className: ['bg-surface-positive'],
      },
    ],
    defaultVariants: {
      size: 'default',
    },
  },
)
