import type { PostFields } from '@tribeplatform/gql-client/graphql'
import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  MutationUpdatePostArgs,
  Post,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getFeedKey } from '../../utils/keys/feed.keys.js'
import { getPostKey, getPostsKey } from '../../utils/keys/post.key.js'
import { getPostsQueryById } from './filters.js'

export const useUpdatePost = (options?: {
  fields?: PostFields
  useMutationOptions?: UseMutationOptions<
    Post,
    ClientError,
    MutationUpdatePostArgs,
    { snapshot: Post }
  >
}) => {
  const { useMutationOptions, fields = 'default' } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<
    Post,
    ClientError,
    MutationUpdatePostArgs,
    { snapshot: Post }
  >((input: MutationUpdatePostArgs) => client.posts.update(input, fields), {
    onMutate: async newPost => {
      const postKey = getPostKey({ variables: { id: newPost?.id } })
      await queryClient.cancelQueries(postKey)
      const snapshot = queryClient.getQueryData<Post>(postKey)

      queryClient.setQueriesData<Post>(postKey, old => ({
        ...old,
        ...newPost,
      }))

      return { snapshot }
    },
    onSuccess: updatedPost => {
      const postKey = getPostKey({ variables: { id: updatedPost?.id } })
      queryClient.setQueriesData<Post>(postKey, oldPost => ({
        ...oldPost,
        ...updatedPost,
      }))
    },
    onError: (err, newPost, context) => {
      const postKey = getPostKey({ variables: { id: newPost.id } })
      if (context.snapshot) {
        queryClient.setQueriesData<Post>(postKey, context.snapshot)
      }
      queryClient.invalidateQueries(postKey)
    },
    onSettled: data => {
      const parentPostId = data?.repliedToId
      const postKey = getPostKey()
      queryClient.invalidateQueries(postKey)
      const postsKey = getPostsKey()
      queryClient.invalidateQueries(postsKey)
      const feedKey = getFeedKey()
      queryClient.invalidateQueries(feedKey)
      // we want to "refetch" only replies query if any
      // (invalidate does not work for disabled queries)
      // (replies withing a reply is a disabled query)
      if (parentPostId) {
        queryClient.refetchQueries(getPostsQueryById(parentPostId))
      }
    },
    ...useMutationOptions,
  })
}
