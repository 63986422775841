import { createContext, useContext } from 'react'

export type NavVerticalSize = 'md' | 'lg'

export type NavVerticalContext = {
  collapsed: boolean
  size: NavVerticalSize
}

export const NavVerticalContext = createContext<NavVerticalContext>(undefined)

export const useNavVerticalContext = () => {
  const context = useContext(NavVerticalContext)
  if (context === undefined) {
    throw new Error(
      'useNavVerticalContext must be used within a NavVerticalProvider',
    )
  }
  return context
}
