import { useEffect } from 'react'

import { Navigate } from 'react-router'

import {
  PostListFilterByEnum,
  PostListFilterByOperator,
  PostStatus,
  PostTypeContext,
} from '@tribeplatform/gql-client/types'
import { findPostType, useCMS } from '@tribeplatform/react-components/CMS'
import { EntityImage } from '@tribeplatform/react-components/common/components'
import {
  useLogin,
  useQuery,
} from '@tribeplatform/react-components/common/hooks'
import { getPostIdFromPath } from '@tribeplatform/react-components/common/utils'
import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { PostForm } from '@tribeplatform/react-components/PostForm'
import { useRouter } from '@tribeplatform/react-sdk'
import {
  useAuthMember,
  useNetwork,
  usePost,
  usePosts,
  useSpace,
} from '@tribeplatform/react-sdk/hooks'
import { IconButton } from '@tribeplatform/react-ui-kit/Button'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { List } from '@tribeplatform/react-ui-kit/Layout'

import { BC } from '../../types/index.js'
import { Drafts } from './Drafts.js'
import {
  getTranslatedUpdatePostText,
  getTranslatedCreatePostText,
  getTranslatedPostTypeName,
} from './translations.js'
import { ComposerProps } from './types.js'

interface ComposerQueryParams {
  ['post_type']: string
  view: string
  ['space_id']: string
}
export const ComposerBlock: BC<ComposerProps> = ({
  new: isNew,
  spacePath,
  postPath,
  postTypeSlug: _,
  ...rest
}) => {
  const intl = useI18n()
  const { composablePostTypes } = useCMS()
  const { Link, push, replace, location } = useRouter()
  const {
    post_type: postTypeSlug,
    view,
    space_id: spaceId,
  } = useQuery<ComposerQueryParams>()

  const {
    data: { id: networkId, postTypes: networkPostTypes },
  } = useNetwork()

  const postId = postPath ? getPostIdFromPath(postPath) : null
  const { data: post, isFetched } = usePost({
    variables: { id: postId },
    useQueryOptions: { enabled: !!postId },
  })

  const postType = findPostType({
    networkId,
    id: post?.postTypeId,
    slug: postTypeSlug,
    postTypes: networkPostTypes,
  })

  const defaultTargetSpace = postType?.authMemberProps?.availableSpaces?.[0]
  const defaultTargetSpaceExists = !!defaultTargetSpace
  const isSpaceSet = !!spacePath || !!spaceId
  const spaceQueryVariablesIfSet = spaceId
    ? { id: spaceId }
    : { path: spacePath }
  const spaceQueryVariablesIfNotSet = { id: defaultTargetSpace?.id }
  const { data: space } = useSpace({
    fields: {
      authMemberProps: {
        availablePostTypes: 'all',
        spaceMember: {
          role: 'basic',
        },
        permissions: 'all',
      },
      image: 'all',
    },
    variables: isSpaceSet
      ? spaceQueryVariablesIfSet
      : spaceQueryVariablesIfNotSet,
    useQueryOptions: { enabled: isSpaceSet || defaultTargetSpaceExists },
  })

  const { data: member, isGuest } = useAuthMember()
  const { showLogin } = useLogin()

  const { data: draftPosts } = usePosts({
    variables: {
      postTypeIds: postType?.id ? [postType?.id] : undefined,
      limit: 0,
      filterBy: [
        {
          keyString: 'status',
          key: PostListFilterByEnum.publishedAt,
          operator: PostListFilterByOperator.equals,
          value: JSON.stringify('DRAFTED'),
        },
        {
          keyString: 'createdById',
          key: PostListFilterByEnum.publishedAt,
          operator: PostListFilterByOperator.equals,
          value: JSON.stringify(member?.id),
        },
      ],
    },
    fields: 'basic',
    useInfiniteQueryOptions: {
      enabled: isNew,
    },
  })
  const draftsCount = draftPosts?.pages?.[0]?.totalCount

  let availablePostTypes = []

  if (isSpaceSet) {
    availablePostTypes = space?.authMemberProps?.availablePostTypes?.filter(
      postType => postType?.context === PostTypeContext.post,
    )
  } else {
    availablePostTypes = networkPostTypes?.filter(
      postType =>
        postType?.context === PostTypeContext.post &&
        postType?.slug !== 'tribe-message' &&
        postType?.authMemberProps?.availableSpaces?.length > 0 &&
        !postType?.archived,
    )
  }

  const onCancel = () => {
    if (location?.key) {
      window?.history?.back()
    } else {
      push(isNew ? space.relativeUrl || '/' : post?.relativeUrl)
    }
  }

  useEffect(() => {
    if (isGuest) {
      showLogin(true)
    }
  }, [isGuest, showLogin])

  if (isGuest) {
    return null
  }

  if (composablePostTypes.length === 0 && !!isNew) {
    return <Navigate to="/" replace />
  }

  if (view === 'drafts') {
    return <Drafts postTypeId={postType?.id} />
  }

  if (isNew && !postTypeSlug) {
    if (availablePostTypes?.length === 1) {
      replace(
        `${isSpaceSet ? `/${space?.slug}` : ''}/new?post_type=${
          availablePostTypes[0].id
        }`,
      )
      return null
    }
    return (
      <Card {...rest}>
        <Card.Header
          title={
            <div className="flex space-s-3 items-center">
              <IconButton
                variant="tertiaryNeutral"
                rounded
                size="lg"
                icon={
                  <SvgIcon className="rtl:-scale-x-100" name="arrow-left" />
                }
                onClick={onCancel}
              ></IconButton>
              <span className="text-xl flex-1">
                <T
                  id="Blocks.Composer.CreateButtonText"
                  defaultMessage="Create a new"
                />
              </span>
              {draftsCount > 0 && (
                <IconButton
                  variant="tertiaryNeutral"
                  rounded
                  as={Link}
                  href="?view=drafts"
                  icon={<SvgIcon name="post" />}
                  className="px-5"
                  size="lg"
                >
                  <T
                    id="Generics.Drafts.Plural"
                    defaultMessage="{drafts_count, plural, =0 {No drafts} =1 {# draft} other {# drafts}}"
                    values={{ drafts_count: draftsCount }}
                  />
                </IconButton>
              )}
            </div>
          }
        />
        <Card.Content>
          <List divider spacing="none" className="border-t border-line-subdued">
            {availablePostTypes?.map(postType => (
              <List.Item key={postType?.id}>
                <Link
                  variant="inherit"
                  className="hover:bg-surface-hovered px-4 py-5 sm:p-6 flex space-s-3 items-center"
                  href={`${isSpaceSet ? `/${space?.slug}` : ''}/new?post_type=${
                    postType.id
                  }`}
                >
                  <EntityImage
                    size="xl"
                    entity={{ image: postType.icon, name: postType?.name }}
                  />
                  <span className="flex-1">
                    {getTranslatedPostTypeName(intl, postType.name)}
                  </span>
                  <SvgIcon
                    className="w-6 h-6 rtl:-scale-x-100"
                    name="chevron-right"
                  />
                </Link>
              </List.Item>
            ))}
          </List>
        </Card.Content>
      </Card>
    )
  }
  if (!isNew && !post) {
    return null
  }

  const postTypeTitle = post?.id
    ? getTranslatedUpdatePostText(intl, postType?.name)
    : getTranslatedCreatePostText(intl, postType?.name)

  return (
    <Card {...rest}>
      <Card.Header
        title={
          <div className="flex space-s-3 items-center">
            <IconButton
              variant="tertiaryNeutral"
              rounded
              size="lg"
              icon={<SvgIcon className="rtl:-scale-x-100" name="arrow-left" />}
              onClick={onCancel}
            />
            <span className="text-xl font-medium flex-1">{postTypeTitle}</span>
            {draftsCount > 0 && (
              <IconButton
                variant="tertiaryNeutral"
                rounded
                as={Link}
                href={`?view=drafts${
                  postType?.id ? `&post_type=${postType?.id}` : ''
                }`}
                icon={<SvgIcon name="post" />}
                className="px-5"
                size="lg"
              >
                <T
                  id="Generics.Drafts.Plural"
                  defaultMessage="{drafts_count, plural, =0 {No drafts} =1 {# draft} other {# drafts}}"
                  values={{ drafts_count: draftsCount }}
                />
              </IconButton>
            )}
          </div>
        }
      />
      <Card.Content>
        <PostForm
          postType={postType}
          space={space}
          availableSpaces={postType?.authMemberProps?.availableSpaces}
          post={post}
          isPostReady={isFetched}
          onCancel={onCancel}
          onSubmit={post => {
            if (post?.status !== PostStatus.DRAFTED) {
              replace(post?.relativeUrl)
            } else {
              replace(`${post?.relativeUrl}/edit`)
            }
          }}
        />
      </Card.Content>
    </Card>
  )
}
