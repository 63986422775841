import { useState, useEffect, useRef } from 'react'

import { useFormContext } from 'react-hook-form'

import { useI18n } from '@tribeplatform/react-components/i18n'
import { useTribeClient } from '@tribeplatform/react-sdk'
import {
  ErrorInteractionEventPayload,
  ExtraInteractionsEvents,
} from '@tribeplatform/react-sdk/types'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { useSlate } from '../../hooks/index.js'
import { BC, CallbackExtraContext } from '../../types/index.js'
import { FormSearchableSelect } from './components/FormSearchableSelect.js'
import { FormSelect } from './components/FormSelect.js'
import { StandAloneSearchableSelect } from './components/StandAloneSearchableSelect.js'
import { StandAloneSelect } from './components/StandAloneSelect.js'
import { SelectBlockProps, SelectItemsQueryTypes } from './types.js'
import { useSelectItems } from './useSelectItems.js'

export const SelectBlock: BC<SelectBlockProps> = ({
  value: defaultValue,
  items: uncleanItems,
  callbackId,
  disabled,
  dataCallbackId,
  isSearchable = false,
  avatarRounded = 'md',
  avatarSize = 'xs',
  // eslint-disable-next-line unused-imports/no-unused-vars
  children,
  ...rest
}) => {
  const {
    context: { appId, dynamicBlockKey, shortcutKey, permissionContext },
  } = useSlate<CallbackExtraContext>()
  const { $t } = useI18n()
  const formContext = useFormContext()
  const initialValue = useRef(defaultValue)
  const { control: enabled, watch } = formContext || {}
  const [search, setSearch] = useState(undefined)
  const { getValues, reset } = formContext ?? {}

  const { config } = useTribeClient()
  const {
    interactionsContext: { useInteractions, interactionEmitter },
  } = config ?? {}

  const { items, fetchMore, isFetching } = useSelectItems({
    callbackId: dataCallbackId,
    appId,
    items: uncleanItems,
    getValues,
    useInteractions,
    interactionEmitter,
    dynamicBlockKey,
    permissionContext,
    shortcutKey,
  })

  useEffect(() => {
    if (typeof search === 'string') {
      fetchMore({ type: SelectItemsQueryTypes.Search, text: search })
    }
  }, [search, fetchMore])

  const name = rest?.name ?? 'Select'
  useEffect(() => {
    const errorInteractionListenerHandler = (
      args: ErrorInteractionEventPayload,
    ) => {
      const fieldName = args?.context?.name
      if (fieldName === name) {
        toast({
          title: $t({
            defaultMessage: 'Something went wrong',
            id: 'Generics.SomethingWentWrong',
          }),
          status: 'error',
        })

        reset({
          ...getValues(),
          [name]: initialValue.current,
        })
      }
    }

    if (interactionEmitter) {
      interactionEmitter.on(
        ExtraInteractionsEvents.InteractionError,
        errorInteractionListenerHandler,
      )
    }

    return () => {
      if (interactionEmitter) {
        interactionEmitter.removeListener(
          ExtraInteractionsEvents.InteractionError,
          errorInteractionListenerHandler,
        )
      }
    }
  }, [interactionEmitter, reset, rest?.name, getValues, name, $t])

  const value = enabled ? watch(rest.name, defaultValue) : defaultValue

  useEffect(() => {
    if (!initialValue.current) {
      initialValue.current = value
    }
  }, [value])

  const sharedProps = {
    callbackId,
    name,
    avatarRounded,
    avatarSize,
    items,
    disabled,
    ...rest,
  }

  if (isSearchable) {
    if (enabled) {
      return (
        <FormSearchableSelect
          {...sharedProps}
          setSearch={setSearch}
          getValues={getValues}
          isFetching={isFetching}
        />
      )
    }
    return (
      <StandAloneSearchableSelect
        {...sharedProps}
        setSearch={setSearch}
        isFetching={isFetching}
        value={value}
      />
    )
  }

  if (enabled) {
    return <FormSelect {...sharedProps} getValues={getValues} />
  }

  return <StandAloneSelect {...sharedProps} value={value} />
}
