import type { NotificationFields } from '@tribeplatform/gql-client/graphql'
import {
  QueryPublisherSettingsArgs,
  type QueryNotificationsArgs,
} from '@tribeplatform/gql-client/types'
import type {
  PublisherFields,
  SubscriberFields,
} from '@tribeplatform/gql-client/types/generated'

import { KeyConstructor } from './keyConstructor.js'

const PUBLISHER_SETTINGS_KEY = 'publisherSettings'
const SUBSCRIBER_SETTINGS_KEY = 'subscriberSettings'
const NOTIFICATIONS_KEY = 'notifications'
const NOTIFICATIONS_COUNT_KEY = 'notificationsCount'

export const getPublisherSettingsKey = KeyConstructor<
  QueryPublisherSettingsArgs,
  PublisherFields
>(PUBLISHER_SETTINGS_KEY)

export const getSubscriberSettingsKey = KeyConstructor<never, SubscriberFields>(
  SUBSCRIBER_SETTINGS_KEY,
)

export const getNotificationsKey = KeyConstructor<
  QueryNotificationsArgs,
  NotificationFields
>(NOTIFICATIONS_KEY)
export const getNotificationsCountKey = KeyConstructor<never, never>(
  NOTIFICATIONS_COUNT_KEY,
)
