import type { QueryTranslateMarkupArgs } from '@tribeplatform/gql-client/types'

import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getTranslateMarkupKey } from '../../utils/keys/translate.keys.js'

export const useTranslateMarkup = (options: {
  variables: QueryTranslateMarkupArgs
  useQueryOptions?: UseQueryOptions<string[]>
}) => {
  const { variables, useQueryOptions } = options
  const { client } = useTribeClient()
  const exportRequestKey = getTranslateMarkupKey({ variables })

  return useQuery<string[]>(
    exportRequestKey,
    () =>
      client.query({
        name: 'translateMarkup',
        args: {
          variables,
        },
      }),
    useQueryOptions,
  )
}
