import type {
  MemberFields,
  SpaceMemberFields,
} from '@tribeplatform/gql-client/graphql'
import {
  MemberStatusInput,
  PaginatedMember,
  PaginatedSpaceMember,
  QueryMembersArgs,
  QuerySpaceMembersArgs,
} from '@tribeplatform/gql-client/types'

import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '../../lib/react-query/useInfiniteQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import {
  getMembersKey,
  getSpaceMembersKey,
} from '../../utils/keys/member.key.js'

export const DefaultMembersLimit = 10
export const DefaultMembersStatus = [
  MemberStatusInput.VERIFIED,
  MemberStatusInput.UNVERIFIED,
]

export const useMembers = (options?: {
  variables?: QueryMembersArgs
  fields?: MemberFields
  useInfiniteQueryOptions?: UseInfiniteQueryOptions<PaginatedMember>
}) => {
  const {
    variables = {
      limit: DefaultMembersLimit,
      status: DefaultMembersStatus,
    },
    fields = 'basic',
    useInfiniteQueryOptions,
  } = options || {}
  const { client } = useTribeClient()
  const membersKey = getMembersKey({ variables, fields })

  return useInfiniteQuery<PaginatedMember>(
    membersKey,
    ({ pageParam: after }) =>
      client?.members.list({ after, ...variables }, fields),
    useInfiniteQueryOptions,
  )
}

export const useSpaceMembers = (options?: {
  variables?: QuerySpaceMembersArgs
  fields?: SpaceMemberFields
  useInfiniteQueryOptions?: UseInfiniteQueryOptions<PaginatedSpaceMember>
}) => {
  const { variables, fields = 'basic', useInfiniteQueryOptions } = options || {}
  const { client } = useTribeClient()
  const spacesKey = getSpaceMembersKey({ variables, fields })

  return useInfiniteQuery<PaginatedSpaceMember>(
    spacesKey,
    ({ pageParam: after }) =>
      client?.spaceMembers.listMembers({ after, ...variables }, fields),
    useInfiniteQueryOptions,
  )
}
