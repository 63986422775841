import {
  MultiselectButton,
  MultiselectSelectedItem,
} from './MultiselectButton.js'
import { useMultiselect } from './MultiselectContext.js'

export const MultiselectCreatableButton = props => {
  // ignore children
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { children, ...rest } = props

  const { selectedItems } = useMultiselect()

  return (
    <MultiselectButton {...rest}>
      {selectedItems.map((item: string, index) => (
        <MultiselectSelectedItem
          /* eslint-disable-next-line react/no-array-index-key */
          key={index}
          value={item}
          index={index}
        >
          {item}
        </MultiselectSelectedItem>
      ))}
    </MultiselectButton>
  )
}
