import { Controller, useFormContext } from 'react-hook-form'

import {
  PickerFormControl,
  PickerFormControlSpaceProps,
} from '../EntityPicker/PickerFormControl.js'
import { FormFieldProps } from './typings.js'

// TODO: form pickers should not be controlled by 'value' and 'onChange' props
export function FormSpacePicker({
  name,
  validation,
  value: fieldValue,
  onChange: fieldOnChange,
  ...rest
}: FormFieldProps<PickerFormControlSpaceProps>) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <PickerFormControl.SpacePicker
          {...rest}
          name={name}
          invalid={!!error?.message}
          error={error?.message}
          value={fieldValue ?? value}
          onChange={value => {
            onChange(value)
            if (typeof fieldOnChange === 'function') {
              fieldOnChange(value)
            }
          }}
        />
      )}
    />
  )
}
