import { clsx } from 'clsx'

import {
  ClientError,
  isForbidden,
  isNotFound,
} from '@tribeplatform/gql-client/lib'
import { PostStatus } from '@tribeplatform/gql-client/types'
import { PostCardLoading } from '@tribeplatform/react-components/LoadingStates'
import { Post } from '@tribeplatform/react-components/Post'
import { ReplyList } from '@tribeplatform/react-components/Reply'
import {
  useAuthMember,
  useNetwork,
  usePost,
} from '@tribeplatform/react-sdk/hooks'

import { BC } from '../../types/index.js'
import { PostNotFound } from './PostNotFound.js'
import { PostBlockProps } from './types.js'

export const PostBlock: BC<PostBlockProps> = ({ id, className, ...rest }) => {
  const { isModerator, data: authMember } = useAuthMember()
  const { data: network } = useNetwork()
  const {
    data: post,
    error: _error,
    isLoading,
    isFetched,
  } = usePost({
    variables: { id },
    fields: 'default',
    useQueryOptions: {
      refetchOnMount: 'always',
      retry: false,
      enabled: !!id,
    },
  })
  const error = _error as ClientError

  if (!post && !isLoading && error) {
    if (isNotFound(error) || isForbidden(error)) {
      return <PostNotFound />
    }
  }

  if (
    post &&
    !isModerator &&
    post?.status !== PostStatus.PUBLISHED &&
    authMember?.id &&
    post?.owner?.member?.id !== authMember?.id
  ) {
    return <PostNotFound />
  }

  if (!post && !isFetched) {
    return <PostCardLoading />
  }

  const validPostTypes =
    network?.postTypes?.find(postType => postType.id === post?.postTypeId)
      ?.validReplyTypes ||
    post.postType?.validReplyTypes ||
    []

  return (
    <div className={clsx('space-y-5', className)} {...rest}>
      <Post replyBar={false} post={post} context="post" collapsible={false} />
      {(validPostTypes?.length > 0 || post?.replies?.nodes?.length > 0) && (
        <ReplyList post={post} />
      )}
    </div>
  )
}
