import chroma from 'chroma-js'
import { DeepPartial } from 'react-hook-form'

import type {
  NewTheme,
  Theme as NetworkTheme,
  ThemeColor,
  ThemeToken,
} from '@bettermode/common/gql/generated'

import {
  convertLegacyThemeColors,
  createColorGroup,
  extendTheme,
  invertThemeColors,
} from './themes.utils.js'
import { ColorGroup, CustomizerTheme, LegacyThemeColors } from './types.js'

const convertFromTokens = (tokens: (ThemeToken | ThemeColor)[]) => {
  const result = {}
  tokens.forEach(token => {
    result[token.key] =
      (token as ThemeToken)?.value || (token as ThemeColor)?.weights
  })
  return result
}

const convertFromColorTokens = (tokens: ThemeColor[]) => {
  const colors = convertFromTokens(tokens)
  Object.keys(colors).forEach(key => {
    colors[key] = convertFromTokens(colors[key])
  })
  return colors
}

export const convertToTokens = (obj: Record<string, string>): ThemeToken[] => {
  return Object.keys(obj)
    .filter(key => !!obj[key])
    .map(key => ({ key, value: obj[key] }))
}

export const convertToColorTokens = (
  colors: LegacyThemeColors,
): ThemeColor[] => {
  return Object.keys(colors).map(key => ({
    key,
    weights: convertToTokens(colors[key]),
  }))
}

const convertNetworkOldThemeToTheme = (
  oldColors: NetworkTheme['tokens']['colors'],
): DeepPartial<CustomizerTheme> => {
  const colorKeyMapper = {
    'accent.base': ['actionPrimary'],
    'bg.base': ['surface', 'actionSecondary'],
    'bg.secondary': ['main'],
    'label.primary': ['basicMain', 'basicSurface', 'basicSecondary'],
  }
  const textWhite = createColorGroup('#FFFFFF', false)
  const backgroundWhite = createColorGroup('#FFFFFF', false, true)
  const labelPrimary = createColorGroup('#27282B', false)
  const lightColors: Record<string, ColorGroup> = {
    actionPrimary: createColorGroup('#2D9F6F', false),
    surface: backgroundWhite,
    actionSecondary: backgroundWhite,
    main: createColorGroup('#F4F4F6', false, true),
    basicMain: labelPrimary,
    basicSurface: labelPrimary,
    basicSecondary: labelPrimary,
  }
  oldColors.forEach(({ key, value }) => {
    if (colorKeyMapper[key]) {
      const hex = value?.replace(/\s+/g, '')
      if (!chroma.valid(hex)) {
        return
      }
      const mappedColor = createColorGroup(
        hex,
        false,
        !['label.primary', 'accent.base'].includes(key),
      )
      colorKeyMapper[key].forEach(mappedKey => {
        lightColors[mappedKey] = mappedColor
      })
    }
  })
  lightColors.actionAccent = createColorGroup(
    lightColors.actionPrimary[600],
    false,
  )
  lightColors.actionAccentHover = createColorGroup(
    lightColors.actionPrimary[400],
    false,
  )
  lightColors.basicPrimary = textWhite

  return {
    colors: { light: lightColors },
  }
}

const convertNetworkThemeToTheme = (
  lightTokens: NewTheme['colors']['light'],
  darkTokens: NewTheme['colors']['dark'],
  typographyTokens: NewTheme['typography'],
): DeepPartial<CustomizerTheme> => {
  const legacyLight = convertFromColorTokens(lightTokens) as LegacyThemeColors
  const legacyDark = convertFromColorTokens(darkTokens) as LegacyThemeColors

  return {
    colors: {
      light: legacyLight,
      dark: legacyDark,
    },
    newColors: {
      light1: convertLegacyThemeColors(legacyLight),
      dark1: convertLegacyThemeColors(invertThemeColors(legacyDark)),
    },
    typography: convertFromTokens(
      typographyTokens.map(token => ({
        ...token,
        value: JSON.parse(token.value),
      })),
    ),
  }
}

export const toTheme = ({
  base,
  oldColors,
  networkThemeLightColors,
  networkThemeDarkColors,
  networkThemeTypography,
  theme,
}: {
  base: CustomizerTheme
  oldColors?: NetworkTheme['tokens']['colors']
  networkThemeLightColors?: NewTheme['colors']['light']
  networkThemeDarkColors?: NewTheme['colors']['dark']
  networkThemeTypography?: NewTheme['typography']
  theme?: DeepPartial<CustomizerTheme>
}): CustomizerTheme => {
  let result: CustomizerTheme = { ...base }

  if (oldColors) {
    result = extendTheme(result, convertNetworkOldThemeToTheme(oldColors))
  }
  if (
    networkThemeLightColors &&
    networkThemeDarkColors &&
    networkThemeTypography
  ) {
    result = extendTheme(
      result,
      convertNetworkThemeToTheme(
        networkThemeLightColors,
        networkThemeDarkColors,
        networkThemeTypography,
      ),
    )
  }
  if (theme) {
    result = extendTheme(result, theme)
  }

  const light = convertLegacyThemeColors(result.colors.light)
  const dark = convertLegacyThemeColors(invertThemeColors(result.colors.dark))
  result.newColors = {
    id: 'legacy',
    name: 'Legacy',
    light1: light,
    light2: light,
    light3: light,
    dark1: dark,
    dark2: dark,
    palette: {
      primary: light['action-primary'],
      accent: light.link,
      neutral: light.surface,
    },
  }
  return result
}

export const toNetworkTheme = ({
  base,
  oldColors,
  networkThemeLightColors,
  networkThemeDarkColors,
  networkThemeTypography,
  theme,
}: {
  base: CustomizerTheme
  oldColors: NetworkTheme['tokens']['colors']
  networkThemeLightColors: NewTheme['colors']['light']
  networkThemeDarkColors: NewTheme['colors']['dark']
  networkThemeTypography: NewTheme['typography']
  theme?: DeepPartial<CustomizerTheme>
}): NewTheme => {
  // ignore newColors
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { newColors, ...rest } = toTheme({
    base,
    oldColors,
    networkThemeLightColors,
    networkThemeDarkColors,
    networkThemeTypography,
    theme,
  })
  return {
    ...rest,
    colors: {
      light: convertToColorTokens(rest.colors.light),
      dark: convertToColorTokens(rest.colors.dark),
    },
    typography: convertToTokens(
      Object.keys(rest.typography).reduce(
        (preValue, key) => ({
          ...preValue,
          [key]: JSON.stringify(rest.typography[key]),
        }),
        {},
      ),
    ),
  }
}
