import { useEffect, useRef } from 'react'

import type { Options } from 'flatpickr/dist/types/options.js'

import { useSavedCallback } from '../hooks/useSavedCallback.js'
import { flatpickr } from './flatpickr.js'
import { flatpickrFixEventsPlugin } from './plugins/fixEvents.js'
import { UseDatePickerProps } from './types.js'
import { updateClassNames } from './utils.js'

export const useDatePicker = ({
  value,
  onChange,
  dateFormat = 'Y-m-d',
  inline,
  disable,
  enable,
  maxDate,
  minDate,
  readOnly = false,
  prefix,
  onOpen,
  onClose,
  allowInput,
  closeOnSelect = true,
  noCalendar = false,
  static: maybeStatic = false,
  mode,
  showMonths,
  enableTime = false,
}: UseDatePickerProps) => {
  const startInputField = useRef(null)
  const endInputField = useRef(null)
  const calendarRef = useRef(null)
  const savedOnChange = useSavedCallback(onChange)
  const savedOnClose = useSavedCallback(onClose)
  const savedOnOpen = useSavedCallback(onOpen)

  useEffect(() => {
    if (startInputField.current === null) {
      return
    }

    const onHook = (_selectedDates, _dateStr, instance, prefix) => {
      updateClassNames(instance, prefix)
      if (startInputField?.current) {
        startInputField.current.readOnly = readOnly
      }
      if (endInputField?.current) {
        endInputField.current.readOnly = readOnly
      }
    }

    // Logic to determine if `enable` or `disable` will be passed down. If neither
    // is provided, we return the default empty disabled array, allowing all dates.
    const enableOrDisable = enable ? 'enable' : 'disable'
    let enableOrDisableArr
    if (!enable && !disable) {
      enableOrDisableArr = []
    } else if (enable) {
      enableOrDisableArr = enable
    } else {
      enableOrDisableArr = disable
    }

    const { current: start } = startInputField
    const { current: end } = endInputField

    const calendar = flatpickr(start, {
      inline: inline ?? false,
      disableMobile: true,
      defaultDate: value,
      closeOnSelect,
      mode,
      allowInput: allowInput ?? true,
      dateFormat,
      [enableOrDisable]: enableOrDisableArr,
      minDate,
      maxDate,
      plugins: [
        flatpickrFixEventsPlugin({
          inputFrom: startInputField.current,
          inputTo: endInputField.current,
        }),
      ],
      clickOpens: !readOnly,
      noCalendar,
      static: maybeStatic,
      showMonths,
      enableTime,
      time_24hr: true,
      onChange: (...args) => {
        if (savedOnChange && !readOnly) {
          savedOnChange(...args)
        }
      },
      onClose: savedOnClose,
      onReady: onHook,
      onMonthChange: onHook,
      onYearChange: onHook,
      onOpen: (...args) => {
        onHook(...args)
        savedOnOpen(...args)
      },
      onValueUpdate: onHook,
    } as Options)

    calendarRef.current = calendar

    function handleArrowDown(event) {
      if (event.code === 'Escape') {
        calendar.calendarContainer.classList.remove('open')
      }

      if (event.code === 'ArrowDown') {
        const {
          calendarContainer,
          selectedDateElem: fpSelectedDateElem,
          todayDateElem: fptodayDateElem,
        } = calendar
        const selectedDateElem =
          calendarContainer.querySelector('.selected') && fpSelectedDateElem
        const todayDateElem =
          calendarContainer.querySelector('.today') && fptodayDateElem
        ;(
          selectedDateElem ||
          todayDateElem ||
          calendarContainer.querySelector('.flatpickr-day[tabindex]') ||
          calendarContainer
        ).focus()
      }
    }

    function handleOnChange() {
      if (start.value !== '') {
        return
      }

      if (!calendar.selectedDates) {
        return
      }

      if (calendar.selectedDates.length === 0) {
        return
      }

      calendar.clear()
      calendar.input.focus()
    }

    if (start) {
      start.addEventListener('keydown', handleArrowDown)
      start.addEventListener('change', handleOnChange)

      if (calendar && calendar.calendarContainer) {
        // Flatpickr's calendar dialog is not rendered in a landmark causing an
        // error with IBM Equal Access Accessibility Checker so we add an aria
        // role to the container div.
        calendar.calendarContainer.setAttribute('role', 'application')
        // IBM EAAC requires an aria-label on a role='region'
        calendar.calendarContainer.setAttribute(
          'aria-label',
          'calendar-container',
        )
      }
    }

    if (end) {
      end.addEventListener('keydown', handleArrowDown)
      end.addEventListener('change', handleOnChange)
    }

    // component did unmount equivalent
    return () => {
      // Note: if the `startInputField` ref is undefined then calendar will be
      // of type: Array and `destroy` will not be defined
      if (calendar && calendar.destroy) {
        calendar.destroy()
      }

      if (start) {
        start.removeEventListener('keydown', handleArrowDown)
        start.removeEventListener('change', handleOnChange)
      }

      if (end) {
        end.removeEventListener('keydown', handleArrowDown)
        end.removeEventListener('change', handleOnChange)
      }
    }
  }, [savedOnChange, savedOnClose, savedOnOpen, readOnly])

  useEffect(() => {
    if (calendarRef?.current?.set) {
      calendarRef.current.set({ dateFormat })
    }
  }, [dateFormat])

  useEffect(() => {
    if (calendarRef?.current?.set) {
      calendarRef.current.set('minDate', minDate)
    }
  }, [minDate])

  useEffect(() => {
    if (calendarRef?.current?.set) {
      calendarRef.current.set('maxDate', maxDate)
    }
  }, [maxDate])

  useEffect(() => {
    if (calendarRef?.current?.set && disable) {
      calendarRef.current.set('disable', disable)
    }
  }, [disable])

  useEffect(() => {
    if (calendarRef?.current?.set && enable) {
      calendarRef.current.set('enable', enable)
    }
  }, [enable])

  useEffect(() => {
    if (calendarRef?.current?.set && inline) {
      calendarRef.current.set('inline', inline)
    }
  }, [inline])

  useEffect(() => {
    if (calendarRef?.current?.set) {
      if (value !== undefined) {
        calendarRef.current.setDate(value)
      }
      updateClassNames(calendarRef.current, prefix)
      // for simple date picker w/o calendar; initial mount may not have value
    } else if (!calendarRef.current && value) {
      startInputField.current.value = value
    }
  }, [value, prefix])

  return {
    startInputField,
    endInputField,
    calendarRef,
  }
}
