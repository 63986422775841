import { useMemo } from 'react'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import type { Post } from '@tribeplatform/gql-client/types'
import {
  useAddReaction,
  useRemoveReaction,
} from '@tribeplatform/react-sdk/hooks'
import { noop } from '@tribeplatform/react-ui-kit/hooks'

import { useLogin } from '../../common/hooks/useLogin.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import {
  ReactionActionTypes,
  ReactAction as TReactAction,
} from '../../Views/types.js'
import { confirmActionOnLockedPost } from '../utils.js'
import { AdaptiveReactionAction } from './AdaptiveReactionAction.js'
import { EmojiReactionAction } from './EmojiReactionAction.js'
import { MultipleReactionAction } from './MultipleReactionAction.js'
import { SingleReactionAction } from './SingleReactionAction.js'

interface Props {
  post: Post
  action: TReactAction
}
export const ReactAction = ({ post, action }: Props) => {
  const intl = useI18n()
  const { mutateAsync: addReaction } = useAddReaction()
  const { mutateAsync: removeReaction } = useRemoveReaction()
  const { isLoggedIn, showLogin } = useLogin()
  const reactions = action?.reactions
  const reactionActionType = action?.reactionActionType
  const disabled = useMemo(() => {
    if (post && isLoggedIn) {
      const [canReact] = hasScopesPermission(post, ['addReaction'])
      if (!canReact || !post.authMemberProps.canReact) {
        return true
      }
    }

    return false
  }, [isLoggedIn, post])

  const handleReact = async (
    key: string,
    shouldRemove: boolean,
    shouldReplace: boolean,
  ) => {
    if (!isLoggedIn) {
      showLogin()
      return
    }

    if (post?.locked) {
      const hasConfirmed = await confirmActionOnLockedPost(intl)(post)
      if (!hasConfirmed) {
        return
      }
    }

    if (shouldRemove) {
      await removeReaction({
        postId: post.id,
        reaction: key,
      })

      return
    }

    await addReaction({
      postId: post?.id,
      input: {
        reaction: key,
        overrideSingleChoiceReactions: shouldReplace,
      },
    })
  }

  switch (reactionActionType) {
    case ReactionActionTypes.allEmojis:
      if (reactions === null) {
        return (
          <EmojiReactionAction
            post={post}
            action={action}
            disabled={disabled}
            onReact={handleReact}
          />
        )
      }

      return null
    case ReactionActionTypes.single:
      if (reactions.length === 1) {
        return (
          <SingleReactionAction
            post={post}
            action={action}
            disabled={disabled}
            onReact={handleReact}
          />
        )
      }

      return null
    case ReactionActionTypes.multiple:
      if (reactions.length > 1) {
        return (
          <MultipleReactionAction
            post={post}
            action={action}
            disabled={disabled}
            onReact={handleReact}
          />
        )
      }

      return null
    case ReactionActionTypes.adaptive:
      return <AdaptiveReactionAction post={post} action={action} />
    default: {
      // Code should never reach here
      const exhaustiveCheck: never = reactionActionType
      noop(exhaustiveCheck)
      return null
    }
  }
}
