import { SearchEntity, SearchEntityType } from '@tribeplatform/gql-client/types'

export const getSearchItemLink = ({
  entityType,
  entityId,
  in: space,
  url,
}: SearchEntity): string => {
  switch (entityType) {
    case SearchEntityType.member:
      return `/member/${entityId}`
    case SearchEntityType.space:
      return `/${space?.address?.path || space?.slug}`
    case SearchEntityType.External:
      return url
    // Post.
    default:
      return `/${space?.address?.path || space?.slug}/post/${entityId}`
  }
}
export const getSearchPageLink = ({
  search,
  type,
}: {
  search: string
  type?: string
}): string => {
  return `/search?query=${search}${type ? `&type=${type}` : ``}`
}

// Remove post results with no spaces
export const cleanupSearchResult = data => {
  if (!data) return null
  data.hits = data.hits.map(section => {
    section.hits = section.hits.filter(hit => {
      return !(section?.entityType === SearchEntityType.post && !hit?.in)
    })
    return section
  })

  data.hits = data.hits.filter(section => section.hits.length)

  return data
}
