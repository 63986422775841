import { SpaceAbout } from '@tribeplatform/react-components/Space'

import { useSlate } from '../../hooks/index.js'
import { BC } from '../../types/index.js'
import { SpaceAboutProps } from './types.js'

export const SpaceAboutBlock: BC<SpaceAboutProps> = props => {
  const {
    context: { spaceId },
  } = useSlate()
  return <SpaceAbout {...{ spaceId, ...props }} />
}
