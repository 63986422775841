import { T } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

interface Props {
  direction: 'vertical' | 'horizontal'
}

export const SpacingLabel = ({ direction }: Props) => {
  if (direction === 'vertical') {
    return (
      <div className="flex justify-center items-center gap-1">
        <SvgIcon name="direction-vertical" size="lg" />
        <T id="Generics.Vertical" defaultMessage="Vertical" />
      </div>
    )
  }

  return (
    <div className="flex justify-center items-center gap-1">
      <SvgIcon name="direction-horizontal" size="lg" />
      <T id="Generics.Horizontal" defaultMessage="Horizontal" />
    </div>
  )
}
