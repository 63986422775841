import { useState } from 'react'

import { Checkbox } from '@tribeplatform/react-ui-kit/Checkbox'
import {
  DateTimePicker,
  Calendar,
} from '@tribeplatform/react-ui-kit/DateTimePicker'
import { RadioGroup } from '@tribeplatform/react-ui-kit/RadioGroup'

import {
  convertTzToUtc,
  convertUtcToTz,
  formatDateTime,
} from '../../common/utils/date.js'
import { T, useI18n } from '../../i18n/index.js'
import type { ValuePickerComponent } from '../types.js'
import { relativeDatesFactory } from './DateValuePicker.js'

export const DateTimeValuePicker: ValuePickerComponent = ({
  filter,
  onChange,
  inline,
}) => {
  const intl = useI18n()
  const { value } = filter
  const [isRelative, setIsRelative] = useState(
    typeof value === 'number' || value === undefined,
  )
  const relativeItems = relativeDatesFactory(intl)

  return (
    <div className="space-y-4">
      <Checkbox
        checked={isRelative}
        onChange={() => {
          onChange(undefined)
          setIsRelative(prev => !prev)
        }}
      >
        <p className="text-content">
          <T defaultMessage="Relative date" id="Generics.RelativeDate" />
        </p>
      </Checkbox>
      {isRelative ? (
        <RadioGroup
          value={value === undefined ? '' : value.toString()}
          onChange={newValue => onChange(Number(newValue))}
        >
          <RadioGroup.Items>
            {relativeItems.map(({ text, value }) => (
              <RadioGroup.Item
                key={text}
                value={value.toString()}
                title={text}
              />
            ))}
          </RadioGroup.Items>
        </RadioGroup>
      ) : (
        <>
          {/* For inline filtering of date times, we show date picker only */}
          {inline ? (
            <Calendar
              // Force render on value change
              key={JSON.stringify(filter.value)}
              value={filter.value as string}
              onChange={date => {
                const dateValue = formatDateTime(date as string)
                onChange(dateValue)
              }}
            />
          ) : (
            <div className="origin-top-left scale-90">
              <DateTimePicker
                // Force render on value change
                key={JSON.stringify(filter.value)}
                value={convertUtcToTz(filter.value as string)}
                onChange={date => {
                  const dateValue = convertTzToUtc(date as string)
                  onChange(dateValue)
                }}
                inline
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}
