import { useEffect, useRef } from 'react'

import { customizerPreviewScrollTo } from '@bettermode/common/customizer'
import { T } from '@tribeplatform/react-components/i18n'
import { useDocument } from '@tribeplatform/react-ui-kit/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { getPropertyValue } from '@tribeplatform/react-ui-kit/utils'

export const NewBlockPlaceholder = () => {
  const { document } = useDocument()
  const ref = useRef<HTMLDivElement>()

  useEffect(() => {
    if (ref.current) {
      customizerPreviewScrollTo({
        headerHeightString: getPropertyValue(document, '--c-header-height'),
        element: ref.current,
        document,
      })
    }
  }, [ref, document])

  return (
    <div
      ref={ref}
      className="@container bg-[#0099F940] border border-[#45B7FF] border-dashed h-[100px] rounded-base flex flex-col items-center justify-center cursor-pointer"
    >
      <div className="flex items-center justify-center gap-1">
        <SvgIcon name="plus" size="sm" />
        <span className="text-lg font-light hidden @[7rem]:inline">
          <T id="BlockActions.AddBlock" defaultMessage="Add block" />
        </span>
      </div>
      <div className="text-content-disabled font-light hidden @[15rem]:block">
        <T
          id="Blocks.NewBlockPlaceholder.HelperText"
          defaultMessage="Select a block from the sidebar"
        />
      </div>
    </div>
  )
}
