import { useState, useEffect, useMemo, memo } from 'react'

import isEqual from 'react-fast-compare'

import { Post } from '@tribeplatform/gql-client/types'
import {
  useAuthMember,
  useNetwork,
  useTranslateMarkup,
} from '@tribeplatform/react-sdk/hooks'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Link } from '@tribeplatform/react-ui-kit/Link'

import { T } from '../i18n/components/T.js'
import {
  getTranslatableFields,
  getTranslatedPost,
} from './utils/postTranslate.js'

export const PostTranslate = memo(
  ({
    post,
    onTranslate,
  }: {
    post: Post
    onTranslate: (post: Post) => void
  }) => {
    const { data: network } = useNetwork()
    const [shouldTranslate, setShouldTranslate] = useState(false)
    const translatableFields = useMemo(() => {
      const postType = network?.postTypes?.find(
        ({ id }) => id === post?.postTypeId,
      )
      const postTypeFields = postType?.postFields?.fields

      return getTranslatableFields(post?.fields ?? [], postTypeFields ?? [])
    }, [network?.postTypes, post?.fields, post?.postTypeId])

    const { data: member } = useAuthMember()
    const { data: translatedFieldsData, isInitialLoading } = useTranslateMarkup(
      {
        useQueryOptions: {
          enabled: shouldTranslate,
        },
        variables: {
          input: Object.values(translatableFields),
        },
      },
    )

    const toggleTranslation = () => {
      if (isInitialLoading) {
        return
      }

      if (shouldTranslate) {
        onTranslate(null)
        setShouldTranslate(false)

        return
      }

      setShouldTranslate(true)
    }

    useEffect(() => {
      if (shouldTranslate && translatedFieldsData) {
        const translatedPost = getTranslatedPost(
          post,
          translatedFieldsData,
          translatableFields,
        )

        onTranslate(translatedPost)
      }
    }, [
      onTranslate,
      post,
      shouldTranslate,
      translatableFields,
      translatedFieldsData,
    ])

    if (!network?.settings?.contentTranslation?.enabled) {
      return null
    }

    if (
      !post?.language ||
      post?.language === member?.locale?.split('-')?.[0] ||
      Object.keys(translatableFields).length === 0
    ) {
      return null
    }

    return (
      <div className="pt-2">
        <Link
          as="button"
          variant="inherit"
          className="inline-flex space-s-1 items-center text-sm"
          onClick={toggleTranslation}
        >
          {isInitialLoading ? (
            <SvgIcon className="animate-spin w-4 h-4" name="spinner" />
          ) : (
            <SvgIcon className="w-4 h-4" name="globe" />
          )}
          <span>
            {!isInitialLoading && shouldTranslate ? (
              <T
                id="Post.PostTranslate.SeeOriginalLing"
                defaultMessage="See original"
              />
            ) : (
              <T
                id="Post.PostTranslate.SeeTranslationLink"
                defaultMessage="See translation"
              />
            )}
          </span>
        </Link>
      </div>
    )
  },
  isEqual,
)
