import { useEffect, useState } from 'react'

import { clsx } from 'clsx'

import { Post, PostStatus } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import { IconButton, Button } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { useI18n } from '../i18n/providers/I18nProvider.js'
import { SpaceImage } from '../Space/SpaceImage.js'
import { PostOptions } from './PostOptions.js'
import type { PostContext } from './types.js'

export const PostHeader = ({
  post,
  context,
  className,
  showBackButton = true,
}: {
  post: Post
  context: PostContext
  className?: string
  showBackButton?: boolean
}) => {
  const { $t } = useI18n()
  const { Link } = useRouter()

  const [hasBackLink, setHasBackLink] = useState(false)
  useEffect(() => {
    setHasBackLink(window?.history?.length > 1)
  }, [])

  const onBack = () => {
    if (hasBackLink) {
      window?.history?.back()
    }
  }

  const showMoreOptions = post?.status === PostStatus.PUBLISHED

  return (
    <div className={clsx('flex', className)}>
      {showBackButton && (
        <IconButton
          as={Link}
          variant="tertiaryNeutral"
          rounded
          size="lg"
          icon={<SvgIcon className="rtl:-scale-x-100" name="arrow-left" />}
          href={hasBackLink ? undefined : post?.space?.relativeUrl}
          onClick={onBack}
          aria-label={$t({
            defaultMessage: 'Back',
            id: 'Generics.Back',
          })}
        />
      )}
      <div className="flex-1 min-w-0">
        <Button
          as={Link}
          variant="tertiaryNeutral"
          size="lg"
          className={clsx(
            'rounded-full',
            !post?.space && 'animate-pulse',
            'max-w-full truncate inline-flex',
          )}
          leadingIcon={<SpaceImage space={post?.space} size="lg" as="span" />}
          href={post?.space?.relativeUrl}
          truncate
        >
          {post?.space ? (
            post?.space?.name
          ) : (
            <span className="h-5 w-32 bg-surface-pressed rounded-full"></span>
          )}
        </Button>
      </div>
      {showMoreOptions && (
        <PostOptions post={post} context={context} size="lg" />
      )}
    </div>
  )
}
