import { clsx } from 'clsx'

import { IconButton } from '../Button/IconButton.js'
import { SvgIcon } from '../Icon/SvgIcon.js'

export interface CarouselArrowProps {
  onClick: () => void
  direction: 'left' | 'right'
  disabled?: boolean
  label?: string
}

export const CarouselArrow = ({
  disabled,
  onClick,
  direction,
  label,
}: CarouselArrowProps) => {
  return (
    <div
      className={clsx(
        'flex items-center self-stretch h-full absolute z-10 p-2 top-0 bottom-0',
        direction === 'right' ? 'end-0' : 'start-0',
        disabled && 'hidden',
      )}
    >
      <IconButton
        variant="secondaryNeutral"
        className="shadow"
        rounded
        icon={
          direction === 'left' ? (
            <SvgIcon className="rtl:-scale-x-100" name="chevron-left" />
          ) : (
            <SvgIcon className="rtl:-scale-x-100" name="chevron-right" />
          )
        }
        size="lg"
        onClick={onClick}
        aria-label={label}
      ></IconButton>
    </div>
  )
}
