import { FC } from 'react'

import { Toggle } from '../Toggle/index.js'
import { FormControlMeta } from './FormControlMeta.js'
import { FormControlToggleProps } from './types.js'

export const FormControlToggle: FC<FormControlToggleProps> = props => {
  const {
    name,
    label,
    helperText,
    error,
    invalid,
    hidden,
    checked,
    onChange,
    className,
    ...rest
  } = props
  return (
    <div hidden={hidden} className={className}>
      <Toggle
        {...rest}
        checked={checked}
        onChange={onChange}
        name={name}
        title={label}
        description={helperText}
      />
      {error && <FormControlMeta {...{ helperText, error, invalid }} />}
    </div>
  )
}
