import { FC } from 'react'

import { slateDtoToRaw } from '@tribeplatform/slate-kit/utils'

import { SlateContext, useSlateKitContext } from '../hooks/index.js'
import { SlateContextProps, SlateProps } from '../types/index.js'
import { compileSlate } from '../utils/index.js'
import { BlockRenderer } from './BlockRenderer.component.js'

export type SlateRendererProps = Partial<
  Omit<Omit<SlateProps, 'slate'>, 'context'>
> & {
  slate: SlateProps['slate']
  context: Partial<SlateContextProps> & {
    path: SlateContextProps['path']
    urlParams: SlateContextProps['urlParams']
    mode?: SlateContextProps['mode']
  }
}

export const SlateRenderer: FC<SlateRendererProps> = ({
  slate,
  compiledSlate,
  mode = 'live',
  context,
  extraContext,
  ...restProps
}) => {
  const { kit, context: slateKitContext } = useSlateKitContext()

  if (!slate) {
    return null
  }

  const cleanedContext: SlateContextProps = {
    ...slateKitContext,
    blocksOutput: {},
    mode,
    ...context,
    memberId: context.memberId ?? null,
    collectionId: context.collectionId ?? null,
    spaceId: context.spaceId ?? null,
    postTypeId: context.postTypeId ?? null,
    postId: context.postId ?? null,
    appId: context.appId ?? null,
    dynamicBlockKey: context.dynamicBlockKey ?? null,
    shortcutKey: context.shortcutKey ?? null,
    permissionContext: context.permissionContext ?? null,
  }
  compiledSlate =
    compiledSlate ?? compileSlate(kit, slateDtoToRaw(slate), cleanedContext)
  cleanedContext.blocksOutput = Object.values(compiledSlate.blocks).reduce(
    (preValue, value) => ({
      ...preValue,
      [value.id]: value?.output,
    }),
    {},
  )
  const SlateWrapper = kit.loadSlateWrapper()

  return (
    <SlateContext.Provider
      value={{
        slate,
        compiledSlate,
        mode,
        context: cleanedContext,
        extraContext: extraContext || { slate: {}, blocks: {} },
        ...restProps,
      }}
    >
      {SlateWrapper ? (
        <SlateWrapper>
          <BlockRenderer
            key={compiledSlate.rootBlock}
            id={compiledSlate.rootBlock}
          />
        </SlateWrapper>
      ) : (
        <BlockRenderer
          key={compiledSlate.rootBlock}
          id={compiledSlate.rootBlock}
        />
      )}
    </SlateContext.Provider>
  )
}
