import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { MarkdownBlock as Component } from './Block.js'
import { MarkdownProps } from './types.js'

export const RegisteredMarkdownBlock = (
  _intl: I18nShape,
): RegisteredBlock<MarkdownProps> => ({
  recommendedName: 'Markdown',
  Component,
  config: {
    type: BlockType.Block,
  },
})
