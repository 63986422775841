import { useStaticIntl } from '@tribeplatform/react-components/i18n'
import { FormControlSlider } from '@tribeplatform/react-ui-kit/FormControl/FormControlSlider'

import { Spacing } from '../types.js'
import { spacingTitleMapper } from '../utils/selectItems.js'
import { sliderNumberToSpacing, spacingToSliderNumber } from '../utils/utils.js'
import { SettingSections } from './SettingSections.js'
import { SliderTertiaryLabel } from './SliderTertiaryLabel.js'
import { SpacingLabel } from './SpacingLabel.js'

interface Props {
  horizontalMargin: Spacing
  verticalMargin: Spacing
  onHorizontalMarginChange: (value: Spacing) => void
  onVerticalMarginChange: (value: Spacing) => void
}

export const MarginSettings = ({
  horizontalMargin,
  verticalMargin,
  onHorizontalMarginChange,
  onVerticalMarginChange,
}: Props) => {
  const spacingTitleMap = useStaticIntl(spacingTitleMapper)

  return (
    <SettingSections.Margin>
      <FormControlSlider
        name="horizontalMargin"
        label={<SpacingLabel direction="horizontal" />}
        tertiaryLabel={
          <SliderTertiaryLabel value={spacingTitleMap[horizontalMargin]} />
        }
        min={0}
        max={5}
        step={1}
        value={spacingToSliderNumber[horizontalMargin]}
        onChange={value =>
          onHorizontalMarginChange(sliderNumberToSpacing[value])
        }
      />
      <FormControlSlider
        name="verticalMargin"
        label={<SpacingLabel direction="vertical" />}
        tertiaryLabel={
          <SliderTertiaryLabel value={spacingTitleMap[verticalMargin]} />
        }
        min={0}
        max={5}
        step={1}
        value={spacingToSliderNumber[verticalMargin]}
        onChange={value => onVerticalMarginChange(sliderNumberToSpacing[value])}
      />
    </SettingSections.Margin>
  )
}
