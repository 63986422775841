import type {
  AuthTokenFields,
  MemberFields,
  MemberInvitationFields,
} from '@tribeplatform/gql-client/graphql'
import type {
  QueryInvitationLinkValidityArgs,
  QueryLoginNetworkArgs,
  QueryMemberInvitationsArgs,
  QueryMemberInvitationValidityArgs,
  QuerySsoMembershipsArgs,
  QuerySsosArgs,
  QuerySsoUrlArgs,
} from '@tribeplatform/gql-client/types'

import { KeyConstructor } from './keyConstructor.js'

const AUTH_TOKEN_KEY = 'authToken'
const AUTH_MEMBER_KEY = 'authMember'
const AUTH_MEMBER_SESSIONS_KEY = 'authMemberSessions'
const LOGIN_KEY = 'loginNetwork'
const LOGIN_WITH_SSO_KEY = 'loginWithSso'
const SSOS_KEY = 'ssos'
const MEMBER_INVITATION_VALIDITY_KEY = 'memberInvitationValidity'
const MEMBER_INVITATIONS_KEY = 'memberInvitations'
const MEMBER_INVITATION_VALIDITY_LINK_KEY = 'memberInvitationValidityLink'
const MEMBER_INVITATION_LINK_KEY = 'memberInvitationLink'
const SSO_MEMBERSHIP_KEY = 'ssoMembership'
const SSO_URL_KEY = 'ssoUrl'

export const getAuthTokenKey = KeyConstructor<never, AuthTokenFields>(
  AUTH_TOKEN_KEY,
)
export const getAuthMemberKey = KeyConstructor<never, MemberFields>(
  AUTH_MEMBER_KEY,
)
export const getAuthMemberSessionsKey = KeyConstructor<never, MemberFields>(
  AUTH_MEMBER_SESSIONS_KEY,
)
export const getLoginKey = KeyConstructor<
  QueryLoginNetworkArgs,
  AuthTokenFields
>(LOGIN_KEY)
export const getLoginWithSsoKey = KeyConstructor<QuerySsoUrlArgs, never>(
  LOGIN_WITH_SSO_KEY,
)
export const getSsosKey = KeyConstructor<QuerySsosArgs, never>(SSOS_KEY)
export const getMemberInvitationValidityKey = KeyConstructor<
  QueryMemberInvitationValidityArgs,
  MemberInvitationFields
>(MEMBER_INVITATION_VALIDITY_KEY)
export const getMemberInvitationLinkKey = KeyConstructor<never, never>(
  MEMBER_INVITATION_LINK_KEY,
)
export const getMemberInvitationsKey = KeyConstructor<
  QueryMemberInvitationsArgs,
  MemberInvitationFields
>(MEMBER_INVITATIONS_KEY)
export const getMemberInvitationLinkValidityKey = KeyConstructor<
  QueryInvitationLinkValidityArgs,
  never
>(MEMBER_INVITATION_VALIDITY_LINK_KEY)
export const getSsoMembershipKey = KeyConstructor<
  QuerySsoMembershipsArgs,
  never
>(SSO_MEMBERSHIP_KEY)
export const getSsoUrlKey = KeyConstructor<QuerySsoUrlArgs, never>(SSO_URL_KEY)
