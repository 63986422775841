import { createContext, useContext } from 'react'

export type AccordionVariant = 'chevron' | 'chevron-down' | 'plus'
export type AccordionIconAlign = 'leading' | 'trailing'
export type AccordionSize = 'md' | 'sm'

/**
 * `inset` children are offset (vertically and horizontally) from edges,
 * `full` children are flush (vertically and horizontally) with edges
 */
export type AccordionInset = 'inset' | 'flush'

export type AccordionContext = {
  variant: AccordionVariant
  iconAlign: AccordionIconAlign
  inset: AccordionInset
  size: AccordionSize
}

export const AccordionContext = createContext<AccordionContext>(undefined)

export const useAccordionContext = () => {
  const context = useContext(AccordionContext)
  if (context === undefined) {
    throw new Error(
      'useAccordionContext must be used within a AccordionProvider',
    )
  }
  return context
}
