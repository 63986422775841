import { isEmpty } from '@bettermode/common/isempty'
import type {
  AuthToken,
  NavigationSlates,
  Network,
  Slate,
} from '@tribeplatform/gql-client/types'

import type { QueryClient } from '../../lib/react-query/QueryClient.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { getAuthTokenKey } from '../../utils/keys/authToken.keys.js'
import { getNetworkKey } from '../../utils/keys/network.key.js'

export const networkUpdater = newNetwork => old => {
  let network = newNetwork
  if (typeof newNetwork === 'function') {
    network = newNetwork(old)
  }
  return {
    ...old,
    ...network,
  }
}

export const authTokenUpdater = newAuthToken => old => {
  return {
    ...old,
    ...newAuthToken,
    network: networkUpdater(newAuthToken?.network)(old?.network),
  }
}

export const navigationSlateReducer = (
  network: Network,
  updatedSlateIds: string[],
  slates: Slate[],
) =>
  Object.keys(network.navigationSlates)
    .map(key => {
      const slate = network.navigationSlates[key]
      if (!slate) {
        return undefined
      }

      if (updatedSlateIds.includes(slate.id)) {
        return { [key]: slates.find(s => s.id === slate.id) }
      }
      return { [key]: slate }
    })
    .reduce(
      (preValue, value) => ({ ...preValue, ...value }),
      network.navigationSlates,
    ) as NavigationSlates

const findNetwork = (queryClient: QueryClient) => {
  const queries = queryClient.getQueriesData<Network>({
    queryKey: getNetworkKey(),
  })

  let network: Network

  queries?.forEach(query => {
    const data = query?.[1]
    network = {
      ...network,
      ...data,
    }
  })

  return network
}

const findNetworkInAuthToken = (queryClient: QueryClient) => {
  const queries = queryClient.getQueriesData<AuthToken>({
    queryKey: getAuthTokenKey(),
  })

  let network: Network

  queries?.forEach(query => {
    const authToken = query?.[1]
    network = {
      ...network,
      ...authToken?.network,
    }
  })

  return network
}

export const useCachedNetwork = () => {
  const queryClient = useQueryClient()

  const network = {
    ...findNetwork(queryClient),
    ...findNetworkInAuthToken(queryClient),
  }

  if (!isEmpty(network)) {
    return network
  }
}
