export enum EventNoun {
  NETWORK = 'network',
  COLLECTION = 'collection',
  SPACE = 'space',
  POST = 'post',
  MEMBER = 'member',
  MEMBER_POST_NOTIFICATION_SETTINGS = 'memberPostNotificationSettings',
  REACTION = 'reaction',
}

export enum EventVerb {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
  HIDDEN = 'hidden',
  UNHIDDEN = 'unhidden',
  ADDED = 'added',
  REMOVED = 'removed',
  JOINED = 'joined',
  LEFT = 'left',
}

export type TribeEventObject = any
export type TribeEventVariables = any

export interface TribeEvent {
  noun: EventNoun
  verb: EventVerb
  object: TribeEventObject
  variables: TribeEventVariables
}
