import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Action,
  MutationUpdateMemberSpaceRoleArgs,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSpaceMembersKey } from '../../utils/keys/member.key.js'

export const useUpdateMemberSpaceRole = (options?: {
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationUpdateMemberSpaceRoleArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<Action, ClientError, MutationUpdateMemberSpaceRoleArgs>(
    input => client.spaceRoles.updateMemberRole(input),
    {
      onSettled: (data, error, variables) => {
        const spaceMembersKey = getSpaceMembersKey({
          variables: {
            spaceId: variables.spaceId,
          },
        })
        queryClient.invalidateQueries(spaceMembersKey)
      },
      ...useMutationOptions,
    },
  )
}
