import { ReactNode } from 'react'

import { IconButton } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

interface Props {
  title: string | JSX.Element
  description?: JSX.Element
  children: ReactNode
  goBack: () => void
}
export const GenericSettings = ({
  title,
  description,
  children,
  goBack,
}: Props) => {
  return (
    <div className="absolute top-0 start-0 w-full h-full bg-surface overflow-y-auto overscroll-none px-3 py-5 space-y-5">
      <IconButton
        variant="secondaryNeutral"
        rounded
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          goBack()
        }}
        icon={<SvgIcon className="rtl:-scale-x-100" name="arrow-left" />}
      />
      <div>
        <h3 className="text-heading-sm text-content">{title}</h3>
        <p className="text-sm text-content-subdued">{description}</p>
      </div>
      <div className="space-y-2">{children}</div>
    </div>
  )
}
