import { useCallback, useEffect, useState } from 'react'

import { useParams } from 'react-router'

import { AppInteractionType } from '@tribeplatform/gql-client/types'
import { useRouterPath } from '@tribeplatform/react-sdk'
import { SlateRenderer } from '@tribeplatform/react-slate-kit/components'
import { useSlate } from '@tribeplatform/react-slate-kit/hooks'
import type {
  CallbackExtraContext,
  SlateExtraContext,
} from '@tribeplatform/react-slate-kit/types'
import { Modal } from '@tribeplatform/react-ui-kit/Modal'

import { useInteractions } from './hooks/index.js'
import { interactionEmitter } from './InteractionEmitter.js'
import type {
  InteractionEventPayload,
  OpenModalInteractionEventPayload,
  ReloadInteractionEventPayload,
} from './types.js'
import { interactionMapper } from './utils.js'

export const ModalInteraction = () => {
  const [payload, setPayload] = useState<OpenModalInteractionEventPayload>()
  const {
    interactionId,
    props: { size = 'md', title = '', description = '' } = {},
  } = payload ?? {}
  const { loadInteractions } = useInteractions({})

  const interactionHandler = useCallback(
    async (newConfigs: InteractionEventPayload) => {
      switch (newConfigs.type) {
        case AppInteractionType.OpenModal:
          setPayload(newConfigs)
          break
        case AppInteractionType.Close:
          setPayload(undefined)
          break
        case AppInteractionType.Reload:
          try {
            await loadInteractions({
              appId: newConfigs.appId,
              permissionContext: newConfigs.permissionContext,
              entityId: payload.interactionId,
              interactionId: payload.interactionId,
            })
            // We are catching the error here because we don't want to crash the app
            // We are already logging the error in the loadInteractions function
            // eslint-disable-next-line no-empty
          } catch {}
          break
        case AppInteractionType.Show:
          if (newConfigs.slate) {
            setPayload(prev => ({ ...prev, slate: newConfigs.slate }))
          }
          break
        default:
          break
      }
    },
    [loadInteractions, payload],
  )

  useEffect(() => {
    const openModalListener = (args: InteractionEventPayload) => {
      interactionHandler(args)
    }
    const closeListener = (args: InteractionEventPayload) => {
      if (args.interactionId === interactionId) {
        interactionHandler(args)
      }
    }
    const showListener = (args: InteractionEventPayload) => {
      if (args.interactionId === interactionId) {
        if (args.slate) {
          interactionHandler(args)
        }
      }
    }
    const reloadListener = async (args: ReloadInteractionEventPayload) => {
      if (args.interactionId === interactionId) {
        interactionHandler(args)
      }
    }
    if (interactionEmitter) {
      interactionEmitter.on(AppInteractionType.OpenModal, openModalListener)
      interactionEmitter.on(AppInteractionType.Close, closeListener)
      interactionEmitter.on(AppInteractionType.Show, showListener)
      interactionEmitter.on(AppInteractionType.Reload, reloadListener)
    }

    return () => {
      if (interactionEmitter) {
        interactionEmitter.removeListener(
          AppInteractionType.OpenModal,
          openModalListener,
        )
        if (interactionId) {
          interactionEmitter.removeListener(
            AppInteractionType.Close,
            closeListener,
          )
        }
        interactionEmitter.removeListener(AppInteractionType.Show, showListener)
        interactionEmitter.removeListener(
          AppInteractionType.Reload,
          reloadListener,
        )
      }
    }
  }, [interactionId, interactionHandler])

  const pathname = useRouterPath()
  const urlParams = useParams()

  const { context: slateContext, extraContext: parentExtraContext } = useSlate()
  const extraContext: SlateExtraContext<CallbackExtraContext> = {
    ...parentExtraContext,
    slate: {
      ...parentExtraContext.slate,
      callback: async (id, options) => {
        if (!payload) {
          return
        }

        // We don't catch errors here because we want the error to be thrown
        // and handled by the caller
        await loadInteractions({
          ...payload,
          callbackId: id,
          inputs: options?.data,
        })
      },
    },
  }

  if (!payload?.slate) {
    return null
  }

  return (
    <Modal
      open
      onClose={() => setPayload(undefined)}
      size={interactionMapper(size)}
    >
      <Modal.Header title={title} description={description} />
      <Modal.Content>
        <SlateRenderer
          slate={payload.slate}
          context={{
            path: pathname,
            urlParams,
            ...slateContext,
            permissionContext: payload?.permissionContext,
            appId: payload?.appId,
            shortcutKey: payload?.shortcutKey,
          }}
          extraContext={extraContext}
        />
      </Modal.Content>
    </Modal>
  )
}
