import { useStaticIntl } from '@tribeplatform/react-components/i18n'
import { FormControlSlider } from '@tribeplatform/react-ui-kit/FormControl/FormControlSlider'

import { Spacing } from '../types.js'
import { spacingTitleMapper } from '../utils/selectItems.js'
import { sliderNumberToSpacing, spacingToSliderNumber } from '../utils/utils.js'
import { SettingSections } from './SettingSections.js'
import { SliderTertiaryLabel } from './SliderTertiaryLabel.js'
import { SpacingLabel } from './SpacingLabel.js'

interface Props {
  horizontalPadding: Spacing
  verticalPadding: Spacing
  onHorizontalPaddingChange: (value: Spacing) => void
  onVerticalPaddingChange: (value: Spacing) => void
}

export const PaddingSettings = ({
  horizontalPadding,
  verticalPadding,
  onHorizontalPaddingChange,
  onVerticalPaddingChange,
}: Props) => {
  const spacingTitleMap = useStaticIntl(spacingTitleMapper)

  return (
    <SettingSections.Padding>
      <FormControlSlider
        name="horizontalPadding"
        label={<SpacingLabel direction="horizontal" />}
        tertiaryLabel={
          <SliderTertiaryLabel value={spacingTitleMap[horizontalPadding]} />
        }
        min={0}
        max={5}
        step={1}
        value={spacingToSliderNumber[horizontalPadding]}
        onChange={value =>
          onHorizontalPaddingChange(sliderNumberToSpacing[value])
        }
      />
      <FormControlSlider
        name="verticalPadding"
        label={<SpacingLabel direction="vertical" />}
        tertiaryLabel={
          <SliderTertiaryLabel value={spacingTitleMap[verticalPadding]} />
        }
        min={0}
        max={5}
        step={1}
        value={spacingToSliderNumber[verticalPadding]}
        onChange={value =>
          onVerticalPaddingChange(sliderNumberToSpacing[value])
        }
      />
    </SettingSections.Padding>
  )
}
