import { useEffect } from 'react'

import { scrollToElement } from '@tribeplatform/react-ui-kit/utils'

interface Props {
  elementRef: React.MutableRefObject<HTMLElement | null>
  containerRef: React.MutableRefObject<HTMLElement | null>
  // Since change in query params does not trigger a re-render,
  // we make it a dependency to trigger a re-render when the highlighted reply changes.
  highlightedId: string
}

/**
 * Handles scrolling to the highlighted reply.
 * It observes the changes to the size of the container element
 * of the highlighted reply and scrolls the highlighted reply into the view as the size changes.
 *
 * The scroll is disabled once the user interacts with
 * the page by initiating a click or a keydown event.
 *
 * @param elementRef - the element to scroll into view.
 * @param containerRef - the parent reply element.
 */
export const useScrollToHighlightedReply = ({
  elementRef,
  containerRef,
  highlightedId,
}: Props) => {
  useEffect(() => {
    if (typeof window === 'undefined' || !highlightedId) {
      return undefined
    }

    const containerElement = containerRef.current

    if (!containerElement) {
      return undefined
    }

    const observer = new ResizeObserver(() => {
      scrollToElement({ elementRef })
    })

    const unobserve = () => {
      observer.disconnect()
    }

    observer.observe(containerElement)

    document.addEventListener('click', unobserve, {
      capture: true,
      once: true,
    })
    document.addEventListener('keydown', unobserve, {
      capture: true,
      once: true,
    })

    return () => {
      unobserve()

      document.removeEventListener('click', unobserve)
      document.removeEventListener('keydown', unobserve)
    }
  }, [containerRef, elementRef, highlightedId])
}
