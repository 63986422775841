import {
  CustomFieldSchema,
  CustomFieldSettings,
} from '@tribeplatform/gql-client/types'

import { isFieldPrivate } from '../CMS/utils/utils.js'
import {
  CustomFieldViews,
  getFieldIcon as getCustomFieldIcon,
  getFieldSetting as getCustomFieldSetting,
  setFieldSetting as setCustomFieldSetting,
} from '../CustomField/index.js'
import type { StaticField, ViewField } from './types.js'

export const mergeFields = (
  viewFields: ViewField[],
  staticFields: StaticField[],
  customFields: CustomFieldSchema[],
): StaticField[] => {
  const currentFields = convertToStatic(
    viewFields,
    staticFields,
    customFields ?? [],
  )
  const restOfStaticFields: StaticField[] = staticFields
    .filter(
      staticField => !currentFields.some(field => field?.id === staticField.id),
    )
    .map(field => ({ ...field, enabled: false }))
  const restOfCustomFields: StaticField[] =
    customFields
      ?.filter(
        customField =>
          !currentFields.some(field => field?.id === customField.key),
      )
      ?.map(customField =>
        convertCustomFieldToStaticField(
          {
            id: customField.key,
            enabled: false,
            settings: {},
            isStatic: false,
          },
          customFields,
        ),
      ) ?? []

  return [...currentFields, ...restOfStaticFields, ...restOfCustomFields]
}

export const convertToStatic = (
  viewFields: ViewField[],
  staticFields: StaticField[],
  customFields: CustomFieldSchema[],
): StaticField[] => {
  return viewFields
    .filter(field =>
      field.isStatic
        ? staticFields.some(({ id }) => id === field.id)
        : customFields.some(({ key }) => key === field.id),
    )
    .map(field =>
      field.isStatic
        ? convertViewFieldToStaticField(field, staticFields)
        : convertCustomFieldToStaticField(field, customFields),
    )
}

export const convertViewFieldToStaticField = (
  viewField: ViewField,
  staticFields: StaticField[],
): StaticField => {
  const staticField = staticFields.find(({ id }) => id === viewField.id)
  return {
    ...staticField,
    ...(viewField?.fields
      ? {
          fields: mergeFields(viewField.fields, staticField?.fields ?? [], []),
        }
      : {}),
    settings: viewField.settings,
    enabled: viewField.enabled,
  }
}

const convertCustomFieldToStaticField = (
  viewField: ViewField,
  customFields: CustomFieldSchema[],
): StaticField => {
  const customField = customFields.find(({ key }) => key === viewField.id)

  return {
    id: customField.key,
    label: customField.name,
    enabled: viewField.enabled ?? false,
    locked: false,
    type: customField.type,
    settings: {
      ...viewField.settings,
      customFieldSettings: mergeCustomFieldSettings(viewField, customField),
    },
    isStatic: false,
    isPrivate: isFieldPrivate(customField?.readPrivacy),
    icon: getCustomFieldIcon(customField),
  }
}

export const convertToViewField = (
  staticFields: StaticField[],
): ViewField[] => {
  if (!staticFields?.length) {
    return [] as ViewField[]
  }

  return staticFields.map(({ id, enabled, fields, settings, isStatic }) => ({
    id,
    enabled,
    ...(fields ? { fields: convertToViewField(fields) } : {}),
    settings,
    isStatic,
  }))
}

const mergeCustomFieldSettings = (
  viewField: ViewField,
  originalCustomField: CustomFieldSchema,
): CustomFieldSettings[] => {
  const { settings } = originalCustomField
  const currentSettings = viewField.settings?.customFieldSettings

  if (!settings) {
    return []
  }

  if (!currentSettings) {
    return settings
  }

  let customField = { settings } as CustomFieldSchema
  const view = getCustomFieldSetting({ settings }, 'view')
  const newView = getCustomFieldSetting({ settings: currentSettings }, 'view')
  const viewProps = getCustomFieldSetting({ settings }, 'viewProps') ?? {}
  const newViewProps =
    getCustomFieldSetting({ settings: currentSettings }, 'viewProps') ?? {}

  if (newView) {
    customField = setCustomFieldSetting(customField, 'view', newView)
  }

  if (originalCustomField?.type === 'date') {
    if (viewProps?.format) {
      delete viewProps.format
    }

    customField = setCustomFieldSetting(customField, 'viewProps', {
      ...viewProps,
      format: newViewProps?.format,
    })
  }

  if (view === CustomFieldViews.Title || newView === CustomFieldViews.Title) {
    customField = setCustomFieldSetting(customField, 'viewProps', {
      ...viewProps,
      titleSize: viewField.settings?.titleSize ?? 'lg',
    })
  }

  if (view === CustomFieldViews.Button || newView === CustomFieldViews.Button) {
    if (newViewProps?.text) {
      customField = setCustomFieldSetting(customField, 'viewProps', {
        ...viewProps,
        text: newViewProps.text,
      })
    }
  }

  return customField.settings
}

export const encodeViewName = (name: string) => name.toLowerCase().trim()
