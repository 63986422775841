/**
 * Extracted from https://gist.github.com/chaance/2f3c14ec2351a175024f62fd6ba64aa6
 */
import { useCallback, useEffect, useRef } from 'react'

import {
  useBeforeUnload,
  unstable_useBlocker as useBlocker,
} from 'react-router-dom'

import { useFirstRenderContext } from '../common/ssr/FirstRenderProvider.js'

export const usePrompt = (
  message: string | null | undefined | false,
  { beforeUnload }: { beforeUnload?: boolean } = {},
) => {
  const blocker = useBlocker(
    useCallback(
      () => (typeof message === 'string' ? !window.confirm(message) : false),
      [message],
    ),
  )
  const prevState = useRef(blocker.state)
  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset()
    }
    prevState.current = blocker.state
  }, [blocker])

  useBeforeUnload(
    useCallback(
      event => {
        if (beforeUnload && typeof message === 'string') {
          event.preventDefault()
          event.returnValue = message
        }
      },
      [message, beforeUnload],
    ),
    { capture: true },
  )
}

export const Prompt = ({ ...props }: PromptProps) => {
  const { isFirstRender } = useFirstRenderContext()

  // Prevent rendering on the server
  if (isFirstRender) {
    return null
  }

  return <PromptForClient {...props} />
}

const PromptForClient = ({ when, message, ...props }: PromptProps) => {
  usePrompt(when ? message : false, props)
  return null
}

interface PromptProps {
  when: boolean
  message: string
  beforeUnload?: boolean
}
