import type { View } from '@tribeplatform/react-components/Views'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { GridList, List } from '@tribeplatform/react-ui-kit/Layout'

import { getCardWidth, getColumns } from '../utils/index.js'

interface Props {
  count: number
  style: View['style']
  gallerySize: View['gallerySize']
  showDivider?: boolean
}

export const LoadingState = ({
  count = 10,
  style,
  gallerySize,
  showDivider,
}: Props) => {
  const columns = getColumns(gallerySize)
  const width = getCardWidth(gallerySize)

  switch (style) {
    case 'gallery':
      return (
        <GridList columns={columns}>
          {[...Array(count)].map((_e, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <GridList.Item key={i}>
              <Card className="h-full">
                <div className="animate-pulse flex-1 flex flex-col mb-2 p-6 pt-4 space-y-2 items-center text-center">
                  <div className="mb-3 w-32 h-32 rounded-base bg-skeleton"></div>
                  <div className="h-4 bg-skeleton rounded-full w-2/3"></div>
                  <div className="h-4 bg-skeleton rounded-full w-3/4"></div>
                </div>
              </Card>
            </GridList.Item>
          ))}
        </GridList>
      )
    case 'card':
      return (
        <div className="flex flex-col gap-5">
          {[...Array(count)].map((_e, idx) => (
            <Card
              padding="md"
              // eslint-disable-next-line react/no-array-index-key
              key={idx}
              className="animate-pulse"
            >
              <Card.Content className="flex space-s-4">
                <div className="rounded-base bg-skeleton h-12 w-12" />
                <div className="flex-1 space-y-4 py-1">
                  <div className="h-4 bg-skeleton rounded-full w-3/4" />
                  <div className="space-y-2">
                    <div className="h-4 bg-skeleton rounded-full" />
                    <div className="h-4 bg-skeleton rounded-full w-5/6" />
                    <div className="h-4 bg-skeleton rounded-full" />
                  </div>
                </div>
              </Card.Content>
            </Card>
          ))}
        </div>
      )
    case 'list':
      return (
        <Card padding="md">
          <Card.Content>
            <List direction="vertical" divider={showDivider} spacing="md">
              {[...Array(count)].map((_e, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <List.Item key={idx}>
                  <div className="flex space-s-4 animate-pulse">
                    <div className="rounded-base bg-skeleton h-10 w-10" />
                    <div className="flex-1 flex flex-col gap-2">
                      <div className="h-4 bg-skeleton rounded-full w-3/4"></div>
                      <div className="h-4 bg-skeleton rounded-full w-5/6"></div>
                    </div>
                  </div>
                </List.Item>
              ))}
            </List>
          </Card.Content>
        </Card>
      )
    case 'carousel':
      return (
        <div className="flex flex-nowrap overflow-x-auto space-s-4 pt-1 pb-4">
          {[...Array(count)].map((_e, i) => (
            <Card
              className="h-full"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              style={{ maxWidth: `${width}px`, minWidth: `${width}px` }}
            >
              <div className="animate-pulse flex-1 flex flex-col mb-2 p-6 pt-4 space-y-2 items-center text-center">
                <div className="mb-3 w-32 h-32 rounded-base bg-skeleton"></div>
                <div className="h-4 bg-skeleton rounded-full w-2/3"></div>
                <div className="h-4 bg-skeleton rounded-full w-3/4"></div>
              </div>
            </Card>
          ))}
        </div>
      )
    default:
      return null
  }
}
