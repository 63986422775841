import { RawBlockDto } from '@tribeplatform/slate-kit/dtos'

import {
  BlockConfig,
  BlockProps,
  CompiledBlock,
  RegisteredBlockWithName,
  SlateProps,
  UnknownProps,
} from '../types/index.js'

export const getBlockProps = <P extends UnknownProps>(
  id: string,
  slateProps: SlateProps,
  customBlock?: CompiledBlock<P>,
): BlockProps<P> => {
  const {
    compiledSlate,
    updateBlockById,
    updateExtraContext,
    extraContext: { blocks: blocksExtraContext },
  } = slateProps
  const block = customBlock || (compiledSlate.blocks[id] as CompiledBlock<P>)
  const extraContext = blocksExtraContext[id] || {}

  return {
    block,
    id,
    extraContext,
    updateBlock: input => updateBlockById(id, input),
    updateOutput: output => updateBlockById(id, { output }),
    updateProps: props =>
      updateBlockById(id, {
        props,
      }),
    upsertProps: newProps => {
      updateBlockById(id, {
        props: {
          ...block.props,
          ...newProps,
        },
      })
    },
    upsertProp: (key, value) => {
      updateBlockById(id, {
        props: { ...block.props, [key]: value },
      })
    },
    updateExtraContext: value => {
      updateExtraContext({
        blocks: { ...blocksExtraContext, [id]: { ...extraContext, ...value } },
      })
    },
  }
}

export const getBlockConfigs = <P extends UnknownProps>(options: {
  context: SlateProps['context']
  block: RawBlockDto
  registeredBlock: RegisteredBlockWithName<P>
  props: P
  compiledProps: P
}): BlockConfig<P> => {
  const {
    context,
    block: { extraProps = {} },
    registeredBlock: { config },
    props,
    compiledProps,
  } = options
  const { getContextAwareConfig } = config
  return {
    ...config,
    ...(getContextAwareConfig
      ? getContextAwareConfig({ context, props, compiledProps })
      : {}),
    ...extraProps,
  }
}
