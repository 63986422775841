import type { ValueRendererComponent } from '../types.js'

export const NumberRenderer: ValueRendererComponent = ({ filter }) => {
  const { value } = filter
  const numberValue = Number(value)

  if (Number.isNaN(numberValue)) {
    return null
  }

  return <>{value}</>
}
