import { theme } from '@tribeplatform/design-system/themes/default'

import { colorTemplates } from './colors/templates.js'
import { DEFAULT_STYLE } from './constants.js'
import { CustomizerTheme } from './types.js'

export const BASE_THEME: CustomizerTheme = {
  id: 'default',
  colors: colorTemplates.find(template => template.name === 'Default').colors,
  newColors: theme,
  typography: {
    theme: 'system',
    style: DEFAULT_STYLE,
  },
}
