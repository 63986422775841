import { ReactNode, ReactElement, FC } from 'react'

import { clsx } from 'clsx'

import { useCard } from './CardContext.js'

type TitleProps = { size?: CardHeaderSize; children?: ReactNode }

const Title = ({ children, size }: TitleProps) => {
  return (
    <h3
      className={clsx(
        'text-content font-medium',
        size === 'md' && 'text-heading-xs',
        size === 'sm' && 'text-heading-2xs',
        size === 'xs' && 'text-heading-2xs',
      )}
    >
      {children}
    </h3>
  )
}

const Description = ({ children, className }) => {
  return (
    <div className={clsx('text-content-subdued', className)}>
      <p>{children}</p>
    </div>
  )
}

export type CardHeaderSize = 'xs' | 'sm' | 'md'

export type CardHeaderProps = {
  title?: string | ReactElement
  description?: string | ReactElement
  action?: ReactNode
  withBorder?: boolean
  size?: CardHeaderSize
  children?: ReactNode
}

export const CardHeader: FC<CardHeaderProps> = props => {
  const {
    title,
    description,
    action,
    withBorder = false,
    size = 'md',
    children,
  } = props
  const { padding } = useCard()

  let content
  if (!!title && !!description && !!action) {
    content = (
      <div className="flex justify-between items-center flex-wrap sm:flex-nowrap gap-2">
        <div className="min-w-0">
          <Title size={size}>{title}</Title>
          <Description className="mt-1">{description}</Description>
        </div>
        <div
          className={clsx(
            'shrink-0',
            size === 'sm' && 'text-sm',
            size === 'xs' && 'text-xs',
          )}
        >
          {action}
        </div>
      </div>
    )
  } else if (action) {
    content = (
      <div className="flex items-center justify-between flex-wrap sm:flex-nowrap gap-2">
        <div className="min-w-0">
          <Title size={size}>{title}</Title>
        </div>
        <div
          className={clsx(
            'shrink-0',
            size === 'sm' && 'text-sm',
            size === 'xs' && 'text-xs',
          )}
        >
          {action}
        </div>
      </div>
    )
  } else if (description) {
    content = (
      <div>
        <Title size={size}>{title}</Title>
        <Description className="mt-1">{description}</Description>
      </div>
    )
  } else if (title) {
    content = <Title size={size}>{title}</Title>
  } else {
    content = children
  }

  return (
    <div
      className={clsx(
        padding === 'md' && 'px-4 py-5 sm:p-6 ',
        padding === 'sm' && 'px-4 py-5 sm:px-3',
        !withBorder && 'pb-0 sm:pb-0',
        withBorder && 'border-b border-line-subdued',
      )}
    >
      {content}
    </div>
  )
}
