import { lazy, Suspense, useState } from 'react'

import { Media, NetworkVisibility } from '@tribeplatform/gql-client/types'
import { EntityImage } from '@tribeplatform/react-components/common/components'
import { T } from '@tribeplatform/react-components/i18n'
import { useNetwork, useSpace } from '@tribeplatform/react-sdk/hooks'

import { ActionButtonRenderer } from './ActionButtonRenderer.js'

const ShareModal = lazy(() =>
  import('@tribeplatform/react-components/ShareModal').then(m => ({
    default: m.ShareModal,
  })),
)

interface Props {
  showText: boolean
  showIcon: boolean
  icon?: Media
  className?: string
  spaceId: string
}

export const ShareButton = ({
  showText,
  showIcon,
  icon,
  className,
  spaceId,
}: Props) => {
  const { data: space } = useSpace({
    variables: {
      id: spaceId,
    },
    useQueryOptions: {
      enabled: !!spaceId,
    },
  })
  const { data: network } = useNetwork()
  const [shareModalOpen, setShareModalOpen] = useState(false)

  return (
    <>
      <ActionButtonRenderer
        variant="secondaryNeutral"
        showText={showText}
        showIcon={showIcon}
        className={className}
        icon={<EntityImage entity={{ image: icon }} size="xl" />}
        label={<T id="Generics.Share" defaultMessage="Share" />}
        tooltip={
          <T
            id="Blocks.SpaceHeader.ShareButton.Tooltip"
            defaultMessage="Share this space"
          />
        }
        onClick={() => setShareModalOpen(true)}
      />
      {!!space && shareModalOpen && (
        <Suspense fallback={<></>}>
          <ShareModal
            open
            isPrivate={
              space?.hidden || network.visibility === NetworkVisibility.private
            }
            spaceId={spaceId}
            onClose={() => setShareModalOpen(false)}
            title={space?.name}
            url={space?.url}
          />
        </Suspense>
      )}
    </>
  )
}
