import { Card } from '@tribeplatform/react-ui-kit/Card'
import { EmptyState as EmptyStateComponent } from '@tribeplatform/react-ui-kit/EmptyState'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { useI18n } from '../i18n/providers/I18nProvider.js'

export const EmptyState = () => {
  const { $t } = useI18n()

  return (
    <Card>
      <Card.Content>
        <div className="my-16">
          <EmptyStateComponent
            title={$t({
              id: 'Generics.NothingHereYet',
              defaultMessage: 'Nothing here yet.',
            })}
            icon={<SvgIcon name="post" />}
          />
        </div>
      </Card.Content>
    </Card>
  )
}
