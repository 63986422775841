import { cva } from 'class-variance-authority'

export const textStyles = cva('', {
  variants: {
    align: {
      leading: 'text-start',
      center: 'text-center',
      trailing: 'text-end',
    },
    fontWeight: {
      regular: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
    },
    color: {
      default: '',
      subdued: '',
      inherit: '',
      disabled: '',
      positive: 'text-content-on-positive',
      negative: 'text-content-on-negative',
      attention: 'text-content-on-attention',
      highlight: 'text-content-on-highlight',
    },
    variant: {
      heading2xl: 'text-heading-2xl',
      headingXl: 'text-heading-xl',
      headingLg: 'text-heading-lg',
      headingMd: 'text-heading-md',
      headingSm: 'text-heading-sm',
      headingXs: 'text-heading-xs',
      heading2xs: 'text-heading-2xs',
      heading3xs: 'text-heading-3xs',
      bodyXl: 'text-xl',
      bodyLg: 'text-lg',
      bodyMd: 'text-md',
      bodySm: 'text-sm',
      bodyXs: 'text-xs',
      labelLg: 'text-label-lg',
      labelMd: 'text-label-md',
      labelSm: 'text-label-sm',
      labelXs: 'text-label-xs',
    },
    truncate: {
      true: 'truncate',
      false: '',
    },
    breakWords: {
      true: 'break-words',
      false: '',
    },
    onBackground: {
      true: '',
      false: '',
    },
  },
  defaultVariants: {
    color: 'default',
    onBackground: false,
  },
  compoundVariants: [
    {
      color: 'default',
      onBackground: true,
      className: 'text-content-on-background',
    },
    {
      color: 'default',
      onBackground: false,
      className: 'text-content',
    },
    {
      color: 'subdued',
      onBackground: true,
      className: 'text-content-on-background-subdued',
    },
    {
      color: 'subdued',
      onBackground: false,
      className: 'text-content-subdued',
    },
    {
      color: 'disabled',
      onBackground: false,
      className: 'text-content-disabled',
    },
    {
      color: 'disabled',
      onBackground: true,
      className: 'text-content-on-background-disabled',
    },
  ],
})
