import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { ComposerBlock as Component } from './Block.js'
import { ComposerProps } from './types.js'

export const RegisteredComposerBlock = (
  intl: I18nShape,
): RegisteredBlock<ComposerProps> => {
  const { $t } = intl

  return {
    recommendedName: 'Composer',
    Component,
    config: {
      type: BlockType.Block,
      displayName: $t({
        id: 'Generics.Composer',
        defaultMessage: 'Composer',
      }),
      Icon: <SvgIcon name="clipboard" />,
    },
  }
}
