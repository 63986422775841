import { useEffect, useRef } from 'react'

// eslint-disable-next-line no-restricted-imports
import type { Instance } from 'flatpickr/dist/types/instance.js'
// eslint-disable-next-line no-restricted-imports
import type { Options } from 'flatpickr/dist/types/options.js'

import { flatpickr } from '../../utils/flatpickr.js'

export type DateTimeValue = Options['defaultDate']
export type DateTimeOptions = Options
export type DateTimeInstance = Instance

interface Props {
  pickerRef: React.MutableRefObject<HTMLElement>
  onChange: (value: DateTimeValue) => void
  options?: Omit<Options, 'defaultDate' | 'onChange'>
}
export const useDateTimePicker = ({ pickerRef, onChange, options }: Props) => {
  const pickerInstanceRef = useRef<Instance>()

  useEffect(() => {
    const pickerElement = pickerRef.current
    let pickerInstance: Instance = null

    if (pickerElement) {
      pickerInstance = flatpickr(pickerElement, {
        ...options,
        onChange,
      })
      pickerInstanceRef.current = pickerInstance
    }

    return () => {
      if (pickerElement && pickerInstance) {
        pickerInstance.destroy()
        pickerInstanceRef.current = null
      }
    }
  }, [onChange, options, pickerRef])

  return pickerInstanceRef
}
