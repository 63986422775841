import type { InfiniteData } from '@tanstack/react-query'

import { isEmpty } from '@bettermode/common/isempty'
import type {
  App,
  PaginatedApp,
  PaginatedAppInstallation,
  QueryAppArgs,
} from '@tribeplatform/gql-client/types'

import type { QueryClient } from '../../lib/react-query/QueryClient.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { getAppInstallationsKey, getAppsKey } from '../../utils/keys/app.key.js'

const validate = (app: App, args: QueryAppArgs) => {
  if (args?.id && app?.id !== args?.id) {
    return false
  }
  if (args?.slug && app?.slug !== args?.slug) {
    return false
  }
  return true
}

const findAppInApps = (args: QueryAppArgs, queryClient: QueryClient): App => {
  const queries = queryClient.getQueriesData<InfiniteData<PaginatedApp>>({
    queryKey: getAppsKey(),
  })

  let app: App

  queries?.forEach(query => {
    const data = query?.[1]
    data?.pages?.forEach(page => {
      page?.edges?.forEach(edge => {
        if (validate(edge?.node, args)) {
          app = {
            ...app,
            ...edge?.node,
          }
        }
      })
    })
  })

  return app
}

const findAppInAppInstallations = (
  args: QueryAppArgs,
  queryClient: QueryClient,
): App => {
  const queries = queryClient.getQueriesData<
    InfiniteData<PaginatedAppInstallation>
  >({
    queryKey: getAppInstallationsKey(),
  })

  let app: App

  queries?.forEach(query => {
    const data = query?.[1]
    data?.pages?.forEach(page => {
      page?.edges?.forEach(edge => {
        if (validate(edge?.node?.app, args)) {
          app = {
            ...app,
            ...edge?.node?.app,
          }
        }
      })
    })
  })

  return app
}

export const useCachedApp = (args: QueryAppArgs): App => {
  const queryClient = useQueryClient()
  const app = {
    ...findAppInApps(args, queryClient),
    ...findAppInAppInstallations(args, queryClient),
  }
  if (!isEmpty(app)) {
    return app
  }
}
