import { Children } from 'react'

import { useBlock, useSlate } from '@tribeplatform/react-slate-kit/hooks'
import { BC } from '@tribeplatform/react-slate-kit/types'
import { Tabs as DumbTabs } from '@tribeplatform/react-ui-kit/Tabs'

import { TabsProps } from './types.js'

export const Tabs: BC<TabsProps> = ({
  tabs,
  spacing = 'md',
  rounded = 'desktop',
  fullWidth = false,
  attached = 'none',
  containerProps,
  children: reactChildren,
  ...rest
}) => {
  const {
    updateExtraContext,
    extraContext: {
      slate: { activeTabs = {}, ...slateExtraContext },
    },
  } = useSlate<{ activeTabs: Record<string, number> }>()
  const {
    id,
    block: { children: childrenIds },
  } = useBlock()
  const children = Children.toArray(reactChildren)
  const activeTabIndex = activeTabs[id]
  const isManual = typeof activeTabIndex === 'number'

  if (!tabs?.length) {
    return null
  }

  return (
    <div {...rest} {...containerProps}>
      <DumbTabs.Group
        {...(isManual
          ? {
              selectedIndex: activeTabIndex,
              onChange: idx => {
                updateExtraContext({
                  slate: {
                    ...slateExtraContext,
                    activeTabs: { ...activeTabs, [id]: idx },
                  },
                })
              },
            }
          : {})}
      >
        <DumbTabs.List
          rounded={rounded}
          fullWidth={fullWidth}
          attached={attached}
          className="overflow-hidden"
          variant="legacyBar"
        >
          {tabs.map(tab => (
            <DumbTabs.Tab key={tab.name} name={tab.name}>
              {tab.name}
            </DumbTabs.Tab>
          ))}
        </DumbTabs.List>
        <DumbTabs.Panels spacing={spacing}>
          {children.map((child, idx) => (
            <DumbTabs.Panel key={childrenIds[idx]}>{child}</DumbTabs.Panel>
          ))}
        </DumbTabs.Panels>
      </DumbTabs.Group>
    </div>
  )
}
