import { type I18nShape, useI18n } from '../../i18n/index.js'
import type { ValueRendererComponent } from '../types.js'

export const ratingValueFactory = (value: number, intl: I18nShape) => {
  const { $t } = intl
  return $t(
    {
      defaultMessage: '{value, plural, =1 {# star} other {# stars}}',
      id: 'Filters.Values.Rating',
    },
    {
      value,
    },
  )
}

export const RatingRenderer: ValueRendererComponent = ({ filter }) => {
  const intl = useI18n()
  const { value } = filter
  const numberValue = Number(value)

  if (Number.isNaN(numberValue)) {
    return null
  }

  const currentValue = Math.min(Math.max(0, numberValue), 5)
  const ratingValue = ratingValueFactory(currentValue, intl)

  return <>{ratingValue}</>
}
