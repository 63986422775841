import type { Space } from '@tribeplatform/gql-client/types'
import type { StaticField, View } from '@tribeplatform/react-components/Views'
import { GridList } from '@tribeplatform/react-ui-kit/Layout'

import { getColumns } from '../utils/index.js'
import { SpacesGalleryItem } from './SpacesGalleryItem.js'

interface Props {
  spaces: Space[]
  fields: StaticField[]
  gallerySize: View['gallerySize']
  activeFieldId?: string
  showMoreButton: JSX.Element
  preview: boolean
}
export const SpacesGallery = ({
  spaces,
  fields,
  gallerySize = 'medium',
  activeFieldId,
  showMoreButton,
  preview,
}: Props) => {
  const columns = getColumns(gallerySize)

  return (
    <>
      <GridList columns={columns}>
        {spaces.map(space => (
          <SpacesGalleryItem
            key={space.id}
            space={space}
            fields={fields.filter(
              field =>
                field.enabled &&
                !(field.styles && field.styles.indexOf('gallery') === -1),
            )}
            activeFieldId={activeFieldId}
            gallerySize={gallerySize}
            preview={preview}
          />
        ))}
      </GridList>
      {!!showMoreButton && <div className="mt-2">{showMoreButton}</div>}
    </>
  )
}
