import { useCallback, useEffect, useRef } from 'react'

import { nanoid } from 'nanoid'

import {
  RawBlockDto,
  RawBlockUpdatesDto,
  RawSlateUpdatesDto,
} from '@tribeplatform/slate-kit/dtos'

import { useBlock } from '../../../hooks/block.hook.js'
import { useSlateKitContext } from '../../../hooks/slate-kit-context.hook.js'
import { useSlate } from '../../../hooks/slate.hook.js'
import { COLUMN_BLOCK_NAME } from '../constants.js'
import { SectionLayout, SectionProps } from '../types.js'
import { ColumnsPerLayout } from '../utils/utils.js'
import type { ColumnsInfo, InitialState } from './types/types.js'

export const useUpdateLayout = () => {
  const { block: sectionBlock } = useBlock<SectionProps>()
  const initialState = useRef<InitialState>()

  const {
    compiledSlate: { blocks },
    updateSlate,
  } = useSlate()
  const { kit } = useSlateKitContext()

  const initializeState = useCallback(() => {
    const columnsInfo: ColumnsInfo = sectionBlock.children.reduce(
      (acc, colId) => {
        const newColumn = {
          [colId]: {
            extraProps: blocks[colId].extraProps,
            children: blocks[colId].children,
            props: blocks[colId].props,
          },
        } as ColumnsInfo

        return { ...acc, ...newColumn }
      },
      {} as ColumnsInfo,
    )

    initialState.current = {
      layout: sectionBlock.props.layout,
      columnsInfo,
    }
  }, [blocks, sectionBlock.children, sectionBlock.props.layout])

  useEffect(() => {
    if (initialState.current === undefined) {
      initializeState()
    }
  }, [initializeState])

  /**
   * Creates new columns with proper order and display name
   * @param count - number of columns to create
   * @param noOfExistingColumns - number of existing columns to properly set the new columns
   * display name. For example, if there are 2 columns already and 2 new columns are to be created,
   * the new columns will have display names as "Column 3" and "Column 4"
   */
  const createColumns = useCallback(
    (count: number, existingColumnCount: number): RawBlockDto[] => {
      const {
        name,
        config: { initialProps = {} },
      } = kit.getRegisteredBlock(COLUMN_BLOCK_NAME)

      return Array.from({ length: count }).map((_, index) => {
        const order = existingColumnCount + index + 1
        return {
          id: nanoid(),
          name,
          props: { ...initialProps },
          extraProps: {
            displayName: `${name} ${order}`,
          },
        }
      })
    },
    [kit],
  )

  const prepareUpdate = useCallback((newLayout: SectionLayout) => {
    const { layout: initialLayout, columnsInfo } = initialState.current
    const initialColumnCount = ColumnsPerLayout[initialLayout]
    const newColumnCount = ColumnsPerLayout[newLayout]
    /**
     * Creates the initial columns update. Basically, if this update is applied, the section
     * will be reverted to it's initial state.
     */
    const initialColumns = Object.entries(columnsInfo).map(
      ([_, { children, props, extraProps }]) => ({
        id: nanoid(),
        name: COLUMN_BLOCK_NAME,
        props,
        extraProps,
        children,
      }),
    )

    return {
      initialColumnCount,
      newColumnCount,
      initialColumns,
    }
  }, [])

  const getAddNewColumnsUpdate = useCallback(
    (newLayout: SectionLayout): RawSlateUpdatesDto => {
      const { initialColumnCount, newColumnCount, initialColumns } =
        prepareUpdate(newLayout)

      const newColumns = createColumns(
        newColumnCount - initialColumnCount,
        initialColumnCount,
      )

      const updatedSection: RawBlockUpdatesDto = {
        id: sectionBlock.id,
        children: [
          ...initialColumns.map(({ id }) => id),
          ...newColumns.map(({ id }) => id),
        ],
        props: {
          ...sectionBlock.props,
          layout: newLayout,
        },
      }

      return {
        removedBlocks: sectionBlock.children,
        addedBlocks: [...initialColumns, ...newColumns],
        updatedBlocks: [updatedSection],
      }
    },
    [
      createColumns,
      prepareUpdate,
      sectionBlock.children,
      sectionBlock.id,
      sectionBlock.props,
    ],
  )

  const getRemoveColumnsUpdate = useCallback(
    (newLayout: SectionLayout): RawSlateUpdatesDto => {
      const { newColumnCount, initialColumns } = prepareUpdate(newLayout)
      const remainingColumns = initialColumns.slice(0, newColumnCount)
      const removedColumns = initialColumns.slice(newColumnCount)
      const blocksToMove = removedColumns.flatMap(({ children }) => children)

      const lastColumnIndex = newColumnCount - 1
      const lastColumn = remainingColumns[lastColumnIndex]
      // Add the moved blocks to the last remaining column
      const columns = [
        ...remainingColumns.slice(0, lastColumnIndex),
        {
          ...lastColumn,
          children: [...lastColumn.children, ...blocksToMove],
        },
      ]

      const updatedSection: RawBlockUpdatesDto = {
        id: sectionBlock.id,
        children: columns.map(({ id }) => id),
        props: {
          ...sectionBlock.props,
          layout: newLayout,
        },
      }

      return {
        removedBlocks: sectionBlock.children,
        addedBlocks: columns,
        updatedBlocks: [updatedSection],
      }
    },
    [prepareUpdate, sectionBlock.children, sectionBlock.id, sectionBlock.props],
  )

  const updateLayout = useCallback(
    (newLayout: SectionLayout) => {
      if (initialState.current === undefined) {
        return
      }

      const { initialColumnCount, newColumnCount } = prepareUpdate(newLayout)
      const shouldAddNewColumns = newColumnCount >= initialColumnCount

      if (shouldAddNewColumns) {
        updateSlate({ ...getAddNewColumnsUpdate(newLayout) })

        return
      }

      updateSlate({
        ...getRemoveColumnsUpdate(newLayout),
      })
    },
    [
      getAddNewColumnsUpdate,
      getRemoveColumnsUpdate,
      prepareUpdate,
      updateSlate,
    ],
  )

  return { updateLayout }
}
