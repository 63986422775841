import { useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { MemberEmailStatus, RoleType } from '@bettermode/common/gql/generated'

import {
  generateMemberVerificationUrl,
  isNoAuthPath,
} from '../../../server/middlewares/auth/utils/permission.utils.js'
import { VISITOR_ID_PREFIX } from '../../../server/middlewares/verify/constants/verify.constants.js'

interface Props {
  emailStatus: MemberEmailStatus
  roleType: RoleType
  memberId: string
}
export const useRedirectUnconfirmedUser = ({
  emailStatus,
  roleType,
  memberId,
}: Props): void => {
  const { search, pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (
      !!emailStatus &&
      emailStatus !== MemberEmailStatus.verified &&
      !!memberId &&
      !memberId.startsWith(VISITOR_ID_PREFIX) &&
      roleType === RoleType.guest &&
      !isNoAuthPath(pathname)
    ) {
      navigate(generateMemberVerificationUrl(memberId, `${pathname}${search}`))
    }
  }, [emailStatus, memberId, navigate, pathname, roleType, search])
}
