import { DocumentNode, FieldNode, OperationDefinitionNode } from 'graphql'

import {
  EventNoun,
  EventVerb,
  TribeEvent,
  TribeEventObject,
  TribeEventVariables,
} from './event.js'

const OPERATION_EVENT_MAP: Record<
  string,
  { noun: EventNoun; verb: EventVerb }
> = {
  // Network Events
  updateNetwork: {
    noun: EventNoun.NETWORK,
    verb: EventVerb.UPDATED,
  },

  // Space Events
  createSpace: {
    noun: EventNoun.SPACE,
    verb: EventVerb.CREATED,
  },
  updateSpace: {
    noun: EventNoun.SPACE,
    verb: EventVerb.UPDATED,
  },
  deleteSpace: {
    noun: EventNoun.SPACE,
    verb: EventVerb.DELETED,
  },
  joinSpace: {
    noun: EventNoun.SPACE,
    verb: EventVerb.JOINED,
  },
  leaveSpace: {
    noun: EventNoun.SPACE,
    verb: EventVerb.LEFT,
  },

  // Collection Events
  createCollection: {
    noun: EventNoun.COLLECTION,
    verb: EventVerb.CREATED,
  },
  updateCollection: {
    noun: EventNoun.COLLECTION,
    verb: EventVerb.UPDATED,
  },
  deleteCollection: {
    noun: EventNoun.COLLECTION,
    verb: EventVerb.DELETED,
  },

  // Post Events
  createPost: {
    noun: EventNoun.POST,
    verb: EventVerb.CREATED,
  },
  updatePost: {
    noun: EventNoun.POST,
    verb: EventVerb.UPDATED,
  },
  deletePost: {
    noun: EventNoun.POST,
    verb: EventVerb.DELETED,
  },
  hidePost: {
    noun: EventNoun.POST,
    verb: EventVerb.HIDDEN,
  },
  unhidePost: {
    noun: EventNoun.POST,
    verb: EventVerb.UNHIDDEN,
  },
  createReply: {
    noun: EventNoun.POST,
    verb: EventVerb.CREATED,
  },

  // Member Events
  updateMember: {
    noun: EventNoun.MEMBER,
    verb: EventVerb.UPDATED,
  },
  deleteMember: {
    noun: EventNoun.MEMBER,
    verb: EventVerb.UPDATED,
  },

  // Member Post Notification Settings Events
  updateMemberPostNotificationSettings: {
    noun: EventNoun.MEMBER_POST_NOTIFICATION_SETTINGS,
    verb: EventVerb.UPDATED,
  },

  // Reaction Events
  addReaction: {
    noun: EventNoun.REACTION,
    verb: EventVerb.ADDED,
  },
  removeReaction: {
    noun: EventNoun.REACTION,
    verb: EventVerb.REMOVED,
  },
}

export const createEvent = (
  query: DocumentNode,
  object: TribeEventObject,
  variables: TribeEventVariables,
): TribeEvent => {
  const operationName = (
    (query?.definitions?.[0] as OperationDefinitionNode)?.selectionSet
      ?.selections?.[0] as FieldNode
  )?.name?.value

  if (!(operationName in OPERATION_EVENT_MAP)) {
    return
  }

  return {
    ...OPERATION_EVENT_MAP[operationName],
    variables,
    object: object?.[operationName],
  }
}
