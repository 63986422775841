import { useMemo } from 'react'

import { clsx } from 'clsx'

import { SpaceMember } from '@tribeplatform/gql-client/types'
import { analyticsNumberFormatter } from '@tribeplatform/react-components/Analytics/utils'
import { MemberAvatar } from '@tribeplatform/react-components/common/components'
import { T } from '@tribeplatform/react-components/i18n'
import { useSpace, useSpaceMembers } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Avatar } from '@tribeplatform/react-ui-kit/Avatar'

import { MembersSettings } from '../../Fields/types.js'
import { Tooltip } from '../ActionButtons/Tooltip.js'
import { MembersFieldSkeleton } from '../Skeletons/MembersFieldSkeleton.js'

export const MEMBERS_COUNT_LIMIT = 5

interface Props {
  className?: string
  settings: MembersSettings
  spaceId: string
  isSimple?: boolean
}

export const MembersField = ({
  className,
  settings,
  spaceId,
  isSimple = false,
}: Props) => {
  const { data: space } = useSpace({
    variables: {
      id: spaceId,
    },
    useQueryOptions: {
      enabled: !!spaceId,
    },
  })
  const { data, isLoading } = useSpaceMembers({
    fields: {
      member: {
        profilePicture: 'all',
      },
    },
    variables: { limit: MEMBERS_COUNT_LIMIT, spaceId },
    useInfiniteQueryOptions: {
      enabled: !!spaceId,
    },
  })
  const members = useMemo(() => {
    if (spaceId) {
      const { nodes: spaceMembers } = simplifyPaginatedResult<SpaceMember>(data)
      const nodes = spaceMembers.map(({ member }) => member)

      return nodes
    }

    return []
  }, [data, spaceId])
  const { showCount, size } = settings
  const showMembersCount = showCount && members.length > 0
  const totalMembersCount = space?.membersCount || 0

  if (isLoading) {
    return <MembersFieldSkeleton size={size} count={MEMBERS_COUNT_LIMIT} />
  }

  if (members.length === 0) {
    return null
  }

  return (
    <div className={clsx('flex -space-x-2 items-stretch', className)}>
      {members.map(member => (
        <Tooltip key={member.id} tooltip={member.name}>
          <MemberAvatar
            member={member}
            size={size}
            className="cursor-pointer"
          />
        </Tooltip>
      ))}
      {showMembersCount && (
        <Tooltip
          tooltip={
            <T
              id="Blocks.SpaceHeader.MembersFieldCount.Tooltip"
              defaultMessage="{count} people have joined this space."
              values={{ count: analyticsNumberFormatter(totalMembersCount) }}
            />
          }
        >
          <Avatar
            className={clsx(
              'cursor-pointer flex text-content-subdued justify-center items-center min-w-min',
              isSimple && 'bg-surface',
              !isSimple && 'bg-surface-subdued',
            )}
            size={size}
            fallback={() => {
              return (
                <div className="flex text-sm justify-center items-center h-full p-2">
                  {analyticsNumberFormatter(totalMembersCount)}
                </div>
              )
            }}
          />
        </Tooltip>
      )}
    </div>
  )
}
