import type { NavigationSlates, Slate } from '@tribeplatform/gql-client/types'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

import { useQueryLayout } from '../providers/QueryLayoutProvider.js'
import { SlateLayout } from '../types.js'
import {
  getLayoutFromQuery,
  getLayoutHasHeaderSidebar,
} from '../utils/utils.js'

interface UseLayoutFrameProps {
  forceLayout?: SlateLayout
  layout?: SlateLayout
  navigationSlates?: NavigationSlates
}
type UseLayoutFrame = (props?: UseLayoutFrameProps) => {
  header: Slate
  sidebar1: Slate
  showHeader: boolean
  showSidebar: boolean
}

export const useLayoutFrame: UseLayoutFrame = ({ layout, forceLayout }) => {
  const { data: network } = useNetwork()
  const navigationSlates = network?.navigationSlates ?? ({} as NavigationSlates)
  const {
    header,
    sidebar1,
    headerDisabled: networkHeaderDisabled,
    sidebar1Disabled: networkSidebar1Disabled,
  } = navigationSlates
  const queryLayout = useQueryLayout()
  const layoutFromQuery = getLayoutFromQuery(queryLayout)
  const slateLayout =
    layoutFromQuery ?? layout ?? forceLayout ?? SlateLayout.headerSidebar
  const { pageHasHeader, pageHasLeftSidebar } =
    getLayoutHasHeaderSidebar(slateLayout)

  return {
    header,
    sidebar1,
    showHeader: forceLayout
      ? pageHasHeader
      : pageHasHeader && !networkHeaderDisabled,
    showSidebar: forceLayout
      ? pageHasLeftSidebar
      : pageHasLeftSidebar && !networkSidebar1Disabled,
  }
}
