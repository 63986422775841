import { AppsEnum } from '../../../common/constants/app.js'

export enum ImageAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  FloatLeft = 'float-left',
  FloatRight = 'float-right',
}

export enum ImageSize {
  Original = 'original',
  Half = 'half',
  Full = 'full',
}

export enum ImageDescriptionType {
  Alt = 'alt',
  Caption = 'caption',
  Overlay = 'overlay',
}

export type AsyncImageAttributes = {
  src?: string
  alt?: string
  title?: string
  id?: string
  align?: ImageAlign
  size?: ImageSize
  loading?: boolean
  uploadId?: string
  app?: AppsEnum
  /**
   * Has description is separate flag to allow users maintain preserve the content during
   * edit. If has description is false when user submits the form, the description will be
   * removed from the content.
   */
  hasDescription?: boolean
  description?: string
  descriptionType?: ImageDescriptionType
}

export enum ImageMarkupVersion {
  V2 = 'v2',
}
