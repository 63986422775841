import { SvgIcon } from '../Icon/SvgIcon.js'
import { AlertStatus } from './types.js'

export type AlertIconProps = {
  status: AlertStatus
}

export const AlertIcon = ({ status, ...rest }: AlertIconProps) => {
  switch (status) {
    case 'error':
      return <SvgIcon {...rest} name="status-error" inline />
    case 'warning':
      return <SvgIcon {...rest} name="status-warning" inline />
    case 'success':
      return <SvgIcon {...rest} name="status-success" inline />
    case 'info':
      return <SvgIcon {...rest} name="status-info" inline />
    case 'neutral':
    default:
      return null
  }
}
