import type { Image, File, Media } from '@tribeplatform/gql-client/types'

import { StoredField } from './Fields/types.js'

export enum SpaceHeaderPreset {
  MediaFullIconTop = 'MediaFullIconTop',
  MediaFullIconCenter = 'MediaFullIconCenter',
  MediaTopIconTop = 'MediaTopIconTop',
  MediaFullNoIcon = 'MediaFullNoIcon',
  NoMediaIconTop = 'NoMediaIconTop',
  NoMediaIconLeft = 'NoMediaIconLeft',
  NoMediaCenter = 'NoMediaCenter',
}
export enum Alignment {
  Center = 'Center',
  Start = 'Start',
  End = 'End',
}
export enum MediaType {
  Image = 'Image',
  Video = 'Video',
  Color = 'Color',
  None = 'None',
}
export enum TextColor {
  Default = 'Default',
  Light = 'Light',
  Dark = 'Dark',
}
export enum Target {
  Blank = 'Blank',
  Self = 'Self',
}
export enum ButtonActionType {
  PrimaryButton = 'PrimaryButton',
  SecondaryButton = 'SecondaryButton',
  NeutralButton = 'NeutralButton',
  TextLink = 'TextLink',
}
export enum ActionButtonVariant {
  Icon = 'Icon',
  Text = 'Text',
  IconText = 'IconText',
}
export interface ActionButton {
  id: string
  name: string
  url: string
  type: ButtonActionType
  target: Target
  locked?: boolean
  enabled?: boolean
  icon?: Media
  variant?: ActionButtonVariant
}
export enum SpaceHeaderIconSize {
  ExtraSmall = '2x',
  Small = '2.5x',
  Medium = '3x',
  Large = '4x',
}
export enum IconWrapperStyle {
  Circle = 'Circle',
  Rounded = 'Rounded',
  Sharp = 'Sharp',
}
export enum MembersAvatarSize {
  ExtraSmall = '2x',
  Small = '2.5x',
  Medium = '3x',
  Large = '4x',
}
export type SpaceHeaderProps = {
  spaceId?: string
  preset: SpaceHeaderPreset
  contentSpacing: number
  mediaAspectRatio: number
  textColor: TextColor
  contentAlignment: Alignment
  mediaType: MediaType
  useSpaceBanner?: boolean
  image: Image
  video: File
  mediaTint: number
  backgroundColor?: string
  fields: StoredField[]
}
export type LegacySpaceHeaderProps = {
  view: 'card' | 'simple'
}
export enum SpaceHeaderFieldKey {
  Title = 'Title',
  Stats = 'Stats',
  Description = 'Description',
  Members = 'Members',
  Icon = 'Icon',
  Buttons = 'Buttons',
  TotalMembers = 'MembersCount',
  TotalPosts = 'PostsCount',
  DateCreated = 'CreationDate',
  OptionsButton = 'OptionsButton',
  JoinButton = 'JoinButton',
  AddPostButton = 'AddPostButton',
  ShareButton = 'ShareButton',
  SearchButton = 'SearchButton',
  NotificationsButton = 'NotificationsButton',
  CustomButton = 'CustomButton',
}
