import {
  ContainerAlignment,
  ContainerDirection,
  ContainerHide,
  ContainerMaxSize,
  ContainerOrder,
  ContainerPadding,
  ContainerPaddingAxis,
  ContainerPaddingSize,
  ContainerSize,
  DetailedContainerAlignment,
  DetailedContainerPadding,
  DetailedContainerPaddingAxis,
} from './types.js'

const CONTAINER_SIZE_ORDER: Record<ContainerSize, number> = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5,
  full: 6,
}

const SIZE_MAPPER: Record<ContainerSize, number> = {
  xs: 1,
  sm: 2,
  md: 4,
  lg: 6,
  xl: 8,
  full: 8,
}

const REVERSE_SIZE_MAPPER: Record<number, ContainerSize> = {
  1: 'xs',
  2: 'sm',
  4: 'md',
  6: 'lg',
  8: 'full',
}

export const CONTAINER_HORIZONTAL_DIRECTIONS: ContainerDirection[] = [
  'horizontal',
  'horizontal-reverse',
]

export const CONTAINER_VERTICAL_DIRECTIONS: ContainerDirection[] = [
  'vertical',
  'vertical-reverse',
]

const isContainerSize = (
  padding: ContainerPadding,
): padding is ContainerPaddingSize => {
  return typeof padding === 'string'
}

const isContainerPaddingAxis = (
  padding: ContainerPadding,
): padding is DetailedContainerPaddingAxis => {
  return (padding as DetailedContainerPaddingAxis)?.size !== undefined
}

export const sumContainerSizes = (...sizes: ContainerSize[]): ContainerSize => {
  const sum = sizes
    .filter(s => s)
    .reduce((acc, size) => acc + SIZE_MAPPER[size], 0)
  return REVERSE_SIZE_MAPPER[sum] || 'full'
}

export const minContainerSize = (...sizes: ContainerSize[]): ContainerSize => {
  return sizes
    .filter(s => s)
    .sort((a, b) => CONTAINER_SIZE_ORDER[a] - CONTAINER_SIZE_ORDER[b])[0]
}

export const extractDetailFromContainerPaddingAxis = (
  padding: ContainerPaddingAxis,
): DetailedContainerPaddingAxis => {
  if (isContainerSize(padding)) {
    return { size: padding }
  }
  return padding
}

const normalizeContainerPadding = (
  padding: Record<string, ContainerPaddingAxis>,
): Record<string, DetailedContainerPaddingAxis> => {
  return Object.keys(padding).reduce((acc, key) => {
    acc[key] = extractDetailFromContainerPaddingAxis(padding[key])
    return acc
  }, {})
}

export const extractDetailFromContainerPadding = (
  padding: ContainerPadding,
): DetailedContainerPadding => {
  if (!padding) {
    return null
  }
  if (isContainerSize(padding) || isContainerPaddingAxis(padding)) {
    const detailedPadding = extractDetailFromContainerPaddingAxis(padding)
    return {
      vertical: detailedPadding,
      horizontal: detailedPadding,
    }
  }
  return normalizeContainerPadding(padding)
}

export const extractDetailFromContainerMaxSize = (
  size: ContainerSize,
  maxSize?: ContainerMaxSize,
): ContainerMaxSize => maxSize || size

export const extractDetailFromContainerOrder = (order?: ContainerOrder) =>
  order && {
    default: order.default,
    sm: typeof order?.sm === 'number' ? order.sm : order.default,
    md: typeof order?.md === 'number' ? order.md : order.default,
    lg: typeof order?.lg === 'number' ? order.lg : order.default,
  }

export const extractDetailFromContainerHide = (hide?: ContainerHide) =>
  hide && {
    default: hide.default,
    sm: typeof hide?.sm === 'boolean' ? hide.sm : hide.default,
    md: typeof hide?.md === 'boolean' ? hide.md : hide.default,
    lg: typeof hide?.lg === 'boolean' ? hide.lg : hide.default,
  }

export const extractDetailFromContainerAlignment = (
  alignment?: ContainerAlignment,
): DetailedContainerAlignment =>
  alignment
    ? {
        horizontal:
          typeof alignment === 'string' ? alignment : alignment.horizontal,
        vertical:
          typeof alignment === 'string' ? alignment : alignment.vertical,
        self:
          typeof alignment === 'string'
            ? alignment
            : alignment.self ?? 'center',
      }
    : { self: 'center' }

export const getContainerSpacing = (options: {
  direction: ContainerDirection
  spacing?: DetailedContainerPaddingAxis
}) => {
  const { direction, spacing } = options
  return (
    spacing && [
      CONTAINER_VERTICAL_DIRECTIONS.includes(direction) && [
        direction === 'vertical-reverse' &&
          'space-y-reverse sm:space-y-reverse md:space-y-reverse lg:space-y-reverse',
        spacing.size === 'none' && 'space-y-0',
        (spacing.size === 'none' || spacing.onlyOnMobile) &&
          'sm:space-y-0 md:space-y-0 lg:space-y-0',
        spacing.size === 'xs' && [
          !spacing.disableOnMobile && 'space-y-1',
          !spacing.onlyOnMobile && 'sm:space-y-1 lg:space-y-1',
        ],
        spacing.size === 'sm' && [
          !spacing.disableOnMobile && 'space-y-2',
          !spacing.onlyOnMobile && 'sm:space-y-2.5 lg:space-y-3',
        ],
        spacing.size === 'md' && [
          !spacing.disableOnMobile && 'space-y-3',
          !spacing.onlyOnMobile && 'sm:space-y-3.5 md:space-y-4 lg:space-y-5',
        ],
        spacing.size === 'lg' && [
          !spacing.disableOnMobile && 'space-y-5',
          !spacing.onlyOnMobile && 'sm:space-y-6 md:space-y-7 lg:space-y-8',
        ],
        spacing.size === 'xl' && [
          !spacing.disableOnMobile && 'space-y-7',
          !spacing.onlyOnMobile && 'sm:space-y-8 md:space-y-9 lg:space-y-10',
        ],
      ],
      CONTAINER_HORIZONTAL_DIRECTIONS.includes(direction) && [
        direction === 'horizontal-reverse' &&
          'space-s-reverse sm:space-s-reverse md:space-s-reverse lg:space-s-reverse',
        spacing.size === 'none' && 'space-s-0',
        (spacing.size === 'none' || spacing.onlyOnMobile) &&
          'sm:space-s-0 md:space-s-0 lg:space-s-0',
        spacing.size === 'xs' && [
          !spacing.disableOnMobile && 'space-s-1',
          !spacing.onlyOnMobile && 'sm:space-s-1 lg:space-s-1',
        ],
        spacing.size === 'sm' && [
          !spacing.disableOnMobile && 'space-s-2',
          !spacing.onlyOnMobile && 'sm:space-s-2.5 lg:space-s-3',
        ],
        spacing.size === 'md' && [
          !spacing.disableOnMobile && 'space-s-3',
          !spacing.onlyOnMobile && 'sm:space-s-3.5 md:space-s-4 lg:space-s-5',
        ],
        spacing.size === 'lg' && [
          !spacing.disableOnMobile && 'space-s-5',
          !spacing.onlyOnMobile && 'sm:space-s-6 md:space-s-7 lg:space-s-8',
        ],
        spacing.size === 'xl' && [
          !spacing.disableOnMobile && 'space-s-7',
          !spacing.onlyOnMobile && 'sm:space-s-8 md:space-s-9 lg:space-s-10',
        ],
      ],
      direction === 'grid' && [
        spacing.size === 'none' && 'gap-0',
        (spacing.size === 'none' || spacing.onlyOnMobile) &&
          'sm:gap-0 md:gap-0 lg:gap-0',
        spacing.size === 'xs' && [
          !spacing.disableOnMobile && 'gap-1',
          !spacing.onlyOnMobile && 'sm:gap-1 lg:gap-1',
        ],
        spacing.size === 'sm' && [
          !spacing.disableOnMobile && 'gap-2',
          !spacing.onlyOnMobile && 'sm:gap-2.5 lg:gap-3',
        ],
        spacing.size === 'md' && [
          !spacing.disableOnMobile && 'gap-3',
          !spacing.onlyOnMobile && 'sm:gap-3.5 md:gap-4 lg:gap-5',
        ],
        spacing.size === 'lg' && [
          !spacing.disableOnMobile && 'gap-5',
          !spacing.onlyOnMobile && 'sm:gap-6 md:gap-7 lg:gap-8',
        ],
        spacing.size === 'xl' && [
          !spacing.disableOnMobile && 'gap-7',
          !spacing.onlyOnMobile && 'sm:gap-8 md:gap-9 lg:gap-10',
        ],
      ],
    ]
  )
}

export const getContainerPadding = (padding?: DetailedContainerPadding) =>
  padding && [
    padding.vertical && [
      padding.vertical.size === 'none' && 'py-0',
      (padding.vertical.size === 'none' || padding.vertical.onlyOnMobile) &&
        'sm:py-0 md:py-0 lg:py-0',
      padding.vertical.size === 'xs' && [
        !padding.vertical.disableOnMobile && 'py-1',
        !padding.vertical.onlyOnMobile && 'sm:py-1 lg:py-1',
      ],
      padding.vertical.size === 'sm' && [
        !padding.vertical.disableOnMobile && 'py-2',
        !padding.vertical.onlyOnMobile && 'sm:py-2.5 lg:py-3',
      ],
      padding.vertical.size === 'md' && [
        !padding.vertical.disableOnMobile && 'py-3',
        !padding.vertical.onlyOnMobile && 'sm:py-3.5 md:py-4 lg:py-5',
      ],
      padding.vertical.size === 'lg' && [
        !padding.vertical.disableOnMobile && 'py-5',
        !padding.vertical.onlyOnMobile && 'sm:py-6 md:py-7 lg:py-8',
      ],
      padding.vertical.size === 'xl' && [
        !padding.vertical.disableOnMobile && 'py-7',
        !padding.vertical.onlyOnMobile && 'sm:py-8 md:py-9 lg:py-10',
      ],
    ],
    padding.horizontal && [
      padding.horizontal.size === 'none' && 'px-0',
      (padding.horizontal.size === 'none' || padding.horizontal.onlyOnMobile) &&
        'sm:px-0 md:px-0 lg:px-0',
      padding.horizontal.size === 'xs' && [
        !padding.horizontal.disableOnMobile && 'px-1',
        !padding.horizontal.onlyOnMobile && 'sm:px-1 lg:px-1',
      ],
      padding.horizontal.size === 'sm' && [
        !padding.horizontal.disableOnMobile && 'px-2',
        !padding.horizontal.onlyOnMobile && 'sm:px-2.5 lg:px-3',
      ],
      padding.horizontal.size === 'md' && [
        !padding.horizontal.disableOnMobile && 'px-3',
        !padding.horizontal.onlyOnMobile && 'sm:px-3.5 md:px-4 lg:px-5',
      ],
      padding.horizontal.size === 'lg' && [
        !padding.horizontal.disableOnMobile && 'px-5',
        !padding.horizontal.onlyOnMobile && 'sm:px-6 md:px-7 lg:px-8',
      ],
      padding.horizontal.size === 'xl' && [
        !padding.horizontal.disableOnMobile && 'px-7',
        !padding.horizontal.onlyOnMobile && 'sm:px-8 md:px-9 lg:px-10',
      ],
    ],
    padding.top && [
      padding.top.size === 'none' && 'pt-0',
      (padding.top.size === 'none' || padding.top.onlyOnMobile) &&
        'sm:pt-0 md:pt-0 lg:pt-0',
      padding.top.size === 'xs' && [
        !padding.top.disableOnMobile && 'pt-1',
        !padding.top.onlyOnMobile && 'sm:pt-1 lg:pt-1',
      ],
      padding.top.size === 'sm' && [
        !padding.top.disableOnMobile && 'pt-2',
        !padding.top.onlyOnMobile && 'sm:pt-2.5 lg:pt-3',
      ],
      padding.top.size === 'md' && [
        !padding.top.disableOnMobile && 'pt-3',
        !padding.top.onlyOnMobile && 'sm:pt-3.5 md:pt-4 lg:pt-5',
      ],
      padding.top.size === 'lg' && [
        !padding.top.disableOnMobile && 'pt-5',
        !padding.top.onlyOnMobile && 'sm:pt-6 md:pt-7 lg:pt-8',
      ],
      padding.top.size === 'xl' && [
        !padding.top.disableOnMobile && 'pt-7',
        !padding.top.onlyOnMobile && 'sm:pt-8 md:pt-9 lg:pt-10',
      ],
    ],
    padding.bottom && [
      padding.bottom.size === 'none' && 'pb-0',
      (padding.bottom.size === 'none' || padding.bottom.onlyOnMobile) &&
        'sm:pb-0 md:pb-0 lg:pb-0',
      padding.bottom.size === 'xs' && [
        !padding.bottom.disableOnMobile && 'pb-1',
        !padding.bottom.onlyOnMobile && 'sm:pb-1 lg:pb-1',
      ],
      padding.bottom.size === 'sm' && [
        !padding.bottom.disableOnMobile && 'pb-2',
        !padding.bottom.onlyOnMobile && 'sm:pb-2.5 lg:pb-3',
      ],
      padding.bottom.size === 'md' && [
        !padding.bottom.disableOnMobile && 'pb-3',
        !padding.bottom.onlyOnMobile && 'sm:pb-3.5 md:pb-4 lg:pb-5',
      ],
      padding.bottom.size === 'lg' && [
        !padding.bottom.disableOnMobile && 'pb-5',
        !padding.bottom.onlyOnMobile && 'sm:pb-6 md:pb-7 lg:pb-8',
      ],
      padding.bottom.size === 'xl' && [
        !padding.bottom.disableOnMobile && 'pb-7',
        !padding.bottom.onlyOnMobile && 'sm:pb-8 md:pb-9 lg:pb-10',
      ],
    ],
    padding.left && [
      padding.left.size === 'none' && 'ps-0',
      (padding.left.size === 'none' || padding.left.onlyOnMobile) &&
        'sm:ps-0 md:ps-0 lg:ps-0',
      padding.left.size === 'xs' && [
        !padding.left.disableOnMobile && 'ps-1',
        !padding.left.onlyOnMobile && 'sm:ps-1 lg:ps-1',
      ],
      padding.left.size === 'sm' && [
        !padding.left.disableOnMobile && 'ps-2',
        !padding.left.onlyOnMobile && 'sm:ps-2.5 lg:ps-3',
      ],
      padding.left.size === 'md' && [
        !padding.left.disableOnMobile && 'ps-3',
        !padding.left.onlyOnMobile && 'sm:ps-3.5 md:ps-4 lg:ps-5',
      ],
      padding.left.size === 'lg' && [
        !padding.left.disableOnMobile && 'ps-5',
        !padding.left.onlyOnMobile && 'sm:ps-6 md:ps-7 lg:ps-8',
      ],
      padding.left.size === 'xl' && [
        !padding.left.disableOnMobile && 'ps-7',
        !padding.left.onlyOnMobile && 'sm:ps-8 md:ps-9 lg:ps-10',
      ],
    ],
    padding.right && [
      padding.right.size === 'none' && 'pe-0',
      (padding.right.size === 'none' || padding.right.onlyOnMobile) &&
        'sm:pe-0 md:pe-0 lg:pe-0',
      padding.right.size === 'xs' && [
        !padding.right.disableOnMobile && 'pe-1',
        !padding.right.onlyOnMobile && 'sm:pe-1 lg:pe-1',
      ],
      padding.right.size === 'sm' && [
        !padding.right.disableOnMobile && 'pe-2',
        !padding.right.onlyOnMobile && 'sm:pe-2.5 lg:pe-3',
      ],
      padding.right.size === 'md' && [
        !padding.right.disableOnMobile && 'pe-3',
        !padding.right.onlyOnMobile && 'sm:pe-3.5 md:pe-4 lg:pe-5',
      ],
      padding.right.size === 'lg' && [
        !padding.right.disableOnMobile && 'pe-5',
        !padding.right.onlyOnMobile && 'sm:pe-6 md:pe-7 lg:pe-8',
      ],
      padding.right.size === 'xl' && [
        !padding.right.disableOnMobile && 'pe-7',
        !padding.right.onlyOnMobile && 'sm:pe-8 md:pe-9 lg:pe-10',
      ],
    ],
  ]

export const getContainerAlignment = (options: {
  alignment?: DetailedContainerAlignment
  direction: ContainerDirection
  parentDirection: ContainerDirection
}) => {
  const { alignment, direction, parentDirection } = options
  return (
    alignment && [
      CONTAINER_VERTICAL_DIRECTIONS.includes(direction) && [
        alignment.horizontal === 'start' && 'items-start',
        alignment.horizontal === 'center' && 'items-center',
        alignment.horizontal === 'end' && 'items-end',
        alignment.horizontal === 'stretch' && 'items-stretch',
        alignment.vertical === 'start' && 'justify-start',
        alignment.vertical === 'center' && 'justify-center',
        alignment.vertical === 'end' && 'justify-end',
      ],
      CONTAINER_HORIZONTAL_DIRECTIONS.includes(direction) && [
        alignment.horizontal === 'start' && 'justify-start',
        alignment.horizontal === 'center' && 'justify-center',
        alignment.horizontal === 'end' && 'justify-end',
        alignment.horizontal === 'stretch' && 'items-stretch',
        alignment.vertical === 'start' && 'items-start',
        alignment.vertical === 'center' && 'items-center',
        alignment.vertical === 'end' && 'items-end',
      ],
      direction === 'grid' && [
        alignment.horizontal === 'start' && 'justify-items-start',
        alignment.horizontal === 'center' && 'justify-items-center',
        alignment.horizontal === 'end' && 'justify-items-end',
        alignment.horizontal === 'stretch' && 'justify-items-stretch',
        alignment.vertical === 'start' && 'content-start',
        alignment.vertical === 'center' && 'content-center',
        alignment.vertical === 'end' && 'content-end',
      ],
      alignment.self &&
        parentDirection === 'vertical' && [
          alignment.self === 'start' && 'self-start',
          alignment.self === 'center' && 'self-center',
          alignment.self === 'end' && 'self-end',
          alignment.self === 'stretch' && 'self-stretch',
        ],
      alignment.self &&
        parentDirection === 'grid' && [
          alignment.self === 'start' && 'justify-self-start',
          alignment.self === 'center' && 'justify-self-center',
          alignment.self === 'end' && 'justify-self-end',
          alignment.self === 'stretch' && 'justify-self-stretch',
        ],
    ]
  )
}

export const getContainerOrder = (order?: ContainerOrder) =>
  order && [
    [
      order.sm === 1 && 'order-1',
      order.sm === 2 && 'order-2',
      order.sm === 3 && 'order-3',
      order.sm === 4 && 'order-4',
      order.sm === 5 && 'order-5',
      order.sm === 6 && 'order-6',
      order.sm === 7 && 'order-7',
      order.sm === 8 && 'order-8',
      order.sm === 9 && 'order-9',
      order.sm === 10 && 'order-10',
      order.sm === 11 && 'order-11',
      order.sm === 12 && 'order-12',
    ],
    [
      order.md === 1 && 'md:order-1',
      order.md === 2 && 'md:order-2',
      order.md === 3 && 'md:order-3',
      order.md === 4 && 'md:order-4',
      order.md === 5 && 'md:order-5',
      order.md === 6 && 'md:order-6',
      order.md === 7 && 'md:order-7',
      order.md === 8 && 'md:order-8',
      order.md === 9 && 'md:order-9',
      order.md === 10 && 'md:order-10',
      order.md === 11 && 'md:order-11',
      order.md === 12 && 'md:order-12',
    ],
    [
      order.lg === 1 && 'lg:order-1',
      order.lg === 2 && 'lg:order-2',
      order.lg === 3 && 'lg:order-3',
      order.lg === 4 && 'lg:order-4',
      order.lg === 5 && 'lg:order-5',
      order.lg === 6 && 'lg:order-6',
      order.lg === 7 && 'lg:order-7',
      order.lg === 8 && 'lg:order-8',
      order.lg === 9 && 'lg:order-9',
      order.lg === 10 && 'lg:order-10',
      order.lg === 11 && 'lg:order-11',
      order.lg === 12 && 'lg:order-12',
    ],
  ]

export const getContainerHide = (hide?: ContainerHide) =>
  hide && [
    hide.sm ? 'hidden' : 'block',
    hide.md ? 'md:hidden' : 'md:block',
    hide.lg ? 'lg:hidden' : 'lg:block',
  ]

export const getContainerMaxSize = (maxSize: ContainerMaxSize) =>
  maxSize !== 'none' && [
    'max-w-full',
    maxSize === 'xs' && 'sm:max-w-xs',
    maxSize === 'sm' && 'md:max-w-sm',
    maxSize === 'md' && 'md:max-w-3xl',
    maxSize === 'lg' && 'md:max-w-5xl',
    maxSize === 'xl' && 'md:max-w-8xl',
  ]
