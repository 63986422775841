import { T, useStaticIntl } from '@tribeplatform/react-components/i18n'
import { FormControlSlider } from '@tribeplatform/react-ui-kit/FormControl/FormControlSlider'

import { SectionLayout, Spacing } from '../types.js'
import { spacingTitleMapper } from '../utils/selectItems.js'
import { sliderNumberToSpacing, spacingToSliderNumber } from '../utils/utils.js'
import { LayoutPicker } from './LayoutPicker.js'
import { SettingSections } from './SettingSections.js'
import { SliderTertiaryLabel } from './SliderTertiaryLabel.js'

interface Props {
  layout: SectionLayout
  gap: Spacing
  onLayoutChange: (value: SectionLayout) => void
  onGapChange: (value: Spacing) => void
}

export const LayoutSettings = ({
  layout,
  gap,
  onLayoutChange,
  onGapChange,
}: Props) => {
  const spacingTitleMap = useStaticIntl(spacingTitleMapper)

  return (
    <SettingSections.Layout>
      <LayoutPicker value={layout} onChange={onLayoutChange} />
      <FormControlSlider
        name="gap"
        label={<T id="Generics.Gap" defaultMessage="Gap" />}
        tertiaryLabel={<SliderTertiaryLabel value={spacingTitleMap[gap]} />}
        helperText={
          <T
            id="Blocks.Section.Settings.GapHelperText"
            defaultMessage="Space between the columns inside section."
          />
        }
        min={0}
        max={5}
        step={1}
        value={spacingToSliderNumber[gap]}
        onChange={value => onGapChange(sliderNumberToSpacing[value])}
      />
    </SettingSections.Layout>
  )
}
