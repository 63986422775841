import type { ClientError } from '@tribeplatform/gql-client/lib'
import type { Action } from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getNotificationsCountKey } from '../../utils/keys/notification.key.js'

export const useClearNotificationsCount = (options?: {
  useMutationOptions?: UseMutationOptions<Action, ClientError>
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<Action, ClientError>(
    () => client.notifications.clearNotificationsCount(),
    {
      onMutate: () => {
        const notificationsCountKey = getNotificationsCountKey()
        queryClient.setQueriesData(notificationsCountKey, () => 0)
      },
      onSettled: () => {
        const notificationsCountKey = getNotificationsCountKey()
        queryClient.invalidateQueries(notificationsCountKey)
      },
      ...useMutationOptions,
    },
  )
}
