import { useMemo } from 'react'

import { useAuthToken } from '@tribeplatform/react-sdk/hooks'

import { getDomainInfo } from '../utils/domain.js'

export const useDomain = () => {
  const { data: authToken } = useAuthToken()

  const { networkPublicInfo } = authToken || {}
  return useMemo(
    () =>
      getDomainInfo({
        domain: networkPublicInfo?.domain,
        domainSubfolder: networkPublicInfo?.domainSubfolder,
      }),
    [networkPublicInfo],
  )
}
