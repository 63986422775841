import {
  createContext,
  ReactNode,
  Suspense,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react'

import { ModalProps } from '@tribeplatform/react-ui-kit/Modal'

const noop = () => {
  // no operation
}
type UncontrolledModalProps<T> = Omit<T, 'open' | 'onClose'>
interface UncontrolledModal<T> {
  name: string
  component:
    | React.LazyExoticComponent<(props: T) => JSX.Element>
    | ((props: T) => JSX.Element)
  props?: UncontrolledModalProps<T>
}
interface Modal {
  name: string
  component:
    | React.LazyExoticComponent<(props: ModalProps) => JSX.Element>
    | ((props: ModalProps) => JSX.Element)
  props?: ModalProps
}

interface ModalContextProps {
  modals: Modal[]
  openModal: <T extends ModalProps>(modal: UncontrolledModal<T>) => void
  closeModal: (name?: string) => void
}
const ModalContext = createContext<ModalContextProps>({
  modals: [],
  openModal: noop,
  closeModal: noop,
})

const CLOSE_MODAL_DELAY = 100 // ms

interface ModalProviderProps {
  children: ReactNode
  initialModals?: Modal[]
}
export const ModalProvider = ({
  children,
  initialModals = [],
}: ModalProviderProps) => {
  const [modals, setModals] =
    useState<ModalContextProps['modals']>(initialModals)
  const timeoutHandle = useRef<NodeJS.Timeout>(null)

  const closeModal = useCallback((name: string) => {
    setModals(prevModals => {
      if (!name) {
        return []
      }
      const newModals = prevModals.map(modal => {
        if (modal.name !== name) {
          return modal
        }

        return { ...modal, props: { ...modal?.props, open: false } }
      })
      return [...newModals]
    })

    clearTimeout(timeoutHandle.current)
    timeoutHandle.current = setTimeout(() => {
      setModals(prevModals => {
        if (!name) {
          return []
        }
        const newModals = prevModals.filter(modal => modal.name !== name)
        return [...newModals]
      })
      timeoutHandle.current = null
    }, CLOSE_MODAL_DELAY)
  }, [])

  const openModal = useCallback<
    <T extends ModalProps>(modal: UncontrolledModal<T>) => void
  >(
    modal => {
      clearTimeout(timeoutHandle.current)
      timeoutHandle.current = null
      setModals(prevModals => {
        const otherModals = prevModals.filter(
          prevModal => prevModal.name !== modal.name,
        )
        const newModal: Modal = {
          ...modal,
          props: {
            ...modal?.props,
            ...{ open: true, onClose: () => closeModal(modal.name) },
          },
        }
        return [...otherModals, newModal]
      })
    },
    [closeModal],
  )

  const openModals = modals.map(modal => ({
    Component: modal.component,
    name: modal.name,
    props: modal?.props,
  }))

  return (
    <ModalContext.Provider value={{ modals, openModal, closeModal }}>
      {children}
      {openModals.map(({ Component, name, props }) => (
        <Suspense fallback={<></>} key={name}>
          <Component {...props} />
        </Suspense>
      ))}
    </ModalContext.Provider>
  )
}

export const useModal = () => useContext(ModalContext)

ModalContext.displayName = 'ModalContext'
