import { Alert } from '@tribeplatform/react-ui-kit/Alert'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { T } from '../../i18n/components/T.js'
import { Link } from '../../Link/Link.js'

interface Props {
  isPrivateNetwork: boolean
  isPrivateSpace: boolean
  isNetworkNoIndex: boolean
  isSpaceNoindex: boolean
}
export const SitemapWarnings = ({
  isPrivateNetwork,
  isPrivateSpace,
  isNetworkNoIndex,
  isSpaceNoindex,
}: Props) => {
  if (isPrivateNetwork) {
    return (
      <Alert status="info">
        <T
          defaultMessage="Your site is private. As a result, its posts will not be indexed by search engines. <link>Site settings</link>"
          id="Admin.Post.SeoSettings.HideSeo.PrivateSiteWarning"
          values={{
            link: chunks => {
              return (
                <Link
                  href="/manage/settings/network"
                  variant="accent"
                  target="_blank"
                  className="inline-block"
                >
                  <SvgIcon name="cog" className="ms-1" inline />
                  {chunks}
                </Link>
              )
            },
          }}
        />
      </Alert>
    )
  }

  if (isNetworkNoIndex) {
    return (
      <Alert status="info">
        <T
          defaultMessage="Your site is hidden from search engines. As a result, its posts will not be indexed. <link>SEO settings</link>"
          id="Admin.Post.SeoSettings.HideSeo.NoIndexSiteWarning"
          values={{
            link: chunks => {
              return (
                <Link
                  href="/manage/settings/seo"
                  variant="accent"
                  target="_blank"
                  className="inline-block"
                >
                  <SvgIcon name="cog" className="ms-1" inline />
                  {chunks}
                </Link>
              )
            },
          }}
        />
      </Alert>
    )
  }

  if (isPrivateSpace) {
    return (
      <Alert status="info">
        <T
          defaultMessage="The space containing this post is private. As a result, its posts will not be indexed by search engines."
          id="Admin.Post.SeoSettings.HideSeo.PrivateSpaceWarning"
        />
      </Alert>
    )
  }

  if (isSpaceNoindex) {
    return (
      <Alert status="info">
        <T
          defaultMessage="The space containing this post is hidden from search engines. As a result, this post will not be indexed."
          id="Admin.Post.SeoSettings.HideSeo.NoIndexSpaceWarning"
        />
      </Alert>
    )
  }

  return null
}
