import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { MemberStatus } from '@tribeplatform/gql-client/types'
import {
  ProfilePicture,
  BannerPlaceholder,
} from '@tribeplatform/react-components/common/components'
import { T } from '@tribeplatform/react-components/i18n'
import { MemberBackgroundImage } from '@tribeplatform/react-components/Member'
import {
  MemberBadges,
  MemberBadgeLocation,
} from '@tribeplatform/react-components/MemberBadge'
import { useAuthMember, useMember } from '@tribeplatform/react-sdk/hooks'
import { Card } from '@tribeplatform/react-ui-kit/Card'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { PageHeader } from '@tribeplatform/react-ui-kit/PageHeader'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

import { MemberActionButtons } from './MemberActionButtons.js'

export const MemberHeader = ({
  memberId,
  attached = 'bottom',
  className,
  ...rest
}: {
  memberId: string
  attached?: 'top' | 'bottom' | 'none'
  className?: string
}) => {
  const { data: member } = useMember({
    variables: { id: memberId },
    fields: {
      authMemberProps: 'all',
      badges: 'all',
      banner: 'all',
      profilePicture: 'all',
      role: 'basic',
      fields: 'all',
    },
    useQueryOptions: {
      refetchOnMount: 'always',
      retry: false,
    },
  })
  const [canUpdateMember] = hasScopesPermission(member, ['updateMember'])
  const { isModerator, isAdmin } = useAuthMember()

  if (!member) {
    return null
  }
  return (
    <>
      <Card className={className} attached={attached} {...rest}>
        <PageHeader
          title={
            <div
              className="shrink-0 truncate max-w-full"
              title={member?.name}
              translate="no"
            >
              {member?.name}
            </div>
          }
          secondaryTitle={
            <>
              {(isModerator || isAdmin) &&
                member?.status === MemberStatus.Suspended && (
                  <Tooltip>
                    <Tooltip.Trigger>
                      <SvgIcon
                        className="w-5 h-5 text-content-subdued"
                        name="ban"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Panel>
                      <T
                        defaultMessage="Profile temporarily deactivated"
                        id="Generics.ProfileTemporarilyDeactivated"
                      />
                    </Tooltip.Panel>
                  </Tooltip>
                )}
              <MemberBadges
                member={member}
                size="sm"
                location={MemberBadgeLocation.ProfileName}
                className="text-heading-xs"
              />
            </>
          }
          avatar={
            <ProfilePicture
              editable={canUpdateMember}
              member={member}
              size="8x"
              className="ring-2 bg-surface ring-offset-surface ring-surface"
            />
          }
          backgroundImage={
            <Card.Media top>
              <MemberBackgroundImage
                member={member}
                fallback={
                  <div className="w-full bg-cover bg-center absolute top-0 start-0">
                    <BannerPlaceholder name={member?.name ?? ''} />
                  </div>
                }
              />
            </Card.Media>
          }
          action={<MemberActionButtons member={member} />}
        />
      </Card>
    </>
  )
}
