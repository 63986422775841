import { useMemo } from 'react'

import type {
  AuthToken,
  Member,
  Scalars,
} from '@tribeplatform/gql-client/types'

import type { QueryClient } from '../../lib/react-query/QueryClient.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { getAuthTokenKey } from '../../utils/keys/authToken.keys.js'
import { getMembersKey } from '../../utils/keys/member.key.js'
import { getPostKey } from '../../utils/keys/post.key.js'

const findMemberInAuthMember = (
  id: Scalars['ID'],
  queryClient: QueryClient,
): Member => {
  let cachedMember = null

  const queries = queryClient.getQueriesData<AuthToken>({
    queryKey: getAuthTokenKey(),
    type: 'active',
  })
  queries?.forEach(query => {
    const member = query[1]?.member as { id }
    if (member?.id === id) {
      cachedMember = { ...member, ...cachedMember }
    }
  })

  return cachedMember
}

const findMemberInMembers = (
  id: Scalars['ID'],
  queryClient: QueryClient,
): Member => {
  let cachedMember = null

  const queries = queryClient.getQueriesData({
    queryKey: getMembersKey(),
    type: 'active',
  })
  queries?.forEach(query => {
    const queryData = query[1] as {
      pages: [{ edges: [{ node: { id: string } }] }]
    }
    queryData?.pages?.forEach(page => {
      page?.edges?.forEach(({ node }) => {
        if (node?.id === id) {
          cachedMember = { ...node, ...cachedMember }
        }
      })
    })
  })

  return cachedMember
}

const findMemberInPost = (
  id: Scalars['ID'],
  queryClient: QueryClient,
): Member => {
  const postKey = getPostKey()
  let cachedMember = null

  const queries = queryClient.getQueriesData({
    queryKey: postKey,
    type: 'active',
  })
  queries?.forEach(query => {
    const post = query[1] as { owner: { member: { id } } }
    if (post?.owner?.member?.id === id) {
      cachedMember = { ...post?.owner?.member, ...cachedMember }
    }
  })

  return cachedMember
}

export const getCachedMember = (
  id: Scalars['ID'],
  queryClient: QueryClient,
): Member => {
  const member =
    findMemberInAuthMember(id, queryClient) ||
    findMemberInMembers(id, queryClient) ||
    findMemberInPost(id, queryClient)
  return member && Object.keys(member).length ? member : null
}

export const useCachedMember = (id: Scalars['ID']): Member => {
  const queryClient = useQueryClient()
  return useMemo(() => getCachedMember(id, queryClient), [id, queryClient])
}
