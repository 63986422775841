import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { LegacyHeroBannerBlock as Component } from './Block.js'
import { LegacyHeroBannerProps } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({
    default: m.LegacyHeroBannerBlockSettings,
  })),
)

export const RegisteredLegacyHeroBannerBlock = (
  intl: I18nShape,
): RegisteredBlock<LegacyHeroBannerProps> => {
  const { $t } = intl

  return {
    recommendedName: 'HeroBanner',
    Component,
    config: {
      type: BlockType.Block,
      usable: false,

      displayName: $t({
        id: 'Blocks.HeroBanner.DisplayName',
        defaultMessage: 'Hero banner',
      }),
      Settings,
      Icon: <SvgIcon name="cursor-click" />,
      defaultProps: {
        title: $t({
          id: 'Blocks.HeroBanner.DefaultTitle',
          defaultMessage: 'Welcome to {COMMUNITY}',
        }),
        description: '',
        align: 'center',
        textColor: 'dark',
        imageTintOpacity: 0,
        imagePosition: 'cover',
        extraPadding: 3,
        style: 'card',
        backgroundType: 'image',
      },
    },
  }
}
