import { lazy, Suspense, useCallback } from 'react'

import { clsx } from 'clsx'
import { domToReact, HTMLReactParserOptions } from 'html-react-parser'

import { useRouter } from '@tribeplatform/react-sdk'
import { useAuthToken, useSpaceUtil } from '@tribeplatform/react-sdk/hooks'

import { getDomainInfo } from '../common/utils/domain.js'
import { ImageMarkupVersion } from '../Composer/extensions/image/types.js'
import { useMediaModal } from '../MediaModal/MediaModalProvider.js'
import { Parser } from '../utils/html-react-parser.js'
import { ComposerModuleName, HtmlContentProps } from './@types.js'
import { AttachmentRenderer } from './AttachmentRenderer.js'
import { EmbedRenderer } from './EmbedRenderer.js'
import { DomAudio, renderV2Img, DomVideo, renderV1Img } from './renderers.js'
import { TableOfContentRenderer } from './TableOfContentRenderer.js'
import {
  attachmentToDeferredSlide,
  figureToSlide,
  imgToSlide,
} from './utils.js'

const CodeBlock = lazy(() =>
  import('../CodeBlock/CodeBlock.js').then(m => ({ default: m.CodeBlock })),
)

export const HtmlContent = ({
  value,
  embeds,
  attachments,
  mentions,
  context,
  trustedDomains = [],
  hideUrl,
}: HtmlContentProps) => {
  const { generateMemberPath } = useSpaceUtil()
  const { Link } = useRouter()
  const {
    data: { networkPublicInfo },
  } = useAuthToken()
  const { domain, subfolder } = getDomainInfo({
    domain: networkPublicInfo?.domain,
    domainSubfolder: networkPublicInfo?.domainSubfolder,
  })

  if (trustedDomains?.indexOf(networkPublicInfo?.domain) === -1) {
    trustedDomains.push(networkPublicInfo?.domain)
  }
  if (subfolder && trustedDomains?.indexOf(domain) === -1) {
    trustedDomains.push(domain)
  }

  const { pushSlide, openSlideWithIndex } = useMediaModal()

  const openMediaModal = useCallback(
    e => {
      const index = Number(e?.currentTarget.dataset?.slideIndex)
      if (Number.isNaN(index)) {
        return
      }
      openSlideWithIndex(index)
    },
    [openSlideWithIndex],
  )

  if (!value) {
    return null
  }

  const options: HTMLReactParserOptions = {
    replace: _domNode => {
      /**
       * The inferred type of _domNode is complex to narrow.
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const domNode = _domNode as any
      const tagName = domNode.name as string
      let isTrusted: boolean
      switch (tagName) {
        case 'figure': {
          const figureSlide = figureToSlide(domNode)
          const figureMediaIndex = pushSlide(figureSlide)
          switch (domNode.firstChild?.name) {
            case 'img': {
              if (
                domNode?.attribs?.['data-version'] === ImageMarkupVersion.V2
              ) {
                return renderV2Img(domNode, {
                  mediaIndex: figureMediaIndex,
                  onClick: openMediaModal,
                })
              }

              return renderV1Img(domNode.firstChild, {
                mediaIndex: figureMediaIndex,
                onClick: openMediaModal,
                context,
              })
            }
            case 'audio':
              return <DomAudio domNode={domNode.firstChild} />
            case 'video':
              return <DomVideo domNode={domNode.firstChild} />
            default:
              return domNode
          }
        }
        case 'img': {
          const imgSlide = imgToSlide(domNode)
          const imageMediaIndex = pushSlide(imgSlide)
          return renderV1Img(domNode, {
            mediaIndex: imageMediaIndex,
            onClick: openMediaModal,
            context,
          })
        }
        case 'h1':
        case 'h2':
        case 'h3': {
          const Component = tagName === 'h1' ? 'h2' : tagName
          return (
            <Component id={domNode?.attribs?.id}>
              {domToReact(domNode?.children, options)}
            </Component>
          )
        }
        case 'audio':
          return <DomAudio domNode={domNode} />
        case 'video':
          return <DomVideo domNode={domNode} />

        case 'code':
          return (
            <code className="bg-surface-pressed text-content rounded px-1.5 py-0.5">
              {domToReact(domNode?.children, options)}
            </code>
          )
        case 'pre': {
          if (
            !domNode?.children?.length ||
            domNode?.children[0]?.name !== 'code' ||
            domNode?.children[0]?.children?.length !== 1 ||
            domNode?.children[0]?.children[0]?.type !== 'text'
          ) {
            return null
          }

          const detectedLanguage =
            domNode?.children[0]?.attribs?.class?.replace('language-', '')
          const content = domToReact(domNode?.children[0].children)

          return (
            <div className={clsx('code-wrapper relative')}>
              <Suspense
                fallback={
                  <pre>
                    <code>{content}</code>
                  </pre>
                }
              >
                <CodeBlock
                  language={detectedLanguage}
                  content={content}
                  copyButton
                />
              </Suspense>
            </div>
          )
        }

        case 'ol':
          return (
            <ol start={domNode?.attribs?.start}>
              {domToReact(domNode?.children, options)}
            </ol>
          )

        case 'ul':
          return <ul>{domToReact(domNode?.children, options)}</ul>

        case 'div':
          if (
            domNode.type === 'tag' &&
            domNode.attribs?.['data-type'] === ComposerModuleName.Embed
          ) {
            return (
              <EmbedRenderer
                domNode={domNode}
                embeds={embeds}
                context={context}
                hideUrl={hideUrl}
              />
            )
          }
          if (domNode.attribs?.['data-type'] === 'table-of-content') {
            return <TableOfContentRenderer content={value} />
          }
          break

        case 'attachment': {
          if (
            domNode.type === 'tag' &&
            domNode.attribs?.['data-type'] === ComposerModuleName.Attachment
          ) {
            const attachmentSlide = attachmentToDeferredSlide(domNode)
            const attachmentMediaIndex = pushSlide(attachmentSlide)
            return (
              <AttachmentRenderer
                domNode={domNode}
                attachments={attachments}
                mediaIndex={attachmentMediaIndex}
                onClick={openMediaModal}
              />
            )
          }
          break
        }

        case 'a':
          if (domNode.attribs?.['data-type'] === ComposerModuleName.Mention) {
            const mentionId = domNode.attribs['data-id']
            const mentionFromDb = mentions?.find?.(
              mention => mention.id === mentionId,
            )

            let str = mentionFromDb?.name
              ? `${mentionFromDb.name}`
              : domToReact(domNode?.children, options)?.toString?.()

            if (str?.[0] === '@') {
              str = str.replace('@', '')
            }
            return (
              <Link
                variant="accent"
                href={generateMemberPath({ id: mentionId })}
                target="blank"
                trusted
              >
                {str}
              </Link>
            )
          }
          isTrusted = !!trustedDomains?.find(
            trustedDomain =>
              trustedDomain === '*' ||
              domNode?.attribs?.href?.indexOf(`https://${trustedDomain}`) === 0,
          )

          return (
            <Link
              variant="accent"
              href={domNode?.attribs?.href}
              target="blank"
              // If isTrusted is false, let the Link component decide based on isInternal
              trusted={isTrusted || undefined}
            >
              {domToReact(domNode?.children, options)}
            </Link>
          )

        case 'iframe':
        case 'script':
          return <></>

        case 'table':
          return (
            <div className="overflow-x-auto">
              {domToReact(domNode?.children, options)}
            </div>
          )

        default:
          // If null is returned parser will render the html variant.
          return null
      }

      return null
    },
  }

  const Component = Parser(value, options)
  return <>{Component}</>
}
