import { clsx } from 'clsx'

import { Space } from '@tribeplatform/gql-client/types'
import { analyticsNumberFormatter } from '@tribeplatform/react-components/Analytics/utils'
import { T } from '@tribeplatform/react-components/i18n'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

interface Props {
  space: Space
  highlighted: boolean
  stretchX: string
  variant: StaticField['settings']['buttonVariant']
}
export const MembersCount = ({
  space,
  highlighted,
  stretchX,
  variant,
}: Props) => {
  const membersCount = space?.membersCount

  if (variant === 'icon') {
    return (
      <div
        className={clsx(
          'flex space-s-1 text-content-subdued items-center empty:hidden',
          'text-sm',
          highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
          stretchX,
        )}
      >
        <SvgIcon className="h-[1.25em] w-[1.25em]" name="member" />
        <span>{analyticsNumberFormatter(membersCount ?? '0')}</span>
      </div>
    )
  }

  return (
    <div
      className={clsx(
        'flex space-s-1 text-content-subdued items-center empty:hidden',
        'text-sm',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
    >
      {variant === 'iconText' && (
        <SvgIcon className="h-[1.25em] w-[1.25em]" name="member" />
      )}
      <span>
        <T
          defaultMessage="{members_count, plural, =0 {No {MEMBERS}} =1 {{members_count_text} {MEMBER}} other {{members_count_text} {MEMBERS}}}"
          id="SpacesListBlock.MembersCount"
          values={{
            members_count: membersCount ?? 0,
            members_count_text: analyticsNumberFormatter(membersCount ?? '0'),
          }}
        />
      </span>
    </div>
  )
}
