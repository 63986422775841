import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

type Value = object
type AdminPanelContextProps<T> = {
  sharedState: T
  setSharedState: (value: T) => void
  resetSharedState: () => void
}
const AdminPanelContext = createContext<AdminPanelContextProps<Value>>(null)

export const useAdminPanel = <T extends Value>(): AdminPanelContextProps<T> => {
  return useContext(AdminPanelContext) as AdminPanelContextProps<T>
}

interface AdminPanelProviderProps {
  children: React.ReactNode
}
export const AdminPanelProvider = ({ children }: AdminPanelProviderProps) => {
  const [sharedState, setSharedState] = useState<Value>({})
  const resetSharedState = useCallback(() => {
    setSharedState({})
  }, [])

  const value = useMemo(() => {
    return {
      sharedState,
      setSharedState: (newValue: Value) =>
        setSharedState(prevValue => ({ ...prevValue, ...newValue })),
      resetSharedState,
    }
  }, [resetSharedState, sharedState])

  return (
    <AdminPanelContext.Provider value={value}>
      {children}
    </AdminPanelContext.Provider>
  )
}
