import { ReactNode } from 'react'

import { VariantProps } from 'class-variance-authority'

import { helperTextStyles } from './HelperText.styles.js'

export type HelperTextProps = VariantProps<typeof helperTextStyles> & {
  className?: string
  children?: ReactNode
}
export const HelperText = ({
  status = 'neutral',
  highlighted = false,
  size,
  children,
  className,
}: HelperTextProps) => {
  return (
    <div
      className={helperTextStyles({
        size,
        status,
        highlighted,
        className,
      })}
    >
      {children}
    </div>
  )
}
