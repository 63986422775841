import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'
import { PostHeader } from '@tribeplatform/react-components/Post'

interface Props {
  post: Post
  highlighted: boolean
  isSinglePost: boolean
  showBackToSpaceButton: boolean
}
export const PostInfoBarField = ({
  post,
  highlighted,
  isSinglePost,
  showBackToSpaceButton,
}: Props) => {
  return (
    <div
      className={clsx(
        'basis-full min-w-0',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
      )}
    >
      <PostHeader
        post={post}
        context="post"
        showBackButton={isSinglePost ? showBackToSpaceButton : false}
        className="pb-0"
      />
    </div>
  )
}
