import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { LinkMenuBlock as Component } from './Block.js'
import { LinkMenuProps } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.LinkMenuBlockSettings })),
)

export const RegisteredLinkMenuBlock = (
  intl: I18nShape,
): RegisteredBlock<LinkMenuProps> => {
  const { $t } = intl

  return {
    recommendedName: 'LinkMenu',
    Component,
    config: {
      type: BlockType.Block,
      usable: true,

      displayName: $t({
        id: 'Blocks.LinkMenu.DisplayName',
        defaultMessage: 'Link menu',
      }),
      Settings,
      Icon: <SvgIcon name="bookmark" />,
      defaultProps: {
        items: [
          {
            text: $t({ id: 'Generics.Home', defaultMessage: 'Home' }),
            href: '/',
            image: {
              id: ':heart_eyes:',
              text: 'heart_eyes',
            },
          },
          {
            text: $t({
              id: 'Generics.Notifications',
              defaultMessage: 'Notifications',
            }),
            href: '/notifications',
            image: {
              id: ':yum:',
              text: 'yum',
            },
          },
          {
            text: $t({ id: 'Generics.Spaces', defaultMessage: '{SPACES_CC}' }),
            href: '/spaces',
            image: {
              id: ':yum:',
              text: 'yum',
            },
          },
        ],
      },
    },
  }
}
