import { clsx } from 'clsx'

import type { AppearanceStyle } from '@tribeplatform/design-system/types'
import {
  Emoji,
  Glyph,
  GlyphMediaVariant,
  Image,
  Media,
} from '@tribeplatform/gql-client/types'
import type { IconSize } from '@tribeplatform/react-ui-kit/Icon'
import {
  iconSizeStyles,
  iconStyles,
  SvgIcon,
  type SvgIconName,
} from '@tribeplatform/react-ui-kit/Icon'

import { IconEmoji } from './IconEmoji.js'
import { MediaGlyphEmoji } from './MediaGlyphEmoji.js'
import { MediaGlyphIcon } from './MediaGlyphIcon.js'

type WithImage = { image?: Media | null; name?: string }

const DefaultEmoji = '1F4AC' // Speech balloon

export const EntityImage = ({
  entity,
  size = 'md',
  defaultIcon,
  iconStyle,
  displayColor,
  as,
  className,
}: {
  entity: WithImage
  size?: IconSize
  defaultIcon?: SvgIconName
  iconStyle?: AppearanceStyle['icons']
  displayColor?: string
  as?: 'div' | 'span'
  className?: string
}) => {
  const Component = as || 'div'

  if ((entity?.image as Image)?.url) {
    const isLargeSize = ['2x', '3x', '4x', '6x', '8x'].includes(size)
    return (
      <Component
        className={clsx(
          'flex items-center justify-center shrink-0',
          iconStyles({ size }),
          className,
        )}
      >
        <img
          alt={entity.name}
          className={clsx(
            'text-content-subdued group-hover:text-content-hovered object-scale-down',
            'shrink-0',
            isLargeSize ? 'rounded-base' : 'rounded-base-sm',
            iconStyles({ size }),
          )}
          width={iconSizeStyles({ size })}
          height={iconSizeStyles({ size })}
          aria-hidden="true"
          src={
            isLargeSize
              ? (entity?.image as Image)?.urls?.small
              : (entity?.image as Image)?.urls?.thumb
          }
        />
      </Component>
    )
  }

  if ((entity.image as Glyph)?.variant) {
    const variant = (entity.image as Glyph)?.variant
    const text = (entity?.image as Glyph)?.text

    if (variant === GlyphMediaVariant.emoji) {
      return (
        <Component
          className={clsx(
            'flex items-center justify-center',
            iconStyles({ size }),
            className,
          )}
        >
          <MediaGlyphEmoji codepoint={text} size={size} />
        </Component>
      )
    }

    return (
      <Component
        className={clsx(
          'flex items-center justify-center',
          iconStyles({ size }),
          className,
        )}
        style={displayColor ? { color: displayColor } : {}}
      >
        <MediaGlyphIcon
          text={text}
          iconStyle={iconStyle}
          className="w-full h-full"
        />
      </Component>
    )
  }

  if ((entity.image as Emoji)?.text) {
    return (
      <Component
        className={clsx(
          'text-content-subdued group-hover:text-content-hovered',
          'flex items-center justify-center',
          iconStyles({ size }),
          className,
        )}
      >
        <IconEmoji
          text={(entity?.image as Emoji)?.text || DefaultEmoji}
          size={size}
        />
      </Component>
    )
  }

  return (
    <Component
      className={clsx(
        'text-content-subdued group-hover:text-content-hovered',
        'flex items-center justify-center',
        iconStyles({ size }),
        className,
      )}
    >
      {defaultIcon ? (
        <SvgIcon name={defaultIcon} size={size} />
      ) : (
        <MediaGlyphEmoji codepoint={DefaultEmoji} size={size} />
      )}
    </Component>
  )
}
