import type {
  Fields,
  Media,
  QueryMediaArgs,
} from '@tribeplatform/gql-client/types'

import {
  useQuery,
  type UseQueryOptions,
} from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getMediaKey } from '../../utils/keys/media.key.js'

export const useMedia = (options: {
  variables: QueryMediaArgs
  fields?: Fields.MediaFields
  useQueryOptions?: UseQueryOptions<Media>
}) => {
  const { variables, fields = 'basic', useQueryOptions } = options
  const { client } = useTribeClient()
  const exportRequestKey = getMediaKey({ variables, fields })

  return useQuery<Media>(
    exportRequestKey,
    () =>
      client.query({
        name: 'media',
        args: {
          fields,
          variables,
        },
      }),
    useQueryOptions,
  )
}
