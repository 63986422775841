import { useState } from 'react'

import { clsx } from 'clsx'
import InfiniteScroll from 'react-infinite-scroller'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { SpaceMember, SpaceRoleType } from '@tribeplatform/gql-client/types'
import {
  useRemoveSpaceMember,
  useSpace,
  useSpaceMembers,
  useSpaceRoles,
  useUpdateMemberSpaceRole,
} from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { confirm } from '@tribeplatform/react-ui-kit/Dialog'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { GridList } from '@tribeplatform/react-ui-kit/Layout'

import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { MemberListLoading } from './CommunityMemberList.js'
import { EmptyState } from './EmptyState.js'
import { FilterBar } from './FilterBar.js'
import { MemberCard } from './MemberCard.js'

export interface SpaceMemberListProps {
  spaceId: string
  className?: string
}

export const SpaceMemberList = ({
  spaceId,
  className,
  ...rest
}: SpaceMemberListProps) => {
  const { $t } = useI18n()
  const [filters, setFilters] = useState({})
  const { data: space } = useSpace({ variables: { id: spaceId } })
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isLoading,
    isFetchingNextPage,
  } = useSpaceMembers({
    fields: {
      member: {
        profilePicture: 'basic',
        banner: 'basic',
        role: 'basic',
        badges: 'default',
      },
      role: 'basic',
    },
    variables: { limit: 30, spaceId, ...filters },
    useInfiniteQueryOptions: {
      refetchOnMount: 'always',
    },
  })
  const { data: roles } = useSpaceRoles({
    variables: {
      spaceId,
    },
  })

  const { mutateAsync: removeSpaceMember } = useRemoveSpaceMember()
  const { mutateAsync: updateMemberSpaceRole } = useUpdateMemberSpaceRole()

  const [canUpdateMemberSpaceRole, canRemoveSpaceMembers] = hasScopesPermission(
    space,
    ['updateMemberSpaceRole', 'removeSpaceMembers'],
  )

  const adminRole = roles?.find(role => role.type === SpaceRoleType.admin)
  const memberRole = roles?.find(role => role.type === SpaceRoleType.member)
  const getOtherMemberRole = (spaceMember: SpaceMember) =>
    spaceMember?.role?.id === adminRole?.id ? memberRole : adminRole

  const getMemberCardActions = (spaceMember: SpaceMember) => {
    if (!(canUpdateMemberSpaceRole || canRemoveSpaceMembers)) {
      return null
    }
    const { member } = spaceMember
    const otherRole = getOtherMemberRole(spaceMember)
    return (
      <>
        {canRemoveSpaceMembers && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="member-remove" />}
            onClick={async () => {
              const confirmed = await confirm({
                title: $t(
                  {
                    id: 'Space.Members.Remove.Confirmation',
                    defaultMessage:
                      'Are you sure you want to remove {member_name} ({member_username}) from this {SPACE}?',
                  },
                  {
                    member_name: member?.name,
                    member_username: member?.username,
                  },
                ),
                proceedLabel: $t({
                  id: 'Generics.Remove',
                  defaultMessage: 'Remove',
                }),
                danger: true,
              })
              if (!confirmed) {
                return
              }
              await removeSpaceMember({
                spaceId,
                memberIds: [member?.id],
              })
            }}
          >
            <T id="Generics.RemoveMember" defaultMessage="Remove {MEMBER}" />
          </Dropdown.Item>
        )}
        {canUpdateMemberSpaceRole && (
          <Dropdown.Item
            leadingIcon={<SvgIcon name="shield" />}
            onClick={async () => {
              const confirmed = await confirm({
                title: $t(
                  {
                    id: 'Space.Members.ChangeRole.Confirmation',
                    defaultMessage:
                      'Are you sure you want to change role of {member_name} ({member_username}) to {role_name, select, admin {space admin} member {space member} other {space {role_name}}}?',
                  },
                  {
                    member_name: member?.name,
                    member_username: member?.username,
                    role_name: otherRole?.name?.toLowerCase(),
                  },
                ),
                proceedLabel: $t({
                  id: 'Space.Members.ChangeRole.Proceed',
                  defaultMessage: 'Change role',
                }),
              })
              if (!confirmed) {
                return
              }
              await updateMemberSpaceRole({
                memberId: member?.id,
                spaceId,
                input: {
                  roleId: otherRole?.id,
                },
              })
            }}
          >
            <T
              defaultMessage="Change role to {role_name, select, admin {space admin} member {space member} other {space {role_name}}}"
              id="Space.Members.ChangeRole"
              values={{
                role_name: getOtherMemberRole(spaceMember)?.name?.toLowerCase(),
              }}
            />
          </Dropdown.Item>
        )}
      </>
    )
  }

  const { nodes: members = [] } = simplifyPaginatedResult<SpaceMember>(data)
  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={fetchNextPage}
      hasMore={hasNextPage || false}
      threshold={800}
    >
      {isFetched && !members?.length && !isLoading ? (
        <EmptyState />
      ) : (
        <>
          {false && <FilterBar setFilters={setFilters} />}
          <GridList
            columns={4}
            className={clsx('items-stretch', className)}
            {...rest}
          >
            {members
              .filter(spaceMember => spaceMember?.member)
              .map(spaceMember => {
                const { member, role } = spaceMember
                return (
                  <GridList.Item key={member.id}>
                    <MemberCard
                      member={member}
                      extraBadge={
                        role?.type === SpaceRoleType.admin
                          ? $t({
                              id: 'Space.Members.SpaceAdmin',
                              defaultMessage: '{SPACE_CC} admin',
                            })
                          : null
                      }
                      actions={getMemberCardActions(spaceMember)}
                    />
                  </GridList.Item>
                )
              })}
            {!isFetched || isLoading || isFetchingNextPage ? (
              <MemberListLoading />
            ) : null}
          </GridList>
        </>
      )}
    </InfiniteScroll>
  )
}
