import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'
import { PostActionBar } from '@tribeplatform/react-components/Post'
import type {
  ActionVariants,
  StaticField,
} from '@tribeplatform/react-components/Views'

interface Props {
  post: Post
  highlighted: boolean
  stretchX: string
  buttonVariant: StaticField['settings']['buttonVariant']
  actionBar: StaticField['settings']['actionBar']
}
export const ActionBar = ({
  post,
  highlighted,
  stretchX,
  buttonVariant,
  actionBar,
}: Props) => {
  return (
    <div
      className={clsx(
        'flex space-s-2 text-content-subdued items-center min-w-0',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
    >
      <PostActionBar
        post={post}
        actionBar={actionBar}
        variant={buttonVariant as ActionVariants}
      />
    </div>
  )
}
