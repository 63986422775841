import { clsx } from 'clsx'

import { useI18n } from '@tribeplatform/react-components/i18n'
import { Card } from '@tribeplatform/react-ui-kit/Card'

import type { BC } from '../../types/block.types.js'
import { IframeProps } from './types.js'

export const IframeBlock: BC<IframeProps> = ({
  src,
  height,
  title,
  hidden,
  className,
  ...rest
}) => {
  const { $t } = useI18n()

  if (hidden) {
    return null
  }

  if (!src?.startsWith('https://')) {
    return (
      <Card className={clsx('overflow-hidden', className)} {...rest}>
        {title && (
          <div className="pb-4 sm:pb-6">
            <Card.Header title={title} size="sm" />
          </div>
        )}
        <div
          className="flex justify-center items-center"
          style={{ height: `${height}px` }}
        >
          <div className="px-4 py-5 sm:p-6">
            {$t({
              id: 'Blocks.Iframe.InvalidUrlError',
              defaultMessage:
                'Embed pages, documents, livestreams, and other interactive media in this block by entering a valid URL starting with “https://“',
            })}
          </div>
        </div>
      </Card>
    )
  }

  return (
    <Card className={clsx('overflow-hidden', className)} {...rest}>
      {title && (
        <div className="pb-4 sm:pb-6">
          <Card.Header title={title} size="sm" />
        </div>
      )}
      <div>
        <iframe
          title={$t({ id: 'Generics.Block', defaultMessage: 'Block' })}
          src={src}
          className={clsx(
            'border-none w-full',
            'transition-opacity duration-300',
          )}
          style={{ height: `${height}px` }}
        ></iframe>
      </div>
    </Card>
  )
}
