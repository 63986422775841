import { useMemo } from 'react'

import type { Role, RoleType } from '@tribeplatform/gql-client/types'

import { useNetwork } from '../network/useNetwork.js'

export const useNetworkRoles = () => {
  const {
    data: { roles },
  } = useNetwork()

  const networkRoles = useMemo(() => {
    if (!roles) {
      return undefined
    }

    return roles.reduce(
      (dict, role) => ({
        ...dict,
        [role.type]: role,
      }),
      {},
    ) as { [x in RoleType]: Role }
  }, [roles])

  return networkRoles
}
