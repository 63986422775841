import { indicatorBadgeStyles } from './IndicatorBadge.styles.js'
import { IndicatorBadgeStyleProps } from './types.js'
import { formatCount } from './utils.js'

export type IndicatorBadgeProps = {
  /**
   * Number to show in badge
   */
  count?: number
  /**
   * Max count to show
   * @default 99
   */
  max?: number
  /**
   * Whether to show badge when count is zero
   * @default false
   */
  showZero?: boolean
  className?: string
} & IndicatorBadgeStyleProps

/**
 * IndicatorBadge is a visual indicator for numeric values such as tallies and scores.
 */
export const IndicatorBadge = ({
  count,
  max = 99,
  showZero = false,
  variant = 'danger',
  size = 'md',
  className,
  ...rest
}: IndicatorBadgeProps) => {
  if (count === undefined || count === null) {
    return null
  }

  if (!showZero && count <= 0) {
    return null
  }

  return (
    <div
      className={indicatorBadgeStyles({ variant, size, className })}
      {...rest}
    >
      <span className="m-0.5 scale-90">{formatCount(count, max)}</span>
    </div>
  )
}
