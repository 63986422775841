import { CustomFieldType } from '@tribeplatform/gql-client/types'

import { CustomFieldSubtype } from '../../CustomField/utils.js'
import {
  ListFilterByOperator,
  type Filter,
  type LegacyFilter,
} from '../types.js'

export const sanitizeFilterOperators = (filters: Filter[]): Filter[] => {
  // For Select and Array types we only use 'in' and 'nin' since the others are redundant
  return filters.map(filter => {
    const { type, subType, operator, value } = filter
    if (
      type === CustomFieldType.text &&
      subType === CustomFieldSubtype.SELECT
    ) {
      if (operator === ListFilterByOperator.equals) {
        return {
          ...filter,
          operator: ListFilterByOperator.in,
          value: [value as string],
        }
      }

      if (operator === ListFilterByOperator.not) {
        return {
          ...filter,
          operator: ListFilterByOperator.nin,
          value: [value as string],
        }
      }
    }

    if (type === CustomFieldType.array) {
      if (operator === ListFilterByOperator.contains) {
        return {
          ...filter,
          operator: ListFilterByOperator.in,
          value: [value as string],
        }
      }
    }

    if (type === CustomFieldType.relation) {
      if (operator === ListFilterByOperator.equals) {
        return {
          ...filter,
          operator: ListFilterByOperator.in,
          value: [value as string],
        }
      }
    }

    return filter
  })
}

export const sanitizeLegacyFilters = (legacyFilters: LegacyFilter[]) => {
  return legacyFilters
    .filter(legacyFilter => {
      const key = legacyFilter?.key
      const keyString = legacyFilter?.keyString
      const operator = legacyFilter?.operator
      const value = legacyFilter?.value

      if (!key || !keyString || !operator || !value) {
        return false
      }

      return true
    })
    .map<LegacyFilter>(({ key, keyString, operator, value }) => ({
      key,
      keyString,
      operator,
      value,
    }))
}
