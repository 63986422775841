import { forwardRef, ReactNode } from 'react'

import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

import { TrapFocus } from '../../TrapFocus/index.js'
import { ModalSize } from '../types.js'

export interface ModalPanelProps {
  size?: ModalSize
  children?: ReactNode
  className?: string
  zenMode?: boolean
}

export const ModalPanel = forwardRef<HTMLDivElement, ModalPanelProps>(
  ({ children, size = 'lg', className, zenMode = false, ...rest }, ref) => {
    const widthClsx = [
      'w-full',
      !zenMode && [
        size === 'md' && 'sm:max-w-md',
        size === 'lg' && 'sm:max-w-lg',
        size === 'xl' && 'sm:max-w-xl',
        size === '2xl' && 'sm:max-w-2xl',
        size === '3xl' && 'sm:max-w-3xl',
        size === '5xl' && 'sm:max-w-5xl',
        size === 'fit' && 'sm:max-w-fit',
        size === 'max' && 'sm:max-w-[90vw]',
        size === 'full' && 'sm:w-full',
      ],
    ]
    const heightClsx = [
      'max-h-screen',
      zenMode ? 'min-h-screen' : 'min-h-screen sm:min-h-0',
      size === 'max' && 'h-[90vh]',
      size === 'full' && 'h-full',
      size !== 'max' && size !== 'full' && 'h-full sm:max-h-[calc(100vh-2rem)]',
    ]

    return (
      <TrapFocus>
        <div
          ref={ref}
          role="dialog"
          aria-modal
          className={twMerge(
            clsx(
              'relative pointer-events-auto',
              'bg-surface text-content-subdued',
              !zenMode && 'sm:rounded-modal',
              'overflow-y-auto overscroll-contain',
              'flex flex-col mx-auto',
              widthClsx,
              heightClsx,
              'shadow-xl transform transition-all',
              className,
            ),
          )}
          {...rest}
        >
          {children}
        </div>
      </TrapFocus>
    )
  },
)
