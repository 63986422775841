import { createContext } from 'react'

import { ComposerContextType } from './@types/index.js'

export const ComposerContext = createContext<ComposerContextType>({
  withProvider: false,
  isTouched: false,
  editor: null,
  state: null,
  toggleDialog: null,
  onFileUpload: null,
  onSlashMenuItemClick: null,
})
