import { ReactNode, useMemo } from 'react'

import { clsx } from 'clsx'

import type { Image } from '@tribeplatform/gql-client/types'

import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { MediaModalBoundary } from '../../MediaModal/MediaModalBoundary.js'
import { useMediaModal } from '../../MediaModal/MediaModalProvider.js'
import { BannerContent } from './BannerContent.js'

type BannerProps = {
  banner: Image
  fallback?: ReactNode
  zoom?: boolean
  title?: ReactNode
  className?: string
}

export const Banner = ({
  banner,
  zoom = false,
  title,
  fallback,
  className,
}: BannerProps) => {
  if (!banner) {
    return (
      <div className={clsx('w-full overflow-hidden', className)}>
        {fallback}
      </div>
    )
  }

  if (!zoom) {
    return <BannerContent banner={banner} className={className} />
  }

  return (
    <MediaModalBoundary
      allowDownload={false}
      title={title}
      /**
       * Adding banner.id as key will force the media boundary to re-render component and
       * clear its slides when user uploads a new banner image.
       * Otherwise the modal context will retain previous images and include the in the modal.
       */
      key={banner.id}
    >
      <ZoomableBanner banner={banner} className={className} />
    </MediaModalBoundary>
  )
}

const ZoomableBanner = ({
  banner,
  className,
}: Pick<BannerProps, 'banner' | 'className'>) => {
  const { $t } = useI18n()
  const { pushSlide, openSlideWithIndex } = useMediaModal()
  const slideIndex = useMemo(() => {
    return pushSlide(banner)
  }, [pushSlide, banner])
  return (
    <button
      type="button"
      onClick={() => openSlideWithIndex(slideIndex)}
      className="w-full block"
      aria-label={$t({ id: 'Generics.Cover', defaultMessage: 'Cover' })}
    >
      <BannerContent banner={banner} className={className} />
    </button>
  )
}
