import { useRef, useState, useEffect } from 'react'

import { Space } from '@tribeplatform/gql-client/types'
import { useSpacesByIds } from '@tribeplatform/react-sdk/hooks'

import { useI18n } from '../i18n/index.js'
import { SpacePicker } from './SpacePicker.js'

export interface SpaceIdPickerProps {
  value: string[]
  onChange: (spaceIds: string[]) => void
  multiple?: boolean
  placeholder?: string
  noWrapper?: boolean
  invalid?: boolean
  className?: string
}

export const SpaceIdPicker = ({
  value,
  onChange,
  multiple = false,
  placeholder,
  noWrapper,
  invalid,
  className,
}: SpaceIdPickerProps) => {
  const { $t } = useI18n()
  const [spaces, setSpaces] = useState<Space[]>([])
  const didLoadInitialData = useRef(value.length === 0)
  const { data: initialSpaces, isInitialLoading } = useSpacesByIds({
    variables: { ids: value },
    fields: {
      image: 'basic',
    },
    useQueryOptions: {
      enabled: value.length > 0 && !didLoadInitialData.current,
      placeholderData: undefined,
    },
  })

  useEffect(() => {
    if (!didLoadInitialData.current && initialSpaces) {
      setSpaces(initialSpaces)
      didLoadInitialData.current = true
    }
  }, [initialSpaces])

  useEffect(() => {
    if (value.length === 0) {
      setSpaces([])
    }
  }, [value])

  const getPlaceHolder = () => {
    if (isInitialLoading || !spaces) {
      return $t({
        id: 'Generics.LoadingDotDotDot',
        defaultMessage: 'Loading...',
      })
    }

    return placeholder
  }

  const handleChanges = (spaces: Space[]) => {
    setSpaces(spaces)
    onChange(spaces.map(space => space.id))
  }

  return (
    <SpacePicker
      value={spaces}
      onChange={handleChanges}
      multiple={multiple}
      disabled={isInitialLoading}
      placeholder={getPlaceHolder()}
      noWrapper={noWrapper}
      className={className}
      invalid={invalid}
    />
  )
}
