import { SystemSpaceSlug } from '@bettermode/common/space'
import { NetworkVisibility } from '@tribeplatform/gql-client/types'
import { useCMS } from '@tribeplatform/react-components/CMS'
import {
  useAuthMember,
  useAuthToken,
  useSpaceUtil,
} from '@tribeplatform/react-sdk/hooks'
import { noop } from '@tribeplatform/react-ui-kit/hooks'
import { convertNetworkToAppearance } from '@tribeplatform/react-ui-kit/ThemeProvider'

import { CreatePostButton } from '../Actions/CreatePostButton.js'
import { LanguageSwitchButton } from '../Actions/LanguageSwitchButton.js'
import { MainMenuButton } from '../Actions/MainMenuButton.js'
import { MessagesButton } from '../Actions/MessagesButton.js'
import { NotificationsButton } from '../Actions/NotificationsButton.js'
import { SearchButton } from '../Actions/SearchButton.js'
import { ThemeSwitchButton } from '../Actions/ThemeSwitchButton.js'
import { useLanguageSwitch } from '../hooks/useLanguageSwitch.js'
import { ActionItemId } from '../types.js'

interface Props {
  id: ActionItemId
  label: string
}
export const ActionItem = ({ id, label }: Props) => {
  const { hasViewPermission } = useSpaceUtil()
  const {
    data: { network, networkPublicInfo },
  } = useAuthToken()
  const { isGuest } = useAuthMember()
  const { items: availableLocales } = useLanguageSwitch()
  const hasMultipleLanguages = availableLocales.length > 1
  const privateMessagingEnabled =
    network?.settings?.privateMessaging?.privateMessagingEnabled
  const isNetworkPrivate =
    networkPublicInfo?.visibility === NetworkVisibility.private
  const appearance = convertNetworkToAppearance({
    oldColors: network?.themes?.active?.tokens?.colors,
    networkThemeLightColors: network?.activeTheme?.colors?.light,
    networkThemeDarkColors: network?.activeTheme?.colors?.dark,
    networkThemeTypography: network?.activeTheme?.typography,
  })
  const canSwitchTheme =
    appearance.networkColorScheme.mode === 'user-preference'
  const { composablePostTypes } = useCMS()

  switch (id) {
    case ActionItemId.mainMenu: {
      if (!hasViewPermission(SystemSpaceSlug.MEMBER) || isGuest) {
        return null
      }

      return <MainMenuButton label={label} />
    }
    case ActionItemId.createEntity: {
      if (composablePostTypes.length === 0) {
        return null
      }

      return <CreatePostButton />
    }
    case ActionItemId.notifications: {
      if (!hasViewPermission(SystemSpaceSlug.NOTIFICATIONS)) {
        return null
      }

      return <NotificationsButton label={label} />
    }
    case ActionItemId.messages: {
      if (!privateMessagingEnabled || isGuest) {
        return null
      }

      return <MessagesButton label={label} />
    }
    case ActionItemId.search: {
      if (isNetworkPrivate && isGuest) {
        return null
      }

      return <SearchButton />
    }
    case ActionItemId.themeSwitch: {
      if (!canSwitchTheme) {
        return null
      }

      return <ThemeSwitchButton />
    }
    case ActionItemId.languageSwitch: {
      if (!hasMultipleLanguages || isGuest) {
        return null
      }

      return <LanguageSwitchButton label={label} />
    }
    default: {
      // Code should never reach the default case
      const exhaustiveCheck: never = id
      noop(exhaustiveCheck)
      return null
    }
  }
}
