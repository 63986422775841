import {
  SpaceJoinRequestStatus,
  SpaceMembershipStatus,
} from '@tribeplatform/gql-client/types'
import { T, useI18n } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { ActionButtonRenderer } from './ActionButtonRenderer.js'
import { getButtonProps, type Permissions } from './utils/buttonUtils.js'

interface Props {
  className?: string
  fullWidth?: boolean
  showText?: boolean
  showIcon?: boolean
  loading?: boolean
  status?: SpaceMembershipStatus | SpaceJoinRequestStatus
  permission?: Permissions
  onClick?: () => void
}

export const JoinButton = ({
  className,
  fullWidth = false,
  showText = true,
  showIcon = true,
  status,
  permission,
  loading,
  onClick,
}: Props) => {
  const intl = useI18n()
  const btnProps = getButtonProps(intl, status, permission)

  const { icon, disabled, label } = btnProps ?? {}

  return (
    <ActionButtonRenderer
      className={className}
      showText={showText}
      showIcon={showIcon}
      disabled={disabled}
      loading={loading}
      fullWidth={fullWidth}
      variant="secondaryNeutral"
      icon={icon || <SvgIcon name="check-circle-broken" size="xl" />}
      label={label ?? <T defaultMessage="Join" id="Generics.Join" />}
      tooltip={
        <T
          defaultMessage="Join to become a member"
          id="Blocks.SpaceHeader.JoinButton.Tooltip"
        />
      }
      onClick={onClick}
    />
  )
}
