import type {
  ClientError,
  ClientFileErrorHandler,
} from '@tribeplatform/gql-client/lib'
import type { Image, UploadImagesArgs } from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useTribeClient } from '../../useTribeClient.js'

export const useCreateImages = (options?: {
  useMutationOptions?: Omit<
    UseMutationOptions<Image[], unknown, Array<UploadImagesArgs>>,
    'onError'
  > & {
    onError: ClientFileErrorHandler
  }
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()

  return useMutation<Image[], ClientError, UploadImagesArgs[]>(
    (input: UploadImagesArgs[]) => {
      return client.media.uploadImages(input, useMutationOptions?.onError)
    },
    {
      ...useMutationOptions,
    },
  )
}
