export const iconNames = [
  'apple-monochrome',
  'apple',
  'discord-monochrome',
  'discord',
  'facebook-monochrome',
  'facebook',
  'figma-monochrome',
  'giphy-monochrome',
  'github-monochrome',
  'google-drive-monochrome',
  'google-monochrome',
  'google',
  'instagram-monochrome',
  'linked-in-monochrome',
  'linked-in',
  'mail-monochrome',
  'mastodon-monochrome',
  'medium-monochrome',
  'reddit-monochrome',
  'slack-monochrome',
  'slack',
  'snapchat-monochrome',
  'stack-overflow-monochrome',
  'telegram-monochrome',
  'twitter-monochrome',
  'twitter',
  'whatsapp-monochrome',
  'youtube-monochrome',
] as const
export type IconName = (typeof iconNames)[number]
