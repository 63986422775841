import { ReactNode } from 'react'

import { clsx } from 'clsx'

import { useBackgroundContext } from '../BackgroundContext/index.js'
import { Text } from '../Text/index.js'

const Title = ({ children }) => {
  const { backgroundType } = useBackgroundContext()

  return (
    <Text
      as="h2"
      variant="headingLg"
      truncate
      onBackground={backgroundType === 'main'}
    >
      {children}
    </Text>
  )
}

const Description = ({ children }) => {
  const { backgroundType } = useBackgroundContext()

  return (
    <Text
      as="div"
      className="mt-2 flex items-center"
      color="subdued"
      onBackground={backgroundType === 'main'}
    >
      {children}
    </Text>
  )
}

const Simple = ({ title }) => {
  return (
    <div className="flex-1 min-w-0 mb-5">
      <Title>{title}</Title>
    </div>
  )
}

const WithDescription = ({ title, description }) => {
  return (
    <div className="flex-1 min-w-0 mb-5">
      <Title>{title}</Title>
      <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-s-6">
        <Description>{description}</Description>
      </div>
    </div>
  )
}

const WithAction = ({ title, action }) => {
  return (
    <div className="md:flex md:items-center md:justify-between mb-5">
      <div className="flex-1 min-w-0">
        <Title>{title}</Title>
      </div>
      <div className="mt-4 flex md:mt-0 md:ms-4 space-s-3">{action}</div>
    </div>
  )
}

const WithDescriptionAndAction = ({ title, description, action }) => {
  return (
    <div className={clsx('lg:flex lg:items-center lg:justify-between mb-5')}>
      <div className="flex-1 min-w-0">
        <Title>{title}</Title>
        <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-s-6">
          <Description>{description}</Description>
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ms-4 space-s-3">{action}</div>
    </div>
  )
}

const WithBannerImage = props => {
  const {
    title,
    secondaryTitle,
    backgroundImage,
    backgroundColor,
    avatar,
    action,
    description,
  } = props

  const { backgroundType } = useBackgroundContext()

  let background = <div className="h-16 w-full object-cover lg:h-24" />
  if (backgroundImage) {
    if (typeof backgroundImage === 'string') {
      background = (
        <img
          className="h-32 w-full object-cover lg:h-48"
          src={backgroundImage}
          alt=""
        />
      )
    } else {
      background = backgroundImage || background
    }
  }

  const titleAndSecondaryTitle = (
    <div className="flex items-center gap-2 flex-wrap">
      <Text
        as="h1"
        variant="headingSm"
        truncate
        className="shrink-0 max-w-full"
        onBackground={backgroundType === 'main'}
      >
        {title}
      </Text>
      {secondaryTitle && (
        <div className="text-heading-xs">{secondaryTitle}</div>
      )}
    </div>
  )

  return (
    <div>
      <div
        className="bg-background sm:rounded-t-card"
        style={{ background: backgroundColor }}
      >
        {background}
      </div>
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        {avatar ? (
          <div className="sm:flex sm:items-end sm:space-s-5 mb-5">
            <div className="flex -mt-12 sm:-mt-16 relative">
              {typeof avatar === 'string' ? (
                <img
                  className="h-24 w-24 rounded-full ring-4 ring-surface ring-offset-surface sm:h-32 sm:w-32 ms-4 sm:ms-0"
                  src={avatar}
                  alt={title}
                />
              ) : (
                <div className="h-24 w-24 flex items-center justify-center sm:h-32 sm:w-32 ms-4 sm:ms-0">
                  {avatar}
                </div>
              )}
            </div>
            <div className="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-s-6 sm:pb-1 mt-5">
              <div className="min-w-0 flex-1 truncate">
                {titleAndSecondaryTitle}
                {description && (
                  <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-s-6">
                    <Description>{description}</Description>
                  </div>
                )}
              </div>
              <div className="flex flex-row space-y-0 space-s-2 mt-3 sm:mt-0">
                {action}
              </div>
            </div>
          </div>
        ) : (
          <div className="my-4 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-s-6 sm:pb-1">
            <div className="mb-2 sm:mb-0 min-w-0 flex-1 truncate">
              {titleAndSecondaryTitle}
            </div>
            <div className="flex flex-row space-y-0 space-s-2 mt-3 sm:mt-0">
              {action}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const WithImage = props => {
  const { title, avatar, action, description } = props

  return (
    <div className="w-full bg-surface mb-5 sm:rounded-lg shadow px-6 py-6">
      <div className="max-w-5xl m-auto overflow-hidden">
        <div className="sm:flex sm:items-center sm:space-s-5">
          <div className="h-24 w-24 flex items-center justify-center shrink-0">
            {avatar}
          </div>
          <div className="min-w-0 flex-grow">
            <Text as="h1" variant="headingSm" truncate>
              {title}
            </Text>
            {description && (
              <div className="flex-row flex-wrap">
                <Text as="div" className="mt-2 items-center line-clamp-2">
                  {description}
                </Text>
              </div>
            )}
          </div>
          <div className="flex flex-row space-y-0 space-s-2 mt-3 sm:mt-0 flex-shrink-0">
            {action}
          </div>
        </div>
      </div>
    </div>
  )
}

export type PageHeaderProps = {
  title: ReactNode
  secondaryTitle?: ReactNode
  description?: ReactNode
  action?: ReactNode
} & (
  | {
      children?: ReactNode
      padding?: 'none' | 'md'
    }
  | {
      backgroundColor: string
      backgroundImage: string | ReactNode
      avatar: string | ReactNode
    }
)

export const PageHeader = (props: PageHeaderProps) => {
  if ('backgroundImage' in props) {
    const {
      title,
      secondaryTitle,
      action,
      backgroundImage,
      backgroundColor,
      avatar,
      description,
    } = props

    return (
      <WithBannerImage
        title={title}
        secondaryTitle={secondaryTitle}
        description={description}
        backgroundColor={backgroundColor}
        backgroundImage={backgroundImage}
        avatar={avatar}
        action={action}
      />
    )
  }

  if ('avatar' in props) {
    const { title, secondaryTitle, action, avatar, description } = props

    return (
      <WithImage
        title={title}
        secondaryTitle={secondaryTitle}
        description={description}
        avatar={avatar}
        action={action}
      />
    )
  }

  const { title, description, action, children, padding = 'md' } = props

  let content
  if (!!title && !!description && !!action) {
    content = (
      <WithDescriptionAndAction
        title={title}
        description={description}
        action={action}
      />
    )
  } else if (action) {
    content = <WithAction title={title} action={action} />
  } else if (description) {
    content = <WithDescription title={title} description={description} />
  } else if (title) {
    content = <Simple title={title} />
  } else {
    content = children
  }

  const paddingClsx = [padding === 'md' && 'px-4 sm:px-0']

  return <div className={clsx(paddingClsx)}>{content}</div>
}
