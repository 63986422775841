import { useCallback, useEffect, useRef } from 'react'

import { useDocument } from '../hooks/useDocument.js'

export function useKeypressListener(
  code: string,
  handler: (event: KeyboardEvent) => void,
  actionType: 'keyup' | 'keydown' = 'keyup',
) {
  const { document } = useDocument()
  const tracked = useRef({ handler, keyCode: code })

  useEffect(() => {
    tracked.current = { handler, keyCode: code }
  }, [handler, code])

  const handleKeyEvent = useCallback((event: KeyboardEvent) => {
    const { handler, keyCode } = tracked.current
    if (event.code === keyCode) {
      handler(event)
    }
  }, [])

  useEffect(() => {
    document.addEventListener(actionType, handleKeyEvent)
    return () => {
      document.removeEventListener(actionType, handleKeyEvent)
    }
  }, [actionType, document, handleKeyEvent])

  return null
}
