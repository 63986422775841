import type { ReactNode } from 'react'

import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

interface Props {
  operatorName: string
  children: ReactNode
}
export const GenericOperatorPicker = ({ operatorName, children }: Props) => {
  return (
    <Dropdown>
      <Dropdown.ButtonMinimal>
        <span className="inline-flex items-center hover:text-interactive-hovered text-content">
          {operatorName}
          <SvgIcon className="w-4 h-4" name="chevron-down" />
        </span>
      </Dropdown.ButtonMinimal>
      <Dropdown.Items noWrapper className="absolute z-20">
        {children}
      </Dropdown.Items>
    </Dropdown>
  )
}
