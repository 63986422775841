import { SpaceListOrderByEnum } from '@tribeplatform/gql-client/types'
import type { I18nShape } from '@tribeplatform/react-components/i18n'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { StaticFieldTypes } from '@tribeplatform/react-components/Views'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { getAssetPath } from '../../../../configs/static.js'
import type { SpacesListBlockProps, SpacesListBlockView } from './types.js'

export const genericViewFactory: (intl: I18nShape) => SpacesListBlockView = ({
  $t,
}) => ({
  id: '',
  collectionId: null,
  name: $t({
    defaultMessage: 'Default',
    id: 'Generics.Default',
  }),
  filterBy: 'collectionId',
  sort: SpaceListOrderByEnum.CREATED_AT,
  reverseSort: true,
  style: 'list',
  gallerySize: 'medium',
  showDivider: true,
  limit: 10,
  fields: [],
  showMore: false,
})

export const staticFieldsFactory: (intl: I18nShape) => StaticField[] = ({
  $t,
}) => [
  {
    id: 'galleryBanner',
    label: $t({
      defaultMessage: 'Banner',
      id: 'Generics.Banner',
    }),
    enabled: true,
    locked: false,
    styles: ['gallery', 'carousel'],
    type: StaticFieldTypes.banner,
    settings: {
      bannerStyle: 'banner',
      bannerSource: 'banner',
      aspectRatio: 'aspect-video',
    },
    isStatic: true,
    icon: <SvgIcon name="image" />,
  },
  {
    id: 'cardBanner',
    label: $t({
      defaultMessage: 'Banner',
      id: 'Generics.Banner',
    }),
    enabled: false,
    locked: true,
    styles: ['card'],
    type: StaticFieldTypes.banner,
    settings: {
      bannerStyle: 'banner',
      bannerSource: 'banner',
      aspectRatio: 'aspect-[4/1]',
    },
    isStatic: true,
    icon: <SvgIcon name="image" />,
    default: getAssetPath('images/banners/banner-placeholder.jpg'),
  },
  {
    id: 'listImage',
    label: $t({
      defaultMessage: 'Image',
      id: 'Generics.Image',
    }),
    enabled: true,
    locked: false,
    styles: ['list'],
    type: StaticFieldTypes.avatar,
    settings: {
      avatarSize: 'lg',
      bannerSource: 'image',
    },
    isStatic: true,
    icon: <SvgIcon name="member" />,
  },
  {
    id: 'listSpaceSummary',
    label: $t({
      defaultMessage: '{SPACE_CC} summary',
      id: 'SpacesListBlocks.FieldLabels.SpaceSummary',
    }),
    enabled: true,
    locked: false,
    styles: ['list'],
    settings: {
      stretchX: true,
    },
    isStatic: true,
    icon: <SvgIcon name="posts" />,
    fields: [
      {
        id: 'listSpaceName',
        label: $t({
          defaultMessage: 'Name',
          id: 'Generics.Name.Noun',
        }),
        enabled: true,
        locked: false,
        type: StaticFieldTypes.title,
        settings: {
          titleSize: 'sm',
        },
        isStatic: true,
        icon: <SvgIcon name="field" />,
        default: 'Lorem sunt excepteur aliquip',
      },
      {
        id: 'listSpaceDescription',
        label: $t({
          defaultMessage: 'Description',
          id: 'Generics.Description',
        }),
        enabled: true,
        locked: false,
        settings: {},
        isStatic: true,
        icon: <SvgIcon name="field-text" />,
        default:
          'Commodo magna esse ea in in. Minim occaecat aute veniam pariatur laboris veniam.',
      },
    ],
  },
  {
    id: 'gallerySpaceName',
    label: $t({
      defaultMessage: 'Name',
      id: 'Generics.Name.Noun',
    }),
    enabled: true,
    locked: false,
    styles: ['gallery', 'carousel', 'card'],
    type: StaticFieldTypes.title,
    settings: {
      fullWidth: true,
      titleSize: 'sm',
    },
    isStatic: true,
    icon: <SvgIcon name="field" />,
    default: 'Lorem sunt excepteur aliquip',
  },
  {
    id: 'gallerySpaceDescription',
    label: $t({
      defaultMessage: 'Description',
      id: 'Generics.Description',
    }),
    enabled: true,
    locked: false,
    styles: ['gallery', 'carousel'],
    settings: {
      fullWidth: true,
    },
    isStatic: true,
    icon: <SvgIcon name="field-text" />,
    default:
      'Commodo magna esse ea in in. Minim occaecat aute veniam pariatur laboris veniam.',
  },
  {
    id: 'cardSpaceDescription',
    label: $t({
      defaultMessage: 'Description',
      id: 'Generics.Description',
    }),
    enabled: true,
    locked: false,
    styles: ['card'],
    settings: {
      fullWidth: true,
      displayStyle: 'truncated',
    },
    isStatic: true,
    icon: <SvgIcon name="field-text" />,
    default:
      'Commodo magna esse ea in in. Minim occaecat aute veniam pariatur laboris veniam.',
  },
  {
    id: 'membersCount',
    label: $t({
      defaultMessage: 'Members count',
      id: 'Generics.MembersCount',
    }),
    type: StaticFieldTypes.info,
    enabled: false,
    locked: false,
    settings: { buttonVariant: 'iconText' },
    isStatic: true,
    icon: <SvgIcon name="member" />,
  },
  {
    id: 'postsCount',
    label: $t({
      defaultMessage: 'Posts count',
      id: 'Generics.PostsCount',
    }),
    type: StaticFieldTypes.info,
    enabled: false,
    locked: false,
    settings: { buttonVariant: 'iconText' },
    isStatic: true,
    icon: <SvgIcon name="post" />,
  },
  {
    id: 'joinButton',
    label: $t({
      defaultMessage: 'Join button',
      id: 'SpacesListBlocks.FieldLabels.JoinButton',
    }),
    type: StaticFieldTypes.button,
    enabled: false,
    locked: false,
    settings: { buttonSize: 'lg' },
    isStatic: true,
    icon: <SvgIcon name="bell" />,
  },
]

export const SpacesListConfig: (intl: I18nShape) => {
  defaultProps: SpacesListBlockProps
  displayName: string
  removable: boolean
  Icon: JSX.Element
} = ({ $t }) => ({
  defaultProps: {
    views: [],
    title: '',
    description: '',
    linkText: '',
    linkUrl: '',
  },
  displayName: $t({
    defaultMessage: 'Spaces',
    id: 'Blocks.SpacesList.DisplayName',
  }),
  removable: true,
  Icon: <SvgIcon name="grid" />,
})
