import { useCallback, useMemo, useState } from 'react'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import {
  SpaceJoinRequestStatus,
  SpaceMembershipStatus,
} from '@tribeplatform/gql-client/types'
import { useLogin } from '@tribeplatform/react-components/common/hooks'
import { useI18n } from '@tribeplatform/react-components/i18n'
import {
  useJoinSpace as useJoinSpaceMutation,
  useLeaveSpace,
  useMemberSpaceMembershipRequest,
  useRequestSpaceMembership,
  useSpace,
} from '@tribeplatform/react-sdk/hooks'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import type { Permissions } from '../Components/ActionButtons/utils/buttonUtils.js'

interface UseSubscribeSpaceReturnType {
  leaveSpace: () => void
  joinSpace: () => void
  leaving: boolean
  joining: boolean
  permissions: Permissions
  status: SpaceMembershipStatus | SpaceJoinRequestStatus
  joined: boolean
}

interface Props {
  spaceId?: string
}

export const useJoinSpace = ({
  spaceId,
}: Props): UseSubscribeSpaceReturnType => {
  const { $t } = useI18n()
  const { data: space } = useSpace({
    variables: {
      id: spaceId,
    },
    useQueryOptions: {
      enabled: !!spaceId,
    },
  })
  const { mutate: leave, isLoading: leaving } = useLeaveSpace()
  const { mutate: join, isLoading: joining } = useJoinSpaceMutation()
  const { mutate: requestSpaceMembership } = useRequestSpaceMembership()
  const { isLoggedIn, showLogin } = useLogin()
  const { data: spaceMembershipRequests } = useMemberSpaceMembershipRequest({
    variables: {
      status: SpaceJoinRequestStatus.PENDING,
    },
    useQueryOptions: {
      refetchOnMount: 'always',
    },
  })
  const [canJoinSpace, canRequestSpaceMembership] = hasScopesPermission(space, [
    'joinSpace',
    'requestSpaceMembership',
  ])
  const [optimisticJoined, setOptimisticJoined] = useState<boolean>(
    space?.authMemberProps?.membershipStatus === SpaceMembershipStatus.joined,
  )
  const spaceJoinRequest = useMemo(
    () => spaceMembershipRequests?.find(({ id }) => id === spaceId),
    [spaceMembershipRequests, spaceId],
  )
  const { membershipStatus } = space?.authMemberProps || {}
  const status = useMemo(
    () =>
      spaceJoinRequest?.status ||
      membershipStatus ||
      SpaceMembershipStatus.notJoined,
    [spaceJoinRequest, membershipStatus],
  )
  const permissions = useMemo(
    () => ({ canJoinSpace, canRequestSpaceMembership, isLoggedIn }),
    [canJoinSpace, canRequestSpaceMembership, isLoggedIn],
  )

  const leaveSpace = useCallback(() => {
    setOptimisticJoined(false)
    leave(
      {
        spaceId,
      },
      {
        onSuccess: () => setOptimisticJoined(false),
        onError: () => setOptimisticJoined(true),
      },
    )
  }, [leave, spaceId])

  const joinSpace = useCallback(() => {
    if (!isLoggedIn) {
      showLogin()
      return
    }

    if (membershipStatus === SpaceMembershipStatus.notJoined) {
      setOptimisticJoined(true)
      if (canJoinSpace) {
        join(
          { spaceId },
          {
            onSuccess: () => setOptimisticJoined(true),
            onError: () => setOptimisticJoined(false),
          },
        )
      } else {
        requestSpaceMembership(
          { spaceId },
          {
            onError: () => {
              toast({
                title: $t({
                  defaultMessage: 'Cannot request to join',
                  id: 'Space.MembershipActions.JoinFailed',
                }),
                description: $t({
                  defaultMessage: 'Please ask the {SPACE} admin to add you.',
                  id: 'Space.MembershipActions.JoinFailed.Description',
                }),
                status: 'error',
              })
            },
          },
        )
      }
    }
  }, [
    $t,
    canJoinSpace,
    isLoggedIn,
    join,
    membershipStatus,
    requestSpaceMembership,
    showLogin,
    spaceId,
  ])

  return {
    leaveSpace,
    joinSpace,
    leaving,
    joining,
    permissions,
    status,
    joined: optimisticJoined,
  }
}
