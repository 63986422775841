import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { CallToActionBlock as Component } from './Block.js'
import { CallToActionProps } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.CallToActionBlockSettings })),
)

export const RegisteredCallToActionBlock = (
  intl: I18nShape,
): RegisteredBlock<CallToActionProps> => {
  const { $t } = intl

  return {
    recommendedName: 'CallToAction',
    Component,
    config: {
      type: BlockType.Block,
      usable: false,

      displayName: $t({
        id: 'Blocks.CallToAction.displayName',
        defaultMessage: 'Call to action',
      }),
      Settings,
      Icon: <SvgIcon name="cursor-click" />,
      defaultProps: {
        alignment: 'left',
        title: $t({
          id: 'Blocks.CallToAction.DefaultTitle',
          defaultMessage: 'Call to action',
        }),
        description: $t({
          id: 'Blocks.CallToAction.DefaultDescription',
          defaultMessage:
            'Attention, everyone. This is a call to action for you to take action on the very actionable call to action button below, which reads “call to action”.',
        }),
        action: {
          type: 'button',
          text: $t({
            id: 'Blocks.CallToAction.DefaultActionText',
            defaultMessage: 'Call to action',
          }),
          href: '#',
        },
      },
    },
  }
}
