import { type I18nShape, useI18n } from '../../i18n/index.js'
import type { ValueRendererComponent } from '../types.js'

export const booleanValuesFactory = (intl: I18nShape) => {
  const { $t } = intl

  return [
    {
      value: true,
      key: 'checked',
      text: $t({
        defaultMessage: 'Checked',
        id: 'CustomField.FieldInput.Checked',
      }),
    },
    {
      value: false,
      key: 'unchecked',
      text: $t({
        defaultMessage: 'Unchecked',
        id: 'CustomField.FieldInput.Unchecked',
      }),
    },
  ]
}

export const BooleanRenderer: ValueRendererComponent = ({ filter }) => {
  const intl = useI18n()
  const [checkedValue, unCheckedValue] = booleanValuesFactory(intl)
  const { value } = filter
  if (typeof value !== 'boolean') {
    return null
  }

  if (value) {
    return <>{checkedValue.text}</>
  }

  return <>{unCheckedValue.text}</>
}
