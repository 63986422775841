import { File } from '@tribeplatform/gql-client/types'

import { logger } from '../common/lib/logger.js'

export const downloadFile = async (
  document: Document,
  src: string,
  name?: string,
): Promise<void> => {
  try {
    const a = document.createElement('a')
    a.href = src
    a.download = name || ''
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } catch (e) {
    window.open(src, '_blank').focus()
    logger.error('Error while downloading file', e.message)
  }
}

const PDF_EXTENSIONS = ['pdf', 'application/pdf']
const IMAGE_EXTENSIONS = [
  'jpg',
  'jpeg',
  'jiff',
  'image/jpeg',

  'png',
  'image/png',

  'gif',
  'image/gif',

  'svg',
  'image/svg+xml',
]
const VIDEO_EXTENSIONS = ['mp4', 'video/mp4']
const AUDIO_EXTENSIONS = [
  'mp3',
  'audio/mpeg',

  'ogg',
  'audio/ogg',

  'wav',
  'audio/wav',
]

const PREVIEW_SUPPORTED_EXTENSION = [
  ...PDF_EXTENSIONS,
  ...IMAGE_EXTENSIONS,
  ...VIDEO_EXTENSIONS,
  ...AUDIO_EXTENSIONS,
]

export const fileHasPreview = (attachment: File) => {
  return extensionHasPreview(attachment?.extension?.toLowerCase())
}

export const extensionHasPreview = (extension: string) => {
  return PREVIEW_SUPPORTED_EXTENSION.includes(extension)
}

export const isPdfExtension = (extension: string): boolean =>
  PDF_EXTENSIONS.includes(extension)

export const isAudioExtension = (extension: string): boolean =>
  AUDIO_EXTENSIONS.includes(extension)

export const isVideoExtension = (extension: string): boolean =>
  VIDEO_EXTENSIONS.includes(extension)
