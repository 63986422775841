import { useContext, useState } from 'react'

import { getExtraProperty } from '@tribeplatform/gql-client/lib'
import { useSlateKitContext } from '@tribeplatform/react-slate-kit/hooks'
import { Container } from '@tribeplatform/react-ui-kit/Layout'
import { Link } from '@tribeplatform/react-ui-kit/Link'

import { CookieConsentPreferencesModal } from '../../Apps/CookieConsent/CookieConsentPreferencesModal.js'
import { CookieContext } from '../../Apps/CookieConsent/CookieContext.js'

export const SidebarFooter = () => {
  const slateKitProps = useSlateKitContext()
  const {
    context: {
      networkExtraProperties,
      networkTermsOfServiceUrl,
      networkPrivacyPolicyUrl,
    },
  } = slateKitProps
  const { isCookieInstalled } = useContext(CookieContext)

  const currentYear = new Date().getFullYear()
  const termsOfServiceUrl =
    getExtraProperty(networkExtraProperties, 'termsOfServiceUrl') ??
    networkTermsOfServiceUrl

  const privacyPolicyUrl =
    getExtraProperty(networkExtraProperties, 'privacyPolicyUrl') ??
    networkPrivacyPolicyUrl

  const [isCookieModalOpen, setIsCookieModalOpen] = useState(false)

  return (
    <Container
      size="sm"
      spacing="xs"
      padding={{
        disableOnMobile: true,
        top: 'none',
        vertical: 'md',
        horizontal: 'sm',
      }}
      maxSize="sm"
    >
      <div className="flex flex-wrap space-s-1 text-xs text-content-on-background-subdued">
        {!!(termsOfServiceUrl || privacyPolicyUrl) && (
          <>
            {!!termsOfServiceUrl && (
              // eslint-disable-next-line react/jsx-no-literals
              <Link variant="neutral" href={termsOfServiceUrl}>
                Terms
              </Link>
            )}

            {/* eslint-disable-next-line react/jsx-no-literals */}
            {!!termsOfServiceUrl && !!privacyPolicyUrl && <span> · </span>}

            {!!privacyPolicyUrl && (
              // eslint-disable-next-line react/jsx-no-literals
              <Link variant="neutral" href={privacyPolicyUrl}>
                Privacy
              </Link>
            )}
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <span> · </span>
          </>
        )}
        {isCookieInstalled && (
          <>
            <Link
              as="button"
              variant="neutral"
              onClick={() => setIsCookieModalOpen(true)}
              // eslint-disable-next-line react/jsx-no-literals
            >
              Cookies
            </Link>
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <span> · </span>
          </>
        )}
        {/* eslint-disable-next-line react/jsx-no-literals */}
        <span aria-label="Copyright">© Copyright {currentYear}</span>
      </div>
      {isCookieModalOpen && (
        <CookieConsentPreferencesModal
          isOpen
          onClose={() => setIsCookieModalOpen(false)}
        />
      )}
    </Container>
  )
}
