import { EntityImage } from '@tribeplatform/react-components/common/components'

import {
  StoredField,
  StaticField,
  ActionButtonSettings,
} from '../Fields/types.js'
import { SpaceHeaderFieldKey } from '../types.js'

export const convertToStoredFields = (
  staticFields: StaticField[],
): StoredField[] => {
  if (!staticFields?.length) {
    return [] as StoredField[]
  }

  return staticFields.map(({ id, enabled, fields, settings, key }) => ({
    id,
    key,
    enabled,
    fields: convertToStoredFields(fields ?? []),
    settings,
  }))
}

export const convertToStaticFields = (
  storedFields: StoredField[],
  staticFields: StaticField[],
): StaticField[] => {
  const blockFields = storedFields.filter(
    field =>
      staticFields.some(({ key }) => key === field.key) ||
      field.key === SpaceHeaderFieldKey.CustomButton,
  )
  const fieldsNotInBlock = staticFields.filter(
    field => !blockFields.some(({ key }) => key === field.key),
  )

  return [
    ...blockFields.map(field => {
      const staticField = staticFields.find(({ key }) => key === field.key)
      return staticField
        ? toStaticField(field, staticFields)
        : customButtonToStaticField(field)
    }),
    ...fieldsNotInBlock,
  ]
}

export const toStaticField = (
  storedField: StoredField,
  staticFields: StaticField[],
): StaticField => {
  const staticField = staticFields.find(({ key }) => key === storedField.key)
  return {
    ...staticField,
    id: storedField.id,
    fields: convertToStaticFields(
      storedField.fields ?? [],
      staticField.fields ?? [],
    ),
    settings: storedField.settings,
    enabled: storedField.enabled,
  }
}

const customButtonToStaticField = (storedField: StoredField): StaticField => {
  const settings = storedField.settings as ActionButtonSettings
  return {
    id: storedField.id,
    enabled: storedField.enabled,
    key: SpaceHeaderFieldKey.CustomButton,
    locked: false,
    icon: <EntityImage entity={{ image: settings.icon }} />,
    label: settings.name,
    settings,
  }
}

export const sanitizeStoredFields = (
  storedFields: StoredField[],
  staticFields: StaticField[],
): StoredField[] => {
  return storedFields.filter(field =>
    staticFields.some(({ key }) => key === field.key),
  )
}
