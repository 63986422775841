import { MediaFields, mediaGQLFields } from '../media/index.js'
import { SlateFields, slateGQLFields } from '../slate/slate.fields.js'

export type DynamicBlockFields =
  | 'basic'
  | 'default'
  | 'all'
  | CustomDynamicBlockFields

export interface CustomDynamicBlockFields {
  slate?: SlateFields
  favicon?: MediaFields
  image?: MediaFields
}

const BASIC_DYNAMIC_BLOCK_FIELDS: CustomDynamicBlockFields = {}
const DEFAULT_DYNAMIC_BLOCK_FIELDS: CustomDynamicBlockFields = {
  slate: 'all',
}
const ALL_DYNAMIC_BLOCK_FIELDS: CustomDynamicBlockFields = {
  slate: 'all',
  favicon: 'basic',
  image: 'basic',
}

export const dynamicBlockGQLFields = (fields: DynamicBlockFields) => {
  if (fields === 'basic') {
    fields = BASIC_DYNAMIC_BLOCK_FIELDS
  }
  if (!fields || fields === 'default') {
    fields = DEFAULT_DYNAMIC_BLOCK_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_DYNAMIC_BLOCK_FIELDS
  }
  return `
    description
    id
    name
    key
    ${
      fields.slate
        ? `
      slate(context: $context, entityId: $entityId) {
        ${slateGQLFields(fields.slate)}
      }
    `
        : ``
    }
    ${
      fields.favicon
        ? `
      favicon {
        ${mediaGQLFields(fields.favicon)}
      }
    `
        : ``
    }
    ${
      fields.image
        ? `
      image {
        ${mediaGQLFields(fields.image)}
      }
    `
        : ``
    }
  `
}
