import type {
  QueryMemberSpaceMembershipRequestArgs,
  QuerySpaceJoinRequestsArgs,
  QuerySpaceMembershipRequestsArgs,
} from '@tribeplatform/gql-client/types'
import {
  PaginatedSpaceJoinRequestFields,
  SpaceJoinRequestFields,
} from '@tribeplatform/gql-client/types/generated'

import { KeyConstructor } from './keyConstructor.js'

const SPACE_JOIN_REQUEST = 'spaceJoinRequest'
const MEMBER_SPACE_MEMBERSHIP_REQUEST = 'memberSpaceMembershipRequest'
const SPACE_MEMBERSHIP_REQUEST = 'spaceMembershipRequest'

export const getSpaceJoinRequestKey = KeyConstructor<
  QuerySpaceJoinRequestsArgs,
  PaginatedSpaceJoinRequestFields
>(SPACE_JOIN_REQUEST)

export const getMemberSpaceMembershipRequestKey = KeyConstructor<
  QueryMemberSpaceMembershipRequestArgs,
  SpaceJoinRequestFields
>(MEMBER_SPACE_MEMBERSHIP_REQUEST)
export const getSpaceMembershipRequestKey = KeyConstructor<
  QuerySpaceMembershipRequestsArgs,
  SpaceJoinRequestFields
>(SPACE_MEMBERSHIP_REQUEST)
