import { Fragment } from 'react'

import { clsx } from 'clsx'

import { useNavigate } from '@tribeplatform/react-components/Link'
import type { StaticField } from '@tribeplatform/react-components/Views'

import type { QuickLinksSource } from '../types.js'
import { Field } from './Field.js'

interface Props {
  source: QuickLinksSource
  fields: StaticField[]
  activeFieldId: string
  preview: boolean
}
export const QuickLinksListItem = ({
  source,
  fields,
  activeFieldId,
  preview,
}: Props) => {
  const navigate = useNavigate()

  return (
    <div
      className={clsx(
        source.linkUrl && 'cursor-pointer',
        'flex flex-wrap items-center gap-y-2 gap-x-5',
      )}
      onClick={() => {
        if (source.linkUrl) {
          navigate(source.linkUrl)
        }
      }}
    >
      {fields.map(field => (
        <Fragment key={field.id}>
          <Field
            field={field}
            source={source}
            activeFieldId={activeFieldId}
            viewStyle="list"
            preview={preview}
          />
          {field.settings?.stretchX && (
            <div className="basis-full web:sm:basis-0"></div>
          )}
        </Fragment>
      ))}
    </div>
  )
}
