import { TagIdPicker } from '../../EntityPicker/TagIdPicker.js'
import { TagPickerFromIds } from '../../EntityPicker/TagPickerFromIds.js'
import { useI18n } from '../../i18n/index.js'
import type { Filter } from '../types.js'
import { InfiniteSpreadTagPicker } from './entityPickers/InfiniteSpreadTagPicker.js'
import { InlineTagIdPicker } from './entityPickers/InlineTagIdPicker.js'
import { InlineTagPickerFromIds } from './entityPickers/InlineTagPickerFromIds.js'
import { SpreadTagPicker } from './entityPickers/SpreadTagPicker.js'

interface Props {
  spread: boolean
  options: string[]
  value: string[]
  inline: boolean
  className?: string
  onChange: (value: Filter['value']) => void
}
export const RelationTagPicker = ({
  spread,
  options,
  value,
  inline,
  className,
  onChange,
}: Props) => {
  const { $t } = useI18n()

  if (!inline) {
    return options ? (
      <TagPickerFromIds
        value={value}
        options={options}
        onChange={onChange}
        placeholder={$t({
          defaultMessage: 'Select {TAGS}',
          id: 'Filters.Values.Tags.Placeholder',
        })}
        className={className}
        noWrapper
      />
    ) : (
      <TagIdPicker
        value={value}
        onChange={onChange}
        placeholder={$t({
          defaultMessage: 'Select {TAGS}',
          id: 'Filters.Values.Tags.Placeholder',
        })}
        className={className}
        multiple
        noWrapper
      />
    )
  }

  if (spread) {
    return options ? (
      <SpreadTagPicker value={value} options={options} onChange={onChange} />
    ) : (
      <InfiniteSpreadTagPicker value={value} onChange={onChange} />
    )
  }

  return options ? (
    <InlineTagPickerFromIds
      value={value}
      options={options}
      className={className}
      onChange={onChange}
    />
  ) : (
    <InlineTagIdPicker
      value={value}
      className={className}
      onChange={onChange}
    />
  )
}
