import { useParams } from 'react-router'

import { useLayoutFrame } from '@tribeplatform/react-components/LayoutFrame'
import { useRouterPath } from '@tribeplatform/react-sdk'

import {
  SlateRenderer,
  SlateRendererProps,
} from '../../../../components/SlateRenderer.component.js'
import { MobileMenuProvider } from '../../providers/MobileMenuProvider.js'

interface Props {
  close: () => void
}
export const MobileMenuFromSidebar = ({ close }: Props) => {
  const pathname = useRouterPath()
  const urlParams = useParams()
  const { sidebar1, showSidebar } = useLayoutFrame({})
  const context: SlateRendererProps['context'] = {
    path: pathname,
    urlParams,
  }

  if (!showSidebar) {
    return null
  }
  return (
    <MobileMenuProvider.Provider
      value={{
        close,
      }}
    >
      <SlateRenderer slate={sidebar1} context={context} />
    </MobileMenuProvider.Provider>
  )
}
