import { useMemo } from 'react'

import { clsx } from 'clsx'

import { useI18n } from '@tribeplatform/react-components/i18n'

import { useSlate } from '../../hooks/slate.hook.js'
import type { RegisteredBlockProps } from '../../types/block.types.js'
import { SpaceHeader } from './Components/SpaceHeader.js'
import { type SpaceHeaderProps } from './types.js'
import { migrateProps } from './utils/migrateProps.js'

export const SpaceHeaderBlock = ({
  'data-block-id': dataBlockId,
  'data-block-name': dataBlockName,
  className,
  ...props
}: RegisteredBlockProps<SpaceHeaderProps>) => {
  const intl = useI18n()
  const {
    context: { spaceId },
  } = useSlate()
  const blockProps = useMemo(
    () => ({
      ...migrateProps({ props, intl }),
      spaceId,
    }),
    [props, intl, spaceId],
  )

  return (
    <div
      className={clsx('@container', className)}
      data-block-id={dataBlockId}
      data-block-name={dataBlockName}
    >
      <SpaceHeader {...blockProps} />
    </div>
  )
}
