import {
  getActivityLogsGQLQuery,
  getLeaderboardGQLQuery,
  getLeaderboardWithScoresGQLQuery,
  getReportGQLQuery,
  ReportFields,
  MemberFields,
  LeaderboardFields,
  ReportRecordFields,
  analyticsGQLQuery,
} from '../../graphql/index.js'
import {
  AnalyticsResponse,
  LeaderboardItem,
  Member,
  QueryActivityLogsArgs,
  QueryAnalyticsArgs,
  QueryLeaderboardArgs,
  QueryLeaderboardWithScoresArgs,
  QueryReportArgs,
  Report,
  ReportRecord,
} from '../../types/index.js'
import { GraphqlClient } from '../graphql.client.js'

export class ReportClient {
  private client: GraphqlClient

  constructor(client: GraphqlClient) {
    this.client = client
  }

  async get(
    variables: QueryReportArgs,
    fields: ReportFields = 'basic',
    accessToken?: string,
  ): Promise<Report> {
    type QueryResult = { report: Report }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getReportGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.report
  }

  async getLeaderboard(
    variables: QueryLeaderboardArgs,
    fields: MemberFields = 'basic',
    accessToken?: string,
  ): Promise<Member[]> {
    type QueryResult = { leaderboard: Member[] }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getLeaderboardGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.leaderboard
  }

  async getLeaderboardWithScores(
    variables: QueryLeaderboardWithScoresArgs,
    fields: LeaderboardFields = 'basic',
    accessToken?: string,
  ): Promise<LeaderboardItem[]> {
    type QueryResult = { leaderboardWithScores: LeaderboardItem[] }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getLeaderboardWithScoresGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.leaderboardWithScores
  }

  async activityLogs(
    variables: QueryActivityLogsArgs,
    fields: ReportRecordFields = 'basic',
    accessToken?: string,
  ): Promise<ReportRecord[]> {
    type QueryResult = { activityLogs: ReportRecord[] }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getActivityLogsGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.activityLogs
  }

  async analytics(
    variables: QueryAnalyticsArgs,
    fields: ReportRecordFields = 'basic',
    accessToken?: string,
  ): Promise<AnalyticsResponse[]> {
    type QueryResult = { analytics: AnalyticsResponse[] }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: analyticsGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.analytics
  }
}
