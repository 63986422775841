import { gql } from 'graphql-tag'

import { emailLogGQLFields } from './emailLog.fields.js'

export const emailLogsGQLQuery = () => gql`
  query EmailLogs(
    $after: String
    $limit: Int!
    $recipient: String
    $reverse: Boolean
    $statuses: [EmailStatus!]
    $types: [EmailType!]
  ) {
    emailLogs(
      after: $after
      limit: $limit
      recipient: $recipient
      reverse: $reverse
      statuses: $statuses
      types: $types
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ${emailLogGQLFields()}
        }
      }
    }
  }
`
