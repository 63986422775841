import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

export const MembersConfig = ({ $t }: I18nShape) => ({
  defaultProps: {
    title: '',
    description: '',
    sort: 'alphabetical',
    limit: 10,
    showTitle: true,
    showTagline: true,
    showViewAll: false,
    filterBy: [],
  },
  displayName: $t({
    defaultMessage: 'Members',
    id: 'Blocks.Members.DisplayName',
  }),
  removable: true,
  Icon: <SvgIcon name="members" />,
})
