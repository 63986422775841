// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import useMobileDetectOriginal from 'use-mobile-detect-hook'

type TUseMobileDetectOriginal = () => {
  isMobile: () => boolean
  isDesktop: () => boolean
  isAndroid: () => boolean
  isIos: () => boolean
  isSSR: () => boolean
}

/* reexported with a TS type because original module has default export */
export const useMobileDetect =
  useMobileDetectOriginal as unknown as TUseMobileDetectOriginal
