import { forwardRef, ReactNode } from 'react'

import { Listbox } from '@headlessui/react'

import { HTMLTribeProps } from '../types/index.js'
import { FloatingWrapper } from '../utils/floating/index.js'
import { runIfFn } from '../utils/index.js'
import { selectItemsStyles } from './Select.styles.js'
import { useSelect } from './SelectContext.js'

interface SelectItemsRenderProps {
  open: boolean
}

export type SelectItemsProps = Omit<HTMLTribeProps<'ul'>, 'children'> & {
  children?: ReactNode | ((props: SelectItemsRenderProps) => ReactNode)
  noWrapper?: boolean
}

export const SelectItems = forwardRef<HTMLUListElement, SelectItemsProps>(
  ({ children, className, noWrapper, ...rest }, ref) => {
    const { open } = useSelect()

    if (noWrapper) {
      return (
        <Listbox.Options
          className={selectItemsStyles({ className })}
          {...rest}
          ref={ref}
        >
          {renderProps => <>{runIfFn(children, renderProps)}</>}
        </Listbox.Options>
      )
    }

    return (
      <FloatingWrapper open={open} sameWidth>
        <Listbox.Options
          className={selectItemsStyles({ className })}
          {...rest}
          ref={ref}
        >
          {renderProps => <>{runIfFn(children, renderProps)}</>}
        </Listbox.Options>
      </FloatingWrapper>
    )
  },
)
