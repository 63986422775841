import {
  getTemplateRequestGQLQuery,
  getTemplatesGQLQuery,
  TemplateFields,
  TemplateRequestFields,
} from '../../graphql/index.js'
import {
  QueryTemplateRequestArgs,
  PaginatedTemplate,
  QueryTemplatesArgs,
  TemplateRequest,
} from '../../types/index.js'
import { GraphqlClient } from '../graphql.client.js'

export class TemplatesClient {
  private client: GraphqlClient

  constructor(client: GraphqlClient) {
    this.client = client
  }

  async list(
    variables?: QueryTemplatesArgs,
    fields: TemplateFields = 'basic',
    accessToken?: string,
  ): Promise<PaginatedTemplate> {
    type QueryResult = { templates: PaginatedTemplate }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getTemplatesGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.templates
  }

  async templateRequest(
    variables?: QueryTemplateRequestArgs,
    fields: TemplateRequestFields = 'basic',
    accessToken?: string,
  ): Promise<TemplateRequest> {
    type QueryResult = { templateRequest: TemplateRequest }
    const result = await this.client.authorizedRequest<QueryResult>({
      query: getTemplateRequestGQLQuery(fields),
      variables,
      customToken: accessToken,
    })
    return result.templateRequest
  }
}
