import { clsx } from 'clsx'

import { generateNewChatPath } from '@bettermode/common/utils'
import { Member } from '@tribeplatform/gql-client/types'
import { T, useI18n } from '@tribeplatform/react-components/i18n'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { useRouter } from '@tribeplatform/react-sdk'
import { IconButton, Button } from '@tribeplatform/react-ui-kit/Button'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

interface Props {
  member: Member
  highlighted: boolean
  stretchX: string
  authMember: Member
  fullWidth: boolean
  buttonVariant: StaticField['settings']['buttonVariant']
}
export const MessageButton = ({
  member,
  highlighted,
  stretchX,
  authMember,
  fullWidth,
  buttonVariant,
}: Props) => {
  const { $t } = useI18n()
  const { Link } = useRouter()
  const showIcon = buttonVariant === 'icon' || buttonVariant === 'iconText'
  const showText = buttonVariant === 'text' || buttonVariant === 'iconText'

  return member?.authMemberProps?.canSendPrivateMessages &&
    member?.id !== authMember?.id ? (
    <div
      className={clsx(
        'empty:hidden space-y-2',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
    >
      {buttonVariant === 'icon' ? (
        <IconButton
          variant="secondaryNeutral"
          as={Link}
          href={generateNewChatPath(member)}
          className="flex-shrink-0"
          icon={<SvgIcon name="message" />}
          onClick={e => e.stopPropagation()}
          accessibilityLabel={$t({
            defaultMessage: 'Message',
            id: 'Member.ActionButton.Message',
          })}
        />
      ) : (
        <Button
          variant="secondaryNeutral"
          as={Link}
          href={generateNewChatPath(member)}
          className="flex-shrink-0"
          leadingIcon={showIcon ? <SvgIcon name="message" /> : null}
          fullWidth={fullWidth}
          onClick={e => e.stopPropagation()}
        >
          {showText ? (
            <T defaultMessage="Message" id="Member.ActionButton.Message" />
          ) : (
            ''
          )}
        </Button>
      )}
    </div>
  ) : null
}
