import React from 'react'

import { ClientFileError } from '@tribeplatform/gql-client/lib'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { FileDropZone } from '@tribeplatform/react-ui-kit/FileUpload'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { List } from '@tribeplatform/react-ui-kit/Layout'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

import { FileListItem } from '../../common/components/FileListItem.js'
import { fileSizeLimitError } from '../../common/lib/upload.js'
import { T } from '../../i18n/components/T.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { UploadableFile } from './types.js'
import { useHandleUploadableFiles } from './useHandleUploadableFiles.js'

type FormFileUploadSingleProps = {
  value?: UploadableFile
  /** Callback triggered on file upload finish */
  onChange?(file: UploadableFile): void
}
type FormFileUploadMultipleProps = {
  value?: UploadableFile[]
  /** Callback triggered on file upload finish */
  onChange?(files: UploadableFile[]): void
}

export type FormFileUploadProps = (
  | FormFileUploadSingleProps
  | FormFileUploadMultipleProps
) & {
  id?: string
  /**
   * Allows multiple files to be uploaded at once
   * @default false
   */
  multiple?: boolean
  /** Callback triggered on file upload start */
  onUploadStart?: () => void
  onError?: (error: ClientFileError) => void
  invalid?: boolean
}

const isMultiple = (
  props: FormFileUploadProps,
): props is FormFileUploadMultipleProps => props.multiple === true

export const FormFileUpload = (props: FormFileUploadProps) => {
  const intl = useI18n()
  const { $t } = intl
  const { onError, onUploadStart, ...rest } = props

  const multiple = isMultiple(props)
  let defaultValue: UploadableFile[]
  if (multiple) {
    defaultValue = props.value || []
  } else {
    defaultValue = props.value ? [props.value] : []
  }

  const { data: network } = useNetwork()
  const { fileTypesLimit, fileSizeLimit } = network?.settings || {}

  const onSuccess = newValue => {
    if (multiple) {
      props.onChange?.(newValue)
    } else {
      props.onChange?.(newValue?.[0])
    }
  }

  const { files, onFileUpload, onRemove } = useHandleUploadableFiles({
    defaultValue,
    multiple,
    onChange: onSuccess,
    onUploadStart,
    onError,
  })

  const fileUpload = !files?.length && (
    <FileDropZone.Label
      actionTitle={
        multiple
          ? $t({
              defaultMessage:
                'Click to upload files, or drag and drop them here.',
              id: 'Form.File.UploadManyFiles',
            })
          : $t({
              defaultMessage:
                'Click to upload a file, or drag and drop it here.',
              id: 'Form.File.UploadAFile',
            })
      }
      actionHint={fileSizeLimitError(fileSizeLimit, 'file', intl)}
    />
  )

  const uploadedFiles = files?.length > 0 && (
    <List spacing="sm" className="p-4">
      {files.map(file => (
        <List.Item key={file.name}>
          <FileListItem
            name={file.name}
            extension={file?.extension}
            size={file?.size}
            downloadUrl={file?.downloadUrl || file?.url}
            action={
              <div className="flex items-center space-s-3">
                {file.isLoading ? (
                  <SvgIcon className="animate-spin w-7 h-7" name="spinner" />
                ) : (
                  <Tooltip>
                    <Tooltip.Trigger>
                      <SvgIcon
                        onClick={e => {
                          e.stopPropagation()
                          onRemove(file)
                        }}
                        className="text-content-subdued hover:text-content-hovered cursor-pointer w-6 h-6"
                        name="close"
                      />
                    </Tooltip.Trigger>
                    <Tooltip.Panel>
                      <T id="Generics.Delete" defaultMessage="Delete" />
                    </Tooltip.Panel>
                  </Tooltip>
                )}
              </div>
            }
          />
        </List.Item>
      ))}
    </List>
  )

  return (
    <FileDropZone
      onDrop={onFileUpload}
      allowMultiple={multiple}
      accept={fileTypesLimit?.join(',')}
      {...rest}
    >
      {fileUpload}
      {uploadedFiles}
    </FileDropZone>
  )
}
