import { RadioGroup } from '@tribeplatform/react-ui-kit/RadioGroup'

import { useI18n } from '../../i18n/index.js'
import type { ValuePickerComponent } from '../types.js'
import { ratingValueFactory } from '../valueRenderers/RatingRenderer.js'

export const RatingValuePicker: ValuePickerComponent = ({
  filter,
  onChange,
}) => {
  const intl = useI18n()
  const { value } = filter
  const numberValue = Number(value)

  if (Number.isNaN(numberValue) && value !== undefined) {
    return null
  }

  const currentValue = Math.min(Math.max(0, numberValue), 5)

  return (
    <RadioGroup
      value={value === undefined ? '' : currentValue.toString()}
      onChange={newValue => {
        onChange(Number(newValue))
      }}
    >
      <RadioGroup.Items>
        {[...Array(5)]
          .map((_, index) => index + 1)
          .map(index => (
            <RadioGroup.Item
              key={index}
              value={index.toString()}
              title={ratingValueFactory(index, intl)}
            />
          ))}
      </RadioGroup.Items>
    </RadioGroup>
  )
}
