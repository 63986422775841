import { clsx } from 'clsx'

import { ReactionCountPositions } from '../../Views/types.js'

interface Props {
  icon: JSX.Element
  text: string
  count: number
  countPosition: ReactionCountPositions
}
export const ReactionButtonContent = ({
  icon,
  text,
  count,
  countPosition,
}: Props) => {
  return (
    <span
      className={clsx(
        'flex items-center gap-x-2',
        countPosition === ReactionCountPositions.next ? 'flex-row' : 'flex-col',
      )}
    >
      <span className="inline-flex items-center gap-x-1">
        {!!icon && icon}
        {!!text && <span className="truncate">{text}</span>}
      </span>
      {(!!count || count === 0) && <span className="truncate">{count}</span>}
    </span>
  )
}
