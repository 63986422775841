import { useCallback, useMemo } from 'react'

import {
  PostType,
  RoleType as NetworkRoleType,
  SpacePostType,
  SpaceRoleType,
} from '@tribeplatform/gql-client/types'
import { useNetwork, useSpaceRoles } from '@tribeplatform/react-sdk/hooks'

import { useStaticIntl } from '../../../i18n/hooks/useStaticIntl.js'
import type { I18nShape } from '../../../i18n/types.js'

type WhoCan = 'allMembers' | 'adminsAndSpaceMembers' | 'admins' | 'nobody'

const WhoCanTitlesFactory: (
  intl: I18nShape,
) => Record<WhoCan, string> = intl => {
  const { $t } = intl
  return {
    allMembers: $t({
      defaultMessage: 'All {MEMBERS}',
      id: 'Admin.Space.PostPermissions.All',
    }),
    adminsAndSpaceMembers: $t({
      defaultMessage: '{SPACE_CC} {MEMBERS}, {SPACE} admins, and staff',
      id: 'Admin.Space.PostPermissions.ExtendedStaff',
    }),
    admins: $t({
      defaultMessage: '{SPACE_CC} admins and staff',
      id: 'Admin.Space.PostPermissions.AdminsAndMods',
    }),
    nobody: $t({
      defaultMessage: 'Nobody',
      id: 'Admin.Space.PostPermissions.Nobody',
    }),
  }
}

export type PostTypePermission = {
  postType: PostType
  enabled: boolean
  whoCanPost: WhoCan
  whoCanReact: WhoCan
  whoCanReply: WhoCan
}

const compareArrays = (arr1: string[], arr2: string[]) => {
  const arr1String = arr1.sort().join(',')
  const arr2String = arr2.sort().join(',')

  return arr1String === arr2String
}

interface Props {
  spaceId: string
  isPrivate: boolean
}
export const useSpacePostTypeSettings = ({ spaceId, isPrivate }: Props) => {
  const WhoCanTitles = useStaticIntl(WhoCanTitlesFactory)
  const {
    data: { roles: networkRoles },
  } = useNetwork()
  const { data: spaceRoles } = useSpaceRoles({
    variables: {
      spaceId,
    },
  })

  const permissionItems = useMemo(
    () =>
      Object.entries(WhoCanTitles)
        .filter(([key]) => !isPrivate || key !== 'allMembers')
        .map(([key, value]) => ({
          text: value,
          value: key as WhoCan,
        })),
    [WhoCanTitles, isPrivate],
  )

  const whoCanValues = useMemo<Record<WhoCan, string[]>>(() => {
    const roleIds = {
      admin:
        networkRoles?.find(r => r.type === NetworkRoleType.admin)?.id ?? '',
      moderator:
        networkRoles?.find(r => r.type === NetworkRoleType.moderator)?.id ?? '',
      spaceAdmin:
        spaceRoles?.find(role => role.type === SpaceRoleType.admin)?.id ?? '',
      spaceMember:
        spaceRoles?.find(role => role.type === SpaceRoleType.member)?.id ?? '',
      member:
        networkRoles?.find(r => r.type === NetworkRoleType.member)?.id ?? '',
    }

    return {
      allMembers: [
        roleIds.admin,
        roleIds.moderator,
        roleIds.spaceAdmin,
        roleIds.spaceMember,
        roleIds.member,
      ],
      adminsAndSpaceMembers: [
        roleIds.admin,
        roleIds.moderator,
        roleIds.spaceAdmin,
        roleIds.spaceMember,
      ],
      admins: [roleIds.admin, roleIds.moderator, roleIds.spaceAdmin],
      nobody: [] as string[],
    }
  }, [networkRoles, spaceRoles])

  const getWhoCan = useCallback(
    (value: WhoCan) => whoCanValues[value],
    [whoCanValues],
  )

  const getPermissionValue = useCallback(
    (whoCanList: string[]): WhoCan => {
      // When you enable a post type for the first time, whoCanList is undefined
      // So we select 'adminsAndSpaceMembers' as the default value
      if (whoCanList === undefined) {
        return 'adminsAndSpaceMembers'
      }

      if (!whoCanList || !Array.isArray(whoCanList)) {
        return undefined
      }

      if (compareArrays(whoCanList, whoCanValues.nobody)) {
        return 'nobody'
      }

      if (compareArrays(whoCanList, whoCanValues.admins)) {
        return 'admins'
      }

      if (compareArrays(whoCanList, whoCanValues.adminsAndSpaceMembers)) {
        return 'adminsAndSpaceMembers'
      }

      if (compareArrays(whoCanList, whoCanValues.allMembers) && !isPrivate) {
        return 'allMembers'
      }

      return undefined
    },
    [whoCanValues, isPrivate],
  )

  const mapToPostTypePermissions = useCallback(
    (postTypes: PostType[], spacePostTypes: SpacePostType[]) => {
      if (!spacePostTypes || postTypes?.length === 0) {
        return {}
      }

      return postTypes.reduce((acc, postType) => {
        const spacePostType = spacePostTypes?.find(
          it => it.postTypeId === postType.id,
        )

        return {
          ...acc,
          [postType.id]: {
            postType,
            enabled: !!spacePostType,
            whoCanPost: getPermissionValue(spacePostType?.whoCanPost),
            whoCanReact: getPermissionValue(spacePostType?.whoCanReact),
            whoCanReply: getPermissionValue(spacePostType?.whoCanReply),
          },
        }
      }, {} as Record<string, PostTypePermission>)
    },
    [getPermissionValue],
  )

  return { permissionItems, getWhoCan, mapToPostTypePermissions }
}
