import type { StaticField, View } from '@tribeplatform/react-components/Views'
import { Carousel } from '@tribeplatform/react-ui-kit/Carousel'

import type { QuickLinksSource } from '../types.js'
import { getCardWidth } from '../utils/index.js'
import { QuickLinksGalleryItem } from './QuickLinksGalleryItem.js'

interface Props {
  sources: QuickLinksSource[]
  fields: StaticField[]
  gallerySize: View['gallerySize']
  activeFieldId?: string
  preview: boolean
}
export const QuickLinksCarousel = ({
  sources,
  fields,
  gallerySize = 'medium',
  activeFieldId,
  preview,
}: Props) => {
  const width = getCardWidth(gallerySize)

  return (
    <Carousel
      className="pt-1 pb-4"
      items={sources.map(source => (
        <QuickLinksGalleryItem
          key={source.id}
          width={width}
          source={source}
          fields={fields.filter(
            field =>
              field.enabled &&
              !(field.styles && field.styles.indexOf('carousel') === -1),
          )}
          activeFieldId={activeFieldId}
          gallerySize={gallerySize}
          className="sm:rounded-lg h-full"
          preview={preview}
        />
      ))}
    ></Carousel>
  )
}
