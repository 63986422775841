import { isEmpty } from '@bettermode/common/isempty'
import type { AuthToken, Member } from '@tribeplatform/gql-client/types'

import type { QueryClient } from '../../lib/react-query/QueryClient.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import {
  getAuthMemberKey,
  getAuthTokenKey,
} from '../../utils/keys/authToken.keys.js'

const findAuthMemberInAuthToken = (queryClient: QueryClient) => {
  const queries = queryClient.getQueriesData<AuthToken>({
    queryKey: getAuthTokenKey(),
  })

  let authMember: Member
  queries?.forEach(query => {
    const authToken = query?.[1]
    authMember = {
      ...authMember,
      ...authToken?.member,
    }
  })

  return authMember
}

const findAuthMember = (queryClient: QueryClient) => {
  const queries = queryClient.getQueriesData<Member>({
    queryKey: getAuthMemberKey(),
  })

  let authMember: Member
  queries?.forEach(query => {
    const data = query?.[1]
    authMember = {
      ...authMember,
      ...data,
    }
  })

  return authMember
}

export const useCachedAuthMember = () => {
  const queryClient = useQueryClient()

  const authMember = {
    ...findAuthMember(queryClient),
    ...findAuthMemberInAuthToken(queryClient),
  }

  if (!isEmpty(authMember)) {
    return authMember
  }
}
