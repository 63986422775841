import { FC, useMemo, useState } from 'react'

import { QueryTagsArgs, Tag } from '@tribeplatform/gql-client/types'
import { useSpaceTags } from '@tribeplatform/react-sdk/hooks'
import { simplifyPaginatedResult } from '@tribeplatform/react-sdk/utils'
import { Multiselect } from '@tribeplatform/react-ui-kit/Multiselect'
import { SearchableSelect } from '@tribeplatform/react-ui-kit/SearchableSelect'

import { useDebounce } from '../common/hooks/useDebounce.js'
import { T } from '../i18n/components/T.js'
import { pickIfExists } from './arrays.utils.js'
import { TagPickerCreatable } from './TagPickerCreatable.js'

type TagPickerSingleProps = {
  value?: Tag
  onChange?: (values: Tag) => void
}
type TagPickerMultipleProps = {
  value?: Tag[]
  onChange?: (values: Tag[]) => void
}

export type TagPickerProps = (TagPickerSingleProps | TagPickerMultipleProps) & {
  placeholder?: string
  variables?: Omit<QueryTagsArgs, 'query'>
  /**
   * Allows to pick multiple values at once
   * @default false
   */
  multiple?: boolean
  /**
   * applies only for multiple picker
   */
  creatable?: boolean

  disabled?: boolean
  noWrapper?: boolean
  className?: string
}

const isMultiple = (props: TagPickerProps): props is TagPickerMultipleProps =>
  props.multiple === true

export const TagPicker: FC<TagPickerProps> = props => {
  const { placeholder, variables, disabled, noWrapper, className } = props

  const [search, setSearch] = useState('')
  const debouncedQuery = useDebounce(search, 300)

  const { data, isInitialLoading } = useSpaceTags({
    variables: {
      query: debouncedQuery,
      limit: 20,
      ...variables,
    },
  })

  const tags = useMemo(
    () => simplifyPaginatedResult<Tag>(data)?.nodes || [],
    [data],
  )
  if (isMultiple(props)) {
    const { value, onChange, creatable = false } = props
    const suggestedTags: Tag[] = pickIfExists(tags, value, 'id')

    if (creatable) {
      return (
        <TagPickerCreatable
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
      )
    }

    // not creatable
    return (
      <Multiselect
        value={value || []}
        options={suggestedTags}
        onChange={onChange}
        searchable
        onInputChange={setSearch}
        disabled={disabled}
        className={className}
      >
        <Multiselect.Button
          placeholder={placeholder}
          hideInput={value?.length > 0}
        >
          {value?.map((tag, index) => (
            <Multiselect.SelectedItem key={tag.id} value={tag} index={index}>
              <span className="truncate">{tag.title}</span>
            </Multiselect.SelectedItem>
          ))}
        </Multiselect.Button>
        <Multiselect.Items noWrapper={noWrapper}>
          {suggestedTags.map((tag, index) => (
            <Multiselect.Item key={tag.id} value={tag} index={index}>
              <span className="truncate">{tag.title}</span>
            </Multiselect.Item>
          ))}
          {isInitialLoading && (
            <Multiselect.ItemsEmpty>
              <T id="Generics.LoadingDotDotDot" defaultMessage="Loading..." />
            </Multiselect.ItemsEmpty>
          )}
          {!isInitialLoading && search && suggestedTags.length === 0 && (
            <Multiselect.ItemsEmpty>
              <T id="Generics.NoResults" defaultMessage="No results" />
            </Multiselect.ItemsEmpty>
          )}
        </Multiselect.Items>
      </Multiselect>
    )
  }

  const { value, onChange } = props
  const suggestedTags: Tag[] = value ? pickIfExists(tags, [value], 'id') : tags

  return (
    <SearchableSelect
      value={value}
      options={suggestedTags}
      onChange={onChange}
      onInputChange={setSearch}
      disabled={disabled}
    >
      <SearchableSelect.Button placeholder={placeholder}>
        {value && <span className="truncate">{value?.title}</span>}
      </SearchableSelect.Button>
      <SearchableSelect.Items>
        {suggestedTags.map(tag => (
          <SearchableSelect.Item key={tag.id} value={tag.id}>
            <span className="truncate">{tag?.title}</span>
          </SearchableSelect.Item>
        ))}
        {isInitialLoading && (
          <SearchableSelect.ItemsEmpty>
            <T id="Generics.LoadingDotDotDot" defaultMessage="Loading..." />
          </SearchableSelect.ItemsEmpty>
        )}
        {!isInitialLoading && search && suggestedTags.length === 0 && (
          <SearchableSelect.ItemsEmpty>
            <T id="Generics.NoResults" defaultMessage="No results" />
          </SearchableSelect.ItemsEmpty>
        )}
      </SearchableSelect.Items>
    </SearchableSelect>
  )
}
