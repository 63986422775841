import { getPropertyValue } from '../utils/index.js'

export const colorStylesFactory = (document: Document) => [
  getPropertyValue(document, '--bm-color-decorative-content-1'),
  getPropertyValue(document, '--bm-color-decorative-content-2'),
  getPropertyValue(document, '--bm-color-decorative-content-3'),
  getPropertyValue(document, '--bm-color-decorative-content-4'),
  getPropertyValue(document, '--bm-color-decorative-content-5'),
  getPropertyValue(document, '--bm-color-decorative-content-6'),
  getPropertyValue(document, '--bm-color-decorative-content-7'),
  getPropertyValue(document, '--bm-color-decorative-surface-1'),
  getPropertyValue(document, '--bm-color-decorative-surface-2'),
  getPropertyValue(document, '--bm-color-decorative-surface-3'),
  getPropertyValue(document, '--bm-color-decorative-surface-4'),
  getPropertyValue(document, '--bm-color-decorative-surface-5'),
  getPropertyValue(document, '--bm-color-decorative-surface-6'),
  getPropertyValue(document, '--bm-color-decorative-surface-7'),
]

export const randomColor = (colorStyles: string[], index = 0): string => {
  return colorStyles[index % colorStyles.length]
}
