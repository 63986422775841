import type { InfiniteData } from '@tanstack/react-query'

import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Action,
  MutationRemoveSpaceMembersArgs,
  PaginatedSpaceMember,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSpaceMembersKey } from '../../utils/keys/member.key.js'

export const useRemoveSpaceMember = (options?: {
  useMutationOptions?: UseMutationOptions<
    Action,
    ClientError,
    MutationRemoveSpaceMembersArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<Action, ClientError, MutationRemoveSpaceMembersArgs>(
    input => client.spaceMembers.remove(input),
    {
      onMutate: variables => {
        const spaceMembersKey = getSpaceMembersKey({
          variables: {
            spaceId: variables.spaceId,
          },
        })

        queryClient.setQueriesData<InfiniteData<PaginatedSpaceMember>>(
          spaceMembersKey,
          old => ({
            ...old,
            pages: old?.pages?.map(page => ({
              ...page,
              edges: page?.edges?.filter(
                edge => !variables?.memberIds?.includes(edge?.node?.member?.id),
              ),
            })),
          }),
        )
      },
      onSettled: (data, error, variables) => {
        const spaceMembersKey = getSpaceMembersKey({
          variables: {
            spaceId: variables.spaceId,
          },
        })
        queryClient.invalidateQueries(spaceMembersKey)
      },
      ...useMutationOptions,
    },
  )
}
