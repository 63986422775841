import { FC, ReactNode } from 'react'

import { FormControlButtonGroupSelect } from './FormControlButtonGroupSelect.js'
import { FormControlCheckbox } from './FormControlCheckbox.js'
import { FormControlInput } from './FormControlInput.js'
import { FormControlInputCopy } from './FormControlInputCopy.js'
import { FormControlLayout } from './FormControlLayout.js'
import { FormControlMeta } from './FormControlMeta.js'
import { FormControlMultiselect } from './FormControlMultiselect.js'
import { FormControlRadioGroup } from './FormControlRadioGroup.js'
import { FormControlSelect } from './FormControlSelect.js'
import { FormControlTextarea } from './FormControlTextarea.js'
import { FormControlToggle } from './FormControlToggle.js'
import { HelperText } from './HelperText.js'
import { Label } from './Label.js'

export type FormControlComponentType = FC<{
  children?: ReactNode
}>

/**
 * FormControl provides context such as invalid, disabled, and required to form elements.
 */
export const FormControlComponent: FormControlComponentType = ({
  children,
}) => <div className="space-y-1">{children}</div>

export interface FormControlType extends FormControlComponentType {
  Label?: typeof Label
  HelperText?: typeof HelperText
  Meta?: typeof FormControlMeta
  Layout?: typeof FormControlLayout
  Checkbox?: typeof FormControlCheckbox
  Input?: typeof FormControlInput
  InputCopy?: typeof FormControlInputCopy
  Textarea?: typeof FormControlTextarea
  Toggle?: typeof FormControlToggle
  Select?: typeof FormControlSelect
  Multiselect?: typeof FormControlMultiselect
  RadioGroup?: typeof FormControlRadioGroup
  ButtonGroupSelect?: typeof FormControlButtonGroupSelect
}

export const FormControl: FormControlType = FormControlComponent

FormControl.Label = Label
FormControl.HelperText = HelperText
FormControl.Meta = FormControlMeta
FormControl.Layout = FormControlLayout

FormControl.Input = FormControlInput
FormControl.InputCopy = FormControlInputCopy
FormControl.Checkbox = FormControlCheckbox
FormControl.Textarea = FormControlTextarea
FormControl.Toggle = FormControlToggle
FormControl.Select = FormControlSelect
FormControl.Multiselect = FormControlMultiselect
FormControl.RadioGroup = FormControlRadioGroup
FormControl.ButtonGroupSelect = FormControlButtonGroupSelect
