export type BlockFields = 'all'

// eslint-disable-next-line unused-imports/no-unused-vars
export const blockGQLFields = (fields: BlockFields) => `
  id
  name
  props
  extraProps
  children
  output
`
