import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'
import { FieldView } from '@tribeplatform/react-components/CustomField'
import type { StaticField } from '@tribeplatform/react-components/Views'

interface Props {
  field: StaticField
  post: Post
  highlighted: boolean
  stretchX: string
}
export const PublishDate = ({ field, post, highlighted, stretchX }: Props) => {
  return (
    <FieldView
      highlighted={highlighted}
      key={field?.id}
      field={{
        key: field?.id,
        name: field?.label,
        type: field.type,
        settings: field.settings?.customFieldSettings,
      }}
      entity={{
        fields: [
          {
            key: field?.id,
            value: JSON.stringify(post?.publishedAt),
          },
        ],
      }}
      className={clsx('mt-0 flex items-center', stretchX)}
    />
  )
}
