import type { ValuePickerComponent } from '@tribeplatform/react-components/Filters'
import { SpreadFilterItems } from '@tribeplatform/react-components/Filters'
import { useI18n } from '@tribeplatform/react-components/i18n'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { Multiselect } from '@tribeplatform/react-ui-kit/Multiselect'

import { getRoleIds, getRoleNames } from '../utils/roles.js'

export const CustomRoleIdPicker: ValuePickerComponent = ({
  filter,
  spread,
  className,
  onChange,
}) => {
  const intl = useI18n()
  const { $t } = intl
  const { data: network } = useNetwork()
  const { value = [] } = filter
  const roles = network?.roles
  const rolesNames = getRoleNames(roles, intl)
  const roleIds = getRoleIds(roles)
  const inlineSelectOptions = filter?.extraProps?.inlineSelectOptions
  const narrowedDownOptions = filter?.extraProps?.narrowedDownOptions
  const options =
    (narrowedDownOptions?.length > 0
      ? narrowedDownOptions
      : inlineSelectOptions) ?? roleIds

  if (!Array.isArray(value)) {
    return null
  }

  if (spread) {
    return (
      <SpreadFilterItems options={options} value={value} onChange={onChange}>
        {selectedOption => (
          <span title={rolesNames?.[selectedOption] ?? ''}>
            {rolesNames?.[selectedOption] ?? ''}
          </span>
        )}
      </SpreadFilterItems>
    )
  }

  return (
    <div className={className}>
      <Multiselect
        value={value}
        options={options}
        onChange={newValues => onChange(newValues)}
      >
        <Multiselect.Button
          placeholder={$t({
            defaultMessage: 'Select role',
            id: 'Filters.Values.Roles.Placeholder',
          })}
          hideInput={value?.length > 0}
        >
          {value.map((roleId, index) => (
            <Multiselect.SelectedItem key={roleId} value={roleId} index={index}>
              {rolesNames?.[roleId] ?? ''}
            </Multiselect.SelectedItem>
          ))}
        </Multiselect.Button>
        <Multiselect.Items noWrapper>
          {options.map((roleId, index) => (
            <Multiselect.Item key={roleId} value={roleId} index={index}>
              {rolesNames?.[roleId] ?? ''}
            </Multiselect.Item>
          ))}
        </Multiselect.Items>
      </Multiselect>
    </div>
  )
}
