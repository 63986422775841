import { FormSearchableSelect as FormSearchableSelectComponent } from '@tribeplatform/react-components/Form/FormSearchableSelect'
import { useTribeClient } from '@tribeplatform/react-sdk'
import { ExtraInteractionsEvents } from '@tribeplatform/react-sdk/types'
import { legacySizeMapper } from '@tribeplatform/react-ui-kit/Avatar'
import type { FormControlSelectProps } from '@tribeplatform/react-ui-kit/FormControl'

import { useSlate } from '../../../hooks/slate.hook.js'
import { CallbackExtraContext } from '../../../types/callback.types.js'
import { MarkdownBlock } from '../../blocks.js'
import { SelectBlockProps } from '../types.js'

type FormSearchableSelectProps = Omit<SelectBlockProps, 'value'> & {
  name: string
  items: FormControlSelectProps['items']
  isFetching: boolean
  getValues: () => Record<string, string>
  setSearch: React.Dispatch<string>
}
export const FormSearchableSelect = ({
  items,
  name,
  callbackId,
  helperText: markdownHelperText,
  isFetching,
  getValues,
  setSearch,
  hideClearIcon,
  avatarRounded = 'md',
  avatarSize = 'xs',
  ...rest
}: FormSearchableSelectProps) => {
  const {
    extraContext: {
      slate: { callback },
    },
  } = useSlate<CallbackExtraContext>()
  const { config } = useTribeClient()
  const {
    interactionsContext: { interactionEmitter },
  } = config ?? {}

  return (
    <FormSearchableSelectComponent
      {...rest}
      name={name}
      loading={isFetching}
      onChange={value => {
        if (callbackId && value !== '') {
          interactionEmitter.emit(ExtraInteractionsEvents.InteractionCalled, {})
          callback(callbackId, {
            data: {
              value,
              name,
              label: items.find(item => item.value === value)?.text ?? '',
              formValues: typeof getValues === 'function' ? getValues() : {}, // we might need the form context in backend
            },
          })
        }
      }}
      onInputChange={setSearch}
      avatarProps={{
        rounded: avatarRounded,
        size: legacySizeMapper(avatarSize),
      }}
      options={items.map(item => ({
        value: item?.value?.toString(),
        key: item?.value?.toString(),
        text: item.text,
        ...(item?.avatar ? { avatar: item?.avatar } : {}),
      }))}
      hideClearIcon={hideClearIcon}
      helperText={
        typeof markdownHelperText === 'string' ? (
          <MarkdownBlock text={markdownHelperText} />
        ) : (
          markdownHelperText
        )
      }
    />
  )
}
