import type {
  SpaceJoinRequest,
  QuerySpaceMembershipRequestsArgs,
} from '@tribeplatform/gql-client/types'
import { SpaceJoinRequestFields } from '@tribeplatform/gql-client/types/generated'

import { useQuery, UseQueryOptions } from '../../lib/react-query/useQuery.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSpaceMembershipRequestKey } from '../../utils/keys/spaceMembership.key.js'

export const useSpaceMembershipRequests = (options: {
  variables: QuerySpaceMembershipRequestsArgs
  fields?: SpaceJoinRequestFields
  useQueryOptions?: UseQueryOptions<Array<SpaceJoinRequest>>
}) => {
  const { variables, fields = 'basic', useQueryOptions } = options
  const { client } = useTribeClient()
  const key = getSpaceMembershipRequestKey({ variables, fields })

  return useQuery<Array<SpaceJoinRequest>>(
    key,
    () =>
      client.query({
        name: 'spaceMembershipRequests',
        args: { variables, fields },
      }),
    {
      ...useQueryOptions,
    },
  )
}
