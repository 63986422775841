import { cva } from 'class-variance-authority'

export const iconStyles = cva(['shrink-0'], {
  variants: {
    size: {
      '2xs': 'h-[0.625em] w-[0.625em]',
      xs: 'h-[0.75em] w-[0.75em]',
      sm: 'h-[0.875em] w-[0.875em]',
      md: 'h-[1em] w-[1em]',
      lg: 'h-[1.25em] w-[1.25em]',
      xl: 'h-[1.5em] w-[1.5em]',
      '1.5x': 'h-[1.5rem] w-[1.5rem]',
      '2x': 'h-[2rem] w-[2rem]',
      '2.5x': 'h-[2.5rem] w-[2.5rem]',
      '3x': 'h-[3rem] w-[3rem]',
      '4x': 'h-[4rem] w-[4rem]',
      '6x': 'h-[6rem] w-[6rem]',
      '8x': 'h-[8rem] w-[8rem]',
    },
  },
})

export const iconSizeStyles = cva([''], {
  variants: {
    size: {
      '2xs': '0.625em',
      xs: '0.75em',
      sm: '0.875em',
      md: '1em',
      lg: '1.25em',
      xl: '1.5em',
      '1.5x': '1.5rem',
      '2x': '2rem',
      '2.5x': '2.5rem',
      '3x': '3rem',
      '4x': '4rem',
      '6x': '6rem',
      '8x': '8rem',
    },
  },
})
