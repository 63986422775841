import {
  NetworkImagesFields,
  NetworkImagesGQLFields,
} from '../../types/fields.generated.js'
import { MediaFields, mediaGQLFields } from '../media/index.js'

export type NetworkPublicInfoFields =
  | 'basic'
  | 'all'
  | CustomNetworkPublicInfoFields

export interface CustomNetworkPublicInfoFields {
  logo?: MediaFields
  images?: NetworkImagesFields
}

const BASIC_NETWORK_PUBLIC_INFO_FIELDS: CustomNetworkPublicInfoFields = {}
const ALL_NETWORK_PUBLIC_INFO_FIELDS: CustomNetworkPublicInfoFields = {
  logo: 'basic',
  images: {
    lightLogo: 'all',
    lightMobileLogo: 'all',
    lightFavicon: 'all',
    darkLogo: 'all',
    darkMobileLogo: 'all',
    darkFavicon: 'all',
  },
}

export function networkPublicInfoGQLFields(
  fields: NetworkPublicInfoFields,
): string {
  if (fields === 'basic') {
    fields = BASIC_NETWORK_PUBLIC_INFO_FIELDS
  }
  if (fields === 'all') {
    fields = ALL_NETWORK_PUBLIC_INFO_FIELDS
  }
  return `
    domain
    domainSubfolder
    id
    membership
    name
    status
    visibility
    faviconId
    jwtSsoAuthUrls {
      authorizationUrl
      signUpUrl
    }
    favicon {
      ${mediaGQLFields('basic')}
    }
    logoId
    ${
      fields.logo
        ? `
      logo {
        ${mediaGQLFields(fields.logo)}
      }
    `
        : ``
    }
    ${
      fields?.images
        ? `
      images {
        ${NetworkImagesGQLFields(fields?.images)}
      }
    `
        : ``
    }
  `
}
