import { useRef, useState, useEffect } from 'react'

import { PostType } from '@tribeplatform/gql-client/types'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'

import { useI18n } from '../i18n/index.js'
import { PostTypePicker } from './PostTypePicker.js'

export interface PostTypeIdPickerProps {
  value: string[]
  onChange: (postTypeIds: string[]) => void
  multiple?: boolean
  placeholder?: string
  noWrapper?: boolean
  invalid?: boolean
  className?: string
}

export const PostTypeIdPicker = ({
  value,
  onChange,
  multiple = false,
  placeholder,
  noWrapper,
  invalid,
  className,
}: PostTypeIdPickerProps) => {
  const { $t } = useI18n()
  const [postTypes, setPostTypes] = useState<PostType[]>([])
  const didLoadInitialData = useRef(value.length === 0)

  const {
    data: { postTypes: allPostTypes },
    isInitialLoading,
  } = useNetwork({
    useQueryOptions: {
      enabled: value.length > 0 && !didLoadInitialData.current,
    },
  })

  const initialPostTypes = allPostTypes?.filter(postType =>
    value.includes(postType.id),
  )

  useEffect(() => {
    if (!didLoadInitialData.current && initialPostTypes) {
      setPostTypes(initialPostTypes)
      didLoadInitialData.current = true
    }
  }, [initialPostTypes])

  useEffect(() => {
    if (value.length === 0) {
      setPostTypes([])
    }
  }, [value])

  const getPlaceHolder = () => {
    if (isInitialLoading || !postTypes) {
      return $t({
        id: 'Generics.LoadingDotDotDot',
        defaultMessage: 'Loading...',
      })
    }

    return placeholder
  }

  const handleChanges = (postTypes: PostType[]) => {
    setPostTypes(postTypes)
    onChange(postTypes.map(postType => postType.id))
  }

  return (
    <PostTypePicker
      value={postTypes}
      onChange={handleChanges}
      multiple={multiple}
      disabled={isInitialLoading}
      placeholder={getPlaceHolder()}
      noWrapper={noWrapper}
      className={className}
      invalid={invalid}
    />
  )
}
