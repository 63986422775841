import { Link } from 'react-router-dom'

import { Media } from '@tribeplatform/gql-client/types'
import { EntityImage } from '@tribeplatform/react-components/common/components'
import { T } from '@tribeplatform/react-components/i18n'

import { ActionButtonRenderer } from './ActionButtonRenderer.js'

interface Props {
  showText: boolean
  showIcon: boolean
  icon?: Media
  className?: string
}

export const SearchButton = ({
  showText,
  showIcon,
  icon,
  className,
}: Props) => {
  return (
    <ActionButtonRenderer
      showText={showText}
      showIcon={showIcon}
      variant="secondaryNeutral"
      as={Link}
      to="/search"
      className={className}
      icon={<EntityImage entity={{ image: icon }} size="xl" />}
      label={<T id="Generics.Search" defaultMessage="Search" />}
      tooltip={
        <T
          id="Blocks.SpaceHeader.SearchButton.Tooltip"
          defaultMessage="Search for posts, members, etc."
        />
      }
    />
  )
}
