import type { Space } from '@tribeplatform/gql-client/types'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { Card } from '@tribeplatform/react-ui-kit/Card'

import { Field } from './Field.js'

interface Props {
  space: Space
  fields: StaticField[]
  preview: boolean
  activeFieldId: string
  isSinglePost?: boolean
}
export const SpacesCardItem = ({
  space,
  fields,
  preview,
  activeFieldId,
  isSinglePost,
}: Props) => {
  const bannerField = fields.find(field => field.id === 'cardBanner')
  const replyListField = fields.find(field => field.id === 'replyList')
  return (
    <>
      <Card padding="md">
        {!!bannerField && (
          <Card.Media top>
            <Field
              key={bannerField.id}
              field={bannerField}
              space={space}
              activeFieldId={activeFieldId}
              viewStyle="card"
              preview={preview}
            />
          </Card.Media>
        )}
        <Card.Content>
          <div className="flex flex-wrap gap-4 items-center">
            {fields
              .filter(field => !['cardBanner', 'replyList'].includes(field.id))
              .map(field => (
                <Field
                  key={field.id}
                  field={field}
                  space={space}
                  activeFieldId={activeFieldId}
                  viewStyle="card"
                  preview={preview}
                />
              ))}
          </div>
        </Card.Content>
        {!!replyListField && !isSinglePost && (
          <Field
            key={replyListField.id}
            field={replyListField}
            space={space}
            activeFieldId={activeFieldId}
            viewStyle="card"
            preview={preview}
          />
        )}
      </Card>
      {!!replyListField && isSinglePost && (
        <Field
          key={replyListField.id}
          field={replyListField}
          space={space}
          activeFieldId={activeFieldId}
          viewStyle="card"
          preview={preview}
        />
      )}
    </>
  )
}
