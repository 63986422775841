import { clsx } from 'clsx'

import { Member } from '@tribeplatform/gql-client/types'
import {
  FieldView,
  getFieldSetting,
} from '@tribeplatform/react-components/CustomField'
import type { StaticField } from '@tribeplatform/react-components/Views'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { useSlate } from '@tribeplatform/react-slate-kit/hooks'

import { FieldProps } from '../types.js'

interface Props {
  field: StaticField
  member: Member
  highlighted: boolean
  stretchX: string
  viewStyle: FieldProps['viewStyle']
}
export const CustomField = ({
  field,
  member,
  highlighted,
  stretchX,
  viewStyle,
}: Props) => {
  const { mode } = useSlate()
  const {
    data: {
      memberFields: { fields: memberFields },
    },
  } = useNetwork()
  // If a field should stretch, then we should allow it to be empty and not return null
  const allowEmpty =
    (viewStyle === 'list' && field.settings?.stretchX) ||
    (viewStyle === 'gallery' && field.settings?.stretchY) ||
    (viewStyle === 'carousel' && field.settings?.stretchY)

  return (
    <>
      {memberFields &&
        memberFields
          ?.filter(
            memberField =>
              !memberField.archived && memberField.key === field.id,
          )
          .map(memberField => (
            <div
              key={memberField?.key}
              className={clsx(
                'empty:hidden min-w-0',
                viewStyle !== 'magazine' && stretchX,
                viewStyle === 'magazine' &&
                  getFieldSetting(memberField, 'category') !== 'socialLink' &&
                  stretchX,
                highlighted &&
                  'ring-2 rounded-sm ring-offset-surface ring-focused',
              )}
            >
              <FieldView
                field={memberField}
                entity={member}
                showLabel
                preview={mode !== 'live'}
                className={clsx(
                  'mt-0 min-w-0 break-words',
                  viewStyle === 'list' &&
                    getFieldSetting(memberField, 'category') !== 'socialLink' &&
                    'flex items-center space-s-2 space-y-0',
                )}
                allowEmpty={allowEmpty}
                showFieldPrivacyBadge={false}
              />
            </div>
          ))}
    </>
  )
}
