import { clsx } from 'clsx'

import { Post } from '@tribeplatform/gql-client/types'
import { AdaptiveReactionAction } from '@tribeplatform/react-components/Post'
import {
  ActionTypes,
  ReactionActionTypes,
  type ActionVariants,
  type ReactAction,
  type StaticField,
  ReactionCountPositions,
} from '@tribeplatform/react-components/Views'

interface Props {
  post: Post
  highlighted: boolean
  stretchX: string
  buttonVariant: StaticField['settings']['buttonVariant']
  showReactionCount: StaticField['settings']['showReactionCount']
  reactionCountPosition: StaticField['settings']['reactionCountPosition']
}
export const ReactButton = ({
  post,
  highlighted,
  stretchX,
  buttonVariant,
  showReactionCount,
  reactionCountPosition,
}: Props) => {
  const action: ReactAction = {
    id: 'react',
    defaultLabel: 'Like',
    label: 'React',
    enabled: true,
    type: ActionTypes.react,
    reactionActionType: ReactionActionTypes.adaptive,
    variant: buttonVariant as ActionVariants,
    isSingleReaction: true,
    reactions: [],
    showCount: showReactionCount ?? true,
    reactionCountPosition:
      reactionCountPosition ?? ReactionCountPositions.below,
  }

  return (
    <div
      className={clsx(
        'flex space-s-2 text-content-subdued items-center',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
      onClick={e => e.stopPropagation()}
    >
      <AdaptiveReactionAction post={post} action={action} />
    </div>
  )
}
