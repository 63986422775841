import { CSSProperties, FC, useEffect } from 'react'

import { Toast, useToaster } from 'react-hot-toast/headless'

import { useMeasure } from '../hooks/useMeasure.js'
import { Portal } from '../Portal/index.js'
import { runIfFn } from '../utils/index.js'

/**
 * Global toast live region, render this permanently at the end of the document
 */
export const ToastContainer: FC = () => {
  const {
    toasts,
    handlers: { calculateOffset, updateHeight, startPause, endPause },
  } = useToaster()

  const getPositionStyle = (toast: Toast) => {
    const offset = calculateOffset(toast, {
      reverseOrder: false,
      gutter: 8,
      defaultPosition: 'top-right',
    })

    const positionStyle: CSSProperties = {
      top: 16,
      right: 16,
      display: 'block',
      position: 'fixed',
      transition: `all 230ms cubic-bezier(.21,1.02,.73,1)`,
      transform: `translateY(${offset}px)`,
      animation: toast.visible
        ? 'toastFadeIn 100ms forwards'
        : 'toastFadeOut 100ms forwards',
    }
    return positionStyle
  }

  return (
    <>
      {toasts.map(toast => (
        <ToastWrapper
          id={toast.id}
          key={toast.id}
          onHeightUpdate={updateHeight}
          style={getPositionStyle(toast)}
          onMouseEnter={startPause}
          onMouseLeave={endPause}
        >
          {runIfFn(toast.message, toast)}
        </ToastWrapper>
      ))}
    </>
  )
}

interface ToastWrapperProps {
  id: string
  className?: string
  style?: React.CSSProperties
  onHeightUpdate: (id: string, height: number) => void
  children?: React.ReactNode
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}
const ToastWrapper = ({
  id,
  className,
  style,
  onHeightUpdate,
  children,
  onMouseEnter,
  onMouseLeave,
}: ToastWrapperProps) => {
  const [ref, { height }] = useMeasure()

  useEffect(() => {
    onHeightUpdate(id, height)
  }, [id, height, onHeightUpdate])

  return (
    <Portal idPrefix="toast">
      <div
        ref={ref}
        className={className}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </div>
    </Portal>
  )
}
