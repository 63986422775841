import { gql } from 'graphql-tag'

import {
  ReportRecordFields,
  reportRecordGQLFields,
} from './reportRecord.fields.js'

export const analyticsGQLQuery = (fields: ReportRecordFields) => gql`
  query Analytics($queries: [String!]!) {
    analytics(queries: $queries) {
      query
      records {
        ${reportRecordGQLFields(fields)}
      }
    }
  }
`
