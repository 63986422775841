import { cva } from 'class-variance-authority'

export const alertStyles = cva(
  ['relative flex flex-col sm:flex-row gap-3 p-4 shadow-card', 'border-base'],
  {
    variants: {
      status: {
        error: [
          'text-content-on-negative bg-surface-negative-subdued',
          'border-line-negative-subdued',
        ],
        warning: [
          'text-content-on-attention bg-surface-attention-subdued',
          'border-line-attention-subdued',
        ],
        success: [
          'text-content-on-positive bg-surface-positive-subdued',
          'border-line-positive-subdued',
        ],
        info: [
          'text-content-on-highlight bg-surface-highlight-subdued',
          'border-line-highlight-subdued',
        ],
        neutral: [
          'text-content-subdued bg-surface-subdued',
          'border-line-subdued',
        ],
        primary: [
          'text-content-on-primary bg-action-primary',
          'border-line-primary',
        ],
      },
      align: {
        leading: '',
        center: 'justify-center',
      },
      rounded: {
        true: 'rounded-base screen-rounded-none',
        false: 'rounded-none',
      },
    },
    defaultVariants: {
      rounded: true,
    },
  },
)
