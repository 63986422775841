import { useEffect } from 'react'

import { useKeypressListener } from '@tribeplatform/react-ui-kit/utils'

import type { MenuItem } from '../types.js'
import { MenuPortal } from './MenuPortal.js'
import { SubMenu } from './SubMenu.js'

interface SubMenuProps {
  isOpen: boolean
  items: MenuItem[]
  isPreviewMode?: boolean
  activeItemId?: string
  close: () => void
}
export const SubMenuWrapper = ({
  isOpen,
  items,
  isPreviewMode,
  activeItemId,
  close,
}: SubMenuProps) => {
  useKeypressListener('Escape', close, 'keydown')

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', close)
    }

    return () => {
      document.removeEventListener('click', close)
    }
  }, [isOpen, close])

  return (
    <MenuPortal shouldOpen={isOpen} transition={!isPreviewMode}>
      <SubMenu
        items={items}
        activeItemId={activeItemId}
        isPreviewMode={isPreviewMode}
        close={close}
      />
    </MenuPortal>
  )
}
