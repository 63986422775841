import { cva } from 'class-variance-authority'

export const cardStyles = cva(
  [
    'border border-card',
    'flex flex-col',
    'text-content-subdued',
    'transition duration-200',
  ],
  {
    variants: {
      justifyContent: {
        start: 'justify-start',
        between: 'justify-between',
      },
      transparent: {
        true: '',
        false: 'bg-surface shadow-card',
      },
      attached: {
        top: 'sm:rounded-b-card',
        bottom: 'sm:rounded-t-card',
        left: 'sm:rounded-e-card',
        right: 'sm:rounded-s-card',
        all: '',
        none: 'sm:rounded-card',
      },
    },
    defaultVariants: {
      justifyContent: 'between',
      transparent: false,
      attached: 'none',
    },
  },
)

export const cardMediaStyles = cva(['overflow-hidden -mx-[1px] empty:hidden'], {
  variants: {
    top: {
      true: 'sm:rounded-t-card -mt-[1px]',
      false: '',
    },
    bottom: {
      true: 'sm:rounded-b-card -mb-[1px]',
      false: '',
    },
    bordered: {
      true: 'border border-x-1',
      false: '',
    },
  },
  compoundVariants: [
    {
      top: true,
      bordered: true,
      className: 'border-t-1',
    },
    {
      bottom: true,
      bordered: false,
      className: 'border-b-1',
    },
    {
      top: false,
      bordered: true,
      className: 'border-t-0',
    },
    {
      bottom: false,
      bordered: true,
      className: 'border-b-0',
    },
  ],
  defaultVariants: {
    top: false,
    bottom: false,
  },
})
