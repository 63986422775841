import { cloneElement, FC, forwardRef, ReactNode } from 'react'

import { clsx } from 'clsx'

import { SvgIcon } from '../Icon/SvgIcon.js'
import {
  selectItemIconStyles,
  selectItemsStyles,
  selectItemStyles,
} from '../Select/Select.styles.js'
import { HTMLTribeProps } from '../types/index.js'
import { FloatingWrapper } from '../utils/floating/index.js'
import { getValidChildren, runIfFn } from '../utils/index.js'
import { useSearchableSelect } from './SearchableSelectContext.js'

export type SearchableSelectItemsProps = HTMLTribeProps<'ul'> & {
  static?: boolean
}
export const SearchableSelectItems = forwardRef<
  HTMLUListElement,
  SearchableSelectItemsProps
>((props, ref) => {
  const { children, className, static: staticProp = false, ...rest } = props

  const { isOpen, getMenuProps } = useSearchableSelect()

  const validChildren = getValidChildren(children)

  const clones = validChildren.map((child, index) => {
    const childProps = {
      index,
      ...child.props,
    }

    return cloneElement(child, childProps)
  })

  const open = isOpen || staticProp

  return (
    <FloatingWrapper open={open} sameWidth>
      <ul
        className={clsx(selectItemsStyles(), className)}
        ref={ref}
        {...getMenuProps({}, { suppressRefError: true })}
        {...rest}
      >
        {clones}
      </ul>
    </FloatingWrapper>
  )
})

interface SearchableSelectItemRenderProps {
  selected: boolean
  active: boolean
}
export type SearchableSelectItemProps<T> = Omit<
  HTMLTribeProps<'li'>,
  'value' | 'children'
> & {
  value: T
  index?: number
  disabled?: boolean
  children?: ReactNode | ((props: SearchableSelectItemRenderProps) => ReactNode)
}
export const SearchableSelectItem: FC<
  SearchableSelectItemProps<unknown>
> = props => {
  const { value, children, disabled, index, className, ...rest } = props

  const { selectedItem, getItemProps, highlightedIndex } = useSearchableSelect()

  const selected = selectedItem === value
  const active = highlightedIndex === index

  return (
    <li
      {...getItemProps({ item: value, index, disabled })}
      className={selectItemStyles({
        active,
        selected,
        disabled,
        className,
      })}
      {...rest}
    >
      <div className="min-w-0 truncate">
        {runIfFn(children, {
          selected,
          active,
        })}
      </div>
      {selected && (
        <span className="absolute inset-y-0 end-0 flex items-center pe-4">
          <SvgIcon
            className={selectItemIconStyles({ active })}
            aria-hidden="true"
            name="check"
          />
        </span>
      )}
    </li>
  )
}

export type SearchableSelectItemsEmptyProps = HTMLTribeProps<'div'>

export const SearchableSelectItemsEmpty: FC<
  SearchableSelectItemsEmptyProps
> = props => {
  const { children, className, ...rest } = props

  return (
    <div
      className={selectItemStyles({
        className,
      })}
      {...rest}
    >
      <span className="font-normal block truncate">{children}</span>
    </div>
  )
}
