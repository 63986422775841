import type { QueryKey } from '../../lib/react-query/QueryKey.js'

export const KeyConstructor = <VariablesType, FieldsType>(key: string) => {
  return (options?: {
    variables?: Partial<VariablesType>
    fields?: Partial<FieldsType>
  }): QueryKey => {
    const { variables, fields } = options || {}
    return [key, { ...(fields && { fields }), ...(variables && { variables }) }]
  }
}
