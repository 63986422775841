import { LayoutBlockProps } from '../../types/block.types.js'

export enum SectionLayout {
  Single = '1',
  OneVOne = '1-1',
  TwoVOne = '2-1',
  OneVTwo = '1-2',
  Three = '3',
  Four = '4',
}

export enum Spacing {
  None = 'None',
  ExtraSmall = 'ExtraSmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  ExtraLarge = 'ExtraLarge',
}

export type SectionProps = Pick<
  LayoutBlockProps,
  'containerProps' | 'HighlightWrapper'
> & {
  layout: SectionLayout
  gap: Spacing
  horizontalPadding: Spacing
  verticalPadding: Spacing
  horizontalMargin: Spacing
  verticalMargin: Spacing
  reverse?: boolean
  restrictMaxWidth?: boolean
  hideOnMobile?: boolean
  hideOnTablet?: boolean
  hideOnDesktop?: boolean
  children?: React.ReactNode
}
