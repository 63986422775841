import { I18nShape } from '@tribeplatform/react-components/i18n'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { CardContentBlock as Component } from './Block.js'

export const RegisteredCardContentBlock = (
  _intl: I18nShape,
): RegisteredBlock<Record<string, never>> => ({
  recommendedName: 'Card.Content',
  Component,
  config: {
    type: BlockType.BuildingBlock,
  },
})
