import type { Member } from '@tribeplatform/gql-client/types'
import type { StaticField, View } from '@tribeplatform/react-components/Views'
import { GridList } from '@tribeplatform/react-ui-kit/Layout'

import { getColumns } from '../utils/utils.js'
import { MembersGalleryItem } from './MembersGalleryItem.js'

interface Props {
  members: Member[]
  fields: StaticField[]
  gallerySize: View['gallerySize']
  activeFieldId?: string
  showMoreButton: JSX.Element
}
export const MembersGallery = ({
  members,
  fields,
  gallerySize = 'medium',
  activeFieldId,
  showMoreButton,
}: Props) => {
  const columns = getColumns(gallerySize)

  return (
    <>
      <GridList columns={columns}>
        {members.map(member => (
          <MembersGalleryItem
            key={member.id}
            member={member}
            fields={fields.filter(
              field =>
                field.enabled &&
                !(field.styles && field.styles.indexOf('gallery') === -1),
            )}
            activeFieldId={activeFieldId}
          />
        ))}
      </GridList>
      {!!showMoreButton && <div className="mt-2">{showMoreButton}</div>}
    </>
  )
}
