import { useStaticIntl } from '@tribeplatform/react-components/i18n'
import { RadioGroup } from '@tribeplatform/react-ui-kit/RadioGroup'

import { SectionLayout } from '../types.js'
import { layoutOptionsFactory } from '../utils/selectItems.js'
import { ColorfulIcon } from './ColorfulIcon.js'

interface Props {
  value: SectionLayout
  onChange: (value: SectionLayout) => void
}

export const LayoutPicker = ({ onChange, value }: Props) => {
  const layouts = useStaticIntl(layoutOptionsFactory)

  return (
    <RadioGroup
      value={value}
      variant="cards"
      onChange={(value: SectionLayout) => onChange(value)}
    >
      <RadioGroup.Items className="grid grid-cols-3 gap-2">
        {layouts.map(({ value, icon, text }) => (
          <RadioGroup.Item
            key={value}
            value={value}
            className="hover:bg-surface-hovered rounded-base !m-0"
            hideRadio
          >
            <div className="flex flex-col gap-1 justify-center items-center whitespace-nowrap">
              <ColorfulIcon>{icon}</ColorfulIcon>
              {text}
            </div>
          </RadioGroup.Item>
        ))}
      </RadioGroup.Items>
    </RadioGroup>
  )
}
