import { useCallback, useState } from 'react'

import { clsx } from 'clsx'

import { Icon } from '../Icon/Icon.js'
import { SvgIcon } from '../Icon/SvgIcon.js'
import type { IconComponentType } from '../Icon/types.js'
import { HTMLTribeProps } from '../types/index.js'
import { alertStyles } from './Alert.styles.js'
import { AlertActions } from './AlertActions.js'
import { AlertIcon } from './AlertIcon.js'
import { AlertStyleProps } from './types.js'

export type AlertProps = HTMLTribeProps<'div'> &
  AlertStyleProps & {
    title?: string
    withClose?: boolean
    onClose?: () => void
    icon?: IconComponentType
  }

/**
 * Alerts are used to communicate a state that affects a system, feature or page.
 */
export const Alert = ({
  as,
  align = 'leading',
  status,
  title,
  children,
  withClose = false,
  onClose,
  rounded,
  className,
  icon: iconProp,
  ...rest
}: AlertProps) => {
  const [close, setClose] = useState(false)

  const onCloseHandler = useCallback(() => {
    setClose(true)
    onClose?.()
  }, [onClose])

  if (close) {
    return null
  }

  const Component = as || 'div'

  const hasIcon = ['error', 'warning', 'success', 'info'].includes(status)
  const icon = iconProp ?? (hasIcon && <AlertIcon status={status} />)

  return (
    <Component
      className={alertStyles({
        status,
        align,
        rounded,
        className,
      })}
      {...rest}
    >
      {icon && (
        <div className="shrink-0">
          <Icon size="lg">{icon}</Icon>
        </div>
      )}
      <div
        className={clsx(
          'min-w-0 grow space-y-1',
          align === 'center' && 'text-center',
        )}
      >
        {title && (
          <h3
            className={clsx(
              'text-sm font-medium break-words',
              withClose && 'sm:pe-10',
            )}
          >
            {title}
          </h3>
        )}
        {children && <div className="text-sm empty:hidden">{children}</div>}
      </div>
      {withClose && (
        <span className="absolute top-0 end-0 flex items-center pe-2 pt-3">
          <button
            onClick={onCloseHandler}
            type="button"
            className="inline-flex rounded-base p-1.5"
          >
            <span className="sr-only">Dismiss</span>
            <SvgIcon name="close" />
          </button>
        </span>
      )}
    </Component>
  )
}

Alert.Actions = AlertActions
