import type { getTokensType } from '../@types/index.js'

export const getTokens: getTokensType = async (client, input) => {
  const { networkDomain, networkId, otp } = input

  if (!networkDomain && !networkId && !otp) {
    throw new Error(
      'Please provide either of these arguments to proceed: networkDomain, networkId or otp',
    )
  }

  const authToken = await client.getTokens(
    {
      networkDomain,
      networkId,
      otp,
    },
    'basic',
  )

  client.setToken(authToken?.accessToken)

  return { authToken }
}
