import type { InfiniteData } from '@tanstack/react-query'

import type { MemberFields } from '@tribeplatform/gql-client/graphql'
import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  Member,
  MutationUpdateMemberArgs,
  PaginatedMember,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import {
  getAuthMemberKey,
  getAuthTokenKey,
} from '../../utils/keys/authToken.keys.js'
import { getMemberKey, getMembersKey } from '../../utils/keys/member.key.js'
import { getNetworkKey } from '../../utils/keys/network.key.js'
import { useAuthMember } from '../auth/useAuthMember.js'
import { getCachedMember } from '../cache/useCachedMember.js'

export const useUpdateMember = (options?: {
  fields?: MemberFields
  useMutationOptions?: UseMutationOptions<
    Member,
    ClientError,
    MutationUpdateMemberArgs,
    { snapshot: Member }
  >
}) => {
  const { useMutationOptions, fields = 'default' } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()
  const { data: authMember } = useAuthMember()

  return useMutation<
    Member,
    ClientError,
    MutationUpdateMemberArgs,
    { snapshot: Member }
  >((input: MutationUpdateMemberArgs) => client.members.update(input, fields), {
    onMutate: async variables => {
      const memberInput = variables.input

      const memberKey = getMemberKey({ variables: { id: variables.id } })
      await queryClient.cancelQueries(memberKey)
      const snapshot = getCachedMember(variables.id, queryClient)

      queryClient.setQueriesData<Member>(memberKey, old => ({
        ...old,
        ...memberInput,
        attributes: {
          ...old?.attributes,
          ...memberInput?.attributes,
        },
      }))

      const membersKey = getMembersKey()
      queryClient.setQueriesData<InfiniteData<PaginatedMember>>(
        membersKey,
        old => ({
          ...old,
          pages: old?.pages?.map(page => ({
            ...page,
            nodes: page?.nodes?.map(member => ({
              ...member,
              flagged:
                variables?.id === member?.id
                  ? variables?.input?.flagged ?? member.flagged
                  : member.flagged,
            })),
            edges: page?.edges?.map(edge => ({
              ...edge,
              node: {
                ...edge?.node,
                flagged:
                  variables?.id === edge?.node?.id
                    ? variables?.input?.flagged ?? edge?.node?.flagged
                    : edge?.node?.flagged,
              },
            })),
          })),
        }),
      )

      return { snapshot }
    },
    onSuccess: (updatedMember, vars, context) => {
      const memberKey = getMemberKey({ variables: { id: updatedMember.id } })
      queryClient.setQueriesData<Member>(memberKey, oldMember => ({
        ...oldMember,
        ...updatedMember,
      }))
      if (authMember.id === updatedMember.id) {
        const authMemberKey = getAuthMemberKey()
        queryClient.setQueriesData<Member>(authMemberKey, oldMember => ({
          ...oldMember,
          ...updatedMember,
        }))
      }
      useMutationOptions?.onSuccess?.(updatedMember, vars, context)
    },
    onError: (err, newMember, context) => {
      const memberKey = getMemberKey({ variables: { id: newMember.id } })
      queryClient.setQueriesData<Member>(memberKey, context.snapshot)

      queryClient.invalidateQueries(memberKey)

      useMutationOptions?.onError?.(err, newMember, context)
    },
    onSettled: () => {
      const membersKey = getMembersKey()
      const authTokenKey = getAuthTokenKey()
      const networkKey = getNetworkKey()

      queryClient.invalidateQueries(membersKey)
      queryClient.invalidateQueries(authTokenKey)
      queryClient.invalidateQueries(networkKey)
    },
    ...useMutationOptions,
  })
}
