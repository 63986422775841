import { AvatarSize, LegacyAvatarSize } from './types.js'

const sizesMap: Record<LegacyAvatarSize, AvatarSize> = {
  xxs: 'lg',
  xs: 'xl',
  sm: '2x',
  md: '2.5x',
  lg: '3x',
  xl: '4x',
  '2xl': '6x',
  '3xl': '8x',
}

export const legacySizeMapper = (size: LegacyAvatarSize): AvatarSize =>
  sizesMap[size]
