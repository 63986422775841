import type { ClientError } from '@tribeplatform/gql-client/lib'
import type {
  MutationRequestSpaceMembershipArgs,
  SpaceJoinRequest,
} from '@tribeplatform/gql-client/types'

import {
  useMutation,
  UseMutationOptions,
} from '../../lib/react-query/useMutation.js'
import { useQueryClient } from '../../lib/react-query/useQueryClient.js'
import { useTribeClient } from '../../useTribeClient.js'
import { getSpaceKey } from '../../utils/keys/space.key.js'
import { getMemberSpaceMembershipRequestKey } from '../../utils/keys/spaceMembership.key.js'

export const useRequestSpaceMembership = (options?: {
  useMutationOptions?: UseMutationOptions<
    SpaceJoinRequest,
    ClientError,
    MutationRequestSpaceMembershipArgs
  >
}) => {
  const { useMutationOptions } = options || {}
  const { client } = useTribeClient()
  const queryClient = useQueryClient()

  return useMutation<
    SpaceJoinRequest,
    ClientError,
    MutationRequestSpaceMembershipArgs
  >(
    (input: MutationRequestSpaceMembershipArgs) =>
      client.spaceMembership.request(input),
    {
      onSettled: (data, error, variables) => {
        const spaceKey = getSpaceKey({ variables: { id: variables.spaceId } })
        queryClient.invalidateQueries(spaceKey)
        queryClient.invalidateQueries(getMemberSpaceMembershipRequestKey())
      },
      ...useMutationOptions,
    },
  )
}
