import { Card } from '@tribeplatform/react-ui-kit/Card'
import { Container } from '@tribeplatform/react-ui-kit/Layout'

import { T } from '../../i18n/components/T.js'

export const CMSEmptyState = () => {
  return (
    <Container size="lg">
      <Card className="mt-5">
        <Card.Content className="flex justify-center">
          <T
            defaultMessage="Choose a CMS model to begin editing or create a new one"
            id="Admin.CMS.Model.EmptyState"
          />
        </Card.Content>
      </Card>
    </Container>
  )
}
