import { ComponentPropsWithoutRef } from 'react'

import { Listbox } from '@headlessui/react'
import { clsx } from 'clsx'

import { SvgIcon } from '../Icon/SvgIcon.js'
import {
  inputAriaExpandedStyles,
  inputIconStyles,
  inputStyles,
} from '../Input/index.js'
import { useFloatingContext } from '../utils/floating/index.js'
import { useSelect } from './SelectContext.js'

export type SelectButtonProps = ComponentPropsWithoutRef<'button'>

export const SelectButton = ({
  children,
  className,
  placeholder,
  ...rest
}: SelectButtonProps) => {
  const { invalid, disabled } = useSelect()
  const { reference } = useFloatingContext()

  // TODO support compact size
  const size = 'default'

  return (
    <div ref={reference}>
      <Listbox.Button
        className={clsx(
          inputStyles({
            size,
            invalid,
            disabled,
            className,
          }),
          inputAriaExpandedStyles({ invalid }),
          'pe-10 text-start cursor-default',
        )}
        {...rest}
      >
        {() => (
          <>
            <span className="block truncate">
              {children || (
                <div className="text-content-disabled text-label-md">
                  {placeholder || '\u00A0'}
                </div>
              )}
            </span>
            <span
              className={clsx(
                'absolute inset-y-0 end-0 flex items-center pe-2 pointer-events-none',
              )}
            >
              <SvgIcon
                className={inputIconStyles({ size })}
                aria-hidden="true"
                name="selector"
              />
            </span>
          </>
        )}
      </Listbox.Button>
    </div>
  )
}
