import { T } from '@tribeplatform/react-components/i18n'
import { Card } from '@tribeplatform/react-ui-kit/Card'

export const EmptyState = () => {
  return (
    <Card>
      <Card.Content>
        <div className="text-sm text-content-subdued text-center">
          <T defaultMessage="No posts to show here" id="Posts.EmptyState" />
        </div>
      </Card.Content>
    </Card>
  )
}
