import { lazy } from 'react'

import { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { MainMenuBlock as Component } from './Block.js'
import { mainMenuDefaultItemsFactory } from './constants.js'
import { MainMenuProps } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.MainMenuBlockSettings })),
)

export const RegisteredMainMenuBlock = (
  intl: I18nShape,
): RegisteredBlock<MainMenuProps> => {
  const { $t } = intl
  const mainMenuDefaultItems = mainMenuDefaultItemsFactory(intl)

  return {
    recommendedName: 'MainMenu',
    Component,
    config: {
      type: BlockType.Block,
      displayName: $t({
        id: 'Blocks.MainMenu.DisplayName',
        defaultMessage: 'List menu',
      }),
      usable: false,
      Settings,
      Icon: <SvgIcon name="list-unordered" />,
      defaultProps: {
        items: mainMenuDefaultItems,
      },
    },
  }
}
