import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { Tooltip } from '@tribeplatform/react-ui-kit/Tooltip'

import { useI18n } from '../i18n/providers/I18nProvider.js'

export const PostHiddenIndicator = ({
  isReply = false,
}: {
  isReply?: boolean
}) => {
  const { $t } = useI18n()

  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <SvgIcon className="me-2" name="eye-off" inline />
      </Tooltip.Trigger>
      <Tooltip.Panel>
        {isReply
          ? $t({
              id: 'Post.PostHiddenIndicator.ReplyIndicator',
              defaultMessage:
                'People won’t see this reply. It will still be available for the author, admins, and moderators.',
            })
          : $t({
              id: 'Post.PostHiddenIndicator.PostIndicator',
              defaultMessage:
                'People won’t see this post in their Feed. It will still be available for the author and in search results.',
            })}
      </Tooltip.Panel>
    </Tooltip>
  )
}
