import { gql } from 'graphql-tag'

export const redirectGQLQuery = () => gql`
  query Redirect($url: String!) {
    redirect(url: $url) {
      resolvedUrl
      url
    }
  }
`
