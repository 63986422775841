import { Fragment, useMemo, useState } from 'react'
import type { ReactNode } from 'react'

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import type { Active, UniqueIdentifier } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable'

import { DefaultView } from './DefaultView.js'
import { DragHandle, SortableItem } from './SortableItem.js'
import { SortableOverlay } from './SortableOverlay.js'
import { reorder } from './utils.js'

export interface DragResult {
  source: {
    index: number
  }
  destination: {
    index: number
  }
}
export interface BaseItem {
  id: UniqueIdentifier
}

export interface SortableListProps<T extends BaseItem> {
  id?: string
  items: T[]
  renderItem(item: T, index: number): ReactNode
  disabled?: boolean
  onDragEnd: (result: DragResult) => void
}

const Sortable = <T extends BaseItem>(props: SortableListProps<T>) => {
  const { id, items, onDragEnd, renderItem, disabled = false } = props

  const [active, setActive] = useState<Active | null>(null)
  const activeItem = useMemo(
    () => items.find(item => item.id === active?.id),
    [active, items],
  )
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      onDragStart={({ active }) => {
        setActive(active)
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id)
          const overIndex = items.findIndex(({ id }) => id === over.id)

          onDragEnd({
            source: {
              index: activeIndex,
            },
            destination: overIndex !== -1 && {
              index: overIndex,
            },
          })
        }
        setActive(null)
      }}
      onDragCancel={() => {
        setActive(null)
      }}
    >
      <SortableContext items={items} disabled={disabled}>
        <ul className="flex flex-col space-y-2" role="application" id={id}>
          {items.map((item, index) => (
            <Fragment key={item.id}>{renderItem(item, index)}</Fragment>
          ))}
        </ul>
      </SortableContext>
      <SortableOverlay>
        {activeItem ? renderItem(activeItem, -1) : null}
      </SortableOverlay>
    </DndContext>
  )
}

export const SortableList = Object.assign(Sortable, {
  Item: SortableItem,
  Handle: DragHandle,
  DefaultView,
  reorder,
})
