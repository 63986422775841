import { clsx } from 'clsx'

import { Member } from '@tribeplatform/gql-client/types'
import { BadgeSize } from '@tribeplatform/react-ui-kit/Badge'

import {
  getBadgeSetting,
  MemberBadge,
  MemberBadgeLocation,
} from './MemberBadge.js'

export const MemberBadges = ({
  member,
  size = 'sm',
  location,
  singleLine = true,
  className,
}: {
  member: Member
  size?: BadgeSize
  location?: MemberBadgeLocation
  singleLine?: boolean
  className?: string
}) => {
  let memberBadges = member?.badges || []
  if (location) {
    memberBadges = memberBadges.filter(memberBadge => {
      if (!memberBadge?.badge) {
        return false
      }
      const locations = getBadgeSetting(memberBadge.badge, 'locations') || []
      return locations.indexOf(location) !== -1
    })
  }
  if (!memberBadges?.length) {
    return null
  }

  return (
    <div
      className={clsx(
        'flex gap-2 items-center min-w-0 overflow-hidden',
        singleLine ? 'flex-nowrap' : 'flex-wrap',
        className,
      )}
    >
      {memberBadges.map(memberBadge => (
        <MemberBadge
          key={memberBadge?.badgeId}
          memberBadge={memberBadge}
          size={size}
          showTooltip
          className={clsx('cursor-default', singleLine && 'flex-none')}
        />
      ))}
    </div>
  )
}
