import { EntityImage } from '@tribeplatform/react-components/common/components'
import { useRouter, useRouterPath } from '@tribeplatform/react-sdk'
import { NavVertical } from '@tribeplatform/react-ui-kit/Sidebar'

import { BC } from '../../types/index.js'
import { useMobileMenu } from '../Navbar/providers/MobileMenuProvider.js'
import { LinkMenuProps } from './types.js'

export const LinkMenuBlock: BC<LinkMenuProps> = ({ items, title, ...rest }) => {
  const { Link } = useRouter()
  const { close } = useMobileMenu()
  const pathname = useRouterPath()
  if (!items) {
    return null
  }

  return (
    <NavVertical {...rest}>
      <NavVertical.Group>
        {!!title && <NavVertical.GroupHeader>{title}</NavVertical.GroupHeader>}
        {items.map(item => {
          let isActive = false
          if (item?.href) {
            if (item.href === '/') {
              isActive = pathname === item.href
            } else {
              isActive = pathname?.indexOf(item?.href) === 0
            }
          }
          return (
            <NavVertical.Item
              key={item?.text}
              variant="inherit"
              as={Link}
              href={item?.href}
              target={item?.openInNewWindow ? '_blank' : '_self'}
              active={isActive}
              leadingIcon={
                <div className="-ms-1 me-3 w-6 h-6 flex-shrink-0 flex items-center justify-center">
                  <EntityImage
                    size="lg"
                    entity={{ image: item?.image, name: item?.text }}
                  />
                </div>
              }
              onClick={close}
            >
              {item?.text}
            </NavVertical.Item>
          )
        })}
      </NavVertical.Group>
    </NavVertical>
  )
}
