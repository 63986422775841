import { PostListProps } from '@tribeplatform/react-components/PostList'
import { PostListBlock as DumbPostListBlock } from '@tribeplatform/react-slate-kit/blocks'
import { BC } from '@tribeplatform/react-slate-kit/types'
import { List } from '@tribeplatform/react-ui-kit/Layout'

import { PendingModerationAlert } from '../../components/Moderation/PendingModerationAlert.js'
import { useQuery } from '../../hooks/useQuery.js'

export const PostListBlock: BC<PostListProps> = props => {
  const { spaceId } = props
  const { tag: tagSlug, tag_id: tagId } = useQuery() || {}

  return (
    <List>
      <List.Item>
        {!!spaceId && <PendingModerationAlert spaceId={spaceId} />}
      </List.Item>
      <List.Item>
        <DumbPostListBlock {...props} tagSlug={tagSlug} tagId={tagId} />
      </List.Item>
    </List>
  )
}
