import { Children, useEffect, useState } from 'react'

import { clsx } from 'clsx'
import { useFormContext } from 'react-hook-form'

import { Form } from '@tribeplatform/react-components/Form'
import { Button as DumbButton } from '@tribeplatform/react-ui-kit/Button'
import { Link } from '@tribeplatform/react-ui-kit/Link'

import { useSlate } from '../../hooks/index.js'
import { BC, CallbackExtraContext } from '../../types/index.js'
import { ButtonProps } from './types.js'
import { getIcon } from './utils.js'

export const ButtonBlock: BC<ButtonProps> = props => {
  const {
    callbackId,
    leadingIcon,
    trailingIcon,
    autoDisabled = true,
    autoLoading = true,
    text,
    link,
    children: givenChildren,
    ...rest
  } = props
  const {
    extraContext: {
      slate: { callback },
    },
  } = useSlate<CallbackExtraContext>()
  const formContext = useFormContext()
  const {
    control: enabled,
    formState: { isSubmitting, isDirty, isValid },
  } = formContext || {
    formState: {},
  }
  const isLoading = isSubmitting
  const isDisabled = !isDirty || !isValid

  const childrenItems = Children.toArray(givenChildren)
  const children = childrenItems.length ? childrenItems : text

  const [loading, setLoading] = useState(isLoading)

  useEffect(() => {
    if (isLoading !== undefined) {
      setLoading(isLoading)
    }
  }, [isLoading])

  if (link) {
    return (
      <DumbButton
        as={Link}
        href={link}
        {...rest}
        leadingIcon={leadingIcon ? getIcon(leadingIcon) : null}
        className={clsx(text && 'justify-center')}
      >
        {children}
      </DumbButton>
    )
  }

  if (enabled) {
    return (
      <Form.Actions>
        <DumbButton
          {...rest}
          loading={
            autoLoading ? rest.type === 'submit' && loading : rest.loading
          }
          disabled={autoDisabled ? loading || isDisabled : rest.disabled}
          leadingIcon={leadingIcon ? getIcon(leadingIcon) : null}
          className={clsx(text && 'justify-center')}
        >
          {children}
        </DumbButton>
      </Form.Actions>
    )
  }
  return (
    <DumbButton
      onClick={async event => {
        if (rest?.onClick) {
          rest.onClick(event)
        } else if (callbackId && callback) {
          setLoading(true)
          try {
            await callback(callbackId)
            // We are catching the error here because we don't want to crash the app
            // We are already logging the error in the callback function
            // eslint-disable-next-line no-empty
          } catch {}
          setLoading(false)
        }
      }}
      {...rest}
      loading={autoLoading ? loading : rest.loading}
      leadingIcon={leadingIcon ? getIcon(leadingIcon) : null}
      trailingIcon={trailingIcon ? getIcon(trailingIcon) : null}
      className={clsx(text && 'justify-center')}
    >
      {children}
    </DumbButton>
  )
}
