import { useMemo, useState } from 'react'

import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect.js'

// eslint-disable-next-line unused-imports/no-unused-vars
export const noop = (props: unknown) => {
  // do nothing
}

export type UseMeasureRect = Pick<
  DOMRectReadOnly,
  'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'
>
export type UseMeasureRef<E extends Element = Element> = (element: E) => void
export type UseMeasureResult<E extends Element = Element> = [
  UseMeasureRef<E>,
  UseMeasureRect,
]

const defaultState: UseMeasureRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
}

/**
 * React sensor hook that tracks dimensions of an HTML element using the Resize Observer API.
 *
 * Usage:
 *
 * `const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();`
 */
const useMeasureInner = <
  E extends Element = Element,
>(): UseMeasureResult<E> => {
  const [element, ref] = useState<E | null>(null)
  const [rect, setRect] = useState<UseMeasureRect>(defaultState)

  const observer = useMemo(
    () =>
      new window.ResizeObserver(entries => {
        if (entries[0]) {
          const { x, y, width, height, top, left, bottom, right } =
            entries[0].contentRect
          setRect({ x, y, width, height, top, left, bottom, right })
        }
      }),
    [],
  )

  useIsomorphicLayoutEffect(() => {
    if (!element) {
      return
    }
    observer.observe(element)
    return () => {
      observer.disconnect()
    }
  }, [element])

  return [ref, rect]
}

export const useMeasure =
  typeof window !== 'undefined' && typeof window.ResizeObserver !== 'undefined'
    ? useMeasureInner
    : ((() => [noop, defaultState]) as typeof useMeasureInner)
