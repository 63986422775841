import { clsx } from 'clsx'

import { getLocaleDetails } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

interface Props {
  locale: string
  className?: string
}
export const LanguageIcon = ({ locale, className = '' }: Props) => {
  const { shortCode } = getLocaleDetails(locale)

  if (!shortCode) {
    return <SvgIcon name="globe" size="xl" className={className} />
  }

  return (
    <div className={clsx('w-5 h-5', className)}>
      <em-emoji shortcodes={shortCode} size={20} />
    </div>
  )
}
