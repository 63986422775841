import { clsx } from 'clsx'

import { Member } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'
import type { TextVariant } from '@tribeplatform/react-ui-kit/Text'
import { Text } from '@tribeplatform/react-ui-kit/Text'

interface Props {
  member: Member
  highlighted: boolean
  stretchX: string
  titleVariant: TextVariant
}
export const MemberName = ({
  member,
  highlighted,
  stretchX,
  titleVariant,
}: Props) => {
  const { Link } = useRouter()

  return (
    <Link
      href={member?.relativeUrl}
      className={clsx(stretchX, 'min-w-0 break-words')}
      onClick={e => e.stopPropagation()}
    >
      <Text
        as="h2"
        variant={titleVariant}
        className={clsx(
          'line-clamp-3',
          highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        )}
        translate="no"
      >
        {member?.name}
      </Text>
    </Link>
  )
}
