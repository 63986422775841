import { clsx } from 'clsx'

import { Member } from '@tribeplatform/gql-client/types'

interface Props {
  member: Member
  highlighted: boolean
  stretchX: string
}
export const Tagline = ({ member, highlighted, stretchX }: Props) => {
  return member?.tagline ? (
    <div
      className={clsx(
        'text-content-subdued text-sm break-words min-w-0',
        highlighted && 'ring-2 rounded-sm ring-offset-surface ring-focused',
        stretchX,
      )}
    >
      {member?.tagline}
    </div>
  ) : null
}
