import { createContext, useContext } from 'react'

import { RawIntlProvider, IntlContext } from 'react-intl'

import { hasLocalStorage } from '@bettermode/common/local-storage-detect'

import type { I18nShape, TranslateFunctionType, I18nObject } from '../types.js'
import { getLanguageDirection } from '../utils/getLanguageDirection.js'

const I18nContext = createContext<I18nShape>(null)
const { Provider } = I18nContext

export const useI18n = (): I18nShape => {
  const intl = useContext(I18nContext)
  return intl
}

interface I18nProviderProps {
  children: React.ReactNode
  i18n?: I18nObject
}

export const I18nProvider = ({ children, i18n }: I18nProviderProps) => {
  const { isDebugMode, debugFormatter } = useDebugMode()

  return (
    <RawIntlProvider value={i18n}>
      <IntlContext.Consumer>
        {intl => {
          const $t = isDebugMode
            ? debugFormatter(intl.formatMessage)
            : intl.formatMessage

          const localeCode = intl.locale || intl.defaultLocale
          const languageDirection = getLanguageDirection(localeCode)

          return (
            <Provider
              value={{
                localeCode,
                languageDirection,
                $t: $t as TranslateFunctionType,
                formatList: intl.formatList,
                formatDisplayName: intl.formatDisplayName,
                formatDate: intl.formatDate,
                formatNumber: intl.formatNumber,
                getMessageByDynamicId: (id, defaultMessage) => {
                  if (intl.messages[id]) {
                    // We have already confirmed the existence of the message
                    // eslint-disable-next-line formatjs/enforce-default-message
                    return $t({ id })
                  }
                  return defaultMessage
                },
              }}
            >
              {children}
            </Provider>
          )
        }}
      </IntlContext.Consumer>
    </RawIntlProvider>
  )
}

/**
 * Enabling debug mode can break markup stylings. Use it only to validate
 * whether a certain string is passed through the translation layer or not.
 * Do not validate layout and other aspects of the UI with it.
 */
const useDebugMode = (): {
  isDebugMode: boolean
  debugFormatter: ($t: TranslateFunctionType) => TranslateFunctionType
} => {
  if (!hasLocalStorage() || localStorage.getItem('i18n-debug') !== 'true') {
    return {
      isDebugMode: false,
      debugFormatter: ($t: TranslateFunctionType) => $t,
    }
  }

  return {
    isDebugMode: true,
    debugFormatter: ($t: TranslateFunctionType) => {
      const debugCharEnd = localStorage.getItem('i18n-debug-char-end') || ' ⦘'
      const debugCharStart =
        localStorage.getItem('i18n-debug-char-start') || '⦗ '

      return ((descriptor, values, opts) => {
        const message = $t(descriptor, values, opts)
        if (typeof message === 'string') {
          return `${debugCharStart}${message}${debugCharEnd}`
        }
        return [debugCharStart, ...message, debugCharEnd]
      }) as TranslateFunctionType
    },
  }
}
