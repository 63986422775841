import { TextVariant } from '@tribeplatform/react-ui-kit/Text'

export const getPrivacyIconSize = (size: TextVariant): TextVariant => {
  switch (size) {
    case 'headingMd':
      return 'heading2xs'
    case 'headingLg':
      return 'headingXs'
    case 'headingXl':
      return 'headingSm'
    case 'heading2xl':
      return 'headingMd'
    default:
      return 'headingSm'
  }
}
