import { lazy, Suspense, useCallback, useState } from 'react'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { Collection } from '@tribeplatform/gql-client/types'
import { T } from '@tribeplatform/react-components/i18n'
import { useNavigate } from '@tribeplatform/react-components/Link'
import { useNetwork } from '@tribeplatform/react-sdk/hooks'
import { Button, IconButton } from '@tribeplatform/react-ui-kit/Button'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

const CollectionDeleteModal = lazy(() =>
  import('@tribeplatform/react-components/Collection').then(m => ({
    default: m.CollectionDeleteModal,
  })),
)
const CollectionFormModal = lazy(() =>
  import('@tribeplatform/react-components/Collection').then(m => ({
    default: m.CollectionFormModal,
  })),
)
const CollectionOrganizationModal = lazy(() =>
  import('@tribeplatform/react-components/Collection').then(m => ({
    default: m.CollectionOrganizationModal,
  })),
)

export const CollectionHeaderActionButtons = ({
  collection,
}: {
  collection?: Collection
}) => {
  const [openOrganizationModal, setOpenOrganizationModal] = useState(false)
  const [openCollectionModal, setOpenCollectionModal] = useState(false)
  const [openDeleteCollectionModal, setOpenDeleteCollectionModal] =
    useState(false)
  const { data: network = {} } = useNetwork()
  const navigate = useNavigate()

  const [canCreateSpace, canCreateCollection] = hasScopesPermission(network, [
    'addSpace',
    'createCollection',
  ])

  const onOrganizationModalClose = useCallback(() => {
    setOpenOrganizationModal(false)
  }, [])

  const onOrganizeClick = useCallback(() => {
    setOpenOrganizationModal(true)
  }, [])

  const onDeleteClick = useCallback(() => {
    setOpenDeleteCollectionModal(true)
  }, [])

  const closeCollectionModal = useCallback(() => {
    setOpenCollectionModal(false)
  }, [])

  const deleteCollectionModal = useCallback(() => {
    setOpenDeleteCollectionModal(false)
  }, [])

  const openCreateSpaceModal = useCallback(() => {
    navigate(`/manage/customizer/create-space?collectionId=${collection?.id}`)
  }, [collection, navigate])

  const openEditCollectionModal = useCallback(() => {
    setOpenCollectionModal(true)
  }, [])

  return (
    <>
      {/* Actions Dropdown */}
      {canCreateCollection && (
        <Dropdown placement="bottom-end">
          <Dropdown.ButtonMinimal as="div">
            <IconButton
              variant="secondaryNeutral"
              icon={<SvgIcon name="dots-horizontal" />}
            />
          </Dropdown.ButtonMinimal>
          <Dropdown.Items className="-mt-2">
            <Dropdown.Item
              leadingIcon={<SvgIcon name="edit" />}
              onClick={openEditCollectionModal}
            >
              <T
                id="Blocks.CollectionHeader.EditCollection"
                defaultMessage="Edit {COLLECTION}"
              />
            </Dropdown.Item>
            <Dropdown.Item
              leadingIcon={<SvgIcon name="list-unordered" />}
              onClick={onOrganizeClick}
            >
              <T id="Generics.Organize" defaultMessage="Organize" />
            </Dropdown.Item>
            <Dropdown.Item
              leadingIcon={<SvgIcon name="trash" />}
              onClick={onDeleteClick}
            >
              <T
                id="Blocks.CollectionHeader.DeleteCollection"
                defaultMessage="Delete {COLLECTION}"
              />
            </Dropdown.Item>
          </Dropdown.Items>
        </Dropdown>
      )}
      {openCollectionModal && (
        <Suspense fallback={<></>}>
          <CollectionFormModal
            open={openCollectionModal}
            id={collection.id}
            name={collection.name}
            description={collection.description}
            onClose={closeCollectionModal}
          />
        </Suspense>
      )}
      {openDeleteCollectionModal && (
        <Suspense fallback={<></>}>
          <CollectionDeleteModal
            open={openDeleteCollectionModal}
            onClose={deleteCollectionModal}
            id={collection.id}
            name={collection.name}
            spaces={collection.spaces.nodes}
          />
        </Suspense>
      )}
      {/* Create Space Button */}
      {canCreateSpace && (
        <Button
          leadingIcon={<SvgIcon name="package-plus" />}
          onClick={openCreateSpaceModal}
        >
          <T id="Generics.AddSpace" defaultMessage="Add {SPACE}" />
        </Button>
      )}
      {openOrganizationModal && (
        <Suspense fallback={<></>}>
          <CollectionOrganizationModal
            open={openOrganizationModal}
            onClose={onOrganizationModalClose}
            id={collection.id}
            spaces={collection.spaces.nodes}
          />
        </Suspense>
      )}
    </>
  )
}
