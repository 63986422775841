export enum SlateLayout {
  /** Layout has topbar and does not have sidebar */
  headerNoSidebar = 'CLASSIC',
  /** Layout has both topbar and sidebar */
  headerSidebar = 'DEFAULT',
  /** Layout has neither topbar nor sidebar */
  noHeaderNoSidebar = 'BASIC',
  /** Layout does not have topbar and has sidebar */
  sidebarNoHeader = 'NO_HEADER',
}
