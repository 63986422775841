import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'

import { SystemSpaceSlug } from '@bettermode/common/space'
import { useRouterPath } from '@tribeplatform/react-sdk'
import { matchPath, useAuthToken } from '@tribeplatform/react-sdk/hooks'

import type { SearchAction } from '../../HeroBanner/types.js'

export type SearchBarContextProps = {
  searchOpen: boolean
  setSearchOpen: Dispatch<SetStateAction<boolean>>
  settings: SearchAction
  hasSearchSection: boolean
}

export const SearchBarContext = createContext<SearchBarContextProps>(null)

export type SearchBarProviderProps = {
  settings: SearchAction
  hasSearchSection: boolean
  children: ReactNode
}

export const SearchBarProvider = ({
  settings,
  hasSearchSection,
  children,
}: SearchBarProviderProps) => {
  const {
    data: { network },
  } = useAuthToken()
  const systemSpaces = network?.systemSpaces
  const pathname = useRouterPath()
  const isSearchPage = !!matchPath(
    systemSpaces,
    SystemSpaceSlug.SEARCH,
    pathname,
  )
  const [searchOpen, setSearchOpen] = useState<boolean>(isSearchPage)

  useEffect(() => {
    if (isSearchPage) {
      setSearchOpen(true)
      return
    }

    setSearchOpen(false)
  }, [isSearchPage, pathname])

  return (
    <SearchBarContext.Provider
      value={{
        searchOpen,
        setSearchOpen,
        settings,
        hasSearchSection,
      }}
    >
      {children}
    </SearchBarContext.Provider>
  )
}

export const useSearchBar = (): SearchBarContextProps => {
  const context = useContext(SearchBarContext)
  return context
}
