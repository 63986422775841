import { Member } from '@tribeplatform/gql-client/types'
import { useRouter } from '@tribeplatform/react-sdk'

import { MemberAvatar } from '../common/components/index.js'

export const ReactionMemberLoading = ({ count = 5 }) => {
  return (
    <>
      {[...Array(count)].map((_e, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} className="bg-surface p-3">
          <div className="animate-pulse flex space-s-4 items-center">
            <div className="rounded-avatar bg-skeleton h-10 w-10" />
            <div className="flex-1 space-y-4 py-1">
              <div className="h-4 bg-skeleton rounded-full w-3/4" />
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export const ReactionMember = ({ member }: { member: Member }) => {
  const { Link } = useRouter()
  return (
    <div className="flex space-s-5 p-3 items-center truncate">
      <MemberAvatar member={member} size="2.5x" clickable />
      <div className="truncate flex-grow">
        <Link
          variant="inherit"
          className="flex-grow overflow-hidden max-w-full"
          href={member?.relativeUrl}
          translate="no"
        >
          <h4 className="text-lg font-medium truncate">{member?.name}</h4>
        </Link>
        {member?.tagline && (
          <Link
            variant="inherit"
            href={member?.relativeUrl}
            className="text-sm text-content-subdued truncate inline-block max-w-full"
          >
            {member?.tagline ? <span>{member?.tagline}</span> : null}
          </Link>
        )}
      </div>
    </div>
  )
}
