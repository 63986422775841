import { T } from '../i18n/index.js'

interface props {
  repliesCount: number
}

export const ReplyCount = ({ repliesCount }: props) => (
  <T
    defaultMessage="{replies_count, plural, =1 {# reply} other {# replies}}"
    id="Reply.Count"
    values={{ replies_count: repliesCount }}
  />
)
