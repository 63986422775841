import { useEffect, useState } from 'react'

import { ActionStatus, type Post } from '@tribeplatform/gql-client/types'
import { useUpdateSubscription } from '@tribeplatform/react-sdk/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'

import { EntityImage } from '../../common/components/EntityImage.js'
import { useLogin } from '../../common/hooks/useLogin.js'
import { convertIdToGlyph } from '../../MediaPicker/utils/utils.js'
import { FollowAction as TFollowAction } from '../../Views/types.js'
import { getTextIconSettings } from '../utils/utils.js'

interface Props {
  post: Post
  action: TFollowAction
}
export const FollowAction = ({ post, action }: Props) => {
  const {
    defaultLabel,
    variant,
    glyphId,
    color,
    activeLabel,
    activeGlyphId,
    activeColor,
  } = action
  const { mutateAsync: updateSubscription } = useUpdateSubscription()
  const [optimisticSubscribed, setOptimisticSubscribed] = useState<boolean>(
    post?.authMemberProps?.subscribed,
  )

  useEffect(() => {
    setOptimisticSubscribed(post?.authMemberProps?.subscribed)
  }, [post?.authMemberProps?.subscribed])

  const { isLoggedIn, showLogin } = useLogin()

  const { showText, showIcon } = getTextIconSettings(variant)

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = async e => {
    if (!isLoggedIn) {
      e.preventDefault()
      showLogin()
      return
    }

    const isSubscribing = !optimisticSubscribed
    setOptimisticSubscribed(isSubscribing)
    try {
      await updateSubscription(
        {
          action: isSubscribing ? 'subscribe' : 'unsubscribe',
          publisherType: 'post',
          publisherId: post.id,
        },
        {
          onSuccess: ({ status }) => {
            if (status === ActionStatus.failed) {
              setOptimisticSubscribed(!isSubscribing)
            }
          },
        },
      )
    } catch (error) {
      setOptimisticSubscribed(!isSubscribing)
    }
  }

  return (
    <div className="flex-1">
      <Button
        variant="secondaryNeutral"
        className="group select-none"
        pressed={optimisticSubscribed}
        size="lg"
        fullWidth
        leadingIcon={
          showIcon &&
          (optimisticSubscribed ? (
            <EntityImage
              entity={{
                image: convertIdToGlyph(activeGlyphId || glyphId),
                name: activeLabel || (defaultLabel ?? ''),
              }}
              size="lg"
              displayColor={activeColor || (color ?? '')}
              iconStyle="solid"
            />
          ) : (
            <EntityImage
              entity={{
                image: convertIdToGlyph(glyphId),
                name: defaultLabel || '',
              }}
              size="lg"
              displayColor={color}
            />
          ))
        }
        onClick={handleClick}
      >
        {showText && (
          <span className="truncate">
            {optimisticSubscribed ? activeLabel || defaultLabel : defaultLabel}
          </span>
        )}
      </Button>
    </div>
  )
}
