import { MultiViewBlock } from '@tribeplatform/react-components/Views'
import { useBlock } from '@tribeplatform/react-slate-kit/hooks'
import type { BC } from '@tribeplatform/react-slate-kit/types'

import { GenericSpacesBlock } from './components/GenericSpacesBlock.js'
import type { SpacesListBlockProps } from './types.js'

export const SpacesListBlock: BC<SpacesListBlockProps> = ({
  title,
  description,
  linkText,
  linkUrl,
  views,
  ...rest
}) => {
  const {
    extraContext: { activeViewIndex, activeFieldId },
  } = useBlock<
    SpacesListBlockProps,
    { activeViewIndex?: number; activeFieldId?: string }
  >()

  return (
    <MultiViewBlock
      activeViewIndex={activeViewIndex}
      title={title}
      description={description}
      views={views}
      linkUrl={linkUrl}
      linkText={linkText}
      {...rest}
    >
      {({ view, preview }) => (
        <GenericSpacesBlock
          view={view}
          activeFieldId={activeFieldId}
          preview={preview}
        />
      )}
    </MultiViewBlock>
  )
}
