import languageManifest from '../../../../lang/manifest.json'
import { TranslatedDictionary } from './types.js'

const { defaultLocale, checksums } = languageManifest
const defaultChecksum = checksums[defaultLocale]

const getLanguageFileChecksum = (locale: string) =>
  checksums[locale] || defaultChecksum

export const fetchTranslationData: (
  locale?: string,
  subfolder?: string,
) => Promise<TranslatedDictionary> = async (
  locale = defaultLocale,
  subfolder,
) => {
  const normalizedLocale = locale.toLowerCase()

  try {
    // When in browser, translation data needs to be fetched from the remote server
    const checksum = getLanguageFileChecksum(normalizedLocale)
    const response = await fetch(
      `${
        subfolder || ''
      }/api/locale/translations/${normalizedLocale}.json?v=${checksum}`,
    )

    return response.json()
  } catch (error) {
    logger.error('Unable to fetch translation data', error)
  }

  return {}
}
