import { Container } from '@tribeplatform/react-ui-kit/Layout'

interface Props {
  children: React.ReactNode
  layoutHasSidebar: boolean
}
export const Content = ({ children, layoutHasSidebar }: Props) => {
  return (
    <Container
      as="main"
      size={layoutHasSidebar ? 'lg' : 'full'}
      spacing="none"
      containerProps={{
        id: 'FrameMain',
      }}
    >
      {children}
    </Container>
  )
}
