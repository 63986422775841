import { IconName as SocialIconName } from '@bettermode/icons/social/types'
import {
  CustomFieldPrivacyOptions,
  CustomFieldSchema,
  CustomFieldType,
  Member,
  Post,
  Space,
  SpaceRoleType,
} from '@tribeplatform/gql-client/types'
import { useAuthMember } from '@tribeplatform/react-sdk/hooks'
import { SvgIcon, SvgSocialIcon } from '@tribeplatform/react-ui-kit/Icon'
import type { TextVariant } from '@tribeplatform/react-ui-kit/Text'

import { logger } from '../common/lib/logger.js'

export const TRUNCATE_SCROLL_OFFSET = '-9rem'

export enum CustomFieldSubtype {
  CHECKBOX = 'checkbox',
  DATE = 'date',
  DATETIME = 'datetime',
  EMAIL = 'email',
  HTML = 'html',
  MULTISELECT = 'multiselect',
  NUMBER = 'number',
  SELECT = 'select',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  TOGGLE = 'toggle',
  URL = 'url',
  RATING = 'rating',
  IMAGE = 'image',
  FILE = 'file',
  MEMBER = 'member',
  TAG = 'tag',
  SPACE = 'space',
  POST = 'post',
  IMAGES = 'images',
  FILES = 'files',
  MEMBERS = 'members',
  TAGS = 'tags',
  SPACES = 'spaces',
  POSTS = 'posts',
}

export enum CustomFieldViews {
  Text = 'Text',
  MultiLineText = 'MultiLineText',
  Title = 'Title',
  Button = 'Button',
  CommaSeparatedText = 'CommaSeparatedText',
  Email = 'Email',
  Phone = 'Phone',
  Url = 'Url',
  Html = 'Html',
  Number = 'Number',
  Rating = 'Rating',
  Date = 'Date',
  Check = 'Check',
  Pills = 'Pills',
  Pill = 'Pill',
  Location = 'Location',
  SocialMedia = 'SocialMedia',
  CoverImage = 'CoverImage',
  Gallery = 'Gallery',
}

export type CustomFieldViewProps = {
  subtype?: CustomFieldSubtype
  field?: CustomFieldSchema
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entity?: any
}

export type TitleSizes = 'xs' | 'sm' | 'md' | 'lg'

export const getFieldSetting = (
  field: Partial<CustomFieldSchema>,
  key: string,
) => {
  const value = field?.settings?.find(setting => setting.key === key)?.value
  if (!value) {
    return null
  }
  try {
    return JSON.parse(value)
  } catch (err) {
    logger.error('Cannot parse value in custom field')
    return null
  }
}

const getNewSettings = (
  field: CustomFieldSchema,
  key: string,
  value: string,
) => {
  const currentSettings = field?.settings ?? []
  const isNewKey = !field?.settings?.some(setting => setting.key === key)

  if (isNewKey) {
    return [...currentSettings, { key, value }]
  }

  return currentSettings.map(setting => {
    if (setting.key === key) {
      return { key, value }
    }

    return setting
  })
}

export const setFieldSetting = (
  field: CustomFieldSchema,
  key: string,
  value,
) => {
  const newValue = JSON.stringify(value)
  const newSettings = getNewSettings(field, key, newValue)

  return {
    ...field,
    settings: newSettings,
  } as CustomFieldSchema
}

export const getFieldIcon = field => {
  const fieldIcons = {
    [CustomFieldType.date]: <SvgIcon name="calendar" />,
    [CustomFieldType.number]: <SvgIcon name="hash" />,
    [CustomFieldType.boolean]: <SvgIcon name="status-success" />,
    [CustomFieldType.richText]: <SvgIcon name="post" />,
    [CustomFieldType.relation]: <SvgIcon name="link-external" />,
    [CustomFieldSubtype.HTML]: <SvgIcon name="post" />,
    [CustomFieldSubtype.TEXTAREA]: <SvgIcon name="field-text" />,
    [CustomFieldSubtype.URL]: <SvgIcon name="link" />,
    [CustomFieldSubtype.SELECT]: <SvgIcon name="selector" />,
    [CustomFieldSubtype.EMAIL]: <SvgIcon name="at-symbol" />,
    [CustomFieldSubtype.CHECKBOX]: <SvgIcon name="check" />,
    [CustomFieldSubtype.TOGGLE]: <SvgIcon name="status-success" />,
    [CustomFieldSubtype.DATETIME]: <SvgIcon name="clock" />,
    [CustomFieldSubtype.RATING]: <SvgIcon name="star" />,
    [CustomFieldSubtype.IMAGE]: <SvgIcon name="image" />,
    [CustomFieldSubtype.IMAGES]: <SvgIcon name="image" />,
    [CustomFieldSubtype.FILE]: <SvgIcon name="attachment" />,
    [CustomFieldSubtype.FILES]: <SvgIcon name="attachment" />,
    [CustomFieldSubtype.MEMBER]: <SvgIcon name="member" />,
    [CustomFieldSubtype.MEMBERS]: <SvgIcon name="members" />,
    [CustomFieldSubtype.TAG]: <SvgIcon name="tag" />,
    [CustomFieldSubtype.TAGS]: <SvgIcon name="tag" />,
    [CustomFieldSubtype.POST]: <SvgIcon name="post" />,
    [CustomFieldSubtype.POSTS]: <SvgIcon name="post" />,
    [CustomFieldSubtype.SPACE]: <SvgIcon name="grid" />,
    [CustomFieldSubtype.SPACES]: <SvgIcon name="grid" />,
  }

  const icon = getFieldSetting(field, 'icon')
  if (icon) {
    return <SvgSocialIcon name={mapFieldToSocialIconName(icon)} rounded />
  }

  const key = field?.subtype || getFieldSetting(field, 'subtype') || field?.type

  return fieldIcons[key] || <SvgIcon name="field" />
}

export const useFieldPermissions = (space: Space) => {
  const { isModerator, data: member } = useAuthMember()
  const isSpaceAdmin =
    space?.authMemberProps?.spaceMember?.role?.type === SpaceRoleType.admin

  return {
    canUpdateField: ({
      field,
      entity,
    }: {
      field: CustomFieldSchema
      entity: Post | Member
    }) => {
      if (isSpaceAdmin || isModerator || !field?.writePrivacy?.allow) {
        return true
      }

      const owner = (entity as Post)?.owner?.member || (entity as Member)

      if (
        field?.writePrivacy?.allow?.indexOf(CustomFieldPrivacyOptions.OWN) !==
        -1
      ) {
        if (owner?.id === member?.id || !entity?.id) {
          return true
        }
      }

      return false
    },
    canViewField: ({
      field,
      entity,
    }: {
      field: CustomFieldSchema
      entity: Post | Member
    }) => {
      if (isSpaceAdmin || isModerator || !field?.readPrivacy?.allow) {
        return true
      }

      const owner = (entity as Post)?.owner?.member || (entity as Member)

      if (
        field?.readPrivacy?.allow?.indexOf(CustomFieldPrivacyOptions.OWN) !== -1
      ) {
        if (owner?.id === member?.id || !entity?.id) {
          return true
        }
      }

      return false
    },
  }
}

export const getTitleVariant = (size: TitleSizes): TextVariant => {
  switch (size) {
    case 'xs':
      return 'heading3xs'
    case 'sm':
      return 'heading2xs'
    case 'md':
    default:
      return 'headingXs'
    case 'lg':
      return 'headingSm'
  }
}

const mapFieldToSocialIconName = (icon: string): SocialIconName => {
  switch (icon) {
    case 'linkedin':
      return 'linked-in-monochrome'
    case 'stackoverflow':
      return 'stack-overflow-monochrome'
    default:
      return `${icon}-monochrome` as SocialIconName
  }
}
