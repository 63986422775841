import { SpaceFields, spaceGQLFields } from '../space/space.fields.js'
import {
  actionPermissionGQLFields,
  ActionPermissionsFields,
} from './actionPermissions.fields.js'

export type PostTypeAuthMemberPropsFields =
  | 'basic'
  | 'all'
  | CustomPostTypeAuthMemberPropsFields

export interface CustomPostTypeAuthMemberPropsFields {
  permissions?: ActionPermissionsFields
  availableSpaces?: SpaceFields
}

const BASIC_POST_TYPE_AUTH_MEMBER_PROPS_FIELDS: CustomPostTypeAuthMemberPropsFields =
  {}
const ALL_POST_TYPE_AUTH_MEMBER_PROPS_FIELDS: CustomPostTypeAuthMemberPropsFields =
  {
    permissions: 'basic',
  }

export function postTypeAuthMemberPropsGQLFields(
  fields: PostTypeAuthMemberPropsFields,
) {
  if (fields === 'basic') fields = BASIC_POST_TYPE_AUTH_MEMBER_PROPS_FIELDS
  if (fields === 'all') fields = ALL_POST_TYPE_AUTH_MEMBER_PROPS_FIELDS
  return `
    context
    scopes
    ${
      fields.permissions
        ? `
      permissions {
        ${actionPermissionGQLFields(fields.permissions)}
      }
    `
        : ``
    }
    ${
      fields.availableSpaces
        ? `
      availableSpaces {
        ${spaceGQLFields(fields.availableSpaces)}
      }
    `
        : ``
    }    
  `
}
