import { lazy } from 'react'

import type { I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

import { BlockType, RegisteredBlock } from '../../types/index.js'
import { LeaderboardBlock as Component } from './Block.js'
import { DEFAULT_MEMBERS_COUNT } from './constants.js'
import { LeaderboardProps } from './types.js'

const Settings = lazy(() =>
  import('./Settings.js').then(m => ({ default: m.LeaderboardBlockSettings })),
)

export const RegisteredLeaderboardBlock = ({
  $t,
}: I18nShape): RegisteredBlock<LeaderboardProps> => {
  return {
    recommendedName: 'Leaderboard',
    Component,
    config: {
      type: BlockType.Block,
      usable: true,
      displayName: $t({
        id: 'Generics.Leaderboard',
        defaultMessage: 'Leaderboard',
      }),
      Settings,
      Icon: <SvgIcon name="star" />,
      defaultProps: {
        heading: $t({
          id: 'Generics.Leaderboard',
          defaultMessage: 'Leaderboard',
        }),
        spaceId: '',
        membersCount: DEFAULT_MEMBERS_COUNT,
        showScore: true,
      },
    },
  }
}
