import { createContext, useContext } from 'react'

import type { TruncateSettings } from './types.js'

export const TruncateSettingsContext = createContext<TruncateSettings>({})

export const useTruncateSettings = () => {
  const context = useContext(TruncateSettingsContext)
  return context
}
