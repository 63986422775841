import { unstable_useBlocker as useBlocker } from 'react-router'

import { useFirstRenderContext } from '@tribeplatform/react-components/common/ssr'

interface Props {
  pathPrefixes: string[]
  subfolder: string
}
export const useHardRefresh = ({ pathPrefixes, subfolder }: Props): void => {
  const { firstRenderPath } = useFirstRenderContext()
  useBlocker(({ nextLocation }) => {
    const matchesPrefix = pathPrefixes.some(prefix => {
      return nextLocation.pathname.startsWith(prefix)
    })

    const hasChangedClientSide = nextLocation.pathname !== firstRenderPath

    if (matchesPrefix && hasChangedClientSide) {
      window.location.href = `${subfolder}${nextLocation.pathname}${nextLocation.search}${nextLocation.hash}`
      return true
    }
    return false
  })
}
