import { clsx } from 'clsx'

import { useCollection } from '@tribeplatform/react-sdk/hooks'
import { PageHeader } from '@tribeplatform/react-ui-kit/PageHeader'

import { BC } from '../../types/index.js'
import { CollectionHeaderActionButtons } from './CollectionHeaderActionButtons.js'
import { CollectionHeaderProps } from './types.js'

export const CollectionHeaderBlock: BC<CollectionHeaderProps> = ({
  collectionId,
  className,
  ...rest
}) => {
  const { data: collection } = useCollection({
    variables: { id: collectionId },
    fields: {
      spaces: { fields: 'all' },
    },
  })

  return (
    <div className={clsx('py-3', className)} {...rest}>
      <PageHeader
        title={collection?.name}
        description={collection?.description}
        action={<CollectionHeaderActionButtons collection={collection} />}
      />
    </div>
  )
}
