import { FC, ReactNode, useCallback, useMemo } from 'react'

import { clsx } from 'clsx'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import { Post } from '@tribeplatform/gql-client/types'
import {
  useAddReaction,
  useRemoveReaction,
} from '@tribeplatform/react-sdk/hooks'
import { Button } from '@tribeplatform/react-ui-kit/Button'
import { Icon as IconComponent } from '@tribeplatform/react-ui-kit/Icon'
import { Link } from '@tribeplatform/react-ui-kit/Link'

import { useLogin } from '../../common/hooks/useLogin.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { confirmActionOnLockedPost } from '../utils.js'

export const LegacySingleReaction: FC<{
  post: Post
  text: string
  reaction: string
  Icon: ReactNode
  className?: string
  simple?: boolean
  showCount?: boolean
}> = ({
  post,
  text,
  reaction,
  Icon,
  className,
  simple = false,
  showCount = false,
}) => {
  const intl = useI18n()
  const { mutate: removeReaction } = useRemoveReaction()
  const { mutate: addReaction } = useAddReaction()
  const { isLoggedIn, showLogin } = useLogin()

  const selectedReaction = useMemo(
    () => post?.reactions?.find(r => r.reaction === reaction),
    [post.reactions, reaction],
  )
  const reacted = selectedReaction?.reacted

  let disabled
  if (post && isLoggedIn) {
    const [canReact] = hasScopesPermission(post, ['addReaction'])
    if (!canReact || !post.authMemberProps.canReact) {
      disabled = true
    }
  }

  const onRemoveReaction = useCallback(
    async reaction => {
      if (!(await confirmActionOnLockedPost(intl)(post))) {
        return
      }

      removeReaction({
        postId: post.id,
        reaction,
      })
    },
    [removeReaction, post, intl],
  )

  const onAddReaction = useCallback(
    async reaction => {
      if (!isLoggedIn) {
        return showLogin()
      }
      if (!(await confirmActionOnLockedPost(intl)(post))) {
        return
      }
      addReaction({
        postId: post?.id,
        input: {
          reaction,
        },
      })
    },
    [addReaction, post, showLogin, isLoggedIn, intl],
  )

  if (simple) {
    return (
      <Link
        as="button"
        variant={reacted ? 'accent' : 'neutral'}
        disabled={disabled}
        className={clsx('flex items-center', className)}
        onClick={() => {
          if (reacted) {
            onRemoveReaction(reaction)
          } else {
            onAddReaction(reaction)
          }
        }}
      >
        <span className="me-2">{Icon}</span>
        <span>{text}</span>
      </Link>
    )
  }

  return (
    <div className="flex-1 flex min-w-0">
      <Button
        variant="secondaryNeutral"
        className={clsx('group', className)}
        size="lg"
        fullWidth
        disabled={disabled}
        leadingIcon={!showCount && Icon}
        pressed={reacted}
        onClick={e => {
          e.preventDefault()
          if (reacted) {
            onRemoveReaction(reaction)
          } else {
            onAddReaction(reaction)
          }
        }}
      >
        {showCount ? (
          <span className="flex flex-col">
            <IconComponent className="w-6 h-6">{Icon}</IconComponent>
            <span className="-mt-1">{post?.reactionsCount}</span>
          </span>
        ) : (
          <span className="truncate">{text}</span>
        )}
      </Button>
    </div>
  )
}
