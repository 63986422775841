import { I18nShape } from '../i18n/types.js'

export const getWelcomeMessageText = (intl: I18nShape, hour: number) => {
  const { $t } = intl
  let message: string
  if (hour < 12) {
    message = $t({
      id: 'Greetings.Morning',
      defaultMessage: 'Good morning',
    })
  } else if (hour < 18) {
    message = $t({
      id: 'Greetings.Afternoon',
      defaultMessage: 'Good afternoon',
    })
  } else {
    message = $t({
      id: 'Greetings.Evening',
      defaultMessage: 'Good evening',
    })
  }
  return message
}
