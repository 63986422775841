import { DocumentNode } from 'graphql'
import { gql } from 'graphql-tag'

import { ActionFields, actionGQLFields } from '../common/action.fields.js'
import { MemberFields, memberGQLFields } from './member.fields.js'

export function getMembersGQLQuery(fields: MemberFields): DocumentNode {
  return gql`
    query Members(
      $after: String
      $before: String
      $filterBy: [MemberListFilterByInput!]
      $limit: Int!
      $offset: Int
      $orderBy: String
      $query: String
      $reverse: Boolean
      $roleIds: [ID!]
      $status: [MemberStatusInput!]
    ){
      members(
        after: $after
        before: $before
        filterBy: $filterBy
        limit: $limit
        offset: $offset
        orderBy: $orderBy
        query: $query
        reverse: $reverse
        roleIds: $roleIds
        status: $status
      ) {
        totalCount
        edges {
          node {
            ${memberGQLFields(fields)}
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `
}

export function getMemberGQLQuery(fields: MemberFields): DocumentNode {
  return gql`
    query Member($id: ID, $username: String) {
      member(id: $id, username: $username) {
        ${memberGQLFields(fields)}
      }
    }
  `
}

export function updateMemberGQLQuery(fields: MemberFields): DocumentNode {
  return gql`
    mutation UpdateMember($input: UpdateMemberInput!, $id: ID) {
      updateMember(id: $id, input: $input) {
        ${memberGQLFields(fields)}
      }
    }
  `
}

export function deleteMemberGQLMutation(): DocumentNode {
  return gql`
    mutation DeleteMember($id: ID!) {
      deleteMember(id: $id) {
        status
      }
    }
  `
}

export const cancelEmailUpdateGQLMutation = () => gql`
  mutation CancelEmailUpdate($id: ID) {
    cancelEmailUpdate(id: $id) {
      status
    }
  }
`

export const blockMemberGQLMutation = (fields: ActionFields) => gql`
  mutation blockMember($memberId: String!) {
    blockMember(memberId: $memberId) {
        ${actionGQLFields(fields)}
    }
  }
`

export const unblockMemberGQLMutation = (fields: ActionFields) => gql`
  mutation unblockMember($memberId: String!) {
    unblockMember(memberId: $memberId) {
        ${actionGQLFields(fields)}
    }
  }
`
