import React, { useRef } from 'react'

import { clsx } from 'clsx'

import { Image } from '@tribeplatform/gql-client/types'
import { useMeasure } from '@tribeplatform/react-ui-kit/hooks'

const clamp = (min: number, value: number, max: number) =>
  Math.min(Math.max(value, min), max)

interface CalculateProps {
  naturalHeight: number
  naturalWidth: number
  height: number
  width: number
  cropY: number
}
const calculate = ({
  naturalHeight,
  naturalWidth,
  height,
  width,
  cropY,
}: CalculateProps) => {
  if (!(width > 0 && height > 0 && naturalWidth > 0 && naturalHeight > 0)) {
    return {
      translateY: cropY,
      width: undefined,
      height: undefined,
    }
  }

  const mediaAspect = naturalWidth / naturalHeight
  const calculatedHeight = width / mediaAspect

  const cropBound = Math.abs(calculatedHeight - height) / 2
  const translateY =
    cropBound > 0 ? clamp(0 - cropBound, cropY, cropBound) : cropY

  return { translateY, width, height: calculatedHeight }
}

interface LegacyBannerProps {
  banner: Image
  className?: string
}

export const LegacyBanner = ({ banner, className }: LegacyBannerProps) => {
  const [wrapperRef, { width, height }] = useMeasure<HTMLDivElement>()
  const imageRef = useRef<HTMLImageElement>()
  const imageWidth = imageRef.current?.naturalWidth
  const imageHeight = imageRef.current?.naturalHeight
  const src = banner.urls?.large ?? banner.url
  const cropY = banner.cropY ?? 0

  const { translateY, width: calculatedWidth } = calculate({
    naturalHeight: imageHeight,
    naturalWidth: imageWidth,
    height,
    width,
    cropY,
  })

  return (
    <div
      ref={wrapperRef}
      className={clsx(
        'overflow-hidden flex items-center transition-colors',
        className,
      )}
      style={{
        backgroundColor: banner.dominantColorHex,
      }}
    >
      <img
        ref={imageRef}
        src={src}
        style={{
          transform: `translate(0px, ${translateY}px)`,
          opacity: calculatedWidth > 0 ? 100 : 0,
        }}
        className="w-full h-auto"
        alt=""
      />
    </div>
  )
}
