import { Form } from '../../Form/index.js'
import { useI18n } from '../../i18n/providers/I18nProvider.js'
import { CustomFieldSubtype } from '../utils.js'

export const NumberInput = ({ name, onChange, subtype, extraProps }) => {
  const { $t } = useI18n()
  if (subtype === CustomFieldSubtype.RATING) {
    extraProps = {
      ...extraProps,
      ...{
        min: 0,
        max: 5,
        step: 1,
        placeholder:
          extraProps?.placeholder ||
          $t({
            id: 'CustomField.FieldInput.DefaultNumberInputPlaceholder',
            defaultMessage: 'Between 0 and 5',
          }),
      },
    }
  }

  return (
    <Form.Input
      name={name}
      type="number"
      onChange={e => {
        if (typeof onChange === 'function') {
          onChange(e.target.value)
        }
      }}
      className="py-1.5"
      {...extraProps}
    />
  )
}
