import { CustomFieldType } from '@tribeplatform/gql-client/types'
import { CustomFieldSubtype } from '@tribeplatform/react-components/CustomField'
import { type RawFilter } from '@tribeplatform/react-components/Filters'
import { type I18nShape } from '@tribeplatform/react-components/i18n'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'

export const staticFiltersFactory = (intl: I18nShape): RawFilter[] => {
  const { $t } = intl
  return [
    {
      id: 'tagIds',
      key: 'tagIds',
      name: $t({
        defaultMessage: '{TAGS_CC}',
        id: 'Generics.Tags',
      }),
      type: CustomFieldType.array,
      subType: CustomFieldSubtype.TAGS,
      icon: <SvgIcon name="tag" />,
    },
    {
      id: 'title',
      key: 'title',
      name: $t({
        defaultMessage: 'Title',
        id: 'Generics.Title',
      }),
      type: CustomFieldType.text,
      icon: <SvgIcon name="field" />,
    },
    {
      id: 'ownerId',
      key: 'ownerId',
      name: $t({
        defaultMessage: 'Author',
        id: 'Generics.Author',
      }),
      type: CustomFieldType.relation,
      subType: CustomFieldSubtype.MEMBER,
      icon: <SvgIcon name="member" />,
    },
    {
      id: 'publishedAt',
      key: 'publishedAt',
      name: $t({
        defaultMessage: 'Published date',
        id: 'Generics.PublishedDate',
      }),
      type: CustomFieldType.date,
      icon: <SvgIcon name="calendar" />,
    },
  ]
}
