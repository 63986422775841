import { useCallback } from 'react'

import { ics } from 'calendar-link'

import { useRouter } from '@tribeplatform/react-sdk'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'

import { BC } from '../../types/index.js'
import { CalendarType, CalendarItemProps } from './types.js'

export const CalendarItemComponent: BC<CalendarItemProps> = ({
  actionItem,
  event,
}) => {
  const { Link } = useRouter()

  const handleDownloadICSFile = useCallback(() => {
    try {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.download = event.title
      a.href = ics(event)
      a.click()
    } catch {
      throw new Error('failed to download ics file.')
    }
  }, [event])

  const Icon = actionItem.icon

  if (actionItem.type === CalendarType.ICS) {
    return (
      <Dropdown.Item
        key={actionItem.name}
        onClick={handleDownloadICSFile}
        leadingIcon={<>{Icon}</>}
      >
        {actionItem.name}
      </Dropdown.Item>
    )
  }

  return (
    <Dropdown.Item
      key={actionItem.name}
      as={Link}
      href={actionItem.url}
      target="_blank"
      leadingIcon={<>{Icon}</>}
    >
      {actionItem.name}
    </Dropdown.Item>
  )
}
