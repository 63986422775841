import { createContext, MutableRefObject, useContext, useRef } from 'react'

import type { Placement } from '@floating-ui/react'

export type FloatingContextType = {
  triggerRef: MutableRefObject<HTMLElement>
  reference: (node: HTMLElement | null) => void
  /**
   * see https://floating-ui.com/docs/computePosition#placement
   */
  placement: Placement
}

export const FloatingContext = createContext<FloatingContextType>(undefined)

export const FloatingContextProvider = ({ placement, children }) => {
  const triggerRef = useRef<HTMLElement | null>(null)

  const setRef = ref => {
    triggerRef.current = ref
  }

  return (
    <FloatingContext.Provider
      value={{
        placement,
        triggerRef,
        reference: setRef,
      }}
    >
      {children}
    </FloatingContext.Provider>
  )
}

export const useFloatingContext = () => {
  const context = useContext(FloatingContext)
  if (context === undefined) {
    throw new Error('useFloatingContext must be used within a FloatingProvider')
  }
  return context
}
