import { NavVertical } from '@tribeplatform/react-ui-kit/Sidebar'

import type { MenuItem } from '../../types.js'
import { MobileMenuItem } from './MobileMenuItem.js'

interface Props {
  menuItems: MenuItem[]
  close: () => void
}
export const MobileMenuFromHeaderMenu = ({ menuItems, close }: Props) => {
  return (
    <NavVertical>
      <NavVertical.Group>
        {menuItems.map(menuItem => (
          <MobileMenuItem key={menuItem.id} item={menuItem} close={close} />
        ))}
      </NavVertical.Group>
    </NavVertical>
  )
}
