import { lazy, Suspense, useState } from 'react'

import type { CropOptions, CropResult } from './types.js'

const CropImageModal = lazy(() =>
  import('./CropImageModal.js').then(m => ({ default: m.CropImageModal })),
)

interface UseCropImageModalProps {
  onSuccess: (cropResult: CropResult) => void
  skipFileTypes?: string[]
  options: CropOptions
}

export const useCropImageModal = ({
  onSuccess,
  skipFileTypes = ['image/svg+xml', 'image/gif'],
  options,
}: UseCropImageModalProps) => {
  const [cropFile, setCropFile] = useState<File | null>(null)

  const onSelectFile = (file: File) => {
    // do not crop svg and gif files
    if (skipFileTypes?.includes(file?.type)) {
      onSuccess?.({ croppedImage: file, zoom: 1, crop: { x: 0, y: 0 } })
      return
    }
    setCropFile(file)
  }

  const onCrop = (cropResult: CropResult) => {
    setCropFile(null)
    onSuccess?.(cropResult)
  }

  const reset = () => {
    setCropFile(null)
  }

  const children = (
    <>
      {cropFile && (
        <Suspense fallback={<></>}>
          <CropImageModal
            open
            close={reset}
            initialImage={cropFile}
            onSubmit={onCrop}
            options={options}
          />
        </Suspense>
      )}
    </>
  )

  return {
    onSelectFile,
    children,
  }
}
