/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'

import { useI18n } from '../providers/I18nProvider.js'
import { I18nShape } from '../types.js'

// eslint-disable-next-line unused-imports/no-unused-vars
type Tail<T extends any[]> = T extends [infer A, ...infer R] ? R : never

export const useStaticIntl: <
  T extends (intl: I18nShape, ...args: any[]) => any,
>(
  factory: T,
  ...factoryArgs: Tail<Parameters<T>>
) => ReturnType<T> = (factory, ...factoryArgs) => {
  const intl = useI18n()

  return useMemo(
    () => factory(intl, ...factoryArgs),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [factory, intl, ...factoryArgs],
  )
}
