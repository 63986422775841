import { useState } from 'react'

import { hasScopesPermission } from '@tribeplatform/gql-client/lib'
import {
  ModerationEntityType,
  PinnedInto,
  Post,
} from '@tribeplatform/gql-client/types'
import {
  useDeletePost,
  useHidePost,
  useNetwork,
  usePinReplyToPost,
  useUnhidePost,
  useUnpinReplyFromPost,
} from '@tribeplatform/react-sdk/hooks'
import { IconButton } from '@tribeplatform/react-ui-kit/Button'
import { Dropdown } from '@tribeplatform/react-ui-kit/Dropdown'
import { SvgIcon } from '@tribeplatform/react-ui-kit/Icon'
import { toast } from '@tribeplatform/react-ui-kit/Toast'

import { T } from '../i18n/components/T.js'
import { useI18n } from '../i18n/providers/I18nProvider.js'
import { ReportModal } from '../ReportModal/index.js'

export const ReplyOptions = ({
  reply,
  onEdit,
  post,
}: {
  reply: Post
  onEdit: () => void
  post?: Post
}) => {
  const { $t } = useI18n()

  const { mutateAsync: deletePost } = useDeletePost()
  const { mutateAsync: hidePost } = useHidePost()
  const { mutateAsync: unhidePost } = useUnhidePost()
  const { mutateAsync: pinReply } = usePinReplyToPost()
  const { mutateAsync: unpinReply } = useUnpinReplyFromPost()

  const { data: network } = useNetwork()

  const [reportModal, setReportModal] = useState<boolean>(false)

  const isPinned = reply?.pinnedInto?.includes(PinnedInto.post)

  const [canUpdatePost, canRemovePost, canHidePost] = hasScopesPermission(
    reply,
    ['updatePost', 'removePost', 'hidePost'],
  )
  const [canReportPost] = hasScopesPermission(network, [
    'createModerationReport',
  ])

  const [canPinReplyToPost] = hasScopesPermission(post, ['pinReplyToPost'])

  const removePost = () => {
    if (
      window.confirm(
        $t({
          id: 'Reply.ReplyOptions.ReplyDeletionConfirmText',
          defaultMessage: 'Are you sure you want to delete this reply?',
        }),
      )
    ) {
      deletePost({
        id: reply.id,
      })
    }
  }

  const toggleHidePost = () => {
    if (reply.isHidden) {
      unhidePost({
        id: reply.id,
      })
    } else {
      hidePost({
        id: reply.id,
      })
    }
  }

  const togglePin = async () => {
    const rootPostId = reply?.repliedToIds?.[reply?.repliedToIds.length - 1]
    try {
      if (isPinned) {
        await unpinReply({
          replyId: reply?.id,
          postId: rootPostId,
        })
      } else {
        await pinReply({
          replyId: reply?.id,
          postId: rootPostId,
        })
      }
    } catch (error) {
      if (error?.response?.errors?.find(e => e.code === '102')) {
        return toast({
          title: $t({
            id: 'Reply.ReplyOptions.CantMakeMultipleRepliesAsBestTitle',
            defaultMessage: 'Cannot mark multiple replies as best reply',
          }),
          description: $t({
            id: 'Reply.ReplyOptions.CantMakeMultipleRepliesAsBestDescription',
            defaultMessage:
              'Please unmark the current best reply and try again.',
          }),
          status: 'error',
        })
      }
      toast({
        title: $t({
          id: 'Generics.SomethingWentWrong',
          defaultMessage: 'Something went wrong',
        }),
        description: $t({
          id: 'Generics.TryAgainLater',
          defaultMessage: 'Please try again later.',
        }),
        status: 'error',
      })
    }
  }

  if (
    !canRemovePost &&
    !canUpdatePost &&
    !canHidePost &&
    !canPinReplyToPost &&
    !canReportPost
  ) {
    return null
  }

  return (
    <>
      <Dropdown placement="bottom-end">
        <Dropdown.ButtonMinimal>
          <IconButton
            as="span"
            variant="tertiaryNeutral"
            size="sm"
            icon={<SvgIcon name="dots-horizontal" />}
          />
        </Dropdown.ButtonMinimal>
        <Dropdown.Items>
          {canUpdatePost && (
            <Dropdown.Item
              leadingIcon={<SvgIcon name="edit" />}
              onClick={onEdit}
            >
              <T id="Generics.Edit" defaultMessage="Edit" />
            </Dropdown.Item>
          )}
          {canRemovePost && (
            <Dropdown.Item
              onClick={removePost}
              leadingIcon={<SvgIcon name="trash" />}
            >
              <T id="Generics.Delete" defaultMessage="Delete" />
            </Dropdown.Item>
          )}
          {canHidePost && (
            <Dropdown.Item
              leadingIcon={<SvgIcon name="eye-off" />}
              onClick={toggleHidePost}
            >
              {reply.isHidden
                ? $t({
                    id: 'Generics.Unhide',
                    defaultMessage: 'Unhide',
                  })
                : $t({
                    id: 'Generics.Hide',
                    defaultMessage: 'Hide',
                  })}
            </Dropdown.Item>
          )}
          {canPinReplyToPost && (
            <Dropdown.Item
              leadingIcon={<SvgIcon name="status-success" />}
              onClick={togglePin}
            >
              {isPinned ? (
                <T
                  id="Reply.ReplyOptions.RemoveAsBest"
                  defaultMessage="Remove as best reply"
                />
              ) : (
                <T
                  id="Reply.ReplyOptions.MarkAsBest"
                  defaultMessage="Mark as best reply"
                />
              )}
            </Dropdown.Item>
          )}
          {canReportPost && (
            <Dropdown.Item
              leadingIcon={<SvgIcon name="status-warning" />}
              onClick={() => setReportModal(true)}
            >
              <T
                id="Reply.ReplyOptions.ReportReplyButtonText"
                defaultMessage="Report reply"
              />
            </Dropdown.Item>
          )}
        </Dropdown.Items>
      </Dropdown>
      {reportModal && (
        <ReportModal
          entity={reply}
          entityType={ModerationEntityType.POST}
          onClose={() => setReportModal(false)}
          open
        />
      )}
    </>
  )
}
